import { useMemo, useRef } from 'react';
import { useEffect, useState } from 'react';

const getSnapshotData = (snapshot, idName) => {
  const data = [];

  snapshot.forEach((doc) =>
    data.push({
      ...doc.data(),
      [idName]: doc.id,
    })
  );

  return data;
};

export default function useDataSnapshot(
  {
    fetchFunction = () => {},
    idName = 'id',
    formatFunction = (values) => values,
    getSnapshotDataFunction = getSnapshotData,
    initialData = [],
    shouldLoad = true,
    onError,
    onUpdate,
    retryOnError = false,
    maxRetries = 1,
  },
  dependencies = []
) {
  const [data, setData] = useState(initialData);
  const [isLoading, setIsLoading] = useState(true);
  const snapshotRef = useRef(null);
  const numRetries = useRef(0);

  const _dependencies = useMemo(
    () => [shouldLoad, ...dependencies],
    [shouldLoad, dependencies]
  );

  useEffect(() => {
    return () => {
      snapshotRef.current ? snapshotRef.current() : null;
    };
  }, []);

  useEffect(() => {
    load();
  }, _dependencies);

  const load = () => {
    snapshotRef.current && snapshotRef.current();

    if (!shouldLoad) return;

    snapshotRef.current = fetchFunction().onSnapshot(
      async (snapshot) => {
        const data = await formatFunction(
          getSnapshotDataFunction(snapshot, idName)
        );
        setData(data);
        setIsLoading(false);

        if (onUpdate) onUpdate(data);

        numRetries.current = 0;
      },
      (err) => {
        if (onError) onError(err);
        console.error(err);
        setIsLoading(false);
        if (retryOnError && numRetries.current < maxRetries) {
          numRetries.current++;
          load();
        }
      }
    );
  };

  return [data, setData, isLoading];
}
