import React from 'react';
import PaginatedData from '../../components/common/PaginatedData';
import BudgetCard from '../../components/reports/BudgetCard';
import firebaseApp from '../../services/Firebase';
import { getDocument } from '../../utils/firebase';

export default function NormalListView({
  budgets,
  itemsPerPage,
  openModal,
  dateSelection,
  filters,
  cards,
  getData,
}) {
  // Functions
  const getUser = (userId) => {
    return getData(`user-${userId}`, () =>
      getDocument(() => firebaseApp.getUserFromId(userId).get())
    );
  };

  return (
    <div>
      <PaginatedData
        items={budgets}
        itemsPerPage={itemsPerPage}
        dependencies={[budgets, dateSelection, filters]}
        render={(budget) => (
          <BudgetCard
            key={budget.id}
            cards={cards}
            budget={budget}
            onClick={() => openModal(budget.id)}
            getUser={getUser}
          />
        )}
      />
    </div>
  );
}
