import React, { Fragment } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { Row, Nav, NavItem } from 'reactstrap';
import classnames from 'classnames';
import IntlMessages from '../../helpers/IntlMessages';
import { Colxx, Separator } from '../../components/common/CustomBootstrap';
import Breadcrumb from '../../containers/navs/Breadcrumb';

import PolicyDataListView from '../../containers/policy/PolicyDataListView';
import RefundDataListView from '../../containers/expenses/RefundDataListView';
import BudgetDataListView from '../../containers/expenses/BudgetDataListView';
import RefundConfigDataListView from '../../containers/expenses/RefundConfigDataListView';
import AliasView from '../../containers/expenses/AliasView';
import MerchantsView from 'src/portao3-legacy/containers/expenses/MerchantsView/MerchantsView';

export default function Expenses(props) {
  const { tabId } = useParams();

  // Render
  return (
    <Fragment>
      <Row>
        <Colxx xxs="12">
          <Breadcrumb heading="admin.refunds.title" match={props.match} />
          <Separator className="mb-5" />
        </Colxx>
      </Row>
      <Row>
        <Colxx xxs="12" className="mb-4">
          <div>
            <Nav className="admin-tabs">
              <NavItem>
                <NavLink
                  to="/app/payments/budget"
                  className={classnames({
                    active: tabId === 'budget',
                    'nav-link': true,
                  })}
                >
                  <IntlMessages id="admin.refunds.budget" />
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  to="/app/payments/categories"
                  className={classnames({
                    active: tabId === 'categories',
                    'nav-link': true,
                  })}
                >
                  <IntlMessages id="admin.refunds.categories" />
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  to="/app/payments/merchants"
                  className={classnames({
                    active: tabId === 'merchants',
                    'nav-link': true,
                  })}
                >
                  <IntlMessages id="admin.policy.merchants" />
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  to="/app/payments/rules"
                  className={classnames({
                    active: tabId === 'rules',
                    'nav-link': true,
                  })}
                >
                  <IntlMessages id="admin.policy.approvalRules" />
                </NavLink>
              </NavItem>
              {/* <NavItem>
                <NavLink
                  to="/app/payments/alias"
                  className={classnames({
                    active: tabId === 'alias',
                    'nav-link': true,
                  })}
                >
                  <IntlMessages id="admin.refunds.alias" />
                </NavLink>
              </NavItem> */}
              <NavItem>
                <NavLink
                  to="/app/payments/config"
                  className={classnames({
                    active: !tabId || tabId === 'config',
                    'nav-link': true,
                  })}
                >
                  <IntlMessages id="general.config" />
                </NavLink>
              </NavItem>
            </Nav>

            {!tabId || tabId === 'config' ? <RefundConfigDataListView /> : null}
            {tabId === 'categories' ? <RefundDataListView /> : null}
            {tabId === 'rules' ? <PolicyDataListView tabId="payments" /> : null}
            {tabId === 'budget' ? <BudgetDataListView /> : null}
            {tabId === 'alias' ? <AliasView /> : null}
            {tabId === 'merchants' ? <MerchantsView /> : null}
          </div>
        </Colxx>
      </Row>
    </Fragment>
  );
}
