import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Label,
  Input,
  InputGroup,
  Collapse,
  CardBody,
} from 'reactstrap';
import { Colxx } from '../common/CustomBootstrap';
import IntlMessages from '../../helpers/IntlMessages';
import map from 'lodash/map';
import Select from 'react-select';

import firebaseApp from '../../services/Firebase';

import {
  rulesOptions,
  comparisonOptions,
  actionType,
  actionTypeValues,
  policyTypes,
  actionAutoValue,
  comparisonOptionsByType,
} from '../../constants/policyOptions';
import { useIntl } from 'react-intl';
import { trackPropertiesSegment } from '../../utils/segment';
import PolicyInput from './PolicyInput';
import useDataArchive from '../../hooks/useDataArchive';
import CustomAnimatedCheckbox from '../common/CustomCheckbox';
import CustomTooltip from '../common/CustomTooltip';
import useShowToggle from 'src/portao3-legacy/hooks/useShowToggle';
import { sortAlphabetically } from 'src/portao3-legacy/utils/sort';
import { formatActions } from 'src/portao3-legacy/utils/policy';
import { getDocumentsFromCollection } from 'src/portao3-legacy/utils/firebase';

function PolicyRule(props) {
  const {
    gI,
    rule = {},
    rI,
    removeRule,
    updateRuleField,
    updateRuleComparison,
    updateRuleValue,
    type = 'travels',
    getDataFromArchive,
  } = props;

  // Hooks
  const { messages } = useIntl();

  // States
  const rules = useMemo(() => rulesOptions[type], [type]);

  const selectedRule = useMemo(
    () => rules.find((r) => r.value === rule.field),
    [rules, rule.field]
  );

  const options = useMemo(
    () =>
      comparisonOptionsByType[
        selectedRule?.comparissonType || selectedRule?.type
      ] || comparisonOptions,
    [selectedRule]
  );

  // Render
  return (
    <div className="rule-row">
      <div className="rule-delete">
        <Button
          color="primary"
          className="ruleAction"
          onClick={() => {
            removeRule(gI, rI);
          }}
        >
          <div className="glyph-icon simple-icon-minus" />
        </Button>
      </div>
      <InputGroup key={`policy-${gI}-${rI}`}>
        <Label
          className="form-group has-top-label w-100"
          style={{ maxWidth: '40%' }}
        >
          <Select
            className="react-select"
            classNamePrefix="react-select"
            isClearable={false}
            name="form-field-name"
            placeholder=""
            value={rules.find((el) => el.value === rule.field)}
            onChange={(e) => updateRuleField(gI, rI, e)}
            options={rules}
            noOptionsMessage={() => messages['general.no-options-found']}
          />
          <IntlMessages id="admin.policy.policy.Field" />
        </Label>
        <Label
          className="form-group has-top-label w-100"
          style={{ maxWidth: '30%' }}
        >
          <Select
            className="react-select"
            classNamePrefix="react-select"
            isClearable={false}
            name="form-field-name"
            placeholder=""
            value={options.find((el) => el.value === rule.comparison)}
            onChange={(e) => updateRuleComparison(gI, rI, e)}
            options={options}
          />
          <IntlMessages id="admin.policy.policy.Comparison" />
        </Label>
        <Label
          className="form-group has-top-label w-100 policy-input"
          style={{ maxWidth: '30%' }}
        >
          <PolicyInput
            rule={selectedRule}
            value={rule.value}
            onChange={(e) => updateRuleValue(gI, rI, e)}
            getDataFromArchive={getDataFromArchive}
          />
          <IntlMessages id="admin.policy.policy.Value" />
        </Label>
      </InputGroup>
    </div>
  );
}

function PolicyGroup(props) {
  const {
    changeGroupComparison,
    removeRule,
    updateRuleField,
    updateRuleComparison,
    updateRuleValue,
    addRule,
    group,
    index,
    type = 'travels',
    getDataFromArchive,
  } = props;

  return (
    <div className="group-row mb-4">
      <div className="group">
        <div className="group-content">
          {map(group.rules, (rule, rI) => {
            return (
              <PolicyRule
                key={`rule_${index}_${rI}`}
                gI={index}
                rule={rule}
                rI={rI}
                removeRule={removeRule}
                updateRuleField={updateRuleField}
                updateRuleComparison={updateRuleComparison}
                updateRuleValue={updateRuleValue}
                type={type}
                getDataFromArchive={getDataFromArchive}
              />
            );
          })}
        </div>
        <div className="group-action">
          {group.rules.length > 1 && (
            <Button
              color="primary"
              className="groupSelector"
              onClick={() => changeGroupComparison(index)}
            >
              {group.comparison}
            </Button>
          )}
        </div>
      </div>
      <Button
        className="ruleAction"
        color="primary"
        onClick={() => addRule(index)}
      >
        <IntlMessages id="admin.policy.policy.Rule.Add" />
      </Button>
    </div>
  );
}

function PolicyAction({
  updateActionValue,
  updateActionType,
  updatePolicyHierarchy,
  removeApproval,
  action,
  index,
  hasAncillaries,
  hasHierarchy,
}) {
  // Hooks
  const { getDataFromArchive } = useDataArchive();

  // States
  const [users, setUsers] = useState([]);
  const [groups, setGroups] = useState([]);

  const { user } = useSelector((state) => state.auth);

  const policyActionTypes = useMemo(
    () =>
      hasAncillaries
        ? [actionType.find((action) => action.value === actionTypeValues.AUTO)]
        : actionType,
    [hasAncillaries]
  );

  const doHasHierarchy = useMemo(
    () => hasHierarchy && action?.type !== actionTypeValues.AUTO,
    [hasHierarchy, action?.type]
  );

  const { hierarchyWidth, comparisonWidth, valueWidth } = useMemo(
    () =>
      doHasHierarchy
        ? {
            hierarchyWidth: '15%',
            comparisonWidth: '40%',
            valueWidth: '45%',
          }
        : {
            hierarchyWidth: '0%',
            comparisonWidth: '50%',
            valueWidth: '50%',
          },
    [doHasHierarchy]
  );

  // Effects
  useEffect(() => {
    (async () => {
      await loadUsers();
      await loadGroups();
    })();
  }, []);

  useEffect(() => {
    if (hasAncillaries && action?.type !== actionTypeValues.AUTO)
      updateActionType(index, {
        type: actionTypeValues.AUTO,
        value: '',
      });
  }, [hasAncillaries, action]);

  // Functions
  const loadUsers = async () => {
    const users = await getDataFromArchive('approval-users', () =>
      getDocumentsFromCollection(() =>
        firebaseApp.getUsersFromOrganization(user.organizationId).get()
      ).then((data) =>
        data.map((user) => ({
          value: user.id,
          label: `${user.firstName} ${user.lastName}`,
        }))
      )
    );

    setUsers(users);
  };

  const loadGroups = async () => {
    const groups = await getDataFromArchive('approval-groups', () =>
      getDocumentsFromCollection(() =>
        firebaseApp.getGroupsFromOrganization(user.organizationId).get()
      ).then((data) =>
        data.map((group) => ({
          value: group.id,
          label: group.name,
        }))
      )
    );

    setGroups(groups);
  };

  // Render
  return (
    <div className="action-row mt-1">
      <div className="action-delete">
        <Button
          color="primary"
          className="ruleAction"
          onClick={() => removeApproval(index)}
        >
          <div className="glyph-icon simple-icon-minus" />
        </Button>
      </div>
      <InputGroup>
        {doHasHierarchy ? (
          <Label
            className="form-group has-top-label"
            style={{ maxWidth: hierarchyWidth }}
          >
            <Input
              type="number"
              min={1}
              name="form-field-hierarcy"
              value={action.hierarchy}
              onChange={(e) => updatePolicyHierarchy(index, e.target.value)}
              disabled={action?.type === actionTypeValues.AUTO}
            />
            <IntlMessages id="admin.policy.policy.Hierarchy" />
          </Label>
        ) : null}
        <Label
          className="form-group has-top-label"
          style={{ maxWidth: comparisonWidth }}
        >
          <Select
            className="react-select"
            classNamePrefix="react-select"
            isClearable={false}
            name="form-field-name"
            placeholder=""
            value={policyActionTypes.find((el) => el.value === action.type)}
            onChange={(e) => updateActionType(index, e)}
            options={policyActionTypes}
          />
          <IntlMessages id="admin.policy.policy.Comparison" />
        </Label>
        <Label
          className="form-group has-top-label"
          style={{ maxWidth: valueWidth }}
        >
          <Select
            className="react-select"
            classNamePrefix="react-select"
            isClearable={false}
            name="form-field-name"
            placeholder=""
            value={
              action.value === ''
                ? ''
                : action.type === actionTypeValues.USER
                ? users.find((el) => el.value === action.value)
                : action.type === actionTypeValues.AUTO
                ? actionAutoValue.find((el) => el.value === action.value)
                : action.type === actionTypeValues.GROUP
                ? groups.find((el) => el.value === action.value)
                : ''
            }
            onChange={(e) => updateActionValue(index, e)}
            options={
              action.type === actionTypeValues.USER
                ? users
                : action.type === actionTypeValues.AUTO
                ? actionAutoValue
                : action.type === actionTypeValues.GROUP
                ? groups
                : []
            }
          />
          <IntlMessages id="admin.policy.policy.Comparison" />
        </Label>
      </InputGroup>
    </div>
  );
}

export default function PolicyModal(props) {
  const { type = 'travels' } = props;

  const [policy, setPolicy] = useState({
    name: '',
    sort: 1,
    selector: {
      groups: [
        {
          rules: [
            {
              field: '',
              comparison: '',
              value: '',
            },
          ],
          comparison: 'AND',
        },
      ],
      comparison: 'AND',
    },
    actions: [
      {
        type: 'auto',
        value: 'allow',
      },
    ],
    type: policyTypes[type],
  });

  const [isLoading, setLoading] = useState(true);
  const [accordion, setAccordion] = useState(1);
  const [hasHierarchy, setHasHierarchy, toggleHasHierarchy] =
    useShowToggle(false);

  const { user } = useSelector((state) => state.auth);
  const { messages } = useIntl();

  const [isPolicyValid, hasAncillaries] = useMemo(() => {
    if (!policy) return [false, false];

    let hasAncillaries = false,
      isValid = policy.selector.groups.length > 0 && policy.actions.length > 0;

    policy.selector.groups.forEach((group) => {
      if (!hasAncillaries || isValid) {
        group.rules.forEach(({ value, field, comparison }) => {
          if (isValid) isValid = value !== undefined && field && comparison;
          if (!hasAncillaries)
            hasAncillaries = field && field.startsWith('ancillaries');
        });
      }
    });

    if (isValid && hasHierarchy)
      isValid = policy.actions.every(({ hierarchy, type }) => {
        try {
          return type === actionTypeValues.AUTO
            ? true
            : parseInt(hierarchy) > 0;
        } catch (err) {
          return false;
        }
      });

    return [!!isValid, !!hasAncillaries];
  }, [policy, hasHierarchy]);

  const { getDataFromArchive } = useDataArchive();

  // Effects
  useEffect(() => {
    (async () => {
      if (props.policyId !== 'new') await loadPolicy();
      setLoading(false);
    })();
  }, []);

  // Load Data
  const loadPolicy = async () => {
    const policySnap = await firebaseApp
      .getPolicyFromId(user.organizationId, props.policyId)
      .get();

    const policy = policySnap.data();

    if (!policy) {
      return props.toggleModal();
    }

    setPolicy(policy);
    setHasHierarchy(policy?.hasHierarchy);
  };

  // Save
  const savePolicy = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (isPolicyValid && policy.name) {
      const newPolicy = { ...policy };

      newPolicy.sort = parseInt(policy.sort);
      newPolicy.actions = formatActions(policy.actions, hasHierarchy);

      if (hasAncillaries) newPolicy.hasAncillaries = true;
      if (hasHierarchy) newPolicy.hasHierarchy = true;

      if (props.policyId === 'new') {
        await firebaseApp.createPolicyFromOrganization(
          user.organizationId,
          newPolicy
        );

        trackPropertiesSegment('New policy created', {
          user,
          policy: newPolicy,
        });
      } else {
        await firebaseApp.editPolicyFromOrganization(
          user.organizationId,
          props.policyId,
          newPolicy
        );

        trackPropertiesSegment('Policy updated', { user, policy: newPolicy });
      }

      props.toggleModal();
    }
  };

  // Form
  const updatePolicyName = (e) => {
    setPolicy({
      ...policy,
      name: e.target.value,
    });
  };

  const updatePolicySort = (e) => {
    setPolicy({
      ...policy,
      sort: e.target.value,
    });
  };

  const changeSelectorComparison = () => {
    setPolicy({
      ...policy,
      selector: {
        ...policy.selector,
        comparison: policy.selector.comparison === 'OR' ? 'AND' : 'OR',
      },
    });
  };

  const addGroup = () => {
    setPolicy({
      ...policy,
      selector: {
        ...policy.selector,
        groups: [
          ...policy.selector.groups,
          {
            rules: [
              {
                field: '',
                comparison: '',
                value: '',
              },
            ],
            comparison: 'AND',
          },
        ],
      },
    });
  };

  const changeGroupComparison = (gI) => {
    const groups = policy.selector.groups;
    groups[gI].comparison = groups[gI].comparison === 'OR' ? 'AND' : 'OR';

    setPolicy({
      ...policy,
      selector: {
        ...policy.selector,
        groups,
      },
    });
  };

  const addRule = (gI) => {
    const groups = policy.selector.groups;
    groups[gI].rules.push({
      field: '',
      comparison: '',
      value: '',
    });

    setPolicy({
      ...policy,
      selector: {
        ...policy.selector,
        groups,
      },
    });
  };

  const removeRule = (gI, rI) => {
    const groups = policy.selector.groups;
    groups[gI].rules.splice(rI, 1);

    if (groups[gI].rules.length === 0) {
      groups.splice(gI, 1);
    }

    setPolicy({
      ...policy,
      selector: {
        ...policy.selector,
        groups,
      },
    });
  };

  const updateRuleField = (gI, rI, field) => {
    const groups = policy.selector.groups;
    groups[gI].rules[rI].field = field.value;
    groups[gI].rules[rI].value = undefined;

    setPolicy({
      ...policy,
      selector: {
        ...policy.selector,
        groups,
      },
    });
  };

  const updateRuleComparison = (gI, rI, comparison) => {
    const groups = policy.selector.groups;
    groups[gI].rules[rI].comparison = comparison.value;

    setPolicy({
      ...policy,
      selector: {
        ...policy.selector,
        groups,
      },
    });
  };

  const updateRuleValue = (gI, rI, value) => {
    const groups = policy.selector.groups;
    groups[gI].rules[rI].value = value;

    setPolicy({
      ...policy,
      selector: {
        ...policy.selector,
        groups,
      },
    });
  };

  const addApproval = () => {
    setPolicy({
      ...policy,
      actions: [
        ...policy.actions,
        {
          type: 'auto',
          value: 'allow',
        },
      ],
    });
  };

  const updateActionType = (index, type) => {
    const actions = policy.actions;
    actions[index].type = type.value;
    actions[index].value = '';

    setPolicy({
      ...policy,
      actions,
    });
  };

  const updatePolicyHierarchy = (index, hierarchy) => {
    const actions = policy.actions;
    actions[index].hierarchy = hierarchy;

    setPolicy({
      ...policy,
      actions,
    });
  };

  const updateActionValue = (index, type) => {
    const actions = policy.actions;
    actions[index].value = type.value;

    setPolicy({
      ...policy,
      actions,
    });
  };

  const removeApproval = (index) => {
    const actions = policy.actions;
    actions.splice(index, 1);

    setPolicy({
      ...policy,
      actions,
    });
  };

  // Render
  if (isLoading) {
    return (
      <Modal size="lg" isOpen={true} toggle={props.toggleModal}>
        <ModalBody className="policy-editor">
          <div className="loading" />
        </ModalBody>
      </Modal>
    );
  }

  const renderHierarcy = () => (
    <Label
      className="hierarchy d-flex align-items-center pointer"
      onClick={() => toggleHasHierarchy()}
    >
      <CustomAnimatedCheckbox
        checked={hasHierarchy}
        purple
        size={15}
        borderWidth={1}
        onChange={toggleHasHierarchy}
      />

      <span className="ml-1 text-primary">
        {messages['admin.policy.policy.Hierarchy.use']}
      </span>
    </Label>
  );

  return (
    <Modal
      size="lg"
      className="policy-editor admin"
      isOpen={true}
      toggle={props.toggleModal}
    >
      <Form onSubmit={savePolicy}>
        <ModalHeader toggle={props.toggleModal}>
          <IntlMessages id="admin.policy.policy.Modal.Add" />
        </ModalHeader>
        <ModalBody>
          <Label className="form-group has-top-label w-20 float-right">
            <Input
              type="number"
              value={policy['sort']}
              onChange={updatePolicySort}
            />
            <IntlMessages id="admin.policy.policy.Sort" />
          </Label>

          <Label className="form-group has-top-label w-33">
            <Input value={policy.name} onChange={updatePolicyName} />
            <IntlMessages id="admin.policy.policy.Name" />
          </Label>

          <div className="border">
            <Button
              color="link"
              onClick={() => setAccordion(1)}
              aria-expanded={accordion === 1}
            >
              {messages['admin.policy.policy']}
            </Button>
            <Collapse isOpen={accordion === 1}>
              <Row>
                <Colxx sm="12">
                  <CardBody>
                    <div className="mb-4">
                      <div className="selector">
                        <div className="selector-content">
                          {map(policy.selector.groups, (group, index) => {
                            return (
                              <PolicyGroup
                                key={`group_${index}`}
                                group={group}
                                index={index}
                                changeGroupComparison={changeGroupComparison}
                                addRule={addRule}
                                removeRule={removeRule}
                                updateRuleField={updateRuleField}
                                updateRuleComparison={updateRuleComparison}
                                updateRuleValue={updateRuleValue}
                                type={type}
                                getDataFromArchive={getDataFromArchive}
                              />
                            );
                          })}
                        </div>
                        {policy.selector.groups.length > 1 && (
                          <div className="selector-action">
                            <Button
                              color="primary"
                              className="groupSelector"
                              onClick={changeSelectorComparison}
                            >
                              {policy.selector.comparison}
                            </Button>
                          </div>
                        )}
                      </div>
                      <Button
                        color="primary"
                        className="groupAction mt-2"
                        onClick={addGroup}
                      >
                        <IntlMessages id="admin.policy.policy.Group.Add" />
                      </Button>
                    </div>
                  </CardBody>
                </Colxx>
              </Row>
            </Collapse>
          </div>
          <div className="border">
            <section className="d-flex align-items-center justify-content-between position-relative">
              <Button
                color="link"
                onClick={() => setAccordion(2)}
                aria-expanded={accordion === 2}
              >
                {messages['admin.policy.approvalRule']}
              </Button>

              {accordion === 2 && renderHierarcy()}
            </section>
            <Collapse isOpen={accordion === 2}>
              <Row>
                <Colxx sm="12">
                  <CardBody>
                    <div className="mb-4">
                      <div className="approval">
                        {map(policy.actions, (action, index) => {
                          return (
                            <PolicyAction
                              key={`action_${index}`}
                              action={action}
                              index={index}
                              updateActionValue={updateActionValue}
                              updateActionType={updateActionType}
                              updatePolicyHierarchy={updatePolicyHierarchy}
                              removeApproval={removeApproval}
                              hasAncillaries={hasAncillaries}
                              hasHierarchy={hasHierarchy}
                            />
                          );
                        })}
                      </div>
                      <Button
                        color="primary"
                        className="approvalAction mt-2"
                        onClick={addApproval}
                        disabled={!isPolicyValid}
                      >
                        <IntlMessages id="admin.policy.policy.Approval.Add" />
                      </Button>
                    </div>
                  </CardBody>
                </Colxx>
              </Row>
            </Collapse>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="d-flex justify-content-between align-items-center">
            <Button type="submit" color="primary" outline size="lg">
              <IntlMessages id="admin.policy.policy.Save" />
            </Button>
          </div>
        </ModalFooter>
      </Form>
    </Modal>
  );
}
