import Checklist from './Checklist';
import ChecklistItem from './ChecklistItem';
import { setChecklistOpen, toggleItem } from '../../redux/actions';
import firebaseApp from '../../services/Firebase';

export const openChecklistAndToggleItem = (
  dispatch,
  checklistId,
  itemId,
  isDone = true,
  delay = 700
) => {
  dispatch(setChecklistOpen(checklistId, true));
  setTimeout(() => dispatch(toggleItem(checklistId, itemId, isDone)), delay);
};

export const toggleChecklistItemDoneInFirebase = (
  checklistId,
  itemId,
  done
) => {
  firebaseApp
    .updateChecklistItem(checklistId, String(itemId), { done })
    .then(() =>
      console.debug(
        `UPDATED DONE FROM ITEM ${itemId} OF ${checklistId} CHECKLIST`
      )
    )
    .catch((e) =>
      console.debug(
        `ERROR ON UPDATE OF DONE FROM ITEM ${itemId} OF ${checklistId} CHECKLIST`,
        e
      )
    );
};

export const setChecklistComplete = (checklistId) => {
  firebaseApp
    .setChecklistComplete(checklistId)
    .then(() => console.debug(`SET ${checklistId} CHECKLIST AS COMPLETED`))
    .catch(() =>
      console.debug(`ERROR ON SET ${checklistId} CHECKLIST AS COMPLETED`)
    );
};

export { Checklist, ChecklistItem };
