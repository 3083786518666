import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import {
  Button,
  InputGroup,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  ModalFooter,
} from 'reactstrap';
import { Colxx } from '../../common/CustomBootstrap';
import Select from 'react-select';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import MaskedInput from 'react-text-mask';
import * as Yup from 'yup';

import IntlMessages from '../../../helpers/IntlMessages';

import { pixMask } from '../../../utils/masks';
import { PixTypes } from '../../../constants/pix';

import PersonalCard from '../PersonalCard';
import LoaderPersonalCard from '../../loaders/LoaderPersonalCard';

import appFunctions from '../../../services/Functions';
import { getPassengerRedux } from '../../../utils/redux';

let dictTimeout = null;

export default function PersonalAccountCreationModal({ closeModal }) {
  const { messages } = useIntl();
  const { passenger } = useSelector(getPassengerRedux);

  // States
  const [loadingDict, setLoadingDict] = useState(false);
  const [pixDictKey, setPixDictKey] = useState(null);

  const pixOptions = useMemo(
    () =>
      Object.values(PixTypes).map((value) => ({
        value,
        label: messages[`pix-types.${value}`],
      })),
    []
  );

  // Consulta DICT
  const getDict = async (pixType, pixKey) => {
    setLoadingDict(true);
    clearTimeout(dictTimeout);

    dictTimeout = setTimeout(async () => {
      try {
        if (pixType === PixTypes.PHONE) {
          pixKey = `+55${pixKey.replace(/\D/g, '')}`;
        }

        const searchDict = await appFunctions.searchDictForKey(
          passenger.organizationId,
          pixKey
        );

        setPixDictKey(searchDict.data);
      } catch (err) {
        setPixDictKey(null);
      } finally {
        setLoadingDict(false);
      }
    }, 2000);
  };

  // Functions
  const createCard = async (values, setSubmitting) => {
    try {
      setSubmitting(true);

      await appFunctions.createExpensesCard(passenger.organizationId, {
        card_id: 'personal',
        name: values.name,
        pix: pixDictKey,
        user_id: passenger.uid,
      });

      closeModal();
    } catch (err) {
      console.error(err);
    } finally {
      setSubmitting(false);
    }
  };

  // Render
  return (
    <Modal
      isOpen={true}
      toggle={closeModal}
      size="lg"
      className="admin refund-new-card-modal personal-account-modal"
      name="personal-account-modal"
    >
      <ModalHeader toggle={closeModal}>
        {messages['refund.personal-account.create.title']}
      </ModalHeader>
      <Formik
        initialValues={{
          name: '',
          pixType: 'CPF',
          pixTypeLabel: 'CPF',
          pixKey: '',
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string(messages['forms.validation.required']).required(
            messages['forms.validation.required']
          ),
          pixType: Yup.string(messages['forms.validation.required'])
            .required(messages['forms.validation.required'])
            .oneOf(
              Object.keys(PixTypes),
              messages['forms.validation.pixtype.invalid']
            ),
          pixKey: Yup.string(messages['forms.validation.required']).when(
            'pixType',
            {
              is: (type) => type === PixTypes.NONE,
              then: (schema) => schema,
              otherwise: (schema) =>
                schema.test(
                  'valid-pix',
                  messages['forms.validation.pix.invalid'],
                  (v) => loadingDict === true || pixDictKey !== null
                ),
            }
          ),
        })}
        onSubmit={(values, { setSubmitting }) => {
          createCard(values, setSubmitting);
        }}
      >
        {({
          isSubmitting,
          values,
          setFieldValue,
          handleChange,
          handleBlur,
        }) => (
          <Form>
            <ModalBody>
              <Row className="px-3 mt-4">
                <Colxx xxx="5">
                  {loadingDict ? (
                    <LoaderPersonalCard />
                  ) : (
                    <PersonalCard
                      card={
                        pixDictKey
                          ? {
                              name: `Ag. ${pixDictKey.branchCode} - ${parseInt(
                                pixDictKey.accountNumber
                              )
                                .toString()
                                .replace(/(\d+)(\d{1})$/, '$1-$2')}`,
                              subtext: pixDictKey.name
                                ? pixDictKey.name
                                : values.name,
                              ispb: pixDictKey.ispb,
                            }
                          : {
                              name: values.name,
                              subtext: '',
                            }
                      }
                      withHover={false}
                    />
                  )}
                </Colxx>
                <Colxx xxs="7">
                  <div className="w-100">
                    <Row>
                      <Colxx xxs="12">
                        <Label className="form-group has-top-label mb-1">
                          <Field
                            name="name"
                            className="form-control"
                            maxLength={21}
                          />
                          <IntlMessages id="refund.personal-account.create.account-name" />
                          <ErrorMessage
                            name="name"
                            component="div"
                            className="invalid-feedback d-block"
                          />
                        </Label>
                      </Colxx>
                    </Row>
                    <Row>
                      <Colxx xxs="12">
                        <InputGroup>
                          <Label
                            className="form-group has-top-label"
                            name="pixType"
                          >
                            <Select
                              className="react-select"
                              classNamePrefix="react-select"
                              isClearable={false}
                              name="form-field-name"
                              placeholder=""
                              value={{
                                value: values.pixType,
                                label: values.pixTypeLabel,
                              }}
                              onChange={(v) => {
                                setFieldValue('pixType', v.value);
                                setFieldValue('pixTypeLabel', v.label);
                                setFieldValue('pixKey', '');
                              }}
                              options={pixOptions}
                            />
                            <IntlMessages id="refund.personal-account.create.pix-type" />
                            <ErrorMessage
                              name="pixType"
                              component="div"
                              className="invalid-feedback d-block"
                            />
                          </Label>
                          <Label className="form-group has-top-label mb-0">
                            {(values.pixType === PixTypes.CPF ||
                              values.pixType === PixTypes.CNPJ ||
                              values.pixType === PixTypes.PHONE) && (
                              <Field name="pixKey">
                                {({ field }) => (
                                  <MaskedInput
                                    type="text"
                                    {...field}
                                    mask={pixMask[values.pixType]}
                                    id="pixKey"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    onKeyUp={(e) =>
                                      getDict(values.pixType, e.target.value)
                                    }
                                    className="form-control"
                                  />
                                )}
                              </Field>
                            )}

                            {(values.pixType === PixTypes.EMAIL ||
                              values.pixType === PixTypes.RANDOM) && (
                              <Field
                                name="pixKey"
                                className="form-control"
                                onKeyUp={(e) =>
                                  getDict(values.pixType, e.target.value)
                                }
                              />
                            )}

                            {values.pixType === PixTypes.NONE && (
                              <Field
                                name="pixKey"
                                className="form-control"
                                disabled={true}
                              />
                            )}
                            <IntlMessages id="refund.personal-account.create.pix" />
                            <ErrorMessage
                              name="pixKey"
                              component="div"
                              className="invalid-feedback d-block"
                            />
                          </Label>
                        </InputGroup>
                      </Colxx>
                    </Row>
                  </div>
                </Colxx>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                type="submit"
                disabled={isSubmitting || loadingDict}
                outline
                size="lg"
              >
                {messages['refund.personal-account.create.do-create']}
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
