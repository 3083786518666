import firebaseApp from 'src/portao3-legacy/services/Firebase';
import { makeFlightParams } from './utils/flight';
import { makeHotelParams } from './utils/hotel';
import { makeCarParams } from './utils/car';
import { makeBusParams } from './utils/bus';
import { makeOfficeParams } from './utils/office';

import { OrderTypes } from 'src/portao3-legacy/constants/orderTypes';

import {
  TBusReservationParams,
  TCarReservationParams,
  TFlightReservationParams,
  TGetParams,
  THotelReservationParams,
  TOfficeReservationParams,
  TReservationParams,
} from './types';

export async function getParams(orderType: string, params: TGetParams) {
  switch (orderType) {
    case OrderTypes.FLIGHT:
      return makeFlightParams(params as TFlightReservationParams);
    case OrderTypes.HOTEL:
      return makeHotelParams(params as THotelReservationParams);
    case OrderTypes.CAR:
      return makeCarParams(params as TCarReservationParams);
    case OrderTypes.BUS:
      return makeBusParams(params as TBusReservationParams);
    case OrderTypes.OFFICE:
      return makeOfficeParams(params as TOfficeReservationParams);
    default:
      throw new Error('Invalid type!');
  }
}

export async function makeReservation(params: TReservationParams) {
  switch (params.type) {
    case OrderTypes.FLIGHT:
      return (firebaseApp as any).createFlightReservation(params);
    case OrderTypes.HOTEL:
      return (firebaseApp as any).createHotelReservation(params);
    case OrderTypes.CAR:
      return (firebaseApp as any).createCarReservation(params);
    case OrderTypes.BUS:
      return (firebaseApp as any).createBusReservation(params);
    case OrderTypes.OFFICE:
      return (firebaseApp as any).createOfficeReservation(params);
    default:
      throw new Error('Invalid type!');
  }
}
