import React, { useMemo } from 'react';
import classNames from 'classnames';
import Category from '../common/Category';
import SlimHotelAmenities from '../order/SlimHotelAmenities';
import { Row } from 'reactstrap';
import { Colxx } from '../common/CustomBootstrap';
import { hasFreeCancellationHotel } from '../../utils/reservation';
import RoomMeal from '../../containers/search/RoomMeal';
import { useIntl } from 'react-intl';

import { ReactComponent as CancelIcon } from '../../assets/img/icons/cancel.svg';

export default function HotelInfo({ className, hotel, selectedRoom }) {
  const { messages } = useIntl();

  // States
  const { name, category, address, photo } = useMemo(() => {
    const { category, name, destination, mainPhoto } = hotel.hotel;

    return {
      category,
      name,
      address: destination.address,
      photo: mainPhoto,
    };
  }, [hotel]);

  const { roomName, roomAmenities, hasFreeCancellation } = useMemo(() => {
    const { amenities = [] } = selectedRoom.info;

    const hasFreeCancellation = hasFreeCancellationHotel(
      selectedRoom.cancellation
    );

    return {
      roomName: selectedRoom.description,
      roomAmenities: amenities.join(' • '),
      hasFreeCancellation,
    };
  }, [selectedRoom]);

  // Render
  return (
    <div className={classNames('hotel-info', className)}>
      <Row className="mx-0">
        <Colxx xxs="3" className="bg-primary m-0 p-0 light-round">
          <img
            src={photo}
            alt={name}
            width="100%"
            height="100%"
            style={{ objectFit: 'cover' }}
            className="light-round"
          />
        </Colxx>
        <Colxx xss="9" className="py-3 px-0 pl-3">
          <Category category={category} />

          <div className="my-2">
            <h2 className="m-0 p-0 font-primary fs-1-1rem lh-1-25">{name}</h2>
            <p className="m-0 p-0 text-muted lh-1-25 mt-1 fs-0-8rem">
              {address}
            </p>
          </div>

          <SlimHotelAmenities
            hotel={hotel.hotel}
            hotelId={hotel.hotelId}
            maxItems={10}
            iconSize={18}
          />

          <div className="mt-4 p-3 bg-secondary-lighter light-round">
            <h3 className="m-0 p-0 font-primary fs-0-8rem lh-1-5">
              {roomName}
            </h3>
            <div className="d-flex align-items-center my-2">
              <RoomMeal room={selectedRoom} />
              {hasFreeCancellation && (
                <CancelIcon
                  alt={
                    messages['alts.containers.search.hotel-modal.cancellation']
                  }
                  title={
                    messages['alts.containers.search.hotel-modal.cancellation']
                  }
                  style={{ width: '16px' }}
                  className="text-primary"
                />
              )}
            </div>
            {roomAmenities ? (
              <p className="m-0 p-0 lh-1-5 text-muted fs-0-6rem mt-2">
                {roomAmenities}
              </p>
            ) : null}
          </div>
        </Colxx>
      </Row>
    </div>
  );
}
