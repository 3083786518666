import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

const InjectMassage = (props) => (
  <span>
    <FormattedMessage {...props} />
  </span>
);

export default injectIntl(InjectMassage, {
  withRef: false,
});
