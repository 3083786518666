class Action {
  constructor(subject, perform, options = {}) {
    const { delay = 0, setup, startDelay = 0, ...others } = options;
    this.subject = subject;
    this.perform = perform;
    this.delay = delay;
    this.setup = setup;
    this.options = others;
    this.startDelay = startDelay;
  }

  start = () => {
    throw new Error("Can't start abstract Action class!");
  };

  callback = () => {
    const { callback, next } = this.options;

    callback && callback();
    next && next();
  };

  setNext = (next) => {
    this.options.next = next;
  };
}

export class Write extends Action {
  constructor(subject, perfom, options = {}) {
    super(subject, perfom, options);
    this.index = 0;
    this.value = '';
  }

  _write = () => {
    const letter = this.subject[this.index++];
    if (letter) {
      this.value += letter;
      this.perform(this.value);
      setTimeout(() => this._write(), this.delay);
    } else this.callback();
  };

  start = () => {
    if (this.setup) this.setup(this);

    setTimeout(() => {
      if (this.subject) {
        if (this.delay) return this._write();
        else this.perform(this.subject);
      }

      this.callback();
    }, this.startDelay);
  };
}

export class Execute extends Action {
  constructor(subject, perfom, options = {}) {
    super(subject, perfom, options);
  }

  start = () => {
    setTimeout(() => {
      if (this.setup) this.setup(this);

      if (this.subject) {
        if (this.delay) {
          return setTimeout(() => {
            this.perform(this.subject);
            this.callback();
          }, this.delay);
        } else this.perform(this.subject);
      }

      this.callback();
    }, this.startDelay);
  };
}

export function performActions(actions = [], interval = 0, start = 0) {
  for (let i = 0, length = actions.length - 1; i < length; i++) {
    actions[i].setNext(() =>
      setTimeout(() => actions[i + 1].start(), interval)
    );
  }

  if (start) {
    setTimeout(() => actions[0].start(), start);
  } else actions[0].start();
}
