import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Row,
  Input,
  Modal,
  Label,
  Progress,
  Alert,
  Card,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';
import classnames from 'classnames';
import { Colxx } from '../../components/common/CustomBootstrap';
import ProfileImage from '../../components/ProfileImage';

import ProfileTab from './UserModalProfileTab';
import CreditCardTab from './UserModalCreditCardTab';

import messages from '../../lang/messages';

import firebaseApp from '../../services/Firebase';
import { useIntl } from 'react-intl';
import PermissionsTab from './UserModalPermissions';
import FidelityTab from './FidelityTab';
import organizationStatus from '../../constants/organizationStatus';
import { getOrganizationConfig } from '../../utils/organization';

let loadUserEvent = () => {};

function UserAvatar({ editUser, userId }) {
  const [isUploading, setIsUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [hasError, setError] = useState(null);

  const { user } = useSelector((state) => state.auth);

  // Avatar
  const uploadFileHandler = (event) => {
    setError(null);
    setIsUploading(true);
    setProgress(0);

    // File
    const file = event.target.files[0];
    const format = file.name.split('.').pop();

    const ref = firebaseApp
      .uploader()
      .ref()
      .child(`/${user.uid}/avatar.${format}`);

    // Uploadf
    const task = ref.put(file);

    task.on(
      'state_changed',
      function progress(snapshot) {
        setProgress((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
      },

      function errors(err) {
        console.error(err);

        setError(messages['user.GeneralException']);
        setIsUploading(false);
        setProgress(0);
      },

      async function complete() {
        try {
          const avatar = await ref.getDownloadURL();

          await firebaseApp.updateUserAvatar(avatar);

          setIsUploading(false);
          setProgress(0);
        } catch (err) {
          console.error(err);

          setError(messages['user.GeneralException']);
        }
      }
    );
  };

  if (isUploading) {
    return (
      <div className="edit-profile-avatar">
        <Progress value={progress} />
      </div>
    );
  } else if (user.uid === userId) {
    return (
      <div className="edit-profile-avatar">
        {hasError && (
          <Alert color="danger" className="mt-2 mb-2">
            {hasError}
          </Alert>
        )}

        <Label className="mt-3 change-avatar">
          <i className="glyph-icon simple-icon-pencil" />

          <ProfileImage user={editUser} />

          <Input
            className="upload-input-avatar"
            type="file"
            onChange={uploadFileHandler}
            accept="image/*"
          />
        </Label>
      </div>
    );
  } else {
    return (
      <div className="edit-profile-avatar">
        <Label className="mt-3 change-avatar">
          <ProfileImage user={editUser} />
        </Label>
      </div>
    );
  }
}

export default function UserModal(props) {
  const { toggleModal, userId } = props;

  const { user, organization } = useSelector((state) => state.auth);

  const { messages } = useIntl();

  // States
  const [editUser, setEditUser] = useState({});
  const [tab, setTab] = useState('profile');
  const [isLoading, setLoading] = useState(true);

  // Effects
  useEffect(() => {
    loadUser();

    return () => {
      loadUserEvent();
    };
  }, []);

  const loadUser = async () => {
    loadUserEvent = firebaseApp.getUserFromId(userId).onSnapshot((userSnap) => {
      setEditUser(userSnap.data());
      setLoading(false);
    });
  };

  if (isLoading) {
    return (
      <Modal isOpen={true} toggle={toggleModal} wrapClassName="modal-right">
        <div className="loading" />
      </Modal>
    );
  }

  return (
    <Modal
      isOpen={true}
      toggle={toggleModal}
      wrapClassName="modal-right edit-profile"
    >
      <UserAvatar userId={userId} editUser={editUser} />

      <Card className="mb-4 mt-5 pt-4 user-tab">
        <CardHeader>
          <Nav tabs className="card-header-tabs">
            <NavItem>
              <NavLink
                to="#"
                location={{}}
                className={classnames({
                  active: tab === 'profile',
                  'nav-link': true,
                })}
                onClick={() => {
                  setTab('profile');
                }}
              >
                {messages['containers.users.user-modal.profile']}
              </NavLink>
            </NavItem>
            {user.uid == editUser.uid &&
              getOrganizationConfig(organization.config, 'canAddOwnCard') && (
                <NavItem>
                  <NavLink
                    to="#"
                    location={{}}
                    className={classnames({
                      active: tab === 'creditCards',
                      'nav-link': true,
                    })}
                    disabled={
                      organization.status === organizationStatus.TESTING
                    }
                    onClick={() => {
                      setTab('creditCards');
                    }}
                  >
                    {messages['containers.users.user-modal.credit-card']}
                  </NavLink>
                </NavItem>
              )}
            <NavItem>
              <NavLink
                to="#"
                location={{}}
                className={classnames({
                  active: tab === 'fidelity',
                  'nav-link': true,
                })}
                onClick={() => {
                  setTab('fidelity');
                }}
              >
                {messages['containers.users.user-modal.fidelity']}
              </NavLink>
            </NavItem>
            {user.admin && (
              <NavItem>
                <NavLink
                  to="#"
                  location={{}}
                  className={classnames({
                    active: tab === 'permissions',
                    'nav-link': true,
                  })}
                  onClick={() => {
                    setTab('permissions');
                  }}
                >
                  {messages['containers.users.user-modal.permissions']}
                </NavLink>
              </NavItem>
            )}
          </Nav>
        </CardHeader>

        <TabContent activeTab={tab}>
          <TabPane tabId="profile">
            <Row>
              <Colxx sm="12">
                <ProfileTab
                  editUser={editUser}
                  toggleModal={toggleModal}
                  userId={userId}
                />
              </Colxx>
            </Row>
          </TabPane>
          <TabPane tabId="programs">
            <Row>
              <Colxx sm="12"></Colxx>
            </Row>
          </TabPane>
          <TabPane tabId="creditCards">
            <Row>
              <Colxx sm="12">
                <CreditCardTab
                  editUser={editUser}
                  toggleModal={toggleModal}
                  userId={userId}
                />
              </Colxx>
            </Row>
          </TabPane>
          <TabPane tabId="permissions">
            <Row>
              <Colxx sm="12">
                <PermissionsTab
                  editUser={editUser}
                  toggleModal={toggleModal}
                  userId={userId}
                />
              </Colxx>
            </Row>
          </TabPane>
          <TabPane tabId="fidelity">
            <Row>
              <Colxx sm="12">
                <FidelityTab
                  toggleModal={toggleModal}
                  userId={userId}
                  editUser={editUser}
                  focused={tab === 'fidelity'}
                />
              </Colxx>
            </Row>
          </TabPane>
        </TabContent>
      </Card>
    </Modal>
  );
}
