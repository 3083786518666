export const FILTER_TYPES = {
  OBJECT: 'object',
  ARRAY: 'array',
  RANGE: 'range',
  BOOLEAN: 'boolean',
};

export const ALL_FILTER_COMPARATIVES = {
  EQUAL: '=',
  NOT_EQUAL: '!=',
  GREATER: '>',
  GREATER_EQUAL: '>=',
  LESS: '<',
  LESS_EQUAL: '<=',
};

export const FILTER_COMPARATIVES = {
  [FILTER_TYPES.RANGE]: Object.values(ALL_FILTER_COMPARATIVES),
  [FILTER_TYPES.ARRAY]: [
    ALL_FILTER_COMPARATIVES.EQUAL,
    ALL_FILTER_COMPARATIVES.NOT_EQUAL,
  ],
  [FILTER_TYPES.BOOLEAN]: [
    ALL_FILTER_COMPARATIVES.EQUAL,
    ALL_FILTER_COMPARATIVES.NOT_EQUAL,
  ],
  [FILTER_TYPES.OBJECT]: [
    ALL_FILTER_COMPARATIVES.EQUAL,
    ALL_FILTER_COMPARATIVES.NOT_EQUAL,
  ],
};

const _array_includes = (a, b, options = {}) => {
  if (options.multiple) {
    return a.some((value) => b.includes(value));
  } else return b.includes(a);
};

export const COMPARATIVE_FUNCTIONS = {
  [FILTER_TYPES.RANGE]: {
    [ALL_FILTER_COMPARATIVES.EQUAL]: (a, b) => a === b,
    [ALL_FILTER_COMPARATIVES.NOT_EQUAL]: (a, b) => a !== b,
    [ALL_FILTER_COMPARATIVES.GREATER]: (a, b) => a > b,
    [ALL_FILTER_COMPARATIVES.GREATER_EQUAL]: (a, b) => a >= b,
    [ALL_FILTER_COMPARATIVES.LESS]: (a, b) => a < b,
    [ALL_FILTER_COMPARATIVES.LESS_EQUAL]: (a, b) => a <= b,
  },
  [FILTER_TYPES.ARRAY]: {
    [ALL_FILTER_COMPARATIVES.EQUAL]: _array_includes,
    [ALL_FILTER_COMPARATIVES.NOT_EQUAL]: (a, b, options = {}) =>
      !_array_includes(a, b, options),
  },
  [FILTER_TYPES.BOOLEAN]: {
    [ALL_FILTER_COMPARATIVES.EQUAL]: (a, b) => a === b,
    [ALL_FILTER_COMPARATIVES.NOT_EQUAL]: (a, b) => a !== b,
  },
};
