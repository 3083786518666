export const getWeather = async (latitude, longitude, city = '', days = 3) => {
  const apiKey = 'a039af70c6f12c4386ba3bc49d815f92';

  try {
    const isLatLong = latitude && longitude;
    const url = 'https://api.openweathermap.org/data/2.5/forecast';
    const latLong = `lat=${latitude}&lon=${longitude}&cnt=${days}`;
    const cityAndDays = `q=${city}&cnt=${days}`;
    const endurl = `&units=metric&lang=pt_br&appid=${apiKey}`;
    const resposta = await fetch(
      `${url}?${isLatLong ? latLong : cityAndDays}${endurl}`
    );

    const data = await resposta.json();
    const weatherCode = data.list[0].weather[0].id;
    const temperature = data.list[data.list.length - 1].main.temp;

    return {
      weatherCode,
      temperature,
    };
  } catch (err) {
    console.error(err);
  }
};
