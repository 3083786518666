import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { convertToLabelValueWithMessages } from 'src/portao3-legacy/utils/others';
import GenericParamsOptions from './GenericParamsOptions';

import { OrderTypes } from 'src/portao3-legacy/constants/orderTypes';
import {
  HotelAmenities,
  HotelCategories,
} from 'src/portao3-legacy/constants/searchParams';

export default function HotelParamsOptions({ className, initialData }) {
  const { messages } = useIntl();

  // States
  const params = useMemo(() => {
    return [
      {
        key: 'categories',
        title: messages['admin.travels.params.hotel.categories'],
        options: convertToLabelValueWithMessages({
          items: HotelCategories,
          messagePrefix: 'search-params.hotel.categories.',
          messages,
        }),
      },
      {
        key: 'amenities',
        title: messages['admin.travels.params.hotel.amenities'],
        options: convertToLabelValueWithMessages({
          items: HotelAmenities,
          messagePrefix: 'search-params.hotel.amenities.',
          messages,
          getMessageId: (value) => value.toLowerCase().replaceAll(' ', '_'),
        }),
      },
    ];
  }, []);

  // Render
  return (
    <GenericParamsOptions
      className={className}
      paramType={OrderTypes.HOTEL}
      params={params}
      initialData={initialData}
      title={messages['travel.menu.hotel']}
    />
  );
}
