import { OrderTypes } from '../constants/orderTypes';
import { THotelSearchItem } from '../types/orders/hotel/hotelOrder';
import { TOrderFare } from '../types/orders/orders';
import { XOR } from './others';

export function sortDefaultSearchItems(items: { fare: TOrderFare }[]) {
  return items.sort((a, b) => {
    return a.fare.total - b.fare.total;
  });
}

export function sortHotelSearchItems(items: THotelSearchItem[]) {
  const newItems = items.filter((item) => item !== null);
  return newItems.sort((a, b) => {
    if (XOR(a?.favorite, b?.favorite)) return a?.favorite ? -1 : 1;
    return a.fare.total - b.fare.total;
  });
}

export const SortOrderFunctions = {
  [OrderTypes.HOTEL]: sortHotelSearchItems,
  default: sortDefaultSearchItems,
};

export function sortSearchItems(items: any[], orderType: string) {
  const sortFunction =
    SortOrderFunctions[orderType] || SortOrderFunctions.default;

  return sortFunction(items);
}
