import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Row,
  InputGroup,
  ModalBody,
  ModalFooter,
  Button,
  Label,
} from 'reactstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import MaskedInput from 'react-text-mask';
import * as Yup from 'yup';
import { phoneMask, dateMask, cnpjMask, cepMask } from '../../utils/masks';
import { isCnpjValid } from '../../utils/cnpj';

import IntlMessages from '../../helpers/IntlMessages';
import messages from '../../lang/messages';

import firebaseApp from '../../services/Firebase';
import appFunctions from '../../services/Functions';

export default function KYCModalCompanyTab({ onGoNext }) {
  const [loading, setLoading] = useState(true);
  const [kyc, setKyc] = useState({});

  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    loadCompanyKYCData();
  }, []);

  // Load Data
  const loadCompanyKYCData = async () => {
    const kycSnap = await firebaseApp
      .getOrganizationKYCFromId(user.organizationId)
      .get();
    const hasKyC = await firebaseApp
      .getInputedOrganizationKYCFromId(user.organizationId)
      .get();

    if (hasKyC.exists) {
      onGoNext();
    }

    if (kycSnap.exists) {
      setKyc(kycSnap.data());
    }

    setLoading(false);
  };

  // Get Postal Code
  const postalCodeChange = async (values, e) => {
    const cep = e.target.value.replace(/\D/g, '');

    const address = await appFunctions.getCep(cep);

    setKyc({
      ...values,
      street: address.logradouro || '',
      streetNumber: '',
      complement: '',
      neighborhood: address.bairro || '',
      city: address.localidade || '',
      uf: address.uf || '',
    });
  };

  // Save Data
  const saveKycStep = async (values, setSubmitting) => {
    setSubmitting(true);

    await firebaseApp.setOrganizationKYCFromId(user.organizationId, values);
    onGoNext();
  };

  if (loading) {
    return (
      <ModalBody>
        <div className="loading" />
      </ModalBody>
    );
  }

  // Render
  return (
    <Formik
      initialValues={{
        documentNumber: kyc.documentNumber || '',
        legalName: kyc.legalName || '',
        tradingName: kyc.tradingName || '',
        foundationDate: kyc.foundationDate || '',
        postalCode: kyc.postalCode || '',
        street: kyc.street || '',
        streetNumber: kyc.streetNumber || '',
        complement: kyc.complement || '',
        neighborhood: kyc.neighborhood || '',
        city: kyc.city || '',
        uf: kyc.uf || '',
        email: kyc.email || '',
        phone: kyc.phone || '',
      }}
      validationSchema={Yup.object().shape({
        documentNumber: Yup.string()
          .required(messages['forms.validation.cnpj.required'])
          .test(
            'cnpj',
            messages['forms.validation.cnpj.number.valid'],
            (value) => isCnpjValid(value)
          ),
        legalName: Yup.string().required(
          messages['forms.validation.legalName.required']
        ),
        tradingName: Yup.string().required(
          messages['forms.validation.tradingName.required']
        ),
        foundationDate: Yup.string().required(
          messages['forms.validation.foundationDate.required']
        ),
        postalCode: Yup.string()
          .matches(
            /^\d{5}-\d{3}$/,
            messages['forms.validation.postalCode.valid']
          )
          .required(messages['forms.validation.postalCode.required']),
        street: Yup.string().required(
          messages['forms.validation.street.required']
        ),
        streetNumber: Yup.number().required(
          messages['forms.validation.streetNumber.required']
        ),
        complement: Yup.string(),
        neighborhood: Yup.string().required(
          messages['forms.validation.neighborhood.required']
        ),
        city: Yup.string().required(messages['forms.validation.city.required']),
        uf: Yup.string()
          .required(messages['forms.validation.uf.required'])
          .oneOf(
            [
              'AC',
              'AL',
              'AP',
              'AM',
              'BA',
              'CE',
              'DF',
              'ES',
              'GO',
              'MA',
              'MT',
              'MS',
              'MG',
              'PA',
              'PB',
              'PR',
              'PE',
              'PI',
              'RJ',
              'RN',
              'RS',
              'RO',
              'RR',
              'SC',
              'SP',
              'SE',
              'TO',
            ],
            messages['forms.validation.uf.invalid']
          ),
        email: Yup.string()
          .email(messages['forms.validation.email.valid'])
          .required(messages['forms.validation.email.required']),
        phone: Yup.string()
          .required(messages['forms.validation.phone.required'])
          .test(
            'phone',
            messages['forms.validation.phone.valid'],
            (value) => value?.length > 9
          ),
      })}
      onSubmit={(values, { setSubmitting }) => {
        saveKycStep(values, setSubmitting);
      }}
      enableReinitialize={true}
    >
      {({ values, isSubmitting, handleChange, handleBlur }) => (
        <Form>
          <ModalBody>
            <Row className="mb-1">
              <Label className="form-group has-top-label mb-0 w-100">
                <Field
                  name="documentNumber"
                  render={({ field }) => (
                    <MaskedInput
                      type="text"
                      {...field}
                      mask={cnpjMask}
                      id="documentNumber"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="form-control"
                    />
                  )}
                />
                <IntlMessages id="admin.kyc.documentNumber" />
                <ErrorMessage
                  name="documentNumber"
                  component="div"
                  className="invalid-feedback d-block"
                />
              </Label>
            </Row>
            <Row className="mb-1">
              <Label className="form-group has-top-label mb-0 w-100">
                <Field
                  type="input"
                  name="legalName"
                  id="legalName"
                  className="form-control"
                />
                <IntlMessages id="admin.kyc.legalName" />
                <ErrorMessage
                  name="legalName"
                  component="div"
                  className="invalid-feedback d-block"
                />
              </Label>
            </Row>
            <Row className="mb-1">
              <InputGroup>
                <Label className="form-group has-top-label mb-0">
                  <Field
                    type="input"
                    name="tradingName"
                    id="tradingName"
                    className="form-control"
                  />
                  <IntlMessages id="admin.kyc.tradingName" />
                  <ErrorMessage
                    name="tradingName"
                    component="div"
                    className="invalid-feedback d-block"
                  />
                </Label>
                <Label className="form-group has-top-label mb-0">
                  <Field
                    name="foundationDate"
                    render={({ field }) => (
                      <MaskedInput
                        type="text"
                        {...field}
                        mask={dateMask}
                        id="foundationDate"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="form-control"
                      />
                    )}
                  />
                  <IntlMessages id="admin.kyc.foundationDate" />
                  <ErrorMessage
                    name="foundationDate"
                    component="div"
                    className="invalid-feedback d-block"
                  />
                </Label>
              </InputGroup>
            </Row>
            <Row className="mt-4 mb-1">
              <Label className="form-group has-top-label mb-0 w-40">
                <Field
                  name="postalCode"
                  render={({ field }) => (
                    <MaskedInput
                      type="text"
                      {...field}
                      mask={cepMask}
                      id="postalCode"
                      onChange={handleChange}
                      onBlur={(e) => {
                        handleBlur(e);
                        postalCodeChange(values, e);
                      }}
                      className="form-control"
                    />
                  )}
                />
                <IntlMessages id="admin.kyc.postalCode" />
                <ErrorMessage
                  name="postalCode"
                  component="div"
                  className="invalid-feedback d-block"
                />
              </Label>
            </Row>
            <Row className="mb-1">
              <InputGroup>
                <Label className="form-group has-top-label mb-0 w-80">
                  <Field
                    type="input"
                    name="street"
                    id="street"
                    className="form-control"
                  />
                  <IntlMessages id="admin.kyc.street" />
                  <ErrorMessage
                    name="street"
                    component="div"
                    className="invalid-feedback d-block"
                  />
                </Label>
                <Label className="form-group has-top-label mb-0 w-20">
                  <Field
                    type="input"
                    name="streetNumber"
                    id="streetNumber"
                    className="form-control"
                  />
                  <IntlMessages id="admin.kyc.streetNumber" />
                  <ErrorMessage
                    name="streetNumber"
                    component="div"
                    className="invalid-feedback d-block"
                  />
                </Label>
              </InputGroup>
            </Row>
            <Row className="mb-1">
              <InputGroup>
                <Label className="form-group has-top-label mb-0">
                  <Field
                    type="input"
                    name="complement"
                    id="complement"
                    className="form-control"
                  />
                  <IntlMessages id="admin.kyc.complement" />
                  <ErrorMessage
                    name="complement"
                    component="div"
                    className="invalid-feedback d-block"
                  />
                </Label>
                <Label className="form-group has-top-label mb-0">
                  <Field
                    type="input"
                    name="neighborhood"
                    id="neighborhood"
                    className="form-control"
                  />
                  <IntlMessages id="admin.kyc.neighborhood" />
                  <ErrorMessage
                    name="neighborhood"
                    component="div"
                    className="invalid-feedback d-block"
                  />
                </Label>
              </InputGroup>
            </Row>
            <Row className="mb-1">
              <InputGroup>
                <Label className="form-group has-top-label mb-0 w-80">
                  <Field
                    type="input"
                    name="city"
                    id="city"
                    className="form-control"
                  />
                  <IntlMessages id="admin.kyc.city" />
                  <ErrorMessage
                    name="city"
                    component="div"
                    className="invalid-feedback d-block"
                  />
                </Label>
                <Label className="form-group has-top-label mb-0 w-20">
                  <Field
                    type="input"
                    name="uf"
                    id="uf"
                    className="form-control"
                    maxLength="2"
                  />
                  <IntlMessages id="admin.kyc.uf" />
                  <ErrorMessage
                    name="uf"
                    component="div"
                    className="invalid-feedback d-block"
                  />
                </Label>
              </InputGroup>
            </Row>
            <Row className="mt-4 mb-1">
              <InputGroup>
                <Label className="form-group has-top-label mb-0 w-70">
                  <Field
                    type="email"
                    name="email"
                    id="email"
                    className="form-control"
                  />
                  <IntlMessages id="admin.kyc.billingEmail" />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="invalid-feedback d-block"
                  />
                </Label>
                <Label className="form-group has-top-label mb-0 w-30">
                  <Field
                    name="phone"
                    render={({ field }) => (
                      <MaskedInput
                        type="text"
                        {...field}
                        mask={phoneMask}
                        id="phone"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="form-control"
                      />
                    )}
                  />
                  <IntlMessages id="admin.kyc.phone" />
                  <ErrorMessage
                    name="phone"
                    component="div"
                    className="invalid-feedback d-block"
                  />
                </Label>
              </InputGroup>
            </Row>
          </ModalBody>
          <ModalFooter>
            <div className="d-flex justify-content-between align-items-center">
              <Button
                type="submit"
                color="primary"
                outline
                size="lg"
                disabled={isSubmitting}
              >
                <IntlMessages id="forms.action.next" />
              </Button>
            </div>
          </ModalFooter>
        </Form>
      )}
    </Formik>
  );
}
