import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import classnames from 'classnames';
import map from 'lodash/map';

import { components } from 'react-select';
import AsyncSelect from 'react-select/async';

import { locationTypes } from '../../constants/travelValues';

import algoliaSearch from '../../services/Algolia';
import classNames from 'classnames';

const Option = (props) => {
  const locationClass = locationTypes[props.data.type] || locationTypes.airport;

  return (
    <div
      className={classnames({
        'react-select__menu_option': true,
        active: props.isFocused,
      })}
    >
      <div className={`option-icon ${locationClass.style}`} />
      <components.Option {...props} />
    </div>
  );
};

export default function LocationSearch({ setValue, value, className }) {
  const { messages } = useIntl();

  // Functions
  const findLocation = useCallback((inputValue) => {
    return new Promise(async (resolve) => {
      const index = algoliaSearch.locationIndex();
      const content = await index.search(inputValue, {
        hitsPerPage: 5,
        filters: `type:airport OR type:city`,
      });

      if (content.nbHits) {
        const results = map(content.hits, (hit) => {
          return {
            value: hit.objectID,
            airportCode: hit.iata,
            cityCode: hit.city,
            label: `(${hit.iata}) ${hit.city} - ${hit.name}`,
            countryName: hit.country,
            type: hit.type,
          };
        });

        resolve(results);
      } else {
        resolve([]);
      }
    });
  }, []);

  // Render
  return (
    <AsyncSelect
      className={classNames('react-select', className)}
      classNamePrefix="react-select"
      components={{ Option }}
      value={value}
      isClearable={false}
      cacheOptions
      placeholder=""
      noOptionsMessage={() => {
        return messages['travel.location-message'];
      }}
      loadingMessage={() => {
        return messages['travel.location-searching'];
      }}
      onChange={(selects) => {
        setValue(selects);
      }}
      loadOptions={findLocation}
    />
  );
}
