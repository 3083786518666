import React, { useState, useRef } from 'react';
import { Popover, PopoverBody } from 'reactstrap';
import uniqueId from 'lodash/uniqueId';

import { useIntl } from 'react-intl';
import { triggerFilterChangeSegmentDebounce } from '../../utils/segment';

export default function Baggages({ flight, luggage = {} }) {
  const [showInfo, setShowInfo] = useState(false);
  const idRef = useRef(uniqueId('baggage-'));
  const { messages, formatMessage } = useIntl();

  const { quantity: luggageQuantity = 0, weight: luggageWeight = 23 } = luggage;

  const renderInfo = (carryOn) => (
    <Popover
      placement="bottom"
      isOpen={showInfo}
      target={idRef.current}
      popperClassName="baggage-info"
    >
      <PopoverBody>
        <div className="baggage-info-item d-flex flex-row justify-content-between">
          <img
            src="/assets/img/icons/handbag.svg"
            alt={messages['travel.Baggage']}
          />
          <div className="d-flex flex-column">
            <span>{messages['components.flight.baggages.bags']}</span>
            <span>{messages['components.flight.baggages.fit.below']}</span>
          </div>
        </div>
        <div
          className={`baggage-info-item d-flex flex-row justify-content-between ${
            carryOn === false ? 'opacity-30' : ''
          }`}
        >
          <img
            src="/assets/img/icons/carryon.svg"
            alt={messages['travel.backpack']}
          />
          <div className="d-flex flex-column">
            <span>{messages['components.flight.baggages.handluggage']}</span>
            <span>
              {carryOn === false
                ? messages['components.flight.baggages.fit.above.no']
                : messages['components.flight.baggages.fit.above']}
            </span>
          </div>
        </div>
        <div
          className={`baggage-info-item d-flex flex-row justify-content-between ${
            luggageQuantity === 0 ? 'opacity-30' : ''
          }`}
        >
          <img
            src="/assets/img/icons/luggage.svg"
            alt={messages['components.flight.baggages.handluggage']}
          />
          <div className="d-flex flex-column">
            <span>{messages['components.flight.baggages.dispatch']}</span>
            <span>
              {formatMessage(
                { id: 'components.flight.baggages.included' },
                {
                  baggageQuantity:
                    luggageQuantity > 1
                      ? `${luggageQuantity} ${messages['travel.baggages']}`
                      : luggageQuantity === 0
                      ? `0 ${messages['travel.baggages']}`
                      : `1 ${messages['travel.baggage']}`,
                  baggageWeight: luggageWeight ? `${luggageWeight}Kg` : '23Kg',
                }
              )}
            </span>
          </div>
        </div>
      </PopoverBody>
    </Popover>
  );

  return (
    <>
      <div
        id={idRef.current}
        className="journey-baggage d-flex justify-content-around pr-3"
        onMouseOver={() => {
          triggerFilterChangeSegmentDebounce('Hover over baggages component');
          setShowInfo(true);
        }}
        onMouseOut={() => setShowInfo(false)}
      >
        <img
          src="/assets/img/icons/handbag.svg"
          alt={messages['travel.backpack']}
        />
        <img
          src="/assets/img/icons/carryon.svg"
          alt={messages['components.flight.baggages.handluggage']}
          className={`${flight.fare?.carryOn === false && 'opacity-30'}`}
        />
        <img
          src="/assets/img/icons/luggage.svg"
          alt={messages['travel.Baggage']}
          className={luggageQuantity === 0 ? 'opacity-30' : ''}
        />
      </div>
      {renderInfo(flight.fare?.carryOn)}
    </>
  );
}
