import React, { useMemo } from 'react';
import CircularOptionButton from '../../../components/common/CircularOptionButton';

const getMessageKey = (id) => `card-option.${id}`;

export default function CardOption({
  option = {},
  messages,
  card = {},
  details,
  showTitle,
}) {
  const {
    Icon,
    title,
    onClick = () => {},
    iconStyle,
    name,
  } = useMemo(
    () => ({
      Icon: option.getIcon(card, details),
      title: messages[getMessageKey(option.getMessageId(card, details))],
      onClick: option.onClick,
      iconStyle: option.iconStyle || {},
      name: option.id,
    }),
    [option, card, details]
  );

  return (
    <CircularOptionButton
      name={name}
      className="card-option"
      Icon={Icon}
      iconStyle={iconStyle}
      showTitle={showTitle}
      title={title}
      onClick={onClick}
    />
  );
}
