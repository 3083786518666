import React from 'react';
import { Card, CardBody } from 'reactstrap';
import moment from 'moment';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import sumBy from 'lodash/sumBy';
import { useIntl } from 'react-intl';

import { SmallLineChart } from '../../components/charts';
import LoaderSmallLine from '../../components/loaders/LoaderSmallLine';
import { stringToMoney } from '../../utils/money';

export default function SmallLineEconomy({ orders, loading }) {
  const { messages } = useIntl();

  const results = groupBy(orders, (o) =>
    moment.unix(o.filterTimestamp).format('DD/MM/YYYY')
  );

  if (loading) {
    return (
      <Card className="dashboard-small-chart">
        <CardBody>
          <LoaderSmallLine />
        </CardBody>
      </Card>
    );
  }

  return (
    <Card className="dashboard-small-chart">
      <CardBody>
        <SmallLineChart
          labelFormat="money"
          callbackFormat={(amount) => stringToMoney(parseFloat(amount), 0)}
          data={{
            labels: Object.keys(results),
            datasets: [
              {
                data: map(results, (items) =>
                  sumBy(items, (i) => parseFloat(i.fare.total) * 0.2)
                ),
                label:
                  messages['containers.dashboard.small-line-economy.economy'],
                borderColor: '#43418e',
                pointBorderColor: '#43418e',
                pointHoverBackgroundColor: '#43418e',
                pointHoverBorderColor: '#43418e',
                pointRadius: 2,
                pointBorderWidth: 3,
                pointHoverRadius: 2,
                fill: false,
                borderWidth: 2,
                datalabels: {
                  align: 'end',
                  anchor: 'end',
                },
              },
            ],
          }}
        />
      </CardBody>
    </Card>
  );
}
