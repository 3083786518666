import { Component } from 'react';
import { Colxx } from '../../components/common/CustomBootstrap';
import { Card, Button, CustomInput } from 'reactstrap';
import moment from 'moment';
import clone from 'lodash/clone';
import find from 'lodash/find';
import map from 'lodash/map';
import isEqual from 'lodash/isEqual';
import size from 'lodash/size';
import includes from 'lodash/includes';

import { registeredAirlines } from '../../constants/travelValues';
import { injectIntl } from 'react-intl';
import { trackPropertiesSegment } from '../../utils/segment';
import CurrencyWrapper from '../../components/common/CurrencyWrapper';
import classNames from 'classnames';

class FlightItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedSegments: [],
    };
  }

  selectSegment(journeyIndex, segmentIndex) {
    const { selectedSegments } = this.state;
    const { item } = this.props;

    const newSelectedSegments = clone(selectedSegments);
    newSelectedSegments[journeyIndex] = {
      ...item.journeys[journeyIndex].segments[segmentIndex],
    };

    this.setState({
      selectedSegments: newSelectedSegments,
    });
  }

  // Render
  renderCityName(station) {
    if (this.props.params.airports) {
      return (
        <span>{find(this.props.params.airports, { iata: station })?.city}</span>
      );
    }

    return <span>{station}</span>;
  }

  renderDuration(segment, startTime, endTime) {
    if (segment.duration) {
      const duration =
        segment.duration > 0 ? segment.duration : segment.duration * -1;
      const hours = Math.floor(duration);
      const minutes = Math.floor((duration - Math.floor(duration)) * 60);

      return `${hours}h ${minutes}m`;
    }

    let start = new moment(startTime);
    let end = new moment(endTime);
    let duration = moment.duration(end.diff(start));

    return `${Math.floor(duration.asHours())}h ${duration.minutes()}m`;
  }

  renderArrivalDays(startTime, endTime) {
    let start = new moment(startTime).startOf('day');
    let end = new moment(endTime).startOf('day');
    let daysDiff = end.diff(start, 'days');

    if (daysDiff > 0) {
      return <span>+{daysDiff}</span>;
    }
  }

  renderJourney(journey, iJ) {
    const {
      index,
      intl: { messages },
      departureTime = [],
    } = this.props;

    const duration =
      iJ === 0 ? this.props.departureDuration : this.props.arrivalDuration;
    const connections =
      iJ === 0
        ? this.props.departureConnections
        : this.props.arrivalConnections;
    const departure = departureTime[iJ];

    return (
      <div className="journey" key={`journey-${index}-${iJ}`}>
        <div className="row header">
          <Colxx xxs="3">
            <div className="title">
              <img
                src="/assets/img/icons/journey_icon.svg"
                alt={messages['alts.containers.search.flight-item']}
              />
              {iJ === 0 && messages['travel.outbound']}
              {iJ === 1 && messages['travel.inbound']}
              <span className="journey-date">
                {moment(journey.departureDate).format('ddd DD MMM YYYY')}
              </span>
            </div>
          </Colxx>
          <Colxx xxs="2">
            <div className="station">
              {journey.departureStation}
              <span>{this.renderCityName(journey.departureStation)}</span>
            </div>
          </Colxx>
          <Colxx xxs="2"></Colxx>
          <Colxx xxs="2" className="station">
            <div className="station">
              {journey.arrivalStation}
              <span>{this.renderCityName(journey.arrivalStation)}</span>
            </div>
          </Colxx>
          <Colxx xxs="3"></Colxx>
        </div>

        {map(journey.segments, (segment, iS) => {
          return this.renderSegment(
            journey,
            iJ,
            segment,
            iS,
            duration,
            connections,
            departure
          );
        })}
      </div>
    );
  }

  renderSegment(journey, iJ, segment, iS, duration, connections, departure) {
    const { selectedSegments } = this.state;
    const {
      index,
      intl: { messages },
      stops = {},
    } = this.props;

    if (stops[segment.flights.length - 1] === false) return null;

    if (duration) {
      if (segment.duration < duration.min || segment.duration > duration.max)
        return null;
    }

    if (connections) {
      if (
        segment.flights.some(
          (flight) => connections[flight.arrivalStation] === false
        )
      )
        return null;
    }

    const departureDateTime = moment(segment.departureDateTime);

    if (departure) {
      const departureHours = departureDateTime.hours();
      if (departureHours < departure.min || departureHours > departure.max)
        return null;
    }

    return (
      <div key={`journey-${index}-${iJ}-${iS}`} className="row trip">
        <Colxx xxs="3" className="selection">
          <CustomInput
            type="checkbox"
            className="custom-check"
            id={`journey-${index}-${iJ}-${iS}`}
            checked={isEqual(selectedSegments[iJ], segment)}
            onChange={() => {
              trackPropertiesSegment('Flight segment selected', {
                segment,
                type: iJ ? 'Inbound' : 'Outbound',
              });
              this.selectSegment(iJ, iS);
            }}
          />
        </Colxx>

        <Colxx xxs="2" className="time">
          {departureDateTime.format('HH:mm')}
        </Colxx>

        <Colxx xxs="2" className="stops-chart">
          <div className="graph">
            <div className="line">
              <div className="dots">
                {map(segment.flights, (flightItem, iFI) => {
                  if (iFI === 0) return '';
                  return (
                    <div
                      key={`result-${index}-${iJ}-${iS}-${iFI}`}
                      className="block"
                    />
                  );
                })}
              </div>
            </div>
          </div>
          <div className="info">
            {segment.flights.length - 1 === 0
              ? messages['flight.no-stops']
              : segment.flights.length - 1 === 1
              ? `1 ${messages['flight.stop']}`
              : `${segment.flights.length - 1} ${messages['flight.stops']}`}
          </div>
        </Colxx>

        <Colxx xxs="2" className="time">
          {moment(segment.arrivalDateTime).format('HH:mm')}
          {this.renderArrivalDays(
            segment.departureDateTime,
            segment.arrivalDateTime
          )}
        </Colxx>

        <Colxx xxs="2" className="duration">
          {this.renderDuration(
            segment,
            segment.departureDateTime,
            segment.arrivalDateTime
          )}
        </Colxx>
      </div>
    );
  }

  render() {
    const {
      item,
      showDetails,
      isTwoWay,
      intl: { messages },
      className = '',
    } = this.props;

    const { selectedSegments } = this.state;
    const isSelected = isTwoWay
      ? size(selectedSegments) === 2
      : size(selectedSegments) === 1;
    const isDisabled = !isSelected || includes(selectedSegments, undefined);

    return (
      <Card className={classNames('search-card flight mt-4', className)}>
        <Colxx xxs="12" lg="9" className="journeys">
          {map(item.journeys, (journey, i) => {
            return this.renderJourney(journey, i);
          })}
        </Colxx>
        <Colxx xxs="12" lg="3" className="total pt-3">
          {registeredAirlines[item.airline] && (
            <div
              className="carrier"
              style={{
                backgroundImage: `url(${
                  registeredAirlines[item.airline].logo
                })`,
              }}
            />
          )}
          <div className="text">
            <span>{messages['general.total']}</span>
            <CurrencyWrapper value={item.fare.total} decimalPlaces={0}>
              {({ formattedValue }) => formattedValue}
            </CurrencyWrapper>
            <hr />
          </div>
          <div className="description">{messages['general.taxes']}</div>

          <Button
            color="primary"
            disabled={isDisabled}
            onClick={() => showDetails(item, selectedSegments)}
          >
            {messages['general.select']}
          </Button>
        </Colxx>
      </Card>
    );
  }
}

export default injectIntl(FlightItem);
