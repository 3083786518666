import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import HotelItineraryResults from '../../components/itinerary/results/HotelItineraryResults';
import FlightItineraryResults from '../../components/itinerary/results/FlightItineraryResults';
import useSearch from '../../hooks/useSearch';
import firebaseApp from '../../services/Firebase';
import { Button, Tooltip } from 'reactstrap';
import ItineraryModal from './ItineraryModal';
import CurrencyWrapper from '../../components/common/CurrencyWrapper';
import {
  getHotelOriginDateBasedOnOutboundSegment,
  getSegmentFromFlightItem,
  getTotalFromItinerary,
} from '../../utils/itinerary';
import { reserveFlightItem, reserveHotelItem } from '../../utils/reservation';
import { useDispatch, useSelector } from 'react-redux';
import { setPassenger } from '../../redux/actions';
import ItineraryTooltip from './ItineraryTooltip';

import { ReactComponent as AlertIcon } from '../../assets/img/icons/alert-circle.svg';
import { OrderTypes } from '../../constants/orderTypes';
import searchStatus from '../../constants/searchStatus';
import moment from 'moment';

function ItineraryTotal({
  total,
  formattedTotal,
  withTooltip = true,
  itemsTotal = {},
  withFlight = true,
}) {
  const tooltipRef = useRef(null);

  // States
  const [showToolTip, setShowToolTip] = useState(false);

  // Render
  return (
    <div className="flex-center mt-1">
      <h3 className="font-primary m-0 p-0 fs-1-2rem text-primary">
        {formattedTotal}
      </h3>
      {total && withTooltip ? (
        <>
          <div
            className="tooltip-icon ml-1 flex-center"
            ref={tooltipRef}
            onMouseEnter={() => setShowToolTip(true)}
            onMouseLeave={() => setShowToolTip(false)}
          >
            <AlertIcon
              width="1rem"
              height="1rem"
              fill="#43418e"
              className="ml-1"
            />
          </div>
          <Tooltip isOpen={showToolTip} target={tooltipRef}>
            <ItineraryTooltip itemsTotal={itemsTotal} />
          </Tooltip>
        </>
      ) : null}
    </div>
  );
}

export default function ItineraryResults({ search }) {
  const { user } = useSelector(({ auth }) => auth);
  const dispatch = useDispatch();

  const { outbound, inbound, hotel } = useMemo(
    () => search.searchCodes || {},
    [search]
  );

  // Searches
  const roundTrip = useMemo(() => outbound === inbound, [outbound, inbound]);

  // Outbound search
  const {
    search: outboundSearch,
    params: outboundParams,
    results: outboundResults,
    loading: outboundLoading,
  } = useSearch({
    searchCode: outbound,
    getParams: firebaseApp.getSearchFlightParams,
    getResults: firebaseApp.getInitialFlightResults,
  });

  // Inbound search
  const {
    search: inboundSearch,
    params: inboundParams,
    results: _inboundResults,
    loading: inboundLoading,
  } = useSearch({
    searchCode: roundTrip ? null : inbound,
    getParams: firebaseApp.getSearchFlightParams,
    getResults: firebaseApp.getInitialFlightResults,
  });

  // Hotel search
  const {
    search: hotelSearch,
    params: hotelParams,
    results: hotelResults,
    loading: hotelLoading,
  } = useSearch({
    searchCode: hotel,
    getParams: firebaseApp.getSearchHotelParams,
    getResults: firebaseApp.getInitialHotelResults,
  });

  // States
  const [showModal, setShowModal] = useState(false);
  const [selectedItems, setSelectedItems] = useState({});
  const [isReservatingItems, setIsReservatingItems] = useState(false);

  const [usedHotelRooms, setUsedHotelRooms] = useState({});

  const minItineraryValue = useMemo(() => {
    const getValue = (results = []) => results[0]?.fare?.total || 0;

    return (
      getValue(outboundResults) +
      getValue(hotelResults) +
      getValue(inboundResults)
    );
  }, [outboundResults, hotelResults, inboundResults]);

  const inboundResults = useMemo(() => {
    if (roundTrip) {
      const { outbound = {}, inbound = {} } = selectedItems;

      if (outbound.item) {
        if (inbound.item && inbound.item.id !== outbound.item.id) {
          setSelectedItems(({ inbound, ...items }) => items);
        }

        return [outbound.item];
      } else return [];
    }

    return _inboundResults;
  }, [_inboundResults, outboundResults, selectedItems.outbound]);

  const [isValid, total, itemsTotal] = useMemo(() => {
    const { outbound, hotel, inbound } = selectedItems;

    const isValid = outbound?.item && hotel?.item && inbound?.item;
    const { total, itemsTotal } = getTotalFromItinerary({ selectedItems });

    return [isValid, total, itemsTotal];
  }, [selectedItems]);

  const inboundProps = roundTrip
    ? {
        searchCode: outbound,
        loading: outboundLoading,
        params: outboundParams,
        search: outboundSearch,
      }
    : {
        searchCode: inbound,
        loading: inboundLoading,
        params: inboundParams,
        search: inboundSearch,
      };

  // Effects
  useEffect(() => {
    setSelectedItems({});
  }, [search]);

  // Functions
  const onSelectItem = (name) => (item) => {
    setSelectedItems((selectedItems) => ({
      ...selectedItems,
      [name]: item,
    }));
  };

  const addToUsedHotelRooms = useCallback(({ item, selectedRoom }) => {
    const hotelId = item.hotelId,
      roomId = selectedRoom.id;

    setUsedHotelRooms((usedRooms) => {
      const { [hotelId]: hotelRooms = {}, ...others } = usedRooms;
      return {
        ...others,
        [hotelId]: {
          ...hotelRooms,
          [roomId]: true,
        },
      };
    });
  }, []);

  const makeReservations = async ({
    items,
    reservationParams,
    orderStructure,
  }) => {
    try {
      if (!(isValid && orderStructure)) return;

      setIsReservatingItems(true);

      const { outbound, inbound, hotel } = items;

      const originDate = getHotelOriginDateBasedOnOutboundSegment(
        hotelSearch,
        outbound.item.journeys[outbound.selectedJourney].segments[
          outbound.selectedSegment
        ]
      );

      const promises = [
        reserveHotelItem({
          item: hotel.item,
          organizationId: user.organizationId,
          reservationParams,
          search: {
            ...hotelSearch,
            outbound_date: moment(originDate, 'DD/MM/YYYY'),
            inbound_date: moment(hotelSearch.destinationDate, 'DD/MM/YYYY'),
          },
          selectedRoom: hotel.selectedRoom,
        }),
      ];

      if (roundTrip) {
        promises.push(
          reserveFlightItem({
            item: outbound.item,
            organizationId: user.organizationId,
            params: outboundParams,
            reservationParams,
            search: outboundSearch,
            selectedSegments: [
              getSegmentFromFlightItem(outbound),
              getSegmentFromFlightItem(inbound),
            ],
          })
        );
      } else {
        promises.push(
          reserveFlightItem({
            item: outbound.item,
            organizationId: user.organizationId,
            params: outboundParams,
            reservationParams,
            search: outboundSearch,
            selectedSegments: [getSegmentFromFlightItem(outbound)],
          })
        );

        promises.push(
          reserveFlightItem({
            item: inbound.item,
            organizationId: user.organizationId,
            params: inboundParams,
            reservationParams,
            search: inboundParams,
            selectedSegments: [getSegmentFromFlightItem(inbound)],
          })
        );
      }

      await Promise.all(promises);

      addToUsedHotelRooms(hotel);
      setSelectedItems(({ hotel, ...items }) => items);

      // // Change timeline to selected user
      dispatch(setPassenger(reservationParams.passengerTimeline));
    } catch (err) {
      console.error(err);
    } finally {
      setIsReservatingItems(false);
    }
  };

  // Render
  if (search.status !== searchStatus.DONE) return null;

  const renderBottom = () => {
    if (outboundLoading || inboundProps.loading || hotelLoading) return null;

    return (
      <div>
        <hr className="my-5" />
        <div className="flex-between mb-5">
          <div>
            <h4
              className="font-primary m-0 p-0 fs-0-8rem text-primary font-weight-medium"
              style={{ opacity: 0.5 }}
            >
              {total ? 'Total' : 'Combo mais barato'}
            </h4>
            <CurrencyWrapper
              value={total || minItineraryValue}
              decimalPlaces={0}
              withTooltip={false}
              className="justify-content-start"
              atRight={false}
            >
              {({ formattedValue }) => (
                <ItineraryTotal
                  total={total}
                  formattedTotal={formattedValue}
                  withTooltip={total}
                  itemsTotal={itemsTotal}
                />
              )}
            </CurrencyWrapper>
          </div>
          <Button
            disabled={!isValid || isReservatingItems}
            onClick={() => setShowModal(true)}
            color="primary"
            size="lg"
          >
            Fazer reservas
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div className="itinerary-results pb-5">
      <FlightItineraryResults
        className="mt-4"
        loading={outboundLoading}
        params={outboundParams}
        results={outboundResults}
        search={outboundSearch}
        searchCode={outbound}
        onSelectItem={onSelectItem('outbound')}
        selectedItem={selectedItems['outbound']}
        roundTrip={roundTrip}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
      />

      <HotelItineraryResults
        className="mt-4"
        loading={hotelLoading}
        params={hotelParams}
        results={hotelResults}
        search={hotelSearch}
        searchCode={hotel}
        onSelectItem={onSelectItem(OrderTypes.HOTEL)}
        selectedItem={selectedItems[OrderTypes.HOTEL]}
        usedRooms={usedHotelRooms}
      />

      <FlightItineraryResults
        {...inboundProps}
        results={inboundResults}
        onSelectItem={onSelectItem('inbound')}
        selectedItem={selectedItems['inbound']}
        roundTrip={roundTrip}
        outbound={false}
        className="mt-4"
      />

      {renderBottom()}

      {showModal ? (
        <ItineraryModal
          items={selectedItems}
          toggleModal={() => setShowModal(false)}
          reserveItems={makeReservations}
          nationality={search?.params?.hotelParams?.nationality}
          outboundParams={outboundParams}
          inboundParams={inboundProps.params}
        />
      ) : null}
    </div>
  );
}
