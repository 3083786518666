import React, { Component } from 'react';
import { Form, Row, Label } from 'reactstrap';
import { Colxx } from '../../components/common/CustomBootstrap';

import CarLocationSearch from '../../components/search/CarLocationSearch';
import SearchButton from '../../components/search/SearchButton';
import DateSearch from '../../components/search/DateSearch';

import { getSearchCode, getProviders } from '../../utils/search';

import { injectIntl } from 'react-intl';
import IntlMessages from '../../helpers/IntlMessages';

import SearchStatus from '../../constants/searchStatus';
import CustomErrorMessage from '../../components/CustomErrorMessage';
import { validateFields, objectValidator } from '../../utils/fieldValidation';
import Sequence from '../../utils/sequence';

import { GoogleApiWrapper } from 'google-maps-react';
import config from '../../constants/GoogleMaps';

class OfficeForm extends Component {
  outboundRef = React.createRef();
  originRef = React.createRef();

  constructor(props) {
    super(props);

    this.state = { errors: {}, geocoder: new props.google.maps.Geocoder() };
    this.sequence = new Sequence([
      [
        (currentProps, _) => currentProps.search.origin.value,
        () => {
          if (this.originRef.current)
            this.originRef.current.children[0].focus();
        },
      ],
      [
        (currentProps, _) => currentProps.search.outbound_date,
        () => {
          if (this.outboundRef.current) this.outboundRef.current.setFocus();
        },
      ],
    ]);
  }

  componentDidMount() {
    this.sequence.run(this.props, this.props);
  }

  componentDidUpdate(prevProps) {
    this.sequence.run(this.props, prevProps);
  }

  verifyFields = (search) => {
    const fields = [['origin', objectValidator], ['outbound_date']];

    const errors = validateFields(search, fields);

    this.setState({ errors });

    return !Object.keys(errors).length;
  };

  removePropertyFromErrors = (property) => {
    if (property in this.state.errors) {
      const { [property]: value, ...errors } = this.state.errors;
      this.setState({ errors });
    }
  };

  // Do Search
  startSearch = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { search, startSearch } = this.props;

    if (search.status !== SearchStatus.LOADING && this.verifyFields(search)) {
      try {
        const { origin, outbound_date, currency } = search;

        let searchCode = '';
        let payload = '';

        searchCode = getSearchCode(
          'office',
          origin.value,
          '',
          '',
          '',
          currency
        );

        const { results } = await this.state.geocoder.geocode({
          address: origin.label,
        });

        if (results.length) {
          const { lat, lng } = results[0].geometry.location;

          payload = {
            reservationCoord: {
              lat: lat(),
              lng: lng(),
            },
            reservationDate: outbound_date.format('DD/MM/YYYY'),
            providers: getProviders('office'),
            status: SearchStatus.LOADING,
          };

          startSearch(searchCode, payload);
        }
      } catch (err) {
        console.error(err);
      }
    }
  };

  // Render
  render() {
    const { messages } = this.props.intl;
    const { search, setOriginLocation, setOutboundDate } = this.props;
    const { errors } = this.state;

    return (
      <Form className="travel-search" onSubmit={this.startSearch}>
        <Row>
          <Colxx xxs="3">
            <div ref={this.originRef}>
              <Label
                className="form-group search has-top-label w-100 mb-2"
                onFocus={() => this.removePropertyFromErrors('origin')}
              >
                <CarLocationSearch
                  defaultValue={search.origin}
                  setValue={setOriginLocation}
                />
                <IntlMessages id="travel.officeLocation" />
                <CustomErrorMessage error={errors.origin}>
                  {messages['forms.validation.required']}
                </CustomErrorMessage>
              </Label>
            </div>
          </Colxx>
          <Label className="form-group search has-top-label w-5 float-left addon-places">
            <img
              src="/assets/img/icons/office.svg"
              alt={messages['alts.containers.search.car-form']}
            />
          </Label>
          <Colxx xxs="2">
            <Label
              className="form-group search has-top-label float-left"
              onClick={(e) => e.preventDefault()}
              onFocus={() => this.removePropertyFromErrors('outbound_date')}
            >
              <DateSearch
                selectedDate={search.outbound_date}
                setValue={setOutboundDate}
                isStart
                defaultStartDate={search.outbound_date}
                ref={this.outboundRef}
              />
              <IntlMessages id="travel.officeDate" />
              <CustomErrorMessage
                error={errors.outbound_date}
                style={{ animationDelay: '0.1s' }}
              >
                {messages['forms.validation.required']}
              </CustomErrorMessage>
            </Label>
          </Colxx>
          <SearchButton status={search.status} />
        </Row>
      </Form>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: config.key,
})(injectIntl(OfficeForm));
