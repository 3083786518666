import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import Spinner from '../../../../../components/common/Spinner';
import useDataLoaderWithArchive from '../../../../../hooks/useDataLoaderWithArchive';
import firebaseApp from '../../../../../services/Firebase';
import HistoryItem from '../HistoryItem';
import GenericEmptyMessage from '../../GenericEmptyMessage';

import { ReactComponent as EmptyIcon } from '../../../../../assets/img/expenses/empty_history.svg';

export default function HistoryTab({ budget }) {
  const { messages, formatMessage } = useIntl();

  // States
  const [history, _, isLoadingHistory] = useDataLoaderWithArchive({
    fetchFunction: () =>
      firebaseApp
        .getHistoryFromRecurrenceBudget(
          budget.id,
          budget.organizationId,
          budget.passengerId
        )
        .orderBy('recurrenceDate', 'desc'),
    archiveKey: `recurrence-history/${budget.id}`,
  });

  const { isEmpty, emptyMessage } = useMemo(() => {
    const isEmpty = history.length === 0;

    if (!isEmpty) return { isEmpty };

    return {
      isEmpty,
      emptyMessage: formatMessage(
        { id: 'recurrence-budget-modal.empty-history' },
        { date: budget.nextRecurrence.split('-').reverse().join('/') }
      ),
    };
  }, [history]);

  // Render
  if (isLoadingHistory) {
    return (
      <div>
        <Spinner style={{ width: 80, height: 80 }} />
      </div>
    );
  }

  const renderEmpty = () => {
    return (
      <div className="d-flex">
        <GenericEmptyMessage
          className="w-80 mt-5 show-up"
          Icon={EmptyIcon}
          description={emptyMessage}
          title={messages['recurrence-budget-modal.empty-history.title']}
        />
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className="w-70 mx-auto history-items">
        <div className="line" />

        {history.map((item, index) => (
          <HistoryItem key={item.id} item={item} right={index % 2 !== 0} />
        ))}
      </div>
    );
  };

  return (
    <div className="history-tab py-3">
      {isEmpty ? renderEmpty() : renderContent()}
    </div>
  );
}
