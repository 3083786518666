import React from 'react';
import ContentLoader from 'react-content-loader';

export default function LoadingDefaultCard() {
  return (
    <div className="loading-default-card bg-white mb-5">
      <ContentLoader
        speed={2}
        height={325}
        viewBox="0 0 100 100"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        className="w-100"
        preserveAspectRatio="none"
      >
        <rect x="2" y="20" rx="1" ry="2" width="18.5" height="44" />
        <rect x="2" y="70" rx="1" ry="2" width="12" height="10" />
        <rect x="22.5" y="0" rx="1" ry="2" width="0.05" height="100" />
        <rect x="24.2" y="7.5" rx="1" ry="2" width="23.8" height="87" />
        <rect x="49.5" y="7.5" rx="1" ry="2" width="23" height="87" />
        <rect x="74" y="7.5" rx="1" ry="2" width="23" height="87" />
      </ContentLoader>
    </div>
  );
}
