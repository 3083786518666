export const BasicStyle = {
  option: (provided, { isSelected, isFocused }) => ({
    ...provided,
    backgroundColor: isSelected ? '#43418e' : 'white',
    ':hover': {
      backgroundColor: isSelected ? '#43418e' : 'rgba(67, 65, 142, 0.1)',
      color: isSelected ? 'white' : 'black',
    },
    color: isSelected ? 'white' : 'black',
  }),
  singleValue: (base) => ({
    ...base,
    color: 'inherit',
  }),
  control: (base, { isFocused }) => {
    return {
      ...base,
      borderColor: isFocused ? '#43418e' : '#e0e0e0',
      boxShadow: 'none',
      ':hover': {
        borderColor: '#43418e',
        boxShadow: '0 0 0 1px rgba(67, 65, 142, 0.2)',
      },
      ':focus': {
        borderColor: '#43418e',
        boxShadow: '0 0 0 1px rgba(67, 65, 142, 0.2)',
      },
    };
  },
};

export const WhiteBottomLineStyle = {
  option: (provided, { isSelected }) => ({
    ...provided,
    backgroundColor: isSelected ? '#43418e' : 'white',
    ':hover': {
      backgroundColor: isSelected ? '#43418e' : 'rgba(67, 65, 142, 0.1)',
      color: isSelected ? 'white' : 'black',
    },
    color: isSelected ? 'white' : 'black',
  }),
  singleValue: (base) => ({
    ...base,
    color: 'inherit',
  }),
  control: (base) => {
    return {
      ...base,
      borderColor: 'transparent',
      backgroundColor: 'transparent',
      boxShadow: 'none',
      ':hover': {
        borderColor: 'transparent',
        boxShadow: '0 0 0 1px rgba(67, 65, 142, 0.2)',
      },
      ':focus': {
        borderColor: 'transparent',
        boxShadow: '0 0 0 1px rgba(67, 65, 142, 0.2)',
      },
    };
  },
  container: (base) => ({
    ...base,
    borderBottomWidth: 1,
    borderBottomColor: 'white',
    borderBottomStyle: 'solid',
    borderRadius: 1,
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: 'white',
  }),
  placeholder: (base) => {
    return {
      ...base,
      color: 'rgba(255, 255, 255, 0.5)',
    };
  },
};

export const MultiPurpleStyle = {
  multiValue: (provided) => {
    return {
      ...provided,
      backgroundColor: '#43418e !important',
      borderRadius: 3,
      border: 'none !important',
    };
  },
  multiValueLabel: (provided) => ({
    ...provided,
    color: 'white !important',
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: 'white',
    ':hover': {
      color: 'green',
    },
  }),
};
