import React, { useEffect, useState, useCallback } from 'react';
import { useIntl, injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import times from 'lodash/times';
import sortBy from 'lodash/sortBy';
import pickBy from 'lodash/pickBy';
import { uniqBy } from 'lodash';

import { Card, Button } from 'reactstrap';

import firebaseApp from '../../services/Firebase';
import HotelProximitySearch from './HotelProximitySearch';

import HotelAmenities from '../../components/order/HotelAmenities';
import NoResults from '../../components/search/NoResults';
import { ReactComponent as Heart } from '../../assets/img/icons/heart-like-hotel.svg';
import { ReactComponent as Check } from '../../assets/img/icons/check-suggestion-hotel.svg';

import { getFavoriteHotelSearch } from '../../utils/getFavoriteHotel';
import { getIDWithinRangeFromLocation } from '../../utils/locations';

import './styles/HotelSuggestionModal.scss';

import LoadingReset from '../../components/auth/LoadingReset';
import { hotelBookingService } from './services/hotelBookingService';

function getListLimitedBy(listHotels, sliceQuantity) {
  return listHotels.slice(0, sliceQuantity);
}

function takeFavoriteHotel(listHotels) {
  const cheapestFavoriteHotel = takeCheapestHotel(listHotels);
  if (cheapestFavoriteHotel && cheapestFavoriteHotel.length >= 2) {
    return getListLimitedBy(cheapestFavoriteHotel, 2);
  }

  return cheapestFavoriteHotel;
}

function takeCheapestHotel(listHotels) {
  return sortBy(listHotels, ['fare.total']);
}

function getAllRoomsWithBreakfast(hotelList) {
  let allRoomsWithBreakfast = [];
  hotelList.reduce((newHotel, hotel) => {
    const filtered = hotel.rooms.filter((room) => room.board.breakfast);
    if (filtered.length > 0) {
      newHotel = hotel;
      newHotel.rooms = filtered;
      allRoomsWithBreakfast.push(newHotel);
    }
  }, 0);
  return allRoomsWithBreakfast;
}

function getRoom(hotelList) {
  let arrayRooms = [];
  const threeHotels = getListLimitedBy(hotelList, 3);
  threeHotels.reduce((newHotel, hotel) => {
    const sortRooms = sortBy(hotel.rooms, ['fare']);
    const filtered = sortRooms.find((room) => room.board.breakfast);
    newHotel = hotel;
    newHotel.rooms = filtered ? [filtered] : [];
    arrayRooms.push(newHotel);
  }, 0);

  return arrayRooms;
}

function RenderImageHotel({ picture }) {
  let backgroundImage = '/assets/img/default-hotel-bw.png';

  if (picture !== '') {
    backgroundImage = picture;
  }

  return (
    <div
      className="hotel-image ml-0 h-100"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    />
  );
}

function filterCoords(coords) {
  return pickBy(coords, (coord) => !coord.includes(null));
}

async function getCoords(hotelState) {
  const { search } = hotelState;

  const paramSnap = await firebaseApp
    .getSearchHotelParams(search.searchCode)
    .get();

  const listCoords = paramSnap.data();

  if (listCoords?.coords) {
    listCoords.coords = filterCoords(listCoords.coords);
  }

  return { params: listCoords || {} };
}

const HotelSuggestionModal = ({ closeReserveHotel, reservationParams }) => {
  const props = useSelector((state) => state);
  const hotelState = {
    ...props.searchHotel,
    activeTab: 'hotel',
    autoSearch: true,
    suggestionHotel: true,
  };

  const { messages } = useIntl();
  const [allHotels, setAllHotels] = useState([]);
  const [suggestionHotel, setSuggestionHotel] = useState([]);
  const [favoriteHotel, setFavoriteHotel] = useState([]);
  const [loading, setLoading] = useState(true);

  const getHotelSearch = async () => {
    const { search } = hotelState;

    const resultsSnap = await firebaseApp
      .getInitialHotelResults(search.searchCode)
      .get();

    let newResults = [];

    resultsSnap.forEach((resultSnap) => {
      const result = resultSnap.data();
      newResults.push(result);
    });

    setLoading(false);

    setAllHotels(newResults);
  };

  const filterSuggestionHotels = () => {
    if (allHotels.length > 0) {
      const cheaperHotel = takeCheapestHotel(allHotels);
      const allRoomsWithBreakfast = getAllRoomsWithBreakfast(cheaperHotel);
      const cheaperRoomWithBreakfast = getRoom(allRoomsWithBreakfast);
      const hotelFavorite = takeFavoriteHotel(favoriteHotel);

      const createListHotel =
        hotelFavorite.length > 0
          ? hotelFavorite.concat(cheaperRoomWithBreakfast)
          : cheaperRoomWithBreakfast;
      const listHotel = uniqBy(createListHotel, 'hotelId');
      const getThreeHotels = getListLimitedBy(listHotel, 3);
      setSuggestionHotel(getThreeHotels);
    } else {
      setSuggestionHotel([]);
    }
  };

  const reserveHotel = async (hotel) => {
    const { user } = props.auth;
    const { search, suggestionHotel } = hotelState;
    const selectedRoom = hotel.rooms[0];

    hotelBookingService(
      hotel,
      search,
      user,
      reservationParams,
      selectedRoom,
      suggestionHotel
    );

    // Change timeline to selected user
    closeReserveHotel(false);
  };

  const getHighlightPin = async (pin) => {
    const coords = await getCoords(hotelState);
    const listHotels = getIDWithinRangeFromLocation(coords.params.coords, pin);
    const newListSuggestionHotels = allHotels.filter((hotel) =>
      listHotels.includes(hotel.hotelId)
    );

    filterSuggestionHotels(newListSuggestionHotels);
  };

  const getFavoriteHotel = async () => {
    const { search } = hotelState;
    const { user } = props.auth;
    const favoriteHotel = await getFavoriteHotelSearch(user, search);
    setFavoriteHotel(favoriteHotel);
  };

  useEffect(() => {
    if (hotelState.search.searchCode) {
      getFavoriteHotel();
      getHotelSearch();
    }
  }, [hotelState.search.searchCode]);

  useEffect(() => {
    filterSuggestionHotels();
  }, [allHotels]);

  const DetailHotel = ({ hotel }) => {
    const { category, mainPhoto, name } = hotel.hotel;
    const { total } = hotel.fare;
    return (
      <Card className="search-card hotel mr-2 flex-column align-items-center pb-4 justify-content-between">
        <RenderImageHotel picture={mainPhoto} />
        <div className="box-reserve-hotel pb-4 pl-4 pr-4">
          <div
            style={{ marginLeft: -3 }}
            className="mb-2 d-flex align-items-center justify-content-between"
          >
            <div>
              {times(category, (index) => {
                return (
                  <img
                    key={`hotel-${hotel.hotelId}-category-${index}`}
                    src="/assets/img/hotel-star.svg"
                    className="ml-1"
                    alt={
                      messages[
                        'alts.containers.search.hotel-item.hotel-category'
                      ]
                    }
                  />
                );
              })}
            </div>

            {hotel.favorite ? (
              <HotelFavorite message={messages} />
            ) : (
              <HotelCheapest message={messages} />
            )}
          </div>
          <h5>{name}</h5>

          <p className="text-muted">{hotel?.rooms[0]?.description}</p>

          <HotelAmenities hotel={hotel.hotel} hotelId={hotel.hotelId} />

          <div className="">
            <p className="text-price">{messages['general.total']}</p>
            <p className="price">R$ {total}</p>
          </div>
        </div>
        <div>
          <Button
            color="primary"
            className="button-reserve"
            onClick={() => reserveHotel(hotel)}
          >
            {messages['search.hotelSuggestionModal.reserve']}
          </Button>

          <a
            href={`/travel/search/hotel?params=${encodeURIComponent(
              JSON.stringify(hotelState)
            )}`}
            className="more-info"
          >
            {messages['general.see-more']}
          </a>
        </div>
      </Card>
    );
  };

  const HotelFavorite = ({ message }) => {
    return (
      <div className="button-status like">
        <p>{message['general.favorite']}</p>
        <Heart />
      </div>
    );
  };

  const HotelCheapest = ({ message }) => {
    return (
      <div className="button-status cheaper">
        <p>{message['general.cheapest']}</p>
      </div>
    );
  };

  const resetHotels = () => {
    filterSuggestionHotels(allHotels);
  };

  return (
    <div className="suggestion-hotel m-4">
      <div className="d-flex align-items-center mb-4">
        <Check className="ml-1 mr-2" />
        <h5 className="modal-title">
          {messages['search.hotelSuggestionModal.title']}
        </h5>
      </div>

      <div className="divider-suggestion-hotels"></div>

      <div className="mt-4">
        <div className="d-flex justify-content-between">
          <div>
            <h5 className="modal-title">
              {messages['search.hotelSuggestionModal.options']}
            </h5>
            <p className="text-muted">
              {messages['search.hotelSuggestionModal.options.locale']}
            </p>
          </div>
          <div className="box-search">
            <div className="search">
              <HotelProximitySearch
                setHighlightPin={getHighlightPin}
                city={hotelState.search.destination.cityCode}
              />
            </div>
            <span className="reset-hotels" onClick={resetHotels}>
              {messages['search.clear-search']}
            </span>
          </div>
        </div>
      </div>

      <div>{loading ? <LoadingReset /> : null}</div>

      {suggestionHotel.length !== 0 && (
        <div className="d-flex justify-content-between">
          {suggestionHotel.map((hotel, index) => {
            return <DetailHotel hotel={hotel} key={index} />;
          })}
        </div>
      )}

      {suggestionHotel.length === 0 && !loading && (
        <div className="d-flex align-items-center justify-content-center">
          <NoResults />
        </div>
      )}
    </div>
  );
};

export default injectIntl(HotelSuggestionModal);
