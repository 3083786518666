import { DDDs } from '../constants/phone';
import { PixTypes } from '../constants/pix';
import { isCnpjValid, numberToCnpj } from './cnpj';
import { cpfRegex, isCpfValid, numberToCpf } from './cpf';

const ValidationFunctions = {
  [PixTypes.CPF]: isCpfValid,
  [PixTypes.CNPJ]: isCnpjValid,
  [PixTypes.EMAIL]: (pix) =>
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      pix
    ),
  [PixTypes.PHONE]: (pix = '') =>
    /^(\(\d{2}\)|\d{2}) ?\d ?\d{4}-?\d{4}$/.test(pix),
  [PixTypes.RANDOM]: (pix) => /^\w{8}-?\w{4}-?\w{4}-?\w{4}-?\w{12}$/.test(pix),
};

const FormatFunctions = {
  [PixTypes.CPF]: numberToCpf,
  [PixTypes.CNPJ]: numberToCnpj,
};

export const validatePix = (pix, pixType) => {
  const func = ValidationFunctions[pixType];

  return func ? func(pix) : false;
};

export const formatPix = (type, key) => {
  const formatFunction = FormatFunctions[type];
  return formatFunction ? formatFunction(key) : key;
};

export const getPixType = (key) => {
  const pixLength = key.length;

  if (pixLength < 3) return null;

  // Initial check for phone, cpf or cnpj
  if (!/[^0-9.() -\/]/.test(key)) {
    const strippedKey = key.replace(/\D/g, '');
    const strippedKeyLength = strippedKey.length;

    // CPF check
    if (strippedKeyLength === 11 && isCpfValid(strippedKey))
      return PixTypes.CPF;

    // Phone check
    if (
      strippedKeyLength === 11 &&
      strippedKey.slice(0, 2) in DDDs &&
      strippedKey[2] === '9'
    ) {
      return cpfRegex.test(key) ? null : PixTypes.PHONE;
    }

    // CNPJ check
    if (strippedKeyLength == 14 && isCnpjValid(strippedKey))
      return PixTypes.CNPJ;
  }

  // Random check
  if (
    (pixLength === 32 || pixLength === 36) &&
    validatePix(key, PixTypes.RANDOM)
  )
    return PixTypes.RANDOM;

  // Email check
  if (validatePix(key, PixTypes.EMAIL)) {
    return PixTypes.EMAIL;
  }

  // QRCode check
  if (pixLength > 50) {
    return PixTypes.QRCODE;
  }

  return null;
};

export function formatAccountNumber(accountNumber) {
  return `${accountNumber.slice(0, -1)}-${accountNumber.slice(-1)}`;
}

export function formatBranchCode(branchCode) {
  return branchCode.padStart(4, '0');
}
