import React, { Component } from 'react';
import { Form, Row, Label, CustomInput } from 'reactstrap';
import { Colxx } from '../../components/common/CustomBootstrap';

import BusLocationSearch from '../../components/search/BusLocationSearch';
import DateSearch from '../../components/search/DateSearch';
import SearchButton from '../../components/search/SearchButton';

import { getSearchCode, getProviders } from '../../utils/search';

import { injectIntl } from 'react-intl';
import IntlMessages from '../../helpers/IntlMessages';

import SearchStatus from '../../constants/searchStatus';
import CustomErrorMessage from '../../components/CustomErrorMessage';
import { validateFields, objectValidator } from '../../utils/fieldValidation';
import Sequence from '../../utils/sequence';

class BusForm extends Component {
  outboundRef = React.createRef();
  inboundRef = React.createRef();
  originRef = React.createRef();
  destinationRef = React.createRef();

  constructor(props) {
    super(props);

    this.state = { errors: {} };
    this.sequence = new Sequence([
      [
        (currentProps, _) => currentProps.search.origin.value,
        () => {
          if (this.originRef.current)
            this.originRef.current.children[0].focus();
        },
      ],
      [
        (currentProps, _) => currentProps.search.destination.value,
        () => {
          if (this.destinationRef.current) {
            const currentRef = this.destinationRef.current;
            setTimeout(() => currentRef.children[0].focus(), 100);
          }
        },
      ],
      [
        (currentProps, _) => currentProps.search.outbound_date,
        () => {
          if (this.outboundRef.current) this.outboundRef.current.setFocus();
        },
      ],
      [
        (currentProps, _) => currentProps.search.inbound_date,
        () => {
          if (this.inboundRef.current) this.inboundRef.current.setFocus();
        },
      ],
    ]);
  }

  componentDidMount() {
    if (this.props.autoSearch) this.startSearch();
    else this.sequence.run(this.props, this.props);
  }

  componentDidUpdate(prevProps) {
    if (this.props.autoSearch) {
      if (this.props.search.searchCode !== prevProps.search.searchCode) {
        return this.startSearch();
      }
    }

    this.sequence.run(this.props, prevProps);
  }

  verifyFields = (search) => {
    const fields = [
      ['origin', objectValidator],
      ['destination', objectValidator],
      ['outbound_date'],
    ];

    if (search.twoWay) fields.push(['inbound_date']);

    const errors = validateFields(search, fields);

    this.setState({ errors });

    return !Object.keys(errors).length;
  };

  removePropertyFromErrors = (property) => {
    if (property in this.state.errors) {
      const { [property]: value, ...errors } = this.state.errors;
      this.setState({ errors });
    }
  };

  // Do Search
  startSearch = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    const { search, startSearch } = this.props;

    if (search.status !== SearchStatus.LOADING && this.verifyFields(search)) {
      const {
        origin,
        destination,
        outbound_date,
        inbound_date,
        twoWay,
        currency,
      } = search;

      let searchCode = '';
      let payload = '';

      // Validate
      if (twoWay) {
        if (
          !origin.value ||
          !outbound_date ||
          !destination.value ||
          !inbound_date
        ) {
          return;
        }

        searchCode = getSearchCode(
          'bus',
          origin.value,
          outbound_date,
          destination.value,
          inbound_date,
          currency
        );

        payload = {
          originCode: origin.value,
          originDate: outbound_date.format('DD/MM/YYYY'),
          destinationCode: destination.value,
          destinationDate: inbound_date.format('DD/MM/YYYY'),
          providers: getProviders('bus'),
          status: SearchStatus.LOADING,
        };
      } else {
        if (!origin.value || !outbound_date || !destination.value) {
          return;
        }

        searchCode = getSearchCode(
          'bus',
          origin.value,
          outbound_date,
          destination.value,
          '',
          currency,
          ''
        );

        payload = {
          originCode: origin.value,
          originDate: outbound_date.format('DD/MM/YYYY'),
          destinationCode: destination.value,
          providers: getProviders('bus'),
          status: SearchStatus.LOADING,
        };
      }

      startSearch(searchCode, payload);
    }
  };

  // Render
  render() {
    const { messages } = this.props.intl;
    const {
      search,
      setOriginLocation,
      toggleTwoWay,
      setDestinationLocation,
      setOutboundDate,
      setInboundDate,
      swapLocations,
    } = this.props;
    const { errors } = this.state;

    return (
      <Form className="travel-search" onSubmit={this.startSearch}>
        <Row>
          <Colxx xxs="5">
            <div ref={this.originRef}>
              <Label
                className="form-group search has-top-label w-45 float-left"
                onFocus={() => this.removePropertyFromErrors('origin')}
              >
                <BusLocationSearch
                  className="outbound-search"
                  value={search.origin}
                  setValue={setOriginLocation}
                />
                <IntlMessages id="travel.origin" />
                <CustomErrorMessage error={errors.origin}>
                  {messages['forms.validation.required']}
                </CustomErrorMessage>
              </Label>
            </div>

            <Label
              className="form-group search has-top-label w-10 float-left addon-places flip pointer"
              onClick={swapLocations}
            >
              <img
                src="/assets/img/icons/search_icon_bus.svg"
                alt="Origin and Destination"
              />
            </Label>

            <div ref={this.destinationRef}>
              <Label
                className="form-group search has-top-label w-45 float-left"
                onFocus={() => this.removePropertyFromErrors('destination')}
              >
                <BusLocationSearch
                  className="inbound-search"
                  setValue={setDestinationLocation}
                  value={search.destination}
                />
                <IntlMessages id="travel.destination" />
                <CustomErrorMessage
                  error={errors.destination}
                  style={{ animationDelay: '0.1s' }}
                >
                  {messages['forms.validation.required']}
                </CustomErrorMessage>
              </Label>
            </div>
          </Colxx>
          <Colxx xxs="4">
            <div className="float-left w-50">
              <div>
                <Label
                  className="form-group search has-top-label mb-2"
                  onClick={(e) => e.preventDefault()}
                  onFocus={() => this.removePropertyFromErrors('outbound_date')}
                >
                  <DateSearch
                    className="outbound-date"
                    selectedDate={search.outbound_date}
                    setValue={setOutboundDate}
                    isStart
                    defaultStartDate={search.outbound_date}
                    defaultEndDate={search.inbound_date}
                    ref={this.outboundRef}
                  />
                  <IntlMessages id="travel.outbound" />
                  <CustomErrorMessage
                    error={errors.outbound_date}
                    style={{ animationDelay: '0.2s' }}
                  >
                    {messages['forms.validation.required']}
                  </CustomErrorMessage>
                </Label>
              </div>
              <div>
                <CustomInput
                  type="checkbox"
                  id={`dropOff-same-as-pickUp`}
                  label={messages['travel.isTwoWay']}
                  checked={search.twoWay}
                  onChange={toggleTwoWay}
                />
              </div>
            </div>

            {search.twoWay && (
              <Label
                className="form-group search has-top-label float-left w-50"
                onClick={(e) => e.preventDefault()}
                onFocus={() => this.removePropertyFromErrors('inbound_date')}
              >
                <DateSearch
                  className="inbound-date"
                  selectedDate={search.inbound_date}
                  setValue={setInboundDate}
                  defaultMinDate={search.outbound_date}
                  isEnd
                  defaultStartDate={search.outbound_date}
                  defaultEndDate={search.inbound_date}
                  ref={this.inboundRef}
                />
                <IntlMessages id="travel.inbound" />
                <CustomErrorMessage
                  error={errors.inbound_date}
                  style={{ animationDelay: '0.3s' }}
                >
                  {messages['forms.validation.required']}
                </CustomErrorMessage>
              </Label>
            )}
          </Colxx>
          <SearchButton status={search.status} />
        </Row>
      </Form>
    );
  }
}

export default injectIntl(BusForm);
