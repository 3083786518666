import { TObject } from 'src/portao3-legacy/types/others';

export enum FilterQueryTypes {
  STRING,
  RANGE,
  RANGE_MAP,
  CONTAINS,
  MAP,
  CUSTOM,
}

export type TGetParams<I = any, P = any> = { item: I; params: P };

export interface TFilterQuery<I = any, P = any> {
  name?: string;
  type: FilterQueryTypes;
  truthWhenEqualsTo?: boolean;
  getValue?: (data: any) => any;
  getParam?: (data: TGetParams<I, P>, index?: number) => any;
  prepareParams?: (data: TGetParams<I, P>) => any;
  skip?: boolean;
  exclusive?: boolean;
}

export interface TStringFilterQuery<I, P> extends TFilterQuery<I, P> {
  fullyEqual?: boolean;
  skipIfEmpty?: boolean;
}

export interface TRangeFilterQuery<I, P> extends TFilterQuery<I, P> {
  minKey?: string;
  maxKey?: string;
}

export interface TContainsFilterQuery<I, P, H = TObject<any>>
  extends TFilterQuery<I, P> {
  getParam: (data: TGetParams<I, P>) => H;
  requireEveryIfArray?: boolean;
  valueIsArray?: boolean;
  filterFalsy?: boolean;
  skipIfEmpty?: boolean;
  allowUndefined?: boolean;
}

export interface TMapFilterQuery<I, P, M> extends TFilterQuery<I, P> {
  getValue: (data: I) => M[];
  queries: TFilterQuery<I, P>[];
}

export interface TCustomFilterQuery<I, P>
  extends Omit<TFilterQuery<I, P>, 'getValue'> {
  getValue: (data: I, params: P) => boolean;
}

export type TFilterQueryTypes<I, P, V> =
  | TStringFilterQuery<I, P>
  | TFilterQuery<I, P>
  | TRangeFilterQuery<I, P>
  | TContainsFilterQuery<I, P, V>
  | TMapFilterQuery<I, P, V>
  | TCustomFilterQuery<I, P>;
