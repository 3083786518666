import React from 'react';
import classNames from 'classnames';

export default function BottomInfoCard({ className = '', children, show }) {
  return (
    <div className={classNames('bottom-info-card', className, { show })}>
      {children}
    </div>
  );
}
