import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Label } from 'reactstrap';
import firebaseApp from '../../../../../services/Firebase';
import IntlMessages from '../../../../../helpers/IntlMessages';
import { useIntl } from 'react-intl';
import CreditCardSelect from '../../../../common/CreditCardSelect';
import { getDocumentsFromCollection } from '../../../../../utils/firebase';

export default function PersonalPayment({
  payment,
  setPayment,
  isAllowed,
  getPayment,
}) {
  const { messages } = useIntl();
  const { user } = useSelector(({ auth }) => auth);

  const [loading, setLoading] = useState(true);
  const [creditCards, setCreditCards] = useState([]);

  const options = useMemo(
    () =>
      creditCards.map((creditCard) => ({
        value: creditCard.id,
        label: `Final ${creditCard.lastDigits}`,
        creditCard,
      })),
    [creditCards]
  );

  // Effects
  useEffect(() => {
    const loadPayments = async () => {
      try {
        setLoading(true);
        const payments = await getPayment(user.uid, () =>
          getDocumentsFromCollection(() =>
            firebaseApp.getCreditCardsFromUser(user.uid).get()
          )
        );
        setCreditCards(payments);
      } catch (err) {
        console.error('Unable to load payments', err);
      } finally {
        setLoading(false);
      }
    };

    loadPayments();
  }, [user]);

  useEffect(() => {
    if (!payment && options.length) {
      setPayment(options[0]);
    }
  }, [options]);

  // Render
  return (
    <div>
      <p className="description m-0 p-0 mb-3 text-warning">
        {
          messages[
            isAllowed
              ? 'flight-alteration.checkout.personal.title.allow'
              : 'flight-alteration.checkout.personal.title'
          ]
        }
      </p>
      <Label className="form-group search reservation has-top-label">
        <CreditCardSelect
          className="react-select"
          classNamePrefix="react-select"
          isClearable={false}
          placeholder=""
          value={payment}
          noOptionsMessage={() => {
            return messages['travel.booking.no-creditCard'];
          }}
          options={options}
          onChange={(selectedCard) => {
            setPayment(selectedCard);
          }}
          isLoading={loading}
        />
        <IntlMessages id="travel.booking.creditCard" />
      </Label>
    </div>
  );
}
