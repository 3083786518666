export const setupList = {
  id: 'setup',
  items: {
    search: 1,
    creditCard: 2,
    costCenter: 3,
    invite: 4,
  },
  links: {
    1: '/travel',
    2: '/app/finance/credit_cards',
    3: '/app/management',
    4: '/app/users',
  },
};
