import orderStatus from './orderStatus';

export const orderStatusMessageIds = {
  [orderStatus.START]: 'travel.order.status.timeline.reserving',
  [orderStatus.RESERVED]: 'travel.order.status.timeline.approval',
  [orderStatus.ISSUING]: 'travel.order.status.timeline.issuing',
  [orderStatus.ALTERING]: 'travel.order.status.timeline.altering',
  [orderStatus.MANUAL_ISSUING]: 'travel.order.status.timeline.manualissuing',
  [orderStatus.EXPIRED]: 'travel.order.status.timeline.expired',
  [orderStatus.CANCELLING]: 'travel.order.status.timeline.cancelling',
  [orderStatus.DENIED]: 'travel.order.status.timeline.denied',
  [orderStatus.CANCELLED]: 'travel.order.status.timeline.cancelled',
  [orderStatus.ERROR]: 'travel.order.status.timeline.error',
};

export const personalOrderStatusMessageIds = {
  ...orderStatusMessageIds,
  [orderStatus.RESERVED]: 'travel.order.status.timeline.toissue',
};

export const openBookingOrderStatusMessageIds = {
  ...orderStatusMessageIds,
  [orderStatus.ISSUING]: 'travel.order.status.timeline.open-booking.issuing',
  [orderStatus.MANUAL_ISSUING]:
    'travel.order.status.timeline.open-booking.issuing',
  [orderStatus.WAITING_ISSUE]:
    'travel.order.status.timeline.open-booking.waiting',
};
