import React, { useEffect, useMemo, useState } from 'react';
import NumberedComponent from '../../../NumberedComponent';
import { useIntl } from 'react-intl';
import { OpenBookingProvidersWebsites } from '../../../../constants/openBookingProviders';
import VirtualCard from '../../../expenses/VirtualCard';
import useDataArchive from '../../../../hooks/useDataArchive';
import useExecuteAction from '../../../../hooks/useExecuteAction';
import appFunctions from '../../../../services/Functions';
import { attachLink } from '../../../../utils/link';

export default function IssueStep({ order }) {
  const { messages } = useIntl();

  // Hooks
  const { getDataFromArchive } = useDataArchive();
  const { executeAction, loading } = useExecuteAction();

  // States
  const card = useMemo(
    () => ({
      icon: order.provider.associate,
      color: '#FFFFFF',
    }),
    [order]
  );
  const [cardInfo, setCardInfo] = useState(null);

  // Effects
  useEffect(() => {
    loadPan();

    attachLink(
      document.getElementById('issue-step-provider-link'),
      OpenBookingProvidersWebsites[order.provider.associate]
    );
  }, [order]);

  // Functions
  const loadPan = () => {
    executeAction(
      (async () => {
        try {
          const cardPan = await getDataFromArchive(`card-${order.id}`, () =>
            appFunctions
              .getExpenseCardPan(
                order.organizationId,
                order.openBookingInfo.cardId
              )
              .then(({ data }) => data)
          );

          setCardInfo(cardPan);
        } catch (err) {
          console.error(err);
        }
      })()
    );
  };

  // Render
  return (
    <div className="issue-step">
      <NumberedComponent index="1">
        <p
          className="m-0 p-0 fs-0-8rem"
          dangerouslySetInnerHTML={{
            __html: messages['open-booking.issue-steps.issue.title'],
          }}
        />
      </NumberedComponent>

      <NumberedComponent index="2" className="mt-4">
        <p className="m-0 p-0 fs-0-8rem">
          {messages['open-booking.issue-steps.use-card.title']}
        </p>
      </NumberedComponent>

      <div className="my-4 flex-center">
        <div className="card-container w-100">
          <VirtualCard
            card={card}
            showPan
            loadingPan={loading || !cardInfo}
            pan={cardInfo}
          />
        </div>
      </div>

      <NumberedComponent index="3" className="mt-4">
        <p className="m-0 p-0 fs-0-8rem">
          {messages['open-booking.issue-steps.attachment.title']}
        </p>
      </NumberedComponent>
    </div>
  );
}
