import React from 'react';
import {
  Font,
  Image,
  Page,
  Document,
  View,
  Text,
  StyleSheet,
} from '@react-pdf/renderer';
import icons from '../../../constants/pngIcons';
import { getCurrencyAndPrice, stringToMoney } from '../../../utils/money';
import MSRegular from '../../../assets/fonts/Montserrat-Regular.ttf';
import MSMedium from '../../../assets/fonts/Montserrat-Medium.ttf';
import MSSemiBold from '../../../assets/fonts/Montserrat-SemiBold.ttf';
import MSBold from '../../../assets/fonts/Montserrat-Bold.ttf';
import { getOrganizationConfig } from '../../../utils/organization';
import moment from 'moment';

Font.register({
  family: 'Montserrat',
  fonts: [
    { src: MSRegular },
    { src: MSMedium, fontWeight: 500 },
    { src: MSSemiBold, fontWeight: 600 },
    { src: MSBold, fontWeight: 700 },
  ],
});

const styles = StyleSheet.create({
  topHeader: {
    paddingHorizontal: 60,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  boldAndUpper: {
    textTransform: 'uppercase',
    fontWeight: 600,
  },
  logo: {
    width: 120,
    maxHeight: 55,
    objectFit: 'contain',
  },
  organizationName: {
    maxWidth: 500,
    marginBottom: 2,
    flexWrap: 'wrap',
  },
  table: {
    fontSize: 10,
    fontFamily: 'Montserrat',
    marginVertical: 30,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'nowrap',
    paddingVertical: 8,
    paddingHorizontal: 20,
  },
  rowHeader: {
    fontSize: 8,
    textTransform: 'uppercase',
    fontWeight: 600,
    paddingBottom: 0,
  },
  icon: {
    width: 15,
    height: 15,
    marginRight: 10,
    objectFit: 'contain',
  },
  passenger: { width: '20%' },
  reservation: {
    width: '25%',
    flexWrap: 'wrap',
    textAlign: 'center',
    paddingHorizontal: 10,
    fontSize: 8,
  },
  date: { width: '8%', textAlign: 'center' },
  smallDate: { fontSize: 8, paddingHorizontal: 3 },
  price: { width: '10%', textAlign: 'right', fontSize: 8 },
  approvers: { width: '20%', textAlign: 'center', fontSize: 8 },
});

export default function ReportsPDF(
  csv,
  organization,
  dateSelection,
  messages,
  isDetailed,
  pageSize = 'A4'
) {
  const [currencySymbol, _] = getCurrencyAndPrice(
    10,
    getOrganizationConfig(organization.config, 'currency')
  );

  const formatDate = (date, currentFormat = 'DD/MM/YYYY') => {
    return moment(date, currentFormat).format('DD MMM YY');
  };

  const totalIndex = isDetailed ? 2 : 1;

  return (
    <Document>
      <Page
        wrap={true}
        size={pageSize}
        style={{ paddingVertical: 40, fontFamily: 'Montserrat' }}
      >
        <View style={styles.topHeader}>
          <Image style={styles.logo} src="/assets/img/logo.png" />
          <View style={{ fontSize: 11, marginLeft: 10 }}>
            <Text style={[styles.boldAndUpper, styles.organizationName]}>
              {organization.name}
            </Text>
            {dateSelection.mode === 'all' ? (
              <Text style={styles.boldAndUpper}>
                {messages['reports.pdf.all-reservations']}
              </Text>
            ) : (
              <View style={{ display: 'flex', flexDirection: 'row' }}>
                <Text style={styles.boldAndUpper}>
                  {messages['reports.pdf.from']}
                </Text>
                <Text
                  style={{ marginHorizontal: 5, textTransform: 'uppercase' }}
                >
                  {moment.unix(dateSelection.startDate).format('DD MMM YYYY')}
                </Text>
                <Text style={styles.boldAndUpper}>
                  {messages['reports.pdf.to']}
                </Text>
                <Text
                  style={{ marginHorizontal: 5, textTransform: 'uppercase' }}
                >
                  {moment.unix(dateSelection.endDate).format('DD MMM YYYY')}
                </Text>
              </View>
            )}
          </View>
        </View>
        <View style={styles.table}>
          <View style={[styles.row, styles.rowHeader]}>
            <View style={[styles.icon, { backgroundColor: 'transparent' }]} />
            <Text style={styles.passenger}>
              {messages['reports.excel.passenger']}
            </Text>
            <Text style={styles.reservation}>
              {messages['reports.excel.reservation']}
            </Text>
            <Text style={styles.date}>
              {messages['reports.excel.short.creation-date']}
            </Text>
            <Text style={styles.date}>
              {messages['reports.excel.short.start-date']}
            </Text>
            <Text style={styles.date}>
              {messages['reports.excel.short.end-date']}
            </Text>
            {isDetailed ? (
              <Text style={styles.approvers}>
                {messages['reports.excel.approvers']}
              </Text>
            ) : null}
            <Text style={styles.price}>
              {messages['reports.excel.total']} ({currencySymbol})
            </Text>
          </View>
          {csv.map(
            (
              [
                type,
                passenger,
                reservation,
                bookingCode,
                costCenter,
                project,
                date,
                startDate,
                endDate,
                total,
                approvers,
              ],
              index
            ) => (
              <View
                style={{
                  ...styles.row,
                  backgroundColor:
                    index % 2 === 0 ? 'rgba(67, 65, 142, 0.03)' : 'white',
                }}
                key={index}
                wrap={false}
              >
                <Image
                  src={`/assets/img/icons/${icons[type]}`}
                  style={styles.icon}
                />
                <View style={styles.passenger}>
                  <Text style={{ flexWrap: 'wrap', fontWeight: 500 }}>
                    {passenger}
                  </Text>
                  <Text style={{ flexWrap: 'wrap', fontSize: 8, opacity: 0.5 }}>
                    {[costCenter, project].filter((value) => value).join(' | ')}
                  </Text>
                </View>
                <Text style={styles.reservation}>{`${reservation}${
                  bookingCode ? `\n(${bookingCode})` : ''
                }`}</Text>
                <Text style={[styles.date, styles.smallDate]}>{date}</Text>
                <Text style={[styles.date, styles.smallDate]}>
                  {formatDate(startDate)}
                </Text>
                <Text style={[styles.date, styles.smallDate]}>
                  {formatDate(endDate)}
                </Text>
                {isDetailed ? (
                  <View style={styles.approvers}>
                    {approvers.map((approver) => (
                      <Text key={approver}>• {approver}</Text>
                    ))}
                  </View>
                ) : null}
                <Text style={styles.price}>{total.toFixed(2)}</Text>
              </View>
            )
          )}
        </View>
        <View>
          <View
            style={{
              display: 'flex',
              alignItems: 'flex-end',
              paddingHorizontal: 30,
            }}
          >
            <Text style={{ fontSize: 22 }}>
              {stringToMoney(
                csv.reduce(
                  (sum, item) => sum + item[item.length - totalIndex],
                  0
                ),
                2,
                getOrganizationConfig(organization.config, 'currency')
              )}
            </Text>
            <Text
              style={{
                fontSize: 10,
                color: '#43418e',
                textTransform: 'uppercase',
                fontWeight: 700,
              }}
            >
              {messages['reports.total-value']}
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
}
