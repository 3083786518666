import firebase from 'firebase/compat/app';
import moment from 'moment';
import axios from 'axios';
import uniq from 'lodash/uniq';
import compact from 'lodash/compact';
import map from 'lodash/map';

import RecordStatus from '../constants/recordStatus';
import OrderStatus from '../constants/orderStatus';
import ApprovalStatus from '../constants/approvalStatus';
import NotificationStatus from '../constants/notificationStatus';
import { ActiveBudgetStatus, RefundStatus } from '../constants/refundStatus';
import recordStatus from '../constants/recordStatus';

import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/storage';
import { DEFAULT_CURRENCY } from '../utils/money';
import { expenseTypes, RefundTypes } from '../constants/refundTypes';
import { formatExpenseItems } from '../utils/expenses';

import { getPassengerInfo } from '../utils/firebase';
import { getOrganizationConfig } from '../utils/organization';
import { EmailStatus } from '../constants/emailStatus';
import { AncillaryStatus } from '../constants/ancillaryStatus';
import appFunctions from './Functions';

const firebaseApp = firebase.initializeApp(
  JSON.parse(process.env.REACT_APP_FIREBASE)
);

const auth = firebaseApp.auth();
const db = firebaseApp.firestore({
  experimentalForceLongPolling: true,
  useFetchStreams: false,
});

if (process.env.REACT_APP_EMULATOR === 'ON') {
  auth.useEmulator('http://127.0.0.1:9090');
  db.useEmulator('127.0.0.1', 8080);
}

const buildAxios = async () => {
  const headers = {};

  if (firebaseApp.isUserLoggedIn()) {
    const token = await auth.currentUser.getIdToken();
    headers['Authorization'] = `Bearer ${token}`;
  }

  return axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers,
  });
};

/*
 * Methods: User
 */
firebaseApp.isUserLoggedIn = function () {
  return auth.currentUser !== null;
};

firebaseApp.getCurrentUser = function () {
  return auth.currentUser;
};

/*
 * Methods: User
 */
firebaseApp.uploader = function () {
  return firebaseApp.storage('gs://portao3-avatar');
};

/*
 * Methods: Firestore
 */
firebaseApp.db = function () {
  return firebaseApp.firestore();
};

// Manipulators
firebaseApp.makeFlightStatusIdFromReservation = function (reservation) {
  return `${moment(reservation.flightDetails.outboundDate, 'DD/MM/YYYY').format(
    'YYYYMMDD'
  )}_${reservation.flightDetails.company}_${
    reservation.flightDetails.flightCode
  }`;
};

// Getters
firebaseApp.getUser = function () {
  return db.collection('users').doc(firebaseApp.getCurrentUser().uid);
};

firebaseApp.getUserFromId = function (userId) {
  return db.collection('users').doc(userId);
};

firebaseApp.getCreditCardsFromUser = function (userId) {
  return db
    .collection('users')
    .doc(userId)
    .collection('credit_cards')
    .where('status', '==', RecordStatus.ACTIVE);
};

firebaseApp.getExpenseCardsByIdFromOrganization = function (
  organizationId,
  cardId
) {
  return db
    .collection('organizations')
    .doc(organizationId)
    .collection('expenses_cards')
    .doc(cardId);
};

firebaseApp.getExpenseCardsByTypeFromOrganization = function (
  organizationId,
  type,
  onlyActive = true
) {
  const query = db
    .collection('organizations')
    .doc(organizationId)
    .collection('expenses_cards')
    .where('type', '==', type);

  return onlyActive ? query.where('status', '==', RecordStatus.ACTIVE) : query;
};

firebaseApp.getExpenseCardsFromUser = function (organizationId, userId) {
  return db
    .collection('organizations')
    .doc(organizationId)
    .collection('expenses_cards')
    .where('user_id', '==', userId);
};

firebaseApp.unassignUserFromExpenseCard = function (organizationId, cardId) {
  return db
    .collection('organizations')
    .doc(organizationId)
    .collection('expenses_cards')
    .doc(cardId)
    .update({
      user_id: null,
    });
};

firebaseApp.assignUserToExpenseCard = function (
  organizationId,
  cardId,
  userId
) {
  return db
    .collection('organizations')
    .doc(organizationId)
    .collection('expenses_cards')
    .doc(cardId)
    .update({
      user_id: userId,
    });
};

firebaseApp.getUsersFromOrganization = function (organizationId) {
  return db
    .collection('users')
    .where('organizationId', '==', organizationId)
    .where('status', '==', 'ACTIVE')
    .orderBy('firstName', 'asc');
};

firebaseApp.getApiKeysFromOrganization = function (organizationId) {
  return db
    .collection('users')
    .where('organizationId', '==', organizationId)
    .where('status', '==', 'APIKEY');
};

firebaseApp.getOrganizationFromId = function (organizationId) {
  return db.collection('organizations').doc(organizationId);
};

firebaseApp.getOrganizationKYCFromId = function (organizationId) {
  return db
    .collection('organizations')
    .doc(organizationId)
    .collection('kyc')
    .doc('initial');
};

firebaseApp.getInputedOrganizationKYCFromId = function (organizationId) {
  return db
    .collection('organizations')
    .doc(organizationId)
    .collection('kyc')
    .doc('inputed');
};

firebaseApp.setOrganizationKYCFromId = function (organizationId, payload) {
  return db
    .collection('organizations')
    .doc(organizationId)
    .collection('kyc')
    .doc('inputed')
    .set({ ...payload }, { merge: true });
};

firebaseApp.getCostCentersFromOrganization = function (
  organizationId,
  onlyActive = true
) {
  const query = db
    .collection('organizations')
    .doc(organizationId)
    .collection('cost_centers');

  return onlyActive ? query.where('status', '==', RecordStatus.ACTIVE) : query;
};

firebaseApp.getCostCentersFromId = function (organizationId, costCenterId) {
  return db
    .collection('organizations')
    .doc(organizationId)
    .collection('cost_centers')
    .doc(costCenterId);
};

firebaseApp.getPoliciesFromOrganization = function (organizationId) {
  return db
    .collection('organizations')
    .doc(organizationId)
    .collection('policies')
    .where('status', '==', RecordStatus.ACTIVE)
    .orderBy('sort', 'asc');
};

firebaseApp.getPolicyFromId = function (organizationId, policyId) {
  return db
    .collection('organizations')
    .doc(organizationId)
    .collection('policies')
    .doc(policyId);
};

firebaseApp.getMotivesFromOrganization = function (organizationId) {
  return db
    .collection('organizations')
    .doc(organizationId)
    .collection('motives')
    .where('status', '==', RecordStatus.ACTIVE);
};

firebaseApp.getMotiveFromId = function (organizationId, motiveId) {
  return db
    .collection('organizations')
    .doc(organizationId)
    .collection('motives')
    .doc(motiveId);
};

firebaseApp.getTagsFromOrganization = function (organizationId) {
  return db
    .collection('organizations')
    .doc(organizationId)
    .collection('tags')
    .where('status', '==', RecordStatus.ACTIVE);
};

firebaseApp.getTagFromId = function (organizationId, tagId) {
  return db
    .collection('organizations')
    .doc(organizationId)
    .collection('tags')
    .doc(tagId);
};

firebaseApp.getCommonFieldById = function (organizationId, fieldName, id) {
  return db
    .collection('organizations')
    .doc(organizationId)
    .collection(fieldName)
    .doc(id);
};

firebaseApp.getInvoicesFromOrganization = async function (organizationId) {
  const p3Axios = await buildAxios();

  try {
    const invoicesRes = await p3Axios.get(
      `/organizations/${organizationId}/invoices`
    );

    return invoicesRes.data;
  } catch (err) {
    console.error(err);
    throw new Error(err);
  }
};

firebaseApp.getInvoicePrint = async function (organizationId, invoiceId) {
  const p3Axios = await buildAxios();

  try {
    const invoice = await p3Axios.get(
      `/organizations/${organizationId}/invoices/${invoiceId}/print`,
      {
        responseType: 'arraybuffer',
      }
    );

    return invoice.data;
  } catch (err) {
    console.error(err);
    throw new Error(err);
  }
};

firebaseApp.getStatementPrint = async function (organizationId, invoiceId) {
  const p3Axios = await buildAxios();

  try {
    const invoice = await p3Axios.get(
      `/organizations/${organizationId}/invoices/${invoiceId}/statement`,
      {
        responseType: 'arraybuffer',
      }
    );

    return invoice.data;
  } catch (err) {
    console.error(err);
    throw new Error(err);
  }
};

firebaseApp.getUserHashAtlas = async function () {
  try {
    const axiosP3 = await buildAxios();

    const response = await axiosP3.get(`/user/atlas-token`);

    return response.data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

firebaseApp.getTransactionPrint = async function (
  organizationId,
  invoiceId,
  transactionId
) {
  const p3Axios = await buildAxios();

  try {
    const invoice = await p3Axios.get(
      `/organizations/${organizationId}/invoices/${invoiceId}/transactions/${transactionId}/print`,
      {
        responseType: 'arraybuffer',
      }
    );

    return invoice.data;
  } catch (err) {
    console.error(err);
    throw new Error(err);
  }
};

firebaseApp.getOfx = async function (organizationId, startDate, endDate) {
  const p3Axios = await buildAxios();

  try {
    const ofx = await p3Axios.get(
      `/organizations/${organizationId}/bank3/transactions/ofx`,
      {
        params: {
          startDate,
          endDate,
        },
        responseType: 'arraybuffer',
      }
    );

    return ofx.data;
  } catch (err) {
    throw new Error(err);
  }
};

firebaseApp.getCsv = async function (organizationId, startDate, endDate) {
  const p3Axios = await buildAxios();

  try {
    const csv = await p3Axios.get(
      `/organizations/${organizationId}/bank3/transactions/csv`,
      {
        params: {
          startDate,
          endDate,
        },
      }
    );

    return csv.data;
  } catch (err) {
    throw new Error(err);
  }
};

firebaseApp.getCreditCardsFromOrganization = function (organizationId) {
  return db
    .collection('organizations')
    .doc(organizationId)
    .collection('credit_cards')
    .where('status', '==', RecordStatus.ACTIVE);
};

firebaseApp.getCreditCardsFromOrganizationById = function (
  organizationId,
  cardId
) {
  return db
    .collection('organizations')
    .doc(organizationId)
    .collection('credit_cards')
    .doc(cardId);
};

firebaseApp.getBank3CardsFromOrganization = function (organizationId) {
  return db
    .collection('organizations')
    .doc(organizationId)
    .collection('credit_cards')
    .where('status', '==', RecordStatus.ACTIVE)
    .where('issuer', '==', 'BANK_3');
};

firebaseApp.getProjectsFromOrganization = function (
  organizationId,
  onlyActive = true
) {
  const query = db
    .collection('organizations')
    .doc(organizationId)
    .collection('projects');

  return onlyActive ? query.where('status', '==', RecordStatus.ACTIVE) : query;
};

firebaseApp.getProjectFromId = function (organizationId, projectId) {
  return db
    .collection('organizations')
    .doc(organizationId)
    .collection('projects')
    .doc(projectId);
};

firebaseApp.getRefundCategoriesFromOrganization = function (organizationId) {
  return db
    .collection('organizations')
    .doc(organizationId)
    .collection('refund_categories')
    .where('status', '==', RecordStatus.ACTIVE);
};

firebaseApp.getGroupsFromOrganization = function (organizationId) {
  return db
    .collection('organizations')
    .doc(organizationId)
    .collection('groups')
    .where('status', '==', recordStatus.ACTIVE);
};

firebaseApp.getGroupFromId = function (organizationId, groupId) {
  return db
    .collection('organizations')
    .doc(organizationId)
    .collection('groups')
    .doc(groupId);
};

firebaseApp.getInvitesFromOrganization = function (organizationId) {
  return db
    .collection('organizations')
    .doc(organizationId)
    .collection('invites');
};

firebaseApp.getOrdersFromOrganization = function (organizationId) {
  return db.collection('orders').where('organizationId', '==', organizationId);
};

firebaseApp.getCreditsOrdersFromOrganization = function (organizationId) {
  return db
    .collection('organizations')
    .doc(organizationId)
    .collection('travel_credits');
};

firebaseApp.getCreditsFromOrganizationAndUser = function (
  organizationId,
  passengerId,
  validatingCarrier
) {
  const data = db
    .collection('organizations')
    .doc(organizationId)
    .collection('travel_credits')
    .where('passengerId', '==', passengerId)
    .where('provider.validatingCarrier', '==', validatingCarrier)
    .where('wasUsed', '==', false);

  return data;
};

firebaseApp.getCreditsFromOrganizationAndUserAndCarrier = function (
  organizationId,
  passengerId,
  carrierCode,
  filterUsed
) {
  const query = db
    .collection('organizations')
    .doc(organizationId)
    .collection('travel_credits')
    .where('passengerId', '==', passengerId)
    .where('provider.validatingCarrier', '==', carrierCode);

  return filterUsed ? query.where('wasUsed', '==', false) : query;
};

firebaseApp.getOrdersFromOrganizationOnInterval = function (
  organizationId,
  startAt,
  endAt
) {
  return db
    .collection('orders')
    .where('organizationId', '==', organizationId)
    .where('createdAt', '>=', startAt)
    .where('createdAt', '<=', endAt);
};

firebaseApp.getPendingOrdersFromOrganization = function (organizationId) {
  return db
    .collection('orders')
    .where('organizationId', '==', organizationId)
    .where('statusCode', '>=', OrderStatus.RESERVED)
    .where('statusCode', '<', OrderStatus.ISSUED);
};

firebaseApp.getApprovalsFromOrderId = function (orderId) {
  return db.collection('orders').doc(orderId).collection('approvals');
};

firebaseApp.getApprovalsFromBudgetId = function (budgetId) {
  return db.collection('budgets').doc(budgetId).collection('budget_approvals');
};

firebaseApp.getNotificationsFromOrder = function (orderId) {
  return db
    .collection('orders')
    .doc(orderId)
    .collection('notifications')
    .where('status', '==', NotificationStatus.NEW);
};

firebaseApp.getNotificationsFromBudget = function (budgetId) {
  return db
    .collection('budgets')
    .doc(budgetId)
    .collection('notifications')
    .where('status', '==', NotificationStatus.NEW);
};

firebaseApp.setNotificationsFromOrderToRead = function (
  orderId,
  notificationId
) {
  return db
    .collection('orders')
    .doc(orderId)
    .collection('notifications')
    .doc(notificationId)
    .set({
      status: NotificationStatus.READ,
      readBy: firebaseApp.getCurrentUser().uid || '',
      readAt: moment().unix(),
    });
};

firebaseApp.setNotificationsFromBudgetToRead = function (
  budgetId,
  notificationId
) {
  return db
    .collection('budgets')
    .doc(budgetId)
    .collection('notifications')
    .doc(notificationId)
    .set({ status: NotificationStatus.READ });
};

firebaseApp.getPendingApprovalsFromUser = function (
  user = firebaseApp.getCurrentUser()
) {
  return db
    .collectionGroup('approvals')
    .where('userId', '==', user.uid)
    .where('status', '==', ApprovalStatus.PENDING);
};

firebaseApp.getOrderFromId = function (orderId) {
  return db.collection('orders').doc(orderId);
};

firebaseApp.getNextOrdersFromUser = function () {
  const startSearchUTC = moment().utc().startOf('day').unix();
  const startSearch = moment().startOf('day').unix();

  return db
    .collection('orders')
    .where('passengerId', '==', firebaseApp.getCurrentUser().uid)
    .where(
      'filterTimestamp',
      '>=',
      startSearchUTC < startSearch ? startSearchUTC : startSearch
    )
    .orderBy('filterTimestamp', 'asc');
};

firebaseApp.getNextOrdersFromUserId = function (organizationId, passengerId) {
  const startSearchUTC = moment().utc().startOf('day').unix();
  const startSearch = moment().startOf('day').unix();

  return db
    .collection('orders')
    .where('passengerId', '==', passengerId)
    .where('organizationId', '==', organizationId)
    .where(
      'filterTimestamp',
      '>=',
      startSearchUTC < startSearch ? startSearchUTC : startSearch
    )
    .orderBy('filterTimestamp', 'asc');
};

firebaseApp.getReservationsFromOrderId = function (organizationId, orderId) {
  return db
    .collection('orders')
    .doc(orderId)
    .collection('reservations')
    .where('organizationId', '==', organizationId);
};

firebaseApp.getReservationsofOrderIdFromPassengerId = function (
  orderId,
  passengerId,
  organizationId
) {
  return db
    .collection('orders')
    .doc(orderId)
    .collection('reservations')
    .where('passengerId', '==', passengerId)
    .where('organizationId', '==', organizationId);
};

firebaseApp.getReservationsFromUser = function () {
  return db
    .collectionGroup('reservations')
    .where('passengerId', '==', firebaseApp.getCurrentUser().uid);
};

firebaseApp.getNextReservationsFromOrganization = function (organizationId) {
  const startSearchUTC = moment().utc().startOf('day').unix();
  const startSearch = moment().startOf('day').unix();

  const stopSearch = moment().add(4, 'days').startOf('day').unix();

  return db
    .collectionGroup('reservations')
    .where('organizationId', '==', organizationId)
    .where(
      'filterTimestamp',
      '>=',
      startSearchUTC < startSearch ? startSearchUTC : startSearch
    )
    .where('filterTimestamp', '<=', stopSearch)
    .orderBy('filterTimestamp', 'asc');
};

firebaseApp.getNextOrdersFromOrganization = function (organizationId) {
  const startSearchUTC = moment().utc().startOf('day').unix();
  const startSearch = moment().startOf('day').unix();

  const stopSearch = moment().add(4, 'days').startOf('day').unix();

  return db
    .collection('orders')
    .where('organizationId', '==', organizationId)
    .where(
      'filterTimestamp',
      '>=',
      startSearchUTC < startSearch ? startSearchUTC : startSearch
    )
    .where('filterTimestamp', '<=', stopSearch)
    .orderBy('filterTimestamp', 'asc');
};

firebaseApp.getAirportFromIATA = function (iataCode) {
  return db.collection('locations').doc(iataCode);
};

firebaseApp.showLatLong = function (iata) {
  return firebaseApp.db().collection('locations').doc(iata);
};

firebaseApp.getStationFromClickbusId = function (locationId) {
  return db
    .collection('locations')
    .where('id', '==', parseInt(locationId))
    .where('source', '==', 'clickbus')
    .limit(1)
    .get();
};

firebaseApp.getNextReservationsFromUser = function () {
  const startSearchUTC = moment().utc().startOf('day').unix();
  const startSearch = moment().startOf('day').unix();

  return db
    .collectionGroup('reservations')
    .where('passengerId', '==', firebaseApp.getCurrentUser().uid)
    .where(
      'filterTimestamp',
      '>=',
      startSearchUTC < startSearch ? startSearchUTC : startSearch
    )
    .orderBy('filterTimestamp', 'asc');
};

firebaseApp.getNextReservationsFromUserId = function (
  organizationId,
  passengerId
) {
  const startSearchUTC = moment().utc().startOf('day').unix();
  const startSearch = moment().startOf('day').unix();

  return db
    .collectionGroup('reservations')
    .where('passengerId', '==', passengerId)
    .where('organizationId', '==', organizationId)
    .where(
      'filterTimestamp',
      '>=',
      startSearchUTC < startSearch ? startSearchUTC : startSearch
    )
    .orderBy('filterTimestamp', 'asc');
};

firebaseApp.getReservationsFromOrganizationIdAndDate = function (
  organizationId,
  startDate,
  endDate
) {
  return db
    .collectionGroup('reservations')
    .where('organizationId', '==', organizationId)
    .where('filterTimestamp', '>=', startDate)
    .where('filterTimestamp', '<=', endDate)
    .orderBy('filterTimestamp', 'asc');
};

firebaseApp.getOrdersFromOrganizationIdAndDate = function (
  organizationId,
  startDate,
  endDate
) {
  return db
    .collection('orders')
    .where('organizationId', '==', organizationId)
    .where('createdAt', '>=', startDate)
    .where('createdAt', '<=', endDate)
    .orderBy('createdAt', 'asc');
};

firebaseApp.getReservationFromId = function (reservationId) {
  return db.collectionGroup('reservations').doc(reservationId);
};

firebaseApp.getReservationsFromRequestorId = function (
  organizationId,
  requestorId = null
) {
  return db
    .collection('orders')
    .where('organizationId', '==', organizationId)
    .where(
      'requestorId',
      '==',
      requestorId || firebaseApp.getCurrentUser().uid
    );
};

firebaseApp.getNextFlightStatus = function () {
  return db.collection('flight_status');
};

firebaseApp.getFlightStatusFromId = function (flightStatusId) {
  return db.collection('flight_status').doc(flightStatusId);
};

firebaseApp.getLocationPhotos = function () {
  return db.collection('locations').where('photos.full', '>', '');
};

firebaseApp.getLocationFromId = function (locationId) {
  return db.collection('locations').doc(locationId);
};

firebaseApp.getLocationFromName = function (name) {
  return db.collection('locations').where('name', '==', name);
};

firebaseApp.getSearch = function (searchCode) {
  return db.collection('searches').doc(searchCode);
};

firebaseApp.getInitialFlightResults = function (searchCode) {
  return db
    .collection('searches')
    .doc(searchCode)
    .collection('results')
    .where('type', '==', 'flight')
    .orderBy('fare.total', 'asc')
    .limit(20);
};

firebaseApp.getRemainingFlightResults = function (searchCode, latestDoc) {
  return db
    .collection('searches')
    .doc(searchCode)
    .collection('results')
    .where('type', '==', 'flight')
    .orderBy('fare.total', 'asc')
    .startAfter(latestDoc);
};

firebaseApp.getInitialHotelResults = function (searchCode) {
  return db
    .collection('searches')
    .doc(searchCode)
    .collection('results')
    .where('type', '==', 'hotel')
    .orderBy('fare.total', 'asc')
    .limit(20);
};

firebaseApp.getHotelSearchFavorite = function (searchCode, hotelId) {
  return db
    .collection('searches')
    .doc(searchCode)
    .collection('results')
    .where('hotelId', '==', hotelId.toString());
};

firebaseApp.getRemainingHotelResults = function (searchCode, latestDoc) {
  return db
    .collection('searches')
    .doc(searchCode)
    .collection('results')
    .where('type', '==', 'hotel')
    .orderBy('fare.total', 'asc')
    .startAfter(latestDoc);
};

firebaseApp.getInitialCarResults = function (searchCode) {
  return db
    .collection('searches')
    .doc(searchCode)
    .collection('results')
    .where('type', '==', 'car')
    .orderBy('fare.total', 'asc')
    .limit(20);
};

firebaseApp.getRemainingCarResults = function (searchCode, latestDoc) {
  return db
    .collection('searches')
    .doc(searchCode)
    .collection('results')
    .where('type', '==', 'car')
    .orderBy('fare.total', 'asc')
    .startAfter(latestDoc);
};

firebaseApp.getInitialBusResults = function (searchCode) {
  return db
    .collection('searches')
    .doc(searchCode)
    .collection('results')
    .where('type', '==', 'bus')
    .orderBy('fare.total', 'asc')
    .limit(20);
};

firebaseApp.getRemainingBusResults = function (searchCode, latestDoc) {
  return db
    .collection('searches')
    .doc(searchCode)
    .collection('results')
    .where('type', '==', 'bus')
    .orderBy('fare.total', 'asc')
    .startAfter(latestDoc);
};

firebaseApp.getInitialOfficeResults = function (searchCode) {
  return db
    .collection('searches')
    .doc(searchCode)
    .collection('results')
    .where('type', '==', 'office')
    .orderBy('fare.total', 'asc')
    .limit(20);
};

firebaseApp.getRemainingOfficeResults = function (searchCode, latestDoc) {
  return db
    .collection('searches')
    .doc(searchCode)
    .collection('results')
    .where('type', '==', 'office')
    .orderBy('fare.total', 'asc')
    .startAfter(latestDoc);
};

firebaseApp.getPackageResults = function (searchCode) {
  return db.collection('searches').doc(searchCode).collection('packages');
};

firebaseApp.getSearchResults = function (searchCode) {
  return db
    .collection('searches')
    .doc(searchCode)
    .collection('results')
    .where('created_at', '>=', moment().subtract(30, 'minutes').unix());
};

firebaseApp.getSearchParams = function (searchCode) {
  return db.collection('searches').doc(searchCode).collection('parameters');
};

firebaseApp.getSearchHotelParams = function (searchCode) {
  return db
    .collection('searches')
    .doc(searchCode)
    .collection('parameters')
    .doc('hotel');
};

firebaseApp.getSearchFlightParams = function (searchCode) {
  return db
    .collection('searches')
    .doc(searchCode)
    .collection('parameters')
    .doc('flight');
};

firebaseApp.getSearchCarParams = function (searchCode) {
  return db
    .collection('searches')
    .doc(searchCode)
    .collection('parameters')
    .doc('car');
};

firebaseApp.getSearchBusParams = function (searchCode) {
  return db
    .collection('searches')
    .doc(searchCode)
    .collection('parameters')
    .doc('bus');
};

firebaseApp.getSearchOfficeParams = function (searchCode) {
  return db
    .collection('searches')
    .doc(searchCode)
    .collection('parameters')
    .doc('office');
};

firebaseApp.getCrisisReport = function () {
  return db.collection('crisis_report').orderBy('date', 'desc').limit(10);
};

firebaseApp.getReceiptFromOrderId = async function (orderId) {
  const p3Axios = await buildAxios();

  try {
    const response = await p3Axios.get(`/orders/${orderId}/print`, {
      responseType: 'arraybuffer',
    });

    return response.data;
  } catch (err) {
    return false;
  }
};

firebaseApp.getPaymentOptionsFromOrderAndCardId = async function ({
  orderId,
  cardId,
}) {
  const p3Axios = await buildAxios();

  try {
    const response = await p3Axios.get(`/orders/${orderId}/payments/${cardId}`);

    return response.data;
  } catch (err) {
    return false;
  }
};

firebaseApp.getChecklistFromUser = function (userId, checklistId) {
  return db
    .collection('users')
    .doc(userId)
    .collection('config')
    .doc(checklistId);
};

firebaseApp.getFlightCarbonEmission = async function (airports) {
  const p3Axios = await buildAxios();

  try {
    const response = await p3Axios.get(`/esg/flight`, {
      params: { airports },
    });

    return response.data;
  } catch (err) {
    return false;
  }
};

firebaseApp.getCarCarbonEmission = async function (sipp, days) {
  const p3Axios = await buildAxios();

  try {
    const response = await p3Axios.get(`/esg/car`, {
      params: { sipp, days },
    });

    return response.data;
  } catch (err) {
    return false;
  }
};

firebaseApp.getBusCarbonEmission = async function (coords) {
  const p3Axios = await buildAxios();

  try {
    const response = await p3Axios.get(`/esg/bus`, {
      params: { coords: coords.join('|') },
    });

    return response.data;
  } catch (err) {
    return false;
  }
};

firebaseApp.getOrganizationCarbonStatus = async function (organizationId) {
  const p3Axios = await buildAxios();

  try {
    const response = await p3Axios.get(`/organizations/${organizationId}/esg`);

    return response.data;
  } catch (err) {
    return false;
  }
};

firebaseApp.getCarbonPricing = async function (kgs) {
  const p3Axios = await buildAxios();

  try {
    const response = await p3Axios.get(`/esg/pricing`, {
      params: {
        kgs: kgs,
      },
    });

    return response.data;
  } catch (err) {
    return false;
  }
};

firebaseApp.makeCarbonPurchase = async function ({
  organizationId,
  kgs,
  creditCardId,
  amount,
}) {
  const p3Axios = await buildAxios();

  try {
    const response = await p3Axios.post(
      `/organizations/${organizationId}/esg`,
      {
        kgs,
        amount,
        creditCardId,
      }
    );

    return response.data;
  } catch (err) {
    return false;
  }
};

firebaseApp.downloadCarbonPurchase = async function (
  organizationId,
  invoiceId
) {
  const p3Axios = await buildAxios();

  try {
    const invoice = await p3Axios.get(
      `/organizations/${organizationId}/esg/${invoiceId}/print`,
      {
        responseType: 'arraybuffer',
      }
    );

    return invoice.data;
  } catch (err) {
    console.error(err);
    throw new Error(err);
  }
};

firebaseApp.getUserLanguage = function (userId = undefined) {
  const uid = userId || firebaseApp.getCurrentUser().uid;

  return db.collection('users').doc(uid).collection('config').doc('lang');
};

firebaseApp._getExpensesFromOrganization = function (organizationId) {
  return db
    .collection('expenses')
    .where('organizationId', '==', organizationId);
};

firebaseApp._getExpensesFromOrganizationOnInterval = function (
  organizationId,
  startAt,
  endAt
) {
  return db
    .collection('expenses')
    .where('organizationId', '==', organizationId)
    .where('createdAt', '>=', startAt)
    .where('createdAt', '<=', endAt);
};

firebaseApp.getExpensesFromUser = function (organizationId, passengerId) {
  return db
    .collection('expenses')
    .where('passengerId', '==', passengerId)
    .where('organizationId', '==', organizationId);
};

firebaseApp.getFavoritePlacesFromId = function (
  organizationId,
  favoritePlaceId
) {
  return db
    .collection('organizations')
    .doc(organizationId)
    .collection('favorite_places')
    .doc(favoritePlaceId);
};

firebaseApp.getFavoritePlacesFromOrganization = function (organizationId) {
  return db
    .collection('organizations')
    .doc(organizationId)
    .collection('favorite_places');
};

firebaseApp.getFavoriteHotelsFromOrganization = function (
  organizationId,
  onlyActive = true
) {
  const query = db
    .collection('organizations')
    .doc(organizationId)
    .collection('favorite_hotels');

  return onlyActive ? query.where('status', '==', 'ACTIVE') : query;
};

firebaseApp.getFavoriteHotelFromId = function (
  organizationId,
  favoriteHotelId
) {
  return db
    .collection('organizations')
    .doc(organizationId)
    .collection('favorite_hotels')
    .doc(favoriteHotelId);
};

firebaseApp.getBudgetsFromOrganization = function (
  organizationId,
  onlyActives = true
) {
  const query = db
    .collection('organizations')
    .doc(organizationId)
    .collection('budgets');
  return onlyActives ? query.where('status', '==', recordStatus.ACTIVE) : query;
};

firebaseApp.getExpensesCardsFromOrganization = function (
  organizationId,
  onlyActives = true
) {
  const query = db
    .collection('organizations')
    .doc(organizationId)
    .collection('expenses_cards');
  return onlyActives ? query.where('status', '==', recordStatus.ACTIVE) : query;
};

firebaseApp.getBudgetByIdFromOrganization = function (
  organizationId,
  budgetId
) {
  return db
    .collection('organizations')
    .doc(organizationId)
    .collection('budgets')
    .doc(budgetId);
};

firebaseApp.getAvailableRefundsFromUser = function (
  organizationId,
  passengerId
) {
  return db
    .collection('refunds')
    .where('passengerId', '==', passengerId)
    .where('organizationId', '==', organizationId)
    .where('expenseId', '==', null);
};

firebaseApp.getExpensesFromRefund = function (refundId, organizationId) {
  return db
    .collection('expenses')
    .where('refundId', '==', refundId)
    .where('organizationId', '==', organizationId);
};

firebaseApp.getRefundsFromUser = function (
  organizationId,
  passengerId,
  cardId
) {
  const collectionRef = db
    .collection('refunds')
    .where('passengerId', '==', passengerId)
    .where('organizationId', '==', organizationId);

  return cardId ? collectionRef.where('cardId', '==', cardId) : collectionRef;
};

firebaseApp.getBudgetsFromUser = function (
  organizationId,
  passengerId,
  cardId
) {
  const collectionRef = db
    .collection('budgets')
    .where('passengerId', '==', passengerId)
    .where('organizationId', '==', organizationId)
    .where('statusCode', '!=', RefundStatus.DISMISSED);

  return cardId ? collectionRef.where('cardId', '==', cardId) : collectionRef;
};

firebaseApp.getAllBudgetsFromCard = function (
  organizationId,
  passengerId,
  cardId
) {
  const query = db
    .collection('budgets')
    .where('organizationId', '==', organizationId)
    .where('statusCode', '!=', RefundStatus.DISMISSED)
    .where('cardId', '==', cardId);

  return passengerId ? query.where('passengerId', '==', passengerId) : query;
};

firebaseApp.getExpenseBudgetsFromOrganization = function (organizationId) {
  return db.collection('budgets').where('organizationId', '==', organizationId);
};

firebaseApp.getBudgetById = function (budgetId) {
  return db.collection('budgets').doc(budgetId);
};

firebaseApp.getItemsFromBudget = function (budgetId) {
  return db.collection('budgets').doc(budgetId).collection('items');
};

firebaseApp.getItemFromBudget = function (budgetId, itemId) {
  return db.collection('budgets').doc(budgetId).collection('items').doc(itemId);
};

firebaseApp.getRefundsFromOrganization = function (organizationId) {
  return db.collection('refunds').where('organizationId', '==', organizationId);
};

firebaseApp.getRefundFromId = function (refundId) {
  return db.collection('refunds').doc(refundId);
};

firebaseApp.getApprovalsFromBudget = function (budgetId) {
  return db.collection('budgets').doc(budgetId).collection('budget_approvals');
};

firebaseApp.getTollsFromPolyline = async function (polyline) {
  const p3Axios = await buildAxios();

  try {
    const tollsRes = await p3Axios.post(`/tolls/search`, { polyline });

    return tollsRes.data;
  } catch (err) {
    console.error(err);
    throw new Error(err);
  }
};

firebaseApp._getExpensesWithStatusCode = function (organizationId, statusCode) {
  return db
    .collection('expenses')
    .where('organizationId', '==', organizationId)
    .where('statusCode', '==', statusCode);
};

firebaseApp.deleteCostCenterFromOrganizationAndId = function (
  organizationId,
  costCenterId
) {
  return db
    .collection('organizations')
    .doc(organizationId)
    .collection('cost_centers')
    .doc(costCenterId)
    .set(
      {
        status: RecordStatus.DELETED,
        deleted_at: moment().unix(),
      },
      { merge: true }
    );
};

firebaseApp.getHotelsFromLocationInterval = function (locationIterval) {
  const { lat, lng } = locationIterval;

  return db
    .collection('hotels')
    .where('localization.lat', '>=', lat.min)
    .where('localization.lat', '<=', lat.max);
};

firebaseApp.getPendingRefundApprovalsFromUser = function (userId) {
  return db
    .collectionGroup('refund_approvals')
    .where('userId', '==', userId)
    .where('status', '==', ApprovalStatus.PENDING);
};

firebaseApp.getPendingBudgetApprovalsFromUser = function (
  user = firebaseApp.getCurrentUser()
) {
  return db
    .collectionGroup('budget_approvals')
    .where('userId', '==', user.uid)
    .where('status', '==', ApprovalStatus.PENDING);
};

firebaseApp.getExpenseFromId = function (expenseId) {
  return db.collection('expenses').doc(expenseId);
};

firebaseApp.getHotelFromId = function (hotelId) {
  return db.collection('hotels').doc(hotelId);
};

firebaseApp.getUserExpenseCard = function (organizationId, userId) {
  return db
    .collection('organizations')
    .doc(organizationId)
    .collection('expenses_cards')
    .where('user_id', '==', userId)
    .where('status', '==', 'ACTIVE');
};

firebaseApp.getExpenseCardFromId = function (organizationId, cardId) {
  return db
    .collection('organizations')
    .doc(organizationId)
    .collection('expenses_cards')
    .doc(cardId);
};

firebaseApp.getExpenseCardFromExternalId = function (organizationId, cardId) {
  return db
    .collection('organizations')
    .doc(organizationId)
    .collection('expenses_cards')
    .where('external_id', '==', cardId);
};

firebaseApp.updateExpenseCardUserId = function (
  organizationId,
  cardId,
  user_id
) {
  return db
    .collection('organizations')
    .doc(organizationId)
    .collection('expenses_cards')
    .doc(cardId)
    .update({
      user_id,
    });
};

firebaseApp.getActiveBudgetsFromPassenger = function (
  passengerId,
  currentDate
) {
  return db
    .collection('refunds')
    .where('passengerId', '==', passengerId)
    .where('endDate', '>=', currentDate);
};

firebaseApp.getOrderAlternatives = function (orderId) {
  return db.collection('orders').doc(orderId).collection('alternatives');
};

firebaseApp.getSearchAlternatives = function (searchId, fareTotal) {
  return db
    .collection('searches')
    .doc(searchId)
    .collection('results')
    .where('fare.total', '<=', fareTotal);
};

firebaseApp.getCardsFromOrganization = function (
  organizationId,
  status = recordStatus.ACTIVE
) {
  return db
    .collection('organizations')
    .doc(organizationId)
    .collection('expenses_cards')
    .where('status', '==', status);
};

firebaseApp.getCardsFromUser = function (userId, organizationId, status) {
  return db
    .collection('organizations')
    .doc(organizationId)
    .collection('expenses_cards')
    .where('status', '==', status)
    .where('user_id', '==', userId);
};

firebaseApp.getCardsFromUsers = function (organizationId, users = []) {
  return db
    .collection('organizations')
    .doc(organizationId)
    .collection('expenses_cards')
    .where('status', '==', recordStatus.ACTIVE)
    .where('user_id', 'in', users);
};

firebaseApp.getTicketsFromOrganization = async function (
  organizationId,
  startDate,
  endDate
) {
  const p3Axios = await buildAxios();

  try {
    const ticketsRes = await p3Axios.get(
      `/organizations/${organizationId}/tickets`,
      {
        params: {
          startDate,
          endDate,
        },
      }
    );

    return ticketsRes.data;
  } catch (err) {
    console.error(err);
    throw new Error(err);
  }
};

firebaseApp.getInboxMessagesFromOrganization = (organizationId) => {
  return db.collection('organizations').doc(organizationId).collection('inbox');
};

firebaseApp.getPersonalAccountFromUser = (organizationId, userId) => {
  return db
    .collection('organizations')
    .doc(organizationId)
    .collection('expenses_cards')
    .where('user_id', '==', userId)
    .where('type', '==', RefundTypes.PERSONAL_ACCOUNT)
    .where('status', '==', RecordStatus.ACTIVE);
};

firebaseApp.getAirPortInfo = (iataCode) => {
  return db.collection('locations').where('iata', '==', iataCode);
};

firebaseApp.getBudgetsFromCard = (userId, cardId) => {
  return db
    .collection('budgets')
    .where('passengerId', '==', userId)
    .where('cardId', '==', cardId)
    .where('statusCode', 'in', [RefundStatus.ACTIVE, RefundStatus.REVIEW]);
};

firebaseApp.getAncillariesFromOrder = (orderId, onlyActive = true) => {
  const ref = db.collection('orders').doc(orderId).collection('ancillaries');

  return onlyActive
    ? ref.where('statusCode', '<', AncillaryStatus.CANCELLED)
    : ref;
};

firebaseApp.getBookedAncillariesFromOrder = (orderId) => {
  return db
    .collection('orders')
    .doc(orderId)
    .collection('ancillaries')
    .where('statusCode', '==', AncillaryStatus.BOOKED)
    .limit(1);
};

firebaseApp.getApprovedBudgetsFromPeriod = (organizationId, days = 7) => {
  const today = moment();
  const start = today.format('YYYY-MM-DD');
  const end = today.add(days, 'days').format('YYYY-MM-DD');

  return db
    .collection('budgets')
    .where('organizationId', '==', organizationId)
    .where('statusCode', '==', RefundStatus.ANTICIPATION_APPROVED)
    .where('startDate', '>=', start)
    .where('startDate', '<=', end);
};

firebaseApp.getSupplierByCNPJ = (cnpj) => {
  return db.collection('suppliers').doc(cnpj);
};

firebaseApp.getBudgetsFromTimeInterval = (organizationId, startAt, endAt) => {
  return db
    .collection('budgets')
    .where('organizationId', '==', organizationId)
    .where('statusCode', 'in', [
      RefundStatus.ANTICIPATION_APPROVED,
      RefundStatus.APPROVED,
    ]);
};

firebaseApp.getApprovedBudgetsFromOrganization = (organizationId) => {
  return db
    .collection('budgets')
    .where('organizationId', '==', organizationId)
    .where('statusCode', 'in', [
      RefundStatus.ANTICIPATION_APPROVED,
      RefundStatus.APPROVED,
    ]);
};

firebaseApp.getFulfilledBudgetsFromPeriod = (
  organizationId,
  startAt,
  endAt
) => {
  return db
    .collection('budgets')
    .where('organizationId', '==', organizationId)
    .where('wasApproved', '==', true)
    .where('filterTimestamp', '>=', startAt)
    .where('filterTimestamp', '<=', endAt);
};

firebaseApp.getBudgetItemsWithSuppliersFromOrganization = (
  organizationId,
  startAt,
  endAt
) => {
  return db
    .collectionGroup('items')
    .where('organizationId', '==', organizationId)
    .where('date', '>=', startAt)
    .where('date', '<=', endAt);
};

firebaseApp.getSupplierById = (supplierId) => {
  return db.collection('suppliers').doc(supplierId);
};

firebaseApp.getFidelitiesFromUser = (passengerId, onlyActive = true) => {
  const query = db
    .collection('users')
    .doc(passengerId)
    .collection('fidelities');
  return onlyActive ? query.where('status', '==', RecordStatus.ACTIVE) : query;
};

firebaseApp.getIssuedOrdersByTypeAndFilterTimestamp = (
  organizationId,
  passengerId,
  type,
  startTimestamp,
  endTimestamp
) => {
  const query = db
    .collection('orders')
    .where('organizationId', '==', organizationId)
    .where('passengerId', '==', passengerId)
    .where('type', '==', type)
    .where('statusCode', '==', OrderStatus.ISSUED)
    .where('filterTimestamp', '>=', startTimestamp);

  return endTimestamp
    ? query.where('filterTimestamp', '<=', endTimestamp)
    : query;
};

firebaseApp.getReservationsInFilterTimestampInterval = (
  organizationId,
  passengerId,
  type,
  startTimestamp,
  endTimestamp
) => {
  const query = db
    .collectionGroup('reservations')
    .where('organizationId', '==', organizationId)
    .where('passengerId', '==', passengerId)
    .where('type', '==', type)
    .where('filterTimestamp', '>=', startTimestamp);

  return endTimestamp
    ? query.where('filterTimestamp', '<=', endTimestamp)
    : query;
};

firebaseApp.getInActionBudgetsFromCardId = (organizationId, cardId) => {
  return db
    .collection('budgets')
    .where('organizationId', '==', organizationId)
    .where('cardId', '==', cardId)
    .where('statusCode', 'in', ActiveBudgetStatus);
};

firebaseApp.getRecurrenceBudgets = (organizationId, passengerId, status) => {
  return db
    .collection('recurrence_budgets')
    .where('organizationId', '==', organizationId)
    .where('passengerId', '==', passengerId)
    .where('status', '==', status);
};

firebaseApp.getRecurrenceBudgetById = (budgetId) => {
  return db.collection('recurrence_budgets').doc(budgetId);
};

firebaseApp.getHistoryFromRecurrenceBudget = (
  budgetId,
  organizationId,
  passengerId
) => {
  return db
    .collection('recurrence_budgets')
    .doc(budgetId)
    .collection('recurrence_history')
    .where('organizationId', '==', organizationId)
    .where('passengerId', '==', passengerId);
};

firebaseApp.getLastIssueTaskFromOrder = (orderId) => {
  return db
    .collection('orders')
    .doc(orderId)
    .collection('status_history')
    .where('task', 'in', 'ISSUE')
    .where('processed', '==', true)
    .orderBy('created_at', 'DESC')
    .limit(1);
};

firebaseApp.getLastIssueTasksFromOrder = (orderId) => {
  return db
    .collection('orders')
    .doc(orderId)
    .collection('status_history')
    .where('task', 'in', ['ISSUE', 'MANUAL_ISSUE'])
    .where('processed', '==', true);
};

firebaseApp.getOrganizationDefaultSearchParams = (organizationId) => {
  return db
    .collection('organizations')
    .doc(organizationId)
    .collection('default_search_params');
};

// Setters
firebaseApp.updateUser = function (payload) {
  if (firebaseApp.isUserLoggedIn()) {
    const userId = firebaseApp.getCurrentUser().uid;

    return db.collection('users').doc(userId).set(payload, { merge: true });
  }
};

firebaseApp.updateUserFromId = function (
  userId,
  payload,
  withUpdate = false,
  params = {}
) {
  const docRef = db.collection('users').doc(userId);

  try {
    withUpdate ? docRef.update(payload) : docRef.set(payload, { merge: true });

    appFunctions.logger(params);
  } catch (error) {
    appFunctions.logger({ body: error, origin: 'CLIENT', message: 'Error' });
  }
};

firebaseApp.setNewUserData = function (payload) {
  return db.collection('users').doc(payload.uid).set(payload);
};

firebaseApp.updateUserAvatar = function (avatar) {
  if (firebaseApp.isUserLoggedIn()) {
    const userId = firebaseApp.getCurrentUser().uid;

    return db.collection('users').doc(userId).set({ avatar }, { merge: true });
  }
};

firebaseApp.createCreditCardForUser = async function (userId, payload) {
  const p3Axios = await buildAxios();

  try {
    await p3Axios.post(`/users/${userId}/credit-cards`, { ...payload });

    return true;
  } catch (err) {
    return false;
  }
};

firebaseApp.deleteCreditCardsFromUserAndId = async function (
  userId,
  creditCardId
) {
  const p3Axios = await buildAxios();

  try {
    await p3Axios.delete(`/users/${userId}/credit-cards/${creditCardId}`);

    return true;
  } catch (err) {
    return false;
  }
};

firebaseApp.createInvitesFromOrganization = function (organizationId, email) {
  return db
    .collection('organizations')
    .doc(organizationId)
    .collection('invites')
    .add({
      email,
      created_at: moment().unix(),
    });
};

firebaseApp.removeInvitesFromOrganization = function (
  organizationId,
  inviteId
) {
  return db
    .collection('organizations')
    .doc(organizationId)
    .collection('invites')
    .doc(inviteId)
    .delete();
};

firebaseApp.createCostCenterFromOrganization = function (
  organizationId,
  payload
) {
  return db
    .collection('organizations')
    .doc(organizationId)
    .collection('cost_centers')
    .add({
      ...payload,
      status: RecordStatus.ACTIVE,
      created_at: moment().unix(),
    });
};

firebaseApp.updateCostCenterFromOrganization = function (
  organizationId,
  costCenterId,
  payload
) {
  return db
    .collection('organizations')
    .doc(organizationId)
    .collection('cost_centers')
    .doc(costCenterId)
    .set(
      {
        ...payload,
        status: RecordStatus.ACTIVE,
        updated_at: moment().unix(),
      },
      { merge: true }
    );
};

firebaseApp.createMotiveFromOrganization = function (organizationId, payload) {
  return db
    .collection('organizations')
    .doc(organizationId)
    .collection('motives')
    .add({
      ...payload,
      status: RecordStatus.ACTIVE,
      created_at: moment().unix(),
    });
};

firebaseApp.updateMotiveFromOrganization = function (
  organizationId,
  motiveId,
  payload
) {
  return db
    .collection('organizations')
    .doc(organizationId)
    .collection('motives')
    .doc(motiveId)
    .set(
      {
        ...payload,
        status: RecordStatus.ACTIVE,
        updated_at: moment().unix(),
      },
      { merge: true }
    );
};

firebaseApp.deleteMotiveFromOrganizationAndId = function (
  organizationId,
  motiveId
) {
  return db
    .collection('organizations')
    .doc(organizationId)
    .collection('motives')
    .doc(motiveId)
    .set(
      {
        status: RecordStatus.DELETED,
        deleted_at: moment().unix(),
      },
      { merge: true }
    );
};

firebaseApp.createTagFromOrganization = function (organizationId, payload) {
  return db
    .collection('organizations')
    .doc(organizationId)
    .collection('tags')
    .add({
      ...payload,
      status: RecordStatus.ACTIVE,
      created_at: moment().unix(),
    });
};

firebaseApp.updateTagFromOrganization = function (
  organizationId,
  tagId,
  payload
) {
  return db
    .collection('organizations')
    .doc(organizationId)
    .collection('tags')
    .doc(tagId)
    .set(
      {
        ...payload,
        status: RecordStatus.ACTIVE,
        updated_at: moment().unix(),
      },
      { merge: true }
    );
};

firebaseApp.deleteTagFromOrganizationAndId = function (organizationId, tagId) {
  return db
    .collection('organizations')
    .doc(organizationId)
    .collection('tags')
    .doc(tagId)
    .set(
      {
        status: RecordStatus.DELETED,
        deleted_at: moment().unix(),
      },
      { merge: true }
    );
};

firebaseApp.createPolicyFromOrganization = function (organizationId, payload) {
  return db
    .collection('organizations')
    .doc(organizationId)
    .collection('policies')
    .add({
      ...payload,
      status: RecordStatus.ACTIVE,
      created_at: moment().unix(),
    });
};

firebaseApp.editPolicyFromOrganization = function (
  organizationId,
  policyId,
  payload
) {
  return db
    .collection('organizations')
    .doc(organizationId)
    .collection('policies')
    .doc(policyId)
    .set(
      {
        ...payload,
        status: RecordStatus.ACTIVE,
        updated_at: moment().unix(),
      },
      { merge: true }
    );
};

firebaseApp.deletePolicyFromOrganizationAndId = function (
  organizationId,
  policyId
) {
  return db
    .collection('organizations')
    .doc(organizationId)
    .collection('policies')
    .doc(policyId)
    .set(
      {
        status: RecordStatus.DELETED,
        deleted_at: moment().unix(),
      },
      { merge: true }
    );
};

firebaseApp.createProjectFromOrganization = function (organizationId, payload) {
  return db
    .collection('organizations')
    .doc(organizationId)
    .collection('projects')
    .add({
      ...payload,
      status: RecordStatus.ACTIVE,
      created_at: moment().unix(),
    });
};

firebaseApp.updateProjectFromOrganization = function (
  organizationId,
  projectId,
  payload
) {
  return db
    .collection('organizations')
    .doc(organizationId)
    .collection('projects')
    .doc(projectId)
    .set(
      {
        ...payload,
        status: RecordStatus.ACTIVE,
        updated_at: moment().unix(),
      },
      { merge: true }
    );
};

firebaseApp.createCategoryFromOrganization = function (
  organizationId,
  payload
) {
  return db
    .collection('organizations')
    .doc(organizationId)
    .collection('refund_categories')
    .add({
      ...payload,
      status: RecordStatus.ACTIVE,
      created_at: moment().unix(),
    });
};

firebaseApp.updateCategoryFromOrganization = function (
  organizationId,
  categoryId,
  payload
) {
  return db
    .collection('organizations')
    .doc(organizationId)
    .collection('refund_categories')
    .doc(categoryId)
    .set(
      {
        ...payload,
        status: RecordStatus.ACTIVE,
        updated_at: moment().unix(),
      },
      { merge: true }
    );
};

firebaseApp.createGroupFromOrganization = function (organizationId, payload) {
  return db
    .collection('organizations')
    .doc(organizationId)
    .collection('groups')
    .add({
      ...payload,
      users: uniq(compact(payload.users)),
      status: RecordStatus.ACTIVE,
      created_at: moment().unix(),
    });
};

firebaseApp.createCreditCardFromOrganization = async function (
  organizationId,
  payload
) {
  const p3Axios = await buildAxios();

  try {
    await p3Axios.post(`/organizations/${organizationId}/credit-cards`, {
      ...payload,
    });

    return true;
  } catch (err) {
    console.error(err);
    throw new Error(err);
  }
};

firebaseApp.updateCreditCardFromOrganization = async function (
  organizationId,
  creditCardId,
  payload
) {
  const p3Axios = await buildAxios();

  try {
    await p3Axios.patch(
      `/organizations/${organizationId}/credit-cards/${creditCardId}`,
      { ...payload }
    );

    return true;
  } catch (err) {
    console.error(err);
    throw new Error(err);
  }
};

firebaseApp.deleteCreditCardsFromOrganizationAndId = async function (
  organizationId,
  creditCardId
) {
  const p3Axios = await buildAxios();

  try {
    await p3Axios.delete(
      `/organizations/${organizationId}/credit-cards/${creditCardId}`
    );

    return true;
  } catch (err) {
    return false;
  }
};

firebaseApp.createBank3FromOrganization = async function (
  organizationId,
  payload
) {
  const p3Axios = await buildAxios();

  try {
    await p3Axios.post(`/organizations/${organizationId}/bank3`, payload);

    return true;
  } catch (err) {
    console.error(err);
    throw new Error(err);
  }
};

firebaseApp.getBank3Balance = async function (organizationId) {
  const p3Axios = await buildAxios();

  try {
    const balance = await p3Axios.get(
      `/organizations/${organizationId}/bank3/balance`
    );

    return balance.data;
  } catch (err) {
    console.error(err);
    throw new Error(err);
  }
};

firebaseApp.getBank3Transactions = async function (
  organizationId,
  startDate,
  endDate
) {
  const p3Axios = await buildAxios();

  try {
    const transactions = await p3Axios.get(
      `/organizations/${organizationId}/bank3/transactions`,
      {
        params: {
          startDate,
          endDate,
        },
      }
    );

    return transactions.data;
  } catch (err) {
    console.error(err);
    throw new Error(err);
  }
};

firebaseApp.printBank3Transactions = async function (
  organizationId,
  startDate,
  endDate
) {
  const p3Axios = await buildAxios();

  try {
    const transactions = await p3Axios.get(
      `/organizations/${organizationId}/bank3/transactions/print`,
      {
        params: {
          startDate,
          endDate,
        },
        responseType: 'arraybuffer',
      }
    );

    return transactions.data;
  } catch (err) {
    console.error(err);
    throw new Error(err);
  }
};

firebaseApp.updateGroupFromOrganizationAndId = function (
  organizationId,
  groupId,
  payload
) {
  return db
    .collection('organizations')
    .doc(organizationId)
    .collection('groups')
    .doc(groupId)
    .set(
      {
        ...payload,
        users: uniq(compact(payload.users)),
        updated_at: moment().unix(),
      },
      { merge: true }
    );
};

firebaseApp.deleteProjectFromOrganizationAndId = function (
  organizationId,
  projectId
) {
  return db
    .collection('organizations')
    .doc(organizationId)
    .collection('projects')
    .doc(projectId)
    .set(
      {
        status: RecordStatus.DELETED,
        deleted_at: moment().unix(),
      },
      { merge: true }
    );
};

firebaseApp.deleteRefundCategoryFromOrganizationAndId = function (
  organizationId,
  categoryId
) {
  return db
    .collection('organizations')
    .doc(organizationId)
    .collection('refund_categories')
    .doc(categoryId)
    .set(
      {
        status: RecordStatus.DELETED,
        deleted_at: moment().unix(),
      },
      { merge: true }
    );
};

firebaseApp.deleteGroupFromOrganizationAndId = function (
  organizationId,
  groupId
) {
  return db
    .collection('organizations')
    .doc(organizationId)
    .collection('groups')
    .doc(groupId)
    .set(
      {
        status: RecordStatus.DELETED,
        deleted_at: moment().unix(),
      },
      { merge: true }
    );
};

firebaseApp.createSearch = function (searchId, searchData) {
  if (firebaseApp.isUserLoggedIn()) {
    return db
      .collection('searches')
      .doc(searchId)
      .set(searchData, { merge: true });
  }
};

firebaseApp.createItinerary = function (searchId, searchData) {
  if (firebaseApp.isUserLoggedIn()) {
    return db
      .collection('itineraries')
      .doc(searchId)
      .set(searchData, { merge: true });
  }
};

firebaseApp.createOrder = async function ({
  organizationId,
  passenger,
  passengerId,
  orderDetails,
  filterTimestamp,
  type,
}) {
  delete orderDetails.passenger;
  delete orderDetails.passengerTimeline;

  return await db.collection('orders').add({
    ...orderDetails,
    organizationId,
    passengerId,
    passengerInfo: getPassengerInfo(passenger, true),
    filterTimestamp,
    createdAt: moment().unix(),
    type,
    statusCode: OrderStatus.START,
    requestorId: firebaseApp.getCurrentUser().uid,
  });
};

firebaseApp.createFlightReservation = async function ({
  organizationId,
  passenger,
  passengerId,
  orderDetails,
  reservations,
  filterTimestamp,
  type,
}) {
  if (firebaseApp.isUserLoggedIn()) {
    const orderRef = await firebaseApp.createOrder({
      organizationId,
      passenger,
      passengerId,
      orderDetails,
      filterTimestamp,
      type,
    });

    const promises = map(reservations, (reservation) => {
      return orderRef.collection('reservations').add({
        type,
        organizationId,
        passengerId,
        flightDetails: reservation,
        filterTimestamp: moment(reservation.arrivalDateTime, 'DD/MM/YYYY HH:mm')
          .endOf('day')
          .utc()
          .unix(),
        orderTimestamp: moment(
          reservation.departureDateTime,
          'DD/MM/YYYY HH:mm'
        )
          .utc()
          .unix(),
      });
    });

    await Promise.all(promises);

    await firebaseApp.requestToReservation({
      orderId: orderRef.id,
    });

    return orderRef.id;
  }
};

firebaseApp.createHotelReservation = async function ({
  organizationId,
  passenger,
  passengerId,
  orderDetails,
  reservations,
  filterTimestamp,
  type = 'hotel',
}) {
  if (firebaseApp.isUserLoggedIn()) {
    const orderRef = await firebaseApp.createOrder({
      organizationId,
      passenger,
      passengerId,
      orderDetails,
      filterTimestamp,
      type,
    });

    const promises = map(reservations, (reservation) => {
      return orderRef.collection('reservations').add({
        type,
        organizationId,
        passengerId,
        hotelDetails: reservation,
        filterTimestamp: moment(reservation.checkIn, 'DD/MM/YYYY')
          .endOf('day')
          .utc()
          .unix(),
        orderTimestamp: moment(reservation.checkOut, 'DD/MM/YYYY').utc().unix(),
      });
    });

    await Promise.all(promises);

    await firebaseApp.requestToReservation({ orderId: orderRef.id });

    return orderRef.id;
  }
};

firebaseApp.createCarReservation = async function ({
  organizationId,
  passenger,
  passengerId,
  orderDetails,
  reservations,
  filterTimestamp,
  type,
}) {
  if (firebaseApp.isUserLoggedIn()) {
    const orderRef = await firebaseApp.createOrder({
      organizationId,
      passenger,
      passengerId,
      orderDetails,
      filterTimestamp,
      type,
    });

    const promises = map(reservations, (reservation) => {
      return orderRef.collection('reservations').add({
        type,
        organizationId,
        passengerId,
        carDetails: reservation,
        filterTimestamp: moment(reservation.pickUp, 'DD/MM/YYYY')
          .endOf('day')
          .utc()
          .unix(),
        orderTimestamp: moment(reservation.dropOff, 'DD/MM/YYYY').utc().unix(),
      });
    });

    await Promise.all(promises);

    await firebaseApp.requestToReservation({ orderId: orderRef.id });

    return orderRef.id;
  }
};

firebaseApp.createBusReservation = async function ({
  organizationId,
  passenger,
  passengerId,
  orderDetails,
  reservations,
  filterTimestamp,
  type,
}) {
  if (firebaseApp.isUserLoggedIn()) {
    const orderRef = await firebaseApp.createOrder({
      organizationId,
      passenger,
      passengerId,
      orderDetails,
      filterTimestamp,
      type,
    });

    const promises = map(reservations, (reservation) => {
      return orderRef.collection('reservations').add({
        type,
        organizationId,
        passengerId,
        busDetails: reservation,
        filterTimestamp: moment(reservation.arrivalDateTime, 'DD/MM/YYYY HH:mm')
          .endOf('day')
          .utc()
          .unix(),
        orderTimestamp: moment(
          reservation.departureDateTime,
          'DD/MM/YYYY HH:mm'
        )
          .utc()
          .unix(),
      });
    });

    await Promise.all(promises);

    await firebaseApp.requestToReservation({ orderId: orderRef.id });

    return orderRef.id;
  }
};

firebaseApp.createOfficeReservation = async function ({
  organizationId,
  passenger,
  passengerId,
  orderDetails,
  reservations,
  filterTimestamp,
  type,
}) {
  if (firebaseApp.isUserLoggedIn()) {
    const orderRef = await firebaseApp.createOrder({
      organizationId,
      passenger,
      passengerId,
      orderDetails,
      filterTimestamp,
      type,
    });

    const promises = map(reservations, (reservation) => {
      return orderRef.collection('reservations').add({
        type,
        organizationId,
        passengerId,
        officeDetails: reservation,
        filterTimestamp: moment(reservation.date, 'DD/MM/YYYY')
          .endOf('day')
          .utc()
          .unix(),
        orderTimestamp: moment(reservation.date, 'DD/MM/YYYY').utc().unix(),
      });
    });

    await Promise.all(promises);

    await firebaseApp.requestToReservation({ orderId: orderRef.id });

    return orderRef.id;
  }
};

firebaseApp.requestToReservation = async function ({ orderId }) {
  if (firebaseApp.isUserLoggedIn()) {
    return db
      .collection('orders')
      .doc(orderId)
      .collection('status_history')
      .add({
        task: 'RESERVATION',
        created_at: moment().unix(),
        source: 'user',
        source_id: firebaseApp.getCurrentUser().uid,
      });
  }
};

firebaseApp.requestToIssue = async function ({
  orderId,
  cardId,
  installments,
  params = {},
}) {
  if (firebaseApp.isUserLoggedIn()) {
    return db
      .collection('orders')
      .doc(orderId)
      .collection('status_history')
      .add({
        task: 'ISSUE',
        created_at: moment().unix(),
        source: 'user',
        source_id: firebaseApp.getCurrentUser().uid,
        params: {
          cardId,
          installments,
          ...params,
        },
      });
  }
};

firebaseApp.requestToCancel = async function (orderId, params = {}) {
  if (firebaseApp.isUserLoggedIn()) {
    return db
      .collection('orders')
      .doc(orderId)
      .collection('status_history')
      .add({
        task: 'CANCEL',
        created_at: moment().unix(),
        source: 'user',
        source_id: firebaseApp.getCurrentUser().uid,
        params,
      });
  }
};

firebaseApp.requestToDismiss = async function (orderId) {
  if (firebaseApp.isUserLoggedIn()) {
    return db
      .collection('orders')
      .doc(orderId)
      .collection('status_history')
      .add({
        task: 'DISMISS',
        created_at: moment().unix(),
        source: 'user',
        source_id: firebaseApp.getCurrentUser().uid,
      });
  }
};

firebaseApp.requestToSelectSeat = async function (orderId, seats = {}) {
  if (firebaseApp.isUserLoggedIn()) {
    return db
      .collection('orders')
      .doc(orderId)
      .collection('status_history')
      .add({
        task: 'SEAT_SELECTION',
        created_at: moment().unix(),
        source: 'user',
        source_id: firebaseApp.getCurrentUser().uid,
        params: { seats },
      });
  }
};

firebaseApp.approveOrder = async function (
  orderId,
  approvalId,
  travelCreditId
) {
  if (firebaseApp.isUserLoggedIn()) {
    return db
      .collection('orders')
      .doc(orderId)
      .collection('approvals')
      .doc(approvalId)
      .set(
        {
          status: ApprovalStatus.APPROVED,
          ...(travelCreditId ? { travelCreditId } : {}),
        },
        { merge: true }
      );
  }
};

firebaseApp.denyOrder = async function (orderId, approvalId, comment) {
  if (firebaseApp.isUserLoggedIn()) {
    return db
      .collection('orders')
      .doc(orderId)
      .collection('approvals')
      .doc(approvalId)
      .set(
        {
          status: ApprovalStatus.DENIED,
          ...(comment ? { comment } : {}),
        },
        { merge: true }
      );
  }
};

firebaseApp.requestToRejectOrder = async function (orderId, comment) {
  if (firebaseApp.isUserLoggedIn()) {
    return db
      .collection('orders')
      .doc(orderId)
      .collection('status_history')
      .add({
        task: 'REJECT',
        created_at: moment().unix(),
        source: 'user',
        source_id: firebaseApp.getCurrentUser().uid,
        ...(comment ? { params: { comment } } : {}),
      });
  }
};

firebaseApp.setChecklist = function (checklistId, checklist) {
  const user = firebaseApp.getCurrentUser();

  if (user && checklistId) {
    const { completed, items } = checklist;

    // Create ref to checklist
    const checklistRef = db
      .collection('users')
      .doc(user.uid)
      .collection('config')
      .doc(checklistId);

    // Add items to items collections
    const itemsBatch = firebaseApp.firestore().batch();

    Object.keys(items).forEach((itemId) => {
      const docRef = checklistRef.collection('items').doc(itemId);
      itemsBatch.set(docRef, items[itemId]);
    });

    return Promise.all([checklistRef.set({ completed }), itemsBatch.commit()]);
  } else throw new Error('Invalid operation. User or checklist ID is missing!');
};

firebaseApp.updateChecklistItem = async function (checklistId, itemId, item) {
  const user = firebaseApp.getCurrentUser();

  if (user && item) {
    return db
      .collection('users')
      .doc(user.uid)
      .collection('config')
      .doc(checklistId)
      .collection('items')
      .doc(itemId)
      .update(item);
  } else throw new Error('Invalid operation. User or item is missing!');
};

firebaseApp.setChecklistComplete = async function (checklistId) {
  const user = firebaseApp.getCurrentUser();

  if (user && checklistId) {
    return db
      .collection('users')
      .doc(user.uid)
      .collection('config')
      .doc(checklistId)
      .update({ completed: true });
  } else throw new Error('Invalid operation. User or checklist id is missing!');
};

firebaseApp.setUserLocale = function (locale) {
  const user = firebaseApp.getCurrentUser();

  if (user) {
    return db
      .collection('users')
      .doc(user.uid)
      .update({ 'config.locale': locale });
  } else throw new Error('Invalid user!');
};

firebaseApp.requestToCreateBudget = async (budgetId) => {
  if (firebaseApp.isUserLoggedIn()) {
    return db
      .collection('budgets')
      .doc(budgetId)
      .collection('status_history')
      .add({
        task: 'CREATE',
        created_at: moment().unix(),
        source: 'user',
        source_id: firebaseApp.getCurrentUser().uid,
      });
  }
};

firebaseApp.requestToReviewBudget = async (
  budgetId,
  comment = '',
  modified_items = {}
) => {
  if (firebaseApp.isUserLoggedIn()) {
    return db
      .collection('budgets')
      .doc(budgetId)
      .collection('status_history')
      .add({
        task: 'REVIEW',
        created_at: moment().unix(),
        source: 'user',
        source_id: firebaseApp.getCurrentUser().uid,
        params: {
          modified_items,
          comment,
        },
      });
  }
};

firebaseApp.reviewBudget = async (budgetId, modifiedItems = {}) => {
  if (firebaseApp.isUserLoggedIn()) {
    const itemsIds = Object.keys(modifiedItems);

    if (itemsIds.length) {
      const batch = db.batch();
      const itemsRef = db
        .collection('budget')
        .doc(budgetId)
        .collection('items');

      itemsIds.forEach((id) => {
        batch.update(itemsRef.doc(id), modifiedItems[id]);
      });

      await batch.commit();
    }

    return firebaseApp.requestToReviewRefund(budgetId, modifiedItems);
  }
};

firebaseApp.requestToSendBudgetForApproval = async (budgetId) => {
  if (firebaseApp.isUserLoggedIn()) {
    return db
      .collection('budgets')
      .doc(budgetId)
      .collection('status_history')
      .add({
        task: 'SEND_FOR_APPROVAL',
        created_at: moment().unix(),
        source: 'user',
        source_id: firebaseApp.getCurrentUser().uid,
      });
  }
};

firebaseApp.requestToPutOnHold = async (budgetId) => {
  if (firebaseApp.isUserLoggedIn()) {
    return db
      .collection('budgets')
      .doc(budgetId)
      .collection('status_history')
      .add({
        task: 'PUT_ON_HOLD',
        created_at: moment().unix(),
        source: 'user',
        source_id: firebaseApp.getCurrentUser().uid,
      });
  }
};

firebaseApp.requestToSendBudgetForProcessing = async (budgetId) => {
  if (firebaseApp.isUserLoggedIn()) {
    return db
      .collection('budgets')
      .doc(budgetId)
      .collection('status_history')
      .add({
        task: 'PROCESSING',
        created_at: moment().unix(),
        source: 'user',
        source_id: firebaseApp.getCurrentUser().uid,
      });
  }
};

firebaseApp.requestToSendBudgetForWaitingPayment = async (budgetId) => {
  if (firebaseApp.isUserLoggedIn()) {
    return db
      .collection('budgets')
      .doc(budgetId)
      .collection('status_history')
      .add({
        task: 'WAITING_PAYMENT',
        created_at: moment().unix(),
        source: 'user',
        source_id: firebaseApp.getCurrentUser().uid,
      });
  }
};

firebaseApp.requestToPayBudgetManually = async (budgetId, user) => {
  if (firebaseApp.isUserLoggedIn()) {
    await db
      .collection('budgets')
      .doc(budgetId)
      .collection('status_history')
      .add({
        task: 'PIX_PAYMENT',
        created_at: moment().unix(),
        source: 'user',
        source_id: firebaseApp.getCurrentUser().uid,
        params: {
          loggedUser: `${user?.firstName} ${user?.lastName}` || '',
        },
      });
    return 'SUCCESS';
  }
  return null;
};

firebaseApp.requestToSendBudgetForPayed = async (budgetId, user) => {
  if (firebaseApp.isUserLoggedIn()) {
    return db
      .collection('budgets')
      .doc(budgetId)
      .collection('status_history')
      .add({
        task: 'PAID',
        created_at: moment().unix(),
        source: 'user',
        source_id: firebaseApp.getCurrentUser().uid,
        comment: `Pago por ${user?.firstName} ${
          user?.lastName
        } em ${moment().format('DD/MM/YYYY')}`,
      });
  }
};

firebaseApp.requestToApproveBudgetAnticipation = async (budgetId) => {
  if (firebaseApp.isUserLoggedIn()) {
    return db
      .collection('budgets')
      .doc(budgetId)
      .collection('status_history')
      .add({
        task: 'APPROVE_ANTICIPATION',
        created_at: moment().unix(),
        source: 'user',
        source_id: firebaseApp.getCurrentUser().uid,
      });
  }
};

firebaseApp.requestToApproveBudget = async (budgetId, rejected_items = {}) => {
  if (firebaseApp.isUserLoggedIn()) {
    const statusHistory = {
      task: 'APPROVE',
      created_at: moment().unix(),
      source: 'user',
      source_id: firebaseApp.getCurrentUser().uid,
    };

    if (Object.keys(rejected_items).length) {
      statusHistory['params'] = { rejected_items };
    }

    return db
      .collection('budgets')
      .doc(budgetId)
      .collection('status_history')
      .add(statusHistory);
  }
};

firebaseApp.requestToRejectBudget = async (budgetId, comment = '') => {
  if (firebaseApp.isUserLoggedIn()) {
    return db
      .collection('budgets')
      .doc(budgetId)
      .collection('status_history')
      .add({
        task: 'REJECT',
        created_at: moment().unix(),
        source: 'user',
        source_id: firebaseApp.getCurrentUser().uid,
        ...(comment ? { params: { comment } } : {}),
      });
  }
};

firebaseApp.requestToCancelBudget = async (budgetId) => {
  if (firebaseApp.isUserLoggedIn()) {
    return db
      .collection('budgets')
      .doc(budgetId)
      .collection('status_history')
      .add({
        task: 'CANCEL',
        created_at: moment().unix(),
        source: 'user',
        source_id: firebaseApp.getCurrentUser().uid,
      });
  }
};

firebaseApp.requestToDismissBudget = async (budgetId) => {
  if (firebaseApp.isUserLoggedIn()) {
    return db
      .collection('budgets')
      .doc(budgetId)
      .collection('status_history')
      .add({
        task: 'DISMISS',
        created_at: moment().unix(),
        source: 'user',
        source_id: firebaseApp.getCurrentUser().uid,
      });
  }
};

firebaseApp.approveBudget = async function (budgetId, approvalId) {
  if (firebaseApp.isUserLoggedIn() && approvalId) {
    return db
      .collection('budgets')
      .doc(budgetId)
      .collection('budget_approvals')
      .doc(approvalId)
      .set(
        {
          status: ApprovalStatus.APPROVED,
        },
        { merge: true }
      );
  }
};

firebaseApp.createRefund = async function (passenger, organization, refund) {
  const { firstName, lastName, email, uid, organizationId } = passenger;
  const { expenses = [] } = refund;

  return db.collection('refunds').add({
    ...refund,
    expenses,
    createdAt: moment().unix(),
    statusCode: RefundStatus.START,
    passengerInfo: {
      firstName,
      lastName,
      email,
    },
    passengerId: uid,
    organizationId,
    fare: {
      total: 0,
      currency: getOrganizationConfig(organization.config, 'currency'),
    },
  });
};

firebaseApp.createBudget = async function (budgetPayload) {
  return db.collection('budgets').add(budgetPayload);
};

firebaseApp.createExpense = function (expense, organization) {
  if (firebaseApp.isUserLoggedIn()) {
    const { date, items: expenseItems, perKm = 1 } = expense;

    const fare = {
      currency: getOrganizationConfig(organization.config, 'currency'),
    };
    let total = 0,
      items = [];

    if (expense.expenseType === expenseTypes.ITEMS) {
      items = expenseItems
        .filter(({ name, price, quantity }) => name && price && quantity)
        .map(({ name, price, quantity }) => {
          const itemPrice = parseFloat(price);
          total += itemPrice * quantity;
          return { name, price: itemPrice, quantity };
        });
    } else {
      fare.perKm = parseFloat(perKm);
      total = expense.route.distance * fare.perKm;
      items = formatExpenseItems(expenseItems);
    }

    fare.total = total;
  }
};

firebaseApp.updateExpense = (expenseId, expense) => {
  return db
    .collection('expenses')
    .doc(expenseId)
    .update({
      ...expense,
    });
};

firebaseApp.updateReceipts = async (budgetId, budgetItemId, receipts) => {
  return db
    .collection('budgets')
    .doc(budgetId)
    .collection('items')
    .doc(budgetItemId)
    .update({
      receipts: firebase.firestore.FieldValue.arrayUnion(...receipts),
    });
};

firebaseApp.updateTravelsConfig = function (organizationId, updatedValue) {
  return db
    .collection('organizations')
    .doc(organizationId)
    .update(updatedValue);
};

firebaseApp.updateRefundsConfig = function (organizationId, newConfig) {
  return db.collection('organizations').doc(organizationId).update({
    'config.refunds.active': newConfig,
  });
};

firebaseApp.updateReceiptsConfig = function (organizationId, newConfig) {
  return db.collection('organizations').doc(organizationId).update({
    'config.refunds.withoutReceipt': newConfig,
  });
};

firebaseApp.updateAdministrativeConfig = function (organizationId, newConfig) {
  return db.collection('organizations').doc(organizationId).update({
    'config.refunds.allowAdministrative': newConfig,
  });
};

firebaseApp.updateAutomaticPaymentConfig = function (
  organizationId,
  newConfig
) {
  return db.collection('organizations').doc(organizationId).update({
    'config.refunds.automaticPaymentByPix': newConfig,
  });
};

firebaseApp.updateObservationsConfig = function (organizationId, newConfig) {
  return db.collection('organizations').doc(organizationId).update({
    'config.observations': newConfig,
  });
};

firebaseApp.updateUserConfig = function (userId, configField, newConfig) {
  if (!configField.startsWith('config.'))
    throw new Error('Invalid config field!');

  return db
    .collection('users')
    .doc(userId)
    .update({
      [configField]: newConfig,
    });
};

firebaseApp.updatePerKmConfig = function (organizationId, value) {
  return db
    .collection('organizations')
    .doc(organizationId)
    .update({
      'config.refunds.perKm': parseFloat(value),
    });
};

firebaseApp.addNewFavoritePlace = function (organizationId, place) {
  return db
    .collection('organizations')
    .doc(organizationId)
    .collection('favorite_places')
    .add({
      ...place,
      status: RecordStatus.ACTIVE,
    });
};

firebaseApp.modifyFavoritePlace = function (organizationId, placeId, changes) {
  return db
    .collection('organizations')
    .doc(organizationId)
    .collection('favorite_places')
    .doc(placeId)
    .set(changes, { merge: true });
};

firebaseApp.deleteFavoritePlace = function (organizationId, placeId) {
  return db
    .collection('organizations')
    .doc(organizationId)
    .collection('favorite_places')
    .doc(placeId)
    .set(
      {
        status: RecordStatus.DELETED,
        deleted_at: moment().unix(),
      },
      { merge: true }
    );
};

firebaseApp.addFavoriteHotel = function (organizationId, hotel) {
  return db
    .collection('organizations')
    .doc(organizationId)
    .collection('favorite_hotels')
    .add({
      ...hotel,
      status: RecordStatus.ACTIVE,
    });
};

firebaseApp.makeFavoriteHotelActive = function (organizationId, hotelId) {
  return db
    .collection('organizations')
    .doc(organizationId)
    .collection('favorite_hotels')
    .doc(hotelId)
    .set(
      {
        status: RecordStatus.ACTIVE,
        deleted_at: null,
      },
      { merge: true }
    );
};

firebaseApp.deleteFavoriteHotel = function (organizationId, hotelId) {
  return db
    .collection('organizations')
    .doc(organizationId)
    .collection('favorite_hotels')
    .doc(hotelId)
    .set(
      {
        status: RecordStatus.DELETED,
        deleted_at: moment().unix(),
      },
      { merge: true }
    );
};

firebaseApp.createRefundBudget = (organizationId, budget) => {
  return db
    .collection('organizations')
    .doc(organizationId)
    .collection('budgets')
    .add({
      ...budget,
      status: recordStatus.ACTIVE,
    });
};

firebaseApp.updateRefundBudget = (organizationId, budgetId, budget) => {
  return db
    .collection('organizations')
    .doc(organizationId)
    .collection('budgets')
    .doc(budgetId)
    .update(budget);
};

firebaseApp.deleteRefundBudget = (organizationId, budgetId) => {
  return db
    .collection('organizations')
    .doc(organizationId)
    .collection('budgets')
    .doc(budgetId)
    .update({
      status: recordStatus.DELETED,
      deleted_at: moment().unix(),
    });
};

firebaseApp.assignRefundToExpense = (expense, refund) => {
  const batch = firebaseApp.firestore().batch();

  batch.update(db.collection('refunds').doc(refund.id), {
    expenseId: expense.id,
  });
  batch.update(db.collection('expenses').doc(expense.id), {
    'fare.total': firebase.firestore.FieldValue.increment(refund.fare.total),
    refunds: firebase.firestore.FieldValue.arrayUnion(refund.id),
  });

  return batch.commit();
};

firebaseApp.createBudgetItem = (budgetId, budgetItem) => {
  return db
    .collection('budgets')
    .doc(budgetId)
    .collection('items')
    .add(budgetItem);
};

firebaseApp.createEmptyBudgetItem = (budgetId, user) => {
  return db.collection('budgets').doc(budgetId).collection('items').add({
    passengerId: user.uid,
    organizationId: user.organizationId,
  });
};

firebaseApp.removeEmptyBudgetItem = (budgetId, budgetItemId) => {
  return db
    .collection('budgets')
    .doc(budgetId)
    .collection('items')
    .doc(budgetItemId)
    .delete();
};

firebaseApp.updateBudgetItem = (budgetId, budgetItemId, payload) => {
  return db
    .collection('budgets')
    .doc(budgetId)
    .collection('items')
    .doc(budgetItemId)
    .update(payload);
};

firebaseApp.deleteBudgetItem = (budgetId, budgetItem) => {
  return db
    .collection('budgets')
    .doc(budgetId)
    .collection('items')
    .doc(budgetItem)
    .delete();
};

firebaseApp.readEmail = (organizationId, emailId) => {
  return db
    .collection('organizations')
    .doc(organizationId)
    .collection('inbox')
    .doc(emailId)
    .update({
      status: EmailStatus.READ,
    });
};

firebaseApp.updateAlias = (organizationId, email_alias) => {
  return db.collection('organizations').doc(organizationId).update({
    email_alias,
  });
};

firebaseApp.archiveCard = (organizationId, passengerId, cardId) => {
  return db
    .collection('organizations')
    .doc(organizationId)
    .collection('expenses_cards')
    .doc(cardId)
    .update({
      status: RecordStatus.ARCHIVED,
      archivedBy: passengerId,
      archivedAt: moment().unix(),
    });
};

firebaseApp.activateCard = (organizationId, passengerId, cardId) => {
  return db
    .collection('organizations')
    .doc(organizationId)
    .collection('expenses_cards')
    .doc(cardId)
    .update({
      status: RecordStatus.ACTIVE,
      activatedBy: passengerId,
      activatedAt: moment().unix(),
    });
};

firebaseApp.addLastDigitsToCard = (organizationId, cardId, lastDigits = '') => {
  return db
    .collection('organizations')
    .doc(organizationId)
    .collection('expenses_cards')
    .doc(cardId)
    .update({
      lastDigits,
    });
};

firebaseApp.updateAllowedTravelTypes = (organizationId, allowedTypes = {}) => {
  return db.collection('organizations').doc(organizationId).update({
    'config.allowedTravelTypes': allowedTypes,
  });
};

firebaseApp.requetToBookAncillary = (ancillary, cardId = null) => {
  if (firebaseApp.isUserLoggedIn()) {
    return db
      .collection('orders')
      .doc(ancillary.orderId)
      .collection('ancillaries')
      .doc(ancillary.id)
      .collection('status_history')
      .add({
        task: 'BOOK',
        created_at: moment().unix(),
        source: 'user',
        source_id: firebaseApp.getCurrentUser().uid,
        params: {
          cardId,
        },
      });
  }
};

firebaseApp.requetToCancelAncillary = (ancillary) => {
  if (firebaseApp.isUserLoggedIn()) {
    return db
      .collection('orders')
      .doc(ancillary.orderId)
      .collection('ancillaries')
      .doc(ancillary.id)
      .collection('status_history')
      .add({
        task: 'CANCEL',
        created_at: moment().unix(),
        source: 'user',
        source_id: firebaseApp.getCurrentUser().uid,
      });
  }
};

firebaseApp.getStatusHistoryFromOrder = (orderId) => {
  return db.collection('orders').doc(orderId).collection('status_history');
};

firebaseApp.updateConfigField = (organizationId, fieldName, value) => {
  return db
    .collection('organizations')
    .doc(organizationId)
    .update({
      [fieldName]: value,
    });
};

firebaseApp.createFidelity = (passengerId, payload) => {
  return db
    .collection('users')
    .doc(passengerId)
    .collection('fidelities')
    .add({
      ...payload,
      status: RecordStatus.ACTIVE,
      createdAt: moment().unix(),
    });
};

firebaseApp.deleteFidelity = (passengerId, fidelityId) => {
  return db
    .collection('users')
    .doc(passengerId)
    .collection('fidelities')
    .doc(fidelityId)
    .update({
      status: RecordStatus.DELETED,
    });
};

firebaseApp.updateOrganizationLocaleConfig = (organizationId, locale) => {
  return db.collection('organizations').doc(organizationId).update({
    'config.locale': locale,
  });
};

firebaseApp.updateOrganizationConnectorsConfig = (
  organizationId,
  connector,
  value
) => {
  return db
    .collection('organizations')
    .doc(organizationId)
    .update({
      [`config.connectors.${connector}`]: value,
    });
};

firebaseApp.updateOrganizationConnectorsConfig = (
  organizationId,
  connector,
  value
) => {
  return db
    .collection('organizations')
    .doc(organizationId)
    .update({
      [`config.connectors.${connector}`]: value,
    });
};

firebaseApp.getBudgetByTransactionId = (transactionId) => {
  return db
    .collectionGroup('items')
    .where('invoice.card_transaction.external_id', '==', transactionId)
    .get();
};

firebaseApp.updateOrganizationSearchParam = ({
  organizationId,
  searchParamsType,
  searchParamsKey,
  searchParamsValue = [],
}) => {
  return db
    .collection('organizations')
    .doc(organizationId)
    .collection('default_search_params')
    .doc(searchParamsType)
    .set(
      {
        [searchParamsKey]: searchParamsValue,
      },
      { merge: true }
    );
};

export function getSearchParamsByType(searchCode, type) {
  return db
    .collection('searches')
    .doc(searchCode)
    .collection('parameters')
    .doc(type);
}

export function getDefaultSearchParamsByType(organizationId, type) {
  return db
    .collection('organizations')
    .doc(organizationId)
    .collection('default_search_params')
    .doc(type);
}

export function getInitialSearchResults(searchCode, type, limit = 20) {
  return db
    .collection('searches')
    .doc(searchCode)
    .collection('results')
    .where('type', '==', type)
    .orderBy('fare.total', 'asc')
    .limit(limit);
}

export function getRemainingResults(searchCode, type, lastItem) {
  return db
    .collection('searches')
    .doc(searchCode)
    .collection('results')
    .where('type', '==', type)
    .orderBy('fare.total', 'asc')
    .startAfter(lastItem);
}

export default firebaseApp;
