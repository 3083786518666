import {
  TCoordsObject,
  TLocationWithId,
} from 'src/portao3-legacy/types/others';

export function mapObjectCoordsToArray(
  coords: TCoordsObject
): TLocationWithId[] {
  if (!coords) return [];

  return Object.keys(coords).reduce((obj, id) => {
    const [lat, lng] = coords[id];

    if (lat !== null && lng !== null) {
      obj.push({
        lat,
        lng,
        id,
      });
    }

    return obj;
  }, [] as TLocationWithId[]);
}
