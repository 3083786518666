import moment from 'moment';
import React from 'react';
import { useState } from 'react';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Form, Label, Row } from 'reactstrap';
import searchStatus from '../../../../../constants/searchStatus';
import IntlMessages from '../../../../../helpers/IntlMessages';
import { Colxx } from '../../../../common/CustomBootstrap';
import DateSearch from '../../../../search/DateSearch';
import SearchButton from '../../../../search/SearchButton';
import { ReactComponent as HotelIcon } from '../../../../../assets/img/icons/raw/hotel.svg';

export default function DateForm({
  order,
  reservations,
  search,
  handleSubmit,
}) {
  const { formatMessage } = useIntl();

  const [dates, setDates] = useState({
    outbound_date: search.outbound_date,
    inbound_date: search.inbound_date,
  });

  const [startDate, endDate] = useMemo(
    () => [search.outbound_date.toDate(), search.inbound_date.toDate()],
    [search]
  );

  const [numDays, daysMessage] = useMemo(() => {
    const days =
      search.outbound_date.diff(dates.outbound_date, 'days') +
      dates.inbound_date.diff(search.inbound_date, 'days');

    return [
      days,
      formatMessage(
        {
          id:
            days > 1
              ? 'travel.alter.hotel.increase-days.multiple-days'
              : 'travel.alter.hotel.increase-days.single-day',
        },
        {
          days,
        }
      ),
    ];
  }, [dates]);

  // Functions
  const handleChange = (name, value) => {
    setDates({
      ...dates,
      [name]: value,
    });
  };

  // Render
  return (
    <Form
      className="travel-search purple d-flex flex-column"
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();

        handleSubmit(dates);
      }}
      style={{
        pointerEvents: search.status === searchStatus.LOADING ? 'none' : 'all',
      }}
    >
      <Row>
        <Colxx xxs="8">
          <Label
            className="form-group search has-top-label float-left w-40"
            onClick={(e) => e.preventDefault()}
            // onFocus={() => this.removePropertyFromErrors('outbound_date')}
          >
            <DateSearch
              selectedDate={dates.outbound_date}
              setValue={(e) => handleChange('outbound_date', moment(e))}
              isStart
              maxDate={startDate}
              defaultEndDate={search.outbound_date}
              // ref={this.outboundRef}
            />
            <IntlMessages id="travel.checkin" />
          </Label>

          <Label className="form-group search has-top-label w-20 h-100 float-left addon-places d-flex justify-content-center">
            <HotelIcon width={20} height={20} fill="white" className="mt-4" />
          </Label>

          <Label
            className="form-group search has-top-label float-left w-40"
            onClick={(e) => e.preventDefault()}
            // onFocus={() => this.removePropertyFromErrors('inbound_date')}
          >
            <DateSearch
              selectedDate={dates.inbound_date}
              setValue={(e) => handleChange('inbound_date', moment(e))}
              isEnd
              minDate={endDate}
              defaultStartDate={dates.outbound_date}
              // ref={this.inboundRef}
            />
            <IntlMessages id="travel.checkout" />
          </Label>
        </Colxx>
        <Colxx xxs="4">
          <SearchButton
            status={''}
            size="4"
            disabled={numDays === 0}
            status={search.status}
          />
        </Colxx>
      </Row>
      <Row className="m-0">
        {numDays > 0 ? (
          <p className="p-0 font-weight-medium" style={{ fontSize: '0.7rem' }}>
            {daysMessage}
          </p>
        ) : null}
      </Row>
    </Form>
  );
}
