import React, { useCallback, useMemo } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Button, Label, ModalBody, Row } from 'reactstrap';
import * as Yup from 'yup';
import IntlMessages from '../../helpers/IntlMessages';
import { useIntl } from 'react-intl';
import useDataSnapshot from '../../hooks/useDataSnapshot';
import firebaseApp from '../../services/Firebase';
import Spinner from '../../components/common/Spinner';
import { CustomSelectStyle } from '../../components/common/CustomSelect';
import FixedSelect from '../../components/common/FixedSelect';
import classNames from 'classnames';

import { registeredAirlines } from '../../constants/travelValues';

const customSelectStyles = {
  ...CustomSelectStyle,
  control: (base) => {
    return {
      ...base,
      borderColor: 'transparent',
      backgroundColor: 'transparent',
      boxShadow: 'transparent',
      padding: 0,
      minHeight: 0,
      margin: 0,
      marginTop: -10,
      ':hover': {
        borderColor: 'transparent',
        boxShadow: 'transparent',
      },
      ':focus': {
        borderColor: 'transparent',
        boxShadow: 'transparent',
      },
    };
  },
  valueContainer: (base) => {
    return {
      ...base,
      padding: 0,
    };
  },
  indicatorContainer: (base) => {
    return {
      ...base,
      padding: 0,
    };
  },
  indicatorsContainer: (base) => {
    return {
      ...base,
      padding: 0,
    };
  },
  input: (base) => {
    return {
      ...base,
      padding: 0,
      margin: 0,
    };
  },
  singleValue: (base) => ({
    ...base,
    fontSize: '0.75rem',
  }),
  container: (base) => ({
    ...base,
    marginTop: -12,
  }),
};

function FidelityForm({ userId, user, addedCompanies = {} }) {
  const { messages } = useIntl();

  const flightCompanies = useMemo(
    () =>
      Object.keys(registeredAirlines)
        .map((key) => {
          const airline = registeredAirlines[key];

          return {
            value: key,
            label: `(${key}) ${airline.name}`,
            name: airline.name,
          };
        })
        .sort((a, b) => a.name.localeCompare(b.name)),
    []
  );

  const filteredCompanies = useMemo(
    () => flightCompanies.filter(({ value }) => !addedCompanies[value]),
    [addedCompanies, flightCompanies]
  );

  // Functions
  const createFidelity = useCallback(
    async (userId, values, setSubmitting, resetForm) => {
      try {
        await firebaseApp.createFidelity(userId, {
          company: values.flightCompany.value,
          fidelityNumber: values.fidelityNumber,
          organizationId: user.organizationId,
        });

        resetForm();
      } catch (err) {
        console.error('Unable to create fidelity', err);
      } finally {
        setSubmitting(false);
      }
    },
    []
  );

  // Render
  return (
    <div>
      <Formik
        initialValues={{
          flightCompany: null,
          fidelityNumber: '',
        }}
        validateOnMount
        validationSchema={Yup.object().shape({
          flightCompany: Yup.object()
            .shape({
              value: Yup.string(),
              label: Yup.string(),
            })
            .required(messages['forms.validation.flight-company.required']),
          fidelityNumber: Yup.string()
            .required(messages['forms.validation.fidelity-number.required'])
            .test(
              'number-validation',
              messages['forms.validation.fidelity-number.invalid'],
              (value) => /[A-z0-9]+/.test(value)
            ),
        })}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          createFidelity(userId, values, setSubmitting, resetForm);
        }}
      >
        {({ values, isSubmitting, isValid, setFieldValue }) => (
          <Form>
            <ModalBody>
              <Row className="mb-1">
                <Label className="form-group has-top-label mb-0 w-100">
                  <div className="input-container">
                    <FixedSelect
                      value={values.flightCompany}
                      placeholder=""
                      maxMenuHeight={150}
                      options={filteredCompanies}
                      onChange={(value) =>
                        setFieldValue('flightCompany', value)
                      }
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      styles={customSelectStyles}
                    />
                  </div>
                  <IntlMessages id="admin.policy.flight-company.name" />
                  <ErrorMessage
                    name="flightCompany"
                    component="div"
                    className="invalid-feedback d-block"
                  />
                </Label>
              </Row>
              <Row className="mb-1">
                <Label className="form-group has-top-label mb-0 w-100">
                  <Field
                    type="input"
                    name="fidelityNumber"
                    id="fidelityNumber"
                    className="form-control"
                    maxLength={30}
                  />
                  <IntlMessages id="admin.policy.fidelity-number.name" />
                  <ErrorMessage
                    name="fidelityNumber"
                    component="div"
                    className="invalid-feedback d-block"
                  />
                </Label>
              </Row>
              <Row className="mt-3 mb-5 pb-5">
                <div className="text-right w-100">
                  <Button
                    type="submit"
                    color="primary"
                    outline
                    size="lg"
                    disabled={isSubmitting || !isValid}
                  >
                    <IntlMessages id="forms.action.create" />
                  </Button>
                </div>
              </Row>
            </ModalBody>
          </Form>
        )}
      </Formik>
    </div>
  );
}

function FidelityItem({ className, fidelity, onDelete }) {
  const airline = useMemo(
    () => registeredAirlines[fidelity.company] || {},
    [fidelity]
  );

  return (
    <div
      className={classNames(
        'fidelity-item d-flex align-items-center justify-content-between',
        className
      )}
    >
      <div className="d-flex align-items-center">
        <img src={airline.logo} width="40px" height="40px" className="mr-3" />
        <div>
          <h4 className="fs-0-9rem font-primary m-0 p-0">
            {fidelity.fidelityNumber}
          </h4>
          <p className="m-0 p-0 fs-0-7rem lh-1 text-muted">{airline.name}</p>
        </div>
      </div>
      <Button size="xs" color="primary" onClick={onDelete}>
        <div className="glyph-icon simple-icon-close" />
      </Button>
    </div>
  );
}

function FidelityCards({ userId, fidelities }) {
  // Functions
  const deleteFidelity = useCallback(async (fidelityId) => {
    try {
      await firebaseApp.deleteFidelity(userId, fidelityId);
    } catch (err) {
      console.error('Unable to delete fidelity', err);
    }
  }, []);

  // Render
  return (
    <div className="fidelity-cards mx-2 pl-1 mt-4 pt-2 mb-3 pr-1">
      {fidelities.map((fidelity, index) => (
        <FidelityItem
          key={fidelity.id}
          fidelity={fidelity}
          onDelete={() => deleteFidelity(fidelity.id)}
          className={index ? 'mt-3' : ''}
        />
      ))}
    </div>
  );
}

export default function FidelityTab({ userId, editUser, focused }) {
  const [fidelties, _, isLoading] = useDataSnapshot({
    fetchFunction: () =>
      firebaseApp.getFidelitiesFromUser(userId).orderBy('createdAt'),
    shouldLoad: focused,
  });

  // States
  const addedCompanies = useMemo(
    () =>
      fidelties.reduce((obj, fidelity) => {
        obj[fidelity.company] = true;
        return obj;
      }, {}),
    [fidelties]
  );

  // Render
  if (isLoading)
    return (
      <div className="flex-center my-5 py-5">
        <Spinner relative style={{ width: 50, height: 50 }} />
      </div>
    );

  return (
    <div className="fidelity-tab">
      <ModalBody className="mt-0 pt-1">
        <FidelityCards userId={userId} fidelities={fidelties} />
        <FidelityForm
          userId={userId}
          user={editUser}
          addedCompanies={addedCompanies}
        />
      </ModalBody>
    </div>
  );
}
