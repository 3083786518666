export default {
  START: 0,
  RESERVED: 30,
  APPROVING: 40,
  ISSUING: 45,
  MANUAL_ISSUING: 46,
  WAITING_ISSUE: 47,
  ISSUED: 50,
  ALTERING: 60,
  ERROR: 80,
  DENIED: 85,
  CANCELLING: 95,
  MANUAL_CANCELLED: 99,
  CANCELLED: 100,
  EXPIRED: 101,
  DISMISSED: 150,
};
