import React from 'react';
import { Nav } from 'reactstrap';
import classnames from 'classnames';

export default function TabSwitch({
  className,
  navLinkClassName,
  navTitleClassName,
  items = [],
  currentTab,
  setTab,
}) {
  return (
    <Nav tabs className={classnames('card-header-tabs', className)}>
      {items.map(({ key, label }) => (
        <div
          key={key}
          onClick={() => currentTab !== key && setTab(key)}
          className={classnames('nav-link', navLinkClassName, {
            active: currentTab === key,
          })}
        >
          <h5 className={classnames('nav-title', navTitleClassName)}>
            {label}
          </h5>
        </div>
      ))}
    </Nav>
  );
}
