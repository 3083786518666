import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import {
  AllOpenBookingProviders,
  OpenBookingProvidersIcons,
  OpenBookingProvidersStyle,
  OpenBookingProvidersWebsites,
} from '../../constants/openBookingProviders';
import classNames from 'classnames';

export default function OpenBookingProviderBadge({
  className,
  provider,
  iconSize = 18,
}) {
  const { messages } = useIntl();

  // States
  const { label, Icon, style, link, title } = useMemo(() => {
    return {
      label:
        messages[`open-booking.providers.short.${provider}`] ||
        messages[`open-booking.providers.${provider}`],
      Icon: OpenBookingProvidersIcons[provider],
      style: OpenBookingProvidersStyle[provider] || {
        backgroundColor: 'white',
        color: 'black',
      },
      link: OpenBookingProvidersWebsites[provider],
      title:
        messages[`open-booking.providers.offer.${provider}`] ||
        `${messages['open-booking.providers.offer']} ${
          messages[`open-booking.providers.${provider}`]
        }`,
    };
  }, [provider]);

  // Functions
  const onClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (!link) return;

    window.open(link);
  };

  // Render
  if (!provider) return null;

  return (
    <div
      className={classNames(
        'open-booking-provider-badge d-flex align-items-center mt-0',
        className,
        {
          'cursor-pointer': link,
        }
      )}
      style={style}
      role={link ? 'link' : 'img'}
      onClick={onClick}
      title={title}
    >
      {Icon ? (
        <Icon
          width={iconSize}
          height={iconSize}
          className="mr-1"
          fill="current"
        />
      ) : null}
      <span className="fs-0-7rem font-weight-medium">{label}</span>
    </div>
  );
}
