import React, { useMemo } from 'react';
import { Card, Badge } from 'reactstrap';
import classnames from 'classnames';
import { Colxx } from '../../components/common/CustomBootstrap';
import ProfileImage from '../../components/ProfileImage';
import { useIntl } from 'react-intl';
import { isUserNational } from '../../utils/nationality';

export default function DataListView({
  user,
  organization,
  isSelect,
  onClickItem,
}) {
  const { messages } = useIntl();

  const isUserInfoMissing = useMemo(
    () =>
      !(
        user &&
        user.birthdate &&
        (isUserNational(user, organization)
          ? user.documents?.cpf?.number
          : user.documents?.identity?.number)
      ),
    [user]
  );

  return (
    <Colxx xxs="12" className="mb-3">
      <Card
        onClick={(event) => onClickItem(event, user)}
        className={classnames('d-flex flex-row cursor-pointer', {
          active: isSelect,
        })}
      >
        <div className="pl-2 d-flex flex-grow-1 mw-0">
          <div className="card-body align-self-center d-flex flex-column flex-lg-row justify-content-between mw-0 align-items-lg-center">
            <div className="w-5">
              <ProfileImage user={user} rounded xxsmall />
            </div>
            <div className="w-40">
              <p className="mb-0 list-item-heading truncate text-capitalize">{`${user.firstName.toLowerCase()} ${user.lastName.toLowerCase()}`}</p>
            </div>
            <p className="mb-0 text-muted text-small w-25">{user.email}</p>
            <p className="mb-0 text-muted text-small w-20">
              {user.mobilePhoneNumber}
            </p>

            <p className="mb-0 text-muted text-small w-10">
              {isUserInfoMissing ? (
                <Badge color="primary">
                  {
                    messages[
                      'containers.users.data-list-view.incomplete-registration'
                    ]
                  }
                </Badge>
              ) : null}
              {user.admin ? (
                <Badge
                  color="primary"
                  className={isUserInfoMissing ? 'mt-1' : ''}
                >
                  {messages['containers.users.data-list-view.admin']}
                </Badge>
              ) : null}
            </p>
          </div>
        </div>
      </Card>
    </Colxx>
  );
}
