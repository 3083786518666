import firebaseApp from '../services/Firebase';
import { getDocumentsFromCollection } from './firebase';

export const loadRoles = async (orgId) => {
  const passengers = await getDocumentsFromCollection(() =>
    firebaseApp.getUsersFromOrganization(orgId).get()
  );

  const roles = [...new Set(passengers.map(({ role }) => role))];

  return roles.reduce((arr, role) => {
    if (role) arr.push({ label: role, value: role });
    return arr;
  }, []);
};
