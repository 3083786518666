import { STORE_BULK_DATA, CLEAR_BULK_DATA, SET_BULK_LOADING } from '../actions';

export const storeBulkData = (id, data) => ({
  type: STORE_BULK_DATA,
  payload: {
    [id]: data,
  },
});

export const setBulkLoading = (id, loading) => ({
  type: SET_BULK_LOADING,
  payload: {
    id,
    loading,
  },
});

export const clearBulkData = (id) => ({
  type: CLEAR_BULK_DATA,
  payload: id,
});
