import React, { Component } from 'react';
import { Colxx } from '../../components/common/CustomBootstrap';
import { Card, Button } from 'reactstrap';
import times from 'lodash/times';
import { injectIntl } from 'react-intl';

import { registeredHotels } from '../../constants/travelValues';
import { stringToMoney } from '../../utils/money';
import HotelAmenities from '../../components/order/HotelAmenities';
import CurrencyWrapper from '../../components/common/CurrencyWrapper';

import { ReactComponent as StarIcon } from '../../assets/img/icons/star.svg';

class HotelItem extends Component {
  handleClick = () => {
    this.props.showDetails(this.props.item);
  };

  // Render
  renderImage() {
    const { item } = this.props;

    let backgroundImage = '/assets/img/default-hotel-bw.png';
    if (item.hotel.mainPhoto !== '') backgroundImage = item.hotel.mainPhoto;

    return (
      <div
        className="hotel-image"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      />
    );
  }

  render() {
    const {
      item,
      intl: { messages },
    } = this.props;

    const roomsFare = item.rooms.map((room) => room.fare);

    const minFare = Math.min(...roomsFare);

    return (
      <Card className="search-card hotel mt-4">
        <Colxx xxs="12" lg="9" className="pl-0">
          <div className="row ml-0 h-100">
            <Colxx xxs="4" className="pl-0">
              {this.renderImage()}
            </Colxx>
            <Colxx xxs="8" className="pt-3 pl-0">
              <h3 className="hotel-title pointer" onClick={this.handleClick}>
                {item.hotel.name}
                <div style={{ marginLeft: -3 }}>
                  {times(item.hotel.category, (index) => {
                    return (
                      <img
                        key={`hotel-${item.hotelId}-category-${index}`}
                        src="/assets/img/hotel-star.svg"
                        className="ml-1"
                        alt={
                          messages[
                            'alts.containers.search.hotel-item.hotel-category'
                          ]
                        }
                      />
                    );
                  })}
                </div>
              </h3>

              <p className="text-muted">
                {item.hotel.destination.address.toLowerCase()}
              </p>

              <HotelAmenities hotel={item.hotel} hotelId={item.hotelId} />
            </Colxx>
          </div>
        </Colxx>
        <Colxx xxs="12" lg="3" className="total pr-0 pt-3 pr-3">
          {item.favorite && (
            <div
              className="favorite-bagde d-flex align-items-center justify-content-center py-2 mb-3"
              title={messages['alts.containers.search.favorite-hotel']}
            >
              <StarIcon
                width={10}
                height={10}
                className="star-icon"
                title={messages['alts.containers.search.favorite-hotel']}
                fill="white"
              />
            </div>
          )}
          {registeredHotels[item.hotel.chain.name] &&
            item.hotel.chain.name !== 'Independent' && (
              <div
                className="logo"
                style={{
                  backgroundImage: `url(${
                    registeredHotels[item.hotel.chain.name].logo
                  })`,
                }}
              />
            )}
          <div className="text">
            <span>{messages['general.from']}</span>
            <CurrencyWrapper value={minFare} decimalPlaces={0}>
              {({ formattedValue }) => formattedValue}
            </CurrencyWrapper>
            <hr />
          </div>
          <div className="description">{messages['general.taxes']}</div>

          <Button color="primary" onClick={this.handleClick}>
            {messages['general.select']}
          </Button>
        </Colxx>
      </Card>
    );
  }
}

export default injectIntl(HotelItem);
