exports.OrderTypes = {
  FLIGHT: 'flight',
  HOTEL: 'hotel',
  CAR: 'car',
  BUS: 'bus',
  OFFICE: 'office',
  ITINERARY: 'itinerary',
};

exports.getOrderTypesAsOptions = (messages) => {
  if (!messages) throw new Error('Empty messages!');

  return Object.values(this.OrderTypes)
    .reduce((options, value) => {
      options.push({
        value,
        label: messages[`travel.menu.${value}`],
      });

      return options;
    }, [])
    .sort((a, b) => a.label.localeCompare(b));
};
