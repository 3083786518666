import { BudgetSortOptions } from '../constants/budget';
import { RefundStatus } from '../constants/refundStatus';

const getTotal = (budget) => {
  if (budget.budget && budget.statusCode < RefundStatus.ACTIVATING)
    return budget.budget || 0;

  return budget.fare.total || 0;
};

export const BudgetSortingFunctions = {
  [BudgetSortOptions.ASC_DATE]: (a, b) => {
    if (a.startDate === b.startDate) return a.createdAt - b.createdAt;

    return a.startDate < b.startDate ? -1 : 1;
  },
  [BudgetSortOptions.DESC_DATE]: (a, b) => {
    if (a.startDate === b.startDate) return b.createdAt - a.createdAt;

    return a.startDate < b.startDate ? 1 : -1;
  },
  [BudgetSortOptions.CHEAPEST]: (a, b) => getTotal(a) - getTotal(b),
  [BudgetSortOptions.EXPENSIVE]: (a, b) => getTotal(b) - getTotal(a),
  [BudgetSortOptions.STATUS]: (a, b) => a.statusCode - b.statusCode,
  [BudgetSortOptions.NAME]: (a, b) => a.name.localeCompare(b.name),
};
