import React, { useMemo } from 'react';
import { Row, Label } from 'reactstrap';
import { Colxx } from '../common/CustomBootstrap';

import IntlMessages from '../../helpers/IntlMessages';
import TagItem from '../common/TagItem';

export default function ReservationParams({ order, showPassengerName = true }) {
  const { tags } = useMemo(() => {
    const { tags = [] } = order;
    return {
      tags: tags.length > 0 ? tags : null,
    };
  }, [order]);

  return (
    <div className="reservation-params pl-3">
      {showPassengerName ? (
        <Row>
          <Colxx xxs="10">
            <Label className="form-group disabled search has-top-label">
              <p className="text-uppercase">
                {order.passengerInfo.firstName} {order.passengerInfo.lastName}
              </p>
              <IntlMessages id="travel.booking.passenger" />
            </Label>
          </Colxx>
        </Row>
      ) : null}
      <Row>
        {order.costCenter?.label && (
          <div className="col-half">
            <Label className="form-group disabled search has-top-label">
              <p className="text-uppercase">{order.costCenter.label}</p>
              <IntlMessages id="travel.booking.costCenter" />
            </Label>
          </div>
        )}
        {order.project?.label && (
          <div className="col-half">
            <Label className="form-group disabled search has-top-label">
              <p className="text-uppercase">{order.project.label}</p>
              <IntlMessages id="travel.booking.project" />
            </Label>
          </div>
        )}
        {order.motive?.label && (
          <div className="col-half">
            <Label className="form-group disabled search has-top-label">
              <p className="text-uppercase">{order.motive.label}</p>
              <IntlMessages id="travel.booking.motive" />
            </Label>
          </div>
        )}
        {tags && (
          <div className="col-half">
            <Label className="form-group disabled search has-top-label">
              <div className="d-flex flex-wrap pt-4 px-2 ml-1 pb-1">
                {tags.map((tag) => (
                  <TagItem key={tag.value} tag={tag} className="mb-1" />
                ))}
              </div>
              <IntlMessages id="travel.booking.tag" />
            </Label>
          </div>
        )}
        {order.observation && (
          <div className="col-half">
            <Label className="form-group disabled search has-top-label">
              <p className="text-uppercase">{order.observation}</p>
              <IntlMessages id="travel.booking.observation" />
            </Label>
          </div>
        )}
      </Row>
    </div>
  );
}
