import React from 'react';
import classNames from 'classnames';
import { Card, CustomInput } from 'reactstrap';

export default function RefundCategoryItem({ category, toggle, isSelected }) {
  return (
    <Card
      onClick={() => toggle(category.id)}
      className={classNames('d-flex flex-row list', {
        active: isSelected,
      })}
    >
      <div className="pl-2 d-flex flex-grow-1 mw-0">
        <div className="card-body align-self-center d-flex flex-column flex-lg-row justify-content-between mw-0 align-items-lg-center">
          <div className="w-40 w-sm-100 d-flex align-items-center">
            <img
              src={`/assets/img/refund/${category.icon}`}
              alt={category.name}
              style={{ width: 30, marginRight: 10 }}
            />

            <p
              className="list-item-heading truncate p-0 m-0"
              onClick={() => this.changeCategory(category)}
              style={{ cursor: 'pointer' }}
            >
              {category.name}
            </p>
          </div>

          <p className="mb-1 text-muted text-small w-15 w-sm-100">
            {category.externalId}
          </p>

          <div className="w-15 w-sm-100" />
        </div>
        <div className="custom-control custom-checkbox pl-1 align-self-center pr-4">
          <CustomInput
            className="item-check mb-0"
            type="checkbox"
            id={`check_${category.id}`}
            checked={isSelected}
            onChange={() => {}}
            label=""
          />
        </div>
      </div>
    </Card>
  );
}
