import { useEffect, useState } from 'react';

export default function WaitBeforeRenderWrapper({ children, waitFor }) {
  const [show, setShow] = useState(false);

  // Effects
  useEffect(() => {
    if (!show) {
      const timeoutRef = setTimeout(() => setShow(true), waitFor);
      return () => clearTimeout(timeoutRef);
    }
  }, [waitFor]);

  // Render
  return show ? children : null;
}
