import React, { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Button, Tooltip } from 'reactstrap';

export default function ReservationButton({
  className = 'mr-3',
  isValid: _isValid,
  disabled,
  onClick,
  title,
  selectMode,
  text,
  showFareModal = false,
  isAllowedToReserveWithDifferentFare = true,
}) {
  const { messages } = useIntl();

  // States
  const [showToolTip, setShowToolTip] = useState(false);

  const btnText = useMemo(
    () =>
      text ||
      messages[
        selectMode ? 'general.select' : 'containers.search.modal.book-now'
      ],
    [selectMode, text]
  );

  const isValid = useMemo(() => selectMode || _isValid, [selectMode, _isValid]);

  // Functions
  const toggleToolTip = (value = !showToolTip) => {
    if (!isValid) setShowToolTip(value);
    if (isValid && !isAllowedToReserveWithDifferentFare) setShowToolTip(value);
  };

  // Render
  return (
    <div>
      <div
        onMouseEnter={() => toggleToolTip(true)}
        onMouseLeave={() => toggleToolTip(false)}
      >
        <Button
          id="reservation-btn"
          color="primary"
          className={className}
          onClick={onClick}
          disabled={showFareModal ? disabled : !selectMode && disabled}
        >
          {btnText}
        </Button>
      </div>
      <Tooltip
        target="reservation-btn"
        isOpen={
          showFareModal
            ? false
            : (!isValid && showToolTip) ||
              (!isAllowedToReserveWithDifferentFare && showToolTip)
        }
        toggle={toggleToolTip}
      >
        <p className="m-0 p-0 small warning">
          {!isAllowedToReserveWithDifferentFare
            ? messages['reservation.invalid-fare-access']
            : title || messages['containers.search.flight-modal.cpf-birthdate']}
        </p>
      </Tooltip>
    </div>
  );
}
