import React from 'react';
import classNames from 'classnames';
import { Row } from 'reactstrap';
import { Colxx } from '../../components/common/CustomBootstrap';
import TravelInfo from '../expenses/dashboard/TravelInfo';
import TravelRelatedInfo from '../expenses/dashboard/RelatedInfo/TravelRelatedInfo';

export default function TravelDataInfo({ className = '', items = [] }) {
  return (
    <Row className={classNames('general-data-info travel', className)}>
      <Colxx md="12" lg="4" className="mb-5">
        <TravelInfo items={items} />
      </Colxx>
      <Colxx md="12" lg="8" className="mb-5">
        <TravelRelatedInfo items={items} />
      </Colxx>
    </Row>
  );
}
