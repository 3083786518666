import React from 'react';
import { useIntl } from 'react-intl';
import FlightFormParams from '../../Params/FlightFormParams';

export default function FlightParams({
  params = {},
  onParamChange = () => {},
}) {
  const { messages } = useIntl();

  // Functions
  const handleParamChange = (name) => (e) => {
    onParamChange(name, e.target.value);
  };

  // Render
  return (
    <div className="flight-params">
      <h4 className="font-primary title">
        {messages['itinerary.flight-params.title']}
      </h4>
      <p className="font-primary description">
        {messages['itinerary.flight-params.description']}
      </p>

      <FlightFormParams
        baggage={params.baggages}
        onBaggageChange={(e) => {
          onParamChange('baggages', parseInt(e.target.value));
        }}
        cabin={params.cabin}
        onCabinChange={handleParamChange('cabin')}
      />
    </div>
  );
}
