import React, { useEffect, useMemo, useState } from 'react';
import firebaseApp from '../../services/Firebase';
import { getDocument } from '../../utils/firebase';
import ProfileImage from '../ProfileImage';

export default function UserWithName({ userId, getData }) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const name = useMemo(
    () => (user ? `${user.firstName} ${user.lastName}` : ''),
    [user]
  );

  // Effects
  useEffect(() => {
    if (userId) {
      try {
        const loadUser = async () => {
          const fetchFunction = () =>
            getDocument(() => firebaseApp.getUserFromId(userId).get());
          const user = await (getData
            ? getData(`user-${userId}`, fetchFunction)
            : fetchFunction());
          setUser(user);
        };

        loadUser();
      } catch (err) {
        console.error('Unable to fetch user!', userId, err);
      }
    }
  }, [userId]);

  useEffect(() => {
    if (user && loading) setLoading(false);
  }, [user]);

  // Render
  return (
    <div className="user-with-name show-up">
      <ProfileImage user={user} loading={loading} title={name} />
    </div>
  );
}
