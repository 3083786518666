import { OrderTypes } from './orderTypes';

export const CancellationTimeOffset = {
  [OrderTypes.BUS]: [1, 'minutes'],
};

export const NeedsCancellationMotive = [OrderTypes.HOTEL];

export const CancellationMotives = {
  [OrderTypes.HOTEL]: [
    {
      label: 'cancellation.hotel.health',
      value: 'HEALTH',
    },
    {
      label: 'cancellation.hotel.unforeseen',
      value: 'UNFORSEEN',
    },
    {
      label: 'cancellation.hotel.mistake',
      value: 'MISKTAKE',
    },
    {
      label: 'cancellation.other',
      value: 'OTHER',
      needsInput: true,
    },
  ],
};
