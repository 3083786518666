import React, { useState, useMemo } from 'react';
import { Row, Button, Modal, ModalBody, Label } from 'reactstrap';
import { Colxx } from '../common/CustomBootstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import FavoritePlacesMap from '../common/FavoritePlacesMap';

export default function FavoritePlaceModal(props) {
  const selectedPlace = useMemo(
    () => props.coords.find((value) => value.id === props.favoritePlaceId),
    [props.coords]
  );
  const [highlightedPin, setHighlightedPin] = useState(selectedPlace || null);

  const favoritePlacesLoookupTable = useMemo(
    () => props.coords.map((coord) => `${coord.lat},${coord.lng}`),
    [props.coords]
  );

  const placesCoords = useMemo(
    () => props.coords.filter((value) => value.id !== props.favoritePlaceId),
    [props.coords]
  );

  const alreadyStored = useMemo(
    () =>
      highlightedPin
        ? favoritePlacesLoookupTable.includes(
            `${highlightedPin.lat},${highlightedPin.lng}`
          )
        : false,
    [highlightedPin]
  );

  const [isDeleting, setIsDeleting] = useState(false);

  const { messages } = useIntl();

  // Functions
  const toggleHighlightedPin = (location) => {
    setHighlightedPin(location);
  };

  const shouldDisableButton = (values, errors) => {
    if (errors.name !== undefined) return true;

    if (selectedPlace)
      return selectedPlace.name === values.name && alreadyStored;
    else return !highlightedPin || alreadyStored;
  };

  const handleDelete = () => {
    setIsDeleting(true);
    props.onDelete(props.favoritePlaceId);
    props.toggleModal();
  };

  // Render
  const renderSelectedPlace = () => {
    if (props.favoritePlaceId === 'new' && alreadyStored) {
      return (
        <div>
          <h3 className="small-medium-title m-0 mb-1">
            {messages['travels.favorite-place.already-saved']}
          </h3>
          <p className="m-0 text-muted medium">
            {messages['travels.favorite-place.already-saved.description']}
          </p>
        </div>
      );
    } else {
      if (highlightedPin) {
        return (
          <div>
            <h3 className="small-medium-title m-0 mb-1">
              {messages['travels.favorite-place.selected']}
            </h3>
            {highlightedPin.loading ? (
              <p className="m-0 text-muted medium">
                {messages['travels.favorite-place.selected.loading']}
              </p>
            ) : (
              <p className="m-0 text-muted medium">{highlightedPin.address}</p>
            )}
          </div>
        );
      } else {
        return (
          <div>
            <h3 className="small-medium-title m-0 mb-1">
              {messages['travels.favorite-place.nothing']}
            </h3>
            <p className="m-0 text-muted medium">
              {messages['travels.favorite-place.nothing.description']}
            </p>
          </div>
        );
      }
    }
  };

  // Render
  return (
    <Modal
      isOpen={true}
      toggle={props.toggleModal}
      className="favorite-place-modal"
    >
      <ModalBody className="p-3 px-3">
        <Row className="px-3">
          <Colxx xss="6" className="mr-3">
            <Formik
              initialValues={{
                name: selectedPlace ? selectedPlace.name : '',
              }}
              validationSchema={Yup.object().shape({
                name: Yup.string().required(
                  messages['forms.validation.firstName.required']
                ),
              })}
              onSubmit={async (values, { setSubmitting }) => {
                await props.savePlace(
                  {
                    ...highlightedPin,
                    ...values,
                  },
                  props.favoritePlaceId
                );
                setSubmitting(false);
                props.toggleModal();
              }}
            >
              {({ values, errors, isSubmitting }) => (
                <Form className="d-flex flex-column justify-content-center h-100">
                  <h2 className="medium-title w-80">
                    {messages['travels.favorite-place.title']}
                  </h2>
                  <div className="my-4">
                    <Label className="mb-3 w-100">
                      <h3 className="small-medium-title pl-1">
                        {messages['admin.policy.costCenter.Name']}
                      </h3>
                      <Field
                        type="input"
                        name="name"
                        className="form-control light-round"
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="invalid-feedback d-block"
                      />
                    </Label>
                    <div className="mt-3 pl-1">{renderSelectedPlace()}</div>
                  </div>
                  <div className="mt-4 btns d-flex justify-content-end">
                    {props.favoritePlaceId !== 'new' && (
                      <Button
                        type="button"
                        color="empty"
                        size="lg"
                        className="px-3 ml-2 pointer"
                        onClick={handleDelete}
                        disabled={isDeleting}
                      >
                        {messages['general.delete']}
                      </Button>
                    )}
                    <Button
                      type="submit"
                      color="primary"
                      size="lg"
                      className="px-3 pointer position-relative ease save-btn"
                      disabled={
                        isDeleting ||
                        isSubmitting ||
                        shouldDisableButton(values, errors)
                      }
                    >
                      {messages['admin.policy.policy.Save']}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </Colxx>
          <Colxx xss="6" className="p-0">
            <FavoritePlacesMap
              highlightedPin={highlightedPin}
              toggleHighlightedPin={toggleHighlightedPin}
              coords={placesCoords}
            />
          </Colxx>
        </Row>
      </ModalBody>
    </Modal>
  );
}
