import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
} from 'reactstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import IntlMessages from '../../helpers/IntlMessages';

import firebaseApp from '../../services/Firebase';
import { setupList } from '../../constants/checklists';
import { openChecklistAndToggleItem } from '../checklist';
import { trackPropertiesSegment } from '../../utils/segment';
import { useIntl } from 'react-intl';
import useDataArchive from '../../hooks/useDataArchive';

export default function CostCenterModal(props) {
  const { messages } = useIntl();
  const dispatch = useDispatch();
  const { deleteDataFromArchive } = useDataArchive();

  // States
  const [costCenter, setCostCenter] = useState({});
  const [isLoading, setLoading] = useState(true);

  const {
    auth: { user },
    checklist: { setup },
  } = useSelector(({ auth, checklist }) => ({
    auth,
    checklist,
  }));

  // Effects
  useEffect(() => {
    (async () => {
      if (props.id !== 'new') await loadCostCenter();
      setLoading(false);
    })();
  }, []);

  // Functions
  const loadCostCenter = async () => {
    const costCenterSnap = await firebaseApp
      .getCostCentersFromId(user.organizationId, props.id)
      .get();

    setCostCenter(costCenterSnap.data());
    return;
  };

  // Update User
  const updateCostCenter = async (values, setSubmitting) => {
    if (props.id !== 'new') {
      await firebaseApp.updateCostCenterFromOrganization(
        user.organizationId,
        props.id,
        values
      );

      trackPropertiesSegment('Cost center updated', {
        user,
        costCenter: values,
      });
    } else {
      await firebaseApp.createCostCenterFromOrganization(
        user.organizationId,
        values
      );

      trackPropertiesSegment('New cost center created', {
        user,
        costCenter: values,
      });
    }

    setSubmitting(false);

    deleteDataFromArchive('costCenters');

    if (setup && !setup[setupList.items.costCenter]) {
      openChecklistAndToggleItem(
        dispatch,
        setupList.id,
        setupList.items.costCenter
      );
    }

    props.toggleModal();
  };

  const deleteCostCenter = async () => {
    if (props.id !== 'new') {
      try {
        await firebaseApp.deleteCostCenterFromOrganizationAndId(
          user.organizationId,
          props.id
        );

        deleteDataFromArchive('costCenters');

        props.toggleModal();
      } catch (err) {
        console.error(err);
      }
    }
  };

  // Render
  if (isLoading) {
    return (
      <Modal isOpen={true} toggle={props.toggleModal}>
        <ModalBody className="edit-group">
          <div className="loading" />
        </ModalBody>
      </Modal>
    );
  }

  // Render
  return (
    <Modal className="admin" isOpen={true} toggle={props.toggleModal}>
      <ModalHeader toggle={props.toggleModal}>
        <IntlMessages id="admin.policy.costCenter.Modal.Add" />
      </ModalHeader>
      <Formik
        initialValues={{
          name: costCenter.name || '',
          externalId: costCenter.externalId || '',
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required(
            messages['forms.validation.firstName.required']
          ),
          externalId: Yup.string(),
        })}
        onSubmit={(values, { setSubmitting }) => {
          updateCostCenter(values, setSubmitting);
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <ModalBody>
              <Row className="mb-1">
                <Label className="form-group has-top-label mb-0 w-100">
                  <Field type="input" name="name" className="form-control" />
                  <IntlMessages id="admin.policy.costCenter.Name" />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="invalid-feedback d-block"
                  />
                </Label>
              </Row>
              <Row className="mb-1">
                <Label className="form-group has-top-label mb-0 w-100">
                  <Field
                    type="input"
                    name="externalId"
                    className="form-control"
                  />
                  <IntlMessages id="admin.policy.costCenter.ExternalId" />
                  <ErrorMessage
                    name="externalId"
                    component="div"
                    className="invalid-feedback d-block"
                  />
                </Label>
              </Row>
            </ModalBody>
            <ModalFooter>
              <div className="d-flex justify-content-between align-items-center">
                {props.id !== 'new' && (
                  <Button
                    type="button"
                    color="empty"
                    size="lg"
                    disabled={isSubmitting}
                    className="mr-3 p-0"
                    style={{ fontSize: 10 }}
                    onClick={deleteCostCenter}
                  >
                    <IntlMessages id="forms.action.delete" />
                  </Button>
                )}
                <Button
                  type="submit"
                  color="primary"
                  outline
                  size="lg"
                  disabled={isSubmitting}
                >
                  <IntlMessages id="forms.action.save" />
                </Button>
              </div>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
