import {
  THotelParams,
  THotelSearchParams,
} from 'src/portao3-legacy/types/orders/hotel/hotelParams';
import { IComponentFilter } from 'src/portao3-legacy/types/orders/orders';
import SearchFilterWrapper from './SearchFilterWrapper';
import useCustomIntl from 'src/portao3-legacy/hooks/useCustomIntl';
import { useMemo } from 'react';
import {
  doesParamHaveItems,
  formatCategories,
  getPriceParamInfo,
  isTruthy,
} from 'src/portao3-legacy/utils/params';
import SearchFilterCard from './SearchFilterCard';
import { RangeTooltip } from 'src/portao3-legacy/components/common/SliderTooltips';
import { CustomInput } from 'reactstrap';

export default function HotelFilters({
  isLoading,
  searchStatus,
  searchParams,
  params,
  setParam,
  setNamedParam,
}: IComponentFilter<THotelSearchParams, THotelParams>) {
  const { messages } = useCustomIntl();

  // States
  const {
    showPrice,
    minPrice,
    maxPrice,
    showCategories,
    formattedCategories,
    showAmenities,
  } = useMemo(() => {
    const showCategories = doesParamHaveItems(searchParams.category);

    return {
      ...getPriceParamInfo(searchParams.values),
      showCategories,
      formattedCategories: showCategories
        ? formatCategories(searchParams.category, messages)
        : [],
      showAmenities: doesParamHaveItems(searchParams.amenities),
    };
  }, [searchParams]);

  // Render
  return (
    <SearchFilterWrapper isLoading={isLoading} searchStatus={searchStatus}>
      <SearchFilterCard
        show={showPrice}
        title={messages['containers.search.hotel-filters.price']}
      >
        <RangeTooltip
          min={minPrice}
          max={maxPrice}
          className="mb-5"
          value={[params.price.min || minPrice, params.price.max || maxPrice]}
          allowCross={false}
          pushable
          onChange={setNamedParam('price')}
        />
      </SearchFilterCard>

      <SearchFilterCard
        show
        title={messages['containers.search.hotel-filters.hotel-name']}
      >
        <input
          id="hotel-name"
          value={params.name}
          onChange={(e) => setParam('name', e.target.value)}
        />
      </SearchFilterCard>

      <SearchFilterCard
        show={showCategories}
        title={messages['containers.search.hotel-filters.category']}
      >
        {formattedCategories.map((category) => (
          <CustomInput
            key={`category-${category.value}`}
            type="checkbox"
            id={`check-category-${category.value}`}
            label={category.label}
            checked={isTruthy(params.categories[category.value])}
            onChange={(e) =>
              setParam('categories', {
                name: category.value,
                value: e.target.checked,
              })
            }
          />
        ))}
      </SearchFilterCard>

      <SearchFilterCard
        show={showAmenities}
        title={messages['containers.search.hotel-filters.amenities']}
      >
        {searchParams.amenities?.map((amenity) => (
          <CustomInput
            key={`amenity-${amenity}`}
            type="checkbox"
            id={`check-amenity-${amenity}`}
            label={amenity}
            checked={isTruthy(params.amenities[amenity])}
            onChange={(e) =>
              setParam('amenities', {
                name: amenity,
                value: e.target.checked,
              })
            }
          />
        ))}
      </SearchFilterCard>
    </SearchFilterWrapper>
  );
}
