import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

export default function CarAmenities({ car }) {
  const { messages } = useIntl();
  const { locale } = useSelector(({ settings }) => settings);

  // States
  const {
    doors,
    passengers,
    transmission,
    baggageSize,
    airConditioning,
    others,
  } = car.amenities;

  const otherAmenities = useMemo(() => {
    if (!others) return '';

    const language = locale.split('-')[0];
    return others.map((obj) => obj[language] || obj.pt).join(' • ');
  }, [others, locale]);

  // Render
  return (
    <div className="mt-3">
      <ul className="amenities-car">
        {doors && (
          <li>
            <img
              src={`/assets/img/icons/amenities/doors.svg`}
              alt={messages['car.doors']}
              title={messages['car.doors']}
            />
            {doors} {messages[doors > 1 ? 'car.doors' : 'car.door']}
          </li>
        )}

        {passengers && (
          <li>
            <img
              src={`/assets/img/icons/amenities/passengers.svg`}
              alt={messages['car.passengers']}
              title={messages['car.passengers']}
            />
            {passengers}{' '}
            {messages[passengers > 1 ? 'car.passengers' : 'car.passenger']}
          </li>
        )}

        {transmission && (
          <li>
            <img
              src={`/assets/img/icons/amenities/transmission.svg`}
              alt={messages['car.transmission']}
              title={messages['car.transmission']}
            />
            {transmission}
          </li>
        )}

        {baggageSize && (
          <li>
            <img
              src={`/assets/img/icons/amenities/luggage.svg`}
              alt={messages['car.transmission']}
              title={messages['car.transmission']}
            />
            {baggageSize}{' '}
            {
              messages[
                baggageSize > 1 ? 'car.baggages-size' : 'car.baggage-size'
              ]
            }
          </li>
        )}

        {airConditioning && (
          <li>
            <img
              src={`/assets/img/icons/amenities/air-conditioning.svg`}
              alt={messages['car.air-conditioning']}
              title={messages['car.air-conditioning']}
            />
            {messages['car.air-conditioning']}
          </li>
        )}
      </ul>

      <p className="text-muted-darker ml-3 others">{otherAmenities}</p>
    </div>
  );
}
