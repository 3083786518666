import firebaseApp from '../services/Firebase';
import { getDocumentsFromCollection } from './firebase';

export async function getFavoriteHotelSearch(user, search) {
  const favoriteHotelsSnap = await firebaseApp
    .getFavoriteHotelsFromOrganization(user.organizationId)
    .get();

  const promises = [];
  favoriteHotelsSnap.forEach((favoriteHotelSnap) => {
    promises.push(
      new Promise(async (resolve, reject) => {
        const iterpecHotelId = favoriteHotelSnap.data().iterpec;
        const resultsSnap = await firebaseApp
          .getHotelSearchFavorite(search.searchCode, iterpecHotelId)
          .get();

        resolve(resultsSnap);
      })
    );
  });

  const results = await Promise.all(promises);

  let newResults = [];

  results.forEach((resultsSnap) => {
    resultsSnap.forEach((resultSnap) => {
      const result = resultSnap.data();
      result.id = resultSnap.id;
      result.favorite = true;

      newResults.push(result);
    });
  });

  return newResults;
}

export async function getFavoriteHotels({ user, getDataFromArchive }) {
  return getDataFromArchive('favorite-hotels', () =>
    getDocumentsFromCollection(() =>
      firebaseApp.getFavoriteHotelsFromOrganization(user.organizationId).get()
    )
  );
}
