import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RESERVATION_ACTION_TIMEOUT } from '../constants/reservations';
import {
  resetReservationAction,
  storeReservationAction,
} from '../redux/actions';

export default function useBlockableAction({
  id,
  initialAllowAction = true,
  waitFor = RESERVATION_ACTION_TIMEOUT,
}) {
  const reservationActions = useSelector(
    ({ reservationActions }) => reservationActions
  );
  const dispatch = useDispatch();

  const [allowAction, setAllowAction] = useState(initialAllowAction);

  // Effects
  useEffect(() => {
    const lastAction = reservationActions[id];

    if (lastAction) {
      const diff = lastAction + waitFor - new Date().getTime();

      if (diff > 0) {
        setAllowAction(false);
        const timeout = setTimeout(() => setAllowAction(true), diff);
        return () => clearTimeout(timeout);
      }
    }

    if (!allowAction) setAllowAction(true);
  }, [id, reservationActions]);

  // Functions
  const blockAction = () => {
    dispatch(storeReservationAction(id));
  };

  const resetAction = () => {
    dispatch(resetReservationAction(id));
  };

  return { allowAction, blockAction, resetAction };
}
