import moment from 'moment';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import {
  RefundStatus,
  RefundStatusClasses,
} from '../../../constants/refundStatus';
import { Button } from 'reactstrap';
import { CardCategoriesIcons } from '../../../constants/cardIcons';

export default function NewBudgetCard({ onCreate }) {
  const { messages } = useIntl();

  const { date, name, CategoryIcon, refundStatusMessage } = useMemo(() => {
    return {
      date: moment().format('DD MMM YYYY'),
      CategoryIcon: CardCategoriesIcons.default,
      category: messages['refund.modal.items.refund-type.free'],
      refundStatusMessage: messages['travel.order.status.timeline.refund.new'],
      name: messages['refund.refund-budget-card.new-budget'],
    };
  }, []);

  return (
    <div
      className="refund-budget-card refund d-flex flex-column justify-content-between pointer"
      onClick={onCreate}
    >
      <div className="d-flex align-items-center top">
        <div className="icon-card d-flex align-items-center justify-content-center">
          <CategoryIcon className="icon" fill="white" />
        </div>
        <div className="ml-3 w-80 align-self-start">
          <p className="p-0 m-0 date">{date}</p>
          <h2 className="p-0 m-0 name mb-2 font-primary">{name}</h2>
          <div className="status-code-container d-flex">
            <div
              className={`status-code rounded text-primary ${
                RefundStatusClasses[RefundStatus.START]
              }`}
            >
              {refundStatusMessage}
            </div>
          </div>
        </div>
      </div>
      <div className="mt-2 flex-grow-1">
        <div className="d-flex align-items-center justify-content-center h-100 px-3">
          <p className="text-center text-muted no-expenses">
            {messages['refund.refund-budget-card.new']}
          </p>
        </div>
      </div>
      <Button
        className="new-expense-btn align-self-center py-2 px-4 mb-4 pretty-btn"
        size="sm"
        color="primary"
        onClick={onCreate}
        name="budget-creation"
      >
        {messages['refund.refund-budget-card.create-budget']}
      </Button>
    </div>
  );
}
