import { OrderTypes } from './orderTypes';

import UploadAnimation from '../assets/lottie/upload-file.json';
import ScanAnimation from '../assets/lottie/scan-file.json';
import SearchAnimation from '../assets/lottie/searching-file.json';
import { RefundTypes } from './refundTypes';

export const Products = [OrderTypes.HOTEL, OrderTypes.CAR];

export const Specificities = {
  NONE: 'none',
  COST_CENTER: 'costCenter',
  PROJECT: 'project',
  MOTIVE: 'motive',
  TAG: 'tag',
};

export const BudgetCategories = {
  FOOD: 'food',
  FUEL: 'fuel',
  FREE: 'free',
  TRANSPORT: 'transport',
};

export const VirtualCategories = {
  NATIONAL: 'NATIONAL',
  INTERNATIONAL: 'INTERNATIONAL',
  ADS: 'ADS',
  SAAS: 'SAAS',
  FLIGHT: 'FLIGHT',
  HOTEL: 'HOTEL',
  MOBILITY: 'MOBILITY',
};

export const PhysicalCategories = {
  FOOD: 'FOOD',
  FUEL: 'FUEL',
  MOBILITY: 'MOBILITY',
  NATIONAL: 'NATIONAL',
  NATIONAL_WITHOUT_WITHDRAWALS: 'NATIONAL_WITHOUT_WITHDRAWALS',
  INTERNATIONAL: 'INTERNATIONAL',
};

export const BudgetCategoriesIcons = {
  [PhysicalCategories.FOOD]: '/assets/img/expenses/food.svg',
  [PhysicalCategories.FUEL]: '/assets/img/expenses/fuel.svg',
  [PhysicalCategories.MOBILITY]: '/assets/img/expenses/taxi.svg',
  [PhysicalCategories.NATIONAL]: '/assets/img/expenses/coin.svg',
  [PhysicalCategories.INTERNATIONAL]: '/assets/img/expenses/globe.svg',
};

export const BudgetIdMapping = {
  1: BudgetCategories.FOOD,
  2: BudgetCategories.FUEL,
  3: BudgetCategories.TRANSPORT,
  4: BudgetCategories.FREE,
};

export const BarcodeTypes = {
  '1D': '1D_BARCODE',
  '2D': '2D_BARCODE',
};

export const BudgetStorageTypes = {
  INBOX: 'inbox',
  BUDGETS: 'budgets',
};

export const InboxDragTypes = {
  ATTACHMENT: 'attachment',
};

export const ItemLoadingStages = [
  {
    lottieAnimation: UploadAnimation,
    messageId: 'receipt-expense.upload-stage',
  },
  {
    lottieAnimation: SearchAnimation,
    messageId: 'receipt-expense.search-stage',
  },
  {
    lottieAnimation: ScanAnimation,
    messageId: 'receipt-expense.scan-stage',
  },
];

export const ValidReceiptMimeTypes = [
  'image/jpg',
  'image/jpeg',
  'image/png',
  'application/pdf',
];

export const BudgetSortOptions = {
  ASC_DATE: 'ASC_DATE',
  DESC_DATE: 'DESC_DATE',
  CHEAPEST: 'CHEAPEST',
  EXPENSIVE: 'EXPENSIVE',
  STATUS: 'STATUS',
  NAME: 'NAME',
};

export const BudgetProviders = {
  ADMINISTRATIVE: 'ADMINISTRATIVE',
  BUDGET: 'BUDGET',
};
