import {
  FilterQueryTypes,
  TFilterQueryTypes,
} from 'src/portao3-legacy/hooks/useFilterItems/types';
import {
  ParamTypes,
  TContains,
} from 'src/portao3-legacy/hooks/useParams/types';
import { THotelSearchItem } from 'src/portao3-legacy/types/orders/hotel/hotelOrder';
import { THotelGetDefaultParams } from 'src/portao3-legacy/types/orders/hotel/hotelParams';
import { makeContainsParamsFromDefaultSearchParams } from 'src/portao3-legacy/utils/params';

export const HotelParamsConfig = [
  {
    name: 'price',
    type: ParamTypes.RANGE,
  },
  {
    name: 'name',
    type: ParamTypes.STRING,
  },
  {
    name: 'categories',
    type: ParamTypes.CONTAINS,
    getDefaultValue: (defaultParams: THotelGetDefaultParams = {} as any) => {
      const defaultCategories = makeContainsParamsFromDefaultSearchParams({
        searchParam: (defaultParams.searchParams?.category || []).map((value) =>
          parseInt(value)
        ),
        defaultParam: defaultParams.defaultSearchParams?.categories,
        returnIfEmpty: null,
      });

      if (defaultCategories) return defaultCategories;

      const searchCategories = defaultParams.searchParams?.category || [];

      return searchCategories.reduce((obj, item) => {
        const value = parseInt(item);
        if (value > 2) obj[value] = true;
        return obj;
      }, {} as TContains);
    },
  },
  {
    name: 'amenities',
    type: ParamTypes.CONTAINS,
    getDefaultValue: (defaultParams: THotelGetDefaultParams = {} as any) =>
      makeContainsParamsFromDefaultSearchParams({
        searchParam: defaultParams.searchParams?.amenities,
        defaultParam: defaultParams.defaultSearchParams?.amenities,
      }),
  },
  {
    name: 'selectedHotels',
    type: ParamTypes.CONTAINS,
  },
];

export const HotelFilterQueries: TFilterQueryTypes<any, any, any>[] = [
  {
    name: 'price',
    type: FilterQueryTypes.RANGE,
    getValue: ({ fare }: THotelSearchItem) => fare.total,
  },
  {
    name: 'name',
    type: FilterQueryTypes.STRING,
    getValue: ({ hotel }: THotelSearchItem) => hotel.name.toLowerCase(),
  },
  {
    name: 'categories',
    type: FilterQueryTypes.CONTAINS,
    getValue: ({ hotel }: THotelSearchItem) => hotel.category,
    allowUndefined: false,
    filterFalsy: true,
    skipIfEmpty: true,
  },
  {
    name: 'selectedHotels',
    type: FilterQueryTypes.CONTAINS,
    getValue: ({ hotelId }: THotelSearchItem) => hotelId,
    filterFalsy: true,
    skipIfEmpty: true,
    exclusive: true,
    allowUndefined: false,
  },
  {
    name: 'amenities',
    type: FilterQueryTypes.CONTAINS,
    getValue: ({ hotel }: THotelSearchItem) => hotel.amenities,
    valueIsArray: true,
    allowUndefined: false,
    filterFalsy: true,
    skipIfEmpty: true,
  },
];
