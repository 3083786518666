import enLang from './en_US';
import esLang from './es_ES';
import ptLang from './pt_BR';

export default {
  en: enLang,
  es: esLang,
  pt: ptLang,
};

export const verifyMissingMessages = (
  languagesMessages = [
    {
      lang: 'en',
      messages: enLang,
    },
    {
      lang: 'es',
      messages: esLang,
    },
  ],
  defaultLanguageMessage = ptLang
) => {
  const keys = Object.keys(defaultLanguageMessage);

  return languagesMessages
    .map(({ lang, messages }) => {
      const missingMessages = [];

      for (let i = 0, length = keys.length; i < length; i++) {
        const key = keys[i];
        if (!messages[key]) missingMessages.push(key);
      }

      return { lang, missingMessages };
    })
    .filter(({ missingMessages }) => missingMessages.length);
};

export const addMissingMessages = (messages, defaultMessages = ptLang) => {
  return { ...defaultMessages, ...messages };
};
