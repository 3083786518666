import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import {
  Card,
  CardImg,
  Label,
  CustomInput,
  Row,
  Button,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Tooltip,
} from 'reactstrap';
import { Colxx } from '../../components/common/CustomBootstrap';
import IntlMessages from '../../helpers/IntlMessages';
import classnames from 'classnames';
import map from 'lodash/map';
import mouseTrap from 'mousetrap';

import CreditCardModal from '../../components/finance/CreditCardModal';
import PolicyLoading from '../policy/PolicyLoading';

import firebaseApp from '../../services/Firebase';
import { useIntl } from 'react-intl';
import { ASSOCIATIVE_PROPERTIES } from '../../constants/creditCard';
import organizationStatus from '../../constants/organizationStatus';

let loadCredirCardsEvent = () => {};

const getAssociatedItems = (creditCard) => {
  const joinItems = (items = []) => {
    return items.map(({ label }) => label).join(', ');
  };

  return Object.values(ASSOCIATIVE_PROPERTIES)
    .reduce((options, value) => {
      const items = creditCard[value] || [];

      if (items.length) {
        options.push({
          value,
          label: joinItems(items),
        });
      }

      return options;
    }, [])
    .sort((a, b) => a.value.localeCompare(b.value));
};

function AssociatedItemTooltip({ associatedItem, index, lastIndex, messages }) {
  const ref = useRef();
  const [isOpen, setIsOpen] = useState(false);

  const separator = useMemo(
    () =>
      index
        ? index === lastIndex
          ? ` ${messages['general.and']} `
          : ', '
        : null,
    [index, lastIndex]
  );

  return (
    <>
      {separator ? <span>{separator}</span> : ''}
      <span
        className="associated-item text-lowercase text-primary font-weight-medium pointer"
        ref={ref}
      >
        {
          messages[
            `admin.credit-cards.associated-items.${associatedItem.value}`
          ]
        }
      </span>
      <Tooltip
        className="associated-item-tooltip"
        placement={'bottom'}
        target={ref}
        isOpen={isOpen}
        toggle={() => setIsOpen(!isOpen)}
      >
        <p className="m-0 p-0 fs-0-7rem lh-1-5">{associatedItem.label}</p>
      </Tooltip>
    </>
  );
}

function AssociatedItems({ creditCard, messages }) {
  const { associatedItems, hasValue, lastIndex } = useMemo(() => {
    const associatedItems = getAssociatedItems(creditCard);
    const numItems = associatedItems.length;

    return {
      associatedItems,
      hasValue: !!numItems,
      lastIndex: numItems - 1,
    };
  }, [creditCard]);

  return hasValue ? (
    <p className="m-0 p-0 text-muted fs-0-7rem lh-1-25">
      {messages['admin.credit-cards.associated-items.title']}{' '}
      <>
        {associatedItems.map((item, index) => (
          <AssociatedItemTooltip
            key={item.value}
            messages={messages}
            associatedItem={item}
            index={index}
            lastIndex={lastIndex}
          />
        ))}
      </>
    </p>
  ) : null;
}

export default function CreditCardsDataListView() {
  const history = useHistory();
  const { resourceId } = useParams();
  const { messages } = useIntl();

  const { user, organization } = useSelector((state) => state.auth);

  const [selectedItems, setSelectedItems] = useState([]);
  const [dropdownSplitOpen, setDropdownSplitOpen] = useState(false);

  const [creditCards, setCreditCards] = useState([]);
  const [isLoading, setLoading] = useState(true);

  const selectedCreditCard = useMemo(
    () => (resourceId ? creditCards.find(({ id }) => id === resourceId) : null),
    [creditCards, resourceId]
  );

  useEffect(() => {
    loadCreditCards();

    return () => {
      loadCredirCardsEvent();
    };
  }, []);

  useEffect(() => {
    mouseTrap.bind(['ctrl+a', 'command+a'], () => {
      setSelectedItems(map(creditCards, (x) => x.id));
      return false;
    });

    mouseTrap.bind(['ctrl+d', 'command+d'], () => {
      setSelectedItems([]);
      return false;
    });

    return () => {
      mouseTrap.unbind('ctrl+a');
      mouseTrap.unbind('command+a');
      mouseTrap.unbind('ctrl+d');
      mouseTrap.unbind('command+d');
    };
  }, [creditCards]);

  // Handle Credit Cards
  const loadCreditCards = async () => {
    loadCredirCardsEvent = firebaseApp
      .getCreditCardsFromOrganization(user.organizationId)
      .onSnapshot((creditCardsSnap) => {
        const creditCards = [];
        creditCardsSnap.forEach((creditCardSnap) => {
          const creditCard = creditCardSnap.data();

          if (creditCard.issuer !== 'BANK_3') {
            creditCards.push({
              ...creditCard,
              id: creditCardSnap.id,
            });
          }
        });

        setCreditCards(
          creditCards.sort(
            ({ priority: aPriority = 1 }, { priority: bPriority = 1 }) =>
              aPriority - bPriority
          )
        );
        setLoading(false);
      });
  };

  const deleteCreditCards = async () => {
    for (let i = 0; i < selectedItems.length; i++) {
      await firebaseApp.deleteCreditCardsFromOrganizationAndId(
        user.organizationId,
        selectedItems[i]
      );
    }

    setSelectedItems([]);
  };

  // Split
  const toggleSplit = () => {
    setDropdownSplitOpen(!dropdownSplitOpen);
  };

  // Selection
  const onCheckItem = (id) => {
    if (selectedItems.includes(id)) {
      setSelectedItems(selectedItems.filter((x) => x !== id));
    } else {
      setSelectedItems([...selectedItems, id]);
    }
  };

  const handleChangeSelectAll = () => {
    if (selectedItems.length >= creditCards.length) {
      setSelectedItems([]);
    } else {
      setSelectedItems(map(creditCards, (x) => x.id));
    }
  };

  // Modal
  const openModal = (creditCardId) => {
    if (organization.status !== organizationStatus.TESTING) {
      history.push(`/app/finance/credit_cards/${creditCardId}`);
    }
  };

  const closeModal = () => {
    history.push('/app/finance/credit_cards');
  };

  // Render
  if (isLoading) {
    return <PolicyLoading />;
  }

  return (
    <div>
      <Row>
        <Colxx xxs="12" className="mt-5">
          <Button
            color="primary"
            className="m-0 pretty-btn"
            onClick={() => {
              openModal('new');
            }}
            disabled={organization.status === organizationStatus.TESTING}
          >
            {messages['admin.finance.creditCards.Add']}
          </Button>

          <ButtonDropdown
            isOpen={dropdownSplitOpen}
            toggle={toggleSplit}
            className="float-right"
          >
            <div className="btn btn-primary btn-lg pl-4 pr-0 check-button check-all">
              <CustomInput
                className="custom-checkbox mb-0 d-inline-block"
                type="checkbox"
                id="checkAll"
                checked={selectedItems.length >= creditCards.length}
                onChange={() => handleChangeSelectAll(true)}
                label={
                  <span
                    className={`custom-control-label ${
                      selectedItems.length > 0 &&
                      selectedItems.length < creditCards.length
                        ? 'indeterminate'
                        : ''
                    }`}
                  />
                }
              />
            </div>
            <DropdownToggle
              caret
              color="primary"
              className="dropdown-toggle-split btn-lg"
            />
            <DropdownMenu right>
              <DropdownItem onClick={deleteCreditCards}>
                <IntlMessages id="admin.policy.delete" />
              </DropdownItem>
            </DropdownMenu>
          </ButtonDropdown>
        </Colxx>
      </Row>
      <Row className="mt-4">
        {map(creditCards, (creditCard, index) => {
          return (
            <Colxx
              key={`creditCard-${index}`}
              xxs="12"
              className="mb-3 pointer"
              onClick={() => openModal(creditCard.id)}
            >
              <Card
                className={classnames('d-flex flex-row list credit-card-list', {
                  active: selectedItems.includes(creditCard.id),
                })}
              >
                <div className="pl-2 d-flex flex-grow-1 min-width-zero">
                  <div className="card-body align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero align-items-lg-center">
                    <div className="w-5 w-sm-100">
                      {creditCard.priority || 1}
                    </div>
                    <CardImg
                      top
                      src={`/assets/img/logos/${creditCard.issuer}.svg`}
                      alt={creditCard.issuer}
                      className="img-thumbnail border-0 rounded-circle list-thumbnail"
                    />
                    <div className="w-40 w-sm-100">
                      <p className="list-item-heading mb-1 truncate">
                        {creditCard.name}
                      </p>
                      <AssociatedItems
                        creditCard={creditCard}
                        messages={messages}
                      />
                    </div>
                    <p className="mb-1 text-muted text-small w-15 w-sm-100">
                      {messages['travel.booking.creditCard.ends-with']}{' '}
                      {creditCard.lastDigits}
                    </p>
                    <div className="w-15 w-sm-100"></div>
                  </div>
                  <div className="custom-control custom-checkbox pl-1 align-self-center pr-4">
                    <Label onClick={(e) => e.stopPropagation()}>
                      <CustomInput
                        className="item-check mb-0"
                        type="checkbox"
                        id={`check_${creditCard.id}`}
                        checked={selectedItems.includes(creditCard.id)}
                        onChange={() => onCheckItem(creditCard.id)}
                      />
                    </Label>
                  </div>
                </div>
              </Card>
            </Colxx>
          );
        })}
      </Row>

      {resourceId && (
        <CreditCardModal
          toggleModal={closeModal}
          creditCardId={resourceId}
          creditCard={selectedCreditCard}
        />
      )}
    </div>
  );
}
