import React, { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import FlightModal from '../../../containers/search/FlightModal';
import { ReactComponent as FlightIcon } from '../../../assets/img/icons/raw/plane.svg';
import ResultsWrapper from './ResultsWrapper';
import DefaultItineraryContainer from '../DefaultItineraryContainer';
import FlightItineraryCard from '../cards/FlightItineraryCard';
import SelectedFlightCard from '../selectionCards/SelectedFlightCard';
import SelectOutboundMessage from '../SelectOutboundMessage';

export default function FlightItineraryResults({
  className,
  searchCode,
  search,
  params,
  results,
  loading,
  outbound = true,
  onSelectItem,
  selectedItem,
  roundTrip,
}) {
  const { messages } = useIntl();

  // States
  const journeyIndex = useMemo(
    () => (!roundTrip || outbound ? 0 : 1),
    [roundTrip, outbound]
  );

  const [title, description] = useMemo(() => {
    let title = '',
      description = '';

    if (outbound) {
      title = messages['travel.outbound'];
      description = messages['itinerary.flight.outbound.description'];
    } else {
      title = messages['travel.inbound'];
      description = messages['itinerary.flight.inbound.description'];
    }

    return [
      `${messages['travel.menu.flight']} (${title})`,
      `${description} ${messages['itinerary.flight.description']}`,
    ];
  }, [outbound]);

  const [selectedFlight, setSelectedFlight] = useState(null);

  const selectedFlightId = useMemo(() => {
    return selectedItem?.item?.id;
  }, [selectedItem]);

  const EmptyComponent = useMemo(() => {
    if (outbound) return undefined;

    if (params?.totalItems && !results.length) return SelectOutboundMessage;

    return undefined;
  }, [outbound, params, results]);

  // Functions
  const onViewFlight = () => {
    if (!selectedItem) return;

    const { item: selectedFlight, selectedSegment } = selectedItem;

    const journeys = roundTrip
      ? [selectedFlight.journeys[outbound ? 0 : 1]]
      : selectedFlight.journeys;

    setSelectedFlight({
      item: roundTrip
        ? {
            ...selectedFlight,
            journeys,
            fare: {
              ...selectedFlight.fare,
              total: selectedFlight.fare.total / 2,
            },
          }
        : selectedFlight,
      selectedSegments: [journeys[0].segments[selectedSegment]],
    });
  };

  // Render
  const renderItemCard = () => (
    <SelectedFlightCard
      className="mt-4"
      item={selectedItem}
      onClick={onViewFlight}
    />
  );

  return (
    <>
      <DefaultItineraryContainer
        Icon={FlightIcon}
        title={title}
        description={description}
        ItemCard={renderItemCard}
        className={className}
      >
        <ResultsWrapper
          loading={loading}
          results={results}
          EmptyComponent={EmptyComponent}
        >
          {results.map((item) => (
            <FlightItineraryCard
              key={item.id}
              item={item}
              onClick={onViewFlight}
              journey={journeyIndex}
              onSelectItem={(segment) =>
                onSelectItem({
                  item,
                  selectedSegment: segment,
                  selectedJourney: roundTrip ? (outbound ? 0 : 1) : 0,
                })
              }
              selected={selectedFlightId === item.id}
              hasSelected={selectedFlightId}
              roundTrip={roundTrip}
            />
          ))}
        </ResultsWrapper>
      </DefaultItineraryContainer>

      {selectedFlight ? (
        <FlightModal
          toggleModal={() => setSelectedFlight(null)}
          item={selectedFlight.item}
          segments={selectedFlight.selectedSegments}
          params={params}
          selectMode
          reservationBtnMessage={messages['general.done']}
        />
      ) : null}
    </>
  );
}
