import React from 'react';
import classNames from 'classnames';

export default function BubbleButton({
  children,
  onClick = () => {},
  className = '',
  disabled,
  isPressed,
  noEffect,
  ...others
}) {
  return (
    <div
      className={`bubble-button ${className} ${classNames({
        isPressed,
        disabled,
        noEffect,
      })}`}
      onClick={(e) => !disabled && onClick(e)}
      {...others}
    >
      <div className="content position-absolute">{children}</div>
      <div className="background"></div>
    </div>
  );
}
