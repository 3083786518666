import { uniqueByKeepLast } from '../../utils/filters';
import {
  ADD_BUDGETS,
  ADD_BUDGET_REPORT_EXPENSE,
  REMOVE_BUDGET_REPORT_EXPENSE,
  ADD_ALL_BUDGETS_REPORT_EXPENSE,
  REMOVE_ALL_BUDGETS_REPORT_EXPENSE_BY_CARD_ID,
  RESET_SELECTED_BUDGETS,
} from '../actions';

const INITIAL_STATE = {
  budgets: {},
  reports: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_BUDGETS: {
      return {
        ...state,
        budgets: {
          ...state.budgets,
          [action.payload.budgets[0].cardId]: action.payload.budgets,
        },
      };
    }
    case ADD_BUDGET_REPORT_EXPENSE: {
      const reports = [...state.reports, action.payload.budget];
      return {
        ...state,
        reports: uniqueByKeepLast(reports, (it) => it.id),
      };
    }
    case REMOVE_BUDGET_REPORT_EXPENSE: {
      return {
        ...state,
        reports: state.reports.filter(
          (report) => report.id !== action.payload.budget.id
        ),
      };
    }
    case ADD_ALL_BUDGETS_REPORT_EXPENSE: {
      const reports = [...state.reports, ...action.payload.budgets];
      return {
        ...state,
        reports: uniqueByKeepLast(reports, (it) => it.id),
      };
    }
    case REMOVE_ALL_BUDGETS_REPORT_EXPENSE_BY_CARD_ID: {
      return {
        ...state,
        reports: state.reports.filter(
          (report) => report.cardId !== action.payload.cardId
        ),
      };
    }
    case RESET_SELECTED_BUDGETS: {
      return {
        ...state,
        budgets: {},
        reports: [],
      };
    }
    default: {
      return { ...state };
    }
  }
};
