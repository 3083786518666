import React, { Component } from 'react';
import { Row } from 'reactstrap';
import { Colxx } from '../../components/common/CustomBootstrap';
import filter from 'lodash/filter';
import sortBy from 'lodash/sortBy';
import map from 'lodash/map';
import xor from 'lodash/xor';

import OfficeItem from './OfficeItem';
import OfficeMap from './OfficeMap';
import OfficeModal from './OfficeModal';
import OfficeFilters from './OfficeFilters';

import Pagination from '../pages/Pagination';
import LoadingHotel from '../../components/search/LoadingHotel';
import NoResults from '../../components/search/NoResults';

import LoadSearchStatus from '../../constants/loadSearchStatus';
import { getIDWithinRangeFromLocation } from '../../utils/locations';
import { trackPropertiesSegment } from '../../utils/segment';

class OfficeResults extends Component {
  constructor(props) {
    super(props);

    this.state = {
      proximityAddress: '',
      amenities: [],
      itemsPerPage: 10,
      currentPage: 1,
      selectedOffices: [],
      selected: null,
      highlightedPin: null,
    };

    this.baseState = this.state;
  }

  // Lifecycle
  componentDidUpdate(prevProps) {
    if (this.props.results.length !== prevProps.results.length) {
      if (this.props.results.length === 0) {
        this.setState(this.baseState);
      }
    }
  }

  // Reservation
  reserveItem = (reservation) => {
    this.props.reserveItem(reservation);

    this.hideDetails();
  };

  // Filters
  filterResults = () => {
    const { results, status, isLoading, params } = this.props;
    const { amenities, selectedOffices, itemsPerPage, currentPage } =
      this.state;

    let filtered = filter(results, function (o) {
      if (selectedOffices.length > 0) {
        return selectedOffices.includes(o.officeId);
      }

      for (let iA = 0; iA < amenities.length; iA++) {
        if (o.office.amenities.includes(amenities[iA]) === false) return false;
      }

      return true;
    });

    const totalItems =
      results.length === params?.totalItems
        ? filtered.length
        : params.totalItems || results.length;

    filtered = sortBy(filtered, 'fare.total').slice(
      (currentPage - 1) * itemsPerPage,
      currentPage * itemsPerPage
    );

    if (
      filtered.length < itemsPerPage &&
      status === LoadSearchStatus.NEW &&
      !isLoading
    ) {
      this.props.getRemainingOfficeSearch();
    }

    return [filtered, totalItems];
  };

  toggleSelectedHotels = (officeId) => {
    const { currentPage, itemsPerPage, selectedOffices } = this.state;
    const newSelectedOffices = xor(selectedOffices, [officeId]);

    this.setState({
      selectedOffices: newSelectedOffices,
      currentPage: newSelectedOffices.length <= itemsPerPage ? 1 : currentPage,
    });
  };

  // Filter Handlers
  onAmenityChange = (amenity) => {
    const { amenities } = this.state;
    const amenityIndex = amenities.indexOf(amenity);

    if (amenityIndex !== -1) {
      amenities.splice(amenityIndex, 1);
    } else {
      amenities.push(amenity);
    }

    this.setState({ amenities });
  };

  onHighlightedPinChange = (location = null) => {
    this.setState({
      highlightedPin: location,
      selectedOffices: location
        ? getIDWithinRangeFromLocation(this.props.params.coords, location)
        : [],
    });
  };

  // Pagination
  onChangePage = (currentPage) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    this.setState({ currentPage });
  };

  // Modal
  showDetails = (officeItem) => {
    trackPropertiesSegment('User selected office item', {
      officeItem,
      user: this.props.user,
    });
    this.setState({ selected: officeItem });
  };

  hideDetails = () => {
    trackPropertiesSegment('User hid office item');
    this.setState({ selected: null });
  };

  // Render
  renderPagination(numFilteredItems) {
    const { itemsPerPage, currentPage } = this.state;

    return (
      numFilteredItems > itemsPerPage && (
        <Pagination
          currentPage={currentPage}
          totalPage={Math.ceil(numFilteredItems / itemsPerPage)}
          onChangePage={(i) => this.onChangePage(i)}
        />
      )
    );
  }

  renderLoadingResults() {
    return (
      <div className="row search-results loader">
        <Colxx xxs="12">
          <LoadingHotel />
        </Colxx>
        <Colxx xxs="12">
          <LoadingHotel />
        </Colxx>
        <Colxx xxs="12">
          <LoadingHotel />
        </Colxx>
        <Colxx xxs="12">
          <LoadingHotel />
        </Colxx>
      </div>
    );
  }

  renderResults() {
    const { params, status, isLoading } = this.props;
    const [filteredItems, totalItems] = this.filterResults();

    if (isLoading) {
      return this.renderLoadingResults();
    }

    if (status === LoadSearchStatus.EMPTY || !filteredItems.length) {
      return <NoResults />;
    }

    return (
      <div>
        <div className="search-map">
          <OfficeMap
            coords={params?.coords}
            selected={this.state.selectedOffices}
            toggleSelectedHotels={this.toggleSelectedHotels}
            highlightedPin={this.state.highlightedPin}
            withProximitySearch
            toggleHighlightedPin={this.onHighlightedPinChange}
          />
        </div>

        {map(filteredItems, (result, index) => {
          return (
            <OfficeItem
              key={`result-${result.officeId}`}
              index={index}
              item={result}
              showDetails={this.showDetails}
            />
          );
        })}

        {isLoading && this.renderLoadingResults()}

        {this.renderPagination(totalItems)}
      </div>
    );
  }

  render() {
    return (
      <Row className="mt-5">
        <Colxx xxs="12" sm="3">
          <OfficeFilters
            params={this.props.params}
            status={this.props.status}
            amenities={this.state.amenities}
            onAmenityChange={this.onAmenityChange}
          />
        </Colxx>
        <Colxx xxs="12" sm="9" className="pb-5 mb-5">
          {this.renderResults()}
        </Colxx>

        {this.state.selected !== null && (
          <OfficeModal
            toggleModal={this.hideDetails}
            item={this.state.selected}
            reserveItem={this.reserveItem}
            user={this.props.user}
          />
        )}
      </Row>
    );
  }
}

export default OfficeResults;
