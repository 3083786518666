import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Form,
  Row,
  Label,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  Dropdown,
} from 'reactstrap';
import { Colxx } from '../../components/common/CustomBootstrap';
import DateSearch from '../../components/search/DateSearch';
import SearchButton from '../../components/search/SearchButton';
import { useIntl } from 'react-intl';
import IntlMessages from '../../helpers/IntlMessages';

import SearchStatus from '../../constants/searchStatus';
import CustomErrorMessage from '../../components/CustomErrorMessage';
import { validateFields, objectValidator } from '../../utils/fieldValidation';
import GoogleLocationSearch from '../../components/search/GoogleLocationSearch';
import Sequence from '../../utils/sequence';
import ItineraryParams from './ItineraryParams/ItineraryParams';

export default function ItineraryForm({
  search,
  startSearch,
  setOriginLocation,
  setDestinationLocation,
  setOutboundDate,
  setInboundDate,
  swapLocations,
  setParamValue,
}) {
  const { messages } = useIntl();

  // Refs
  const outboundRef = useRef();
  const inboundRef = useRef();
  const originRef = useRef();
  const destinationRef = useRef();

  // States
  const [originValue, setOriginValue] = useState();
  const [destinationValue, setDestinationValue] = useState();
  const [errors, setErrors] = useState({});

  const [showParams, setShowParams] = useState(false);

  const { outboundDate, inboundDate } = search;

  const sequence = useMemo(
    () =>
      new Sequence([
        [
          ({ search }, _) => search.origin.value,
          () => {
            if (originRef.current) originRef.current.children[0].focus();
          },
        ],
        [
          ({ search }, _) => search.destination.value,
          () => {
            if (destinationRef.current) {
              setTimeout(() => destinationRef.current.children[0].focus(), 100);
            }
          },
        ],
        [
          ({ search }, _) => search.outboundDate,
          () => {
            if (outboundRef.current) outboundRef.current.setFocus();
          },
        ],
        [
          ({ search }, _) => search.inboundDate,
          () => {
            if (inboundRef.current) inboundRef.current.setFocus();
          },
        ],
      ]),
    []
  );

  // Effects
  useEffect(() => {
    sequence.run({ search }, { search });
  }, [search]);

  // Functions
  const removePropertyFromErrors = (property) => {
    if (property in errors) {
      const { [property]: value, ...otherErrors } = errors;
      setErrors(otherErrors);
    }
  };

  const verifyFields = useCallback((search) => {
    const errors = validateFields(search, [
      ['origin', objectValidator],
      ['destination', objectValidator],
      ['inboundDate'],
      ['outboundDate'],
    ]);

    setErrors(errors);

    return !Object.keys(errors).length;
  }, []);

  const doStartSearch = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    if (search.status !== SearchStatus.LOADING && verifyFields(search)) {
      startSearch();
    }
  };

  // Render
  return (
    <Form className="travel-search" onSubmit={doStartSearch}>
      <Row>
        <Colxx xxs="5">
          <div ref={originRef}>
            <Label
              className="form-group search has-top-label w-45 float-left"
              onFocus={() => removePropertyFromErrors('origin')}
            >
              <GoogleLocationSearch
                setValue={setOriginLocation}
                value={originValue || search.origin}
              />
              <IntlMessages id="travel.origin" />
              <CustomErrorMessage error={errors.origin}>
                {messages['forms.validation.required']}
              </CustomErrorMessage>
            </Label>
          </div>

          <Label
            className="form-group search has-top-label w-10 float-left addon-places flip pointer"
            onClick={swapLocations}
          >
            <img
              src="/assets/img/icons/search_icon.svg"
              alt={messages['alts.containers.search.car-form']}
            />
          </Label>

          <div ref={destinationRef}>
            <Label
              className="form-group search has-top-label w-45 float-left"
              onFocus={() => removePropertyFromErrors('destination')}
            >
              <GoogleLocationSearch
                setValue={setDestinationLocation}
                value={destinationValue || search.destination}
              />
              <IntlMessages id="travel.destination" />
              <CustomErrorMessage
                error={errors.destination}
                style={{ animationDelay: '0.1s' }}
              >
                {messages['forms.validation.required']}
              </CustomErrorMessage>
            </Label>
          </div>
        </Colxx>
        <Colxx xxs="3">
          <div className="float-left w-50">
            <div>
              <Label
                className="form-group search has-top-label mb-2"
                onClick={(e) => e.preventDefault()}
                onFocus={() => removePropertyFromErrors('outbound_date')}
              >
                <DateSearch
                  selectedDate={outboundDate}
                  setValue={setOutboundDate}
                  isStart
                  defaultStartDate={outboundDate}
                  defaultEndDate={inboundDate}
                  ref={outboundRef}
                />
                <IntlMessages id="travel.outbound" />
                <CustomErrorMessage
                  error={errors.outbound_date}
                  style={{ animationDelay: '0.2s' }}
                >
                  {messages['forms.validation.required']}
                </CustomErrorMessage>
              </Label>
            </div>
          </div>

          <div className="float-left w-50">
            <Label
              className="form-group search has-top-label mb-2"
              onClick={(e) => e.preventDefault()}
              onFocus={() => removePropertyFromErrors('inbound_date')}
            >
              <DateSearch
                selectedDate={inboundDate}
                setValue={(e) => {
                  setInboundDate(e);
                }}
                defaultMinDate={outboundDate}
                isEnd
                defaultStartDate={outboundDate}
                defaultEndDate={inboundDate}
                ref={inboundRef}
              />
              <IntlMessages id="travel.inbound" />
              <CustomErrorMessage
                error={errors.inboundDate}
                style={{ animationDelay: '0.3s' }}
              >
                {messages['forms.validation.required']}
              </CustomErrorMessage>
            </Label>
          </div>
        </Colxx>

        <Colxx xxs="2">
          <Label className="form-group search has-top-label float-left w-100 dropdown-container">
            <Dropdown
              className="roomSelection"
              isOpen={showParams}
              toggle={(e) => setShowParams((v) => !v)}
            >
              <DropdownToggle caret>
                {messages['general.select']}
              </DropdownToggle>
              <DropdownMenu right className="itinerary-dropdown-menu">
                <ItineraryParams
                  params={search.params}
                  setParamValue={setParamValue}
                />
              </DropdownMenu>
            </Dropdown>
            <IntlMessages id="general.options" />
          </Label>
        </Colxx>

        <SearchButton status={search.status} />
      </Row>
    </Form>
  );
}
