import React, { Component } from 'react';
import IntlMessages from '../../helpers/IntlMessages';
import moment from 'moment';

import AlertCard from './AlertCard';

import { imageUrl } from '../../utils/image';
import { registeredBus } from '../../constants/travelValues';
import { injectIntl } from 'react-intl';

class FlightCard extends Component {
  render() {
    const {
      reservation,
      locations,
      order,
      intl: { messages },
    } = this.props;

    const busData = registeredBus[reservation.busDetails.carrier];

    return (
      <div className="item" data-testid={order.id}>
        <div className="date">
          <div className="content">
            <span className="day">
              {moment(
                reservation.busDetails.departureDateTime,
                'DD/MM/YYYY HH:mm'
              ).format('DD')}
            </span>
            <span className="month">
              {moment(
                reservation.busDetails.departureDateTime,
                'DD/MM/YYYY HH:mm'
              ).format('MMM')}
            </span>
          </div>
        </div>
        <div
          className="details"
          onClick={() => {
            this.props.openBus(reservation);
          }}
        >
          <div className="details-content bus">
            <div className="gradient">
              <div className="header">
                {busData && <img src={busData.logoBlack} alt={busData.name} />}
                <div>
                  <span>
                    <IntlMessages id="travel.bus.carrier" />
                  </span>
                  {reservation.busDetails.carrier}
                </div>
              </div>

              <div className="route clearfix">
                <div className="airport">
                  <span>
                    {reservation.busDetails.departureStation.split(',')[0]}
                  </span>
                  {moment(
                    reservation.busDetails.departureDateTime,
                    'DD/MM/YYYY HH:mm'
                  ).format('HH:mm')}
                </div>
                <div className="to text-center">
                  <img
                    src="/assets/img/icons/timeline_bus_white.svg"
                    alt={messages['alts.components.timeline.bus-card']}
                  />
                </div>
                <div className="airport text-right">
                  <span>
                    {reservation.busDetails.arrivalStation.split(',')[0]}
                  </span>
                  {moment(
                    reservation.busDetails.arrivalDateTime,
                    'DD/MM/YYYY HH:mm'
                  ).format('HH:mm')}
                </div>
              </div>
            </div>
          </div>

          <AlertCard order={order} />
        </div>
      </div>
    );
  }
}

export default injectIntl(FlightCard);
