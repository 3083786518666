export const chartTooltip = {
  backgroundColor: 'white',
  titleFontColor: '#8c8ca5',
  borderColor: '#d7d7d7',
  borderWidth: 0.5,
  bodyFontColor: '#8c8ca5',
  bodySpacing: 10,
  xPadding: 15,
  yPadding: 15,
  cornerRadius: 0.15,
};

export const smallLineChartOptions = {
  layout: {
    padding: {
      left: 5,
      right: 5,
      top: 10,
      bottom: 10,
    },
  },
  responsive: true,
  maintainAspectRatio: false,
  legend: {
    display: false,
  },
  scales: {
    y: {
      ticks: {
        beginAtZero: true,
      },
      display: false,
    },
    x: {
      display: false,
    },
  },
};

export const areaChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    tooltip: {
      enabled: false,
    },
    legend: {
      display: false,
    },
  },
  scales: {
    y: {
      gridLines: {
        display: true,
        lineWidth: 1,
        color: '#8c8ca5',
        drawBorder: false,
        zeroLineColor: '#8c8ca5',
        borderDash: [3, 3],
      },
      ticks: {
        beginAtZero: true,
        padding: 0,
        count: 3,
        maxTicksLimit: 3,
        fontColor: '#8c8ca5',
        callback: function (label, index, labels) {
          return (label / 1000).toFixed(0) + 'k';
        },
      },
    },
    x: {
      gridLines: {
        display: false,
      },
      ticks: {
        count: 4,
        maxTicksLimit: 4,
        padding: 0,
        fontColor: '#8c8ca5',
      },
    },
  },
};

export const gaugeChartOptions = {
  backgroundColor: '#000000',
  plugins: {
    tooltip: {
      enabled: false,
    },
    legend: {
      display: false,
    },
  },
};

export const pieChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'left',
      align: 'left',
      labels: {
        usePointStyle: true,
      },
    },
  },
};
