import { useCallback, useState } from 'react';
import { getIDWithinRangeFromLocation } from '../utils/locations';
import { mapIntoBooleanObject } from '../utils/others';

import { TCoordArray, TLocation, TObject } from '../types/others';

interface IProps {
  coords: TObject<TCoordArray>;
  setSelectedLocationIds: (ids: TObject<boolean>) => void;
  initialHighlightedPin?: TLocation;
  rangeInKm?: number;
}

export default function useMapHighlightedPin({
  initialHighlightedPin,
  setSelectedLocationIds,
  coords = {},
  rangeInKm = 1,
}: IProps) {
  const [highlightedPin, setHighlightedPin] = useState<TLocation | null>(
    () => initialHighlightedPin || null
  );

  // Functions
  const onSelectHighlightedPin = useCallback(
    (highlightedPin?: TLocation) => {
      let locationIds: TObject<boolean> = {};

      if (highlightedPin) {
        locationIds = mapIntoBooleanObject(
          getIDWithinRangeFromLocation(coords, highlightedPin, rangeInKm)
        );
      }

      setHighlightedPin(highlightedPin || null);
      setSelectedLocationIds(locationIds);
    },
    [coords, rangeInKm]
  );

  // Render
  return {
    highlightedPin,
    onSelectHighlightedPin,
  };
}
