import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Button, Label } from 'reactstrap';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import PinInput from 'react-pin-input';
import classNames from 'classnames';
import CustomErrorMessage from '../../CustomErrorMessage';
import appFunctions from '../../../services/Functions';
import SavingScreen from '../../common/SavingScreen';
import { getPassengerRedux } from '../../../utils/redux';

const initalState = { password: '', confirmation: '' };

export default function PinForm({
  cardId,
  setStep,
  setError,
  onSucess = () => {},
}) {
  const { messages } = useIntl();
  const { passenger } = useSelector(getPassengerRedux);

  const [stage, setStage] = useState(0);
  const [execute, setExecute] = useState(false);

  // Functions
  const changePassword = async (password) => {
    try {
      await appFunctions.updateExpenseCardPin(
        passenger.organizationId,
        cardId,
        password
      );
      setStep('SUCCESS');
      onSucess();
    } catch (err) {
      console.error(err);
      setStep('CANCEL');
      setError(messages['forms.validation.creditCard.genericError']);
    }
  };

  // Render
  const renderPassword = (setFieldValue) => {
    return (
      <div
        className={classNames('container text-center mb-5', {
          left: execute,
        })}
        key="password"
      >
        <Label className="text-center">
          <p>{messages['change-pin.password']}</p>
          <div className="custom-pin-input">
            <PinInput
              length={4}
              type="numeric"
              inputMode="number"
              secret
              focus
              onComplete={(password) => {
                setFieldValue('password', password);
                setExecute(true);
                setTimeout(() => {
                  setStage(1);
                  setExecute(false);
                }, 400);
              }}
            />
          </div>
        </Label>
      </div>
    );
  };

  const renderPasswordConfirmation = (setFieldValue, errors, values) => {
    return (
      <div
        className={classNames('container text-center mb-5', {
          right: execute,
        })}
        key="confirmation"
      >
        <Label className="text-center">
          <p>{messages['change-pin.confirmation']}</p>
          <div className="custom-pin-input">
            <PinInput
              length={4}
              type="numeric"
              inputMode="number"
              secret
              focus
              onComplete={(password) => {
                setFieldValue('confirmation', password, true);
              }}
              onChange={() => {
                if (values.confirmation)
                  setFieldValue('confirmation', '', true);
              }}
            />
          </div>
          <CustomErrorMessage
            error={errors.confirmation === 'Diff'}
            style={{ maxWidth: 200, marginTop: 10 }}
          >
            {messages['change-pin.not-same-password']}
          </CustomErrorMessage>
        </Label>
      </div>
    );
  };

  return (
    <div className="w-100 px-5 change-card-pin pin-form">
      <Formik
        initialValues={initalState}
        validationSchema={Yup.object().shape({
          password: Yup.string().min(4).required(),
          confirmation: Yup.string()
            .min(4)
            .required()
            .oneOf([Yup.ref('password')], 'Diff'),
        })}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            const { password, confirmation } = values;
            if (password === confirmation) {
              await changePassword(values.password);
            }
          } catch (err) {
            console.error(err);
            setSubmitting(false);
          }
        }}
        validateOnChange
        validateOnMount
      >
        {({
          isSubmitting,
          values,
          setFieldValue,
          setValues,
          isValid,
          errors,
        }) => (
          <Form>
            <SavingScreen show={isSubmitting} noBackground />
            {stage === 0
              ? renderPassword(setFieldValue)
              : renderPasswordConfirmation(setFieldValue, errors, values)}
            <div
              className={`bottom-btns d-flex align-items-center justify-content-end ${
                stage === 0 ? '' : 'final-stage'
              }`}
            >
              <Button
                className="back-btn m-0 p-0 mr-3"
                type="button"
                color="link"
                size="sm"
                onClick={() => {
                  setStage(0);
                  setValues(initalState);
                }}
              >
                {messages['general.go-back']}
              </Button>
              <Button
                className="pretty-btn submit-btn"
                type="submit"
                color="primary"
                disabled={isSubmitting || !isValid}
                size="md"
              >
                {messages['change-pin.submit']}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
