import md5 from 'md5';
import searchStatus from '../constants/searchStatus';
import { getOrganizationConfig } from '../utils/organization';
import { DEFAULT_CURRENCY } from './money';
import firebaseApp from '../services/Firebase';
import { min } from 'lodash';

export const PROVIDERS = {
  package: { IDEASFRACTAL: 'new', ITERPEC: 'new' },
  hotel: { ITERPEC: 'new' },
  offline_hotels: { ITERPEC: 'new', OFFLINE_HOTELS: 'new' },
  flight: { IDEASFRACTAL: 'new' },
  car: { ITERPECCAR: 'new' },
  bus: { CLICKBUS: 'new' },
  office: { OFFICES: 'new' },
  flight_external: { KAYAK: 'new' },
};

export const getSearchCode = (
  type,
  outboundIata,
  outboundDate,
  inboundIata,
  inboundDate,
  currency = DEFAULT_CURRENCY,
  params = ''
) => {
  let code = '';

  if (type === 'package') {
    code = `${outboundIata}-${outboundDate.format(
      'YYYYMMDD'
    )}-${inboundIata}-${inboundDate.format('YYYYMMDD')}`;
  }

  if (type === 'hotel') {
    code = `${outboundDate.format(
      'YYYYMMDD'
    )}-${inboundIata}-${inboundDate.format('YYYYMMDD')}-${params}`;
  }

  if (type === 'flight') {
    code = `${outboundIata}-${outboundDate.format('YYYYMMDD')}-${inboundIata}-${
      inboundDate ? inboundDate.format('YYYYMMDD') : ''
    }-${params.baggages}-${params.cabin}`;
  }

  if (type === 'car') {
    code = `${outboundIata}-${outboundDate.format(
      'YYYYMMDDHHmm'
    )}-${inboundIata}-${inboundDate.format('YYYYMMDDHHmm')}`;
  }

  if (type === 'bus') {
    code = `${outboundIata}-${outboundDate.format(
      'YYYYMMDDHHmm'
    )}-${inboundIata}-${inboundDate ? inboundDate.format('YYYYMMDDHHmm') : ''}`;
  }

  if (type === 'office') {
    code = `office-${outboundIata}`;
  }

  return md5(`${code}-${currency}`);
};

export const getProviders = (searchType) => {
  return PROVIDERS[searchType] || {};
};

export const getHotelSearchParams = (search, organization = {}) => {
  const {
    destination,
    outbound_date,
    inbound_date,
    hotel_params,
    currency = DEFAULT_CURRENCY,
  } = search;

  // Validate
  if (!outbound_date || !destination.value || !inbound_date) {
    return {};
  }

  const withOfflineHotel =
    getOrganizationConfig(organization.config, 'roomsAdded') > 0 &&
    getOrganizationConfig(organization.config, 'connectors.OFFLINE_HOTELS') !==
      false;

  const searchCode = getSearchCode(
    'hotel',
    '',
    outbound_date,
    destination.value,
    inbound_date,
    currency,
    `${hotel_params.adults}-${hotel_params.rooms}-${hotel_params.nationality}${
      withOfflineHotel ? `-${organization.id}` : ''
    }`
  );

  const payload = {
    originDate: outbound_date.format('DD/MM/YYYY'),
    destinationCode: destination.value,
    destinationDate: inbound_date.format('DD/MM/YYYY'),
    providers: getProviders(withOfflineHotel ? 'offline_hotels' : 'hotel'),
    hotel_params: {
      ...hotel_params,
      ...(withOfflineHotel ? { organizationId: organization.id } : {}),
    },
    status: searchStatus.LOADING,
  };

  return { searchCode, payload };
};

export const getFareOptionsFromSegments = (segments, item) => {
  let fares = {};

  segments.forEach((segment) => {
    if (segment.faresOptions.length === 0) {
      segment.faresOptions.push({
        name: segment.flights[0].fare.familyName,
        TotalFare: {
          Total: item.fare.total,
          TotalTaxes: item.fare.taxes,
          TotalFare: item.fare.fare,
        },
        airline: segment.validatingAirline,
      });
    }

    segment.faresOptions.forEach((fare) => {
      if (!fares[fare.FareFamilyName]) {
        fares[fare.FareFamilyName] = {
          name: fare?.name || fare.FareFamilyName,
          total: 0,
          taxes: 0,
          fare: 0,
          airline: segment.validatingAirline,
        };
      }
      fares[fare.FareFamilyName].total = fare.TotalFare.Total;
      fares[fare.FareFamilyName].taxes = fare.TotalFare.TotalTaxes;
      fares[fare.FareFamilyName].fare = fare.TotalFare.TotalFare;
    });
  });

  const result = Object.keys(fares).map((fare) => {
    return fares[fare];
  });

  return result;
};

export const updateSegmentFlightFare = (segments, fareOption) => {
  const fareName = fareOption.name;

  segments.forEach((segment) => {
    const fare = segment.faresOptions.find(
      (option) => option.FareFamilyName === fareName
    );

    segment.flights.forEach((flight) => {
      flight.fare = {
        ...flight.fare,
        baggage: Number(fare?.BaggageAllowance.split('')[0]),
        baggageWeight: fare?.BaggageWeight.replace(/\D/g, ''),
        base: fare?.FareBase,
        class: fare?.Class,
        family: fare?.FareFamily,
        familyName: fare?.FareFamilyName,
        sellKey: fare?.SellKey,
      };
    });
  });
};

export const userHasSingleSearch = (user) => {
  return (user.organizationId === 'wFHg8FuQSUgs03z4oaQt' &&
    (user.email === 'raiana.martins@sulamerica.com.br' ||
      user.email === 'marcia.camara@sulamerica.com.br' ||
      user.email === 'adriana.sasso@sulamerica.com.br' ||
      user.email === 'margarida.garcia@sulamerica.com.br' ||
      user.email === 'caroline.tamura@sulamerica.com.br' ||
      user.email === 'mariana.quirino@sulamerica.com.br' ||
      user.email === 'angra.oliveira@sulamerica.com.br')) ||
    user.organizationId === 'BZVlNmBmjlzAAOXDgw4c' ||
    user.organizationId === 'fM25RPQNiEfb9sXYuGNF'
    ? true
    : false;
};

export const isPassengerAllowedToHaveReservationWithDifferentFare = (
  passengerEmail
) => {
  const allowedPassengers = [
    'heitor.augusto@sulamerica.com.br',
    'pablo.meneses@sulamerica.com.br',
    'raquel.reis@sulamerica.com.br',
    'jefferson.klock@sulamerica.com.br',
    'marcelo.mello@sulamerica.com.br',
    'rafael.froes@sulamerica.com.br',
    'ricardo@ltnbrasil.com.br',
    'fabianagama@sememail.com',
    'mariaeduarda@sememail.com.br',
    'mariaeduarda@sememail.com.br',
  ];
  return allowedPassengers.includes(passengerEmail);
};

export const isCheapestFare = (total, item) => {
  const segments = item.journeys[0].segments.filter(
    (segment) => segment.faresOptions.length > 0
  );

  const cheapestFare = min(
    segments[0].faresOptions.map((fare) => fare.TotalFare.Total)
  );

  return total === cheapestFare;
};
