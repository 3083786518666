import Excel from 'exceljs';
import { verifyMissingMessages } from '../lang/locales';
import ptBR from '../lang/locales/pt_BR';
import { getExcelFromMessages } from './reports';

export const getMaxLengthOfColumns = (csv, columns) => {
  const numItems = csv.length;

  if (numItems) {
    columns = columns ? columns : [...Array(csv[0].length).keys()];
    const numColumns = columns.length;
    const maxes = new Array(numColumns).fill(0);
    for (let i = 0; i < numItems; i++) {
      for (let j = 0; j < numColumns; j++) {
        const columnLength = csv[i][columns[j]].length;
        if (columnLength > maxes[j]) maxes[j] = columnLength;
      }
    }

    return maxes;
  } else return null;
};

export const generateXLSX = async (
  worksheetName,
  columns,
  rows,
  fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
) => {
  const workbook = new Excel.Workbook();
  const worksheet = workbook.addWorksheet(worksheetName);

  worksheet.columns = columns;
  worksheet.addRows(rows);

  const headerRow = worksheet.getRow(1);
  headerRow.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: '1143418e' },
  };
  headerRow.font = {
    color: { argb: '11FFFFFF' },
    bold: true,
  };
  headerRow.height = 30;

  const xlsxBuffer = await workbook.xlsx.writeBuffer();
  return new Blob([xlsxBuffer], { type: fileType });
};

export const downloadFromURL = (url, fileName) => {
  const link = document.createElement('a');
  link.style.display = 'none';
  link.href = url;
  link.download = fileName;

  document.body.appendChild(link);

  link.dispatchEvent(
    new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window,
    })
  );

  URL.revokeObjectURL(url);
  document.body.removeChild(link);
};

export const downloadBlob = (blob, fileName) => {
  //IE11 support
  if (window.navigator?.msSaveOrOpenBlob)
    return window.navigator.msSaveOrOpenBlob(blob, fileName);

  const exportUrl = window.URL.createObjectURL(blob);

  downloadFromURL(exportUrl, fileName);
};

export const downloadMissingFile = async () => {
  try {
    const missingMessages = verifyMissingMessages();

    missingMessages.forEach(async ({ lang, missingMessages }) => {
      const file = await getExcelFromMessages({
        name: lang,
        rows: missingMessages.map((key) => [key, ptBR[key]]),
      });

      await downloadBlob(file, `${lang}.xlsx`);
    });
  } catch (err) {
    console.error(err);
  }
};

export const openBlob = (blob) => {
  //IE11 support
  if (window.navigator?.msSaveOrOpenBlob)
    return window.navigator.msSaveOrOpenBlob(blob, fileName);

  const fileURL = window.URL.createObjectURL(blob);
  window.open(fileURL, '_blank');
};
