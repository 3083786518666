import classNames from 'classnames';
import React from 'react';

export default function SelectWrapper({ className, item, children }) {
  return (
    <div className={classNames('select-wrapper', className, { show: item })}>
      {children}
    </div>
  );
}
