import React, { useMemo, useState } from 'react';
import { Colxx } from '../../../../../common/CustomBootstrap';
import { Card, CustomInput } from 'reactstrap';
import moment from 'moment';
import map from 'lodash/map';
import isEqual from 'lodash/isEqual';
import { trackPropertiesSegment } from '../../../../../../utils/segment';
import { useIntl } from 'react-intl';

import { ReactComponent as MoneyIcon } from '../../../../../../assets/img/icons/money.svg';

function FlightSegment({
  index,
  iJ,
  segment,
  iS,
  duration,
  connections,
  stops,
  selectedSegments,
  selectSegment,
  maxSymbolItems,
}) {
  const { messages } = useIntl();

  const {
    departureDateTime,
    arrivalDateTime,
    symbolsOpacityArr = [],
  } = useMemo(() => {
    const { departureDateTime, arrivalDateTime, estimatedPrice } = segment;

    return {
      departureDateTime: moment(departureDateTime).format('HH:mm'),
      arrivalDateTime: moment(arrivalDateTime).format('HH:mm'),
      symbolsOpacityArr: new Array(maxSymbolItems)
        .fill(false)
        .map((_, index) => (index < estimatedPrice ? 1 : 0.3)),
    };
  }, [segment]);

  // Render
  if (stops[segment.flights.length - 1] === false) return null;

  if (duration) {
    if (
      segment.duration < duration.selected_min ||
      segment.duration > duration.selected_max
    )
      return null;
  }

  if (connections) {
    if (
      segment.flights.some(
        (flight) => connections[flight.arrivalStation] === false
      )
    )
      return null;
  }

  const renderDuration = (segment, startTime, endTime) => {
    if (segment.duration) {
      const hours = Math.floor(segment.duration);
      const minutes = Math.floor(
        (segment.duration - Math.floor(segment.duration)) * 60
      );

      return `${hours}h ${minutes}m`;
    }

    var start = new moment(startTime);
    var end = new moment(endTime);
    var duration = moment.duration(end.diff(start));

    return `${Math.floor(duration.asHours())}h ${duration.minutes()}m`;
  };

  const renderArrivalDays = (startTime, endTime) => {
    var start = new moment(startTime).startOf('day');
    var end = new moment(endTime).startOf('day');
    var daysDiff = end.diff(start, 'days');

    if (daysDiff > 0) {
      return <span>+{daysDiff}</span>;
    }
  };

  return (
    <div className="row trip">
      <Colxx xxs="2" className="selection">
        <CustomInput
          type="checkbox"
          className="custom-check"
          id={`journey-${index}-${iJ}-${iS}`}
          checked={isEqual(selectedSegments[iJ], segment)}
          onChange={() => {
            trackPropertiesSegment('Flight alteration segment selected', {
              segment,
              type: iJ ? 'Inbound' : 'Outbound',
            });
            selectSegment(iJ, iS);
          }}
        />
      </Colxx>

      <Colxx xxs="2" className="time">
        {departureDateTime}
      </Colxx>

      <Colxx xxs="1" className="stops-chart px-0">
        <div className="graph">
          <div className="line">
            <div className="dots">
              {map(segment.flights, (_, iFI) => {
                if (iFI === 0) return '';
                return (
                  <div
                    key={`result-${index}-${iJ}-${iS}-${iFI}`}
                    className="block"
                  />
                );
              })}
            </div>
          </div>
        </div>
        <div className="info">
          {segment.flights.length - 1 === 0
            ? messages['flight.no-stops']
            : segment.flights.length - 1 === 1
            ? `1 ${messages['flight.stop']}`
            : `${segment.flights.length - 1} ${messages['flight.stops']}`}
        </div>
      </Colxx>

      <Colxx xxs="2" className="time">
        {arrivalDateTime}
        {renderArrivalDays(segment.departureDateTime, segment.arrivalDateTime)}
      </Colxx>

      <Colxx xxs="2" className="duration">
        {renderDuration(
          segment,
          segment.departureDateTime,
          segment.arrivalDateTime
        )}
      </Colxx>

      <Colxx xxs="3" className="estimated-price">
        {symbolsOpacityArr.map((opacity, index) => (
          <MoneyIcon
            key={`symbol-${index}`}
            width="0.85rem"
            height="0.85rem"
            className="text-primary"
            style={{ opacity }}
          />
        ))}
      </Colxx>
    </div>
  );
}

function FlightJourney({
  journey,
  iJ,
  index,
  departureDuration,
  arrivalDuration,
  departureConnections,
  arrivalConnections,
  ...props
}) {
  const { messages } = useIntl();

  const { duration, connections, formattedDepartureDate, typeMessage } =
    useMemo(() => {
      return {
        duration: iJ === 0 ? departureDuration : arrivalDuration,
        connections: iJ === 0 ? departureConnections : arrivalConnections,
        typeMessage: messages[iJ === 0 ? 'travel.outbound' : 'travel.inbound'],
        formattedDepartureDate: moment(journey.departureDate).format(
          'ddd DD MMM YYYY'
        ),
      };
    }, [iJ, departureDuration, arrivalDuration]);

  // Render
  return (
    <div className="journey">
      <div className="row header">
        <Colxx xxs="2" className="flight-info">
          <div className="title">
            <img
              src="/assets/img/icons/journey_icon.svg"
              alt={messages['alts.containers.search.flight-item']}
            />
            {typeMessage}
            <span className="day">{formattedDepartureDate}</span>
          </div>
        </Colxx>
        <Colxx xxs="2" className="station-container">
          <div className="station">{journey.departureStation}</div>
        </Colxx>
        <Colxx xxs="1"></Colxx>
        <Colxx xxs="2" className="station-container">
          <div className="station">{journey.arrivalStation}</div>
        </Colxx>
        <Colxx xxs="2"></Colxx>
        <Colxx xxs="3">
          <div className="font-weight-medium fs-0-9rem">
            {messages['general.price']}
          </div>
        </Colxx>
      </div>

      {map(journey.segments, (segment, iS) => {
        return (
          <FlightSegment
            key={`journey-${index}-${iJ}-${iS}`}
            index={index}
            journey={journey}
            iJ={iJ}
            segment={segment}
            iS={iS}
            duration={duration}
            connections={connections}
            {...props}
          />
        );
      })}
    </div>
  );
}

export default function FlightAlterationItem({
  item,
  index,
  stops = {},
  maxSymbolItems = 3,
  doSegmentSelection,
}) {
  const [selectedSegments, setSelectedSegments] = useState([]);

  // Functions
  const selectSegment = (journeyIndex, segmentIndex) => {
    const newSelectedSegments = { ...selectedSegments };
    const selectedSegment = item.journeys[journeyIndex].segments[segmentIndex];

    newSelectedSegments[journeyIndex] = {
      ...selectedSegment,
    };

    setSelectedSegments(newSelectedSegments);
    doSegmentSelection(selectedSegment);
  };

  return (
    <Card className="search-card flight mt-5">
      <Colxx xxs="12" lg="12" className="journeys">
        {map(item.journeys, (journey, i) => {
          return (
            <FlightJourney
              key={`journey-${index}-${i}`}
              journey={journey}
              iJ={i}
              departureDuration={journey.departureStation}
              arrivalDuration={journey.arrivalStation}
              stops={stops}
              selectedSegments={selectedSegments}
              selectSegment={selectSegment}
              maxSymbolItems={maxSymbolItems}
            />
          );
        })}
      </Colxx>
    </Card>
  );
}
