export const ORDER_PROVIDERS = {
  IDEASFRACTAL: 'IDEASFRACTAL',
  ITERPEC: 'ITERPEC',
  OFFLINE_HOTELS: 'OFFLINE_HOTELS',
  ITERPECCAR: 'ITERPECCAR',
  CLICKBUS: 'CLICKBUS',
  OFFICES: 'OFFICES',
  KAYAK: 'KAYAK',
  FLIGHT_OPEN_BOOKING: 'FLIGHT_OPEN_BOOKING',
};
