import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import map from 'lodash/map';

import firebaseApp from '../../services/Firebase';

import LoaderCardWithText from '../../components/loaders/LoaderCardWithText';
import InvoiceCard from '../../components/finance/InvoiceCard';

export default function CreditCardsDataListView() {
  const [invoices, setInvoices] = useState([]);
  const [isLoading, setLoading] = useState(true);

  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    loadInvoices();
  }, []);

  // Handle Invoices
  const loadInvoices = async () => {
    const invoices = await firebaseApp.getInvoicesFromOrganization(
      user.organizationId
    );

    setInvoices(invoices);
    setLoading(false);
  };

  // Render
  if (isLoading) {
    return (
      <div className="mt-5">
        <LoaderCardWithText />
        <LoaderCardWithText />
        <LoaderCardWithText />
        <LoaderCardWithText />
        <LoaderCardWithText />
      </div>
    );
  }

  return (
    <div className="mt-5">
      {map(invoices, (invoice, index) => {
        return <InvoiceCard invoice={invoice} key={`invoice-${index}`} />;
      })}
    </div>
  );
}
