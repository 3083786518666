import React, { useState, useEffect, useMemo, useRef } from 'react';
import { hexToRgb } from '../../utils/colors';
import CreditCard from './CreditCard';

const fetchIcon = async (icon = {}) => {
  const response = await import(
    `!@svgr/webpack!../../assets/img/companies/${icon.value || icon}.svg`
  );
  const { default: Icon } = response;
  return Icon;
};

export default function VirtualCard({
  card,
  rgbColor,
  withHover = true,
  blocked = false,
  toggleBlock = () => {},
  loadingPan,
  showPan,
  pan,
}) {
  const { icon, color = '#43418e' } = card;

  const [_, setIconName] = useState('');
  const Icon = useRef(null);

  const rgbaColor = useMemo(() => {
    const value = rgbColor || hexToRgb(color);

    if (!value) return `rgba(0, 0, 0, 0.05)`;

    const { r, g, b } = value;

    return `rgba(${r}, ${g}, ${b}, 0.05)`;
  }, [color, rgbColor]);

  // Effects
  useEffect(() => {
    const loadIcon = async () => {
      if (icon) {
        try {
          Icon.current = await fetchIcon(icon);
        } catch (err) {
          console.error(err);
          Icon.current = null;
        }
      } else Icon.current = null;

      setIconName(icon);
    };

    loadIcon();
  }, [icon]);

  // Render
  return (
    <CreditCard
      card={card}
      backgroundColor={rgbaColor}
      Icon={() => (
        <div className="icon">
          {Icon.current ? (
            <Icon.current fill={color} className="icon-svg" />
          ) : null}
        </div>
      )}
      blocked={blocked}
      toggleBlock={toggleBlock}
      showDetails={showPan}
      details={pan}
      loadingDetails={loadingPan}
      withHover={withHover}
    />
  );
}
