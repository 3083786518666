import React from 'react';
import {
  Font,
  Image,
  Page,
  Document,
  View,
  Text,
  StyleSheet,
} from '@react-pdf/renderer';
import { getCurrencyAndPrice, stringToMoney } from '../../../utils/money';
import MSRegular from '../../../assets/fonts/Montserrat-Regular.ttf';
import MSMedium from '../../../assets/fonts/Montserrat-Medium.ttf';
import MSSemiBold from '../../../assets/fonts/Montserrat-SemiBold.ttf';
import MSBold from '../../../assets/fonts/Montserrat-Bold.ttf';
import moment from 'moment';
import { RefundTypes } from '../../../constants/refundTypes';
import { hexToRgb } from '../../../utils/colors';
import { getOrganizationConfig } from '../../../utils/organization';

import physicalIcon from '../../../assets/img/expenses/card_icons.png';
import personalIcon from '../../../assets/img/expenses/personal_icon.png';
import administrativeIcon from '../../../assets/img/expenses/administrative_icon.png';

Font.register({
  family: 'Montserrat',
  fonts: [
    { src: MSRegular },
    { src: MSMedium, fontWeight: 500 },
    { src: MSSemiBold, fontWeight: 600 },
    { src: MSBold, fontWeight: 700 },
  ],
});

const styles = StyleSheet.create({
  topHeader: {
    paddingHorizontal: 60,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  boldAndUpper: {
    textTransform: 'uppercase',
    fontWeight: 600,
  },
  logo: {
    width: 120,
    maxHeight: 55,
    objectFit: 'contain',
  },
  organizationName: {
    maxWidth: 500,
    marginBottom: 2,
    flexWrap: 'wrap',
  },
  table: {
    fontSize: 10,
    fontFamily: 'Montserrat',
    marginVertical: 30,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'nowrap',
    paddingVertical: 8,
    paddingHorizontal: 20,
    fontSize: 8,
  },
  rowHeader: {
    fontSize: 8,
    textTransform: 'uppercase',
    fontWeight: 600,
    paddingBottom: 10,
  },
  icon: {
    width: 15,
    height: 15,
    marginRight: 10,
    objectFit: 'contain',
  },
  name: { width: '11%', flexWrap: 'wrap' },
  user: {
    width: '14%',
    flexWrap: 'wrap',
    textAlign: 'center',
    paddingHorizontal: 10,
  },
  paymentType: { width: '8%', textAlign: 'center' },
  category: { width: '14%', flexWrap: 'wrap', textAlign: 'center' },
  createdAt: { width: '12%', textAlign: 'center' },
  startDate: { width: '9%', textAlign: 'center' },
  endDate: { width: '9%', textAlign: 'center' },
  budget: { width: '15%', textAlign: 'center' },
  total: { width: '10%', textAlign: 'right' },
  line: {
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    height: 0.5,
    marginVertical: 10,
  },
  card: {
    width: 100,
    height: 60,
    borderRadius: 3,
    backgroundColor: 'black',
    color: 'white',
    paddingVertical: 7,
    paddingHorizontal: 7,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  cardInfo: {
    title: {
      fontSize: 8,
      opacity: 0.5,
      fontWeight: 500,
      textAlign: 'center',
    },
    value: {
      fontSize: 13,
      textAlign: 'center',
    },
  },
  cardName: {
    textTransform: 'uppercase',
    fontSize: 6,
  },
  cardNumber: {
    fontSize: 7,
    letterSpacing: 1,
    marginBottom: 4,
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

function PhysicalCard(card) {
  const { lastDigits, name } = card;

  return (
    <View style={[styles.card, { backgroundColor: '#43418e', color: 'white' }]}>
      <Image
        src={physicalIcon}
        style={{ position: 'absolute', top: 10, right: 10, width: 20 }}
      />
      <View style={[styles.cardInfo, { color: 'white' }]}>
        {lastDigits ? (
          <Text style={styles.cardNumber}>•••• •••• •••• {lastDigits}</Text>
        ) : null}
        <Text style={styles.cardName}>{name}</Text>
      </View>
    </View>
  );
}

function VirtualCard(card) {
  const { lastDigits, name, color = '#000000', icon } = card;
  const { r, g, b } = hexToRgb(color);

  return (
    <View
      style={[
        styles.card,
        {
          border: `1px solid ${color}`,
          color,
          backgroundColor: `rgba(${r}, ${g}, ${b}, 0.05)`,
        },
      ]}
    >
      {icon ? (
        <Text
          style={{
            textTransform: 'uppercase',
            color,
            position: 'absolute',
            top: 7,
            right: 7,
            fontSize: 5,
            fontWeight: 600,
          }}
        >
          {icon}
        </Text>
      ) : null}
      <View style={styles.cardInfo}>
        {lastDigits ? (
          <Text style={styles.cardNumber}>•••• •••• •••• {lastDigits}</Text>
        ) : null}
        <Text style={styles.cardName}>{name}</Text>
      </View>
    </View>
  );
}

function AdministrativeCard(card) {
  const { name } = card;
  const color = '#000000';
  const { r, g, b } = hexToRgb(color);

  return (
    <View
      style={[
        styles.card,
        {
          border: `1px solid ${color}`,
          color,
          backgroundColor: `rgba(${r}, ${g}, ${b}, 0.05)`,
        },
      ]}
    >
      <Image
        src={administrativeIcon}
        style={{ position: 'absolute', top: 10, right: 10, width: 15 }}
      />
      <View style={styles.cardInfo}>
        <Text style={styles.cardName}>{name}</Text>
      </View>
    </View>
  );
}

function PersonalCard({ name }) {
  return (
    <View
      style={[
        styles.card,
        {
          border: '1px solid #000000',
          backgroundColor: 'white',
          color: '#000000',
        },
      ]}
    >
      <Image
        src={personalIcon}
        style={{ position: 'absolute', top: 5, left: 5, width: 20 }}
      />
      <View style={styles.cardInfo}>
        <Text style={styles.cardName}>{name}</Text>
      </View>
    </View>
  );
}

const CARD_COMPONENTS = {
  [RefundTypes.PHYSICAL_CARD]: PhysicalCard,
  [RefundTypes.VIRTUAL_CARD]: VirtualCard,
  [RefundTypes.PERSONAL_ACCOUNT]: PersonalCard,
  [RefundTypes.ADMINISTRATIVE_ACCOUNT]: AdministrativeCard,
};

export default async function BudgetPDF({
  items,
  cards,
  organization,
  dateSelection,
  messages,
  isDetailed,
  pageSize = 'A4',
}) {
  const [currencySymbol, _] = getCurrencyAndPrice(
    10,
    getOrganizationConfig(organization.config, 'currency')
  );

  const { total, totalSpent } = items.reduce(
    (obj, value) => {
      const total = value[9];

      obj.total += value[9];
      if (value[10]) obj.totalSpent += total;

      return obj;
    },
    {
      total: 0,
      totalSpent: 0,
    }
  );

  // Render
  const renderItem = (
    [
      budgetName,
      passenger,
      cardType,
      category,
      createdAt,
      startDate,
      endDate,
      anticipationDate,
      budgetValue,
      total,
    ],
    index
  ) => (
    <View
      style={{
        ...styles.row,
        backgroundColor: index % 2 === 0 ? 'rgba(67, 65, 142, 0.03)' : 'white',
      }}
      key={index}
      wrap={false}
    >
      <Text style={styles.name}>{budgetName}</Text>
      <Text style={styles.user}>{passenger}</Text>
      <Text style={styles.paymentType}>{cardType}</Text>
      <Text style={styles.category}>{category}</Text>
      <Text style={styles.createdAt}>{createdAt}</Text>
      <Text style={styles.startDate}>{startDate}</Text>
      <Text style={styles.endDate}>{endDate}</Text>
      <Text style={styles.budget}>
        {budgetValue ? budgetValue.toFixed(2) : '-'}
      </Text>
      <Text style={styles.total}>{total.toFixed(2)}</Text>
    </View>
  );

  const renderHeader = () => (
    <View style={[styles.row, styles.rowHeader]}>
      <Text style={styles.name}>{messages['reports.excel.budget.name']}</Text>
      <Text style={styles.user}>
        {messages['reports.excel.budget.passengerName']}
      </Text>
      <Text style={styles.paymentType}>
        {messages['reports.excel.budget.cardType']}
      </Text>
      <Text style={styles.category}>
        {messages['reports.excel.budget.budgetCategory']}
      </Text>
      <Text style={styles.createdAt}>
        {messages['reports.excel.budget.createdAt']}
      </Text>
      <Text style={styles.startDate}>
        {messages['reports.excel.budget.startDate']}
      </Text>
      <Text style={styles.endDate}>
        {messages['reports.excel.budget.endDate']}
      </Text>
      <Text style={styles.budget}>
        {messages['reports.excel.budget.budgetValue']} ({currencySymbol})
      </Text>
      <Text style={styles.total}>
        {messages['reports.excel.total']} ({currencySymbol})
      </Text>
    </View>
  );

  const renderCard = (card, items) => {
    const renderCardComponent = CARD_COMPONENTS[card.type];

    let total = 0,
      totalSpent = 0;

    items.forEach((item) => {
      const budget = item[8],
        itemTotal = item[9],
        wasApproved = item[10];

      total += itemTotal || budget;
      if (wasApproved) totalSpent += itemTotal;
    });

    const renderCardInfo = (value, title) => {
      return (
        <View style={styles.center}>
          <Text style={styles.cardInfo.value}>{value}</Text>
          <Text style={styles.cardInfo.title}>{title}</Text>
        </View>
      );
    };

    return (
      <View
        wrap={false}
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-evenly',
          paddingTop: 15,
          paddingBottom: 25,
        }}
      >
        {renderCardComponent(card)}
        {renderCardInfo(items.length, '# Orçamentos')}
        {renderCardInfo(stringToMoney(total, 2), 'Total dos orçamentos')}
        {renderCardInfo(stringToMoney(totalSpent, 2), 'Gastos efetivos')}
      </View>
    );
  };

  const renderItems = (items) => (
    <>
      {renderHeader()}
      {items.map((budget, index) => renderItem(budget, index))}
    </>
  );

  const renderCardContainer = (card, items) => {
    return (
      <View>
        {renderCard(card, items)}
        {renderItems(items)}
      </View>
    );
  };

  const renderByCards = () => {
    const sorted = Object.values(
      items.reduce((obj, item) => {
        const cardId = item[11];

        if (cardId in obj) obj[cardId].items.push(item);
        else {
          obj[cardId] = {
            card: cards[cardId],
            items: [item],
          };
        }

        return obj;
      }, {})
    );

    return sorted.map(({ card, items }, index) => (
      <>
        {index > 0 ? <View style={styles.line} /> : null}
        {renderCardContainer(card, items)}
      </>
    ));
  };

  return (
    <Document>
      <Page
        wrap={true}
        size={pageSize}
        style={{ paddingVertical: 40, fontFamily: 'Montserrat' }}
      >
        <View style={styles.topHeader}>
          <Image style={styles.logo} src="/assets/img/logo.png" />
          <View style={{ fontSize: 11, marginLeft: 15 }}>
            <Text style={[styles.boldAndUpper, styles.organizationName]}>
              {organization.name}
            </Text>
            {dateSelection.mode === 'all' ? (
              <Text style={styles.boldAndUpper}>
                {messages['reports.pdf.all-reservations']}
              </Text>
            ) : (
              <View style={{ display: 'flex', flexDirection: 'row' }}>
                <Text style={styles.boldAndUpper}>
                  {messages['reports.pdf.from']}
                </Text>
                <Text
                  style={{ marginHorizontal: 5, textTransform: 'uppercase' }}
                >
                  {moment.unix(dateSelection.startDate).format('DD MMM YYYY')}
                </Text>
                <Text style={styles.boldAndUpper}>
                  {messages['reports.pdf.to']}
                </Text>
                <Text
                  style={{ marginHorizontal: 5, textTransform: 'uppercase' }}
                >
                  {moment.unix(dateSelection.endDate).format('DD MMM YYYY')}
                </Text>
              </View>
            )}
          </View>
        </View>
        <View style={styles.table}>
          {isDetailed ? renderByCards() : renderItems(items)}
        </View>
        <View
          style={{
            display: 'flex',
            alignItems: 'end',
            justifyContent: 'flex-end',
            flexDirection: 'row',
          }}
        >
          <View
            style={{
              display: 'flex',
              alignItems: 'flex-end',
              paddingHorizontal: 20,
            }}
          >
            <Text style={{ fontSize: 22 }}>
              {stringToMoney(
                total,
                2,
                getOrganizationConfig(organization.config, 'currency')
              )}
            </Text>
            <Text
              style={{
                fontSize: 10,
                color: '#43418e',
                textTransform: 'uppercase',
                fontWeight: 700,
                textAlign: 'right',
              }}
            >
              {messages['reports.total-value']}
            </Text>
          </View>
          <View
            style={{
              display: 'flex',
              alignItems: 'flex-end',
              paddingHorizontal: 20,
            }}
          >
            <Text style={{ fontSize: 22 }}>
              {stringToMoney(
                totalSpent,
                2,
                getOrganizationConfig(organization.config, 'currency')
              )}
            </Text>
            <Text
              style={{
                fontSize: 10,
                color: '#43418e',
                textTransform: 'uppercase',
                fontWeight: 700,
                textAlign: 'right',
              }}
            >
              {messages['reports.excel.budget.approved-total']}
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
}
