import { Row } from 'reactstrap';
import { Colxx } from '../../../components/common/CustomBootstrap';
import { useIntl } from 'react-intl';
import FlightParamsOptions from './options/FlightParamsOptions';
import HotelParamsOptions from './options/HotelParamsOptions';
import CarParamsOptions from './options/CarParamsOptions';
import BusParamsOptions from './options/BusParamsOptions';
import useDataLoader from 'src/portao3-legacy/hooks/useDataLoader';
import firebaseApp from 'src/portao3-legacy/services/Firebase';
import { useSelector } from 'react-redux';
import { getAuthRedux } from 'src/portao3-legacy/utils/redux';

import 'rc-switch/assets/index.css';
import { OrderTypes } from 'src/portao3-legacy/constants/orderTypes';
import { ReactComponent as ApprovalImg } from '../../../assets/img/expenses/approvals.svg';

function formatParams(params) {
  return params.reduce((obj, param) => {
    obj[param.id] = param;
    return obj;
  }, {});
}

export default function TravelParamsConfigView() {
  const { messages } = useIntl();
  const { user } = useSelector(getAuthRedux);

  // States
  const [searchParams, _, isLoading] = useDataLoader({
    fetchFunction: () =>
      firebaseApp.getOrganizationDefaultSearchParams(user.organizationId).get(),
    prepareData: formatParams,
    initialDataState: {},
  });

  // Render
  return (
    <Row className="travel-data-list-view d-flex justify-content-between mt-5 ml-3 w-100 pb-5 mb-5">
      <Colxx
        xxs="12"
        md="5"
        className="d-flex justify-content-center align-self-start"
      >
        <ApprovalImg width={'85%'} />
      </Colxx>
      <Colxx xxs="12" md="7" className="pl-3 mb-5">
        <p className="text-one w-70 mt-3 slide-left">
          {messages['admin.travels.params.description']}
        </p>

        <section className="slide-left" style={{ animationDelay: '25ms' }}>
          <FlightParamsOptions initialData={searchParams[OrderTypes.FLIGHT]} />
          <HotelParamsOptions
            className="mt-3"
            initialData={searchParams[OrderTypes.HOTEL]}
          />
          <CarParamsOptions
            className="mt-3"
            initialData={searchParams[OrderTypes.CAR]}
          />
          <BusParamsOptions
            className="mt-3"
            initialData={searchParams[OrderTypes.BUS]}
          />
        </section>
      </Colxx>
    </Row>
  );
}
