import React, { useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { CancellationMotives } from '../../constants/cancellation';
import { useIntl } from 'react-intl';
import FixedSelect from '../common/FixedSelect';
import { WhiteBottomLineStyle } from '../../constants/selectStyles';
import AlertBox from '../AlertBox';

export default function CancellationMotive({
  className = '',
  orderType,
  motive,
  setMotive,
  customMotive,
  setCustomMotive,
  style = {},
}) {
  const { messages } = useIntl();

  const inputRef = useRef(null);
  const [showInput, setShowInput] = useState(false);

  const possibleMotives = useMemo(() => {
    const motives = CancellationMotives[orderType] || [];

    return motives.map((motive) => ({
      ...motive,
      label: messages[motive.label],
    }));
  }, [orderType]);

  // Effects
  useEffect(() => {
    const needsInput = motive?.needsInput;
    if (needsInput !== showInput) setShowInput(needsInput);
  }, [motive]);

  useEffect(() => {
    if (inputRef.current) inputRef.current.focus();
  }, [showInput]);

  // Render
  return (
    <div className={classNames('cancellation-motive', className)} style={style}>
      <label className="w-100">
        <p className="mb-1 fs-0-7rem lh-1-5 font-weight-medium">
          {messages['cancellation.motive.title']}
        </p>
        <FixedSelect
          options={possibleMotives}
          value={motive}
          onChange={setMotive}
          editable={false}
          isSearchable={false}
          styles={WhiteBottomLineStyle}
          placeholder={messages['cancellation.input.placeholder']}
          components={{
            IndicatorSeparator: () => null,
          }}
        />
      </label>
      {showInput ? (
        <input
          className="custom-motive my-2 w-100 p-2 fs-0-7rem text-white"
          placeholder={messages['cancellation.motive.placeholder']}
          ref={inputRef}
          value={customMotive}
          onChange={({ target }) => setCustomMotive(target.value)}
          maxLength={150}
        />
      ) : null}
      <div>
        <AlertBox type="warning" message="Teste" />
      </div>
    </div>
  );
}
