export const HotelCategories = [0, 1, 2, 3, 4, 5];

export const HotelAmenities = [
  "Babá ou creche",
  "Café da manhã",
  "Internet",
  "Lavanderia",
  "Restaurante",
  "Estacionamento",
  "Piscina",
  "Academia",
  "Spa"
]

export const CarTransmissions = ["Manual", "Automatic", "2WD"];

export const CarTypes = [
  "Compact",
  "Compact Elite",
  "Economy",
  "Economy Elite",
  "Fullsize",
  "Fullsize Elite",
  "Intermediate",
  "Intermediate Elite",
  "Mini",
  "Mini Elite",
  "Premium",
  "Special",
  "Standard"
]

export const BusSeats = ["Cama", "Convencional", "Executivo", "Leito", "Semi-leito"]