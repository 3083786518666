import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Alert,
  Input,
} from 'reactstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import IntlMessages from '../../helpers/IntlMessages';

import User from '../../services/User';
import { setupList } from '../../constants/checklists';
import { trackPropertiesSegment } from '../../utils/segment';
import { useIntl } from 'react-intl';

export default function ApiKeyModal(props) {
  const { messages } = useIntl();

  const {
    auth: { user },
  } = useSelector(({ auth, checklist }) => ({
    auth,
    checklist,
  }));

  const [apiKey, setApiKey] = useState(null);
  const [step, setStep] = useState('form');
  const [error, setError] = useState(null);

  // Create API Key
  const createApiKey = async (values, setSubmitting) => {
    try {
      const response = await User.createApiKey(values);

      trackPropertiesSegment('Api Key Created', {
        user,
        apiKey: values,
      });

      setSubmitting(false);
      setApiKey(response);
      setStep('result');
    } catch (err) {
      setError('Um erro aconteceu enquanto criávamos a sua credencial');
    }
  };

  // Render
  return (
    <Modal className="admin" isOpen={true} toggle={props.toggleModal}>
      <ModalHeader toggle={props.toggleModal}>
        <IntlMessages id="admin.integrations.apiKey.Modal.Add" />
      </ModalHeader>
      {error && (
        <ModalBody>
          <Alert color="danger">{error}</Alert>
        </ModalBody>
      )}
      {step === 'form' && (
        <Formik
          initialValues={{
            name: '',
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().required(
              messages['forms.validation.firstName.required']
            ),
          })}
          onSubmit={(values, { setSubmitting }) => {
            createApiKey(values, setSubmitting);
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <ModalBody>
                <Row className="mb-1">
                  <Label className="form-group has-top-label mb-0 w-100">
                    <Field type="input" name="name" className="form-control" />
                    <IntlMessages id="admin.integrations.apiKey.Name" />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="invalid-feedback d-block"
                    />
                  </Label>
                </Row>
              </ModalBody>
              <ModalFooter>
                <div className="d-flex justify-content-between align-items-center">
                  <Button
                    type="submit"
                    color="primary"
                    outline
                    size="lg"
                    disabled={isSubmitting}
                  >
                    <IntlMessages id="forms.action.create" />
                  </Button>
                </div>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      )}
      {step === 'result' && (
        <ModalBody>
          <Row className="mb-1">
            <IntlMessages id="admin.integrations.apiKey.Description" />
          </Row>
          <Row className="mb-1">
            <Label className="form-group has-top-label mb-0 w-100">
              <Input
                className="form-control"
                readOnly={true}
                value={apiKey.client_id}
              />
              <IntlMessages id="admin.integrations.apiKey.ClientID" />
            </Label>
          </Row>
          <Row className="mb-1">
            <Label className="form-group has-top-label mb-0 w-100">
              <Input
                className="form-control"
                readOnly={true}
                value={apiKey.client_secret}
              />
              <IntlMessages id="admin.integrations.apiKey.ClientSecret" />
            </Label>
          </Row>
        </ModalBody>
      )}
    </Modal>
  );
}
