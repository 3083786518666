import { Component } from 'react';
import { Colxx } from '../../components/common/CustomBootstrap';
import { Card, Button, CustomInput } from 'reactstrap';
import moment from 'moment';
import clone from 'lodash/clone';
import map from 'lodash/map';
import isEqual from 'lodash/isEqual';
import size from 'lodash/size';
import includes from 'lodash/includes';

import { registeredBus } from '../../constants/travelValues';
import { injectIntl } from 'react-intl';
import { trackPropertiesSegment } from '../../utils/segment';
import { treatBusClass } from '../../utils/bus';
import CurrencyWrapper from '../../components/common/CurrencyWrapper';

class BusItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedSegments: [],
    };
  }

  getCityAndState = (station) => {
    const index = station.indexOf('-');
    return index !== -1 ? station.slice(0, index - 1) : station;
  };

  selectSegment(journeyIndex, segmentIndex) {
    const { selectedSegments } = this.state;
    const { item } = this.props;

    const newSelectedSegments = clone(selectedSegments);
    newSelectedSegments[journeyIndex] = {
      ...item.journeys[journeyIndex].segments[segmentIndex],
    };

    this.setState({
      selectedSegments: newSelectedSegments,
    });
  }

  // Render
  renderDuration(startTime, endTime) {
    var start = new moment(startTime);
    var end = new moment(endTime);
    var duration = moment.duration(end.diff(start));

    return `${Math.floor(duration.asHours())}h ${duration.minutes()}m`;
  }

  renderArrivalDays(startTime, endTime) {
    var start = new moment(startTime).startOf('day');
    var end = new moment(endTime).startOf('day');
    var daysDiff = end.diff(start, 'days');

    if (daysDiff > 0) {
      return <span>+{daysDiff}</span>;
    }
  }

  renderJourney(journey, iJ) {
    const {
      index,
      intl: { messages },
    } = this.props;

    return (
      <div className="journey" key={`journey-${index}-${iJ}`}>
        <div className="row header">
          <Colxx xxs="3">
            <div className="title">
              <img
                src="/assets/img/icons/journey_bus_icon.svg"
                alt={messages['alts.containers.search.flight-item']}
              />
              {iJ === 0 && messages['travel.outbound']}
              {iJ === 1 && messages['travel.inbound']}
              <span>
                {moment(journey.departureDate).format('ddd DD MMM YYYY')}
              </span>
            </div>
          </Colxx>
          <Colxx xxs="2">
            <div className="station">
              <span>{this.getCityAndState(journey.departureStation)}</span>
            </div>
          </Colxx>
          <Colxx xxs="2"></Colxx>
          <Colxx xxs="2" className="station">
            <div className="station">
              <span>{this.getCityAndState(journey.arrivalStation)}</span>
            </div>
          </Colxx>
          <Colxx xxs="3"></Colxx>
        </div>

        {map(journey.segments, (segment, iS) => {
          return this.renderSegment(journey, iJ, segment, iS);
        })}
      </div>
    );
  }

  renderSegment(journey, iJ, segment, iS) {
    const { selectedSegments } = this.state;
    const {
      index,
      intl: { messages },
      user,
      seatClasses,
    } = this.props;

    const { class: segmentClass, treatedClass = '' } = segment.legs[0].fare;
    const treatedSegmentClass = treatedClass || treatBusClass(segmentClass);

    if (seatClasses[treatedSegmentClass] === false) return null;

    return (
      <div key={`journey-${index}-${iJ}-${iS}`} className="row trip">
        <Colxx xxs="3" className="selection">
          <CustomInput
            type="checkbox"
            className="custom-check"
            id={`journey-${index}-${iJ}-${iS}`}
            checked={isEqual(selectedSegments[iJ], segment)}
            onChange={() => {
              trackPropertiesSegment('Bus segment selected', {
                segment,
                type: iJ ? 'Inbound' : 'Outbound',
                user,
              });
              this.selectSegment(iJ, iS);
            }}
          />
        </Colxx>

        <Colxx xxs="2" className="time">
          {moment(segment.departureDateTime).format('HH:mm')}
        </Colxx>

        <Colxx xxs="2" className="stops-chart">
          <div className="graph">
            <div className="line">
              <div className="dots">
                {map(segment.legs, (flightItem, iFI) => {
                  if (iFI === 0) return '';
                  return (
                    <div
                      key={`result-${index}-${iJ}-${iS}-${iFI}`}
                      className="block"
                    />
                  );
                })}
              </div>
            </div>
          </div>
          <div className="info">
            {segment.legs.length - 1 === 0
              ? messages['flight.no-stops']
              : segment.legs.length - 1 === 1
              ? `1 ${messages['flight.stop']}`
              : `${segment.legs.length - 1} ${messages['flight.stops']}`}
          </div>
        </Colxx>

        <Colxx xxs="2" className="time">
          {moment(segment.arrivalDateTime).format('HH:mm')}
          {this.renderArrivalDays(
            segment.departureDateTime,
            segment.arrivalDateTime
          )}
        </Colxx>

        <Colxx xxs="2" className="duration">
          {this.renderDuration(
            segment.departureDateTime,
            segment.arrivalDateTime
          )}
        </Colxx>

        <Colxx xxs="1" className="position-relative">
          <img
            src={`/assets/img/icons/bus/${treatedSegmentClass}.svg`}
            alt={treatedSegmentClass}
            title={segmentClass}
            className={`seat-class ${treatedSegmentClass.toLowerCase()}`}
          />
        </Colxx>
      </div>
    );
  }

  render() {
    const {
      item,
      showDetails,
      isTwoWay,
      intl: { messages },
    } = this.props;
    const { selectedSegments } = this.state;
    const isSelected = isTwoWay
      ? size(selectedSegments) === 2
      : size(selectedSegments) === 1;
    const isDisabled = !isSelected || includes(selectedSegments, undefined);

    return (
      <Card className="search-card flight mt-4">
        <Colxx xxs="12" lg="9" className="journeys">
          {map(item.journeys, (journey, i) => {
            return this.renderJourney(journey, i);
          })}
        </Colxx>
        <Colxx xxs="12" lg="3" className="total pt-3">
          {registeredBus[item.carrier] && (
            <div
              className="carrier"
              style={{
                backgroundImage: `url(${registeredBus[item.carrier].logo})`,
              }}
            />
          )}
          <div className="text">
            <span>{messages['general.total']}</span>
            <CurrencyWrapper value={item.fare.total} decimalPlaces={0}>
              {({ formattedValue }) => formattedValue}
            </CurrencyWrapper>
            <hr />
          </div>
          <div className="description">{messages['general.taxes']}</div>

          <Button
            color="primary"
            disabled={isDisabled}
            onClick={() => showDetails(item, selectedSegments)}
          >
            {messages['general.select']}
          </Button>
        </Colxx>
      </Card>
    );
  }
}

export default injectIntl(BusItem);
