import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Label, Row } from 'reactstrap';
import MaskedInput from 'react-text-mask';
import { boleto48Mask, boletoMask } from '../../../../../utils/masks';
import { useSelector } from 'react-redux';
import { debounce } from 'lodash';
import useExecuteAction from '../../../../../hooks/useExecuteAction';
import useDataArchive from '../../../../../hooks/useDataArchive';
import appFunctions from '../../../../../services/Functions';
import { Colxx } from '../../../../common/CustomBootstrap';
import { formatYYYYMMDD } from '../../../../../utils/date';
import { convertToFloat, stringToMoney } from '../../../../../utils/money';
import NumberFormat from 'react-number-format';
import { getBoletoMaxAmountBasedOnDueDate } from '../../../../../utils/administrativeCards';
import AnimateHeight from 'react-animate-height';
import classNames from 'classnames';

function maskFunction(value = '') {
  return value.length < 55 ? boletoMask : boleto48Mask;
}

export default function BoletoTab({
  budget,
  cardId,
  paymentInfo,
  setPaymentInfo,
  addToPaymentInfo,
  resetPaymentInfo,
}) {
  const { user } = useSelector(({ auth }) => auth);
  const { messages } = useIntl();

  const { executeAction, loading } = useExecuteAction();
  const { getDataFromArchive } = useDataArchive();

  // States
  const [errorMessage, setErrorMessage] = useState('');
  const [barcode, setBarcode] = useState(
    () => paymentInfo?.info?.barcode || ''
  );
  const boletoInfo = paymentInfo?.info;

  const {
    payee,
    payee_document,
    payer,
    payer_document,
    issuer,
    due_date,
    max_amount,
    formatted_max_amount,
    min_amount,
    canPayLess,
    formatted_amount,
    amount,
    hasDivergentAmounts,
  } = useMemo(() => {
    if (!boletoInfo) return {};

    const {
      payee,
      issuer,
      payee_document,
      payer,
      payer_document,
      due_date,
      min_amount,
      amount,
    } = boletoInfo;

    const max_amount = getBoletoMaxAmountBasedOnDueDate(
        boletoInfo,
        budget?.startDate
      ),
      converted_amount = convertToFloat(amount);

    return {
      payee,
      payee_document,
      issuer,
      payer,
      payer_document,
      due_date: formatYYYYMMDD(due_date),
      max_amount,
      formatted_max_amount: stringToMoney(max_amount, 2),
      min_amount: convertToFloat(min_amount),
      amount: converted_amount,
      formatted_amount: stringToMoney(converted_amount, 2),
      canPayLess: max_amount !== min_amount,
      hasDivergentAmounts: max_amount !== converted_amount,
    };
  }, [boletoInfo, budget]);

  // Effects
  useEffect(() => {
    const cleanedBarcode = barcode.replace(/\D/g, '');

    if (boletoInfo?.barcode === cleanedBarcode) return;

    if (boletoInfo) resetPaymentInfo();

    if (errorMessage) setErrorMessage('');

    if ([47, 48].includes(cleanedBarcode.length)) {
      doCheckBoleto(
        user.organizationId,
        cardId,
        cleanedBarcode,
        budget,
        paymentInfo
      );
    }
  }, [barcode]);

  // Functions
  const doCheckBoleto = useCallback(
    debounce(async (organizationId, cardId, barcode, budget, paymentInfo) => {
      try {
        setErrorMessage('');

        const boletoInfo = await executeAction(
          getDataFromArchive(`boleto-${barcode}`, () =>
            appFunctions.checkBoleto(organizationId, cardId, barcode)
          )
        );

        setPaymentInfo({
          ...paymentInfo,
          info: boletoInfo,
          total: getBoletoMaxAmountBasedOnDueDate(
            boletoInfo,
            budget?.startDate
          ),
        });
      } catch (err) {
        console.error(err);
        setErrorMessage(messages['administrative.boleto.error.fetch']);
      }
    }, 500),
    []
  );

  const checkInformedTotal = () => {
    const total = paymentInfo?.total || 0;

    if (total > max_amount) addToPaymentInfo('total', max_amount);
    else if (total < min_amount) addToPaymentInfo('total', min_amount);
  };

  // Render
  const renderBoletoAmountInfo = () => {
    return (
      <Row>
        <Colxx md="6" sm="12">
          <Label className="form-group disabled search has-top-label">
            <p className="text-uppercase form-control form-purple-value bold">
              {formatted_amount}
            </p>
            <span>{messages['administrative.boleto.to-be-paid.amount']}</span>
          </Label>
        </Colxx>
        {hasDivergentAmounts ? (
          <Colxx md="6" sm="12">
            <Label className="form-group disabled search has-top-label">
              <p className="text-uppercase form-control form-purple-value bold">
                {formatted_max_amount}
              </p>
              <span>
                {messages['administrative.boleto.to-be-paid.max-amount']}
              </span>
            </Label>
          </Colxx>
        ) : null}
        <Colxx sm="12" md={hasDivergentAmounts ? '12' : '6'}>
          <Label className="form-group disabled search has-top-label">
            <NumberFormat
              name="boleto-value"
              prefix={'R$ '}
              suffix={''}
              thousandSeparator="."
              decimalSeparator=","
              decimalScale="2"
              fixedDecimalScale="2"
              className="currency-input w-100 form-purple-value mt-0"
              defaultValue={paymentInfo?.total}
              onValueChange={(v) => addToPaymentInfo('total', v.floatValue)}
              onBlur={checkInformedTotal}
              value={paymentInfo?.total}
              disabled={!canPayLess}
            />
            <span>
              {messages['administrative.boleto.value']} {canPayLess ? '*' : ''}
            </span>
          </Label>
        </Colxx>
      </Row>
    );
  };

  const renderBoletoInfo = () => (
    <>
      {renderBoletoAmountInfo()}
      <Row>
        <Colxx md="6" sm="12">
          <Label className="form-group disabled search has-top-label">
            <p className="text-uppercase form-control form-purple-value bold">
              {payee}
            </p>
            <span>{messages['administrative.boleto.payee']}</span>
          </Label>
        </Colxx>
        <Colxx md="6" sm="12">
          <Label className="form-group disabled search has-top-label">
            <p className="text-uppercase form-control form-purple-value bold">
              {payee_document}
            </p>
            <span>{messages['administrative.boleto.cnpj']}</span>
          </Label>
        </Colxx>
      </Row>
      <Row>
        <Colxx md="6" sm="12">
          <Label className="form-group disabled search has-top-label">
            <p className="text-uppercase form-control form-purple-value bold">
              {payer}
            </p>
            <span>{messages['administrative.boleto.payer']}</span>
          </Label>
        </Colxx>
        <Colxx md="6" sm="12">
          <Label className="form-group disabled search has-top-label">
            <p className="text-uppercase form-control form-purple-value bold">
              {payer_document}
            </p>
            <span>{messages['administrative.boleto.payer-document']}</span>
          </Label>
        </Colxx>
      </Row>
      <Row>
        <Colxx md="6" sm="12">
          <Label className="form-group disabled search has-top-label">
            <p className="text-uppercase form-control form-purple-value bold">
              {issuer}
            </p>
            <span>{messages['administrative.boleto.issuer']}</span>
          </Label>
        </Colxx>
        <Colxx md="6" sm="12">
          <Label className="form-group disabled search has-top-label">
            <p className="text-uppercase form-control form-purple-value bold">
              {due_date}
            </p>
            <span>{messages['administrative.boleto.due-date']}</span>
          </Label>
        </Colxx>
      </Row>
    </>
  );

  return (
    <div className="reservation-params refund-params light">
      <div className="px-3">
        <Row>
          <Label className="form-group disabled search has-top-label w-100 position-relative">
            <MaskedInput
              id="boleto-input"
              defaultValue={barcode}
              type="text"
              mask={maskFunction}
              onChange={(e) => setBarcode(e.target.value)}
              className={classNames('form-control form-purple-value bold', {
                fetching: loading,
              })}
              disabled={loading}
              guide={false}
            />

            <span>{messages['administrative.boleto.number']} *</span>

            {loading ? (
              <div className="simple-loading-container">
                <div className="loading" />
              </div>
            ) : null}
          </Label>
        </Row>
        <AnimateHeight height={errorMessage ? 'auto' : 0} easing="ease">
          <p className="text-primary mt-0 pt-0 font-weight-semibold fs-0-7rem">
            {errorMessage}
          </p>
        </AnimateHeight>

        {boletoInfo ? renderBoletoInfo() : null}
      </div>
    </div>
  );
}
