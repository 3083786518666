import React from 'react';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Button } from 'reactstrap';

import { ReactComponent as EmptyImage } from '../../../../../assets/img/config/no-hotels-light.svg';

export default function EmptyRooms({ results, goForward }) {
  const { messages, formatMessage } = useIntl();

  const { hasAvailable, emptyDates } = useMemo(() => {
    const emptyDates = [];

    results.forEach((result) => {
      if (!result.result) {
        emptyDates.push(
          formatMessage(
            {
              id: 'travel.alter.hotel.increase-days.empty-data',
            },
            {
              startDate: result.search.outbound_date.format('DD MMM'),
              endDate: result.search.inbound_date.format('DD MMM'),
            }
          )
        );
      }
    });

    return {
      hasAvailable: emptyDates.length < results.length,
      emptyDates,
    };
  }, [results]);

  // // Render
  const renderPartialEmpty = () => (
    <div>
      <p style={{ fontSize: '0.8rem' }}>
        {messages['travel.alter.hotel.increase-days.partially-empty']}
      </p>
      <ul className="m-0">
        {emptyDates.map((emptyDate) => (
          <li key={emptyDate} style={{ fontSize: '0.8rem' }}>
            {emptyDate}
          </li>
        ))}
      </ul>
      <Button onClick={goForward}>
        {messages['travel.alter.hotel.increase-days.yes']}
      </Button>
    </div>
  );

  const renderFullyEmpty = () => (
    <p style={{ fontSize: '0.8rem' }}>
      {messages['travel.alter.hotel.increase-days.fully-empty']}
    </p>
  );

  return (
    <div className="d-flex mt-3">
      <EmptyImage width={300} />
      <div className="ml-4">
        {hasAvailable ? renderPartialEmpty() : renderFullyEmpty()}
      </div>
    </div>
  );
}
