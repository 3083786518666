import React, { useEffect, useState } from 'react';
import firebaseApp from '../../../../../services/Firebase';
import { sortByFareBasedOnProperty } from '../../../../../utils/expensesDashboard';
import { getDocumentsFromCollection } from '../../../../../utils/firebase';
import DefaultTab from '../DefaultTab';

export default function CategoryTab({ items, getData, ...props }) {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchVendors = async () => {
      try {
        setLoading(true);

        const budgetItems = (
          await Promise.all(
            items.map((budget) => {
              return getData(`bi-${budget.id}`, () =>
                getDocumentsFromCollection(() =>
                  firebaseApp.getItemsFromBudget(budget.id).get()
                )
              );
            })
          )
        ).flat();

        const categories = sortByFareBasedOnProperty(
          budgetItems,
          ({ category = {} }) => category.value
        ).map((item) => {
          item.value = item.items[0].category.label;
          return item;
        });

        setCategories(categories);
        setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };

    fetchVendors();
  }, [items]);

  return <DefaultTab values={categories} loading={loading} {...props} />;
}
