import React from 'react';
import useDataLoader from '../../hooks/useDataLoader';
import firebaseApp from '../../services/Firebase';
import { ReactComponent as TickIcon } from '../../assets/img/icons/tick.svg';

export default function Comment({ comment }) {
  const [user, setUser, loading] = useDataLoader({
    fetchFunction: () => firebaseApp.getUserFromId(comment.source_id).get(),
    getDataFunction: (snap) => snap.data(),
  });

  if (loading || !comment) return null;

  return (
    <div
      className="budget-comment d-flex flex-column justify-content-end align-items-end px-4 py-3 show-up mw-50"
      style={{ animationDelay: '0.4s' }}
    >
      <p className="m-0 p-0 text text-right">{comment.value}</p>
      <div
        className="d-flex align-items-center"
        style={{ marginTop: '0.1rem' }}
      >
        <h3 className="m-0 p-0 mr-1 font-primary name font-weight-medium">
          {user.firstName}
        </h3>
        <TickIcon className="icon" width="0.8rem" height="0.8rem" />
      </div>
    </div>
  );
}
