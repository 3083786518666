import React, { useMemo } from 'react';
import { Button } from 'reactstrap';
import { useIntl } from 'react-intl';
import StepsBulletPoints from '../../StepsBulletPoints';
import PerfectScrollbar from 'react-perfect-scrollbar';
import orderStatus from '../../../constants/orderStatus';
import IssueStep from './Steps/IssueStep';
import FinalStep from './Steps/FinalStep';
import WaitBeforeRenderWrapper from '../../../wrappers/WaitBeforeRenderWrapper';
import firebaseApp from '../../../services/Firebase';
import useExecuteAction from '../../../hooks/useExecuteAction';

const STEPS = {
  [orderStatus.WAITING_ISSUE]: IssueStep,
  [orderStatus.ISSUED]: FinalStep,
};

export default function OpenBookingIssueInfoModal({
  order,
  goForOpenBookingSteps,
}) {
  const { messages } = useIntl();

  // Hooks
  const { executeAction, loading } = useExecuteAction({
    blockOnLoading: true,
  });

  // States
  const ContentComponent = useMemo(() => STEPS[order.statusCode], [order]);

  const steps = useMemo(
    () => [
      {
        title: messages['open-booking.steps.reservation'],
      },
      {
        title: messages['open-booking.steps.approval'],
      },
      {
        title: messages['open-booking.steps.issue'],
      },
      {
        title: messages['open-booking.steps.receipt'],
        openBullet: true,
        openBullet: order.statusCode === orderStatus.WAITING_ISSUE,
      },
    ],
    [order]
  );

  const canCancel = useMemo(
    () =>
      order.statusCode === orderStatus.WAITING_ISSUE &&
      !order.openBookingInfo?.receipts?.length,
    [order]
  );

  // Functions
  const cancelReservation = () =>
    executeAction(
      (async () => {
        await firebaseApp.requestToCancel(order.id);

        goForOpenBookingSteps();
      })()
    );

  // Render
  const renderBottom = () => {
    return (
      <div className="d-flex align-items-center justify-content-end flex-shrink-0 mt-1">
        <Button color="link" onClick={goForOpenBookingSteps} className="m-0">
          {messages['general.go-back']}
        </Button>
        {canCancel ? (
          <Button
            color="white"
            onClick={cancelReservation}
            className="m-0 ml-2"
            disabled={loading}
          >
            {messages['general.cancel']}
          </Button>
        ) : null}
      </div>
    );
  };

  return (
    <div className="open-booking-issue-info approval-steps h-100 w-100 pt-2 d-flex flex-column">
      <div className="flex-grow-1 mh-0 my-2">
        <WaitBeforeRenderWrapper waitFor={300}>
          <div className="d-flex flex-grow-1 mh-0 h-100">
            <div className="header flex-shrink-0 ml-4 mr-5">
              <StepsBulletPoints
                rightOriented
                steps={steps}
                spaceBetweenItems={12}
                bulletBorderWidth={0}
                innerBulletBorderWidth={2}
                bulletSize={20}
              />
            </div>

            <PerfectScrollbar
              options={{
                suppressScrollX: true,
                wheelPropagation: false,
              }}
              className="flex-grow-1 m-0 p-0 slide-left"
              style={{ minHeight: 0, animationDelay: '200ms' }}
            >
              {ContentComponent ? <ContentComponent order={order} /> : null}
            </PerfectScrollbar>
          </div>
        </WaitBeforeRenderWrapper>
      </div>
      {renderBottom()}
    </div>
  );
}
