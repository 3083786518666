import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
} from 'reactstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import IntlMessages from '../../helpers/IntlMessages';
import { injectIntl } from 'react-intl';

import firebaseApp from '../../services/Firebase';
import { trackPropertiesSegment } from '../../utils/segment';
import Select, { components } from 'react-select';
import refundCategoryIcons from '../../constants/refundCategoryIcons';

const customSelectStyles = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: '1px dotted rgba(0, 0, 0, 0.2)',
    padding: 20,
    backgroundColor: state.isSelected ? 'rgba(67, 65, 142, 0.1)' : 'white',
    color: 'black',
  }),
  control: (base, state) => ({
    ...base,
    border: 0,
    boxShadow: 'none',
  }),
};

const CustomOption = (props) => {
  const { data } = props;

  return (
    <components.Option {...props}>
      <img
        src={`/assets/img/expenses/${data.src}`}
        alt={data.name}
        style={{ width: 25 }}
      />
      <span className="ml-2">{data.label}</span>
    </components.Option>
  );
};

const CustomSingleValue = (props) => {
  const { data } = props;

  return (
    <components.SingleValue {...props}>
      <img
        src={`/assets/img/expenses/${data.src}`}
        alt={data.name}
        style={{ width: 25 }}
      />
      <span className="ml-2">{data.label}</span>
    </components.SingleValue>
  );
};

class CategoryModal extends Component {
  constructor(props) {
    super(props);

    const { messages } = props.intl;

    const categories = refundCategoryIcons.map((icon) => ({
      ...icon,
      label: messages[`refund.category.${icon.value}`],
    }));

    this.state = {
      categories,
      icon: props.selectedCategory
        ? categories.find((i) => i.src === props.selectedCategory.icon)
        : undefined,
    };
  }

  createCategory = async (values, setSubmitting) => {
    const { user, selectedCategory } = this.props;
    const { icon = { src: 'money.svg' } } = this.state;

    if (selectedCategory) {
      await firebaseApp.updateCategoryFromOrganization(
        user.organizationId,
        selectedCategory.id,
        {
          ...values,
          icon: icon.src,
        }
      );
    } else {
      await firebaseApp.createCategoryFromOrganization(user.organizationId, {
        ...values,
        icon: icon.src,
      });
    }

    setSubmitting(false);

    trackPropertiesSegment('Refund Category added', { category: values, user });

    this.props.toggleModal();
  };

  // Render
  render() {
    const { name = '', externalId = '' } = this.props.selectedCategory || {};
    const { messages } = this.props.intl;

    return (
      <Modal
        className="admin"
        isOpen={this.props.modalOpen}
        toggle={this.props.toggleModal}
      >
        <ModalHeader toggle={this.props.toggleModal}>
          <IntlMessages id="admin.policy.refund.Modal.Add" />
        </ModalHeader>
        <Formik
          initialValues={{
            name,
            externalId,
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().required(
              messages['forms.validation.name.required']
            ),
            externalId: Yup.string(),
          })}
          onSubmit={(values, { setSubmitting }) => {
            this.createCategory(values, setSubmitting);
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <ModalBody>
                <Row className="mb-1">
                  <Label className="form-group has-top-label mb-0 w-100">
                    <Field type="input" name="name" className="form-control" />
                    <IntlMessages id="admin.policy.project.Name" />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="invalid-feedback d-block"
                    />
                  </Label>
                </Row>
                <Row className="mb-1">
                  <Label className="form-group has-top-label mb-0 w-100">
                    <Field
                      type="input"
                      name="externalId"
                      className="form-control"
                    />
                    <IntlMessages id="admin.policy.project.ExternalId" />
                    <ErrorMessage
                      name="externalId"
                      component="div"
                      className="invalid-feedback d-block"
                    />
                  </Label>
                </Row>
                <Row className="mb-1">
                  <Label className="form-group has-top-label mb-0 w-100">
                    <div className="form-control">
                      <Select
                        components={{
                          Option: CustomOption,
                          SingleValue: CustomSingleValue,
                        }}
                        options={this.state.categories}
                        onChange={(icon) => this.setState({ icon })}
                        value={this.state.icon}
                        styles={customSelectStyles}
                        placeholder={
                          this.props.intl.messages[
                            'refund.category.placeholder'
                          ]
                        }
                      />
                    </div>
                    <IntlMessages id="admin.policy.refund.Icon" />
                    <ErrorMessage
                      name="externalId"
                      component="div"
                      className="invalid-feedback d-block"
                    />
                  </Label>
                </Row>
              </ModalBody>
              <ModalFooter>
                <div className="d-flex justify-content-between align-items-center">
                  <Button
                    type="submit"
                    color="primary"
                    outline
                    size="lg"
                    disabled={isSubmitting}
                  >
                    <IntlMessages id="forms.action.save" />
                  </Button>
                </div>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { user } = auth;
  return { user };
};

export default connect(mapStateToProps)(injectIntl(CategoryModal));
