import React from 'react';
import firebaseApp from '../../services/Firebase';
import DefaultDataListView from './DefaultDataListView';
import MotiveModal from '../../components/policy/MotiveModal';

export default function MotivesDataListView() {
  return (
    <DefaultDataListView
      fieldName="motive"
      path="motives"
      fetchFunction={firebaseApp.getMotivesFromOrganization}
      deleteFunction={firebaseApp.deleteMotiveFromOrganizationAndId}
      updateFunction={firebaseApp.updateMotiveFromOrganization}
      createFunction={firebaseApp.createMotiveFromOrganization}
      ItemModal={MotiveModal}
      emptyResultsTitleId="admin.policy.empty.motive.title"
      emptyResultsDescriptionId="admin.policy.empty.motive.description"
      emptyResultsButtonTextId="admin.policy.empty.motive.btn"
      archiveKey="motives"
    />
  );
}
