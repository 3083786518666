import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { getAuthRedux } from '../../../../utils/redux';
import { DEFAULT_NATIONALITY } from '../../../../constants/countries';
import OrderStructures from '../../../../constants/orderStructure';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Input, InputGroup, Label, Row } from 'reactstrap';
import { Colxx } from '../../../../components/common/CustomBootstrap';
import IntlMessages from '../../../../helpers/IntlMessages';
import MaskedInput from 'react-text-mask';
import { cpfMask, dateMask } from '../../../../utils/masks';
import PassportParams from '../../PassportParams';

export default function PersonalParams({
  reservationParams,
  setReservationParams,
  nationality = DEFAULT_NATIONALITY,
  orderStructure = OrderStructures.PERSONAL,
  needPassport,
}) {
  const { user } = useSelector(getAuthRedux);

  // States
  const [passengerInfo, setPassengerInfo] = useState({});
  const [passport, setPassport] = useState({});
  const isNational = useMemo(
    () => nationality === DEFAULT_NATIONALITY,
    [nationality]
  );

  // Effects
  useEffect(() => {
    if (orderStructure === OrderStructures.PERSONAL) {
      onChangeForm(passengerInfo);
    }
  }, [passengerInfo, orderStructure]);

  useEffect(() => {
    onChangePassport();
  }, [passport]);

  // Functions
  const onChangeForm = (values) => {
    setReservationParams({
      ...reservationParams,
      needPassport,
      structure: OrderStructures.PERSONAL,
      passenger: values,
      passengerId: user.uid,
      passengerTimeline: user,
      nationality,
      passport,
    });

    return {};
  };

  const onChangePassport = () => {
    setReservationParams({
      ...reservationParams,
      passport,
    });

    return {};
  };

  // Render
  return (
    <div className="reservation-params px-3">
      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          birthdate: '',
          documents: {
            cpf: {
              number: '',
            },
            identity: {
              number: '',
            },
          },
        }}
        validate={setPassengerInfo}
      >
        {({ handleChange, handleBlur }) => (
          <Form className="custom-default">
            <Row className="pb-3">
              <Colxx xxs="12">
                <InputGroup>
                  <Label className="form-group search has-top-label mb-0">
                    <Field
                      type="input"
                      name="firstName"
                      className="form-control form-purple-value bold"
                      autoComplete="given-name"
                    />
                    <span>
                      <IntlMessages id="user.firstName" /> *
                    </span>
                    <ErrorMessage
                      name="firstName"
                      component="div"
                      className="invalid-feedback d-block"
                    />
                  </Label>
                  <Label className="form-group search has-top-label mb-0 pl-1">
                    <Field
                      type="input"
                      name="lastName"
                      className="form-control form-purple-value bold"
                      autoComplete="family-name"
                    />
                    <span>
                      <IntlMessages id="user.lastName" /> *
                    </span>
                    <ErrorMessage
                      name="lastName"
                      component="div"
                      className="invalid-feedback d-block"
                    />
                  </Label>
                </InputGroup>
              </Colxx>
            </Row>

            <Row className="pb-3">
              <Colxx xxs="12">
                <InputGroup>
                  <Label className="form-group search has-top-label mb-0">
                    <Field name="birthdate" autoComplete="bday">
                      {({ field }) => (
                        <MaskedInput
                          type="text"
                          {...field}
                          mask={dateMask}
                          id="birthdate"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="form-control form-purple-value bold"
                        />
                      )}
                    </Field>
                    <span>
                      <IntlMessages id="user.birthdate" /> *
                    </span>
                    <ErrorMessage
                      name="birthdate"
                      component="div"
                      className="invalid-feedback d-block"
                    />
                  </Label>
                  {isNational ? (
                    <Label className="form-group search has-top-label mb-0 pl-1">
                      <Field name="documents.cpf.number">
                        {({ field }) => (
                          <MaskedInput
                            type="text"
                            {...field}
                            mask={cpfMask}
                            id="documents.cpf.number"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="form-control form-purple-value bold"
                          />
                        )}
                      </Field>
                      <span>
                        <IntlMessages id="user.cpf" /> *
                      </span>
                      <ErrorMessage
                        name="documents.cpf.number"
                        component="div"
                        className="invalid-feedback d-block"
                      />
                    </Label>
                  ) : (
                    <Label className="form-group search has-top-label mb-0 pl-1">
                      <Input
                        type="text"
                        name="documents.identity.number"
                        className="form-control form-purple-value bold"
                        onChange={handleChange}
                      />
                      <ErrorMessage
                        name="documents.identity.number"
                        component="div"
                        className="invalid-feedback d-block"
                      />
                      <span>
                        <IntlMessages id="user.identity" /> *
                      </span>
                    </Label>
                  )}
                </InputGroup>
              </Colxx>
            </Row>
            {needPassport && (
              <PassportParams
                setPassport={setPassport}
                passport={passport}
                orderStructure={orderStructure}
              />
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
}
