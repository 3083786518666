import React, { useEffect, useMemo, useState } from 'react';
import Pagination from '../../containers/pages/Pagination';

export default function PaginatedData({
  items,
  itemsPerPage = 10,
  render = () => {},
  dependencies = [],
}) {
  const [currentPage, setCurrentPage] = useState(1);

  const itemsInInterval = useMemo(() => {
    const startAt = (currentPage - 1) * itemsPerPage;
    return items.slice(startAt, startAt + itemsPerPage);
  }, [items, currentPage, itemsPerPage]);

  // Effects
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(1);
  }, dependencies);

  // Render
  const renderPagination = () => {
    const totalItems = items.length;

    return (
      totalItems > itemsPerPage && (
        <Pagination
          currentPage={currentPage}
          totalPage={totalItems ? Math.ceil(totalItems / itemsPerPage) : 1}
          onChangePage={(i) => setCurrentPage(i)}
        />
      )
    );
  };

  return (
    <>
      {itemsInInterval.map(render)}
      {renderPagination()}
    </>
  );
}
