import classNames from 'classnames';
import moment from 'moment';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { expenseTypes } from '../../../constants/refundTypes';
import { getBudgetItemPayload } from '../../../utils/budgetItems';
import { stringToMoney } from '../../../utils/money';

function makeBudgetItemParams(item) {
  return {
    name: item.name,
    date: moment(item.date).format('DD MMM'),
    total: stringToMoney(item.fare.total, 2, item.fare.currency),
    iconSrc: `/assets/img/expenses/${
      item.type === expenseTypes.KM
        ? 'carRental.svg'
        : item.category.icon || 'money.svg'
    }`,
    alt: item.category?.label || item.name,
    refunded: item.refunded,
  };
}

function makeAdministrativeBudgetItemParams(item, messages) {
  return {
    name: item.name,
    date: messages[`administrative.payment-option.${item.payment.type}`],
    iconSrc: `/assets/img/expenses/${item.payment.type}.svg`,
    alt: item.name,
  };
}

export default function BudgetItemCard({
  item,
  className = '',
  onClick = () => {},
  style = {},
}) {
  const { messages } = useIntl();

  // States
  const {
    name,
    date,
    total,
    iconSrc,
    iconAlt,
    refunded,
    showTotal = true,
  } = useMemo(() => getBudgetItemPayload(item, messages), [item]);

  return (
    <div
      className={classNames(
        'budget-item-card d-flex align-items-center justify-content-between px-2 py-2',
        className,
        {
          refunded,
        }
      )}
      onClick={onClick}
      style={style}
    >
      <div className="d-flex align-items-center texts flex-grow-1">
        <div className="icon-container d-flex align-items-center justify-content-center mr-2">
          <img src={iconSrc} alt={iconAlt} />
        </div>
        <div>
          <p className="p-0 m-0 date text-uppercase text-muted">{date}</p>
          <h3 className="m-0 p-0 name text-dark font-primary">{name}</h3>
        </div>
      </div>
      {showTotal ? (
        <p className="m-0 p-0 total font-weight-medium ml-2 flex-shrink-0 text-dark">
          {total}
        </p>
      ) : null}
    </div>
  );
}
