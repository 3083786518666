import React from 'react';
import classNames from 'classnames';

export default function CircularOptionButton({
  className = '',
  Icon,
  title,
  onClick = () => {},
  iconStyle,
  showTitle,
  pressed,
  name,
}) {
  return (
    <div
      className={classNames('circular-option-button', className, { pressed })}
      title={title}
      onClick={onClick}
      name={name}
      role="button"
    >
      {Icon ? (
        <Icon className="icon" width="45%" height="45%" style={iconStyle} />
      ) : null}
      {showTitle ? <div className="title">{title}</div> : null}
    </div>
  );
}
