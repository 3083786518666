import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { sortByFareBasedOnProperty } from '../../../../../utils/expensesDashboard';
import DefaultTab from '../DefaultTab';

export default function ReservationTab({ items, ...props }) {
  const { messages } = useIntl();

  const values = useMemo(() => {
    return sortByFareBasedOnProperty(
      items,
      ({ type }) => messages[`travel.menu.${type}`]
    );
  }, [items]);

  return <DefaultTab values={values} loading={false} {...props} />;
}
