import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import useShouldInitialize from '../../../hooks/useShouldInitialize';
import CardFunds from './CardFunds';
import DefaultCardContainer from './DefaultCardContainer/DefaultCardContainer';
import VirtualCard from '../../../components/expenses/VirtualCard';
import { RefundTypes } from '../../../constants/refundTypes';
import useCreditCard from '../../../hooks/useCreditCard';
import { CARD_OPTIONS, CARD_OPTION_TYPES } from '../../../constants/creditCard';
import CardOptions from '../CardOptions/CardOptions';
import firebaseApp from '../../../services/Firebase';
import { getPassengerRedux } from '../../../utils/redux';
import OnlyWithFunds from '../../../hoc/OnlyWithFunds';
import { useState } from 'react';
import recordStatus from '../../../constants/recordStatus';
import ActivateCardButton from './ActivateCardButton';

export default function VirtualCardContainer({
  card,
  goToCreateBudget,
  goToRefund,
  goToExpense,
  visible,
  onlyWithFunds,
  ...props
}) {
  const { passenger } = useSelector(getPassengerRedux);

  // Hooks
  const shouldInitialize = useShouldInitialize(visible || onlyWithFunds);

  const {
    details,
    loading,
    togglePan,
    toggleBlock,
    refresh,
    ...cardProperties
  } = useCreditCard({
    organizationId: passenger.organizationId,
    cardNumber: card.id,
    detailsInitialState: {},
    shouldLoad: shouldInitialize,
    cardExternalId: card.external_id,
  });

  // States
  const [canArchive, setCanArchive] = useState(false);

  const options = useMemo(
    () => [
      {
        ...CARD_OPTIONS[CARD_OPTION_TYPES.BLOCK],
        onClick: toggleBlock,
      },
      {
        ...CARD_OPTIONS[CARD_OPTION_TYPES.PAN],
        onClick: togglePan,
      },
      {
        ...CARD_OPTIONS[CARD_OPTION_TYPES.ARCHIVE],
        onClick: () =>
          firebaseApp.archiveCard(
            passenger.organizationId,
            passenger.uid,
            card.id
          ),
        hide: !canArchive,
      },
    ],
    [canArchive]
  );

  const isArchived = useMemo(
    () => card.status === recordStatus.ARCHIVED,
    [card]
  );

  // Render
  const renderBottom = () => {
    if (isArchived) return <ActivateCardButton className="mt-4" card={card} />;

    return (
      <>
        <CardOptions
          className="ml-1 my-3"
          options={options}
          card={card}
          details={cardProperties}
        />
        <CardFunds
          funds={details.balance}
          className="ml-2 align-self-start"
          loading={loading}
        />
      </>
    );
  };

  return (
    <OnlyWithFunds active={onlyWithFunds} funds={details.balance}>
      <DefaultCardContainer
        className="virtual-card-container"
        card={card}
        canCreateBudget={!isArchived}
        canCreateExpense={false}
        onCreateBudget={() =>
          goToCreateBudget(card.id, RefundTypes.VIRTUAL_CARD, card.user_id)
        }
        onRefundClick={goToRefund}
        onExpenseClick={goToExpense}
        shouldInitialize={shouldInitialize}
        onBudgetUpdate={refresh}
        setCanArchive={(value) => value !== canArchive && setCanArchive(value)}
        {...props}
      >
        <div className="d-flex align-items-center justify-content-center flex-column card-container">
          <VirtualCard
            card={card}
            details={details}
            toggleBlock={toggleBlock}
            {...cardProperties}
          />
          {renderBottom()}
        </div>
      </DefaultCardContainer>
    </OnlyWithFunds>
  );
}
