import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import lottie from 'lottie-web';
import classNames from 'classnames';

const destroyLottie = (animationRef) => {
  if (animationRef.current) animationRef.current.destroy();
};

const loadLottie = (ref, animation, options = {}, animationRef) => {
  destroyLottie(animationRef);

  if (ref.current && animation) {
    animationRef.current = lottie.loadAnimation({
      animationData: animation,
      autoplay: true,
      loop: true,
      container: ref.current,
      rendererSettings: {
        filterSize: {
          width: '200%',
          height: '200%',
          x: '-50%',
          y: '-50%',
        },
      },
      ...options,
    });
  }
};

export default function LoadingStages({
  show,
  startStage = 0,
  stage,
  stages = [],
  className = '',
  style = {},
}) {
  const { messages } = useIntl();
  const containerRef = useRef(null);
  const lottieRef = useRef(null);

  const [currentStage, setCurrentStage] = useState(stage || startStage);
  const [doShow, setDoShow] = useState(show);
  const [showItems, setShowItems] = useState(show);

  const { message } = useMemo(() => {
    const stage = stages[currentStage];

    if (stage) {
      const { messageId, lottieAnimation, lottieOptions = {} } = stage;

      loadLottie(containerRef, lottieAnimation, lottieOptions, lottieRef);

      return {
        message: messages[messageId],
      };
    } else {
      destroyLottie(lottieRef);
      return {};
    }
  }, [currentStage, stages]);

  // Effects
  useEffect(() => {
    const stage = stages[currentStage];
    if (containerRef.current && stage) {
      loadLottie(
        containerRef,
        stage.lottieAnimation,
        stage.lottieOptions,
        lottieRef
      );
    }
  }, []);

  useEffect(() => {
    if (show) {
      setDoShow(true);
      setShowItems(false);
      setTimeout(() => setShowItems(true), 100);
    } else {
      if (showItems) {
        setShowItems(false);
        const timeout = setTimeout(() => setDoShow(false), 400);
        return () => clearTimeout(timeout);
      } else setDoShow(false);
    }
  }, [show]);

  useEffect(() => {
    // if (stage !== currentStage) {
    setShowItems(false);
    const timeout = setTimeout(() => {
      setCurrentStage(stage);
      setShowItems(true);
    });

    return () => {
      clearTimeout(timeout);
    };
    // }
  }, [stage]);

  // Render
  if (!doShow) return null;

  return (
    <div
      className={classNames(
        'loading-stages d-flex flex-column align-items-center justify-content-center p-3',
        className,
        {
          show: showItems,
        }
      )}
      style={style}
    >
      <div className="lottie-container flex-grow-1 h-50" ref={containerRef} />
      {message ? (
        <p className="message text-center font-weight-medium px-3 py-1 text-white">
          {message}
        </p>
      ) : null}
    </div>
  );
}
