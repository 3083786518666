import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ModalBody, ModalFooter } from 'reactstrap';

import IntlMessages from '../../helpers/IntlMessages';

import firebaseApp from '../../services/Firebase';
import QRCode from 'qrcode';

import { stringToMoney } from '../../utils/money';

export default function KYCModalSetupTab({ onGoNext }) {
  const [isLoading, setLoading] = useState(true);
  const [qrCode, setQrCode] = useState('');
  const [kyc, setKyc] = useState({});

  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    loadQRCode();
    loadCompanyKYCData();
  }, []);

  // Load Data
  const loadQRCode = async () => {
    const qrcodeUrl = await QRCode.toDataURL(
      '00020101021226760014BR.GOV.BCB.PIX01363d9bce8a-3e6f-4c9b-b920-2374334fb8b60214Setup Portao 3520400005303986540599.905802BR5908Portao 36014Belo Horizonte6108303804036229052598c10943721049cfa0bcb24d863042915'
    );

    setQrCode(qrcodeUrl);
  };

  const loadCompanyKYCData = async () => {
    const hasKyc = await firebaseApp
      .getInputedOrganizationKYCFromId(user.organizationId)
      .get();

    if (hasKyc.exists) {
      setKyc(hasKyc.data());
    }

    setLoading(false);
  };

  if (isLoading) {
    return (
      <ModalBody>
        <div className="loading" />
      </ModalBody>
    );
  }

  // Render
  return (
    <div>
      <ModalBody>
        <h5 className="mt-0">Pronto para ativar sua conta</h5>
        <p>
          Tudo certo! Para ativar a sua conta só falta o pagamento do setup.
          Para reconhcimento, é importante que o PIX seja feito a partir de uma
          conta do CNPJ {kyc.documentNumber}.
        </p>
        <div className="mb-1 text-center">
          <img src={qrCode} />
          <p>
            <span className="text-muted">Valor</span>
            <br />
            <strong>{stringToMoney('99.90', 2)}</strong>
          </p>
          <p>
            <span className="text-muted">CNPJ da Portão 3</span>
            <br />
            <strong>28.352.868/0001-70</strong>
          </p>
        </div>
      </ModalBody>
      <ModalFooter>
        <a
          className="btn btn-outline-transparent btn-lg"
          href="http://cdn.portao3.com.br/pix/Porta%CC%83o%203%20-%20Setup%20PIX.pdf"
          target="_blank"
        >
          <IntlMessages id="admin.kyc.payment.download" />
        </a>
      </ModalFooter>
    </div>
  );
}
