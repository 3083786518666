import React, { useMemo, useRef } from 'react';
import { useIntl } from 'react-intl';
import CustomAnimatedCheckbox from '../../components/common/CustomCheckbox';
import { PopupModalPositions } from '../../components/common/PopupModal/consts';
import PopupModal from '../../components/common/PopupModal/PopupModal';
import { BudgetSortOptions } from '../../constants/budget';
import useShowToggle from '../../hooks/useShowToggle';
import { sortAlphabetically } from '../../utils/sort';

export default function BudgetSortPopup({ sort, setSort, children }) {
  const { messages } = useIntl();

  // Refs
  const targetRef = useRef(null);

  // State
  const [show, _, toggle] = useShowToggle();

  const options = useMemo(
    () =>
      sortAlphabetically(
        Object.keys(BudgetSortOptions).map((id) => ({
          id,
          name: messages[`budget.sort.${id}`],
        })),
        'name'
      ),
    []
  );

  // Function
  const onSelect = (id) => {
    setSort(id);
    toggle();
  };

  // Render
  return (
    <>
      <div
        className="budget-sort-popup-container"
        ref={targetRef}
        onClick={toggle}
      >
        {children}
      </div>

      <PopupModal
        className="budget-sort-popup budget-options-popup"
        show={show}
        toggle={toggle}
        target={targetRef}
        showPosition={PopupModalPositions.LEFT}
        calculateStyleOnShow
        hideOnScroll
        displacementX={-15}
      >
        <div className="content p-4">
          <h3 className="text-primary font-primary m-0 p-0 fs-1-2rem">
            {messages['budget.sort.title']}
          </h3>
          <p className="m-0 p-0 text-muted fs-0-8rem lh-1-5 mt-1">
            {messages['budget.sort.description']}
          </p>
          <ul className="m-0 p-0 mt-3">
            {options.map(({ id, name }) => {
              const isSelected = id === sort;

              return (
                <li
                  key={id}
                  className="d-flex align-items-center my-2 pointer"
                  onClick={() => onSelect(id)}
                >
                  <CustomAnimatedCheckbox
                    checked={isSelected}
                    purple
                    size="1.5rem"
                    onChange={() => onSelect(id)}
                  />
                  <span className="ml-2 fs-0-8rem">{name}</span>
                </li>
              );
            })}
          </ul>
        </div>
      </PopupModal>
    </>
  );
}
