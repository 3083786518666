import moment from 'moment';
import recordStatus from '../constants/recordStatus';
import firebaseApp from '../services/Firebase';

let lastUpdated = null;

const shouldRenewMotives = async () => {
  return moment().isAfter(moment(lastUpdated).add(1, 'days'));
};

const getNewMotives = async (orgId, onlyActive = false) => {
  let snap = firebaseApp.getMotivesFromOrganization(orgId);

  if (onlyActive) snap = snap.where('status', '==', recordStatus.ACTIVE);

  return await snap.get({ source: 'server' });
};

export const getMotives = async (orgId, onlyActive = false) => {
  const storageSnapshot = await firebaseApp
    .getMotivesFromOrganization(orgId)
    .get({ source: 'cache' });

  if (storageSnapshot.exists) {
    const shouldRenew = shouldRenewMotives();
    if (shouldRenew) {
      lastUpdated = moment();
      return await getNewMotives(orgId, onlyActive);
    }

    return storageSnapshot;
  }

  return await getNewMotives(orgId, onlyActive);
};

export const loadMotives = async (orgId, onlyActive = false) => {
  const motiviesSnap = await getMotives(orgId, onlyActive);

  const motives = [];
  motiviesSnap.forEach((motiveSnap) => {
    motives.push({
      value: motiveSnap.id,
      label: motiveSnap.data().name,
    });
  });

  return motives;
};
