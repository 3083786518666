import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import moment from 'moment';
import { Button } from 'reactstrap';
import { calculateNextRecurrenceInDay } from '../../../../utils/recurrenceBudget';

import recordStatus from '../../../../constants/recordStatus';
import appFunctions from '../../../../services/Functions';
import useExecuteAction from '../../../../hooks/useExecuteAction';
import classNames from 'classnames';

function ActiveStatus({ budget, toggle }) {
  const { messages, formatMessage } = useIntl();

  const { executeAction, loading } = useExecuteAction();

  // States
  const nextRecurrence = useMemo(() => {
    const daysDiff = Math.max(
      calculateNextRecurrenceInDay(budget.nextRecurrence),
      0
    );

    return formatMessage(
      { id: 'recurrence-budget-modal.status.next-recorrence' },
      {
        days: daysDiff,
        date: moment(budget.nextRecurrence, 'YYYY-MM-DD').format('DD/MM/YY'),
      }
    );
  }, [budget]);

  // Functions
  const deactivateBudget = () =>
    executeAction(
      (async () => {
        try {
          await appFunctions.updateRecurrenceBudgetStatus(
            budget.id,
            recordStatus.DEACTIVATED
          );

          toggle();
        } catch (err) {
          console.error(err);
        }
      })()
    );

  // Render
  return (
    <div className="active-status text order-status">
      <p className="color-alert m-0 p-0 fs-0-75rem lh-1-5">{nextRecurrence}</p>
      <hr className="my-3" />
      <Button
        outline
        color="primary"
        className="m-0 mb-3 break-title"
        onClick={deactivateBudget}
        disabled={loading}
      >
        {messages['recurrence-budget-modal.status.btn.deactivate']}
      </Button>
    </div>
  );
}

function DeactivatedStatus({ budget, toggle }) {
  const { messages, formatMessage } = useIntl();

  const { executeAction, loading } = useExecuteAction();

  // States
  const { topText, colorClass } = useMemo(() => {
    if (budget.deactivatedMotive) {
      return {
        topText: formatMessage(
          { id: 'recurrence-budget-modal.status.deactivated-at.disabled' },
          {
            date: moment.unix(budget.updatedAt).format('DD/MM/YY'),
            message:
              messages[
                `recurrence-budget.history.msg.${budget.deactivatedMotive}`
              ],
          }
        ),
        colorClass: 'color-danger',
      };
    }

    return {
      topText: formatMessage(
        { id: 'recurrence-budget-modal.status.deactivated-at' },
        {
          date: moment.unix(budget.updatedAt).format('DD/MM/YY'),
        }
      ),
      colorClass: 'color-alert',
    };
  }, [budget]);

  // Functions
  const activateBudget = () =>
    executeAction(
      (async () => {
        try {
          await appFunctions.updateRecurrenceBudgetStatus(
            budget.id,
            recordStatus.ACTIVE
          );

          toggle();
        } catch (err) {
          console.error(err);
        }
      })()
    );

  // Render
  return (
    <div className="active-status text order-status">
      <p className={classNames('m-0 p-0 fs-0-75rem lh-1-5', colorClass)}>
        {topText}
      </p>
      {budget.deactivatedMotive ? null : (
        <>
          <hr className="my-3" />
          <Button
            outline
            color="primary"
            className="m-0 mb-3 break-title"
            onClick={activateBudget}
            disabled={loading}
          >
            {messages['recurrence-budget-modal.status.btn.activate']}
          </Button>
        </>
      )}
    </div>
  );
}

export default function RecurrenceBudgetStatus({ budget, toggle }) {
  const Component = useMemo(
    () =>
      budget.status === recordStatus.ACTIVE ? ActiveStatus : DeactivatedStatus,
    [budget]
  );

  return (
    <div className="recurrence-budget-status pr-3">
      <Component budget={budget} toggle={toggle} />
    </div>
  );
}
