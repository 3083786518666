import moment from 'moment';
import { useMemo } from 'react';

export default function useCanAlter({
  reservations,
  detailsField,
  dateField,
  dateFormat = 'DD/MM/YYYY',
  setValues,
  cancellations = [],
}) {
  const canAlter = useMemo(() => {
    const details = reservations[detailsField];

    if (details) {
      const dateValue = details[dateField];

      if (dateValue) {
        const date = moment(dateValue, dateFormat);

        if (setValues) date.set(setValues);

        return !moment().isAfter(date);
      }
    }

    return false;
  }, [
    reservations,
    detailsField,
    dateField,
    dateFormat,
    setValues,
    cancellations,
  ]);

  return canAlter;
}
