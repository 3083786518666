import React, { useMemo } from 'react';
import { Button } from 'reactstrap';

import { useIntl } from 'react-intl';

import { trackPropertiesSegment } from '../../utils/segment';
import moment from 'moment';
import orderStatus from '../../constants/orderStatus';

export default function AlterationInfo({
  order,
  goForAlteration,
  toggleModal,
}) {
  const { messages } = useIntl();

  // States
  const canAlter = useMemo(() => {
    if (order.statusCode === orderStatus.ISSUED) {
      const today = moment().unix();
      if (order.filterTimestamp >= today) return true;
    }

    return false;
  }, [order]);

  // Render
  if (canAlter) {
    return (
      <div>
        <Button
          outline
          color="primary"
          className="mt-0 mb-2"
          onClick={() => {
            trackPropertiesSegment('User alter order', {
              order,
            });

            goForAlteration();
          }}
        >
          {messages['travel.order.cancellation.alter']}
        </Button>
      </div>
    );
  }

  return <div />;
}
