import React, { Component } from 'react';
import { Form, Row, Label, CustomInput } from 'reactstrap';
import { Colxx } from '../../components/common/CustomBootstrap';

import CarLocationSearch from '../../components/search/CarLocationSearch';
import SearchButton from '../../components/search/SearchButton';
import DateSearch from '../../components/search/DateSearch';

import { getSearchCode, getProviders } from '../../utils/search';

import { injectIntl } from 'react-intl';
import IntlMessages from '../../helpers/IntlMessages';

import SearchStatus from '../../constants/searchStatus';
import CustomErrorMessage from '../../components/CustomErrorMessage';
import { validateFields, objectValidator } from '../../utils/fieldValidation';
import Sequence from '../../utils/sequence';

class CarForm extends Component {
  outboundRef = React.createRef();
  originRef = React.createRef();

  constructor(props) {
    super(props);

    this.state = { errors: {} };
    this.sequence = new Sequence([
      [
        (currentProps, _) => currentProps.search.origin.value,
        () => {
          if (this.originRef.current)
            this.originRef.current.children[0].focus();
        },
      ],
      [
        (currentProps, _) => currentProps.search.outbound_date,
        () => {
          if (this.outboundRef.current) this.outboundRef.current.setFocus();
        },
      ],
    ]);
  }

  componentDidMount() {
    if (this.props.autoSearch) this.startSearch();
    else this.sequence.run(this.props, this.props);
  }

  componentDidUpdate(prevProps) {
    if (this.props.autoSearch) {
      if (this.props.search.searchCode !== prevProps.search.searchCode) {
        this.startSearch();
      }
    }

    this.sequence.run(this.props, prevProps);
  }

  verifyFields = (search) => {
    const fields = [
      ['origin', objectValidator],
      ['inbound_date'],
      ['outbound_date'],
    ];

    if (!search.dropOffSamePickUp)
      fields.push(['destination', objectValidator]);

    const errors = validateFields(search, fields);

    this.setState({ errors });

    return !Object.keys(errors).length;
  };

  removePropertyFromErrors = (property) => {
    if (property in this.state.errors) {
      const { [property]: value, ...errors } = this.state.errors;
      this.setState({ errors });
    }
  };

  // Do Search
  startSearch = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    const { search, startSearch } = this.props;

    if (search.status !== SearchStatus.LOADING && this.verifyFields(search)) {
      const {
        origin,
        destination,
        outbound_date,
        inbound_date,
        dropOffSamePickUp,
        currency,
      } = search;

      let searchCode = '';
      let payload = '';

      if (dropOffSamePickUp) {
        // Validate
        if (!origin.value || !outbound_date || !inbound_date) {
          return;
        }

        searchCode = getSearchCode(
          'car',
          origin.value,
          outbound_date,
          '',
          inbound_date,
          currency
        );

        payload = {
          originCode: origin.cityCode,
          originDate: outbound_date.format('DD/MM/YYYY'),
          originTime: outbound_date.format('HH:mm'),
          destinationDate: inbound_date.format('DD/MM/YYYY'),
          destinationTime: inbound_date.format('HH:mm'),
          providers: getProviders('car'),
          status: SearchStatus.LOADING,
        };
      } else {
        // Validate
        if (
          !origin.value ||
          !outbound_date ||
          !destination.value ||
          !inbound_date
        ) {
          return;
        }

        searchCode = getSearchCode(
          'car',
          origin.value,
          outbound_date,
          destination.value,
          inbound_date,
          currency
        );

        payload = {
          originCode: origin.cityCode,
          originDate: outbound_date.format('DD/MM/YYYY'),
          originTime: outbound_date.format('HH:mm'),
          destinationCode: destination.cityCode,
          destinationDate: inbound_date.format('DD/MM/YYYY'),
          destinationTime: inbound_date.format('HH:mm'),
          providers: getProviders('car'),
          status: SearchStatus.LOADING,
        };
      }

      startSearch(searchCode, payload);
    }
  };

  // Render
  render() {
    const { messages } = this.props.intl;
    const {
      search,
      setOriginLocation,
      setDestinationLocation,
      setOutboundDate,
      setInboundDate,
      toggleDropOffSamePickUp,
    } = this.props;
    const { errors } = this.state;

    if (search.dropOffSamePickUp) {
      return (
        <Form className="travel-search car" onSubmit={this.startSearch}>
          <Row>
            <Colxx xxs="3">
              <div ref={this.originRef}>
                <Label
                  className="form-group search has-top-label w-100 mb-2"
                  onFocus={() => this.removePropertyFromErrors('origin')}
                >
                  <CarLocationSearch
                    className="outbound-search"
                    value={search.origin}
                    setValue={setOriginLocation}
                  />
                  <IntlMessages id="travel.pickUpLocation" />
                  <CustomErrorMessage error={errors.origin}>
                    {messages['forms.validation.required']}
                  </CustomErrorMessage>
                </Label>
              </div>
              <div>
                <CustomInput
                  type="checkbox"
                  id={`dropOff-same-as-pickUp`}
                  label={messages['travel.dropOffSamePickUp']}
                  checked={search.dropOffSamePickUp}
                  onChange={toggleDropOffSamePickUp}
                />
              </div>
            </Colxx>
            <Colxx xxs="5">
              <Label
                className="form-group search has-top-label float-left w-40"
                onClick={(e) => e.preventDefault()}
                onFocus={() => this.removePropertyFromErrors('outbound_date')}
              >
                <DateSearch
                  className="outbound-date"
                  selectedDate={search.outbound_date}
                  setValue={setOutboundDate}
                  isStart
                  defaultStartDate={search.outbound_date}
                  defaultEndDate={search.inbound_date}
                  showTime
                  ref={this.outboundRef}
                />
                <IntlMessages id="travel.pickUpTime" />
                <CustomErrorMessage
                  error={errors.outbound_date}
                  style={{ animationDelay: '0.1s' }}
                >
                  {messages['forms.validation.required']}
                </CustomErrorMessage>
              </Label>

              <Label className="form-group search has-top-label w-20 float-left addon-places">
                <img
                  src="/assets/img/icons/search_icon_car.svg"
                  alt={messages['alts.containers.search.car-form']}
                />
              </Label>

              <Label
                className="form-group search has-top-label float-left w-40"
                onClick={(e) => e.preventDefault()}
                onFocus={() => this.removePropertyFromErrors('inbound_date')}
              >
                <DateSearch
                  className="inbound-date"
                  selectedDate={search.inbound_date}
                  setValue={setInboundDate}
                  defaultMinDate={search.outbound_date}
                  isEnd
                  defaultStartDate={search.outbound_date}
                  defaultEndDate={search.inbound_date}
                  showTime
                />
                <IntlMessages id="travel.dropOffTime" />
                <CustomErrorMessage
                  error={errors.inbound_date}
                  style={{ animationDelay: '0.2s' }}
                >
                  {messages['forms.validation.required']}
                </CustomErrorMessage>
              </Label>
            </Colxx>
            <SearchButton status={search.status} />
          </Row>
        </Form>
      );
    } else {
      return (
        <Form className="travel-search" onSubmit={this.startSearch}>
          <Row>
            <Colxx xxs="4">
              <div className="clearfix">
                <Label
                  className="form-group search has-top-label float-left w-60 mb-2"
                  onFocus={() => this.removePropertyFromErrors('origin')}
                >
                  <CarLocationSearch
                    className="outbound-search"
                    value={search.origin}
                    setValue={setOriginLocation}
                  />
                  <IntlMessages id="travel.pickUpLocation" />
                  <CustomErrorMessage error={errors.origin}>
                    {messages['forms.validation.required']}
                  </CustomErrorMessage>
                </Label>

                <Label
                  className="form-group search has-top-label float-left w-40 mb-2"
                  onFocus={() => this.removePropertyFromErrors('outbound_date')}
                >
                  <DateSearch
                    className="outbound-date"
                    selectedDate={search.outbound_date}
                    setValue={setOutboundDate}
                    isStart
                    defaultStartDate={search.outbound_date}
                    defaultEndDate={search.inbound_date}
                    showTime
                  />
                  <IntlMessages id="travel.pickUpTime" />
                  <CustomErrorMessage
                    error={errors.outbound_date}
                    style={{ animationDelay: '0.1s' }}
                  >
                    {messages['forms.validation.required']}
                  </CustomErrorMessage>
                </Label>
              </div>
              <div>
                <CustomInput
                  type="checkbox"
                  id={`dropOff-same-as-pickUp`}
                  label={messages['travel.dropOffSamePickUp']}
                  checked={search.dropOffSamePickUp}
                  onChange={toggleDropOffSamePickUp}
                />
              </div>
            </Colxx>
            <Colxx xxs="1" className="text-center">
              <img
                src="/assets/img/icons/search_icon_car.svg"
                alt={messages['alts.containers.search.car-form']}
              />
            </Colxx>
            <Colxx xxs="4">
              <Label
                className="form-group search has-top-label float-left w-60 mb-2"
                onFocus={() => this.removePropertyFromErrors('destination')}
              >
                <CarLocationSearch
                  className="inbound-search"
                  value={search.destination}
                  setValue={setDestinationLocation}
                />
                <IntlMessages id="travel.dropOffLocation" />
                <CustomErrorMessage
                  error={errors.destination}
                  style={{ animationDelay: '0.2s' }}
                >
                  {messages['forms.validation.required']}
                </CustomErrorMessage>
              </Label>

              <Label
                className="form-group search has-top-label float-left w-40"
                onFocus={() => this.removePropertyFromErrors('inbound_date')}
              >
                <DateSearch
                  className="inbound-date"
                  selectedDate={search.inbound_date}
                  setValue={setInboundDate}
                  defaultMinDate={search.outbound_date}
                  isEnd
                  defaultStartDate={search.outbound_date}
                  defaultEndDate={search.inbound_date}
                  showTime
                />
                <IntlMessages id="travel.dropOffTime" />
                <CustomErrorMessage
                  error={errors.inbound_date}
                  style={{ animationDelay: '0.3s' }}
                >
                  {messages['forms.validation.required']}
                </CustomErrorMessage>
              </Label>
            </Colxx>
            <Colxx xxs="1"></Colxx>
            <SearchButton status={search.status} />
          </Row>
        </Form>
      );
    }
  }
}

export default injectIntl(CarForm);
