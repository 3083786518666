import firebaseApp from '../services/Firebase';
import appFunctions from '../services/Functions';
import clone from 'lodash/clone';
import { trackPropertiesSegment } from './segment';
import moment from 'moment';
import orderStructure from '../constants/orderStructure';
import { RESERVATION_ACTION_TIMEOUT } from '../constants/reservations';
import { getUserNationality } from './nationality';
import { DEFAULT_NATIONALITY } from '../constants/countries';
import { getOrganizationConfig } from './organization';
import { isCpfValid } from './cpf';
import { isIdentityValid } from './identity';

import find from 'lodash/find';
import map from 'lodash/map';
import maxBy from 'lodash/maxBy';
import minBy from 'lodash/minBy';

export const getRequiredFields = (requiredFields = {}, invalidFields = []) => {
  let hasRequiredField = false;

  const filteredRequiredFields = Object.keys(requiredFields).reduce(
    (arr, key) => {
      if (requiredFields[key] && !invalidFields.includes(key)) {
        hasRequiredField = true;
        arr.push(key);
      }

      return arr;
    },
    []
  );

  return hasRequiredField ? filteredRequiredFields : null;
};

export const checkRequiredFields = (reservationParams, requiredFields) => {
  if (!requiredFields) return true;

  return requiredFields.every((key) => {
    const field = reservationParams[key];
    return field ? field.value || field.length : false;
  });
};

export const canMakeReservation = ({
  orderStructure: reservationOrderStructure,
  reservationParams,
  requiredFields = [],
  organization,
}) => {
  const { needPassport, passport } = reservationParams;
  const validPassport = needPassport
    ? !!passport?.number && !!passport?.validUntil && !!passport?.country
    : true;

  if (reservationOrderStructure !== reservationParams.structure) return false;

  const nationality =
    reservationParams.nationality ||
    getUserNationality({ user: reservationParams.passenger, organization });

  if (reservationOrderStructure === orderStructure.ORGANIZATION) {
    var identity =
      reservationParams?.passenger?.documents?.identity?.number ||
      reservationParams.identity;

    return Boolean(
      (nationality === DEFAULT_NATIONALITY
        ? isCpfValid(reservationParams.cpf)
        : isIdentityValid(identity)) &&
        reservationParams.birthdate &&
        checkRequiredFields(reservationParams, requiredFields) &&
        validPassport
    );
  } else {
    const {
      firstName = '',
      lastName = '',
      birthdate = '',
      documents = {},
    } = reservationParams.passenger || {};

    return Boolean(
      firstName &&
        lastName &&
        birthdate.length === 10 &&
        (nationality === DEFAULT_NATIONALITY
          ? isCpfValid(documents.cpf?.number)
          : isIdentityValid(documents.identity?.number)) &&
        validPassport
    );
  }
};

export const sortBySegment = (reservations) => {
  if (reservations[0].type === 'flight') {
    return reservations.sort(
      (a, b) => a.flightDetails.flightIndex - b.flightDetails.flightIndex
    );
  } else {
    return reservations.sort(
      (a, b) => a.busDetails.legIndex - b.busDetails.legIndex
    );
  }
};

export const sortReservationJourneys = (reservations) => {
  const obj = {};

  reservations.forEach((reservation) => {
    const details = reservation.flightDetails || reservation.busDetails;
    if (details.journeyId in obj) obj[details.journeyId].push(reservation);
    else obj[details.journeyId] = [reservation];
  });

  return obj[1]
    ? [sortBySegment(obj[0]), sortBySegment(obj[1])]
    : [sortBySegment(obj[0])];
};

export const analyzeReservationParams = async (
  reservationParams,
  doUpdateUser = true
) => {
  const {
    cpf,
    birthdate,
    identity,
    passenger,
    nationality,
    passport,
    needPassport,
    ...otherParams
  } = reservationParams;
  const passengerChanges = {};
  const documents = passenger?.documents || {};
  const isPersonal = reservationParams.structure === orderStructure.PERSONAL;

  const userDocuments = documents;

  if (!passenger.birthdate) {
    passengerChanges['birthdate'] = birthdate;
    passenger['birthdate'] = birthdate;
  }

  if (nationality && !passenger.nationality) {
    passengerChanges['nationality'] = nationality;
    passenger['nationality'] = nationality;
  }

  if (getUserNationality(passenger) === DEFAULT_NATIONALITY) {
    if (!documents.cpf?.number) {
      documents['cpf'] = {
        number: cpf,
      };
      passenger['documents'] = documents;
      passengerChanges['documents.cpf.number'] = cpf;
    }
  } else {
    if (!documents.identity?.number) {
      documents['identity'] = {
        number: identity,
      };

      passenger['documents'] = documents;
      passengerChanges['documents.identity.number'] = identity;
    }
  }

  const docPassport = documents?.passport;

  if (
    needPassport &&
    (!docPassport?.number ||
      !docPassport?.validUntil ||
      !docPassport?.country ||
      passport)
  ) {
    passenger.documents = {
      ...documents,
      passport: {
        number: passport?.number,
        validUntil: passport?.validUntil,
        country: passport?.country.value,
      },
    };

    if (!isPersonal) {
      passengerChanges.documents = {
        ...documents,
        passport: {
          number: passport?.number,
          validUntil: passport?.validUntil,
          country: passport?.country.value,
        },
      };

      const documentsPassenger =
        otherParams?.passengerTimeline?.documents?.passport;
      documentsPassenger.number = passport?.number;
      documentsPassenger.validUntil = passport?.validUntil;
      documentsPassenger.country = passport?.country.value;
    }
  }

  const hasChange = Object.keys(passengerChanges).length > 0;

  if (!isPersonal && doUpdateUser && hasChange) {
    const params = {
      body: {
        user: userDocuments,
        userChanges: passengerChanges,
      },
      message: 'Updated user',
      origin: 'CLIENT',
    };

    await firebaseApp.updateUserFromId(
      passenger.uid,
      passengerChanges,
      true,
      params
    );
  }

  if (isPersonal) {
    delete otherParams.costCenter;
    delete otherParams.project;
    delete otherParams.motive;
    delete otherParams.tags;
  }

  return {
    ...otherParams,
    passenger,
  };
};

export const getReservationParamsFromOrder = (order) => {
  if (order.structure === orderStructure.ORGANIZATION) {
    const {
      passengerInfo,
      passengerId,
      costCenter,
      project,
      motive,
      tags,
      observation = '',
    } = order;

    return {
      cpf: passengerInfo.documents?.cpf?.number || '',
      birthdate: passengerInfo.birthdate,
      passenger: passengerInfo,
      passengerTimeline: passengerInfo,
      passengerId,
      costCenter,
      project,
      motive,
      tags,
      observation,
      structure: orderStructure.ORGANIZATION,
    };
  } else {
    const { passengerInfo, passengerId } = order;

    return {
      structure: orderStructure.PERSONAL,
      passenger: passengerInfo,
      passengerId,
      passengerTimeline: passengerInfo,
    };
  }
};

export const reserveHotelItem = async ({
  organizationId,
  search,
  reservationParams,
  item,
  selectedRoom,
  doUpdateUser = true,
  orderParams = {},
}) => {
  const room = clone(selectedRoom);

  const newReservationParams = await analyzeReservationParams(
    reservationParams,
    doUpdateUser
  );

  const orderDetails = {
    ...orderParams,
    ...newReservationParams,
    fare: {
      hasBreakfast: selectedRoom.board.breakfast,
      issueUntil: null,
      total: selectedRoom.fare,
      currency: selectedRoom.currency,
    },
    provider: {
      origin: item.origin,
      token: item.token,
    },
    searchCode: search.searchCode,
  };

  const cancellation = room.cancellation;
  delete room.cancellation;

  if (!room.info) room.info = {};

  const reservation = {
    hotel: item.hotel,
    hotelId: item.hotelId,
    selectedRoom: room,
    cancellation,
    checkIn: moment(search.outbound_date).format('DD/MM/YYYY'),
    checkOut: moment(search.inbound_date).format('DD/MM/YYYY'),
    confirmation: '',
  };

  trackPropertiesSegment('New Reservation', {
    ...orderDetails,
    type: 'hotel',
  });

  await firebaseApp.createHotelReservation({
    organizationId,
    passenger: newReservationParams.passenger,
    passengerId: newReservationParams.passengerId,
    orderDetails,
    reservations: [reservation],
    filterTimestamp: moment(reservation.checkIn, 'DD/MM/YYYY')
      .endOf('day')
      .utc()
      .unix(),
    type: 'hotel',
  });
};

export const reserveFlightItem = async ({
  organizationId,
  search,
  reservationParams,
  item,
  selectedSegments,
  doUpdateUser = true,
  orderParams = {},
  params,
}) => {
  const newReservationParams = await analyzeReservationParams(
    reservationParams,
    doUpdateUser
  );

  // // Create Order and Reservations
  const orderDetails = await {
    ...newReservationParams,
    journeys: map(item.journeys, (journey, index) => {
      const arrivalStation =
        find(params.airports, {
          iata: journey.arrivalStation,
        })?.city || journey.arrivalStation;
      const departureStation =
        find(params.airports, {
          iata: journey.departureStation,
        })?.city || journey.arrivalStation;

      const segment = selectedSegments[index];

      return {
        id: index,
        departureStation: {
          iataCode: journey.departureStation,
          name: departureStation,
        },
        arrivalStation: {
          iataCode: journey.arrivalStation,
          name: arrivalStation,
        },
        departureDate: journey.departureDate,
        sellKey: segment.sellKey,
      };
    }),
    provider: {
      origin: item.origin,
      source: item.source,
      validatingCarrier: item.validatingAirline,
    },
    fare: item.fare,
    buyWithCredit,
    searchCode: search.searchCode,
  };

  const reservations = [];
  item.journeys.forEach((_, index) => {
    const segment = selectedSegments[index];

    segment.flights.forEach((flight, flightIndex) => {
      const arrivalStationName =
        find(params.airports, {
          iata: flight.arrivalStation,
        })?.city || flight.arrivalStation;
      const departureStationName =
        find(params.airports, {
          iata: flight.departureStation,
        })?.city || flight.departureStation;

      reservations.push({
        baggage: '',
        bookingCode: '',
        airline: flight.airline,
        airlineOperating: flight.airlineOperating,
        validatingAirline: segment.validatingAirline,
        flightNumber: flight.flightNumber,
        departureDateTime: moment(flight.departureDateTime).format(
          'DD/MM/YYYY HH:mm'
        ),
        departureStation: {
          iataCode: flight.departureStation,
          name: departureStationName,
        },
        arrivalDateTime: moment(flight.arrivalDateTime).format(
          'DD/MM/YYYY HH:mm'
        ),
        arrivalStation: {
          iataCode: flight.arrivalStation,
          name: arrivalStationName,
        },
        fare: flight.fare,
        journeyId: index,
        buyWithCredit,
        flightIndex,
      });
    });
  });

  trackPropertiesSegment('New Reservation', {
    ...orderDetails,
    type: 'flight',
  });

  const latestReservation = maxBy(reservations, (r) => {
    return moment(r.arrivalDateTime, 'DD/MM/YYYY HH:mm').unix();
  });

  await firebaseApp.createFlightReservation({
    organizationId,
    passengerId: newReservationParams.passengerId,
    passenger: newReservationParams.passenger,
    orderDetails,
    reservations,
    filterTimestamp: moment(
      latestReservation.arrivalDateTime,
      'DD/MM/YYYY HH:mm'
    )
      .endOf('day')
      .utc()
      .unix(),
    type: 'flight',
  });
};

export const getDefaultCommonParams = (user = {}) => ({
  costCenter: {
    value: user.costCenter?.id || '',
    label: user.costCenter?.name || '',
  },
  project: {
    value: user.project?.id || '',
    label: user.project?.name || '',
  },
  motive: {
    value: '',
    label: '',
  },
  tags: [],
  observation: '',
});

export const canPerformAction = (
  lastAction,
  timeout = RESERVATION_ACTION_TIMEOUT
) => {
  return lastAction ? new Date().getTime() - lastAction > timeout : true;
};

export const hasFreeCancellationHotel = (cancellation = []) => {
  let hasFreeCancellation = cancellation.length > 0,
    freeCancellationDate = null;

  if (hasFreeCancellation) {
    freeCancellationDate = minBy(cancellation, (c) => {
      return moment(c.startDate).unix();
    }).startDate;

    // Free cancelation is past date?
    if (moment(freeCancellationDate).isBefore(moment())) {
      hasFreeCancellation = false;
      freeCancellationDate = null;
    }
  }

  return hasFreeCancellation;
};

export const getTravelConfig = (auth) => {
  const { organization = true, personal = true } = getOrganizationConfig(
    auth.organization.config,
    'travels'
  );

  return {
    [orderStructure.ORGANIZATION]: organization,
    [orderStructure.PERSONAL]: personal,
  };
};
