import React, { Component } from 'react';
import ContentLoader from 'react-content-loader';

import { injectIntl } from 'react-intl';

class LoadingPackage extends Component {
  render() {
    const { messages } = this.props.intl;

    return (
      <ContentLoader
        title={messages['travel.search.loading']}
        speed={2}
        width={250}
        height={300}
        viewBox="0 0 250 300"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        className={'w-100'}
      >
        <rect x="10" y="220" rx="3" ry="3" width="200" height="10" />
        <rect x="10" y="240" rx="3" ry="3" width="200" height="10" />
        <rect x="10" y="260" rx="3" ry="3" width="150" height="30" />
        <circle cx="25" cy="195" r="15" />
        <circle cx="65" cy="195" r="15" />
        <rect x="10" y="40" rx="3" ry="3" width="60" height="20" />
      </ContentLoader>
    );
  }
}

export default injectIntl(LoadingPackage);
