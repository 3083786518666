import {
  TBusParams,
  TBusSearchParams,
} from 'src/portao3-legacy/types/orders/bus/busParams';
import { IComponentFilter } from 'src/portao3-legacy/types/orders/orders';
import SearchFilterWrapper from './SearchFilterWrapper';
import useCustomIntl from 'src/portao3-legacy/hooks/useCustomIntl';
import { useMemo } from 'react';
import {
  doesParamHaveItems,
  formatBusJourneys,
  formatCarriers,
  formatContainsParamsWithEvent,
  formatStops,
  getPriceParamInfo,
  isTruthy,
} from 'src/portao3-legacy/utils/params';
import SearchFilterCard from './SearchFilterCard';
import { RangeTooltip } from 'src/portao3-legacy/components/common/SliderTooltips';
import { CustomInput } from 'reactstrap';
import { registeredBus } from 'src/portao3-legacy/constants/travelValues';

export default function BusFilters({
  isLoading,
  searchStatus,
  searchParams,
  params,
  status,
  setParam,
  setNamedParam,
}: IComponentFilter<TBusSearchParams, TBusParams>) {
  const { messages } = useCustomIntl();

  // States
  const {
    showPrice,
    minPrice,
    maxPrice,
    showStops,
    stops,
    showCarriers,
    formattedCarriers,
    showJourneys,
    formattedJourneys,
    showSeatClasses,
    isRoundTrip,
  } = useMemo(() => {
    const showStops = doesParamHaveItems(searchParams.stops);
    const showCarriers = doesParamHaveItems(searchParams.carriers);
    const showJourneys = doesParamHaveItems(searchParams.journeys);

    return {
      ...getPriceParamInfo(searchParams.values),
      showStops,
      stops: showStops ? formatStops(searchParams.stops, messages) : [],
      showCarriers,
      formattedCarriers: showCarriers
        ? formatCarriers(searchParams.carriers, registeredBus)
        : [],
      showSeatClasses: doesParamHaveItems(searchParams.seatClasses),
      showJourneys,
      formattedJourneys: showJourneys
        ? formatBusJourneys(searchParams.journeys, messages)
        : [],
      isRoundTrip: searchParams.journeys?.length > 1,
    };
  }, [searchParams]);

  // Render
  return (
    <SearchFilterWrapper isLoading={isLoading} searchStatus={searchStatus}>
      <SearchFilterCard
        show={showPrice}
        title={messages['containers.search.flight-filters.prices']}
      >
        <RangeTooltip
          min={minPrice}
          max={maxPrice}
          className="mb-5"
          value={[params.price.min || minPrice, params.price.max || maxPrice]}
          allowCross={false}
          pushable
          onChange={setNamedParam('price')}
        />
      </SearchFilterCard>

      <SearchFilterCard show={showStops} title={messages['flight.stops']}>
        {stops.map((stop) => (
          <CustomInput
            key={`stops-${stop.value}`}
            type="checkbox"
            id={`check-stop-${stop.value}`}
            label={stop.label}
            checked={isTruthy(params.stops[stop.value])}
            onChange={(e) =>
              setParam(
                'stops',
                formatContainsParamsWithEvent(stop.value as string, e)
              )
            }
          />
        ))}
      </SearchFilterCard>

      <SearchFilterCard
        show={showCarriers}
        title={messages['containers.search.bus-filters.companies']}
      >
        {formattedCarriers.map((carrier) => (
          <CustomInput
            key={carrier.value}
            type="checkbox"
            id={`check-airline-${carrier.value}`}
            label={carrier.label}
            checked={isTruthy(params.carriers[carrier.value])}
            onChange={(e) =>
              setParam(
                'carriers',
                formatContainsParamsWithEvent(carrier.value, e)
              )
            }
          />
        ))}
      </SearchFilterCard>

      <SearchFilterCard
        show={showJourneys}
        title={messages['containers.search.flight-filters.departure']}
      >
        {formattedJourneys.map((journey) => (
          <div key={`timefilter-${journey.index}`}>
            {isRoundTrip ? <small>{journey.label}</small> : null}
            <RangeTooltip
              min={0}
              max={24}
              className="mb-5"
              value={[
                params.departureTime[journey.index]
                  ? params.departureTime[journey.index].min
                  : 0,
                params.departureTime[journey.index]
                  ? params.departureTime[journey.index].max
                  : 24,
              ]}
              allowCross={false}
              pushable
              isTime
              onChange={(selectedPrices: number[]) =>
                setParam('departureTime', {
                  name: journey.index.toString(),
                  value: selectedPrices,
                })
              }
            />
          </div>
        ))}
      </SearchFilterCard>

      <SearchFilterCard
        show={showSeatClasses}
        title={messages['containers.search.flight-filters.seat-classes']}
      >
        {searchParams.seatClasses?.map((seatClass) => (
          <CustomInput
            key={seatClass}
            type="checkbox"
            id={`seat-class-${seatClass}`}
            label={seatClass}
            checked={isTruthy(params.seatClasses[seatClass], true)}
            onChange={(e) =>
              setParam(
                'seatClasses',
                formatContainsParamsWithEvent(seatClass, e)
              )
            }
          />
        ))}
      </SearchFilterCard>
    </SearchFilterWrapper>
  );
}
