import { TYPES_OF_CLASS } from '../constants/bus';
import moment from 'moment';

const busClassRegex = /[ ].*|-.*/g;

export function treatBusClass(busClass = '') {
  const normalizedClass = busClass.replace(busClassRegex, '').toUpperCase();
  return TYPES_OF_CLASS[normalizedClass] || TYPES_OF_CLASS.CONVENCIONAL;
}

export function getAlterableReservations(reservations, minHoursNotice = 6) {
  const today = moment();

  return reservations
    .filter((reservation) => {
      return today.isBefore(
        moment(
          reservation.busDetails.departureDateTime,
          'DD/MM/YYYY HH:mm'
        ).subtract(minHoursNotice, 'hours')
      );
    })
    .sort((a, b) => {
      if (a.busDetails.journeyId === b.busDetails.journeyId)
        return a.busDetails.legIndex - b.busDetails.legIndex;

      return a.busDetails.journeyId - b.busDetails.journeyId;
    });
}
