import React, { Component } from 'react';
import {
  Form,
  Row,
  Label,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import { Colxx } from '../../components/common/CustomBootstrap';

import HotelLocationSearch from '../../components/search/HotelLocationSearch';
import SearchButton from '../../components/search/SearchButton';
import DateSearch from '../../components/search/DateSearch';

import { getHotelSearchParams } from '../../utils/search';

import { injectIntl } from 'react-intl';
import IntlMessages from '../../helpers/IntlMessages';

import SearchStatus from '../../constants/searchStatus';
import CustomErrorMessage from '../../components/CustomErrorMessage';
import { validateFields, objectValidator } from '../../utils/fieldValidation';
import Sequence from '../../utils/sequence';
import HotelFormParams from './Params/HotelFormParams';
import searchStatus from '../../constants/searchStatus';

class HotelForm extends Component {
  outboundRef = React.createRef();
  inboundRef = React.createRef();
  originRef = React.createRef();

  constructor(props) {
    super(props);

    this.state = { errors: {} };

    this.sequence = new Sequence([
      [
        (currentProps, _) => currentProps.search.destination.value,
        () => {
          if (this.originRef.current)
            this.originRef.current.children[0].focus();
        },
      ],
      [
        (currentProps, _) => currentProps.search.outbound_date,
        () => {
          if (this.outboundRef.current) this.outboundRef.current.setFocus();
        },
      ],
      [
        (currentProps, _) => currentProps.search.inbound_date,
        () => {
          if (this.inboundRef.current) this.inboundRef.current.setFocus();
        },
      ],
    ]);
  }

  componentDidMount() {
    if (this.props.autoSearch) this.startSearch();
    else this.sequence.run(this.props, this.props);
  }

  componentDidUpdate(prevProps) {
    if (this.props.autoSearch) {
      if (
        prevProps.search.searchCode !== this.props.search.searchCode ||
        (prevProps.search.status === searchStatus.DONE &&
          this.props.search.status === searchStatus.NEW)
      ) {
        this.startSearch();
      }
    }

    this.sequence.run(this.props, prevProps);
  }

  verifyFields = (search) => {
    const errors = validateFields(search, [
      ['destination', objectValidator],
      ['inbound_date'],
      ['outbound_date'],
    ]);

    this.setState({ errors });

    return !Object.keys(errors).length;
  };

  removePropertyFromErrors = (property) => {
    if (property in this.state.errors) {
      const { [property]: value, ...errors } = this.state.errors;
      this.setState({ errors });
    }
  };

  // Do Search
  startSearch = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    const { search, startSearch } = this.props;

    if (search.status !== SearchStatus.LOADING && this.verifyFields(search)) {
      const { searchCode, payload } = getHotelSearchParams(
        search,
        this.props.organization
      );

      if (searchCode && payload) startSearch(searchCode, payload);
      else return null;
    }
  };

  // Render
  render() {
    const { messages } = this.props.intl;
    const {
      search,
      setDestinationLocation,
      setOutboundDate,
      setInboundDate,
      setAdult,
      setNationality,
    } = this.props;
    const { errors } = this.state;

    return (
      <Form className="travel-search" onSubmit={this.startSearch}>
        <Row>
          <Colxx xxs="3">
            <div ref={this.originRef}>
              <Label
                className="form-group search has-top-label w-100"
                onFocus={() => this.removePropertyFromErrors('destination')}
              >
                <HotelLocationSearch
                  className="outbound-search"
                  value={search.destination}
                  setValue={setDestinationLocation}
                />
                <IntlMessages id="travel.destination" />
                <CustomErrorMessage error={errors.destination}>
                  {messages['forms.validation.required']}
                </CustomErrorMessage>
              </Label>
            </div>
          </Colxx>
          <Colxx xxs="5">
            <Label
              className="form-group search has-top-label float-left w-40"
              onClick={(e) => e.preventDefault()}
              onFocus={() => this.removePropertyFromErrors('outbound_date')}
            >
              <DateSearch
                className="outbound-date"
                selectedDate={search.outbound_date}
                setValue={setOutboundDate}
                isStart
                defaultStartDate={search.outbound_date}
                defaultEndDate={search.inbound_date}
                ref={this.outboundRef}
              />
              <IntlMessages id="travel.checkin" />
              <CustomErrorMessage
                error={errors.outbound_date}
                style={{ animationDelay: '0.1s' }}
              >
                {messages['forms.validation.required']}
              </CustomErrorMessage>
            </Label>

            <Label className="form-group search has-top-label w-20 float-left addon-places">
              <img
                src="/assets/img/icons/search_icon_hotel.svg"
                alt={messages['alts.containers.search.car-form']}
              />
            </Label>

            <Label
              className="form-group search has-top-label float-left w-40"
              onClick={(e) => e.preventDefault()}
              onFocus={() => this.removePropertyFromErrors('inbound_date')}
            >
              <DateSearch
                className="inbound-date"
                selectedDate={search.inbound_date}
                setValue={setInboundDate}
                defaultMinDate={search.outbound_date}
                isEnd
                defaultStartDate={search.outbound_date}
                defaultEndDate={search.inbound_date}
                ref={this.inboundRef}
              />
              <IntlMessages id="travel.checkout" />
              <CustomErrorMessage
                error={errors.inbound_date}
                style={{ animationDelay: '0.2s' }}
              >
                {messages['forms.validation.required']}
              </CustomErrorMessage>
            </Label>
          </Colxx>
          <Colxx xxs="2">
            <Label className="form-group search has-top-label float-left w-100 dropdown-container">
              <UncontrolledDropdown className="roomSelection">
                <DropdownToggle caret size="xs">
                  {search.hotel_params.adults === 1
                    ? ` ${messages['travel.room.simple']}`
                    : search.hotel_params.adults === 2
                    ? ` ${messages['travel.room.double']}`
                    : search.hotel_params.adults === 3
                    ? ` ${messages['travel.room.triple']}`
                    : ` ${messages['travel.room.quadruple']}`}
                </DropdownToggle>
                <DropdownMenu>
                  <HotelFormParams
                    adults={search.hotel_params.adults}
                    setAdults={setAdult}
                    nationality={search.hotel_params.nationality}
                    setNationality={setNationality}
                  />
                </DropdownMenu>
              </UncontrolledDropdown>
              <IntlMessages id="travel.peopleRooms" />
            </Label>
          </Colxx>
          <SearchButton status={search.status} />
        </Row>
      </Form>
    );
  }
}

export default injectIntl(HotelForm);
