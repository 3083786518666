module.exports = {
  /* 01.General */
  'general.copyright': 'Puerta 3 © 2021 Todos los derechos reservados.',

  'general.loading': 'Cargando ...',
  'general.go-back': 'Atrás',
  'general.delete': 'Borrar',
  'general.send': 'Enviar',
  'general.reservations': 'reservas',
  'general.nights': 'noches',
  'general.total': 'Total',
  'general.new-total': 'Nuevo Total',
  'general.select': 'Select',
  'general.taxes': 'Impuestos, tasas y gravámenes ya incluidos',
  'general.adult': 'adulto',
  'general.adults': 'adultos',
  'general.star': 'estrella',
  'general.stars': 'estrellas',
  'general.from': 'de',
  'general.duration': 'Duración',
  'general.price': 'Precio',
  'general.more-details': 'Más detalles',
  'general.book': 'Libro',
  'general.slogan': 'Simple,<br/>inteligencia<br/>y eficaz.',
  'general.person': 'persona',
  'general.people': 'personas',
  'general.search': 'Búsqueda',
  'general.prices': 'Precios',
  'general.categories': 'Categorias',
  'general.all-selected': 'Todo seleccionado',
  'general.none': 'Ninguno',
  'general.yes': 'Sí',
  'general.no': 'No',
  'general.see-more': 'Ver más',
  'general.favorite': 'Favorito',
  'general.cheapest': 'Más barato',

  /* 02.Inicio de sesión de usuario, Cierre de sesión, Registro */
  'user.simple': 'Simple',
  'user.inteligent': 'Inteligente',
  'user.direct': 'Efectivo',
  'user.login': 'Iniciar sesión',
  'user.login.page': 'Login',
  'user.newPassword':
    'Su contraseña ha caducado, cree una nueva para continuar',
  'user.shouldInvite': 'Enviar invitación para acceder a la Puerta 3',
  'user.setupMFA': 'Añadir más seguridad a su cuenta',
  'user.setupMFA_desc':
    'La autenticación en dos pasos es una práctica sencilla que añade una capa adicional de protección a su usuario y contraseña. Lea el código QR con una aplicación adecuada.',
  'user.username': 'Correo electrónico o usuario',
  'user.password': 'Introduzca su contraseña',
  'user.reset_code': 'Código de verificación',
  'user.mfa_code': 'Código  simbólico',
  'user.new_password': 'Nueva contraseña',
  'user.new_password_confirm': 'Confirmar contraseña',
  'user.forgot-password-question': '¿Olvidó la contraseña?',
  'user.forgot-password': 'Olvidó la contraseña',
  'user.forgot-password_desc': 'Crea una nueva contraseña para tu cuenta.',
  'user.authMFA': 'Código de verificación',
  'user.authMFA_desc':
    'Introduce el código de verificación configurado en tu aplicación',
  'user.forgot-password-request-success':
    'Se ha enviado un enlace de intercambio de contraseñas a su correo electrónico.',
  'user.forgot-password-request-error':
    'El enlace utilizado ya no es válido. Por favor, intente solicitar el reinicio de nuevo.',

  'user.new-password': '¡Bienvenido a Portão 3!',
  'user.new-password_desc':
    'Crear una nueva contraseña para empezar a utilizar la plataforma',

  'user.CodeMismatchException':
    'Código de validación no válido. Por favor, compruebe el código que le enseramos por correo electrónico e inténtelo de nuevo.',
  'user.EnableSoftwareTokenMFAException':
    'Código de token no válido. Inténtelo de nuevo.',
  'user.NotAuthorizedException':
    'Contraseña incorrecta. Inténtelo de nuevo o haga clic en "¿Olvidó su contraseña?" para restablecerla.',
  'user.UserNotFoundException': 'No se pudo encontrar su cuenta en la puerta 3',
  'user.UserDisabledException':
    'Usuario bloqueado. Póngase en contacto con el soporte técnico para obtener más información.',
  'user.LimitExceededException':
    'Se han superado los intentos de restablecimiento. Inténtelo de nuevo más tarde.',
  'user.InvalidParameterException':
    'Su nueva contraseña es muy simple. Introduzca una contraseña más compleja para continuar.',
  'user.WeakPasswordException': 'La contraseña debe ser más compleja',
  'user.ExpiredCodeException':
    'El código de verificación ha caducado. Por favor, vuelva a solicitarlo.',
  'user.GeneralException':
    'Se ha producido un error interno. Nuestro equipo ya ha sido advertido',

  'user.fullName': 'Nombre completo',
  'user.firstName': 'Nombre',
  'user.lastName': 'Apellido',
  'user.gender': 'Género',
  'user.gender.MALE': 'Masculino',
  'user.gender.FEMALE': 'Feminino',
  'user.birthdate': 'Fecha de nacimiento',
  'user.email': 'E-mail',
  'user.mobilePhoneNumber': 'Móvil',
  'user.phoneNumber': 'Teléfono',
  'user.role': 'Cargo',
  'user.companyId': 'Registro',
  'user.costCenter': 'Centro de coste',
  'user.project': 'Proyecto',
  'user.rgNumber': 'Identidad',
  'user.rgEmitter': 'Organismo emisor',
  'user.cpf': 'CPF',
  'user.cnpj': 'CNPJ',
  'user.cnhNumber': 'CNH',
  'user.cnhValidUntil': 'Fecha de caducidad',
  'user.passportCountry': 'País emisor del pasaporte',
  'user.passportNumber': 'Número',
  'user.passportValidUntil': 'Fecha de caducidad',

  /* 03. Menu */
  'menu.app': 'Administrador',
  'menu.dashboard': 'Dashboard',
  'menu.active': 'Viajes activos',
  'menu.reports': 'Informes',
  'menu.analytics': 'Analytics',
  'menu.management': 'Políticas',
  'menu.finance': 'Financiero',
  'menu.users': 'Usuarios',
  'menu.about': 'About',
  'menu.kyc': 'Registrarse',
  'menu.add-user': 'Añadir usuario',
  'menu.summary': 'Resumen',

  'menu.pending': 'Pendiente',
  'menu.approval': 'Aprobaciones',
  'active.travelersInit': 'Usted es dueño',
  'active.travelersEnd': 'viajeros activos',
  'active.mapBrazil': 'Brasil',
  'active.mapWorld': 'Mundo',

  /* 04.Travel */
  'travel.menu.package': 'Aire + Hotel',
  'travel.menu.flight': 'Aire',
  'travel.menu.hotel': 'Hotel',
  'travel.menu.car': 'Coche',
  'travel.menu.bus': 'Autobús',
  'travel.menu.office': 'Oficina',
  'travel.next': 'Next Trips',
  'travel.flight': 'Vuelo',
  'travel.origin': 'Origen',
  'travel.destination': 'Destino',
  'travel.dropOffSamePickUp': 'Volver en la misma tienda',
  'travel.isTwoWay': 'Ida y vuelta',
  'travel.rental': 'Empresa de alquiler',
  'travel.pickUpLocation': 'Ubicación de recogida',
  'travel.pickUpTime': 'Fecha y hora de desistimiento',
  'travel.dropOffLocation': 'Ubicación de devolución',
  'travel.dropOffTime': 'Fecha y hora de devolución',
  'travel.officeLocation': 'Localización',
  'travel.officeDate': 'Fecha deseada',
  'travel.outbound': 'Ida',
  'travel.inbound': 'Atrás',
  'travel.baggage': 'maleta',
  'travel.baggages': 'maletas',
  'travel.Baggage': 'Equipaje',
  'travel.no_baggage': 'Sin equipaje',
  'travel.with_baggage': 'Con equipaje',
  'travel.backpack': 'Mochila',
  'travel.class': 'Clase',
  'travel.Economy': 'Económico',
  'travel.PremiumEconomy': 'Premium Economy',
  'travel.Business': 'Ejecutivo',
  'travel.FirstClass': 'Primera Clase',
  'travel.stay': 'Hosting',
  'travel.checkin': 'Check in',
  'travel.checkout': 'Check out',
  'travel.location-message': 'Tipo para buscar',
  'travel.location-searching': 'Buscando...',
  'travel.peopleRooms': 'Personas y habitaciones',
  'travel.baggageCabin': 'Equipaje y cabina',
  'travel.office_name': 'Oficina',
  'travel.room_name': 'Sala',
  'travel.date': 'Fecha',
  'travel.room_size': '# Personas',
  'travel.total': 'Total',
  'travel.search': 'Buscar',
  'travel.room': 'habitación',
  'travel.rooms': 'habitaciónes',
  'travel.Rooms': 'Habitaciónes',
  'travel.room.simple': 'simple',
  'travel.room.double': 'doble',
  'travel.room.triple': 'triple',
  'travel.room.quadruple': 'cuádruple',
  'travel.bus.carrier': 'Transportista de autobús',
  'travel.car': 'Coche',
  'travel.car.pickup': 'Recogida',
  'travel.car.dropoff': 'Entrega',

  'travel.timeline.loading': 'Cargando tu línea de tiempo',
  'travel.search.loading': 'Cargando',

  'travel.booking.passenger': 'Nombre del pasajero',
  'travel.booking.no-passenger': 'No se ha encontrado ningún pasajero',
  'travel.booking.costCenter': 'Centro de coste',
  'travel.booking.no-costCenter': 'No se ha encontrado ningún centro de coste',
  'travel.booking.project': 'Proyecto',
  'travel.booking.no-project': 'No se ha encontrado ningún proyecto',

  'travel.booking.creditCard': 'Tarjeta de crédito',
  'travel.booking.no-creditCard':
    'No se ha encontrado ninguna tarjeta de crédito',
  'travel.booking.creditCard.ends-with': 'Final',
  'travel.booking.creditCard.insufficient-balance':
    'Saldo insuficiente para emitir esta reserva.',
  'travel.booking.installments': 'Cuotas',
  'travel.booking.loading-installments': 'Cargando...',

  'travel.empty.title': 'No hay resultados para su búsqueda',
  'travel.empty.subtitle': 'Intenta rehacer tu búsqueda con nuevos parámetros.',

  'travel.nodata.title': 'No hay resultados',
  'travel.nodata.subtitle': 'Intente hacer nuevas reservas.',

  'travel.order.status.title':
    'Seguimiento por debajo del estado de su reserva.',
  'travel.order.status.reserving': 'Reserva',
  'travel.order.status.timeline.reserving': 'Reservando...',
  'travel.order.status.reserved': 'Reservado',
  'travel.order.status.timeline.approval': 'En espera de aprobación',
  'travel.order.status.timeline.toissue': 'En espera para emitir',
  'travel.order.status.timeline.issuing': 'Emitiendo...',
  'travel.order.status.timeline.manualissuing': 'Emitiendo...',
  'travel.order.status.timeline.cancelled': 'Cancelado',
  'travel.order.status.timeline.error': 'Se ha producido un error',
  'travel.order.status.timeline.expired': 'Caducado',
  'travel.order.status.approval': 'Aprobación',
  'travel.order.status.issue': 'Problema',
  'travel.order.status.title.approval':
    'Siga a continuación la aprobación de su reserva.',
  'travel.order.status.title.validUntil': 'Válido hasta',
  'travel.order.status.title.hasNoValidUntil':
    'Reserva no confirmada, los valores pueden cambiar.',
  'travel.order.status.title.issuing': 'Estamos emitindo a sua reserva...',
  'travel.order.status.title.issued':
    '¡Todo bien! ¡Su reserva ha sido emitida!',
  'travel.order.status.title.error':
    'Se produjo un error con esta reserva. Inténtalo de nuevo.',
  'travel.order.cancellation.date': 'Cancelación gratuita',
  'travel.order.cancellation.issue': 'Cancelación gratuita hasta su expedición',
  'travel.order.cancellation.cancel': 'Cancelar',
  'travel.order.cancellation.cancelled':
    'Esta reserva ha sido cancelada o el plazo de expedición ha expirado.',
  'travel.order.cancellation.dismiss': 'Dispensar',
  'travel.order.approval.approve': 'Aprobar',
  'travel.order.aproval.review': 'Analizar',
  'travel.order.approval.issue': 'Expedición',

  'travel.order.status.timeline.refund.start': 'Creando',
  'travel.order.status.timeline.refund.review': 'Revisar',
  'travel.order.status.timeline.refund.approved': 'Aprobado',
  'travel.order.status.timeline.refund.rejected': 'Rechazado',
  'travel.order.status.timeline.refund.cancelled': 'Cancelado',
  'travel.order.status.timeline.refund.undone': 'Deshecho',
  'travel.order.status.timeline.refund.dismissed': 'Despedido',
  'travel.order.status.timeline.refund.error': 'Error',
  'travel.order.status.timeline.refund.processing': 'Procesando',
  'travel.order.status.timeline.refund.waiting-payment': 'En espera de pago',
  'travel.order.status.timeline.refund.paid': 'Pagado',

  'travel.duration': 'Duración',
  'travel.approve-trip': 'Aprobar viaje',
  'travel.alternatives': 'Alternativas',
  'travel.best-option': '¡Esa es la mejor opción!',
  'travel.payment': 'Pago',
  'travel.issue-trip': 'Issue trip',
  'travel.details': 'Infomación sobre reservas',
  'travel.currentHotel.details': 'Información actual del hotel',
  'travel.details.advance': 'Antelación',
  'travel.details.car.dailyValue': 'Valor diario',
  'travel.details.hotel.dailyValue': 'Valor por noche',
  'travel.details.flightandbus.price-per-way': 'Promedio por viaje',

  'travel.with-breakfast': 'Con desayuno',
  'travel.without-breakfast': 'Sin desayuno',

  'orders.notifications.FARE_UPDATED':
    'Lamentablemente esta tarifa ha caducado y no se ha podido hacer la reserva. Realice una nueva búsqueda para continuar.',
  'orders.notifications.CREDIT_CARD_UNAUTHORIZED':
    'Ha habido un problema con su tarjeta y la reserva no puede ser confirmada',
  'orders.notifications.RESERVATION_FAILED':
    'Se produjo un error interno y no se pudo hacer la reserva.',

  'payment.single-installment': 'Vista. 1x de {quantity}.',
  'payment.installment-with-interest':
    '{installment} installments. 1x de {firstInstallmentAmount} y{remainingInstallments}x of {remainingInstallmentsAmount}(con interés)',
  'payment.installment-without-interest':
    '{installment} plots. 1x de {firstInstallmentAmount} y {remainingInstallments}x of {remainingInstallmentsAmount}',

  /* 04.1. Viajes: Coche */
  'car.door': 'Puerta',
  'car.doors': 'Puertas',
  'car.passenger': 'Pasajero',
  'car.passengers': 'Pasajeros',
  'car.baggage-size': 'Equipaje',
  'car.baggages-size': 'Equipaje',
  'car.air-conditioning': 'Aire acondicionado',
  'car.transmission': 'Transmisión',

  /* 04.1. Viaje: Vuelo */
  'flight.no-stop': 'Correcto',
  'flight.stop': 'Stop',
  'flight.stops': 'Stops',

  'flight.select-fare': 'Selecciona tu tarifa',
  'flight.select-fare-description':
    'Personaliza tu viaje y elige una de las siguientes opciones:',
  'flight.select-fare-card-out-return': 'Ida y vuelta',
  'flight.select-fare-card-out': 'Sólo ida',
  'flight.select-fare-card-total': 'Total',
  'flight.select-fare-card-btn-select': 'Seleccionar tarifa',
  'flight.select-fare-card-btn-continue': 'Continuar con tarifa',
  'flight.select-fare-card-btn-goToReservation': 'Próximo paso',
  /* 04.1. Travel: ESG */
  'esg.flight.estimate':
    'Você vai voar aproximadamente {miles} milhas e esta viagem irá emitir cerca de {carbon} kg de dióxido de carbono e outros gases de efeito estufa.',
  'esg.empty.title': 'No hay reservas elegibles',
  'esg.empty.subtitle': 'Intenta hacer otros nuevos.',

  /* Order Alternatives */
  'order.alternative.best-price': 'Mejor precio',
  'order.alternative.save': 'Ahorre {save}',
  'order.alternative.loading': 'Cargando alternativas',

  /* Admin */
  'admin.active.mapWorld': 'Visión general de los casos confirmados',
  'admin.active.mapBrazil': 'Brasil vista de los casos confirmados',
  'admin.active.travelersInit': 'Tienes ',
  'admin.active.travelersEnd': 'viajeros activos',

  'admin.users.invite': 'INVITE',
  'admin.users.bulkImport': 'IMPORTACIÓN MASIVA',
  'admin.users.bulkImportSuccess':
    'Archivo importado correctamente. Está en tratamiento y estará disponible pronto',
  'admin.users.invite-traveler': 'INVITE pasajero',
  'admin.users.no-pending': 'Todavía no has invitado a nadie',
  'admin.users.emails': 'Emails (introduce uno por línea)',
  'admin.users.submit': 'Invitar',

  'admin.dashboard.title': 'Tablero',
  'admin.dates.month': 'Mes actual',
  'admin.dates.year': 'Este año',
  'admin.dates.all': 'Todos',

  'admin.policy.title': 'Políticas',
  'admin.policy.costCenters': 'Centros de coste',
  'admin.policy.projects': 'Proyectos',
  'admin.policy.groups': 'Grupos',
  'admin.policy.categories': 'Categorías de reembolso',
  'admin.policy.approvalRule': 'Regla de aprobación',
  'admin.policy.approvalRules': 'Reglas de aprobación',
  'admin.policy.delete': 'Eliminar',

  'admin.policy.costCenter.Add': 'Añadir',
  'admin.policy.costCenter.Modal.Add': 'Crear centro de coste',
  'admin.policy.costCenter.Name': 'Nombre',
  'admin.policy.costCenter.ExternalId': 'Identificador externo',
  'admin.policy.costCenter.Create': 'Crear',

  'admin.policy.project.Add': 'Añadir',
  'admin.policy.project.Modal.Add': 'Crear proyecto',
  'admin.policy.project.Name': 'Nombre',
  'admin.policy.project.ExternalId': 'Identificador externo',
  'admin.policy.project.Create': 'Crear',

  'admin.policy.refund.Modal.Add': 'Crear categoría de reembolso',
  'admin.policy.refund.Icon': 'Ícono de categoría',

  'admin.policy.group.Add': 'Añadir',
  'admin.policy.group.Modal.Add': 'Administrar grupo',
  'admin.policy.group.Name': 'Nombre',
  'admin.policy.group.Username': 'Usuario',

  'admin.policy.policy': 'Política',

  'admin.policy.policy.Add': 'Añadir',
  'admin.policy.policy.Modal.Add': 'Crear directiva',
  'admin.policy.policy.Name': 'Nombre',
  'admin.policy.policy.Sort': 'Prioridad',
  'admin.policy.policy.Field': 'Información',
  'admin.policy.policy.Comparison': 'Comparativa',
  'admin.policy.policy.Value': 'Valor',
  'admin.policy.policy.Save': 'Guardar',
  'admin.policy.policy.Group.Add': 'Agregar grupo',
  'admin.policy.policy.Rule.Add': 'Agregar regla',
  'admin.policy.policy.Approval.Add': 'Agregar aprobador',

  'admin.finance.title': 'Financiero',
  'admin.finance.invoice': 'Documentos fiscales',
  'admin.finance.credit_cards': 'Tarjetas de crédito',

  'admin.policy.creditCard.Modal.Add': 'Crear tarjeta de crédito',
  'admin.finance.creditCards.Add': 'Añadir',
  'admin.policy.creditCard.cardName': 'Nombre interno',
  'admin.policy.creditCard.documentNumber': 'CPF del propietario',
  'admin.policy.creditCard.number': 'Número de tarjeta',
  'admin.policy.creditCard.name': 'Nombre impreso en la tarjeta',
  'admin.policy.creditCard.expiry': 'Fecha de caducidad',
  'admin.policy.creditCard.cvv': 'CVV',
  'admin.policy.placeholder.name': 'SU NOMBRE',
  'admin.policy.placeholder.valid': 'válido hasta',

  'admin.kyc.companyTabTitle': 'Empresa',
  'admin.kyc.companyTabDesc': 'Registrarse',
  'admin.kyc.setupTabTitle': 'Setup',
  'admin.kyc.setupTabDesc': 'Pago',
  'admin.kyc.documentNumber': 'Seguridad Social',
  'admin.kyc.legalName': 'Razón Social',
  'admin.kyc.tradingName': 'Nombre Comercial',
  'admin.kyc.foundationDate': 'Fecha de Fundación',
  'admin.kyc.postalCode': 'Código Postal',
  'admin.kyc.street': 'Camino',
  'admin.kyc.streetNumber': 'Número de Calle',
  'admin.kyc.complement': 'Complemento',
  'admin.kyc.neighborhood': 'Barrio',
  'admin.kyc.city': 'Ciudad',
  'admin.kyc.uf': 'UF',
  'admin.kyc.billingEmail': 'Correo Electrónico de Facturas',
  'admin.kyc.phone': 'Teléfono',
  'admin.kyc.partner.documentNumber': 'Seguridad Social del Socio',
  'admin.kyc.partner.legalName': 'Razón Social del Socio',
  'admin.kyc.partner.birthdate': 'Fecha de Nacimiento',
  'admin.kyc.partner.phone': 'Teléfono',
  'admin.kyc.partner.email': 'Correo Electrónico',
  'admin.kyc.payment.download': 'Descargar Instrucciones',

  'admin.reports.title': 'Informes',
  'admin.reports.summary': 'Resumen',
  'admin.reports.refunds': 'Reembolso',
  'admin.reports.esg': 'ESG',

  'admin.reports.esg.totalEmittedCo2': 'Emisiones totales de CO<sub>2</sub>',
  'admin.reports.esg.totalSpent':
    'Total gastado con compensación de CO<sub>2</sub>',
  'admin.reports.esg.totalSpentWithCo2':
    '% del total gastado con compensación de CO2 basado en el total gastado',
  'admin.reports.esg.topSections':
    'Sección de aire con la mayoría de las emisiones de CO<sub>2</sub>',
  'admin.reports.esg.topCostCenter':
    'Centros de costos con la mayoría de las emisiones de CO<sub>2</sub> (t)',
  'admin.reports.esg.topProjects':
    'Proyectos con más emisiones de CO<sub>2</sub> (t)',
  'admin.reports.esg.reservations':
    'Reservas y sus emisiones de CO<sub>2</sub> ',

  // Refund
  'refund.nav.expenses': 'Peticiones',
  'refund.nav.approvals': 'Aprobaciones',

  'refund.modal.name': 'Nombre de reembolso',
  'refund.modal.location': 'Localización',
  'refund.modal.date': 'Fecha',
  'refund.modal.category': 'Categoría',
  'refund.modal.all-data': 'Información de reembolso',
  'refund.modal.items': 'Reembolsar artículos',
  'refund.modal.add-items': 'Agregar Elementos',
  'refund.modal.no-items-added': 'No se agregaron elementos',

  'refund.modal.expense.all-data': 'Solicitar información',
  'refund.modal.expense.items': 'Artículos de solicitud',
  'refund.modal.expense.name': 'Solicitud nombre',

  'refund.modal.create-refund': 'Crear reembolso',
  'refund.modal.store-modifications': 'Guardar cambios',
  'refund.modal.undo-expense': 'Deshacer solicitud',

  'refund.modal.alright-refund': 'Todo está bien con tu reembolso!',
  'refund.modal.incomplete-refund': 'Reembolso incompleto!',

  'refund.empty.title': 'No se encontraron reembolsos!',
  'refund.empty.description':
    'Aún no agregaste un reembolso. Comience a usar esta funcionalidad creando un nuevo reembolso haciendo clic en el botón de abajo:',

  'expenses.empty.title': 'No se encontró ninguna solicitud de reembolso!',
  'expenses.empty.description':
    'Aún no creaste una solicitud de reembolso. Haciendo su primera solicitud haciendo clic en el botón de abajo:',

  'approvals.empty.title': 'No se encontró ninguna solicitud de reembolso!',
  'approvals.empty.description':
    'Sin solicitud de reembolso esperando aprobación. Volver más tarde!',

  'refund.modal.items.name': 'Nobre',
  'refund.modal.items.unity-price': 'Unit Price',
  'refund.modal.items.quantity': 'Precio unitario',
  'refund.modal.items.total': 'Total',

  'refund.num-items': 'Núm. Artículos',

  'expenses.num-items': 'Número de artículos reembolsados',
  'expenses.status': 'Estado de la solicitud',

  'expenses.placeholder.num-items.singular': '{number} artículo de solicitud',
  'expenses.placeholder.num-items.plural': '{number} artículos de solicitud',

  'refund.placeholder.refund-item-name': 'Nuevo artículo de reembolso',
  'refund.placeholder.no-categories': 'Ninguna categoría agregada',

  'approval-refund-item.placeholder.commentary':
    'Escribe un comentario sobre este reembolso para el pasajero',

  'refund.modal.btn.new-expense': 'Crear solicitud',
  'refund.modal.btn.cancel-expense': 'cancelar solicitud',
  'refund.modal.btn.review': 'Enviar para aprobación',

  'refund.modal.not-associated':
    'Este reembolso no se asigna a ninguna solicitud.!',
  'refund.modal.associated':
    'Este reembolso se asigna a la siguiente solicitud:',
  'refund.modal.item-reject': 'Este elemento fue rechazado por el aprobador',

  'refund.expense.approving': 'Esta solicitud está esperando aprobación!',
  'refund.expense.review': 'Esta solicitud debe revisarse!',
  'refund.expense.review-done':
    '¡Todo está bien con esta solicitud! Envíelo para su aprobación.',
  'refund.expense.approved': '¡Está bien! Esta solicitud fue aceptada!',
  'refund.expense.rejected': 'Aff! Esta solicitud fue rechazada!',
  'refund.expense.cancelled': 'Esta solicitud fue cancelada!',
  'refund.expense.dismissed': 'Esta solicitud fue rechazada!',
  'refund.expense.error': 'Se produjo un error con esta solicitud!',
  'refund.expense.complete-fields':
    'Complete los campos obligatorios para que sus gastos se envíen para su aprobación!',
  'refund.expense.waiting-payment': '¡Este solicitud está pendiente de pago!',
  'refund.expense.processing': '¡Esta solicitud está en proceso!',
  'refund.expense.paid': '¡Esta solicitud es paga!',

  'expense.detailed-item.single-item': '{number} elemento',
  'expense.detailed-item.multiple-items': '{number} elementos',

  'refund.expense.no-refund': 'No tienes ninguna solicitud sin asignar!',
  'refund.expense.available-refund': 'Tienes 1 solicitud sin asignar!',
  'refund.expense.available-refunds':
    'Tienes {refunds} solicitudes sin asignar!',

  'refund.expense.good-score':
    '¡Bien hecho! Se ha aprobado el {percentage}% de sus solicitudes.',
  'refund.expense.bad-score':
    '¡Puaj! Solo se ha aprobado el {percentage}% de sus solicitudes.',
  'refund.expense.worse-score':
    '¡Puaj! Ninguna de sus solicitudes ha sido aprobada.',
  'refund.expense.no-score':
    '¡Aún no tienes ninguna solicitud aprobada o rechazada!',

  'refund.expense.review-requested':
    'Se solicitó una revisión para esta solicitud de reembolso!',
  'refund.expense.without-expense':
    'Este reembolso no se asigna a ninguna solicitud.!',

  'refund.modal.no-receipt': 'Sin recibo agregado',
  'refund.modal.no-receipt.description':
    'No agregaste ningún recibo para este reembolso',

  'refund.modal.no-categories':
    'Las categorías de reembolso deben insertarse antes de que se pueda crear un reembolso.<br/>Pídale a un administrador que inserte uno en la pestaña <b>Políticas</b> en el panel de administrador!',
  'refund.modal.no-categories.admin':
    'Las categorías de reembolso deben insertarse antes de que se pueda crear un reembolso. ¿Quieres insertar uno ahora?',

  'expenses.modal.no-refunds': 'Aún no ha creado ningún reembolso!',

  /* 05.Páginas */
  'pages.add-new': 'Añadir nuevo',
  'pages.add-new-modal-title': 'Añadir nuevo elemento',
  'pages.display-options': 'Opciones de visualización',
  'pages.orderby': 'Ordenar por:',
  'pages.product-name': 'Nombre del   producto',
  'pages.category': 'Categoría',
  'pages.description': 'Descripción',
  'pages.status': 'Status',
  'pages.cancel': 'Cancelar',
  'pages.submit': 'Enviar',
  'pages.delete': 'Eliminar',
  'pages.another-action': 'Otra  acción',
  'pages.actions': 'ACCIONES',
  'pages.header': 'Header',
  'pages.details': 'DETALLES',
  'pages.orders': 'PEDIDOS',
  'pages.rating': 'Rating',
  'pages.price': 'Precio',
  'pages.ingredients': 'Ingredientes',
  'pages.is-vegan': 'Es vegano',
  'pages.order-status': 'Estado del pedido',
  'pages.bake-progress': 'Bake   Progress',
  'pages.popularity': 'Popularidad',
  'pages.comments': 'Comentarios',
  'pages.error-title': 'Ooops... parece que se ha producido un error !',
  'pages.error-code': 'Código de error',
  'pages.go-back-home': 'GO BACK HOME',
  'pages.mailing-info':
    'Las plantillas de correo utilizan estilos en línea  y diseño de tabla  para mostrarse  bien en varios proveedores de servicios. Para proporcionar una mejor usabilidad lo incluimos como html regular con dangerouslySetInnerHTML.',
  'pages.invoice-info':
    'La plantilla de factura tiene una versión de estilo en línea para su uso fuera del proyecto, así como  la versión de React .',
  'pages.react-version': 'React Version',
  'pages.inline-version': 'Inline Styled Html Version',

  /* 06.Aplicaciones */

  /* 06. 01.Chat */
  'chat.messages': 'Mensajes',
  'chat.contacts': 'Contactos',
  'chat.saysomething': 'Di algo..',

  /* 06. 02.Encuesta */
  'survey.delete': 'Eliminar',
  'survey.edit': 'Editar',
  'survey.add-new': 'ADD NEW',
  'survey.add-new-title': 'Añadir   nueva  encuesta',
  'survey.title': 'Título',
  'survey.category': 'Categoría',
  'survey.label': 'Label',
  'survey.status': 'Status',
  'survey.cancel': 'Cancelar',
  'survey.submit': 'Enviar',
  'survey.another-action': 'Otra  acción',
  'survey.display-options': 'Opciones de visualización',
  'survey.orderby': 'Ordenar por:',
  'survey.all-surveys': 'Todas las encuestas',
  'survey.completed-surveys': 'Encuestas   completadas',
  'survey.categories': 'Categorías',
  'survey.active-surveys': 'Encuestas activas',
  'survey.labels': 'Etiquetas',

  /* 06. 03.Todo */
  'todo.add-new': 'ADD NEW',
  'todo.add-new-title': 'Añadir   nuevo todo',
  'todo.title': 'Título',
  'todo.detail': 'Detalle',
  'todo.category': 'Categoría',
  'todo.label': 'Label',
  'todo.status': 'Status',
  'todo.cancel': 'Cancelar',
  'todo.submit': 'Enviar',
  'todo.action': 'Acción',
  'todo.another-action': 'Otra  acción',
  'todo.display-options': 'Opciones de visualización',
  'todo.orderby': 'Order By : ',
  'todo.all-tasks': 'Todas las  tareas',
  'todo.pending-tasks': 'Tareas pendientes',
  'todo.completed-tasks': ' Tareascompletadas ',
  'todo.categories': 'Categorías',
  'todo.labels': 'Etiquetas',

  /* 07.UI */

  /* 07. 01.Alertas */
  'alert.rounded': ' Alertaredondeada ',
  'alert.react-notifications': 'React Notifications',
  'alert.outline': 'Esquema',
  'alert.primary': 'Primaria',
  'alert.secondary': 'Secundario',
  'alert.info': 'Info',
  'alert.success': 'Éxito',
  'alert.warning': 'Advertencia',
  'alert.error': 'Error',
  'alert.filled': 'Filled',
  'alert.primary-text': 'Esta es una alerta primaria —¡compruébalo!',
  'alert.secondary-text': 'Esta es una alerta secundaria —¡compruébalo!',
  'alert.success-text': 'Esta es una alertade éxito —¡compruébalo!',
  'alert.danger-text': 'Esta es una alerta de peligro —¡compruébalo!',
  'alert.warning-text': 'Esta es una alertade advertencia —¡compruébalo!',
  'alert.info-text': 'Esta es una alerta de información ,¡échale un vistazo!',
  'alert.light-text': 'Esta es una alertade luz —¡compruébalo!',
  'alert.dark-text': 'Esta es una alerta oscura —¡compruébalo!',
  'alert.default': 'Alerta predeterminada',
  'alert.dismissing': 'Dismissing Alert',
  'alert.dismissing-text':
    'Santo guacamole! Usted debe comprobar en algunos de esos campos a continuación.',
  'alert.dismissing-without-animate-text':
    '¡Soy un alerta y  puedo ser despedido sin animar!',

  /* 07. 02.Insignias */
  'badge.sizes': 'Tamaños',
  'badge.colors': 'Colores',
  'badge.outline': 'Esquema',
  'badge.links': 'Enlaces',
  'badge.counter-badges': 'Counter   Badges',
  'badge.bootstrap-default': 'DefaultBootstrap',
  'badge.primary': 'Primaria',
  'badge.secondary': 'Secundaria',
  'badge.success': 'Éxito',
  'badge.danger': 'Peligro',
  'badge.warning': 'Advertencia',
  'badge.info': 'Info',
  'badge.light': 'Luz',
  'badge.dark': 'Oscuro',

  /* 07. 03.Botones */
  'button.default': 'Bootstrap   Default',
  'button.colors': 'Colores',
  'button.rounded': 'Redondeado',
  'button.outline': 'Contorno',
  'button.states': 'Estados',
  'button.sizes': 'Tamaños',
  'button.button-groups': 'Grupos de botones',
  'button.large-button': ' Botóngrande ',
  'button.small-button': ' Botónpequeño ',
  'button.extra-small-button': 'Botón extra pequeño',
  'button.block-button': ' Botónde bloqueo ',
  'button.active': 'Activo',
  'button.disabled': 'Deshabilitado',
  'button.basic': 'Básico',
  'button.toolbar': 'Barra de herramientas',
  'button.nesting': 'Anidamiento',
  'button.vertical-variation': 'Variación vertical',
  'button.checkbox-radio-button': 'Casilla de verificación y botón de  opción',
  'button.checkbox': 'Checkbox',
  'button.radio': 'Radio',
  'button.radio-button': 'Botón de radio',
  'button.primary': 'Primaria',
  'button.secondary': 'Secundario',
  'button.success': 'Éxito',
  'button.danger': 'Peligro',
  'button.warning': 'Advertencia',
  'button.info': 'Info',
  'button.light': 'Luz',
  'button.dark': 'Oscuro',
  'button.states-text':
    'Este botón muestra un spinner durante 2 segundos y un icono de error durante 3 segundos antes de cambiar al estado normal. Estos estados se pueden desencadenar agregando  y quitando clases.',
  'button.click-here': 'Haga clic   aquí',
  'button.states-text-alternate':
    'Este botón muestra un spinner durante 2 segundos y un icono de error durante 3 segundos antes de cambiar al estado normal. Estos estados se pueden desencadenar agregando  y quitando clases.',
  'button.primary-link': 'Enlace   primario',
  'button.link': 'Enlace',
  'button.primary-button': ' Botónprincipal ',
  'button.button': 'Botón',
  'button.left': 'Izquierda',
  'button.middle': 'Medio',
  'button.right': 'Correcto',
  'button.dropdown': 'Dropdown',
  'button.dropdown-link': 'Enlace desplegable',

  'button.approval.ask-review': 'Solicitar revisión',
  'button.approval.approve': 'Aprobar',
  'button.approval.reprove': 'Rechazar',

  /* 07. 04.Tarjetas */
  'cards.icon-card': 'Tarjeta de icono',
  'cards.image-card': 'Tarjeta de imagen',
  'cards.image-overlay-card': 'Tarjeta de   superposición de imágenes',
  'cards.image-card-list': 'Image   Card List',
  'cards.tab-card': 'Tab Card',
  'cards.user-card': 'Tarjeta de usuario',

  /* 07. 05.Carrusel */
  'carousel.basic': 'Carrusel   básico',
  'carousel.single': 'Carrusel   Single',
  'carousel.without-controls': 'Carrusel sin controles',

  /* 07. 06.Gráficos */
  'charts.line': 'Gráfico de líneas',
  'charts.polar': 'Polar Chart',
  'charts.area': 'Gráfico de área',
  'charts.scatter': 'Gráfico de dispersión',
  'charts.bar': 'Gráfico de barras',
  'charts.radar': 'Gráfico de radar',
  'charts.pie': 'Gráfico circular',
  'charts.doughnut': 'Doughnut Chart',
  'charts.shadow': 'Sombra',
  'charts.no-shadow': 'Sin sombra',

  /* 07. 07.Contraer */
  'collapse.basic': 'Básico',
  'collapse.toggle': 'Alternar',
  'collapse.accordion': 'Acordeón',
  'collapse.controlled': 'Controlado',
  'collapse.uncontrolled': 'Incontrolado',

  /* 07. 08.Desplegables */
  'dropdowns.basic': 'Básico',
  'dropdowns.controlled': 'Controlado',
  'dropdowns.uncontrolled': 'Incontrolado',
  'dropdowns.dropdown': 'Desplegable',
  'dropdowns.header': 'Encabezado',
  'dropdowns.action': 'Acción',
  'dropdowns.another-action': 'Otra  acción',
  'dropdowns.right': 'Correcto',
  'dropdowns.left': 'Izquierda',
  'dropdowns.drop-directions': 'Drop   Directions',
  'dropdowns.dropright': 'Dropright',
  'dropdowns.dropleft': 'Dropleft',
  'dropdowns.small-button': ' Botónpequeño ',
  'dropdowns.large-button': ' Botóngrande ',
  'dropdowns.sizing': 'Dimensionamiento',
  'dropdowns.split-button': 'Split Button Dropdowns',
  'dropdowns.dropup': 'Dropup',

  /* 07. 09.Editores */
  'editors.draftjs': 'Borrador.js',
  'editors.quill-standart': 'Quill Standard',
  'editors.quill-bubble': 'Quill Bubble',

  /* 07. 10.Formularios */
  'forms.action.skip': 'Skip',
  'forms.action.have_code': 'Ya tengo un código',
  'forms.action.register': 'REGISTRARSE',
  'forms.action.login': 'ACCESO',
  'forms.action.reset': 'RESET',
  'forms.action.change': 'CAMBIAR',
  'forms.action.save': 'AHORRAR',
  'forms.action.delete': 'REMOVER',
  'forms.action.invite': 'INVITACIÓN',
  'forms.action.next': 'SIGUIENTE',
  'forms.action.create': 'REGISTRARSE',

  /* 07. 10.Validación de formularios  */
  'forms.validation.email.valid': 'El correo electrónico no es válido',
  'forms.validation.email.required': 'Correo electrónico requerido',
  'forms.validation.email.already-exists': 'El Email ya existe',
  'forms.validation.password.required': 'Contraseña requerida',
  'forms.validation.password.weak':
    'La contraseña debe contener al menos 6 caracteres',
  'forms.validation.password.confirmation.required':
    'La confirmación no es igual a la contraseña',
  'forms.validation.code.required': 'Código requerido',
  'forms.validation.firstName.required': 'Nombre requerido',
  'forms.validation.lastName.required': 'Apellido requerido',
  'forms.validation.gender.valid': 'Género no válido',
  'forms.validation.gender.required': 'Género obligatorio',
  'forms.validation.birthdate.valid': 'Fecha de nacimiento no válida',
  'forms.validation.birthdate.required': 'Fecha de nacimiento obligatoria',
  'forms.validation.rg.number.required': 'Identidad requerida',
  'forms.validation.rg.emitter.required': 'Organismo emisor obligatorio',
  'forms.validation.cpf.required': 'CPF required',
  'forms.validation.cpf.number.valid': 'CPF no válido',
  'forms.validation.cnpj.required': 'CNPJ required',
  'forms.validation.cnpj.number.valid': 'CNPJ NO VÁLIDO',
  'forms.validation.cnh.validUntil.valid': 'Fecha no válida',
  'forms.validation.passport.validUntil.valid': 'Fecha no válida',
  'forms.validation.name.required': 'Nombre requerido',
  'forms.validation.cardName.required': 'Nombre requerido',
  'forms.validation.documentNumber.required': 'Documento requerido',
  'forms.validation.cardNumber.required': 'Número de tarjeta requerido',
  'forms.validation.cardExpiry.valid': 'Fecha de caducidad no válida',
  'forms.validation.cardExpiry.required': 'Fecha de caducidad requerida',
  'forms.validation.cvv.valid': 'CVV no válido',
  'forms.validation.cvv.required': 'CVV required',
  'forms.validation.document.expired': 'Documento caducado',
  'forms.validation.phone.required': 'Teléfono requerido',
  'forms.validation.phone.valid': 'Teléfono no válido',
  'forms.validation.legalName.required': 'Razón social requerido',
  'forms.validation.tradingName.required': 'Nombre comercial requerido',
  'forms.validation.foundationDate.required': 'Fecha de fundación requerida',
  'forms.validation.postalCode.valid': 'Código postal no válido',
  'forms.validation.postalCode.required': 'Código postal requerido',
  'forms.validation.street.required': 'Calle requerida',
  'forms.validation.streetNumber.required': 'Número de calle requerido',
  'forms.validation.neighborhood.required': 'Barrio requerido',
  'forms.validation.city.required': 'Ciudad requerida',
  'forms.validation.uf.required': 'Estado requerido',
  'forms.validation.uf.invalid': 'Estado inválido',

  'forms.validation.required': 'Campo obligatorio',

  'forms.basic': 'Básico',
  'forms.email': 'E-mail',
  'forms.email-muted':
    'Nunca compartiremos su correo electrónico con nadie más',
  'forms.password': 'Contraseña',
  'forms.submit': 'Submit',
  'forms.horizontal': 'Horizontal',
  'forms.radios': 'Radios',
  'forms.first-radio': 'Primera radio',
  'forms.second-radio': 'Segunda   radio',
  'forms.third-radio-disabled': 'Tercera radio discapacitada',
  'forms.checkbox': 'Caja',
  'forms.signin': 'Iniciar sesión',
  'forms.top-labels-over-line': 'Etiquetas superiores sobre la línea',
  'forms.tags': 'Tags',
  'forms.date': 'Fecha',
  'forms.state': 'State',
  'forms.top-labels-in-input': 'Top Labels In Input',
  'forms.email-u': 'CORREO ELECTRÓNICO',
  'forms.password-u': 'CONTRASEÑA',
  'forms.tags-u': 'TAGS',
  'forms.date-u': 'FECHA',
  'forms.state-u': 'Población',
  'forms.grid': 'Cuadrícula de formulario',
  'forms.address': 'Dirección',
  'forms.address2': 'Dirección 2',
  'forms.city': 'Ciudad',
  'forms.city-message': '¡Por favor, seleccione una  ciudad!',
  'forms.state-message': '¡Seleccione un población!',
  'forms.zip': 'Zip',
  'forms.signup': 'Inscribirse',
  'forms.inline': 'En línea',
  'forms.validation-availity': 'Validación de Availity Reactstrap',
  'forms.validation-formik': 'Validación Formik',
  'forms.default': 'defecto',
  'forms.firstname': 'Nombre',
  'forms.firstname-message': '¡Introduce tu nombre!',
  'forms.lastname': 'Apellido',
  'forms.lastname-message': '¡Introduce tu apellido!',

  /* 07. 11.Componentes del formulario  */
  'form-components.custom-inputs': 'Entradas personalizadas',
  'form-components.checkboxes': 'Caja',
  'form-components.radios': 'Radios',
  'form-components.inline': 'Inline',
  'form-components.react-select': 'Reaccionar Seleccionar',
  'form-components.state-single': 'Estado soltero',
  'form-components.state-multiple': 'Estado múltiple',
  'form-components.date-picker': 'Selector de fecha',
  'form-components.date': 'Fecha',
  'form-components.date-range': 'Intervalo de fechas',
  'form-components.date-with-time': 'Fecha con hora',
  'form-components.embedded': 'Incorporado',
  'form-components.dropzone': 'Zona de descenso',
  'form-components.drop-files-here': 'Ponga ficheros aquí',
  'form-components.tags': 'Tags',
  'form-components.switch': 'Cambiar',
  'form-components.primary': 'Primario',
  'form-components.secondary': 'Secundario',
  'form-components.primary-inverse': 'Primario Inverse',
  'form-components.secondary-inverse': 'Secundario Inverse',
  'form-components.slider': 'Slider',
  'form-components.double-slider': 'Slider duplo',
  'form-components.single-slider': 'Slider único',
  'form-components.rating': 'Clasificación',
  'form-components.interactive': 'Interactivo',
  'form-components.readonly': 'Readonly',
  'form-components.type-a-cake': 'Escriba un pastel',
  'form-components.start': 'Inicio',
  'form-components.end': 'Fin',
  'form-components.tables': 'Tablas',
  /* 07. 12.Iconos */
  'icons.simplelineicons': 'Iconos de línea simple',
  'icons.iconsmind': 'Iconsmind Iconos',

  /* 07. 13.Grupos de entrada  */
  'input-groups.basic': 'Básico',
  'input-groups.sizing': 'Dimensionamiento',
  'input-groups.small': 'Pequeño',
  'input-groups.default': 'Predeterminado',
  'input-groups.large': 'Grande',
  'input-groups.checkboxes-and-radios': 'Casillas de verificación y radios',
  'input-groups.multiple-inputs': 'Entradas múltiples',
  'input-groups.first-and-last-name': 'Nombre y apellidos',
  'input-groups.multiple-addons': 'Complementos múltiples',
  'input-groups.button-addons': 'Botones Complementos',
  'input-groups.button': 'Boton',
  'input-groups.buttons-with-dropdowns': 'Botonescon desplegables',
  'input-groups.dropdown': 'desplegables',
  'input-groups.header': 'Encabezado',
  'input-groups.action': 'Acción',
  'input-groups.another-action': 'Otra Acción',
  'input-groups.custom-select': 'Selección personalizada',
  'input-groups.options': 'Opciones',
  'input-groups.choose': 'Elegir...',
  'input-groups.custom-file-input': 'Entrada de archivo personalizado',

  /* 07. 14.Jumbotron */
  'jumbotron.hello-world': 'Hola, mundo!',
  'jumbotron.lead':
    'Se trata de una simple unidad de héroe, un componente simple de estilo jumbotron para llamar la atención adicional sobre el contenido o la informacióndestacados',
  'jumbotron.lead-detail':
    'Utiliza clases de utilidad para la tipografía y el espaciado para espaciar el contenido  dentro del contenedor más grande.',
  'jumbotron.learn-more': 'Más información',

  /* 07. 15.Modal */
  'modal.basic': 'Básico',
  'modal.modal-title': 'Título modal',
  'modal.launch-demo-modal': 'Lanzamiento Demo Modal',
  'modal.scrolling-long-content': 'Desplazamientode contenido largo ',
  'modal.backdrop': 'Telón de fondo',
  'modal.backdrop-value': 'Valor de fondo',
  'modal.right-modal': 'Modal correcto',
  'modal.launch-right-modal': 'Lanzamiento Modal Derecho',
  'modal.nested-modal': 'Modal anidado',
  'modal.size': 'Tamaño',
  'modal.launch-large-modal': 'Lanzamiento grande Modal',
  'modal.launch-small-modal': 'Lanzamiento pequeño Modal',

  'dialog.approval.approve.title': 'Aprobar Solicitud',
  'dialog.approval.reject.title': 'Reprobar Solicitud',
  'dialog.approval.review.title': 'Solicitud de Revisión',

  'dialog.approval.approve.msg': '¿Quieres aprobar este reembolso?',
  'dialog.approval.reject.msg': '¿Quieres rechazar este reembolso?',
  'dialog.approval.review.msg':
    '¿Quieres solicitar una revisión para este reembolso?',
  'dialog.approval.review.msg.changed':
    'Rechazó {rechazó} artículo(s) y escribió {comments} comentario(s).',
  'dialog.approval.approve.msg.changed':
    'Rechazó {rechazado} artículo(s) y el total de la nueva solicitud es {total}.',

  /* 07. 16.Navegación */
  'nav.basic': 'Nav Basico',
  'nav.active': 'Activo',
  'nav.disabled': 'Deshabilitado',
  'nav.disabled-link': 'Enlace deshabilitado',
  'nav.link': 'Enlace',
  'nav.longer-link': 'Enlace de navegación más largo',
  'nav.another-link': 'Otro enlace',
  'nav.right': 'Derecha',
  'nav.dropdown': 'Desplegable',
  'nav.header': 'Encabezado',
  'nav.action': 'Acción',
  'nav.another-action': 'Otra acción',
  'nav.horizontal-alignment': 'Nav Alineación horizontal',
  'nav.vertical-alignment': 'Nav Alineación vertical',
  'nav.pills': 'Nav Píldoras',
  'nav.fill-justify': 'Nav Píldoras y Justificadas',
  'nav.pills-dropdowns': 'Nav Píldoras con Dropdowns',
  'nav.pagination-basic': 'Paginación básica',
  'nav.pagination-sizing': 'Tamaño depaginación ',
  'nav.large': 'Grande',
  'nav.small': 'Pequeño',
  'nav.center': 'Centro',
  'nav.pagination-alignment': ' Alineaciónde paginación ',
  'nav.breadcrumb-basic': 'Migajas Basic',

  /* 07. 17.Popover &Tooltip */
  'popover-tooltip.popover': 'Popover',
  'popover-tooltip.tooltip': 'Información sobreherramientas',

  /* 07. 18.Clasificable */
  'sortable.columns': 'Ordenar columnas',
  'sortable.basic': 'Básico',
  'sortable.handles': 'Manejas',

  /* 07. 19.Mapas */
  'maps.google': 'Google',
  'maps.yandex': 'Yandex',

  /* 07. 20.Tablas */
  'table.bootstrap-tables': 'Tablas de Bootstrap',
  'table.bootstrap-basic': 'Tabla básica',
  'table.bootstrap-striped': ' Filassecadas ',
  'table.bootstrap-bordered': 'Tabla conborde ',
  'table.bootstrap-borderless': 'Mesa sin bordes',
  'table.bootstrap-hoverable': 'Filas flotantes',
  'table.bootstrap-responsive': 'Tabla responsiva',
  'table.react-pagination': 'Paginación',
  'table.react-scrollable': 'Desplazable',
  'table.react-advanced': 'Filtro, Longitud y Salto',

  'error.approval-info.credit-card.register':
    'Es necesario registrar una tarjeta antes de continuar',
  'error.approval-info.credit-card.select':
    'Seleccione una tarjeta y el número de paquetes',

  // 08. Componentes
  'components.timeline.modal.reservation-data': 'Datos de reserva',
  'components.timeline.modal.conditions': 'Descripción y condiciones',
  'components.flight.airportwait.wait':
    'larga espera de {horas} en {ciudad} ({estación})',

  'components.flight.baggages.bags': 'Mochila o bolsa',
  'components.flight.baggages.fit.below':
    'Debe caber debajo del asiento delantero.',
  'components.flight.baggages.handluggage': 'Equipaje de mano',
  'components.flight.baggages.fit.above':
    'Deberá caber en el compartimento superior de la aeronave.',
  'components.flight.baggages.dispatch': 'Equipaje facturado',
  'components.flight.baggages.included':
    'Incluido {baggageQuantity} desde hasta {baggageWeight}. El envío se realiza durante el check-in en el aeropuerto.',

  'components.bus.baggages.handluggage': 'Equipaje de mano',
  'components.bus.baggages.fit.above':
    'Debe caber sobre el asiento del pasajero.',
  'components.bus.baggages.dispatch': 'Equipajes para despachar',
  'components.bus.baggages.included':
    '{baggageQuantity} equipaje incluido de {baggageWeight} como máximo, que debe registrarse en el maletero y no puede exceder los 100cm x 50cm x 60cm..',
  'components.navs.user-dropdown.profile': 'Perfil',
  'components.navs.user-dropdown.logout': 'Logout',

  /*09. recipientes*/

  'containers.active.crisisreport.no-traveler': 'Ningún viajero impactado',
  'containers.active.crisisreport.emergency': 'Situaciones de emergencia',

  'containers.active.details.gate': 'Gate',

  'containers.dashboard.adoption-card.title': 'Adopción',
  'containers.dashboard.adoption-card.registered-travelers':
    'Viajeros registrados',
  'containers.dashboard.adoption-card.amount-travelers':
    'Cantidad total deviajeros',
  'containers.dashboard.adoption-card.active-travelers': 'Viajeros activos',
  'containers.dashboard.adoption-card.with-travel': 'Viajeros con viaje',

  'containers.dashboard.card.summary': 'Resumen',

  'containers.dashboard.flight-card.title': 'Vuelos',
  'containers.dashboard.flight-card.best-stretch': 'Top Stretches',
  'containers.dashboard.flight-card.best-companies': 'Top Companies',

  'containers.dashboard.card-summary.total-reservations': 'Total de reservas',
  'containers.dashboard.card-summary.reservations-per-period':
    'Número de reservas en el período',

  'containers.dashboard.flight-card-summary.total-spent': 'Gasto total',
  'containers.dashboard.flight-card-summary.flight-total': 'Total in aereo',
  'containers.dashboard.flight-card-summary.average-savings': 'Economía media',
  'containers.dashboard.flight-card-summary.help-saving':
    'Cuánto ayudamos a ahorrar',
  'containers.dashboard.flight-card-summary.average-fare': 'Tarifa media',
  'containers.dashboard.flight-card-summary.fare-average-value':
    'Tarifa media por reserva',

  'containers.dashboard.flight-card-top-airlines.stretches': 'stretches',

  'containers.dashboard.hotel-card.title': 'Hoteles',
  'containers.dashboard.hotel-card.best-hotels': 'Top Hotels',
  'containers.dashboard.hotel-card.best-companies': 'Top Brands',
  'containers.dashboard.hotel-card.best-cities': 'Top Cities',

  'containers.dashboard.hotel-card-summary.total-spent': 'Gasto total',
  'containers.dashboard.hotel-card-summary.total-daily': 'Total en diario',
  'containers.dashboard.hotel-card-summary.average-savings': 'Economía media',
  'containers.dashboard.hotel-card-summary.help-saving':
    'Cuánto ayudamos a ahorrar',
  'containers.dashboard.hotel-card-summary.average-daily': 'Promedio diario',
  'containers.dashboard.hotel-card-summary.average-daily-value':
    'Valor medio por noche',

  'containers.dashboard.small-line-bookings.reservations': 'Reservas',
  'containers.dashboard.small-line-economy.economy': 'Economía',
  'containers.dashboard.small-line-spent.total-spent': 'Total Gastado',

  'containers.dashboard.support-card.title': 'Apoyo',
  'containers.dashboard.support-card.average-waiting-time':
    'Tiempo medio de espera',
  'containers.dashboard.support-card.average-waiting-time-abbreviation': 'TME',
  'containers.dashboard.support-card.number-conversations':
    'Número de conversaciones',
  'containers.dashboard.support-card.total-chats': 'Total de chats realizados',
  'containers.dashboard.support-card.feeling': 'Sentimiento',
  'containers.dashboard.support-card.suport-rating':
    'Calificación de asistencia',

  'containers.navs.top-nav.my-travels': 'Mis viajes',
  'containers.navs.top-nav.refunds': 'Reembolsos',
  'containers.navs.top-nav.admin': 'Admin',

  'containers.policy.group-data-list-view.users': 'usuario(s)',

  'containers.search.modal.fees': 'Impuestos y tasas ya incluidos',
  'containers.search.modal.taxes': 'Impuestos ya incluidos.',
  'containers.search.modal.book-now': 'Reserva ahora',
  'conatiner.issue.with.credit': 'Emitir billete con credito',

  'containers.search.car-results.title': 'Categoría del vehículo',
  'containers.search.car-results.features': 'Características',

  'containers.search.flight-filters.prices': 'Precios',
  'containers.search.flight-filters.duration': 'Duración (h)',
  'containers.search.flight-filters.no-stop': 'Derecha',
  'containers.search.flight-filters.stop': 'Stop',
  'containers.search.flight-filters.stops': 'Stops',
  'containers.search.flight-filters.flight-companies': 'Airlines',
  'containers.search.flight-filters.departure': 'Hora de salida',
  'containers.search.flight-filters.outbound-connections':
    'Conexión de Vuelo de Salida',
  'containers.search.flight-filters.inbound-connections':
    'Conexión de Vuelo Entrante',

  'containers.search.flight-modal.taxes': '+ {taxes} de cargos por servicio',

  'containers.search.flight-modal.reservation':
    'Libro para {numAdults} {adultos}',
  'containers.search.flight-modal.valid-until': 'Reserva válida hasta {fecha}',
  'containers.search.flight-modal.baggages-not-included':
    'No incluye el equipaje facturado.',

  'containers.search.hotel-filters.price': 'Precios',
  'containers.search.hotel-filters.hotel-name': 'Nombre del hotel',
  'containers.search.hotel-filters.category': 'Categoría',
  'containers.search.hotel-filters.no-ratings': 'Sin calificación',
  'containers.search.hotel-filters.amenities': 'Amenities',

  'containers.search.hotel-modal.breakfast-included': 'Desayuno incluido',
  'containers.search.hotel-modal.select-room':
    'Seleccione una habitación para reservar',
  'containers.search.hotel-modal.room-category': 'Categoría de habitación',
  'containers.search.hotel-modal.main-amenities': 'Servicios principales',
  'containers.search.hotel-modal.meal': 'Comidas',
  'containers.search.hotel-modal.price-total': 'Precio (total)',
  'containers.search.hotel-modal.hotel-description': 'Descripción del hotel',

  'containers.search.office-modal.select-room':
    'Seleccione una oficina para reservar',
  'containers.search.office-modal.room-name': 'Tipo',
  'containers.search.office-modal.room-size': 'Número de personas',
  'containers.search.office-modal.price-total': 'Precio (total)',
  'containers.search.office-modal.office-description':
    'Descripción de la oficina',

  'containers.search.hotel-proximity-search.address-placeholder':
    'Direcciones, ubicaciones...',

  'containers.search.package-items.cheaper-option': 'Opción más barata',
  'containers.search.package-items.stay-longer': 'Más tiempo en destino',
  'containers.search.package-items.arive-early': 'Llegar temprano',

  'containers.search.package-search.change-flight':
    'Cambiar el vuelo a continuación',
  'containers.search.package-search.choose-another-flight': 'Elige otro vuelo',
  'containers.search.package-search.change-hotel': 'Cambia el hotel de abajo',
  'containers.search.package-search.choose-another-hotel': 'Elige otro hotel',

  'containers.search.reservation-params.company-travel': 'Viajes por empresa',
  'containers.search.reservation-params.personal-travel': 'Viajes personales',

  'containers.timeline.timeline.hello': 'Hola, {nombre}!',
  'containers.timeline.timeline.next-trips':
    'Siga debajo de <br/>guientes viajes.',
  'containers.timeline.timeline.add-trip':
    'Añadir un viaje y empezar<br/>para montar.',
  'containers.timeline.timeline.app': 'Usa nuestra aplicación también',

  'containers.timeline.timeline.add-refund':
    'Agregue un reembolso y <br/>comience a crear solicitudes.',
  'containers.timeline.timeline.next-refunds':
    'Siga sus reembolsos<br/>a continuación.',
  'containers.timeline.timeline.available-refunds':
    'Seleccione los reembolsos<br/>que desea agregar.',

  'containers.users.data-list-view.incomplete-registration':
    'Registro incompleto',

  'containers.users.user-modal.profile': 'Perfil',
  'containers.users.user-modal.credit-card': 'Tarjetas de crédito',

  'containers.users.user-modal-credit-card.registered-cards':
    'Tarjetas de crédito',
  'containers.users.user-modal-credit-card.new-card': 'Nueva tarjeta',

  'containers.users.user-modal-profile-tab.personal-data': 'Datos personales',
  'containers.users.user-modal-profile-tab.company': 'Empresa',
  'containers.users.user-modal-profile-tab.documents': 'Documents',
  'containers.users.user-modal-profile-tab.passport': 'Passport',

  'containers.version.version-card':
    '¡Oye! Se lanzó una nueva versión de la aplicación web. Actualice la página o haga clic en el botón de abajo para disfrutar de la nueva versión:',

  'containers.version.version-card.button': 'Actualizar',

  /*10. Vistas*/
  // 'servicio: [hotel, vuelos, coches]'
  'views.travel.new-requests.searching': 'Buscando {service} para {location}',
  'service.package': 'vuelo y hotel',
  'service.flight': 'vuelo',
  'service.hotel': 'hotel',
  'service.car': 'coche',
  'service.bus': 'autobús',
  'service.office': 'oficina',

  'views.user.onboarding.alright': '¡Todo salió bien!',
  'views.user.onboarding.verify-email':
    'Revisa tu correo electrónico para crear tu contraseña',
  'views.user.onboarding.sign-in':
    'Regístrese y asegúrese de comenzar su <b>mete experiencia</b> con la gestión de viajes.',

  /*11. Alts*/
  'alts.components.flight.airport-wait': 'larga espera en el aeropuerto',

  'alts.components.search.hotel-item.hotel-category': 'Categoría de hotel',

  'alts.containers.search.hotel-modal.breakfast': 'Desayuno incluido',
  'alts.containers.search.hotel-modal.cancellation': 'Cancelación gratuita',

  'alts.containers.search.hotel-proximity-search.erase': 'Eliminar',

  'alts.containers.search.package-search.station': 'Eliminar',

  'alts.components.flight.journey.journey': 'Journey',
  'alts.components.flight.journey.duration': 'Duración',

  'alts.components.search.no-results': 'No encontramos resultados',

  'alts.components.timeline.timeline.download': 'Descargar desde la App  Store',
  'alts.components.timeline.timeline.available': 'Disponible en Google Play',

  'alts.components.timeline.flight-card': 'Vuelos',

  'alts.components.timeline.hotel-card': 'Hoteles',
  'alts.components.timeline.office-card': 'Oficina',
  'alts.components.timeline.car-card': 'Carro',
  'alts.components.timeline.bus-card': 'Autobús',

  'alts.containers.search.car-form': 'Origen y destino',

  'alts.containers.search.flight-item': 'Fragmento',

  /*12. Marcador de posición*/
  'placeholders.components.reports.data-selection': 'Seleccione las fechas',
  'placeholders.components.reports.search':
    'Nombres, centros de costos, proyectos...',

  // 13. Checklists
  'checklist.help': '¿Necesitas ayuda?',
  'checklist.setup': 'Bienvenido a P3!',
  'checklist.setup.1': 'Busca la plataforma',
  'checklist.setup.2': 'Registrar una tarjeta de crédito',
  'checklist.setup.3': 'Registrar un centro de costos',
  'checklist.setup.4': 'Invita a los usuarios a la plataforma',

  // 14. Reports
  'reports.print.type': 'Tipo',
  'reports.print.details': 'Detalles',
  'reports.print.generate': 'Generar archivo',

  'reports.show-filters': 'Mostrar todos los filtros',
  'reports.excel.product': 'Producto',
  'reports.excel.passenger': 'Pasajero',
  'reports.excel.reservation': 'Reserva',
  'reports.excel.cost-center': 'Centro de costos',
  'reports.excel.project': 'Proyecto',
  'reports.excel.date': 'Fecha',
  'reports.excel.total': 'Total',

  'reports.details.simple': 'Sencillo',
  'reports.details.detailed': 'Detallado',

  'reports.excel.worksheet-name': 'Informe P3',

  'reports.pdf.from': 'Desde',
  'reports.pdf.to': 'hasta',

  'reports.json.type': 'escribe',
  'reports.json.passenger': 'pasajero',
  'reports.json.reservation': 'reserva',
  'reports.json.cost-center': 'centroCostos',
  'reports.json.project': 'proyecto',
  'reports.json.date': 'fecha',
  'reports.json.total': 'total',

  'reports.name': 'Informe P3',

  'reports.total-value': 'Monto',

  'reports.filter.products': 'Productos',
  'reports.filter.passengers': 'Pasajeros',
  'reports.filter.costCenters': 'Centros de Costo',
  'reports.filter.projects': 'Proyectos',
  'reports.filter.prices': 'Precios',

  'reports.advanced-filter': 'Avanzado',

  'reports.pdf.all-reservations': 'Todas las reservaciones',

  'reports.advanced-filter.departureIataCode': 'Salida',
  'reports.advanced-filter.arrivalIataCode': 'Llegada',
  'reports.advanced-filter.roundTrip': 'Ida y vuelta?',
  'reports.advanced-filter.category': 'Categoría',
  'reports.advanced-filter.chain': 'Cadena',
  'reports.advanced-filter.hotelName': 'Nombre',
  'reports.advanced-filter.city': 'Ciudad',
  'reports.advanced-filter.carName': 'Modelo',
  'reports.advanced-filter.rental': 'Alquiler',
  'reports.advanced-filter.airConditioning': 'Aire acondicionado',
  'reports.advanced-filter.baggageSize': 'Tamaño del equipaje',
  'reports.advanced-filter.doors': 'Num. puertas',
  'reports.advanced-filter.passengers': 'Num. pasajeros',
  'reports.advanced-filter.traction': 'Tracción',
  'reports.advanced-filter.transmission': 'Transmisión',
  'reports.advanced-filter.departureStation': 'Salida',
  'reports.advanced-filter.arrivalStation': 'Llegada',
  'reports.advanced-filter.carType': 'Escribe',
  'reports.advanced-filter.roomName': 'Tipo de reserva',

  // 14. Refund Categories
  'refund.category.placeholder': 'Seleccione un icono para esta categoría',
  'refund.category.car': 'Coche',
  'refund.category.hotel': 'Hotel',
  'refund.category.flight': 'Vuelo',
  'refund.category.food': 'Comida',
  'refund.category.fuel': 'Combustible',
  'refund.category.lazer': 'Lazer',
  'refund.category.money': 'Dinero',
  'refund.category.apple': 'Fruta',
  'refund.category.bank': 'Banco',
  'refund.category.boat': 'Bote',
  'refund.category.building': 'Edificio',
  'refund.category.building2': 'Edificio 2',
  'refund.category.building3': 'Edificio 3',
  'refund.category.bus': 'Autobús',
  'refund.category.bulb': 'Bombilla',
  'refund.category.church': 'Iglesia',
  'refund.category.clothes': 'Ropa',
  'refund.category.coffee': 'Café',
  'refund.category.coffee2': 'Café 2',
  'refund.category.coin': 'Moneda',
  'refund.category.doctor': 'Médico',
  'refund.category.drink': 'Bebida',
  'refund.category.globe': 'Globo',
  'refund.category.health': 'Salud',
  'refund.category.industry': 'Industry',
  'refund.category.law': 'Industria',
  'refund.category.syringe': 'Jeringuilla',
  'refund.category.taxi': 'Taxi',
  'refund.category.wine': 'Vino',
  'refund.category.suitcase': 'Maleta',

  // 14. Titles
  'refund.expense.cancel': 'Cancelar solicitud y eliminar reembolsos',
  'refund.expense.undo': 'Cancelar la solicitud, pero conservar los reembolsos',

  'expense.expense-items.reject': 'Hay elementos rechazados en esta solicitud!',
  'expense.expense-items.comment': 'Hay un comentario en esta solicitud.!',
  'refund.approver-comment': 'Comentario del aprobador',

  'approval-card.review':
    'Debe rechazar un artículo o escribir un comentario para solicitar una revisión.!',
  'approval-card.all-rejected':
    'No puede rechazar todos los elementos y aprobar esta solicitud. Intenta rechazar la solicitud en su lugar!',

  // 15. Modals
  'modal.yes': 'Sí',
  'modal.no': 'No',
  'modal.ok': 'Ok',

  'search.hotelSuggestionModal.title':
    '¡Excelente, su solicitud de reserva ha sido enviada con éxito!',
  'search.hotelSuggestionModal.options':
    'Ver también opciones de alojamiento en:',
  'search.hotelSuggestionModal.options.locale':
    'Seleccione una opción de alojamiento para hacer su reserva.',
  'search.hotelSuggestionModal.reserve': 'Reservar con cafe',
  'search.clear-search': 'Borrar búsqueda',

  //NPS
  'nps.questions':
    '¡Hola! ¿Qué tan probable es que recomiende Portão 3 a sus colegas?',
  'nps.thanks': '¡Gracias por compartir sus comentarios con nosotros!',
  'nps.followUp': '¿Te importaría decirnos por qué elegiste',
  'nps.placeholder': 'Cuéntanos un poco mejor si es posible.',
  'nps.dismiss': 'Despedir',
  'nps.submit': 'Mandar',
  'nps.min.label': 'Poco provable',
  'nps.max.label': 'Muy provable',
};
