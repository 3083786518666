import React, { useEffect, useMemo, useState } from 'react';
import { Button } from 'reactstrap';
import CustomSelect from '../../common/CustomSelect';
import TopMessage from '../../TopMessage';

export default function BarcodeMessages({
  show,
  barcodes,
  onDone = () => {},
  onReject = () => {},
  onFail = () => {},
}) {
  const [barcode, setBarcode] = useState(null);
  const [doShow, setDoShow] = useState(show);

  const options = useMemo(
    () =>
      barcodes
        ? barcodes.map((barcode) => ({
            value: barcode,
            label: barcode,
          }))
        : [],
    [barcodes]
  );

  // Effects
  useEffect(() => {
    if (show) {
      setBarcode(null);
      setDoShow(true);
    } else setDoShow(false);
  }, [show]);

  // Functions
  const handleSelection = () => {
    setDoShow(false);
    onDone(barcode.value);
  };

  const handleReject = () => {
    setDoShow(false);
    // setTimeout(() => onReject(), 400);
  };

  return (
    <TopMessage
      show={doShow}
      description="Eu identifiquei mais de um possível código de barras. Você poderia selecionar o correto, por favor?"
      toggle={handleReject}
    >
      <CustomSelect
        value={barcode}
        options={options}
        isMulti={false}
        className="w-100 my-3"
        onChange={(value) => setBarcode(value)}
      />
      <Button disabled={!barcode} onClick={handleSelection} color="primary">
        Pronto!
      </Button>
    </TopMessage>
  );
}
