import { useCallback, useEffect, useMemo, useRef } from 'react';
import { TLocationMapping } from './types';
import { TObject } from 'src/portao3-legacy/types/others';
import useHistoryWithScroll from '../useHistoryWithScroll';

interface IProps {
  paths: TObject<TLocationMapping>;
  preventLoop?: boolean;
}

export default function useLocationMapping(
  { paths, preventLoop = true }: IProps,
  dependencies: any[] = []
) {
  const { location } = useHistoryWithScroll();

  // Refs
  const lastLocation = useRef<string>();

  // States
  const _deps = useMemo(
    () => new Date().getTime(),
    [dependencies, location.pathname]
  );

  // Effects
  useEffect(() => {
    onRouteChange({ paths, pathname: location.pathname, preventLoop });
  }, [_deps]);

  // Functions
  const onRouteChange = useCallback(
    ({
      paths,
      pathname,
      preventLoop = true,
    }: {
      paths: TObject<TLocationMapping>;
      pathname: string;
      preventLoop: boolean;
    }) => {
      const splittedPaths = pathname.split('/');
      const lastIndex = splittedPaths.length - 1;

      for (let i = lastIndex, route = splittedPaths[lastIndex]; i > 0; i--) {
        const match = paths[route];

        if (match) {
          if (
            !match.validate() ||
            (preventLoop && lastLocation.current === route)
          ) {
            if (match.onFail && lastLocation.current !== route) match.onFail();

            return false;
          }

          match.onMatch();
          lastLocation.current = route;
          return true;
        }
      }

      return false;
    },
    []
  );

  return location;
}
