import { useSelector } from 'react-redux';
import { getUserNationality } from '../utils/nationality';
import { getOrganizationConfig } from '../utils/organization';
import { useMemo } from 'react';

export default function useReservationParamsInfo(defaultNationality) {
  const {
    requiredFields = {},
    editableFields = {},
    organization,
  } = useSelector(({ auth }) => ({
    requiredFields: getOrganizationConfig(auth.organization.config, 'required'),
    editableFields: getOrganizationConfig(auth.organization.config, 'editable'),
    organization: auth.organization,
  }));

  // States
  const nationality = useMemo(
    () => defaultNationality || getUserNationality({ organization }),
    [defaultNationality]
  );

  return {
    nationality,
    requiredFields,
    editableFields,
  };
}
