export function attachLink(
  element,
  link,
  classList = ['white-link', 'text-underline']
) {
  if (!(element && link)) return;

  element.setAttribute('href', link);

  if (classList.length) {
    element.classList.add(...classList);
  }
}
