import React, { useState, useEffect, useMemo } from 'react';
import { Button } from 'reactstrap';
import firebaseApp from '../../services/Firebase';
import { useIntl } from 'react-intl';
// import PrintOrder from '../order/PrintOrder';
import { RefundStatus } from '../../constants/refundStatus';
import DownloadReceipts from '../order/DownloadReceipts';

let notificationsEvent = () => {};

const validStatusCodes = [
  RefundStatus.APPROVED,
  RefundStatus.REJECTED,
  RefundStatus.CANCELLED,
];

function NotificationItem({ budget, notification, total }) {
  const { messages } = useIntl();

  const [loading, setLoading] = useState(false);

  const setToRead = async () => {
    await firebaseApp.setNotificationsFromBudgetToRead(
      budget.id,
      notification.id
    );
  };

  const handleClick = async () => {
    try {
      setLoading(true);
      setToRead();
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="notification-item">
      <div className="delete m-0">
        <div className="float-left">1/{total}</div>
        <Button onClick={setToRead} disabled={loading} color="transparent">
          x
        </Button>
      </div>
      <div className="content d-flex align-items-center justify-content-center flex-grow-1 pb-4">
        <div>
          <p className="m-0 p-0">
            {messages[`budget.notifications.${notification.type}`]}
          </p>
          <Button size="sm" disabled={loading} onClick={handleClick}>
            {messages['orders.notifications.btn.ok']}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default function BudgetStatusHeader({ budget, budgetItems }) {
  const [notifications, setNotifications] = useState([]);

  const [numNotifications, hasNotifications] = useMemo(() => {
    const length = notifications.length;
    return [length, length > 0];
  }, [notifications]);

  const showHeaderButtons = useMemo(
    () =>
      budget.administrative
        ? budget.statusCode === RefundStatus.APPROVED
        : validStatusCodes.includes(budget.statusCode) ||
          validStatusCodes.includes(budget.previousStatusCode),
    [budget]
  );

  // Effects
  useEffect(() => {
    getNotifications();

    return () => {
      notificationsEvent();
    };
  }, []);

  // Functions
  const getNotifications = async () => {
    notificationsEvent = await firebaseApp
      .getNotificationsFromBudget(budget.id)
      .onSnapshot((notificationsSnap) => {
        const notifications = [];

        notificationsSnap.forEach((notificationSnap) => {
          notifications.push({
            ...notificationSnap.data(),
            id: notificationSnap.id,
          });
        });

        setNotifications(notifications);
      });
  };

  // Render
  return (
    <div
      className={`order-header ${hasNotifications ? 'has-notification' : ''}`}
    >
      {showHeaderButtons ? (
        <div className="header-btns">
          <DownloadReceipts budget={budget} budgetItems={budgetItems} />
        </div>
      ) : null}

      {hasNotifications && (
        <NotificationItem
          budget={budget}
          notification={notifications[0]}
          total={numNotifications}
        />
      )}
    </div>
  );
}
