import React from 'react';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { ReactComponent as KeyIcon } from '../../../assets/img/icons/key.svg';
import { ReactComponent as LockedIcon } from '../../../assets/img/icons/locked.svg';
import { ReactComponent as UnlockedIcon } from '../../../assets/img/icons/unlocked.svg';
import { ReactComponent as ShowIcon } from '../../../assets/img/icons/show.svg';
import { ReactComponent as HideIcon } from '../../../assets/img/icons/hide.svg';
import { ReactComponent as CopyIcon } from '../../../assets/img/icons/copy.svg';

import { useHistory } from 'react-router-dom';

export default function CardHoverComponent({
  card,
  canChangePassword = true,
  onChangePassword = null,
  canBlock = true,
  toggleBlock = () => {},
  blocked = false,
  canShowDetails = true,
  showDetails = false,
  toggleDetails = () => {},
  loadingDetails = false,
  pan,
}) {
  const { messages } = useIntl();
  const history = useHistory();

  const [BlockIcon, blockTitle] = useMemo(() => {
    if (blocked) {
      return [LockedIcon, messages['expense-card.do-unblocked']];
    } else {
      return [UnlockedIcon, messages['expense-card.do-blocked']];
    }
  }, [blocked]);

  const [ShowDetailsIcon, detailsTitle] = useMemo(() => {
    if (showDetails && !loadingDetails)
      return [HideIcon, messages['expense-card.hide-details']];
    else return [ShowIcon, messages['expense-card.show-details']];
  }, [showDetails]);

  // Functions
  const onPasswordClick = () => {
    if (onChangePassword) onChangePassword();
    else {
      history.push(`/payments/cards/${card.card_id}/pin`);
    }
  };

  const onCopy = () => {
    if (pan) {
      navigator.clipboard.writeText(pan.pan);
    }
  };

  // Render
  return (
    <div className="card-hover-component d-flex align-items-center justify-content-center">
      {canChangePassword ? (
        <div
          className="button d-flex align-items-center justify-content-center mx-2"
          onClick={onPasswordClick}
        >
          <KeyIcon
            width="70%"
            fill="white"
            stroke="white"
            className="btn-icon"
          />
        </div>
      ) : null}
      {canBlock ? (
        <div
          className="button d-flex align-items-center justify-content-center mx-2"
          onClick={toggleBlock}
          title={blockTitle}
        >
          <BlockIcon
            width="50%"
            fill="white"
            stroke="white"
            className="btn-icon"
          />
        </div>
      ) : null}
      {canShowDetails ? (
        <div
          className="button d-flex align-items-center justify-content-center mx-2"
          onClick={toggleDetails}
          title={detailsTitle}
        >
          <ShowDetailsIcon
            width="50%"
            fill="white"
            stroke="white"
            className="btn-icon"
          />
        </div>
      ) : null}
      {canShowDetails && showDetails && !loadingDetails ? (
        <div
          className="button d-flex align-items-center justify-content-center mx-2"
          onClick={onCopy}
          title={messages['expense-card.copy']}
        >
          <CopyIcon
            width="50%"
            fill="white"
            stroke="white"
            className="btn-icon"
          />
        </div>
      ) : null}
    </div>
  );
}
