import React from 'react';
import { Modal } from 'reactstrap';
import { useHistory } from 'react-router-dom';

import { Wizard, Steps, Step } from 'react-albus';
import { TopNavigation } from '../wizard/TopNavigation';

import KYCModalWelcomeTab from './KYCModalWelcomeTab';
import KYCModalCompanyTab from './KYCModalCompanyTab';
import KYCModalSetupTab from './KYCModalSetupTab';

import messages from '../../lang/messages';

export default function KYCModal() {
  const history = useHistory();

  return (
    <Modal
      toggle={() => {
        history.push('/');
      }}
      isOpen={true}
      className="wizard wizard-default mt-5"
    >
      <Wizard>
        <TopNavigation className="justify-content-center" disableNav={false} />
        <Steps>
          <Step
            id="intro"
            hideTopNav={true}
            render={({ next }) => (
              <div className="wizard-basic-step">
                <KYCModalWelcomeTab onGoNext={next} />
              </div>
            )}
          />
          <Step
            id="step1"
            name={messages['admin.kyc.companyTabTitle']}
            desc={messages['admin.kyc.companyTabDesc']}
            render={({ next }) => (
              <div className="wizard-basic-step">
                <KYCModalCompanyTab onGoNext={next} />
              </div>
            )}
          />
          <Step
            id="step2"
            name={messages['admin.kyc.setupTabTitle']}
            desc={messages['admin.kyc.setupTabDesc']}
            render={({ next }) => (
              <div className="wizard-basic-step">
                <KYCModalSetupTab onGoNext={next} />
              </div>
            )}
          />
        </Steps>
      </Wizard>
    </Modal>
  );
}
