import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  Row,
  DropdownToggle,
  DropdownMenu,
  ButtonDropdown,
  DropdownItem,
} from 'reactstrap';
import { Colxx, Separator } from '../../components/common/CustomBootstrap';
import Breadcrumb from '../../containers/navs/Breadcrumb';
import DataListView from '../../containers/users/DataListView';
import InviteModal from '../../containers/users/InviteModal';
import BulkModal from '../../containers/users/BulkModal';
import UserModal from '../../containers/users/UserModal';
import { diff } from 'deep-object-diff';
import Pagination from '../../containers/pages/Pagination';

import firebaseApp from '../../services/Firebase';

import { useIntl } from 'react-intl';
import { setDBUserToBulk, setToModel } from '../../schemas/BulkUser';
import BulkImportButton from '../../containers/users/BulkImportButton';
import { isCpfValid, numberToCpf } from '../../utils/cpf';
import RetractableSearchBar from '../../components/common/RetractableSearchBar';

import { ReactComponent as PlusIcon } from '../../assets/img/icons/plus.svg';
import NoResults from '../../components/search/NoResults';
import {
  addMultipleCostCenters,
  loadCostCenters,
} from '../../utils/costCenters';
import { addMultipleProjects, loadProjects } from '../../utils/projects';
import { maxNameLength } from '../../constants/defaultValues';
import { filterObject } from '../../utils/filters';
import useDataSnapshot from '../../hooks/useDataSnapshot';
import { storeBulkData } from '../../redux/bulk/actions';
import { BULK_USER_DATA_ACTIONS } from '../../constants/batch';
import { useCallback } from 'react';
import { getDateFormatBasedOnData } from '../../utils/users';
import useDataArchive from '../../hooks/useDataArchive';
import useHistoryWithScroll from 'src/portao3-legacy/hooks/useHistoryWithScroll';

const DEFINED_FIELDS = {
  costCenter: {
    fetch: loadCostCenters,
    add: addMultipleCostCenters,
  },
  project: {
    fetch: loadProjects,
    add: addMultipleProjects,
  },
};

const NEED_TO_SWITCH_DATE = {
  xlsx: true,
  xls: true,
};

const convertToObj = (values) => {
  return values.reduce((obj, item) => {
    if (item) {
      const { label, value } = item;
      obj[label] = value;
    }
    return obj;
  }, {});
};

export default function Users(props) {
  const {
    user,
    organization,
    doingActions = false,
  } = useSelector(({ auth, bulk }) => ({
    user: auth.user,
    organization: auth.organization,
    doingActions: bulk.users?.loading,
  }));
  const dispatch = useDispatch();

  const { messages } = useIntl();

  const { userId } = useParams();
  const { push } = useHistoryWithScroll();

  const [items, _, isLoading] = useDataSnapshot({
    fetchFunction: () =>
      firebaseApp.getUsersFromOrganization(user.organizationId),
    shouldLoad: !doingActions,
  });

  const { deleteDataFromArchive } = useDataArchive();

  // States
  const [invitesModal, setInvitesModal] = useState(false);
  const [bulkModal, setBulkModal] = useState(false);
  const [bulkUsers, setBulkUsers] = useState([]);
  const [dropdownSplitOpen, setDropdownSplitOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchKey, setSearchKey] = useState('');

  // Bulk import
  const [definedFieldValues, setDefinedFieldValues] = useState({});

  const usersFilterKeys = useMemo(
    () =>
      items.reduce((obj, user) => {
        obj[user.uid] = `${user.firstName} ${user.lastName} ${user.email} ${
          user.mobilePhoneNumber || ''
        }`.toLowerCase();
        return obj;
      }, {}),
    [items]
  );

  const users = useMemo(() => {
    if (searchKey.trim()) {
      const lowerSearchKey = searchKey.toLowerCase();
      return items.filter((user) =>
        (usersFilterKeys[user.uid] || '').includes(lowerSearchKey)
      );
    } else return items;
  }, [searchKey, usersFilterKeys]);

  const paginatedUsers = useMemo(() => {
    const { itemsPerPage = 15 } = props;

    const startAt = itemsPerPage * (currentPage - 1);
    return users.slice(startAt, startAt + itemsPerPage);
  }, [users, currentPage]);

  const bulkImportFields = useMemo(
    () => [
      {
        label: `${messages['bulk.columns.first-name']} *`,
        key: 'firstName',
        validators: [
          { validate: 'required' },
          {
            validate: 'regex_matches',
            regex: `^.{1,${maxNameLength}}$`,
            error: messages['forms.validation.firstName.invalid'],
          },
        ],
      },
      {
        label: `${messages['bulk.columns.last-name']} *`,
        key: 'lastName',
        validators: [
          { validate: 'required' },
          {
            validate: 'regex_matches',
            regex: `^.{1,${maxNameLength}}$`,
            error: messages['forms.validation.lastName.invalid'],
          },
        ],
      },
      {
        label: 'CPF',
        key: 'cpf',
        // validators: [{ validate: 'required' }, { validate: 'unique' }],
        validators: [{ validate: 'unique' }],
      },
      {
        label: `${messages['bulk.columns.birthdate']}`,
        key: 'birthdate',
        description: messages['bulk.description'],
        validators: [
          // { validate: 'required' },
          {
            validate: 'regex_matches',
            regex: '^\\d{1,2}/\\d{1,2}/\\d{2,4}$',
            error: `${messages['forms.validation.birthdate.valid']} (DD/MM/YYYY)`,
          },
        ],
      },
      {
        label: messages['bulk.columns.identity'],
        key: 'identity',
        validators: [{ validate: 'unique' }],
      },
      {
        label: 'Email*',
        key: 'email',
        validators: [{ validate: 'required' }, { validate: 'unique' }],
      },
      { label: messages['user.costCenter'], key: 'costCenter' },
      { label: messages['user.project'], key: 'project' },
      { label: messages['user.role'], key: 'role' },
      { label: messages['user.companyId'], key: 'companyId' },
      { label: messages['user.phoneNumber'], key: 'mobilePhoneNumber' },
      {
        label: messages['user.gender'],
        key: 'gender',
        validators: [
          {
            validate: 'regex_matches',
            regex: '^[mMFf]$',
            error: `${messages['forms.validation.gender.valid']} (M | F)`,
          },
        ],
      },
      {
        label: messages['admin.users.bulk.rgNumber'],
        key: 'rg',
        validators: [{ validate: 'required_with', fields: ['rgEmitter'] }],
      },
      {
        label: messages['user.rgEmitter'],
        key: 'rgEmitter',
        validators: [{ validate: 'required_with', fields: ['rg'] }],
      },
      {
        label: messages['user.cnhNumber'],
        key: 'cnh',
        validators: [{ validate: 'required_with', fields: ['cnhValidUntil'] }],
      },
      {
        label: messages['admin.users.bulk.cnhValidUntil'],
        key: 'cnhValidUntil',
        validators: [
          {
            validate: 'regex_matches',
            regex: '^\\d{1,2}/\\d{1,2}/\\d{2,4}$',
            error: `${messages['forms.validation.cnh.validUntil.valid']} (DD/MM/YYYY)`,
          },
          { validate: 'required_with', fields: ['cnh'] },
        ],
      },
      {
        label: messages['admin.users.passport.number'],
        key: 'passportNumber',
        validators: [
          { validate: 'required_with', fields: ['passaportValidUntil'] },
        ],
      },
      {
        label: messages['admin.users.passport.validUntil'],
        key: 'passaportValidUntil',
        validators: [
          {
            validate: 'regex_matches',
            regex: '^\\d{1,2}/\\d{1,2}/\\d{2,4}$',
            error: `${messages['forms.validation.cnh.validUntil.valid']} (DD/MM/YYYY)`,
          },
          { validate: 'required_with', fields: ['passportNumber'] },
        ],
      },
    ],
    []
  );

  // Effects
  useEffect(() => {
    if (currentPage !== 1) setCurrentPage(1);
  }, [searchKey]);

  useEffect(() => {
    if (currentPage !== 1 && !searchKey) {
      const { itemsPerPage = 15 } = props;
      const numPages = Math.ceil(users.length / itemsPerPage);
      if (currentPage > numPages) setCurrentPage(1);
    }
  }, [users, props.itemsPerPage]);

  // Functions
  const onClickItem = (userId) => {
    push(`/app/users/${userId}`);
  };

  const closeModal = () => {
    push(`/app/users`);
  };

  // Invite
  const toggleInvitesModal = () => {
    setInvitesModal(!invitesModal);
  };

  const toggleBulkModal = () => {
    setBulkModal(!bulkModal);
  };

  const verifyNewUserEmail = (email) => {
    if (items && items.length)
      return !items.some((user) => user.email === email);
    else return true;
  };

  const getUsersObject = () => {
    return items.reduce((currentObj, item) => {
      currentObj[item.email] = item;
      return currentObj;
    }, {});
  };

  const populateFields = (user, definedFields, fields) => {
    for (let i = 0, length = definedFields.length; i < length; i++) {
      const field = definedFields[i];
      const userField = user[field];

      if (userField) {
        user[field] = {
          id: fields[field][userField],
          name: userField,
        };
      } else delete user[field];
    }

    return user;
  };

  const sortBulkUsers = (
    newUsers = [],
    definedFields = [],
    fields = {},
    fileType
  ) => {
    const usersObj = getUsersObject();
    const modified = [],
      created = [],
      invalid = [];
    const emailsFromNewUsers = [];

    const dateFormat = getDateFormatBasedOnData(newUsers);

    for (let i = 0, length = newUsers.length; i < length; i++) {
      const user = newUsers[i];
      user.cpf = user.cpf ? numberToCpf(user.cpf.padStart(11, '0')) : '';
      user.identity = user.identity || '';
      user.firstName = user.firstName.trim();
      user.lastName = user.lastName.trim();
      user.email = user.email.trim();

      const _cpfValid = user.cpf ? isCpfValid(user.cpf) : true;

      if (
        _cpfValid &&
        `${user.firstName} ${user.lastName}`.length <= maxNameLength
      ) {
        emailsFromNewUsers.push(user.email);

        populateFields(user, definedFields, fields);

        if (user.gender)
          user.gender =
            user.gender.toLowerCase()[0] === 'm' ? 'MALE' : 'FEMALE';

        if (usersObj[user.email]) {
          const oldUser = setDBUserToBulk(usersObj[user.email]);
          const newUser = setToModel(user, dateFormat);

          const userDiff = filterObject(diff(oldUser, newUser));

          if (Object.keys(userDiff).length > 0) {
            modified.push({
              ...newUser,
              uid: usersObj[user.email].uid,
              changes: userDiff,
            });
          }
        } else created.push(filterObject(setToModel(user, dateFormat, true)));
      } else {
        user.invalidMotive = _cpfValid ? 'name' : 'cpf';
        invalid.push(user);
      }
    }

    return {
      modified,
      created,
      deleted: Object.keys(usersObj).reduce((arr, email) => {
        if (!emailsFromNewUsers.includes(email)) arr.push(usersObj[email]);
        return arr;
      }, []),
      invalid,
    };
  };

  const fetchDefinedFields = async (matchedHeaders) => {
    const headers = matchedHeaders.map((header) => header.matched_key);
    const definedFieldsKeys = Object.keys(DEFINED_FIELDS);
    const fields = definedFieldValues;

    for (let i = 0, length = headers.length; i < length; i++) {
      const header = headers[i];
      if (definedFieldsKeys.includes(header) && !fields[header]) {
        fields[header] = convertToObj(
          await DEFINED_FIELDS[header].fetch(user.organizationId, true)
        );
      }
    }

    setDefinedFieldValues({ ...fields });

    return fields;
  };

  const verifyExistence = (user, field, createdValues, newValues) => {
    const value = user[field];

    if (value && !createdValues[value] && !newValues[value]) {
      newValues[value] = true;
    }

    return newValues;
  };

  const createNewFields = async (data, fields = {}) => {
    const definedFieldsKeys = Object.keys(DEFINED_FIELDS);
    const definedFieldsLength = definedFieldsKeys.length;

    const newFields = definedFieldsKeys.reduce((obj, key) => {
      obj[key] = {};
      return obj;
    }, {});

    // For each user, verify if there's a new defined field
    for (let i = 0, length = data.length; i < length; i++) {
      const user = data[i];

      for (let j = 0; j < definedFieldsLength; j++) {
        const field = definedFieldsKeys[j];

        verifyExistence(user, field, fields[field], newFields[field]);
      }
    }

    // For each defined field, verify if there's at least a new one, then added it
    for (let i = 0; i < definedFieldsLength; i++) {
      const field = definedFieldsKeys[i];

      const newKeys = Object.keys(newFields[field]);

      if (newKeys.length > 0) {
        fields[field] = {
          ...fields[field],
          ...convertToObj(
            await DEFINED_FIELDS[field].add(user.organizationId, newKeys)
          ),
        };
      }
    }

    return fields;
  };

  const handleBulkImport = (data, fields, fileType) => {
    setBulkUsers(
      sortBulkUsers(data, Object.keys(DEFINED_FIELDS), fields, fileType)
    );
    setTimeout(() => setBulkModal(true), 1100);
  };

  const applyBulkImport = useCallback((data) => {
    const formattedData = {};

    if (data.created?.length)
      formattedData[BULK_USER_DATA_ACTIONS.CREATE] = data.created;
    if (data.modified?.length)
      formattedData[BULK_USER_DATA_ACTIONS.MODIFY] = data.modified;
    if (data.deleted?.length)
      formattedData[BULK_USER_DATA_ACTIONS.DELETE] = data.deleted;

    dispatch(
      storeBulkData('users', {
        data: formattedData,
        finalQuestions: data.finalQuestions || {},
        loading: true,
        timestamp: new Date().getTime(),
      })
    );

    deleteDataFromArchive('users');
  }, []);

  const handleOnData = async (results) => {
    const fileType = results.fileName
      ? results.fileName.split('#')[0].split('.').pop()
      : '';

    let fields = await fetchDefinedFields(results.headersMatched);
    fields = await createNewFields(results.validData, fields);
    handleBulkImport(results.validData, fields, fileType);
    setDefinedFieldValues(fields);

    return messages['bulk.successful'];
  };

  // Render
  const renderPagination = () => {
    const { itemsPerPage = 15 } = props;
    const numUsers = users.length;

    return (
      numUsers > itemsPerPage && (
        <Pagination
          currentPage={currentPage}
          totalPage={Math.ceil(numUsers / itemsPerPage)}
          onChangePage={(i) => setCurrentPage(i)}
        />
      )
    );
  };

  if (isLoading) {
    return <div className="loading" />;
  }

  return (
    <>
      <div className="users disable-text-selection pb-5">
        <Row>
          <Colxx xxs="12">
            <div className="mb-2">
              <Breadcrumb heading="menu.users" match={props.match} />
            </div>
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        <Row className="d-flex justify-content-end mr-1 mb-4">
          <RetractableSearchBar
            value={searchKey}
            onChange={(e) => setSearchKey(e.target.value)}
            placeholder={messages['placeholders.components.reports.search']}
            width={200}
          />

          <ButtonDropdown
            isOpen={dropdownSplitOpen}
            toggle={() =>
              !doingActions && setDropdownSplitOpen(!dropdownSplitOpen)
            }
            className="float-right ml-3"
            style={{ height: 45 }}
            disabled={doingActions}
          >
            <div
              className="btn btn-primary btn-lg p-0 d-flex align-items-center justify-content-center"
              onClick={() => !doingActions && toggleInvitesModal()}
            >
              <PlusIcon width={40} fill="white" />
            </div>
            <DropdownToggle
              caret
              color="primary"
              className="dropdown-toggle-split btn-lg"
            />
            <DropdownMenu right style={{ width: 180 }}>
              <div className="bulk-import-container">
                <BulkImportButton
                  user={user}
                  onData={handleOnData}
                  simple
                  type={messages['bulk.type']}
                  fields={bulkImportFields}
                />
              </div>
              <a
                href="https://storage.googleapis.com/portao3-static/models/Planilha_Modelo_Usuarios.xlsx"
                rel="noopener noreferer"
                download
              >
                <DropdownItem
                  className="w-100 m-0 text-break"
                  style={{ whiteSpace: 'pre-wrap' }}
                >
                  {messages['admin.users.spreadsheet-model']}
                </DropdownItem>
              </a>
            </DropdownMenu>
          </ButtonDropdown>
        </Row>
        <Row>
          {paginatedUsers.length > 0 ? (
            <>
              {paginatedUsers.map((user) => {
                return (
                  <DataListView
                    key={user.uid}
                    user={user}
                    organization={organization}
                    onClickItem={(_, user) => onClickItem(user.uid)}
                  />
                );
              })}
              {renderPagination()}
            </>
          ) : (
            <div className="d-flex justify-content-center w-100">
              <NoResults />
            </div>
          )}
        </Row>
      </div>

      {invitesModal && (
        <InviteModal
          toggleModal={toggleInvitesModal}
          verifyEmail={verifyNewUserEmail}
        />
      )}

      {bulkModal && (
        <BulkModal
          toggleModal={toggleBulkModal}
          bulkUsers={bulkUsers}
          onCancel={() => {
            toggleBulkModal();
            setBulkUsers({});
          }}
          onApply={(values) => {
            toggleBulkModal();
            applyBulkImport(values);
          }}
          finalQuestions={[
            {
              name: 'shouldInvite',
              message: messages['user.shouldInvite'],
              initialValue: true,
            },
          ]}
        />
      )}

      {userId && <UserModal toggleModal={closeModal} userId={userId} />}
    </>
  );
}
