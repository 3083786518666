import moment from 'moment';
import orderStatus from '../constants/orderStatus';
import firebaseApp from '../services/Firebase';

const getOrderFromIdsIfIssued = async (orderIds) => {
  const orders = await Promise.all(
    orderIds.map((orderId) =>
      firebaseApp
        .getOrderFromId(orderId)
        .get()
        .then((orderSnap) => {
          const order = {
            id: orderId,
            ...orderSnap.data(),
          };

          return order.statusCode === orderStatus.ISSUED ? order : null;
        })
        .catch((err) => {
          console.error('Unable to get order', orderId, err);
          return null;
        })
    )
  );

  return orders.filter((order) => order);
};

const getDuplicityBasedOnReservations = async ({
  order,
  reservationProperty = 'hotelDetails',
  startTimeObject = { property: 'checkIn', format: 'DD/MM/YYYY' },
  endTimeObject = { property: 'checkOut', format: 'DD/MM/YYYY' },
  startTimeHoursMinutes,
  endTimeHoursMinutes,
}) => {
  const orderReservationSnap = await firebaseApp
    .getReservationsFromOrderId(order.organizationId, order.id)
    .get();
  const reservations = [];

  orderReservationSnap.forEach((docRef) => {
    reservations.push({
      id: docRef.id,
      ...docRef.data(),
    });
  });

  if (!reservations.length) return [];
  else reservations.sort((a, b) => a.filterTimestamp - b.filterTimestamp);

  const startAt = moment(
    reservations[0][reservationProperty][startTimeObject.property],
    startTimeObject.format
  );
  const endAt = moment(
    reservations[reservations.length - 1][reservationProperty][
      endTimeObject.property
    ],
    endTimeObject.format
  );

  const reservationsSnap = await firebaseApp
    .getReservationsInFilterTimestampInterval(
      order.organizationId,
      order.passengerId,
      order.type,
      (startTimeHoursMinutes
        ? startAt.set(startTimeHoursMinutes)
        : startAt.startOf('day')
      ).unix(),
      (endTimeHoursMinutes
        ? endAt.set(endTimeHoursMinutes)
        : endAt.endOf('day')
      ).unix()
    )
    .get();

  if (reservationsSnap.size > 0) {
    const orderIds = [];

    reservationsSnap.forEach((docRef) => {
      const orderId = docRef.ref.path.split('/')[1];
      if (!orderIds.includes(orderId)) orderIds.push(orderId);
    });

    if (orderIds.length) {
      const orders = await getOrderFromIdsIfIssued(orderIds);
      return orders[0];
    }
  }

  return null;
};

const isFlightOrderADuplicity = (newOrder, originalOrder) => {
  const {
    departureDate,
    departureStation: { iataCode, name },
  } = originalOrder.journeys[0];

  return newOrder.journeys.some(
    (journey) =>
      journey.departureDate === departureDate &&
      (journey.departureStation.iataCode === iataCode ||
        journey.departureStation.name === name)
  );
};

const isBusOrderADuplicity = (newOrder, originalOrder) => {
  const { departureDate, departureStation } = originalOrder.journeys[0];
  const formattedDepartureStation = departureStation.split(' - ')[0];

  return newOrder.journeys.some(
    (journey) =>
      journey.departureDate === departureDate &&
      journey.departureStation.split(' - ')[0] === formattedDepartureStation
  );
};

const getDuplicityBasedOnOrders = async (
  order,
  validationFunction = isFlightOrderADuplicity
) => {
  const duplicities = [];
  const today = moment.unix(order.filterTimestamp);

  const reservationsSnap = await firebaseApp
    .getIssuedOrdersByTypeAndFilterTimestamp(
      order.organizationId,
      order.passengerId,
      order.type,
      today.clone().subtract(1, 'day').endOf('day').unix()
    )
    .get();

  reservationsSnap.forEach((docRef) => {
    const otherOrder = {
      id: docRef.id,
      ...docRef.data(),
    };

    if (validationFunction(otherOrder, order)) duplicities.push(otherOrder);
  });

  return duplicities[0];
};

export const getFlightDuplicity = (order) => getDuplicityBasedOnOrders(order);

export const getHotelDuplicity = (order) =>
  getDuplicityBasedOnReservations({
    order,
    startTimeHoursMinutes: { hours: 15, minutes: 0 },
    endTimeHoursMinutes: { hours: 12, minutes: 0 },
  });

export const getCarDuplicity = (order) =>
  getDuplicityBasedOnReservations({
    order,
    reservationProperty: 'carDetails',
    startTimeObject: { property: 'pickUp', format: 'DD/MM/YYYY HH:mm' },
    endTimeObject: { property: 'dropOff', format: 'DD/MM/YYYY HH:mm' },
  });

export const getBusDuplicity = (order) =>
  getDuplicityBasedOnOrders(order, isBusOrderADuplicity);
