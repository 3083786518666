import React from 'react';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { hasExpenses } from '../utils/organization';

export default function Main() {
  const organization = useSelector(({ auth }) => auth.organization);

  const redirectRoute = useMemo(() =>
    hasExpenses(organization) ? '/payments' : '/travel'
  );

  // Render
  return <Redirect to={redirectRoute} />;
}
