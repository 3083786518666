import React from 'react';
import { useIntl } from 'react-intl';
import { Button } from 'reactstrap';
import AlterationFrame from '../../AlterationFrame';
import OptionCard from '../../FlightAlteration/OptionCard';
import { CAR_ALTERATION_TABS } from '..';

import { ReactComponent as CancelIcon } from '../../../../../assets/img/icons/wallet.svg';

export default function InitialTab({
  canCancel,
  goForAlteration,
  setCurrentTab,
}) {
  const { messages } = useIntl();

  // Render
  const renderBottom = () => (
    <div className="ml-3 text-right btns">
      <Button color="link" onClick={goForAlteration} className="mb-0">
        {messages['general.go-back']}
      </Button>
    </div>
  );

  return (
    <AlterationFrame
      title={messages['travel.alter.title']}
      subtitle={messages['travel.alter.subtitle']}
      BottomBar={renderBottom}
    >
      <div className="card-menu-display">
        {canCancel ? (
          <OptionCard
            Icon={CancelIcon}
            title={messages['travel.cancel.title']}
            description={messages['travel.cancel.description']}
            className="show-up"
            style={{ animationDelay: '250ms' }}
            buttonText={messages['general.select']}
            onClick={() => setCurrentTab(CAR_ALTERATION_TABS.CANCEL)}
          />
        ) : null}
      </div>
    </AlterationFrame>
  );
}
