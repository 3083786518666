import PerfectScrollbar from 'react-perfect-scrollbar';
import BudgetStatusInfo from './BudgetStatusInfo';
import BudgetApproval from './BudgetApproval';
import BudgetStatusHeader from './BudgetStatusHeader';
import BudgetTotalStatus from './BudgetTotalStatus';

export default function BudgetStatus({
  budget,
  budgetItems = [],
  isValid = true,
  onDismiss = () => {},
  onCancel = () => {},
  onSendForApproval = () => {},
  onPutOnHold = () => {},
  approvals = [],
  goForApproval = () => {},
  payBudget = () => {},
  isApproving,
  disableActions = false,
  title,
  isCardArchived,
  toggleModal = () => {},
}) {
  // Render
  if (isApproving) {
    return (
      <BudgetApproval
        budget={budget}
        approvals={approvals}
        goForApproval={goForApproval}
        toggleModal={toggleModal}
      />
    );
  } else
    return (
      <div className="expense-status description h-100 d-flex align-items-center justify-content-end">
        <BudgetStatusHeader budget={budget} budgetItems={budgetItems} />

        <PerfectScrollbar
          options={{
            suppressScrollX: true,
            wheelPropagation: false,
          }}
          className="h-auto mh-100"
        >
          <BudgetTotalStatus budget={budget} />

          {isCardArchived ? null : (
            <div className="text order-status">
              <BudgetStatusInfo
                budget={budget}
                items={budgetItems}
                isValid={isValid}
                onDismiss={onDismiss}
                onCancel={onCancel}
                onSendForApproval={onSendForApproval}
                onPutOnHold={onPutOnHold}
                approvals={approvals}
                goForApproval={goForApproval}
                payBudget={payBudget}
                disableActions={disableActions}
                title={title}
              />
            </div>
          )}
        </PerfectScrollbar>
      </div>
    );
}
