export function mapIntoObject(keys, values) {
  return keys.reduce((obj, key, index) => {
    obj[key] = values[index];
    return obj;
  }, {});
}

export function mapIntoBooleanObject(keys, value = true) {
  return keys.reduce((obj, key) => {
    obj[key] = value;
    return obj;
  }, {});
}

export function getDuration(startMoment, endMoment) {
  const _minutes = endMoment.diff(startMoment, 'minutes');

  let minutes = 0,
    hours = 0;

  if (_minutes > 59) {
    const _hours = _minutes / 60;
    hours = Math.trunc(_hours);
    minutes = Math.trunc((_hours % 1) * 60);
  } else minutes = Math.trunc(_minutes);

  return {
    minutes,
    hours,
  };
}

export function removeUndefinedFromObject(obj) {
  const newObj = {};

  Object.keys(obj).forEach((key) => {
    const value = obj[key];

    if (value && typeof value === 'object') {
      newObj[key] = Array.isArray(value)
        ? value
        : removeUndefinedFromObject(value);
    } else if (value !== undefined) newObj[key] = value;
  });

  return newObj;
}

export function formatToLabelValueObject({ id, name }) {
  return {
    label: name,
    value: id,
  };
}

export function convertIdNameToValueLabel(obj = {}, elseValue = {}) {
  try {
    const { id: value, name: label } = obj || {};

    return value && label ? { value, label } : elseValue;
  } catch (err) {
    console.error(err);
    return elseValue;
  }
}
export function convertToLabelValueWithMessages({
  items = [],
  messages,
  messagePrefix = '',
  getMessageId = (id) => id,
}) {
  return items.map((value) => ({
    value,
    label: messages
      ? messages[`${messagePrefix}${getMessageId(value)}`] || value
      : value,
  }));
}

export function XOR(a, b) {
  return a && b ? false : a || b;
}

export function convertArrayToLabelValueArray(
  array,
  getLabel = (label) => label
) {
  return array.map((value) => ({ value, label: getLabel(value) }));
}

export function mapRegisteredItems(registeredItems) {
  return Object.keys(registeredItems).map((value) => ({
    value,
    label: `(${value}) ${registeredItems[value].name}`,
  }));
}
