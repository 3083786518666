import { ReactComponent as KeyIcon } from '../assets/img/icons/key.svg';
import { ReactComponent as LockedIcon } from '../assets/img/icons/locked.svg';
import { ReactComponent as UnlockedIcon } from '../assets/img/icons/unlocked.svg';
import { ReactComponent as ShowIcon } from '../assets/img/icons/show.svg';
import { ReactComponent as HideIcon } from '../assets/img/icons/hide.svg';
import { ReactComponent as ArchiveIcon } from '../assets/img/icons/archive.svg';

export const BLOCKED_STATUS = {
  block: 'BLOCK',
  unblock: 'UNBLOCK',
};

export const CARD_OPTION_TYPES = {
  PASSWORD: 'password',
  BLOCK: 'block',
  PAN: 'pan',
  ARCHIVE: 'archive',
};

export const CARD_OPTIONS = {
  [CARD_OPTION_TYPES.BLOCK]: {
    id: 'block',
    mutable: true,
    getIcon: (_, details = {}) => (details.blocked ? UnlockedIcon : LockedIcon),
    getMessageId: (_, details = {}) =>
      details.blocked ? 'block.unblocked' : 'block.blocked',
  },
  [CARD_OPTION_TYPES.PASSWORD]: {
    id: 'password',
    getIcon: () => KeyIcon,
    getMessageId: () => 'password',
  },
  [CARD_OPTION_TYPES.PAN]: {
    id: 'pan',
    mutable: true,
    getIcon: (_, details = {}) => (details.showPan ? HideIcon : ShowIcon),
    getMessageId: (_, details = {}) =>
      details.showPan ? 'pan.hide' : 'pan.show',
  },
  [CARD_OPTION_TYPES.ARCHIVE]: {
    id: 'archive',
    getIcon: () => ArchiveIcon,
    getMessageId: () => 'archive',
    iconStyle: { strokeWidth: 0 },
  },
};

export const ASSOCIATIVE_PROPERTIES = {
  COST_CENTERS: 'costCenters',
  PRODUCTS: 'products',
};
