import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import {
  CustomInput,
  Row,
  Button,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { Colxx } from '../../components/common/CustomBootstrap';
import map from 'lodash/map';
import mouseTrap from 'mousetrap';

import IntlMessages from '../../helpers/IntlMessages';

import firebaseApp from '../../services/Firebase';

import { trackPropertiesSegment } from '../../utils/segment';
import useDataSnapshot from '../../hooks/useDataSnapshot';
import FavoritePlaceModal from '../../components/travels/FavoritePlaceModal';
import recordStatus from '../../constants/recordStatus';
import { diff } from 'deep-object-diff';
import EmptyResults from '../../components/common/EmptyResults';
import { useIntl } from 'react-intl';
import FavoritePlaceCard from '../../components/travels/FavoritePlaceCard';
import FavoritePlacesLoading from './FavoritePlacesLoading';

export default function FavoritePlaceDataListView() {
  const { resourceId } = useParams();
  const history = useHistory();
  const { user } = useSelector((state) => state.auth);
  const { messages } = useIntl();

  const [selectedItems, setSelectedItems] = useState([]);
  const [dropdownSplitOpen, setDropdownSplitOpen] = useState(false);
  const [favoritePlaces, setFavoritePlaces, isLoading] = useDataSnapshot({
    fetchFunction: () =>
      firebaseApp
        .getFavoritePlacesFromOrganization(user.organizationId)
        .where('status', '==', recordStatus.ACTIVE),
  });
  const isEmpty = useMemo(
    () => !favoritePlaces || favoritePlaces.length === 0,
    [favoritePlaces]
  );

  useEffect(() => {
    mouseTrap.bind(['ctrl+a', 'command+a'], () => {
      setSelectedItems(map(favoritePlaces, (x) => x.id));
      return false;
    });

    mouseTrap.bind(['ctrl+d', 'command+d'], () => {
      setSelectedItems([]);
      return false;
    });

    return () => {
      mouseTrap.unbind('ctrl+a');
      mouseTrap.unbind('command+a');
      mouseTrap.unbind('ctrl+d');
      mouseTrap.unbind('command+d');
    };
  }, [favoritePlaces]);

  // Handle Places
  const savePlace = async (place = {}, placeId) => {
    const { name, address, lat, lng } = place;

    if (placeId !== 'new') {
      const oldPlace = favoritePlaces.find((value) => value.id === placeId);
      const diffPlace = diff(
        {
          name: oldPlace.name,
          lat: oldPlace.lat,
          lng: oldPlace.lng,
          address: oldPlace.address,
        },
        { name, lat, lng, address }
      );

      if (Object.keys(diffPlace).length > 0)
        return firebaseApp.modifyFavoritePlace(
          user.organizationId,
          placeId,
          diffPlace
        );
    } else {
      return firebaseApp.addNewFavoritePlace(user.organizationId, {
        name,
        address,
        lat,
        lng,
      });
    }
  };

  const deleteFavoritePlace = async () => {
    for (let i = 0; i < selectedItems.length; i++) {
      await firebaseApp.deleteFavoritePlace(
        user.organizationId,
        selectedItems[i]
      );
    }

    trackPropertiesSegment('Favorite place deleted', {
      favoritePlaces: selectedItems,
      user,
    });

    setSelectedItems([]);
  };

  const deleteSinglePlace = async (id) => {
    await firebaseApp.deleteFavoritePlace(user.organizationId, id);
  };

  // Split
  const toggleSplit = () => {
    setDropdownSplitOpen(!dropdownSplitOpen);
  };

  // Selection
  const onCheckItem = (id) => {
    if (selectedItems.includes(id)) {
      setSelectedItems(selectedItems.filter((x) => x !== id));
    } else {
      setSelectedItems([...selectedItems, id]);
    }
  };

  const handleChangeSelectAll = () => {
    if (selectedItems.length >= favoritePlaces.length) {
      setSelectedItems([]);
    } else {
      setSelectedItems(map(favoritePlaces, (x) => x.id));
    }
  };

  // Modal
  const openModal = (ruleId) => {
    history.push(`/app/travels/favorite-places/${ruleId}`);
  };

  const closeModal = () => {
    history.push('/app/travels/favorite-places');
  };

  // Render
  if (isLoading) {
    return <FavoritePlacesLoading />;
  }

  return (
    <div>
      {isEmpty ? (
        <div className="w-100 d-flex justify-content-center mt-5">
          <EmptyResults
            title={messages['admin.travels.empty.favorite-places.title']}
            description={
              messages['admin.travels.empty.favorite-places.description']
            }
            buttonText={messages['admin.travels.empty.favorite-places.btn']}
            className="w-70"
            onClick={() => openModal('new')}
          />
        </div>
      ) : (
        <>
          <Row>
            <Colxx xxs="12" className="mt-5">
              <Button
                color="primary"
                className="m-0 pretty-btn"
                onClick={() => {
                  openModal('new');
                }}
              >
                {messages['general.add']}
              </Button>

              <ButtonDropdown
                isOpen={dropdownSplitOpen}
                toggle={toggleSplit}
                className="float-right"
              >
                <div className="btn btn-primary btn-lg pl-4 pr-0 check-button check-all">
                  <CustomInput
                    className="custom-checkbox mb-0 d-inline-block"
                    type="checkbox"
                    id="checkAll"
                    checked={selectedItems.length >= favoritePlaces.length}
                    onChange={handleChangeSelectAll}
                    label={
                      <span
                        className={`custom-control-label ${
                          selectedItems.length > 0 &&
                          selectedItems.length < favoritePlaces.length
                            ? 'indeterminate'
                            : ''
                        }`}
                      />
                    }
                  />
                </div>
                <DropdownToggle
                  caret
                  color="primary"
                  className="dropdown-toggle-split btn-lg"
                />
                <DropdownMenu right>
                  <DropdownItem onClick={deleteFavoritePlace}>
                    <IntlMessages id="admin.policy.delete" />
                  </DropdownItem>
                </DropdownMenu>
              </ButtonDropdown>
            </Colxx>
          </Row>
          <Row className="mt-4 pb-5">
            {map(favoritePlaces, (favoritePlace, index) => {
              return (
                <FavoritePlaceCard
                  key={`favoritePlace-${index}`}
                  favoritePlace={favoritePlace}
                  selectedItems={selectedItems}
                  onCheckItem={onCheckItem}
                />
              );
            })}
          </Row>
        </>
      )}

      {resourceId && (
        <FavoritePlaceModal
          toggleModal={closeModal}
          favoritePlaceId={resourceId}
          coords={favoritePlaces}
          savePlace={savePlace}
          onDelete={() => deleteSinglePlace(resourceId)}
        />
      )}
    </div>
  );
}
