export const hexToRgb = (hex = '') => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

export const colorBrightness = (value) => {
  if (!value) return 255;

  const { r, g, b } = value;
  return (r + r + b + g + g + g) / 6;
};
