import React, { useEffect } from 'react';
import { useMemo } from 'react';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Row } from 'reactstrap';
import { expenseTypes } from '../../../constants/refundTypes';
import {
  calculateTotal,
  createBudget,
  formatKmBudgetValues,
  getItemBudgetValues,
  modifyBudgetItem,
  turnRouteIntoItems,
} from '../../../utils/budget';
import { createRouteStop } from '../../../utils/expenses';
import { stringToMoney } from '../../../utils/money';
import { getOrganizationConfig } from '../../../utils/organization';
import { Colxx } from '../../common/CustomBootstrap';
import RouteMap from '../../common/RouteMap';
import ExpenseKmContent from '../../expensesTimeline/ExpenseKmContent';
import ExpenseKmParams from '../../expensesTimeline/ExpenseKmParams';
import ExpenseBottom from './ExpenseBottom';

import firebaseApp from '../../../services/Firebase';

export default function KmExpense({
  budget,
  item,
  toggle,
  isNew,
  performAction,
  editable,
  updateTotal,
  atBottom,
}) {
  const { passenger, organization } = useSelector(({ auth, timeline }) => ({
    organization: auth.organization,
    passenger: timeline.passenger || auth.user,
  }));

  const { formatMessage } = useIntl();

  const [perKm, perKmFormatted] = useMemo(() => {
    const perKm =
      item.fare?.perKm ||
      getOrganizationConfig(organization.config, 'refunds.perKm');
    return [perKm, stringToMoney(perKm, 2, budget.fare.currency)];
  }, [item, organization]);

  const [values, setValues] = useState(
    isNew ? { name: '' } : getItemBudgetValues(item)
  );
  const [itemType, setItemType] = useState(expenseTypes.ITEMS);
  const [stops, setStops] = useState(item.route ? item.route.stops : []);
  const [selectedRoute, setSelectedRoute] = useState(item.route || null);
  const [items, setItems] = useState([]);
  const [paddingBottom, setPaddingBottom] = useState(0);

  const total = useMemo(
    () => (selectedRoute ? selectedRoute.distance * perKm : 0),
    [selectedRoute, perKm]
  );

  const isValuesValid = useMemo(() => values.name && values.date, [values]);
  const isValid = useMemo(
    () => Boolean(isValuesValid && selectedRoute),
    [isValuesValid, selectedRoute]
  );

  const numStopsText = useMemo(
    () =>
      editable
        ? ''
        : formatMessage(
            {
              id: 'refund.km-expense.info',
            },
            {
              stops: stops.length,
            }
          ),
    [stops, editable]
  );

  // Effects
  useEffect(() => {
    if (stops.length === 0) {
      setStops([createRouteStop(), createRouteStop()]);
    }
  }, []);

  useEffect(() => {
    if (
      stops.some((stop) => !stop.address) ||
      (stops.length < 2 && selectedRoute)
    ) {
      setSelectedRoute(null);
    }
  }, [stops]);

  // Functions
  const handleCreateStop = (route, index) => {
    if (!stops.some((stop) => stop.address === route.address)) {
      if (index !== undefined) {
        const newStops = [...stops];
        newStops[index] = createRouteStop(route);
        setStops(newStops);
      } else {
        setStops([...stops, createRouteStop(route)]);
      }
    }
  };

  const handleDeleteStop = (index) => {
    if (index !== undefined) {
      const newStops = [...stops];
      newStops.splice(index, 1);
      setStops(newStops);
    }
  };

  const handleSwapItem = (index, otherIndex) => {
    const newStops = [...stops];
    const item = newStops[index];
    newStops[index] = newStops[otherIndex];
    newStops[otherIndex] = item;

    setStops(newStops);
  };

  const handleSelectedRoute = (route, stops, polyline) => {
    setSelectedRoute({
      stops,
      distance: item.route
        ? item?.route?.distance
        : Math.ceil(route.distance / 1000),
      duration: item.route ? item?.route?.duration : route.duration / 3600,
    });

    calculateTolls(polyline);
  };

  const calculateTolls = async (polyline) => {
    const tollsResponse = await firebaseApp.getTollsFromPolyline(polyline);

    setSelectedRoute((prevState) => {
      return { ...prevState, tolls: tollsResponse.tolls };
    });
  };

  const doCreateBudgetItem = () => {
    const items = turnRouteIntoItems(selectedRoute, perKm);

    performAction(() =>
      createBudget({
        budgetId: budget.id,
        user: passenger,
        values: formatKmBudgetValues(values),
        items,
        type: expenseTypes.KM,
        additional: {
          route: selectedRoute,
        },
        fareAdditional: {
          perKm,
        },
      })
    );

    updateTotal(calculateTotal(items));
  };

  const doModifyBudgetItem = () => {
    const items = turnRouteIntoItems(selectedRoute, perKm);

    performAction(() =>
      modifyBudgetItem({
        values: formatKmBudgetValues(values),
        items,
        additional: { route: selectedRoute },
        budgetId: budget.id,
        budgetItemId: item.id,
      })
    );

    updateTotal(calculateTotal(items) - item.fare.total);
  };

  const onCreate = () =>
    item?.id ? doModifyBudgetItem() : doCreateBudgetItem();

  // Render
  const renderTotalAddional = () => {
    return (
      <p className="m-0 p-0 text-primary per-km mt-1">({perKmFormatted}/km)</p>
    );
  };

  const renderInfo = () => {
    return <p className="m-0 p-0 w-40 info">{numStopsText}</p>;
  };

  return (
    <>
      <div className="km-expense" style={{ paddingBottom }}>
        <Row className="bg-secondary-light h-auto p-0 m-0 w-100">
          <Colxx xxs="5" className="p-0">
            <RouteMap
              stops={stops}
              setSelectedRoute={handleSelectedRoute}
              editable={false}
            />
          </Colxx>
          <Colxx xss="7">
            <ExpenseKmParams
              user={passenger}
              editable={editable}
              values={values}
              setValues={setValues}
              expenseType={itemType}
              setExpenseType={setItemType}
              isNew={true}
              budgetItem={item}
              budget={budget}
            />
          </Colxx>
        </Row>

        <ExpenseKmContent
          items={items}
          setItems={setItems}
          addNewItem={() => {}}
          stops={stops}
          setStops={setStops}
          onStopCreation={handleCreateStop}
          hasRejectedItems={false}
          editable={editable}
          selectedRoute={selectedRoute}
          onStopDelete={handleDeleteStop}
          onSwapItem={handleSwapItem}
        />
      </div>

      <ExpenseBottom
        budget={budget}
        total={total}
        onCancel={toggle}
        onCreate={onCreate}
        canCreate={isValid}
        isNew={isNew}
        TotalAdditionalComponent={renderTotalAddional}
        setPaddingBottom={setPaddingBottom}
        editable={editable}
        atBottom={atBottom}
        InfoComponent={renderInfo}
        multiplier={1.5}
      />
    </>
  );
}
