import React, { useEffect, useMemo, useRef } from 'react';
import NumberedComponent from '../../../NumberedComponent';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { getAuthRedux } from '../../../../utils/redux';
import { attachLink } from '../../../../utils/link';
import { getOrganizationConfig } from '../../../../utils/organization';
import { OpenBookingProvidersWebsites } from '../../../../constants/openBookingProviders';
import ReceiptFileHandler from '../../../expenses/ReceiptFileHandler';
import { uploadBudgetItemReceipts } from '../../../../utils/budget';
import appFunctions from '../../../../services/Functions';
import useExecuteAction from '../../../../hooks/useExecuteAction';

export default function FinalStep({ order }) {
  const { organization } = useSelector(getAuthRedux);
  const { messages } = useIntl();

  // Hooks
  const { executeAction, loading } = useExecuteAction();

  // States
  const firstTitle = useMemo(() => {
    return messages[
      getOrganizationConfig(organization.config, 'openBooking.requireReceipt')
        ? 'open-booking.final-steps.generate-receipt.required.title'
        : 'open-booking.final-steps.generate-receipt.title'
    ];
  }, [order, organization]);

  // Effects
  useEffect(() => {
    attachLink(
      document.getElementById('final-step-provider-link'),
      OpenBookingProvidersWebsites[order.provider.associate]
    );
  }, [order]);

  // Functions
  const uploadReceipt = async (file) => {
    try {
      await uploadBudgetItemReceipts(
        order.id,
        order.openBookingInfo.budgetItemId,
        file
      );
    } catch (err) {
      console.error('Unable to upload receipt', err);
      throw err;
    }
  };

  const deleteReceipt = () =>
    executeAction(
      (async () => {
        try {
          await appFunctions.deleteReceipts(
            order.id,
            order.openBookingInfo.budgetItemId
          );
        } catch (err) {
          console.error('Unable to delete receipt');
        }
      })()
    );

  // Render
  return (
    <div className="final-step">
      <NumberedComponent index="1">
        <p
          className="m-0 p-0 fs-0-8rem"
          dangerouslySetInnerHTML={{
            __html: firstTitle,
          }}
        />
      </NumberedComponent>

      <NumberedComponent index="2" className="mt-4">
        <p className="m-0 p-0 fs-0-8rem">
          {messages['open-booking.final-steps.attach-receipt.title']}
        </p>
        <div className="flex-center pt-3">
          <ReceiptFileHandler
            className="receipt-uploader"
            receipts={order.openBookingInfo?.receipts}
            budgetId={order.id}
            budgetItemId={order.openBookingInfo?.budgetItemId}
            editable={true}
            canDoAction={!loading}
            onUpload={uploadReceipt}
            onDelete={deleteReceipt}
            white
            dragMessage={messages['open-booking.final-steps.receipt.drag']}
            dropMessage={messages['open-booking.final-steps.receipt.drop']}
          />
        </div>
      </NumberedComponent>
    </div>
  );
}
