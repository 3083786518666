import React from 'react';

export default function CustomErrorMessage(props) {
  const { error = false, children, style = {} } = props;
  return (
    error && (
      <div
        className="custom-error-message invalid-feedback d-block"
        style={style}
      >
        {children}
      </div>
    )
  );
}
