import React from 'react';
import GlideComponent from '../carousel/GlideComponent';
import map from 'lodash/map';

export default function HotelPhotos({ hotelId, hotel, room }) {
  let mainImage = '/assets/img/default-hotel-bw.png';
  if (hotel.mainPhoto !== '') mainImage = hotel.mainPhoto;

  const roomHasPhotos = room && (room.info?.photos || []).length;
  const photos = roomHasPhotos ? room.info.photos : hotel.photos;
  const currentId = roomHasPhotos ? room.id : hotelId;

  return (
    <>
      <GlideComponent
        settings={{
          gap: 1,
          perView: 1,
          type: 'carousel',
          breakpoints: {
            600: { perView: 1 },
            1400: { perView: 1 },
          },
          hideNav: true,
          reservationNav: true,
        }}
        key={currentId}
      >
        {photos.length === 0 && (
          <div
            className="image-slide"
            style={{
              backgroundImage: `url(${mainImage})`,
            }}
          />
        )}

        {map(photos, (photo, index) => {
          return (
            <div
              key={`photo-${currentId}-photo-${index}`}
              className="image-slide"
              style={{
                backgroundImage: `url(${photo})`,
              }}
            />
          );
        })}
      </GlideComponent>
      {roomHasPhotos && (
        <div className="room-name" key={`room-${currentId}`}>
          {room.description}
        </div>
      )}
    </>
  );
}
