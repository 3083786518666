import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { Row, Label, CustomInput } from 'reactstrap';

import IntlMessages from '../../helpers/IntlMessages';
import CityLocationSearch from '../search/CityLocationSearch';
import DateSearch from '../search/DateSearch';
import { loadCategories } from '../../utils/categories';
import { useIntl } from 'react-intl';
import { Colxx } from '../common/CustomBootstrap';
import FixedSelect from '../common/FixedSelect';
import { EditModes } from '../../constants/editModes';
import AutoHeightTextArea from '../common/AutoHeightTextArea';

export default function ExpenseItemsParams({
  values = {},
  setValues = () => {},
  editable,
  budget,
  invoice,
  editMode,
}) {
  const { messages } = useIntl();

  const [categories, setCategories] = useState([]);

  const [minDate, maxDate] = useMemo(() => {
    if (budget) {
      return [
        moment(budget.startDate, 'YYYY-MM-DD'),
        moment(budget.endDate, 'YYYY-MM-DD'),
      ];
    } else return [null, null];
  }, [budget]);

  // Effects
  useEffect(() => {
    if (editable || editMode !== EditModes.NONE) {
      loadCategories(budget.organizationId).then((categories) => {
        setCategories(categories);
      });
    }
  }, []);

  // Functions
  const handleValueChange = (name, value) => {
    setValues({
      ...values,
      [name]: value,
    });
  };

  // Render
  const renderEditable = () => (
    <>
      <Row>
        <Label className="form-group search has-top-label float-left w-100">
          <CustomInput
            id="refund-param-name"
            type="text"
            placeholder=""
            value={values.name}
            onChange={(e) => handleValueChange('name', e.target.value)}
            className="form-input mt-4 w-100 refund-name"
            maxLength={50}
          />
          <span>
            <IntlMessages id="refund.modal.name" />
          </span>
        </Label>
      </Row>
      <Row>
        <Label className="form-group disabled search has-top-label w-100">
          <CityLocationSearch
            value={values.location}
            setValue={(e) => handleValueChange('location', e)}
            readOnly={invoice}
          />
          <span>
            <IntlMessages id="refund.modal.location" />
          </span>
        </Label>
      </Row>
      <Row className="align-items-end">
        <Colxx xxs="6">
          <Label
            className="form-group disabled search has-top-label"
            onClick={(e) => e.preventDefault()}
          >
            <DateSearch
              className="refund-date bg-secondary-light"
              selectedDate={values.date}
              setValue={(e) => handleValueChange('date', e)}
              isStart
              defaultMinDate={minDate}
              defaultMaxDate={maxDate}
              disabled={invoice}
            />
            <IntlMessages id="refund.modal.date" />
          </Label>
        </Colxx>
        <Colxx xxs="6">
          <Label className="form-group disabled search has-top-label">
            <FixedSelect
              className="react-select mt-1 w-100"
              classNamePrefix="react-select"
              isClearable={false}
              placeholder=""
              value={values.category}
              noOptionsMessage={() => {
                return messages['refund.placeholder.no-categories'];
              }}
              options={categories}
              onChange={(category) => handleValueChange('category', category)}
              maxMenuHeight={150}
            />
            <span>
              <IntlMessages id="refund.modal.category" />
            </span>
          </Label>
        </Colxx>
      </Row>
      <Row>
        <Label className="form-group disabled search has-top-label w-100 mb-2">
          <AutoHeightTextArea
            className="w-100 form-input"
            value={values.description}
            onChange={(e) => handleValueChange('description', e.target.value)}
            rows={1}
          />

          <span>
            <IntlMessages id="refund.modal.description" />
          </span>
        </Label>
      </Row>
    </>
  );

  const renderReadOnly = () => (
    <>
      <Row>
        <div className="col-half">
          <Label className="form-group disabled search has-top-label">
            <p className="text-uppercase">{values.name}</p>
            <IntlMessages id="refund.modal.name" />
          </Label>
        </div>
        <div className="col-half">
          <Label className="form-group disabled search has-top-label">
            <p className="text-uppercase">{values.location?.label}</p>
            <IntlMessages id="refund.modal.location" />
          </Label>
        </div>
      </Row>
      <Row>
        <div className="col-half">
          <Label className="form-group disabled search has-top-label">
            <p className="text-uppercase">{values.category?.label || '-'}</p>
            <IntlMessages id="refund.modal.category" />
          </Label>
        </div>
        <div className="col-half">
          <Label className="form-group disabled search has-top-label">
            <p className="text-uppercase">
              {moment(values.date).format('DD/MM/YYYY')}
            </p>
            <IntlMessages id="refund.modal.date" />
          </Label>
        </div>
      </Row>
      {values.description ? (
        <Row>
          <Label className="form-group disabled search has-top-label">
            <p className="text-uppercase">{values.description}</p>
            <IntlMessages id="refund.modal.description" />
          </Label>
        </Row>
      ) : null}
    </>
  );

  return (
    <div className="reservation-params refund-params expense-items-params">
      {editMode === EditModes.NONE ? renderReadOnly() : renderEditable()}
    </div>
  );
}
