import React, { useMemo } from 'react';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { Label, Row } from 'reactstrap';
import { formatBarcode } from '../../../../utils/administrativeCards';
import { convertToFloat, stringToMoney } from '../../../../utils/money';
import { Colxx } from '../../../common/CustomBootstrap';

export default function BoletoPaymentInfo({ item }) {
  const { messages } = useIntl();

  // States
  const {
    barcode,
    payee,
    payeeDocument,
    issuer,
    dueDate,
    amount,
    discountAmount,
  } = useMemo(() => {
    if (!item) return {};

    const {
      barcode,
      payee,
      payeeDocument,
      issuer,
      dueDate,
      amount,
      updatedAmount,
    } = item.payment.info;

    return {
      barcode: formatBarcode(barcode),
      payee,
      payeeDocument,
      issuer,
      dueDate: moment(dueDate, 'YYYY-MM-DD').format('DD/MM/YYYY'),
      amount: stringToMoney(convertToFloat(amount), 2),
      discountAmount:
        amount !== updatedAmount
          ? stringToMoney(convertToFloat(updatedAmount), 2)
          : null,
    };
  }, [item]);

  // Render
  return (
    <>
      <div className="mt-4 mb-2 ml-2">
        <h5>{messages['administrative-expense-item.payment-info']}</h5>
      </div>

      <div className="reservation-params refund-params light">
        <Row>
          <Colxx sm="12">
            <Label className="form-group disabled search has-top-label">
              <p className="text-uppercase">{barcode}</p>
              <span>{messages['administrative.boleto.number']}</span>
            </Label>
          </Colxx>
        </Row>
        <Row>
          {payeeDocument ? (
            <>
              <Colxx md="6" sm="12">
                <Label className="form-group disabled search has-top-label">
                  <p className="text-uppercase">{payee}</p>
                  <span>{messages['administrative.boleto.payee']}</span>
                </Label>
              </Colxx>
              <Colxx md="6" sm="12">
                <Label className="form-group disabled search has-top-label">
                  <p className="text-uppercase">{payeeDocument}</p>
                  <span>{messages['administrative.boleto.cnpj']}</span>
                </Label>
              </Colxx>
            </>
          ) : (
            <Colxx sm="12">
              <Label className="form-group disabled search has-top-label">
                <p className="text-uppercase">{payee}</p>
                <span>{messages['administrative.boleto.payee']}</span>
              </Label>
            </Colxx>
          )}
        </Row>
        <Row>
          <Colxx md="6" sm="12">
            <Label className="form-group disabled search has-top-label">
              <p className="text-uppercase">{issuer}</p>
              <span>{messages['administrative.boleto.issuer']}</span>
            </Label>
          </Colxx>
          <Colxx md="6" sm="12">
            <Label className="form-group disabled search has-top-label">
              <p className="text-uppercase">{dueDate}</p>
              <span>{messages['administrative.boleto.due-date']}</span>
            </Label>
          </Colxx>
        </Row>
        <Row>
          {discountAmount ? (
            <>
              <Colxx md="6" sm="12">
                <Label className="form-group disabled search has-top-label">
                  <p className="text-uppercase">{amount}</p>
                  <span>{messages['administrative.boleto.amount']}</span>
                </Label>
              </Colxx>
              <Colxx md="6" sm="12">
                <Label className="form-group disabled search has-top-label">
                  <p className="text-uppercase">{discountAmount}</p>
                  <span>
                    {messages['administrative.boleto.updated-amount']}
                  </span>
                </Label>
              </Colxx>
            </>
          ) : (
            <Colxx sm="12">
              <Label className="form-group disabled search has-top-label">
                <p className="text-uppercase">{amount}</p>
                <span>{messages['administrative.boleto.amount']}</span>
              </Label>
            </Colxx>
          )}
        </Row>
      </div>
    </>
  );
}
