import moment from 'moment';

import firebaseApp from '../../../services/Firebase';

import { analyzeReservationParams } from '../../../utils/reservation';
import { trackPropertiesSegment } from '../../../utils/segment';

export async function hotelBookingService(
  item,
  search,
  user,
  reservationParams,
  room,
  suggestionHotel = false
) {
  const newReservationParams = await analyzeReservationParams(
    reservationParams
  );

  const orderDetails = {
    ...newReservationParams,
    fare: {
      hasBreakfast: room.board.breakfast,
      issueUntil: null,
      total: room.fare,
      currency: room.currency,
    },
    provider: {
      origin: item.origin,
      token: item.token,
    },
    searchCode: search.searchCode,
    suggestionHotel,
  };

  const cancellation = room.cancellation;
  delete room.cancellation;

  const reservation = {
    hotel: item.hotel,
    hotelId: item.hotelId,
    selectedRoom: room,
    cancellation,
    checkIn: moment(search.outbound_date).format('DD/MM/YYYY'),
    checkOut: moment(search.inbound_date).format('DD/MM/YYYY'),
    confirmation: '',
  };

  trackPropertiesSegment('New Reservation', {
    ...orderDetails,
    type: 'hotel',
  });

  await firebaseApp.createHotelReservation({
    organizationId: user.organizationId,
    passenger: newReservationParams.passenger,
    passengerId: newReservationParams.passengerId,
    orderDetails,
    reservations: [reservation],
    filterTimestamp: moment(reservation.checkIn, 'DD/MM/YYYY')
      .endOf('day')
      .utc()
      .unix(),
    type: 'hotel',
  });
}
