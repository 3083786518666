import { PopupModalPositions } from './consts';

export function getHalves({ width, height }) {
  return { width: Math.trunc(width / 2), height: Math.trunc(height / 2) };
}

export function getCenterFromBounding({ top, left, width, height }) {
  const halves = getHalves({ width, height });
  return {
    top: Math.trunc(top + halves.height),
    left: Math.trunc(left + halves.width),
  };
}

export function getShowStyle({
  position,
  scale = 1,
  targetBounding,
  windowSize,
  componentSize,
  displacementX,
  displacementY,
}) {
  switch (position) {
    case PopupModalPositions.CENTER: {
      const popupSizeHalves = getHalves(componentSize);
      const screenHalves = getHalves(windowSize);

      return {
        transform: `translate(${Math.trunc(
          screenHalves.width - popupSizeHalves.width + displacementX
        )}px, ${Math.trunc(
          screenHalves.height - popupSizeHalves.height + displacementY
        )}px) scale(${scale})`,
      };
    }
    case PopupModalPositions.LEFT: {
      const popupSizeHalves = getHalves(componentSize);

      return {
        transform: `translate(${Math.trunc(
          Math.max(targetBounding.left - componentSize.width + displacementX, 0)
        )}px, ${Math.trunc(
          Math.max(
            targetBounding.top -
              popupSizeHalves.height +
              targetBounding.height / 2 +
              displacementY,
            0
          )
        )}px) scale(${scale})`,
      };
    }
    case PopupModalPositions.RIGHT: {
      return {
        transform: `translate(${targetBounding.left + displacementX}px, ${
          targetBounding.top + displacementY
        }px) scale(${scale})`,
      };
    }
    default:
      return {};
  }
}

export function getHideStyle({
  position,
  scale = 0,
  targetBounding,
  windowSize,
  componentSize,
}) {
  switch (position) {
    case PopupModalPositions.CENTER: {
      const popupSizeHalves = getHalves(componentSize);
      const targetBoundingCenter = getCenterFromBounding(targetBounding);

      return {
        transform: `translate(${Math.trunc(
          targetBoundingCenter.left - popupSizeHalves.width
        )}px, ${Math.trunc(
          targetBoundingCenter.top - popupSizeHalves.height
        )}px) scale(${scale})`,
      };
    }
    case PopupModalPositions.LEFT: {
      return {
        transform: `translate(${Math.trunc(
          targetBounding.left - componentSize.width
        )}px, ${Math.trunc(
          targetBounding.top - componentSize.height
        )}px) scale(${scale})`,
      };
    }
    case PopupModalPositions.RIGHT: {
      return {
        transform: `translate(${targetBounding.left}px, ${targetBounding.top}px) scale(0)`,
      };
    }
    default:
      return {};
  }
}
