import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import firebaseApp from '../services/Firebase';
import { getOrganizationConfig } from '../utils/organization';

export default function useToggleRequiredField({
  name,
  items = [],
  loading = true,
}) {
  const { organization } = useSelector(({ auth }) => auth);

  // States
  const requiredFields = useMemo(
    () => getOrganizationConfig(organization.config, 'required'),
    [organization]
  );

  // Effects
  useEffect(() => {
    if (!loading && items.length === 0) revokeRequirement();
  }, [items, loading]);

  // Functions
  const revokeRequirement = async () => {
    if (requiredFields[name]) {
      await firebaseApp.updateConfigField(
        organization.id,
        `config.required.${name}`,
        false
      );
    }
  };

  return null;
}
