import moment from 'moment';
import React from 'react';
import { Row, Label, CustomInput } from 'reactstrap';

import IntlMessages from '../../helpers/IntlMessages';
import DateSearch from '../search/DateSearch';
import { BudgetParams } from './BudgetParams';

export default function ExpenseKmParams({
  values = {},
  setValues = () => {},
  editable,
  budgetItem = {},
  budget,
}) {
  const handleValueChange = (name, value) => {
    setValues({
      ...values,
      [name]: value,
    });
  };

  const startDate = moment(budget.startDate, 'YYYY-MM-DD');
  const endDate = moment(budget.endDate, 'YYYY-MM-DD');

  if (editable) {
    return (
      <div className="reservation-params refund-params pl-3 pb-3 pr-3">
        <Row>
          <Label className="form-group search has-top-label float-left w-100 mb-1">
            <CustomInput
              id="refund-param-name"
              type="text"
              placeholder=""
              value={values.name}
              onChange={(e) => handleValueChange('name', e.target.value)}
              className="form-input mt-4 w-100 refund-name"
              maxLength={50}
            />
            <span>
              <IntlMessages id="refund.modal.name" />
            </span>
          </Label>
        </Row>
        <Row className="align-items-end">
          <Label
            className="form-group disabled search has-top-label w-100"
            onClick={(e) => e.preventDefault()}
          >
            <DateSearch
              className="refund-date"
              selectedDate={values.date}
              setValue={(e) => handleValueChange('date', e)}
              isStart
              defaultMinDate={startDate}
              defaultMaxDate={endDate}
            />
            <IntlMessages id="refund.modal.date" />
          </Label>
        </Row>
      </div>
    );
  } else {
    return (
      <div className="reservation-params pl-3">
        <Row>
          <div className="col-half">
            <Label className="form-group disabled search has-top-label">
              <p className="text-uppercase">{budgetItem.name}</p>
              <IntlMessages id="refund.modal.name" />
            </Label>
          </div>
          <div className="col-half">
            <Label className="form-group disabled search has-top-label">
              <p className="text-uppercase">
                {moment(budgetItem.date).format('DD/MM/YYYY')}
              </p>
              <IntlMessages id="refund.modal.date" />
            </Label>
          </div>
        </Row>
      </div>
    );
  }
}
