import React from 'react';
import { useIntl } from 'react-intl';
import { Button } from 'reactstrap';
import AlterationFrame from '../AlterationFrame';
import OptionCard from '../FlightAlteration/OptionCard';
import { TABS } from '.';

import { ReactComponent as CancelIcon } from '../../../../assets/img/icons/wallet.svg';
import { ReactComponent as CalendarIcon } from '../../../../assets/img/icons/calendar-add.svg';

export default function InitialTab({
  order,
  canCancel,
  goForAlteration,
  setCurrentTab,
  canAlter,
}) {
  const { messages } = useIntl();

  // Render
  const renderBottom = () => (
    <div className="ml-3 text-right btns">
      <Button color="link" onClick={goForAlteration} className="mb-0">
        {messages['general.go-back']}
      </Button>
    </div>
  );

  return (
    <AlterationFrame
      title={messages['travel.alter.title']}
      subtitle={messages['travel.alter.subtitle']}
      BottomBar={renderBottom}
    >
      <div className="card-menu-display">
        {canAlter ? (
          <OptionCard
            Icon={CalendarIcon}
            title={messages['travel.alter.hotel.increase-days.title']}
            description={
              messages['travel.alter.hotel.increase-days.description']
            }
            buttonText={messages['general.select']}
            onClick={() => setCurrentTab(TABS.INCREASE_DAYS)}
            className="show-up"
            style={{ animationDelay: '150ms' }}
          />
        ) : null}
        {canCancel ? (
          <OptionCard
            Icon={CancelIcon}
            title={messages['travel.cancel.title']}
            description={messages['travel.cancel.description']}
            className="show-up"
            style={{ animationDelay: '250ms' }}
            buttonText={messages['general.select']}
            onClick={() => setCurrentTab(TABS.CANCEL)}
            disabled={order.ongoingAction}
          />
        ) : null}
      </div>
    </AlterationFrame>
  );
}
