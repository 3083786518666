import React from 'react';
import map from 'lodash/map';

import { amenities } from '../../constants/offices';

export default function OfficeAmenities({ officeId, office }) {
  return (
    <ul className="amenities">
      {map(office.amenities, (amenity, index) => {
        if (!amenities[amenity] || index > 8) return;

        return (
          <li key={`hotel-${officeId}-amenity-${index}`}>
            <img
              src={`/assets/img/icons/amenities/${amenities[amenity]}`}
              alt={amenity}
              title={amenity}
            />
          </li>
        );
      })}
    </ul>
  );
}
