import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import {
  Card,
  Label,
  CustomInput,
  Row,
  Button,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { Colxx } from '../../components/common/CustomBootstrap';
import classnames from 'classnames';
import map from 'lodash/map';
import mouseTrap from 'mousetrap';

import IntlMessages from '../../helpers/IntlMessages';

import { useIntl } from 'react-intl';
import { trackPropertiesSegment } from '../../utils/segment';

import firebaseApp from '../../services/Firebase';

import ApiKeyModal from '../../components/integrations/ApiKeyModal';
import EmptyResults from '../../components/common/EmptyResults';
import PolicyLoading from '../policy/PolicyLoading';
import User from '../../services/User';

let loadapiKeysEvent = () => {};

export default function apiKeysDataListView() {
  const { resourceId } = useParams();
  const history = useHistory();
  const { messages } = useIntl();

  const [selectedItems, setSelectedItems] = useState([]);

  const [apiKeys, setapiKeys] = useState([]);
  const [dropdownSplitOpen, setDropdownSplitOpen] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const isEmpty = useMemo(() => !apiKeys || apiKeys.length === 0, [apiKeys]);

  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    loadapiKeys();

    return () => {
      loadapiKeysEvent();
    };
  }, []);

  useEffect(() => {
    mouseTrap.bind(['ctrl+a', 'command+a'], () => {
      setSelectedItems(map(apiKeys, (x) => x.uid));
      return false;
    });

    mouseTrap.bind(['ctrl+d', 'command+d'], () => {
      setSelectedItems([]);
      return false;
    });

    return () => {
      mouseTrap.unbind('ctrl+a');
      mouseTrap.unbind('command+a');
      mouseTrap.unbind('ctrl+d');
      mouseTrap.unbind('command+d');
    };
  }, [apiKeys]);

  // Handle Cost Centers
  const loadapiKeys = async () => {
    loadapiKeysEvent = firebaseApp
      .getApiKeysFromOrganization(user.organizationId)
      .onSnapshot((apiKeysSnap) => {
        const newapiKeys = [];
        apiKeysSnap.forEach((apiKeysnap) => {
          newapiKeys.push(apiKeysnap.data());
        });

        setapiKeys(newapiKeys);
        setLoading(false);
      });
  };

  const deleteApiKeys = async () => {
    await Promise.all(selectedItems.map((userId) => User.deleteUser(userId)));

    trackPropertiesSegment('API Key deleted', {
      apiKeys: selectedItems,
      user,
    });

    setSelectedItems([]);
  };

  // Split
  const toggleSplit = () => {
    setDropdownSplitOpen(!dropdownSplitOpen);
  };

  // Selection
  const onCheckItem = (id) => {
    if (selectedItems.includes(id)) {
      setSelectedItems(selectedItems.filter((x) => x !== id));
    } else {
      setSelectedItems([...selectedItems, id]);
    }
  };

  const handleChangeSelectAll = () => {
    if (selectedItems.length >= apiKeys.length) {
      setSelectedItems([]);
    } else {
      setSelectedItems(map(apiKeys, (x) => x.uid));
    }
  };

  // Modal
  const openModal = (ruleId) => {
    trackPropertiesSegment('Api Key modal opened', { user });
    history.push(`/app/integrations/api_keys/${ruleId}`);
  };

  const closeModal = () => {
    trackPropertiesSegment('Api Key model closed', { user });
    history.push('/app/integrations/api_keys');
  };

  // Render
  if (isLoading) {
    return <PolicyLoading />;
  }

  return (
    <div>
      {isEmpty ? (
        <div className="w-100 d-flex justify-content-center mt-5">
          <EmptyResults
            title={messages['admin.integrations.empty.api-key.title']}
            description={
              messages['admin.integrations.empty.api-key.description']
            }
            buttonText={messages['admin.integrations.empty.api-key.btn']}
            className="w-70"
            onClick={() => openModal('new')}
          />
        </div>
      ) : (
        <>
          <Row>
            <Colxx xxs="12" className="mt-5">
              <Button
                color="primary"
                className="m-0 pretty-btn"
                onClick={() => {
                  openModal('new');
                }}
              >
                {messages['admin.integrations.apiKey.Add']}
              </Button>

              <ButtonDropdown
                isOpen={dropdownSplitOpen}
                toggle={toggleSplit}
                className="float-right"
              >
                <div className="btn btn-primary btn-lg pl-3 pr-0 check-button check-all">
                  <CustomInput
                    className="custom-checkbox mb-0 d-inline-block"
                    type="checkbox"
                    id="checkAll"
                    checked={selectedItems.length >= apiKeys.length}
                    onChange={handleChangeSelectAll}
                    label={
                      <span
                        className={`custom-control-label ${
                          selectedItems.length > 0 &&
                          selectedItems.length < apiKeys.length
                            ? 'indeterminate'
                            : ''
                        }`}
                      />
                    }
                  />
                </div>
                <DropdownToggle
                  caret
                  color="primary"
                  className="dropdown-toggle-split btn-lg"
                />
                <DropdownMenu right style={{ width: 180 }}>
                  <DropdownItem onClick={deleteApiKeys}>
                    <IntlMessages id="admin.policy.delete" />
                  </DropdownItem>
                </DropdownMenu>
              </ButtonDropdown>
            </Colxx>
          </Row>
          <Row className="mt-4">
            {map(apiKeys, (apiKey, index) => {
              return (
                <Colxx key={`apiKey-${index}`} xxs="12" className="mb-3">
                  <Card
                    className={classnames('d-flex flex-row list', {
                      active: selectedItems.includes(apiKey.uid),
                    })}
                  >
                    <div className="pl-2 d-flex flex-grow-1 min-width-zero">
                      <div className="card-body align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero align-items-lg-center">
                        <div className="w-40 w-sm-100">
                          <p className="list-item-heading mb-1 truncate">
                            {apiKey.name}
                          </p>
                        </div>
                        <p className="mb-1 text-muted text-small w-15 w-sm-100">
                          {apiKey.client_id}
                        </p>
                        <div className="w-15 w-sm-100"></div>
                      </div>
                      <div className="custom-control custom-checkbox pl-1 align-self-center pr-4">
                        <Label>
                          <CustomInput
                            className="item-check mb-0"
                            type="checkbox"
                            id={`check_${apiKey.uid}`}
                            checked={selectedItems.includes(apiKey.uid)}
                            onChange={() => onCheckItem(apiKey.uid)}
                          />
                        </Label>
                      </div>
                    </div>
                  </Card>
                </Colxx>
              );
            })}
          </Row>
        </>
      )}

      {resourceId && <ApiKeyModal toggleModal={closeModal} />}
    </div>
  );
}
