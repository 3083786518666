import React, { useEffect, useMemo, useRef, useState } from 'react';

import SearchHotelAlternative from './SearchHotelAlternative';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import useWindowSize from '../../../hooks/useWindowSize';
import { OrderTypes } from 'src/portao3-legacy/constants/orderTypes';

const AllAlternativeCards = {
  [OrderTypes.HOTEL]: SearchHotelAlternative,
};

export default function SearchAlternativeCards({
  alternatives,
  type,
  selectedItem,
  handleSelectHotel,
  handleCloseAlernatives,
}) {
  const divRef = useRef(null);
  const { width } = useWindowSize();

  // States
  const [slidesToShow, setSlidesToShow] = useState(1);
  const AlternativeCard = useMemo(() => AllAlternativeCards[type], [type]);

  // Effects
  useEffect(() => {
    if (divRef.current) {
      setSlidesToShow(Math.floor(divRef.current.offsetWidth / 240) || 10);
    }
  }, [width]);

  return (
    <div ref={divRef}>
      <Slider
        className="text-primary d-flex flex-column align-content-start alternative-cards"
        dots={true}
        infinite={false}
        arrows={false}
        slidesToShow={slidesToShow}
        slidesToScroll={4}
        variableWidth={true}
        dotsClass="slick-dots"
        centerMode={false}
      >
        {alternatives.map((alternative, index) => (
          <AlternativeCard
            key={`alternative-${alternative.id}`}
            alternative={alternative}
            originalTotal={selectedItem.fare}
            selectedItem={selectedItem}
            index={index}
            handleSelectHotel={handleSelectHotel}
            handleCloseAlernatives={handleCloseAlernatives}
          />
        ))}
      </Slider>
    </div>
  );
}
