import classNames from 'classnames';
import React from 'react';
import Select from 'react-select';

const customStyles = {
  control: (styles) => ({
    ...styles,
    border: 'none',
    backgroundColor: 'transparent',
    padding: '0',
    margin: '0',
    boxShadow: 'none',
  }),
  option: (provided, { isSelected }) => ({
    ...provided,
    backgroundColor: isSelected ? '#43418e' : 'white',
    '&:hover': {
      backgroundColor: isSelected ? '#43418e' : '#efeef8',
    },
  }),
  menu: (provided) => ({
    ...provided,
    marginTop: '-5px',
  }),
};

export default function LocaleSelector({
  defaultValue,
  options,
  onChange,
  className,
}) {
  return (
    <Select
      onChange={onChange}
      options={options}
      className={classNames('locale-selector', className)}
      defaultValue={defaultValue}
      styles={customStyles}
      components={{
        IndicatorSeparator: () => null,
      }}
    />
  );
}
