import React, { useEffect, useState } from 'react';
import { ValidReceiptMimeTypes } from '../../constants/budget';
import { ReceiptTypes } from '../../constants/refundTypes';
import appFunctions from '../../services/Functions';
import { formatUploadedReceiptFile } from '../../utils/file';
import FileUploader from '../common/FileUploader';
import Spinner from '../common/Spinner';
import FilePreview from '../FilePreview';
import classNames from 'classnames';

const FILE_TABS = {
  CREATION: 1,
  PREVIEW: 2,
};

export default function ReceiptFileHandler({
  receipts = [],
  budgetId,
  budgetItemId,
  editable = true,
  onUpload = () => {},
  onDelete = () => {},
  canDoAction = true,
  className,
  white,
  dropMessage,
  dragMessage,
  allowedTypesMessage,
}) {
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState(
    editable ? FILE_TABS.CREATION : FILE_TABS.PREVIEW
  );
  const [storedReceipts, setStoredReceipts] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);

  // Effects
  useEffect(() => {
    if (receipts.length > 0) {
      fetchReceipts();
    }
  }, [receipts]);

  // Functions
  const fetchReceipts = async () => {
    try {
      setLoading(true);

      const { data: receipts = [] } = await appFunctions.getReceipts(
        budgetId,
        budgetItemId
      );

      setStoredReceipts(receipts);
      setTab(FILE_TABS.PREVIEW);
      setSelectedFile(receipts[0]);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleFileUpload = async (files) => {
    try {
      if (files.length > 0) {
        const filteredFiles = files.filter(({ type }) =>
          ValidReceiptMimeTypes.includes(type)
        );

        if (!filteredFiles.length) return;

        const newFiles = filteredFiles.map((file) =>
          formatUploadedReceiptFile(file)
        );
        setTab(ReceiptTypes.PREVIEW);
        setSelectedFile(newFiles[0]);
        onUpload(newFiles);
      }
    } catch (err) {
      setTab(ReceiptTypes.CREATION);
      setSelectedFile(null);
    }
  };

  const handleDelete = async (props) => {
    try {
      const resp = await onDelete(props);
      setTab(FILE_TABS.CREATION);
      setSelectedFile(null);
      return resp;
    } catch (err) {
      console.error('Unable to delete receipt');
    }
  };

  // Render
  const renderFilePreview = () => {
    return editable && tab === FILE_TABS.CREATION ? (
      <FileUploader
        onDrop={handleFileUpload}
        white={white}
        dropMessage={dropMessage}
        dragMessage={dragMessage}
        allowedTypesMessage={allowedTypesMessage}
      />
    ) : selectedFile ? (
      <FilePreview
        src={selectedFile.src || selectedFile.previewUrl || selectedFile.url}
        className="w-100 h-100 d-flex align-items-center justify-content-center"
        selectedFile={selectedFile}
        onDelete={handleDelete}
        editable={editable}
        canDoAction={canDoAction}
        white={white}
      />
    ) : null;
  };

  const renderContent = () => {
    if (loading) return <Spinner relative style={{ width: 50, height: 50 }} />;
    else return renderFilePreview();
  };

  return (
    <div
      className={classNames(
        'refund-file-handler d-flex justify-content-center align-items-center position-relative',
        className,
        { white }
      )}
    >
      {renderContent()}
    </div>
  );
}
