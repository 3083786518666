import { OrderTypes } from '../../constants/orderTypes';
import firebaseApp from '../../services/Firebase';
import { getDocument, getDocumentsFromCollection } from '../../utils/firebase';
import orderStatus from '../../constants/orderStatus';
import { formatOrder } from '../../components/reports/filters/TypeSchemas';
import { getApprovalsFromOrderId } from '../../utils/approvals';
import approvalStatus from '../../constants/approvalStatus';

export const NEED_RESERVATION_COLLECTION = [
  OrderTypes.HOTEL,
  OrderTypes.CAR,
  OrderTypes.OFFICE,
];

export const fetchReservations = (order, getDataFromArchive) =>
  getDataFromArchive(`reservations-${order.orderId}`, () =>
    getDocumentsFromCollection(() =>
      firebaseApp
        .getReservationsFromOrderId(order.organizationId, order.orderId)
        .get()
    )
  );

export const fetchUser = (userId, getDataFromArchive) =>
  getDataFromArchive(`user-${userId}`, () =>
    getDocument(() => firebaseApp.getUserFromId(userId).get())
  ).catch(() => null);

export async function getCreditCardUsedToPayOrder(order, getDataFromArchive) {
  try {
    if (order.statusCode < orderStatus.ISSUING || !order.fulfilledAt) return {};

    const id = order.orderId;

    const { cardId, installments = 1 } = await getDataFromArchive(
      `order-cc-${id}`,
      () =>
        getDocumentsFromCollection(() =>
          firebaseApp.getLastIssueTasksFromOrder(id).get()
        ).then((tasks = []) =>
          tasks
            .sort((a, b) => a.created_at - b.created_at)
            .reduce((obj, { params = {} }) => {
              return {
                ...obj,
                ...params,
              };
            }, {})
        )
    );

    if (!cardId) return {};

    const creditCard = await getDataFromArchive(`cc-${cardId}`, () =>
      getDocument(() =>
        firebaseApp
          .getCreditCardsFromOrganizationById(order.organizationId, cardId)
          .get()
      )
    );

    return {
      creditCard,
      installments,
    };
  } catch (err) {
    console.error(err);
    return {};
  }
}

export const formatOrders = async (orders, getDataFromArchive) => {
  return Promise.all(
    orders
      .filter(
        (order) =>
          order.fulfilledAt ||
          order.statusCode === orderStatus.DENIED ||
          order.previousStatusCode
      )
      .map((order) =>
        (async () => {
          const approvals = await getDataFromArchive(
            `approvers-${order.orderId}`,
            () => getApprovalsFromOrderId(order.orderId)
          );

          order['approvers'] = approvals.map((approval) => approval.name);
          order['whoApproved'] = approvals
            .filter(({ status }) => status === approvalStatus.APPROVED)
            .map((approval) => approval.name);
          order['statusCodes'] =
            order.previousStatusCode > orderStatus.MANUAL_ISSUING
              ? [order.statusCode, order.previousStatusCode]
              : [order.statusCode];

          if (NEED_RESERVATION_COLLECTION.includes(order.type)) {
            const reservations = await fetchReservations(
              order,
              getDataFromArchive
            );
            order['reservations'] = reservations;
          }

          return {
            ...order,
            filter: formatOrder(order),
          };
        })()
      )
  );
};

export const formatToReportOrders = (orders, getDataFromArchive) =>
  Promise.all(
    orders.map((order) =>
      (async () => {
        try {
          if (!order.reservations) {
            const reservations = await fetchReservations(
              order,
              getDataFromArchive
            );
            order['reservations'] = reservations;
          }

          order.passenger = await fetchUser(
            order.passengerId,
            getDataFromArchive
          );

          if (order.requestorId !== order.passengerId) {
            order.requestor = await fetchUser(
              order.requestorId,
              getDataFromArchive
            );
          } else order.requestor = order.passenger;

          const cheapestAlternative = await getDataFromArchive(
            `alternative-${order.orderId}`,
            async () => {
              const alternatives = await getDocumentsFromCollection(() =>
                firebaseApp
                  .getOrderAlternatives(order.orderId)
                  .orderBy('fare.total')
                  .limit(1)
                  .get()
              );

              return alternatives[0];
            }
          ).catch(() => null);

          if (cheapestAlternative) {
            order.cheapestAlternative = cheapestAlternative;
          }

          order.statusHistory = await getDataFromArchive(
            `status-${order.orderId}`,
            async () =>
              getDocumentsFromCollection(() =>
                firebaseApp
                  .getStatusHistoryFromOrder(order.orderId)
                  .where('task', 'in', ['ISSUE', 'MANUAL_ISSUE'])
                  .where('processed', '==', true)
                  .get()
              )
          ).catch(() => []);

          // Handle admin issue
          if (order.statusHistory?.length && !order.whoApproved?.length) {
            const userId = order.statusHistory.find(
              ({ source, source_id }) => source === 'user' && source_id
            )?.source_id;

            if (userId) {
              const adminApprover = await fetchUser(userId, getDataFromArchive);
              if (adminApprover) {
                order.whoApproved = [
                  `${adminApprover.firstName} ${adminApprover.lastName}`,
                ];
              }
            }
          }

          const { creditCard = null, installments } =
            await getCreditCardUsedToPayOrder(order, getDataFromArchive);

          order.creditCard = creditCard;
          if (installments) order.installments = installments;

          return order;
        } catch (err) {
          console.error('Unable to format order to reports', err);
        }
      })()
    )
  );
