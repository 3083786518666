import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PhysicalCard from '../../../components/expenses/PhysicalCard';
import { CARD_OPTIONS, CARD_OPTION_TYPES } from '../../../constants/creditCard';
import { RefundTypes } from '../../../constants/refundTypes';
import useShouldInitialize from '../../../hooks/useShouldInitialize';
import firebaseApp from '../../../services/Firebase';
import useCreditCard from '../../../hooks/useCreditCard';
import { getPassengerRedux } from '../../../utils/redux';
import CardOptions from '../CardOptions/CardOptions';
import CardFunds from './CardFunds';
import DefaultCardContainer from './DefaultCardContainer/DefaultCardContainer';
import OnlyWithFunds from '../../../hoc/OnlyWithFunds';
import recordStatus from '../../../constants/recordStatus';
import ActivateCardButton from './ActivateCardButton';

export default function PhysicalCardContainer({
  card,
  goToCreateBudget,
  goToRefund,
  goToExpense,
  visible,
  onlyWithFunds,
  ...props
}) {
  const { passenger } = useSelector(getPassengerRedux);
  const history = useHistory();

  const shouldInitialize = useShouldInitialize(visible || onlyWithFunds);

  // States
  const [canArchive, setCanArchive] = useState(false);
  const [savedLastDigits, setSavedLastDigits] = useState(false);

  const { details, loading, toggleBlock, refresh, ...cardProps } =
    useCreditCard({
      organizationId: passenger.organizationId,
      cardNumber: card.id,
      detailsInitialState: {},
      shouldLoad: shouldInitialize,
      cardExternalId: card.external_id,
    });

  const improvedCard = useMemo(() => {
    let lastDigits = card.lastDigits || '';

    if (details?.number) {
      lastDigits = details.number.slice(-4);
    }

    return { ...card, lastDigits, name: '' };
  }, [details]);

  const options = useMemo(
    () => [
      {
        ...CARD_OPTIONS[CARD_OPTION_TYPES.BLOCK],
        onClick: toggleBlock,
      },
      {
        ...CARD_OPTIONS[CARD_OPTION_TYPES.PASSWORD],
        onClick: () => history.push(`/payments/cards/pin/${card.id}`),
      },
      {
        ...CARD_OPTIONS[CARD_OPTION_TYPES.ARCHIVE],
        onClick: () =>
          firebaseApp.archiveCard(
            passenger.organizationId,
            passenger.uid,
            card.id
          ),
        hide: !canArchive,
      },
    ],
    [canArchive]
  );

  const isArchived = useMemo(
    () => card.status === recordStatus.ARCHIVED,
    [card]
  );

  // Effects
  useEffect(() => {
    if (!savedLastDigits && !card?.lastDigits && details?.number) {
      const lastDigits = details.number.slice(-4);

      if (/^\d*$/.test(lastDigits)) {
        try {
          firebaseApp.addLastDigitsToCard(
            passenger.organizationId,
            card.id,
            lastDigits
          );
          setSavedLastDigits(true);
        } catch (err) {
          console.error(err);
        }
      }
    }
  }, [card, details]);

  // Render
  const renderBottom = () => {
    if (isArchived) return <ActivateCardButton className="mt-4" card={card} />;

    return (
      <>
        <CardOptions
          className="my-3"
          options={options}
          card={card}
          details={cardProps}
        />
        <CardFunds
          funds={details.balance}
          className="ml-2 align-self-start"
          loading={loading}
        />
      </>
    );
  };

  return (
    <OnlyWithFunds active={onlyWithFunds} funds={details.balance}>
      <DefaultCardContainer
        className="virtual-card-container"
        card={card}
        canCreateBudget={!isArchived}
        canCreateExpense={false}
        onCreateBudget={() =>
          goToCreateBudget(card.id, RefundTypes.PHYSICAL_CARD, card.user_id)
        }
        onRefundClick={goToRefund}
        onExpenseClick={goToExpense}
        shouldInitialize={shouldInitialize}
        passenger={passenger}
        onBudgetUpdate={refresh}
        setCanArchive={(value) => value !== canArchive && setCanArchive(value)}
        {...props}
      >
        <div className="d-flex align-items-center justify-content-center flex-column card-container">
          <PhysicalCard
            card={improvedCard}
            toggleBlock={toggleBlock}
            {...cardProps}
          />
          {renderBottom()}
        </div>
      </DefaultCardContainer>
    </OnlyWithFunds>
  );
}
