import find from 'lodash/find';
import maxBy from 'lodash/maxBy';
import moment from 'moment';

import { TFlightSearchReservation } from 'src/portao3-legacy/types/orders/flight/flightOrder';
import { TFlightReservationParams } from '../types';

function makeOrderDetails({
  reservationParams,
  item,
  selectedSegments,
  buyWithCredit,
  search,
  params,
}: TFlightReservationParams) {
  return {
    ...reservationParams,
    journeys: item.journeys.map((journey, index) => {
      const arrivalStation =
        find(params.airports, {
          iata: journey.arrivalStation,
        })?.city || journey.arrivalStation;
      const departureStation =
        find(params.airports, {
          iata: journey.departureStation,
        })?.city || journey.departureStation;

      const segment = selectedSegments[index];

      return {
        id: index,
        departureStation: {
          iataCode: journey.departureStation,
          name: departureStation,
        },
        arrivalStation: {
          iataCode: journey.arrivalStation,
          name: arrivalStation,
        },
        departureDate: journey.departureDate,
        sellKey: segment.sellKey,
      };
    }),
    provider: {
      origin: item.origin,
      source: item.source,
      validatingCarrier: item.validatingAirline,
    },
    fare: item.fare,
    buyWithCredit,
    searchCode: search.searchCode,
  };
}

function makeReservations({
  item,
  selectedSegments,
  buyWithCredit,
  params,
}: TFlightReservationParams) {
  const reservations: TFlightSearchReservation[] = [];

  item.journeys.forEach((_, index) => {
    const segment = selectedSegments[index];

    segment.flights.forEach((flight, flightIndex) => {
      const arrivalStationName =
        find(params.airports, {
          iata: flight.arrivalStation,
        })?.city || flight.arrivalStation;
      const departureStationName =
        find(params.airports, {
          iata: flight.departureStation,
        })?.city || flight.departureStation;

      reservations.push({
        baggage: '',
        bookingCode: '',
        airline: flight.airline,
        airlineOperating: flight.airlineOperating,
        validatingAirline: segment.validatingAirline,
        flightNumber: flight.flightNumber,
        departureDateTime: moment(flight.departureDateTime).format(
          'DD/MM/YYYY HH:mm'
        ),
        departureStation: {
          iataCode: flight.departureStation,
          name: departureStationName,
        },
        duration: flight.duration,
        arrivalDateTime: moment(flight.arrivalDateTime).format(
          'DD/MM/YYYY HH:mm'
        ),
        arrivalStation: {
          iataCode: flight.arrivalStation,
          name: arrivalStationName,
        },
        fare: flight.fare,
        journeyId: index,
        buyWithCredit,
        flightIndex,
      });
    });
  });

  return reservations;
}

export async function makeFlightParams(params: TFlightReservationParams) {
  const orderDetails = makeOrderDetails(params);

  const reservations = makeReservations(params);

  const latestReservation = maxBy(reservations, (r) => {
    return moment(r.arrivalDateTime, 'DD/MM/YYYY HH:mm').unix();
  }) as TFlightSearchReservation;

  return {
    order: orderDetails,
    reservations,
    filterTimestamp: moment(
      latestReservation.arrivalDateTime,
      'DD/MM/YYYY HH:mm'
    )
      .endOf('day')
      .utc()
      .unix(),
  };
}
