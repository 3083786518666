import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Card, CardTitle, CardBody } from 'reactstrap';
import moment from 'moment';

import LoaderAnalytics from '../../components/loaders/LoaderAnalytics';

import firebaseApp from '../../services/Firebase';

export default function SupportCard({ dateSelection }) {
  const { messages } = useIntl();

  const [ticketCount, setTicketCount] = useState(0);
  const [ticketRating, setTicketRating] = useState(5);
  const [loading, setLoading] = useState(true);

  const { user } = useSelector(({ auth }) => auth);

  useEffect(() => {
    loadTickets();
  }, [dateSelection]);

  const loadTickets = async () => {
    setLoading(true);

    const tickets = await firebaseApp.getTicketsFromOrganization(
      user.organizationId,
      moment.unix(dateSelection.startDate).format('YYYY-MM-DD'),
      moment.unix(dateSelection.endDate).format('YYYY-MM-DD')
    );

    const scores = _.countBy(
      tickets.results,
      (t) => t.satisfaction_rating.score
    );
    const good = scores.good || 0;
    const bad = scores.bad || 0;
    const average_rating = good / (good + bad);

    setTicketCount(tickets.count);
    setTicketRating(average_rating);
    setLoading(false);
  };

  if (loading) {
    return (
      <Card className="mb-2">
        <CardBody>
          <CardTitle>
            {messages['containers.dashboard.support-card.title']}
          </CardTitle>
          <LoaderAnalytics />
          <LoaderAnalytics />
          <LoaderAnalytics />
        </CardBody>
      </Card>
    );
  }

  return (
    <Card className="mb-2">
      <CardBody>
        <CardTitle>
          {messages['containers.dashboard.support-card.title']}
        </CardTitle>

        <div className="d-flex flex-row mb-2 pb-2 justify-content-between align-items-center">
          <div className="flex-fill">
            <p className="font-weight-medium mb-0">
              {
                messages[
                  'containers.dashboard.support-card.number-conversations'
                ]
              }
            </p>
            <p className="text-muted mb-0 text-small">
              {messages['containers.dashboard.support-card.total-chats']}
            </p>
          </div>
          <div>{ticketCount}</div>
        </div>

        <div className="d-flex flex-row mb-2 pb-2 justify-content-between align-items-center">
          <div className="flex-fill">
            <p className="font-weight-medium mb-0">
              {messages['containers.dashboard.support-card.feeling']}
            </p>
            <p className="text-muted mb-0 text-small">
              {messages['containers.dashboard.support-card.suport-rating']}
            </p>
          </div>
          <div>
            <span role="img" aria-label="Avaliação do atendimento">
              {ticketRating < 0.5
                ? '😠'
                : ticketRating < 0.6
                ? '🙁'
                : ticketRating < 0.75
                ? '😐'
                : ticketRating < 0.9
                ? '😃'
                : '🤩'}
            </span>
          </div>
        </div>
      </CardBody>
    </Card>
  );
}
