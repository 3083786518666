export const languages = [
  {
    value: 'pt-BR',
    label: 'Português',
  },
  {
    value: 'en-US',
    label: 'English',
  },
  {
    value: 'es-ES',
    label: 'Español',
  },
];

export const DEFAULT_LOCALE = 'pt-BR';
