import React, { useMemo } from 'react';
import classNames from 'classnames';
import CustomProgressBar from '../common/CustomProgressBar';
import { useIntl } from 'react-intl';

import { BULK_USER_DATA_ACTIONS } from '../../constants/batch';

import { ReactComponent as CreateIcon } from '../../assets/img/icons/user-add.svg';
import { ReactComponent as ModifyIcon } from '../../assets/img/icons/pen.svg';
import { ReactComponent as DeleteIcon } from '../../assets/img/icons/delete.svg';

const ICONS = {
  [BULK_USER_DATA_ACTIONS.CREATE]: CreateIcon,
  [BULK_USER_DATA_ACTIONS.MODIFY]: ModifyIcon,
  [BULK_USER_DATA_ACTIONS.DELETE]: DeleteIcon,
};

export default function UserImportItem({
  className = '',
  batchData = {},
  value,
}) {
  const { formatMessage, messages } = useIntl();

  const { progress, loading, title, resultMessage, Icon } = useMemo(() => {
    const {
      progress = 0,
      loading = true,
      successful = 0,
      failed = 0,
      total = 0,
    } = batchData[value] || {};

    return {
      progress,
      loading,
      title:
        messages[
          `admin.users.bulk.card.${value}.title${loading ? '.loading' : ''}`
        ],
      resultMessage: formatMessage(
        {
          id: `admin.users.bulk.card.${value}.result.${
            failed ? 'with-fail' : 'without-fail'
          }`,
        },
        { successful, failed, total }
      ),
      Icon: ICONS[value],
    };
  }, [batchData, value]);

  // Render
  return (
    <div
      className={classNames(
        'user-import-item d-flex align-items-center',
        className
      )}
    >
      <div className="mr-3 loading-container flex-center">
        {loading ? (
          <div className={classNames('loading relative w-100 h-100')} />
        ) : (
          <Icon className="text-primary w-100 h-100" />
        )}
      </div>
      <div>
        <h3 className="m-0 fs-0-8rem mb-1 pr-5 font-primary">{title}</h3>
        {loading ? (
          <CustomProgressBar progress={progress} decimals={false} />
        ) : (
          <p className="result-message m-0 p-0 text-muted fs-0-7rem">
            {resultMessage}
          </p>
        )}
      </div>
    </div>
  );
}
