import React from 'react';
import { FlatfileButton } from '@flatfile/react';
import FlatfileInfo from '../../constants/Flatfile';
import { useIntl } from 'react-intl';

export default function BulkImportButton({
  user,
  onData,
  simple,
  type,
  fields,
}) {
  const { messages } = useIntl();

  return (
    <FlatfileButton
      className={`bulk-btn mr-3 pointer font-weight-bold px-5 ${
        simple ? 'simple' : ''
      }`}
      licenseKey={FlatfileInfo.licenseKey}
      customer={{
        companyId: user.organizationId,
        email: user.email,
        name: `${user.firstName} ${user.lastName}`,
        userId: user.uid,
      }}
      settings={{
        type,
        fields,
        managed: true,
        theme: {
          buttons: {
            primary: {
              backgroundColor: '#43418e',
              border: '1px solid #4f4da7',
              ':hover': {
                backgroundColor: '#4f4da7',
              },
            },
          },
          header: {
            closeButton: {
              ':hover': {
                color: '#43418e',
              },
            },
          },
        },
        i18nOverrides: {
          en: {
            otherLocales: ['en-US', 'en-CA', 'en-GB'],
            overrides: {
              breadcrumbs: {
                columns: messages['bulk.breadcrumbs.columns'],
                complete: messages['bulk.breadcrumbs.complete'],
                match: messages['bulk.breadcrumbs.match'],
                problems: messages['bulk.breadcrumbs.problems'],
                repair: messages['bulk.breadcrumbs.repair'],
                spreadsheet: messages['bulk.breadcrumbs.spreadsheet'],
                upload: messages['bulk.breadcrumbs.upload'],
              },
              errors: {
                missingRequiredFields:
                  messages['bulk.errors.missingRequiredFields'],
              },
              buttons: {
                back: messages['bulk.btns.back'],
                cancel: messages['bulk.btns.cancel'],
                clear: messages['bulk.btns.clear'],
                complete: messages['bulk.btns.complete'],
                confirm: messages['bulk.btns.confirm'],
                confirmMapping: messages['bulk.btns.confirmMapping'],
                continue: messages['bulk.btns.continue'],
                customField: messages['bulk.btns.customField'],
                edit: messages['bulk.btns.edit'],
                ignore: messages['bulk.btns.ignore'],
                no: messages['bulk.btns.no'],
                ok: messages['bulk.btns.ok'],
                review: messages['bulk.btns.review'],
                yes: messages['bulk.btns.yes'],
                selectAnotherRow: messages['bulk.btns.selectAnotherRow'],
                noHeaderRow: messages['bulk.btns.noHeaderRow'],
              },
              clearAllChanges: messages['bulk.clearAllChanges'],
              dropzone: {
                accepted: messages['bulk.dropzone.accepted'],
                button: messages['bulk.dropzone.button'],
              },
              fileTypes: messages['bulk.fileTypes'],
              header: messages['bulk.header'],
              header2: messages['bulk.header2'],
              lookupMatchingFields: messages['bulk.lookupMatchingFields'],
              processingPleaseWait: messages['bulk.processingPleaseWait'],
              readySubmit: messages['bulk.readySubmit'],
              success: messages['bulk.success'],
              unableToMatch: messages['bulk.unableToMatch'],
              manual: messages['bulk.manual'],
              selectSheet: messages['bulk.selectSheet'],
              autoMatchedField1: messages['bulk.autoMatchedField1'],
              autoMatchedField2: messages['bulk.autoMatchedField2'],
              rowsHaveValue: messages['bulk.rowsHaveValue'],
              validationPass: messages['bulk.validationPass'],
              excel: {
                statusLoadedSheets: messages['bulk.statusLoadedSheets'],
                stepOneSuccess: messages['bulk.stepOneSuccess'],
                stepThreeDefault: messages['bulk.stepThreeDefault'],
                stepThreeSuccess: messages['bulk.stepThreeSuccess'],
                stepTwoDefault: messages['bulk.stepTwoDefault'],
              },
              headerSelection: {
                question1: messages['bulk.question1'],
                question2: messages['bulk.question2'],
              },
            },
          },
        },
      }}
      onData={onData}
    >
      {
        messages[
          simple ? 'admin.users.bulkImport.lower' : 'admin.users.bulkImport'
        ]
      }
    </FlatfileButton>
  );
}
