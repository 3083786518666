import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Route, Switch, Redirect } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import './portao3-legacy/services/Firebase';
import AppLocale, {
  MomentLocales,
  validateLanguage,
} from './portao3-legacy/lang';
import main from './portao3-legacy/views';
import app from './portao3-legacy/views/app';
import travel from './portao3-legacy/views/travel';
import expenses from './portao3-legacy/views/expenses';
import data from './portao3-legacy/views/data';
import user from './portao3-legacy/views/user';
import TermsModal from './portao3-legacy/views/terms';
import error from './portao3-legacy/views/error';
import Spinner from './portao3-legacy/components/common/Spinner';
import { Checklist } from './portao3-legacy/components/checklist';

import {
  createSetupChecklist,
  getSetupValidators,
} from './portao3-legacy/utils/checklist/setup';

import {
  setAuth,
  setUser,
  setOrganization,
  logoutUser,
} from './portao3-legacy/redux/auth/actions';

import {
  setChecklist,
  toggleItem,
  setChecklistComplete,
} from './portao3-legacy/redux/checklist/actions';

import { changeLocale } from './portao3-legacy/redux/actions';

import firebaseApp from './portao3-legacy/services/Firebase';

// Analytics
import {
  identifySegment,
  clearSegment,
  seenSegment,
} from './portao3-legacy/utils/segment';

import { initAtlas } from '../src/portao3-legacy/utils/atlas';

// Datepicker
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import { ptBR, enUS, es } from 'date-fns/locale';
import moment from 'moment';
import FocusMask from './portao3-legacy/components/common/FocusMask';
import { setupList } from './portao3-legacy/constants/checklists';
import { getCurrentAppVersion } from './portao3-legacy/utils/version';
import VersionCard from './portao3-legacy/components/version/VersionCard';
import NotificationCenter from './portao3-legacy/components/NotificationCenter';
import UserImportFixedCard from './portao3-legacy/components/users/UserImportFixedCard';
import { getLocale } from './portao3-legacy/utils/language';
import VitallyComponent from '../src/shared/components/VitallyComponent';

// Language default config
require('moment/locale/pt-br');

registerLocale('pt', ptBR);
moment.locale('pt-br');

let currentLocale = null;

const Locales = {
  'pt-BR': ptBR,
  'en-US': enUS,
  'es-ES': es,
};

const AuthRoute = ({ component: Component, authUser, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      authUser ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/user/login',
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

class App extends Component {
  constructor(props) {
    super(props);

    this.unsub = () => {};
    this.organizationSnap = () => {};
    this.state = {
      loading: true,
      focusMask: 'hide',
      showVersionCard: false,
      currentVersion: 0,
    };
  }

  componentDidMount() {
    this.authenticateUser();

    this.historyUnlisten = this.props.history.listen((location, _) => {
      seenSegment(location.pathname);
    });

    getCurrentAppVersion().then((version) => {
      console.debug('Initial version:', version);
      const versionInterval = setInterval(this.updateVersion, 600000);

      this.setState({ versionInterval, currentVersion: version });
    });
  }

  updateVersion = async () => {
    try {
      const newVersion = await getCurrentAppVersion();

      if (this.state.currentVersion < newVersion) {
        this.setState({ showVersionCard: true });

        if (this.state.versionInterval)
          clearInterval(this.state.versionInterval);
      }
    } catch (err) {
      console.debug(
        'An error occurred while trying to fetch the current web app version',
        err
      );
    }
  };

  componentWillUnmount() {
    if (this.historyUnlisten) this.historyUnlisten();
    if (this.state.versionInterval) clearInterval(this.state.versionInterval);
    this.unsub();
    this.organizationSnap();
  }

  componentDidUpdate(prevProps) {
    if (this.state.showVersionCard && this.state.versionInterval) {
      clearInterval(this.state.versionInterval);
    }
    if (prevProps.locale && this.props.locale !== prevProps.locale) {
      this.configLocale(this.props.locale);
    }
  }

  createSetupChecklist = async (user, checklistId) => {
    const { admin, organizationId } = user;

    if (admin) {
      const validators = await getSetupValidators(organizationId);
      const checklist = createSetupChecklist(validators);
      await firebaseApp.setChecklist(checklistId, checklist);
      console.debug(`${checklistId} CHECKLIST WAS CREATED!`);

      return checklist;
    } else return null;
  };

  getSetupChecklist = async (
    user,
    checklistId,
    getItemsIfCompleted = false
  ) => {
    try {
      if (user.admin && checklistId) {
        const checklistRef = firebaseApp.getChecklistFromUser(
          user.uid,
          checklistId
        );
        const checklistSnap = await checklistRef.get();

        if (checklistSnap.exists) {
          console.debug('SETUP CHECKLIST ALREADY EXISTS');
          const checklistItemsSnap = await checklistRef
            .collection('items')
            .get();
          const checklist = checklistSnap.data();

          if (!checklist.completed || getItemsIfCompleted) {
            checklist.items = checklistItemsSnap.docs.reduce(
              (currentItems, doc) => {
                currentItems[doc.id] = doc.data();
                return currentItems;
              },
              {}
            );
          }

          return checklist;
        } else {
          console.debug('SETUP CHECKLIST DOENST EXIST');
          return this.createSetupChecklist(user, checklistId);
        }
      } else return null;
    } catch (err) {
      console.debug(
        'AN ERROR OCCURRED WHEN TRYING TO GET SETUP CHECKLIST',
        err
      );
      return null;
    }
  };

  getUserOrganization = async (organizationId) => {
    return new Promise((resolve, _) => {
      this.organizationSnap = firebaseApp
        .getOrganizationFromId(organizationId)
        .onSnapshot((organizationSnap) => {
          const organization = organizationSnap.data();

          organization.id = organizationId;

          this.props.setOrganization(organization);
          resolve(organization);
        });
    });
  };

  configLocale = async (locale) => {
    if (currentLocale === locale) return;

    this.props.changeLocale(locale);

    // Imports moment locale module based on locale
    const momentLocale = MomentLocales[locale];

    if (momentLocale) await import(`moment/locale/${momentLocale}.js`);
    moment.locale(locale.toLowerCase());

    registerLocale(locale, Locales[locale]);
    currentLocale = locale;
  };

  getUserData = async () => {
    this.unsub = firebaseApp.getUser().onSnapshot(async (userSnap) => {
      try {
        const user = userSnap.data();
        this.props.setUser(user);

        const setupChecklistPromise = this.getSetupChecklist(
          user,
          setupList.id
        );

        const { userHash } = await firebaseApp.getUserHashAtlas();

        const organization = await this.getUserOrganization(
          user.organizationId
        );

        initAtlas({ user, organization, userHash });

        identifySegment({ user, organization });

        const setupChecklist = await setupChecklistPromise;

        if (setupChecklist) {
          if (!setupChecklist.completed) {
            this.props.setChecklist(setupList.id, setupChecklist);
          }
        }

        await this.configLocale(getLocale(user, organization));

        this.setState({ loading: false });
      } catch (err) {
        console.log('Unable to get user data', err);
        this.doLogout();
      }
    });
  };

  doLogout = () => {
    clearSegment();

    if (this.unsub) {
      this.unsub();
    }

    this.props.logoutUser();
    this.setState({ loading: false });
  };

  authenticateUser = async () => {
    firebaseApp.auth().onAuthStateChanged(async (user) => {
      try {
        if (user != null) {
          this.props.setAuth(user);
          this.getUserData();
        } else this.doLogout();
      } catch (err) {
        console.error('Unable to authenticate user', err);
        this.doLogout();
      }
    });

    return null;
  };

  render() {
    const { loading, showVersionCard } = this.state;
    const {
      locale,
      authUser,
      checklist,
      toggleItem,
      animation: { showMask },
      setChecklistComplete,
    } = this.props;

    const currentAppLocale = AppLocale[locale];
    const setupChecklist = checklist[setupList.id];

    if (loading) {
      return <Spinner />;
    }

    // Locale for Datepicker
    setDefaultLocale(locale);

    return (
      <div className="h-100">
        <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}
        >
          <React.Fragment>
            {authUser ? <NotificationCenter /> : null}

            <VersionCard
              hidden={!showVersionCard}
              onClose={() => {
                this.setState({ showVersionCard: false });
                if (this.state.versionInterval)
                  clearInterval(this.state.versionInterval);
              }}
            />

            <TermsModal />

            <FocusMask show={showMask} />

            {setupChecklist && !setupChecklist.completed ? (
              <Checklist
                id={setupList.id}
                items={setupChecklist.items}
                links={setupList.links}
                onCheck={(id) => toggleItem(setupList.id, id)}
                show={setupChecklist.open}
                onCompletion={() =>
                  setTimeout(() => setChecklistComplete(setupList.id), 1200)
                }
              />
            ) : null}

            <UserImportFixedCard />

            <Switch>
              <AuthRoute path="/app" authUser={authUser} component={app} />

              <AuthRoute
                exact
                path={`/travel`}
                authUser={authUser}
                component={travel}
              />
              <AuthRoute
                exact
                path={`/travel/search/:searchType`}
                authUser={authUser}
                component={travel}
              />
              <AuthRoute
                exact
                path={`/travel/orders/:orderId`}
                authUser={authUser}
                component={travel}
              />
              <AuthRoute
                exact
                path={`/travel/orders/:orderId/approval`}
                authUser={authUser}
                component={travel}
              />
              <AuthRoute
                exact
                path={`/travel/orders/:orderId/budget`}
                authUser={authUser}
                component={travel}
              />
              <AuthRoute
                exact
                path={`/travel/orders/:orderId/alteration`}
                authUser={authUser}
                component={travel}
              />
              <AuthRoute
                exact
                path={`/travel/orders/:orderId/alteration/:alterId`}
                authUser={authUser}
                component={travel}
              />

              <AuthRoute
                path={`/payments`}
                authUser={authUser}
                component={expenses}
              />

              <AuthRoute path={`/data`} authUser={authUser} component={data} />

              <Route path="/user" component={user} />

              <Route
                path="/onboarding"
                component={() =>
                  window.location.replace('https://portao3.com.br/onboarding')
                }
              />

              <Route path="/error" exact component={error} />
              <Route path="/" exact component={main} />
              <Redirect to="/error" />
            </Switch>
          </React.Fragment>
          {!window.VitallyStatus && <VitallyComponent />}
        </IntlProvider>
      </div>
    );
  }
}

const mapStateToProps = ({ settings, auth, checklist, animation }) => {
  const { locale } = settings;

  return {
    locale: validateLanguage(locale),
    authUser: auth.auth,
    checklist,
    animation,
  };
};

export default connect(mapStateToProps, {
  setChecklist,
  setAuth,
  setUser,
  setOrganization,
  logoutUser,
  toggleItem,
  setChecklistComplete,
  changeLocale,
})(withRouter(App));
