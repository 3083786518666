import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Row, Label, CustomInput } from 'reactstrap';
import { Colxx } from '../../components/common/CustomBootstrap';
import { getOrganizationConfig } from '../../utils/organization';

import IntlMessages from '../../helpers/IntlMessages';
import messages from '../../lang/messages';

import { loadCostCenters as _loadCostCenters } from '../../utils/costCenters';
import { loadProjects as _loadProjects } from '../../utils/projects';
import { loadMotives as _loadMotives } from '../../utils/motives';
import { loadTags as _loadTags } from '../../utils/tags';
import FixedSelect from '../../components/common/FixedSelect';
import useCommonInformation from '../../hooks/useCommonInformation';
import useShowToggle from '../../hooks/useShowToggle';

function renderAsterisc(shouldRender) {
  return shouldRender ? ' *' : '';
}

export default function CommonParams({
  commonParams,
  setCommonParams,
  requiredFields = {},
  editableFields = {},
  selectedPax,
}) {
  const { user, organization } = useSelector((state) => state.auth);

  // States
  const [shouldLoadCostCenters, setCostCenters, toggleLoadCostCenters] =
    useShowToggle();
  const [shouldLoadProjects, setProjects, toggleLoadProjects] = useShowToggle();
  const [shouldLoadMotives, setMotives, toggleLoadMotives] = useShowToggle();
  const [shouldLoadTags, setTags, toggleLoadTags] = useShowToggle();

  const {
    costCenters,
    loadingCostCenters,
    projects,
    loadingProjects,
    motives,
    loadingMotives,
    tags,
    loadingTags,
  } = useCommonInformation(user.organizationId, {
    shouldLoadCostCenters,
    shouldLoadProjects,
    shouldLoadMotives,
    shouldLoadTags,
    initialLoading: false,
  });

  useEffect(() => {
    toggleLoadCostCenters();
    toggleLoadProjects();
    toggleLoadMotives();
    toggleLoadTags();
  }, []);

  const canMakeObservation = useMemo(
    () => getOrganizationConfig(organization.config, 'observations'),
    [organization]
  );

  const selectedUser = selectedPax || user;

  // Functions
  const onChange = (name, value) => {
    setCommonParams({
      ...commonParams,
      [name]: value,
    });
  };

  const checkIfShouldLoad = (value, toggleFunction) =>
    !value && toggleFunction();

  // Render
  return (
    <div className="common-params">
      <Row>
        {costCenters.length ? (
          <Colxx xxs={12} xs={projects.length == 0 ? 12 : 6}>
            <Label className="form-group search reservation has-top-label">
              <FixedSelect
                isDisabled={
                  selectedUser.costCenter?.id &&
                  editableFields.costCenter === false
                }
                className="react-select"
                classNamePrefix="react-select"
                isClearable={false}
                placeholder=""
                value={commonParams.costCenter}
                noOptionsMessage={() =>
                  messages['travel.booking.no-costCenter']
                }
                loadingMessage={() => messages['general.loading']}
                options={costCenters}
                onChange={(value) => onChange('costCenter', value)}
                maxMenuHeight={150}
                showOnlyX={50}
                onMenuOpen={() =>
                  checkIfShouldLoad(
                    shouldLoadCostCenters,
                    toggleLoadCostCenters
                  )
                }
                isLoading={loadingCostCenters}
              />

              <span>
                <IntlMessages id="travel.booking.costCenter" />
                {renderAsterisc(requiredFields['costCenter'])}
              </span>
            </Label>
          </Colxx>
        ) : null}

        {projects.length ? (
          <Colxx xxs={12} xs={costCenters.length == 0 ? 12 : 6}>
            <Label className="form-group search reservation has-top-label">
              <FixedSelect
                isDisabled={
                  selectedUser.project?.id && editableFields.project === false
                }
                className="react-select"
                classNamePrefix="react-select"
                isClearable={false}
                placeholder=""
                value={commonParams.project}
                noOptionsMessage={() => messages['travel.booking.project']}
                loadingMessage={() => messages['general.loading']}
                options={projects}
                onChange={(value) => onChange('project', value)}
                maxMenuHeight={150}
                showOnlyX={50}
                onMenuOpen={() =>
                  checkIfShouldLoad(shouldLoadProjects, toggleLoadProjects)
                }
                isLoading={loadingProjects}
              />
              <span>
                <IntlMessages id="travel.booking.project" />
                {renderAsterisc(requiredFields['project'])}
              </span>
            </Label>
          </Colxx>
        ) : null}

        {motives.length ? (
          <Colxx xxs={12}>
            <Label className="form-group search reservation has-top-label">
              <FixedSelect
                className="react-select"
                classNamePrefix="react-select"
                isClearable={false}
                placeholder=""
                value={commonParams.motive}
                noOptionsMessage={() => messages['travel.booking.no-motive']}
                loadingMessage={() => messages['general.loading']}
                options={motives}
                onChange={(value) => onChange('motive', value)}
                maxMenuHeight={150}
                showOnlyX={50}
                onMenuOpen={() =>
                  checkIfShouldLoad(shouldLoadMotives, toggleLoadMotives)
                }
                isLoading={loadingMotives}
              />

              <span>
                <IntlMessages id="travel.booking.motive" />
                {renderAsterisc(requiredFields['motive'])}
              </span>
            </Label>
          </Colxx>
        ) : null}

        {tags.length ? (
          <Colxx xxs={12}>
            <Label className="form-group search reservation has-top-label">
              <FixedSelect
                className="react-select select tags is-multi"
                classNamePrefix="react-select"
                isClearable={false}
                placeholder=""
                value={commonParams.tags}
                noOptionsMessage={() => messages['travel.booking.no-tag']}
                loadingMessage={() => messages['general.loading']}
                options={tags}
                onChange={(value) => onChange('tags', value)}
                isMulti
                maxMenuHeight={150}
                showOnlyX={50}
                onMenuOpen={() =>
                  checkIfShouldLoad(shouldLoadTags, toggleLoadTags)
                }
                isLoading={loadingTags}
              />
              <span>
                <IntlMessages id="travel.booking.tag" />
                {renderAsterisc(requiredFields['tags'])}
              </span>
            </Label>
          </Colxx>
        ) : null}

        {canMakeObservation && (
          <Colxx xxs="12">
            <Label className="form-group search reservation has-top-label">
              <CustomInput
                type="text"
                id="observation-input"
                className="w-100 observation mt-4"
                maxLength={256}
                value={commonParams.observation}
                onChange={(e) => onChange('observation', e.target.value)}
              />

              <span>
                <IntlMessages id="travel.booking.observation" />
                {renderAsterisc(requiredFields['observation'])}
              </span>
            </Label>
          </Colxx>
        )}
      </Row>
    </div>
  );
}
