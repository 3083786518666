import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import CurrencyWrapper from '../common/CurrencyWrapper';
import Badge from './Badge';
import { getPriceDifference, getSelectedClass } from './utils';

export default function CarAlternative({
  alternative,
  originalTotal,
  index,
  selectedAlternative,
  onClick = () => {},
  badgeText,
}) {
  const { messages, formatMessage } = useIntl();

  // States
  const { carName, carImage, carType, total, _badgeText } = useMemo(() => {
    return {
      carName: alternative.name,
      carImage: alternative.mainPhoto,
      carType: alternative.amenities.type,
      total: alternative.fare.total,
      _badgeText:
        badgeText ||
        (index === 0
          ? messages['order.alternative.best-price']
          : formatMessage(
              {
                id: 'order.alternative.save',
              },
              {
                save: getPriceDifference(
                  originalTotal,
                  alternative.fare.total,
                  alternative.fare.currency
                ),
              }
            )),
    };
  }, [alternative]);

  const selectedClass = useMemo(
    () => getSelectedClass(alternative, selectedAlternative),
    [alternative, selectedAlternative]
  );

  // Render
  return (
    <div
      className={`alternative-card d-flex flex-column justify-content-between p-3 ${selectedClass}`}
      onClick={onClick}
    >
      <img
        src={carImage}
        alt={carName}
        className="w-100 hotel-img"
        style={{ height: '40%', objectFit: 'contain' }}
      />
      <div
        className="d-flex flex-column justify-content-between mt-2"
        style={{ height: '60%' }}
      >
        <div className="position-relative">
          <h2
            className="m-0 text-dark font-primary title car mt-1"
            style={{ fontSize: '1.4em' }}
          >
            {carName}
          </h2>
          <p
            className="m-0 position-absolute text-muted"
            style={{ fontSize: '1.1em' }}
          >
            {carType}
          </p>
        </div>
        <div className="text-right">
          <p className="m-0 font-primary one-line-height medium">
            {messages['general.total']}
          </p>
          <h3 className="m-0 font-primary text-dark font-weight-medium one-line-height mt-1">
            <CurrencyWrapper
              value={total}
              decimalPlaces={0}
              tooltipFill="black"
            >
              {({ formattedValue }) => <span>{formattedValue}</span>}
            </CurrencyWrapper>
          </h3>
          <Badge color="#0A936D" className="mt-2 py-2 bottom-badge">
            <span className="font-weight-normal text-white small-medium-title">
              {_badgeText}
            </span>
          </Badge>
        </div>
      </div>
    </div>
  );
}
