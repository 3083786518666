import React from 'react';
import classnames from 'classnames';

import Icons from '../../constants/icons';

import { stringToMoney } from '../../utils/money';

import { isFinancialImpactTypeCredit } from './constants';

export default function Bank3TransactionCard({ transaction, style, onClick }) {
  return (
    <div
      className="order-card"
      onClick={() => onClick(transaction)}
      style={{
        ...style,
        cursor: transaction?.reserva ? 'pointer' : 'default',
      }}
    >
      <div className="pl-2 d-flex flex-grow-1 mw-0">
        <div className="card-body align-self-center d-flex flex-column flex-lg-row justify-content-between mw-0 align-items-lg-center">
          {Icons[transaction.tipo] && (
            <img
              src={`/assets/img/icons/${Icons[transaction.tipo] || 'other'}`}
              alt="Icon"
              className="mr-4 ml-2"
            />
          )}
          <div className="w-30 w-sm-100">
            <p className="list-item-heading mb-1 truncate">
              {transaction.merchant_name}
              <br />
              <span className="text-muted text-small">
                {transaction.merchant_city}
              </span>
            </p>
          </div>
          <div className="w-30 w-sm-100">
            <p className="list-item-heading mb-1 truncate">
              {transaction.passageiro}
            </p>
          </div>
          <p className="mb-1 text-muted text-small w-30 w-sm-100">
            {transaction.detalhe}
            <br />
            {transaction.data_inicio} - {transaction.data_fim}
          </p>
        </div>
        <div className="custom-control custom-checkbox pl-1 align-self-center pr-4">
          {isFinancialImpactTypeCredit(transaction)
            ? stringToMoney(transaction.amount / 100, 2)
            : stringToMoney((transaction.amount * -1) / 100, 2)}
        </div>
      </div>
    </div>
  );
}
