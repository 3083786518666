import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export default function useHistoryWithScroll() {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const { state } = location;

    if (state) {
      const { scrollY = 0, scrollX = 0 } = state;
      window.scroll(scrollX, scrollY);
    }
  }, [location.state]);

  const push = (pathname, options = {}) => {
    const state = options.state || {};

    state.scrollX = window.scrollX;
    state.scrollY = window.scrollY;

    options.state = state;

    history.push({
      pathname,
      ...options,
    });
  };

  return { history, location, push };
}
