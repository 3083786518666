import { THotelReservationParams } from 'src/portao3-legacy/hooks/useReserveTravelItem/types';
import { THotelSearchItem } from 'src/portao3-legacy/types/orders/hotel/hotelOrder';
import SearchResultWrapper from './SearchResultWrapper';
import LoadingHotel from 'src/portao3-legacy/components/search/LoadingHotel';
import HotelItem from './HotelItem';
import { useSelector } from 'react-redux';
import { getAuthRedux } from 'src/portao3-legacy/utils/redux';
import { trackPropertiesSegment } from 'src/portao3-legacy/utils/segment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import HotelModal from './HotelModal';
import FavoritePlacesHOC from 'src/portao3-legacy/hoc/FavoritePlacesHOC';
import useMapHighlightedPin from 'src/portao3-legacy/hooks/useMapHighlightedPin';
import SearchMap from './SearchMap/SearchMap.tsx';
import ConditionalRenderWrapper from 'src/portao3-legacy/wrappers/ConditionalRenderWrapper.tsx';
import LoadingMap from './SearchMap/LoadingMap.tsx';

import { HotelFilterQueries } from 'src/portao3-legacy/constants/search/hotel';
import loadSearchStatus from 'src/portao3-legacy/constants/loadSearchStatus.js';

import {
  THotelParams,
  THotelSearchParams,
} from 'src/portao3-legacy/types/orders/hotel/hotelParams';
import { ITravelResultComponentProps } from 'src/portao3-legacy/types/search';
import { TString } from 'src/portao3-legacy/hooks/useParams/types';
import { TLocationWithId } from 'src/portao3-legacy/types/others.ts';
import { OrderTypes } from 'src/portao3-legacy/constants/orderTypes.js';
import { getBreakfastMinRoomFare } from 'src/portao3-legacy/utils/orders.js';

function formatParams({ name = '', ...others }: THotelParams) {
  return { ...others, name: (name as TString).toLowerCase() };
}

interface IProps
  extends ITravelResultComponentProps<
    THotelSearchItem,
    THotelSearchParams,
    THotelParams,
    THotelReservationParams
  > {
  favoriteHotelIds: string[];
}

export default function HotelResults({
  searchParams,
  params,
  status,
  isLoading,
  search,
  items,
  getRemainingSearch,
  reserveItemWithToggle,
  favoriteHotelIds = [],
  setParam,
  replaceParam,
}: IProps) {
  const { user } = useSelector(getAuthRedux);

  // Hooks
  const { highlightedPin, onSelectHighlightedPin } = useMapHighlightedPin({
    coords: searchParams.coords,
    setSelectedLocationIds: (locations) =>
      replaceParam('selectedHotels', locations),
  });

  // States
  const [selectedItem, setSelectedItem] = useState<THotelSearchItem | null>(
    null
  );

  const [hasAlternatives, setHasAlternatives] = useState(
    user.organizationId === 'wFHg8FuQSUgs03z4oaQt' ? true : false
  );

  const searchCity = useMemo(
    () => search.destination?.label.split(',').slice(0, -1).join('') || '',
    [search]
  );

  const numDays = useMemo(() => {
    try {
      return search.inbound_date && search.outbound_date
        ? search.inbound_date.diff(search.outbound_date, 'days')
        : 1;
    } catch (err) {
      console.error('Unable to calculate hotel number of days', err);
      return 1;
    }
  }, [search]);

  // Functions
  const onSelectedDetails = useCallback(
    (hotelItem: THotelSearchItem) => {
      trackPropertiesSegment('User selected hotel item', {
        hotelItem,
        user,
      });

      setSelectedItem(hotelItem);
    },
    [user]
  );

  const handleSelectItem = (item: any) => {
    setSelectedItem(item);
  };

  const onPinSelect = useCallback((item: TLocationWithId) => {
    setParam('selectedHotels', {
      name: item.id,
      value: true,
      invertValue: true,
    });
  }, []);

  // Render
  return (
    <>
      <ConditionalRenderWrapper show={status !== loadSearchStatus.EMPTY}>
        <div className="search-map mb-5">
          {isLoading ? (
            <LoadingMap />
          ) : (
            <FavoritePlacesHOC>
              <SearchMap
                loading={isLoading}
                pointCoords={searchParams?.coords}
                selectedPoints={params.selectedHotels}
                onPinSelect={onPinSelect}
                highlightedPin={highlightedPin as any}
                onLocationSelection={onSelectHighlightedPin}
                withLocationSearch
                city={searchCity}
                selectedPinIconSrc="/assets/img/icons/hotel_pin.svg"
                pinIconSrc="/assets/img/icons/light_hotel_pin.svg"
                highlightedPinIconSrc="/assets/img/icons/red_pin.svg"
                favoritePlacesIds={favoriteHotelIds}
              />
            </FavoritePlacesHOC>
          )}
        </div>
      </ConditionalRenderWrapper>

      <SearchResultWrapper
        LoadingComponent={LoadingHotel}
        filterQueries={HotelFilterQueries as any}
        items={items}
        params={params}
        searchParams={searchParams}
        isLoading={isLoading}
        status={status}
        getRemainingSearch={getRemainingSearch}
        formatParams={formatParams}
        orderType={OrderTypes.HOTEL}
      >
        {({ paginatedItems }) => {
          if (params?.amenities?.['Café da manhã'] === true) {
            const sortedPaginatedItems = paginatedItems.sort((a, b) => {
              a = getBreakfastMinRoomFare(a);
              b = getBreakfastMinRoomFare(b);

              return a.minFare - b.minFare;
            });

            return (
              <>
                {sortedPaginatedItems.map((item, index) => {
                  return item.rooms.length ? (
                    <HotelItem
                      key={`result-${item.hotelId}`}
                      index={index}
                      item={item}
                      showDetails={onSelectedDetails}
                    />
                  ) : null;
                })}
              </>
            );
          }

          return (
            <>
              {paginatedItems.map((item, index) => {
                return (
                  <HotelItem
                    key={`result-${item.hotelId}`}
                    index={index}
                    item={item}
                    showDetails={onSelectedDetails}
                  />
                );
              })}
            </>
          );
        }}
      </SearchResultWrapper>

      {selectedItem ? (
        <HotelModal
          searchCode={search.searchCode}
          toggleModal={() => setSelectedItem(null)}
          item={selectedItem}
          reserveItem={reserveItemWithToggle(() => setSelectedItem(null))}
          nationality={search.hotel_params?.nationality}
          selectItem={null}
          numDays={numDays}
          handleSelectHotel={handleSelectItem}
          hasAlternatives={hasAlternatives}
          params={params}
        />
      ) : null}
    </>
  );
}
