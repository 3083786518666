import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Card, Row, Button, CardBody } from 'reactstrap';
import moment from 'moment';
import reverse from 'lodash/reverse';
import useHistoryWithScroll from '../../hooks/useHistoryWithScroll';
import IntlMessages from '../../helpers/IntlMessages';
import firebaseApp from '../../services/Firebase';
import { Colxx } from '../../components/common/CustomBootstrap';
import DateSelection from '../../components/reports/DateSelection';
import { stringToMoney } from '../../utils/money';
import { STATEMENT_FILE_TYPES } from '../../utils/reports';

import PrintDropdown from '../../components/common/PrintDropdown';
import LoaderAnalyticsSingle from '../../components/loaders/LoaderAnalyticsSingle';
import LoaderCardWithText from '../../components/loaders/LoaderCardWithText';
import OrderModal from '../timeline/OrderModal';
import Bank3Modal from '../../components/finance/Bank3Modal';
import Bank3DateView from '../../components/finance/Bank3DateView';
import { useIntl } from 'react-intl';
import { downloadFromURL } from '../../utils/fileGeneration';
import { generateCSV, generateExcelFile } from '../../utils/reports';
import { generateXLSX } from '../../utils/fileGeneration';

import {
  getDataFromDocument,
  getDocument,
  getDocumentsFromCollection,
} from '../../utils/firebase';

import ExpenseDetailsModal from '../../components/finance/ExpenseDetailsModal';
import BudgetOrderModal from '../timeline/BudgetOrderModal';
import { set } from 'lodash';

let loadCredirCardsEvent = () => {};

export default function VcnBank3View() {
  const { messages } = useIntl();
  const { resourceId, travelId, budgetId, itemId } = useParams();
  const { push } = useHistoryWithScroll();

  const [creditCard, setCreditCard] = useState(null);
  const [isLoading, setLoading] = useState(true);

  const [balance, setBalance] = useState(null);
  const [timeline, setTimeline] = useState({});

  const [isPrinting, setPrinting] = useState(false);

  const [dateSelection, setDateSelection] = useState({
    startDate: moment().startOf('month').unix(),
    endDate: moment().unix(),
  });

  const [shouldDisableButtons, setShouldDisableButtons] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [selectedBudgetData, setSelectedBudgetData] = useState(null);
  const [selectedBudgetItems, setSelectedBudgetItems] = useState([]);

  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    loadCreditCards();

    return () => {
      loadCredirCardsEvent();
    };
  }, []);

  useEffect(() => {
    if (creditCard) {
      loadBalance();
      loadTransactions();
    }
  }, [creditCard]);

  useEffect(() => {
    if (creditCard) {
      loadTransactions();
    }
  }, [dateSelection]);

  // Filters
  const setCustomDate = (start, end) => {
    setDateSelection({
      startDate: moment(start).startOf('day').unix(),
      endDate: moment(end).endOf('day').unix(),
    });
  };

  // Handle Credit Cards
  const loadCreditCards = async () => {
    loadCredirCardsEvent = firebaseApp
      .getBank3CardsFromOrganization(user.organizationId)
      .onSnapshot((creditCardsSnap) => {
        let creditCard = null;

        creditCardsSnap.forEach((creditCardSnap) => {
          creditCard = {
            ...creditCardSnap.data(),
            id: creditCardSnap.id,
          };
        });
        setCreditCard(creditCard);
        setLoading(false);
      });
  };

  const loadBalance = async () => {
    try {
      const balance = await firebaseApp.getBank3Balance(user.organizationId);

      setBalance(balance.totalBalance / 100);
    } catch (err) {
      setBalance(0);
      console.error(err);
    }
  };

  const loadTransactions = async () => {
    try {
      const response = await firebaseApp.getBank3Transactions(
        user.organizationId,
        moment.unix(dateSelection.startDate).format('YYYY-MM-DD'),
        moment.unix(dateSelection.endDate).format('YYYY-MM-DD')
      );

      setTimeline(response.timeline);
    } catch (err) {
      console.error(err);
    }
  };

  // Modal
  const openModalSetup = () => {
    push(`/data/finance/details/new`);
  };

  const closeModal = () => {
    push('/data/finance/details');
  };

  const isTransactionFromBudget = (transaction) =>
    transaction?.custom_fields?.external === 'EXPENSES_CARD';

  const isTransactionFromOrder = (transaction) => transaction?.reserva;

  const onRequestToOpenResource = async (transaction) => {
    try {
      if (isTransactionFromBudget(transaction)) {
        const budget = await firebaseApp.getBudgetByTransactionId(
          transaction.id
        );

        const budgetId = budget.docs[0].ref.parent.parent.id;

        const itemId = budget.docs[0].id;

        push(`/data/finance/details/expenses/${budgetId}/items/${itemId}`);
      } else if (isTransactionFromOrder(transaction)) {
        const travelId = transaction.reserva;
        push(`/data/finance/details/travel/${travelId}`);
      }
    } catch (err) {
      console.log(err);
    }
  };

  // Download
  const handleOfxPrint = async () => {
    const { downloadFromBlobUrl } = require('../../utils/file');
    setPrinting(true);

    const response = await firebaseApp.getOfx(
      user.organizationId,
      moment.unix(dateSelection.startDate).format('YYYY-MM-DD'),
      moment.unix(dateSelection.endDate).format('YYYY-MM-DD')
    );

    let blob = new Blob([response], {
      type: 'application/x-ofx',
    });

    let url = window.URL.createObjectURL(blob);
    let fileName = `${moment
      .unix(dateSelection.startDate)
      .format('YYYY-MM-DD')} - ${moment
      .unix(dateSelection.endDate)
      .format('YYYY-MM-DD')}.ofx`;
    downloadFromURL(url, fileName);
    setPrinting(false);
  };

  const handleCsvPrint = async () => {
    setPrinting(true);

    const { STATEMENT_REPORTS_FIELDS } = require('../../constants/reports');

    const response = await firebaseApp.getCsv(
      user.organizationId,
      moment.unix(dateSelection.startDate).format('YYYY-MM-DD'),
      moment.unix(dateSelection.endDate).format('YYYY-MM-DD')
    );

    if (response.records === 0) {
      setPrinting(false);
      return;
    }

    let transactionItems = [];
    Object.keys(response.timeline).map((key, index) => {
      transactionItems = [...transactionItems, ...response.timeline[key].items];
      return transactionItems;
    });

    transactionItems.forEach((item) => {
      if (item.financial_impact_type === 'credit') {
        item.amount = item.amount / 100;
      } else {
        item.amount = (item.amount / 100) * -1;
      }
    });

    const transactions = await Promise.all(
      transactionItems.map(async (item, index) => {
        const cardRef = await getDocumentsFromCollection(() =>
          firebaseApp
            .getExpenseCardFromExternalId(user.organizationId, item.card_id)
            .get()
        );

        const card = cardRef[0];

        if (!card) {
          item = {
            ...item,
            card_last_digits: 'XXXX',
          };
        } else {
          const userId = card.user_id;
          const userRef = await getDocument(() =>
            firebaseApp.getUserFromId(userId).get()
          );
          const userName = `${userRef.firstName} ${userRef.lastName}`;

          item = {
            ...item,
            card_last_digits: card.lastDigits,
            card_user_name: userName,
            user_cost_center: userRef.costCenter?.name,
            user_project: userRef.project?.name,
          };
        }

        if (isTransactionFromBudget(item)) {
          const budgetRef = await firebaseApp.getBudgetByTransactionId(item.id);
          const budgetId = budgetRef.docs[0]?.ref?.parent?.parent?.id;

          if (!budgetId) {
            item = {
              ...item,
              budget_id: 'N/A',
            };
          } else {
            const budget = await getDocument(() =>
              firebaseApp.getBudgetById(budgetId).get()
            );

            const budgetItemId = budgetRef?.docs[0]?.id;

            const budgetItem = await getDocument(() =>
              firebaseApp.getItemFromBudget(budgetId, budgetItemId).get()
            );

            const itemCategory = budgetItem
              ? budgetItem?.category?.label
                ? budgetItem?.category?.label
                : ''
              : '';

            item = {
              ...item,
              budget_id: budgetId,
              budget_name: budget.name,
              budget_category: budget.category.label,
              budget_item_category: itemCategory,
              budget_observation: budget.observation,
              budget_total_amount: budget.budget,
              budget_total_spent: budget.fare.total,
            };
          }
        }

        if (isTransactionFromOrder(item)) {
          const order = await getDocument(() =>
            firebaseApp.getOrderFromId(item.reserva).get()
          );

          item = {
            ...item,
            order_id: item.reserva,
            order_cost_center: order?.costCenter?.label || '',
          };
        }

        return item;
      })
    );

    let blob = new Blob(
      [
        generateCSV({
          data: transactions,
          fields: STATEMENT_REPORTS_FIELDS,
          messages,
          params: {
            headerIdPrefix: 'statement.excel',
          },
        }),
      ],
      {
        type: 'text/csv',
      }
    );

    let url = window.URL.createObjectURL(blob);
    let fileName = `${moment
      .unix(dateSelection.startDate)
      .format('YYYY-MM-DD')} - ${moment
      .unix(dateSelection.endDate)
      .format('YYYY-MM-DD')}.csv`;
    downloadFromURL(url, fileName);

    setPrinting(false);
  };

  const handleXlsxPrint = async () => {
    setPrinting(true);

    const { STATEMENT_REPORTS_FIELDS } = require('../../constants/reports');
    const { getFieldsFromData } = require('../../utils/reports');

    const response = await firebaseApp.getCsv(
      user.organizationId,
      moment.unix(dateSelection.startDate).format('YYYY-MM-DD'),
      moment.unix(dateSelection.endDate).format('YYYY-MM-DD')
    );

    if (response.records === 0) {
      setPrinting(false);
      return;
    }

    let transactionItems = [];
    Object.keys(response.timeline).map((key, index) => {
      transactionItems = [...transactionItems, ...response.timeline[key].items];
      return transactionItems;
    });

    transactionItems.forEach((item) => {
      if (item.financial_impact_type === 'credit') {
        item.amount = item.amount / 100;
      } else {
        item.amount = (item.amount / 100) * -1;
      }
    });

    const transactions = await Promise.all(
      transactionItems.map(async (item, index) => {
        const cardRef = await getDocumentsFromCollection(() =>
          firebaseApp
            .getExpenseCardFromExternalId(user.organizationId, item.card_id)
            .get()
        );

        const card = cardRef[0];

        if (!card) {
          item = {
            ...item,
            card_last_digits: 'XXXX',
          };
        } else {
          const userId = card.user_id;
          const userRef = await getDocument(() =>
            firebaseApp.getUserFromId(userId).get()
          );
          const userName = `${userRef.firstName} ${userRef.lastName}`;

          item = {
            ...item,
            card_last_digits: card.lastDigits,
            card_user_name: userName,
            user_cost_center: userRef.costCenter?.name,
            user_project: userRef.project?.name,
          };
        }

        if (isTransactionFromBudget(item)) {
          const budgetRef = await firebaseApp.getBudgetByTransactionId(item.id);
          const budgetId = budgetRef.docs[0]?.ref?.parent?.parent?.id;

          if (!budgetId) {
            item = {
              ...item,
              budget_id: 'N/A',
            };
          } else {
            const budget = await getDocument(() =>
              firebaseApp.getBudgetById(budgetId).get()
            );

            const budgetItemId = budgetRef?.docs[0]?.id;

            const budgetItem = await getDocument(() =>
              firebaseApp.getItemFromBudget(budgetId, budgetItemId).get()
            );

            const itemCategory = budgetItem
              ? budgetItem?.category?.label
                ? budgetItem?.category?.label
                : ''
              : '';

            item = {
              ...item,
              budget_id: budgetId,
              budget_name: budget.name,
              budget_category: budget.category.label,
              budget_item_category: itemCategory,
              budget_observation: budget.observation,
              budget_total_amount: budget.budget,
              budget_total_spent: budget.fare.total,
            };
          }
        }

        try{
          if (isTransactionFromOrder(item)) {
            const order = await getDocument(() =>
              firebaseApp.getOrderFromId(item.reserva).get()
            );

            item = {
              ...item,
              order_id: item.reserva,
              order_cost_center: order?.costCenter?.label || '',
            };
          }
        }catch(err){
          console.log(err, "Reserva não encontrada id: ", item.reserva);
        }

        return item;
      })
    );

    async function generateExcelFile({
      worksheetName,
      data = [],
      fields = [],
      messages,
      params = {},
    }) {
      if (!data.length) return null;

      const { columnWidth = 20, headerIdPrefix = 'statement.excel' } = params;

      const csv = getFieldsFromData(data, fields);

      const columns = fields.map((id) => ({
        header: messages[`${headerIdPrefix}.${id}`],
        id,
        width: columnWidth,
        style: { alignment: { vertical: 'middle', horizontal: 'center' } },
      }));

      columns[0].style = {
        alignment: { vertical: 'middle', horizontal: 'left' },
      };
      columns[columns.length - 1].style = {
        alignment: { vertical: 'middle', horizontal: 'right' },
      };

      return generateXLSX(worksheetName, columns, csv);
    }

    let blob = new Blob(
      [
        await generateExcelFile({
          worksheetName: 'Statement',
          data: transactions,
          fields: STATEMENT_REPORTS_FIELDS,
          messages,
          params: {
            headerIdPrefix: 'statement.excel',
          },
        }),
      ],
      {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      }
    );

    let url = window.URL.createObjectURL(blob);
    let fileName = `${moment
      .unix(dateSelection.startDate)
      .format('YYYY-MM-DD')} - ${moment
      .unix(dateSelection.endDate)
      .format('YYYY-MM-DD')}.xlsx`;
    downloadFromURL(url, fileName);

    setPrinting(false);
  };

  const handlePdfPrint = async () => {
    setPrinting(true);
    const response = await firebaseApp.printBank3Transactions(
      user.organizationId,
      moment.unix(dateSelection.startDate).format('YYYY-MM-DD'),
      moment.unix(dateSelection.endDate).format('YYYY-MM-DD')
    );

    let blob = new Blob([response], {
      type: 'application/pdf',
    });

    let url = window.URL.createObjectURL(blob);
    window.open(url);
    setPrinting(false);
  };

  // Render
  if (isLoading) {
    return <div className="loading" />;
  }

  if (creditCard === null) {
    return (
      <Row className="mt-5">
        <Colxx md="4">
          <img
            src="https://bank3.com.br/images/hero.png"
            style={{ width: '100%' }}
          />
        </Colxx>
        <Colxx md="7">
          <h3>{messages['admin.finance.bank3.add-b3-connector']}</h3>
          <Button
            color="primary"
            size="lg"
            className="top-right-button mt-3"
            onClick={() => openModalSetup()}
          >
            <IntlMessages id="admin.finance.creditCards.Add" />
          </Button>
        </Colxx>

        {resourceId && <Bank3Modal toggleModal={closeModal} />}
      </Row>
    );
  }

  return (
    <div>
      {budgetId && (
        <BudgetOrderModal
          budgetId={budgetId}
          itemId={itemId}
          closeModalPath="/data/finance"
          path="/data/finance/details/expenses"
        />
      )}
      <Row>
        <Colxx md="4" className="mt-3">
          {balance !== null && (
            <Card className="order-card">
              <CardBody>
                <p className="lead color-theme-1 mb-1 value">
                  {stringToMoney(balance, 2)}
                </p>
                <p className="mb-0 label text-small">
                  {messages['admin.finance.bank3.available-balance']}
                </p>
              </CardBody>
            </Card>
          )}

          {balance === null && (
            <Card className="order-card">
              <CardBody>
                <LoaderAnalyticsSingle />
              </CardBody>
            </Card>
          )}
        </Colxx>
      </Row>

      <Row className="top-container">
        <Colxx className="reports" xxs="5">
          <Row>
            <Colxx xxs="12" className="mt-4 d-flex align-items-center">
              <DateSelection
                dateSelection={dateSelection}
                setDateSelection={setCustomDate}
              />
            </Colxx>
          </Row>
        </Colxx>
        <Colxx xxs="7" className="d-flex align-items-end justify-content-end">
          <div className="ml-3 d-flex justify-content-center">
            <PrintDropdown
              arrow={true}
              typeOptions={STATEMENT_FILE_TYPES}
              showDetails={false}
              onPrint={({ value }) => {
                switch (value) {
                  case 'pdf':
                    handlePdfPrint();
                    break;

                  case 'ofx':
                    handleOfxPrint();
                    break;

                  case 'csv':
                    handleCsvPrint();
                    break;

                  case 'xlsx':
                      handleXlsxPrint();
                    break;
                }
              }}
              disabled={shouldDisableButtons}
            />
          </div>
        </Colxx>
      </Row>

      <div className="mt-4">
        {reverse(Object.keys(timeline)).map((date, index) => {
          return (
            <Bank3DateView
              date={timeline[date]}
              dateIndex={index}
              key={`dateView-${index}`}
              style={{ animationDelay: `${index * 100}ms` }}
              onRequestToOpenResource={onRequestToOpenResource}
            />
          );
        })}

        {Object.keys(timeline).length === 0 && (
          <>
            <LoaderCardWithText />
            <LoaderCardWithText />
            <LoaderCardWithText />
            <LoaderCardWithText />
            <LoaderCardWithText />
          </>
        )}
      </div>

      {travelId && (
        <OrderModal orderId={travelId} closeModalPath="/data/finance" />
      )}
    </div>
  );
}
