import React, { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import Documentation from '../../common/Documentation';
import PopupModal from '../../common/PopupModal/PopupModal';
import ItemDocumentation from './ItemDocumentation';

const documentationItems = [
  {
    id: 'days',
    items: [
      {
        title: 'budget-name-tooltip.d',
        result: 'DD',
      },
      {
        title: 'budget-name-tooltip.D',
        result: 'ddd',
      },
      {
        title: 'budget-name-tooltip.DD',
        result: 'dddd',
      },
    ],
  },
  {
    id: 'months',
    items: [
      {
        title: 'budget-name-tooltip.m',
        result: 'MM',
      },
      {
        title: 'budget-name-tooltip.M',
        result: 'MMM',
      },
      {
        title: 'budget-name-tooltip.MM',
        result: 'MMMM',
      },
    ],
  },
  {
    id: 'years',
    items: [
      {
        title: 'budget-name-tooltip.y',
        result: 'YY',
      },
      {
        title: 'budget-name-tooltip.a',
        result: 'YY',
      },
      {
        title: 'budget-name-tooltip.Y',
        result: 'YYYY',
      },
      {
        title: 'budget-name-tooltip.A',
        result: 'YYYY',
      },
    ],
  },
];

export default function BudgetNameDocumentationModal({
  show,
  toggle,
  target,
  calculateStyleOnShow,
  budgetCreatorModalClassName = 'refund-creator',
}) {
  const { messages } = useIntl();

  // States
  const items = useMemo(() => {
    return documentationItems.map(({ id, items }) => ({
      id,
      name: messages[`budget-name-tooltip.${id}.title`],
      Component: () => <ItemDocumentation id={id} items={items} />,
    }));
  }, []);

  // Effects
  useEffect(() => {
    if (budgetCreatorModalClassName) {
      const element = document.querySelector(`.${budgetCreatorModalClassName}`);

      if (!element) return;

      if (show) element.classList.add('hide');
      else element.classList.remove('hide');
    }
  }, [show, budgetCreatorModalClassName]);

  // Render
  return (
    <PopupModal
      className="budget-name-modal"
      show={show}
      toggle={toggle}
      target={target}
      displacementY={-39}
      calculateStyleOnShow={calculateStyleOnShow}
    >
      <Documentation items={items} toggle={toggle} />
    </PopupModal>
  );
}
