import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Button } from 'reactstrap';
import orderStatus from '../../constants/orderStatus';
import { useSelector } from 'react-redux';
import { getAuthRedux } from '../../utils/redux';
import useHistoryWithScroll from '../../hooks/useHistoryWithScroll';
import { CARDS_PATH } from '../../constants/paths';
import { getOrganizationConfig } from '../../utils/organization';

const openBookingMessages = {
  [orderStatus.ISSUING]: 'travel.order.status.title.open-booking.issuing',
  [orderStatus.MANUAL_ISSUING]:
    'travel.order.status.title.open-booking.issuing',
  [orderStatus.WAITING_ISSUE]: 'travel.order.status.title.open-booking.waiting',
  [orderStatus.ISSUED]: 'travel.order.status.title.issued',
  ADD_RECEIPT: 'travel.order.status.title.open-booking.receipt',
};

export default function OpenBookingIssueStatusInfo({
  order,
  goForOpenBookingSteps,
  shouldHideOpenBookingSteps,
  toggleModal,
}) {
  const { organization } = useSelector(getAuthRedux);
  const { messages } = useIntl();
  const { push } = useHistoryWithScroll();

  // States
  const { descriptionMessage, showButton, showOpenBudget } = useMemo(() => {
    let descriptionMessage = messages[openBookingMessages[order.statusCode]],
      showButton =
        order.statusCode > orderStatus.MANUAL_ISSUING &&
        order.statusCode < orderStatus.ALTERING;

    if (order.statusCode === orderStatus.ISSUED) {
      if (
        !order.openBookingInfo?.addedReceipt &&
        getOrganizationConfig(organization.config, 'openBooking.requireReceipt')
      ) {
        descriptionMessage = messages[openBookingMessages.ADD_RECEIPT];
      }
    }

    return {
      descriptionMessage,
      showButton,
      showOpenBudget:
        order.statusCode === orderStatus.ISSUED &&
        order.openBookingInfo?.receipts,
    };
  }, [order]);

  // Functions
  const goToBudget = () => {
    push(`${CARDS_PATH}/${order.id}`);
  };

  // Render
  const renderButton = () => {
    if (showOpenBudget && shouldHideOpenBookingSteps) {
      return (
        <>
          <hr className="my-3" />

          <Button
            className="m-0"
            name="open-booking"
            outline
            color="primary"
            onClick={goToBudget}
          >
            {messages['travel.order.open-booking.show-transaction']}
          </Button>
        </>
      );
    }

    return showButton ? (
      <>
        <hr className="my-3" />

        <Button
          className="m-0"
          name="open-booking"
          outline
          color="primary"
          onClick={goForOpenBookingSteps}
        >
          {messages['travel.order.open-booking.show-steps']}
        </Button>
      </>
    ) : null;
  };

  return (
    <div>
      {descriptionMessage}

      {renderButton()}
    </div>
  );
}
