import React, { useMemo, useState } from 'react';
import useShouldInitialize from '../../../hooks/useShouldInitialize';
import DefaultCardContainer from './DefaultCardContainer/DefaultCardContainer';
import { RefundTypes } from '../../../constants/refundTypes';
import { CARD_OPTIONS, CARD_OPTION_TYPES } from '../../../constants/creditCard';
import firebaseApp from '../../../services/Firebase';
import { useSelector } from 'react-redux';
import { getPassengerRedux } from '../../../utils/redux';
import CardOptions from '../CardOptions/CardOptions';
import OnlyWithFunds from '../../../hoc/OnlyWithFunds';
import ActivateCardButton from './ActivateCardButton';
import recordStatus from '../../../constants/recordStatus';
import AdministrativeCard from '../../../components/expenses/AdministrativeCard';

export default function AdministrativeAccountContainer({
  card,
  goToCreateBudget,
  goToRefund,
  goToCreateExpense,
  goToExpense,
  visible,
  lastCreatedBudget,
  onlyWithFunds,
  ...props
}) {
  const { passenger } = useSelector(getPassengerRedux);
  const shouldInitialize = useShouldInitialize(visible);

  // States
  const [canArchive, setCanArchive] = useState(false);

  const options = useMemo(
    () => [
      {
        ...CARD_OPTIONS[CARD_OPTION_TYPES.ARCHIVE],
        onClick: () =>
          firebaseApp.archiveCard(
            passenger.organizationId,
            passenger.uid,
            card.id
          ),
        hide: !canArchive,
      },
    ],
    [canArchive]
  );

  const isArchived = useMemo(
    () => card.status === recordStatus.ARCHIVED,
    [card]
  );

  // Render
  const renderBottom = () => {
    if (isArchived) return <ActivateCardButton className="mt-4" card={card} />;

    return (
      <CardOptions
        className="my-3"
        options={options}
        card={card}
        details={props}
      />
    );
  };

  return (
    <OnlyWithFunds active={onlyWithFunds}>
      <DefaultCardContainer
        className="personal-account-container"
        card={card}
        onCreateBudget={() =>
          goToCreateBudget(
            card.id,
            RefundTypes.ADMINISTRATIVE_ACCOUNT,
            card.user_id
          )
        }
        onRefundClick={goToRefund}
        onCreateExpense={goToCreateExpense}
        canCreateBudget={!isArchived}
        canCreateExpense={false}
        onExpenseClick={goToExpense}
        shouldInitialize={shouldInitialize}
        lastCreatedBudget={lastCreatedBudget}
        setCanArchive={(value) => value !== canArchive && setCanArchive(value)}
        {...props}
      >
        <div className="d-flex align-items-center justify-content-center flex-column card-container">
          <AdministrativeCard card={card} withHover={false} />
          {renderBottom()}
        </div>
      </DefaultCardContainer>
    </OnlyWithFunds>
  );
}
