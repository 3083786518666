import React, { useMemo } from 'react';
import recordStatus from '../../../constants/recordStatus';
import classNames from 'classnames';

import { ReactComponent as DeleteIcon } from '../../../assets/img/icons/delete.svg';
import { ReactComponent as AddIcon } from '../../../assets/img/icons/small_add.svg';

export default function BudgetDropArea({ show, status, onAction }) {
  // States
  const Icon = useMemo(
    () => (status === recordStatus.ACTIVE ? DeleteIcon : AddIcon),
    [status]
  );

  // Render
  return (
    <div
      className={classNames('budget-drop-area flex-center pointer', { show })}
      onClick={onAction}
    >
      <Icon className="icon text-primary" width="40%" height="40%" />
    </div>
  );
}
