import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import RetractableSearchBar from '../../../components/common/RetractableSearchBar';
import UserSwitcher from '../../../components/common/UserSwitcher';
import useDataSnapshot from '../../../hooks/useDataSnapshot';
import firebaseApp from '../../../services/Firebase';
import appFunctions from '../../../services/Functions';
import BudgetDropArea from './BudgetDropArea';
import GenericEmptyMessage from './GenericEmptyMessage';
import LoadingRecurrenceCard from './LoadingRecurrenceCard';
import RecurrenceCard from './RecurrenceCard';
import StatusSelector from './StatusSelector';
import { formatRecurrenceBudgets, getFilterFunction } from './utils';
import { useParams } from 'react-router-dom';
import RecurrenceBudgetModal from './RecurrenceBudgetModal/RecurrenceBudgetModal';
import useHistoryWithScroll from '../../../hooks/useHistoryWithScroll';

import recordStatus from '../../../constants/recordStatus';

import { ReactComponent as EmptyIcon } from '../../../assets/img/expenses/empty_recurrence.svg';
import { ReactComponent as SearchIcon } from '../../../assets/img/expenses/no_search_recurrence.svg';

export default function RecurrenceBudgetListing({
  cardWidth = 340,
  cardHeight = 180,
  cardSpacing = 20,
}) {
  const { messages } = useIntl();
  const { user, passenger } = useSelector(({ auth, timeline }) => ({
    user: auth.user,
    passenger: timeline.passenger || auth.user,
  }));

  // Navigation
  const { push } = useHistoryWithScroll();
  const { budgetId } = useParams();

  // Snapshots
  const [status, setStatus] = useState(recordStatus.ACTIVE);
  const [recurrenceBudgets, _, isLoading] = useDataSnapshot(
    {
      fetchFunction: () =>
        firebaseApp.getRecurrenceBudgets(
          passenger.organizationId,
          passenger.uid,
          status
        ),
      formatFunction: formatRecurrenceBudgets,
    },
    [passenger, status]
  );
  const [deletedBudgetIds, setDeletedBudgetIds] = useState([]);

  // States
  const [searchKey, setSearchKey] = useState('');
  const [selectedBudgets, setSelectedBudgets] = useState({});

  const isEmpty = useMemo(() => !recurrenceBudgets.length, [recurrenceBudgets]);

  const [filteredBudgets, noSearchResults] = useMemo(() => {
    const filterFunction = getFilterFunction({
      searchKey: searchKey.toLowerCase(),
      deletedBudgetIds,
    });
    let filteredBudgets = recurrenceBudgets;

    if (filterFunction) {
      filteredBudgets = recurrenceBudgets.filter(filterFunction);
    }

    return [filteredBudgets, !filteredBudgets.length];
  }, [recurrenceBudgets, searchKey, deletedBudgetIds]);

  const loadingCards = useMemo(() => {
    const width =
      (window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth) - 100;
    const height =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;

    const xCards = Math.trunc(width / (cardWidth + cardSpacing)),
      yCards = Math.trunc(height / (cardHeight + cardSpacing));

    return new Array(xCards * yCards).fill(true);
  }, [cardWidth, cardHeight, cardSpacing]);

  // Effects
  useEffect(() => {
    if (deletedBudgetIds.length) setDeletedBudgetIds([]);
    setSelectedBudgets({});
  }, [status]);

  // Functions
  const closeModal = useCallback(() => {
    push(`/payments/recurrence`);
  }, [push]);

  const goToBudget = useCallback(
    (budgetId) => {
      push(`/payments/recurrence/${budgetId}`);
    },
    [push]
  );

  const onBudgetAction = async () => {
    const { size, ...budgets } = selectedBudgets;
    const budgetIds = Object.keys(budgets),
      newStatus =
        status === recordStatus.ACTIVE
          ? recordStatus.DEACTIVATED
          : recordStatus.ACTIVE;

    try {
      setDeletedBudgetIds((arr = []) => arr.concat(budgetIds));
      setSelectedBudgets({});

      await Promise.all(
        budgetIds.map((id) =>
          appFunctions.updateRecurrenceBudgetStatus(id, newStatus)
        )
      );
    } catch (err) {
      console.error(err);
      setDeletedBudgetIds(...deletedBudgetIds);
    }
  };

  const toggleBudgetSelection = useCallback((id, value) => {
    setSelectedBudgets((budgets) => {
      const { [id]: item = value, size = 0, ...others } = budgets;

      return {
        ...others,
        size: size + (item ? -1 : 1),
        ...(item ? {} : { [id]: true }),
      };
    });
  }, []);

  // Render
  const renderHeader = () => {
    return (
      <div className="d-flex align-items-center mb-4" style={{ height: 60 }}>
        {user.admin ? <UserSwitcher className="mr-3" /> : null}

        <StatusSelector status={status} setStatus={setStatus} />

        {isEmpty ? null : (
          <RetractableSearchBar
            className="ml-2"
            value={searchKey}
            onChange={(e) => setSearchKey(e.target.value)}
            placeholder={messages['recurrence-budget.search']}
            width={200}
          />
        )}
      </div>
    );
  };
  const renderContent = () => {
    if (isLoading)
      return (
        <div className="recurrence-budget-listing-content">
          {loadingCards.map((_, index) => (
            <LoadingRecurrenceCard
              key={`loading-card-${index}`}
              width={cardWidth}
              height={cardHeight}
            />
          ))}
        </div>
      );

    if (isEmpty || (deletedBudgetIds.length && noSearchResults))
      return (
        <GenericEmptyMessage
          className="mt-5 show-up"
          title={messages['recurrence-budget.empty.title']}
          description={messages['recurrence-budget.empty.description']}
          Icon={EmptyIcon}
        />
      );

    if (noSearchResults)
      return (
        <GenericEmptyMessage
          className="show-up mt-5"
          title={messages['recurrence-budget.empty-search.title']}
          description={messages['recurrence-budget.empty-search.description']}
          Icon={SearchIcon}
        />
      );

    return (
      <div className="recurrence-budget-listing-content">
        {filteredBudgets.map((budget) => (
          <RecurrenceCard
            key={budget.id}
            budget={budget}
            width={cardWidth}
            height={cardHeight}
            onClick={() => goToBudget(budget.id)}
            toggle={() => toggleBudgetSelection(budget.id)}
            selected={selectedBudgets[budget.id]}
          />
        ))}
      </div>
    );
  };

  return (
    <div className="recurrence-budget-listing">
      {renderHeader()}

      {renderContent()}

      <BudgetDropArea
        show={selectedBudgets.size}
        status={status}
        onAction={onBudgetAction}
      />

      {budgetId ? (
        <RecurrenceBudgetModal budgetId={budgetId} closeModal={closeModal} />
      ) : null}
    </div>
  );
}
