import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import UsersList from '../../../components/UsersList';
import orderStatus from '../../../constants/orderStatus';
import useDataArchive from '../../../hooks/useDataArchive';
import firebaseApp from '../../../services/Firebase';
import { getDocument } from '../../../utils/firebase';
import { stringToMoney } from '../../../utils/money';
import GeneralInfo from './GeneralInfo';

export default function TravelInfo({ className = '', items }) {
  const { messages } = useIntl();
  const { getDataFromArchive: getData } = useDataArchive();

  // States
  const {
    numItems,
    numUsers,
    users = [],
    total,
    totalSpent,
  } = useMemo(() => {
    const usersObj = {};
    let total = 0,
      totalSpent = 0;

    items.forEach(({ passengerId, fare, statusCode }) => {
      const parsedTotal = parseFloat(fare.total);

      if (!usersObj[passengerId]) usersObj[passengerId] = true;
      if (statusCode === orderStatus.ISSUED) totalSpent += parsedTotal;

      total += parsedTotal;
    });

    const users = Object.keys(usersObj);

    return {
      numItems: items.length,
      numUsers: users.length,
      users,
      total: stringToMoney(total, 2),
      totalSpent: stringToMoney(totalSpent, 2),
    };
  }, [items]);

  // Functions
  const fetchUser = (userId) =>
    getData(`user-${userId}`, () =>
      getDocument(() => firebaseApp.getUserFromId(userId).get())
    );

  return (
    <GeneralInfo className={className} title={messages['general-view.travel']}>
      {(renderItem, renderDescription) => (
        <>
          {renderItem(
            messages['general-view.travel.created-reservations'],
            numItems,
            () =>
              renderDescription(
                messages['general-view.travel.created-reservations.description']
              )
          )}
          {renderItem(messages['general-view.travel.total'], total, () =>
            renderDescription(messages['general-view.travel.total.description'])
          )}
          {renderItem(
            messages['general-view.travel.total-spent'],
            totalSpent,
            () =>
              renderDescription(
                messages['general-view.travel.total-spent.description']
              )
          )}
          {renderItem(messages['general-view.active-users'], numUsers, () => (
            <div className="mt-2">
              <UsersList users={users} fetchFunction={fetchUser} />
            </div>
          ))}
        </>
      )}
    </GeneralInfo>
  );
}
