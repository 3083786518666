import React from 'react';
import CreditCard from './CreditCard';
import { ReactComponent as CardIcons } from '../../assets/img/expenses/card_icons.svg';

export default function PhysicalCard({ card, blocked, toggleBlock }) {
  // Render
  return (
    <CreditCard
      card={card}
      className="physical-card"
      textColor="white"
      Icon={() => (
        <div className="icon" style={{ width: 45 }}>
          <CardIcons />
        </div>
      )}
      blocked={blocked}
      toggleBlock={toggleBlock}
    />
  );
}
