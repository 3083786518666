import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Alert,
} from 'reactstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import IntlMessages from '../../helpers/IntlMessages';
import { useIntl } from 'react-intl';

import firebaseApp from '../../services/Firebase';
import { trackPropertiesSegment } from '../../utils/segment';

export default function Bank3Modal({ toggleModal }) {
  const { messages } = useIntl();
  const [error, setError] = useState(null);

  const {
    auth: { user },
    checklist: { setup },
  } = useSelector(({ auth, checklist }) => ({
    auth,
    checklist,
  }));
  const dispatch = useDispatch();

  // Handler
  const createCreditCard = async (
    { clientId, clientSecret },
    setSubmitting
  ) => {
    setError(null);

    try {
      await firebaseApp.createBank3FromOrganization(user.organizationId, {
        client_id: clientId,
        client_secret: clientSecret,
      });

      setSubmitting(false);
      trackPropertiesSegment('Bank 3 added', {
        user,
        client_id,
      });

      toggleModal();
    } catch (err) {
      if (err.message) setError(err.message);
      else setError(messages['forms.validation.creditCard.genericError']);

      setSubmitting(false);
    }
  };

  // Render
  return (
    <Modal isOpen={true} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal}>
        <IntlMessages id="admin.policy.bank3.Modal.Add" />
      </ModalHeader>
      <Formik
        initialValues={{
          clientId: '',
          clientSecret: '',
        }}
        validationSchema={Yup.object().shape({
          clientId: Yup.string().required(
            messages['forms.validation.clientId.required']
          ),
          clientSecret: Yup.string().required(
            messages['forms.validation.clientSecret.required']
          ),
        })}
        onSubmit={(values, { setSubmitting }) => {
          createCreditCard(values, setSubmitting);
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <ModalBody>
              {error && (
                <Row>
                  <Alert color="danger">{error}</Alert>
                </Row>
              )}

              <Row className="mb-1">
                <Label className="form-group has-top-label mb-0 w-100">
                  <Field
                    type="input"
                    name="clientId"
                    id="clientId"
                    className="form-control"
                  />
                  <IntlMessages id="admin.policy.bank3.clientId" />
                  <ErrorMessage
                    name="clientId"
                    component="div"
                    className="invalid-feedback d-block"
                  />
                </Label>
              </Row>
              <Row className="mb-1">
                <Label className="form-group has-top-label mb-0 w-100">
                  <Field
                    type="password"
                    name="clientSecret"
                    id="clientSecret"
                    className="form-control"
                  />
                  <IntlMessages id="admin.policy.bank3.clientSecret" />
                  <ErrorMessage
                    name="clientSecret"
                    component="div"
                    className="invalid-feedback d-block"
                  />
                </Label>
              </Row>
            </ModalBody>
            <ModalFooter>
              <div className="d-flex justify-content-between align-items-center">
                <Button
                  type="submit"
                  color="primary"
                  outline
                  size="lg"
                  disabled={isSubmitting}
                >
                  <IntlMessages id="forms.action.save" />
                </Button>
              </div>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
