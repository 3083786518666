import React from 'react';
import { Row, Card, CardBody, CardTitle } from 'reactstrap';
import { Colxx } from '../../common/CustomBootstrap';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import sumBy from 'lodash/sumBy';

import LoaderChart from '../../loaders/LoaderChart';
import Pizza from '../../charts/Pizza';

export default function ESGTopProjects({ orders, loading }) {
  const projects = groupBy(orders, (o) => o.project?.label || 'Nenhum');

  if (loading) {
    return (
      <Card className="mb-2">
        <CardBody>
          <CardTitle>Projetos que mais emitiram CO2</CardTitle>
          <Row className="w-100">
            <Colxx xxs="12" className="text-center">
              <LoaderChart />
            </Colxx>
          </Row>
        </CardBody>
      </Card>
    );
  }

  return (
    <Card className="mb-2">
      <CardBody>
        <CardTitle>Projetos que mais emitiram CO2</CardTitle>
        <Row className="w-100">
          <Colxx xxs="12">
            <Pizza
              data={{
                labels: Object.keys(projects),
                datasets: [
                  {
                    data: map(projects, (orders) =>
                      sumBy(orders, (o) => parseInt(o.esg.distance_km) || 0)
                    ),
                    backgroundColor: [
                      '#434189',
                      '#58569d',
                      '#7372b5',
                      '#b1b0dc',
                      '#d4d4e6',
                    ],
                    hoverBackgroundColor: [
                      '#434189',
                      '#58569d',
                      '#7372b5',
                      '#b1b0dc',
                      '#d4d4e6',
                    ],
                    pointStyle: 'circle',
                  },
                ],
              }}
            />
          </Colxx>
        </Row>
      </CardBody>
    </Card>
  );
}
