import React from 'react';
import ContentLoader from 'react-content-loader';

export default function LoaderSmallLine() {
  return (
    <ContentLoader
      speed={2}
      width={'100%'}
      height={90}
      viewBox="0 0 350 90"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="40" y="0" rx="2" ry="2" width="140" height="30" />
      <rect x="0" y="0" rx="2" ry="2" width="30" height="30" />
      <circle cx="18" cy="81" r="3" />
      <circle cx="58" cy="80" r="3" />
      <circle cx="98" cy="79" r="3" />
      <circle cx="138" cy="78" r="3" />
      <circle cx="178" cy="76" r="3" />
      <circle cx="218" cy="76" r="3" />
      <circle cx="258" cy="72" r="3" />
      <circle cx="299" cy="65" r="3" />
      <circle cx="339" cy="48" r="3" />
    </ContentLoader>
  );
}
