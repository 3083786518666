import React from 'react';
import { Card } from 'reactstrap';
import { Colxx } from '../common/CustomBootstrap';
import ContentLoader from 'react-content-loader';
import LoadingTotal from './LoadingTotal';

export default function LoadingHotel() {
  return (
    <Card className="search-card hotel mt-5">
      <Colxx xxs="12" lg="9" className="p-0">
        <ContentLoader
          speed={2}
          width={600}
          height={200}
          viewBox="0 0 600 200"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
        >
          <rect x="0" y="0" rx="0" ry="0" width="170" height="200" />
          <rect x="180" y="10" rx="1" ry="1" width="300" height="20" />
          <rect x="180" y="40" rx="1" ry="1" width="150" height="12" />
          <circle cx="190" cy="70" r="10" />
          <circle cx="220" cy="70" r="10" />
        </ContentLoader>
      </Colxx>
      <Colxx xxs="12" lg="3" className="text-right pt-0">
        <LoadingTotal />
      </Colxx>
    </Card>
  );
}
