import { TOGGLE_BUDGET_DRAGGING } from '../actions';

const INITIAL_STATE = {
  isDraggingBudget: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TOGGLE_BUDGET_DRAGGING: {
      return {
        ...state,
        isDraggingBudget: action.payload,
      };
    }
    default: {
      return { ...state };
    }
  }
};
