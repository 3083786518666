import React from 'react';
import { useIntl } from 'react-intl';
import { Row } from 'reactstrap';
import { Colxx } from 'src/portao3-legacy/components/common/CustomBootstrap';
import MerchantToggles from './MerchantToggles';

import { RefundTypes } from 'src/portao3-legacy/constants/refundTypes';
import {
  PhysicalCategories,
  VirtualCategories,
} from 'src/portao3-legacy/constants/budget';
import { ReactComponent as MerchantIcon } from 'src/portao3-legacy/assets/img/expenses/merchants.svg';

export default function MerchantsView() {
  const { messages } = useIntl();

  // Render
  return (
    <Row className="d-flex justify-content-between mt-5 pb-5 ml-3 w-100">
      <Colxx
        xxs="12"
        md="5"
        className="d-flex justify-content-center align-self-start"
      >
        <MerchantIcon width="90%" />
      </Colxx>
      <Colxx xxs="12" md="7" className="mt-3 pl-3">
        <p className="text-one w-70 mt-3 slide-left">
          {messages['admin.refunds.merchants.description']}
        </p>

        <MerchantToggles
          className="mt-4 slide-left"
          id={RefundTypes.PHYSICAL_CARD}
          merchants={PhysicalCategories}
        />
        <MerchantToggles
          className="mt-4 slide-left"
          id={RefundTypes.VIRTUAL_CARD}
          merchants={VirtualCategories}
        />
      </Colxx>
    </Row>
  );
}
