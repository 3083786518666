import { flushSync } from 'react-dom';
import { useEffect, useCallback, useState } from 'react';
import { splitData } from '../utils/batch';
import { mapIntoObject } from '../utils/others';

const createEmptyBatchData = (params = {}) => ({
  loading: false,
  progress: 0,
  ...params,
});

export default function useBatchJobs({ jobs, partitionSize = 500 }) {
  const [loading, setLoading] = useState(false);
  const [batchData, setBatchData] = useState({});

  // Functions
  const addToBatchData = useCallback((key, data) => {
    flushSync(() => {
      setBatchData((batchData) => ({
        ...batchData,
        [key]: {
          ...batchData[key],
          ...data,
        },
      }));
    });
  }, []);

  const runBatch = useCallback(
    async (key, { data, partitionSize, jobFunction }) => {
      try {
        addToBatchData(key, { loading: true, progress: 0 });

        const partitions = splitData(data, partitionSize);

        let doneJobs = 0,
          failedJobs = 0;

        for (let i = 0, length = partitions.length; i < length; i++) {
          const partition = partitions[i];

          const jobResults = await Promise.all(
            partition.map((item) =>
              jobFunction(item)
                .then(() => 1)
                .catch((err) => {
                  console.error('Unable to process batch item', item, err);
                  return -1;
                })
            )
          );

          jobResults.map((value) =>
            value > 0 ? (doneJobs += 1) : (failedJobs += 1)
          );
          addToBatchData(key, { progress: (i / length) * 100 });
        }

        const result = {
          successful: doneJobs,
          failed: failedJobs,
          total: data.length,
        };

        addToBatchData(key, { loading: false, progress: 100, ...result });

        return result;
      } catch (err) {
        console.error(`Unable to run batch job (${key})`, err);

        const result = {
          successful: 0,
          failed: data.length,
          total: data.length,
        };
        addToBatchData(key, {
          loading: false,
          failed: true,
          progress: 100,
          ...result,
        });

        return { successful: 0, failed: data.length, total: data.length };
      }
    },
    []
  );

  const runJob = useCallback(
    async (key, data, job = {}) => {
      try {
        const {
          jobFunction,
          batch = true,
          interpretResults = (value) => value,
          partitionSize: _partitionSize = partitionSize,
        } = job;

        if (batch) {
          return runBatch(key, {
            data,
            partitionSize: _partitionSize,
            jobFunction,
          });
        } else {
          addToBatchData(key, { loading: true, progress: 50 });

          const result = await jobFunction(data);
          const interpretedResults = interpretResults(result, data);

          addToBatchData(key, {
            loading: false,
            progress: 100,
            ...interpretedResults,
          });

          return interpretedResults;
        }
      } catch (err) {
        console.error(`Unable to run job (${key})`, err);

        const result = { successful: 0, failed: data.length };
        addToBatchData(key, {
          loading: false,
          failed: true,
          progress: 100,
          ...result,
        });

        return result;
      }
    },
    [partitionSize]
  );

  const doBatchJob = useCallback(
    async (data, concurrent = true) => {
      try {
        if (!jobs) return;

        const keys = Object.keys(data).filter((key) => data[key].length);

        if (!keys.length) return null;

        setLoading(true);
        setBatchData(
          mapIntoObject(
            keys,
            keys.map(() => createEmptyBatchData({ loading: concurrent }))
          )
        );

        let batchResult = {};

        if (concurrent) {
          batchResult = mapIntoObject(
            keys,
            await Promise.all(
              keys.map((key) => runJob(key, data[key], jobs[key]))
            )
          );
        } else {
          for (let i = 0, length = keys.length; i < length; i++) {
            const key = keys[i];
            batchResult[key] = await runJob(key, data[key], jobs[key]);
          }
        }

        return batchResult;
      } catch (err) {
        console.error('Unable to run batch job', err);
      } finally {
        setLoading(false);
      }
    },
    [jobs, runJob]
  );

  return { doBatchJob, loading, batchData };
}
