import property from 'lodash/property';
import User from '../services/User';

export const mapPermissions = (permissions = {}) => {
  const addKey = (obj, prefix = '') => {
    return Object.keys(obj).reduce((acc, key) => {
      const finalKey = prefix ? `${prefix}.${key}` : key;
      const value = obj[key];

      if (typeof value === 'object') {
        return addKey(value, finalKey, acc);
      } else {
        acc[finalKey] = value;
        return acc;
      }
    }, {});
  };

  return addKey(permissions);
};

export const isUserAllowed = (user, permissions = []) => {
  const userPermissions = user?.permissions || {};

  return (
    User.isAdmin(user) ||
    permissions.some((permission) => property(permission)(userPermissions))
  );
};
