import React, { useMemo } from 'react';
import { Row } from 'reactstrap';
import { Colxx } from '../common/CustomBootstrap';
import moment from 'moment';
import { registeredAirlines } from '../../constants/travelValues';

import AirportName from './AirportName';
import CityName from './CityName';
import { useIntl } from 'react-intl';

function FlightInfo({ journey, params, index, flight }) {
  const { messages } = useIntl();

  // States
  const { logo, departureDate, departureTime, arrivalDate } = useMemo(() => {
    const departureDateTime = moment(
        flight.departureDateTime,
        'DD/MM/YYYY HH:mm'
      ),
      arrivalDateTime = moment(flight.arrivalDateTime, 'DD/MM/YYYY HH:mm');

    return {
      logo: registeredAirlines[flight.airline]?.logo,
      departureDate: departureDateTime.format('ddd, DD MMM YYYY'),
      departureTime: departureDateTime.format('HH:mm'),
      arrivalDate: arrivalDateTime.format('ddd, DD MMM YYYY'),
      arrivalTime: arrivalDateTime.format('HH:mm'),
    };
  }, [flight]);

  // Render
  return (
    <div>
      <div className="d-flex justify-content-between">
        <div className="d-flex justify-content-start">
          {index === 0 && (
            <div className="journey-title d-flex align-items-center">
              <img
                src="/assets/img/icons/search_icon.svg"
                alt={messages['alts.components.flight.journey.journey']}
              />
              <h3>
                <CityName station={journey.departureStation} params={params} />{' '}
                • <CityName station={journey.arrivalStation} params={params} />
              </h3>
            </div>
          )}
        </div>
      </div>
      <Row className="px-3 mt-3 origin">
        <Colxx
          xxs="12"
          className="d-flex align-items-center justify-content-between"
        >
          <div className="d-flex align-items-center">
            {logo ? (
              <div
                className="carrier"
                style={{
                  backgroundImage: `url(${logo})`,
                }}
              />
            ) : null}
          </div>
        </Colxx>
      </Row>
      <Row className="px-3 mt-3">
        <Colxx xxs="4" className="origin">
          <p>{departureDate}</p>
          <p className="highlight">{departureTime}</p>
          <p className="mt-2">
            <CityName station={flight.departureStation} params={params} />
          </p>
          <p className="highlight">
            {flight.departureStation?.name
              ? flight.departureStation.name
              : flight.departureStation}
          </p>
          <p>
            <AirportName station={flight.departureStation} params={params} />
          </p>
        </Colxx>
        <Colxx xxs="4" className="duration">
          <img
            src="/assets/img/icons/search_icon.svg"
            alt={messages['alts.components.flight.journey.duration']}
          />
        </Colxx>
        <Colxx xxs="4" className="destination">
          <p>{arrivalDate}</p>
          <p className="highlight">--:--</p>
          <p className="mt-2">
            <CityName station={flight.arrivalStation} params={params} />
          </p>
          <p className="highlight">
            {flight.arrivalStation?.name
              ? flight.arrivalStation.name
              : flight.arrivalStation}
          </p>
          <p>
            <AirportName station={flight.arrivalStation} params={params} />
          </p>
        </Colxx>
      </Row>
    </div>
  );
}

export default function OpenBookingJourneyLarge({ journey, segments, params }) {
  // Render
  return (
    <div className="journey pb-5 mb-5 mt-1">
      {segments.map((segment, index) => (
        <FlightInfo
          key={`flight-${index}`}
          flight={segment}
          index={index}
          journey={journey}
          params={params}
        />
      ))}
    </div>
  );
}
