import classNames from 'classnames';
import React, { useEffect, useMemo, useRef } from 'react';
import LoaderCardWithText from '../components/loaders/LoaderCardWithText';
import { Button } from 'reactstrap';
import { useIntl } from 'react-intl';

export default function LazyLoadingTrackerWrapper({
  className,
  disabled,
  loading,
  children,
  onReachEnd = () => {},
  numLoadingItems = 10,
  LoadingItemComponent = LoaderCardWithText,
  trackerSize = 30,
  trackerBottomPosition = 300,
  loadingContainerClassName = 'mx-3',
}) {
  const { messages } = useIntl();

  // Refs
  const trackerRef = useRef(null);

  // States
  const loadingItems = useMemo(
    () => new Array(numLoadingItems).fill(true),
    [numLoadingItems]
  );

  // Effects
  useEffect(() => {
    if (trackerRef.current && !disabled) {
      const observer = new IntersectionObserver((entries) => {
        if (entries[0]?.isIntersecting) onReachEnd();
      });

      observer.observe(trackerRef.current);

      return () => observer.disconnect();
    }
  }, [disabled]);

  // Render
  const renderLoading = () => {
    if (!loading || !LoadingItemComponent) return null;

    return (
      <div
        className={classNames('loading-container', loadingContainerClassName)}
      >
        {loadingItems.map((_, index) => (
          <LoadingItemComponent key={`loading-${index}`} />
        ))}
      </div>
    );
  };

  const renderButton = () => {
    if (disabled || loading) return null;

    return (
      <div className="w-100 flex-center my-4">
        <Button color="primary" className="m-0 pretty-btn" onClick={onReachEnd}>
          {messages['general.fetch-more']}
        </Button>
      </div>
    );
  };

  return (
    <div
      className={classNames(
        'lazy-loading-tracker w-100 position-relative',
        className
      )}
    >
      {children}

      {renderLoading()}

      <div
        className="tracker align-self-center w-100 position-absolute pointer-none"
        ref={trackerRef}
        style={{
          backgroundColor: 'transparent',
          height: trackerSize,
          left: 0,
          right: 0,
          bottom: trackerBottomPosition,
        }}
      />

      {renderButton()}
    </div>
  );
}
