import React, { useMemo } from 'react';
import classnames from 'classnames';

export default function ProfileImage({
  user,
  className,
  rounded,
  small,
  xsmall,
  xxsmall,
  loading = false,
  style = {},
  title = '',
}) {
  const image = useMemo(
    () => (loading ? '' : user?.avatar || '/assets/img/profile-pic-l.png'),
    [user, loading]
  );

  return (
    <div
      style={{
        ...style,
        backgroundImage: `url(${image})`,
      }}
      title={title}
      className={`img-thumbnail list-thumbnail align-self-center ${className} ${classnames(
        {
          'rounded-circle': rounded,
          small: small,
          xsmall: xsmall,
          xxsmall: xxsmall,
          'loading-img': loading,
        }
      )}`}
    />
  );
}
