import React, { PureComponent } from 'react';

import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import config from '../../constants/GoogleMaps';
import map from 'lodash/map';
import HotelProximitySearch from '../../containers/search/HotelProximitySearch';

const GOOGLE_API_KEY = config.key;

const fetchedPlaces = {};

class FavoritePlacesMap extends PureComponent {
  constructor(props) {
    super(props);

    this.mapRef = React.createRef();

    this.state = {
      coords: props.coords,
      defaultBounds: null,
      geocoder: new props.google.maps.Geocoder(),
    };
  }

  //Lifecycle
  componentDidUpdate(prevProps) {
    const { highlightedPin: oldHighlightedPin } = prevProps;
    const { highlightedPin } = this.props;

    if (JSON.stringify(oldHighlightedPin) !== JSON.stringify(highlightedPin)) {
      if (highlightedPin) {
        const bounds = new window.google.maps.LatLngBounds(highlightedPin);
        this.mapRef.current.map.fitBounds(bounds);
      } else {
        if (this.state.defaultBounds)
          this.mapRef.current.map.fitBounds(this.state.defaultBounds);
        else this.defineBounds();
      }
    }

    if (prevProps.coords !== this.props.coords) {
      this.setState({ coords: this.props.coords }, () => this.defineBounds());
    }
  }

  mapLoaded(_, map) {
    map.setOptions({ styles: config.mapStyle });

    this.defineBounds();
  }

  defineBounds() {
    const { coords = [] } = this.state;

    const bounds = new window.google.maps.LatLngBounds();

    if (coords.length > 0) {
      map(coords, (coord) => {
        bounds.extend(new window.google.maps.LatLng(coord.lat, coord.lng));
        return true;
      });
    }

    if (this.props.highlightedPin) {
      bounds.extend(
        new window.google.maps.LatLng(
          this.props.highlightedPin.lat,
          this.props.highlightedPin.lng
        )
      );
    }

    this.mapRef.current.map.fitBounds(bounds);

    this.setState({ defaultBounds: bounds });
  }

  filterMarker = (hotelId) => {
    this.props.toggleSelectedHotels(hotelId);
  };

  getAddressFromLatLng = async (location) => {
    const storedAddress = fetchedPlaces[`${location.lat},${location.lng}`];

    if (storedAddress) return storedAddress;
    else {
      const { results } = await this.state.geocoder.geocode({ location });

      const address = results[0]?.formatted_address || '';

      fetchedPlaces[`${location.lat},${location.lng}`] = address;

      return address;
    }
  };

  handleDoubleClick = async (event, map, coord) => {
    const { latLng } = coord;

    const location = { lat: latLng.lat(), lng: latLng.lng() };

    this.props.toggleHighlightedPin({
      ...location,
      loading: true,
    });

    const address = await this.getAddressFromLatLng(location);

    this.props.toggleHighlightedPin({
      ...location,
      address,
      loading: false,
    });
  };

  // Render
  render() {
    const {
      highlightedPin,
      toggleHighlightedPin,
      className = '',
      highlightedPinIcon = '/assets/img/icons/highlighted_heart_pin.svg',
      favoritedPinIcon = '/assets/img/icons/highlighted_heart_pin.svg',
      pinIcon = '/assets/img/icons/pin_heart.svg',
      selectedPlaces = {},
      proximityPlaceholder,
    } = this.props;
    const { coords } = this.state;

    return (
      <div className={`favorite-places-map hotel-map ${className}`}>
        <HotelProximitySearch
          setHighlightPin={toggleHighlightedPin}
          fullLocation
          includeCity={this.props.includeCity}
          placeholder={proximityPlaceholder}
        />

        <Map
          google={this.props.google}
          zoom={14}
          scrollwheel={false}
          keyboardShortcuts={false}
          disableDoubleClickZoom
          zoomControl
          mapTypeControl={false}
          maxZoom={15}
          scaleControl={false}
          streetViewControl={false}
          panControl={false}
          rotateControl={false}
          fullscreenControl={false}
          onReady={(mapProps, map) => this.mapLoaded(mapProps, map)}
          ref={this.mapRef}
          onDblclick={this.handleDoubleClick}
        >
          {map(coords, (coord) => {
            return (
              <Marker
                key={`hotel-map-${coord.id}`}
                position={{ lat: coord.lat, lng: coord.lng }}
                onClick={() => {
                  toggleHighlightedPin(coord);
                }}
                icon={{
                  url: coord.id in selectedPlaces ? favoritedPinIcon : pinIcon,
                  innerWidth: 10,
                  outerWidth: 10,
                  innerHeight: 10,
                  outerHeight: 10,
                }}
              />
            );
          })}

          {highlightedPin && (
            <Marker
              key="highlighted-pin"
              position={{ lat: highlightedPin.lat, lng: highlightedPin.lng }}
              onClick={() => toggleHighlightedPin(null)}
              icon={{
                url: highlightedPinIcon,
              }}
            />
          )}
        </Map>
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: GOOGLE_API_KEY,
})(FavoritePlacesMap);
