import React, { useEffect, useRef, useMemo, useState } from 'react';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Row } from 'reactstrap';
import { BarcodeTypes, ItemLoadingStages } from '../../../constants/budget';
import { expenseTypes } from '../../../constants/refundTypes';
import useItems from '../../../hooks/useItems';
import firebaseApp from '../../../services/Firebase';
import appFunctions from '../../../services/Functions';
import {
  calculateTotal,
  createBudget,
  formatReceiptBudgetValues,
  getItemBudgetValues,
  modifyBudgetItem,
  tagItems,
  uploadBudgetItemReceipts,
} from '../../../utils/budget';
import { analyseBarcodes } from '../../../utils/receipts';
import { Colxx } from '../../common/CustomBootstrap';
import LoadingStages from '../../common/LoadingStages';
import { ExpenseItems } from '../../expensesTimeline/ExpenseItems';
import ExpenseItemsParams from '../../expensesTimeline/ExpenseItemsParams';
import InvoiceParams from '../../expensesTimeline/InvoiceParams';
import BarcodeMessages from './BarcodeMessages';
import ExpenseBottom from './ExpenseBottom';
import QrCodeMessages from './QrCodeMessages';
import ReceiptFileHandler from '../ReceiptFileHandler';
import { getPassengerRedux } from '../../../utils/redux';
import SupplierParams from '../../expensesTimeline/SupplierParams';
import { stringToMoney } from '../../../utils/money';
import FailedToReadInvoiceMessage from './FailedToReadInvoiceMessage';
import { CanAddInvoice, RefundStatus } from '../../../constants/refundStatus';
import useHasChanged from '../../../hooks/useHasChanged';
import { isItemsEqual } from '../../../utils/equalities';
import { EditModes } from '../../../constants/editModes';

const valuesFields = ['name', 'location', 'date', 'category'];

export default function ReceiptExpense({
  budget,
  item,
  toggle,
  isNew,
  performAction,
  editable,
  updateTotal,
  atBottom,
  canCreateItem,
}) {
  const { messages, formatMessage } = useIntl();
  const { passenger } = useSelector(getPassengerRedux);

  const didSaveRef = useRef(false);
  const isEmptyRef = useRef(false);

  const [itemId, setItemId] = useState(item?.id);
  const [values, setValues] = useState(
    isNew ? { name: '', description: '' } : getItemBudgetValues(item)
  );

  const [itemType, setItemType] = useState(expenseTypes.ITEMS);
  const {
    items,
    setItems,
    removeItem,
    modifyItem,
    total,
    isValid: isItemsValid,
    onItemBlur,
  } = useItems(item.items);

  const [canScanReceipt, setCanScanReceipt] = useState(false);
  const [barcodes, setBarcodes] = useState(null);

  const [receiptId, setReceiptId] = useState(null);
  const [invoice, setInvoice] = useState(item?.invoice);

  const [showLoadingStages, setShowLoadingStages] = useState(false);
  const [loadingStage, setLoadingStage] = useState(-1);
  const [failedToRead, setFailedToRead] = useState(false);

  const [paddingBottom, setPaddingBottom] = useState(0);

  // Memos
  const isValuesValid = useMemo(
    () => valuesFields.every((field) => values[field]),
    [values]
  );
  const receipts = useMemo(() => item?.receipts, []);
  const canEditReceipt = useMemo(
    () => CanAddInvoice.includes(budget.statusCode) && !item?.refunded,
    [budget, item]
  );
  const showReceipts = useMemo(
    () =>
      item?.refunded
        ? item.receipts?.length
        : canEditReceipt || editable || item.receipts?.length,
    [editable, item, canEditReceipt]
  );

  const { itemTotal, formattedDiscount } = useMemo(() => {
    const { total: itemTotal = total, discount = 0 } = invoice
      ? invoice?.fare || item?.fare
      : {};

    isEmptyRef.current = itemTotal === 0;

    return {
      itemTotal,
      formattedDiscount: discount
        ? stringToMoney(discount, 2, budget.fare.currency)
        : null,
    };
  }, [total, item, invoice]);

  const infoText = useMemo(() => {
    if (editable) return '';
    else {
      const length = items.length;
      return formatMessage(
        {
          id: `refund.item-expense.${length > 1 ? 'plural' : 'singular'}-info`,
        },
        { items: length }
      );
    }
  }, [editable, items]);

  const cardTransaction = useMemo(
    () => !!item?.invoice?.card_transaction,
    [item]
  );

  const editMode = useMemo(() => {
    const { statusCode } = budget;

    if (!editable) return EditModes.NONE;

    if (statusCode === RefundStatus.ACTIVE) return EditModes.FULL;

    if ([RefundStatus.WAITING, RefundStatus.REVIEW].includes(statusCode)) {
      return cardTransaction || invoice ? EditModes.PARTIAL : EditModes.FULL;
    }

    return EditModes.NONE;
  }, [budget, cardTransaction, invoice, editable]);

  // Hooks
  const originalState = useMemo(() => {
    if (item?.id) {
      return {
        values: {
          value: values,
        },
        items: {
          value: items,
          isEqualFunction: isItemsEqual,
        },
      };
    } else return {};
  }, [item]);

  const [hasChanged, checkEquality] = useHasChanged(originalState);

  const canCreate = useMemo(() => {
    const canCreate = Boolean(isValuesValid && (isItemsValid || invoice));
    return item?.id ? hasChanged && canCreate : canCreate;
  }, [isValuesValid, isItemsValid, invoice, hasChanged, item]);

  // Effects
  useEffect(() => {
    return () => {
      if (item.id && !budget.withAnticipation && isEmptyRef.current) {
        firebaseApp.removeEmptyBudgetItem(budget.id, item.id);
      }
    };
  }, []);

  useEffect(() => {
    if (!item.id && itemId) {
      return () => {
        if (!didSaveRef.current) {
          firebaseApp.removeEmptyBudgetItem(budget.id, itemId);
        }
      };
    }
  }, [itemId]);

  useEffect(() => {
    checkEquality('items', items);
  }, [items]);

  useEffect(() => {
    checkEquality('values', values);
  }, [values]);

  // Functions
  const createEmptyBudgetItem = async () => {
    if (itemId) return itemId;
    else {
      const docRef = await firebaseApp.createEmptyBudgetItem(
        budget.id,
        passenger
      );
      setItemId(docRef.id);
      return docRef.id;
    }
  };

  const analyseReceipt = ({ barcode, keys = [] }, itemId, receipt) => {
    if (barcode) {
      if (barcode.type === BarcodeTypes['1D']) {
        if (keys.length > 1) {
          const barcodes = analyseBarcodes(keys);
          setBarcodes(barcodes);
        } else handleBarcodeRead(keys[0], itemId, receipt.filename);
      } else if (barcode.type === BarcodeTypes['2D']) {
        setCanScanReceipt(true);
      }
    }
  };

  const handleReceiptUpload = async (files) => {
    try {
      setShowLoadingStages(true);

      const itemId = await createEmptyBudgetItem();

      setLoadingStage(0);

      const receipts = await uploadBudgetItemReceipts(budget.id, itemId, files);
      setLoadingStage(1);
      const receipt = receipts[0];

      setItemId(itemId);
      setReceiptId(receipt.filename);

      if (budget.statusCode !== RefundStatus.WAITING) {
        const { data } = await appFunctions.readReceipt(
          budget.id,
          itemId,
          receipt
        );
        setShowLoadingStages(false);
        analyseReceipt(data, itemId, receipt);
      } else setShowLoadingStages(false);
    } catch (err) {
      console.error(err);
      setShowLoadingStages(false);
    }
  };

  const handleReceiptDelete = async () => {
    if (!(editable || canEditReceipt)) return;

    return performAction(async () => {
      try {
        await appFunctions.deleteReceipts(budget.id, itemId);

        if (!invoice && canCreateItem) {
          setItems([]);
          setInvoice(null);
        }
      } catch (err) {
        console.error('Unable to delete receipt');
      }
    }, false);
  };

  const doCreateBudgetItem = () => {
    performAction(async () => {
      await createBudget({
        budgetId: budget.id,
        user: passenger,
        values: formatReceiptBudgetValues(values),
        items,
        type: expenseTypes.ITEMS,
      });

      didSaveRef.current = true;

      updateTotal(calculateTotal(items));
    });
  };

  const doModifyBudgetItem = () => {
    performAction(async () => {
      const oldTotal = item?.fare?.total || 0;
      const total = invoice ? invoice?.fare?.total || oldTotal || 0 : 0;

      await modifyBudgetItem({
        values: formatReceiptBudgetValues(values, invoice),
        items,
        budgetId: budget.id,
        budgetItemId: itemId,
        user: item?.passengerInfo ? null : passenger,
        total,
      });

      didSaveRef.current = true;

      if (total) updateTotal(total - oldTotal);
      else if (item.items)
        updateTotal(calculateTotal(items) - calculateTotal(item.items));
      else updateTotal(calculateTotal(items));
    });
  };

  const onCreate = () =>
    item?.id || invoice || receiptId
      ? doModifyBudgetItem()
      : doCreateBudgetItem();

  const handleImport = async (importFunction) => {
    try {
      setShowLoadingStages(true);
      setLoadingStage(2);
      const { data } = await importFunction();
      populateFields(data);
    } catch (err) {
      console.error(err);
      setFailedToRead(true);
    } finally {
      setShowLoadingStages(false);
    }
  };

  const handleQrCodeRead = ({ qrcode, state }) => {
    setCanScanReceipt(false);
    handleImport(() =>
      appFunctions.importQrCode(budget.id, itemId, receiptId, qrcode, state)
    );
  };

  const handleBarcodeRead = (
    barcode,
    _itemId = itemId,
    _receiptId = receiptId
  ) => {
    handleImport(() =>
      appFunctions.importAccessCode(budget.id, _itemId, _receiptId, barcode)
    );
  };

  const handleImportReject = () => {
    setBarcodes(null);
    setCanScanReceipt(false);
    setReceiptId(null);
  };

  const populateFields = (data) => {
    const { date, items, location, invoice, fare } = data;

    setValues((values) => ({
      ...values,
      date: moment(date),
      location: {
        label: location,
      },
      name: values.name,
      category: values.category,
      description: values.description,
    }));

    setItems(tagItems(items));

    setInvoice({
      ...invoice,
      date,
      fare,
    });

    setLoadingStage(false);
  };

  const handleToggle = () => {
    if (item?.id && !itemTotal && !invoice) {
      firebaseApp.removeEmptyBudgetItem(budget.id, itemId);
    }

    toggle();
  };

  // Render
  const renderInfo = () => {
    return <p className="m-0 p-0 w-40 info text-right">{infoText}</p>;
  };

  const renderTotalAddional = () => {
    if (item?.refunded) {
      return (
        <p className="m-0 p-0 per-km mt-1 text-warning font-weight-medium">
          {messages['refund.item-expense.refunded']}
        </p>
      );
    } else if (formattedDiscount) {
      return (
        <p className="m-0 p-0 text-primary per-km mt-1">
          {messages['receipt-expense.discount']} {formattedDiscount}
        </p>
      );
    }

    return null;
  };

  return (
    <>
      <div style={{ paddingBottom }}>
        <Row className="bg-secondary-light h-auto p-0 m-0 w-100 receipt-container">
          {showReceipts ? (
            <Colxx xxs="5" className="p-0 position-relative overflow-hidden">
              <FailedToReadInvoiceMessage
                show={failedToRead}
                toggle={(value) => setFailedToRead(!value)}
              />
              <LoadingStages
                show={showLoadingStages}
                stages={ItemLoadingStages}
                stage={loadingStage}
              />
              <QrCodeMessages
                show={canScanReceipt}
                onReject={handleImportReject}
                onDone={handleQrCodeRead}
              />
              <BarcodeMessages
                show={barcodes}
                barcodes={barcodes}
                onDone={handleBarcodeRead}
                onReject={handleImportReject}
              />
              <ReceiptFileHandler
                receipts={receipts}
                budgetId={budget.id}
                budgetItemId={itemId}
                editable={canEditReceipt}
                onUpload={handleReceiptUpload}
                onDelete={handleReceiptDelete}
                canDoAction={!(showLoadingStages || canScanReceipt)}
              />
            </Colxx>
          ) : null}
          <Colxx xss={showReceipts ? '7' : '12'}>
            <ExpenseItemsParams
              user={passenger}
              editable={editable}
              values={values}
              setValues={setValues}
              expenseType={itemType}
              setExpenseType={setItemType}
              isNew={true}
              budget={budget}
              invoice={invoice}
              editMode={editMode}
            />
          </Colxx>
        </Row>

        <div style={{ minHeight: 250 }}>
          {cardTransaction ? null : (
            <>
              <div className="mt-4 mb-3 ml-2">
                <h5>{messages['receipt-expense.items']}</h5>
              </div>
              <div className="px-2">
                <ExpenseItems
                  items={items}
                  onItemChange={modifyItem}
                  onItemDelete={removeItem}
                  onItemBlur={onItemBlur}
                  editable={editable && !invoice}
                />
              </div>
            </>
          )}
          {invoice ? (
            <>
              <div>
                <div className="mt-4 ml-2">
                  <h5>
                    {
                      messages[
                        cardTransaction
                          ? 'receipt-expense.transaction-info'
                          : 'receipt-expense.invoice-info'
                      ]
                    }
                  </h5>
                </div>
                <InvoiceParams invoice={invoice} />
              </div>
              {invoice.supplier?.documents?.cnpj?.number ? (
                <div>
                  <div className="mt-4 ml-2">
                    <h5>{messages['receipt-expense.supplier-info']}</h5>
                  </div>
                  <SupplierParams
                    cnpj={invoice.supplier?.documents?.cnpj?.number}
                  />
                </div>
              ) : null}
            </>
          ) : null}
        </div>
      </div>

      <ExpenseBottom
        budget={budget}
        total={itemTotal}
        onCancel={handleToggle}
        onCreate={onCreate}
        canCreate={canCreate}
        editable={editMode !== EditModes.NONE}
        isNew={isNew}
        atBottom={atBottom}
        InfoComponent={renderInfo}
        setPaddingBottom={setPaddingBottom}
        TotalAdditionalComponent={renderTotalAddional}
        multiplier={1.5}
      />
    </>
  );
}
