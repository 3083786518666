import React from 'react';
import AnimateHeight from 'react-animate-height';
import { Button, Row } from 'reactstrap';
import { Colxx } from './common/CustomBootstrap';
import { useIntl } from 'react-intl';
import classNames from 'classnames';

export default function OpenBookingAction({
  onCreateReservation,
  show,
  titleMessage = 'open-booking.action.title',
  descriptionMessage = 'open-booking.action.description',
  btnMessage = 'open-booking.action.btn',
  allowed,
  className = '',
}) {
  const { messages } = useIntl();

  // Render
  if (!allowed) return null;

  return (
    <AnimateHeight height={show ? 'auto' : 0} easing="ease">
      <section className={classNames('open-booking-action card', className)}>
        <Row className="m-0 p-4">
          <Colxx xxs="7" className="m-0 p-0">
            <h3 className="m-0 p-0 text-primary font-primary fs-1-25rem mb-2">
              {messages[titleMessage]}
            </h3>
            <p className="m-0 p-0 text-muted fs-0-8rem lh-1-25">
              {messages[descriptionMessage]}
            </p>
          </Colxx>
          <Colxx
            xxs="5"
            className="m-0 p-0 d-flex align-items-center justify-content-end"
          >
            <Button
              color="primary"
              onClick={onCreateReservation}
              className="px-4"
            >
              {messages[btnMessage]}
            </Button>
          </Colxx>
        </Row>
      </section>
    </AnimateHeight>
  );
}
