import React from 'react';
import { CardBody, Row } from 'reactstrap';
import { Colxx } from '../../components/common/CustomBootstrap';
import filter from 'lodash/filter';
import sumBy from 'lodash/sumBy';
import meanBy from 'lodash/meanBy';
import { useIntl } from 'react-intl';

import FlightMap from './FlightMap';

import { OrderTypes } from '../../constants/orderTypes';

import { stringToMoney } from '../../utils/money';

export default function FlightCardSummary({ orders, airports }) {
  const { messages } = useIntl();

  const flightOrders = filter(orders, (o) => {
    return o.type === OrderTypes.FLIGHT;
  });

  return (
    <CardBody>
      <Row>
        <Colxx sm="6">
          <div className="d-flex flex-row mb-3 pb-3 justify-content-between align-items-center">
            <div className="flex-fill">
              <p className="font-weight-medium mb-0">
                {
                  messages[
                    'containers.dashboard.flight-card-summary.total-spent'
                  ]
                }
              </p>
              <p className="text-muted mb-0 text-small">
                {
                  messages[
                    'containers.dashboard.flight-card-summary.flight-total'
                  ]
                }
              </p>
            </div>
            <div>
              {stringToMoney(
                sumBy(flightOrders, (o) => {
                  return o.fare.total;
                })
              )}
            </div>
          </div>

          <div className="d-flex flex-row mb-3 pb-3 justify-content-between align-items-center">
            <div className="flex-fill">
              <p className="font-weight-medium mb-0">
                {
                  messages[
                    'containers.dashboard.flight-card-summary.average-fare'
                  ]
                }
              </p>
              <p className="text-muted mb-0 text-small">
                {
                  messages[
                    'containers.dashboard.flight-card-summary.fare-average-value'
                  ]
                }
              </p>
            </div>
            <div>
              {stringToMoney(
                meanBy(flightOrders, (o) => {
                  return o.fare.total;
                }) || 0
              )}
            </div>
          </div>

          <div className="d-flex flex-row mb-3 pb-3 justify-content-between align-items-center">
            <div className="flex-fill">
              <p className="font-weight-medium mb-0">
                {
                  messages[
                    'containers.dashboard.card-summary.total-reservations'
                  ]
                }
              </p>
              <p className="text-muted mb-0 text-small">
                {
                  messages[
                    'containers.dashboard.card-summary.reservations-per-period'
                  ]
                }
              </p>
            </div>
            <div>{flightOrders.length}</div>
          </div>
        </Colxx>
        <Colxx sm="5" className="text-right">
          <FlightMap airports={airports} />
        </Colxx>
      </Row>
    </CardBody>
  );
}
