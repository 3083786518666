import React, { useEffect, useMemo, useState } from 'react';
import { Input } from 'reactstrap';
import { policyFieldType } from '../../constants/policyOptions';
import { useIntl } from 'react-intl';

import Select from 'react-select';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';
import { getCurrencySymbol } from '../../utils/money';
import { getOrganizationConfig } from '../../utils/organization';
import MaskedInput from 'react-text-mask';

function DataInput({ rule, value, onChange, getDataFromArchive }) {
  const { messages } = useIntl();
  const { user, organization } = useSelector(({ auth }) => auth);

  const [options, setOptions] = useState([]);
  const [currentValue, setCurrentValue] = useState(null);

  useEffect(() => {
    const f = async () => {
      const { fetchFunction, formatData, dataKey } = rule;

      setOptions([]);

      if (fetchFunction) {
        const data = await getDataFromArchive(
          dataKey,
          fetchFunction({ messages, user, organization }),
          formatData
        );
        setOptions(data);
        if (value) setCurrentValue(data.find((item) => item.value === value));
      }
    };

    f();
  }, [rule]);

  return (
    <Select
      className="react-select w-100"
      classNamePrefix="react-select"
      isClearable={false}
      placeholder=""
      value={currentValue}
      onChange={(item) => {
        onChange(item.value);
        setCurrentValue(item);
      }}
      options={options}
    />
  );
}

function CurrencyInput({ value, onChange }) {
  const { organization } = useSelector(({ auth }) => auth);

  const prefix = useMemo(() => {
    return `${getCurrencySymbol(
      getOrganizationConfig(organization.config, 'currency')
    )} `;
  }, [organization]);

  return (
    <NumberFormat
      prefix={prefix}
      suffix={''}
      thousandSeparator="."
      decimalSeparator=","
      decimalScale="2"
      fixedDecimalScale="2"
      className="currency-input w-100"
      defaultValue={value}
      onValueChange={(v) => onChange(v.floatValue)}
      allowNegative={false}
    />
  );
}

function BooleanInput({ value, onChange }) {
  const { messages } = useIntl();

  const values = useMemo(
    () => [
      {
        value: true,
        label: messages['general.yes'],
      },
      {
        value: false,
        label: messages['general.no'],
      },
    ],
    []
  );

  const [currentValue, setCurrentValue] = useState(
    values.find((item) => item.value === value)
  );

  return (
    <Select
      className="react-select"
      classNamePrefix="react-select"
      isClearable={false}
      name="form-field-name"
      placeholder=""
      value={currentValue}
      onChange={(item) => {
        onChange(item.value);
        setCurrentValue(item);
      }}
      options={values}
    />
  );
}

function MaskInput({ value, onChange, rule }) {
  const { mask = [], placeholder = '', onBlur = () => {} } = rule;

  return (
    <MaskedInput
      type="text"
      placeholder={placeholder}
      value={value}
      mask={mask}
      onChange={(e) => onChange(e.target.value)}
      onBlur={(e) => onBlur(e, onChange)}
      className="mask-input w-100"
    />
  );
}

function NumberMask({ value, onChange, rule }) {
  const { mask = {} } = rule;

  return (
    <NumberFormat
      className="currency-input w-100"
      value={value}
      onValueChange={(v) => onChange(v.floatValue)}
      {...mask}
    />
  );
}

function NumberInput({ value, onChange }) {
  return (
    <Input
      type="number"
      value={value}
      onChange={(e) => onChange(e.target.value)}
    />
  );
}

function DefaultInput({ value, onChange }) {
  return <Input value={value} onChange={(e) => onChange(e.target.value)} />;
}

const COMPONENTS = {
  [policyFieldType.BOOLEAN]: BooleanInput,
  [policyFieldType.CURRENCY]: CurrencyInput,
  [policyFieldType.MASK]: MaskInput,
  [policyFieldType.DATA]: DataInput,
  [policyFieldType.NUMBER]: NumberInput,
  [policyFieldType.NUMBER_MASK]: NumberMask,
};

export default function PolicyInput({ rule = {}, ...props }) {
  const Component = useMemo(
    () => COMPONENTS[rule.type] || DefaultInput,
    [rule]
  );

  return <Component key={rule.value} rule={rule} {...props} />;
}
