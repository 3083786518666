import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Button, ModalBody, ModalFooter, Label } from 'reactstrap';
import IntlMessages from '../../helpers/IntlMessages';
import firebaseApp from '../../services/Firebase';
import { useIntl } from 'react-intl';
import { trackPropertiesSegment } from '../../utils/segment';
import Switch from 'rc-switch';
import { PermissionTypes } from '../../constants/permissions';
import { mapPermissions } from '../../utils/permissions';

export default function PermissionsTab({ toggleModal, userId, editUser }) {
  const { user } = useSelector((state) => state.auth);
  const { messages } = useIntl();

  const [admin, setAdmin] = useState(editUser.admin);

  const [saving, setSaving] = useState(false);

  const [permissions, setPermissions] = useState(() =>
    mapPermissions(editUser.permissions)
  );

  const hasPermissions = useMemo(
    () => Object.values(permissions).some((p) => p),
    [permissions]
  );

  const handlePermissionChange = (name) => (value) =>
    setPermissions((permissions) => ({
      ...permissions,
      [name]: value,
    }));

  const renderDataPermissionItems = () => (
    <section className="ml-3">
      <Label className="d-flex align-items-center justify-content-between">
        <span className="fs-0-9rem pr-5">
          {messages['containers.users.user-modal-permissions-tab.data.budget']}
        </span>
        <Switch
          disabled={admin}
          checked={permissions[PermissionTypes.DATA_BUDGET]}
          onChange={handlePermissionChange(PermissionTypes.DATA_BUDGET)}
          className="mr-2 custom-switch flex-shrink-0"
        />
      </Label>
      <Label className="d-flex align-items-center justify-content-between">
        <span className="fs-0-9rem pr-5">
          {messages['containers.users.user-modal-permissions-tab.data.travel']}
        </span>
        <Switch
          disabled={admin}
          checked={permissions[PermissionTypes.DATA_TRAVEL]}
          onChange={handlePermissionChange(PermissionTypes.DATA_TRAVEL)}
          className="mr-2 custom-switch flex-shrink-0"
        />
      </Label>
      <Label className="d-flex align-items-center justify-content-between">
        <span className="fs-0-9rem pr-5">
          {messages['containers.users.user-modal-permissions-tab.esg']}
        </span>
        <Switch
          disabled={admin}
          checked={permissions[PermissionTypes.DATA_ESG]}
          onChange={handlePermissionChange(PermissionTypes.DATA_ESG)}
          className="mr-2 custom-switch flex-shrink-0"
        />
      </Label>
      <Label className="d-flex align-items-center justify-content-between">
        <span className="fs-0-9rem pr-5">
          {
            messages[
              'containers.users.user-modal-permissions-tab.data.statement'
            ]
          }
        </span>
        <Switch
          disabled={admin}
          checked={permissions[PermissionTypes.DATA_STATEMENT]}
          onChange={handlePermissionChange(PermissionTypes.DATA_STATEMENT)}
          className="mr-2 custom-switch flex-shrink-0"
        />
      </Label>
    </section>
  );

  function convertToDBPermissions(permissions = {}, prefix = 'permissions.') {
    return Object.keys(permissions).reduce((obj, key) => {
      obj[`${prefix}${key}`] = Boolean(permissions[key]);
      return obj;
    }, {});
  }

  // Update User
  const updateUser = async () => {
    try {
      setSaving(true);

      await firebaseApp.updateUserFromId(
        userId,
        {
          admin,
          ...convertToDBPermissions(permissions),
        },
        true
      );

      trackPropertiesSegment('User info updated', {
        whoUpdated: user,
        userInfo: { admin, permissions },
      });

      toggleModal();
    } catch (err) {
      setError(messages['user.GeneralException']);
      console.error(err);
    } finally {
      setSaving(false);
    }
  };

  return (
    <>
      <ModalBody className="mt-2">
        <Label className="d-flex align-items-center justify-content-between">
          <span className="fs-0-9rem pr-5">
            {messages['containers.users.user-modal-permissions-tab.admin']}
          </span>
          <Switch
            data-testid="admin-switch"
            disabled={editUser.uid === user.uid || !user.admin}
            checked={admin}
            onChange={setAdmin}
            className="mr-2 custom-switch flex-shrink-0"
          />
        </Label>
        <Label className="d-flex align-items-center justify-content-between mt-4">
          <span className="fs-0-9rem pr-5">
            {messages['containers.users.user-modal-permissions-tab.timeline']}
          </span>
          <Switch
            data-testid="admin-switch"
            disabled={admin}
            checked={permissions[PermissionTypes.TIMELINE]}
            onChange={handlePermissionChange(PermissionTypes.TIMELINE)}
            className="mr-2 custom-switch flex-shrink-0"
          />
        </Label>
        <Label className="d-flex align-items-center justify-content-between mt-4">
          <span className="fs-0-9rem pr-5">
            {messages['containers.users.user-modal-permissions-tab.budgets']}
          </span>
          <Switch
            data-testid="admin-switch"
            disabled={admin}
            checked={permissions[PermissionTypes.BUDGETS]}
            onChange={handlePermissionChange(PermissionTypes.BUDGETS)}
            className="mr-2 custom-switch flex-shrink-0"
          />
        </Label>
        <Label className="d-flex align-items-center justify-content-between mt-4">
          <span className="fs-0-9rem pr-5">
            {
              messages[
                'containers.users.user-modal-permissions-tab.data.access'
              ]
            }
          </span>
          <Switch
            data-testid="admin-switch"
            disabled={admin}
            checked={permissions[PermissionTypes.DATA_ACCESS]}
            onChange={handlePermissionChange(PermissionTypes.DATA_ACCESS)}
            className="mr-2 custom-switch flex-shrink-0"
          />
        </Label>
        {permissions[PermissionTypes.DATA_ACCESS]
          ? renderDataPermissionItems()
          : null}
      </ModalBody>
      <ModalFooter className="mb-5">
        <div className="d-flex align-items-center">
          <Button
            type="submit"
            color="primary"
            outline
            size="lg"
            className="mb-5"
            onClick={updateUser}
            disabled={saving}
          >
            <IntlMessages id="forms.action.save" />
          </Button>
        </div>
      </ModalFooter>
    </>
  );
}
