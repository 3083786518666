import React, { useMemo, useState } from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import BudgetCreatorStatus from '../BudgetCreatorStatus';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import {
  PhysicalCategories,
  VirtualCategories,
} from '../../../constants/budget';
import firebaseApp from '../../../services/Firebase';
import { HasBudget, RefundTypes } from '../../../constants/refundTypes';
import { getDefaultCommonParams } from '../../../utils/reservation';
import useDataArchive from '../../../hooks/useDataArchive';
import { getDocument } from '../../../utils/firebase';
import TabSwitch from '../TabSwitch';
import BudgetInfoTab from './tabs/BudgetInfoTab';
import RecurrenceTab from './tabs/RecurrenceTab';
import appFunctions from '../../../services/Functions';
import { createBudgetPayload } from '../../../utils/budget';
import { useEffect } from 'react';
import useBudgetCategories from 'src/portao3-legacy/hooks/useBudgetCategories';

const TABS = {
  budget: 'budget',
  recurrence: 'recurrence',
};

export default function BudgetCreatorModal({
  toggleModal = () => {},
  cardId,
  cardType,
  userId,
  onCreateBudget = () => {},
}) {
  const { messages } = useIntl();
  const { passenger, organization } = useSelector(({ auth, timeline }) => ({
    passenger: timeline.passenger || auth.user,
    organization: auth.organization,
  }));

  // Tabs
  const tabs = useMemo(
    () =>
      cardType === RefundTypes.PERSONAL_ACCOUNT
        ? [
            {
              key: TABS.budget,
              label: messages['refund.modal.tabs.budget'],
            },
          ]
        : [
            {
              key: TABS.budget,
              label: messages['refund.modal.tabs.budget'],
            },
            {
              key: TABS.recurrence,
              label: messages['refund.modal.tabs.recurrence'],
            },
          ],
    []
  );

  // Hooks
  const { getDataFromArchive } = useDataArchive();
  const budgetCategories = useBudgetCategories({
    cardType,
  });

  // States
  const [tab, setTab] = useState(() => tabs[0].key);
  const [budget, setBudget] = useState(getDefaultCommonParams(passenger));
  const [recurrence, setRecurrence] = useState({});
  const [isSaving, setIsSaving] = useState(false);

  const needsBudget = useMemo(() => HasBudget.includes(cardType), [cardType]);

  const Tab = useMemo(
    () => (tab === TABS.budget ? BudgetInfoTab : RecurrenceTab),
    [tab]
  );

  // Effects
  useEffect(() => {
    if (budget.startDate && budget.endDate) {
      if (budget.endDate.isBefore(budget.startDate)) {
        setBudget((budget) => ({ ...budget, endDate: null }));
      }
    }
  }, [budget.startDate, budget.endDate]);

  // Function
  const onBudgetChange = (value) => {
    setBudget({
      ...budget,
      ...value,
    });
  };

  const getFormattedBudgetInfo = async () => {
    const {
      name,
      budget: budgetValue,
      startDate,
      endDate,
      refundType = '',
      ...others
    } = budget;

    const budgetOwner =
      !userId || userId === passenger.uid
        ? passenger
        : await getDataFromArchive(`user-${userId}`, () =>
            getDocument(() => firebaseApp.getUserFromId(userId).get())
          );

    const newBudget = {
      name,
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
      ...others,
    };

    if (budgetValue) {
      newBudget['budget'] = budgetValue;
    }

    if (cardId) newBudget['cardId'] = cardId;

    return {
      budget: newBudget,
      budgetOwner,
    };
  };

  const createBudget = async () => {
    try {
      setIsSaving(true);
      const { budget, budgetOwner } = await getFormattedBudgetInfo();

      const budgetPayload = createBudgetPayload({
        passenger: budgetOwner,
        organization,
        budget,
        parseName: true,
      });

      if (recurrence?.type) {
        await appFunctions.createBudgetRecurrence({
          budget: {
            ...budgetPayload,
            name: budget.name,
          },
          recurrence,
        });
      }

      const docRef = await firebaseApp.createBudget(budgetPayload);

      onCreateBudget({ cardId, budgetId: docRef.id });
      toggleModal();
    } catch (err) {
      console.error(err);
    } finally {
      setIsSaving(false);
    }
  };

  const createRecurrence = async () => {
    try {
      setIsSaving(true);
      const { budget, budgetOwner } = await getFormattedBudgetInfo();

      const budgetPayload = createBudgetPayload({
        passenger: budgetOwner,
        organization,
        budget,
      });

      await appFunctions.createBudgetRecurrence({
        budget: budgetPayload,
        recurrence,
      });

      toggleModal();
    } catch (err) {
      console.error(err);
    } finally {
      setIsSaving(false);
    }
  };

  // Render
  return (
    <Modal
      isOpen={true}
      toggle={() => {
        toggleModal();
      }}
      className="reservation refund-creator"
    >
      <ModalBody className="hotel">
        <PerfectScrollbar
          options={{
            suppressScrollX: true,
            wheelPropagation: false,
          }}
        >
          <TabSwitch
            className="budget-creation-tabs ml-2"
            items={tabs}
            currentTab={tab}
            setTab={setTab}
            navLinkClassName="mx-2"
            navTitleClassName="font-primary fs-0-8rem"
          />

          <div className="mt-4 mb-5 pb-4">
            <Tab
              budget={budget}
              onBudgetChange={onBudgetChange}
              hasBudget={needsBudget}
              budgetCategories={budgetCategories}
              setBudget={setBudget}
              recurrence={recurrence}
              setRecurrence={setRecurrence}
              cardType={cardType}
            />
          </div>
        </PerfectScrollbar>
      </ModalBody>

      <ModalFooter className="p-3">
        <BudgetCreatorStatus
          budget={budget}
          cardType={cardType}
          onCreate={createBudget}
          onRecurrenceClick={createRecurrence}
          isSaving={isSaving}
          recurrence={recurrence}
        />
      </ModalFooter>
    </Modal>
  );
}
