import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { ReactComponent as LuggageIcon } from '../../../../../../assets/img/icons/luggage.svg';
import OptionCard from '../../OptionCard';

export default function BaggageSection({
  onClick = () => {},
  className = '',
  items = [],
  ...props
}) {
  const { messages } = useIntl();

  const itemDescription = useMemo(() => {
    const bags = items.filter((item) => item.product_type === 'bag');
    if (bags.length > 0) {
      return `+${bags.length}`;
    } else return '';
  }, [items]);

  return (
    <OptionCard
      title={messages['flight-alteration.baggage-sec.title']}
      description={messages['flight-alteration.baggage-sec.description']}
      buttonText={messages['general.add']}
      itemDescription={itemDescription}
      onClick={onClick}
      className={className}
      Icon={LuggageIcon}
      {...props}
    />
  );
}
