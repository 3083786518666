import React from 'react';
import BottomInfoCard from '../common/BottomInfoCard';
import OrderStatusTitle from './OrderStatusTitle';

export default function OrderIssueCard({
  show,
  order,
  reservations,
  ...props
}) {
  return (
    <BottomInfoCard show={show} {...props}>
      <div className="order-issue-card px-3 py-2">
        <OrderStatusTitle
          order={order}
          reservations={reservations}
          tooltipFill="#43418e"
        />
      </div>
    </BottomInfoCard>
  );
}
