import { DEFAULT_CURRENCY } from './money';

export function mapCurrencyToProps({ auth }) {
  return { currency: auth.organization?.currency || DEFAULT_CURRENCY };
}

export const parseJSONFromLocalStorage = (key) => {
  try {
    const data = localStorage.getItem(key);
    return JSON.parse(data) || null;
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const getPassengerRedux = ({ timeline, auth }) => ({
  passenger: timeline.passenger || auth.user,
});

export const getAuthRedux = ({ auth }) => auth;
