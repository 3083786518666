import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import TopNav from '../containers/navs/Topnav';
import Sidebar from '../containers/navs/Sidebar';

import MENU_DATA from '../constants/menuData';
import { getAuthRedux } from '../utils/redux';
import { isUserAllowed } from '../utils/permissions';

export default function DataLayout({ history, children }) {
  const { user = {} } = useSelector(({ auth }) => auth);

  // States
  const menuItems = useMemo(
    () => MENU_DATA.filter((item) => isUserAllowed(user, [item.id])),
    [user]
  );

  // Render
  return (
    <div id="data-container" className="admin-menu">
      <Sidebar items={menuItems} />

      <main className="data-main">
        <TopNav history={history} />
        <div className="container-fluid">{children}</div>
      </main>
    </div>
  );
}
