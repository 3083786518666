import React, { Component } from 'react';
import { Card, Button } from 'reactstrap';
import { Colxx } from '../../components/common/CustomBootstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import times from 'lodash/times';
import orderBy from 'lodash/orderBy';
import map from 'lodash/map';

import { stringToMoney } from '../../utils/money';
import HotelAmenities from '../../components/order/HotelAmenities';
import { injectIntl } from 'react-intl';

class HotelItem extends Component {
  // Render
  renderImage() {
    const { item } = this.props;

    let backgroundImage = '/assets/img/default-hotel-bw.png';
    if (item.hotel.mainPhoto !== '') backgroundImage = item.hotel.mainPhoto;

    return (
      <div
        className="hotel-image"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      />
    );
  }

  render() {
    const {
      item,
      intl: { messages },
    } = this.props;

    return (
      <Card className="search-card hotel mt-5">
        <Colxx lg="12" className="pl-0 pr-0">
          <div className="row ml-0 h-100">
            <Colxx xxs="4" className="pl-0">
              {this.renderImage()}
            </Colxx>
            <Colxx xxs="8" className="pt-2">
              <h3 className="hotel-title">
                {item.hotel.name}
                <span className="ml-2">
                  {times(item.hotel.category, (index) => {
                    return (
                      <img
                        key={`hotel-${item.hotelId}-category-${index}`}
                        src="/assets/img/hotel-star.svg"
                        className="ml-1"
                        alt={
                          messages[
                            'alts.components.search.hotel-item.hotel-category'
                          ]
                        }
                      />
                    );
                  })}
                </span>
              </h3>

              <p className="text-muted">{item.hotel.destination.address}</p>

              <HotelAmenities hotel={item.hotel} hotelId={item.hotelId} />

              <div className="scroll">
                <table className="reservation-rooms">
                  <thead>
                    <tr>
                      <th>
                        <h5>
                          {
                            messages[
                              'containers.search.hotel-modal.room-category'
                            ]
                          }
                        </h5>
                      </th>
                      <th>
                        <h5>{messages['general.price']}</h5>
                      </th>
                      <th />
                    </tr>
                  </thead>
                </table>

                <PerfectScrollbar
                  options={{
                    suppressScrollX: true,
                    wheelPropagation: false,
                  }}
                >
                  <table className="reservation-rooms">
                    <tbody>
                      {map(orderBy(item.rooms, 'fare'), (room, index) => {
                        // const roomDetails = _.find(item.hotel.rooms, {
                        //    id: room.mediaId,
                        //});

                        return (
                          <tr key={`room-${index}`}>
                            <td>{room.description}</td>
                            <td>{stringToMoney(room.fare)}</td>
                            <td>
                              <Button
                                size="xs"
                                color="light"
                                onClick={() => {
                                  this.props.changeHotel(item, room);
                                }}
                              >
                                {messages['general.select']}
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </PerfectScrollbar>
              </div>
            </Colxx>
          </div>
        </Colxx>
      </Card>
    );
  }
}

export default injectIntl(HotelItem);
