import React from 'react';
import { useIntl } from 'react-intl';
import { Button } from 'reactstrap';
import MessageWithIcon from '../../../../common/MessageWithIcon';
import AlterationFrame from '../../AlterationFrame';

import { ReactComponent as ExclamationIcon } from '../../../../../assets/img/icons/exclamation.svg';

export default function FinalTab({ goForAlteration }) {
  const { messages } = useIntl();

  // Render
  const renderBottom = () => (
    <div className="d-flex justify-content-end">
      <Button color="link" onClick={goForAlteration} className="m-0">
        {messages['general.ok']}
      </Button>
    </div>
  );

  return (
    <AlterationFrame
      title={messages['travel.alter.flight.cancellation.final.title']}
      subtitle={messages['travel.alter.flight.cancellation.final.description']}
      BottomBar={renderBottom}
    >
      <MessageWithIcon
        className="mt-3 show-up"
        text={messages['travel.alter.flight.cancellation.final.successful']}
        Icon={ExclamationIcon}
        iconStyle={{ width: '7%' }}
        style={{ animationDelay: '200ms' }}
      />
    </AlterationFrame>
  );
}
