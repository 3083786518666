import moment from 'moment';
import { useEffect, useState } from 'react';

export default function useLazyDataLoader({
  fetchFunction,
  interval,
  dateField = 'createdAt',
  formatFunction = (orders) => orders,
  idName = 'orderId',
}) {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [splitDelimiter, setSplitDelimiter] = useState(null);
  const [selectedData, setSelectedData] = useState([]);

  useEffect(() => {
    if (interval) {
      fetchData();
    }
  }, [interval]);

  const fetchData = async () => {
    setIsLoading(true);
    let allData = data;

    const [startAt, endAt] =
      interval.mode === 'all'
        ? [0, moment().unix()]
        : [interval.startDate, interval.endDate];

    if (splitDelimiter === null || startAt < splitDelimiter) {
      const newData = [];

      let dataSnap = await fetchFunction()
        .where(dateField, '>=', startAt)
        .where(
          dateField,
          '<=',
          splitDelimiter === null ? moment().unix() : splitDelimiter
        )
        .get();

      dataSnap.forEach((doc) => {
        newData.push({
          ...doc.data(),
          [idName]: doc.id,
        });
      });

      const formattedData = await formatFunction(newData);

      allData = [...data, ...formattedData].sort(
        (a, b) => b[dateField] - a[dateField]
      );
      setData(allData);
      setSplitDelimiter(startAt);
    }

    setSelectedData(
      allData.filter(
        (value) => value[dateField] >= startAt && value[dateField] <= endAt
      )
    );
    setIsLoading(false);
  };

  return [selectedData, isLoading];
}
