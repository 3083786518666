export const countriesNoNeedPassport = [
  'AR',
  'BR',
  'BO',
  'CL',
  'CO',
  'EC',
  'PY',
  'PE',
  'UY',
  'VE',
];
