import React, { useEffect, useState } from 'react';
import { Input, Label, Row } from 'reactstrap';
import FixedSelect from '../../components/common/FixedSelect';
import { Colxx } from '../../components/common/CustomBootstrap';
import CustomErrorMessage from '../../components/CustomErrorMessage';
import { useIntl } from 'react-intl';
import { countries } from '../../constants/countries';
import { isAfter } from 'date-fns';
import { formatDate } from '../../utils/date';

export default function PassportParams({ passport, setPassport }) {
  const { messages } = useIntl();

  //States
  const [errors, setErrors] = useState({});
  const [numberPassport, setNumberPassport] = useState('');
  const [validatePassport, setValidatePassport] = useState('');
  const [countryPassport, setCountryPassport] = useState('');

  const handleErrors = (name, value = true) => {
    setErrors({
      ...errors,
      [name]: value,
    });
  };

  const setPassportParametters = (name, value) => {
    setPassport({
      ...passport,
      [name]: value,
    });
  };

  const PassportCountries = Object.keys(countries)
    .filter((value) => value)
    .map((value) => ({
      value,
      label: countries[value],
    }));

  const handlePassportNumber = (numberPassport) => {
    const regexPassport = /^[A-Z0-9]{6,9}$/;
    const passportNumber = numberPassport.target.value;

    const validPassport = regexPassport.test(passportNumber);

    if (validPassport) {
      setPassportParametters('number', passportNumber);
      handleErrors('passportNumber', false);
    } else {
      setPassportParametters('number', undefined);
      handleErrors('passportNumber', true);
    }

    setNumberPassport(passportNumber);
  };

  const handlePassportValidationDate = (date) => {
    const inputDate = date.target.value;

    const { parsedInputdate, formatted } = formatDate(inputDate);
    const today = new Date();

    if (isAfter(parsedInputdate, today)) {
      setPassportParametters('validUntil', formatted);
      handleErrors('validate', false);
    } else {
      setPassportParametters('validUntil', undefined);
      handleErrors('validate', true);
    }

    setValidatePassport(formatted);
  };

  const handlePassportCountry = (country) => {
    setPassportParametters('country', country);
    setCountryPassport(country);
  };

  useEffect(() => {
    if (!passport) {
      setNumberPassport('');
      setValidatePassport('');
      setCountryPassport('');
    }
  }, [passport]);

  useEffect(() => {
    if (passport) {
      const countryPass = PassportCountries.find(
        (obj) => obj.value === passport.country
      );

      setNumberPassport(passport.number);
      setValidatePassport(passport.validUntil);
      setCountryPassport(countryPass);

      setPassport({
        ...passport,
        country: countryPass,
        number: passport.number,
        validUntil: passport.validUntil,
      });
    }
  }, []);

  return (
    <Row>
      <Colxx xxs="6">
        <Label className="form-group search reservation has-top-label">
          <Input
            type="text"
            value={numberPassport}
            name="number"
            className="form-control"
            onChange={handlePassportNumber}
            required={true}
          />
          <CustomErrorMessage error={errors.passportNumber}>
            {messages['forms.validation.passport.number.valid']}
          </CustomErrorMessage>
          <span>{messages['user.passportNumber']} *</span>
        </Label>
      </Colxx>
      <Colxx xxs="6">
        <Label className="form-group search reservation has-top-label">
          <Input
            type="text"
            value={validatePassport}
            name="validUntil"
            className="form-control"
            maxLength={10}
            onChange={handlePassportValidationDate}
            required={true}
          />
          <CustomErrorMessage error={errors.validate}>
            {messages['forms.validation.passport.validUntil.valid']}
          </CustomErrorMessage>
          <span>{messages['user.passportValidUntil']} *</span>
        </Label>
      </Colxx>
      <Colxx>
        <Label className="form-group search reservation has-top-label">
          <FixedSelect
            name="country"
            className="react-select"
            classNamePrefix="react-select"
            isClearable={false}
            placeholder=""
            value={countryPassport}
            noOptionsMessage={() => messages['general.no-options-found']}
            options={PassportCountries}
            onChange={(passportCountries) => {
              handlePassportCountry(passportCountries);
            }}
            maxMenuHeight={150}
            required={true}
          />
          <span>{messages['user.passportCountry']} *</span>
        </Label>
      </Colxx>
    </Row>
  );
}
