export const sortArrayAlphabetically = (array) =>
  array.sort((a, b) => a.localeCompare(b));

export const sortAlphabetically = (array, valueKey = 'key') =>
  array.sort((a, b) => a[valueKey].localeCompare(b[valueKey]));

export const sortByValue = (array, valueKey = 'key', order = 'asc') =>
  order === 'asc'
    ? array.sort((a, b) => a[valueKey] - b[valueKey])
    : array.sort((a, b) => b[valueKey] - a[valueKey]);
