import axios from 'axios';

import firebaseApp from './Firebase';
import User from './User';
import { createFormData } from '../utils/file';
import { compressImages } from '../utils/compress';
import { BLOCKED_STATUS } from '../constants/creditCard';
import { BudgetStorageTypes } from '../constants/budget';
import sanitizeHtml from 'sanitize-html';
import pickBy from 'lodash/pickBy';

const appFunctions = {};

/**
 * Constroi uma instância do axios
 *
 * @return {axios} Instância do axios com configurações para chamar a SWAP
 * @throws Failed to build axios for SWAP
 */
const buildAxios = async (environment, headerParams = {}) => {
  try {
    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      ...headerParams,
    };

    if (firebaseApp.isUserLoggedIn()) {
      const authToken = await User.getToken();
      headers['Authorization'] = `Bearer ${authToken}`;
    }

    if (environment) {
      headers['x-environment'] = environment;
    }

    return axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers,
    });
  } catch (err) {
    throw new Error('Failed to build axios for P3');
  }
};

/**
 * Methods: User
 */
appFunctions.activateAccount = async function ({ code, password }) {
  try {
    const axiosP3 = await buildAxios();

    const response = await axiosP3.post(`/activate-account`, {
      code,
      password,
    });

    return response.data;
  } catch (err) {
    console.error(err);
    return false;
  }
};

appFunctions.registerOrganization = async function ({
  name,
  email,
  cnpj,
  phone,
}) {
  const axiosP3 = await buildAxios();

  try {
    const response = await axiosP3.post('/register', {
      name,
      email,
      cnpj,
      phone,
    });
    return response.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

appFunctions.validateEmail = async function (email) {
  const axiosP3 = await buildAxios();

  try {
    const response = await axiosP3.get('/validate-email', {
      params: { email },
    });
    return response.data?.isValid;
  } catch (err) {
    console.error(err);
    return false;
  }
};

/**
 * Methods: CEP
 */
appFunctions.getCep = async function (postalCode) {
  try {
    const response = await axios.get(
      `https://viacep.com.br/ws/${postalCode}/json/`
    );
    return response.data;
  } catch (err) {
    console.error(err);
    return false;
  }
};

/**
 * Methods: Refunds
 */
appFunctions.storeReceipts = async ({
  files,
  budgetId,
  budgetItemId,
  onUploadProgress = () => {},
  shouldCompressImages = true,
  compressOptions = {
    maxWidthOrHeight: 1920,
    useWebWorker: true,
    initialQuality: 0.7,
  },
}) => {
  const axiosP3 = await buildAxios(null, {
    'Content-Type': 'multipart/form-data',
  });

  if (shouldCompressImages) {
    const compressedFiles = await compressImages(
      files.map((file) => file.file),
      compressOptions
    );

    files = files.map((file, index) => ({
      ...file,
      file: compressedFiles[index],
    }));
  }

  const formData = createFormData(files);

  return axiosP3.post(
    `/budgets/${budgetId}/items/${budgetItemId}/receipts`,
    formData,
    {
      onUploadProgress,
    }
  );
};

appFunctions.getReceipts = async (budgetId, budgetItemId) => {
  const axiosP3 = await buildAxios();

  return axiosP3.get(`/budgets/${budgetId}/items/${budgetItemId}/receipts`);
};

appFunctions.deleteReceipt = async (budgetId, budgetItemId, receiptId) => {
  const axiosP3 = await buildAxios();
  return axiosP3.delete(
    `/budgets/${budgetId}/items/${budgetItemId}/receipts/${receiptId}`
  );
};

appFunctions.deleteExpense = async (expenseId) => {
  const axiosP3 = await buildAxios();
  return axiosP3.delete(`/expenses/${expenseId}`);
};

appFunctions.assignExpenseToRefund = async (expenseId, refundId) => {
  const axiosP3 = await buildAxios();

  return axiosP3.post(`/expenses/${expenseId}/assign-to-refund`, {
    refundId,
  });
};

appFunctions.removeExpenseFromRefund = async (expenseId, refundId) => {
  const axiosP3 = await buildAxios();

  return axiosP3.post(`/expenses/${expenseId}/remove-from-refund`, {
    refundId,
  });
};

/**
 * Methods: Expenses Cards
 */
appFunctions.createExpensesCard = async (organizationId, params) => {
  const axiosP3 = await buildAxios();

  return axiosP3.post(
    `/organizations/${organizationId}/expenses-cards`,
    params
  );
};

appFunctions.updateExpenseCardUserId = async (
  organizationId,
  expenseCardId,
  user_id
) => {
  const axiosP3 = await buildAxios();

  return axiosP3.put(
    `/organizations/${organizationId}/expenses-cards/${expenseCardId}`,
    {
      user_id,
    }
  );
};

appFunctions.updateExpenseCardPin = async (
  organizationId,
  expenseCardId,
  pin
) => {
  const axiosP3 = await buildAxios();

  return axiosP3.post(
    `/organizations/${organizationId}/expenses-cards/${expenseCardId}/pin`,
    { pin }
  );
};

appFunctions.getExpenseCardDetails = async function (
  organizationId,
  cardId,
  external = false
) {
  const axiosP3 = await buildAxios();

  return axiosP3.get(
    `/organizations/${organizationId}/expenses-cards/${cardId}`,
    {
      params: { external },
    }
  );
};

appFunctions.getExpenseCardPan = async function (organizationId, cardId) {
  const axiosP3 = await buildAxios();

  return axiosP3.get(
    `/organizations/${organizationId}/expenses-cards/${cardId}/pan`
  );
};

appFunctions.putExpenseCardBlockStatus = async function (
  organizationId,
  cardId,
  shouldBlock
) {
  const axiosP3 = await buildAxios();

  return axiosP3.put(
    `/organizations/${organizationId}/expenses-cards/${cardId}/status/${
      shouldBlock ? BLOCKED_STATUS.block : BLOCKED_STATUS.unblock
    }`
  );
};

appFunctions.searchDictForKey = async function (organizationId, key) {
  const axiosP3 = await buildAxios();

  return axiosP3.get(`/organizations/${organizationId}/dict`, {
    params: { key },
  });
};

appFunctions.readReceipt = async function (budgetId, budgetItemId, receipt) {
  const axiosP3 = await buildAxios();

  const bucketFile = receipt.attachment
    ? `${BudgetStorageTypes.INBOX}/${budgetId}/${receipt.filename}`
    : `${BudgetStorageTypes.BUDGETS}/${budgetId}/${budgetItemId}/${receipt.filename}`;

  return axiosP3.get(`/budgets/nfe/read`, {
    params: {
      bucketFile,
    },
  });
};

appFunctions.importQrCode = async function (
  budgetId,
  budgetItemId,
  receiptId,
  qrcode,
  state
) {
  const axiosP3 = await buildAxios();

  return axiosP3.patch(
    `/budgets/${budgetId}/items/${budgetItemId}/receipts/${receiptId}/import/qrCode`,
    {
      qrcode,
      state,
    }
  );
};

appFunctions.importAccessCode = async function (
  budgetId,
  budgetItemId,
  receiptId,
  access_key
) {
  const axiosP3 = await buildAxios();

  return axiosP3.patch(
    `/budgets/${budgetId}/items/${budgetItemId}/receipts/${receiptId}/import/accessKey`,
    {
      access_key,
    }
  );
};

appFunctions.deleteReceipts = async function (budgetId, budgetItemId) {
  const axiosP3 = await buildAxios();

  return axiosP3.delete(`/budgets/${budgetId}/items/${budgetItemId}/receipts`);
};

appFunctions.getReceiptsFromBudget = async function (budgetId, asZip = false) {
  const axiosP3 = await buildAxios();

  return axiosP3.get(
    `/budgets/${budgetId}/receipts`,
    asZip
      ? {
          params: { zip: true },
        }
      : {}
  );
};

appFunctions.makeReceiptForAdministrativeBudget = async function (budgetId) {
  const axiosP3 = await buildAxios();

  return axiosP3.get(`/budgets/${budgetId}/receipt`, {
    responseType: 'arraybuffer',
  });
};

/**
 * Methods: Orders
 */
appFunctions.getCancelPolicies = async (orderId) => {
  const axiosP3 = await buildAxios();

  return axiosP3.get(`orders/${orderId}/cancelPolicy`);
};

appFunctions.getAddressFromLatLng = async (lat, lng) => {
  const axiosP3 = await buildAxios();

  return axiosP3.get(`maps/latlng`, {
    params: {
      lat,
      lng,
    },
  });
};

appFunctions.getAddress = async (address) => {
  const axiosP3 = await buildAxios();

  return axiosP3.get(`maps/latlng/${address}`, {
    params: {
      getAll: true,
    },
  });
};

appFunctions.getResultFromSearch = async (type, searchCode, itemId) => {
  const axiosP3 = await buildAxios();

  return axiosP3.post(`search/${type}/${searchCode}/${itemId}`);
};

appFunctions.getEmailAttachments = async (organizationId, emailId) => {
  const axiosP3 = await buildAxios();

  return axiosP3.get(`/admin/inbox/${organizationId}/${emailId}/attachments`);
};

appFunctions.checkEmailAlias = async (alias) => {
  const axiosP3 = await buildAxios();

  return axiosP3.get(`/email-alias/${alias}`);
};

appFunctions.createGordianFlight = async (orderId) => {
  const axiosP3 = await buildAxios();

  return axiosP3.post(`/gordian/trip/${orderId}`);
};

appFunctions.checkAncillaryPoliciesFromOrder = async (orderId) => {
  const axiosP3 = await buildAxios();

  return axiosP3.get(`/gordian/trip/${orderId}/checkPolicies`);
};

appFunctions.checkoutAncillaries = async (orderId, creditCardId = null) => {
  const axiosP3 = await buildAxios();

  return axiosP3.post(`/gordian/trip/${orderId}/checkout`, { creditCardId });
};

appFunctions.verifyAncillariesCancellation = async (orderId, ancillaryId) => {
  const axiosP3 = await buildAxios();

  return axiosP3.post(`/gordian/trip/${orderId}/${ancillaryId}/cancel/verify`);
};

appFunctions.confirmAncillariesCancellation = async (orderId) => {
  const axiosP3 = await buildAxios();

  return axiosP3.post(`/gordian/trip/${orderId}/cancel/confirm`);
};

appFunctions.getBank3Balance = async (organizationId) => {
  const axiosP3 = await buildAxios();

  return axiosP3.get(`/organizations/${organizationId}/bank3/balance`);
};

appFunctions.cancelFlightReservation = async (orderId, cancellationType) => {
  const axiosP3 = await buildAxios();

  return axiosP3.post(`/orders/${orderId}/cancel`, {
    cancellationType,
  });
};

appFunctions.cancelHotelReservation = async (orderId, motive = '') => {
  const axiosP3 = await buildAxios();

  return axiosP3.post(`/orders/${orderId}/cancel`, {
    motive: sanitizeHtml(motive, { allowedTags: [], allowedAttributes: {} }),
  });
};

appFunctions.createSearchBasedOnFlight = async (searchCode, date, type) => {
  const axiosP3 = await buildAxios();

  return axiosP3.post(`/search/flight/${searchCode}/based-search`, {
    type,
    date,
  });
};

appFunctions.requestFlightAlteration = async (orderId, flights) => {
  const axiosP3 = await buildAxios();

  return axiosP3.post(`/orders/${orderId}/alteration`, {
    flights: pickBy(flights, (value) => value?.id),
  });
};

appFunctions.getCreditCardsFromOrganization = async (
  organizationId,
  orderId
) => {
  const axiosP3 = await buildAxios();

  return axiosP3.get(`/organizations/${organizationId}/credit-cards`, {
    params: {
      orderId,
    },
  });
};

appFunctions.denyReservationAsAdmin = async (orderId, comment) => {
  const axiosP3 = await buildAxios();

  return axiosP3.post(`/orders/${orderId}/deny`, {
    comment,
  });
};

appFunctions.notifyApprovers = async (orderId) => {
  const axiosP3 = await buildAxios();

  return axiosP3.get(`/orders/${orderId}/notify-approvers`);
};

appFunctions.getAuthLink = async (url) => {
  const axiosP3 = await buildAxios();

  return axiosP3.get(`/user/auth-link`, {
    params: {
      url,
    },
  });
};

appFunctions.getCurrencyQuote = async (currency) => {
  const axiosP3 = await buildAxios();

  const { data } = await axiosP3.get(`/currency/${currency}`);

  return data.quote;
};

appFunctions.getItinerary = async (search) => {
  const axiosP3 = await buildAxios();

  const { data } = await axiosP3.post(`/itinerary`, search);

  return data;
};

appFunctions.logger = async (params) => {
  const axiosP3 = await buildAxios();

  const body = {
    body: params.body,
    origin: params.origin,
    message: params.message,
  };

  const { data } = await axiosP3.post(`/events/log`, body);

  return data;
};

appFunctions.getItinerary = async (search) => {
  const axiosP3 = await buildAxios();

  const { data } = await axiosP3.post(`/itinerary`, search);

  return data;
};

appFunctions.createBudgetRecurrence = async ({ budget, recurrence }) => {
  const axiosP3 = await buildAxios();

  const { data } = await axiosP3.post(`/recurrence-budgets`, {
    budget,
    recurrence,
  });

  return data;
};

appFunctions.updateRecurrenceBudget = async (budgetId, payload) => {
  const axiosP3 = await buildAxios();

  const { data } = await axiosP3.patch(
    `/recurrence-budgets/${budgetId}`,
    payload
  );

  return data;
};

appFunctions.updateRecurrenceBudgetStatus = async (budgetId, status) => {
  return appFunctions.updateRecurrenceBudget(budgetId, { status });
};

appFunctions.getBusSeats = async (scheduleId) => {
  const axiosP3 = await buildAxios();

  const { data } = await axiosP3.get(`/bus/seats/${scheduleId}`);

  return data;
};

appFunctions.checkBoleto = async (organizationId, cardId, barcode) => {
  const axiosP3 = await buildAxios();

  const { data } = await axiosP3.get(
    `/organizations/${organizationId}/expenses-cards/${cardId}/banking/boleto`,
    {
      params: {
        barcode,
      },
    }
  );

  return data;
};

appFunctions.checkPix = async (organizationId, cardId, pixType, pixKey) => {
  const axiosP3 = await buildAxios();

  const { data } = await axiosP3.get(
    `/organizations/${organizationId}/expenses-cards/${cardId}/banking/pix`,
    {
      params: {
        key: pixKey,
        type: pixType,
      },
    }
  );

  return data;
};

appFunctions.generateTravelVouchersUrl = async (orderIds, filename) => {
  const axiosP3 = await buildAxios();

  const { data } = await axiosP3.post(`/orders/print`, {
    orderIds,
    downloadFileName: filename,
  });

  return data;
};

/**
 * Methods: Budgets Expenses
 */
appFunctions.printBudgetsExpenses = async (reports) => {
  const axiosP3 = await buildAxios();

  return await axiosP3.post(
    '/budgets/report-expenses/print',
    {
      reports,
    },
    {
      responseType: 'arraybuffer',
    }
  );
};

appFunctions.getReceiptUrlsFromBudgets = async (budgetIds) => {
  const axiosP3 = await buildAxios();

  const { data } = await axiosP3.post('/budgets/reports/receipts', budgetIds);

  return data;
};

export default appFunctions;
