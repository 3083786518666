import React, { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Button } from 'reactstrap';
import { FlightTypes } from '../../../../../constants/flight';
import AlterationFrame from '../../AlterationFrame';
import moment from 'moment';
import useExecuteAction from '../../../../../hooks/useExecuteAction';
import appFunctions from '../../../../../services/Functions';
import Spinner from '../../../../common/Spinner';
import MessageWithIcon from '../../../../common/MessageWithIcon';
import debounce from 'lodash/debounce';

import { ReactComponent as InboundIcon } from '../../../../../assets/img/icons/inbound.svg';
import { ReactComponent as OutboundIcon } from '../../../../../assets/img/icons/oneway.svg';
import { ReactComponent as ExclamationIcon } from '../../../../../assets/img/icons/exclamation.svg';

const getConnectionText = (flights, messages) => {
  const numConnection = flights.length - 1;

  if (numConnection) {
    return `${numConnection} ${messages[
      numConnection > 1 ? `flight.stops` : 'flight.stop'
    ].toLowerCase()}`;
  } else return messages['flight.no-stop'];
};

function FlightInfo({ reservation, type }) {
  const { messages } = useIntl();

  const { title, Icon } = useMemo(() => {
    return {
      title: messages[`travel.${type.toLowerCase()}`],
      Icon: type === FlightTypes.OUTBOUND ? OutboundIcon : InboundIcon,
    };
  }, [type]);

  const { departureStation, arrivalStation, dateTime, connections } =
    useMemo(() => {
      const {
        departureStation,
        arrivalStation,
        departureDateTime: rawDepartureDateTime,
        arrivalDateTime: rawArrivalDateTime,
        flights = [],
      } = reservation.journeys[0];
      const departureDateTime = moment(rawDepartureDateTime);
      const arrivalDateTime = moment(rawArrivalDateTime);

      return {
        departureStation,
        arrivalStation,
        dateTime: `${departureDateTime.format(
          'DD/MM/YYYY'
        )} de ${departureDateTime.format('HH:mm')} até ${arrivalDateTime.format(
          'HH:mm'
        )}`,
        connections: getConnectionText(flights, messages),
      };
    }, [reservation]);

  return (
    <div className="flight-info mb-4">
      <h3 className="m-0 p-0 text-white font-primary fs-1rem mb-2 d-flex align-items-center">
        <Icon fill="white" width="1.3rem" height="1.3rem" className="mr-2" />
        {title}
      </h3>
      <div className="ml-3 text-white-muted lighter fs-1rem">
        <p className="m-0 p-0 d-flex align-items-center">
          {departureStation}{' '}
          <OutboundIcon width="0.9rem" height="0.9rem" className="mx-1" />{' '}
          {arrivalStation}
        </p>
        <p className="m-0 p-0">{dateTime}</p>
        <p className="m-0 p-0">{connections}</p>
      </div>
    </div>
  );
}

export default function CheckoutTab({
  goForAlteration,
  goToPrevious,
  flights,
  order,
}) {
  const { messages } = useIntl();

  const { loading, error, executeAction } = useExecuteAction({
    defaultErrorMessage: messages['travel.alter.flight.alteration.error'],
  });
  const [done, setDone] = useState(false);

  const flightKeys = useMemo(
    () => Object.keys(flights).filter((key) => flights[key]),
    [flights]
  );

  // Functions
  const requestAlteration = useCallback(
    debounce(async () => {
      try {
        const alterationFlights = flightKeys.reduce((obj, key) => {
          obj[key] = flights[key];
          return obj;
        }, {});

        await executeAction(
          appFunctions.requestFlightAlteration(order.id, alterationFlights)
        );

        setDone(true);
      } catch (err) {
        console.error(err);
      }
    }, 300),
    []
  );

  // Render
  const renderBottom = () => (
    <div className="d-flex align-items-center justify-content-end">
      {done ? (
        <Button color="link" onClick={goForAlteration} className="m-0">
          {messages['general.go-back']}
        </Button>
      ) : (
        <>
          <Button
            color="link"
            disabled={loading}
            onClick={goToPrevious}
            className="m-0"
          >
            {messages['general.go-back']}
          </Button>
          <Button
            color="primary"
            disabled={loading}
            onClick={requestAlteration}
            className="m-0"
          >
            {messages['general.request']}
          </Button>
        </>
      )}
    </div>
  );

  const renderDone = () => (
    <div>
      <MessageWithIcon
        className="mt-3 show-up"
        text={messages['travel.alter.flight.alteration.final.successful']}
        Icon={ExclamationIcon}
        iconStyle={{ width: '7%' }}
        style={{ animationDelay: '200ms' }}
      />
    </div>
  );

  const renderInitial = () =>
    loading ? (
      <div className="w-100 h-100 flex-center">
        <Spinner white relative style={{ width: 60, height: 60 }} />
      </div>
    ) : (
      <div className="mt-2">
        {error ? <p className="error-badge mb-4">{error}</p> : null}
        {flightKeys.map((key) => (
          <FlightInfo key={key} reservation={flights[key]} type={key} />
        ))}
      </div>
    );

  const renderContent = () => (done ? renderDone() : renderInitial());

  return (
    <AlterationFrame
      title={messages['travel.alter.flight.alteration.final.title']}
      subtitle={messages['travel.alter.flight.alteration.final.description']}
      BottomBar={renderBottom}
    >
      {renderContent()}
    </AlterationFrame>
  );
}
