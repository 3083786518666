import React, { useCallback, useEffect, useRef } from 'react';
import classNames from 'classnames';
import throttle from 'lodash/throttle';

export default function AutoHeightTextArea({
  className,
  value,
  allowEnter = true,
  throttleMs = 500,
  ...props
}) {
  const commentRef = useRef(null);
  const lastWidth = useRef(null);

  // Effects
  useEffect(() => {
    if (commentRef.current) {
      const observer = new ResizeObserver(([entry]) => {
        const currentWidth = entry.contentRect.width;

        if (lastWidth.current !== currentWidth) {
          lastWidth.current = currentWidth;
          autoResizeThrottle(commentRef.current);
        }
      });

      observer.observe(commentRef.current);

      return () => observer.disconnect();
    }
  }, []);

  useEffect(() => {
    autoResize(commentRef.current);
  }, [value]);

  // Functions
  const autoResize = useCallback((element) => {
    if (element) {
      element.style.height = 'auto';
      element.style.height = `${element.scrollHeight}px`;
    }
  }, []);

  const autoResizeThrottle = useCallback(throttle(autoResize, throttleMs), [
    autoResize,
    throttleMs,
  ]);

  // Render
  return (
    <textarea
      className={classNames('auto-height-text-area', className)}
      ref={commentRef}
      value={value}
      {...props}
    />
  );
}
