export const getShrinkClass = (text) => {
  const length = text.length;

  if (length < 12) return '';
  else if (length < 15) return 'text-medium-alternative';
  else if (length < 18) return 'text-small';
  else return 'text-smaller';
};

export const joinArrayWithComa = (values, andLabel = 'e') => {
  const length = values.length;

  if (length < 3) {
    return values.join(` ${andLabel} `);
  } else {
    const lastElement = length - 1;
    return `${values.slice(0, lastElement).join(', ')} ${andLabel} ${
      values[lastElement]
    }`;
  }
};
