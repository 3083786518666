import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Row, Label, Button, Alert } from 'reactstrap';
import { Colxx } from '../../../common/CustomBootstrap';
import IntlMessages from '../../../../helpers/IntlMessages';
import Select from 'react-select';
import map from 'lodash/map';
import find from 'lodash/find';
import { useIntl } from 'react-intl';
import firebaseApp from '../../../../services/Firebase';
import appFunctions from '../../../../services/Functions';
import CreditSelectionCheckbox from '../../CreditSelectionCheckbox';

import { trackPropertiesSegment } from '../../../../utils/segment';
import { getInstallmentsText } from '../utils';
import PaymentAtDestinationMessage from '../Others/PaymentAtDestinationMessage';

export default function PaymentForm({
  approvals = [],
  order,
  goForApproval,
  requestToIssue,
  requestToApprove,
  requestToReject,
  ongoingAction,
  error,
  setError,
  shouldDisableButtons,
  credit,
  setCredit,
  isRejecting,
  setIsRejecting,
}) {
  const { user } = useSelector((state) => state.auth);
  const { messages, formatMessage } = useIntl();

  // States
  const [creditCards, setCreditCards] = useState([]);
  const [selectedCard, setSelectedCard] = useState(null);
  const [paymentOptions, setPaymentOption] = useState([]);
  const [installments, setInstallments] = useState(1);
  const [selectedTab, setSelectedTab] = useState('issue');
  const [shouldDisableIssueButton, setShouldDisableIssueButton] =
    useState(false);

  const isApprover = useMemo(
    () =>
      approvals && user
        ? approvals.some((approval) => approval.userId === user.uid)
        : false,
    [approvals, user]
  );

  // Effects
  useEffect(() => {
    getCreditCards();
  }, []);

  useEffect(() => {
    if (selectedCard) loadPayments();
  }, [selectedCard]);

  useEffect(() => {
    handleSelectedCardBalance(selectedCard, creditCards);
  }, [selectedCard, creditCards]);

  // Functions
  const handleSelectedCardBalance = (selectedCard, creditCards) => {
    const selectedCardData = creditCards.find(
      (card) => selectedCard === card.id
    );

    if (selectedCardData?.issuer === 'BANK_3') {
      const cardBalance = parseFloat(
        selectedCardData.name
          .replace(/[a-z A-Z\(\$\\.)]/gi, '')
          .replace(',', '.')
      );

      setShouldDisableIssueButton(cardBalance < order.fare.total);
    } else {
      setShouldDisableIssueButton(false);
    }
  };

  const getCreditCards = async () => {
    try {
      const { data: creditCards } =
        await appFunctions.getCreditCardsFromOrganization(
          user.organizationId,
          order.id
        );

      setCreditCards(creditCards);

      if (creditCards.length > 0) {
        setSelectedCard(
          find(creditCards, (card) => {
            return card.default === true;
          })?.id || creditCards[0].id
        );
      } else {
        setError(messages['error.approval-info.credit-card.register']);
      }
    } catch (err) {
      console.error(err);
      setError(messages['error.approval-info.credit-card.error']);
    }
  };

  // Payments
  const loadPayments = async () => {
    setPaymentOption([]);

    const paymentOptionsResponse =
      await firebaseApp.getPaymentOptionsFromOrderAndCardId({
        orderId: order.id,
        cardId: selectedCard,
      });

    setPaymentOption(paymentOptionsResponse.options);
  };

  // Render
  const renderReject = () => {
    return (
      <div
        className="text-right btns my-3 mr-1"
        style={{
          pointerEvents: ongoingAction ? 'none' : 'auto',
          opacity: ongoingAction ? 0.6 : 1,
        }}
      >
        <Button
          color="link"
          disabled={shouldDisableButtons}
          onClick={goForApproval}
          className="my-0 px-2"
        >
          {messages['general.go-back']}
        </Button>
        <Button
          color="link"
          onClick={() => setIsRejecting(false)}
          disabled={shouldDisableButtons}
          title={shouldDisableButtons ? messages['travel.timeout'] : ''}
          className="my-0 px-2 mx-2"
        >
          {messages['general.cancel']}
        </Button>
        <Button
          className="my-0 px-2 mx-2"
          onClick={requestToReject}
          disabled={shouldDisableButtons}
          title={shouldDisableButtons ? messages['travel.timeout'] : ''}
        >
          {messages['general.reject']}
        </Button>
      </div>
    );
  };

  const renderIssue = () => {
    return (
      <div>
        <Row className="payment-options">
          <Colxx xxs="6">
            <Label className="form-group search reservation has-top-label">
              <Select
                className="react-select"
                classNamePrefix="react-select"
                isClearable={false}
                placeholder=""
                value={{
                  value: selectedCard || '',
                  label:
                    find(creditCards, (card) => {
                      return card.id === selectedCard;
                    })?.name || '',
                }}
                noOptionsMessage={() => {
                  return messages['travel.booking.no-creditCard'];
                }}
                options={map(creditCards, (creditCard) => {
                  return {
                    value: creditCard.id,
                    label: creditCard.name,
                  };
                })}
                onChange={(selectedCard) => {
                  trackPropertiesSegment('User selected a credit card', {
                    selectedCard,
                    user,
                  });
                  setSelectedCard(selectedCard.value);
                  handleSelectedCardBalance(selectedCard.value, creditCards);
                }}
              />
              <IntlMessages id="travel.booking.creditCard" />
              {shouldDisableIssueButton && (
                <div
                  className="mt-1"
                  style={{
                    color: '#fe375e',
                    fontWeight: 'bold',
                    fontSize: '12px',
                  }}
                >
                  {messages['travel.booking.creditCard.insufficient-balance']}
                </div>
              )}
            </Label>
          </Colxx>
          <Colxx xxs="6">
            <Label className="form-group search reservation has-top-label">
              <Select
                className="react-select"
                classNamePrefix="react-select"
                isClearable={false}
                placeholder=""
                value={{
                  value: installments || 0,
                  label:
                    getInstallmentsText(
                      find(paymentOptions, (paymentOption) => {
                        return paymentOption.installment === installments;
                      }),
                      formatMessage,
                      order.fare.currency
                    ) || '',
                }}
                noOptionsMessage={() => {
                  return messages['general.loading'];
                }}
                options={map(paymentOptions, (paymentOption) => {
                  return {
                    value: paymentOption.installment,
                    label: getInstallmentsText(
                      paymentOption,
                      formatMessage,
                      order.fare.currency
                    ),
                  };
                })}
                onChange={(installments) => {
                  trackPropertiesSegment('User selected installments', {
                    installments,
                    user,
                  });
                  setInstallments(installments.value);
                }}
              />
              <IntlMessages id="travel.booking.installments" />
            </Label>
          </Colxx>
        </Row>

        <CreditSelectionCheckbox
          order={order}
          selectedCredit={credit}
          setSelectedCredit={setCredit}
        />

        <PaymentAtDestinationMessage order={order} />

        <div
          className="text-right btns my-3 mr-1"
          style={{
            pointerEvents: ongoingAction ? 'none' : 'auto',
            opacity: ongoingAction ? 0.6 : 1,
          }}
        >
          <Button
            color="link"
            disabled={shouldDisableButtons}
            onClick={goForApproval}
            className="my-0 px-2"
          >
            {messages['general.go-back']}
          </Button>
          <Button
            color="link"
            onClick={requestToReject}
            disabled={shouldDisableButtons}
            title={shouldDisableButtons ? messages['travel.timeout'] : ''}
            className="my-0 px-2 mx-2"
          >
            {messages['general.reject']}
          </Button>
          <Button
            className="my-0 ml-2"
            onClick={() => requestToIssue(selectedCard, installments, credit)}
            disabled={shouldDisableIssueButton || shouldDisableButtons}
            title={shouldDisableButtons ? messages['travel.timeout'] : ''}
          >
            {messages['travel.order.approval.issue']}
          </Button>
        </div>
      </div>
    );
  };

  const renderApproval = () => {
    return (
      <>
        <CreditSelectionCheckbox
          order={order}
          selectedCredit={credit}
          setSelectedCredit={setCredit}
        />

        <PaymentAtDestinationMessage order={order} />

        <div
          className="text-right btns m-0 my-3 mr-1"
          style={{
            pointerEvents: ongoingAction ? 'none' : 'auto',
            opacity: ongoingAction ? 0.6 : 1,
          }}
        >
          <Button
            color="link"
            disabled={shouldDisableButtons}
            onClick={goForApproval}
            className="my-0 px-2"
          >
            {messages['general.go-back']}
          </Button>
          <Button
            color="link"
            onClick={requestToReject}
            disabled={shouldDisableButtons}
            title={shouldDisableButtons ? messages['travel.timeout'] : ''}
            className="my-0 px-2 mx-2"
          >
            {messages['general.reject']}
          </Button>
          <Button
            className="my-0 ml-2"
            onClick={() => requestToApprove(credit)}
            disabled={shouldDisableButtons}
            title={shouldDisableButtons ? messages['travel.timeout'] : ''}
          >
            {messages['travel.order.approval.to-approve']}
          </Button>
        </div>
      </>
    );
  };

  const renderTabs = () => {
    return (
      <div className="mt-4">
        {error && <Alert>{error}</Alert>}

        {isApprover ? (
          <div className="d-flex nav-links mb-3">
            <h5
              className={`text-white m-0 pointer ${
                selectedTab === 'issue' ? 'selected' : ''
              }`}
              onClick={() => setSelectedTab('issue')}
            >
              {messages['travel.order.approval.issue']}
            </h5>
            <h5
              className={`text-white pointer m-0 ml-2 ${
                selectedTab === 'approval' ? 'selected' : ''
              }`}
              onClick={() => setSelectedTab('approval')}
            >
              {messages['travel.order.approval.approve']}
            </h5>
          </div>
        ) : null}

        {selectedTab === 'issue' ? renderIssue() : renderApproval()}
      </div>
    );
  };

  return isRejecting ? renderReject() : renderTabs();
}
