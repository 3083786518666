import React, { useMemo } from 'react';
import HotelAlteration from './Alteration/HotelAlteration';
import { OrderTypes } from '../../constants/orderTypes';
import FlightAlteration from './Alteration/FlightAlteration';
import CarAlteration from './Alteration/CarAlteration';
import BusAlteration from './Alteration/BusAlteration';

const ALTERATION_COMPONENTS = {
  [OrderTypes.HOTEL]: HotelAlteration,
  [OrderTypes.FLIGHT]: FlightAlteration,
  [OrderTypes.CAR]: CarAlteration,
  [OrderTypes.BUS]: BusAlteration,
};

export default function AlterationInfoModal({ order, ...props }) {
  const Component = useMemo(() => ALTERATION_COMPONENTS[order.type], [order]);

  return (
    <div
      className="approval-steps h-100 px-3 pt-2 pb-1"
      id="alteration-info-modal"
    >
      {Component ? <Component order={order} {...props} /> : null}
    </div>
  );
}
