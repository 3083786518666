import { stringToMoney } from '../../../utils/money';

export const getInstallmentsText = (
  paymentOption,
  formatMessage = () => {},
  currency
) => {
  if (!paymentOption) {
    return undefined;
  }

  if (paymentOption?.installment === 1) {
    return formatMessage(
      { id: 'payment.single-installment' },
      {
        quantity: stringToMoney(
          paymentOption.firstInstallmentAmount,
          2,
          currency
        ),
      }
    );
  }

  const installment = paymentOption?.installment || 1;
  const id =
    paymentOption?.amountWithInterest !== paymentOption?.amountWithOutInterest
      ? 'payment.installment-with-interest'
      : 'payment.installment-without-interest';

  return formatMessage(
    { id },
    {
      installment,
      remainingInstallments: installment - 1,
      firstInstallmentAmount: stringToMoney(
        paymentOption.firstInstallmentAmount,
        2,
        currency
      ),
      remainingInstallmentsAmount: stringToMoney(
        paymentOption.remainingInstallmentAmount,
        2,
        currency
      ),
    }
  );
};
