import React, { useState, useEffect, createRef, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { Button } from 'reactstrap';
import RetractableSearchBar from '../../components/common/RetractableSearchBar';
import { RefundTypes } from '../../constants/refundTypes';
import useDataSnapshot from '../../hooks/useDataSnapshot';
import firebaseApp from '../../services/Firebase';
import LoadingDefaultCard from './CardContainers/LoadingDefaultCard';

import PersonalAccountContainer from './CardContainers/PersonalAccountContainer';
import PhysicalCardContainer from './CardContainers/PhysicalCardContainer';
import VirtualCardContainer from './CardContainers/VirtualCardContainer';
import NewCreditCardModal from '../../components/expenses/NewCreditCardModal';
import BudgetOrderModal from '../timeline/BudgetOrderModal';

import ChangePasswordModal from '../../components/expenses/ChangePasswordModal';
import UserSwitcher from '../../components/common/UserSwitcher';
import BalanceCard from '../../components/expenses/BalanceCard';
import BubbleButton from '../../components/common/BubbleButton';
import useHistoryWithScroll from '../../hooks/useHistoryWithScroll';

import { ReactComponent as EmptyCards } from '../../assets/img/expenses/empty-budgets.svg';
import { ReactComponent as MultipleIcon } from '../../assets/img/icons/multiple.svg';
import { ReactComponent as MoneyIcon } from '../../assets/img/expenses/money.svg';
import { ReactComponent as PrintReportIcon } from '../../assets/img/icons/print-report.svg';
import { ReactComponent as Loading } from '../../assets/img/icons/loading.svg';
import useDataArchive from '../../hooks/useDataArchive';
import { sortExpenseCards } from '../../utils/expenses';
import recordStatus from '../../constants/recordStatus';
import StatusSelector from './Recurrence/StatusSelector';
import AdministrativeAccountContainer from './CardContainers/AdministrativeAccountContainer';
import { hasExpenses } from '../../utils/organization';
import useBudgetsExpenses from '../../hooks/useBudgetsExpenses';
import { resetSelectedBudgets } from 'src/portao3-legacy/redux/actions';

const CARD_COMPONENTS = {
  [RefundTypes.PHYSICAL_CARD]: PhysicalCardContainer,
  [RefundTypes.VIRTUAL_CARD]: VirtualCardContainer,
  [RefundTypes.PERSONAL_ACCOUNT]: PersonalAccountContainer,
  [RefundTypes.ADMINISTRATIVE_ACCOUNT]: AdministrativeAccountContainer,
};

const formatCards = (cards) => {
  return cards.map((card) => ({
    ...card,
    filterKey: (card.type === RefundTypes.PERSONAL_ACCOUNT
      ? `${card.pix?.id || ''} ${card.name}`
      : `${card.lastDigits || ''} ${card.name}`
    ).toLowerCase(),
  }));
};

export default function MyCards() {
  const { user, passenger, organization } = useSelector(
    ({ auth, timeline }) => ({
      user: auth.user,
      passenger: timeline.passenger || auth.user,
      organization: auth.organization,
    })
  );
  const dispatch = useDispatch();
  const { location, push } = useHistoryWithScroll();
  const { messages } = useIntl();
  const { budgetId, itemId, cardId } = useParams();
  const { reports } = useSelector(
    ({ reportBudgetExpenses }) => reportBudgetExpenses
  );

  const { buildReport, loading: isLoadingBuildReport } = useBudgetsExpenses();

  const { getDataFromArchive: getData } = useDataArchive();

  // Refs
  const lastVisibleIndex = useRef(0);

  // States
  const [showAllCards, setShowAllCards] = useState(false);
  const [status, setStatus] = useState(recordStatus.ACTIVE);

  const [cards, _, isLoadingCards] = useDataSnapshot(
    {
      fetchFunction: () =>
        showAllCards
          ? firebaseApp.getCardsFromOrganization(
              passenger.organizationId,
              status
            )
          : firebaseApp.getCardsFromUser(
              passenger.uid,
              passenger.organizationId,
              status
            ),
      formatFunction: formatCards,
    },
    [passenger, showAllCards, status]
  );

  const isEmpty = useMemo(() => cards.length === 0, [cards]);

  const [searchKey, setSearchKey] = useState('');
  const [cardRefs, setCardRefs] = useState([]);
  const [visibleCards, setVisibleCards] = useState({});
  const [createCardModal, setCreateCardModal] = useState(false);
  const [lastBudgets, setLastBudget] = useState({});
  const [onlyWithFunds, setOnlyWithFunds] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(
        (entries, observer) => {
          const visible = {};
          let lastIndex = lastVisibleIndex.current;

          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              const id = entry.target.getAttribute('data-id');
              const index = parseInt(entry.target.getAttribute('data-index'));

              observer.unobserve(entry.target);

              visible[id] = true;
              if (index > lastIndex) lastIndex = index;
            }
          });

          setVisibleCards(visible);
          if (lastIndex > lastVisibleIndex.current)
            lastVisibleIndex.current = lastIndex + 1;
        },
        {
          threshold: 0.15,
        }
      ),
    []
  );

  const filteredCards = useMemo(() => {
    const filteredCards = cards.sort(sortExpenseCards);

    if (searchKey) {
      const lowerSearchKey = searchKey.toLowerCase();

      return filteredCards.filter(({ filterKey }) =>
        filterKey.includes(lowerSearchKey)
      );
    } else return filteredCards;
  }, [searchKey, cards]);

  const canChangeUser = useMemo(
    () => user.admin || user.permissions?.budgets === true,
    [user]
  );

  const canCreateBudgets = useMemo(
    () => hasExpenses(organization),
    [organization]
  );

  // Effects
  useEffect(() => {
    const params = new URLSearchParams(location.search || '');
    if (params.get('add') !== null) setCreateCardModal(true);
  }, []);

  useEffect(() => {
    if (filteredCards.length > 0) {
      const refs = cards.map(() => createRef());
      setCardRefs(refs);
      setVisibleCards({});
    }
  }, [filteredCards]);

  useEffect(() => {
    if (cardRefs.length > 0) {
      cardRefs.forEach((ref) => ref.current && observer.observe(ref.current));

      return () => {
        observer.disconnect();
      };
    }
  }, [cardRefs]);

  // Functions
  const closeModal = () => {
    push(`/payments/cards`);
  };

  const toggleCreateCard = () => {
    setCreateCardModal(!createCardModal);
  };

  const goToCreateBudget = (id, type, userId) => {
    push(`/payments/cards/new`, {
      search: `?cardId=${id}&type=${type}&userId=${userId}`,
    });
  };

  const goToCreateBudgetItem = (budgetId) => {
    push(`/payments/cards/${budgetId}/items/new`);
  };

  const goToRefund = (refundId) => {
    push(`/payments/cards/${refundId}`);
  };

  const goToExpense = (budgetId, itemId) => {
    push(`/payments/cards/${budgetId}/items/${itemId}`);
  };

  // Render
  if (isLoadingCards) {
    return (
      <div className="with-padding" style={{ marginTop: 100 }}>
        <LoadingDefaultCard />
        <LoadingDefaultCard />
        <LoadingDefaultCard />
      </div>
    );
  }

  const printBudgetExpenseReport = async () => {
    await buildReport(reports);
  };

  // Render
  const renderTop = () => (
    <div className="d-flex justify-content-end align-items-center">
      {!!reports.length && (
        <>
          <BubbleButton
            data-testid="print-report-float-btn"
            className="print-report-float-btn"
            disabled={isLoadingBuildReport}
            onClick={printBudgetExpenseReport}
            title={messages['admin.reports.search-title']}
          >
            {isLoadingBuildReport ? (
              <Loading width={25} height={25} />
            ) : (
              <PrintReportIcon width={25} height={25} />
            )}
          </BubbleButton>

          <Button
            data-testid="print-report-btn"
            className="pretty-btn mr-3 flex-center"
            color="outline-primary"
            disabled={isLoadingBuildReport}
            onClick={printBudgetExpenseReport}
            name="add-payment"
          >
            {messages['refund.my-cards.print-report']}
            {isLoadingBuildReport ? (
              <Loading className="ml-2" width="1.25em" height="1.25em" />
            ) : (
              <PrintReportIcon
                className="ml-2"
                width="1.25em"
                height="1.25em"
              />
            )}
          </Button>
        </>
      )}

      <StatusSelector
        className="mr-3"
        status={status}
        setStatus={setStatus}
        allowedStatus={[recordStatus.ACTIVE, recordStatus.ARCHIVED]}
        messagePrefix="budget.status"
      />

      {isEmpty ? null : (
        <RetractableSearchBar
          value={searchKey}
          onChange={(e) => setSearchKey(e.target.value)}
          placeholder={messages['refund.my-cards.search-card']}
          width={200}
        />
      )}

      {canChangeUser ? (
        <div className="mx-2 d-flex align-items-center">
          <BubbleButton
            className="mr-2"
            isPressed={onlyWithFunds}
            onClick={() => setOnlyWithFunds((value) => !value)}
            title={messages['refund.my-cards.with-funds']}
          >
            <MoneyIcon width="1.4rem" />
          </BubbleButton>
          <BubbleButton
            className="mr-3"
            isPressed={showAllCards}
            onClick={() => setShowAllCards((value) => !value)}
            title={messages['refund.my-cards.all-cards']}
          >
            <MultipleIcon width="1.5rem" style={{ strokeWidth: 2 }} />
          </BubbleButton>
          <UserSwitcher onUserChange={() => dispatch(resetSelectedBudgets())} />
        </div>
      ) : null}

      {canCreateBudgets ? (
        <Button
          className="pretty-btn ml-3"
          color="primary"
          onClick={toggleCreateCard}
          name="add-payment"
        >
          {messages['refund.my-cards.add-payment']}
        </Button>
      ) : null}
    </div>
  );

  const renderEmpty = () => {
    return (
      <>
        {renderTop()}
        <div className="w-50 mx-auto d-flex justify-content-center align-items-center mt-5 pt-4">
          <EmptyCards className="mr-5" />
          <div>
            <h2
              className="font-primary m-0 p-0 mb-2 font-weight-semibold"
              style={{ fontSize: '1.5rem', lineHeight: 1.3 }}
            >
              {messages['refund.my-cards.empty.title']}
            </h2>
            <p className="m-0 p-0 mb-4 text-muted">
              {messages['refund.my-cards.empty.description']}
            </p>
          </div>
        </div>
      </>
    );
  };

  const renderCards = () => {
    return (
      <>
        {renderTop()}

        <div className="cards-container mt-4" data-testid="render-cards">
          {filteredCards.map((card, index) => {
            const Component = CARD_COMPONENTS[card.type];

            return (
              <div
                ref={cardRefs[index]}
                key={card.id}
                data-id={card.id}
                data-index={index}
              >
                {Component ? (
                  <Component
                    card={card}
                    goToCreateBudget={goToCreateBudget}
                    goToRefund={goToRefund}
                    onCreateBudgetItem={goToCreateBudgetItem}
                    goToExpense={goToExpense}
                    visible={visibleCards[card.id]}
                    style={{
                      transitionDelay:
                        index > lastVisibleIndex.current
                          ? `${(index - lastVisibleIndex.current) * 100}ms`
                          : 0,
                    }}
                    lastCreatedBudget={
                      lastBudgets.cardId === card.id
                        ? lastBudgets.budgetId
                        : null
                    }
                    onlyWithFunds={onlyWithFunds}
                    showUser={showAllCards}
                    getData={getData}
                    doesOrganizationHasExpenses={canCreateBudgets}
                  />
                ) : null}
              </div>
            );
          })}
        </div>
      </>
    );
  };

  return (
    <div id="my-cards" className="pb-5 mb-5 with-padding">
      {user.admin ? (
        <BalanceCard show={true} style={{ marginBottom: 60 }} />
      ) : null}

      {isEmpty ? renderEmpty() : renderCards()}

      {createCardModal && <NewCreditCardModal closeModal={toggleCreateCard} />}

      {budgetId && (
        <BudgetOrderModal
          budgetId={budgetId}
          itemId={itemId}
          closeModal={closeModal}
          onCreate={setLastBudget}
          doesOrganizationHasExpenses={canCreateBudgets}
        />
      )}

      {cardId && (
        <ChangePasswordModal cardId={cardId} closeModal={closeModal} />
      )}
    </div>
  );
}
