import { useEffect, useState, useCallback } from 'react';
import appFunctions from '../services/Functions';
import useDataArchive from './useDataArchive';

import { DEFAULT_CURRENCY } from '../utils/money';

export default function useCurrencyQuotation({ currency = DEFAULT_CURRENCY }) {
  const [loading, setLoading] = useState(true);
  const [quote, setQuote] = useState(1);
  const [error, setError] = useState(false);

  const { getDataFromArchive } = useDataArchive();

  // Effects
  useEffect(() => {
    setError(false);

    if (currency === DEFAULT_CURRENCY) {
      setQuote(1);
      setLoading(false);
    } else {
      getQuote(currency);
    }
  }, [currency]);

  // Function
  const getQuote = useCallback(async (currency) => {
    try {
      const quote = await getDataFromArchive(currency, () =>
        appFunctions.getCurrencyQuote(currency)
      );

      setQuote(quote);
    } catch (err) {
      console.error('Unable to get quote', err);
      setError(true);
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    loading,
    quote,
    error,
  };
}
