import React, { useEffect, useState } from 'react';
import PreviewActionButtons from './PreviewActionButtons';

export default function ImagePreview({
  src,
  className = '',
  zoom = 400,
  preventPropagation = false,
  show,
  selectedIndex,
  editable,
  canDoAction,
  onDelete,
  white,
}) {
  const [zoomIn, setZoomIn] = useState(false);
  const [style, setStyle] = useState({});

  useEffect(() => {
    if (!zoomIn)
      setStyle({ backgroundPosition: 'center', backgroundSize: 'contain' });
  }, [zoomIn]);

  useEffect(() => {
    setZoomIn(false);
  }, [show, selectedIndex]);

  const normalizeValue = (value) => {
    if (value < 0) return 0;
    else if (value > 100) return 100;
    else return value;
  };

  const handleClick = (e) => {
    if (preventPropagation) {
      e.stopPropagation();
    }

    if (zoomIn) setZoomIn(false);
    else {
      setZoomIn(true);
      handleMouseMove(e, true, true);
    }
  };

  const handleMouseMove = (e, doIt = zoomIn, removeTransition = false) => {
    if (doIt) {
      var { left, top, width, height } = e.target.getBoundingClientRect();
      var x = (e.clientX - left) / width; //x position within the element.
      var y = (e.clientY - top) / height; //y position within the element.

      const newStyle = {
        ...style,
        backgroundSize: `${zoom}%`,
        backgroundPosition: `${normalizeValue(x * 100)}% ${normalizeValue(
          y * 100
        )}%`,
        transition: removeTransition ? 'none' : '',
      };

      setStyle(newStyle);
    }
  };

  const openImage = () => {
    window.open(src, '_blank');
  };

  return (
    <div className={`image-preview ${className}`} role="img">
      <div
        className={`image ${zoomIn ? 'zoom-in' : 'zoom-out'}`}
        onClick={handleClick}
        onMouseMove={handleMouseMove}
        style={{
          backgroundImage: `url(${src})`,
          ...style,
        }}
      />
      <PreviewActionButtons
        onDelete={onDelete}
        onOpenFile={openImage}
        canDoAction={canDoAction}
        editable={editable}
        white={white}
      />
    </div>
  );
}
