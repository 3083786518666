import moment from 'moment';
import { ADMINISTRATIVE_BUDGET_ITEM_TYPE } from '../constants/administrativeBudgets';
import { stringToMoney } from './money';

export function getStandardBudgetItemPayload(item) {
  return {
    name: item.name,
    total: stringToMoney(item.fare.total, 2, item.fare.currency),
    date: (item.invoice || item.date
      ? moment(item.date)
      : moment.unix(item.createdAt || item.createAt)
    ).format('HH:mm'),
    iconSrc: item.category?.icon
      ? `/assets/img/refund/${item.category.icon}`
      : `/assets/img/refund/money.svg`,
    iconAlt: item.category?.label || item.name,
    refunded: item.refunded,
  };
}

export function getAdministrativeBudgetItemPayload(item, messages) {
  return {
    name: item.name,
    date: messages[`administrative.payment-option.${item.payment.type}`],
    iconSrc: `/assets/img/expenses/${item.payment.type}.svg`,
    iconAlt: item.name,
    total: stringToMoney(item.fare.total, 2, item.fare.currency),
    refunded: item.refunded,
  };
}

export function getBudgetItemPayload(budgetItem, messages) {
  const payloadFunc =
    budgetItem.type === ADMINISTRATIVE_BUDGET_ITEM_TYPE
      ? getAdministrativeBudgetItemPayload
      : getStandardBudgetItemPayload;

  return payloadFunc(budgetItem, messages);
}
