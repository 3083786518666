import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import { CustomInput, Label, Row } from 'reactstrap';
import { useSelector } from 'react-redux';
import useExecuteAction from '../../../../../hooks/useExecuteAction';
import useDataArchive from '../../../../../hooks/useDataArchive';
import appFunctions from '../../../../../services/Functions';
import { Colxx } from '../../../../common/CustomBootstrap';
import NumberFormat from 'react-number-format';
import { formatPix, getPixType } from '../../../../../utils/pix';
import { debounce } from 'lodash';
import AnimateHeight from 'react-animate-height';
import { PixTypes } from '../../../../../constants/pix';
import { pixBanks } from '../../../../../constants/pixBanks';
import { convertToFloat } from '../../../../../utils/money';
import classNames from 'classnames';

export default function PixTab({
  budget,
  cardId,
  paymentInfo,
  setPaymentInfo,
  addToPaymentInfo,
  resetPaymentInfo,
}) {
  const { user } = useSelector(({ auth }) => auth);
  const { messages } = useIntl();

  const { executeAction, loading } = useExecuteAction();
  const { getDataFromArchive } = useDataArchive();

  // Refs
  const inputRef = useRef(null);

  // States
  const pixInfo = paymentInfo?.info;

  const [pixKey, setPixKey] = useState(() => pixInfo?.raw?.key || '');
  const [pixTypeInfo, setPixTypeInfo] = useState(() => pixInfo?.raw || null);
  const [lastKey, setLastKey] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const { document, bankName, owner } = useMemo(() => {
    if (!pixInfo) return {};

    const { bank_ispb, document, name } = pixInfo.credit_party;

    return {
      document: document,
      bankName: pixBanks[bank_ispb].short_name,
      owner: name,
    };
  }, [pixInfo]);

  // Effects
  useEffect(() => {
    assertPix(pixKey);
  }, [pixKey]);

  useEffect(() => {
    if (!lastKey || lastKey !== pixKey)
      checkPix(pixTypeInfo, user.organizationId, cardId, paymentInfo);
  }, [pixTypeInfo]);

  // Functions
  const assertPix = (pixKey) => {
    const type = getPixType(pixKey);

    setPixTypeInfo({
      type,
      key: (type ? formatPix(type, pixKey) : pixKey).trim(),
    });
  };

  const checkPix = useCallback(
    debounce((pixTypeInfo, organizationId, cardId, paymentInfo = {}) => {
      setErrorMessage('');

      if (!pixTypeInfo?.type) {
        if (pixTypeInfo?.key) {
          setErrorMessage(messages['administrative.pix.error.invalid']);
        }

        return;
      }

      executeAction(
        (async () => {
          try {
            if (inputRef.current) {
              inputRef.current.blur();
            }

            const { type, key } = pixTypeInfo;
            setLastKey(key);

            const pixInfo = await getDataFromArchive(`pix-${type}-${key}`, () =>
              appFunctions.checkPix(organizationId, cardId, type, key)
            );

            const isQrCode = type === PixTypes.QRCODE;
            const pixAmount = isQrCode ? convertToFloat(pixInfo.amount) : 0;

            setPaymentInfo((paymentInfo) => ({
              ...paymentInfo,
              total: pixAmount || paymentInfo.total || 0,
              info: {
                ...pixInfo,
                raw: {
                  type,
                  key,
                  rawKey: isQrCode
                    ? pixInfo.pix_copy_paste
                    : pixInfo.credit_party?.key || key,
                },
                amount: pixAmount,
              },
            }));
          } catch (err) {
            console.error(err);
            setErrorMessage(messages['administrative.pix.error.fetch']);
            setPaymentInfo({ type: paymentInfo.type });
            setLastKey('');
          }
        })()
      );
    }, 500),
    []
  );

  // Functions
  const onPixKeyChange = (e) => {
    if (pixInfo) resetPaymentInfo();
    setPixKey(e.target.value);
  };

  // Render
  const renderPixInfo = () => (
    <>
      <Row>
        <Colxx md="6" sm="12">
          <Label className="form-group disabled search has-top-label">
            <NumberFormat
              name="pix-value"
              prefix={'R$ '}
              suffix={''}
              thousandSeparator="."
              decimalSeparator=","
              decimalScale="2"
              fixedDecimalScale="2"
              className="currency-input w-100 form-purple-value mt-0"
              onValueChange={(v) => addToPaymentInfo('total', v.floatValue)}
              value={paymentInfo?.total}
              disabled={pixInfo?.amount}
            />
            <span>{messages['administrative.pix.value']} *</span>
          </Label>
        </Colxx>
        <Colxx md="6" sm="12">
          <Label className="form-group disabled search has-top-label">
            <CustomInput
              id="pix-description-input"
              value={paymentInfo?.description}
              type="text"
              onChange={(e) => addToPaymentInfo('description', e.target.value)}
              className="form-control form-purple-value bold"
              maxLength={100}
            />
            <span>{messages['administrative.pix.description']}</span>
          </Label>
        </Colxx>
      </Row>
      <Row></Row>
      <Row>
        <Colxx sm="12">
          <Label className="form-group disabled search has-top-label">
            <p className="text-uppercase form-control form-purple-value bold">
              {bankName}
            </p>
            <span>{messages['administrative.pix.bank-name']}</span>
          </Label>
        </Colxx>
      </Row>
      <Row>
        <Colxx sm="12">
          <Label className="form-group disabled search has-top-label">
            <p className="text-uppercase form-control form-purple-value bold">
              {owner}
            </p>
            <span>{messages['administrative.pix.owner']}</span>
          </Label>
        </Colxx>
        <Colxx sm="12">
          <Label className="form-group disabled search has-top-label">
            <p className="text-uppercase form-control form-purple-value bold">
              {document}
            </p>
            <span>{messages['administrative.pix.tax-id']}</span>
          </Label>
        </Colxx>
      </Row>
    </>
  );

  return (
    <div className="reservation-params refund-params light">
      <div className="px-3">
        <Row>
          <Colxx sm="12">
            <Label className="form-group disabled search has-top-label">
              <CustomInput
                id="pix-key-input"
                value={isFocused ? pixKey : pixTypeInfo?.key || pixKey}
                type="text"
                onChange={onPixKeyChange}
                className={classNames('form-control form-purple-value bold', {
                  fetching: loading,
                })}
                disabled={loading}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                maxLength={200}
                innerRef={inputRef}
              />

              <span>{messages['administrative.pix.key']} *</span>

              {loading ? (
                <div className="simple-loading-container">
                  <div className="loading" />
                </div>
              ) : null}
            </Label>
          </Colxx>
        </Row>
        <AnimateHeight height={errorMessage ? 'auto' : 0} easing="ease">
          <p className="text-primary mt-0 pt-0 font-weight-semibold fs-0-7rem">
            {errorMessage}
          </p>
        </AnimateHeight>

        {pixInfo ? renderPixInfo() : null}
      </div>
    </div>
  );
}
