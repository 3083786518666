import classNames from 'classnames';
import React from 'react';
import BudgetParams from '../../../expensesTimeline/BudgetParams';

export default function BudgetInfoTab({
  budget,
  onBudgetChange,
  hasBudget,
  budgetCategories,
  setBudget,
  editable = true,
  setShowNameDocumentation,
  administrative,
  maxDate,
  className,
  cardType,
}) {
  return (
    <div className={classNames('hotel-content', className)}>
      <BudgetParams
        budget={budget}
        onBudgetChange={onBudgetChange}
        editable={editable}
        hasBudget={hasBudget}
        budgetCategories={budgetCategories}
        setBudget={setBudget}
        setShowNameDocumentation={setShowNameDocumentation}
        administrative={administrative}
        maxDate={maxDate}
        cardType={cardType}
      />
    </div>
  );
}
