import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import {
  Button,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import PinInput from 'react-pin-input';
import classNames from 'classnames';
import CustomErrorMessage from '../CustomErrorMessage';
import appFunctions from '../../services/Functions';
import SavingScreen from '../common/SavingScreen';
import { getPassengerRedux } from '../../utils/redux';

const initalState = { password: '', confirmation: '' };

export default function ChangePasswordModal({
  closeModal = () => {},
  pinLength = 4,
  cardId,
}) {
  const { messages } = useIntl();
  const { passenger } = useSelector(getPassengerRedux);

  const [stage, setStage] = useState(0);
  const [execute, setExecute] = useState(false);

  // Functions
  const changePassword = async (password) => {
    await appFunctions.updateExpenseCardPin(
      passenger.organizationId,
      cardId,
      password
    );
    closeModal();
  };

  // Render
  const renderPassword = (setFieldValue) => {
    return (
      <div
        className={classNames('container text-center mb-5', {
          left: execute,
        })}
        key="password"
      >
        <Label className="text-center">
          <p>{messages['change-pin.password']}</p>
          <div className="custom-pin-input">
            <PinInput
              length={pinLength}
              type="numeric"
              inputMode="number"
              secret
              focus
              onComplete={(password) => {
                setFieldValue('password', password);
                setExecute(true);
                setTimeout(() => {
                  setStage(1);
                  setExecute(false);
                }, 400);
              }}
            />
          </div>
        </Label>
      </div>
    );
  };

  const renderPasswordConfirmation = (setFieldValue, errors, values) => {
    return (
      <div
        className={classNames('container text-center mb-5', {
          right: execute,
        })}
        key="confirmation"
      >
        <Label className="text-center">
          <p>{messages['change-pin.confirmation']}</p>
          <div className="custom-pin-input">
            <PinInput
              length={pinLength}
              type="numeric"
              inputMode="number"
              secret
              focus
              onComplete={(password) => {
                setFieldValue('confirmation', password, true);
              }}
              onChange={() => {
                if (values.confirmation)
                  setFieldValue('confirmation', '', true);
              }}
            />
          </div>
          <CustomErrorMessage
            error={errors.confirmation === 'Diff'}
            style={{ maxWidth: 200, marginTop: 10 }}
          >
            {messages['change-pin.not-same-password']}
          </CustomErrorMessage>
        </Label>
      </div>
    );
  };

  return (
    <Modal
      isOpen={true}
      toggle={closeModal}
      size="lg"
      className="admin change-card-pin"
    >
      <ModalHeader toggle={closeModal}></ModalHeader>
      <Formik
        initialValues={initalState}
        validationSchema={Yup.object().shape({
          password: Yup.string().min(4).required(),
          confirmation: Yup.string()
            .min(4)
            .required()
            .oneOf([Yup.ref('password')], 'Diff'),
        })}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            await changePassword(values.password);
          } catch (err) {
            console.error(err);
            setSubmitting(false);
          }
        }}
        validateOnChange
        validateOnMount
      >
        {({
          isSubmitting,
          values,
          setFieldValue,
          setValues,
          isValid,
          errors,
        }) => (
          <Form>
            <SavingScreen show={isSubmitting} />
            <ModalBody>
              {stage === 0
                ? renderPassword(setFieldValue)
                : renderPasswordConfirmation(setFieldValue, errors, values)}
            </ModalBody>
            <ModalFooter>
              {stage > 0 ? (
                <Button
                  className="back-btn m-0 p-0 mr-3"
                  type="button"
                  color="link"
                  size="sm"
                  onClick={() => {
                    setStage(0);
                    setValues(initalState);
                  }}
                >
                  {messages['general.go-back']}
                </Button>
              ) : null}
              <Button
                type="submit"
                color="primary"
                disabled={isSubmitting || !isValid}
                outline
                size="lg"
              >
                {messages['change-pin.submit']}
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
