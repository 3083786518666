import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Button } from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { stringToMoney } from '../../../../../../utils/money';
import Spinner from '../../../../../common/Spinner';
import { FLIGHT_GORDIAN_TABS } from '..';
import PersonalPayment from '../PersonalPayment';
import OrganizationPayment from '../OrganizationPayment';
import useDataArchive from '../../../../../../hooks/useDataArchive';
import firebaseApp from '../../../../../../services/Firebase';
import useBlockableAction from '../../../../../../hooks/useBlockableAction';

const TABS = {
  ORGANIZATION: 'organization',
  PERSONAL: 'personal',
};

export default function CheckoutTab({
  ancillaries,
  basket,
  total,
  goForAlteration,
  setCurrentTab,
  checkPolicies,
}) {
  const { messages } = useIntl();

  const [loading, setLoading] = useState(false);
  const [isAllowed, setIsAllowed] = useState({});
  const [payment, setPayment] = useState(null);
  const [tab, setTab] = useState();
  const [error, setError] = useState(null);

  const { allowAction, blockAction, resetAction } = useBlockableAction(
    `${ancillaries.id}-${ancillaries.statusCode}`
  );

  const { getDataFromArchive: getPayment } = useDataArchive();

  // Memos
  const formattedTotal = useMemo(() => stringToMoney(total, 2), [total]);
  const tabItems = useMemo(() => Object.values(TABS), []);
  const canCheckout = useMemo(
    () => !loading && (isAllowed || payment),
    [loading, payment, isAllowed]
  );
  const Component = useMemo(
    () => (tab === TABS.ORGANIZATION ? OrganizationPayment : PersonalPayment),
    [tab]
  );

  // Effects
  useEffect(() => {
    const check = async () => {
      setLoading(true);
      const policies = await checkPolicies();
      setIsAllowed(policies);
      setLoading(false);
    };

    check();
  }, [basket.trip_state_hash]);

  useEffect(() => {
    setTab(isAllowed ? TABS.ORGANIZATION : TABS.PERSONAL);
  }, [isAllowed]);

  // Functions
  const doCheckout = async () => {
    try {
      blockAction();

      setLoading(true);
      await firebaseApp.requetToBookAncillary(ancillaries, payment?.value);
      goForAlteration();
    } catch (err) {
      console.error(err);
      setError(messages['flight-alteration.checkout.error.cancel']);
      setLoading(false);

      resetAction();
    }
  };

  // Render
  const renderContent = () => (
    <PerfectScrollbar
      options={{
        suppressScrollX: true,
        wheelPropagation: false,
      }}
      className="h-100 content"
    >
      {error ? <p className="error-badge mb-4">{error}</p> : null}

      {isAllowed ? (
        <div className="tabs d-flex align-items-center mb-3">
          {tabItems.map((item) => (
            <nav
              key={item}
              onClick={() => setTab(item)}
              className={item === tab ? 'selected' : ''}
            >
              {messages[`flight.checkout-page.${item}`]}
            </nav>
          ))}
        </div>
      ) : null}

      <Component
        payment={payment}
        setPayment={setPayment}
        getPayment={getPayment}
        isAllowed={isAllowed}
      />
    </PerfectScrollbar>
  );

  return (
    <div className="d-flex flex-column justify-content-between h-100 checkout-page">
      <div>
        <h3 className="hotel-title m-0">
          {messages['travel.alter.checkout.title']}
        </h3>
        <p className="m-0 mt-1" style={{ opacity: 0.5 }}>
          {messages['travel.alter.checkout.subtitle']}
        </p>
      </div>

      <div className="flex-grow-1 py-4" style={{ minHeight: 0 }}>
        {loading ? (
          <div className="d-flex align-items-center justify-content-center w-100 h-100">
            <Spinner relative white style={{ width: 60, height: 60 }} />
          </div>
        ) : (
          renderContent()
        )}
      </div>

      <div className="d-flex align-items-center justify-content-between flex-shrink-0">
        {total > 0 ? (
          <div className="price-container">
            <p
              className="m-0 p-0 one-line-height title font-weight-semibold mb-1"
              style={{ fontSize: '0.6rem' }}
            >
              {messages['general.total']}
            </p>
            <p
              className="m-0 p-0 one-line-height total"
              style={{ fontSize: '1rem' }}
            >
              {formattedTotal}
            </p>
          </div>
        ) : (
          <div />
        )}

        <div>
          <Button
            color="link"
            onClick={() => setCurrentTab(FLIGHT_GORDIAN_TABS.INITIAL)}
            className="m-0"
          >
            {messages['general.go-back']}
          </Button>
          <Button
            color="primary"
            disabled={!canCheckout || loading || !allowAction}
            className="m-0"
            onClick={doCheckout}
          >
            {messages['flight-alteration.checkout']}
          </Button>
        </div>
      </div>
    </div>
  );
}
