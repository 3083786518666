import React from 'react';
import Spinner from './Spinner';
import classNames from 'classnames';

export default function SavingScreen({
  show = false,
  white = true,
  style = {},
  spinnerStyle = { width: '80%', height: '80%' },
  containerStyle = {},
  noBackground,
}) {
  return (
    show && (
      <div
        className={classNames('saving-screen w-100 h-100 flex-center', {
          'non-white': !white,
          'no-background': noBackground,
        })}
        style={containerStyle}
      >
        <div className="spinner-container flex-center" style={style}>
          <Spinner white={white} style={spinnerStyle} relative />
        </div>
      </div>
    )
  );
}
