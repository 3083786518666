import React from 'react';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSelector } from 'react-redux';
import ApprovalOrders from './ApprovalOrders';
import { useIntl } from 'react-intl';

const ApprovalCounter = ({ loading, length = 0 }) => {
  if (loading) {
    return <div className="loading" />;
  } else {
    if (length > 0) return <span className="count">{length}</span>;

    return <div />;
  }
};

const ApprovalEmpty = () => {
  const { messages } = useIntl();

  return (
    <div className="flex-center h-100">
      <div className="text-center text-muted">
        {messages['approval-orders.none']}
      </div>
    </div>
  );
};

export default function TopnavNotifications() {
  const {
    approvalInfo: { loading, numOrders, approvals, lastIndex },
  } = useSelector(({ approvals }) => approvals);

  return (
    <div className="position-relative d-inline-block">
      <UncontrolledDropdown className="dropdown-menu-right">
        <DropdownToggle
          className="header-icon notificationButton"
          color="empty"
          style={{ pointerEvents: loading ? 'none' : 'all' }}
        >
          <i className="simple-icon-bell bell" />
          <ApprovalCounter loading={loading} length={numOrders} />
        </DropdownToggle>
        <DropdownMenu
          className="position-absolute mt-1 scroll"
          right
          id="notificationDropdown"
        >
          <PerfectScrollbar
            options={{ suppressScrollX: true, wheelPropagation: false }}
            style={{ touchAction: 'none' }}
          >
            {numOrders > 0 ? (
              approvals.map((orders, index) => {
                return (
                  <ApprovalOrders
                    key={index}
                    orders={orders}
                    style={{ marginBottom: index < lastIndex ? 30 : 0 }}
                  />
                );
              })
            ) : (
              <ApprovalEmpty />
            )}
          </PerfectScrollbar>
        </DropdownMenu>
      </UncontrolledDropdown>
    </div>
  );
}
