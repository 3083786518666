import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';

import {
  UncontrolledDropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
} from 'reactstrap';

import ProfileImage from '../ProfileImage';
import User from '../../services/User';
import UserModal from '../../containers/users/UserModal';
import { useIntl } from 'react-intl';

import { languages } from '../../constants/languages';
import { changeLocale, resetChecklist } from '../../redux/actions';
import LocaleSelector from './LocaleSelector';
import firebaseApp from '../../services/Firebase';
import debounce from 'lodash/debounce';

const setUserLocale = debounce(
  (locale) => firebaseApp.setUserLocale(locale),
  2000
);

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function UserDropdown() {
  const history = useHistory();
  const query = useQuery();
  const { messages } = useIntl();

  const dispatch = useDispatch();
  const locale = useSelector((state) => state.settings.locale);

  const [profileModal, setProfileModal] = useState(false);

  const { user } = useSelector((state) => state.auth);

  // Profile
  const toggleProfileModal = () => {
    setProfileModal(!profileModal);
  };

  // Locale
  const onLocaleChange = ({ value }) => {
    dispatch(changeLocale(value));
    setUserLocale(value);
  };

  // Logout
  const handleLogout = () => {
    dispatch(resetChecklist());
    User.logOut();
    history.push(`/`);
  };

  // Open Terms and Privacy
  const openTerms = () => {
    query.append('terms-of-service', '');

    history.replace({ search: query.toString() });
  };

  const openPrivacy = () => {
    query.append('privacy-policy', '');

    history.replace({ search: query.toString() });
  };

  return (
    <UncontrolledDropdown className="dropdown-menu-right user-menu">
      <DropdownToggle className="p-0" color="empty">
        <span>
          <ProfileImage user={user} xsmall />
        </span>
      </DropdownToggle>
      <DropdownMenu className="mt-3" right>
        <DropdownItem onClick={toggleProfileModal}>
          {messages['components.navs.user-dropdown.profile']}
        </DropdownItem>
        <DropdownItem toggle={false} className="language-container">
          <LocaleSelector
            options={languages}
            defaultValue={languages.find((obj) => obj.value === locale)}
            onChange={onLocaleChange}
          />
        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem onClick={openTerms}>
          {messages['admin.settings.terms']}
        </DropdownItem>
        <DropdownItem onClick={openPrivacy}>
          {messages['admin.settings.policy']}
        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem onClick={handleLogout}>
          {messages['components.navs.user-dropdown.logout']}
        </DropdownItem>
      </DropdownMenu>

      {profileModal && (
        <UserModal toggleModal={toggleProfileModal} userId={user.uid} />
      )}
    </UncontrolledDropdown>
  );
}
