import { useState, useEffect, useMemo } from 'react';
import { Modal, ModalBody, ModalFooter, Button } from 'reactstrap';
import classnames from 'classnames';
import PerfectScrollbar from 'react-perfect-scrollbar';
import firebaseApp from '../../../services/Firebase';
import { useIntl } from 'react-intl';
import SavingScreen from '../../common/SavingScreen';
import {
  CanEditBudgetItems,
  CanEditTransactionBudgetItems,
  RefundStatus as RefundStatusCodes,
} from '../../../constants/refundStatus';
import useDataSnapshot from '../../../hooks/useDataSnapshot';
import { AllowBudgetItemCreation } from '../../../constants/refundTypes';
import RouteStopDragLayer from '../RouteStopDragLayer';
import BudgetStatus from '../../expenses/BudgetStatus';
import Comment from '../Comment';
import BudgetParams from '../BudgetParams';
import BudgetItems from '../../expenses/BudgetItems';
import useHistoryWithScroll from '../../../hooks/useHistoryWithScroll';
import useBlockableAction from '../../../hooks/useBlockableAction';
import Dialog from '../../Dialog';
import { useSelector } from 'react-redux';
import recordStatus from '../../../constants/recordStatus';
import ExpenseItemDecider from '../../expenses/ExpenseItemDecider';
import { isBudgetItemsValid } from './utils';
import useShowToggle from 'src/portao3-legacy/hooks/useShowToggle';
import useModalStages from '../../../hooks/useModalStages';
import { BUDGET_PATH } from 'src/portao3-legacy/constants/paths';
import useLocationMapping from '../../../hooks/useLocationMapping/useLocationMapping';
import { canApproveReservation } from '../../../utils/orders';

export default function BudgetModal({
  toggleModal,
  budget,
  creditCard,
  itemId,
  approvals,
  path = BUDGET_PATH,
}) {
  const { organization, user } = useSelector(({ auth }) => auth);

  const { messages } = useIntl();
  const { push, location } = useHistoryWithScroll();

  // Hooks
  const { allowAction, blockAction, resetAction } = useBlockableAction({
    id: `${budget.id}-${budget.statusCode}`,
  });

  const [expenses, setExpenses, loadingExpenses] = useDataSnapshot({
    fetchFunction: () => firebaseApp.getItemsFromBudget(budget.id),
    formatFunction: (data) => data.filter((item) => item.name),
  });

  const { isApproving, setIsApproving, toggleIsApproving } = useModalStages({
    item: budget,
    path,
  });

  useLocationMapping(
    {
      paths: {
        approval: {
          onMatch: () => setIsApproving(true),
          validate: () =>
            itemId !== 'new' && canApproveReservation({ approvals, user }),
          onFail: () => toggleIsApproving(false),
        },
      },
    },
    [approvals, user]
  );

  // States
  const [isSaving, setIsSaving] = useState(false);
  const [showExpense, setShowExpense] = useState(false);
  const [selectedExpense, setSelectedExpense] = useState(undefined);
  const [total, setTotal] = useState(0);
  const [showDialog, setShowDialog, toggleShowDialog] = useShowToggle();

  const shouldShowExpenses = useMemo(
    () =>
      budget.statusCode >= RefundStatusCodes.ACTIVE || budget.administrative,
    [budget]
  );

  const { isValid, title } = useMemo(
    () =>
      isBudgetItemsValid({
        budget,
        expenses,
        messages,
        organization,
      }),
    [expenses, organization, budget]
  );

  const allowEditing = useMemo(() => {
    const allowedStatus = budget.withAnticipation
      ? CanEditTransactionBudgetItems
      : CanEditBudgetItems;
    return allowedStatus.includes(budget.statusCode);
  }, [budget]);

  const allowBudgetItemCreation = useMemo(
    () => allowEditing && AllowBudgetItemCreation.includes(creditCard.type),
    [allowEditing, creditCard]
  );

  const isCardArchived = useMemo(
    () => creditCard?.status === recordStatus.ARCHIVED,
    [creditCard]
  );

  const canCreateExpense = useMemo(
    () =>
      allowEditing &&
      isValid &&
      allowBudgetItemCreation &&
      expenses.length &&
      !isCardArchived,
    [allowAction, isValid, allowBudgetItemCreation, expenses, isCardArchived]
  );

  // Effects
  useEffect(() => {
    if (budget) setTotal(budget.fare.total);
  }, [budget]);

  useEffect(() => {
    if (loadingExpenses) return;

    if (itemId && (itemId !== 'new' || allowBudgetItemCreation)) {
      const budgetItem =
        itemId === 'new'
          ? {}
          : expenses.find((expense) => expense.id === itemId);

      if (budgetItem) {
        setSelectedExpense(budgetItem);
        return setShowExpense(true);
      }
    }

    setShowExpense(false);
    if (!location.pathname.includes('/approval')) push(`${path}/${budget.id}`);
  }, [loadingExpenses, itemId, allowBudgetItemCreation]);

  useEffect(() => {
    if (expenses.length && budget.createdByOpenBooking)
      goToExpense(expenses[0].id);
  }, [expenses]);

  // Functions
  const updateTotal = (value) => {
    setTotal((total) => total + value);
  };

  const handleSaving = async (callback) => {
    setIsSaving(true);
    await callback();
    setIsSaving(false);
    toggleModal();
  };

  const performAction = async (func, isValid = true) => {
    try {
      if (!allowAction || !isValid) return;

      blockAction();
      await func();
    } catch (err) {
      console.error(err);
      resetAction();
    }
  };

  const doSendForApproval = async () => {
    performAction(async () => {
      await firebaseApp.requestToSendBudgetForApproval(budget.id);
      toggleModal();
    }, budget.statusCode < RefundStatusCodes.APPROVING);
  };

  const handlePutOnHold = async () => {
    performAction(async () => {
      await firebaseApp.requestToPutOnHold(budget.id);
      toggleModal();
    }, budget.statusCode < RefundStatusCodes.APPROVING);
  };

  const handleSendForApproval = () => {
    if (
      budget.statusCode === RefundStatusCodes.ACTIVE &&
      budget.withAnticipation
    )
      setShowDialog(true);
    else doSendForApproval();
  };

  const handleCancel = async () => {
    performAction(
      async () => firebaseApp.requestToCancelBudget(budget.id),
      budget.statusCode < RefundStatusCodes.APPROVED
    );
  };

  const handleDismiss = async () => {
    performAction(
      async () => firebaseApp.requestToDismissBudget(budget.id),
      budget.statusCode > RefundStatusCodes.REVIEW
    );
  };

  const removeBudgetItem = async (budgetItem) => {
    if (allowEditing) {
      try {
        if (selectedExpense && budgetItem.id === selectedExpense.id) {
          toggle();
        }

        await firebaseApp.deleteBudgetItem(budget.id, budgetItem.id);
        setExpenses(
          expenses.filter((otherExpense) => otherExpense.id !== budgetItem.id)
        );
        updateTotal(-budgetItem.fare.total);
      } catch (err) {
        console.error(err);
      }
    }
  };

  // Aproval
  const goToExpense = (budgetItemId) => {
    if (isApproving) setIsApproving(false);

    if (itemId === budgetItemId) {
      push(`${path}/${budget.id}/`);
    } else push(`${path}/${budget.id}/items/${budgetItemId}`);
  };

  const toggle = () => {
    setShowExpense(false);
    setSelectedExpense(null);
    push(`${path}/${budget.id}`);
  };

  const payBudget = async () => {
    try {
      performAction(async () => {
        await firebaseApp.requestToPayBudgetManually(budget.id, user);
      });

      toggleModal();
    } catch (error) {
      console.error(error);
    }
  };

  // Render
  const renderExpenses = () => (
    <div className="mt-4">
      {canCreateExpense ? (
        <div className="mx-3 mb-3">
          <Button
            size="sm"
            color="primary"
            className="mb-2"
            outline
            onClick={() => goToExpense('new')}
          >
            {messages['budget.modal.new-item']}
          </Button>
        </div>
      ) : null}
      <div className="pb-4">
        <BudgetItems
          items={expenses}
          currency={budget.fare.currency}
          onClick={(expense) => goToExpense(expense.id)}
          removeExpenseFromRefund={removeBudgetItem}
          selectedExpense={showExpense ? selectedExpense : undefined}
          small={showExpense}
          editable={allowEditing && !isCardArchived}
          onCreate={() => goToExpense('new')}
          allowBudgetItemCreation={allowBudgetItemCreation}
          allowDeletion={!budget.withAnticipation}
          showHeaderDate={!budget.administrative}
        />
      </div>
    </div>
  );

  if (loadingExpenses) {
    return (
      <Modal isOpen={true} toggle={toggleModal} className="reservation small">
        <ModalBody>
          <div className="loading p-relative" />
        </ModalBody>
      </Modal>
    );
  }

  return (
    <>
      <Modal
        isOpen={true}
        toggle={toggleModal}
        className={classnames('refund-modal', {
          reservation: true,
          small: true,
          approving: isApproving,
        })}
      >
        <SavingScreen show={isSaving} containerStyle={{ zIndex: 200 }} />

        <ModalBody
          className={classnames('flight d-flex', {
            'show-expense': showExpense,
          })}
        >
          <div className="flight-content left-part">
            <PerfectScrollbar
              options={{
                suppressScrollX: true,
                wheelPropagation: false,
              }}
              className="pb-5"
            >
              <div>
                <h5 className="mt-4 ml-3">
                  {messages['refund.modal.refund.all-data']}
                </h5>
                <BudgetParams budget={budget} />
              </div>

              {shouldShowExpenses ? renderExpenses() : null}

              {budget.comment ? (
                <div className="mx-3 comments">
                  <h5 className="m-0 p-0 mb-1 title">
                    {messages['refund.modal.refund.comments']}
                  </h5>
                  <Comment comment={budget.comment} />
                </div>
              ) : null}
            </PerfectScrollbar>
          </div>
          <div className="right-part position-relative h-100">
            {selectedExpense && (
              <ExpenseItemDecider
                key={selectedExpense.id}
                budget={budget}
                editable={allowEditing && !isCardArchived}
                item={selectedExpense}
                toggle={toggle}
                updateTotal={updateTotal}
                canCreateItem={allowBudgetItemCreation}
              />
            )}
          </div>
        </ModalBody>

        <ModalFooter className="p-3">
          <BudgetStatus
            budget={budget}
            budgetItems={expenses}
            toggleModal={toggleModal}
            approvals={approvals}
            isValid={isValid}
            onSendForApproval={handleSendForApproval}
            onDismiss={() => handleSaving(handleDismiss)}
            onCancel={() => handleSaving(handleCancel)}
            onPutOnHold={handlePutOnHold}
            total={total}
            goForApproval={() => toggleIsApproving()}
            payBudget={payBudget}
            isApproving={isApproving}
            disableActions={!allowAction}
            title={title}
            isCardArchived={isCardArchived}
          />
        </ModalFooter>
      </Modal>

      <RouteStopDragLayer />

      <Dialog
        isOpen={showDialog}
        toggle={toggleShowDialog}
        title={messages['refund.dialog.send-for-approval.title']}
        onYes={doSendForApproval}
      >
        <p className="m-0 p-0">{messages['refund.dialog.send-for-approval']}</p>
      </Dialog>
    </>
  );
}
