import { filterTrueValues } from 'src/portao3-legacy/utils/filters';

import { TObject } from 'src/portao3-legacy/types/others';

import {
  FilterQueryTypes,
  TCustomFilterQuery,
  TFilterQuery,
  TContainsFilterQuery,
  TMapFilterQuery,
  TRangeFilterQuery,
  TStringFilterQuery,
} from './types';

function doGetValue<I, Q>(
  name: string = '',
  item: I,
  getValue: TFilterQuery<I, Q>['getValue']
) {
  return getValue ? getValue(item) : (item as any)[name];
}

function doGetParam<I, Q>(
  name: string = '',
  params: any = {},
  getParams: TFilterQuery<I, Q>['getParam']
) {
  return getParams ? getParams(params) : params[name];
}

function filterString<I, P>(
  {
    name,
    getValue,
    getParam,
    truthWhenEqualsTo = true,
    fullyEqual,
    skipIfEmpty = true,
  }: TStringFilterQuery<I, P>,
  item: any,
  params: any
): boolean {
  const stringParam = doGetParam(name, params, getParam) || '';

  if (skipIfEmpty && !stringParam) return true;

  const value = doGetValue(name, item, getValue);

  return (
    (fullyEqual ? value === stringParam : value.includes(stringParam)) ===
    truthWhenEqualsTo
  );
}

function filterRange<I, P>(
  {
    name,
    getValue,
    getParam,
    minKey = 'min',
    maxKey = 'max',
    truthWhenEqualsTo = true,
  }: TRangeFilterQuery<I, P>,
  item: any,
  params: any
): boolean {
  const value = doGetValue(name, item, getValue);
  const rangeParam = doGetParam(name, params, getParam);

  const min = rangeParam[minKey],
    max = rangeParam[maxKey];

  const isInRange = (!min || value >= min) && (!max || value <= max);

  return isInRange === truthWhenEqualsTo;
}

function filterRangeMap<I, P>(
  {
    name,
    getValue,
    getParam,
    minKey = 'min',
    maxKey = 'max',
    truthWhenEqualsTo = true,
  }: TRangeFilterQuery<I, P>,
  item: any,
  params: any
): boolean {
  const value = doGetValue(name, item, getValue);
  const rangeParam = doGetParam(name, params, getParam);

  const min = rangeParam[minKey],
    max = rangeParam[maxKey];

  const isInRange = (!min || value >= min) && (!max || value <= max);

  return isInRange === truthWhenEqualsTo;
}

function filterContains<I, P, H>(
  {
    name,
    getValue,
    getParam,
    truthWhenEqualsTo = true,
    requireEveryIfArray,
    valueIsArray,
    allowUndefined = true,
  }: TContainsFilterQuery<I, P, H>,
  item: any,
  params: any
): boolean {
  const value = doGetValue(name, item, getValue);
  const hasParam = doGetParam(name, params, getParam);

  const isValid = (item: any) => {
    const paramValue = hasParam[item];

    return (
      paramValue === truthWhenEqualsTo ||
      (allowUndefined && paramValue === undefined)
    );
  };

  if (valueIsArray) {
    return value[requireEveryIfArray ? 'every' : 'some'](isValid);
  }

  return isValid(value);
}

function filterMap<I, P, M>(
  {
    getValue,
    getParam = () => {},
    truthWhenEqualsTo = true,
    queries,
  }: TMapFilterQuery<I, P, M>,
  item: any,
  params: any
): boolean {
  const values = getValue(item);

  return (
    values.every((item: any, index) =>
      applyQueries<I, P>({ queries, item, params: getParam(params, index) })
    ) === truthWhenEqualsTo
  );
}

function filterCustom<I, P>(
  { getValue, truthWhenEqualsTo = true }: TCustomFilterQuery<I, P>,
  item: I,
  params: P
): boolean {
  const values = getValue(item, params);

  return values === truthWhenEqualsTo;
}

const FilterFunctions = {
  [FilterQueryTypes.STRING]: filterString,
  [FilterQueryTypes.RANGE]: filterRange,
  [FilterQueryTypes.RANGE_MAP]: filterRangeMap,
  [FilterQueryTypes.CONTAINS]: filterContains,
  [FilterQueryTypes.MAP]: filterMap,
  [FilterQueryTypes.CUSTOM]: filterCustom,
};

export function applyQueries<I, P>({
  queries,
  item,
  params,
}: {
  queries: TFilterQuery<I, P>[];
  item: I;
  params: P;
}) {
  return queries.every((query) =>
    (FilterFunctions[query.type] as any)(query, item, params)
  );
}

export function filterItems<I, P>(
  queries: TFilterQuery<I, P>[],
  items: I[],
  params: P,
  formatParams: (params: P) => P = (data) => data
) {
  const formattedParams = formatParams(params);
  const formattedQueries = setupQueries(queries, formattedParams);

  // let filteredItems: any = [];

  // if (formattedParams.amenities?.['Café da manhã'] === true) {
  //   items.forEach((item) => {
  //     const newItem = {
  //       ...item,
  //       rooms: item.rooms.filter((room) => room.board.breakfast === true),
  //     };

  //     if (newItem.rooms.length) {
  //       filteredItems.push(newItem);
  //     }
  //   });
  // } else {
  //   filteredItems = items;
  // }

  // console.log(filteredItems.length);

  // return params?.amenities?.['Café da manhã'] === true
  //   ? items
  //       .map((item) => {
  //         const newItem = {
  //           ...item,
  //           rooms: item.rooms.filter((room) => room.board.breakfast === true),
  //         };

  //         return newItem.rooms.length ? newItem : null;
  //       })
  //       .filter(
  //         (item) =>
  //           item &&
  //           applyQueries({
  //             queries: formattedQueries,
  //             item,
  //             params: formattedParams,
  //           })
  //       )

  return items.filter((item: any) =>
    applyQueries({
      queries: formattedQueries,
      item,
      params: formattedParams,
    })
  );
}

export const FilterPriorities = {
  [FilterQueryTypes.STRING]: 1,
  [FilterQueryTypes.RANGE]: 1,
  [FilterQueryTypes.CONTAINS]: 1,
  [FilterQueryTypes.RANGE_MAP]: 2,
  [FilterQueryTypes.MAP]: 2,
  [FilterQueryTypes.CUSTOM]: 3,
};

export function sortQueries<I, P>(queries: TFilterQuery<I, P>[]) {
  return queries.sort(
    (a, b) => FilterPriorities[a.type] - FilterPriorities[b.type]
  );
}

export function setupContains<I, P>(
  query: TContainsFilterQuery<I, P>,
  params: P
) {
  if (!query.filterFalsy && !query.skipIfEmpty) return query;

  let param = doGetParam(query.name, params, query.getParam);

  if (query.filterFalsy) param = filterTrueValues(param);

  return {
    ...query,
    getParam: () => param,
    skip: query.skipIfEmpty && !Object.keys(param).length ? true : query.skip,
  };
}

const SetupFunctions: TObject<any> = {
  [FilterQueryTypes.CONTAINS]: setupContains,
};

export function setupQueries<I, P>(queries: TFilterQuery<I, P>[], params: P) {
  const newQueries: TFilterQuery<I, P>[] = [],
    exclusiveQueries: TFilterQuery<I, P>[] = [];

  queries.forEach((query) => {
    const formattedQuery =
      query.type in SetupFunctions
        ? SetupFunctions[query.type](query, params)
        : query;

    if (formattedQuery.skip) return;

    newQueries.push(formattedQuery);
    if (formattedQuery.exclusive) exclusiveQueries.push(formattedQuery);
  });

  return exclusiveQueries.length ? exclusiveQueries : newQueries;
}
