import React, { useMemo, useState } from 'react';
import { Button } from 'reactstrap';
import OrderStatus from '../../constants/orderStatus';
import { useIntl } from 'react-intl';
import appFunctions from '../../services/Functions';
import OrderStructure from '../../constants/orderStructure';

import { ReactComponent as MailIcon } from '../../assets/img/icons/mail.svg';
import { ReactComponent as CheckIcon } from '../../assets/img/icons/check-mark.svg';

export default function NotifyApprovers({ order }) {
  const { messages } = useIntl();

  const [isLoading, setIsLoading] = useState(false);
  const [done, setDone] = useState(false);

  const isValid = useMemo(
    () =>
      order.statusCode === OrderStatus.APPROVING &&
      order.structure === OrderStructure.ORGANIZATION,
    [order]
  );

  const Icon = useMemo(() => (done ? CheckIcon : MailIcon), [done]);

  // Functions
  const doNotifyApprovers = async () => {
    try {
      setIsLoading(true);

      await appFunctions.notifyApprovers(order.id);

      setDone(true);
    } catch (err) {
      console.error('Unable to notify approvers', err);
    } finally {
      setIsLoading(false);
    }
  };

  // Render
  const renderIcon = () => {
    if (isLoading) return <div className="loading relative white"></div>;
    return <Icon color="white" className="icon" />;
  };

  if (isValid) {
    return (
      <div className="print">
        <Button
          onClick={doNotifyApprovers}
          disabled={isLoading || done}
          className="flex-center"
          title={
            messages[
              done ? 'notify-approvers.title.success' : 'notify-approvers.title'
            ]
          }
        >
          {renderIcon()}
        </Button>
      </div>
    );
  }

  return null;
}
