import React from 'react';
import { ReactComponent as RoundTripArrow } from '../../assets/img/icons/roundtrip.svg';
import { ReactComponent as OneWayArrow } from '../../assets/img/icons/oneway.svg';

export default function FlightTripInfo(props) {
  const { journeys } = props;
  const firstAirport = journeys[0].departureStation.iataCode;
  const lastAirport = journeys[journeys.length - 1].arrivalStation.iataCode;

  const getJourneyComponent = () => {
    if (firstAirport === lastAirport) {
      const middleJourney = journeys[journeys.length / 2 - 1];

      return (
        <>
          {firstAirport}
          <RoundTripArrow className="round-trip-arrow" />
          {middleJourney.arrivalStation.iataCode}
        </>
      );
    } else {
      return (
        <>
          {firstAirport}
          <OneWayArrow className="one-way-arrow" />
          {lastAirport}
        </>
      );
    }
  };

  return (
    <div className="trip-info d-flex justify-content-center align-items-center text-dark">
      {getJourneyComponent()}
    </div>
  );
}
