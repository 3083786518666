import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Row, Card } from 'reactstrap';
import { CardCategoriesIcons } from '../../constants/cardIcons';
import { BudgetStatusMessages } from '../../constants/refundStatus';
import { RefundTypes } from '../../constants/refundTypes';
import { formatDate, getBudgetTotal } from '../../utils/budget';
import { stringToMoney } from '../../utils/money';
import { Colxx } from '../common/CustomBootstrap';
import AdministrativeCard from '../expenses/AdministrativeCard';
import PersonalCard from '../expenses/PersonalCard';
import PhysicalCard from '../expenses/PhysicalCard';
import VirtualCard from '../expenses/VirtualCard';
import ProfileImage from '../ProfileImage';

const CARD_COMPONENTS = {
  [RefundTypes.PHYSICAL_CARD]: PhysicalCard,
  [RefundTypes.VIRTUAL_CARD]: VirtualCard,
  [RefundTypes.PERSONAL_ACCOUNT]: PersonalCard,
  [RefundTypes.ADMINISTRATIVE_ACCOUNT]: AdministrativeCard,
};

export default function BudgetCard({
  budget,
  cards,
  onClick = () => {},
  getUser,
}) {
  const { messages } = useIntl();
  const [user, setUser] = useState(null);

  const {
    name,
    total,
    passengerName,
    category,
    Icon,
    date,
    statusName,
    budgetValue,
    card,
    CardComponent,
    cardTitle,
  } = useMemo(() => {
    const {
      name,
      fare: { total, currency },
      passengerInfo: { firstName, lastName },
      costCenter,
      project,
      category = {},
      startDate,
      endDate,
      statusCode,
      budget: budgetValue,
      cardId,
    } = budget;

    const { label, value } = category;
    const card = cards ? cards[cardId] : null;

    return {
      name,
      total: stringToMoney(getBudgetTotal(budget), 2, currency),
      budgetValue:
        budgetValue && budgetValue !== total
          ? stringToMoney(budgetValue, 2, currency)
          : null,
      passengerName: `${firstName} ${lastName}`,
      costAndProject: [costCenter?.label, project?.label]
        .filter((value) => value)
        .join(' | '),
      category: label,
      Icon: CardCategoriesIcons[value] || CardCategoriesIcons.default,
      date: `${formatDate(startDate, 'YYYY-MM-DD', 'DD/MM')} - ${formatDate(
        endDate,
        'YYYY-MM-DD',
        'DD/MM'
      )}`,
      statusName: messages[BudgetStatusMessages[statusCode]],
      card,
      CardComponent: CARD_COMPONENTS[card?.type],
      cardTitle: card
        ? `${card.name || ''} (${
            messages[`refund.card-type.${card.type.toLowerCase()}`]
          })`
        : '',
    };
  }, [budget]);

  // Effects
  useEffect(() => {
    if (getUser) {
      getUser(budget.passengerId)
        .then((value) => setUser(value))
        .catch((err) =>
          console.error('Unable to get user', budget.passengerId, err)
        );
    }
  }, []);

  return (
    <Card className="order-card budget-card mb-3 py-4 px-3" onClick={onClick}>
      <Row>
        <Colxx xxs="5" className="d-flex align-items-center">
          <Icon width="1.5rem" className="mr-2 text-primary" title={category} />
          <div className="d-flex flex-column justify-content-center text-truncate">
            <h2 className="budget-name p-0 m-0 text-capitalize text-dark h5 text-nowrap text-truncate">
              {name}
            </h2>
            <div className="d-flex align-items-center mt-1">
              <ProfileImage
                user={user}
                loading={!user}
                xxsmall
                title={passengerName}
              />
              <p className="passenger-name p-0 m-0 ml-1 text-muted text-nowrap text-truncate">
                {passengerName}
              </p>
            </div>
          </div>
        </Colxx>
        <Colxx
          xxs="4"
          className="d-flex align-items-center justify-content-between"
        >
          {CardComponent ? (
            <div
              className="card-component d-flex align-items-center justify-content-center"
              title={cardTitle}
            >
              <CardComponent card={card} />
            </div>
          ) : null}
          <div className="date mx-4 text-center" style={{ lineHeight: 1.2 }}>
            {date}
          </div>
          <div
            className="d-flex justify-content-start align-items-center"
            style={{ minWidth: 150 }}
          >
            <div className="px-3 py-1 status-code text-center">
              {statusName}
            </div>
          </div>
        </Colxx>
        <Colxx
          xxs="3"
          className="d-flex align-items-center justify-content-end text-dark pr-4"
        >
          <div className="text-right">
            <span>{total}</span>
            {budgetValue && (
              <p
                className="p-0 text-right text-primary"
                style={{ fontSize: '0.7rem', margin: '-2px 0 0 0' }}
              >
                de {budgetValue}
              </p>
            )}
          </div>
        </Colxx>
      </Row>
    </Card>
  );
}
