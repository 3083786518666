import React, { useMemo } from 'react';
import { Label, Row } from 'reactstrap';
import { Colxx } from '../../../../components/common/CustomBootstrap';
import IntlMessages from '../../../../helpers/IntlMessages';
import DateSearch from '../../../../components/search/DateSearch';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import { useIntl } from 'react-intl';
import { registeredAirlines } from '../../../../constants/travelValues';
import { sortAlphabetically } from '../../../../utils/sort';
import FixedSelect from '../../../../components/common/FixedSelect';

export default function FlightParams({
  params,
  setParams,
  search,
  associates,
  showingSteps,
}) {
  const { messages } = useIntl();

  // States
  const airlineOptions = useMemo(() => {
    const airlines = sortAlphabetically(
      Object.keys(registeredAirlines).map((value) => ({
        value,
        label: `${registeredAirlines[value].name} (${value})`,
      })),
      'label'
    );

    airlines.push({
      value: null,
      label: messages['general.none-a'],
    });

    return airlines;
  }, []);

  const isRoundTrip = useMemo(() => search.twoWay, [search]);

  const columnSize = useMemo(() => (showingSteps ? '12' : '6'), [showingSteps]);

  // Render
  return (
    <div className="reservation-params pl-3 pr-4 pt-2 pb-0 refund-params common-params">
      <Row>
        <Colxx xxs={isRoundTrip && !showingSteps ? '6' : '12'}>
          <Label className="form-group search reservation has-top-label">
            <DateSearch
              selectedDate={params.outboundDate}
              setValue={(value) => setParams('outboundDate', moment(value))}
              isStart
              defaultMinDate={moment.unix(0)}
              className="refund-date form-purple-value purple"
              placeholder="DD/MM/YY hh:mm"
              name="outbound-date"
              showTime
              portalId="datetime-portal"
            />
            <span>
              <IntlMessages id="travel.outbound" /> *
            </span>
          </Label>
        </Colxx>
        {isRoundTrip ? (
          <Colxx xxs={columnSize}>
            <Label className="form-group search reservation has-top-label">
              <DateSearch
                selectedDate={params.inboundDate}
                setValue={(value) => setParams('inboundDate', moment(value))}
                isEnd
                showTime
                defaultMinDate={params.outboundDate}
                className="refund-date form-purple-value purple"
                placeholder="DD/MM/YY hh:mm"
                name="inbound-date"
                portalId="datetime-portal"
              />
              <span>
                <IntlMessages id="travel.inbound" /> *
              </span>
            </Label>
          </Colxx>
        ) : null}
      </Row>
      <Row>
        <Colxx xxs={columnSize}>
          <Label className="form-group search reservation has-top-label">
            <FixedSelect
              className="react-select select"
              classNamePrefix="react-select"
              isClearable={false}
              placeholder=""
              value={params.airline}
              options={airlineOptions}
              onChange={(value) => setParams('airline', value)}
              maxMenuHeight={150}
            />
            <span>
              <IntlMessages id="travel.open-booking.airline" />
            </span>
          </Label>
        </Colxx>
        <Colxx xxs={columnSize} className="pl-1px">
          <Label className="form-group search reservation has-top-label">
            <NumberFormat
              name="amount"
              prefix={'R$ '}
              suffix={''}
              thousandSeparator="."
              decimalSeparator=","
              decimalScale="2"
              fixedDecimalScale="2"
              className="currency-input w-100 form-purple-value mt-0 alternative-padding"
              defaultValue={params.amount || ''}
              onValueChange={(v) => {
                setParams('amount', v.floatValue);
              }}
            />
            <span>
              <IntlMessages id="travel.open-booking.amount" /> *
            </span>
          </Label>
        </Colxx>
      </Row>
      <Row>
        <Colxx xxs="12">
          <Label className="form-group search reservation has-top-label">
            <FixedSelect
              className="react-select select"
              classNamePrefix="react-select"
              isClearable={false}
              placeholder=""
              value={params.associate}
              options={associates}
              onChange={(value) => setParams('associate', value)}
              maxMenuHeight={150}
            />
            <span>
              <IntlMessages id="travel.open-booking.associate" /> *
            </span>
          </Label>
        </Colxx>
      </Row>
    </div>
  );
}
