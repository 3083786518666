import moment from 'moment';
import firebaseApp from '../services/Firebase';

let lastUpdated = null;

const shouldRenewCategories = async () => {
  if (moment().isAfter(moment(lastUpdated).add(1, 'days'))) {
    return true;
  }

  return false;
};

const getNewCategories = async (orgId) => {
  return await firebaseApp
    .getRefundCategoriesFromOrganization(orgId)
    .get({ source: 'server' });
};

export const getCategories = async (orgId) => {
  const storageSnapshot = await firebaseApp
    .getRefundCategoriesFromOrganization(orgId)
    .get({ source: 'cache' });

  if (storageSnapshot.exists) {
    const shouldRenew = shouldRenewCategories();
    if (shouldRenew) {
      lastUpdated = moment();
      return await getNewCategories(orgId);
    }

    return storageSnapshot;
  }

  return await getNewCategories(orgId);
};

export const getRefundCategoriesSnapshotData = (snapshot) => {
  const data = [];

  snapshot.forEach((doc) => {
    const { name, icon } = doc.data();

    data.push({
      value: doc.id,
      label: name,
      icon,
    });
  });

  return data;
};

export const loadCategories = async (orgId) => {
  const categoriesSnap = await getCategories(orgId);

  const categories = [];
  categoriesSnap.forEach((categorySnap) => {
    const { name, icon } = categorySnap.data();

    categories.push({
      value: categorySnap.id,
      label: name,
      icon,
    });
  });

  return categories;
};
