import SearchLoading from './SearchLoading';
import LoadingFilter from 'src/portao3-legacy/components/search/LoadingFilter';

import loadSearchStatus from 'src/portao3-legacy/constants/loadSearchStatus';
import useShouldInitialize from 'src/portao3-legacy/hooks/useShouldInitialize';

import { TChildren } from 'src/portao3-legacy/types/others';

interface IProps {
  isLoading: boolean;
  searchStatus: string;
  children: TChildren;
}

export default function SearchFilterWrapper({
  isLoading,
  searchStatus,
  children,
}: IProps) {
  // Hooks
  const hasInitialized = useShouldInitialize(!isLoading);

  // Render
  if (!hasInitialized)
    return (
      <SearchLoading className="filter" Component={LoadingFilter as any} />
    );

  if (searchStatus === loadSearchStatus.EMPTY) {
    return null;
  }

  return children;
}
