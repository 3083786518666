export const RefundStatus = {
  START: 0,
  APPROVING_ANTICIPATION: 10,
  ANTICIPATION_APPROVED: 20,
  ACTIVATING: 45,
  ACTIVE: 50,
  WAITING: 55,
  REVIEW: 60,
  APPROVING: 65,
  APPROVED: 70,
  PROCESSING: 75,
  REJECTED: 80,
  WAITING_PAYMENT: 85,
  PAID: 90,
  CANCELLING: 95,
  CANCELLED: 100,
  UNDO: 125,
  DISMISSED: 150,
  ERROR: 200,
};

export const BudgetStatusMessages = {
  [RefundStatus.START]: 'travel.order.status.timeline.refund.start',
  [RefundStatus.APPROVING_ANTICIPATION]:
    'travel.order.status.timeline.refund.approving-anticipation',
  [RefundStatus.ANTICIPATION_APPROVED]:
    'travel.order.status.timeline.refund.approved-anticipation',
  [RefundStatus.ACTIVATING]: 'travel.order.status.timeline.refund.activating',
  [RefundStatus.ACTIVE]: 'travel.order.status.timeline.refund.active',
  [RefundStatus.WAITING]: 'travel.order.status.timeline.refund.waiting',
  [RefundStatus.APPROVING]: 'travel.order.status.timeline.refund.approving',
  [RefundStatus.PROCESSING]: 'travel.order.status.timeline.refund.processing',
  [RefundStatus.REVIEW]: 'travel.order.status.timeline.refund.review',
  [RefundStatus.APPROVED]: 'travel.order.status.timeline.refund.approved',
  [RefundStatus.REJECTED]: 'travel.order.status.timeline.refund.rejected',
  [RefundStatus.WAITING_PAYMENT]:
    'travel.order.status.timeline.refund.waiting-payment',
  [RefundStatus.PAID]: 'travel.order.status.timeline.refund.paid',
  [RefundStatus.CANCELLING]: 'travel.order.status.timeline.refund.cancelling',
  [RefundStatus.CANCELLED]: 'travel.order.status.timeline.refund.cancelled',
  [RefundStatus.UNDO]: 'travel.order.status.timeline.refund.undone',
  [RefundStatus.DISMISSED]: 'travel.order.status.timeline.refund.dismissed',
  [RefundStatus.ERROR]: 'travel.order.status.timeline.refund.error',
};

export const AdminstrativeBudgetStatusMessages = {
  [RefundStatus.START]: 'travel.order.status.timeline.refund.start',
  [RefundStatus.APPROVING_ANTICIPATION]:
    'travel.order.status.timeline.refund.approving-anticipation',
  [RefundStatus.ANTICIPATION_APPROVED]:
    'travel.order.status.timeline.refund.approved-anticipation',
  [RefundStatus.ACTIVATING]:
    'travel.order.status.timeline.administrative-budget.activating',
  [RefundStatus.ACTIVE]:
    'travel.order.status.timeline.administrative-budget.activating',
  [RefundStatus.APPROVED]:
    'travel.order.status.timeline.administrative-budget.approved',
  [RefundStatus.REJECTED]: 'travel.order.status.timeline.refund.rejected',
  [RefundStatus.CANCELLING]: 'travel.order.status.timeline.refund.cancelling',
  [RefundStatus.CANCELLED]: 'travel.order.status.timeline.refund.cancelled',
  [RefundStatus.DISMISSED]: 'travel.order.status.timeline.refund.dismissed',
  [RefundStatus.ERROR]: 'travel.order.status.timeline.refund.error',
  [RefundStatus.PROCESSING]: 'travel.order.status.timeline.refund.processing',
  [RefundStatus.WAITING_PAYMENT]:
    'travel.order.status.timeline.refund.waiting-payment',
  [RefundStatus.PAID]: 'travel.order.status.timeline.refund.paid',
};

export const RefundStatusClasses = {
  [RefundStatus.START]: 'details-alert start',
  [RefundStatus.APPROVING_ANTICIPATION]: 'details-alert warning',
  [RefundStatus.ANTICIPATION_APPROVED]: 'details-alert approved',
  [RefundStatus.ACTIVATING]: 'details-alert approved',
  [RefundStatus.ACTIVE]: 'details-alert approved',
  [RefundStatus.WAITING]: 'details-alert warning',
  [RefundStatus.APPROVING]: 'details-alert warning',
  [RefundStatus.REVIEW]: 'details-alert warning',
  [RefundStatus.APPROVED]: 'details-alert approved',
  [RefundStatus.REJECTED]: 'details-alert danger',
  [RefundStatus.CANCELLING]: 'details-alert danger',
  [RefundStatus.CANCELLED]: 'details-alert danger',
  [RefundStatus.UNDO]: 'details-alert danger',
  [RefundStatus.DISMISSED]: 'details-alert danger',
  [RefundStatus.ERROR]: 'details-alert danger',
  [RefundStatus.PROCESSING]: 'details-alert warning',
  [RefundStatus.WAITING_PAYMENT]: 'details-alert warning',
  [RefundStatus.PAID]: 'details-alert approved',
};

export const CanDropStatus = [RefundStatus.ACTIVE, RefundStatus.REVIEW];

export const CanAddBudgetItems = [RefundStatus.ACTIVE, RefundStatus.REVIEW];

export const CanEditBudgetItems = [RefundStatus.ACTIVE, RefundStatus.REVIEW];

export const CanEditTransactionBudgetItems = [
  RefundStatus.ACTIVE,
  RefundStatus.WAITING,
  RefundStatus.REVIEW,
];

export const CanAddInvoice = [
  RefundStatus.ACTIVE,
  RefundStatus.WAITING,
  RefundStatus.REVIEW,
];

export const ActiveBudgetStatus = [
  RefundStatus.ACTIVATING,
  RefundStatus.ACTIVE,
  RefundStatus.WAITING,
  RefundStatus.REVIEW,
  RefundStatus.APPROVING,
  RefundStatus.PROCESSING,
  RefundStatus.WAITING_PAYMENT,
  RefundStatus.PAID,
];
