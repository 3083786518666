import React, { useState, useEffect, useCallback, useRef } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import useBatchJobs from '../../hooks/useBatchJobs';
import UserImportItem from './UserImportItem';
import { clearBulkData, setBulkLoading } from '../../redux/bulk/actions';
import User from '../../services/User';
import firebaseApp from '../../services/Firebase';
import debounce from 'lodash/debounce';

import { BULK_USER_DATA_ACTIONS } from '../../constants/batch';
import { ReactComponent as LeftIcon } from '../../assets/img/icons/left.svg';
import { ReactComponent as CloseIcon } from '../../assets/img/icons/close.svg';

const USER_DATA_KEYS = [
  BULK_USER_DATA_ACTIONS.CREATE,
  BULK_USER_DATA_ACTIONS.MODIFY,
  BULK_USER_DATA_ACTIONS.DELETE,
];

export default React.memo(() => {
  const dispatch = useDispatch();
  const { users: usersData } = useSelector(({ bulk }) => ({
    users: bulk?.users || {},
  }));

  const { data } = usersData;

  // States
  const timeoutRef = useRef(null);

  const [jobs, setJobs] = useState({});
  const [show, setShow] = useState(false);
  const [hidden, setHidden] = useState(false);

  const { doBatchJob, loading, batchData } = useBatchJobs({ jobs });

  // Effects
  useEffect(() => {
    if (usersData?.data) configureJobs(usersData);
  }, [usersData.timestamp]);

  useEffect(() => {
    if (show) handleNewData(data);
  }, [show]);

  useEffect(() => {
    if (!loading && Object.keys(batchData).length) {
      // Wait for 3 min before auto closes
      timeoutRef.current = setTimeout(doClose, 1000 * 60 * 3);
      return () => timeoutRef.current && clearTimeout(timeoutRef.current);
    }
  }, [loading, batchData]);

  // Functions
  const configureJobs = useCallback(({ finalQuestions = {} }) => {
    const shouldInvite = finalQuestions['shouldInvite'] || false;

    setJobs({
      [BULK_USER_DATA_ACTIONS.CREATE]: {
        jobFunction: (users) =>
          User.bulkInviteUsers(
            users.map((user) => ({
              ...user,
              shouldInvite,
            }))
          )
            .then(({ data }) => data)
            .catch((err) => console.error('Unable to process batch item', err)),
        interpretResults: (
          { addedUsers = 0, invalidUsers = 0 } = {},
          data
        ) => ({
          successful: addedUsers,
          failed: invalidUsers,
          total: data.length,
        }),
        batch: false,
      },
      [BULK_USER_DATA_ACTIONS.MODIFY]: {
        jobFunction: ({ uid, changes }) =>
          firebaseApp.updateUserFromId(uid, changes),
      },
      [BULK_USER_DATA_ACTIONS.DELETE]: {
        jobFunction: ({ uid }) => User.deleteUser(uid),
      },
    });

    setShow(true);
  }, []);

  const doClose = useCallback(
    debounce(() => {
      timeoutRef.current && clearTimeout(timeoutRef.current);

      setShow(false);
      setHidden(false);

      setTimeout(() => {
        dispatch(clearBulkData('users'));
      }, 500);
    }, 200),
    []
  );

  const handleClose = () => {
    if (loading) return setHidden(true);
    else doClose();
  };

  const handleNewData = async (data) => {
    await doBatchJob(data);
    dispatch(setBulkLoading('users', false));
  };

  // Render
  return (
    <div className={classNames('user-import-card-container', { show, hidden })}>
      <div className="user-import-card" key={`${usersData.timestamp}`}>
        <CloseIcon
          className="close-icon cursor-pointer"
          onClick={handleClose}
        />

        {data
          ? USER_DATA_KEYS.map((value) => {
              return data[value] ? (
                <UserImportItem
                  key={value}
                  batchData={batchData}
                  value={value}
                />
              ) : null;
            })
          : null}
      </div>
      <div
        className="flap cursor-pointer flex-center"
        onClick={() => setHidden(false)}
      >
        <LeftIcon width={15} height={15} fill="white" />
      </div>
    </div>
  );
});
