import React from 'react';
import { Row, Card, CardBody, CardTitle } from 'reactstrap';
import { Colxx } from '../../common/CustomBootstrap';
import moment from 'moment';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import sumBy from 'lodash/sumBy';

import { AreaChart } from '../../charts';
import SpentLoading from '../../../containers/dashboard/SpentLoading';

import { ThemeColors } from '../../../helpers/ThemeColors';
const colors = ThemeColors();

export default function ESGSmallLineTotal({ orders, loading }) {
  const results = groupBy(orders, (o) =>
    moment.unix(o.filterTimestamp).format('DD/MM/YYYY')
  );

  if (loading) {
    return (
      <Card className="dashboard-small-chart h-100">
        <CardBody>
          <CardTitle>Emissão de CO2 total</CardTitle>
          <SpentLoading />
        </CardBody>
      </Card>
    );
  }

  let count = 0;

  return (
    <Card className="dashboard-small-chart h-100">
      <CardBody>
        <CardTitle>Emissão de CO2 total</CardTitle>
        <Row className="w-100">
          <Colxx xxs="6" className="mt-5 pt-5">
            <p className="lead color-theme-1 mb-1 value">
              {sumBy(orders, (o) => parseFloat(o.esg.carbon_kg)).toFixed(0)} Kg
            </p>
            <p className="text-muted">Total emitido no período</p>
          </Colxx>
          <Colxx xxs="6" className="mt-3">
            {Object.keys(results).length && (
              <AreaChart
                shadow
                data={{
                  labels: Object.keys(results),
                  datasets: [
                    {
                      data: map(results, (orders) => {
                        count =
                          count +
                          sumBy(orders, (o) => parseFloat(o.esg.carbon_kg));
                        return count;
                      }),
                      borderColor: colors.themeColor1,
                      pointBackgroundColor: colors.foregroundColor,
                      pointBorderColor: colors.themeColor1,
                      pointHoverBackgroundColor: colors.themeColor1,
                      pointHoverBorderColor: colors.foregroundColor,
                      pointRadius: 0,
                      pointBorderWidth: 2,
                      pointHoverRadius: 5,
                      fill: true,
                      borderWidth: 2,
                      backgroundColor: colors.themeColor1,
                    },
                  ],
                }}
              />
            )}
          </Colxx>
        </Row>
      </CardBody>
    </Card>
  );
}
