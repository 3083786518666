import React from 'react';
import { ReactComponent as SearchIcon } from '../../assets/img/icons/search-icon.svg';

export default function SimpleSearchBar({
  value,
  onChange,
  placeholder = '',
  style = {},
}) {
  return (
    <div
      className="simple-search-bar d-flex align-items-center px-3 py-2"
      style={style}
    >
      <SearchIcon width={20} height={20} className="search-icon mr-2" />
      <input
        type="text"
        value={value}
        onChange={onChange}
        className="w-100 search-bar"
        placeholder={placeholder}
      />
    </div>
  );
}
