import React from 'react';
import ContentLoader from 'react-content-loader';

export default function LoadingDetailed({
  backgroundColor = '#f3f3f3',
  foregroundColor = '#ecebeb',
}) {
  return (
    <div className="loading-detailed w-100 m-0">
      <ContentLoader
        speed={2}
        height={60}
        width="100%"
        viewBox="0 0 100 100"
        backgroundColor={backgroundColor}
        foregroundColor={foregroundColor}
        preserveAspectRatio="none"
      >
        <rect x="0" y="0" rx="2" ry="2" width="100" height="35px" />
        <rect x="0" y="50" rx="2" ry="2" width="30" height="35px" />
        <rect x="70" y="50" rx="2" ry="2" width="30" height="35px" />
      </ContentLoader>
    </div>
  );
}
