import FlightAlternative from '../components/alternatives/FlightAlternative';
import HotelAlternative from '../components/alternatives/HotelAlternative';
import CarAlternative from '../components/alternatives/CarAlternative';
import BusAlternative from '../components/alternatives/BusAlternative';
import { OrderTypes } from './orderTypes';

export const AllAlternativeCards = {
  [OrderTypes.FLIGHT]: FlightAlternative,
  [OrderTypes.HOTEL]: HotelAlternative,
  [OrderTypes.CAR]: CarAlternative,
  [OrderTypes.BUS]: BusAlternative,
};
