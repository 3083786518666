import classNames from 'classnames';
import React from 'react';
import { useIntl } from 'react-intl';
import { ReactComponent as DownloadIcon } from '../../assets/img/icons/download-cloud.svg';

export default function DownloadVouchersButton({
  onGenerateVouchers,
  disabled,
  loading,
}) {
  const { messages } = useIntl();

  // Render
  return (
    <div
      className={classNames(
        'data-list-view-btn p-3 d-flex align-items-center justify-content-center',
        { disabled, isLoading: loading }
      )}
      role="button"
      title={messages['generate-vouchers.title']}
      onClick={() => !disabled && onGenerateVouchers()}
    >
      {loading ? (
        <div
          className="loading relative white"
          style={{ width: 25, height: 25 }}
        />
      ) : (
        <DownloadIcon width={25} height={25} />
      )}
    </div>
  );
}
