import { useCallback } from 'react';
import useShowToggle from './useShowToggle';
import useHistoryWithScroll from './useHistoryWithScroll';
import {
  getAlterationModalPath,
  getApprovalModalPath,
  getModalPath,
} from '../utils/location';

export default function useModalStages({
  isApproving: _isApproving,
  isAltering: _isAltering,
  isCancelling: _isCancelling,
  isShowingOpenBookingSteps: _isShowingOpenBookingSteps,
  item,
  path,
} = {}) {
  const { push } = useHistoryWithScroll();

  const [isApproving, setIsApproving, toggleIsApproving] =
    useShowToggle(_isApproving);
  const [isAltering, setIsAltering, toggleIsAltering] =
    useShowToggle(_isAltering);
  const [isCancelling, setIsCancelling, toggleIsCancelling] =
    useShowToggle(_isCancelling);
  const [
    isShowingOpenBookingSteps,
    setIsShowingOpenBookingSteps,
    toggleIsShowingOpenBookingSteps,
  ] = useShowToggle(_isShowingOpenBookingSteps);

  // Functions
  const handleToggle = useCallback(
    (toggleFunction, onTrue) => {
      const currentValue = toggleFunction();

      push(
        currentValue
          ? onTrue(item, path)
          : getModalPath({
              item,
              rootPath: path,
            })
      );
    },
    [item, path]
  );

  const handleToggleIsApproving = useCallback(
    (value) =>
      handleToggle(() => toggleIsApproving(value), getApprovalModalPath),
    [handleToggle]
  );

  const handleToggleIsAltering = useCallback(
    (value) =>
      handleToggle(() => toggleIsAltering(value), getAlterationModalPath),
    [handleToggle]
  );

  // Render
  return {
    isApproving,
    setIsApproving,
    toggleIsApproving: handleToggleIsApproving,
    isAltering,
    setIsAltering,
    toggleIsAltering: handleToggleIsAltering,
    isShowingOpenBookingSteps,
    setIsShowingOpenBookingSteps,
    toggleIsShowingOpenBookingSteps,
    isCancelling,
    setIsCancelling,
    toggleIsCancelling,
  };
}
