import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import moment from 'moment';
import InformationCard from '../../InformationCard';
import CurrencyWrapper from '../../../common/CurrencyWrapper';
import { ReactComponent as MoneyIcon } from '../../../../assets/img/icons/money-minimalist.svg';
import { ReactComponent as CalenderIcon } from '../../../../assets/img/icons/calender.svg';

export default function CarReservationInformation({ order, reservations }) {
  const messages = useIntl();

  const { dailyValue, anticipation } = useMemo(() => {
    const pickUp = moment(reservations[0].carDetails.pickUp, 'DD-MM-YYYY');
    const dropOff = moment(reservations[0].carDetails.dropOff, 'DD-MM-YYYY');
    const createdAt = moment(createdAt).format('DD-MM-YYYY');

    const dailyValue = (
      order.fare.total /
      Number(moment(dropOff).diff(moment(pickUp, 'DD-MM-YYYY'), 'days'))
    ).toFixed(2);

    const anticipation = moment(pickUp, 'DD-MM-YYYY').diff(
      moment(createdAt, 'DD-MM-YYYY'),
      'days'
    );

    return { dailyValue, anticipation };
  }, [reservations, order]);

  return (
    <div>
      <div className="d-flex mb-3">
        <InformationCard
          title={messages['travel.details.car.dailyValue']}
          value={
            <CurrencyWrapper value={dailyValue} decimalPlaces={0}>
              {({ formattedValue }) => formattedValue}
            </CurrencyWrapper>
          }
          Icon={MoneyIcon}
        />
        <InformationCard
          title={messages['travel.details.advance']}
          value={`${anticipation} ${messages['general.days']}`}
          Icon={CalenderIcon}
        />
      </div>
    </div>
  );
}
