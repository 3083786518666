import React, { useMemo } from 'react';
import { getFilterParamsFromConfig } from '../FilterFunctions';
import FilterItem from './FilterItem';
import Input from './Input';

export default function InputFilter({ orders, config, filters, setFilters }) {
  const [generalFilters, hasFilters] = useMemo(
    () =>
      orders && orders.length > 0
        ? [getFilterParamsFromConfig(orders, config), true]
        : [{}, false],
    [orders, config]
  );

  const didSelectFilters = useMemo(() => filters.length > 0, [filters]);

  // Functions
  const handleFilterDelete = (index) => {
    const newFilters = [...filters];
    newFilters.splice(index, 1);
    setFilters(newFilters);
  };

  // Render
  return (
    <div
      className={`input-filter d-flex flex-wrap w-100 ${
        didSelectFilters ? 'with-filters' : ''
      }`}
    >
      {filters.map((item, index) => (
        <FilterItem
          key={item.id}
          item={item}
          onDelete={() => handleFilterDelete(index)}
        />
      ))}
      {hasFilters ? (
        <Input
          config={config}
          filter={generalFilters}
          filters={filters}
          setFilters={setFilters}
          onCreate={(filter) => setFilters([...filters, filter])}
        />
      ) : null}
    </div>
  );
}
