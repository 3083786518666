import React, { Component } from 'react';
import IntlMessages from '../../helpers/IntlMessages';
import moment from 'moment';

import AlertCard from './AlertCard';
import { injectIntl } from 'react-intl';

class HotelCard extends Component {
  render() {
    const {
      reservation,
      order,
      intl: { messages },
    } = this.props;

    return (
      <div className="item" data-testid={order.id}>
        <div className="date">
          <div className="content">
            <span className="day">
              {moment(
                reservation.hotelDetails.checkIn,
                'DD/MM/YYYY HH:mm'
              ).format('DD')}
            </span>
            <span className="month">
              {moment(
                reservation.hotelDetails.checkIn,
                'DD/MM/YYYY HH:mm'
              ).format('MMM')}
            </span>
          </div>
        </div>
        <div
          className="details"
          onClick={() => {
            this.props.openHotel(reservation);
          }}
        >
          <div
            className="details-content hotel"
            style={{
              backgroundImage: reservation.hotelDetails.hotel.mainPhoto
                ? `url(${reservation.hotelDetails.hotel.mainPhoto})`
                : 'inherit',
            }}
          >
            <div className="gradient">
              <div className="header">
                <div className="img" />
                <div>
                  <span>
                    <IntlMessages id="travel.stay" />
                  </span>
                  {reservation.hotelDetails.hotel.name}
                </div>
              </div>

              <div className="route clearfix">
                <div className="airport">
                  <span>
                    <IntlMessages id="travel.checkin" />
                  </span>
                  {moment(
                    reservation.hotelDetails.checkIn,
                    'DD/MM/YYYY HH:mm'
                  ).format('DD/MM')}
                </div>
                <div className="to text-center">
                  <img
                    src="/assets/img/icons/timeline_hotel_white.svg"
                    alt={messages['alts.components.timeline.hotel-card']}
                  />
                </div>
                <div className="airport text-right">
                  <span>
                    <IntlMessages id="travel.checkout" />
                  </span>
                  {moment(
                    reservation.hotelDetails.checkOut,
                    'DD/MM/YYYY HH:mm'
                  ).format('DD/MM')}
                </div>
              </div>
            </div>
          </div>

          <AlertCard order={order} />
        </div>
      </div>
    );
  }
}

export default injectIntl(HotelCard);
