// Layout
export const defaultMenuType = 'menu-default';
export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;

// Lang
export const defaultLocale = 'pt';
export const localeOptions = [
  { id: 'en', name: 'English', direction: 'ltr' },
  { id: 'es', name: 'Español', direction: 'ltr' },
  { id: 'pt', name: 'Portugues', direction: 'ltr' },
];

// Colors
export const themeColorStorageKey = '__theme_color';
export const isMultiColorActive = true;
export const defaultColor = 'light';
export const isDarkSwitchActive = true;
export const defaultDirection = 'ltr';

// User
export const maxNameLength = 29;
