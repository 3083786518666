import React from 'react';

export default function SimpleSpinner({
  white = false,
  className = '',
  style = {},
}) {
  return (
    <div
      className={`simple-spinner loading relative ${className}`}
      style={style}
    />
  );
}
