import { useState, useEffect } from 'react';

const { OrderTypes } = require('../../constants/orderTypes');

import useHistoryWithScroll from '../../hooks/useHistoryWithScroll';
import LoadingModal from '../../components/timeline/LoadingModal';
import FlightModal from '../../components/timeline/FlightModal';
import HotelModal from '../../components/timeline/HotelModal';
import CarModal from '../../components/timeline/CarModal';
import BusModal from '../../components/timeline/BusModal';
import OfficeModal from '../../components/timeline/OfficeModal';

import firebaseApp from '../../services/Firebase';
import { trackPropertiesSegment } from '../../utils/segment';

let snapshotOrder = () => {};

export default function OrderModal(props) {
  const { closeModalPath = '/travel', path } = props;

  const history = useHistoryWithScroll();

  const closeModal = () => {
    trackPropertiesSegment('Closed order modal', { orderId: props.orderId });
    props.closeModal ? props.closeModal() : history.push(closeModalPath);
  };

  const [order, setOrder] = useState(null);

  useEffect(() => {
    loadOrder();
    return () => {
      snapshotOrder();
    };
  }, []);

  // Load Reservations
  const loadOrder = async () => {
    const { orderId } = props;

    const saveState = (order) => setOrder(order);

    snapshotOrder = firebaseApp
      .getOrderFromId(orderId)
      .onSnapshot(function (querySnapshot) {
        saveState({
          ...querySnapshot.data(),
          id: querySnapshot.id,
        });
      });
  };

  if (!order) {
    return <LoadingModal />;
  }

  if (order.type === OrderTypes.FLIGHT) {
    return <FlightModal toggleModal={closeModal} order={order} path={path} />;
  }

  if (order.type === OrderTypes.HOTEL) {
    return <HotelModal toggleModal={closeModal} order={order} path={path} />;
  }

  if (order.type === OrderTypes.CAR) {
    return <CarModal toggleModal={closeModal} order={order} path={path} />;
  }

  if (order.type === OrderTypes.BUS) {
    return <BusModal toggleModal={closeModal} order={order} path={path} />;
  }

  if (order.type === OrderTypes.OFFICE) {
    return <OfficeModal toggleModal={closeModal} order={order} path={path} />;
  }
}
