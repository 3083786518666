import {
  CLEAR_SEARCH_CODES_WITH_ERROR,
  SET_PASSENGER,
  SET_SEARCH_CODES_WITH_ERROR,
} from '../actions';

const INIT_STATE = {
  passenger: null,
  searchCodesWithError: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_PASSENGER:
      return { ...state, passenger: action.payload };
    case SET_SEARCH_CODES_WITH_ERROR: {
      const searchCodes = [...state.searchCodesWithError, ...action.payload];

      return { ...state, searchCodesWithError: [...new Set(searchCodes)] };
    }
    case CLEAR_SEARCH_CODES_WITH_ERROR: {
      const searchCode = action.payload;
      let searchCodesWithError = [];

      if (searchCode) {
        searchCodesWithError = state.searchCodesWithError.filter(
          (code) => code !== searchCode
        );
      }

      return { ...state, searchCodesWithError };
    }

    default:
      return { ...state };
  }
};
