import React, { useState, useEffect } from 'react';

import firebaseApp from '../../services/Firebase';
import BudgetModal from '../../components/expensesTimeline/BudgetModal/BudgetModal';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getPassengerRedux } from '../../utils/redux';
import BudgetCreatorModal from '../../components/expenses/BudgetCreatorModal/BudgetCreatorModal';
import useHistoryWithScroll from '../../hooks/useHistoryWithScroll';
import { RefundTypes } from '../../constants/refundTypes';
import AdministrativeBudgetCreatorModal from '../../components/expenses/AdministrativeBudgetCreatorModal/AdministrativeBudgetCreatorModal';
import useDataArchive from '../../hooks/useDataArchive';
import { getDocument } from '../../utils/firebase';

let snapshotBudget = () => {};
let snapshotCard = () => {};
let snapshotApprovals = () => {};

export default function BudgetOrderModal({
  budgetId,
  itemId,
  closeModal,
  closeModalPath = '/payments/cards',
  path,
  onCreate = () => {},
  canCreateBudgets = true,
}) {
  const {
    push,
    location: { search = '' },
  } = useHistoryWithScroll();
  const { passenger } = useSelector(getPassengerRedux);

  const doCloseModal = () => {
    closeModal ? closeModal() : push(closeModalPath);
  };

  // Hooks
  const { getDataFromArchive } = useDataArchive();

  // States
  const [loading, setLoading] = useState(true);
  const [budget, setBudget] = useState(null);
  const [creditCard, setCreditCard] = useState(null);
  const [approvals, setApprovals] = useState([]);

  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  const {
    Component,
    cardId = '',
    cardType = '',
    userId = '',
    isNew = true,
  } = useMemo(() => {
    if (budgetId !== 'new') return { Component: BudgetModal, isNew: false };
    else {
      const modalPayload = {
        cardId: searchParams.get('cardId'),
        cardType: searchParams.get('type'),
        userId: searchParams.get('userId'),
      };

      return {
        Component:
          modalPayload.cardType === RefundTypes.ADMINISTRATIVE_ACCOUNT
            ? AdministrativeBudgetCreatorModal
            : BudgetCreatorModal,
        ...modalPayload,
      };
    }
  }, [budgetId, searchParams]);

  // Effects
  useEffect(() => {
    setLoading(true);

    if (budgetId !== 'new') {
      loadInfo(budgetId);
    } else {
      if (searchParams.get('type') == RefundTypes.VIRTUAL_CARD) {
        loadNewInfo();
      } else setLoading(false);
    }

    return () => {
      snapshotBudget();
      snapshotCard();
      snapshotApprovals();
    };
  }, [budgetId]);

  useEffect(() => {
    if (budgetId === 'new' && (creditCard?.openBooking || !canCreateBudgets))
      doCloseModal();
  }, [creditCard, canCreateBudgets, budgetId]);

  // Load Budget Info
  const loadInfo = async (budgetId) => {
    try {
      setLoading(true);
      const budget = await loadBudget(budgetId);
      await Promise.all([loadCard(budget), loadApprovals(budget)]);
    } catch (err) {
      console.error(err);
      doCloseModal();
    } finally {
      setLoading(false);
    }
  };

  const loadNewInfo = async () => {
    try {
      setLoading(true);

      const cardId = searchParams.get('cardId');

      const creditCard = await getDataFromArchive(`cc-${cardId}`, () =>
        getDocument(() =>
          firebaseApp
            .getExpenseCardFromId(passenger.organizationId, cardId)
            .get()
        )
      );

      setCreditCard(creditCard);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const loadBudget = (budgetId) =>
    new Promise((res, rej) => {
      snapshotBudget();

      snapshotBudget = firebaseApp
        .getBudgetById(budgetId)
        .onSnapshot(function (querySnapshot) {
          if (querySnapshot.exists) {
            const budget = {
              ...querySnapshot.data(),
              id: querySnapshot.id,
            };

            setBudget(budget);
            return res(budget);
          } else return rej(null);
        }, doCloseModal);
    });

  const loadCard = (budget) =>
    new Promise((res) => {
      snapshotCard();

      snapshotCard = firebaseApp
        .getExpenseCardFromId(passenger.organizationId, budget.cardId)
        .onSnapshot((querySnapshot) => {
          if (querySnapshot.exists) {
            const card = {
              ...querySnapshot.data(),
              id: querySnapshot.id,
            };

            setCreditCard(card);
            return res(card);
          } else return res(null);
        }, doCloseModal);
    });

  const loadApprovals = (budget) =>
    new Promise((res) => {
      snapshotApprovals();

      snapshotApprovals = firebaseApp
        .getApprovalsFromBudget(budget.id)
        .onSnapshot(function (querySnapshot) {
          const approvals = [];

          querySnapshot.forEach((docSnap) => {
            approvals.push({
              ...docSnap.data(),
              id: docSnap.id,
            });
          });

          setApprovals(approvals);
          return res(approvals);
        }, doCloseModal);
    });

  // Render
  if (loading) {
    return null;
  }

  return Component && (isNew || (budget && creditCard)) ? (
    <Component
      expenseId={budgetId}
      budget={budget}
      creditCard={creditCard}
      cardId={cardId}
      itemId={itemId}
      cardType={cardType}
      toggleModal={doCloseModal}
      approvals={approvals}
      onCreateBudget={onCreate}
      path={path}
      userId={userId}
    />
  ) : null;
}
