import React, { Component } from 'react';
import { Card, CardBody, CardHeader, CardTitle, CustomInput } from 'reactstrap';
import isUndefined from 'lodash/isUndefined';
import map from 'lodash/map';

import LoadingFilter from '../../components/search/LoadingFilter';

import LoadSearchStatus from '../../constants/loadSearchStatus';
import { injectIntl } from 'react-intl';
import { triggerFilterChangeSegment } from '../../utils/segment';

class OfficeFilters extends Component {
  shouldComponentUpdate() {
    // TODO
    return true;
  }

  getFilterObject = () => ({ amenities: this.props.amenities });

  // Render
  render() {
    const {
      params,
      status,
      amenities,
      onAmenityChange,
      intl: { messages },
    } = this.props;

    if (status === LoadSearchStatus.EMPTY || isUndefined(params)) {
      return <div />;
    }

    if (!params?.amenities) {
      return (
        <div className="search-results loader">
          <LoadingFilter />
          <LoadingFilter />
          <LoadingFilter />
        </div>
      );
    }

    return (
      <div>
        {Object.keys(params?.amenities) && (
          <Card className="search">
            <CardHeader>
              <CardTitle>
                {messages['containers.search.hotel-filters.amenities']}
              </CardTitle>
            </CardHeader>
            <CardBody>
              {map(params.amenities, (qtd, amenity) => {
                return (
                  <CustomInput
                    key={`amenity-${amenity}`}
                    type="checkbox"
                    id={`check-amenity-${amenity}`}
                    label={amenity}
                    checked={amenities[amenity]}
                    onChange={(e) => {
                      triggerFilterChangeSegment(
                        `Amenities changed (${amenity})`,
                        e.target.checked,
                        this.getFilterObject()
                      );
                      onAmenityChange(amenity);
                    }}
                  />
                );
              })}
            </CardBody>
          </Card>
        )}
      </div>
    );
  }
}

export default injectIntl(OfficeFilters);
