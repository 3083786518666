import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { stringToMoney } from '../../../utils/money';
import LoadingFunds from './LoadingFunds';

export default function CardFunds({ funds, className = '', loading }) {
  const { messages } = useIntl();

  const { total, decimalPart } = useMemo(() => {
    const value = funds ? funds : 0;
    const total = stringToMoney(value / 100, 2);
    const length = total.length;
    const intPart = total.slice(0, length - 3),
      decimalPart = total.slice(length - 3);

    return {
      total: intPart,
      decimalPart,
    };
  }, [funds]);

  return (
    <div className={`card-funds ${className}`}>
      <p className="m-0 p-0 remaining-funds font-primary text-muted mb-3">
        {messages['cards.card-funds.total']}
      </p>
      <div style={{ minHeight: 30 }}>
        {loading && funds === undefined ? (
          <LoadingFunds />
        ) : (
          <h3 className="m-0 p-0 total font-primary font-weight-medium d-flex">
            {total}
            <span className="decimal-part">{decimalPart}</span>
          </h3>
        )}
      </div>
    </div>
  );
}
