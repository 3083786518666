export const loadingSeats = [
  [
    {
      id: '01',
      name: '01',
      available: true,
      position: { x: 1, y: 0, z: 0 },
    },
    {
      id: '02',
      name: '02',
      available: true,
      position: { x: 1, y: 1, z: 0 },
    },
    {
      id: '03',
      name: '03',
      available: true,
      position: { x: 1, y: 4, z: 0 },
    },
    {
      id: '04',
      name: '04',
      available: true,
      position: { x: 1, y: 3, z: 0 },
    },
  ],
  [
    {
      id: '05',
      name: '05',
      available: true,
      position: { x: 2, y: 0, z: 0 },
    },
    {
      id: '06',
      name: '06',
      available: true,
      position: { x: 2, y: 1, z: 0 },
    },
    {
      id: '07',
      name: '07',
      available: true,
      position: { x: 2, y: 4, z: 0 },
    },
    {
      id: '08',
      name: '08',
      available: true,
      position: { x: 2, y: 3, z: 0 },
    },
  ],
  [
    {
      id: '09',
      name: '09',
      available: true,
      position: { x: 3, y: 0, z: 0 },
    },
    {
      id: '10',
      name: '10',
      available: true,
      position: { x: 3, y: 1, z: 0 },
    },
    {
      id: '11',
      name: '11',
      available: true,
      position: { x: 3, y: 4, z: 0 },
    },
    {
      id: '12',
      name: '12',
      available: true,
      position: { x: 3, y: 3, z: 0 },
    },
  ],
  [
    {
      id: '13',
      name: '13',
      available: true,
      position: { x: 4, y: 0, z: 0 },
    },
    {
      id: '14',
      name: '14',
      available: true,
      position: { x: 4, y: 1, z: 0 },
    },
    {
      id: '15',
      name: '15',
      available: true,
      position: { x: 4, y: 4, z: 0 },
    },
    {
      id: '16',
      name: '16',
      available: true,
      position: { x: 4, y: 3, z: 0 },
    },
  ],
  [
    {
      id: '17',
      name: '17',
      available: true,
      position: { x: 5, y: 0, z: 0 },
    },
    {
      id: '18',
      name: '18',
      available: true,
      position: { x: 5, y: 1, z: 0 },
    },
    {
      id: '19',
      name: '19',
      available: true,
      position: { x: 5, y: 4, z: 0 },
    },
    {
      id: '20',
      name: '20',
      available: true,
      position: { x: 5, y: 3, z: 0 },
    },
  ],
  [
    {
      id: '21',
      name: '21',
      available: true,
      position: { x: 6, y: 0, z: 1 },
    },
    {
      id: '22',
      name: '22',
      available: true,
      position: { x: 6, y: 1, z: 1 },
    },
    {
      id: '23',
      name: '23',
      available: true,
      position: { x: 6, y: 4, z: 1 },
    },
    {
      id: '24',
      name: '24',
      available: true,
      position: { x: 6, y: 3, z: 1 },
    },
  ],
  [
    {
      id: '25',
      name: '25',
      available: true,
      position: { x: 7, y: 0, z: 1 },
    },
    {
      id: '26',
      name: '26',
      available: true,
      position: { x: 7, y: 1, z: 1 },
    },
    {
      id: '27',
      name: '27',
      available: true,
      position: { x: 7, y: 4, z: 1 },
    },
    {
      id: '28',
      name: '28',
      available: true,
      position: { x: 7, y: 3, z: 1 },
    },
  ],
  [
    {
      id: '29',
      name: '29',
      available: true,
      position: { x: 8, y: 0, z: 1 },
    },
    {
      id: '30',
      name: '30',
      available: true,
      position: { x: 8, y: 1, z: 1 },
    },
    {
      id: '31',
      name: '31',
      available: true,
      position: { x: 8, y: 4, z: 1 },
    },
    {
      id: '32',
      name: '32',
      available: true,
      position: { x: 8, y: 3, z: 1 },
    },
  ],
  [
    {
      id: '33',
      name: '33',
      available: true,
      position: { x: 9, y: 0, z: 1 },
    },
    {
      id: '34',
      name: '34',
      available: true,
      position: { x: 9, y: 1, z: 1 },
    },
    {
      id: '35',
      name: '35',
      available: true,
      position: { x: 9, y: 4, z: 1 },
    },
    {
      id: '36',
      name: '36',
      available: true,
      position: { x: 9, y: 3, z: 1 },
    },
  ],
  [
    {
      id: '37',
      name: '37',
      available: true,
      position: { x: 10, y: 0, z: 1 },
    },
    {
      id: '38',
      name: '38',
      available: true,
      position: { x: 10, y: 1, z: 1 },
    },
    {
      id: '39',
      name: '39',
      available: true,
      position: { x: 10, y: 4, z: 1 },
    },
    {
      id: '40',
      name: '40',
      available: true,
      position: { x: 10, y: 3, z: 1 },
    },
  ],
  [
    {
      id: '41',
      name: '41',
      available: true,
      position: { x: 11, y: 0, z: 1 },
    },
    {
      id: '42',
      name: '42',
      available: true,
      position: { x: 11, y: 1, z: 1 },
    },
    {
      id: '43',
      name: '43',
      available: true,
      position: { x: 11, y: 4, z: 1 },
    },
    {
      id: '44',
      name: '44',
      available: true,
      position: { x: 11, y: 3, z: 1 },
    },
  ],
  [
    {
      id: '45',
      name: '45',
      available: true,
      position: { x: 12, y: 0, z: 1 },
    },
    {
      id: '46',
      name: '46',
      available: true,
      position: { x: 12, y: 1, z: 1 },
    },
  ],
];
