import React, { Component } from 'react';
import { Row, Button } from 'reactstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import find from 'lodash/find';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import classnames from 'classnames';

import { Colxx } from '../../components/common/CustomBootstrap';
import { stringToMoney } from '../../utils/money';

import PackageItem from './PackageItem';
import PackageFlightSearch from './PackageFlightSearch';
import PackageFlightModal from './PackageFlightModal';
import PackageHotelSearch from './PackageHotelSearch';
import PackageHotelModal from './PackageHotelModal';
import NoResults from '../../components/search/NoResults';

import LoadingPackage from '../../components/search/LoadingPackage';

import firebaseApp from '../../services/Firebase';
import { trackPropertiesSegment } from '../../utils/segment';

import LoadSearchStatus from '../../constants/loadSearchStatus';
import SearchStatus from '../../constants/searchStatus';

import { setPassenger } from '../../redux/timeline/actions';
import { injectIntl } from 'react-intl';
import { maxBy } from 'lodash';

class PackageSearch extends Component {
  constructor(props) {
    super(props);

    this.initialState = {
      packages: [],
      flightResults: [],
      hotelResults: [],
      status: LoadSearchStatus.NEW,
      isLoading: true,
      lastHotel: null,
      params: {
        flight: {},
        hotel: {},
      },
      selectionIndex: 0,
      selection: null,
      activeTab: 'flight',
      flightDetails: null,
      hotelDetails: null,
    };

    this.state = this.initialState;
  }

  // Search
  componentDidUpdate(prevProps) {
    if (
      prevProps.search.status !== SearchStatus.LOADING &&
      this.props.search.status === SearchStatus.LOADING
    ) {
      this.setState(this.initialState);
    }

    if (
      prevProps.search.status !== SearchStatus.DONE &&
      this.props.search.status === SearchStatus.DONE
    ) {
      this.getParams();
      this.getInitialPackageSearch();
    }
  }

  getParams = async () => {
    const { search } = this.props;

    const paramsSnap = await firebaseApp
      .getSearchParams(search.searchCode)
      .get();

    let params = {};

    paramsSnap.forEach((paramSnap) => {
      params[paramSnap.id] = paramSnap.data();
    });

    this.setState({ params });
  };

  getInitialPackageSearch = async () => {
    const { search } = this.props;

    const resultsSnap = await firebaseApp
      .getPackageResults(search.searchCode)
      .get();

    let newPackages = [];

    resultsSnap.forEach((resultSnap) => {
      const packageItem = resultSnap.data();
      packageItem.id = resultSnap.id;

      newPackages.push(packageItem);
    });

    this.setState({
      packages: newPackages,
      selection: newPackages[0],
      isLoading: false,
      status: LoadSearchStatus.ALL,
    });
  };

  // Reservation
  onSelectPackage = (selection) => {
    this.setState({ selection });
  };

  reserveItem = async () => {
    const { user, setPassenger, search } = this.props;
    const { params, selection } = this.state;

    // Get Passenger
    const passengerSnap = await firebaseApp.getUserFromId(user.uid).get();
    const passenger = passengerSnap.data();

    // Flight
    const flightOrderPayload = {
      costCenter: {
        value: user.costCenter?.id || '',
        label: user.costCenter?.name || '',
      },
      project: {
        value: user.project?.id || '',
        label: user.project?.name || '',
      },
      journeys: map(selection.flight.flight.journeys, (journey, index) => {
        const arrivalStation = find(params.flight.airports, {
          iata: journey.arrivalStation,
        }).city;
        const departureStation = find(params.flight.airports, {
          iata: journey.departureStation,
        }).city;

        const segment = selection.flight.segments[index];

        return {
          id: index,
          departureStation: {
            iataCode: journey.departureStation,
            name: departureStation,
          },
          arrivalStation: {
            iataCode: journey.arrivalStation,
            name: arrivalStation,
          },
          departureDate: journey.departureDate,
          sellKey: segment.sellKey,
        };
      }),
      fare: selection.flight.flight.fare,
      provider: {
        origin: selection.flight.flight.origin,
        source: selection.flight.flight.source,
        validatingCarrier: selection.flight.flight.validatingAirline,
      },
      searchCode: search.searchCode,
    };

    const flightReservations = [];
    selection.flight.flight.journeys.forEach((journey, index) => {
      const segment = selection.flight.segments[index];

      segment.flights.forEach((flight, flightIndex) => {
        const arrivalStationName = find(params.flight.airports, {
          iata: flight.arrivalStation,
        }).city;
        const departureStationName = find(params.flight.airports, {
          iata: flight.departureStation,
        }).city;

        flightReservations.push({
          baggage: '',
          bookingCode: '',
          airline: flight.airline,
          airlineOperating: flight.airlineOperating,
          validatingAirline: segment.validatingAirline,
          flightNumber: flight.flightNumber,
          arrivalDateTime: moment(flight.arrivalDateTime).format(
            'DD/MM/YYYY HH:mm'
          ),
          arrivalStation: {
            iataCode: flight.arrivalStation,
            name: arrivalStationName,
          },
          departureDateTime: moment(flight.departureDateTime).format(
            'DD/MM/YYYY HH:mm'
          ),
          departureStation: {
            iataCode: flight.departureStation,
            name: departureStationName,
          },
          fare: flight.fare,
          journeyId: index,
          flightIndex,
        });
      });
    });

    trackPropertiesSegment('New Reservation', {
      ...flightOrderPayload,
      type: 'flight',
    });

    const latestReservation = maxBy(flightReservations, (r) => {
      return moment(r.arrivalDateTime, 'DD/MM/YYYY HH:mm').unix();
    });

    await firebaseApp.createFlightReservation({
      organizationId: user.organizationId,
      passengerId: user.uid,
      passenger,
      orderDetails: flightOrderPayload,
      reservations: flightReservations,
      filterTimestamp: moment(
        latestReservation.arrivalDateTime,
        'DD/MM/YYYY HH:mm'
      )
        .endOf('day')
        .utc()
        .unix(),
      type: 'flight',
    });

    // Hotel
    const hotelOrderPayload = {
      costCenter: {
        value: user.costCenter?.id || '',
        label: user.costCenter?.name || '',
      },
      project: {
        value: user.project?.id || '',
        label: user.project?.name || '',
      },
      fare: selection.hotel.fare,
      provider: {
        origin: selection.hotel.origin,
        token: selection.hotel.token,
      },
      searchCode: search.searchCode,
    };

    const cancellation = selection.hotel.selectedRoom.cancellation;
    delete selection.hotel.selectedRoom.cancellation;

    const hotelReservation = {
      hotel: selection.hotel.hotel,
      hotelId: selection.hotel.hotelId,
      selectedRoom: selection.hotel.selectedRoom,
      cancellation,
      checkIn: moment(search.outbound_date).format('DD/MM/YYYY'),
      checkOut: moment(search.inbound_date).format('DD/MM/YYYY'),
      confirmation: '',
    };

    trackPropertiesSegment('New Reservation', {
      ...hotelOrderPayload,
      type: 'hotel',
    });

    await firebaseApp.createHotelReservation({
      organizationId: user.organizationId,
      passenger,
      passengerId: user.uid,
      orderDetails: hotelOrderPayload,
      reservations: hotelReservation,
      filterTimestamp: moment(hotelReservation.checkIn, 'DD/MM/YYYY')
        .endOf('day')
        .utc()
        .unix(),
      type: 'hotel',
    });

    setPassenger(passenger);
  };

  changeHotel = (item, room) => {
    const { selection } = this.state;

    this.setState({
      selection: {
        ...selection,
        hotel: {
          ...item,
          selectedRoom: room,
        },
      },
    });

    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  changeFlight = (item, selectedSegments) => {
    const { selection } = this.state;

    this.setState({
      selection: {
        ...selection,
        flight: {
          flight: item,
          segments: selectedSegments,
        },
      },
    });

    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Tabs
  toggleTabs = (activeTab) => {
    this.setState({ activeTab });
  };

  toggleSelection = (selectionIndex) => {
    const { packages } = this.state;

    this.setState({
      selectionIndex,
      selection: packages[selectionIndex],
    });
  };

  // Empty
  setEmpty = () => {
    this.setState({
      status: LoadSearchStatus.EMPTY,
    });
  };

  // Details
  showFlightDetails = () => {
    const { selection } = this.state;

    this.setState({ flightDetails: selection.flight });
  };

  hideFlightDetails = () => {
    this.setState({ flightDetails: null });
  };

  showHotelDetails = () => {
    const { selection } = this.state;

    this.setState({ hotelDetails: selection.hotel });
  };

  hideHotelDetails = () => {
    this.setState({ hotelDetails: null });
  };

  // Render
  renderPackages() {
    const { isLoading, packages, params } = this.state;

    if (isLoading) {
      return (
        <Row className="search-results loader">
          <Colxx xxs="12" sm="4">
            <LoadingPackage />
          </Colxx>
          <Colxx xxs="12" sm="4">
            <LoadingPackage />
          </Colxx>
          <Colxx xxs="12" sm="4">
            <LoadingPackage />
          </Colxx>
        </Row>
      );
    }

    return (
      <Row className="package-items">
        <Colxx sm="4">
          {!isEmpty(packages[0]) && (
            <PackageItem
              item={packages[0]}
              index={0}
              params={params}
              selectionIndex={this.state.selectionIndex}
              toggleSelection={this.toggleSelection}
            />
          )}
        </Colxx>
        <Colxx sm="4">
          {!isEmpty(packages[1]) && (
            <PackageItem
              item={packages[1]}
              index={1}
              params={params}
              selectionIndex={this.state.selectionIndex}
              toggleSelection={this.toggleSelection}
            />
          )}
        </Colxx>
        <Colxx sm="4">
          {!isEmpty(packages[2]) && (
            <PackageItem
              item={packages[2]}
              index={2}
              params={params}
              selectionIndex={this.state.selectionIndex}
              toggleSelection={this.toggleSelection}
            />
          )}
        </Colxx>
      </Row>
    );
  }

  renderPackageDetails() {
    const {
      search,
      intl: { messages },
    } = this.props;
    const { packages, activeTab, isLoading, selection } = this.state;

    if (isLoading) return;

    const renderFlightRows = () => {
      const renderFlightSegment = (segment, index) => {
        return (
          <div className="row-item" key={`package-flight-${index}`}>
            <div className="left">
              <p className="destination">
                <img
                  src="/assets/img/icons/search_icon_gray.svg"
                  alt={
                    messages['alts.containers.search.package-search.station']
                  }
                />
                {segment.departureStation}
              </p>
              <p>{moment(segment.departureDateTime).format('DD/MM HH:mm')}</p>
            </div>
            <div className="stops-chart w-30">
              <div className="graph">
                <div className="line">
                  <div className="dots">
                    {map(segment.flights, (flightItem, iFI) => {
                      if (iFI === 0) return '';
                      return (
                        <div key={`result-${index}-${iFI}`} className="block" />
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="info">
                {segment.flights.length - 1 === 0
                  ? messages['flight.no-stop']
                  : segment.flights.length - 1 === 1
                  ? `1 ${messages['flight.stop']}`
                  : `${segment.flights.length - 1} ${messages['flight.stops']}`}
              </div>
            </div>
            <div className="right">
              <p className="destination">{segment.arrivalStation}</p>
              <p>{moment(segment.arrivalDateTime).format('DD/MM HH:mm')}</p>
            </div>
          </div>
        );
      };

      if (selection.flight.segment) {
        return (
          <div className="dates">
            {renderFlightSegment(selection.flight.segment, 0)}
          </div>
        );
      }

      if (selection.flight.segments) {
        return (
          <div className="dates">
            {map(selection.flight.segments, (segment, index) => {
              return renderFlightSegment(segment, index);
            })}
          </div>
        );
      }
    };

    const renderHotelRows = () => {
      const {
        intl: { messages },
      } = this.props;
      const hotel = selection.hotel;

      return (
        <div className="dates">
          <div className="row-item">
            <div className="left">
              <p className="destination">
                <img
                  src="/assets/img/icons/search_icon_gray.svg"
                  alt={messages['travel.checkin']}
                />
                {messages['travel.checkin']}
              </p>
              <p>{moment(search.outbound_date).format('ddd DD [de] MMM')}</p>
            </div>
            <div className="right">
              <p className="destination">{messages['travel.checkout']}</p>
              <p>{moment(search.inbound_date).format('ddd DD [de] MMM')}</p>
            </div>
          </div>
          <div className="row-item">
            <div className="left">
              <p className="destination">{hotel?.hotel.name}</p>
              <p>
                {hotel?.selectedRoom.board.breakfast
                  ? messages['travel.with-breakfast']
                  : messages['travel.without-breakfast']}
              </p>
            </div>
          </div>
        </div>
      );
    };

    if (search.status === SearchStatus.LOADING || !packages.length) {
      return <Row className="search-results loader" />;
    }

    const flightClassName = classnames({
      'package-item': true,
      active: activeTab === 'flight',
    });
    const hotelClassName = classnames({
      'package-item': true,
      active: activeTab === 'hotel',
    });

    let total = 0;
    if (selection.flight) total = total + selection.flight.flight.fare.total;
    if (selection.hotel) total = total + selection.hotel.fare.total;

    return (
      <Row className="package-selection">
        <Colxx xs="5" className={flightClassName}>
          <div className="title">
            <span>{messages['travel.flight']}</span>
            <Button color="link" onClick={this.showFlightDetails}>
              {messages['travel.flight']}
            </Button>
          </div>
          {renderFlightRows()}
          <div className="action">
            {activeTab === 'flight' ? (
              messages['containers.search.package-search.change-flight']
            ) : (
              <Button
                onClick={() => {
                  this.toggleTabs('flight');
                }}
                size="xs"
              >
                {
                  messages[
                    'containers.search.package-search.choose-another-flight'
                  ]
                }
              </Button>
            )}
          </div>
        </Colxx>
        <Colxx xs="5" className={hotelClassName}>
          <div className="title">
            <span>{messages['travel.menu.hotel']}</span>
            <Button color="link" onClick={this.showHotelDetails}>
              {messages['general.more-details']}
            </Button>
          </div>
          {renderHotelRows()}
          <div className="action">
            {activeTab === 'hotel' ? (
              messages['containers.search.package-search.change-hotel']
            ) : (
              <Button
                onClick={() => {
                  this.toggleTabs('hotel');
                }}
                size="xs"
              >
                {
                  messages[
                    'containers.search.package-search.choose-another-hotel'
                  ]
                }
              </Button>
            )}
          </div>
        </Colxx>
        <Colxx xs="2" className="total">
          <div className="text">
            <span>{messages['general.total']}</span>
            {stringToMoney(total)}
            <hr />
          </div>
          <div className="description">{messages['general.taxes']}</div>

          <Button color="primary" size="xs" onClick={this.reserveItem}>
            {messages['general.book']}
          </Button>
        </Colxx>
      </Row>
    );
  }

  render() {
    const { search } = this.props;
    const { status, params } = this.state;

    if (search.status === SearchStatus.NEW) return <div />;

    if (status === LoadSearchStatus.EMPTY) {
      return <NoResults />;
    }

    return (
      <div>
        <Row className="mt-3">
          <Colxx xxs="12" sm={{ offset: 3, size: 9 }}>
            {this.renderPackages()}

            {this.renderPackageDetails()}
          </Colxx>
        </Row>
        {this.state.activeTab === 'flight' && (
          <PackageFlightSearch
            search={search}
            params={params?.flight}
            changeFlight={this.changeFlight}
            setEmpty={this.setEmpty}
          />
        )}

        {this.state.activeTab === 'hotel' && (
          <PackageHotelSearch
            search={search}
            params={params?.hotel}
            changeHotel={this.changeHotel}
            setEmpty={this.setEmpty}
          />
        )}

        {this.state.flightDetails && (
          <PackageFlightModal
            showModal={this.state.flightDetails !== null}
            toggleModal={this.hideFlightDetails}
            item={this.state.flightDetails.flight}
            segments={this.state.flightDetails.segments}
            params={this.props.params}
          />
        )}

        {this.state.hotelDetails && (
          <PackageHotelModal
            showModal={this.state.hotelDetails !== null}
            toggleModal={this.hideHotelDetails}
            item={this.state.hotelDetails}
            params={this.props.params}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { user } = auth;
  return { user };
};

export default connect(mapStateToProps, { setPassenger })(
  injectIntl(PackageSearch)
);
