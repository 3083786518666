import { combineReducers } from 'redux';
import auth from './auth/reducer';
import settings from './settings/reducer';
import locations from './locations/reducer';
import timeline from './timeline/reducer';
import checklist from './checklist/reducer';
import animation from './animation/reducer';
import refunds from './refunds/reducer';
import approvals from './approvals/reducer';
import reservationActions from './reservations/reducer';
import bulk from './bulk/reducer';
import recurrenceBudgets from './recurrenceBudgets/reducer';
import reportBudgetExpenses from './reportExpenses/reducer';
import searchHotel from './searchHotel/reducer';

const reducers = combineReducers({
  auth,
  settings,
  locations,
  timeline,
  checklist,
  animation,
  refunds,
  approvals,
  reservationActions,
  bulk,
  recurrenceBudgets,
  reportBudgetExpenses,
  searchHotel,
});

export default reducers;
