import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Button } from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { stringToMoney } from '../../../../../../utils/money';
import {
  AncillaryStatus,
  AncillaryStatusClasses,
  AncillaryStatusMessages,
} from '../../../../../../constants/ancillaryStatus';
import AncillaryItems from '../AncillaryItems';
import Spinner from '../../../../../common/Spinner';

export default function AncillaryTab({
  ancillaries,
  reservations,
  goForAlteration,
  order,
}) {
  const { messages } = useIntl();

  const { total, formattedTotal, statusMessage, statusClass, isBooking } =
    useMemo(() => {
      if (ancillaries) {
        const total = ancillaries.fare?.total || 0;

        return {
          total,
          formattedTotal: stringToMoney(total, 2),
          statusMessage:
            messages[AncillaryStatusMessages[ancillaries.statusCode]],
          statusClass: AncillaryStatusClasses[ancillaries.statusCode],
          isBooking: ancillaries.statusCode === AncillaryStatus.BOOKING,
        };
      } else return {};
    }, [ancillaries]);

  // Render

  return (
    <div className="d-flex flex-column justify-content-between h-100 ancillary-tab">
      <div className="d-flex flex-column align-items-start">
        <h3 className="hotel-title m-0">
          {messages['travel.ancillary.title']}
        </h3>
        <div
          className={`status-code rounded text-center ${statusClass} px-3 py-1 mt-2`}
        >
          <span>{statusMessage}</span>
        </div>
      </div>

      <div className="flex-grow-1 py-4 items" style={{ minHeight: 0 }}>
        <PerfectScrollbar
          options={{
            suppressScrollX: true,
            wheelPropagation: false,
          }}
        >
          {isBooking ? (
            <div className="flex-center w-100 h-100">
              <Spinner white relative style={{ width: 60, height: 60 }} />
            </div>
          ) : (
            <div className="d-flex flex-wrap py-2">
              <AncillaryItems
                ancillary={ancillaries}
                reservations={reservations}
                order={order}
              />
            </div>
          )}
        </PerfectScrollbar>
      </div>

      <div className="d-flex align-items-center justify-content-between flex-shrink-0">
        {total > 0 ? (
          <div className="price-container">
            <p
              className="m-0 p-0 one-line-height title font-weight-semibold mb-1"
              style={{ fontSize: '0.7rem' }}
            >
              {messages['general.total']}
            </p>
            <p className="m-0 p-0 one-line-height total">{formattedTotal}</p>
          </div>
        ) : (
          <div />
        )}

        <div>
          <Button color="link" onClick={goForAlteration} className="m-0">
            {messages['general.go-back']}
          </Button>
        </div>
      </div>
    </div>
  );
}
