import { TChildren } from '../types/others';

interface IProps {
  show: boolean;
  children: TChildren;
}

export default function ConditionalRenderWrapper({ show, children }: IProps) {
  return show ? children : null;
}
