import React, { useMemo } from 'react';
import { Button } from 'reactstrap';

import { useIntl } from 'react-intl';

import firebaseApp from '../../services/Firebase';

import OrderStatus from '../../constants/orderStatus';
import { trackPropertiesSegment } from '../../utils/segment';
import moment from 'moment';

export default function CancellationInfo({
  order,
  goForCancellation,
  toggleModal,
  cancellations = [],
  showCancelButton = true,
}) {
  const { messages } = useIntl();

  // States
  const canCancel = useMemo(() => {
    const numCancellations = cancellations.length;

    if (numCancellations > 0) {
      const today = moment().format('YYYY-MM-DD');
      return today <= cancellations[numCancellations - 1].endDate;
    } else return false;
  }, [cancellations]);

  // Functions
  const dismissReservation = async () => {
    await firebaseApp.requestToDismiss(order.id);

    toggleModal();
  };

  // Render
  if (!showCancelButton) return null;

  if (
    [
      OrderStatus.CANCELLED,
      OrderStatus.EXPIRED,
      OrderStatus.ERROR,
      OrderStatus.DENIED,
    ].includes(order.statusCode)
  )
    return (
      <div>
        <Button
          name="cancel"
          outline
          color="primary"
          className="mt-0"
          onClick={dismissReservation}
        >
          {messages['travel.order.cancellation.dismiss']}
        </Button>
      </div>
    );

  if (order.statusCode < OrderStatus.ISSUING) {
    return (
      <div>
        <p className="color-alert">
          {messages['travel.order.cancellation.issue']}
        </p>

        <Button
          name="cancel"
          outline
          color="primary"
          className="mt-0"
          onClick={() => {
            trackPropertiesSegment('User cancelled order', {
              order,
            });
            dismissReservation();
          }}
        >
          {messages['travel.order.cancellation.cancel']}
        </Button>
      </div>
    );
  } else if (order.statusCode === OrderStatus.ISSUED && canCancel) {
    return (
      <div>
        <Button
          outline
          color="primary"
          className="mt-0"
          onClick={() => {
            trackPropertiesSegment('User cancelled order', {
              order,
            });
            goForCancellation();
          }}
        >
          {messages['travel.order.cancellation.cancel']}
        </Button>
      </div>
    );
  }

  return <div />;
}
