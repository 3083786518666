import React from 'react';
import Switch from 'rc-switch';
import classNames from 'classnames';

export default function SwitchItem({
  title,
  done = true,
  onChange = () => {},
  style = {},
  className = '',
  titleClassName = '',
}) {
  return (
    <div className={`switch-item d-flex ${className}`} style={style}>
      <Switch checked={done} onChange={onChange} className="custom-switch" />
      <p className={classNames('m-0 mb-3 ml-2', titleClassName)}>{title}</p>
    </div>
  );
}
