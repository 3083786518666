export function sortBusSeats(seats) {
  const sortedSeats = seats.sort((a, b) => a.id.localeCompare(b.id));
  const finalSeats = {};

  sortedSeats.forEach((seat) => {
    const { x, y, z } = seat.position;
    const xPosition = x - 1;

    if (!(z in finalSeats))
      finalSeats[z] = {
        seats: [],
        columnSize: 0,
      };

    const { seats, columnSize } = finalSeats[z];

    if (!seats[xPosition]) seats[xPosition] = [];

    seats[xPosition].push(seat);

    if (y > columnSize) finalSeats[z].columnSize = y;
  });

  return Object.values(finalSeats).map(({ seats, columnSize }) => ({
    seats,
    columnSize: columnSize + 1,
  }));
}
