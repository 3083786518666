import React, { useState, useMemo, useRef, useEffect } from 'react';
import { Row, Button, Modal, ModalBody } from 'reactstrap';
import { Colxx } from '../common/CustomBootstrap';
import { useIntl } from 'react-intl';
import FavoritePlacesMap from '../common/FavoritePlacesMap';

import {
  distanceBetweenPoints,
  offsetLocationByKm,
} from '../../utils/locations';
import HotelItem from './HotelItem';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Pagination from '../../containers/pages/Pagination';

import { ReactComponent as NoneHotelsIcon } from '../../assets/img/config/no-hotels.svg';
import { ReactComponent as EmptyHotelsIcon } from '../../assets/img/config/emptyHotels.svg';
import { ReactComponent as LoadingIcon } from '../../assets/img/config/loading.svg';
import firebaseApp from '../../services/Firebase';
import SimpleSearchBar from '../common/SimpleSearchBar';
import { getNumberFromAddress } from '../../utils/city';

export default function FavoriteHotelModal(props) {
  const {
    itemsPerPage = 10,
    saveHotel = () => {},
    deleteHotel = () => {},
    selectedHotels: _selectedHotels = [],
  } = props;

  const [highlightedPin, setHighlightedPin] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const [isLoading, setIsLoading] = useState(false);
  const [selectedHotels, setSelectedHotels] = useState(
    _selectedHotels.reduce((obj, hotel) => {
      obj[hotel.iterpec] = hotel;
      return obj;
    }, {})
  );
  const [hotels, setHotels] = useState([]);
  const [search, setSearch] = useState('');
  const [inProcessHotels, setInProcessHotels] = useState([]);

  const scrollRef = useRef(null);

  const hotelCoords = useMemo(
    () =>
      hotels.map(({ localization, iterpec }) => ({
        lat: localization.lat,
        lng: localization.lng,
        id: iterpec,
      })),
    [hotels]
  );

  const matchingHotels = useMemo(() => {
    if (search) {
      const lowerSearch = search.toLowerCase();
      return hotels.filter((hotel) =>
        `${hotel.name} ${hotel.destination.address} ${hotel.destination.city.pt}`
          .toLowerCase()
          .includes(lowerSearch)
      );
    } else return null;
  }, [search]);

  const hotelsData = matchingHotels || hotels;

  const { messages, formatMessage } = useIntl();

  // Effects
  useEffect(() => {
    if (scrollRef.current) scrollRef.current._container.scrollTop = 0;
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(1);
  }, [search]);

  // Functions
  const toggleHighlightedPin = (location) => {
    if (location) {
      if (location.id) {
        const selectedHotel = hotels.find(
          (hotel) => hotel.iterpec === location.id
        );
        toggleHotel(selectedHotel);
      } else {
        setHighlightedPin(location);
        fetchHotels(location, offsetLocationByKm(location));
      }
    } else {
      stopSearch();
    }
  };

  const stopSearch = () => {
    setHighlightedPin(null);
    setHotels(Object.values(selectedHotels));
    setCurrentPage(1);
  };

  const fetchHotels = async (location, offsetLocation) => {
    const {
      lng: { max, min },
    } = offsetLocation;

    try {
      setIsLoading(true);
      const hotelSnap = await firebaseApp
        .getHotelsFromLocationInterval(offsetLocation)
        .get();
      const hotels = [];
      const alreadyAdded = new Set();

      hotelSnap.forEach((doc) => {
        const hotel = doc.data();

        if (!alreadyAdded.has(hotel.iterpec)) {
          hotels.push({
            ...hotel,
            id: doc.id,
            distance: distanceBetweenPoints(
              location,
              hotel.localization,
              'lat',
              'lng'
            ),
          });

          alreadyAdded.add(hotel.iterpec);
        }
      });

      setHotels(
        hotels
          .filter(
            (hotel) =>
              hotel.localization.lng >= min && hotel.localization.lng <= max
          )
          .sort((a, b) => a.distance - b.distance)
      );
    } catch (err) {
      console.debug(err);
    } finally {
      setIsLoading(false);
    }
  };

  const toggleHotel = async (hotel) => {
    if (inProcessHotels.includes(hotel.id)) {
      return false;
    }

    const oldInProcessHotels = inProcessHotels;

    try {
      setInProcessHotels([...inProcessHotels, hotel.id]);

      if (hotel.iterpec in selectedHotels) {
        const { [hotel.iterpec]: selectedHotel, ...others } = selectedHotels;
        setSelectedHotels(others);
        await deleteHotel(hotel);
      } else {
        setSelectedHotels({
          ...selectedHotels,
          [hotel.iterpec]: hotel,
        });
        await saveHotel(hotel);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setInProcessHotels(oldInProcessHotels);
    }
  };

  // Render
  const renderPagination = () => {
    const numHotels = hotelsData.length;

    return (
      numHotels > itemsPerPage && (
        <Pagination
          currentPage={currentPage}
          totalPage={Math.ceil(numHotels / itemsPerPage)}
          onChangePage={setCurrentPage}
          numberLimit={3}
        />
      )
    );
  };

  const renderHotels = () => {
    if (hotelsData.length > 0) {
      const startAt = (currentPage - 1) * itemsPerPage;
      return (
        <div className="hotels my-2">
          <PerfectScrollbar
            options={{ suppressScrollX: true, useBothWheelAxes: false }}
            style={{ height: '300px', marginBottom: -15 }}
            ref={scrollRef}
          >
            <div className="hotel-items">
              {hotelsData
                .slice(startAt, startAt + itemsPerPage)
                .map((hotel, index) => (
                  <HotelItem
                    key={hotel.id}
                    hotel={hotel}
                    favorite={hotel.iterpec in selectedHotels}
                    onFavorite={() => toggleHotel(hotel, index)}
                    onClick={() => setHighlightedPin(hotel.localization)}
                  />
                ))}
            </div>
          </PerfectScrollbar>
          <div className="pagination-container mt-3">{renderPagination()}</div>
        </div>
      );
    } else
      return (
        <div className="w-80 text-center mx-auto my-3">
          <EmptyHotelsIcon
            width="50%"
            className="slide-left"
            style={{ animationDelay: '200ms' }}
          />
          <h3
            className="small-medium-title text-dark m-0 my-2 slide-left"
            style={{ animationDelay: '250ms' }}
          >
            {messages['travels.favorite-hotels.nothing-found']}
          </h3>
          <p
            className="medium m-0 p-0 text-muted slide-left"
            style={{ animationDelay: '300ms' }}
          >
            {messages['travels.favorite-hotels.nothing-found.description']}
          </p>
        </div>
      );
  };

  const renderContent = () => {
    if (isLoading) {
      return (
        <div className="w-80 text-center mx-auto my-3">
          <LoadingIcon width={'50%'} />
          <h3 className="small-medium-title text-dark m-0 my-2 slide-left">
            {messages['travels.favorite-hotels.searching']}
          </h3>
          {highlightedPin.address !== undefined && (
            <p className="medium m-0 p-0 text-muted slide-left">
              {formatMessage(
                { id: 'travels.favorite-hotels.searching.description' },
                { address: highlightedPin.address }
              )}
            </p>
          )}
        </div>
      );
    }

    if (search || highlightedPin || hotelsData.length > 0)
      return renderHotels();

    return (
      <div className="w-80 text-center mx-auto my-5">
        <NoneHotelsIcon
          width={'50%'}
          className="slide-left"
          style={{ animationDelay: '200ms' }}
        />
        <h3
          className="small-medium-title text-dark m-0 my-2 slide-left"
          style={{ animationDelay: '250ms' }}
        >
          {messages['travels.favorite-hotels.none-selected']}
        </h3>
        <p
          className="medium m-0 p-0 text-muted slide-left"
          style={{ animationDelay: '300ms' }}
        >
          {messages['travels.favorite-hotels.none-selected.description']}
        </p>
      </div>
    );
  };

  // Render
  return (
    <Modal
      isOpen={true}
      toggle={props.toggleModal}
      className="favorite-place-modal hotels"
    >
      <ModalBody className="p-3 px-3">
        <Row className="px-3">
          <Colxx
            xxs="6"
            className="py-3 d-flex flex-column justify-content-between"
          >
            <h2 className="medium-title w-80">
              {messages['travels.favorite-hotels.title']}
            </h2>
            {(hotelsData.length > 0 || search) && (
              <div className="mb-3">
                <SimpleSearchBar
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder="Filtrar"
                  style={{ marginLeft: -2, fontSize: '0.85em' }}
                />
              </div>
            )}
            <div className="content">{renderContent()}</div>
            <div className="mt-1 d-flex justify-content-end">
              <Button
                type="button"
                color="primary"
                size="lg"
                className="px-3 ml-2 pointer"
                onClick={props.toggleModal}
              >
                {messages['general.close']}
              </Button>
            </div>
          </Colxx>
          <Colxx xxs="6" className="p-0 pl-3 map-container">
            <FavoritePlacesMap
              highlightedPin={highlightedPin}
              toggleHighlightedPin={toggleHighlightedPin}
              coords={hotelCoords}
              includeCity
              pinIcon="/assets/img/icons/hotel_pin.svg"
              highlightedPinIcon="/assets/img/icons/selected_pin.svg"
              selectedPlaces={selectedHotels}
              className="h-100"
            />
          </Colxx>
        </Row>
      </ModalBody>
    </Modal>
  );
}
