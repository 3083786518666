import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import CardOption from './CardOption';
import CollapsedOptions from './CollapsedOptions';

export default function CardOptions({
  card,
  details,
  options,
  showAtMost = 3,
  className = '',
}) {
  const { messages } = useIntl();

  const { visibleOptions, collapsedOptions = null } = useMemo(() => {
    if (options.length > showAtMost) {
      return {
        visibleOptions: options.slice(0, showAtMost),
        collapsedOptions: options.slice(showAtMost),
      };
    } else {
      return {
        visibleOptions: options,
      };
    }
  }, [options, showAtMost]);

  return (
    <div className={`card-options d-flex align-self-start ${className}`}>
      {visibleOptions.map((option, index) =>
        option.hide ? null : (
          <CardOption
            key={`${option.key}-${index}`}
            option={option}
            card={card}
            details={details}
            messages={messages}
          />
        )
      )}
      {collapsedOptions ? (
        <CollapsedOptions
          options={collapsedOptions}
          card={card}
          details={details}
          messages={messages}
        />
      ) : null}
    </div>
  );
}
