import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import BrazilStates from '../../../constants/brazilStates';
import { getReceiptState } from '../../../utils/receipts';
import CustomSelect from '../../common/CustomSelect';
import ReceiptQrCodeReader from '../../common/ReceiptQrCodeReader';
import TopMessage from '../../TopMessage';

const states = BrazilStates.map(({ sigla }) => ({
  value: sigla,
  label: sigla,
}));

export default function QrCodeMessages({
  show,
  onDone = () => {},
  onReject = () => {},
  onFail = () => {},
}) {
  const [step, setStep] = useState(0);
  const [showMessage, setShowMessage] = useState(false);

  const [info, setInfo] = useState({});

  // Effects
  useEffect(() => {
    if (show) {
      setInfo({});
      moveToStep(1);
    }
  }, [show]);

  // Functions
  const moveToStep = async (step) => {
    return new Promise((res) => {
      setShowMessage(false);
      setTimeout(() => {
        setStep(step);
        setShowMessage(true);
        res();
      }, 400);
    });
  };

  const handleReject = async () => {
    await moveToStep(0);
    onReject();
  };

  const handleDone = async (value) => {
    onDone(value);
    await moveToStep(0);
  };

  const handleQrCodeRead = (qrcode) => {
    const state = getReceiptState(qrcode);

    if (state) {
      onDone({
        qrcode,
        state,
      });
      moveToStep(0);
    } else {
      setInfo({ qrcode });
      moveToStep(2);
    }
  };

  const handleStateSelection = () => {
    const { qrcode, state } = info;

    handleDone({
      qrcode,
      state: state.value,
    });
  };

  // Render
  if (!show) return null;

  if (step === 1) {
    return (
      <TopMessage
        description="Importar informações do QR Code"
        show={showMessage}
        toggle={handleReject}
      >
        <ReceiptQrCodeReader
          show={true}
          className="w-100 mt-2"
          onScan={handleQrCodeRead}
          onError={onFail}
        />
      </TopMessage>
    );
  } else if (step === 2) {
    return (
      <TopMessage
        description="Não consegui identificar o estado da nota fiscal. Poderia me informâ-lo?"
        show={showMessage}
        toggle={handleReject}
      >
        <div className="w-100">
          <CustomSelect
            value={info.state}
            options={states}
            isMulti={false}
            className="w-100 my-3"
            onChange={(state) => setInfo({ ...info, state })}
          />

          <Button
            onClick={handleStateSelection}
            disabled={!info.state}
            color="primary"
          >
            Pronto!
          </Button>
        </div>
      </TopMessage>
    );
  }

  return null;
}
