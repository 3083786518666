import React from 'react';
import PreviewActionButtons from './PreviewActionButtons';

export default function PDFPreview({
  src,
  className = '',
  canDoAction,
  editable,
  onDelete,
  white,
}) {
  const handleClick = () => {
    window.open(src, '_blank');
  };

  return (
    <div className={`pdf-preview pointer ${className}`}>
      <PreviewActionButtons
        onDelete={onDelete}
        onOpenFile={handleClick}
        canDoAction={canDoAction}
        editable={editable}
        white={white}
      />
      <embed
        src={src}
        type="application/pdf"
        frameBorder="0"
        scrolling="auto"
        height="100%"
        width="100%"
      ></embed>
    </div>
  );
}
