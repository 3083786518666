import firebaseApp from '../services/Firebase';

export const getApprovalsFromOrderId = async (
  orderId,
  fetchFunction = firebaseApp.getApprovalsFromOrderId
) => {
  const approvalSnap = await fetchFunction(orderId).get();

  const approvals = [];

  approvalSnap.forEach((doc) => {
    approvals.push({
      ...doc.data(),
      id: doc.id,
    });
  });

  return approvals;
};
