import React, { useState } from 'react';
import { Card } from 'reactstrap';
import { NavLink } from 'react-router-dom';

import RequestReset from '../../components/auth/RequestReset';
import SuccessReset from '../../components/auth/SuccessReset';

export default function ForgotPassword({}) {
  const [step, setStep] = useState('request');

  return (
    <Card className="auth-card">
      <NavLink to={`/`} className="black">
        <span className="logo-single mb-4" />
      </NavLink>
      <div className="form-side">
        {step === 'request' && <RequestReset setStep={setStep} />}
        {step === 'success' && <SuccessReset />}
      </div>
    </Card>
  );
}
