import LoadingFlight from '../../components/search/LoadingFlight';

export default function FlightLoading() {
  return (
    <div>
      <LoadingFlight />
      <LoadingFlight />
      <LoadingFlight />
      <LoadingFlight />
    </div>
  );
}
