import React, { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Button, CardBody, Collapse, Label, Row } from 'reactstrap';
import firebaseApp from '../../services/Firebase';
import { Colxx } from '../../components/common/CustomBootstrap';
import SwitchItem from '../../components/SwitchItem';
import Switch from 'rc-switch';
import { OrderTypes } from '../../constants/orderTypes';
import debounce from 'lodash/debounce';

import Currencies from '../../constants/Currencies';
import { ReactComponent as TravelImg } from '../../assets/img/config/travels.svg';
import { getUserNationality } from '../../utils/nationality';
import { getCountriesAsValueLabel } from '../../constants/countries';
import { getOrganizationConfig } from '../../utils/organization';
import FixedSelect from '../../components/common/FixedSelect';
import { AllowedOrganizations } from 'src/portao3-legacy/constants/search/car';

const OPTIONS = ['organization', 'personal'];

const checkBoolean = (value) => (value === undefined ? true : value);

const Countries = getCountriesAsValueLabel();

export default function TravelConfigDataListView() {
  const { organization } = useSelector(({ auth }) => auth);
  const { messages } = useIntl();

  // States
  const initialState = useMemo(
    () => getOrganizationConfig(organization.config, 'travels'),
    []
  );

  const [travels, setTravels] = useState(initialState);

  const [showProducts, setShowProducts] = useState(false);
  const [showCurrency, setShowCurrency] = useState(false);
  const [showNationality, setShowNationality] = useState(false);

  const [allowedTypes, setAllowedProducts] = useState(() => {
    const allowedTypes = getOrganizationConfig(
      organization.config,
      'allowedTravelTypes'
    );

    return {
      [OrderTypes.FLIGHT]: checkBoolean(allowedTypes[OrderTypes.FLIGHT]),
      [OrderTypes.HOTEL]: checkBoolean(allowedTypes[OrderTypes.HOTEL]),
      [OrderTypes.CAR]: checkBoolean(allowedTypes[OrderTypes.CAR]),
      [OrderTypes.BUS]: checkBoolean(allowedTypes[OrderTypes.BUS]),
      [OrderTypes.OFFICE]: checkBoolean(allowedTypes[OrderTypes.OFFICE]),
    };
  });

  const currency = useMemo(() => {
    const currency = getOrganizationConfig(organization.config, 'currency');
    return Currencies.find(({ value }) => value === currency);
  }, []);

  const nationality = useMemo(() => {
    const nationality = getUserNationality({ organization });
    return Countries.find(({ value }) => value === nationality);
  }, []);

  // Functions
  const saveAllowedProducts = useCallback(
    debounce(async (allowedTypes) => {
      try {
        await firebaseApp.updateAllowedTravelTypes(
          organization.id,
          allowedTypes
        );
        console.debug('Allowed travel types updated!');
      } catch (err) {
        console.error('Unable to update allowed travel types', err);
      }
    }, 1000),
    []
  );

  const toggleTravel = async (name) => {
    try {
      setTravels({
        ...travels,
        [name]: !travels[name],
      });

      await firebaseApp.updateTravelsConfig(organization.id, {
        [`config.travels.${name}`]: !travels[name],
      });
    } catch (err) {
      console.error(err);
    }
  };

  const toggleShowProduct = () => setShowProducts((value) => !value);
  const toggleShowCurrency = () => setShowCurrency((value) => !value);
  const toggleShowNationality = () => setShowNationality((value) => !value);

  const toggleOption = (key, value) => {
    const newAllowedTypes = {
      ...allowedTypes,
      [key]: value,
    };

    // Prevent all from being false
    if (Object.keys(newAllowedTypes).some((key) => newAllowedTypes[key])) {
      setAllowedProducts(newAllowedTypes);
      saveAllowedProducts(newAllowedTypes);
    }
  };

  const onCurrencyChange = async (currency) => {
    try {
      await firebaseApp.updateConfigField(
        organization.id,
        'config.currency',
        currency.value
      );
    } catch (err) {
      console.error('Unable to set organization currency', err);
    }
  };

  const onNationalityChange = async (nationality) => {
    try {
      await firebaseApp.updateConfigField(
        organization.id,
        'nationality',
        nationality.value
      );
    } catch (err) {
      console.error('Unable to set organization nationality', err);
    }
  };

  // Render
  return (
    <Row className="travel-data-list-view d-flex justify-content-between mt-5 ml-3 mb-5 pb-3 w-100">
      <Colxx
        xxs="12"
        md="5"
        className="d-flex justify-content-center align-items-start"
      >
        <TravelImg width={'85%'} />
      </Colxx>
      <Colxx xxs="12" md="7" className="pl-3">
        <p className="text-one w-70 slide-left font-weight-medium">
          {messages['admin.settings.travels.description']}
        </p>
        <div
          className="slide-left mt-4 mb-5"
          style={{ animationDelay: '25ms' }}
        >
          {OPTIONS.map((option) => (
            <SwitchItem
              title={messages[`admin.settings.travels.${option}-travel`]}
              key={option}
              done={travels[option]}
              onChange={() => toggleTravel(option)}
            />
          ))}
        </div>

        <div
          className="border mt-4 slide-left"
          style={{ animationDelay: '50ms' }}
        >
          <Button
            color="link"
            onClick={toggleShowProduct}
            aria-expanded={showProducts}
          >
            {messages['admin.travels.advanced_options.products']}
          </Button>
          <Collapse isOpen={showProducts}>
            <Row>
              <Colxx sm="12">
                <CardBody>
                  <p className="text-one w-70 mb-2">
                    {messages['admin.travels.permissions.description']}
                  </p>
                  <p className="text-small w-70 text-muted">
                    {messages['admin.travels.permissions.description.extra']}
                  </p>
                  <div className="mt-4 mb-5">
                    {Object.keys(allowedTypes).map((key) => {
                      if (key === OrderTypes.CAR) {
                        if (AllowedOrganizations.includes(organization.id)) {
                          return (
                            <Label className="d-block mb-3" key={key}>
                              <Switch
                                checked={allowedTypes[key]}
                                onChange={(value) => toggleOption(key, value)}
                                className="mr-2 custom-switch"
                              />
                              {messages[`travel.menu.${key}`]}
                            </Label>
                          );
                        } else {
                          return null;
                        }
                      }

                      return (
                        <Label className="d-block mb-3" key={key}>
                          <Switch
                            checked={allowedTypes[key]}
                            onChange={(value) => toggleOption(key, value)}
                            className="mr-2 custom-switch"
                          />
                          {messages[`travel.menu.${key}`]}
                        </Label>
                      );
                    })}
                  </div>
                </CardBody>
              </Colxx>
            </Row>
          </Collapse>
        </div>

        <div
          className="border mt-4 slide-left"
          style={{ animationDelay: '50ms' }}
        >
          <Button
            color="link"
            onClick={toggleShowCurrency}
            aria-expanded={showCurrency}
          >
            {messages['admin.travels.advanced_options.currency']}
          </Button>
          <Collapse isOpen={showCurrency}>
            <Row>
              <Colxx sm="12">
                <CardBody>
                  <p className="text-one w-70 mb-2">
                    {
                      messages[
                        'admin.travels.advanced_options.currency.description'
                      ]
                    }
                  </p>
                  <div className="mt-4">
                    <FixedSelect
                      className="react-select mt-1"
                      classNamePrefix="react-select"
                      isClearable={false}
                      placeholder=""
                      defaultValue={currency}
                      options={Currencies}
                      onChange={onCurrencyChange}
                      maxMenuHeight={150}
                    />
                  </div>
                </CardBody>
              </Colxx>
            </Row>
          </Collapse>
        </div>

        <div
          className="border mt-4 slide-left"
          style={{ animationDelay: '50ms' }}
        >
          <Button
            color="link"
            onClick={toggleShowNationality}
            aria-expanded={showNationality}
          >
            {messages['admin.travels.advanced_options.nationality']}
          </Button>
          <Collapse isOpen={showNationality}>
            <Row>
              <Colxx sm="12">
                <CardBody>
                  <p className="text-one w-70 mb-2">
                    {
                      messages[
                        'admin.travels.advanced_options.nationality.description'
                      ]
                    }
                  </p>
                  <div className="mt-4">
                    <FixedSelect
                      className="react-select mt-1"
                      classNamePrefix="react-select"
                      isClearable={false}
                      placeholder=""
                      options={Countries}
                      defaultValue={nationality}
                      onChange={onNationalityChange}
                      maxMenuHeight={150}
                    />
                  </div>
                </CardBody>
              </Colxx>
            </Row>
          </Collapse>
        </div>
      </Colxx>
    </Row>
  );
}
