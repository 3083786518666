import React, { useMemo } from 'react';
import { Row } from 'reactstrap';
import { Colxx } from '../common/CustomBootstrap';
import map from 'lodash/map';
import moment from 'moment';
import { registeredAirlines } from '../../constants/travelValues';
import { useIntl } from 'react-intl';

import AirportName from './AirportName';
import CityName from './CityName';
import Duration from './Duration';
import AirportWait from './AirportWait';
import Baggages from './Baggages';

export default function JourneyLarge({
  journey,
  index,
  params,
  flights,
  bag,
  seat,
  openBooking,
}) {
  const { messages } = useIntl();

  const luggage = useMemo(() => {
    let luggage = {
      quantity: 0,
      weight: 23,
    };

    flights.forEach((flight) => {
      if (flight.fare.baggage > luggage.quantity)
        luggage = {
          quantity: flight.fare.baggage,
          weight: flight.fare.baggageWeight || 23,
        };
    });

    if (!luggage.quantity && bag) {
      const flightId = flights[0].id;
      const ancillary = bag.items.find((item) =>
        item.relatedTo.includes(flightId)
      );

      if (ancillary) {
        luggage = {
          quantity: ancillary.quantity,
          weight: ancillary.details.weight,
        };
      }
    }

    return luggage;
  }, [bag, flights]);

  return (
    <div className="journey pb-5 mb-5 mt-1">
      {map(flights, (flight, i) => {
        var nextFlight = flights[i + 1];
        const flightSeat = seat ? seat[flight.id] : null;
        const flightAirline = flight.airlineOperating || flight.airline;
        const classInfo =
          flight.fare.class || flight.fare.base
            ? `${flight.fare.base} (${flight.fare.class})`
            : '';

        return (
          <div key={`journey-${index}-flight-${i}`}>
            <div className="d-flex justify-content-between">
              <div className="d-flex justify-content-start">
                {i === 0 && (
                  <div className="journey-title d-flex align-items-center">
                    <img
                      src="/assets/img/icons/search_icon.svg"
                      alt={messages['alts.components.flight.journey.journey']}
                    />
                    <h3>
                      <CityName
                        station={journey.departureStation}
                        params={params}
                      />{' '}
                      •{' '}
                      <CityName
                        station={journey.arrivalStation}
                        params={params}
                      />
                    </h3>
                  </div>
                )}
              </div>
              <Baggages index={i} flight={flight} luggage={luggage} />
            </div>
            <Row className="px-3 mt-3 origin">
              <Colxx
                xxs="12"
                className="d-flex align-items-center justify-content-between"
              >
                <div className="d-flex align-items-center">
                  {registeredAirlines[flightAirline] && (
                    <div
                      className="carrier"
                      style={{
                        backgroundImage: `url(${registeredAirlines[flightAirline].logo})`,
                      }}
                    />
                  )}
                </div>
                <div className="text-right">
                  {openBooking ? null : (
                    <p className="title m-0 p-0">
                      {`${messages['refund.category.flight']} ${flightAirline} ${flight.flightNumber} / ${flight.fare.familyName}`}
                    </p>
                  )}
                  {flightSeat ? (
                    <p className="title m-0 p-0 font-weight-normal">{`${messages['flight-alteration.seat-sec.title']} ${flightSeat.details.row}${flightSeat.details.column}`}</p>
                  ) : null}
                  {classInfo ? (
                    <p className="title m-0 p-0 fs-0-7rem">{classInfo}</p>
                  ) : null}
                </div>
              </Colxx>
            </Row>
            <Row className="px-3 mt-3">
              <Colxx xxs="4" className="origin">
                <p>
                  {moment(flight.departureDateTime, [
                    'DD/MM/YYYY HH:mm',
                    'YYYY-MM-DD[T]HH:mm:ss',
                  ]).format('ddd, DD MMM YYYY')}
                </p>
                <p className="highlight">
                  {moment(flight.departureDateTime, [
                    'DD/MM/YYYY HH:mm',
                    'YYYY-MM-DD[T]HH:mm:ss',
                  ]).format('HH:mm')}
                </p>
                <p className="mt-2">
                  <CityName station={flight.departureStation} params={params} />
                </p>
                <p className="highlight">
                  {flight.departureStation?.name
                    ? flight.departureStation.name
                    : flight.departureStation}
                </p>
                <p>
                  <AirportName
                    station={flight.departureStation}
                    params={params}
                  />
                </p>
              </Colxx>
              <Colxx xxs="4" className="duration">
                <img
                  src="/assets/img/icons/search_icon.svg"
                  alt={messages['alts.components.flight.journey.duration']}
                />
                {openBooking ? null : (
                  <>
                    {messages['travel.duration']}:{' '}
                    <Duration duration={flight.duration} />
                  </>
                )}
              </Colxx>
              <Colxx xxs="4" className="destination">
                <p>
                  {moment(flight.arrivalDateTime, [
                    'DD/MM/YYYY HH:mm',
                    'YYYY-MM-DD[T]HH:mm:ss',
                  ]).format('ddd, DD MMM YYYY')}
                </p>
                <p className="highlight">
                  {openBooking
                    ? '--:--'
                    : moment(flight.arrivalDateTime, [
                        'DD/MM/YYYY HH:mm',
                        'YYYY-MM-DD[T]HH:mm:ss',
                      ]).format('HH:mm')}
                </p>
                <p className="mt-2">
                  <CityName station={flight.arrivalStation} params={params} />
                </p>
                <p className="highlight">
                  {flight.arrivalStation?.name
                    ? flight.arrivalStation.name
                    : flight.arrivalStation}
                </p>
                <p>
                  <AirportName
                    station={flight.arrivalStation}
                    params={params}
                  />
                </p>
              </Colxx>
            </Row>

            {nextFlight && (
              <AirportWait
                startTime={flight.arrivalDateTime}
                finishTime={nextFlight.departureDateTime}
                station={flight.arrivalStation}
                params={params}
              />
            )}
          </div>
        );
      })}
    </div>
  );
}
