import React from 'react';
import { useState } from 'react';
import { useMemo } from 'react';
import { expenseTypes } from '../../../constants/refundTypes';
import ExpenseTypeSwitch from '../../expensesTimeline/ExpenseTypeSwitch';
import ReceiptExpense from './ReceiptExpense';
import PerfectScrollbar from 'react-perfect-scrollbar';
import classNames from 'classnames';
import KmExpense from './KmExpense';
import SavingScreen from '../../common/SavingScreen';

const ExpenseComponents = {
  [expenseTypes.ITEMS]: ReceiptExpense,
  [expenseTypes.KM]: KmExpense,
};

export default function ExpenseItem({
  budget,
  item,
  toggle,
  editable,
  updateTotal = () => {},
  canCreateItem,
}) {
  const [type, setType] = useState(item.type || expenseTypes.ITEMS);
  const [block, setBlock] = useState(false);
  const [atBottom, setAtBottom] = useState(false);

  const isNew = useMemo(() => !item.id, [item]);
  const Component = useMemo(
    () => ExpenseComponents[type] || ReceiptExpense,
    [type]
  );

  // Functions
  const perfomAction = async (func, doToggle = true) => {
    try {
      setBlock(true);
      await func();
      if (doToggle) toggle();
    } catch (err) {
      console.error(err);
    } finally {
      setBlock(false);
    }
  };

  // Render
  return (
    <>
      <SavingScreen show={block} />
      <PerfectScrollbar
        options={{
          suppressScrollX: true,
          wheelPropagation: false,
        }}
        className={classNames('expense-item pb-4 mr-0 pr-0', { block })}
        style={{ position: 'static' }}
        onScrollUp={() => atBottom && setAtBottom(false)}
        onYReachEnd={() => !atBottom && setAtBottom(true)}
      >
        <div className="ml-4 mb-3" style={{ height: '2rem' }}>
          <ExpenseTypeSwitch
            isNew={isNew}
            expenseType={type}
            setExpenseType={setType}
          />
        </div>
        {Component ? (
          <Component
            budget={budget}
            item={item}
            toggle={toggle}
            isNew={isNew}
            editable={editable}
            performAction={perfomAction}
            updateTotal={updateTotal}
            atBottom={atBottom}
            canCreateItem={canCreateItem}
          />
        ) : null}
      </PerfectScrollbar>
    </>
  );
}
