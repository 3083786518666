import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { OrderTypes } from '../../constants/orderTypes';
import firebaseApp from '../../services/Firebase';
import { getDocumentsFromCollection } from '../../utils/firebase';
import CustomCheckbox from '../common/CustomCheckbox';

export default function CreditSelectionCheckbox({
  order,
  selectedCredit,
  setSelectedCredit,
}) {
  const { messages } = useIntl();
  const [credit, setCredit] = useState({});
  // Effects
  useEffect(() => {
    order.buyWithCredit && setSelectedCredit(order.buyWithCredit);
    if (order?.type === OrderTypes.FLIGHT) {
      const loadCredit = async () => {
        try {
          const credit = await getDocumentsFromCollection(() =>
            firebaseApp
              .getCreditsFromOrganizationAndUser(
                order.organizationId,
                order.passengerId,
                order.provider.validatingCarrier
              )
              .limit(1)
              .get()
          );

          setCredit(
            credit.sort((a, b) => a?.creditCreatedAt - b?.creditCreatedAt)[0] ||
              {}
          );
        } catch (err) {
          console.error('Unable to fetch credits', err);
        }
      };

      loadCredit();
    }
  }, []);

  // Functions
  const toggleCredit = () => {
    setSelectedCredit(selectedCredit ? null : credit.id);
  };

  // Render
  return (
    !!credit.id && (
      <label className="d-flex align-items-center" onClick={toggleCredit}>
        <div className="pointer" style={{ fontSize: '1.1rem' }}>
          <CustomCheckbox
            className="pointer-none"
            checked={selectedCredit}
            white
          />
        </div>
        <span className="ml-1 fs-0-7rem font-weight-medium lh-1">
          {messages['travel.order.use-credits']}{' '}
          <Link
            className="text-white font-weight-semibold"
            to={`/travel/orders/${credit.id}`}
          >
            {credit.bookingCode}
          </Link>
        </span>
      </label>
    )
  );
}
