import React, { useMemo, useState } from 'react';
import { Row, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { Colxx } from '../../components/common/CustomBootstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import minBy from 'lodash/minBy';
import moment from 'moment';

import { stringToMoney } from '../../utils/money';

import ReservationParams from './ReservationParams/ReservationParams';
import CarAmenities from '../../components/car/CarAmenities';
import CarPhotos from '../../components/car/CarPhotos';
import CarCarbonDetail from '../../components/car/CarCarbonDetail';

import { useIntl } from 'react-intl';
import ReservationButton from './ReservationButton';
import { useSelector } from 'react-redux';
import useIsReservationValid from '../../hooks/useIsReservationValid';
import CurrencyWrapper from '../../components/common/CurrencyWrapper';
import OrderStructures from '../../constants/orderStructure';

function CarTotal({
  item,
  createReservation,
  canReservate = true,
  orderStructure,
  reservationParams,
  serviceCharge = 0,
}) {
  const { messages, formatMessage } = useIntl();

  const additionalInfo = useMemo(
    () =>
      orderStructure === OrderStructures.PERSONAL
        ? formatMessage(
            {
              id: 'containers.search.flight-modal.taxes',
            },
            {
              taxes: stringToMoney(serviceCharge, 0, item.fare.currency),
            }
          )
        : '',
    [item, orderStructure, serviceCharge]
  );

  const hasFreeCancellation = item.cancellation.length > 0;
  let freeCancellationDate = null;
  if (hasFreeCancellation) {
    freeCancellationDate = minBy(item.cancellation, (c) => {
      return moment(c.startDate).unix();
    }).startDate;
  }

  const { isValid, disabled, title } = useIsReservationValid({
    orderStructure,
    reservationParams,
  });

  return (
    <ModalFooter>
      <div>
        <div className="text mt-3 pr-3">
          <span>{messages['general.total']}</span>
          <CurrencyWrapper
            value={item.fare.total}
            tooltipFill="white"
            decimalPlaces={0}
          >
            {({ formattedValue }) => formattedValue}
          </CurrencyWrapper>
          {additionalInfo ? (
            <span
              style={{ fontSize: '0.5em' }}
              className="font-primary text-theme-3 mt-1 font-weight-normal"
            >
              {additionalInfo}
            </span>
          ) : null}
          <hr className="my-3" />
        </div>
        <div className="description pr-3">
          {hasFreeCancellation && (
            <p className="color-alert">
              {messages['travel.order.cancellation.date']}{' '}
              {moment(freeCancellationDate).format('DD/MM/YYYY')}
            </p>
          )}

          <p className="mb-0 text-small">
            {messages['containers.search.modal.taxes']}
          </p>
        </div>

        <ReservationButton
          onClick={createReservation}
          disabled={!canReservate || disabled}
          isValid={isValid}
          title={title}
        />
      </div>
    </ModalFooter>
  );
}

export default function CarModal({ toggleModal, item, reserveItem, search }) {
  const { messages } = useIntl();
  const { user, serviceCharge } = useSelector(({ auth }) => ({
    serviceCharge: parseFloat(auth.organization.fee?.car || 0),
    user: auth.user,
  }));

  // State
  const [reservationParams, setReservationParams] = useState({});
  const [orderStructure, setStructure] = useState(OrderStructures.ORGANIZATION);

  // Reservation
  const createReservation = () => {
    if (orderStructure) {
      reserveItem({
        reservationParams,
        item: item,
      });
    }
  };

  // Render
  return (
    <Modal isOpen={true} toggle={toggleModal} className="reservation">
      <ModalBody className="hotel">
        <Row>
          <Colxx xxs="5" className="pr-0">
            <CarPhotos car={item} />
          </Colxx>
          <Colxx xxs="7" className="pl-0">
            <div className="hotel-content">
              <PerfectScrollbar
                options={{
                  suppressScrollX: true,
                  wheelPropagation: false,
                }}
              >
                <h3 className="hotel-title ml-3">{item.name}</h3>

                <p className="text-muted-darker ml-3 mb-0">
                  {item.amenities.type}
                </p>

                <div className="text-muted-darker ml-3 address">
                  {item.pickUp.code !== item.dropOff.code ? (
                    <div className="glyph-icon iconsminds-right" />
                  ) : (
                    <div className="glyph-icon iconsminds-sync" />
                  )}
                  {item.pickUp.address.toLowerCase()}
                </div>

                {item.pickUp.code !== item.dropOff.code && (
                  <div className="text-muted-darker ml-3 address">
                    <div className="glyph-icon iconsminds-left" />
                    {item.dropOff.address.toLowerCase()}
                  </div>
                )}

                <CarAmenities car={item} />

                <ReservationParams
                  setReservationParams={setReservationParams}
                  orderStructure={orderStructure}
                  setStructure={setStructure}
                  nationality={user.nationality}
                />

                <h5 className="mt-5 ml-3">
                  {messages['components.timeline.modal.conditions']}
                </h5>
                <div
                  className="text-muted-darker others ml-3 mr-3 mb-5"
                  dangerouslySetInnerHTML={{
                    __html: item.conditions,
                  }}
                />

                <CarCarbonDetail
                  sipp={item.amenities.sipp}
                  days={moment(search.inbound_date).diff(
                    moment(search.outbound_date),
                    'days',
                    true
                  )}
                />
              </PerfectScrollbar>
            </div>
          </Colxx>
        </Row>
      </ModalBody>

      <CarTotal
        item={item}
        createReservation={createReservation}
        canReservate={orderStructure}
        orderStructure={orderStructure}
        reservationParams={reservationParams}
        serviceCharge={serviceCharge}
      />
    </Modal>
  );
}
