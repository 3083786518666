import React from 'react';
import ContentLoader from 'react-content-loader';
import { useIntl } from 'react-intl';

export default function LoadingAlternative(props) {
  const { messages } = useIntl();

  return (
    <ContentLoader
      title={messages['order.alternative.loading']}
      speed={2}
      viewBox="0 0 220 270"
      backgroundColor="#DDDDDD"
      foregroundOpacity="white"
      className="alternative-card"
      {...props}
    >
      <rect x="0" y="0" rx="0" ry="0" width="220px" height="270px" />
    </ContentLoader>
  );
}
