const getDates = (budgets) => {
  return [...new Set(budgets.map(({ startDate }) => startDate))].sort((a, b) =>
    a < b ? 0 : 1
  );
};

export const getGraphLabelAndValues = (
  budgets,
  sortedData,
  sumData = false
) => {
  const labels = getDates(budgets);
  const indexes = labels.reduce((obj, element, index) => {
    obj[element] = index;
    return obj;
  }, {});
  const allData = {};

  Object.keys(sortedData).forEach((type) => {
    const budgets = sortedData[type].budgets;
    let values = new Array(labels.length).fill(0);

    Object.keys(budgets).forEach((date) => {
      values[indexes[date]] = budgets[date].reduce(
        (sum, budget) => sum + budget.fare.total,
        0
      );
    });

    if (sumData) {
      let sum = 0;

      values = values.map((value) => {
        sum += value;
        return sum;
      });
    }

    allData[type] = values;
  });

  return {
    ...allData,
    labels: labels.map((label) => {
      const [_, month, day] = label.split('-');
      return `${day}/${month}`;
    }),
  };
};

export const sortByFareBasedOnProperty = (
  items,
  getProperty = () => {},
  limit
) => {
  const values = {};

  items.forEach((item) => {
    const value = getProperty(item);

    if (value) {
      if (value in values) {
        const obj = values[value];
        obj.total += parseFloat(item.fare.total || 0);
        obj.quantity += 1;
        obj.items.push(item);
      } else
        values[value] = {
          value,
          total: parseFloat(item.fare.total || 0),
          quantity: 1,
          items: [item],
        };
    }
  });

  const finalValues = Object.values(values)
    .filter((value) => value.total)
    .sort((a, b) => b.total - a.total);

  return limit ? finalValues.slice(0, limit) : finalValues;
};
