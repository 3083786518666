import moment from 'moment';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { ReactComponent as ArrowIcon } from '../../../assets/img/icons/oneway.svg';

export default function ItemDocumentation({ id, items: _items }) {
  const { messages } = useIntl();

  // States
  const { description, items } = useMemo(() => {
    const today = moment();

    const resultBase = messages['budget-name-tooltip.days.result-base'];

    return {
      description: messages[`budget-name-tooltip.${id}.description`],
      items: _items.map(({ title, result }) => ({
        title: messages[`${title}.title`],
        result: `${resultBase} ${today.format(result)}`,
      })),
    };
  }, [_items]);

  // Render
  return (
    <section className="item-documentation">
      <p className="m-0 p-0 mb-3 fs-0-9rem">{description}</p>

      <div className="mt-4">
        {items.map(({ title, result }, index) => (
          <div className="flex-center my-2" key={`item-${index}`}>
            <span className="font-primary text-primary font-weight-medium fs-0-9rem">
              {title}
            </span>
            <ArrowIcon
              width="1.25rem"
              height="1.25rem"
              className="text-primary mx-3"
            />
            <span className="font-primary text-primary font-weight-medium fs-0-9rem">
              {result}
            </span>
          </div>
        ))}
      </div>
    </section>
  );
}
