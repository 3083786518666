import React from 'react';

export default function TagItem({ tag, className = '' }) {
  return (
    <div
      className={`tag-item bg-primary px-2 py-1 mr-2 ${className}`}
      style={{ borderRadius: 3 }}
    >
      {tag.label}
    </div>
  );
}
