import React, { useMemo } from 'react';
import { hexToRgb } from '../../../utils/colors';
import { formatCardNumber } from '../../../utils/creditCard';
import LoadingDetailed from './LoadingDetailed';
import CopiableTextWrapper from '../../../wrappers/CopiableTextWrapper';

export default function DetailedCardInfo({
  card,
  color,
  BankIcon,
  details,
  loadingDetails,
}) {
  const {
    cardNumber,
    rawCardNumber,
    cvv,
    expirationDate,
    foregroundColor,
    backgroundColor,
  } = useMemo(() => {
    const { r, g, b } = hexToRgb(color);
    const foregroundColor = `rgba(${r}, ${g}, ${b}, 0.4)`;
    const backgroundColor = `rgba(${r}, ${g}, ${b}, 0.1)`;

    if (details) {
      return {
        cardNumber: formatCardNumber(details.number),
        rawCardNumber: details.number,
        cvv: details.cvv,
        expirationDate: details.expiration,
        foregroundColor,
        backgroundColor,
      };
    } else
      return {
        foregroundColor,
        backgroundColor,
      };
  }, [details]);

  if (loadingDetails)
    return (
      <LoadingDetailed
        foregroundColor={foregroundColor}
        backgroundColor={backgroundColor}
      />
    );

  return (
    <div className="detailed-card-info">
      <CopiableTextWrapper text={rawCardNumber}>
        <h3 className="last-digits full p-0 m-0 font-primary" style={{ color }}>
          {cardNumber}
        </h3>
      </CopiableTextWrapper>

      <div className="details d-flex justify-content-between align-items-center mt-2">
        <div className="flex-center">
          <div className="detail-title" style={{ color }}>
            <span>Valid</span>
            <span>Thru</span>
          </div>
          <CopiableTextWrapper text={expirationDate}>
            <h3
              className="detail text-uppercase p-0 m-0 font-primary"
              style={{ color }}
            >
              {expirationDate}
            </h3>
          </CopiableTextWrapper>
        </div>

        <div className="flex-center">
          <div className="detail-title" style={{ color }}>
            <span>Security</span>
            <span>Code</span>
          </div>
          <CopiableTextWrapper text={cvv}>
            <h3
              className="detail text-uppercase p-0 m-0 font-primary"
              style={{ color }}
            >
              {cvv}
            </h3>
          </CopiableTextWrapper>
        </div>
      </div>
    </div>
  );
}
