import React, { useEffect, useRef, useState } from 'react';
import { Chart, LineController, registerables } from 'chart.js';
import sum from 'lodash/sum';

import { smallLineChartOptions } from './config';

class CustomLineController extends LineController {
  draw(ease) {
    super.draw(this, ease);

    const {
      chart: { ctx, tooltip, scales },
    } = this;

    if (tooltip._active && tooltip._active[0]) {
      const activePoint = tooltip._active[0];

      const { x } = activePoint.element;
      const { top: topY, bottom: bottomY } = scales.y;
      ctx.save();
      ctx.beginPath();
      ctx.moveTo(x, topY);
      ctx.lineTo(x, bottomY);
      ctx.lineWidth = 1;
      ctx.strokeStyle = 'rgba(0,0,0,0.1)';
      ctx.stroke();
      ctx.restore();
    }
  }
}

CustomLineController.id = 'lineWithLine';
CustomLineController.defaults = LineController.defaults;

Chart.register(CustomLineController, ...registerables);

export default function Scatter({ data, callbackFormat }) {
  const chartContainer = useRef(null);
  const chartInstance = useRef(null);
  const [currentValue, setCurrentValue] = useState('');
  const [currentLabel, setCurrentLabel] = useState('');

  const changeState = (yLabel, xLabel) => {
    setCurrentValue(yLabel);
    setCurrentLabel(xLabel);
  };

  useEffect(() => {
    if (chartContainer && chartContainer.current) {
      if (chartInstance.current) chartInstance.current.destroy();

      const context = chartContainer.current.getContext('2d');

      const newChartInstance = new Chart(context, {
        type: 'lineWithLine',
        options: {
          ...smallLineChartOptions,
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              enabled: false,
              intersect: false,
              external: ({ tooltip }) => {
                if (tooltip && tooltip.dataPoints) {
                  const { formattedValue: yLabel, label: xLabel } =
                    tooltip.dataPoints[0];
                  const label = tooltip.body[0].lines[0].split(':')[0];
                  changeState(
                    `${callbackFormat ? callbackFormat(yLabel) : yLabel}`,
                    `${label}: ${xLabel}`
                  );
                }
              },
            },
          },
        },
        plugins: [
          {
            afterInit(chart, _options) {
              const yLabel = sum(chart.data.datasets[0].data);
              const { label } = chart.data.datasets[0];
              changeState(
                `${callbackFormat ? callbackFormat(yLabel) : yLabel}`,
                `${label}`
              );
            },
            afterEvent: function (chart, args, options) {
              if (args.event.type === 'mouseout') {
                const yLabel = sum(chart.data.datasets[0].data);
                const { label } = chart.data.datasets[0];
                changeState(
                  `${callbackFormat ? callbackFormat(yLabel) : yLabel}`,
                  `${label}`
                );
              }
            },
          },
        ],
        data,
      });

      chartInstance.current = newChartInstance;
    }
  }, [chartContainer, data]);

  return (
    <>
      <div className="w-100">
        <p className="lead color-theme-1 mb-1 value">{currentValue}</p>
        <p className="mb-0 label text-small ml-1">{currentLabel}</p>
      </div>
      <div className="chart w-100">
        <canvas ref={chartContainer} />
      </div>
    </>
  );
}
