import { STORE_BULK_DATA, CLEAR_BULK_DATA, SET_BULK_LOADING } from '../actions';

const INIT_STATE = {};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case STORE_BULK_DATA: {
      return { ...state, ...action.payload };
    }

    case SET_BULK_LOADING: {
      const { id, loading = false } = action.payload;

      if (id in state) {
        return { ...state, [id]: { ...state[id], loading } };
      } else return { ...state };
    }

    case CLEAR_BULK_DATA: {
      const id = action.payload;
      const { [id]: item, ...others } = state;
      return others;
    }

    default:
      return { ...state };
  }
};
