import { parseJSONFromLocalStorage } from '../../utils/redux';
import { SET_LOCATION } from '../actions';

const INIT_STATE = {
  locations: parseJSONFromLocalStorage('organization'),
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_LOCATION:
      const newLocations = {
        ...state.locations,
        [action.id]: action.payload,
      };
      localStorage.setItem('locations', JSON.stringify(newLocations));
      return { ...newLocations };

    default:
      return { ...state };
  }
};
