import React, { Component } from 'react';
import { Card, Badge } from 'reactstrap';
import times from 'lodash/times';
import find from 'lodash/find';
import classnames from 'classnames';

import { imageUrl } from '../../utils/image';
import {
  registeredAirlines,
  registeredHotels,
} from '../../constants/travelValues';
import { stringToMoney } from '../../utils/money';
import { injectIntl } from 'react-intl';

class PackageItem extends Component {
  render() {
    const {
      item,
      params,
      index,
      selectionIndex,
      intl: { messages },
    } = this.props;

    let image = '/assets/img/default-flight.png';
    if (item.hotel) {
      image = imageUrl('hotel', item.hotel.hotel.mainPhoto);
    }

    const packageClassName = classnames({
      'search-card package mt-5': true,
      active: index === selectionIndex,
    });

    return (
      <Card
        className={packageClassName}
        onClick={() => {
          this.props.toggleSelection(this.props.index);
        }}
      >
        <div style={{ backgroundImage: `url(${image})` }}>
          <div className="gradient">
            <div className="logos">
              {registeredAirlines[item.flight.flight.airline] && (
                <div
                  className="carrier"
                  style={{
                    backgroundImage: `url(${
                      registeredAirlines[item.flight.flight.airline].logoBlack
                    })`,
                  }}
                />
              )}

              {registeredHotels[item.hotel.hotel.chain.name] &&
                item.hotel.hotel.chain.name !== 'Independent' && (
                  <img
                    src={
                      registeredHotels[item.hotel.hotel.chain.name].logoBlack
                    }
                    alt={item.hotel.hotel.chain.name}
                    className="hotel"
                  />
                )}
            </div>
            <div className="content">
              <img
                src="/assets/img/icons/timeline_hotel_white.svg"
                alt={messages['travel.menu.hotel']}
                className="mr-1"
              />
              {times(item.hotel.hotel.category, (index) => {
                return (
                  <img
                    key={`hotel-${item.hotelId}-category-${index}`}
                    src="/assets/img/hotel-star.svg"
                    className="star mr-1"
                    alt={
                      messages[
                        'alts.components.search.hotel-item.hotel-category'
                      ]
                    }
                  />
                );
              })}
            </div>
            <div className="content">
              <img
                src="/assets/img/icons/timeline_flight_white.svg"
                alt={messages['travel.menu.flight']}
                className="mr-1"
              />
              {/* {moment(item.flight.segments[0].departureDateTime).format("DD/MM HH:mm")}
              {" • "}
              {moment(item.flight.segments[1].departureDateTime).format("DD/MM HH:mm")} */}
              {item.isCheapestFlight && (
                <Badge color="light">
                  {messages['containers.search.package-items.cheaper-option']}
                </Badge>
              )}
              {item.isLongestFlight && (
                <Badge color="light">
                  {messages['containers.search.package-items.stay-longer']}
                </Badge>
              )}
              {item.isEarliestFlight && (
                <Badge color="light">
                  {messages['containers.search.package-items.arive-early']}
                </Badge>
              )}
            </div>

            <h3>
              {params?.flight?.airports
                ? find(params.flight.airports, {
                    iata: item.flight.flight.journeys[0].arrivalStation,
                  }).city
                : item.flight.flight.journeys[0].arrivalStation}
            </h3>
            <p className="price">
              {stringToMoney(
                item.flight.flight.fare.total + item.hotel.fare.total,
                0,
                item.flight.flight.fare.currency
              )}
            </p>
          </div>
        </div>
      </Card>
    );
  }
}

export default injectIntl(PackageItem);
