import React from 'react';
import { Button, Input, Label } from 'reactstrap';
import IntlMessages from '../../../helpers/IntlMessages';
import classNames from 'classnames';

import { getCountriesAsValueLabel } from '../../../constants/countries';

const AdultValues = [
  {
    value: 1,
    Component: () => <i className="glyph-icon iconsminds-men" />,
  },
  {
    value: 2,
    Component: () => (
      <>
        <i className="glyph-icon iconsminds-men" />
        <i className="glyph-icon iconsminds-men" />
      </>
    ),
  },
  {
    value: 3,
    Component: () => (
      <>
        <i className="glyph-icon iconsminds-men" />
        <i className="glyph-icon iconsminds-men" />
        <i className="glyph-icon iconsminds-men" />
      </>
    ),
  },
  {
    value: 4,
    Component: () => (
      <>
        <i className="glyph-icon iconsminds-men" />
        <i className="glyph-icon iconsminds-men" />
        <i className="glyph-icon iconsminds-men" />
        <i className="glyph-icon iconsminds-men" />
      </>
    ),
  },
];

const Countries = getCountriesAsValueLabel();

export default function HotelFormParams({
  adults = 1,
  setAdults = () => {},
  nationality = '',
  setNationality = () => {},
}) {
  // Render
  return (
    <div className="hotel-form-params">
      <div className="roomItem roomTypeItem mt-2">
        {AdultValues.map(({ value, Component }) => (
          <Button
            key={`adults-${value}`}
            className={classNames({
              selected: adults === value,
            })}
            onClick={() => setAdults(value)}
          >
            <Component />
          </Button>
        ))}
      </div>
      <div className="w-100 mt-3 mb-2">
        <Label
          className="form-group has-top-label position-relative mb-0"
          style={{ top: 0, left: 0 }}
        >
          <Input
            type="select"
            onChange={(e) => setNationality(e.target.value)}
            value={nationality}
          >
            {Countries.map(({ value, label }) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </Input>
          <IntlMessages id="travel.nationality" />
        </Label>
      </div>
    </div>
  );
}
