import moment from 'moment';

import { TCarReservationParams } from '../types';

function makeOrderDetails({
  reservationParams,
  item,
  search,
}: TCarReservationParams) {
  return {
    ...reservationParams,
    fare: item.fare,
    provider: {
      origin: item.origin,
      token: item.token,
    },
    searchCode: search.searchCode,
  };
}

function makeReservations({ item, search }: TCarReservationParams) {
  return [
    {
      amenities: item.amenities,
      dropOffLocation: item.dropOff,
      mainPhoto: item.mainPhoto,
      photos: item.photos,
      cancellation: item.cancellation,
      name: item.name,
      pickUpLocation: item.pickUp,
      rental: item.rental,
      carId: item.carId,
      conditions: item.conditions,
      pickUp: moment(search.outbound_date).format('DD/MM/YYYY HH:mm'),
      dropOff: moment(search.inbound_date).format('DD/MM/YYYY HH:mm'),
      token: item.token,
    },
  ];
}

export function makeCarParams(params: TCarReservationParams) {
  const orderDetails = makeOrderDetails(params);

  const reservations = makeReservations(params);

  return {
    order: orderDetails,
    reservations,
    filterTimestamp: moment(reservations[0].pickUp, 'DD/MM/YYYY')
      .endOf('day')
      .utc()
      .unix(),
  };
}
