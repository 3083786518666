import moment from 'moment';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { HasBudget, RefundTypes } from '../../constants/refundTypes';
import { canCreateRefund } from '../../utils/expenses';
import { getOrganizationConfig } from '../../utils/organization';
import {
  checkRequiredFields,
  getRequiredFields,
} from '../../utils/reservation';

function DefaultBudgetButtons({
  allowRefundCreation,
  isValid,
  isSaving,
  onCreate,
  title,
}) {
  // Render
  return (
    <div className="text order-status">
      <div className="mt-3">
        <Button
          name="create-budget"
          outline
          color="primary"
          className="m-0 mb-3"
          onClick={() => allowRefundCreation && onCreate()}
          disabled={!(isValid && allowRefundCreation) || isSaving}
          title={title}
        >
          {messages['refund.modal.create-refund']}
        </Button>
      </div>
    </div>
  );
}

export default function ModularBudgetCreationStatus({
  budget = {},
  onCreate = () => {},
  isSaving,
  cardType,
  ButtonsComponent = DefaultBudgetButtons,
  ...props
}) {
  const { messages } = useIntl();
  const { organization } = useSelector(({ auth }) => auth);

  // States
  const requiredFields = useMemo(
    () =>
      getRequiredFields(getOrganizationConfig(organization.config, 'required')),
    [organization]
  );

  const isValid = useMemo(
    () =>
      Boolean(
        budget.name &&
          budget.startDate &&
          budget.endDate &&
          (HasBudget.includes(cardType)
            ? budget.budget && budget.category?.value
            : true) &&
          (cardType === RefundTypes.PERSONAL_ACCOUNT ||
            budget.endDate.format('YYYY-MM-DD') >=
              moment().format('YYYY-MM-DD')) &&
          checkRequiredFields(budget, requiredFields)
      ),
    [budget, cardType]
  );

  const allowRefundCreation = useMemo(
    () => canCreateRefund(organization),
    [organization]
  );

  const title = useMemo(
    () =>
      allowRefundCreation
        ? isValid
          ? ''
          : messages['refund.modal.invalid']
        : messages['refund.modal.cannot-create'],
    [allowRefundCreation, isValid]
  );

  // Render
  return (
    <div className="refund-status description h-100 d-flex align-items-center justify-content-center w-100">
      <ButtonsComponent
        budget={budget}
        isValid={isValid}
        isSaving={isSaving}
        allowRefundCreation={allowRefundCreation}
        onCreate={onCreate}
        title={title}
        {...props}
      />
    </div>
  );
}
