import esMessages from '../locales/es_ES';

import { addMissingMessages } from '../locales';

const EsLang = {
  messages: addMissingMessages(esMessages),
  locale: 'es-ES',
  name: 'Español',
};

export default EsLang;
