import classNames from 'classnames';
import React from 'react';

export default function IconsMenu({
  items = [],
  selectedItem,
  className = '',
  horizontal,
  iconSize = 22,
  onSelect = () => {},
  displayLines = true,
}) {
  // Render
  return (
    <div className={classNames('icons-menu', className, { horizontal })}>
      {items.map((item, index) => {
        const { id, Icon } = item;
        const selected = item.id === selectedItem?.id;

        return (
          <React.Fragment key={`item-${id}`}>
            {displayLines && index ? <div className="line" /> : null}
            <div
              className={classNames('menu-item pointer', { selected })}
              onClick={() => onSelect(item, index)}
            >
              <Icon
                className="icon"
                style={{ width: iconSize, height: iconSize }}
              />
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
}
