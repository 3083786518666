import { Component } from 'react';
import ContentLoader from 'react-content-loader';

import { injectIntl } from 'react-intl';

class LoadingFilter extends Component {
  render() {
    const { messages } = this.props.intl;

    return (
      <ContentLoader
        title={messages['travel.search.loading']}
        speed={2}
        width={200}
        height={140}
        viewBox="0 0 200 140"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        className={'mb-4'}
      >
        <circle cx="13" cy="47" r="5" />
        <rect x="30" y="42" rx="1" ry="1" width="100" height="10" />
        <circle cx="13" cy="67" r="5" />
        <rect x="30" y="62" rx="1" ry="1" width="100" height="10" />
        <circle cx="13" cy="87" r="5" />
        <rect x="30" y="82" rx="1" ry="1" width="100" height="10" />
        <circle cx="13" cy="107" r="5" />
        <rect x="30" y="102" rx="1" ry="1" width="100" height="10" />
        <rect x="0" y="0" rx="0" ry="0" width="200" height="30" />
      </ContentLoader>
    );
  }
}

export default injectIntl(LoadingFilter);
