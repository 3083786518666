import {
  TFlightParams,
  TFlightSearchParams,
} from 'src/portao3-legacy/types/orders/flight/flightParams';
import { IComponentFilter } from 'src/portao3-legacy/types/orders/orders';
import SearchFilterWrapper from './SearchFilterWrapper';
import { useMemo } from 'react';
import {
  doesParamHaveItems,
  formatCarriers,
  formatConnections,
  formatContainsParamsWithEvent,
  formatJourneys,
  formatStops,
  getPriceParamInfo,
  isTruthy,
} from 'src/portao3-legacy/utils/params';
import SearchFilterCard from './SearchFilterCard';
import useCustomIntl from 'src/portao3-legacy/hooks/useCustomIntl';
import { RangeTooltip } from 'src/portao3-legacy/components/common/SliderTooltips';
import { CustomInput } from 'reactstrap';
import ItemClipper from 'src/portao3-legacy/components/common/ItemClipper';
import {
  TLabelValueObject,
  TMinMaxObject,
  TObject,
} from 'src/portao3-legacy/types/others';
import { registeredAirlines } from 'src/portao3-legacy/constants/travelValues';

export default function FlightFilters({
  isLoading,
  searchStatus,
  searchParams,
  params,
  setParam,
  setNamedParam,
}: IComponentFilter<TFlightSearchParams, TFlightParams>) {
  const { messages } = useCustomIntl();

  // States
  const {
    showPrice,
    minPrice,
    maxPrice,
    showStops,
    stops,
    showConnection,
    showArrivalConnections,
    showDepartureConnections,
    formattedArrivalConnections,
    formattedDepartureConnections,
    showCarriers,
    formattedCarriers,
    showJourneys,
    formattedJourneys,
    showDuration,
    minDepartureDuration,
    maxDepartureDuration,
    minArrivalDuration,
    maxArrivalDuration,
  } = useMemo(() => {
    const showStops = doesParamHaveItems(searchParams.stops);
    const showArrivalConnections = doesParamHaveItems(
      searchParams.arrivalConnections
    );
    const showDepartureConnections = doesParamHaveItems(
      searchParams.departureConnections
    );
    const showCarriers = doesParamHaveItems(searchParams.carriers);
    const showJourneys = doesParamHaveItems(searchParams.journeys, 0);

    return {
      ...getPriceParamInfo(searchParams.values),
      showStops,
      stops: showStops ? formatStops(searchParams.stops, messages) : [],
      showArrivalConnections,
      showDepartureConnections,
      showConnection: showArrivalConnections || showDepartureConnections,
      formattedArrivalConnections: showArrivalConnections
        ? formatConnections(
            searchParams.airports,
            searchParams.arrivalConnections
          )
        : [],
      formattedDepartureConnections: showDepartureConnections
        ? formatConnections(
            searchParams.airports,
            searchParams.departureConnections
          )
        : [],
      showCarriers,
      formattedCarriers: showCarriers
        ? formatCarriers(searchParams.carriers, registeredAirlines)
        : [],
      showJourneys,
      formattedJourneys: showJourneys
        ? formatJourneys(searchParams.journeys, searchParams.airports)
        : [],
      showDuration: Boolean(
        searchParams.departureDuration || searchParams.arrivalDuration
      ),
      minDepartureDuration: Math.floor(searchParams.departureDuration?.min),
      maxDepartureDuration: Math.ceil(searchParams.departureDuration?.max),
      minArrivalDuration: Math.floor(searchParams.arrivalDuration?.min),
      maxArrivalDuration: Math.ceil(searchParams.arrivalDuration?.max),
    };
  }, [searchParams]);

  // Render
  const renderConnections = ({
    show,
    title,
    items,
    key,
    params,
    paramName,
  }: {
    show: boolean;
    title?: string;
    items: TLabelValueObject[];
    key: string;
    params: TObject<boolean>;
    paramName: string;
  }) => {
    return show ? (
      <div className="mb-2">
        {title ? (
          <h3 className="small-title text-primary mb-2">{title}</h3>
        ) : null}

        <ItemClipper items={items}>
          {(connections: TLabelValueObject[]) =>
            connections.map((connection) => (
              <CustomInput
                key={`${key}-connection-${connection.value}`}
                type="checkbox"
                id={`check-${key}-connection-${connection.value}`}
                label={connection.label}
                name={connection.value}
                checked={isTruthy(params[connection.value])}
                onChange={(e) =>
                  setParam(
                    paramName,
                    formatContainsParamsWithEvent(connection.value, e)
                  )
                }
              />
            ))
          }
        </ItemClipper>
      </div>
    ) : null;
  };

  const renderDuration = ({
    show,
    min,
    max,
    param,
    paramName,
    title,
  }: {
    show: boolean;
    min: number;
    max: number;
    paramName: string;
    param: TMinMaxObject;
    title: string;
  }) => {
    return show ? (
      <>
        <small className="mb-1">{title}</small>
        <RangeTooltip
          min={min}
          max={max}
          className="mb-5"
          value={[param.min, param.max || max]}
          allowCross={false}
          pushable
          onChange={setNamedParam(paramName)}
        />
      </>
    ) : null;
  };

  return (
    <SearchFilterWrapper isLoading={isLoading} searchStatus={searchStatus}>
      <SearchFilterCard
        show={showPrice}
        title={messages['containers.search.flight-filters.prices']}
      >
        <RangeTooltip
          min={minPrice}
          max={maxPrice}
          className="mb-5"
          value={[params.price.min || minPrice, params.price.max || maxPrice]}
          allowCross={false}
          pushable
          onChange={setNamedParam('price')}
        />
      </SearchFilterCard>

      <SearchFilterCard show={showStops} title={messages['flight.stops']}>
        {stops.map((stop) => (
          <CustomInput
            key={`stops-${stop.value}`}
            type="checkbox"
            id={`check-stop-${stop.value}`}
            label={stop.label}
            checked={isTruthy(params.stops[stop.value])}
            onChange={(e) =>
              setParam(
                'stops',
                formatContainsParamsWithEvent(stop.value as string, e)
              )
            }
          />
        ))}
      </SearchFilterCard>

      <SearchFilterCard
        show={showConnection}
        title={messages['containers.search.flight-filters.connections']}
      >
        <>
          {renderConnections({
            show: showDepartureConnections,
            items: formattedDepartureConnections,
            key: 'departure',
            paramName: 'departureConnections',
            params: params.departureConnections,
            title: showArrivalConnections ? messages['travel.outbound'] : '',
          })}

          {renderConnections({
            show: showArrivalConnections,
            items: formattedArrivalConnections,
            key: 'arrival',
            paramName: 'arrivalConnections',
            params: params.arrivalConnections,
            title: messages['travel.inbound'],
          })}
        </>
      </SearchFilterCard>

      <SearchFilterCard
        show={showCarriers}
        title={messages['containers.search.flight-filters.flight-companies']}
      >
        {formattedCarriers.map((carrier) => (
          <CustomInput
            key={carrier.value}
            type="checkbox"
            id={`check-airline-${carrier.value}`}
            label={carrier.label}
            checked={isTruthy(params.airlines[carrier.value], false)}
            onChange={(e) =>
              setParam(
                'airlines',
                formatContainsParamsWithEvent(carrier.value, e)
              )
            }
          />
        ))}
      </SearchFilterCard>

      <SearchFilterCard
        show={showJourneys}
        title={messages['containers.search.flight-filters.departure']}
      >
        {formattedJourneys.map((journey) => (
          <div key={`timefilter-${journey.index}`}>
            <small>{journey.label}</small>
            <RangeTooltip
              min={0}
              max={24}
              className="mb-5"
              value={[
                params.departureTime[journey.index]
                  ? params.departureTime[journey.index].min
                  : 0,
                params.departureTime[journey.index]
                  ? params.departureTime[journey.index].max
                  : 24,
              ]}
              allowCross={false}
              pushable
              isTime
              onChange={(selectedPrices: number[]) =>
                setParam('departureTime', {
                  name: journey.index.toString(),
                  value: selectedPrices,
                })
              }
            />
          </div>
        ))}
      </SearchFilterCard>

      <SearchFilterCard
        show={showDuration}
        title={messages['containers.search.flight-filters.duration']}
      >
        <>
          {renderDuration({
            show: !!searchParams.departureDuration,
            min: minDepartureDuration,
            max: maxDepartureDuration,
            param: params.departureDuration,
            title: messages['travel.outbound'],
            paramName: 'departureDuration',
          })}

          {renderDuration({
            show: !!searchParams.arrivalDuration,
            min: minArrivalDuration,
            max: maxArrivalDuration,
            param: params.arrivalDuration,
            title: messages['travel.inbound'],
            paramName: 'arrivalDuration',
          })}
        </>
      </SearchFilterCard>
    </SearchFilterWrapper>
  );
}
