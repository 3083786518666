import React, { useMemo } from 'react';
import SelectWrapper from './SelectWrapper';
import StarCategories from '../../common/StarCategories';

export default function SelectedHotelCard({ className, item, onClick }) {
  const { hotelImage, hotelName, roomName, category } = useMemo(() => {
    if (!item) return {};

    const { item: selectedItem, selectedRoom } = item;

    const { mainPhoto, name, category } = selectedItem.hotel;

    return {
      hotelImage: mainPhoto,
      hotelName: name,
      roomName: selectedRoom.description,
      category,
    };
  }, [item]);

  // // Render
  const renderItem = () => (
    <div
      className="itinerary-selected-card hotel pointer flex-between"
      onClick={onClick}
    >
      <img className="hotel-image" src={hotelImage} />
      <div className="ml-3">
        <StarCategories category={category} />
        <h3 className="fs-0-95rem m-0 p-0 font-primary one-liner mt-2 mb-1">
          {hotelName}
        </h3>
        <p className="m-0 p-0 text-muted fs-0-75rem one-liner lh-1">
          {roomName}
        </p>
      </div>
    </div>
  );

  return (
    <SelectWrapper className={className} item={item}>
      {item ? renderItem() : null}
    </SelectWrapper>
  );
}
