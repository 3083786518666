import React, { useEffect, useRef } from 'react';
import Lottie from 'lottie-web';

export default function AnimatedToggle({
  animationData,
  value = false,
  toggle = () => {},
  className = '',
  ...props
}) {
  const divRef = useRef(null);
  const animationRef = useRef(null);

  useEffect(() => {
    if (divRef.current) {
      animationRef.current = Lottie.loadAnimation({
        animationData,
        autoplay: value,
        loop: false,
        container: divRef.current,
      });
    }
  }, []);

  const toggleAnimation = () => {
    if (animationRef.current) {
      const animation = animationRef.current;

      animation.setDirection(value ? -1 : 1);
      animation.play();
    }
  };

  const onToggle = () => {
    toggle();
    toggleAnimation();
  };

  return (
    <div
      className={`animated-toggle ${className}`}
      ref={divRef}
      onClick={onToggle}
      {...props}
    />
  );
}
