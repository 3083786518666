import React, { useState } from 'react';
import InboxEmail from './InboxEmail/InboxEmail';
import InboxMail from './InboxMail';
import InboxNavbar from './InboxNavbar';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { InboxSortBy } from '../../../constants/inbox';
import useDataSnapshot from '../../../hooks/useDataSnapshot';
import { useSelector } from 'react-redux';
import firebaseApp from '../../../services/Firebase';
import useDataArchive from '../../../hooks/useDataArchive';

const SORT_OPTIONS = [
  {
    firebaseWrapper: (ref) => ref.orderBy('createdAt', 'desc'),
    id: InboxSortBy.MOST_RECENT,
  },
  {
    firebaseWrapper: (ref) => ref.orderBy('createdAt', 'asc'),
    id: InboxSortBy.OLDEST,
  },
];

export default function Inbox() {
  const { user } = useSelector(({ auth }) => auth);

  const [selectedEmail, setSelectedEmail] = useState(null);
  const [sortBy, setSortBy] = useState(null);
  const [selectedBudget, setSelectedBudget] = useState(null);
  const [shouldLoadAccounts, setShouldLoadAccount] = useState(false);

  const [accounts, _, loadingAccounts] = useDataSnapshot(
    {
      fetchFunction: () =>
        firebaseApp.getPersonalAccountFromUser(user.organizationId, user.uid),
      formatFunction: (data) =>
        data.map(({ id, name }) => ({ value: id, label: name })),
      shouldLoad: shouldLoadAccounts,
    },
    [user]
  );

  const { getDataFromArchive } = useDataArchive();

  // Functions
  const toggleLoadAccounts = () =>
    !shouldLoadAccounts && setShouldLoadAccount(true);

  const fetchBudgets = (cardId) => {
    return getDataFromArchive(
      cardId,
      () => firebaseApp.getBudgetsFromCard(user.uid, cardId).get(),
      (snap) => {
        const budgets = [];

        snap.forEach((docRef) => {
          budgets.push({
            value: docRef.id,
            label: docRef.data().name,
          });
        });

        return budgets;
      }
    );
  };

  // Render
  return (
    <div id="inbox" className="d-flex">
      <PerfectScrollbar
        options={{ suppressScrollX: true, useBothWheelAxes: false }}
        className="left-container mr-0 pr-0"
      >
        <InboxNavbar
          sortOptions={SORT_OPTIONS}
          sortBy={sortBy}
          setSortBy={setSortBy}
        />
        <InboxMail
          selectedEmail={selectedEmail}
          setSelectedEmail={setSelectedEmail}
          sortBy={sortBy}
          className="pb-5"
        />
      </PerfectScrollbar>
      <PerfectScrollbar
        options={{ suppressScrollX: true, useBothWheelAxes: false }}
        className="right-container mr-0 pr-0"
      >
        {selectedEmail ? (
          <InboxEmail
            email={selectedEmail}
            key={selectedEmail.id}
            selectedBudget={selectedBudget}
            setSelectedBudget={setSelectedBudget}
            accounts={accounts}
            loadingAccounts={loadingAccounts}
            toggleLoadAccounts={toggleLoadAccounts}
            fetchBudgets={fetchBudgets}
          />
        ) : (
          <div />
        )}
      </PerfectScrollbar>
    </div>
  );
}
