import React, { useState } from 'react';
import CardOption from './CardOption';
import { ReactComponent as DotsIcon } from '../../../assets/img/icons/dots.svg';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';

const option = {
  id: 'dots',
  getIcon: () => DotsIcon,
  getMessageId: () => 'options',
};

export default function CollapsedOptions({ options, card, details, messages }) {
  const [show, setShow] = useState(false);

  const toggle = () => {
    setShow(!show);
  };

  return (
    <div className="collapsed-options">
      <Dropdown toggle={toggle} isOpen={show} size="sm">
        <DropdownToggle tag="div">
          <CardOption
            option={option}
            card={card}
            details={details}
            messages={messages}
          />
        </DropdownToggle>
        <DropdownMenu className="menu py-2">
          {options.map((option, index) => (
            <CardOption
              key={`${option.id}-${index}`}
              option={option}
              card={card}
              details={details}
              messages={messages}
              showTitle
            />
          ))}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
}
