import React from 'react';
import { CardBody, Row } from 'reactstrap';
import { Colxx } from '../../components/common/CustomBootstrap';
import moment from 'moment';
import filter from 'lodash/filter';
import sum from 'lodash/sum';
import sumBy from 'lodash/sumBy';
import map from 'lodash/map';
import isUndefined from 'lodash/isUndefined';
import find from 'lodash/find';
import { useIntl } from 'react-intl';
import HotelMap from './HotelMap';

import { OrderTypes } from '../../constants/orderTypes';

import { DEFAULT_CURRENCY, stringToMoney } from '../../utils/money';

export default function HotelCardSummary({ orders, reservations }) {
  const { messages } = useIntl();

  const hotelOrders = filter(orders, (o) => {
    return o.type === OrderTypes.HOTEL;
  });

  const currency = hotelOrders.length
    ? hotelOrders[0].fare.currency
    : DEFAULT_CURRENCY;

  const hotelReservations = filter(reservations, (reservation) => {
    const orderExists = !isUndefined(
      find(orders, (order) => {
        return order.id === reservation.refs.orderId;
      })
    );

    return reservation.type === OrderTypes.HOTEL && orderExists;
  });

  const numNightsPerReservation = map(hotelReservations, (reservation) => {
    return moment(reservation.hotelDetails.checkOut, 'DD/MM/YYYY').diff(
      moment(reservation.hotelDetails.checkIn, 'DD/MM/YYYY'),
      'days'
    );
  });

  const sumNumNightsPerReservation = sum(numNightsPerReservation);

  const totalSpent = sumBy(hotelOrders, (o) => {
    return parseFloat(o.fare.total);
  });

  return (
    <CardBody>
      <Row>
        <Colxx sm="6">
          <div className="d-flex flex-row mb-3 pb-3 justify-content-between align-items-center">
            <div className="flex-fill">
              <p className="font-weight-medium mb-0">
                {
                  messages[
                    'containers.dashboard.hotel-card-summary.total-spent'
                  ]
                }
              </p>
              <p className="text-muted mb-0 text-small">
                {
                  messages[
                    'containers.dashboard.hotel-card-summary.total-daily'
                  ]
                }
              </p>
            </div>
            <div>{stringToMoney(totalSpent, 2, currency)}</div>
          </div>

          <div className="d-flex flex-row mb-3 pb-3 justify-content-between align-items-center">
            <div className="flex-fill">
              <p className="font-weight-medium mb-0">
                {
                  messages[
                    'containers.dashboard.hotel-card-summary.average-daily'
                  ]
                }
              </p>
              <p className="text-muted mb-0 text-small">
                {
                  messages[
                    'containers.dashboard.hotel-card-summary.average-daily-value'
                  ]
                }
              </p>
            </div>
            <div>
              {stringToMoney(
                sumNumNightsPerReservation
                  ? totalSpent / sumNumNightsPerReservation
                  : 0,
                2,
                currency
              )}
            </div>
          </div>

          <div className="d-flex flex-row mb-3 pb-3 justify-content-between align-items-center">
            <div className="flex-fill">
              <p className="font-weight-medium mb-0">
                {
                  messages[
                    'containers.dashboard.card-summary.total-reservations'
                  ]
                }
              </p>
              <p className="text-muted mb-0 text-small">
                {
                  messages[
                    'containers.dashboard.card-summary.reservations-per-period'
                  ]
                }
              </p>
            </div>
            <div>{hotelOrders.length}</div>
          </div>
        </Colxx>
        <Colxx sm="5">
          <HotelMap reservations={hotelReservations} />
        </Colxx>
      </Row>
    </CardBody>
  );
}
