import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import classnames from 'classnames';
import map from 'lodash/map';

import { components } from 'react-select';
import AsyncSelect from 'react-select/async';

import { locationTypes } from '../../constants/travelValues';

import algoliaSearch from '../../services/Algolia';
import classNames from 'classnames';

const Option = (props) => {
  const locationClass = locationTypes[props.data.type] || locationTypes.airport;

  return (
    <div
      className={classnames({
        'react-select__menu_option': true,
        active: props.isFocused,
      })}
    >
      <div className={`option-icon ${locationClass.style}`} />
      <components.Option {...props} />
    </div>
  );
};

class BusLocationSearch extends Component {
  // Search Locations
  findLocation = (inputValue) => {
    return new Promise(async (resolve, fail) => {
      const index = algoliaSearch.locationIndex();
      const content = await index.search(inputValue, {
        hitsPerPage: 5,
        filters: `type:bus`,
      });

      if (content.nbHits) {
        const results = map(content.hits, (hit) => {
          return {
            value: hit.objectID,
            label: `${hit.name}`,
            type: hit.type,
          };
        });

        resolve(results);
      } else {
        resolve([]);
      }
    });
  };

  // Render
  render() {
    const { messages } = this.props.intl;
    const { setValue, value, className } = this.props;

    return (
      <AsyncSelect
        className={classNames('react-select', className)}
        classNamePrefix="react-select"
        components={{ Option }}
        value={value}
        isClearable={false}
        cacheOptions
        placeholder=""
        noOptionsMessage={() => {
          return messages['travel.location-message'];
        }}
        loadingMessage={() => {
          return messages['travel.location-searching'];
        }}
        onChange={(selects) => {
          setValue(selects);
        }}
        loadOptions={this.findLocation}
      />
    );
  }
}

export default injectIntl(BusLocationSearch);
