import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Row } from 'reactstrap';
import { Colxx } from '../../components/common/CustomBootstrap';
import moment from 'moment';

import DateSelection from '../../components/reports/DateSelection';
import SmallLineBookings from '../dashboard/SmallLineBookings';
import SmallLineSpent from '../dashboard/SmallLineSpent';
import SmallLineEconomy from '../dashboard/SmallLineEconomy';
import AdoptionCard from '../dashboard/AdoptionCard';
import SupportCard from '../dashboard/SupportCard';
import HotelCard from '../dashboard/HotelCard';
import FlightCard from '../dashboard/FlightCard';
import CarCard from '../dashboard/CarCard';

import firebaseApp from '../../services/Firebase';

import OrderStatus from '../../constants/orderStatus';
import BusCard from '../dashboard/BusCard';
import useDataArchive from '../../hooks/useDataArchive';
import { getDocumentsFromCollection } from '../../utils/firebase';
import orderStructure from '../../constants/orderStructure';

export default function DashboardView() {
  const { user } = useSelector((state) => state.auth);

  // States
  const [loading, setLoading] = useState(true);
  const [dateSelection, setDateSelection] = useState({
    startDate: moment().startOf('month').startOf('day').unix(),
    endDate: moment().endOf('day').unix(),
  });

  const [orders, setOrders] = useState([]);
  const [reservations, setReservations] = useState([]);

  const { getDataFromArchive } = useDataArchive();

  // Effects
  useEffect(() => {
    reloadData();
  }, [dateSelection]);

  // Functions
  // Date Selection
  const setCustomDate = (start, end) => {
    setDateSelection({
      startDate: moment(start).startOf('day').unix(),
      endDate: moment(end).endOf('day').unix(),
    });
  };

  // Load Data
  const reloadData = async () => {
    setLoading(true);

    const orders = await loadOrders();
    await loadReservations(orders);

    setLoading(false);
  };

  const loadReservations = async (orders) => {
    const reservations = await Promise.all(
      orders.map(({ id, organizationId }) =>
        getDataFromArchive(
          `r-${id}`,
          () =>
            getDocumentsFromCollection(() =>
              firebaseApp.getReservationsFromOrderId(organizationId, id).get()
            ),
          (arr) =>
            arr.map((reservation) => ({
              ...reservation,
              refs: {
                orderId: id,
              },
            }))
        )
      )
    );

    setReservations(reservations.flat());
  };

  const loadOrders = async () => {
    const snapshotOrder = await firebaseApp
      .getOrdersFromOrganizationIdAndDate(
        user.organizationId,
        dateSelection.startDate,
        dateSelection.endDate
      )
      .get();

    const orders = [];

    snapshotOrder.forEach(function (doc) {
      const order = {
        ...doc.data(),
        id: doc.id,
      };

      if (
        order.statusCode === OrderStatus.ISSUED &&
        order.structure === orderStructure.ORGANIZATION
      )
        orders.push(order);
    });

    setOrders(orders);

    return orders;
  };

  // Render
  return (
    <div className="reports mt-3">
      <Row>
        <Colxx xxs="12" className="mb-4 text-right">
          <DateSelection
            dateSelection={dateSelection}
            setDateSelection={setCustomDate}
          />
        </Colxx>
      </Row>

      <Row className="mb-5">
        <Colxx xxs="8" className="mb-7 align-items-stretch">
          <SmallLineSpent
            loading={loading}
            orders={orders}
            reservations={reservations}
          />
        </Colxx>
        <Colxx xxs="4" className="mb-7 align-items-stretch">
          <Row>
            <Colxx xxs="12" className="mb-5">
              <SmallLineBookings
                loading={loading}
                orders={orders}
                reservations={reservations}
              />
            </Colxx>
          </Row>
          <Row>
            <Colxx xxs="12">
              <SmallLineEconomy
                loading={loading}
                orders={orders}
                reservations={reservations}
              />
            </Colxx>
          </Row>
        </Colxx>
      </Row>
      <Row className="mb-2">
        <Colxx xxs="4">
          <div className="mb-5">
            <AdoptionCard
              orders={orders}
              dateSelection={dateSelection}
              parentLoading={loading}
            />
          </div>

          <SupportCard dateSelection={dateSelection} />
        </Colxx>
        <Colxx xxs="8">
          <div className="mb-5">
            <HotelCard
              orders={orders}
              reservations={reservations}
              parentLoading={loading}
            />
          </div>

          <div className="mb-5">
            <FlightCard
              orders={orders}
              reservations={reservations}
              parentLoading={loading}
            />
          </div>

          <div className="mb-5">
            <CarCard
              orders={orders}
              reservations={reservations}
              parentLoading={loading}
            />
          </div>

          <BusCard
            orders={orders}
            reservations={reservations}
            parentLoading={loading}
          />
        </Colxx>
      </Row>
    </div>
  );
}
