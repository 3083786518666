import React from 'react';
import { Input, Label } from 'reactstrap';
import map from 'lodash/map';
import IntlMessages from '../../../helpers/IntlMessages';
import { useIntl } from 'react-intl';
import { cabinTypes } from '../../../constants/travelValues';

export default function FlightFormParams({
  baggage = 0,
  onBaggageChange = () => {},
  cabin = cabinTypes[0],
  onCabinChange = () => {},
}) {
  const { messages } = useIntl();

  // Render
  return (
    <div className="flight-baggage d-flex flex-column justify-content-center align-items-center p-0 m-0 mb-2">
      <Label className="form-group has-top-label w-100 position-relative">
        <Input type="select" onChange={onBaggageChange} value={baggage}>
          <option value={0}>{messages[`travel.no_baggage`]}</option>
          <option value={1}>{messages[`travel.with_baggage`]}</option>
        </Input>
        <IntlMessages id="travel.Baggage" />
      </Label>

      <Label className="form-group has-top-label w-100 position-relative">
        <Input type="select" onChange={onCabinChange} value={cabin}>
          {map(cabinTypes, (cabinType) => (
            <option key={cabinType} value={cabinType}>
              {messages[`travel.${cabinType}`]}
            </option>
          ))}
        </Input>
        <IntlMessages id="travel.class" />
      </Label>
    </div>
  );
}
