import React, { Component } from 'react';
import {
  Form,
  Row,
  Label,
  CustomInput,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
} from 'reactstrap';
import { Colxx } from '../../components/common/CustomBootstrap';
import firebaseApp from '../../services/Firebase';

import { connect } from 'react-redux';
import { useSelector } from 'react-redux';

import { toggleItem, setChecklistOpen } from '../../redux/checklist/actions';
import { toggleMask } from '../../redux/animation/actions';

import AirportLocationSearch from '../../components/search/LocationSearch';
import DateSearch from '../../components/search/DateSearch';
import SearchButton from '../../components/search/SearchButton';

import { getSearchCode, getProviders } from '../../utils/search';

import { injectIntl } from 'react-intl';
import IntlMessages from '../../helpers/IntlMessages';
import User from '../../services/User';

import SearchStatus from '../../constants/searchStatus';
import CustomErrorMessage from '../../components/CustomErrorMessage';
import { validateFields, objectValidator } from '../../utils/fieldValidation';
import { Write, Execute, performActions } from '../../utils/actions';
import moment from 'moment';
import { setupList } from '../../constants/checklists';
import searchStatus from '../../constants/searchStatus';
import Sequence from '../../utils/sequence';
import { DEFAULT_CURRENCY } from '../../utils/money';
import FlightFormParams from './Params/FlightFormParams';
import {
  filterHotelLocations,
  findHotelLocation,
  resultDestinations,
} from '../../utils/findHotelLocation';
import { getHotelSearchParams } from '../../utils/search';
import { updateSearchHotel } from '../../redux/actions';

class FlightForm extends Component {
  outboundRef = React.createRef();
  inboundRef = React.createRef();
  originRef = React.createRef();
  destinationRef = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      originValue: null,
      destinationValue: null,
      inboundDate: null,
      outboundDate: null,
      errors: {},
      waitingResults: false,
    };

    this.stateHotel = {
      search: {
        destination: null,
        currency: null,
        origin: null,
        outbound_date: null,
        inbound_date: null,
        searchCode: null,
        status: null,
        hotel_params: null,
        twoWay: null,
        dropOffSamePickUp: null,
      },
    };

    this.sequence = new Sequence([
      [
        (currentProps, _) => currentProps.search.origin.value,
        () => {
          if (this.originRef.current)
            this.originRef.current.children[0].focus();
        },
      ],
      [
        (currentProps, _) => currentProps.search.destination.value,
        () => {
          if (this.destinationRef.current) {
            const currentRef = this.destinationRef.current;
            setTimeout(() => currentRef.children[0].focus(), 100);
          }
        },
      ],
      [
        (currentProps, _) => currentProps.search.outbound_date,
        () => {
          if (this.outboundRef.current) this.outboundRef.current.setFocus();
        },
      ],
      [
        (currentProps, _) => currentProps.search.inbound_date,
        () => {
          if (this.inboundRef.current) this.inboundRef.current.setFocus();
        },
      ],
    ]);
  }

  componentDidMount() {
    if (this.props.checklist) {
      const setupChecklist = this.props.checklist[setupList.id];
      if (
        setupChecklist &&
        !setupChecklist.completed &&
        !setupChecklist.items[setupList.items.search].done
      ) {
        return this.startAnimation();
      }
    }

    if (this.props.autoSearch) this.startSearch();
    else this.sequence.run(this.props, this.props);
  }

  componentDidUpdate(prevProps) {
    const { search = {} } = this.props;

    if (this.state.animationInProgress) {
      if (
        search.outbound_date !== prevProps.search?.outbound_date &&
        !search.inbound_date
      ) {
        if (this.inboundRef.current) this.inboundRef.current.setFocus();
      }

      if (
        this.state.waitingResults &&
        this.props.search?.status === searchStatus.DONE
      ) {
        // Verify if it's waiting for search finish animation
        this.setState({
          waitingResults: false,
          animationInProgress: false,
          originValue: null,
          destinationValue: null,
          inboundDate: null,
          outboundDate: null,
        });

        this.props.setChecklistOpen(setupList.id, true);

        setTimeout(() => {
          this.props.toggleItem(setupList.id, setupList.items.search, true);
        }, 700);

        return;
      }
    } else {
      if (
        !prevProps.checklist[setupList.id] &&
        this.props.checklist[setupList.id]
      ) {
        const setupChecklist = this.props.checklist[setupList.id];
        if (
          setupChecklist &&
          !setupChecklist.completed &&
          !setupChecklist.items[setupList.items.search].done
        ) {
          if (document.activeElement) document.activeElement.blur();
          this.startAnimation();
        }
      } else {
        if (this.props.autoSearch) {
          if (prevProps.search.searchCode !== this.props.search.searchCode) {
            this.startSearch();
          }
        }

        this.sequence.run(this.props, prevProps);
      }
    }
  }

  startAnimation = () => {
    this.props.toggleMask(true);
    this.simulateUserInteraction();
    this.setState({
      animationInProgress: true,
    });
  };

  simulateUserInteraction = () => {
    const outboundDate = moment();
    const inboundDate = moment().add(7, 'days');
    performActions(
      [
        new Execute(true, () => {
          this.props.setChecklistOpen('welcome', false);
          this.props.toggleMask(true);
        }),
        new Write(
          'São Paulo',
          (label) =>
            this.setState({
              originValue: {
                airportCode: 'SAO',
                cityCode: 'São Paulo',
                countryName: 'Brazil',
                label,
                type: 'city',
                value: 'SAO',
              },
            }),
          {
            delay: 80,
            callback: () => {
              this.props.setOriginLocation({
                airportCode: 'SAO',
                cityCode: 'São Paulo',
                countryName: 'Brazil',
                label: 'São Paulo',
                type: 'city',
                value: 'SAO',
              });
            },
          }
        ),
        new Write(
          'Rio de Janeiro',
          (label) =>
            this.setState({
              destinationValue: {
                airportCode: 'RIO',
                cityCode: 'Rio de Janeiro',
                countryName: 'Brazil',
                label,
                type: 'city',
                value: 'RIO',
              },
            }),
          {
            delay: 80,
            callback: () => {
              this.props.setDestinationLocation({
                airportCode: 'RIO',
                cityCode: 'Rio de Janeiro',
                countryName: 'Brazil',
                label: 'Rio de Janeiro',
                type: 'city',
                value: 'RIO',
              });
            },
          }
        ),
        new Write(
          outboundDate.format(),
          (date) => this.setState({ outboundDate: date }),
          {
            callback: () => {
              this.props.setOutboundDate(outboundDate.toDate());
              this.outboundRef.current.setOpen(false);
            },
            setup: () => this.outboundRef.current.setOpen(true),
            startDelay: 500,
          }
        ),
        new Write(
          inboundDate.format(),
          (date) => this.setState({ inboundDate: date }),
          {
            callback: () => {
              this.props.setInboundDate(inboundDate.toDate());
              setTimeout(() => this.inboundRef.current.setOpen(false), 500);
            },
          }
        ),
        new Execute(true, () => this.startSearch()),
        new Execute(true, () => this.props.toggleMask(false)),
        new Execute(true, () => this.setState({ waitingResults: true })),
      ],
      500,
      0
    );
  };

  verifyFields = (search) => {
    const fields = [
      ['origin', objectValidator],
      ['destination', objectValidator],
      ['outbound_date'],
    ];

    if (search.twoWay) fields.push(['inbound_date']);

    const errors = validateFields(search, fields);

    this.setState({ errors });

    return !Object.keys(errors).length;
  };

  removePropertyFromErrors = (property) => {
    if (property in this.state.errors) {
      const { [property]: value, ...errors } = this.state.errors;
      this.setState({ errors });
    }
  };

  locale = ({ settings }) => {
    const { locale } = settings;
    return locale ? locale.split('-')[0] : '';
  };

  findLocation = (destination) => {
    return new Promise(async (resolve, fail) => {
      try {
        const token = await User.getToken();
        const responseServiceLocation = await findHotelLocation(
          token,
          destination.cityCode
        );

        if (responseServiceLocation?.data?.size > 0) {
          const filteredData = filterHotelLocations(
            responseServiceLocation.data.data
          );

          const results = resultDestinations(
            filteredData,
            responseServiceLocation.data.data,
            this.locale
          );

          resolve(results);
        }

        resolve([]);
      } catch (err) {
        throw err;
      }
    });
  };

  getSearchCodeHotel = () => {
    const { search } = this.stateHotel;
    const { searchCode, payload } = getHotelSearchParams(
      search,
      this.props.organization
    );

    this.stateHotel = {
      ...this.stateHotel,
      search: {
        ...search,
        searchCode,
      },
    };

    console.log('Search code hotel -> ', searchCode);

    return { searchCode, payload };
  };

  createSearch = (searchCode, payload) =>
    firebaseApp.createSearch(searchCode, payload);

  findLocationHotel = async () => {
    const { search } = this.props;
    const { destination } = search;

    const hotelFindLocation = await this.findLocation(destination);

    this.stateHotel = {
      search: {
        destination: hotelFindLocation[0],
        currency: this.props.search.currency || DEFAULT_CURRENCY,
        origin: {},
        outbound_date: moment(this.props.search.outbound_date),
        inbound_date: moment(this.props.search.inbound_date),
        searchCode: null,
        status: SearchStatus.NEW,
        hotel_params: this.props.search.hotel_params,
        twoWay: true,
        dropOffSamePickUp: true,
      },
    };

    const { searchCode, payload } = this.getSearchCodeHotel();
    this.createSearch(searchCode, payload);
    this.props.updateSearchHotel(this.stateHotel.search);
  };

  // Do Search
  startSearch = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    const { search, startSearch } = this.props;

    this.findLocationHotel();

    if (search.status !== SearchStatus.LOADING && this.verifyFields(search)) {
      const {
        origin,
        destination,
        outbound_date,
        inbound_date,
        twoWay,
        flightParams,
        currency = DEFAULT_CURRENCY,
      } = search;

      let searchCode = '';
      let payload = '';
      const providers = getProviders(
        !currency || currency === DEFAULT_CURRENCY
          ? 'flight'
          : 'flight_external'
      );

      // Validate
      if (twoWay) {
        if (
          !origin.value ||
          !outbound_date ||
          !destination.value ||
          !inbound_date
        ) {
          return;
        }

        searchCode = getSearchCode(
          'flight',
          origin.value,
          outbound_date,
          destination.value,
          inbound_date,
          currency,
          flightParams
        );

        payload = {
          originCode: origin.value,
          originDate: outbound_date.format('DD/MM/YYYY'),
          destinationCode: destination.value,
          destinationDate: inbound_date.format('DD/MM/YYYY'),
          providers,
          flightParams,
          status: SearchStatus.LOADING,
        };
      } else {
        if (!origin.value || !outbound_date || !destination.value) {
          return;
        }

        searchCode = getSearchCode(
          'flight',
          origin.value,
          outbound_date,
          destination.value,
          '',
          currency,
          flightParams
        );

        payload = {
          originCode: origin.value,
          originDate: outbound_date.format('DD/MM/YYYY'),
          destinationCode: destination.value,
          providers,
          flightParams,
          status: SearchStatus.LOADING,
        };
      }

      startSearch(searchCode, payload);
    }
  };

  // Render
  render() {
    const { messages } = this.props.intl;
    const {
      search,
      setOriginLocation,
      toggleTwoWay,
      setDestinationLocation,
      setOutboundDate,
      setInboundDate,
      setBaggage,
      setCabin,
      swapLocations,
    } = this.props;
    const { originValue, destinationValue, inboundDate, outboundDate, errors } =
      this.state;

    return (
      <Form className="travel-search" onSubmit={this.startSearch}>
        <Row>
          <Colxx xxs="5">
            <div ref={this.originRef}>
              <Label
                className="form-group search has-top-label w-45 float-left"
                onFocus={() => this.removePropertyFromErrors('origin')}
              >
                <AirportLocationSearch
                  setValue={setOriginLocation}
                  value={originValue || search.origin}
                  className="outbound-search"
                />
                <IntlMessages id="travel.origin" />
                <CustomErrorMessage error={errors.origin}>
                  {messages['forms.validation.required']}
                </CustomErrorMessage>
              </Label>
            </div>

            <Label
              className="form-group search has-top-label w-10 float-left addon-places flip pointer"
              onClick={swapLocations}
            >
              <img
                src="/assets/img/icons/search_icon.svg"
                alt={messages['alts.containers.search.car-form']}
              />
            </Label>

            <div ref={this.destinationRef}>
              <Label
                className="form-group search has-top-label w-45 float-left"
                onFocus={() => this.removePropertyFromErrors('destination')}
              >
                <AirportLocationSearch
                  setValue={setDestinationLocation}
                  value={destinationValue || search.destination}
                  className="inbound-search"
                />
                <IntlMessages id="travel.destination" />
                <CustomErrorMessage
                  error={errors.destination}
                  style={{ animationDelay: '0.1s' }}
                >
                  {messages['forms.validation.required']}
                </CustomErrorMessage>
              </Label>
            </div>
          </Colxx>
          <Colxx xxs="5">
            <div className="float-left w-33">
              <div>
                <Label
                  className="form-group search has-top-label mb-2"
                  onClick={(e) => e.preventDefault()}
                  onFocus={() => this.removePropertyFromErrors('outbound_date')}
                >
                  <DateSearch
                    selectedDate={outboundDate || search.outbound_date}
                    setValue={setOutboundDate}
                    isStart
                    defaultStartDate={search.outbound_date}
                    defaultEndDate={search.inbound_date}
                    ref={this.outboundRef}
                    className="outbound-date"
                  />
                  <IntlMessages id="travel.outbound" />
                  <CustomErrorMessage
                    error={errors.outbound_date}
                    style={{ animationDelay: '0.2s' }}
                  >
                    {messages['forms.validation.required']}
                  </CustomErrorMessage>
                </Label>
              </div>
              <div>
                <CustomInput
                  type="checkbox"
                  id={`dropOff-same-as-pickUp`}
                  label={messages['travel.isTwoWay']}
                  checked={search.twoWay}
                  onChange={toggleTwoWay}
                />
              </div>
            </div>

            {search.twoWay && (
              <div className="float-left w-33">
                <Label
                  className="form-group search has-top-label mb-2"
                  onClick={(e) => e.preventDefault()}
                  onFocus={() => this.removePropertyFromErrors('inbound_date')}
                >
                  <DateSearch
                    selectedDate={inboundDate || search.inbound_date}
                    setValue={(e) => {
                      setInboundDate(e);
                    }}
                    defaultMinDate={search.outbound_date}
                    isEnd
                    defaultStartDate={search.outbound_date}
                    defaultEndDate={search.inbound_date}
                    ref={this.inboundRef}
                    className="inbound-date"
                  />
                  <IntlMessages id="travel.inbound" />
                  <CustomErrorMessage
                    error={errors.inbound_date}
                    style={{ animationDelay: '0.3s' }}
                  >
                    {messages['forms.validation.required']}
                  </CustomErrorMessage>
                </Label>
              </div>
            )}

            <Label className="form-group search has-top-label float-left w-33 dropdown-container">
              <UncontrolledDropdown className="roomSelection">
                <DropdownToggle caret>
                  {search.flightParams.baggages === 1
                    ? messages['travel.with_baggage']
                    : messages['travel.no_baggage']}
                  • {messages[`travel.${search.flightParams.cabin}`]}
                </DropdownToggle>
                <DropdownMenu>
                  <FlightFormParams
                    baggage={search.flightParams.baggages}
                    onBaggageChange={setBaggage}
                    cabin={search.flightParams.cabin}
                    onCabinChange={setCabin}
                  />
                </DropdownMenu>
              </UncontrolledDropdown>
              <IntlMessages id="travel.baggageCabin" />
            </Label>
          </Colxx>
          <SearchButton status={search.status} />
        </Row>
      </Form>
    );
  }
}

export default connect(
  ({ checklist }) => ({
    checklist,
  }),
  { toggleItem, toggleMask, setChecklistOpen, updateSearchHotel }
)(injectIntl(FlightForm));
