import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Label, Row } from 'reactstrap';
import { Colxx } from '../../../../../components/common/CustomBootstrap';
import TagItem from '../../../../../components/common/TagItem';
import IntlMessages from '../../../../../helpers/IntlMessages';
import useDataArchive from '../../../../../hooks/useDataArchive';
import { stringToMoney } from '../../../../../utils/money';
import { fetchCommonInfo } from '../utils';
import useDataLoaderWithArchive from '../../../../../hooks/useDataLoaderWithArchive';
import firebaseApp from '../../../../../services/Firebase';
import { getCardNameFromMessages } from '../../../../../utils/recurrenceBudget';

import { RecurrenceBudgetTypes } from '../../../../../constants/recurrenceBudgets';

export default React.memo(({ budget }) => {
  const { formatMessage } = useIntl();

  const { getDataFromArchive } = useDataArchive();

  const [expenseCard] = useDataLoaderWithArchive({
    fetchFunction: () =>
      firebaseApp.getExpenseCardsByIdFromOrganization(
        budget.organizationId,
        budget.cardId
      ),
    archiveKey: `expense-card/${budget.cardId}`,
  });

  // States
  const [commonInfo, setCommonInfo] = useState([]);
  const {
    name,
    formattedActiveDays,
    formattedRecurrence,
    budgetValue,
    category,
    tags,
  } = useMemo(() => {
    const {
      name,
      activePeriod,
      recurrence,
      budgetValue,
      category,
      tags = [],
    } = budget;

    return {
      name,
      formattedActiveDays: formatMessage(
        { id: 'recurrence-budget-modal.info.active-days.description' },
        {
          days: activePeriod || 1,
        }
      ),
      formattedRecurrence: formatMessage(
        {
          id:
            recurrence.type === RecurrenceBudgetTypes.date
              ? 'recurrence-budget.type.date'
              : 'recurrence-budget.type.interval',
        },
        { days: recurrence.days }
      ),
      budgetValue: budgetValue ? stringToMoney(budgetValue, 2) : null,
      category: category?.label,
      tags: tags.length ? tags : null,
    };
  }, [budget]);

  const cardNumber = useMemo(
    () => getCardNameFromMessages(expenseCard, formatMessage),
    [expenseCard]
  );

  // Effects
  useEffect(() => {
    handleCommonInfo(budget);
  }, [budget]);

  // Functions
  const handleCommonInfo = useCallback(async (budget) => {
    try {
      const commonInfo = await fetchCommonInfo(budget, getDataFromArchive);
      setCommonInfo(commonInfo);
    } catch (err) {
      console.error(err);
    }
  }, []);

  // Render
  return (
    <section className="recurrence-tab refund-params reservation-params px-4 py-2">
      <Row>
        <Label className="form-group disabled search has-top-label">
          <p className="text-uppercase">{name}</p>
          <IntlMessages id="recurrence-budget-modal.info.name" />
        </Label>
      </Row>
      <Row>
        <Colxx xxs="6">
          <Label className="form-group disabled search has-top-label">
            <p className="text-uppercase">{formattedRecurrence}</p>
            <IntlMessages id="recurrence-budget-modal.info.period" />
          </Label>
        </Colxx>
        <Colxx xxs="6">
          <Label className="form-group disabled search has-top-label">
            <p className="text-uppercase">{formattedActiveDays}</p>
            <IntlMessages id="recurrence-budget-modal.info.active-days" />
          </Label>
        </Colxx>
      </Row>
      <Row>
        <Label className="form-group disabled search has-top-label">
          <p className="text-uppercase">{cardNumber}</p>
          <IntlMessages id="recurrence-budget-modal.info.card" />
        </Label>
      </Row>
      {budget.budgetValue ? (
        <Row>
          <Colxx xxs="6">
            <Label className="form-group disabled search has-top-label">
              <p className="text-uppercase">{budgetValue}</p>
              <IntlMessages id="refund.modal.refund.budget" />
            </Label>
          </Colxx>
          <Colxx xxs="6">
            <Label className="form-group disabled search has-top-label">
              <p className="text-uppercase">{category}</p>
              <IntlMessages id="refund.modal.refund.budget-category" />
            </Label>
          </Colxx>
        </Row>
      ) : null}
      <Row>
        {commonInfo.map((info) => (
          <Colxx xxs={info.size} key={info.id}>
            <Label className="form-group disabled search has-top-label">
              <p className="text-uppercase">
                {info.data.name || info.data.label}
              </p>
              <IntlMessages id={info.message} />
            </Label>
          </Colxx>
        ))}
        {tags ? (
          <Colxx xxs="12">
            <Label className="form-group disabled search has-top-label">
              <div className="d-flex flex-wrap pt-4 px-2 ml-1 pb-1">
                {tags.map((tag) => (
                  <TagItem key={tag.value} tag={tag} className="mb-1" />
                ))}
              </div>
              <IntlMessages id="travel.booking.tag" />
            </Label>
          </Colxx>
        ) : null}
      </Row>
    </section>
  );
});
