import React, { useState } from 'react';
import { Label, Button, Alert, CardTitle } from 'reactstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import IntlMessages from '../../helpers/IntlMessages';
import { useIntl } from 'react-intl';

import User from '../../services/User';

export default function LoginPassword({ email, setStep }) {
  const [error, setError] = useState('');
  const { messages } = useIntl();

  const doPasswordLogin = async (password, setSubmitting) => {
    setSubmitting(true);
    console.debug(`Tentativa de login como ${email}`);

    try {
      await User.passwordLogin(email, password);
    } catch (err) {
      if (err.code === 'auth/wrong-password') {
        setError(messages['user.NotAuthorizedException']);
      } else if (err.code === 'auth/user-not-found') {
        setError(messages['user.UserNotFoundException']);
      } else if (err.code === 'auth/user-disabled') {
        setError(messages['user.UserDisabledException']);
      } else {
        setError(`${messages['user.GeneralException']} ${err.message}`);
      }

      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={{ password: '' }}
      validationSchema={Yup.object().shape({
        password: Yup.string().required(
          messages['forms.validation.password.required']
        ),
      })}
      onSubmit={(values, { setSubmitting }) => {
        doPasswordLogin(values.password, setSubmitting);
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <CardTitle className="mb-4">
            <IntlMessages id="user.login" />
          </CardTitle>

          <Button
            onClick={() => {
              setStep('auth-EMAIL');
            }}
            color="transparent"
            size="xs"
            className="mb-4 text-dark p-0"
          >
            <i className="iconsminds-arrow-out-left" />
            {email}
          </Button>

          {error && (
            <Alert
              name="alert-password-error"
              color="danger"
              className="mt-2 mb-2"
            >
              {error}
            </Alert>
          )}

          <Label className="form-group has-top-label mb-4">
            <Field
              type="password"
              name="password"
              className="form-control"
              autoComplete="current-password"
              id="password"
            />
            <IntlMessages id="user.password" />
            <ErrorMessage
              name="password"
              component="div"
              className="invalid-feedback d-block"
            />
          </Label>
          <div className="d-flex justify-content-end">
            <Button
              type="submit"
              color="primary"
              outline
              size="lg"
              disabled={isSubmitting}
            >
              <IntlMessages id="forms.action.login" />
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
