import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Row } from 'reactstrap';
import { Colxx } from '../../../../components/common/CustomBootstrap';
import { stringToMoney } from '../../../../utils/money';
import PropertyPieChart from '../PropertyPieChart';
import PerfectScrollbar from 'react-perfect-scrollbar';
import classNames from 'classnames';

import { ReactComponent as EmptyImg } from '../../../../assets/img/expenses/new-expense.svg';
import Spinner from '../../../../components/common/Spinner';
import { useIntl } from 'react-intl';

function ListItem({ value }) {
  const { messages, formatMessage } = useIntl();

  const [total, quantityMsg] = useMemo(() => {
    return [
      stringToMoney(value.total, 2),
      value.quantity > 1
        ? formatMessage(
            {
              id: 'refund.dashboard.multiple-items',
            },
            {
              quantity: value.quantity,
            }
          )
        : messages['refund.dashboard.single-item'],
    ];
  }, [value]);

  return (
    <div className="d-flex align-items-center justify-content-between list-item mb-3">
      <div>
        <h3 className="font-primary title font-weight-medium m-0 p-0 mr-5">
          {value.value}
        </h3>
        <p className="details m-0 p-0 text-muted one-line-height mt-1">
          {quantityMsg}
        </p>
      </div>
      <p className="price flex-shrink-0">{total}</p>
    </div>
  );
}

export default function DefaultTab({
  className = '',
  values,
  loading,
  showGraph = true,
}) {
  const { messages } = useIntl();
  const containerRef = useRef(null);
  const rowRef = useRef(null);

  const [flex, setFlex] = useState(true);
  const isEmpty = useMemo(() => values.length === 0, [values]);

  // Effects
  useEffect(() => {
    if (containerRef.current) {
      setFlex(containerRef.current.offsetHeight < rowRef.current.offsetHeight);
    }
  }, [values, loading]);

  // Functions
  const renderLoading = () => {
    return (
      <div className="d-flex align-items-center justify-content-center w-100">
        <Spinner relative style={{ width: 60, height: 60 }} />
      </div>
    );
  };

  const renderEmpty = () => (
    <div className="d-flex justify-content-center align-items-center px-5 w-80 mx-auto show-up">
      <EmptyImg height="100%" />
      <div className="ml-4" style={{ width: 'fit-content' }}>
        <h2
          className="text-dark font-primary m-0 p-0"
          style={{ lineHeight: 1.2, fontSize: '1.3rem' }}
        >
          {messages['refund.dashboard.empty.title']}
        </h2>
        <p className="m-0 p-0 text-muted mt-2">
          {messages['refund.dashboard.empty.description']}
        </p>
      </div>
    </div>
  );

  const renderContent = () => (
    <>
      <Colxx xxs={showGraph ? '7' : '12'} className="h-100">
        <PerfectScrollbar
          options={{ suppressScrollX: true, useBothWheelAxes: false }}
          className={
            flex ? 'd-flex flex-column justify-content-center pl-0' : 'pl-0'
          }
        >
          <div ref={containerRef} className="last-mb-0 pt-2">
            {values.map((value, index) => (
              <ListItem key={`item-${index}`} value={value} />
            ))}
          </div>
        </PerfectScrollbar>
      </Colxx>
      {showGraph ? (
        <Colxx xxs="5" className="h-100 pl-5">
          <PropertyPieChart values={values} />
        </Colxx>
      ) : null}
    </>
  );

  return (
    <div ref={rowRef}>
      <Row className={classNames('default-tab p-3', className)}>
        {loading ? renderLoading() : isEmpty ? renderEmpty() : renderContent()}
      </Row>
    </div>
  );
}
