exports.amenities = {
  Academia: 'gym.svg',
  'Aceita animais de estimação': 'pet.svg',
  'Babá ou creche': 'baby.svg',
  'Café da manhã': 'coffee.svg',
  Estacionamento: 'parking.svg',
  Internet: 'internet.svg',
  Lavanderia: 'laundry.svg',
  Piscina: 'pool.svg',
  Restaurante: 'restaurant.svg',
  Spa: 'spa.svg',
  'Recepção 24 horas': 'front-desk.svg',
  'Restaurante Buffet': 'buffet.svg',
  'Berço disponivel a pedido': 'crib.svg',
  'Acesso directo à praia': 'beach.svg',
  'Ginásio Gratuito': 'free-gym.svg',
  'Free Wifi': 'internet.svg',
  'Wi-Fi grátis': 'internet.svg',
  'Internet access': 'internet.svg',
  'Cartões de Crédito Aceites': 'credit-cards.svg',
  'Restaurante Publico': 'restaurant.svg',
  'Accessibilidade para Cadeira de Rodas': 'wheelchairs.svg',
  'Equipa Poliglota': 'multi-language.svg',
  'Centro de Congressos': 'conference.svg',
  'Serviços de Cocktails': 'cocktail.svg',
  'Room Service 24horas': 'room-service.svg',
  Bagageira: 'baggage-hold.svg',
  'Salas de Reuniões': 'meeting-rooms.svg',
  'Quartos para Deficientes': 'handicapped-rooms.svg',
  'Internet Banda Larga': 'high-speed-internet.svg',
  Elevador: 'elevator.svg',
  'Estacionamento Gratuito': 'free-parking.svg',
  'Serviço de limpeza diário': 'daily-cleaning.svg',
  'Lavandaria/Limpeza a seco': 'dry-cleaning.svg',
  Bar: 'bar.svg',
  'Piscina Exterior': 'swimming-pool.svg',
  'Salas de conferências paralelas': 'conference.svg',
  'Serviço de Estacionamento com manobrista': 'valet-parking.svg',
  'Restaurante Privado': 'private-restaurant.svg',
  'Garagem paga': 'garage.svg',
  'Business Center': 'meeting-rooms.svg',
  Garagem: 'free-parking.svg',
  'Piscina Interior': 'swimming-pool-internal.svg',
  Palco: 'stage.svg',
  Fotocópias: 'copy-machine.svg',
  'Cabeleireiro a Pedido': 'hair-styler.svg',
  'Massagens a Pedido': 'spa.svg',
  'Zona de Fumadores': 'smoking-area.svg',
  'Treinador Pessoal a pedido': 'personal-trainer.svg',
  Auditório: 'conference.svg',
  'Bar de Piscina': 'pool-bar.svg',
  'Cyber Point': 'meeting-rooms.svg',
  Fax: 'fax.svg',
  'Loja de Prendas': 'gift-shop.svg',
  'Estacionamento para autocarros': 'free-parking.svg',
  'Aluguer de bicicletas': 'bycicle.svg',
  'Serviço de Massagens': 'spa.svg',
  SPA: 'spa.svg',
  'Piscina Exterior de crianças de água salgada': 'pool-kids.svg',
  'Babysitting a Pedido': 'baby.svg',
  'Kids Club': 'kids-club.svg',
  'Kids Playground': 'kids-club.svg',
  'Sala de televisão': 'tv-room.svg',
  'Mesa de Bilhar': 'pool-table.svg',
  'Sala de Jogos': 'game-room.svg',
  'Mini Golfe': 'golf.svg',
  'Serviço de Casamentos': 'wedding.svg',
  'Salão de Beleza': 'hair-styler.svg',
  Cabeleireiro: 'hair-styler.svg',
  Cambios: 'exchange.svg',
  Multibanco: 'atm.svg',
  'Jornal grátis': '',
  Minibar: '',
  'Máquina de café/chá': '',
  'Serviço diário de arrumação': 'daily-cleaning.svg',
  'Roupas de cama antialérgicas disponíveis': '',
  'Banheiro privativo': '',
  'Produtos de toalete grátis': '',
  'Secador de cabelo': '',
  'Ferro/Tábua de passar roupa': '',
  'Serviço de quarto (24 horas)': 'room-service.svg',
  Escrivaninha: '',
  'Controle de temperatura (ar-condicionado) no quarto': '',
  'Roupas de cama premium': '',
  'Quartos com janelas antirruído': '',
  'Abertura de cama para dormir': '',
  'Canais de TV premium': '',
  'Cortinas blackout': '',
  'Banheira e chuveiro separados': '',
  'Cofre no quarto (para notebook)': '',
  'Serviço de TV a cabo': '',
  'Wi-Fi (sobretaxa)': 'internet.svg',
  'Internet com fio grátis': '',
  'Edredom de pena': '',
  'Menu de travesseiros': '',
  'TV LCD': '',
  'Dock para iPod': '',
  'Ducha com efeito de chuva': '',
  'Berços grátis': '',
  'Para não fumantes': '',
  'Camas extras/dobráveis (sobretaxa)': '',
  'TV com legenda oculta': '',
  'Barra de apoio próxima ao vaso sanitário': '',
  'Alarme visual contra incêndio': '',
  'Maçanetas tipo alavanca': '',
};

exports.unwantedHotels = ['2311392', '2303094'];
