import moment from 'moment';
import firebaseApp from '../services/Firebase';
import inRange from 'lodash/inRange';
import User from '../services/User';
import axios from 'axios';
import GoogleMaps from '../constants/GoogleMaps';
import algoliaSearch from '../services/Algolia';
import appFunctions from '../services/Functions';
import { INSPECT_MAX_BYTES } from 'buffer';

export const shouldRenewLocations = async (parsedLocations) => {
  if (moment().isAfter(moment.unix(parsedLocations.date).add(7, 'days'))) {
    return true;
  }
  return false;
};

export const getNewLocations = async () => {
  const documentsSnap = await firebaseApp.getLocationPhotos().get();
  const baseLocations = {
    date: moment().unix(),
    locations: [],
  };
  documentsSnap.forEach((doc) => {
    baseLocations.locations.push(doc.data());
  });
  return localStorage.setItem('locations', JSON.stringify(baseLocations));
};

export const handleLocations = async () => {
  const locationsFromStorage = await localStorage.getItem('locations');

  if (locationsFromStorage) {
    const locations = JSON.parse(locationsFromStorage);
    const shouldRenew = shouldRenewLocations(locations);
    if (shouldRenew) {
      return getNewLocations();
    }
    return locations;
  }

  return getNewLocations();
};

export const getLocations = async () => {
  const locations = await localStorage.getItem('locations');
  return locations;
};

export const getStartAndEndLocationInterval = (location, rangeInKm = 1) => {
  const { lat, lng } = location;
  const distance = 0.008999 * rangeInKm;

  return {
    startLat: lat - distance,
    endLat: lat + distance,
    startLng: lng - distance,
    endLng: lng + distance,
  };
};

export const getIDWithinRangeFromLocation = (
  coords,
  location,
  rangeInKm = 1,
  getLocationFunction = (value) => value
) => {
  const { startLat, endLat, startLng, endLng } = getStartAndEndLocationInterval(
    location,
    rangeInKm
  );

  return Object.keys(coords).filter((key) => {
    const [lat, lng] = getLocationFunction(coords[key]);
    return inRange(lat, startLat, endLat) && inRange(lng, startLng, endLng);
  });
};

export const getObjectWithinRangeFromLocation = (
  coords,
  location,
  rangeInKm = 1,
  getLocationFunction = (value) => value
) => {
  const { startLat, endLat, startLng, endLng } = getStartAndEndLocationInterval(
    location,
    rangeInKm
  );

  return Object.keys(coords).reduce((filteredObj, key) => {
    const [lat, lng] = getLocationFunction(coords[key]);

    if (inRange(lat, startLat, endLat) && inRange(lng, startLng, endLng))
      filteredObj[key] = coords[key];

    return filteredObj;
  }, {});
};

export const getMaxMin = (min, max) => {
  return min < max ? { min, max } : { min: max, max: min };
};

export const offsetLocationByKm = (location, km = 10) => {
  const { lat, lng } = location;
  const kmToDegree = km / 111;

  return {
    lat: getMaxMin(lat - kmToDegree, lat + kmToDegree),
    lng: getMaxMin(lng - kmToDegree, lng + kmToDegree),
  };
};

export const distanceBetweenPoints = (p1, p2, xName = 'x', yName = 'y') => {
  const x = p1[xName] - p2[xName];
  const y = p1[yName] - p2[yName];

  return Math.sqrt(x * x + y * y);
};

export const getCityFromLocations = async (cityName) => {
  const token = await User.getToken();

  const res = await axios.get(
    `${process.env.REACT_APP_API_URL}/hotel/locations/autocomplete`,
    {
      params: { search: cityName, removeHotels: true },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (res.data.size > 0) {
    const cities = res.data.data.filter((hit) => hit.type === 'city');

    if (cities.length > 0) {
      const hit =
        cities.find((city) =>
          (city.name['pt-BR'] || city.name['en']).startsWith(cityName)
        ) || cities[0];

      return {
        value: hit.destinationId,
        cityCode: hit.destinationId,
        label: hit.name['pt-BR'],
        countryName: hit.countryId,
        type: hit.type,
      };
    }
  }

  return null;
};

export const getCityFromCarLocations = async (cityName) => {
  const token = await User.getToken();

  const res = await axios.get(
    `${process.env.REACT_APP_API_URL}/car/locations/autocomplete`,
    {
      params: { search: cityName, removeHotels: true },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (res.data.size > 0) {
    const filteredData = res.data.data.filter(
      (result) => result.type !== 'airport'
    );

    if (filteredData.length > 0) {
      const hit = filteredData[0];
      return {
        value: hit.destinationId,
        cityCode: hit.destinationId,
        label: hit.name['pt-BR'],
        countryName: hit.countryId,
        type: hit.type,
      };
    }
  }

  return null;
};

export const getCityFromLatLong = async (lat, lng) => {
  try {
    const res = await appFunctions.getAddressFromLatLng(lat, lng);

    const results = res.data;

    if (results.length > 0) {
      const isCity = (o) => o.types.includes('administrative_area_level_2');

      const city = results.find(isCity).address_components;
      if (city) return city.find(isCity).short_name;
    }

    return null;
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const getCityFromBusLocations = async (cityName) => {
  const index = algoliaSearch.locationIndex();
  const content = await index.search(cityName, {
    hitsPerPage: 5,
    filters: `type:bus`,
  });

  if (content.nbHits) {
    const hit = content.hits[0];

    return {
      value: hit.objectID,
      label: `${hit.name}`,
      type: hit.type,
    };
  }

  return null;
};
