import React from 'react';
import { useIntl } from 'react-intl';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

export default function Dialog({
  children,
  onYes = () => {},
  onNo = () => {},
  isOpen = false,
  title = '',
  toggle = () => {},
  okMode,
}) {
  const { messages } = useIntl();

  const handleSelection = (func = () => {}) => {
    toggle();
    func();
  };

  return (
    <Modal
      isOpen={isOpen}
      backdrop={true}
      toggle={toggle}
      className="text-center dialog"
    >
      <ModalHeader className="bg-primary justify-content-center p-3 font-primary">
        {title}
      </ModalHeader>
      <ModalBody className="pb-3">{children}</ModalBody>
      <ModalFooter className="d-flex justify-content-center m-0 p-3">
        {okMode ? (
          <>
            <Button color="primary" onClick={() => toggle()}>
              {messages['modal.ok']}
            </Button>
          </>
        ) : (
          <>
            <Button color="primary" onClick={() => handleSelection(onYes)}>
              {messages['modal.yes']}
            </Button>
            <Button color="white" onClick={() => handleSelection(onNo)}>
              {messages['modal.no']}
            </Button>
          </>
        )}
      </ModalFooter>
    </Modal>
  );
}
