import imageCompression from 'browser-image-compression';

export const compressImage = (file, options = {}) => {
  return imageCompression(file, options);
};

export const compressImages = async (files, options) => {
  return Promise.all(
    files.map((file) => {
      const type = file.mimeType || file.type || '';
      return type.includes('image/') ? compressImage(file, options) : file;
    })
  );
};
