import React, { useEffect, useMemo, useRef, useState } from 'react';
import appFunctions from '../../../../../services/Functions';
import SlidingComponents from '../../../../SlidingComponents';
import InitialTab from './tabs/InitialTab';
import CheckoutTab from './tabs/CheckoutTab';
import useDataArchive from '../../../../../hooks/useDataArchive';
import useDataSnapshot from '../../../../../hooks/useDataSnapshot';
import firebaseApp from '../../../../../services/Firebase';
import AncillaryTab from './tabs/AncillaryTab';
import Spinner from '../../../../common/Spinner';
import { AncillaryStatus } from '../../../../../constants/ancillaryStatus';

export const FLIGHT_GORDIAN_TABS = {
  INITIAL: 'initial',
  CHECKOUT: 'checkout',
  ANCILLARIES: 'ancillaries',
};

const COMPONENTS = {
  [FLIGHT_GORDIAN_TABS.INITIAL]: InitialTab,
  [FLIGHT_GORDIAN_TABS.CHECKOUT]: CheckoutTab,
  [FLIGHT_GORDIAN_TABS.ANCILLARIES]: AncillaryTab,
};

export default function FlightGordianAlteration({
  order,
  reservations,
  goForAlteration,
  goForInitial,
}) {
  const gordian = useRef(null);

  const [initialTab, setInitialTab] = useState(FLIGHT_GORDIAN_TABS.INITIAL);
  const [loadingGordian, setLoadingGordian] = useState(true);
  const [basket, setBasket] = useState({});
  const [total, setTotal] = useState(0);
  const [availableProducts, setAvailableProducts] = useState({
    seat: null,
    bag: null,
  });

  const [ancillaries, _, loadingAncillaries] = useDataSnapshot({
    fetchFunction: () => firebaseApp.getAncillariesFromOrder(order.id).limit(1),
    formatFunction: (data = []) => data[0] || null,
    initialData: null,
  });

  const { getDataFromArchive: getDataPolicies } = useDataArchive();

  const loading = useMemo(
    () => loadingGordian || loadingAncillaries,
    [loadingGordian, loadingAncillaries]
  );

  // Effects
  useEffect(() => {
    if (
      !loadingAncillaries &&
      !gordian.current &&
      (!ancillaries || ancillaries.statusCode === AncillaryStatus.CREATED)
    ) {
      startGordian();
    }
  }, [loadingAncillaries, ancillaries]);

  useEffect(() => {
    if (
      order.gordian?.purchased ||
      (ancillaries && ancillaries.statusCode > AncillaryStatus.CREATED)
    )
      setInitialTab(FLIGHT_GORDIAN_TABS.ANCILLARIES);
  }, [order, ancillaries]);

  useEffect(() => {
    if (loadingGordian) {
      const isLoaded = Object.values(availableProducts).every(
        (value) => value !== null
      );
      if (isLoaded) setLoadingGordian(false);
    }
  }, [availableProducts]);

  useEffect(() => {
    if (basket.basket) {
      let total = 0;
      const items = Object.values(basket.basket || {});

      if (items.length > 0) {
        const sum = items.reduce(
          (sum, item) => sum + item.price.total.amount,
          0
        );

        total = items[0].price.total.decimal_places
          ? sum / Math.pow(10, items[0].price.total.decimal_places)
          : sum;
      }

      setTotal(total);
    }
  }, [basket]);

  // Functions
  const getTokens = async () => {
    try {
      const { data } = await appFunctions.createGordianFlight(order.id);
      gordian.current = data;
      return data;
    } catch (err) {
      console.error(err);
      return null;
    }
  };

  const startGordian = async () => {
    try {
      if (window.Gordian) {
        gordian.current = true;

        setLoadingGordian(true);

        const element = document.getElementById('flight-gordian-container');

        if (element) {
          const tokens = await getTokens();

          if (tokens) {
            const {
              trip_id,
              trip_access_token,
              basket = {},
              trip_state_hash = null,
            } = tokens;

            setBasket({
              basket,
              trip_state_hash,
            });

            await window.Gordian.init({
              tripId: trip_id,
              tripAccessToken: trip_access_token,
              onBasketChange: (value) => {
                setBasket(value);
              },
              eventCallbacks: {
                onSeatLoad: () => {
                  console.debug('Seat load');
                  setAvailableProducts((prev) => ({ ...prev, seat: true }));
                },
                onBagLoad: () => {
                  console.debug('Bag load');
                  setAvailableProducts((prev) => ({ ...prev, bag: true }));
                },
                onSeatFail: () => {
                  console.debug('Seat fail');
                  setAvailableProducts((prev) => ({ ...prev, seat: false }));
                },
                onBagFail: () => {
                  console.debug('Bag fail');
                  setAvailableProducts((prev) => ({ ...prev, bag: false }));
                },
              },
            });

            await window.Gordian.showUpsell({
              container: element,
              display: 'card', // card | embedded | modal
              allowProducts: ['seats', 'bags'], //"bags", "fare_family_upsell", "priority_boading"...
              excludeProducts: [''],
            });

            if (Object.keys(basket).length > 0) {
              const typesInBasket = Object.values(basket).reduce(
                (obj, { product_type }) => {
                  obj[product_type] = true;
                  return obj;
                },
                { seat: false, bag: false }
              );

              setAvailableProducts({
                seat: typesInBasket.seat,
                bag: typesInBasket.bag,
              });
            }
          }
        }
      }
    } catch (err) {
      console.error(err);
      setLoadingGordian(false);
    }
  };

  const checkPolicies = () =>
    getDataPolicies(basket.trip_state_hash, async () => {
      try {
        const { data } = await appFunctions.checkAncillaryPoliciesFromOrder(
          order.id
        );
        return data;
      } catch (err) {
        console.error('Unable to get ancillary policies', err);
        return {};
      }
    });

  // Render
  return loadingAncillaries ? (
    <div className="d-flex align-items-center justify-content-center w-100 h-100 flight-alteration-tab">
      <Spinner relative white style={{ width: 60, height: 60 }} />
    </div>
  ) : (
    <SlidingComponents
      components={COMPONENTS}
      initialTab={initialTab}
      basket={basket}
      loading={loading}
      total={total}
      goForAlteration={goForAlteration}
      goForInitial={goForInitial}
      checkPolicies={checkPolicies}
      availableProducts={availableProducts}
      order={order}
      ancillaries={ancillaries}
      reservations={reservations}
      className="flight-alteration-tab h-100"
    />
  );
}
