import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { registeredAirlines } from 'src/portao3-legacy/constants/travelValues';
import GenericParamsOptions from './GenericParamsOptions';
import { OrderTypes } from 'src/portao3-legacy/constants/orderTypes';
import { mapRegisteredItems } from 'src/portao3-legacy/utils/others';

export default function FlightParamsOptions({ className, initialData }) {
  const { messages } = useIntl();

  // States
  const params = useMemo(
    () => [
      {
        key: 'airlines',
        options: mapRegisteredItems(registeredAirlines),
        title: messages['admin.travels.params.flight.companies'],
      },
    ],
    []
  );

  // Render
  return (
    <GenericParamsOptions
      className={className}
      title={messages['travel.menu.flight']}
      paramType={OrderTypes.FLIGHT}
      params={params}
      initialData={initialData}
    />
  );
}
