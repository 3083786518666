import React, { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import CreditCardItem from './CreditCardItem';
import { useSelector } from 'react-redux';

import { RefundTypes } from '../../../constants/refundTypes';
import { getOrganizationConfig } from '../../../utils/organization';

// Cards
import { ReactComponent as PhysicalCard } from '../../../assets/img/expenses/physical_card.svg';
import { ReactComponent as VirtualCard } from '../../../assets/img/expenses/virtual_card.svg';
import { ReactComponent as PersonalCard } from '../../../assets/img/expenses/personal_card.svg';
import { ReactComponent as AdministrativeCard } from '../../../assets/img/expenses/administrative_card.svg';

const PAYMENTS = {
  [RefundTypes.PHYSICAL_CARD]: {
    type: RefundTypes.PHYSICAL_CARD,
    Card: PhysicalCard,
  },
  [RefundTypes.VIRTUAL_CARD]: {
    type: RefundTypes.VIRTUAL_CARD,
    Card: VirtualCard,
  },
  [RefundTypes.PERSONAL_ACCOUNT]: {
    type: RefundTypes.PERSONAL_ACCOUNT,
    Card: PersonalCard,
  },
  [RefundTypes.ADMINISTRATIVE_ACCOUNT]: {
    type: RefundTypes.ADMINISTRATIVE_ACCOUNT,
    Card: AdministrativeCard,
  },
};

export default function NewCreditCardSelectionModal({ closeModal, setStep }) {
  const { organization } = useSelector(({ auth }) => auth);
  const { messages } = useIntl();

  // States
  const [selected, setSelected] = useState('');

  const paymentsArray = useMemo(() => {
    const payments = [
      PAYMENTS[RefundTypes.PHYSICAL_CARD],
      PAYMENTS[RefundTypes.VIRTUAL_CARD],
      PAYMENTS[RefundTypes.PERSONAL_ACCOUNT],
    ];

    if (
      getOrganizationConfig(
        organization.config,
        'refunds.allowAdministrative'
      ) !== false
    ) {
      payments.push(PAYMENTS[RefundTypes.ADMINISTRATIVE_ACCOUNT]);
    }

    return payments;
  }, [organization]);

  // Render
  return (
    <Modal
      isOpen={true}
      toggle={closeModal}
      size="lg"
      className="admin refund-new-card-modal card-creation"
    >
      <ModalBody>
        <div className="d-flex flex-wrap align-items-center justify-content-center">
          {paymentsArray.map(({ type, Card }) => (
            <CreditCardItem
              key={type}
              className="m-2"
              value={type}
              selected={selected === type}
              onClick={() => setSelected(type)}
            >
              <Card className="card" />
            </CreditCardItem>
          ))}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={() => setStep(selected)}
          disabled={!selected}
          style={{ transition: 'all 0.3s ease' }}
          outline
          size="lg"
          name="next-payment-stage"
        >
          {messages['general.go-forward']}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
