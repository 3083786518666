import classNames from 'classnames';
import React, { useMemo } from 'react';

import { ReactComponent as ArrowIcon } from '../../assets/img/icons/down.svg';

export default function FloorSelector({
  className,
  floor,
  setFloor,
  numFloors,
}) {
  const { currentFloor, upDisabled, downDisabled } = useMemo(
    () => ({
      currentFloor: floor + 1,
      upDisabled: floor === numFloors - 1,
      downDisabled: !floor,
    }),
    [floor, numFloors]
  );

  // Render
  return (
    <div
      className={classNames(
        'floor-selector flex-center flex-column',
        className
      )}
    >
      <ArrowIcon
        className={classNames('up', { disabled: upDisabled })}
        onClick={() => !upDisabled && setFloor((floor) => floor + 1)}
      />
      <p className="floor m-0 p-0 fs-1-5rem my-3 pointer-none text-center no-selection">
        {currentFloor}
      </p>
      <ArrowIcon
        className={classNames('down', { disabled: downDisabled })}
        onClick={() => !downDisabled && setFloor((floor) => floor - 1)}
      />
    </div>
  );
}
