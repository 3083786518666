export function splitData(data, partitionSize = 500) {
  const size = data.length;
  const partitions = [];

  for (let i = 0; i < size; i += partitionSize) {
    partitions.push(data.slice(i, i + partitionSize));
  }

  return partitions;
}
