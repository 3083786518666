import React from 'react';
import { useEffect } from 'react';
import PinForm from '../CreditCardDetailModal/PinForm';

export default function CreatePinModal({
  cardId,
  setStep,
  setError,
  setHeight,
}) {
  useEffect(() => {
    setHeight(250);
  }, []);

  return <PinForm cardId={cardId} setStep={setStep} setError={setError} />;
}
