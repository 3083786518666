import React, { useState } from 'react';
import { Modal, ModalBody, Row } from 'reactstrap';
import { Colxx } from '../common/CustomBootstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSelector } from 'react-redux';
import { getAuthRedux } from '../../utils/redux';
import OpenBookingReservationParams from '../../containers/search/OpenBookingReservationParams/OpenBookingReservationParams';
import { TravelParamsFunctions } from '../../utils/openBooking';
import OpenBookingCreationFooter from './OpenBookingCreationFooter';
import { OpenBookingReservationFunctions } from '../../utils/openBookingReservations';
import useShowToggle from '../../hooks/useShowToggle';
import classNames from 'classnames';
import OpenBookingReservationDetails from '../../containers/search/OpenBookingReservationDetails/OpenBookingReservationDetails';
import firebaseApp from '../../services/Firebase';
import useExecuteAction from '../../hooks/useExecuteAction';

export default function OpenBookingCreationModal({
  show,
  toggleModal,
  search,
  params,
  travelType,
}) {
  const { user, organization } = useSelector(getAuthRedux);

  // Hooks
  const { executeAction, loading: isReserving } = useExecuteAction();

  // States
  const [reservationParams, setReservationParams] = useState({});
  const [travelParams, setTravelParams] = useState(() =>
    travelType in TravelParamsFunctions
      ? TravelParamsFunctions[travelType](search)
      : {}
  );

  const [orderStructure, setStructure] = useState('organization');

  const [showOpenBookingSteps, _, toggleOpenBookingSteps] = useShowToggle();

  // Functions
  const onTravelFieldChange = (name, value) =>
    setTravelParams((values) => ({ ...values, [name]: value }));

  const onCreateReservation = (dontShowStepsNextTime) => {
    if (!showOpenBookingSteps && !user.config?.openBooking?.skipSteps) {
      return toggleOpenBookingSteps();
    }

    executeAction(
      (async () => {
        try {
          const promises = [];

          if (dontShowStepsNextTime && !user.config?.openBooking?.skipSteps) {
            promises.push(
              firebaseApp.updateUserConfig(
                user.uid,
                'config.openBooking.skipSteps',
                true
              )
            );
          }

          const reservationFunction =
            OpenBookingReservationFunctions[travelType];

          if (reservationFunction)
            promises.push(
              reservationFunction({
                user,
                organization,
                search,
                searchParams: params,
                reservationParams,
                travelParams,
              })
            );

          await Promise.all(promises);

          toggleModal();
        } catch (err) {
          console.log(err);
        }
      })()
    );
  };

  // Render
  return (
    <Modal
      isOpen={show}
      toggle={toggleModal}
      className={classNames('reservation small accordion-modal', {
        open: showOpenBookingSteps,
      })}
    >
      <ModalBody className="flight">
        <Row>
          <Colxx xxs="12">
            <div className="flight-content">
              <PerfectScrollbar
                options={{
                  suppressScrollX: true,
                  wheelPropagation: false,
                }}
              >
                <OpenBookingReservationParams
                  orderStructure={orderStructure}
                  setStructure={setStructure}
                  setReservationParams={setReservationParams}
                  nationality={user.nationality}
                  travelParams={travelParams}
                  setTravelParams={onTravelFieldChange}
                  travelType={travelType}
                  search={search}
                  showingSteps={showOpenBookingSteps}
                />

                <OpenBookingReservationDetails
                  className="mt-5"
                  travelParams={travelParams}
                  travelType={travelType}
                  search={search}
                  searchParams={params}
                  showingSteps={showOpenBookingSteps}
                />
              </PerfectScrollbar>
            </div>
          </Colxx>
        </Row>
      </ModalBody>

      <OpenBookingCreationFooter
        reservationParams={reservationParams}
        travelParams={travelParams}
        travelType={travelType}
        createReservation={onCreateReservation}
        showSteps={showOpenBookingSteps}
        toggleSteps={toggleOpenBookingSteps}
        isReserving={isReserving}
      />
    </Modal>
  );
}
