import React from 'react';
import classNames from 'classnames';

export default function GenericEmptyMessage({
  Icon,
  title,
  description,
  className = '',
  maxWidth = 900,
}) {
  return (
    <div
      className={classNames(
        'mx-auto d-flex justify-content-center align-items-center',
        className
      )}
      style={{ maxWidth, width: '60%' }}
    >
      {Icon ? <Icon className="mr-4 pr-2" /> : null}
      <div>
        <h2
          className="font-primary m-0 p-0 mb-2 font-weight-semibold"
          style={{ fontSize: '1.5rem', lineHeight: 1.3 }}
        >
          {title}
        </h2>
        <p className="m-0 p-0 mb-4 text-muted">{description}</p>
      </div>
    </div>
  );
}
