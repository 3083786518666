import React, { useState, useEffect, useMemo, useRef } from 'react';
import CreditCard from './CreditCard';
import { ReactComponent as PersonalIcons } from '../../assets/img/expenses/personal_icon.svg';
import CardHoverComponent from '../../containers/expenses/CardContainers/CardHoverComponent';

import { dictBankNames } from '../../constants/banks';

const fetchIcon = async (bankId) => {
  if (bankId === undefined) return null;

  const { default: Icon } = await import(
    `!@svgr/webpack!../../assets/img/companies/banks/${bankId}.svg`
  );
  return Icon;
};

export default function PersonalCard({ card, withHover }) {
  const [loadingIcon, setLoadingIcon] = useState(false);
  const Icon = useRef(null);

  const HoverComponent = useMemo(
    () => (withHover ? CardHoverComponent : null),
    [withHover]
  );

  useEffect(() => {
    const loadIcon = async () => {
      if (card.ispb) {
        try {
          setLoadingIcon(true);
          Icon.current = await fetchIcon(dictBankNames[card.ispb]);
          setTimeout(() => setLoadingIcon(false), 200);
        } catch (err) {
          console.error(err);
          Icon.current = null;
        }
      } else Icon.current = null;
    };

    loadIcon();
  }, [card]);

  // Render
  return (
    <CreditCard
      card={card}
      textColor="#303030"
      className="personal-card"
      Icon={() => (
        <div className="icon" style={{ width: 45 }}>
          <PersonalIcons />
        </div>
      )}
      // BankIcon={() => {
      //   if (dictBankNames[card.ispb]) {
      //     return <div className="bank-icon">{Icon.current ? <Icon.current className="icon-svg" /> : null}</div>;
      //   } else {
      //     return <div />;
      //   }
      // }}
      HoverComponent={HoverComponent}
    />
  );
}
