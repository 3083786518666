import { useMemo, useRef, useEffect, useState } from 'react';
import { getDocumentsFromCollectionReference } from '../utils/firebase';
import useDataArchive from './useDataArchive';

export default function useDataLoaderWithArchive(
  {
    fetchFunction,
    idName = 'id',
    formatFunction = (values) => values,
    getSnapshotDataFunction = getDocumentsFromCollectionReference,
    initialData = [],
    shouldLoad = true,
    retryOnError = false,
    maxRetries = 1,
    archiveKey,
    initialLoading = true,
  },
  dependencies = []
) {
  const numRetries = useRef(0);
  const { getDataFromArchive } = useDataArchive();

  const [data, setData] = useState(initialData);
  const [isLoading, setIsLoading] = useState(initialLoading);
  const [error, setError] = useState(null);

  const _dependencies = useMemo(
    () => [shouldLoad, ...dependencies],
    [shouldLoad, dependencies]
  );

  // Effects
  useEffect(() => {
    load();
  }, _dependencies);

  const load = async () => {
    try {
      if (!shouldLoad) return;

      setIsLoading(true);

      const data = await getDataFromArchive(
        archiveKey,
        () => fetchFunction().get(),
        (query) => formatFunction(getSnapshotDataFunction(query, idName))
      );

      setData(data);
      setIsLoading(false);

      numRetries.current = 0;
    } catch (err) {
      console.log('Data loader error', err);

      if (retryOnError && numRetries.current < maxRetries) {
        numRetries.current++;
        load();
      } else {
        setError(err);
        setIsLoading(false);
      }
    }
  };

  return [data, setData, isLoading, error];
}
