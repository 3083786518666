import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import { Card } from 'reactstrap';
import { stringToMoney } from '../../utils/money';
import BubbleButton from '../common/BubbleButton';
import { ReactComponent as PrintIcon } from '../../assets/img/icons/print_2.svg';

import firebaseApp from '../../services/Firebase';

function TransactionDetails({ invoice, transaction }) {
  const [isDownloading, setDownloadingInvoice] = useState(false);

  const { user } = useSelector((state) => state.auth);

  const getDocument = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    try {
      setDownloadingInvoice(true);

      const response = await firebaseApp.getTransactionPrint(
        user.organizationId,
        invoice.id,
        transaction.id
      );

      var blob = new Blob([response], {
        type: 'application/pdf',
      });

      var url = window.URL.createObjectURL(blob);
      window.open(url);
    } catch (err) {
      console.error(err);
    }

    setDownloadingInvoice(false);
  };

  if (transaction.type === 'BOLETO') {
    return (
      <div className="invoice-rows">
        <div>
          <span>Pagamento:</span> Boleto com vencimento para {transaction.due}.{' '}
          <a
            href="#"
            className={classnames({ active: isDownloading })}
            onClick={getDocument}
          >
            {transaction.id}
          </a>
          .
        </div>
        <div>{stringToMoney(transaction.amount / 100, 2)}</div>
      </div>
    );
  }

  if (transaction.returnCode === '00') {
    return (
      <div className="invoice-rows">
        <div>
          <span>Pagamento:</span> • • • {transaction.last4}.{' '}
          <a
            href="#"
            className={classnames({ active: isDownloading })}
            onClick={getDocument}
          >
            {transaction.id}
          </a>
          .
        </div>
        <div>{stringToMoney(transaction.amount / 100, 2)}</div>
      </div>
    );
  }

  return (
    <div className="invoice-rows">
      <div>
        <span>Pagamento recusado:</span> • • • {transaction.last4} de{' '}
        {stringToMoney(transaction.amount / 100, 2)}. {transaction.tid}.
        <br />
        {transaction.returnMessage}.
      </div>
      <div>{stringToMoney(0, 2)}</div>
    </div>
  );
}

export default function InvoiceCard({ invoice }) {
  const [isDownloadingInvoice, setDownloadingInvoice] = useState(false);
  const [isDownloadingStatement, setDownloadingStatement] = useState(false);

  const [detailsOpen, setDetailsOpen] = useState(false);

  const { user } = useSelector((state) => state.auth);

  // Details
  const toggleDetails = () => {
    setDetailsOpen(!detailsOpen);
  };

  // Statement
  const getStatement = async () => {
    setDownloadingStatement(true);

    try {
      const response = await firebaseApp.getStatementPrint(
        user.organizationId,
        invoice.id
      );

      var blob = new Blob([response], {
        type: 'application/pdf',
      });

      var url = window.URL.createObjectURL(blob);
      window.open(url);
    } catch (err) {
      console.error(err);
    }

    setDownloadingStatement(false);
  };

  // Invoice
  const getInvoice = async () => {
    setDownloadingInvoice(true);

    try {
      const response = await firebaseApp.getInvoicePrint(
        user.organizationId,
        invoice.id
      );

      var blob = new Blob([response], {
        type: 'application/pdf',
      });

      var url = window.URL.createObjectURL(blob);
      window.open(url);
    } catch (err) {
      console.error(err);
    }

    setDownloadingInvoice(false);
  };

  return (
    <div className="day-card mb-3" style={{ cursor: 'default' }}>
      <div
        className="invoice-title pl-2 d-flex flex-grow-1 mw-0"
        onClick={toggleDetails}
      >
        <div className="card-body align-self-center d-flex flex-column flex-lg-row justify-content-between mw-0 align-items-lg-center">
          <div className="w-30 w-sm-100">
            <p className="list-item-heading mb-1 truncate">
              # {invoice.number}
            </p>
          </div>
        </div>
        <div className="custom-control custom-checkbox pl-1 align-self-center pr-4">
          <span className="text-weigth-500 text-small">Valor Final</span>
          <br />
          {stringToMoney(invoice.sale.serviceTotal, 2)}
        </div>
      </div>
      <div
        className={classnames({
          'invoice-details': true,
          hidden: !detailsOpen,
        })}
      >
        <div className="invoice-cards">
          <Card className="invoice-document">
            <div>
              <span>Fatura</span>
            </div>
            <BubbleButton
              className="reverse center"
              disabled={isDownloadingStatement}
              onClick={getStatement}
            >
              <PrintIcon width={25} height={25} />
            </BubbleButton>
          </Card>

          {invoice?.serviceInvoice?.statusNotaFiscal === 'EMITIDA' && (
            <Card className="invoice-document">
              <div>
                <span>Nota Fiscal:</span> #{invoice.serviceInvoice.numero}
              </div>
              <BubbleButton
                className="reverse center"
                disabled={isDownloadingInvoice}
                onClick={getInvoice}
              >
                <PrintIcon width={25} height={25} fill="white" />
              </BubbleButton>
            </Card>
          )}
        </div>

        <div className="invoice-rows">
          <div>
            <span>Descrição</span>
          </div>
          <div>
            <span>Valor</span>
          </div>
        </div>

        {invoice.transactions.map((transaction, index) => {
          return (
            <TransactionDetails
              invoice={invoice}
              transaction={transaction}
              key={`trnasaction-${index}`}
            />
          );
        })}

        {invoice.items.map((item, index) => {
          return (
            <div className="invoice-rows" key={`items-${index}`}>
              <div>
                <span>{item.item.descricao}:</span> {item.description}
              </div>
              <div>{stringToMoney(item.price, 2)}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
