import React from 'react';
import { CardTitle } from 'reactstrap';
import { NavLink } from 'react-router-dom';

import IntlMessages from '../../helpers/IntlMessages';

export default function ErrorReset({ error }) {
  return (
    <div>
      <NavLink to="/user/login" className="d-block mb-4">
        <i className="iconsminds-arrow-out-left" />
        <IntlMessages id="user.login.page" />
      </NavLink>

      <CardTitle className="mb-4">
        <IntlMessages id="user.forgot-password" />
      </CardTitle>

      <p>{error}</p>
    </div>
  );
}
