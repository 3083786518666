import { ITravelResultComponentProps } from 'src/portao3-legacy/types/search';
import SearchResultWrapper from './SearchResultWrapper';
import { TBusSearchItem } from 'src/portao3-legacy/types/orders/busOrder';
import {
  TBusParams,
  TBusSearchParams,
} from 'src/portao3-legacy/types/orders/bus/busParams';
import { TBusReservationParams } from 'src/portao3-legacy/hooks/useReserveTravelItem/types';
import LoadingFlight from 'src/portao3-legacy/components/search/LoadingFlight';
import { BusFilterQueries } from 'src/portao3-legacy/constants/search/bus';
import BusItem from './BusItem';
import BusModal from './BusModal';
import { useCallback, useState } from 'react';
import { TObject } from 'src/portao3-legacy/types/others';
import { trackPropertiesSegment } from 'src/portao3-legacy/utils/segment';
import { useSelector } from 'react-redux';
import { getAuthRedux } from 'src/portao3-legacy/utils/redux';
import { OrderTypes } from 'src/portao3-legacy/constants/orderTypes';
import { filterTrueValues } from 'src/portao3-legacy/utils/filters';

function formatParams(data: TBusParams) {
  return {
    ...data,
    stops: filterTrueValues(data.stops, null as any),
  };
}

export default function BusResults({
  searchParams,
  params,
  status,
  isLoading,
  search,
  items,
  getRemainingSearch,
  reserveItemWithToggle,
}: ITravelResultComponentProps<
  TBusSearchItem,
  TBusSearchParams,
  TBusParams,
  TBusReservationParams
>) {
  const { user } = useSelector(getAuthRedux);

  // States
  const [selectedItem, setSelectedItem] = useState<TObject<any> | null>(null);

  // Functions
  const onSelectItem = useCallback(
    (selectedBus: any, selectedSegments: any) => {
      trackPropertiesSegment('User clicked on bus item', {
        selectedBus,
        selectedSegments,
        user,
      });

      setSelectedItem({ selectedBus, selectedSegments });
    },
    [user]
  );

  // Render
  return (
    <>
      <SearchResultWrapper
        LoadingComponent={LoadingFlight}
        filterQueries={BusFilterQueries as any}
        items={items}
        params={params}
        searchParams={searchParams}
        isLoading={isLoading}
        status={status}
        getRemainingSearch={getRemainingSearch}
        orderType={OrderTypes.BUS}
        formatParams={formatParams}
      >
        {({ paginatedItems }) =>
          paginatedItems.map((item, index) => (
            <BusItem
              key={`result-${index}`}
              index={index}
              isTwoWay={search.twoWay}
              item={item}
              params={searchParams}
              showDetails={onSelectItem}
              user={user}
              seatClasses={params.seatClasses}
            />
          ))
        }
      </SearchResultWrapper>

      {selectedItem ? (
        <BusModal
          toggleModal={() => setSelectedItem(null)}
          item={selectedItem.selectedBus}
          segments={selectedItem.selectedSegments}
          params={searchParams}
          reserveItem={reserveItemWithToggle(() => setSelectedItem(null))}
        />
      ) : null}
    </>
  );
}
