export async function initAtlas({ user, organization, userHash }) {
  const userData = {
    userId: user.uid,
    name: `${user.firstName} ${user.lastName}`,
    email: user.email,
    userHash: userHash,
    account: {
      name: organization.name,
      externalId: organization.id,
    },
  };

  if (user.mobilePhoneNumber && user.mobilePhoneNumber !== '') {
    userData.phoneNumber = user.mobilePhoneNumber;
  }

  window.Atlas.call('identify', userData);
}
