import React from 'react';
import { createRoot } from 'react-dom/client';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { configureStore } from './portao3-legacy/redux/store';
import { BrowserRouter as Router } from 'react-router-dom';

import App from './App';

const root = createRoot(document.getElementById('root'));

root.render(
  <Router>
    <Provider store={configureStore()}>
      <App />
    </Provider>
  </Router>
);
/*
 * If you want your app to work offline and load faster, you can change
 * unregister() to register() below. Note this comes with some pitfalls.
 * Learn more about service workers: https://bit.ly/CRA-PWA
 */
serviceWorker.unregister();
