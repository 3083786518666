import React, { useMemo } from 'react';

export default function TimelineCardDecider({
  order,
  DefaultCardComponent,
  OpenBookingCardComponent,
  ...props
}) {
  const CardComponent = useMemo(
    () =>
      order?.openBooking ? OpenBookingCardComponent : DefaultCardComponent,
    [order, DefaultCardComponent, OpenBookingCardComponent]
  );

  // Render
  return <CardComponent order={order} {...props} />;
}
