import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
} from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { pieChartOptions } from './config';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip
);

export default function Pizza({ data }) {
  return <Pie options={pieChartOptions} data={data} />;
}
