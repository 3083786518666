import { useCallback } from 'react';
import Pagination from '../pages/Pagination.tsx';
import loadSearchStatus from 'src/portao3-legacy/constants/loadSearchStatus';
import SearchLoading from './SearchLoading.tsx';
import usePagination from 'src/portao3-legacy/hooks/usePagination';
import useFilterItems from 'src/portao3-legacy/hooks/useFilterItems/useFilterItems';
import NoResults from 'src/portao3-legacy/components/search/NoResults.js';

import {
  IResultItemsWrapper,
  TSimpleSearchParams,
} from 'src/portao3-legacy/types/orders/orders.ts';
import { sortSearchItems } from 'src/portao3-legacy/utils/orderSort.ts';

export default function SearchResultWrapper<
  TSearchItem,
  TSearchParams,
  TReservationParams,
  TParams
>({
  isLoading,
  filterQueries,
  items,
  LoadingComponent,
  status,
  children,
  itemsPerPage,
  params,
  searchParams,
  getRemainingSearch,
  formatParams,
  orderType,
}: IResultItemsWrapper<
  TSearchItem,
  TSearchParams,
  TReservationParams,
  TParams
>) {
  // States
  const sortItems = useCallback(
    (items: TSearchItem[]) =>
      sortSearchItems(items, orderType) as TSearchItem[],
    [orderType]
  );

  // Hooks
  const filteredItems = useFilterItems({
    queries: filterQueries,
    items,
    params,
    formatParams,
    sortItems,
  });

  const {
    currentPage,
    paginatedItems,
    goToPage,
    shouldShowPagination,
    numberOfPages,
  } = usePagination({
    items: filteredItems,
    itemsPerPage,
    numberOfItems: (searchParams as TSimpleSearchParams)?.totalItems,
    numberOfFetchedItems: items.length,
    getRemainingSearch,
    isLoading,
    status,
  });

  // Render
  if (isLoading) return <SearchLoading Component={LoadingComponent} />;

  if (status === loadSearchStatus.EMPTY || !filteredItems.length) {
    return (
      <div className="search-results empty">
        <NoResults />
      </div>
    );
  }

  return (
    <div className="search-results">
      {children ? children({ items: filteredItems, paginatedItems }) : null}

      <Pagination
        show={shouldShowPagination}
        currentPage={currentPage}
        totalPage={numberOfPages}
        onChangePage={goToPage}
      />
    </div>
  );
}
