import React, { useMemo } from 'react';
import FlightReservationInformation from './components/FlightReservationInformation';
import HotelReservationInformation from './components/HotelReservationInformation';
import CarReservationInformation from './components/CarReservationInformation';
import BusReservationInformation from './components/BusReservationInformation';

export default function ReservationInformation({ order, reservations }) {
  const Component = useMemo(() => {
    switch (order.type) {
      case 'hotel':
        return HotelReservationInformation;
      case 'car':
        return CarReservationInformation;
      case 'flight':
        return FlightReservationInformation;
      case 'bus':
        return BusReservationInformation;
      default:
        return null;
    }
  }, [order.type]);

  return Component ? (
    <Component order={order} reservations={reservations} />
  ) : null;
}
