function objectValidator(obj = {}) {
  return Object.keys(obj).length;
}

function validateField(value, validationFunction = (v) => v) {
  return validationFunction(value);
}

/**
 * @param fields = [[property, validationFunction?]]
 */
function validateFields(object, fields) {
  return fields.reduce((currentObj, field) => {
    const [property, validationFunction] = field;

    return validateField(object[property], validationFunction)
      ? currentObj
      : { ...currentObj, [property]: true };
  }, {});
}

export { objectValidator, validateField, validateFields };
