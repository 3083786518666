import classNames from 'classnames';
import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap';
import { TChildren } from 'src/portao3-legacy/types/others';

interface IProps {
  show: boolean | undefined;
  title: string;
  children: TChildren;
  className?: string;
}

export default function SearchFilterCard({
  show,
  title,
  children,
  className,
}: IProps) {
  if (!show) return null;

  return (
    <Card className={classNames('search mb-4', className)}>
      <CardHeader>
        <CardTitle>{title}</CardTitle>
      </CardHeader>
      <CardBody>{children}</CardBody>
    </Card>
  );
}
