import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Card, Row } from 'reactstrap';
import { Colxx } from '../../components/common/CustomBootstrap';
import DateSelection from '../../components/reports/DateSelection';
import useDataSnapshot from '../../hooks/useDataSnapshot';
import firebaseApp from '../../services/Firebase';
import { getDocument } from '../../utils/firebase';
import NoResults from '../../components/search/NoResults';
import { stringToMoney } from '../../utils/money';
import UsersList from '../../components/UsersList';
import SupplierIcon from './SupplierIcon';
import LoaderCardColumns from '../../components/loaders/LoaderCardColumns';
import PaginatedData from '../../components/common/PaginatedData';
import { useIntl } from 'react-intl';
import { getCNAEFromSupplier } from '../../utils/suppliers';

function SupplierItem({ item, supplier = {}, getData }) {
  const { messages } = useIntl();

  const { name, total, numItems, since, users, service } = useMemo(() => {
    const numItems = item.items.length;
    const first = item.items.sort((a, b) => a.date.localeCompare(b.date))[0];

    return {
      numItems,
      total: stringToMoney(item.total, 2),
      name: (
        supplier.nomeFantasia ||
        supplier.nomeEmpresarial ||
        ''
      ).toLowerCase(),
      since: `desde ${moment(first.date).format('DD/MM/YYYY')}`,
      users: Object.keys(
        item.items.reduce((obj, item) => {
          obj[item.passengerId] = true;
          return obj;
        }, {})
      ),
      service: getCNAEFromSupplier(supplier, messages).toLowerCase(),
    };
  }, [supplier, item]);

  const fetchUser = (id) => {
    return getData(`user-${id}`, () =>
      getDocument(() => firebaseApp.getUserFromId(id).get())
    );
  };

  return (
    <Card className="order-card budget-card mb-3 py-4 px-3 w-100 cursor-default">
      <Row className="align-items-center mx-0">
        <Colxx>
          <div className="d-flex align-items-center">
            <SupplierIcon supplier={supplier} />
            <div className="ml-2">
              <h2 className="fs-1rem text-capitalize text-dark font-primary m-0 p-0 lh-1-1">
                {name}
              </h2>
              <p className="m-0 p-0 text-muted lh-1 mt-1 fs-0-7rem capitalize-first">
                {service}
              </p>
            </div>
          </div>
        </Colxx>
        <Colxx className="text-center fs-0-8rem">{numItems} itens</Colxx>
        <Colxx className="d-flex align-items-center justify-content-center">
          <UsersList users={users} fetchFunction={fetchUser} maxUsers={5} />
        </Colxx>
        <Colxx className="text-right">
          <p className="m-0 p-0 lh-1-25 fs-1rem">{total}</p>
          <p className="m-0 p-0 fs-0-7rem text-muted lh-1 mt-1">{since}</p>
        </Colxx>
      </Row>
    </Card>
  );
}

export default function SuppliersView({ getData }) {
  const { user } = useSelector(({ auth }) => auth);

  const [suppliers, setSuppliers] = useState([]);
  const [loading, setLoading] = useState(true);

  const [dateSelection, setDateSelection] = useState({
    startDate: moment().startOf('month').startOf('day').unix(),
    endDate: moment().endOf('day').unix(),
  });

  const [items] = useDataSnapshot(
    {
      fetchFunction: () => {
        setLoading(true);

        const startDate = moment
          .unix(dateSelection.startDate)
          .toISOString(true);
        const endDate = moment.unix(dateSelection.endDate).toISOString(true);

        return firebaseApp.getBudgetItemsWithSuppliersFromOrganization(
          user.organizationId,
          startDate,
          endDate
        );
      },
      formatFunction: (data) =>
        Object.values(
          data.reduce((obj, value) => {
            const { cancelled = false, supplier, fare } = value;

            if (supplier && !cancelled) {
              const supplierObj = obj[supplier];

              if (supplierObj) {
                supplierObj.items.push(value);
                supplierObj.total += fare.total || 0;
              } else
                obj[supplier] = {
                  supplier,
                  items: [value],
                  total: fare.total || 0,
                };
            }

            return obj;
          }, {})
        ).sort((a, b) => b.total - a.total),
    },
    [dateSelection]
  );

  // Effects
  useEffect(() => {
    if (items) {
      loadSuppliers();
    }
  }, [items]);

  // Functions
  const loadSuppliers = async () => {
    try {
      const suppliers = await Promise.all(
        items.map(({ supplier }) =>
          getData(`sup-${supplier}`, () =>
            getDocument(() => firebaseApp.getSupplierById(supplier).get())
          )
        )
      );

      setSuppliers(
        suppliers.reduce((obj, supplier) => {
          if (supplier) {
            obj[supplier.id] = supplier;
          }

          return obj;
        }, {})
      );
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const setCustomDate = (start, end) => {
    setDateSelection({
      startDate: moment(start).startOf('day').unix(),
      endDate: moment(end).endOf('day').unix(),
    });
  };

  // Render
  const renderItems = () => {
    if (items.length) {
      return (
        <Row className="mx-0">
          <PaginatedData
            items={items}
            dependencies={[items]}
            render={(item) => (
              <SupplierItem
                key={item.supplier}
                item={item}
                supplier={suppliers[item.supplier]}
                getData={getData}
              />
            )}
          />
        </Row>
      );
    } else
      return (
        <div className="w-100 d-flex justify-content-center">
          <NoResults />
        </div>
      );
  };

  const renderContent = () => {
    if (loading)
      return (
        <div>
          <LoaderCardColumns />
          <LoaderCardColumns />
          <LoaderCardColumns />
          <LoaderCardColumns />
          <LoaderCardColumns />
        </div>
      );
    else return renderItems();
  };

  return (
    <div className="reports mt-3 mb-5 pb-4">
      <Row className="mx-0 p-0">
        <Colxx xxs="12" className="mb-4 text-right p-0">
          <DateSelection
            dateSelection={dateSelection}
            setDateSelection={setCustomDate}
          />
        </Colxx>
      </Row>
      {renderContent()}
    </div>
  );
}
