import React from 'react';

import ContentLoader from 'react-content-loader';

export default function LoaderESG() {
  return (
    <div className="esg-label">
      <div className="esg-icon" />
      <ContentLoader
        speed={2}
        width={450}
        height={25}
        viewBox="0 0 450 25"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
      >
        <rect x="0" y="0" rx="3" ry="3" width="450" height="10" />
        <rect x="0" y="15" rx="3" ry="3" width="200" height="10" />
      </ContentLoader>
    </div>
  );
}
