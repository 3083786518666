import { toggleChecklistItemDoneInFirebase } from '../../components/checklist';
import {
  RESET_CHECKLIST,
  SET_CHECKLIST,
  SET_CHECKLIST_COMPLETE,
  SET_CHECKLIST_OPEN,
  TOGGLE_ITEM,
} from '../actions';

const INITIAL_STATE = {};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_CHECKLIST: {
      const { id, checklist } = action.payload;
      if (checklist) {
        return { ...state, [id]: checklist };
      } else return state;
    }
    case TOGGLE_ITEM: {
      const { checklistId, itemId, value } = action.payload;
      const { [checklistId]: currentChecklist, ...others } = state;

      if (currentChecklist && !currentChecklist.completed) {
        const { [itemId]: item, ...otherItems } = currentChecklist.items;

        if (item) {
          const done = value !== undefined ? value : !item.done;
          const newItem = {
            ...item,
            done,
          };

          toggleChecklistItemDoneInFirebase(checklistId, itemId, done);

          return {
            ...others,
            [checklistId]: {
              ...currentChecklist,
              items: {
                ...otherItems,
                [itemId]: newItem,
              },
            },
          };
        }
      }

      return state;
    }
    case SET_CHECKLIST_OPEN: {
      const { checklistId, isOpen } = action.payload;
      const selectedChecklist = state[checklistId];
      if (selectedChecklist && !selectedChecklist.completed) {
        selectedChecklist.open = isOpen;
        return { ...state, [checklistId]: { ...selectedChecklist } };
      } else return state;
    }
    case SET_CHECKLIST_COMPLETE: {
      const { checklistId, completed } = action.payload;
      const selectedChecklist = state[checklistId];
      if (selectedChecklist) {
        return { ...state, [checklistId]: { ...selectedChecklist, completed } };
      } else return state;
    }
    case RESET_CHECKLIST:
      return INITIAL_STATE;
    default:
      return state;
  }
};
