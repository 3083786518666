import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  InputGroup,
  Button,
  ModalBody,
  ModalFooter,
  Label,
  Alert,
} from 'reactstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import MaskedInput from 'react-text-mask';
import moment from 'moment';
import { dateMask, cpfMask, phoneMask } from '../../utils/masks';

import 'react-credit-cards/es/styles-compiled.css';

import IntlMessages from '../../helpers/IntlMessages';
import messages from '../../lang/messages';

import UserSchema from '../../schemas/user';
import {
  countries,
  DEFAULT_NATIONALITY,
  getCountriesAsValueLabel,
} from '../../constants/countries';

import firebaseApp from '../../services/Firebase';
import User from '../../services/User';
import { useIntl } from 'react-intl';
import { trackPropertiesSegment } from '../../utils/segment';
import { maxNameLength } from '../../constants/defaultValues';
import Dialog from '../../components/Dialog';
import { getUserNationality } from '../../utils/nationality';
import {
  convertIdNameToValueLabel,
  removeUndefinedFromObject,
} from '../../utils/others';
import FixedSelect from '../../components/common/FixedSelect';
import useCommonInformation from '../../hooks/useCommonInformation';
import useShowToggle from '../../hooks/useShowToggle';
import useDataArchive from '../../hooks/useDataArchive';

const PassportCountries = Object.keys(countries)
  .filter((value) => value)
  .map((value) => ({
    value,
    label: countries[value],
  }));

function ExpiredWarning() {
  return (
    <div className="warning-feedback">
      <abbr title={messages['forms.validation.document.expired']}>
        <div className="glyph-icon simple-icon-exclamation"></div>
      </abbr>
    </div>
  );
}

export default function ProfileTab({ toggleModal, userId, editUser }) {
  const { user, organization } = useSelector((state) => state.auth);
  const { messages } = useIntl();
  const { deleteDataFromArchive } = useDataArchive();

  // States
  const [shouldLoadCostCenters, _c, toggleLoadCostCenters] = useShowToggle();
  const [shouldLoadProjects, _p, toggleLoadProjects] = useShowToggle();

  const { costCenters, loadingCostCenters, projects, loadingProjects } =
    useCommonInformation(user.organizationId, {
      shouldLoadCostCenters,
      shouldLoadProjects,
      shouldLoadMotives: false,
      shouldLoadTags: false,
      initialLoading: false,
    });

  const costCentersOptions = [{}, ...costCenters];

  const [hasError, setError] = useState(null);
  const [deletingUser, setDeletingUser] = useState(false);

  const Countries = useMemo(() => getCountriesAsValueLabel(), []);
  const userNationality = useMemo(() => {
    const userNationality = getUserNationality({
      user: editUser || user,
      organization,
    });

    return userNationality
      ? Countries.find(({ value }) => value === userNationality)
      : null;
  }, [user, editUser, organization, Countries]);

  // Form states
  const [passportCountry, setPassportCountry] = useState(() => {
    const passportCountry = editUser?.documents?.passport?.country;
    return passportCountry
      ? PassportCountries.find(({ value }) => value === passportCountry)
      : null;
  });

  // Functions
  const updateUser = async (values, setSubmitting) => {
    try {
      const { costCenter, project, gender, nationality } = values;

      await firebaseApp.updateUserFromId(
        userId,
        removeUndefinedFromObject({
          ...values,
          costCenter: costCenter
            ? {
                id: costCenter.value,
                name: costCenter.label,
              }
            : {},
          project: project
            ? {
                id: project.value,
                name: project.label,
              }
            : {},
          firstName: values.firstName.trim(),
          lastName: values.lastName.trim(),
          gender: gender?.value,
          nationality: nationality?.value,
        })
      );

      setSubmitting(false);

      trackPropertiesSegment('User info updated', {
        whoUpdated: user,
        userInfo: values,
      });

      deleteDataFromArchive('users');

      toggleModal();
    } catch (err) {
      setSubmitting(false);

      setError(messages['user.GeneralException']);
      console.error('Update user', err);
    }
  };

  const deleteUser = async () => {
    try {
      await User.deleteUser(userId);

      deleteDataFromArchive('users');

      toggleModal();
    } catch (err) {
      setError(messages['user.GeneralException']);
      console.error(err);
    }
  };

  const handleDeleteUser = () => {
    setDeletingUser(true);
  };

  return (
    <>
      <Formik
        initialValues={{
          firstName: editUser.firstName || '',
          lastName: editUser.lastName || '',
          gender: editUser.gender
            ? {
                value: editUser.gender,
                label: messages[`user.gender.${editUser.gender}`],
              }
            : {},
          birthdate: editUser.birthdate || '',
          email: editUser.email || '',
          role: editUser.role || '',
          companyId: editUser.companyId || '',
          nationality: userNationality,
          documents: {
            rg: {
              number:
                editUser.documents && editUser.documents.rg
                  ? editUser.documents.rg.number
                  : '',
              emitter:
                editUser.documents && editUser.documents.rg
                  ? editUser.documents.rg.emitter
                  : '',
            },
            cpf: {
              number: editUser.documents?.cpf?.number
                ? editUser.documents.cpf.number
                : '',
            },
            identity: {
              number: editUser.documents?.identity?.number
                ? editUser.documents.identity.number
                : '',
            },
            cnh: {
              number:
                editUser.documents && editUser.documents.cnh
                  ? editUser.documents.cnh.number
                  : '',
              validUntil:
                editUser.documents && editUser.documents.cnh
                  ? editUser.documents.cnh.validUntil
                  : '',
            },
            passport: {
              country:
                editUser.documents && editUser.documents.passport
                  ? editUser.documents.passport.country
                  : '',
              number:
                editUser.documents && editUser.documents.passport
                  ? editUser.documents.passport.number
                  : '',
              validUntil:
                editUser.documents && editUser.documents.passport
                  ? editUser.documents.passport.validUntil
                  : '',
            },
          },
          costCenter: convertIdNameToValueLabel(editUser.costCenter),
          project: convertIdNameToValueLabel(editUser.project),
          mobilePhoneNumber: editUser.mobilePhoneNumber || '',
        }}
        validationSchema={UserSchema}
        onSubmit={(values, { setSubmitting }) => {
          updateUser(values, setSubmitting);
        }}
      >
        {({
          isSubmitting,
          values,
          handleChange,
          handleBlur,
          setFieldValue,
        }) => (
          <Form className="custom-default user-profile-form">
            {hasError && (
              <Alert color="danger" className="mt-2 mb-2">
                {hasError}
              </Alert>
            )}

            <ModalBody className="mt-0 pt-1">
              <h5 className="mt-4">
                {
                  messages[
                    'containers.users.user-modal-profile-tab.personal-data'
                  ]
                }
              </h5>
              <div className="mb-1">
                <InputGroup>
                  <Label className="form-group has-top-label mb-0">
                    <Field
                      type="input"
                      name="firstName"
                      className="form-control"
                      autoComplete="given-name"
                      maxLength={
                        maxNameLength - (values.lastName?.trim().length || 0)
                      }
                    />
                    <IntlMessages id="user.firstName" />
                    <ErrorMessage
                      name="firstName"
                      component="div"
                      className="invalid-feedback d-block"
                    />
                  </Label>
                  <Label className="form-group has-top-label mb-0">
                    <Field
                      type="input"
                      name="lastName"
                      className="form-control"
                      autoComplete="family-name"
                      maxLength={
                        maxNameLength - (values.firstName?.trim().length || 0)
                      }
                    />
                    <IntlMessages id="user.lastName" />
                    <ErrorMessage
                      name="lastName"
                      component="div"
                      className="invalid-feedback d-block"
                    />
                  </Label>
                </InputGroup>
              </div>
              <div className="mb-1">
                <Label className="form-group has-top-label mb-0 w-100">
                  <FixedSelect
                    className="react-select"
                    classNamePrefix="react-select"
                    isClearable={false}
                    placeholder=""
                    value={values.gender}
                    noOptionsMessage={() =>
                      messages['general.no-options-found']
                    }
                    options={[
                      {
                        value: 'MALE',
                        label: messages[`user.gender.MALE`],
                      },
                      {
                        value: 'FEMALE',
                        label: messages[`user.gender.FEMALE`],
                      },
                    ]}
                    onChange={(gender) => setFieldValue('gender', gender)}
                    maxMenuHeight={150}
                  />
                  <IntlMessages id="user.gender" />
                  <ErrorMessage
                    name="gender"
                    component="div"
                    className="invalid-feedback d-block"
                  />
                </Label>
              </div>
              <div className="mb-1">
                <Label className="form-group has-top-label mb-0 w-100">
                  <Field name="birthdate" autoComplete="bday">
                    {({ field }) => (
                      <MaskedInput
                        type="text"
                        {...field}
                        mask={dateMask}
                        id="birthdate"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="form-control"
                      />
                    )}
                  </Field>
                  <IntlMessages id="user.birthdate" />
                  <ErrorMessage
                    name="birthdate"
                    component="div"
                    className="invalid-feedback d-block"
                  />
                </Label>
              </div>
              <div className="mb-1">
                <Label className="form-group has-top-label mb-0 w-100">
                  <Field
                    type="email"
                    name="email"
                    className="form-control bg-white"
                    autoComplete="email"
                    readOnly
                  />
                  <IntlMessages id="user.email" />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="invalid-feedback d-block"
                  />
                </Label>
              </div>
              <div className="mb-1">
                <Label className="form-group has-top-label mb-0 w-100">
                  <Field name="mobilePhoneNumber" autoComplete="bday">
                    {({ field }) =>
                      values.nationality === DEFAULT_NATIONALITY ? (
                        <MaskedInput
                          type="text"
                          {...field}
                          mask={phoneMask}
                          id="mobilePhoneNumber"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="form-control"
                        />
                      ) : (
                        <Field
                          type="text"
                          name="mobilePhoneNumber"
                          className="form-control bg-white"
                          autoComplete="mobilePhoneNumber"
                        />
                      )
                    }
                  </Field>
                  <IntlMessages id="user.mobilePhoneNumber" />
                  <ErrorMessage
                    name="mobilePhoneNumber"
                    component="div"
                    className="invalid-feedback d-block"
                  />
                </Label>
              </div>
              <div className="mb-1">
                <Label className="form-group has-top-label mb-0 w-100">
                  <FixedSelect
                    className="react-select"
                    classNamePrefix="react-select"
                    isClearable={false}
                    placeholder=""
                    value={values.nationality}
                    noOptionsMessage={() =>
                      messages['general.no-options-found']
                    }
                    options={Countries}
                    onChange={(nationality) =>
                      setFieldValue('nationality', nationality)
                    }
                    maxMenuHeight={150}
                  />
                  <IntlMessages id="user.nationality" />
                  <ErrorMessage
                    name="nationality"
                    component="div"
                    className="invalid-feedback d-block"
                  />
                </Label>
              </div>

              <h5 className="mt-4">
                {messages['containers.users.user-modal-profile-tab.company']}
              </h5>
              <div className="mb-1">
                <Label className="form-group has-top-label mb-0 w-100">
                  <Field type="input" name="role" className="form-control" />
                  <IntlMessages id="user.role" />
                  <ErrorMessage
                    name="role"
                    component="div"
                    className="invalid-feedback d-block"
                  />
                </Label>
              </div>
              <div className="mb-1">
                <Label className="form-group has-top-label mb-0 w-100">
                  <Field
                    type="input"
                    name="companyId"
                    className="form-control"
                  />
                  <IntlMessages id="user.companyId" />
                  <ErrorMessage
                    name="companyId"
                    component="div"
                    className="invalid-feedback d-block"
                  />
                </Label>
              </div>
              <div className="mb-1">
                <Label className="form-group has-top-label mb-0 w-100">
                  <FixedSelect
                    className="react-select"
                    classNamePrefix="react-select"
                    isClearable={false}
                    placeholder=""
                    value={values.costCenter}
                    noOptionsMessage={() =>
                      messages['general.no-options-found']
                    }
                    options={costCentersOptions}
                    onChange={(costCenter) =>
                      setFieldValue('costCenter', costCenter)
                    }
                    maxMenuHeight={150}
                    showOnlyX={50}
                    loadingMessage={() => messages['general.loading']}
                    onMenuOpen={() =>
                      !shouldLoadCostCenters && toggleLoadCostCenters()
                    }
                    isLoading={loadingCostCenters}
                  />
                  <IntlMessages id="user.costCenter" />
                  <ErrorMessage
                    name="costCenter"
                    component="div"
                    className="invalid-feedback d-block"
                  />
                </Label>
              </div>
              <div className="mb-1">
                <Label className="form-group has-top-label mb-0 w-100">
                  <FixedSelect
                    className="react-select"
                    classNamePrefix="react-select"
                    isClearable={false}
                    placeholder=""
                    value={values.project}
                    noOptionsMessage={() =>
                      messages['general.no-options-found']
                    }
                    options={projects}
                    onChange={(project) => setFieldValue('project', project)}
                    maxMenuHeight={150}
                    showOnlyX={50}
                    loadingMessage={() => messages['general.loading']}
                    onMenuOpen={() =>
                      !shouldLoadProjects && toggleLoadProjects()
                    }
                    isLoading={loadingProjects}
                  />
                  <IntlMessages id="user.project" />
                  <ErrorMessage
                    name="project"
                    component="div"
                    className="invalid-feedback d-block"
                  />
                </Label>
              </div>

              <h5 className="mt-4">
                {messages['containers.users.user-modal-profile-tab.documents']}
              </h5>
              {values.nationality?.value === DEFAULT_NATIONALITY ? (
                <>
                  <div className="mb-1">
                    <Label className="form-group has-top-label mb-0 w-100">
                      <Field name="documents.cpf.number">
                        {({ field }) => (
                          <MaskedInput
                            type="text"
                            {...field}
                            mask={cpfMask}
                            id="documents.cpf.number"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="form-control"
                          />
                        )}
                      </Field>
                      <IntlMessages id="user.cpf" />
                      <ErrorMessage
                        name="documents.cpf.number"
                        component="div"
                        className="invalid-feedback d-block"
                      />
                    </Label>
                  </div>
                  <div className="mb-1">
                    <InputGroup>
                      <Label className="form-group has-top-label mb-0">
                        <Field
                          type="input"
                          name="documents.rg.number"
                          className="form-control"
                          autoComplete="given-name"
                        />
                        <IntlMessages id="user.rgNumber" />
                        <ErrorMessage
                          name="documents.rg.number"
                          component="div"
                          className="invalid-feedback d-block"
                        />
                      </Label>
                      <Label className="form-group has-top-label mb-0">
                        <Field
                          type="input"
                          name="documents.rg.emitter"
                          className="form-control"
                          autoComplete="given-name"
                        />
                        <IntlMessages id="user.rgEmitter" />
                        <ErrorMessage
                          name="documents.rg.emitter"
                          component="div"
                          className="invalid-feedback d-block"
                        />
                      </Label>
                    </InputGroup>
                  </div>
                  <div className="mb-1">
                    <InputGroup>
                      <Label className="form-group has-top-label mb-0">
                        <Field
                          type="input"
                          name="documents.cnh.number"
                          className="form-control"
                          autoComplete="given-name"
                        />
                        <IntlMessages id="user.cnhNumber" />
                        <ErrorMessage
                          name="documents.cnh.number"
                          component="div"
                          className="invalid-feedback d-block"
                        />
                      </Label>
                      <Label className="form-group has-top-label mb-0">
                        <Field
                          name="documents.cnh.validUntil"
                          autoComplete="bday"
                        >
                          {({ field }) => (
                            <MaskedInput
                              type="text"
                              {...field}
                              mask={dateMask}
                              id="documents.cnh.validUntil"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className="form-control"
                            />
                          )}
                        </Field>
                        <IntlMessages id="user.cnhValidUntil" />
                        <ErrorMessage
                          name="documents.cnh.validUntil"
                          component="div"
                          className="invalid-feedback d-block"
                        />
                        {moment(
                          values.documents.cnh.validUntil,
                          ['DD/MM/YYYY'],
                          true
                        ).isValid() &&
                          moment(
                            values.documents.cnh.validUntil,
                            ['DD/MM/YYYY'],
                            true
                          ).isBefore(moment()) && <ExpiredWarning />}
                      </Label>
                    </InputGroup>
                  </div>
                </>
              ) : (
                <div className="mb-1">
                  <Label className="form-group has-top-label mb-0 w-100">
                    <Field
                      type="input"
                      name="documents.identity.number"
                      className="form-control"
                      autoComplete="given-name"
                    />
                    <IntlMessages id="user.identity" />
                    <ErrorMessage
                      name="documents.identity.number"
                      component="div"
                      className="invalid-feedback d-block"
                    />
                  </Label>
                </div>
              )}

              <h5 className="mt-4">
                {messages['containers.users.user-modal-profile-tab.passport']}
              </h5>
              <div className="mb-1">
                <Label className="form-group has-top-label mb-0 w-100">
                  <FixedSelect
                    className="react-select"
                    classNamePrefix="react-select"
                    isClearable={false}
                    placeholder=""
                    value={passportCountry}
                    noOptionsMessage={() =>
                      messages['general.no-options-found']
                    }
                    options={PassportCountries}
                    onChange={(passportCountries) => {
                      setFieldValue(
                        'documents.passport.country',
                        passportCountries.value
                      );

                      setPassportCountry(passportCountries);
                    }}
                    maxMenuHeight={150}
                  />
                  <IntlMessages id="user.passportCountry" />
                  <ErrorMessage
                    name="documents.passport.country"
                    component="div"
                    className="invalid-feedback d-block"
                  />
                </Label>
              </div>
              <div className="mb-1">
                <InputGroup>
                  <Label className="form-group has-top-label mb-0">
                    <Field
                      type="input"
                      name="documents.passport.number"
                      className="form-control"
                      autoComplete="given-name"
                    />
                    <IntlMessages id="user.passportNumber" />
                    <ErrorMessage
                      name="documents.passport.number"
                      component="div"
                      className="invalid-feedback d-block"
                    />
                  </Label>
                  <Label className="form-group has-top-label mb-0">
                    <Field
                      name="documents.passport.validUntil"
                      autoComplete="bday"
                    >
                      {({ field }) => (
                        <MaskedInput
                          type="text"
                          {...field}
                          mask={dateMask}
                          id="documents.passport.validUntil"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="form-control"
                        />
                      )}
                    </Field>
                    <IntlMessages id="user.passportValidUntil" />
                    <ErrorMessage
                      name="documents.passport.validUntil"
                      component="div"
                      className="invalid-feedback d-block"
                    />
                    {moment(
                      values.documents.passport.validUntil,
                      ['DD/MM/YYYY'],
                      true
                    ).isValid() &&
                      moment(
                        values.documents.passport.validUntil,
                        ['DD/MM/YYYY'],
                        true
                      ).isBefore(moment().add(6, 'months')) && (
                        <ExpiredWarning />
                      )}
                  </Label>
                </InputGroup>
              </div>
            </ModalBody>
            <ModalFooter className="mb-5">
              <div className="d-flex align-items-center">
                {user.uid !== userId && (
                  <Button
                    onClick={handleDeleteUser}
                    color="empty"
                    size="sm"
                    disabled={isSubmitting}
                    className="mr-5 mb-5"
                  >
                    <IntlMessages id="forms.action.delete" />
                  </Button>
                )}

                <Button
                  type="submit"
                  color="primary"
                  outline
                  size="lg"
                  disabled={isSubmitting}
                  className="mb-5"
                >
                  <IntlMessages id="forms.action.save" />
                </Button>
              </div>
            </ModalFooter>
          </Form>
        )}
      </Formik>

      <Dialog
        isOpen={deletingUser}
        toggle={() => setDeletingUser(false)}
        onYes={deleteUser}
        title={messages['admin.users.remove-user.title']}
      >
        <p className="m-0">{messages['admin.users.remove-user.description']}</p>
      </Dialog>
    </>
  );
}
