import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
} from 'reactstrap';
import { Formik, Form, Field, FieldArray, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import map from 'lodash/map';
import { useIntl } from 'react-intl';
import IntlMessages from '../../helpers/IntlMessages';

import firebaseApp from '../../services/Firebase';
import { trackPropertiesSegment } from '../../utils/segment';

export default function GroupModal(props) {
  const [group, setGroup] = useState({});
  const [passengers, setPassengers] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const { messages } = useIntl();

  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    (async () => {
      if (props.groupId !== 'new') await loadGroup();
      await loadPassengers();

      setLoading(false);
    })();
  }, []);

  // Load Data
  const loadPassengers = async () => {
    const usersSnap = await firebaseApp
      .getUsersFromOrganization(user.organizationId)
      .get();

    const passengers = [];
    usersSnap.forEach((userSnap) => {
      passengers.push({
        value: userSnap.id,
        label: `${userSnap.data().firstName} ${userSnap.data().lastName}`,
      });
    });

    setPassengers(passengers);
    return;
  };

  const loadGroup = async () => {
    const groupSnap = await firebaseApp
      .getGroupFromId(user.organizationId, props.groupId)
      .get();

    setGroup(groupSnap.data());
    return;
  };

  // Update User
  const updateGroup = async (values, setSubmitting) => {
    if (props.groupId !== 'new') {
      trackPropertiesSegment('Group updated', { group: values, user });
      await firebaseApp.updateGroupFromOrganizationAndId(
        user.organizationId,
        props.groupId,
        values
      );
    } else {
      trackPropertiesSegment('New group created', { group: values, user });
      await firebaseApp.createGroupFromOrganization(
        user.organizationId,
        values
      );
    }

    setSubmitting(false);
    props.toggleModal();
  };

  const deleteGroup = async () => {
    if (props.groupId !== 'new') {
      try {
        await firebaseApp.deleteGroupFromOrganizationAndId(
          user.organizationId,
          props.groupId
        );
        props.toggleModal();
      } catch (err) {
        console.error(err);
      }
    }
  };

  // Render
  if (isLoading) {
    return (
      <Modal isOpen={true} toggle={props.toggleModal}>
        <ModalBody className="edit-group">
          <div className="loading" />
        </ModalBody>
      </Modal>
    );
  }

  return (
    <Modal isOpen={true} toggle={props.toggleModal}>
      <ModalHeader toggle={props.toggleModal}>
        <IntlMessages id="admin.policy.group.Modal.Add" />
      </ModalHeader>
      <Formik
        initialValues={{
          name: group.name || '',
          users: group.users || [''],
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required(
            messages['forms.validation.name.required']
          ),
          users: Yup.array().of(Yup.string()),
        })}
        onSubmit={(values, { setSubmitting }) => {
          updateGroup(values, setSubmitting);
        }}
      >
        {({ values, isSubmitting }) => (
          <Form>
            <ModalBody className="edit-group">
              <Row className="mb-1">
                <Label className="form-group has-top-label mb-0 w-100">
                  <Field
                    type="input"
                    name="name"
                    className="form-control"
                    autoComplete="given-name"
                  />
                  <IntlMessages id="admin.policy.group.Name" />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="invalid-feedback d-block"
                  />
                </Label>
              </Row>

              <h5 className="mt-4 row">{messages['menu.users']}</h5>
              <div>
                <FieldArray name="users">
                  {(arrayHelpers) => (
                    <div>
                      {map(values.users, (user, index) => (
                        <Row className="mb-1 user-list" key={index}>
                          <div className="action-delete">
                            <Button
                              color="primary"
                              className="ruleAction"
                              onClick={() => {
                                arrayHelpers.remove(index);
                              }}
                            >
                              <div className="glyph-icon simple-icon-minus" />
                            </Button>
                          </div>
                          <Label className="form-group has-top-label mb-0 w-100">
                            <Field
                              as="select"
                              name={`users.${index}`}
                              className="form-control"
                            >
                              <option value=""></option>
                              {map(passengers, (passenger, i) => (
                                <option
                                  key={`user-${index}-${i}`}
                                  value={passenger.value}
                                >
                                  {passenger.label}
                                </option>
                              ))}
                            </Field>
                            <IntlMessages id="admin.policy.group.Username" />
                          </Label>
                        </Row>
                      ))}
                      <Button
                        className="groupAction"
                        onClick={() => {
                          arrayHelpers.push('');
                        }}
                      >
                        {messages['menu.add-user']}
                      </Button>
                    </div>
                  )}
                </FieldArray>
              </div>
            </ModalBody>
            <ModalFooter>
              <div className="d-flex justify-content-between align-items-center">
                {props.groupId !== 'new' && (
                  <Button
                    type="button"
                    color="empty"
                    size="lg"
                    disabled={isSubmitting}
                    className="mr-3 p-0"
                    style={{ fontSize: 10 }}
                    onClick={deleteGroup}
                  >
                    <IntlMessages id="forms.action.delete" />
                  </Button>
                )}
                <Button
                  type="submit"
                  color="primary"
                  outline
                  size="lg"
                  disabled={isSubmitting}
                >
                  <IntlMessages id="forms.action.save" />
                </Button>
              </div>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
