import React from 'react';
import { ReactComponent as BreakfastIcon } from '../../assets/img/icons/meal-breakfast.svg';

export default function RoomMeal({ room, iconColor = '#43418e' }) {
  const boardDescription = room.board.description
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();

  if (boardDescription.includes('sem cafe da manha')) {
    return <div></div>;
  }

  if (boardDescription.includes('cafe da manha continental')) {
    return (
      <img
        className="mr-2"
        src="/assets/img/icons/amenities/meal-breakfast-simple.svg"
        alt={room.board.description}
        title={room.board.description}
      />
    );
  }

  if (boardDescription.includes('cafe da manha')) {
    return (
      <BreakfastIcon
        className="mr-2"
        alt={room.board.description}
        title={room.board.description}
        fill={iconColor}
        style={{ width: '16px' }}
      />
    );
  }

  if (
    boardDescription.includes('pensao') ||
    boardDescription.includes('almoco') ||
    boardDescription.includes('jantar')
  ) {
    return (
      <img
        className="mr-2"
        src="/assets/img/icons/amenities/meal-lunch.svg"
        alt={room.board.description}
        title={room.board.description}
      />
    );
  }

  if (boardDescription.includes('tudo incluso')) {
    return (
      <img
        className="mr-2"
        src="/assets/img/icons/amenities/meal-drinks.svg"
        alt={room.board.description}
        title={room.board.description}
      />
    );
  }

  if (boardDescription.includes('credito')) {
    return (
      <img
        className="mr-2"
        src="/assets/img/icons/amenities/meal-credit.svg"
        alt={room.board.description}
        title={room.board.description}
      />
    );
  }

  return <div></div>;
}
