import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { RefundStatus } from '../../constants/refundStatus';
import { getBudgetTotal } from '../../utils/budget';
import { stringToMoney } from '../../utils/money';
import { getShrinkClass } from '../../utils/text';

export default function BudgetTotalStatus({ budget }) {
  const { messages, formatMessage } = useIntl();

  // States
  const [formattedTotal, shrinkClass = '', budgetTotal] = useMemo(() => {
    const formattedTotal = stringToMoney(
      getBudgetTotal(budget),
      2,
      budget.fare.currency
    );

    return [
      formattedTotal,
      getShrinkClass(formattedTotal),
      budget.budget
        ? stringToMoney(budget.budget, 2, budget.fare.currency)
        : null,
    ];
  }, [budget]);

  // Render
  return (
    <div className="text d-flex flex-column align-items-end">
      <span>{messages['general.total']}</span>

      <div className={shrinkClass} style={{ overflowWrap: 'anywhere' }}>
        {formattedTotal}
      </div>

      {budgetTotal && budget.statusCode > RefundStatus.ANTICIPATION_APPROVED ? (
        <p className="color-alert m-0 p-0">
          {formatMessage(
            {
              id: 'expense.budget-total',
            },
            { budget: budgetTotal }
          )}
        </p>
      ) : null}
    </div>
  );
}
