import React, { PureComponent } from 'react';

import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import config from '../../constants/GoogleMaps';
import map from 'lodash/map';
import pickBy from 'lodash/pickBy';
import HotelProximitySearch from './HotelProximitySearch';

const GOOGLE_API_KEY = config.key;

class HotelMap extends PureComponent {
  constructor(props) {
    super(props);

    this.resultMap = React.createRef();

    this.state = {
      coords: this.filterCoords(props.coords),
      defaultBounds: null,
    };
  }

  //Lifecycle
  componentDidUpdate(prevProps) {
    const { highlightedPin: oldHighlightedPin } = prevProps;
    const { highlightedPin } = this.props;

    if (JSON.stringify(oldHighlightedPin) !== JSON.stringify(highlightedPin)) {
      if (highlightedPin) {
        const bounds = new window.google.maps.LatLngBounds(highlightedPin);
        this.resultMap.current.map.fitBounds(bounds);
      } else {
        if (this.state.defaultBounds)
          this.resultMap.current.map.fitBounds(this.state.defaultBounds);
        else this.defineBounds();
      }
    }
  }

  filterCoords(coords) {
    return pickBy(coords, (coord) => !coord.includes(null));
  }

  mapLoaded(mapProps, map) {
    map.setOptions({ styles: config.mapStyle });

    this.defineBounds();
  }

  defineBounds() {
    const { coords } = this.state;

    const bounds = new window.google.maps.LatLngBounds();
    map(Object.keys(coords), (hotelId) => {
      bounds.extend(
        new window.google.maps.LatLng(coords[hotelId][0], coords[hotelId][1])
      );

      return true;
    });

    this.resultMap.current.map.fitBounds(bounds);
    this.setState({ defaultBounds: bounds });
  }

  filterMarker = (hotelId) => {
    this.props.toggleSelectedHotels(hotelId);
  };

  handleDoubleClick = (event, map, coord) => {
    const { latLng } = coord;

    this.props.toggleHighlightedPin({
      lat: latLng.lat(),
      lng: latLng.lng(),
    });
  };

  // Render
  render() {
    const {
      highlightedPin,
      toggleHighlightedPin,
      withProximitySearch,
      city = '',
      pinIcon = '/assets/img/icons/location_pin_selected.svg',
      selectedPinIcon = '/assets/img/icons/location_pin_selected.svg',
      highlightedPinIcon = '/assets/img/icons/highlighted_pin.svg',
      favoritePinIcon = '/assets/img/icons/star_pin.svg',
      selectedFavoritePinIcon = '/assets/img/icons/dark_star_pin.svg',
      favoritePlacePinIcon = '/assets/img/icons/highlighted_heart_pin.svg',
      selected,
      favoriteHotels = [],
      showFavoritePlaces,
      favoritePlaces = [],
    } = this.props;
    const { coords } = this.state;

    return (
      <div className="hotel-map">
        {withProximitySearch && (
          <HotelProximitySearch
            setHighlightPin={toggleHighlightedPin}
            city={city}
            editable
            selectedAddress=""
          />
        )}

        <Map
          google={this.props.google}
          zoom={14}
          scrollwheel={false}
          keyboardShortcuts={false}
          disableDoubleClickZoom
          zoomControl
          mapTypeControl={false}
          maxZoom={15}
          scaleControl={false}
          streetViewControl={false}
          panControl={false}
          rotateControl={false}
          fullscreenControl={false}
          onReady={(mapProps, map) => this.mapLoaded(mapProps, map)}
          ref={this.resultMap}
          onDblclick={this.handleDoubleClick}
        >
          {map(Object.keys(coords), (hotelId) => {
            let iconUrl = pinIcon;

            if (favoriteHotels.includes(hotelId)) {
              iconUrl = selected.includes(hotelId)
                ? selectedFavoritePinIcon
                : favoritePinIcon;
            } else if (selected.includes(hotelId)) iconUrl = selectedPinIcon;

            return (
              <Marker
                key={`hotel-map-${hotelId}`}
                hotelId={hotelId}
                position={{ lat: coords[hotelId][0], lng: coords[hotelId][1] }}
                onClick={() => {
                  this.filterMarker(hotelId);
                }}
                icon={{
                  url: iconUrl,
                }}
              />
            );
          })}

          {highlightedPin && (
            <Marker
              key="highlighted-pin"
              position={{ lat: highlightedPin.lat, lng: highlightedPin.lng }}
              onClick={() => toggleHighlightedPin(null)}
              icon={{
                url: highlightedPinIcon,
              }}
            />
          )}

          {showFavoritePlaces &&
            favoritePlaces.map(({ id, lat, lng }) => (
              <Marker
                key={id}
                position={{ lat, lng }}
                onClick={() => {
                  toggleHighlightedPin(
                    highlightedPin &&
                      highlightedPin.lat === lat &&
                      highlightedPin.lng === lng
                      ? null
                      : { lat, lng }
                  );
                }}
                icon={{
                  url: favoritePlacePinIcon,
                }}
              />
            ))}
        </Map>
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: GOOGLE_API_KEY,
})(HotelMap);
