export default [
  {
    value: 'car',
    label: 'car',
    src: 'carRental.svg',
  },
  {
    value: 'hotel',
    label: 'hotel',
    src: 'hotel.svg',
  },
  {
    value: 'flight',
    label: 'flight',
    src: 'flight.svg',
  },
  {
    value: 'food',
    label: 'food',
    src: 'food.svg',
  },
  {
    value: 'fuel',
    label: 'fuel',
    src: 'fuel.svg',
  },
  {
    value: 'lazer',
    label: 'lazer',
    src: 'lazer.svg',
  },
  {
    value: 'money',
    label: 'money',
    src: 'money.svg',
  },
  {
    value: 'pharmacy',
    label: 'pharmacy',
    src: 'pharmacy.svg',
  },
  {
    value: 'apple',
    label: 'apple',
    src: 'apple.svg',
  },
  {
    value: 'bank',
    label: 'bank',
    src: 'bank.svg',
  },
  {
    value: 'boat',
    label: 'boat',
    src: 'boat.svg',
  },
  {
    value: 'building',
    label: 'building',
    src: 'building.svg',
  },
  {
    value: 'building2',
    label: 'building2',
    src: 'building2.svg',
  },
  {
    value: 'building3',
    label: 'building3',
    src: 'building3.svg',
  },
  {
    value: 'bus',
    label: 'bus',
    src: 'bus.svg',
  },
  {
    value: 'bulb',
    label: 'bulb',
    src: 'bulb.svg',
  },
  {
    value: 'church',
    label: 'church',
    src: 'church.svg',
  },
  {
    value: 'clothes',
    label: 'clothes',
    src: 'clothes.svg',
  },
  {
    value: 'coffee',
    label: 'coffee',
    src: 'coffee.svg',
  },
  {
    value: 'coffee2',
    label: 'coffee2',
    src: 'coffee2.svg',
  },
  {
    value: 'coin',
    label: 'coin',
    src: 'coin.svg',
  },
  {
    value: 'doctor',
    label: 'doctor',
    src: 'doctor.svg',
  },
  {
    value: 'drink',
    label: 'drink',
    src: 'drink.svg',
  },
  {
    value: 'globe',
    label: 'globe',
    src: 'globe.svg',
  },
  {
    value: 'health',
    label: 'health',
    src: 'health.svg',
  },
  {
    value: 'industry',
    label: 'industry',
    src: 'industry.svg',
  },
  {
    value: 'law',
    label: 'law',
    src: 'law.svg',
  },
  {
    value: 'suitcase',
    label: 'suitcase',
    src: 'suitcase.svg',
  },
  {
    value: 'syringe',
    label: 'syringe',
    src: 'syringe.svg',
  },
  {
    value: 'taxi',
    label: 'taxi',
    src: 'taxi.svg',
  },
  {
    value: 'wine',
    label: 'wine',
    src: 'wine.svg',
  },
];
