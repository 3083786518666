import React from 'react';
import { Row } from 'reactstrap';
import { Colxx } from '../common/CustomBootstrap';
import map from 'lodash/map';
import moment from 'moment';
import { useIntl } from 'react-intl';
import Duration from './Duration';
import StopWait from './StopWait';
import Baggages from './Baggages';

import { registeredBus } from '../../constants/travelValues';

export default function JourneyLarge({ journey, index, legs }) {
  const { messages, formatMessage } = useIntl();

  // Render
  const renderSeats = (leg = {}) => {
    return leg?.seat ? (
      <p className="m-0 p-0 fs-0-7rem font-weight-medium mt-2 pt-1 px-3 py-1 bg-primary rounded text-center">
        {formatMessage({ id: 'travel.bus.seat' }, { seat: leg.seat })}
      </p>
    ) : null;
  };

  return (
    <div className="journey pb-5 mb-5 mt-1">
      {map(legs, (leg, i) => {
        var nextFlight = legs[i + 1];

        return (
          <div key={`journey-${index}-flight-${i}`}>
            <Row className="pl-3 pr-3 mx-0">
              <Colxx xxs="7" className="journey-title d-flex m-0 p-0">
                <img
                  src="/assets/img/icons/search_icon_bus.svg"
                  alt={messages['alts.components.flight.journey.journey']}
                  className="mt-1"
                />
                <div className="ml-2 pl-1">
                  <h3 className="fs-0-9rem m-0 p-0">
                    <span>
                      {journey.departureCity
                        ? journey.departureCity
                        : journey.departureStation}
                    </span>{' '}
                    •{' '}
                    <span>
                      {journey.arrivalCity
                        ? journey.arrivalCity
                        : journey.arrivalStation}
                    </span>
                  </h3>
                  <p className="m-0 p-0 fs-0-8rem text-primary font-weight-medium">
                    {leg.fare.class}
                  </p>
                </div>
              </Colxx>
              <Colxx
                xxs="5"
                className="d-flex flex-column align-items-end m-0 p-0 mt-1"
              >
                <Baggages leg={leg} index={i} />

                {renderSeats(leg)}
              </Colxx>
            </Row>

            <Row className="pl-3 pr-3 mt-4">
              <Colxx xss="6" className="origin">
                {registeredBus[leg.carrier] ? (
                  <div
                    className="carrier"
                    style={{
                      backgroundImage: `url(${
                        registeredBus[leg.carrier].logo
                      })`,
                    }}
                  />
                ) : null}
              </Colxx>
              <Colxx xss="6" className="text-right destination">
                <p className="title">{`${leg.carrier}`}</p>
              </Colxx>
            </Row>

            <Row className="pl-3 pr-3">
              <Colxx xxs="4" className="origin">
                <p>
                  {moment(leg.departureDateTime, [
                    'DD/MM/YYYY HH:mm',
                    'YYYY-MM-DD[T]HH:mm:ss',
                  ]).format('ddd, DD MMM YYYY')}
                </p>
                <p className="highlight">
                  {moment(leg.departureDateTime, [
                    'DD/MM/YYYY HH:mm',
                    'YYYY-MM-DD[T]HH:mm:ss',
                  ]).format('HH:mm')}
                </p>
                <p className="mt-2">
                  <span>{journey.departureCity}</span>
                </p>
                <p className="highlight fs-1rem">{leg.departureStation}</p>
              </Colxx>
              <Colxx xxs="4" className="duration">
                <img
                  src="/assets/img/icons/search_icon_bus.svg"
                  alt={messages['alts.components.flight.journey.duration']}
                />
                {messages['travel.duration']}:{' '}
                <Duration
                  startTime={leg.departureDateTime}
                  finishTime={leg.arrivalDateTime}
                />
              </Colxx>
              <Colxx xxs="4" className="destination">
                <p>
                  {moment(leg.arrivalDateTime, [
                    'DD/MM/YYYY HH:mm',
                    'YYYY-MM-DD[T]HH:mm:ss',
                  ]).format('ddd, DD MMM YYYY')}
                </p>
                <p className="highlight">
                  {moment(leg.arrivalDateTime, [
                    'DD/MM/YYYY HH:mm',
                    'YYYY-MM-DD[T]HH:mm:ss',
                  ]).format('HH:mm')}
                </p>
                <p className="mt-2">{journey.arrivalCity}</p>
                <p className="highlight fs-1rem">{leg.arrivalStation}</p>
              </Colxx>
            </Row>

            {nextFlight && (
              <StopWait
                startTime={leg.arrivalDateTime}
                finishTime={nextFlight.departureDateTime}
                station={leg.arrivalStation}
                cityName={leg.arrivalCity}
              />
            )}
          </div>
        );
      })}
    </div>
  );
}
