import { useIntl, IntlShape } from 'react-intl';
import { TObject } from '../types/others';

interface ICustomIntl extends IntlShape {
  messages: TObject<string>;
}

export default function useCustomIntl() {
  const { messages, ...others } = useIntl();

  return { messages, ...others } as ICustomIntl;
}
