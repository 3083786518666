import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Button } from 'reactstrap';
import appFunctions from '../../../../../services/Functions';
import AlterationFrame from '../../AlterationFrame';
import OptionCard from '../OptionCard';
import Spinner from '../../../../common/Spinner';
import { CANCELLATION_TABS } from '.';
import debounce from 'lodash/debounce';
import useExecuteAction from '../../../../../hooks/useExecuteAction';
import moment from 'moment-timezone';

import { ReactComponent as WalletIcon } from '../../../../../assets/img/icons/wallet.svg';
import { ReactComponent as MoneyIcon } from '../../../../../assets/img/icons/money.svg';

const CANCELLATION_TYPES = {
  CREDIT: 'credit',
  REFUND: 'refund',
  VOID: 'void',
};

export default function OptionsTab({ order, goToInitialTab, setCurrentTab }) {
  const { messages } = useIntl();

  const { loading, error, executeAction } = useExecuteAction({
    defaultErrorMessage: messages['travel.alter.flight.cancellation.error'],
  });

  // States
  const { canVoid, canCancelWithRefund } = useMemo(() => {
    const createdAt = moment
      .unix(order.createdAt)
      .tz('America/Sao_Paulo')
      .endOf('day');

    return {
      canVoid: moment().tz('America/Sao_Paulo').isBefore(createdAt),
      canCancelWithRefund: !['JJ', 'LA'].includes(
        order.provider.validatingCarrier
      ),
    };
  }, [order]);

  // Functions
  const cancelReservation = useCallback(
    debounce(async (selectedCancellation) => {
      try {
        await executeAction(
          appFunctions.cancelFlightReservation(order.id, selectedCancellation)
        );
        setCurrentTab(CANCELLATION_TABS.FINAL);
      } catch (err) {
        console.error(err);
      }
    }, 300),
    []
  );

  // Render
  const renderBottom = () => (
    <div className="d-flex justify-content-end">
      {loading ? null : (
        <Button
          color="link"
          onClick={goToInitialTab}
          className="m-0"
          disabled={loading}
        >
          {messages['general.go-back']}
        </Button>
      )}
    </div>
  );

  return (
    <>
      <AlterationFrame
        title={messages['travel.alter.flight.cancellation.title']}
        subtitle={
          messages['travel.alter.flight.cancellation.options-tab.description']
        }
        BottomBar={renderBottom}
      >
        {loading ? (
          <div className="d-flex align-items-center justify-content-center w-100 h-100">
            <Spinner white relative style={{ width: 60, height: 60 }} />
          </div>
        ) : (
          <>
            {error ? <p className="error-badge mb-4">{error}</p> : null}
            <div className="d-flex" style={{ gap: '20px' }}>
              <OptionCard
                title={
                  messages[
                    'travel.alter.flight.cancellation.options-tab.credit.title'
                  ]
                }
                description={
                  messages[
                    'travel.alter.flight.cancellation.options-tab.credit.description'
                  ]
                }
                buttonText={messages['general.request']}
                onClick={() => cancelReservation(CANCELLATION_TYPES.CREDIT)}
                disabled={loading}
                Icon={MoneyIcon}
              />
              {canCancelWithRefund ? (
                <OptionCard
                  title={
                    messages[
                      'travel.alter.flight.cancellation.options-tab.refund.title'
                    ]
                  }
                  description={
                    messages[
                      'travel.alter.flight.cancellation.options-tab.refund.description'
                    ]
                  }
                  buttonText={messages['general.request']}
                  onClick={() => cancelReservation(CANCELLATION_TYPES.REFUND)}
                  disabled={loading}
                  Icon={WalletIcon}
                />
              ) : null}
              {canVoid ? (
                <OptionCard
                  title={
                    messages[
                      'travel.alter.flight.cancellation.options-tab.void.title'
                    ]
                  }
                  description={
                    messages[
                      'travel.alter.flight.cancellation.options-tab.void.description'
                    ]
                  }
                  buttonText={messages['general.request']}
                  onClick={() => cancelReservation(CANCELLATION_TYPES.VOID)}
                  disabled={loading}
                  Icon={WalletIcon}
                />
              ) : null}
            </div>
          </>
        )}
      </AlterationFrame>
    </>
  );
}
