import React, { useMemo, useState } from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import firebaseApp from '../../../services/Firebase';
import { getDefaultCommonParams } from '../../../utils/reservation';
import useDataArchive from '../../../hooks/useDataArchive';
import { getDocument } from '../../../utils/firebase';
import TabSwitch from '../TabSwitch';
import {
  createAdminstrativeBudgetPayload,
  createPaymentPayload,
} from '../../../utils/budget';
import { useEffect } from 'react';
import PaymentTab from './tabs/PaymentTab';
import BudgetInfoTab from '../BudgetCreatorModal/tabs/BudgetInfoTab';
import moment from 'moment';
import { ADMINISTRATIVE_BUDGET_TYPES } from '../../../constants/administrativeBudgets';
import { getBoletoMaxAmountBasedOnDueDate } from '../../../utils/administrativeCards';
import AdministrativeBudgetCreatorStatus from '../AdministrativeBudgetCreatorStatus';
import ModularBudgetCreationStatus from '../ModularBudgetCreatorStatus';

const TABS = {
  budget: 'budget',
  payment: 'payment',
};

const TAB_COMPONENTS = {
  [TABS.budget]: BudgetInfoTab,
  [TABS.payment]: PaymentTab,
};

export default function AdministrativeBudgetCreatorModal({
  toggleModal = () => {},
  cardId,
  cardType,
  userId,
  onCreateBudget = () => {},
}) {
  const { messages } = useIntl();
  const { passenger, organization } = useSelector(({ auth, timeline }) => ({
    passenger: timeline.passenger || auth.user,
    organization: auth.organization,
  }));

  const { getDataFromArchive } = useDataArchive();

  // Tabs
  const tabs = useMemo(
    () => [
      {
        key: TABS.budget,
        label: messages['refund.modal.tabs.budget'],
      },
    ],
    []
  );

  // States
  const [tab, setTab] = useState(() => tabs[0].key);
  const [budget, setBudget] = useState(() => ({
    ...getDefaultCommonParams(passenger),
    startDate: moment(),
  }));
  const [paymentInfo, setPaymentInfo] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [maxDate, setMaxDate] = useState(null);

  // Effects
  useEffect(() => {
    if (budget.startDate) {
      if (paymentInfo?.type === ADMINISTRATIVE_BUDGET_TYPES.BOLETO) {
        const maxAmount = getBoletoMaxAmountBasedOnDueDate(
          paymentInfo.info,
          budget.startDate
        );

        if (paymentInfo.total > maxAmount)
          setPaymentInfo((payment) => ({ ...payment, total: maxAmount }));
      }

      setBudget((budget) => ({
        ...budget,
        endDate: budget.startDate.clone().add(1, 'day'),
      }));
    }
  }, [budget.startDate]);

  useEffect(() => {
    if (
      paymentInfo?.type === ADMINISTRATIVE_BUDGET_TYPES.BOLETO &&
      paymentInfo?.info
    ) {
      if (paymentInfo.info.final_payment_date) {
        const newMaxDate = moment(
          paymentInfo.info.final_payment_date,
          'YYYY-MM-DD'
        );

        if (budget.startDate && budget.startDate.isAfter(newMaxDate, 'day')) {
          setBudget((budget) => ({
            ...budget,
            startDate: newMaxDate,
          }));
        }

        return setMaxDate(newMaxDate);
      }
    }

    if (maxDate) setMaxDate(null);
  }, [paymentInfo.type, paymentInfo.info]);

  // Function
  const onBudgetChange = (value) => {
    setBudget({
      ...budget,
      ...value,
    });
  };

  const getFormattedBudgetInfo = async () => {
    const {
      name,
      budget: budgetValue,
      startDate,
      endDate,
      refundType = '',
      ...others
    } = budget;

    const budgetOwner =
      !userId || userId === passenger.uid
        ? passenger
        : await getDataFromArchive(`user-${userId}`, () =>
            getDocument(() => firebaseApp.getUserFromId(userId).get())
          );

    const newBudget = {
      name,
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
      ...others,
    };

    if (budgetValue) {
      newBudget['budget'] = budgetValue;
    }

    if (cardId) newBudget['cardId'] = cardId;

    return {
      budget: newBudget,
      budgetOwner,
    };
  };

  const createBudget = async () => {
    try {
      setIsSaving(true);
      const { budget, budgetOwner } = await getFormattedBudgetInfo();

      const budgetPayload = createAdminstrativeBudgetPayload({
        passenger: budgetOwner,
        organization,
        budget,
        parseName: true,
        payment: createPaymentPayload(paymentInfo),
      });

      const docRef = await firebaseApp.createBudget(budgetPayload);

      onCreateBudget({ cardId, budgetId: docRef.id });
      toggleModal();
    } catch (err) {
      console.error(err);
    } finally {
      setIsSaving(false);
    }
  };

  // Render
  return (
    <Modal
      isOpen={true}
      toggle={() => {
        toggleModal();
      }}
      className="reservation refund-creator administrative"
    >
      <ModalBody className="hotel">
        <PerfectScrollbar
          options={{
            suppressScrollX: true,
            wheelPropagation: false,
          }}
        >
          <div className="mb-5 pb-3">
            <TabSwitch
              className="budget-creation-tabs ml-2 mb-2"
              items={tabs}
              currentTab={tab}
              setTab={setTab}
              navLinkClassName="mx-2"
              navTitleClassName="font-primary fs-0-8rem"
            />

            <BudgetInfoTab
              className="h-auto"
              budget={budget}
              onBudgetChange={onBudgetChange}
              setBudget={setBudget}
              administrative
              cardId={cardId}
              maxDate={maxDate}
            />

            <PaymentTab
              budget={budget}
              paymentInfo={paymentInfo}
              setPaymentInfo={setPaymentInfo}
              cardId={cardId}
            />
          </div>
        </PerfectScrollbar>
      </ModalBody>

      <ModalFooter className="p-3">
        <ModularBudgetCreationStatus
          budget={budget}
          cardType={cardType}
          onCreate={createBudget}
          isSaving={isSaving}
          ButtonsComponent={AdministrativeBudgetCreatorStatus}
          paymentInfo={paymentInfo}
        />
      </ModalFooter>
    </Modal>
  );
}
