import React, { useRef } from 'react';
import { useIntl } from 'react-intl';
import CustomAnimatedCheckbox from '../../components/common/CustomCheckbox';
import { PopupModalPositions } from '../../components/common/PopupModal/consts';
import PopupModal from '../../components/common/PopupModal/PopupModal';
import useShowToggle from '../../hooks/useShowToggle';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Button } from 'reactstrap';

export default function BudgetStatusFilterPopup({
  statusFilters,
  setStatusFilters,
  statusFilterOptions = [],
  children,
}) {
  const { messages } = useIntl();

  // Refs
  const targetRef = useRef(null);

  // State
  const [show, _, toggle] = useShowToggle();

  // Function
  const onSelect = (id, isSelected) => {
    setStatusFilters((filters) => {
      if (isSelected) return filters.filter((value) => value !== id);
      return [...filters, id];
    });
  };

  const onClean = () => {
    setStatusFilters([]);
    toggle();
  };

  // Render
  return (
    <>
      <div
        className="budget-filter-popup-container"
        ref={targetRef}
        onClick={toggle}
      >
        {children}
      </div>

      <PopupModal
        className="budget-filter-popup budget-options-popup"
        show={show}
        toggle={toggle}
        target={targetRef}
        showPosition={PopupModalPositions.LEFT}
        calculateStyleOnShow
        blockScroll
        displacementX={-15}
      >
        <div className="content p-4 d-flex flex-column mh-0">
          <h3 className="text-primary font-primary m-0 p-0 fs-1-2rem">
            {messages['budget.filter.title']}
          </h3>
          <p className="m-0 p-0 text-muted fs-0-8rem lh-1-5 mt-1">
            {messages['budget.filter.description']}
          </p>
          <PerfectScrollbar
            className="my-3"
            options={{
              suppressScrollX: true,
              useBothWheelAxes: false,
            }}
          >
            <ul className="m-0 p-0">
              {statusFilterOptions.map(({ value, label }) => {
                const isSelected = statusFilters.includes(value);

                return (
                  <li
                    key={`filter-${value}`}
                    className="d-flex align-items-center my-2 pointer"
                    onClick={() => onSelect(value, isSelected)}
                  >
                    <CustomAnimatedCheckbox
                      checked={isSelected}
                      purple
                      size="1.25rem"
                      onChange={() => onSelect(value, isSelected)}
                      radio={false}
                    />
                    <span className="ml-2 fs-0-8rem">{label}</span>
                  </li>
                );
              })}
            </ul>
          </PerfectScrollbar>

          {statusFilters.length ? (
            <Button className="pretty-btn primary" onClick={onClean}>
              {messages['general.clean']}
            </Button>
          ) : null}
        </div>
      </PopupModal>
    </>
  );
}
