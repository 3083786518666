import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Row, Card, CardBody, Label, Button, Alert } from 'reactstrap';
import { Colxx } from '../../common/CustomBootstrap';
import NumberFormat from 'react-number-format';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';

import Gauge from '../../charts/Gauge';
import { ReactComponent as EcoSvg } from '../../../assets/img/icons/eco.svg';
import { stringToMoney } from '../../../utils/money';

import firebaseApp from '../../../services/Firebase';

import IntlMessages from '../../../helpers/IntlMessages';
import LoaderChart from '../../loaders/LoaderChart';
import LoaderLabelData from '../../loaders/LoaderLabelData';
import LoaderField from '../../loaders/LoaderField';
import LoaderTotal from '../../loaders/LoaderTotal';
import LoaderButton from '../../loaders/LoaderButton';

let pricingTimeout = null;

export default function ESGPurchase() {
  const { messages } = useIntl();

  const [loading, setLoading] = useState(true);
  const [loadingAmount, setLoadingAmount] = useState(true);
  const [creditCards, setCreditCards] = useState([]);
  const [amount, setAmount] = useState({});
  const [status, setStatus] = useState({});
  const [error, setError] = useState('');

  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    (async () => {
      await Promise.all([loadOrganizationsStatus(), loadCreditCards()]);
    })();
  }, []);

  const loadOrganizationsStatus = async () => {
    setLoading(true);

    const status = await firebaseApp.getOrganizationCarbonStatus(
      user.organizationId
    );

    if (status.totalNeutralized === 0) {
      setStatus({
        ...status,
        red: 1,
        none: 9,
        percentage: 0,
      });
    } else {
      const percentage = status.totalNeutralized / status.totalFootprint;
      if (percentage < 0.1) {
        setStatus({
          ...status,
          red: 1,
          none: 9,
          percentage,
        });
      } else if (percentage < 0.5) {
        setStatus({
          ...status,
          red: percentage * 100,
          none: 1 - percentage * 100,
          percentage,
        });
      } else if (percentage < 0.7) {
        setStatus({
          ...status,
          yellow: percentage * 100,
          none: 1 - percentage * 100,
          percentage,
        });
      } else {
        setStatus({
          ...status,
          green: percentage * 100,
          none: 1 - percentage * 100,
          percentage,
        });
      }
    }

    await loadPriceAmount(status.totalFootprint - status.totalNeutralized);

    setLoading(false);
  };

  const loadCreditCards = async () => {
    const creditCardsSnap = await firebaseApp
      .getCreditCardsFromOrganization(user.organizationId)
      .get();

    const creditCards = [];
    creditCardsSnap.forEach((creditCardSnap) => {
      creditCards.push({
        ...creditCardSnap.data(),
        id: creditCardSnap.id,
      });
    });

    setCreditCards(creditCards);
  };

  const loadPriceAmount = async (kgs) => {
    setLoadingAmount(true);
    clearTimeout(pricingTimeout);

    pricingTimeout = setTimeout(async () => {
      const carbonPricing = await firebaseApp.getCarbonPricing(kgs);

      setAmount(carbonPricing);
      setLoadingAmount(false);
    }, 2000);
  };

  // Purchase
  const purchaseEsg = async (values, setSubmitting) => {
    setSubmitting(true);

    try {
      const result = await firebaseApp.makeCarbonPurchase({
        organizationId: user.organizationId,
        kgs: values.kgAmount,
        creditCardId: values.creditCard,
        amount: amount.amount,
      });

      await loadOrganizationsStatus();

      await downloadCertificate(result.id);
    } catch (err) {
      setError(
        'Não foi possível realizar essa compra. Por favor, tente novamente'
      );
    }

    setSubmitting(false);
  };

  const downloadCertificate = async (inoiceId) => {
    setLoading(true);

    try {
      const response = await firebaseApp.downloadCarbonPurchase(
        user.organizationId,
        inoiceId
      );

      var blob = new Blob([response], {
        type: 'application/pdf',
      });

      var url = window.URL.createObjectURL(blob);
      window.open(url);
    } catch (err) {
      setError(
        'Não foi possível baixar esse certificado. Por favor, tente novamente'
      );
    }

    setLoading(false);
  };

  if (loading) {
    return (
      <Colxx sm="12" className="small-cards mb-5">
        <Card className="mb-4 esg-purchase">
          <CardBody>
            <Row>
              <Colxx xs="3" xl="2">
                <LoaderChart />
              </Colxx>
              <Colxx
                xs="3"
                xl="4"
                className="d-flex flex-column justify-content-center"
              >
                <LoaderLabelData />
                <LoaderLabelData />
              </Colxx>
              <Colxx
                xs="6"
                xl="6"
                className="d-flex flex-column justify-content-center esg-total"
              >
                <Row>
                  <Colxx xs="6" xl="6">
                    <LoaderField />
                    <LoaderField />
                  </Colxx>
                  <Colxx xs="6" xl="6">
                    <div className="text-right">
                      <div className="text mb-2">
                        <LoaderTotal />
                      </div>
                      <LoaderButton />
                    </div>
                  </Colxx>
                </Row>
              </Colxx>
            </Row>
          </CardBody>
        </Card>
      </Colxx>
    );
  }

  return (
    <Colxx sm="12" className="small-cards mb-5">
      <Card className="mb-4 esg-purchase">
        <CardBody>
          <Row>
            <Colxx xs="3" xl="2">
              <Gauge
                data={{
                  datasets: [
                    {
                      data: [
                        status.red,
                        status.yellow,
                        status.green,
                        status.none,
                      ],
                      backgroundColor: [
                        '#FE375E',
                        '#F8B900',
                        '#0A936D',
                        '#B3B3B3',
                      ],
                      borderColor: ['#FFFFFF'],
                      border: 2,
                      cutout: '85%',
                      circumference: 180,
                      rotation: 270,
                      borderRadius: 50,
                    },
                  ],
                }}
                icon={<EcoSvg />}
              />
            </Colxx>
            <Colxx
              xs="3"
              xl="4"
              className="d-flex flex-column justify-content-center"
            >
              <div className="mb-0 mt-2">
                <span className="text-small">
                  CO2 emitido em todas as suas viagens
                </span>
                <h3>{status.totalFootprint.toFixed(2)} Kg</h3>
              </div>
              <div className="mb-0">
                <span className="text-small">Total neutralizado</span>
                <h3>
                  {status.totalNeutralized.toFixed(2)} Kg (
                  {(status.percentage * 100).toFixed(0)}%)
                </h3>
              </div>

              <div className="mt-2 mb-2">
                {status.purchases.map((purchase, index) => {
                  return (
                    <span
                      key={`purchase-${index}`}
                      className="mb-1 badge badge-primary badge-pill cursor-pointer mr-1"
                      onClick={() => {
                        downloadCertificate(purchase.id);
                      }}
                    >
                      {purchase.esg.kgs}Kg em{' '}
                      {moment(purchase.created_at).format('DD/MM/YYYY')}
                    </span>
                  );
                })}
              </div>
            </Colxx>
            <Colxx
              xs="6"
              xl="6"
              className="d-flex flex-column justify-content-center esg-total"
            >
              <Formik
                initialValues={{
                  creditCard: '',
                  kgAmount: status.totalFootprint - status.totalNeutralized,
                }}
                validationSchema={Yup.object().shape({
                  creditCard: Yup.string(
                    messages['forms.validation.required']
                  ).required(messages['forms.validation.required']),
                  kgAmount: Yup.string(
                    messages['forms.validation.required']
                  ).required(messages['forms.validation.required']),
                })}
                onSubmit={(values, { setSubmitting }) => {
                  purchaseEsg(values, setSubmitting);
                }}
              >
                {({ isSubmitting, values, setFieldValue }) => (
                  <Form>
                    {error && (
                      <Alert color="danger" className="mt-2 mb-2">
                        {error}
                      </Alert>
                    )}

                    <Row>
                      <Colxx xs="6" xl="6">
                        <Label className="form-group has-top-label mb-1">
                          <NumberFormat
                            prefix={''}
                            suffix={' Kg'}
                            thousandSeparator="."
                            decimalSeparator=","
                            decimalScale="2"
                            fixedDecimalScale="2"
                            className="form-control"
                            defaultValue={values.kgAmount || ''}
                            onValueChange={(v) => {
                              setFieldValue('kgAmount', v.floatValue || '');
                              loadPriceAmount(v.floatValue);
                            }}
                          />
                          <IntlMessages id="esg.form.kg" />
                          <ErrorMessage
                            name="kgAmount"
                            component="div"
                            className="invalid-feedback d-block"
                          />
                        </Label>

                        <Label className="form-group has-top-label mb-1">
                          <Field
                            as="select"
                            name="creditCard"
                            className="form-control"
                          >
                            <option value=""></option>
                            {creditCards.map((creditCard, index) => {
                              return (
                                <option
                                  key={`creditCard-${index}`}
                                  value={creditCard.id}
                                >
                                  {creditCard.name}
                                  {creditCard.lastDigits && '(Final '}
                                  {creditCard.lastDigits}
                                  {creditCard.lastDigits && ')'}
                                </option>
                              );
                            })}
                          </Field>
                          <IntlMessages id="esg.form.card" />
                          <ErrorMessage
                            name="creditCard"
                            component="div"
                            className="invalid-feedback d-block"
                          />
                        </Label>
                      </Colxx>
                      <Colxx
                        xs="6"
                        xl="6"
                        className="d-flex flex-column justify-content-center"
                      >
                        <div className="text-right">
                          {loadingAmount ? (
                            <div className="text">
                              <LoaderTotal />
                            </div>
                          ) : (
                            <div className="text">
                              <span>Total</span>
                              {stringToMoney(amount.amount, 2)}
                            </div>
                          )}
                          <Button
                            type="submit"
                            color="primary"
                            disabled={loadingAmount || isSubmitting}
                            className="mt-2"
                            size="sm"
                          >
                            <IntlMessages id="esg.form.purchase" />
                          </Button>
                        </div>
                      </Colxx>
                    </Row>
                  </Form>
                )}
              </Formik>
            </Colxx>
          </Row>
        </CardBody>
      </Card>
    </Colxx>
  );
}
