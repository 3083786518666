import React, { useRef } from 'react';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Button } from 'reactstrap';

import OkAnimation from '../../../assets/lottie/ok.json';
import lottie from 'lottie-web';

export default function SuccessModal({ closeModal, setHeight }) {
  const { messages } = useIntl();
  const containerRef = useRef(null);

  useEffect(() => {
    setHeight(200);

    if (containerRef.current) {
      lottie.loadAnimation({
        container: containerRef.current,
        animationData: OkAnimation,
        autoplay: true,
        loop: false,
      });
    }
  }, []);

  return (
    <div className="text-center">
      <div
        ref={containerRef}
        style={{ height: 100, width: 100 }}
        className="mx-auto"
      />
      <div className="flex-grow-1">
        <p className="description m-0 px-5 text-dark mt-1 mb-4">
          {messages['refund.card.sucess']}
        </p>
        <Button
          color="primary"
          className="mt-0 pretty-btn"
          onClick={closeModal}
        >
          {messages['refund.card.btn.done']}
        </Button>
      </div>
    </div>
  );
}
