import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { ReactComponent as SeatIcon } from '../../../../../../assets/img/icons/seat.svg';
import OptionCard from '../../OptionCard';

export default function SeatSection({
  onClick = () => {},
  className = '',
  items = [],
  ...props
}) {
  const { messages } = useIntl();

  const itemDescription = useMemo(() => {
    const seats = items.filter((item) => item.product_type === 'seat');

    if (seats.length > 2) {
      return `+${seats.length}`;
    } else
      return seats
        .map(
          ({ product_details }) =>
            `${product_details.row}${product_details.column}`
        )
        .join(' / ');
  }, [items]);

  return (
    <OptionCard
      title={messages['flight-alteration.seat-sec.title']}
      description={messages['flight-alteration.seat-sec.description']}
      itemDescription={itemDescription}
      buttonText={messages['general.add']}
      onClick={onClick}
      className={className}
      Icon={SeatIcon}
      {...props}
    />
  );
}
