import classNames from 'classnames';
import React from 'react';
import { useIntl } from 'react-intl';
import { Card, CardBody } from 'reactstrap';

import IntlMessages from '../../helpers/IntlMessages';

export default function NoResults({
  titleMessageID = 'travel.empty.title',
  subtitleMessageID = 'travel.empty.subtitle',
  className = '',
}) {
  const { messages } = useIntl();

  // Render
  return (
    <Card className={classNames('no-results', className)}>
      <CardBody>
        <img
          src="/assets/img/person.svg"
          alt={messages['alts.components.search.no-results']}
        />
        <h1>
          <IntlMessages id={titleMessageID} />
        </h1>
        <h2>
          <IntlMessages id={subtitleMessageID} />
        </h2>
      </CardBody>
    </Card>
  );
}
