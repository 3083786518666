import React, { useMemo, useState } from 'react';
import { Row, Modal, ModalBody, ModalFooter } from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Colxx } from '../../components/common/CustomBootstrap';
import map from 'lodash/map';
import flattenDeep from 'lodash/flattenDeep';

import JourneyLarge from '../../components/bus/JourneyLarge';

import { stringToMoney } from '../../utils/money';
import ReservationParams from './ReservationParams/ReservationParams';
import { useIntl } from 'react-intl';
import BusCarbonDetail from '../../components/bus/BusCarbonDetail';
import ReservationButton from './ReservationButton';
import { useSelector } from 'react-redux';
import useIsReservationValid from '../../hooks/useIsReservationValid';
import CurrencyWrapper from '../../components/common/CurrencyWrapper';
import OrderStructures from '../../constants/orderStructure';

function BusTotal({
  item,
  createReservation,
  canReservate = true,
  orderStructure,
  reservationParams,
  serviceCharge = 0,
}) {
  const { messages, formatMessage } = useIntl();

  const { isValid, disabled, title } = useIsReservationValid({
    orderStructure,
    reservationParams,
  });

  // States
  const additionalInfo = useMemo(
    () =>
      orderStructure === OrderStructures.PERSONAL
        ? formatMessage(
            {
              id: 'containers.search.flight-modal.taxes',
            },
            {
              taxes: stringToMoney(serviceCharge, 0, item.fare.currency),
            }
          )
        : null,
    [item, orderStructure, serviceCharge]
  );

  return (
    <ModalFooter>
      <div>
        <div className="text mt-3 pr-3">
          <span>{messages['general.total']}</span>
          <CurrencyWrapper
            value={item.fare.total}
            tooltipFill="white"
            decimalPlaces={0}
          >
            {({ formattedValue }) => formattedValue}
          </CurrencyWrapper>
          {additionalInfo ? (
            <span style={{ fontSize: '0.5em' }} className="text-theme-3 mb-2">
              {additionalInfo}
            </span>
          ) : null}
          <hr />
        </div>
        <div className="description pr-3">
          <p>
            {formatMessage(
              { id: 'containers.search.flight-modal.reservation' },
              {
                numAdults: item.fare.quantity,
                adults:
                  item.fare.quantity > 1
                    ? messages['general.adults']
                    : messages['general.adult'],
              }
            )}
          </p>

          <p className="mb-0 text-small">
            {messages['containers.search.modal.fees']}
          </p>
        </div>

        <ReservationButton
          onClick={createReservation}
          disabled={!canReservate || disabled}
          isValid={isValid}
          title={title}
        />
      </div>
    </ModalFooter>
  );
}

export default function BusModal({
  toggleModal,
  item,
  segments,
  params,
  reserveItem,
}) {
  const { messages } = useIntl();
  const { serviceCharge } = useSelector(({ auth }) => ({
    serviceCharge: parseFloat(auth.organization.fee?.bus || 0),
  }));

  // States
  const [reservationParams, setReservationParams] = useState({});
  const [orderStructure, setStructure] = useState(OrderStructures.ORGANIZATION);

  // Functions
  const createReservation = () => {
    if (orderStructure) {
      reserveItem({
        reservationParams,
        item: item,
        selectedSegments: segments,
      });
    }
  };

  // Render
  return (
    <Modal isOpen={true} toggle={toggleModal} className="reservation small">
      <ModalBody className="flight">
        <Row>
          <Colxx xxs="12">
            <div className="flight-content">
              <PerfectScrollbar
                options={{
                  suppressScrollX: true,
                  wheelPropagation: false,
                }}
              >
                <ReservationParams
                  setReservationParams={setReservationParams}
                  orderStructure={orderStructure}
                  setStructure={setStructure}
                />

                <div className="mt-5">
                  {map(item.journeys, (journey, index) => {
                    const segment = segments[index];

                    return (
                      <JourneyLarge
                        key={`journey-${index}`}
                        journey={journey}
                        index={index}
                        params={params}
                        segment={segment}
                        legs={segment.legs}
                      />
                    );
                  })}
                </div>

                <h5 className="mt-5 ml-3">
                  {messages['components.timeline.modal.conditions']}
                </h5>
                <p className="text-muted-darker ml-3 others mb-5">
                  {messages['components.bus.description']}
                </p>

                <BusCarbonDetail
                  legs={flattenDeep(
                    map(item.journeys, (journey) =>
                      map(journey.segments, (segment) =>
                        map(segment.legs, (leg) => leg)
                      )
                    )
                  )}
                />
              </PerfectScrollbar>
            </div>
          </Colxx>
        </Row>
      </ModalBody>

      <BusTotal
        item={item}
        segments={segments}
        createReservation={createReservation}
        canReservate={orderStructure}
        orderStructure={orderStructure}
        reservationParams={reservationParams}
        serviceCharge={serviceCharge}
      />
    </Modal>
  );
}
