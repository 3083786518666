import {
  COMPARATIVE_FUNCTIONS,
  FILTER_COMPARATIVES,
  FILTER_TYPES,
} from '../constants/filters';

export const shouldApplyFilter = (obj) => obj && Object.keys(obj).length;

export const getComparativeLabelValue = (messages) => {
  return Object.keys(FILTER_COMPARATIVES).reduce((obj, type) => {
    obj[type] = FILTER_COMPARATIVES[type].map((value) => ({
      value,
      label: messages[`comparatives.label.${value}`],
    }));
    return obj;
  }, {});
};

function getFilterValues(type, value, valueKey) {
  switch (type) {
    case FILTER_TYPES.ARRAY:
    case FILTER_TYPES.OBJECT:
      return valueKey ? value.map(({ [valueKey]: value }) => value) : value;
    case FILTER_TYPES.BOOLEAN:
      return value.value;
    default:
      return value;
  }
}

export const generateFilterFunctions = (filters) =>
  filters.map(({ config, comparative, value }) => {
    const {
      type,
      filterFunctionConfig: {
        valueKey = 'label',
        getField = config.getField ? config.getField : (obj) => obj,
        field = config.field,
        options = config.options,
      } = {},
    } = config;

    const comparativeFunction = (COMPARATIVE_FUNCTIONS[type] ||
      COMPARATIVE_FUNCTIONS[FILTER_TYPES.ARRAY])[comparative.value];

    let filterValues = getFilterValues(type, value, valueKey);

    const getObjField = (obj) => {
      const objField = obj[field];
      return objField ? getField(objField) : objField;
    };

    return (obj) => {
      const objValue = getObjField(obj);

      return objValue !== undefined
        ? comparativeFunction(objValue, filterValues, options)
        : false;
    };
  });

export const filterObject = (obj) => {
  const keys = Object.keys(obj);

  return keys.reduce((o, key) => {
    let value = obj[key];

    if (typeof value === 'object') {
      const newObj = filterObject(value);
      value = Object.keys(newObj).length > 0 ? newObj : null;
    }

    if (value) o[key] = value;

    return o;
  }, {});
};

export const uniqueByKeepLast = (arr, key) => {
  return [...new Map(arr.map((item) => [key(item), item])).values()];
};

export function filterTrueValues(items = {}, returnIfEmpty = {}) {
  let addedItems = 0;

  const filteredValues = Object.keys(items).reduce((obj, key) => {
    const value = items[key];

    if (value) {
      obj[key] = value;
      addedItems++;
    }

    return obj;
  }, {});

  return addedItems ? filteredValues : returnIfEmpty;
}
