import { find, maxBy } from 'lodash';
import { OrderTypes } from '../constants/orderTypes';
import { analyzeReservationParams } from './reservation';
import moment from 'moment';
import firebaseApp from '../services/Firebase';
import { OpenBookingProviders } from '../constants/openBookingProviders';
import { getOrganizationConfig } from '../utils/organization';
import { sortAlphabetically } from './sort';
import orderStatus from '../constants/orderStatus';

export function getFlightTravelParams(search = {}) {
  return {
    outboundDate: search.outbound_date.clone().startOf('day'),
    inboundDate: search.inbound_date?.clone().startOf('day') || null,
    airline: '',
    amount: 0,
    associate: null,
  };
}

export const TravelParamsFunctions = {
  [OrderTypes.FLIGHT]: getFlightTravelParams,
};

// Validation
export function isFlightTravelParamsValid(params = {}) {
  const { outboundDate, inboundDate, amount, associate } = params;

  return Boolean(outboundDate && inboundDate && amount && associate?.value);
}

export const TravelParamsValidationFunctions = {
  [OrderTypes.FLIGHT]: isFlightTravelParamsValid,
};

// Reservation
export async function makeOpenBookingFlightReservation({
  user,
  search,
  searchParams,
  reservationParams,
  travelParams,
}) {
  const newReservationParams = await analyzeReservationParams(
    reservationParams
  );

  // // Create Order and Reservations
  const orderDetails = {
    ...newReservationParams,
    journeys: map(item.journeys, (journey, index) => {
      const arrivalStation =
        find(searchParams.airports, {
          iata: journey.arrivalStation,
        })?.city || journey.arrivalStation;
      const departureStation =
        find(searchParams.airports, {
          iata: journey.departureStation,
        })?.city || journey.departureStation;

      return {
        id: index,
        departureStation: {
          iataCode: journey.departureStation,
          name: departureStation,
        },
        arrivalStation: {
          iataCode: journey.arrivalStation,
          name: arrivalStation,
        },
        departureDate: journey.departureDate,
        sellKey: null,
      };
    }),
    provider: {
      origin: item.origin,
      source: item.source,
      validatingCarrier: item.validatingAirline,
    },
    fare: {
      currency: 'BRL',
      total: travelParams.amount,
    },
    searchCode: search.searchCode,
  };

  const reservations = [];
  item.journeys.forEach((_, index) => {
    const segment = selectedSegments[index];

    segment.flights.forEach((flight, flightIndex) => {
      const arrivalStationName =
        find(searchParams.airports, {
          iata: flight.arrivalStation,
        })?.city || flight.arrivalStation;
      const departureStationName =
        find(searchParams.airports, {
          iata: flight.departureStation,
        })?.city || flight.departureStation;

      reservations.push({
        baggage: '',
        bookingCode: '',
        airline: null,
        airlineOperating: null,
        validatingAirline: null,
        flightNumber: null,
        departureDateTime: moment(flight.departureDateTime).format(
          'DD/MM/YYYY HH:mm'
        ),
        departureStation: {
          iataCode: flight.departureStation,
          name: departureStationName,
        },
        arrivalDateTime: moment(flight.arrivalDateTime).format(
          'DD/MM/YYYY HH:mm'
        ),
        arrivalStation: {
          iataCode: flight.arrivalStation,
          name: arrivalStationName,
        },
        fare: flight.fare,
        journeyId: index,
        flightIndex,
      });
    });
  });

  // trackPropertiesSegment('New Reservation', {
  //   ...orderDetails,
  //   type: 'flight',
  // });

  const latestReservation = maxBy(reservations, (r) => {
    return moment(r.arrivalDateTime, 'DD/MM/YYYY HH:mm').unix();
  });

  return firebaseApp.createFlightReservation({
    organizationId: user.organizationId,
    passengerId: newReservationParams.passengerId,
    passenger: newReservationParams.passenger,
    orderDetails,
    reservations,
    filterTimestamp: moment(
      latestReservation.arrivalDateTime,
      'DD/MM/YYYY HH:mm'
    )
      .endOf('day')
      .utc()
      .unix(),
    type: 'flight',
  });
}

// Providers
export function getOpenBookingProviders(travelType, organization, messages) {
  const providers = OpenBookingProviders[travelType] || [];
  const organizationProviders = getOrganizationConfig(
    organization.config,
    'openBooking.providers'
  );
  const allowedProviders = organizationProviders[travelType] || {};

  return sortAlphabetically(
    providers
      .filter((provider) => allowedProviders[provider] !== false)
      .map((provider) => ({
        value: provider,
        label: messages[`open-booking.providers.${provider}`],
      })),
    'label'
  );
}

// Modal
export function shouldShowOpenBookingSteps(order) {
  return (
    order.openBooking &&
    [orderStatus.WAITING_ISSUE, orderStatus.ISSUED].includes(
      order.statusCode
    ) &&
    !order.openBookingInfo?.receipts
  );
}
