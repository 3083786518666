import classNames from 'classnames';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { RefundTypes } from '../../../constants/refundTypes';
import useExecuteAction from '../../../hooks/useExecuteAction';
import firebaseApp from '../../../services/Firebase';

export default function ActivateCardButton({ className, card }) {
  const { user } = useSelector(({ auth }) => auth);
  const { messages } = useIntl();

  const { executeAction, loading } = useExecuteAction();

  // States
  const title = useMemo(
    () =>
      messages[
        card.type === RefundTypes.PERSONAL_ACCOUNT
          ? 'budget.my-cards.activate-account'
          : 'budget.my-cards.activate-card'
      ],
    [card]
  );

  // Functions
  const activateCard = () =>
    executeAction(
      (async () => {
        await firebaseApp.activateCard(user.organizationId, user.uid, card.id);
      })()
    );

  // Render
  if (card.openBooking) return null;

  return (
    <Button
      className={classNames(
        'activate-button pretty-btn primary w-100',
        className
      )}
      onClick={activateCard}
      disabled={loading}
    >
      {title}
    </Button>
  );
}
