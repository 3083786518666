import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import {
  Button,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  ModalFooter,
} from 'reactstrap';
import { Colxx } from '../../common/CustomBootstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import IntlMessages from '../../../helpers/IntlMessages';

import { getPassengerRedux } from '../../../utils/redux';
import appFunctions from '../../../services/Functions';
import AdministrativeCard from '../AdministrativeCard';
import CustomColorPicker from '../../CustomColorPicker/CustomColorPicker';
import { colorBrightness, hexToRgb } from '../../../utils/colors';

export default function AdministrativeAccountCreationModal({ closeModal }) {
  const { messages } = useIntl();
  const { passenger } = useSelector(getPassengerRedux);

  // Functions
  const createCard = async (values, setSubmitting) => {
    try {
      setSubmitting(true);

      await appFunctions.createExpensesCard(passenger.organizationId, {
        card_id: 'administrative',
        name: values.name,
        user_id: passenger.uid,
        color: values.color,
      });

      closeModal();
    } catch (err) {
      console.error(err);
    } finally {
      setSubmitting(false);
    }
  };

  // Render
  return (
    <Modal
      isOpen={true}
      toggle={closeModal}
      size="lg"
      className="admin refund-new-card-modal virtual-card-modal"
      name="administrative-account-modal"
    >
      <ModalHeader toggle={closeModal}>
        {messages['refund.administrative-account.create.title']}
      </ModalHeader>
      <Formik
        initialValues={{ name: '', color: '#43418e' }}
        validationSchema={Yup.object().shape({
          name: Yup.string(messages['forms.validation.required']).required(
            messages['forms.validation.required']
          ),
          color: Yup.string(messages['forms.validation.required'])
            .required(messages['forms.validation.required'])
            .test(
              'too-bright',
              messages['refund.virtual-card.create.too-bright'],
              (v) => {
                return colorBrightness(hexToRgb(v)) < 200;
              }
            ),
        })}
        onSubmit={(values, { setSubmitting }) => {
          createCard(values, setSubmitting);
        }}
      >
        {({ isSubmitting, values, setFieldValue }) => (
          <Form>
            <ModalBody>
              <Row className="px-3">
                <Colxx xxx="5">
                  <AdministrativeCard card={values} />
                </Colxx>
                <Colxx xxs="7" className="px-4">
                  <Label className="form-group has-top-label mb-1">
                    <Field
                      name="name"
                      className="form-control"
                      maxLength={21}
                    />
                    <IntlMessages id="refund.virtual-card.create.card-name" />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="invalid-feedback d-block"
                    />
                  </Label>
                  <Label className="form-group has-top-label mb-1">
                    <CustomColorPicker
                      color={values.color}
                      setColor={(v) => setFieldValue('color', v)}
                    />
                    <IntlMessages id="refund.virtual-card.create.color" />
                    <ErrorMessage
                      name="color"
                      component="div"
                      className="invalid-feedback d-block"
                    />
                  </Label>
                </Colxx>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                type="submit"
                disabled={isSubmitting}
                outline
                size="lg"
              >
                {messages['refund.administrative-account.create.do-create']}
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
