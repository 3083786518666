import React from 'react';
import { useIntl } from 'react-intl';
import { ReactComponent as CloseIcon } from '../../assets/img/icons/close.svg';

export default function VersionCard({ hidden = true, onClose = () => {} }) {
  const { messages } = useIntl();

  // Functions
  const handleClick = () => {
    window.location.reload(true);
  };

  // Render
  return (
    <div className={`version-card ${hidden ? 'hidden' : ''}`}>
      <div className="left">
        <p>{messages['containers.version.version-card']}</p>
        <button onClick={handleClick}>
          {messages['containers.version.version-card.button']}
        </button>
      </div>
      <div className="right ml-3">
        <CloseIcon className="close-icon" onClick={onClose} />
      </div>
    </div>
  );
}
