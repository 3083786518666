import React, { Fragment } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { Row, Nav, NavItem } from 'reactstrap';
import classnames from 'classnames';
import IntlMessages from '../../helpers/IntlMessages';
import { Colxx, Separator } from '../../components/common/CustomBootstrap';
import Breadcrumb from '../../containers/navs/Breadcrumb';

import ApiKeysDataListView from '../../containers/integrations/ApiKeysDataListView';

export default function Travels(props) {
  const { tabId } = useParams();

  // Render
  return (
    <Fragment>
      <Row>
        <Colxx xxs="12">
          <Breadcrumb heading="menu.integrations" match={props.match} />
          <Separator className="mb-5" />
        </Colxx>
      </Row>
      <Row>
        <Colxx xxs="12" className="mb-4">
          <div>
            <Nav className="admin-tabs">
              <NavItem>
                <NavLink
                  to="/app/integrations/api_keys"
                  className={classnames({
                    active: !tabId || tabId === 'api_keys',
                    'nav-link': true,
                  })}
                >
                  <IntlMessages id="admin.integrations.api-keys" />
                </NavLink>
              </NavItem>
            </Nav>

            {!tabId || tabId === 'api_keys' ? <ApiKeysDataListView /> : null}
          </div>
        </Colxx>
      </Row>
    </Fragment>
  );
}
