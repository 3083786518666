import React, { useMemo } from 'react';
import { CardBody, Row } from 'reactstrap';
import { Colxx } from '../../components/common/CustomBootstrap';
import { useIntl } from 'react-intl';
import filter from 'lodash/filter';
import find from 'lodash/find';
import isUndefined from 'lodash/isUndefined';
import countBy from 'lodash/countBy';

import { OrderTypes } from '../../constants/orderTypes';
import { registeredCompanies } from '../../constants/travelValues';

export default function CarCardTopCompanies({ orders, reservations }) {
  const { messages } = useIntl();

  const companies = useMemo(() => {
    if (!(orders.length && reservations.length)) return {};

    const carReservations = filter(reservations, (reservation) => {
      const orderExists = !isUndefined(
        find(orders, (order) => {
          return order.id === reservation.refs.orderId;
        })
      );

      return reservation.type === OrderTypes.CAR && orderExists;
    });

    return countBy(carReservations, 'carDetails.rental.code');
  }, [orders, reservations]);

  return (
    <CardBody>
      <Row>
        <Colxx sm="12">
          {Object.keys(companies).map((company) => {
            return (
              <div
                key={company}
                className="d-flex flex-row mb-3 pb-3 justify-content-between align-items-center"
              >
                <div
                  className="chain-image"
                  style={{
                    backgroundImage: `url(${
                      registeredCompanies[company]
                        ? registeredCompanies[company].logo
                        : '/assets/img/icons/search_icon_hotel.svg'
                    })`,
                  }}
                />
                <div className="flex-fill pl-2">
                  <p className="font-weight-medium mb-0">
                    {registeredCompanies[company]
                      ? registeredCompanies[company].name
                      : company}
                  </p>
                </div>
                <div className="pr-2">
                  {companies[company]} {messages['general.reservations']}
                </div>
              </div>
            );
          })}
        </Colxx>
      </Row>
    </CardBody>
  );
}
