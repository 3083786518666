import React, { useMemo, useState } from 'react';
import { Button } from 'reactstrap';
import OrderStatus from '../../constants/orderStatus';
import firebaseApp from '../../services/Firebase';
import { ReactComponent as PrintIcon } from '../../assets/img/icons/print-bold.svg';

function checkOrder(order) {
  if (order.openBooking)
    return (
      order.statusCode > OrderStatus.WAITING_ISSUE &&
      order.openBookingInfo?.receipts?.length
    );

  return order.statusCode == OrderStatus.ISSUED;
}

export default function PrintOrder({
  order,
  generationFunction = firebaseApp.getReceiptFromOrderId,
  validationFunction,
}) {
  const [isLoading, setIsLoading] = useState(false);

  const isValid = useMemo(
    () => (validationFunction ? validationFunction(order) : checkOrder(order)),
    [order, validationFunction]
  );

  // Get Receipt
  const printReceipt = async () => {
    setIsLoading(true);

    const response = await generationFunction(order.id);

    var blob = new Blob([response], {
      type: 'application/pdf',
    });

    var url = window.URL.createObjectURL(blob);
    window.open(url);

    setIsLoading(false);
  };

  if (order && isValid) {
    return (
      <div className="print">
        <Button
          onClick={printReceipt}
          disabled={isLoading}
          className="flex-center"
        >
          <PrintIcon className="icon" />
        </Button>
      </div>
    );
  }

  return null;
}
