import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Row } from 'reactstrap';
import { Colxx } from '../../components/common/CustomBootstrap';
import moment from 'moment';

import DateSelection from '../../components/reports/DateSelection';
import firebaseApp from '../../services/Firebase';
import useDataSnapshot from '../../hooks/useDataSnapshot';
import { getDocument } from '../../utils/firebase';
import { RefundTypes } from '../../constants/refundTypes';
import SmallLineSpent from './SmallLineSpent';
import BudgetsInfo from './dashboard/BudgetInfo';
import BudgetRelatedInfo from './dashboard/RelatedInfo/BudgetRelatedInfo';
import { RefundStatus } from '../../constants/refundStatus';

function getSortObject() {
  return {
    budgets: {},
    amount: 0,
    creditCardsUsed: 0,
  };
}

export default function DashboardView({ getData }) {
  const { user } = useSelector(({ auth }) => auth);

  const [dateSelection, setDateSelection] = useState({
    startDate: moment().startOf('month').startOf('day').unix(),
    endDate: moment().endOf('day').unix(),
  });

  const [budgets, _, loadingBudgets] = useDataSnapshot(
    {
      fetchFunction: () =>
        firebaseApp.getFulfilledBudgetsFromPeriod(
          user.organizationId,
          dateSelection.startDate,
          dateSelection.endDate
        ),
      formatFunction: (data) =>
        data.sort((a, b) => a.filterTimestamp - b.filterTimestamp),
    },
    [dateSelection]
  );

  const [creditCards, setCreditCards] = useState({});
  const [loadingCreditCards, setLoadingCreditCards] = useState(true);

  const loading = useMemo(
    () => loadingBudgets || loadingCreditCards,
    [loadingBudgets, loadingCreditCards]
  );

  const sortedData = useMemo(() => {
    if (loading) return {};
    else {
      const sortedData = {
        [RefundTypes.PHYSICAL_CARD]: getSortObject(),
        [RefundTypes.VIRTUAL_CARD]: getSortObject(),
        [RefundTypes.PERSONAL_ACCOUNT]: getSortObject(),
        [RefundTypes.ADMINISTRATIVE_ACCOUNT]: getSortObject(),
      };

      budgets.forEach((budget) => {
        const { cardId, fare, startDate, statusCode } = budget;
        const card = creditCards[cardId];

        if (card) {
          const sortObj = sortedData[card.type];

          if (statusCode > RefundStatus.ACTIVATING)
            sortObj.amount += fare.total;

          if (sortObj.budgets[startDate])
            sortObj.budgets[startDate].push(budget);
          else sortObj.budgets[startDate] = [budget];
        }
      });

      Object.values(creditCards).forEach((card) => {
        sortedData[card.type].creditCardsUsed += 1;
      });

      return sortedData;
    }
  }, [loading, budgets]);

  // Effects
  useEffect(() => {
    if (budgets.length) {
      loadCreditCardFromBudgets(budgets);
    }
  }, [budgets]);

  // Functions
  const loadCreditCardFromBudgets = async (budgets) => {
    try {
      setLoadingCreditCards(true);
      const addedCards = {};

      const creditCards = await Promise.all(
        budgets.map((budget) => {
          const cardId = budget.cardId;

          if (cardId in addedCards) return null;
          else {
            addedCards[cardId] = true;

            return getData(`expense-card/${cardId}`, () =>
              getDocument(() =>
                firebaseApp
                  .getExpenseCardsByIdFromOrganization(
                    budget.organizationId,
                    cardId
                  )
                  .get()
              )
            );
          }
        })
      );

      setCreditCards(
        creditCards
          .filter((v) => v)
          .reduce((obj, card) => {
            obj[card.id] = card;
            return obj;
          }, {})
      );

      setLoadingCreditCards(false);
    } catch (err) {
      console.error(err);
    }
  };

  // Date Selection
  const setCustomDate = (start, end) => {
    setDateSelection({
      startDate: moment(start).startOf('day').unix(),
      endDate: moment(end).endOf('day').unix(),
    });
  };

  // Render
  return (
    <div className="reports expenses mt-3 pb-5">
      <Row className="mx-0">
        <Colxx xxs="12" className="mb-4 text-right">
          <DateSelection
            dateSelection={dateSelection}
            setDateSelection={setCustomDate}
          />
        </Colxx>
      </Row>

      <Row className="mx-0 mb-5">
        <Colxx xxs="12" className="align-items-stretch">
          <SmallLineSpent
            loading={loading}
            data={sortedData}
            budgets={budgets}
          />
        </Colxx>
      </Row>

      <Row className="mx-0">
        <Colxx md="12" lg="4" className="mb-5">
          <BudgetsInfo budgets={budgets} getData={getData} />
        </Colxx>
        <Colxx md="12" lg="8" className="mb-5">
          <BudgetRelatedInfo budgets={budgets} getData={getData} />
        </Colxx>
      </Row>
    </div>
  );
}
