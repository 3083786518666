import React, { useState } from 'react';
import { CardTitle, Label, Button, Alert } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import User from '../../services/User';

import IntlMessages from '../../helpers/IntlMessages';
import { useIntl } from 'react-intl';

export default function RequestReset({ setStep }) {
  const [error, setError] = useState('');
  const { messages } = useIntl();

  const onUserReset = async (email, setSubmitting) => {
    try {
      await User.requestResetPassword(email);

      setStep('success');
    } catch (err) {
      if (err.response?.status === 401)
        setError(messages['user.UserSSOOrganization']);
      else setError(messages['user.UserNotFoundException']);
      setSubmitting(false);
    }
  };

  // Render
  return (
    <Formik
      initialValues={{ email: '' }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email(messages['forms.validation.email.valid'])
          .required(messages['forms.validation.email.required']),
      })}
      onSubmit={(values, { setSubmitting }) => {
        onUserReset(values.email, setSubmitting);
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <NavLink to="/user/login" className="d-block mb-4">
            <i className="iconsminds-arrow-out-left" />
            <IntlMessages id="user.login.page" />
          </NavLink>

          <CardTitle className="mb-4">
            <IntlMessages id="user.forgot-password" />
          </CardTitle>

          {error && (
            <Alert
              id="error-forgot-password"
              color="danger"
              className="mt-2 mb-2"
            >
              {error}
            </Alert>
          )}

          <Label className="form-group has-top-label mb-4">
            <Field type="email" name="email" className="form-control" />
            <IntlMessages id="user.username" />
            <ErrorMessage
              name="email"
              component="div"
              className="invalid-feedback d-block"
            />
          </Label>

          <div className="d-flex justify-content-end">
            <Button
              type="submit"
              color="primary"
              outline
              size="lg"
              disabled={isSubmitting}
            >
              <IntlMessages id="forms.action.reset" />
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
