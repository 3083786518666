import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Row } from 'reactstrap';
import useDataSnapshot from '../../hooks/useDataSnapshot';
import useIntervalFetch from '../../hooks/useIntervalFetch';
import firebaseApp from '../../services/Firebase';
import appFunctions from '../../services/Functions';
import { stringToMoney } from '../../utils/money';
import { Colxx } from '../common/CustomBootstrap';
import CustomSelect from '../common/CustomSelect';
import LoadingFunds from '../../containers/expenses/CardContainers/LoadingFunds';
import SmallLineBalance from './SmallLineBalance';
import classNames from 'classnames';
import AnimateHeight from 'react-animate-height';
import Spinner from '../common/Spinner';
import moment from 'moment';
import { ReactComponent as ReloadIcon } from '../../assets/img/icons/refresh.svg';

const NUM_DAYS = [7, 15, 30];

const generateDays = (startDate, numDays) => {
  const days = {};

  for (let i = 0; i < numDays; i++) {
    const key = startDate.format('YYYY-MM-DD');
    days[key] = { budgets: [], amount: 0 };
    startDate = startDate.add(1, 'days');
  }

  return days;
};

const selectStyle = {
  control: (base, { isFocused }) => {
    return {
      ...base,
      borderColor: isFocused ? '#43418e' : '#e0e0e0',
      boxShadow: 'none',
      ':hover': {
        borderColor: '#43418e',
        boxShadow: '0 0 0 1px rgba(67, 65, 142, 0.2)',
      },
      ':focus': {
        borderColor: '#43418e',
        boxShadow: '0 0 0 1px rgba(67, 65, 142, 0.2)',
      },
      borderRadius: 100,
      paddingLeft: 5,
    };
  },
};

export default function BalanceCard({
  show,
  refreshEvery = 120000,
  className = '',
  ...props
}) {
  const { user } = useSelector(({ auth }) => auth);
  const { messages, formatMessage } = useIntl();
  const lastRefresh = useRef(0);

  const periodOptions = useMemo(() => {
    return NUM_DAYS.map((value) => ({
      value,
      label: formatMessage(
        { id: 'balance-card.periods.next-days' },
        { days: value }
      ),
    }));
  }, []);

  const [period, setPeriod] = useState(periodOptions[0]);

  const fetchBalance = useCallback(async () => {
    const { data } = await appFunctions.getBank3Balance(user.organizationId);
    return data;
  }, []);

  const [data, loading, forcedRefresh] = useIntervalFetch({
    fetchFunction: fetchBalance,
    refresh: refreshEvery,
    shouldLoad: show,
  });

  const [budgets, _, loadingBudgets] = useDataSnapshot(
    {
      fetchFunction: () =>
        firebaseApp.getApprovedBudgetsFromPeriod(
          user.organizationId,
          period.value
        ),
      shouldLoad: show,
    },
    [period]
  );

  const [budgetNeededAmount, sortedAmount] = useMemo(() => {
    if (budgets.length > 0) {
      const sortedAmount = generateDays(moment(), period.value);
      let sumAmount = 0;

      budgets.forEach((budget) => {
        const { startDate, budget: budgetValue = 0, fare = {} } = budget;

        const value = budgetValue || fare.total;

        if (value) {
          const obj = sortedAmount[startDate] || {
            budgets: [],
            amount: 0,
          };

          obj.amount += value;
          obj.budgets.push(budget);

          sortedAmount[startDate] = obj;
          sumAmount += value;
        }
      });
      return [sumAmount, sortedAmount];
    } else return [0, null];
  }, [budgets]);

  const {
    formattedBalance = '',
    formattedActiveBalance = '',
    formattedNeedAmount = '',
    neededAmount = 0,
  } = useMemo(() => {
    if (data) {
      const balance = data.balance / 100,
        activeBalance = data.activeBalance / 100;
      const neededAmount = Math.max(
        budgetNeededAmount ? budgetNeededAmount - balance : 0,
        0
      );

      return {
        formattedBalance: stringToMoney(balance, 2),
        formattedActiveBalance: stringToMoney(activeBalance, 2),
        formattedNeedAmount: stringToMoney(neededAmount, 2),
        neededAmount,
      };
    } else return {};
  }, [data, budgetNeededAmount]);

  // Functions
  const doRefresh = () => {
    const now = new Date().getTime();

    if (now - lastRefresh.current > 300000) {
      lastRefresh.current = now;
      forcedRefresh();
    }
  };

  // Render
  return (
    <AnimateHeight height={show ? 'auto' : 0} easing="ease" {...props}>
      <Row
        className={classNames(
          'custom-card-container balance-card w-100 px-4 py-4 m-0',
          className
        )}
      >
        <Colxx xxs="6" sm="4" className="d-flex align-items-center">
          <div>
            <div className="mb-5">
              {loading ? (
                <LoadingFunds />
              ) : (
                <p className="m-0 p-0 price">{formattedBalance}</p>
              )}
              <h3 className="title m-0">{messages['balance-card.balance']}</h3>
            </div>
            <div className="mb-5">
              {loading ? (
                <LoadingFunds />
              ) : (
                <p className="m-0 p-0 price">{formattedActiveBalance}</p>
              )}
              <h3 className="title m-0">
                {messages['balance-card.active-balance']}
              </h3>
            </div>
            <div>
              {loading || loadingBudgets ? (
                <LoadingFunds />
              ) : (
                <p className="m-0 p-0 price">{formattedNeedAmount}</p>
              )}
              <h3 className="title m-0">
                {messages['balance-card.needed-balance']}
              </h3>
            </div>
          </div>
        </Colxx>
        <Colxx xxs="6" sm="8">
          <div className="d-flex align-items-center justify-content-end">
            <ReloadIcon
              width="1.3rem"
              className="mr-3 pointer text-primary spin"
              onClick={doRefresh}
              title={messages['balance-card.refresh']}
            />
            <div style={{ width: 180 }}>
              <CustomSelect
                options={periodOptions}
                value={period}
                onChange={setPeriod}
                editable={false}
                isSearchable={false}
                components={{
                  IndicatorSeparator: () => null,
                }}
                style={selectStyle}
              />
            </div>
          </div>
          <div
            className="mt-4 d-flex align-items-center justify-content-center"
            style={{ height: 180 }}
          >
            {loading || loadingBudgets ? (
              <Spinner relative style={{ width: 60, height: 60 }} />
            ) : (
              <SmallLineBalance
                balance={sortedAmount}
                className="w-100 h-100"
              />
            )}
          </div>
        </Colxx>
      </Row>
    </AnimateHeight>
  );
}
