import React, { useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import ProfileImage from '../ProfileImage';

export default function CommentInput({
  value,
  onChange,
  placeholder = '',
  maxLength = 150,
}) {
  const { user } = useSelector(({ auth }) => auth);
  const commentRef = useRef(null);

  // Effects
  useEffect(() => {
    autoResize(commentRef.current);
  }, [value]);

  // Functions
  const autoResize = useCallback((element) => {
    if (element) {
      element.style.height = 'auto';
      element.style.height = `${element.scrollHeight}px`;
    }
  }, []);

  return (
    <div className="comment-input slide-left" data-testid="comment-input">
      <textarea
        data-testid="comment-input-textarea"
        name="comment"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
        className="input w-100 px-4 py-3 text-primary text-right"
        ref={commentRef}
        maxLength={maxLength}
        autoFocus
      />
      <div className="d-flex align-items-center justify-content-end pr-3 mt-2">
        <ProfileImage user={user} />
        <p className="m-0 p-0 ml-2 font-weight-medium">{user.firstName}</p>
      </div>
    </div>
  );
}
