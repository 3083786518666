import React, { useState, useEffect } from 'react';
import { Row, CardTitle, Button, Alert } from 'reactstrap';

import IntlMessages from '../../helpers/IntlMessages';
import { useIntl } from 'react-intl';

import User from '../../services/User';

export default function LoginGoogle({ email, setStep }) {
  const [error, setError] = useState('');
  const { messages } = useIntl();

  useEffect(() => {
    doGoogleLogin();
  }, []);

  const doGoogleLogin = async () => {
    const provider = User.googleProvider(email);

    try {
      await User.signInWithPopup(provider);
    } catch (err) {
      console.error(err);
      setError(`${messages['user.GeneralException']} ${err.message}`);
    }
  };

  return (
    <div>
      <CardTitle className="mb-4">
        <IntlMessages id="user.login" />
      </CardTitle>

      <Button
        onClick={() => {
          setStep('auth-EMAIL');
        }}
        color="transparent"
        size="xs"
        className="mb-4 text-dark p-0"
      >
        <i className="iconsminds-arrow-out-left" />
        {email}
      </Button>

      <Row>
        {error ? (
          <Alert color="danger" className="mt-2 mb-2">
            {error}
          </Alert>
        ) : (
          <div className="loading p-relative" />
        )}
      </Row>
    </div>
  );
}
