import React from 'react';
import find from 'lodash/find';

export default function CityName({ station, params }) {
  if (params?.airports) {
    const airport = find(params.airports, { iata: station });

    if (airport) {
      return <span>{airport.city}</span>;
    }
  }

  if (station?.name) {
    return <span>{station.name}</span>;
  }

  return <span>{station}</span>;
}
