import React, { useMemo } from 'react';
import OrderStructures from '../../constants/orderStructure';
import { useIntl } from 'react-intl';
import useIsReservationValid from '../../hooks/useIsReservationValid';
import { ModalFooter } from 'reactstrap';
import CurrencyWrapper from '../common/CurrencyWrapper';
import ReservationButton from '../../containers/search/ReservationButton';
import { useSelector } from 'react-redux';
import { getAuthRedux } from '../../utils/redux';
import { getOrganizationConfig } from '../../utils/organization';
import useIsTravelParamsValid from '../../hooks/useIsTravelParamsValid';
import OpenBookingSteps from './OpenBookingSteps';
import AdjustableText from '../AdjustableText';

export default function OpenBookingCreationFooter({
  orderStructure = OrderStructures.ORGANIZATION,
  reservationParams,
  travelParams,
  additionalInfo,
  createReservation,
  travelType,
  showSteps,
  toggleSteps,
  isReserving,
}) {
  const { messages, formatMessage } = useIntl();
  const { organization } = useSelector(getAuthRedux);

  // States
  const {
    isValid: isReservationValid,
    disabled: reservationDisabled,
    title: reservationTitle,
  } = useIsReservationValid({
    orderStructure,
    reservationParams,
  });

  const {
    isValid: isTravelParamsValid,
    disabled: travelDisabled,
    title: travelTitle,
  } = useIsTravelParamsValid({
    travelType,
    travelParams,
  });

  const isValid = isReservationValid && isTravelParamsValid,
    disabled = reservationDisabled || travelDisabled,
    title = reservationTitle || travelTitle;

  const issueMessage = useMemo(() => {
    const issueDays = getOrganizationConfig(
      organization.config,
      'openBooking.daysBeforeExpiration'
    );

    return formatMessage(
      {
        id:
          issueDays > 1
            ? 'open-booking.issue-days.plural'
            : 'open-booking.issue-days.singular',
      },
      {
        days: issueDays,
      }
    );
  }, [organization]);

  // Render
  const renderContent = () => (
    <>
      <div className="mw-0">
        <div className="total-container mt-3">
          <span className="d-flex align-items-center justify-content-end header">
            {messages['general.total']}
          </span>

          <CurrencyWrapper
            value={travelParams.amount || 0}
            tooltipFill="white"
            decimalPlaces={2}
          >
            {({ formattedValue }) => (
              <AdjustableText
                textClassName="amount"
                text={formattedValue}
                minFontSize={16}
              />
            )}
          </CurrencyWrapper>

          {additionalInfo ? (
            <span style={{ fontSize: '0.5em' }} className="text-theme-3">
              {additionalInfo}
            </span>
          ) : null}

          <hr className="my-3 small" />
        </div>

        <div className="color-alert">
          <p className="mb-0 fs-0-7rem lh-1-25">{issueMessage}</p>
        </div>

        <ReservationButton
          onClick={createReservation}
          disabled={disabled}
          title={title}
          isValid={isValid}
          text={messages['open-booking.action.btn']}
          className="mr-0"
        />
      </div>
    </>
  );

  return (
    <ModalFooter className="p-3">
      {showSteps ? (
        <OpenBookingSteps
          toggle={toggleSteps}
          onCreate={createReservation}
          isReserving={isReserving}
        />
      ) : (
        renderContent()
      )}
    </ModalFooter>
  );
}
