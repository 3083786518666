import classNames from 'classnames';
import Switch from 'rc-switch';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Button, CardBody, Collapse, Label, Row } from 'reactstrap';
import { Colxx } from 'src/portao3-legacy/components/common/CustomBootstrap';
import useShowToggle from 'src/portao3-legacy/hooks/useShowToggle';
import firebaseApp from 'src/portao3-legacy/services/Firebase';
import { getAuthRedux } from 'src/portao3-legacy/utils/redux';
import { sortAlphabetically } from 'src/portao3-legacy/utils/sort';

export default function MerchantToggles({ id, merchants, className }) {
  const { messages } = useIntl();
  const { organization } = useSelector(getAuthRedux);

  // Hooks
  const [isOpen, setIsOpen, toggleIsOpen] = useShowToggle();

  // States
  const storedCategories = useMemo(
    () => (organization.config?.refunds?.categories || {})[id] || {},
    [id, organization]
  );

  const title = useMemo(
    () => messages[`admin.refunds.merchants.${id}.title`],
    [id]
  );
  const categories = useMemo(
    () =>
      sortAlphabetically(
        Object.keys(merchants).map((value) => ({
          value,
          label: messages[`refund.budget-category.${id}.${value}`],
        })),
        'label'
      ),
    [id, merchants]
  );

  // Functions
  const updateMerchantConfig = async (category, value) => {
    try {
      await firebaseApp.updateConfigField(
        organization.id,
        `config.refunds.categories.${id}.${category}`,
        value
      );
    } catch (err) {
      console.error('Unable to update merchant config', err);
    }
  };

  // Render
  return (
    <div className={classNames('border', className)}>
      <Button color="link" onClick={toggleIsOpen} aria-expanded={isOpen}>
        {title}
      </Button>

      <Collapse isOpen={isOpen}>
        <section className="px-4 py-2">
          {categories.map((category) => (
            <Label key={category.value} className="d-block">
              <Switch
                checked={storedCategories[category.value] !== false}
                onChange={(toggleValue) =>
                  updateMerchantConfig(category.value, toggleValue)
                }
                className="mr-2 custom-switch"
              />

              {category.label}
            </Label>
          ))}
        </section>
      </Collapse>
    </div>
  );
}
