import { useEffect } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import MyCards from '../../containers/expenses/MyCards';
import ExpensesLayout from '../../layout/ExpensesLayout';
import { useDispatch, useSelector } from 'react-redux';
import { setPassenger } from '../../redux/actions';
import Inbox from '../../containers/expenses/Inbox/Inbox';
import { canGoToExpenseRoute } from '../../utils/organization';
import { useIntl } from 'react-intl';
import Recurrence from '../../containers/expenses/Recurrence/Recurrence';

function Expenses({ match }) {
  const { messages } = useIntl();
  const dispatch = useDispatch();

  const { user, organization } = useSelector(({ auth }) => auth);
  const history = useHistory();

  // Effects
  useEffect(() => {
    document.title = `Portão 3 - ${messages['menu.expenses']}`;
    dispatch(setPassenger(user));
  }, []);

  useEffect(() => {
    if (organization && !canGoToExpenseRoute(organization))
      history.push('/travel');
  }, [organization]);

  // Render
  return (
    <ExpensesLayout>
      <Switch>
        <Route exact path={`${match.url}/`} component={MyCards} />
        {/* <Route exact path={`${match.url}/inbox`} component={Inbox} /> */}

        <Route exact path={`${match.url}/cards`} component={MyCards} />
        <Route
          exact
          path={`${match.url}/cards/:budgetId`}
          component={MyCards}
        />
        <Route
          exact
          path={`${match.url}/cards/:budgetId/approval`}
          component={MyCards}
        />
        <Route
          exact
          path={`${match.url}/cards/:budgetId/items/:itemId`}
          component={MyCards}
        />

        <Route
          exact
          path={`${match.url}/cards/pin/:cardId`}
          component={MyCards}
        />

        <Route exact path={`${match.url}/recurrence`} component={Recurrence} />
        <Route
          exact
          path={`${match.url}/recurrence/:budgetId`}
          component={Recurrence}
        />

        <Redirect to="/error" />
      </Switch>
    </ExpensesLayout>
  );
}

export default Expenses;
