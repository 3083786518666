import { loadCostCenters } from '../utils/costCenters';
import { loadMotives } from '../utils/motives';
import { loadPassengers } from '../utils/passengers';
import { loadProjects } from '../utils/projects';
import { loadTags } from '../utils/tags';
import { loadRoles } from '../utils/roles';
import { OrderTypes } from './orderTypes';
import { RefundStatus } from './refundStatus';
import { countries } from './countries';
import { ADMINISTRATIVE_BUDGET_TYPES } from './administrativeBudgets';

export const policyTypes = {
  travels: 'TRAVEL',
  refunds: 'REFUND',
  expenses: 'EXPENSES',
  payments: 'EXPENSES',
};

export const policyFieldType = {
  MASK: 'MASK',
  NUMBER_MASK: 'NUMBER_MASK',
  DATA: 'DATA',
  CURRENCY: 'CURRENCY',
  BOOLEAN: 'BOOLEAN',
  STRING: 'STRING',
  NUMBER: 'NUMBER',
  ARRAY: 'ARRAY',
};

// Comparisons
export const comparisonOptions = [
  { label: 'Igual a', value: '=', key: 0 },
  { label: 'Diferente de', value: '!=', key: 1 },
  { label: 'Menor que', value: '<', key: 2 },
  { label: 'Menor ou igual a', value: '<=', key: 3 },
  { label: 'Maior que', value: '>', key: 4 },
  { label: 'Maior ou igual que a', value: '>=', key: 5 },
  { label: 'Começa com', value: 'STARTS_WITH', key: 6 },
  { label: 'Termina com', value: 'ENDS_WITH', key: 8 },
  { label: 'Contém', value: 'CONTAINS', key: 9 },
  { label: 'Igual a', value: 'IN', key: 10 },
  { label: 'Diferente de', value: 'NOT_IN', key: 11 },
];

export const comparisonOptionsObject = {
  EQUAL: { label: 'Igual a', value: '=', key: 0 },
  DIFF: { label: 'Diferente de', value: '!=', key: 1 },
  LESS: { label: 'Menor que', value: '<', key: 2 },
  LESS_EQUAL: { label: 'Menor ou igual a', value: '<=', key: 3 },
  GREATER: { label: 'Maior que', value: '>', key: 4 },
  GREATER_EQUAL: { label: 'Maior ou igual que a', value: '>=', key: 5 },
  STARTS: { label: 'Começa com', value: 'STARTS_WITH', key: 6 },
  ENDS: { label: 'Termina com', value: 'ENDS_WITH', key: 8 },
  CONTAINS: { label: 'Contém', value: 'CONTAINS', key: 9 },
  ARRAY_IN: { label: 'Igual a', value: 'IN', key: 10 },
  ARRAY_NOT_IN: { label: 'Diferente de', value: 'NOT_IN', key: 11 },
};

export const comparisonOptionsByType = {
  [policyFieldType.DATA]: [
    comparisonOptionsObject.EQUAL,
    comparisonOptionsObject.DIFF,
  ],
  [policyFieldType.CURRENCY]: [
    comparisonOptionsObject.EQUAL,
    comparisonOptionsObject.DIFF,
    comparisonOptionsObject.LESS,
    comparisonOptionsObject.LESS_EQUAL,
    comparisonOptionsObject.GREATER,
    comparisonOptionsObject.GREATER_EQUAL,
  ],
  [policyFieldType.BOOLEAN]: [
    comparisonOptionsObject.EQUAL,
    comparisonOptionsObject.DIFF,
  ],
  [policyFieldType.MASK]: [
    comparisonOptionsObject.EQUAL,
    comparisonOptionsObject.DIFF,
    comparisonOptionsObject.LESS,
    comparisonOptionsObject.LESS_EQUAL,
    comparisonOptionsObject.GREATER,
    comparisonOptionsObject.GREATER_EQUAL,
  ],
  [policyFieldType.STRING]: [
    comparisonOptionsObject.EQUAL,
    comparisonOptionsObject.DIFF,
    comparisonOptionsObject.STARTS,
    comparisonOptionsObject.ENDS,
    comparisonOptionsObject.CONTAINS,
  ],
  [policyFieldType.NUMBER]: [
    comparisonOptionsObject.EQUAL,
    comparisonOptionsObject.DIFF,
    comparisonOptionsObject.LESS,
    comparisonOptionsObject.LESS_EQUAL,
    comparisonOptionsObject.GREATER,
    comparisonOptionsObject.GREATER_EQUAL,
  ],
  [policyFieldType.NUMBER_MASK]: [
    comparisonOptionsObject.EQUAL,
    comparisonOptionsObject.DIFF,
    comparisonOptionsObject.LESS,
    comparisonOptionsObject.LESS_EQUAL,
    comparisonOptionsObject.GREATER,
    comparisonOptionsObject.GREATER_EQUAL,
  ],
  [policyFieldType.ARRAY]: [
    comparisonOptionsObject.ARRAY_IN,
    comparisonOptionsObject.ARRAY_NOT_IN,
  ],
};

const onBlurIata = (e, onChange = () => {}) => {
  const value = (e.target.value || '').replace(/_/g, '');

  if (value.length === 3) onChange(value.toUpperCase());
  else onChange('');
};

export const generatePassengerRules = (name = 'Passageiro', keyStart = 0) => {
  const rules = [
    {
      label: 'É Admin',
      value: 'passenger.admin',
      type: policyFieldType.BOOLEAN,
    },
    {
      label: 'Data de Nascimento',
      value: 'passenger.birthdate',
      type: policyFieldType.MASK,
      mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
      placeholder: 'DD/MM/YYYY',
    },
    {
      label: 'Centro de Custo',
      value: 'passenger.costCenter.id',
      type: policyFieldType.DATA,
      fetchFunction:
        ({ organization }) =>
        async () =>
          loadCostCenters(organization.id, true),
      dataKey: 'costCenter',
    },
    {
      label: 'CNH - Número',
      value: 'passenger.documents.cnh.number',
      type: policyFieldType.STRING,
    },
    {
      label: 'CNH - Validade',
      value: 'passenger.documents.cnh.validUntil',
      type: policyFieldType.STRING,
    },
    {
      label: 'CPF - Número',
      value: 'passenger.documents?.cpf?.number',
      type: policyFieldType.MASK,
      mask: [
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
      ],
      placeholder: '000.000.000-00',
    },
    {
      label: 'Passaporte - País',
      value: 'passenger.documents.passport.country',
      type: policyFieldType.STRING,
    },
    {
      label: 'Passaporte - Número',
      value: 'passenger.documents.passport.number',
      type: policyFieldType.STRING,
    },
    {
      label: 'Passaporte - Validade',
      value: 'passenger.documents.passport.validUntil',
      type: policyFieldType.STRING,
    },
    {
      label: 'RG - Emissor',
      value: 'passenger.documents.rg.emitter',
      type: policyFieldType.STRING,
    },
    {
      label: 'RG - Número',
      value: 'passenger.documents.rg.number',
      type: policyFieldType.STRING,
    },
    { label: 'Email', value: 'passenger.email', type: policyFieldType.STRING },
    {
      label: 'Primeiro Nome',
      value: 'passenger.firstName',
      type: policyFieldType.STRING,
    },
    {
      label: 'Gênero',
      value: 'passenger.gender',
      type: policyFieldType.STRING,
    },
    {
      label: 'Sobrenome',
      value: 'passenger.lastName',
      type: policyFieldType.STRING,
    },
    {
      label: 'Celular',
      value: 'passenger.mobilePhoneNumber',
      type: policyFieldType.MASK,
      mask: [
        '(',
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ],
      placeholder: '(00) 0 0000-0000',
    },
    {
      label: 'Telefone',
      value: 'passenger.phoneNumber',
      type: policyFieldType.MASK,
      mask: [
        '(',
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ],
      placeholder: '(00) 0000-0000',
    },
    {
      label: 'Projeto',
      value: 'passenger.project.id',
      type: policyFieldType.DATA,
      fetchFunction:
        ({ organization }) =>
        async () =>
          loadProjects(organization.id, true),
      dataKey: 'project',
    },
    { label: 'Cargo', value: 'passenger.role', type: policyFieldType.STRING },
    { label: 'ID', value: 'passenger.uid', type: policyFieldType.STRING },
    {
      label: 'Cargo',
      value: 'passenger.role',
      type: policyFieldType.DATA,
      fetchFunction:
        ({ organization }) =>
        async () =>
          loadRoles(organization.id),
      dataKey: 'roles',
    },
  ];

  return rules.map((rule, index) => ({
    ...rule,
    label: `${name} - ${rule.label}`,
    key: keyStart + index,
  }));
};

export const rulesOptions = {
  travels: [
    {
      label: 'Pedido - Tipo',
      value: 'order.type',
      key: 0,
      type: policyFieldType.DATA,
      fetchFunction:
        ({ messages }) =>
        async () => {
          return Object.values(OrderTypes).map((value) => ({
            value,
            label: messages[`travel.menu.${value}`],
          }));
        },
      dataKey: 'type',
    },
    {
      label: 'Pedido - Projeto',
      value: 'order.project.value',
      key: 1,
      type: policyFieldType.DATA,
      fetchFunction:
        ({ organization }) =>
        async () =>
          loadProjects(organization.id, true),
      dataKey: 'project',
    },
    {
      label: 'Pedido - Centro de Custo',
      value: 'order.costCenter.value',
      key: 2,
      type: policyFieldType.DATA,
      fetchFunction:
        ({ organization }) =>
        async () =>
          loadCostCenters(organization.id, true),
      dataKey: 'costCenter',
    },
    {
      key: 3,
      label: 'Pedido - Antecedência de compra',
      value: 'special.advancePurchase',
      type: policyFieldType.NUMBER_MASK,
      mask: {
        allowNegative: false,
        suffix: ' dia(s)',
        decimalScale: 0,
      },
    },
    {
      label: 'Pedido - País de Origem',
      value: 'special.countryOrigin',
      key: 4,
      type: policyFieldType.DATA,
      fetchFunction:
        ({ messages }) =>
        async () =>
          Object.keys(countries).map((key) => {
            return { value: key, label: countries[key] };
          }),
      dataKey: 'countryOrigin',
    },
    {
      label: 'Pedido - País de Destino',
      value: 'special.countryDestination',
      key: 5,
      type: policyFieldType.DATA,
      fetchFunction:
        ({ messages }) =>
        async () =>
          Object.keys(countries).map((key) => {
            return { value: key, label: countries[key] };
          }),
      dataKey: 'countryDestination',
    },
    {
      label: 'Pedido - Tarifa - Valor Inicial',
      value: 'order.fare.fare',
      key: 6,
      type: policyFieldType.CURRENCY,
    },
    {
      label: 'Pedido - Tarifa - Taxas',
      value: 'order.fare.taxes',
      key: 7,
      type: policyFieldType.CURRENCY,
    },
    {
      label: 'Pedido - Tarifa - Adicional',
      value: 'order.fare.additional',
      key: 8,
      type: policyFieldType.CURRENCY,
    },
    {
      label: 'Pedido - Tarifa - Quantidade',
      value: 'order.fare.quantity',
      key: 8,
      type: policyFieldType.NUMBER,
    },
    {
      label: 'Pedido - Tarifa - Total',
      value: 'order.fare.total',
      key: 10,
      type: policyFieldType.CURRENCY,
    },
    {
      label: 'Pedido - Tarifa - Comparativo de menor preço',
      value: 'special.minimumPricePercentage',
      key: 11,
      type: policyFieldType.NUMBER_MASK,
      mask: {
        allowNegative: false,
        suffix: '%',
        decimalScale: 0,
      },
    },
    {
      label: 'Pedido - Qtd. Viagens',
      value: 'order.journeys.length',
      key: 12,
      type: policyFieldType.NUMBER,
    },
    {
      label: 'Pedido - Aéreo - Aeroporto de origem (IATA)',
      value: 'special.outbound',
      key: 13,
      type: policyFieldType.MASK,
      comparissonType: policyFieldType.ARRAY,
      mask: [/[A-Za-z]/, /[A-Za-z]/, /[A-Za-z]/],
      placeholder: 'GRU',
      onBlur: onBlurIata,
    },
    {
      label: 'Pedido - Aéreo - Aeroporto de destino (IATA)',
      value: 'special.inbound',
      key: 14,
      type: policyFieldType.MASK,
      comparissonType: policyFieldType.ARRAY,
      mask: [/[A-Za-z]/, /[A-Za-z]/, /[A-Za-z]/],
      placeholder: 'SDU',
      onBlur: onBlurIata,
    },
    {
      label: 'Pedido - Aéreo - Tarifa média por trecho',
      value: 'special.ratePerSegment',
      key: 15,
      type: policyFieldType.CURRENCY,
    },
    {
      label: 'Pedido - Aéreo - Duração entre jornadas',
      value: 'special.betweenJourneyDuration',
      key: 16,
      type: policyFieldType.NUMBER_MASK,
      mask: {
        allowNegative: false,
        suffix: ' dias(s)',
        decimalScale: 0,
      },
    },
    {
      label: 'Pedido - Aéreo - Bagagens inclusas',
      value: 'special.flightIncludedBaggage',
      key: 17,
      type: policyFieldType.NUMBER_MASK,
      mask: {
        allowNegative: false,
        suffix: ' bagagens(s)',
        decimalScale: 0,
      },
    },
    {
      label: 'Pedido - Aéreo - Duração da jornada',
      value: 'special.journeyDuration',
      key: 18,
      type: policyFieldType.NUMBER_MASK,
      mask: {
        allowNegative: false,
        suffix: ' minuto(s)',
        decimalScale: 0,
      },
    },
    {
      label: 'Pedido - Aéreo - Conexões por jornada',
      value: 'special.journeyLegs',
      key: 19,
      type: policyFieldType.NUMBER_MASK,
      mask: {
        allowNegative: false,
        suffix: ' conexões(s)',
        decimalScale: 0,
      },
    },
    {
      label: 'Pedido - Aéreo - Quantidade de Países',
      value: 'special.journeyCountries',
      key: 20,
      type: policyFieldType.NUMBER_MASK,
      mask: {
        allowNegative: false,
        suffix: ' país(es)',
        decimalScale: 0,
      },
    },
    {
      label: 'Pedido - Aéreo - Cabine',
      value: 'special.cabin',
      key: 21,
      type: policyFieldType.DATA,
      fetchFunction:
        ({ messages }) =>
        async () =>
          [
            {
              value: 'ECONOMY',
              label: messages['travel.Economy'],
            },
            {
              value: 'PREMIUMECONOMY',
              label: messages['travel.PremiumEconomy'],
            },
            {
              value: 'BUSINESS',
              label: messages['travel.Business'],
            },
            {
              value: 'FIRST',
              label: messages['travel.FirstClass'],
            },
          ],
    },
    {
      label: 'Pedido - Hotel - Tarifa diária',
      value: 'special.dailyRate',
      key: 22,
      type: policyFieldType.CURRENCY,
    },
    {
      label: 'Pedido - Hotel - Nome do hotel',
      value: 'special.hotelName',
      key: 23,
      type: policyFieldType.STRING,
    },
    {
      label: 'Pedido - Carro - Tarifa diária',
      value: 'special.carDailyRate',
      key: 24,
      type: policyFieldType.CURRENCY,
    },
    {
      label: 'Pedido - Ônibus - Tarifa média por trecho',
      value: 'special.busRatePerSegment',
      key: 25,
      type: policyFieldType.CURRENCY,
    },
    {
      label: 'Pedido - Passageiro',
      value: 'order.passengerId',
      key: 26,
      type: policyFieldType.DATA,
      fetchFunction:
        ({ organization }) =>
        async () =>
          loadPassengers(organization.id),
      dataKey: 'passenger',
    },
    {
      label: 'Pedido - Solicitante',
      value: 'order.requestorId',
      key: 27,
      type: policyFieldType.DATA,
      fetchFunction:
        ({ organization }) =>
        async () =>
          loadPassengers(organization.id),
      dataKey: 'passenger',
    },
    {
      label: 'Pedido - Solicitante - Cargo',
      value: 'requestor.role',
      key: 28,
      type: policyFieldType.DATA,
      fetchFunction:
        ({ organization }) =>
        async () =>
          loadRoles(organization.id),
      dataKey: 'roles',
    },
    {
      label: 'Pedido - Open Boooking',
      value: 'special.openBooking',
      key: 29,
      type: policyFieldType.BOOLEAN,
    },
    {
      label: 'Extras - Assento - Total',
      value: 'ancillaries.seat.fare.total',
      key: 30,
      type: policyFieldType.CURRENCY,
    },
    {
      label: 'Extras - Bagagem - Total',
      value: 'ancillaries.bag.fare.total',
      key: 31,
      type: policyFieldType.CURRENCY,
    },
    ...generatePassengerRules('Passageiro', 30),
  ],
  payments: [
    {
      label: 'Despesas - Tipo de Aprovação',
      value: 'budget.statusCode',
      key: 1,
      type: policyFieldType.DATA,
      fetchFunction:
        ({ messages }) =>
        async () =>
          [
            {
              value: RefundStatus.APPROVING_ANTICIPATION,
              label: messages['budget.policy.anticipation-approval'],
            },
            {
              value: RefundStatus.APPROVING,
              label: messages['budget.policy.approval'],
            },
          ],
      dataKey: 'approvalTypes',
    },
    {
      label: 'Despesas - Tarifa - Total',
      value: 'budget.fare.total',
      key: 2,
      type: policyFieldType.CURRENCY,
    },
    {
      label: 'Despesas - Antecipação - Valor',
      value: 'budget.budget',
      key: 3,
      type: policyFieldType.CURRENCY,
    },
    {
      label: 'Despesas - Centro de custo',
      value: 'budget.costCenter.value',
      key: 4,
      type: policyFieldType.DATA,
      fetchFunction:
        ({ organization }) =>
        async () =>
          loadCostCenters(organization.id, true),
      dataKey: 'costCenter',
    },
    {
      label: 'Despesas - Projeto',
      value: 'budget.project.value',
      key: 5,
      type: policyFieldType.DATA,
      fetchFunction:
        ({ organization }) =>
        async () =>
          loadProjects(organization.id, true),
      dataKey: 'project',
    },
    {
      label: 'Despesas - Tag',
      value: 'budget.tag.value',
      key: 6,
      type: policyFieldType.DATA,
      fetchFunction:
        ({ organization }) =>
        async () =>
          loadTags(organization.id, true),
      dataKey: 'tag',
    },
    {
      label: 'Despesas - Motivo',
      value: 'budget.motive.value',
      key: 7,
      type: policyFieldType.DATA,
      fetchFunction:
        ({ organization }) =>
        async () =>
          loadMotives(organization.id, true),
      dataKey: 'motive',
    },
    {
      label: 'Despesas - Pagamento - Total',
      value: 'special.paymentTotal',
      key: 8,
      type: policyFieldType.CURRENCY,
    },
    {
      label: 'Despesas - Pagamento - Tipo',
      value: 'special.paymentType',
      key: 9,
      type: policyFieldType.DATA,
      fetchFunction:
        ({ messages }) =>
        async () =>
          [
            {
              value: ADMINISTRATIVE_BUDGET_TYPES.BOLETO,
              label: messages['administrative.payment-option.boleto'],
            },
            {
              value: ADMINISTRATIVE_BUDGET_TYPES.PIX,
              label: messages['administrative.payment-option.pix'],
            },
          ],
      dataKey: 'paymentType',
    },
    {
      label: 'Despesas - Usuário',
      value: 'budget.passengerId',
      key: 10,
      type: policyFieldType.DATA,
      fetchFunction:
        ({ organization }) =>
        async () =>
          loadPassengers(organization.id),
      dataKey: 'passenger',
    },
    ...generatePassengerRules('Solicitante', 11),
  ],
};

// Actions
export const actionType = [
  { label: 'Ação automática', value: 'AUTO', key: 0 },
  { label: 'Aprovação de usuário', value: 'USER', key: 1 },
  { label: 'Aprovação de grupo', value: 'GROUP', key: 2 },
];

export const actionTypeValues = {
  AUTO: 'AUTO',
  USER: 'USER',
  GROUP: 'GROUP',
};

export const actionAutoValue = [
  { label: 'Aprovar', value: 'ALLOW', key: 0 },
  { label: 'Recusar', value: 'DENY', key: 1 },
];
