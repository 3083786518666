import React, { useMemo } from 'react';
import UserProfile from '../../components/common/UserProfile';

export default function SupplierIcon({ supplier }) {
  const [name, imgSrc] = useMemo(() => {
    const { nomeFantasia, nomeEmpresarial, image } = supplier;
    return [
      nomeFantasia || nomeEmpresarial,
      image
        ? `https://storage.googleapis.com/portao3-static/suppliers/${supplier.image}`
        : null,
    ];
  }, [supplier]);

  return <UserProfile name={name} imgSrc={imgSrc} />;
}
