import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Row, Modal, ModalBody, ModalFooter, CustomInput } from 'reactstrap';
import { Colxx } from '../../components/common/CustomBootstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import moment from 'moment';
import find from 'lodash/find';
import minBy from 'lodash/minBy';
import orderBy from 'lodash/orderBy';
import map from 'lodash/map';

import { stringToMoney } from '../../utils/money';
import ReservationParams from './ReservationParams/ReservationParams';
import HotelPhotos from '../../components/order/HotelPhotos';
import HotelAmenities from '../../components/order/HotelAmenities';
import HotelStars from '../../components/order/HotelStars';
import { useIntl } from 'react-intl';
import { trackPropertiesSegment } from '../../utils/segment';
import ReservationButton from './ReservationButton';

import { ReactComponent as CancelIcon } from '../../assets/img/icons/cancel.svg';
import { ReactComponent as BreakfastIcon } from '../../assets/img/icons/meal-breakfast.svg';
import useIsReservationValid from '../../hooks/useIsReservationValid';
import CurrencyWrapper from '../../components/common/CurrencyWrapper';
import { DEFAULT_NATIONALITY } from '../../constants/countries';
import OfflineReservationBadge from '../../components/OfflineReservationBadge';
import { ORDER_PROVIDERS } from '../../constants/orderProviders';
import OrderStructures from '../../constants/orderStructure';
import RoomItem from './RoomItem';
import ReservationReview from '../../components/order/ApprovalInfo/Approvals/ReservationReview';
import useModalStages from '../../hooks/useModalStages';

function HotelTotal({
  user,
  searchCode = '',
  item,
  selectedRoom,
  createReservation,
  canReservate = true,
  reservationParams,
  orderStructure,
  serviceCharge = 0,
  selectMode,
  setGoForDetails,
  hasAlternatives,
  isModalAlternativesClosed,
}) {
  const { messages, formatMessage } = useIntl();

  // States
  const [shouldDoReservation, setShouldDoReservation] = useState(
    isModalAlternativesClosed
  );

  useEffect(() => {
    if (isModalAlternativesClosed) {
      setGoForDetails(false);
      setShouldDoReservation(false);
    }
  }, [isModalAlternativesClosed]);
  const cheapestRoom = useMemo(() => minBy(item.rooms, 'fare'), [item]);

  const additionalInfo = useMemo(
    () =>
      orderStructure === OrderStructures.PERSONAL
        ? formatMessage(
            {
              id: 'containers.search.flight-modal.taxes',
            },
            {
              taxes: stringToMoney(serviceCharge, 0, item.fare.currency),
            }
          )
        : null,
    [item, orderStructure, serviceCharge]
  );

  const { isValid, disabled, title } = useIsReservationValid({
    orderStructure,
    reservationParams,
  });

  if (selectedRoom?.id) {
    let hasFreeCancellation = selectedRoom?.cancellation?.length > 0;
    let freeCancellationDate = null;

    if (hasFreeCancellation) {
      freeCancellationDate = minBy(selectedRoom?.cancellation, (c) => {
        return moment(c.startDate).unix();
      }).startDate;

      // Free cancelation is past date?
      if (moment(freeCancellationDate).isBefore(moment())) {
        hasFreeCancellation = false;
        freeCancellationDate = null;
      }
    }

    // Render
    return (
      <ModalFooter>
        <div>
          <div className="text mt-3 pr-3">
            <span>{messages['general.total']}</span>
            <CurrencyWrapper
              value={selectedRoom?.fare}
              tooltipFill="white"
              decimalPlaces={0}
            >
              {({ formattedValue }) => formattedValue}
            </CurrencyWrapper>
            {additionalInfo ? (
              <span style={{ fontSize: '0.5em' }} className="text-theme-3 mb-2">
                {additionalInfo}
              </span>
            ) : null}
            <hr />
          </div>
          <div className="description pr-3">
            <p className="mb-0">
              {selectedRoom?.quantity}{' '}
              {selectedRoom?.quantity > 1
                ? messages['travel.rooms']
                : messages['travel.room']}
            </p>

            <p className="mb-0">
              {selectedRoom?.adults}{' '}
              {selectedRoom?.quantity > 1
                ? messages['general.adults']
                : messages['general.adult']}
            </p>

            <p>{selectedRoom?.description}</p>
          </div>
          <div className="text pr-3">
            <hr />
          </div>
          <div className="description pr-3">
            <p>{selectedRoom?.board.description}</p>

            {hasFreeCancellation && (
              <p className="color-alert">
                {messages['travel.order.cancellation.date']}{' '}
                {moment(freeCancellationDate).format('DD/MM/YYYY')}
              </p>
            )}

            <p className="mb-0 text-small">
              {messages['containers.search.modal.fees']}
            </p>
          </div>
          <ReservationButton
            onClick={
              hasAlternatives
                ? !shouldDoReservation
                  ? () => {
                      setGoForDetails(true);
                      setShouldDoReservation(true);
                    }
                  : createReservation
                : createReservation
            }
            disabled={!canReservate || disabled}
            isValid={isValid}
            title={title}
            selectMode={selectMode}
          />
        </div>
      </ModalFooter>
    );
  } else {
    return (
      <ModalFooter>
        <div>
          <div className="text mt-3 pr-3">
            <span>{messages['general.from']}</span>
            <CurrencyWrapper
              value={cheapestRoom.fare}
              tooltipFill="white"
              decimalPlaces={0}
            >
              {({ formattedValue }) => formattedValue}
            </CurrencyWrapper>
            <hr />
          </div>
          <div className="description pr-3">
            <p>{messages['containers.search.hotel-modal.select-room']}</p>
          </div>
        </div>
      </ModalFooter>
    );
  }
}

export default function HotelModal({
  searchCode,
  toggleModal,
  item,
  reserveItem,
  nationality = DEFAULT_NATIONALITY,
  selectItem,
  numDays,
  invalidRooms = {},
  selectedRoom: _selectedRoom = {},
  handleSelectHotel,
  hasAlternatives,
  params,
}) {
  const { messages } = useIntl();
  const { user, serviceCharge, locale } = useSelector(({ auth, settings }) => ({
    user: auth.user,
    serviceCharge: parseFloat(auth.organization.fee?.hotel || 0),
    locale: settings.locale,
  }));

  // States
  const [reservationParams, setReservationParams] = useState({});
  const [selectedRoom, setRoom] = useState(_selectedRoom);
  const [roomsInfo, setRoomsInfo] = useState({});
  const [orderStructure, setStructure] = useState(OrderStructures.ORGANIZATION);
  const [goForDetails, setGoForDetails] = useState(false);
  const [isModalAlternativesClosed, setIsModalAlternativesClosed] =
    useState(false);

  const showReservationReview = (show) => {
    setGoForDetails(show);
  };

  const hotelDescription = useMemo(
    () =>
      item.hotel.description[locale.split('-')[0]] ||
      item.hotel.description['pt'],
    [item, locale]
  );

  const selectMode = useMemo(() => !!selectItem, [selectItem]);

  const hotelRooms = useMemo(
    () =>
      Object.keys(invalidRooms).length
        ? item.rooms.filter(({ id }) => !invalidRooms[id])
        : item.rooms,
    [item, invalidRooms]
  );

  useEffect(() => {
    if (goForDetails === false) {
      setIsModalAlternativesClosed(false);
    }
  }, [goForDetails]);

  // Effects
  useEffect(() => {
    if (item) {
      setRoomsInfo(
        item.hotel.rooms.reduce(
          (currentObj, room) => ({ ...currentObj, [room.id]: room }),
          {}
        )
      );
    }
  }, [item]);

  // Functions
  const selectCheckbox = (e) => {
    if (e.target.checked) {
      const room = find(item.rooms, ['id', e.target.id]);
      const roomInfo = find(item.hotel.rooms, ['id', room.mediaId]) || {};
      const roomObj = { ...room, info: roomInfo };

      setRoom(roomObj);
      trackPropertiesSegment('User selected a room', { room, user });
    } else {
      setRoom({});
    }
  };

  // Reservation
  const createReservation = () => {
    if (selectMode) {
      selectItem({
        item: item,
        selectedRoom: selectedRoom,
      });

      toggleModal();
    } else if (orderStructure) {
      reserveItem({
        reservationParams,
        item: item,
        selectedRoom: selectedRoom,
      });
    }
  };

  const handleCloseAlernatives = () => {
    setIsModalAlternativesClosed(true);
  };

  const renderHotelModal = () => {
    return (
      <Modal
        isOpen={true}
        toggle={() => {
          trackPropertiesSegment('User closed hotel modal', { item, user });
          toggleModal();
        }}
        style={{ width: '80%', height: '100%' }}
        className="reservation"
      >
        <ModalBody className="hotel">
          {!goForDetails ? (
            <Row>
              <Colxx xxs="5" className="pr-0">
                <HotelPhotos
                  hotel={item.hotel}
                  hotelId={item.hotelId}
                  room={selectedRoom}
                />
              </Colxx>
              <Colxx xxs="7" className="pl-0">
                <div className="hotel-content">
                  <PerfectScrollbar
                    options={{
                      suppressScrollX: true,
                      wheelPropagation: false,
                    }}
                  >
                    <h3 className="hotel-title ml-3">
                      {item.hotel.name}
                      <HotelStars hotel={item.hotel} hotelId={item.hotelId} />
                    </h3>

                    <p className="text-muted-darke ml-3 text-capitalize">
                      {item.hotel.destination.address.toLowerCase()}
                    </p>

                    <HotelAmenities hotel={item.hotel} hotelId={item.hotelId} />

                    {selectMode ? null : (
                      <ReservationParams
                        setReservationParams={setReservationParams}
                        orderStructure={orderStructure}
                        setStructure={setStructure}
                        nationality={nationality}
                        hasNationality
                      />
                    )}

                    <div className="reservation-rooms">
                      <div className="d-flex justify-content-between px-3 header">
                        <h5>
                          {
                            messages[
                              'containers.search.hotel-modal.room-category'
                            ]
                          }
                        </h5>
                        <h5>
                          {
                            messages[
                              'containers.search.hotel-modal.price-total'
                            ]
                          }
                        </h5>
                      </div>
                      <div className="rooms">
                        {map(orderBy(hotelRooms, 'fare'), (room, index) => {
                          return (
                            <RoomItem
                              key={`room-${index}`}
                              roomsInfo={roomsInfo}
                              index={index}
                              room={room}
                              selectedRoom={selectedRoom}
                              selectCheckbox={selectCheckbox}
                              locale={locale}
                              numDays={numDays}
                            />
                          );
                        })}
                      </div>
                    </div>

                    <h5 className="mt-4 ml-3">
                      {
                        messages[
                          'containers.search.hotel-modal.hotel-description'
                        ]
                      }
                    </h5>
                    <div
                      className="hotel-description ml-3 mr-3 mb-5"
                      dangerouslySetInnerHTML={{
                        __html: hotelDescription,
                      }}
                    />
                  </PerfectScrollbar>
                </div>
              </Colxx>
            </Row>
          ) : (
            renderReservationReview()
          )}
        </ModalBody>

        <HotelTotal
          user={user}
          searchCode={searchCode}
          item={item}
          selectedRoom={selectedRoom}
          createReservation={createReservation}
          canReservate={orderStructure}
          reservationParams={reservationParams}
          orderStructure={orderStructure}
          serviceCharge={serviceCharge}
          selectMode={selectMode}
          setGoForDetails={showReservationReview}
          hasAlternatives={hasAlternatives}
          isModalAlternativesClosed={isModalAlternativesClosed}
        />
      </Modal>
    );
  };

  const renderReservationReview = () => {
    return (
      <div>
        <ReservationReview
          searchId={searchCode}
          item={item}
          selectedRoom={selectedRoom}
          type="hotel"
          createReservation={createReservation}
          handleSelectHotel={handleSelectHotel}
          handleCloseAlernatives={handleCloseAlernatives}
          params={params}
        />
      </div>
    );
  };

  return renderHotelModal();
}
