import React, { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import approvalStatus from '../../constants/approvalStatus';
import { RefundStatus } from '../../constants/refundStatus';
import useBlockableAction from '../../hooks/useBlockableAction';
import firebaseApp from '../../services/Firebase';
import CommentInput from '../expensesTimeline/CommentInput';

export default function BudgetApproval({
  budget,
  approvals,
  goForApproval,
  toggleModal = () => {},
}) {
  const { messages } = useIntl();
  const { user } = useSelector(({ auth }) => auth);

  const { allowAction, blockAction, resetAction } = useBlockableAction({
    id: `${budget.id}-${budget.statusCode}`,
  });

  const [ongoingOperation, setOngoingOperation] = useState(false);
  const [showComment, setShowComment] = useState('');
  const [comment, setComment] = useState('');

  const antecipation = useMemo(
    () => budget.statusCode === RefundStatus.APPROVING_ANTICIPATION,
    [budget]
  );
  const canApprove = useMemo(
    () =>
      approvals.some(
        (approval) =>
          approval.status === approvalStatus.PENDING &&
          approval.userId === user.uid
      ),
    []
  );
  const shouldDisable = useMemo(
    () => ongoingOperation || !allowAction,
    [ongoingOperation, allowAction]
  );

  // Functions
  const perfomAction = async (func) => {
    try {
      blockAction();
      setOngoingOperation(true);
      setShowComment('');
      await func();
      goForApproval();
      toggleModal();
    } catch (err) {
      console.error(err);
      resetAction();
    } finally {
      setOngoingOperation(false);
    }
  };

  const requestToReview = () => {
    if (showComment) {
      perfomAction(() => firebaseApp.requestToReviewBudget(budget.id, comment));
    } else setShowComment('review');
  };

  const requestToApprove = () => {
    perfomAction(() =>
      firebaseApp.approveBudget(
        budget.id,
        approvals.find((approval) => approval.userId === user.uid).id
      )
    );
  };

  const requestToApproveNow = () => {
    perfomAction(() => {
      return budget.statusCode === RefundStatus.APPROVING_ANTICIPATION
        ? firebaseApp.requestToApproveBudgetAnticipation(budget.id)
        : firebaseApp.requestToApproveBudget(budget.id);
    });
  };

  const requestToReject = () => {
    if (showComment) {
      perfomAction(() => firebaseApp.requestToRejectBudget(budget.id, comment));
    } else setShowComment(true);
  };

  // Render
  return (
    <div className="approval-steps h-100">
      <PerfectScrollbar
        options={{
          suppressScrollX: true,
          wheelPropagation: false,
          useBothWheelAxes: false,
        }}
        className="d-flex flex-column justify-content-between py-2 pl-2 pr-4"
      >
        <h3 className="hotel-title m-0 p-0">
          {messages['refund.approval.title']}
        </h3>

        <div className="content flex-grow-1 py-2">
          <p style={{ opacity: 0.6 }}>
            {
              messages[
                antecipation
                  ? 'refund.anticipate-approval.text'
                  : 'expense.modal.approval.text'
              ]
            }
          </p>

          {showComment && (
            <div className="w-60 ml-auto mt-5">
              <CommentInput
                value={comment}
                onChange={setComment}
                placeholder={messages['expense.comment-placeholder']}
              />
            </div>
          )}
        </div>

        <div
          className="text-right btns my-3 mr-1 slide-right"
          style={{
            pointerEvents: ongoingOperation ? 'none' : 'auto',
            animationDelay: '0.2s',
          }}
        >
          {showComment ? (
            <>
              <Button
                size="sm"
                color="link"
                onClick={() => setShowComment('')}
                style={{ marginRight: -10 }}
                className="my-0"
              >
                {messages['general.go-back']}
              </Button>
              {antecipation || showComment === 'reprove' ? (
                <Button
                  size="sm"
                  className="my-0 ml-3 px-3"
                  onClick={requestToReject}
                  disabled={shouldDisable}
                  name="reprove-budget"
                >
                  {messages['button.approval.reprove']}
                </Button>
              ) : (
                <Button
                  size="sm"
                  className="my-0 ml-3 px-3"
                  onClick={requestToReview}
                  disabled={shouldDisable}
                >
                  {messages['button.approval.ask-review']}
                </Button>
              )}
            </>
          ) : (
            <>
              <Button
                size="sm"
                color="link"
                onClick={goForApproval}
                style={{ marginRight: -10 }}
                className="my-0"
              >
                {messages['general.go-back']}
              </Button>
              {antecipation || !budget.withAnticipation ? (
                <Button
                  size="sm"
                  className="my-0 ml-3 px-3"
                  onClick={requestToReject}
                  disabled={shouldDisable}
                  name="reprove-budget"
                >
                  {messages['button.approval.reprove']}
                </Button>
              ) : null}
              {antecipation ? null : (
                <Button
                  size="sm"
                  className="my-0 ml-3 px-3"
                  onClick={requestToReview}
                  disabled={shouldDisable}
                >
                  {messages['button.approval.ask-review']}
                </Button>
              )}
              {canApprove ? (
                <Button
                  size="sm"
                  className="my-0 ml-3 px-3"
                  onClick={requestToApprove}
                  disabled={shouldDisable}
                >
                  {messages['button.approval.approve']}
                </Button>
              ) : null}
              {user.admin ? (
                <Button
                  className="my-0 ml-3 px-3"
                  onClick={requestToApproveNow}
                  disabled={shouldDisable}
                  title={messages['button.approval.approve-now.description']}
                  size="sm"
                  name="approve-budget-immediately"
                >
                  {messages['button.approval.approve-now']}
                </Button>
              ) : null}
            </>
          )}
        </div>
      </PerfectScrollbar>
    </div>
  );
}
