import React, { useState } from 'react';
import appFunctions from '../../../services/Functions';
import { sortBusSeats } from '../../../utils/seats';
import PopupModal from '../../common/PopupModal/PopupModal';
import SeatMapTab from './tabs/SeatMapTab';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Button } from 'reactstrap';
import { useIntl } from 'react-intl';
import useShouldInitialize from '../../../hooks/useShouldInitialize';
import useDataLoaderWithArchive from '../../../hooks/useDataLoaderWithArchive';

import { ReactComponent as CloseIcon } from '../../../assets/img/icons/close.svg';

function fetchSeats(scheduleId) {
  return {
    get: () => appFunctions.getBusSeats(scheduleId),
  };
}

export default function SeatSelectorModal({
  show,
  toggle,
  scheduleId,
  target,
  onSelect,
}) {
  const { messages } = useIntl();
  const shouldInitialize = useShouldInitialize(show);

  const [seatsInfo, _, loading] = useDataLoaderWithArchive(
    {
      fetchFunction: () => fetchSeats(scheduleId),
      getSnapshotDataFunction: (data) => data,
      formatFunction: sortBusSeats,
      archiveKey: `seats/${scheduleId}`,
      shouldLoad: show,
    },
    [scheduleId]
  );

  // States
  const [selectedSeat, setSelectedSeat] = useState(null);

  // Functions
  const onSelectSeat = (seat) => {
    setSelectedSeat(seat);
  };

  const onDone = () => {
    onSelect(selectedSeat);
    toggle();
  };

  const onClear = () => {
    setSelectedSeat(null);
    onSelect(null);
    toggle();
  };

  // Render
  const renderContent = () => {
    if (!shouldInitialize) return null;

    return (
      <div className="seat-selector-modal d-flex flex-column h-100 px-4 pb-4">
        <div className="close-btn pointer" onClick={toggle}>
          <CloseIcon
            className="close-icon text-primary pointer"
            width="70%"
            height="70%"
          />
        </div>

        <PerfectScrollbar
          options={{
            suppressScrollX: true,
            wheelPropagation: false,
          }}
          className="flex-grow-1 mx-0 p-0"
        >
          <div className="d-flex flex-column h-100">
            <SeatMapTab
              seats={seatsInfo}
              loading={loading}
              selectedSeat={selectedSeat}
              onSelectSeat={onSelectSeat}
            />
          </div>
        </PerfectScrollbar>

        <div className="text-right">
          <Button className="pretty-btn link mr-3" onClick={onClear}>
            {messages['general.clean']}
          </Button>
          <Button
            className="pretty-btn primary"
            disabled={selectedSeat === null}
            onClick={onDone}
          >
            {messages['general.done']}
          </Button>
        </div>
      </div>
    );
  };

  return (
    <PopupModal
      className="seat-selector-modal"
      show={show}
      toggle={toggle}
      target={target}
      calculateStyleOnShow
      displacementY={-40}
    >
      {renderContent()}
    </PopupModal>
  );
}
