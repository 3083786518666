import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { stringToMoney } from '../../../../utils/money';

export default function PaymentAtDestinationMessage({ order }) {
  const { formatMessage } = useIntl();

  const paymentAtDestination = useMemo(() => {
    return order.fare.paymentAtDestination
      ? formatMessage(
          { id: 'travel.approval.payment-at-destination' },
          {
            value: stringToMoney(
              order.fare.paymentAtDestination,
              2,
              order.fare.currency
            ),
          }
        )
      : null;
  }, [order]);

  return paymentAtDestination ? (
    <p className="text-left color-alert fs-0-7rem lh-1-5 my-3">
      {paymentAtDestination}
    </p>
  ) : null;
}
