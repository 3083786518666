import React from 'react';
import { Button, Row } from 'reactstrap';
import { Colxx } from './CustomBootstrap';
import { ReactComponent as EmptyImage } from '../../assets/img/config/emptyResults.svg';
import { useMemo } from 'react';

export default function EmptyResults({
  title = 'Title',
  description = 'Description',
  buttonText = '',
  onClick = () => {},
  children,
  className = 'w-80',
  canClickBtn = true,
}) {
  const showBtn = useMemo(
    () => canClickBtn && buttonText,
    [canClickBtn, buttonText]
  );

  return (
    <Row className={`empty-results align-items-center ${className}`}>
      <Colxx sm="12" md="6" className="mb-3">
        {children ? children : <EmptyImage />}
      </Colxx>
      <Colxx sm="12" md="6">
        <h2 className="title text-dark font-weight-medium">{title}</h2>
        <p className="description text-muted mb-3">{description}</p>
        {showBtn && (
          <Button color="primary" onClick={onClick}>
            {buttonText}
          </Button>
        )}
      </Colxx>
    </Row>
  );
}
