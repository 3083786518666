import classNames from 'classnames';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { getStatusClassName } from '../utils';
import { Link } from 'react-router-dom';

import { RecurrenceBudgetHistoryStatus } from '../../../../constants/recurrenceBudgets';

import { ReactComponent as SuccessIcon } from '../../../../assets/img/icons/bold_check.svg';
import { ReactComponent as FailIcon } from '../../../../assets/img/icons/close.svg';

function HistoryStep({ className, status, size = 25 }) {
  const [Icon, iconSize] = useMemo(
    () =>
      status === RecurrenceBudgetHistoryStatus.SUCCESS
        ? [SuccessIcon, '40%']
        : [FailIcon, '35%'],
    [status]
  );

  // Render
  return (
    <div
      className={classNames('history-step flex-center', className)}
      style={{ width: size, height: size }}
    >
      <Icon width={iconSize} height={iconSize} fill="white" stroke="white" />
    </div>
  );
}

export default function HistoryItem({ className, item, right }) {
  const { messages } = useIntl();

  // // States
  const {
    name,
    date,
    status,
    formattedStatus,
    statusClassName,
    message,
    budgetLink,
  } = useMemo(() => {
    const { budgetName, recurrenceDate, status, budgetId, messageId } =
      item || {};

    return {
      name: budgetName,
      date: recurrenceDate.split('-').reverse().join('/'),
      status,
      formattedStatus:
        messages[
          status === RecurrenceBudgetHistoryStatus.SUCCESS
            ? 'history-item.status.success'
            : 'history-item.status.fail'
        ],
      statusClassName: getStatusClassName(status),
      message: messageId
        ? messages[`recurrence-budget.history.msg.${messageId}`]
        : '',
      budgetId,
      budgetLink: budgetId ? `/payments/cards/${budgetId}` : null,
    };
  }, [item]);

  // // Render
  const renderName = () => (
    <h3 className="m-0 p-0 font-primary fs-0-95rem">{name}</h3>
  );

  const renderContent = () => (
    <section className="content">
      <p className="m-0 p-0 text-muted fs-0-7rem">{date}</p>
      {budgetLink ? (
        <Link target="_blank" to={budgetLink}>
          {renderName()}
        </Link>
      ) : (
        renderName()
      )}
      {message ? (
        <p className="m-0 p-0 fs-0-7rem my-2 lh-1-25">{message}</p>
      ) : null}
      <span
        className={classNames(
          'badge fs-0-6rem font-weight-medium mt-2',
          statusClassName
        )}
      >
        {formattedStatus}
      </span>
    </section>
  );

  return (
    <div className={classNames('history-item', className, { right })}>
      {renderContent()}
      <div className="step-container">
        <HistoryStep status={status} />
      </div>
      <div className="space" />
    </div>
  );
}
