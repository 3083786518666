import React, { Component } from 'react';
import { Row } from 'reactstrap';
import { Colxx } from '../../components/common/CustomBootstrap';
import filter from 'lodash/filter';
import sortBy from 'lodash/sortBy';
import clone from 'lodash/clone';
import map from 'lodash/map';

import PackageHotelItem from './PackageHotelItem';
import HotelMap from './HotelMap';

import Pagination from '../pages/Pagination';
import LoadingHotel from '../../components/search/LoadingHotel';
import NoResults from '../../components/search/NoResults';
import HotelFilters from './HotelFilters';

import LoadSearchStatus from '../../constants/loadSearchStatus';
import { getIDWithinRangeFromLocation } from '../../utils/locations';

class PackageHotelResults extends Component {
  constructor(props) {
    super(props);

    this.state = {
      nameFilter: '',
      price: {
        selected_min: 0,
        selected_max: 0,
      },
      categories: {
        0: false,
        1: false,
        2: false,
      },
      amenities: [],
      itemsPerPage: 10,
      currentPage: 1,
      selectedHotels: [],
      selected: null,
      highlightedPin: null,
      city: this.getCityName(props.search.destination),
    };

    this.baseState = this.state;
  }

  // Lifecycle
  componentDidUpdate(prevProps) {
    if (this.props.results.length !== prevProps.results.length) {
      if (this.props.results.length === 0) {
        this.setState(this.baseState);
      }
    }
  }

  // Reservation
  reserveItem = (passenger, costCenter, project, item, selectedRoom) => {};

  // Filters
  filterResults = () => {
    const { results, status, isLoading, params } = this.props;
    const {
      categories,
      amenities,
      price,
      selectedHotels = [],
      itemsPerPage,
      currentPage,
      nameFilter,
      highlightedPin,
    } = this.state;

    const lowerNameFilter = nameFilter ? nameFilter.toLowerCase() : '';

    let filtered = filter(results, function (o) {
      if (highlightedPin || selectedHotels.length) {
        return selectedHotels.includes(o.hotelId);
      }

      if (categories[o.hotel.category] === false) return false;

      for (let iA = 0; iA < amenities.length; iA++) {
        if (o.hotel.amenities.includes(amenities[iA]) === false) return false;
      }

      if (lowerNameFilter) {
        if (!o.hotel.name.toLowerCase().includes(lowerNameFilter)) return false;
      }

      if (price.selected_min !== 0 && price.selected_max !== 0) {
        let hasPricedRoom = false;
        o.rooms.forEach((room) => {
          if (
            room.fare >= price.selected_min &&
            room.fare <= price.selected_max
          ) {
            hasPricedRoom = true;
          }
        });

        if (!hasPricedRoom) return false;
      }

      return true;
    });

    const totalItems =
      results.length === params?.totalItems
        ? filtered.length
        : params.totalItems || results.length;

    filtered = sortBy(filtered, 'fare.total').slice(
      (currentPage - 1) * itemsPerPage,
      currentPage * itemsPerPage
    );

    if (
      filtered.length < itemsPerPage &&
      status === LoadSearchStatus.NEW &&
      !isLoading
    ) {
      this.props.getRemainingHotelSearch();
    }

    return [filtered, totalItems];
  };

  toggleSelectedHotels = (hotelId) => {
    const newSelectedHotels = clone(this.state.selectedHotels);
    if (newSelectedHotels.includes(hotelId)) {
      newSelectedHotels.splice(newSelectedHotels.indexOf(hotelId), 1);
    } else {
      newSelectedHotels.push(hotelId);
    }

    this.setState({ selectedHotels: newSelectedHotels });
  };

  onHighlightedPinChange = (location = null) => {
    this.setState({
      highlightedPin: location,
      selectedHotels: location
        ? getIDWithinRangeFromLocation(this.props.params.coords, location)
        : [],
    });
  };

  getCityName = (destination) => {
    if (destination.type === 'airport') {
      return `${destination.cityCode}, ${destination.countryName}`;
    } else {
      const index = destination.label.indexOf(')');
      return `${
        index !== -1 ? destination.label.slice(index + 2) : destination.label
      }, ${destination.countryName}`;
    }
  };

  // Filter Handlers
  onPriceChange = (selectedPrices) => {
    const { price } = this.state;

    const selected_min = selectedPrices[0];
    const selected_max = selectedPrices[1];

    this.setState({
      currentPage: 1,
      price: {
        ...price,
        selected_min,
        selected_max,
      },
    });
  };

  onAmenityChange = (amenity) => {
    const { amenities } = this.state;

    if (amenities.indexOf(amenity) >= 0) {
      amenities.splice(amenities.indexOf(amenity), 1);
    } else {
      amenities.push(amenity);
    }

    this.setState({ amenities });
  };

  onCategoryChange = (checked, category) => {
    const { categories } = this.state;

    categories[category] = checked;

    this.setState({ currentPage: 1, categories });
  };

  onNameFilterChange = (nameFilter) => {
    this.setState({
      currentPage: 1,
      nameFilter,
    });
  };

  // Pagination
  onChangePage = (currentPage) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    this.setState({ currentPage });
  };

  // Render
  renderPagination(numFilteredItems) {
    const { itemsPerPage, currentPage } = this.state;

    return (
      numFilteredItems > itemsPerPage && (
        <Pagination
          currentPage={currentPage}
          totalPage={Math.ceil(numFilteredItems / itemsPerPage)}
          onChangePage={(i) => this.onChangePage(i)}
        />
      )
    );
  }

  renderLoadingResults() {
    return (
      <div className="row search-results loader">
        <Colxx xxs="12">
          <LoadingHotel />
        </Colxx>
        <Colxx xxs="12">
          <LoadingHotel />
        </Colxx>
        <Colxx xxs="12">
          <LoadingHotel />
        </Colxx>
        <Colxx xxs="12">
          <LoadingHotel />
        </Colxx>
      </div>
    );
  }

  renderResults() {
    const { params, status, isLoading, results } = this.props;
    const [filteredItems, totalItems] = this.filterResults();

    if (isLoading) {
      return this.renderLoadingResults();
    }

    if (
      status === LoadSearchStatus.EMPTY ||
      (!this.state.highlightedPin && !filteredItems.length)
    ) {
      return <NoResults />;
    }

    return (
      <div>
        <div className="search-map">
          <HotelMap
            coords={params?.coords}
            selected={this.state.selectedHotels}
            toggleSelectedHotels={this.toggleSelectedHotels}
            highlightedPin={this.state.highlightedPin}
            withProximitySearch
            city={this.state.city}
            toggleHighlightedPin={this.onHighlightedPinChange}
          />
        </div>

        {map(filteredItems, (result, index) => {
          return (
            <PackageHotelItem
              key={`result-${result.hotelId}`}
              index={index}
              item={result}
              changeHotel={this.props.changeHotel}
            />
          );
        })}

        {isLoading && this.renderLoadingResults()}

        {this.renderPagination(totalItems)}
      </div>
    );
  }

  render() {
    return (
      <Row className="mt-5">
        <Colxx xxs="12" sm="3">
          <HotelFilters
            params={this.props.params}
            status={this.props.status}
            nameFilter={this.state.nameFilter}
            price={this.state.price}
            onPriceChange={this.onPriceChange}
            categories={this.state.categories}
            onCategoryChange={this.onCategoryChange}
            onNameFilterChange={this.onNameFilterChange}
            amenities={this.state.amenities}
            onAmenityChange={this.onAmenityChange}
          />
        </Colxx>
        <Colxx xxs="12" sm="9" className="pb-5 mb-5">
          {this.renderResults()}
        </Colxx>
      </Row>
    );
  }
}

export default PackageHotelResults;
