import { map } from 'lodash';
import { Card, CardBody, CardHeader, CardTitle, CustomInput } from 'reactstrap';
import { RangeTooltip } from 'src/portao3-legacy/components/common/SliderTooltips';
import { triggerFilterChangeSegment } from 'src/portao3-legacy/utils/segment';
import { useMemo } from 'react';
import useCustomIntl from 'src/portao3-legacy/hooks/useCustomIntl';
import {
  doesParamHaveItems,
  formatContainsParamsWithEvent,
  isTruthy,
} from 'src/portao3-legacy/utils/params';
import SearchFilterWrapper from './SearchFilterWrapper';

import {
  TCarParams,
  TCarSearchParams,
} from 'src/portao3-legacy/types/orders/car/carParams';
import { IComponentFilter } from 'src/portao3-legacy/types/orders/orders';
import { TObject } from 'src/portao3-legacy/types/others';

export default function CarFilters({
  searchParams,
  searchStatus,
  params,
  setNamedParam,
  setParam,
  isLoading = true,
}: IComponentFilter<TCarSearchParams, TCarParams>) {
  const { messages } = useCustomIntl();

  // States
  const {
    showPrice,
    minPrice,
    maxPrice,
    showRental,
    showTypes,
    showTransmissions,
  } = useMemo(() => {
    const items: TObject<any> = {
      showRental: doesParamHaveItems(searchParams.rentals),
      showTypes: doesParamHaveItems(searchParams.types),
      showTransmissions: doesParamHaveItems(searchParams.transmissions),
    };

    if (
      searchParams?.price &&
      searchParams.price.min !== searchParams.price.max
    ) {
      items.showPrice = true;
      items.maxPrice = Math.ceil(searchParams.price.max);
      items.minPrice = Math.floor(searchParams.price.min);
    }

    return items;
  }, [searchParams]);

  // Render
  return (
    <SearchFilterWrapper isLoading={isLoading} searchStatus={searchStatus}>
      <div>
        {showPrice ? (
          <Card className="search mb-4">
            <CardHeader>
              <CardTitle>
                {messages['containers.search.flight-filters.prices']}
              </CardTitle>
            </CardHeader>
            <CardBody>
              <RangeTooltip
                min={minPrice}
                max={maxPrice}
                className="mb-5"
                value={[
                  params.price.min || searchParams.price?.min,
                  params.price.max || searchParams.price?.max,
                ]}
                allowCross={false}
                pushable
                onChange={setNamedParam('price')}
              />
            </CardBody>
          </Card>
        ) : null}

        {showRental ? (
          <Card className="search mb-4">
            <CardHeader>
              <CardTitle>
                {messages['containers.search.car-results.rentals']}
              </CardTitle>
            </CardHeader>
            <CardBody>
              {map(
                searchParams.rentals,
                ({ code: rentalCode, name: rentalName }) => {
                  const paramValue = params.rentals[rentalCode];

                  return (
                    <CustomInput
                      key={`rental-${rentalCode}`}
                      type="checkbox"
                      id={`check-rental-${rentalCode}`}
                      label={rentalName}
                      checked={paramValue || paramValue === undefined}
                      onChange={(e) =>
                        setParam(
                          'rentals',
                          formatContainsParamsWithEvent(rentalCode, e)
                        )
                      }
                    />
                  );
                }
              )}
            </CardBody>
          </Card>
        ) : null}

        {showTypes ? (
          <Card className="search">
            <CardHeader>
              <CardTitle>
                {messages['containers.search.car-results.title']}
              </CardTitle>
            </CardHeader>
            <CardBody>
              {map(searchParams.types, (category) => {
                return (
                  <CustomInput
                    key={`category-${category}`}
                    type="checkbox"
                    id={`check-category-${category}`}
                    label={category}
                    checked={isTruthy(params.categories[category])}
                    onChange={(e) => {
                      triggerFilterChangeSegment(
                        `Category changed (${category})`,
                        e.target.checked
                      );

                      setParam(
                        'categories',
                        formatContainsParamsWithEvent(category, e)
                      );
                    }}
                  />
                );
              })}
            </CardBody>
          </Card>
        ) : null}

        {showTransmissions ? (
          <Card className="search mt-4">
            <CardHeader>
              <CardTitle>
                {messages['containers.search.car-results.features']}
              </CardTitle>
            </CardHeader>
            <CardBody>
              {map(searchParams.transmissions, (transmission) => {
                return (
                  <CustomInput
                    key={`category-${transmission}`}
                    type="checkbox"
                    id={`check-category-${transmission}`}
                    label={transmission}
                    checked={isTruthy(params.transmissions[transmission])}
                    onChange={(e) => {
                      triggerFilterChangeSegment(
                        `Transmission changed (${transmission})`,
                        e.target.checked
                      );

                      setParam(
                        'transmissions',
                        formatContainsParamsWithEvent(transmission, e)
                      );
                    }}
                  />
                );
              })}

              {map(searchParams.tractions, (traction) => {
                return (
                  <CustomInput
                    key={`category-${traction}`}
                    type="checkbox"
                    id={`check-category-${traction}`}
                    label={traction}
                    checked={isTruthy(params.tractions[traction])}
                    onChange={(e) => {
                      triggerFilterChangeSegment(
                        `Traction changed (${traction})`,
                        e.target.checked
                      );

                      setParam(
                        'tractions',
                        formatContainsParamsWithEvent(traction, e)
                      );
                    }}
                  />
                );
              })}
            </CardBody>
          </Card>
        ) : null}
      </div>
    </SearchFilterWrapper>
  );
}
