module.exports = {
  /* 01.General */
  'general.copyright': 'Portão 3 © 2021 Todos os direitos reservados.',

  'general.loading': 'Carregando ...',
  'general.go-back': 'Voltar',
  'general.go-forward': 'Próximo',
  'general.delete': 'Excluir',
  'general.cancel': 'Cancelar',
  'general.save': 'Salvar',
  'general.send': 'Enviar',
  'general.reservations': 'reservas',
  'general.nights': 'noites',
  'general.total': 'Total',
  'general.new-total': 'Novo total',
  'general.view': 'Visualizar',
  'general.select': 'Selecionar',
  'general.selected': 'Selecionado',
  'general.taxes': 'Impostos, taxas e encargos já inclusos',
  'general.adult': 'adulto',
  'general.adults': 'adultos',
  'general.star': 'estrela',
  'general.stars': 'estrelas',
  'general.from': 'a partir de',
  'general.duration': 'Duraçxão',
  'general.price': 'Preço',
  'general.more-details': 'Mais detalhes',
  'general.book': 'Reservar',
  'general.slogan': 'Simples,<br/>inteligente<br/>e eficaz.',
  'general.person': 'pessoa',
  'general.people': 'pessoas',
  'general.search': 'Buscar',
  'general.prices': 'Preços',
  'general.categories': 'Categorias',
  'general.all-selected': 'Todos selecionados',
  'general.none-selected': 'Nenhum selecionado',
  'general.loading-options': 'Carregando opções...',
  'general.none': 'Nenhum',
  'general.yes': 'Sim',
  'general.no': 'Não',
  'general.alright': 'Tudo bem!',
  'general.ok': 'Ok',
  'general.saved-successfully': 'Salvo com sucesso!',
  'general.show-all': 'Mostrar tudo',
  'general.num-items': 'N. Itens',
  'general.close': 'Fechar',
  'general.permissions': 'Permissões',
  'general.config': 'Configurações',
  'general.realod': 'Atualizar',
  'general.done': 'Pronto!',
  'general.add': 'Adicionar',
  'general.items.singular': '1 item',
  'general.items.plural': '{number} itens',
  'general.do-cancel': 'Sim, cancelar',
  'general.and': 'e',
  'general.request': 'Solicitar',
  'general.finish': 'Concluir',
  'general.price-estimate': 'Estimativa de preço',
  'general.reject': 'Recusar',
  'general.required': 'Obrigatório',
  'general.required-fields': 'Campos obrigatórios',
  'general.clean': 'Limpar',
  'general.time': 'Horas',
  'general.options': 'Opções',
  'general.disable': 'Desativar',
  'general.activate': 'Ativar',
  'general.no-options-found': 'Nenhum opção encontrada!',
  'general.none-a': 'Nenhuma',
  'general.copied': 'Copiado!',
  'general.day': 'dia',
  'general.days': 'dias',
  'general.fetch-more': 'Carregar mais',
  'general.see-more': 'Ver mais',
  'general.favorite': 'Favorito',
  'general.cheapest': 'Mais Barato',

  /* 02.User Login, Logout, Register */
  'user.simple': 'Simples',
  'user.inteligent': 'Inteligente',
  'user.direct': 'Eficaz',
  'user.login': 'Fazer login',
  'user.login.page': 'Login',
  'user.authType.error':
    'Não conseguimos te identificar. Isso significa que você não tem uma conta ou ela está bloqueada. Por favor, tente novamente com outro email ou contate o administrador da sua empresa.',
  'user.newPassword': 'Sua senha expirou, crie uma nova para continuar',
  'user.shouldInvite': 'Enviar convite para acessar a Portão 3',
  'user.setupMFA': 'Adicione mais segurança a sua conta',
  'user.setupMFA_desc':
    'Autenticação em duas etapas é uma prática simples que adiciona uma camada extra de proteção ao seu usuário e senha. Leia o QR Code com um aplicativo apropriado.',
  'user.username': 'E-mail',
  'user.password': 'Digite sua senha',
  'user.reset_code': 'Código de verificação',
  'user.mfa_code': 'Código do Token',
  'user.new_password': 'Nova senha',
  'user.new_password_confirm': 'Confirme a senha',
  'user.forgot-password-question': 'Esqueceu a senha?',
  'user.forgot-password': 'Esqueci a senha',
  'user.forgot-password_desc': 'Crie uma nova senha para a sua conta.',
  'user.authMFA': 'Código de Verificação',
  'user.authMFA_desc':
    'Insira o código de verificação configurado em seu aplicativo.',
  'user.forgot-password-request-success':
    'Um link para troca de senha foi enviado para o seu email.',
  'user.forgot-password-request-error':
    'O link utilizado não é mais válido. Por favor, solicite um novo reset novamente.',

  'user.new-password': 'Seja bem vindo a Portão 3!',
  'user.new-password_desc':
    'Crie uma nova senha para começar a usar a plataforma',

  'user.CodeMismatchException':
    'Código de validação inválido. Por favor verifique o código que te enviamos por email e tente novamente.',
  'user.EnableSoftwareTokenMFAException':
    'Código do Token inválido. Por favor, tente novamente.',
  'user.NotAuthorizedException':
    'Senha incorreta. Tente novamente ou clique em "Esqueceu a senha?" para redefini-la.',
  'user.UserNotFoundException':
    'Não foi possível encontrar a sua conta na Portão 3.',
  'user.UserSSOOrganization':
    'Sua organização utiliza SSO, sua senha deve ser trocada lá. Consulte o time técnico de sua organização, por favor!',
  'user.UserDisabledException':
    'Usuário bloqueado. Contate o suporte para mais informações.',
  'user.LimitExceededException':
    'Tentativas de reset excedidas. Por favor tente novamente mais tarde.',
  'user.InvalidParameterException':
    'Sua nova senha é muito simples. Insira uma senha mais complexa para continuar.',
  'user.WeakPasswordException': 'Senha deve ser mais complexa',
  'user.ExpiredCodeException':
    'Código de verificação expirou. Por favor solicite novamente.',
  'user.GeneralException':
    'Um erro interno ocorreu. Nosso time já foi avisado.',
  'user.register.error':
    'Não foi possível cadastrar esse usuário. Tente novamente ou entre em contato com nosso suporte!',
  'user.register.error.email-taken':
    'O e-mail já está em uso. Por favor, escolha outro!',
  'user.register.error.invalid-params':
    'Há parâmetros inválidos. Valide-os e tente novamente!',

  'user.fullName': 'Nome Completo',
  'user.firstName': 'Nome',
  'user.lastName': 'Sobrenome',
  'user.gender': 'Gênero',
  'user.gender.MALE': 'Masculino',
  'user.gender.FEMALE': 'Feminino',
  'user.birthdate': 'Data de nascimento',
  'user.email': 'E-mail',
  'user.mobilePhoneNumber': 'Celular',
  'user.nationality': 'Nacionalidade',
  'user.phoneNumber': 'Telefone',
  'user.role': 'Cargo',
  'user.companyId': 'Matrícula',
  'user.costCenter': 'Centro de Custo',
  'user.project': 'Projeto',
  'user.rgNumber': 'Identidade',
  'user.rgEmitter': 'Órgão Emissor',
  'user.cpf': 'CPF',
  'user.identity': 'Identidade',
  'user.cnpj': 'CNPJ',
  'user.cnhNumber': 'CNH',
  'user.cnhValidUntil': 'Data de validade',
  'user.passportCountry': 'País emissor do passaporte',
  'user.passportNumber': 'Número do passaporte',
  'user.passportValidUntil': 'Data de validade do passaporte',

  /* 03.Menu */
  'menu.app': 'Administrador',
  'menu.dashboard': 'Dashboard',
  'menu.active': 'Viagens Ativas',
  'menu.reports': 'Relatórios',
  'menu.analytics': 'Analytics',
  'menu.management': 'Gestão',
  'menu.travels': 'Viagens',
  'menu.expenses': 'Contas a pagar',
  'menu.payments': 'Contas a pagar',
  'menu.items': 'Itens',
  'menu.finance': 'Financeiro',
  'menu.integrations': 'Integrações',
  'menu.users': 'Usuários',
  'menu.about': 'Sobre',
  'menu.kyc': 'Cadastro',
  'menu.add-user': 'Adicionar usuário',
  'menu.others': 'Outros',
  'menu.summary': 'Resumo',

  'menu.pending': 'Pendentes',
  'menu.approval': 'Aprovações',
  'active.travelersInit': 'Você possui',
  'active.travelersEnd': 'viajantes ativos',
  'active.mapBrazil': 'Brasil',
  'active.mapWorld': 'Mundo',

  'menu.data': 'Dados',
  'menu.esg': 'ESG',

  /* 03.1.Menu */
  'refund-menu.cards': 'Cartões',
  'refund-menu.bills': 'Contas',
  'refund-menu.inbox': 'Inbox',
  'refund-menu.recurrence': 'Recorrências',

  /* 03.1. Data Menu */
  'data-menu.travels': 'Viagens',
  'data-menu.expenses': 'Contas a pagar',
  'data-menu.esg': 'ESG',
  'data-menu.finance': 'Extrato',

  /* 04.Travel */
  'travel.menu.package': 'Aéreo + Hotel',
  'travel.menu.flight': 'Aéreo',
  'travel.menu.hotel': 'Hotel',
  'travel.menu.car': 'Carro',
  'travel.menu.bus': 'Ônibus',
  'travel.menu.office': 'Escritório',
  'travel.menu.itinerary': 'Itinerário',
  'travel.next': 'Próximas Viagens',
  'travel.flight': 'Voo',
  'travel.origin': 'Origem',
  'travel.destination': 'Destino',
  'travel.dropOffSamePickUp': 'Devolução na mesma loja',
  'travel.isTwoWay': 'Ida e volta',
  'travel.rental': 'Locadora',
  'travel.pickUpLocation': 'Local de Retirada',
  'travel.pickUpTime': 'Data e Hora de Retirada',
  'travel.dropOffLocation': 'Local de Devolução',
  'travel.dropOffTime': 'Data e Hora de Devolução',
  'travel.officeLocation': 'Local próximo',
  'travel.officeDate': 'Data desejada',
  'travel.outbound': 'Ida',
  'travel.inbound': 'Volta',
  'travel.baggage': 'mala',
  'travel.baggages': 'malas',
  'travel.Baggage': 'Bagagem despachada',
  'travel.no_baggage': 'Sem bagagem',
  'travel.with_baggage': 'Com bagagem',
  'travel.backpack': 'Mochila',
  'travel.nationality': 'Nacionalidade',
  'travel.class': 'Classe',
  'travel.Economy': 'Econômica',
  'travel.PremiumEconomy': 'Econômica Premium',
  'travel.Business': 'Executiva',
  'travel.FirstClass': 'Primeira Classe',
  'travel.stay': 'Hospedagem',
  'travel.checkin': 'Check-in',
  'travel.checkout': 'Check-out',
  'travel.location-message': 'Digite para pesquisar',
  'travel.location-searching': 'Pesquisando...',
  'travel.peopleRooms': 'Pessoas e nacionalidades',
  'travel.baggageCabin': 'Bagagem e Cabine',
  'travel.office_name': 'Escritório',
  'travel.room_name': 'Sala',
  'travel.date': 'Data',
  'travel.room_size': '# Pessoas',
  'travel.total': 'Total',
  'travel.search': 'Pesquisar',
  'travel.Room': 'Quarto',
  'travel.room': 'quarto',
  'travel.rooms': 'quartos',
  'travel.Rooms': 'Quartos',
  'travel.room.simple': 'simples',
  'travel.room.double': 'duplo',
  'travel.room.triple': 'triplo',
  'travel.room.quadruple': 'quadruplo',
  'travel.room.offline-reservation.title': 'Reserva offline',
  'travel.room.offline-reservation.description':
    'Faz parte dos acordos de hotel de sua organização. É feita de modo offline, entrando em contato com o hotel. Logo, o tempo para a efetuação da reserva é mais longo!',
  'travel.board': 'Regime',
  'travel.bus.carrier': 'Companhia',
  'travel.car': 'Carro',
  'travel.car.pickup': 'Retirada',
  'travel.car.dropoff': 'Devolução',

  'travel.timeline.loading': 'Carregando sua timeline',
  'travel.search.loading': 'Carregando',
  'travel.search.best-options': 'Melhores opções',

  'travel.booking.passenger': 'Nome do passageiro',
  'travel.booking.no-passenger': 'Nenhum passageiro encontrado',
  'travel.booking.loading-passengers': 'Carregando passageiros',
  'travel.booking.costCenter': 'Centro de custo',
  'travel.booking.no-costCenter': 'Nenhum centro de custo encontrado',
  'travel.booking.project': 'Projeto',
  'travel.booking.no-project': 'Nenhum projeto encontrado',
  'travel.booking.motive': 'Motivos',
  'travel.booking.no-motive': 'Nenhum motivo encontrado',
  'travel.booking.tag': 'Tags',
  'travel.booking.no-tag': 'Nenhuma tag encontrada',
  'travel.booking.observation': 'Observação',

  'travel.booking.creditCard': 'Cartão de Crédito',
  'travel.booking.no-creditCard': 'Nenhum cartão de crédito encontrado',
  'travel.booking.creditCard.ends-with': 'Final',
  'travel.booking.creditCard.insufficient-balance':
    'Saldo insuficiente para emitir esta reserva.',
  'travel.booking.installments': 'Parcelamento',
  'travel.booking.loading-installments': 'Carregando...',

  'travel.empty.title': 'Não há resultados para sua pesquisa',
  'travel.empty.subtitle': 'Tente refazer sua busca com novos parâmetros.',

  'travel.nodata.title': 'Não há resultados',
  'travel.nodata.subtitle': 'Tente fazer novas reservas.',

  'travel.order.status.title': 'Acompanhe abaixo o status da sua reserva.',
  'travel.order.status.reserving': 'Reservando',
  'travel.order.status.timeline.reserving': 'Reservando...',
  'travel.order.status.reserved': 'Reservado',
  'travel.order.status.timeline.approval': 'Aguardando aprovação',
  'travel.order.status.timeline.toissue': 'Aguardando emissão',
  'travel.order.status.timeline.issuing': 'Emitindo...',
  'travel.order.status.timeline.open-booking.issuing': 'Gerando cartão...',
  'travel.order.status.timeline.open-booking.waiting': 'Emita sua reserva',
  'travel.order.status.timeline.altering': 'Alterando...',
  'travel.order.status.timeline.manualissuing': 'Emitindo...',
  'travel.order.status.timeline.cancelling': 'Cancelando...',
  'travel.order.status.timeline.cancelled': 'Cancelado',
  'travel.order.status.timeline.error': 'Um erro aconteceu',
  'travel.order.status.timeline.denied': 'Recusado',
  'travel.order.status.timeline.expired': 'Expirado',
  'travel.order.status.approval': 'Aprovação',
  'travel.order.status.issue': 'Emissão',
  'travel.order.status.title.approval':
    'Acompanhe abaixo a aprovação da sua reserva.',
  'travel.order.status.title.validUntil': 'Válida até',
  'travel.order.status.title.hasNoValidUntil':
    'Reserva não confirmada, valores podem alterar.',
  'travel.order.status.title.issuing': 'Estamos emitindo a sua reserva...',
  'travel.order.status.title.open-booking.issuing':
    'Estamos criando o cartão virtual desta reserva...',
  'travel.order.status.title.open-booking.waiting':
    'Agora você precisa emitir esta reserva no site escolhido',
  'travel.order.status.title.open-booking.receipt':
    'Identificamos a reserva no site escolhido, mas você ainda precisa anexar o comprovante da reserva',
  'travel.order.status.title.issued': 'Tudo ok! Sua reserva foi emitida!',
  'travel.order.status.title.issued.altering':
    'Sua reserva foi emitida, mas uma alteração está em processamento!',
  'travel.order.status.title.cancelling':
    'Estamos processando o cancelamento dessa reserva.',
  'travel.order.status.title.error':
    'Um erro aconteceu com essa reserva. Por favor tente novamente.',
  'travel.order.status.title.denied':
    'Infelizmente esta reserva foi rejeitada por um dos aprovadores',
  'travel.order.cancellation.date': 'Cancelamento gratuito até ',
  'travel.order.cancellation.issue': 'Cancelamento gratuito até a emissão',
  'travel.order.open-booking.show-steps': 'Ver passos',
  'travel.order.open-booking.show-transaction': 'Ver transação',
  'travel.order.cancellation.cancel': 'Cancelar',
  'travel.order.cancellation.cancelled':
    'Esta reserva foi cancelada ou o prazo de emissão expirou.',
  'travel.order.cancellation.dismissed': 'Esta reserva foi dispensada.',
  'travel.order.cancellation.alter': 'Alterar',
  'travel.order.cancellation.dismiss': 'Dispensar',
  'travel.order.approval.approve': 'Aprovação',
  'travel.order.aproval.review': 'Analisar',
  'travel.order.approval.to-approve': 'Aprovar',
  'travel.order.approval.approve-immediately': 'Aprovar imediatamente',
  'travel.order.approval.generate-card': 'Gerar cartão',
  'travel.order.approval.just-approve': 'Somente aprovar',
  'travel.order.approval.issue': 'Emitir',
  'travel.order.use-credits': 'Utilizar créditos da reserva',

  'travel.order.status.timeline.refund.new': 'Novo',
  'travel.order.status.timeline.refund.start': 'Criando',
  'travel.order.status.timeline.refund.approving-anticipation': 'Aprovando',
  'travel.order.status.timeline.refund.approved-anticipation': 'Agendado',
  'travel.order.status.timeline.refund.activating': 'Ativando',
  'travel.order.status.timeline.refund.active': 'Ativo',
  'travel.order.status.timeline.refund.waiting': 'Em espera',
  'travel.order.status.timeline.refund.review': 'Revisão',
  'travel.order.status.timeline.refund.approving': 'Aprovando',
  'travel.order.status.timeline.refund.approved': 'Aprovado',
  'travel.order.status.timeline.refund.rejected': 'Rejeitado',
  'travel.order.status.timeline.refund.cancelling': 'Cancelando',
  'travel.order.status.timeline.refund.cancelled': 'Cancelado',
  'travel.order.status.timeline.refund.undone': 'Desfeito',
  'travel.order.status.timeline.refund.dismissed': 'Escondido',
  'travel.order.status.timeline.refund.error': 'Erro',
  'travel.order.status.timeline.refund.processing': 'Em processamento',
  'travel.order.status.timeline.refund.waiting-payment': 'Aguardando pagamento',
  'travel.order.status.timeline.refund.paid': 'Pago',

  'travel.order.status.timeline.administrative-budget.activating': 'Pagando',
  'travel.order.status.timeline.administrative-budget.approved': 'Pago',

  'travel.timeout':
    'Espere alguns minutos antes de executar essa ação novamente!',
  'travel.payment-at-destination': '+ {value} na retirada',

  'travel.approval.payment-at-destination':
    'Uma quantia de {value} deverá ser paga na retirada do veículo. Lembre o passageiro de levar algum método de pagamento para evitar possíveis transtornos!',

  'travel.bus.choose-seat': 'Escolher assento',
  'travel.bus.seat': 'Assento {seat}',

  'travel.structure.organization': 'Viagem pela organização',
  'travel.structure.personal': 'Viagem pessoal',

  'travel.open-booking.flight-number': 'Número do voo',
  'travel.open-booking.amount': 'Valor encontrado',
  'travel.open-booking.airline': 'Companhia aérea',
  'travel.open-booking.associate': 'Site da reserva',

  'travel.comment-placeholder':
    'Escreva um comentário para que o passageiro possa entender o motivo dessa decisão, se desejar.',

  // Bus seat
  'bus-seat.tabs.seat': 'Assento',
  'bus-seat.tabs.availability': 'Disponibilidade',
  'bus-seat.availability.title':
    'Qual deve ser o comportamento caso o assento selecionado não esteja mais disponível no momento da emissão dessa reserva?',
  'bus-seat.availability.similar.title':
    'Encontrar o assento de maior similaridade disponível',
  'bus-seat.availability.similar.description':
    'Se o assento escolhido for na janela, tenta encontrar um outro que esteja disponível e também seja na janela. Se não achar, seleciona o assento disponível mais próximo daquele escolhido',
  'bus-seat.availability.cancel.title': 'Cancelar a reserva',

  // Seat map
  'seat-map.legend.occupied': 'Ocupado',
  'seat-map.legend.available': 'Disponível',
  'seat-map.legend.selected': 'Selecionado',

  'seat-map.empty.title': 'Nenhum assento encontrado',
  'seat-map.empty.description':
    'Não foi possível encontrar os assentos desse ônibus. Tente novamente mais tarde!',

  'refund.status-code.0': 'Novo',
  'refund.status-code.10': 'Aprovando antecipação',
  'refund.status-code.20': 'Antecipação Aprovada',
  'refund.status-code.45': 'Ativando',
  'refund.status-code.50': 'Ativo',
  'refund.status-code.55': 'Em espera',
  'refund.status-code.60': 'Revisão',
  'refund.status-code.65': 'Aprovando',
  'refund.status-code.70': 'Aprovado',
  'refund.status-code.80': 'Rejeitado',
  'refund.status-code.85': 'Aguardando pagamento',
  'refund.status-code.90': 'Pago',
  'refund.status-code.95': 'Cancelando',
  'refund.status-code.100': 'Cancelado',
  'refund.status-code.125': 'Desfeito',
  'refund.status-code.150': 'Escondido',
  'refund.status-code.200': 'Erro',

  'travel.duration': 'Duração',
  'travel.approve-trip': 'Aprovar viagem',
  'travel.reject-trip': 'Recusar viagem',
  'travel.alternatives': 'Alternativas',
  'travel.duplicity': 'Duplicidade',
  'travel.best-option': 'Essa é a melhor opção!',
  'travel.payment': 'Pagamento',
  'travel.issue-trip': 'Emitir viagem',
  'travel.details': 'Informações da reserva',
  'travel.currentHotel.details': 'Informações do hotel atual',
  'travel.details.advance': 'Antecedência',
  'travel.details.car.dailyValue': 'Valor diário',
  'travel.details.hotel.dailyValue': 'Valor por diária',
  'travel.details.flightandbus.price-per-way': 'Média por trecho',

  'travel.cancel.title': 'Cancelar reserva',
  'travel.cancel.description': 'Você pode cancelar a reserva deste pedido',

  'travel.cancel.subtitle': 'Quanto custa para cancelar esta reserva?',
  'travel.cancel.free': 'Gratuito',
  'travel.cancel.free-until': 'Até {date} às {hour}',
  'travel.cancel.cancel-dates':
    'De {startDate} às {startHour} até {endDate} às {endHour}',
  'travel.cancel.cannot-cancel':
    'Infelizmente o prazo de cancelamento desta reserva expirou!',

  'travel.alter.title': 'Alterar reserva',
  'travel.alter.subtitle': 'Selecione o que você deseja alterar nessa reserva',

  'travel.ancillaries.tab.title': 'Adicionais',
  'travel.ancillaries.subtitle.loading': 'Carregando possíveis adicionais',
  'travel.ancillaries.subtitle':
    'Selecione o que você deseja adicionar nesta reserva',

  'travel.alter.checkout.title': 'Efetuar compra',
  'travel.alter.checkout.subtitle': 'Selecione o método de pagamento desejado',
  'travel.alter.checkout.cancellation':
    'Após a compra, não será possível cancelar o pedido',

  'travel.alter.flight.has-ancillaries':
    'Esta reserva possui adicionais. Cancele-os para que você possa prosseguir com esta ação!',

  'travel.alter.flight.ancillary.title': 'Adicionais',
  'travel.alter.flight.ancillary.description':
    'Selecione assentos e bagagens para esta reserva',

  'travel.alter.flight.cancellation.title': 'Cancelar voo',
  'travel.alter.flight.cancellation.description':
    'Faça o cancelamento dessa reserva',

  'travel.alter.flight.cancellation.options-tab.description':
    'Solicite o cancelamento dessa reserva por aqui. Sujeito a multas e taxas da companhia aérea conforme regra do seu bilhete.',

  'travel.alter.flight.cancellation.options-tab.credit.title': 'Deixar crédito',
  'travel.alter.flight.cancellation.options-tab.credit.description':
    'O crédito fica atrelado ao passageiro com a companhia aérea e poderá ser utilizado em novas emissões. A multa é sempre menor deixando crédito do que com reembolso.',

  'travel.alter.flight.cancellation.options-tab.refund.title':
    'Reembolso no cartão',
  'travel.alter.flight.cancellation.options-tab.refund.description':
    'O reembolso será realizado pela cia aérea em até 7 dias úteis no mesmo cartão utilizado para a compra. A multa é sempre maior do que a opção de crédito.',

  'travel.alter.flight.cancellation.options-tab.void.title':
    'Reembolso integral',
  'travel.alter.flight.cancellation.options-tab.void.description':
    'O reembolso integral pode ser solicitado em reservas feitas no mesmo dia, sem nenhum custo adicional ou multas aplicadas.',

  'travel.alter.flight.cancellation.options-tab.dialog.description':
    'Tem certeza que deseja solicitar o cancelamento deste voo?',

  'travel.alter.flight.cancellation.error':
    'Não foi possível cancelar esta reserva. Tente novamente mais tarde ou contate nosso suporte!',

  'travel.alter.flight.cancellation.final.title':
    'O cancelamento da reserva foi solicitado',
  'travel.alter.flight.cancellation.final.description':
    'Nosso time já está conversando com a companhia aérea para solicitar o cancelamento dessa reserva.',
  'travel.alter.flight.cancellation.final.successful':
    'A sua reserva ainda não foi cancelada, mas o nosso time já está entrando em contato com a companhia aérea para finalizar o cancelamento conforme solicitado. Nós iremos te avisar por email assim que tivermos um retorno e mais informações.',

  'travel.alter.flight.alteration.card.title': 'Alterar data',
  'travel.alter.flight.alteration.card.description':
    'Altere a data dos voos desta reserva',

  'travel.alter.flight.alteration.title': 'Alterar data',

  'travel.alter.flight.alteration.search.description.searching':
    'Buscando todos os voos nesta data',
  'travel.alter.flight.alteration.search.description.select':
    'Selecione o voo que você deseja subtituir pelo atual',
  'travel.alter.flight.alteration.search.description.outbound':
    'Deseja alterar o voo de ida?',
  'travel.alter.flight.alteration.search.description.inbound':
    'Deseja alterar o voo de volta?',
  'travel.alter.flight.alteration.search.description.route':
    'Solicite a alteração da sua reserva',

  'travel.alter.flight.alteration.search.journey': 'Trecho',
  'travel.alter.flight.alteration.search.select-date': 'Selecione a nova data',
  'travel.alter.flight.alteration.search.estimated-value': 'Valor estimado',

  'travel.alter.flight.alteration.final.title': 'Solicitar alteração de datas',
  'travel.alter.flight.alteration.final.description':
    'Solicite a alteração da sua reserva abaixo',

  'travel.alter.flight.alteration.final.successful':
    'Nosso time já está entrando em contato com a companhia aérea e logo em seguida você receberá mais informações por email. É importante frisar que a alteração pode não ser efetuada!',

  'travel.alter.flight.alteration.searching.error':
    'Não foi possível começar uma nova busca com esses parâmetros. Tente novamente mais tarde ou entre em contato com nosso suporte!',
  'travel.alter.flight.alteration.empty-data':
    'Nenhum voo foi encontrado nesta data. Você pode fazer uma nova busca.',
  'travel.alter.flight.alteration.error':
    'Não foi possível alterar esta reserva. Tente novamente mais tarde ou contate nosso suporte!',

  'travel.alter.flight.alteration.btn.keep-reservation': 'Manter voo',

  'travel.ancillary.title': 'Alterações na reserva',
  'travel.ancillary.subtitle': 'Você fez alterações nessa reserva',

  'travel.ancillary.cancel.title': 'Cancelar alterações',
  'travel.ancillary.cancel.loading-subtitle':
    'Verificando o que pode ser cancelado',
  'travel.ancillary.cancel.subtitle': 'Os items abaixo serão cancelados',
  'travel.ancillary.cancel.not': 'Poxa! Nada pode ser cancelado',

  'travel.ancillary.msg.created': 'Concluíndo alterações',
  'travel.ancillary.msg.booking': 'Comprando alterações',
  'travel.ancillary.msg.booked': 'Alterações concluídas',
  'travel.ancillary.msg.cancelling': 'Cancelando alterações',
  'travel.ancillary.msg.cancelled': 'Alterações canceladas',

  'travel.alter.hotel.increase-days.yes': 'Sim, desejo!',

  'travel.alter.hotel.increase-days.title': 'Adicionar novas diárias',
  'travel.alter.hotel.increase-days.description':
    'Você pode estender sua estadia neste hotel adicionando novas diárias.',

  'travel.alter.hotel.increase-days.reservation':
    'Reserva de {startDate} a {endDate}',

  'travel.alter.hotel.increase-days.single-day':
    '1 nova diária será adicionada',
  'travel.alter.hotel.increase-days.multiple-days':
    '{days} novas diárias serão adicionadas',

  'travel.alter.hotel.increase-days.has-room':
    'O quarto que você reservou está disponível nestas datas. Clique em Próximo para avançar, ou selecione outro quarto se desejar!',
  'travel.alter.hotel.increase-days.similar-room':
    'O quarto que você reservou não está disponível nestas datas, mas selecionei um com as mesmas características dele. Clique em Próximo para avançar, ou selecione outro quarto se desejar!',
  'travel.alter.hotel.increase-days.no-room':
    'O quarto que você reservou não está disponível nestas datas. Por favor, selecione um novo e clique em Próximo!',

  'travel.alter.hotel.increase-days.fully-empty':
    'Este hotel não possui quartos disponíveis nas datas que você informou!',
  'travel.alter.hotel.increase-days.partially-empty':
    'Este hotel não possui quartos disponíveis na data abaixo. Deseja seguir somente com a reserva na outra data?',

  'travel.alter.hotel.increase-days.empty-data': '{startDate} à {endDate}',

  'travel.alter.bus.seats.title': 'Assentos',
  'travel.alter.bus.seats.description': 'Escolha os assentos de sua viagem',

  'travel.alter.bus.alteration.seats.title': 'Selecionar assentos',
  'travel.alter.bus.alteration.seats.description':
    'Escolha os assentos dessa reserva. Você não precisa alterar todos, se não quiser!',
  'travel.alter.bus.alteration.seats.error':
    'Não foi possível alterar os assentos dessa reserva. Tente novamente!',

  'travel.alter.bus.cancellation.title': 'Cancelar reserva',
  'travel.alter.bus.cancellation.description':
    'Faça o cancelamento dessa reserva',

  'travel.with-breakfast': 'Com café da manhã',
  'travel.without-breakfast': 'Sem café da manhã',

  'orders.notifications.FARE_UPDATED':
    'Infelizmente essa tarifa expirou e não foi possível realizar a reserva. Faça uma nova pesquisa para continuar.',
  'orders.notifications.FARE_UPDATED.with-params':
    'Infelizmente essa tarifa expirou e não foi possível realizar a reserva. Faça uma nova pesquisa para continuar.',
  'orders.notifications.CREDIT_CARD_UNAUTHORIZED':
    'Houve um problema com o seu cartão e a reserva não pode ser confirmada.',
  'orders.notifications.EMPTY_CARD':
    'Nenhum cartão de crédito compatível com esta reserva foi encontrado. Peça ao administrador para adicionar novos cartões ou contate nosso suporte para mais informações!',
  'orders.notifications.RESERVATION_FAILED':
    'Infelizmente essa tarifa expirou e não foi possível realizar a reserva. Faça uma nova pesquisa para continuar.',
  'orders.notifications.EXPIRED_RESERVATION':
    'Infelizmente sua reserva expirou antes de ser emitada.',
  'orders.notifications.UNAVAILABLE_SEAT':
    'Infelizmente todos os assentos disponíveis foram ocupados. Faça uma nova pesquisa para continuar.',
  'orders.notifications.UNAVAILABLE_CHOOSEN_SEAT':
    'Infelizmente o assento escolhido na alteração não está mais disponível. Tente reservar outro, por favor!',
  'orders.notifications.ITEM_UNAVAILABLE':
    'Infelizmente esse item não está mais disponível para emissão. Você pode fazer uma nova pesquisa e assegurar uma nova reserva.',
  'orders.notifications.FREQUENT_FLYER_NUMBER':
    'O número de fidelidade informado não bate com o nome do passageiro na companhia aérea. Verifique as informações e tente novamente!',
  'orders.notifications.FREQUENT_FLYER_NUMBER.with-params':
    'O número de fidelidade informado ({frequentFlyerNumber}) não bate com o nome do passageiro ({passengerName}) na companhia aérea. Verifique as informações e tente novamente!',
  'orders.notifications.OPEN_BOOKING_REFUNDED':
    'A transação feita no site da reserva foi estornada após a data máxima de emissão da reserva',
  'orders.notifications.REPEATED_FLIGHT':
    'Identificamos 3 ou mais tentativas de realizar esta mesma reserva, por favor contate o suporte.',

  'orders.notifications.btn.ok': 'Tudo bem!',
  'orders.notifications.btn.search': 'Buscar novamente',

  'payment.single-installment': 'À Vista. 1x de {quantity}.',
  'payment.installment-with-interest':
    '{installment} parcelas. 1x de {firstInstallmentAmount} e {remainingInstallments}x de {remainingInstallmentsAmount} (com juros)',
  'payment.installment-without-interest':
    '{installment} parcelas. 1x de {firstInstallmentAmount} e {remainingInstallments}x de {remainingInstallmentsAmount}',

  /* 04.1. Travel: Car */
  'car.door': 'Porta',
  'car.doors': 'Portas',
  'car.passenger': 'Passageiro',
  'car.passengers': 'Passageiros',
  'car.baggage-size': 'Bagagem',
  'car.baggages-size': 'Bagagens',
  'car.air-conditioning': 'Ar Condicionado',
  'car.transmission': 'Transmissão',

  /* 04.1. Travel: Flight */
  'flight.no-stop': 'Direto',
  'flight.stop': 'Parada',
  'flight.stops': 'Paradas',
  'flight.checkout-page.organization': 'Organização',
  'flight.checkout-page.personal': 'Pessoal',
  'flight.with-selected-seat': 'Com assento selecionado',
  'flight.with-luggage': 'Com bagagem adicional',

  'flight-alteration.unavailable.title': 'Serviço não disponível',
  'flight-alteration.unavailable.description':
    ' A companhia parece não fornecer o serviço em questão por aqui, mas você pode contatar nosso suporte para tentar selecionar assentos e adicionar bagagens.',
  'flight-alteration.unavailable.btn': 'Contatar suporte',

  'flight-alteration.baggage-sec.title': 'Bagagem',
  'flight-alteration.baggage-sec.description':
    'Adicione bagagens em suas reservas de antemão',

  'flight-alteration.seat-sec.title': 'Assento',
  'flight-alteration.seat-sec.description':
    'Reserve os assentos de suas reservas de antemão',

  'flight-alteration.checkout': 'Finalizar compra',

  'flight.select-fare': 'Selecione sua tarifa',
  'flight.select-fare-description':
    'Personalize sua viagem e escolha uma das opções abaixo:',
  'flight.select-fare-card-out-return': 'Ida e volta',
  'flight.select-fare-card-out': 'Apenas ida',
  'flight.select-fare-card-total': 'Total',
  'flight.select-fare-card-btn-select': 'Selectionar tarifa',
  'flight.select-fare-card-btn-continue': 'Continuar com tarifa',
  'flight.select-fare-card-btn-goToReservation': 'Próxima etapa',

  'flight-alteration.checkout.organization.title': `As alterações solicitadas são cobertas pelas políticas da sua empresa. Você pode finalizar a compra clicando no botão abaixo, ou pode optar pagar as alterações por conta própria na aba "Pessoal".`,
  'flight-alteration.checkout.personal.title':
    'Sua organização não definiu nenhuma política para as alterações requeridas, mas relaxa que você ainda pode pagar por elas se quiser.',
  'flight-alteration.checkout.personal.title.allow':
    'As alterações requeridas são coberta pelas políticas de sua organização, mas você pode optar por pagá-las se quiser.',

  'flight-alteration.bags': 'Bagagens',
  'flight-alteration.bag': 'Bagagens',
  'flight-alteration.bags.single': '1 mala',
  'flight-alteration.bags.plural': '{bags} malas',
  'flight-alteration.bags.item': '{bags} de até {weight}',

  'flight-alteration.seats': 'Assentos',
  'flight-alteration.seat': 'Assentos',
  'flight-alteration.seats.item': 'Assento {seat}',

  'flight-alteration.refund-total': 'Valor a ser estornado',

  'flight-alteration.btn.cancel': 'Quero cancelar',

  'flight-alteration.error.cancel':
    'Não foi possível cancelar essa alteração. Entre em contato com nosso suporte, por favor!',

  'flight-alteration.checkout.error.cancel':
    'Não foi possível finalizar a compra das alterações. Tente novamente mais tarde!',

  'flight-alteration.cancellation.unable-to':
    'Não é possível cancelar as alterações feitas! Entre em contato com nosso suporte para mais informações.',
  'flight-alteration.cancellation.total-value': 'Valor pago pelas alterações',
  'flight-alteration.cancellation.refund-value': 'Valor que será reembolsado',
  'flight-alteration.cancellation.confirmation':
    'Tem certeza que deseja cancelar as alterações feitas nessa reserva?',

  /* 04.1. Travel: ESG */
  'esg.flight.estimate':
    'Você vai voar aproximadamente {miles} milhas e esta viagem irá emitir cerca de {carbon} kg de dióxido de carbono e outros gases de efeito estufa.',
  'esg.car.estimate':
    'A reserva desse veículo por esse período irá emitir cerca de {carbon} kg de dióxido de carbono e outros gases de efeito estufa.',
  'esg.bus.estimate':
    'Você vai viajar por aproximadamente {km} km e esta viagem irá emitir cerca de {carbon} kg de dióxido de carbono e outros gases de efeito estufa.',
  'esg.empty.title': 'Não há reservas elegíveis',
  'esg.empty.subtitle': 'Tente fazer novas reservas.',

  'esg.form.kg': 'Neutralização total',
  'esg.form.card': 'Cartão de Crédito',
  'esg.form.purchase': 'Neutralizar',

  /* Order Alternatives */
  'order.alternative.best-price': 'Melhor preço',
  'order.alternative.duplicity': 'Emissão duplicada',
  'order.alternative.save': 'Economize {save}',
  'order.alternative.arrive-early': 'Chega antes',
  'order.alternative.loading': 'Carregando alternativas',
  'order.alternative.cancellation': 'Cancelamento gratuito até {date}',

  /* Admin */
  'admin.active.mapWorld': 'Visão global de casos confirmados',
  'admin.active.mapBrazil': 'Visão Brasil de casos confirmados',
  'admin.active.travelersInit': 'Você tem ',
  'admin.active.travelersEnd': 'viajantes ativos',

  'admin.users.invite': 'CONVIDAR',
  'admin.users.bulkImport': 'IMPORTAR EM LOTE',
  'admin.users.bulkImport.lower': 'Importar em lote',
  'admin.users.bulkImportSuccess':
    'Arquivo importado com sucesso. Ele está sendo tratado e estará disponível em breve',
  'admin.users.invite-traveler': 'CONVIDAR VIAJANTE',
  'admin.users.no-pending': 'Você ainda não convidou ninguém.',
  'admin.users.emails': 'E-mails (insira um por linha)',
  'admin.users.submit': 'Convidar',

  'admin.users.remove-user.title': 'Remover usuário',
  'admin.users.remove-user.description':
    'Esta ação removerá o usuário da plataforma, para reativar será necessário acionar o suporte. Deseja prosseguir?',

  'admin.users.invite-btn': 'Convidar usuários',
  'admin.users.spreadsheet-model': 'Baixar planilha modelo',

  'admin.users.bulk.created.title': 'Convidar usuários',
  'admin.users.bulk.created.description.singular':
    'Foi encontrado 1 novo usuário! Você deseja convidá-lo para a plataforma?',
  'admin.users.bulk.created.description.plural':
    'Foram encontrados {number} novos usuários! Você deseja convidá-los para a plataforma?',

  'admin.users.bulk.modified.title': 'Modificar usuários',
  'admin.users.bulk.modified.description.singular':
    'Foi encontrado 1 usuário que já está cadastrado! Você deseja atualizar seu cadastro na plataforma?',
  'admin.users.bulk.modified.description.plural':
    'Foram encontrados {number} usuários que já estão cadastrados! Você deseja atualizar seus cadastros na plataforma?',

  'admin.users.bulk.deleted.title': 'Remover usuários',
  'admin.users.bulk.deleted.description.singular':
    'Foi encontrado 1 usuário que não está nos arquivos, mas que está cadastrado! Você deseja remover seu cadastro da plataforma?',
  'admin.users.bulk.deleted.description.plural':
    'Foram encontrados {number} usuários que não estão nos arquivos, mas que estão cadastrados! Você deseja remover os cadastros da plataforma?',

  'admin.users.bulk.invalid.title': 'Usuários inválidos',
  'admin.users.bulk.invalid.description.singular':
    'Foi encontrado 1 usuário que cujo CPF está inválido ou o tamanho do nome completo ultrapassa mais de 30 caracteres! Certifique-se de que o número está correto ou o tamanho máximo do nome completo é respeitado antes de adicioná-lo.',
  'admin.users.bulk.invalid.description.plural':
    'Foram encontrados {number} usuários cujos CPF estão inválidos ou o tamanho do nome completo ultrapassa mais de 30 caracteres! Certifique-se de que o número está correto ou o tamanho máximo do nome completo é respeitado antes de adicioná-los.',
  'admin.users.bulk.invalid.btn.download': 'Baixar usuários',

  'admin.users.bulk.card.create.title': 'Usuários criados',
  'admin.users.bulk.card.create.title.loading': 'Criando usuários',
  'admin.users.bulk.card.create.result.without-fail':
    '{successful} usuário(s) adicionado(s)',
  'admin.users.bulk.card.create.result.with-fail':
    '{successful} usuário(s) adicionado(s) e {failed} falha(s)',
  'admin.users.bulk.card.modify.title': 'Usuários modificados',
  'admin.users.bulk.card.modify.title.loading': 'Modificando usuários',
  'admin.users.bulk.card.modify.result.without-fail':
    '{successful} usuário(s) modificado(s)',
  'admin.users.bulk.card.modify.result.with-fail':
    '{successful} usuário(s) modificado(s) e {failed} falha(s)',
  'admin.users.bulk.card.delete.title': 'Usuários removidos',
  'admin.users.bulk.card.delete.title.loading': 'Removendo usuários',
  'admin.users.bulk.card.delete.result.without-fail':
    '{successful} usuário(s) removidos(s)',
  'admin.users.bulk.card.delete.result.with-fail':
    '{successful} usuário(s) removidos(s) e {failed} falha(s)',

  'invalid-users.xlsx.name': 'Usuários inválidos',
  'invalid-users.xlsx.filename': 'usuarios-invalidos',
  'invalid-users.columns.first-name': 'Nome',
  'invalid-users.columns.last-name': 'Sobrenome',
  'invalid-users.columns.cpf': 'CPF',
  'invalid-users.columns.email': 'E-mail',
  'invalid-users.columns.invalid-motive': 'Inválido',

  'invalid-users.motives.cpf': 'CPF',
  'invalid-users.motives.name': 'Nome completo',

  'admin.users.bulk.finish.title': 'Resumo',
  'admin.users.bulk.finish.description':
    'As seguintes mudanças serão efetuadas:',

  'admin.users.bulk.finish.created.none': 'Nenhum usuário será convidado!',
  'admin.users.bulk.finish.created.singular':
    'Um novo usuário será convidado para a plataforma!',
  'admin.users.bulk.finish.created.plural':
    'Serão convidados {number} usuários para a plataforma!',

  'admin.users.bulk.finish.deleted.none': 'Nenhum usuário será removido!',
  'admin.users.bulk.finish.deleted.singular':
    'Um usuário será removido da plataforma!',
  'admin.users.bulk.finish.deleted.plural':
    'Serão removidos {number} usuários da plataforma!',

  'admin.users.bulk.finish.modified.none': 'Nenhum usuário será modificado!',
  'admin.users.bulk.finish.modified.singular':
    'Um usuário será modificado na plataforma!',
  'admin.users.bulk.finish.modified.plural':
    'Serão modificados {number} usuários na plataforma!',

  'admin.users.bulk.finish.btn.apply': 'Aplicar mudanças',
  'admin.users.bulk.finish.btn.cancel': 'Cancelar mudanças',

  'admin.users.bulk.rgNumber': 'Número do RG',
  'admin.users.bulk.cnhValidUntil': 'Data de validade (CNH)',

  'admin.users.passport.number': 'Número do passaporte',
  'admin.users.passport.validUntil': 'Data de validade do passaporte',

  'admin.dashboard.title': 'Dashboard',
  'admin.dates.month': 'Mês atual',
  'admin.dates.year': 'Este ano',
  'admin.dates.all': 'Todos',

  'admin.policy.title': 'Gestão',
  'admin.policy.costCenters': 'Centros de Custo',
  'admin.policy.projects': 'Projetos',
  'admin.policy.groups': 'Grupos',
  'admin.policy.observations': 'Observações',
  'admin.policy.approvalRule': 'Regra de aprovação',
  'admin.policy.approvalRules': 'Regras de aprovação',
  'admin.policy.merchants': 'Estabelecimentos',
  'admin.policy.motives': 'Motivos',
  'admin.policy.tags': 'Tags',
  'admin.policy.delete': 'Deletar',

  'admin.policy.costCenter.Add': 'Adicionar',
  'admin.policy.costCenter.Modal.Add': 'Centro de Custo',
  'admin.policy.costCenter.Name': 'Nome',
  'admin.policy.costCenter.ExternalId': 'Identificador Externo',
  'admin.policy.costCenter.Create': 'Criar',
  'admin.costCenter.empty': 'Sem centros de custo',

  'admin.integrations.apiKey.Add': 'Criar',
  'admin.integrations.apiKey.Modal.Add': 'Chave de API',
  'admin.integrations.apiKey.Name': 'Nome',
  'admin.integrations.apiKey.Description':
    'Guarde as informações abaixo, pois elas só serão visíveis agora',
  'admin.integrations.apiKey.ClientID': 'Client ID',
  'admin.integrations.apiKey.ClientSecret': 'Client Secret',

  'admin.policy.project.Add': 'Adicionar',
  'admin.policy.project.Modal.Add': 'Criar Projeto',
  'admin.policy.project.Name': 'Nome',
  'admin.policy.project.ExternalId': 'Identificador Externo',
  'admin.policy.project.Create': 'Criar',

  'admin.policy.refund.Modal.Add': 'Criar Categoria de Orçamento',
  'admin.policy.refund.Icon': 'Ícone da categoria',

  'admin.policy.group.Add': 'Adicionar',
  'admin.policy.group.Modal.Add': 'Gerenciar Grupo',
  'admin.policy.group.Name': 'Nome',
  'admin.policy.group.Username': 'Usuário',

  'admin.policy.motive.Add': 'Adicionar',
  'admin.policy.motive.Modal.Add': 'Criar Motivo',
  'admin.policy.motive.Name': 'Nome',
  'admin.policy.motive.ExternalId': 'Identificador Externo',
  'admin.policy.motive.Create': 'Criar',

  'admin.policy.tag.Modal.Add': 'Criar Tag',

  'admin.policy.policy': 'Política',

  'admin.policy.policy.Add': 'Adicionar',
  'admin.policy.policy.Modal.Add': 'Criar Política',
  'admin.policy.policy.Name': 'Nome',
  'admin.policy.policy.Sort': 'Prioridade',
  'admin.policy.policy.Field': 'Informação',
  'admin.policy.policy.Comparison': 'Comparativo',
  'admin.policy.policy.Hierarchy': 'Hierarquia',
  'admin.policy.policy.Hierarchy.use': 'Utilizar hierarquia',
  'admin.policy.policy.Value': 'Valor',
  'admin.policy.policy.Save': 'Salvar',
  'admin.policy.policy.Group.Add': 'Adicionar Grupo',
  'admin.policy.policy.Rule.Add': 'Adicionar Regra',
  'admin.policy.policy.Approval.Add': 'Adicionar Aprovador',

  'admin.policy.empty.cost-center.title': 'Nenhum centro de custo',
  'admin.policy.empty.cost-center.description':
    'Você não possui nenhum centro de custo adicionado. Adicione um clicando no botão abaixo:',
  'admin.policy.empty.cost-center.btn': 'Adicionar centro de custo',

  'admin.policy.empty.project.title': 'Nenhum projeto adicionado',
  'admin.policy.empty.project.description':
    'Você não possui nenhum projeto adicionado. Adicione um clicando no botão abaixo:',
  'admin.policy.empty.project.btn': 'Adicionar projeto',

  'admin.policy.empty.motive.title': 'Nenhum motivo adicionado',
  'admin.policy.empty.motive.description':
    'Você não possui nenhum motivo adicionado. Adicione um clicando no botão abaixo:',
  'admin.policy.empty.motive.btn': 'Adicionar motivo',

  'admin.policy.empty.tag.title': 'Nenhuma tag adicionada',
  'admin.policy.empty.tag.description':
    'Você não possui nenhum tag adicionada. Adicione uma clicando no botão abaixo:',
  'admin.policy.empty.tag.btn': 'Adicionar tag',

  'admin.integrations.empty.api-key.title': 'Nenhuma Chave de API cadastrada',
  'admin.integrations.empty.api-key.description':
    'Você não possui nenhuma chave de API adicionada. Adicione uma clicando no botão abaixo:',
  'admin.integrations.empty.api-key.btn': 'Adicionar chave de API',

  'admin.finance.title': 'Financeiro',
  'admin.finance.invoice': 'Faturas',
  'admin.finance.credit_cards': 'Cartões de Crédito',
  'admin.finance.bank3': 'Cartão Virtual Bank 3',
  'admin.finance.bank3.funds-additon': 'Adição de Saldo',
  'admin.finance.bank3.available-balance': 'Saldo Disponível',
  'admin.finance.bank3.add-b3-connector': 'Adicionar conexão com a Bank 3',
  'admin.finance.bank3.final-balance': 'Saldo Final',

  'admin.policy.creditCard.Modal.Add': 'Criar Cartão de Crédito',
  'admin.policy.creditCard.Modal.Update': 'Modificar Cartão de Crédito',
  'admin.finance.creditCards.Add': 'Adicionar',
  'admin.policy.creditCard.cardName': 'Nome interno',
  'admin.policy.creditCard.documentNumber': 'CPF do proprietário',
  'admin.policy.creditCard.number': 'Número do Cartão',
  'admin.policy.creditCard.name': 'Nome Impresso no Cartão',
  'admin.policy.creditCard.expiry': 'Data de Expiração',
  'admin.policy.creditCard.cvv': 'CVV',
  'admin.policy.creditCard.priority': 'Prioridade',
  'admin.policy.creditCard.cost-centers': 'Centros de custo associados',
  'admin.policy.creditCard.products': 'Produtos associados',
  'admin.policy.placeholder.name': 'SEU NOME',
  'admin.policy.placeholder.valid': 'valido até',
  'admin.policy.fidelity-number.name': 'Número de fidelidade',
  'admin.policy.fidelity-name.name': 'Nome do programa',
  'admin.policy.flight-company.name': 'Companhia aérea',

  'admin.policy.bank3.Modal.Add': 'Conectar à Bank 3',
  'admin.policy.bank3.clientId': 'Client ID',
  'admin.policy.bank3.clientSecret': 'Client Secret',

  'admin.policy.observations.description':
    'Você deseja permitir que os passageiros possam escrever observações quando forem reservar algo?',
  'admin.policy.observations.allow': 'Permitir observações',

  'admin.policy.bulk.type': 'valores',
  'admin.policy.bulk.name': 'Nome',
  'admin.policy.bulk.identifier': 'Identificador Externo',
  'admin.policy.bulk.successful': 'Dados importados com sucesso!',

  'admin.policy.bulk.created.title': 'Adicionar valores',
  'admin.policy.bulk.created.description.singular':
    'Foi encontrado 1 novo valor! Você deseja adicioná-lo para a plataforma?',
  'admin.policy.bulk.created.description.plural':
    'Foram encontrados {number} novos valores! Você deseja adicioná-los a plataforma?',

  'admin.policy.bulk.modified.title': 'Modificar valores',
  'admin.policy.bulk.modified.description.singular':
    'Foi encontrado 1 valor que já está cadastrado! Você deseja atualizá-lo na plataforma?',
  'admin.policy.bulk.modified.description.plural':
    'Foram encontrados {number} valores que já estão cadastrados! Você deseja atualizá-los na plataforma?',

  'admin.policy.bulk.deleted.title': 'Remover valor',
  'admin.policy.bulk.deleted.description.singular':
    'Foi encontrado 1 valor que não está nos arquivos, mas que está cadastrado! Você deseja removê-lo da plataforma?',
  'admin.policy.bulk.deleted.description.plural':
    'Foram encontrados {number} valores que não estão nos arquivos, mas que estão cadastrados! Você deseja removê-los da plataforma?',

  'admin.policy.bulk.finish.title': 'Resumo',
  'admin.policy.bulk.finish.description':
    'As seguintes mudanças serão efetuadas:',

  'admin.policy.bulk.finish.created.none': 'Nenhum valor será adicionado!',
  'admin.policy.bulk.finish.created.singular':
    'Um novo valor será adicionado a plataforma!',
  'admin.policy.bulk.finish.created.plural':
    'Serão adicionados {number} valores na plataforma!',

  'admin.policy.bulk.finish.deleted.none': 'Nenhum valor será removido!',
  'admin.policy.bulk.finish.deleted.singular':
    'Um valor será removido da plataforma!',
  'admin.policy.bulk.finish.deleted.plural':
    'Serão removidos {number} valores da plataforma!',

  'admin.policy.bulk.finish.modified.none': 'Nenhum valor será modificado!',
  'admin.policy.bulk.finish.modified.singular':
    'Um valor será modificado na plataforma!',
  'admin.policy.bulk.finish.modified.plural':
    'Serão modificados {number} valores na plataforma!',

  'admin.policy.bulk.invalid.description.singular':
    'Foi encontrado 1 item cujo nome está inválido! Certifique-se de que ele está correto antes de adicioná-lo.',
  'admin.policy.bulk.invalid.description.plural':
    'Foram encontrados {number} items cujos nomes estão inválidos! Certifique-se de que estão corretos antes de adicioná-los.',

  'admin.kyc.companyTabTitle': 'Empresa',
  'admin.kyc.companyTabDesc': 'Cadastro',
  'admin.kyc.setupTabTitle': 'Setup',
  'admin.kyc.setupTabDesc': 'Pagamento',
  'admin.kyc.documentNumber': 'CNPJ da empresa',
  'admin.kyc.legalName': 'Razão Social',
  'admin.kyc.tradingName': 'Nome Fantasia',
  'admin.kyc.foundationDate': 'Data de Fundação',
  'admin.kyc.postalCode': 'CEP',
  'admin.kyc.street': 'Rua',
  'admin.kyc.streetNumber': 'Número',
  'admin.kyc.complement': 'Complemento',
  'admin.kyc.neighborhood': 'Bairro',
  'admin.kyc.city': 'Cidade',
  'admin.kyc.uf': 'UF',
  'admin.kyc.billingEmail': 'Email de cobrança',
  'admin.kyc.phone': 'Telefone',
  'admin.kyc.partner.documentNumber': 'CPF do sócio',
  'admin.kyc.partner.legalName': 'Nome Completo do sócio',
  'admin.kyc.partner.birthdate': 'Data de Nascimento',
  'admin.kyc.partner.phone': 'Telefone',
  'admin.kyc.partner.email': 'Email',
  'admin.kyc.payment.download': 'Baixar instruções',

  'admin.reports.title': 'Relatórios',
  'admin.reports.summary': 'Viagens',
  'admin.reports.expenses': 'Despesas',
  'admin.reports.esg': 'ESG',

  'admin.reports.search-title': 'Buscar',
  'admin.reports.filter-title': 'Filtros',
  'admin.reports.print-title': 'Exportar relatório',

  'admin.reports.esg.totalEmittedCo2': 'Total de CO2 emitido',
  'admin.reports.esg.totalSpent': 'Total gasto na redução de CO2',
  'admin.reports.esg.totalSpentWithCo2':
    '% do total gasto na redução do CO2 em relação ao total gasto',
  'admin.reports.esg.topSections': 'Trechos que mais geraram CO2',
  'admin.reports.esg.topCostCenter':
    'Centros de custo que mais emitiram CO2 (t)',
  'admin.reports.esg.topProjects': 'Projetos que mais emitiram CO2 (t)',
  'admin.reports.esg.reservations': 'Reservas e suas emissões de CO2',

  'admin.travels.title': 'Viagens',
  'admin.travels.dashboard': 'Dashboard',
  'admin.travels.active': 'Viagens Ativas',
  'admin.travels.travels': 'Configurações',
  'admin.travels.favorite-places': 'Lugares Favoritos',
  'admin.travels.favorite-hotels': 'Hotéis Favoritos',

  'admin.integrations.api-keys': 'Chaves de API',
  'admin.others.language': 'Linguagem',
  'admin.others.connectors': 'Conectores',
  'admin.others.open-booking': 'Open Booking',
  'admin.others.params': 'Parâmetros de busca',

  'admin.travels.permissions.description':
    'Selecione quais os tipos de reserva que os usuários podem fazer na plataforma.',
  'admin.travels.permissions.description.extra':
    'Pelo menos uma das opções deve estar habilitada!',
  'admin.travels.advanced_options.products': 'Produtos',
  'admin.travels.advanced_options.currency': 'Moeda',
  'admin.travels.advanced_options.nationality': 'Nacionalidade',

  'admin.travels.advanced_options.currency.description':
    'Essa configuração altera a moeda que será exibida na busca de tarifas de passagens aéreas, hotéis e alugueis de carros utilizando uma cotação online e valores aproximados. Os valores cobrados em seu cartão de crédito serão sempre em Reais Brasileiros. A reserva, voucher e políticas ainda serão configuradas em Reais Brasileiros',

  'admin.travels.advanced_options.nationality.description':
    'Essa configuração altera a nacionalidade padrão dos passageiros na busca de tarifas de passagens aéreas, hotéis e alugueis de carros',

  'admin.data.details': 'Detalhamento',
  'admin.data.travel_credits': 'Bilhetes não voados',
  'admin.data.suppliers': 'Fornecedores',

  'admin.refunds.title': 'Contas a pagar',
  'admin.refunds.receipts': 'Recibos',
  'admin.refunds.travels': 'Permissões',
  'admin.refunds.categories': 'Categorias de despesa',
  'admin.refunds.cards': 'Cartões',
  'admin.refunds.budget': 'Despesas com viagem',
  'admin.refunds.km': 'Despesa por Km',
  'admin.refunds.advanced_options': 'Opções avançadas',
  'admin.refunds.alias': 'E-mail',

  'admin.esg.title': 'ESG',

  'admin.refunds.permissions.description':
    'Nesta seção você pode escolher se deseja permitir que os usuários desta organização possam usufruir da funcionalidade de despesas:',
  'admin.refunds.permissions.allow':
    'Permitir que usuários utilizem as despesas',

  'admin.receipts.permissions.description':
    'Você deseja permitir que passageiros só possam criar uma solicitação de orçamento se o mesmo possuir um recibo?',
  'admin.receipts.permissions.allow':
    'Permitir a criação de solicitação de orçamento sem recibo',

  'admin.receipts.km.description':
    'Defina abaixo qual o valor cobrado por um quilômetro percorrido em um despesa por km:',

  'admin.receipts.card.description':
    'Você deseja configurar a plataforma para utilizar os cartões Portão 3 de despesas?',
  'admin.receipts.card.config': 'Configurar o conector de gestão de cartões',

  'admin.receipts.administrative.description':
    'Você deseja permitir que os usuários possam criar contas administrativas e realizar o pagamento de boletos e PIX?',
  'admin.receipts.administrative.config':
    'Permitir criação de contas administrativas',

  'admin.refunds.merchants.description':
    'Nesta seção você escolher quais estabelecimentos seus colaboradores poderão usar para criar seus orçamentos:',
  'admin.refunds.merchants.PHYSICAL.title': 'Cartões físicos',
  'admin.refunds.merchants.VIRTUAL.title': 'Cartões virtuais',

  'admin.automatic.payments.description':
    'Você deseja habilitar o pagamento automático de reembolsos aprovados via Pix?',
  'admin.automatic.payments.config':
    'Habilitar pagamento automático de reembolsos aprovados via Pix',

  'admin.receipts.alias.description':
    'Utilize um pseudônimo como seu e-mail de despesas, tornando-o muito mais legível e fácil de compartilhar. Mas lembre-se, você só pode alterá-lo uma vez!',
  'admin.receipts.alias.added.description':
    'Você já adicionou um pseudônimo como seu e-mail de despesas:',

  'admin.settings.title': 'Configurações',
  'admin.settings.terms': 'Termos de Uso',
  'admin.settings.policy': 'Políticas de Privacidade',
  'admin.settings.esg': 'ESG',

  'admin.settings.travels.description':
    'Nesta seção você pode escolher se deseja permitir, ou não, que viagens corporativas e pessoais possam ser feitas nesta organização:',
  'admin.settings.travels.organization-travel': 'Permitir viagens pela empresa',
  'admin.settings.travels.personal-travel': 'Permitir viagens pessoais',

  'admin.travels.empty.favorite-places.title':
    'Nenhum lugar favorito encontrado',
  'admin.travels.empty.favorite-places.description':
    'Você não possui nenhum lugar favorito adicionado! Adicione um clicando no botão abaixo:',
  'admin.travels.empty.favorite-places.btn': 'Adicionar lugar favorito',

  'admin.travels.empty.favorite-hotels.title': 'Nenhum hotel favorito',
  'admin.travels.empty.favorite-hotels.description':
    'Você não possui nenhum hotel favorito! Clique no botão abaixo para procurar seus hotéis favoritos:',
  'admin.travels.empty.favorite-hotels.btn': 'Adicionar hotel favorito',

  'admin.travels.empty.policy.title': 'Nenhum política adicionada',
  'admin.travels.empty.policy.description':
    'Você não possui nenhuma política adicionada! Seus aprovadores não poderão aprovar as reservas de sua organização. Adicione uma nova política clicando no botão abaixo:',
  'admin.travels.empty.policy.btn': 'Adicionar política',

  'admin.travels.empty.group.title': 'Nenhum grupo adicionado',
  'admin.travels.empty.group.description':
    'Você não possui nenhum grupo adicionado! Adicione um novo clicando no botão abaixo:',
  'admin.travels.empty.group.btn': 'Adicionar grupo',

  'admin.travels.empty.categories.title': 'Nenhuma categoria adicionada',
  'admin.travels.empty.categories.description':
    'Você não possui nenhuma categoria de orçamento adicionada! Os usuários dessa organização não serão capazes de adicionar novos orçamentos. Adicione uma nova categoria clicando no botão abaixo:',
  'admin.travels.empty.categories.btn': 'Adicionar categoria',

  'admin.travels.open-booking.description':
    'O Open Booking permite que seus colaboradores façam reservas em outros sites a partir de um cartão virtual criado após a aprovação da reserva. Garanta o melhor preço sem perder o controle das viagens!',
  'admin.travels.open-booking.permissions.allow': 'Permitir o Open Booking',

  'admin.travels.params.description':
    'Selecione os parâmetros que ficarão selecionados quando um passageiro fizer uma busca',

  'admin.travels.params.flight.companies':
    'Quais companhias aéreas devem ser selecionadas inicialmente?',

  'admin.travels.params.hotel.categories':
    'Quais categorias devem ser selecionadas inicialmente?',
  'admin.travels.params.hotel.amenities':
    'Quais amenidades devem ser selecionadas inicialmente?',

  'admin.travels.params.car.rentals':
    'Quais locadoras devem ser selecionadas inicialmente?',
  'admin.travels.params.car.categories':
    'Quais características devem ser selecionadas inicialmente?',
  'admin.travels.params.car.amenities':
    'Quais tipos devem ser selecionados inicialmente?',

  'admin.travels.params.bus.carriers':
    'Quais companhias devem ser selecionadas inicialmente?',
  'admin.travels.params.bus.categories':
    'Quais características devem ser selecionadas inicialmente?',

  'admin.travels.open-booking.days-before-expiration':
    'Quantos dias o colaborador terá para emitir a reserva no site escolhido?',

  'admin.refunds.empty.budget.title': 'Nenhum orçamento adicionado',
  'admin.refunds.empty.budget.description':
    'Você não possui nenhum orçamento adicionado! Adicione um novo clicando no botão abaixo:',
  'admin.refunds.empty.budget.btn': 'Adicionar orçamento',

  'admin.credit-cards.associated-items.title': 'Associado a',
  'admin.credit-cards.associated-items.costCenters': 'Centros de custo',
  'admin.credit-cards.associated-items.products': 'Produtos',

  'admin.others.languages.description':
    'Nesta seção você pode escolher qual a linguagem padrão mostrada aos passageiros desta organização:',
  'admin.others.languages.options.title': 'Linguagem padrão',

  'admin.others.connectors.description':
    'Nesta seção você pode escolher quais conectores são permitidos nesta organização:',

  'admin.others.connectors.flight.title': 'Aéreo',
  'admin.others.connectors.hotel.title': 'Hotel',
  'admin.others.connectors.car.title': 'Carro',
  'admin.others.connectors.bus.title': 'Ônibus',

  'admin.others.connectors.provider.IDEASFRACTAL': 'P3 Voos',
  'admin.others.connectors.provider.ITERPEC': 'P3 Hotéis',
  'admin.others.connectors.provider.OFFLINE_HOTELS': 'Hotéis offline',
  'admin.others.connectors.provider.ITERPECCAR': 'P3 Veículos',
  'admin.others.connectors.provider.CLICKBUS': 'P3 Ônibus',

  /* Open Booking */
  'open-booking.skip-steps':
    'Não desejo ver isso nas próximas vezes que fizer uma reserva Open Booking',
  'open-booking.generate-card.description':
    'Pula a etapa de aprovação e vai direto para a geração do cartão virtual',
  'open-booking.approval-info.description':
    'Esta é uma reserva Open Booking. Ao ser aprovada, um cartão virtual será gerado e o passageiro deve ir até o site da companhia escolhida e fazer a emissão por conta própria. Tem certeza que deseja aprovar esta reserva?',

  'open-booking.action.title': 'Encontrou mais barato em outro site?',
  'open-booking.action.description':
    'Não queremos que você perca dinheiro. Que tal usar nosso Open Booking e, além de garantir o menor preço, ainda conseguir manter o controle das viagens de sua empresa?',
  'open-booking.action.btn': 'Criar reserva',

  'open-booking.issue-days.singular':
    'Após a aprovação, você terá 1 dia para fazer a emissão',
  'open-booking.issue-days.plural':
    'Após a aprovação, você terá {days} dias para fazer a emissão',

  'open-booking.modal.description':
    'Você está reservando uma oferta Open Booking. Seu funcionamento é diferente das reservas ofertadas pela Portão 3:',

  'open-booking.steps.approval.title': 'Aprovação',
  'open-booking.steps.approval.description':
    'Esta reserva passará pela aprovação normalmente, igual uma reserva ofertada pela Portão 3',
  'open-booking.steps.issue.title': 'Emissão e pagamento',
  'open-booking.steps.issue.description':
    'Após sua aprovação, um cartão virtual será criado e você deverá efetuar a emissão no site parceiro. Quando recebermos a notificação da transação no cartão, o status desta reserva mudará para Emitido',
  'open-booking.steps.done.title': 'Tudo pronto!',
  'open-booking.steps.done.description':
    'Por fim, depois da emissão, você deverá anexar um comprovante da reserva feita no site parceiro e poderá adicionar informações referentes a reserva',

  'open-booking.steps.reservation': 'Reserva',
  'open-booking.steps.approval': 'Aprovação',
  'open-booking.steps.issue': 'Emissão',
  'open-booking.steps.receipt': 'Comprovante',

  'open-booking.issue-steps.issue.title':
    'Acesse o <a href="#" target="_blank" id="issue-step-provider-link">site escolhido</a> e faça a reserva desejada',
  'open-booking.issue-steps.use-card.title':
    'Utilize os dados do cartão abaixo para realizar sua reserva:',
  'open-booking.issue-steps.attachment.title':
    'Ficaremos monitorando esse cartão. Quando identificarmos que uma compra foi efetuada, o status dessa reserva passará para Emitido. Além disso, você deverá anexar um comprovante da compra feita no site da reserva.',

  'open-booking.final-steps.generate-receipt.required.title':
    'Agora que a emissão foi feita, você deverá ir até o <a href="#" target="_blank" id="final-step-provider-link">site da reserva</a> e gerar um comprovante contendo os dados da mesma. Sua organização definiu esse passo como obrigatório, você será lembrado diariamente enquanto não anexar o comprovante.',
  'open-booking.final-steps.generate-receipt.title':
    'Agora que a emissão foi feita, você pode ir até o <a href="#" target="_blank" id="final-step-provider-link">site da reserva</a> e gerar um comprovante para anexá-lo abaixo, se quiser',
  'open-booking.final-steps.attach-receipt.title':
    'Para adicionar o comprovante da reserva, arraste-o até o componente abaixo ou então clique nele para abrir o seletor de arquivos',

  'open-booking.final-steps.receipt.drag': 'Comprovante da reserva',
  'open-booking.final-steps.receipt.drop': 'Solte aqui!',

  // Refund
  'refund.nav.expenses': 'Despesas',
  'refund.nav.refunds': 'Orçamentos',
  'refund.nav.approvals': 'Aprovações',

  'refund.modal.name': 'Nome',
  'refund.modal.location': 'Localização',
  'refund.modal.description': 'Descrição',
  'refund.modal.date': 'Data',
  'refund.modal.category': 'Categoria',
  'refund.modal.all-data': 'Dados da despesa',
  'refund.modal.items': 'Itens da despesa',
  'refund.modal.add-items': 'Adicionar Itens',
  'refund.modal.no-items-added': 'Nenhum item foi adicionado',
  'refund.modal.start-at': 'Começa em',
  'refund.modal.end-at': 'Termina em',
  'refund.modal.account': 'Conta pessoal',
  'refund.modal.budget': 'Orçamento',

  'refund.modal.km.items': 'Paradas',
  'refund.modal.km.add-items': 'Adicionar Parada',
  'refund.modal.km.no-items-added': 'Nenhuma parada foi adicionada',
  'refund.modal.km.details': 'Detalhes',

  'refund.modal.tabs.budget': 'Orçamento',
  'refund.modal.tabs.recurrence': 'Recorrência',
  'refund.modal.tabs.payment': 'Pagamento',

  'refund.modal.refund.all-data': 'Dados do orçamento',
  'refund.modal.refund.items': 'Despesas vinculadas',
  'refund.modal.refund.comments': 'Comentários',
  'refund.modal.refund.name': 'Nome do orçamento',
  'refund.modal.refund.budget': 'Valor (R$)',
  'refund.modal.refund.budget-category': 'Estabelecimento',
  'refund.modal.refund.no-budget-category': 'Sem estabelecimentos',
  'refund.modal.refund.spend-limit': 'Teto de gastos',
  'refund.modal.refund.add-credit-card': 'Adicionar ao cartão de crédito',

  'refund.modal.items.refund-type': 'Tipo do orçamento',
  'refund.modal.items.refund-type.food': 'Alimentação',
  'refund.modal.items.refund-type.fuel': 'Combustível',
  'refund.modal.items.refund-type.transport': 'Transporte',
  'refund.modal.items.refund-type.free': 'Livre',

  'refund.budget-category.PHYSICAL.FOOD': 'Alimentação',
  'refund.budget-category.PHYSICAL.FUEL': 'Combustível',
  'refund.budget-category.PHYSICAL.MOBILITY': 'Mobilidade',
  'refund.budget-category.PHYSICAL.NATIONAL': 'Nacional',
  'refund.budget-category.PHYSICAL.NATIONAL_WITHOUT_WITHDRAWALS':
    'Nacional (sem saques)',
  'refund.budget-category.PHYSICAL.INTERNATIONAL': 'Internacional',

  'refund.budget-category.VIRTUAL.NATIONAL': 'Nacional',
  'refund.budget-category.VIRTUAL.INTERNATIONAL': 'Internacional',
  'refund.budget-category.VIRTUAL.ADS': 'Ads',
  'refund.budget-category.VIRTUAL.SAAS': 'Saas',
  'refund.budget-category.VIRTUAL.FLIGHT': 'Aéreo',
  'refund.budget-category.VIRTUAL.HOTEL': 'Hotel',
  'refund.budget-category.VIRTUAL.MOBILITY': 'Mobilidade',

  'refund.modal.create-refund': 'Criar orçamento',
  'refund.modal.create-payment': 'Criar pagamento',
  'refund.modal.create-recurrency-and-budget': 'Criar orçamento e recorrência',
  'refund.modal.create-just-recurrency': 'Criar recorrência',
  'refund.modal.create-expense': 'Criar despesa',
  'refund.modal.store-modifications': 'Salvar modificação',
  'refund.modal.undo-expense': 'Desfazer solicitação',
  'refund.modal.expense-items': 'Itens da despesa',

  'refund.modal.cannot-create':
    'Sua organização não permite a criação de orçamentos',
  'refund.modal.invalid':
    'Você precisa preencher todos os campos obrigatórios para criar este orçamento',

  'refund.budget.description': 'Limita o valor que um orçamento pode ter.',

  'refund.modal.alright-refund': 'Tudo certo com seu orçamento!',
  'refund.modal.incomplete-refund': 'Seu orçamento está incompleto!',

  'refund.modal.alright-expense': 'Tudo certo com sua despesa!',
  'refund.modal.incomplete-expense': 'Sua despesa está incompleta!',

  'refund.empty.title': 'Nenhum orçamento encontrado!',
  'refund.empty.description':
    'Você ainda não adicionou nenhum orçamento. Começe a usar esta funcionalidade criando um novo orçamento clicando no botão abaixo:',

  'expenses.empty.title': 'Nenhuma despesa encontrada!',
  'expenses.empty.none-added': 'Nenhuma despesa adicionada!',
  'expenses.empty.description':
    'Você ainda não criou nenhuma despesa. Faça sua primeira clicando no botão abaixo:',

  'approvals.empty.title': 'Nenhuma solicitação aguardando aprovação!',
  'approvals.empty.description':
    'Não existem nenhuma solicitação de orçamento aguardando sua aprovação. Volte aqui mais tarde.',

  'refund.modal.items.name': 'Nome',
  'refund.modal.items.owner': 'Pertence a',
  'refund.modal.items.unity-price': 'Preço Unitário',
  'refund.modal.items.quantity': 'Qtde.',
  'refund.modal.items.total': 'Total',

  'refund.params.start-date': 'Começa em',
  'refund.params.end-date': 'Termina em',
  'refund.params.time-interval': 'Período',
  'refund.params.administrative.time-interval': 'Data definida para pagamento',
  'refund.params.payment-date': 'Data de pagamento',

  'refund.num-items': 'Nro. itens',

  'expense.modal.all-data': 'Dados do orçamento',
  'expenses.num-items': 'Número de Itens de Orçamento',
  'expenses.status': 'Status da Solicitação',

  'expense.modal.km.title': 'Nenhuma parada adicionada',
  'expense.modal.km.description':
    'Você pode adicionar novas paradas procurando por um endereço ou cidade no campo de busca do mapa, que se encontra na parte superiror do mesmo!',

  'expenses.placeholder.num-items.singular': '{number} itens de orçamento',
  'expenses.placeholder.num-items.plural': '{number} itens de orçamento',

  'expenses.no-refund-added': 'Nenhum orçamento adicinado',
  'expenses.refunds-added': '{refunds} orçamento(s) adicionado(s)',

  'refund.placeholder.refund-item-name': 'Novo item',
  'refund.placeholder.no-categories': 'Nenhuma categoria cadastrada',
  'refund.placeholder.no-account': 'Nenhuma conta cadastrada',
  'refund.placeholder.no-budget': 'Nenhuma orçamento cadastrado',

  'approval-refund-item.placeholder.commentary':
    'Escreva um comentário a respeito desta despesa para o passageiro',

  'refund.modal.btn.new-expense': 'Criar solicitação',
  'refund.modal.btn.cancel-expense': 'Cancelar solicitação',
  'refund.modal.btn.send-for-approval': 'Pedir aprovação',
  'refund.modal.btn.put-on-hold': 'Colocar em espera',
  'refund.modal.btn.put-on-hold.title':
    'Retira o saldo do orçamento e o coloca em espera, permitindo a criação de outro de mesmo período e categoria',
  'refund.modal.btn.pay': 'Pagar',

  'refund.modal.not-associated':
    'Este item não está associado a nenhuma solicitação',
  'refund.modal.associated': 'Este item está associado ao seguinte orçamento:',
  'refund.modal.item-reject': 'Este item foi rejeitado pelo aprovador',

  'refund.dialog.send-for-approval.title': 'Pedir aprovação',
  'refund.dialog.send-for-approval': `Este orçamento ainda está ativo! Ao enviá-lo para aprovação, todo o saldo restante deste orçamento será
  retirado do cartão associado a ele e novas transações serão bloqueadas. Tem certeza que deseja enviá-lo para
  aprovação?`,

  'refund.expense.approving': 'Este orçamento está aguardando a aprovação!',
  'refund.expense.review':
    'Há despesas deste orçamento que precisam ser revisadas!',
  'refund.expense.no-items': 'Nenhuma despesa foi adicionada a este orçamento!',
  'refund.expense.review-done':
    'Tudo ok com seu orçamento! Mande-o para aprovação',
  'refund.expense.approved': 'Tudo ok! Este orçamento foi aprovado!',
  'refund.expense.rejected': 'Poxa! Este orçamento foi rejeitado!',
  'refund.expense.cancelling': 'Estamos cancelando este orçamento!',
  'refund.expense.cancelled': 'Este orçamento foi cancelado!',
  'refund.expense.dismissed': 'Este orçamento foi escondido!',
  'refund.expense.error': 'Um erro aconteceu com este orçamento!',
  'refund.expense.complete-fields':
    'Complete os campos obrigatórios das suas despesas para enviar para aprovação!',
  'refund.expense.waiting-payment': 'Este orçamento está aguardando pagamento!',
  'refund.expense.processing': 'Este orçamento está em processamento!',
  'refund.expense.paid': 'Este orçamento já está pago!',

  'refund.administrative-expense.approved':
    'Tudo ok! Este pagamento foi efetuado!',
  'refund.administrative-expense.cancelled':
    'Este pagamento não pôde ser efetuado!',
  'refund.administrative-expense.activating':
    'Estamos efetuando o pagamento...',

  'refund.timeline.title.approval':
    'Acompanhe abaixo a aprovação do seu orçamento.',
  'refund.timeline.title.waiting':
    'Acompanhe abaixo o andamento do seu orçamento.',
  'refund.timeline.title.activating': 'Seu orçamento está sendo ativado.',
  'refund.timeline.created': 'Criado',
  'refund.timeline.approved': 'Aprovado',
  'refund.timeline.waiting': 'Aguardando data',

  'refund.expense.overflow':
    'O total ultrapassa o teto de gastos do orçamento!',
  'refund.expense.perKm': '({price} / km)',

  'refund.no-items.title': 'Nenhuma despesa adicionada',
  'refund.no-items.description':
    'Você ainda não adicionou nenhuma despesa neste orçamento. Clique na despesa que você deseja adicionar e arraste até o card deste orçamento, que pode ser encontrado no topo da página.',
  'refund.no-budget': 'Este orçamento não possui um teto de gasto',

  'expense.detailed-item.single-item': '{number} item',
  'expense.detailed-item.multiple-items': '{number} itens',

  'refund.expense.review-requested': 'Esse orçamento precisa ser revisado!',
  'refund.expense.km.review-requested':
    'Essa rota não foi aprovada! Mude-a ou cancele a despesa',
  'refund.expense.without-expense':
    'Este item não está associado a nenhum orçamento',

  'refund.expense.no-refund': 'Você não possui nenhum orçamento em aberto!',
  'refund.expense.available-refund': 'Você possui 1 orçamento em aberto!',
  'refund.expense.available-refunds':
    'Você possui {refunds} orçamentos em aberto!',

  'refund.expense.good-score':
    'Muito bem! {percentage}% de suas solicitações foram aprovadas.',
  'refund.expense.bad-score':
    'Poxa! Apenas {percentage}% de suas solicitações foram aprovadas.',
  'refund.expense.worse-score':
    'Poxa! Nenhuma de suas solicitações foram aprovadas.',
  'refund.expense.no-score':
    'Você ainda não possui solicitações aprovadas ou recusadas!',

  'refund.modal.no-receipt': 'Nenhum recibo adicionado',
  'refund.modal.no-receipt.description':
    'Você não adicionou nenhum recibo nesta despesa',

  'refund.modal.no-categories':
    'Categorias de despesa precisam ser cadastradas para que esta despesa seja criada.<br/>Peça para um administrador cadastrá-las na opção <b>Contas a Pagar</b> do painel do administrador!',
  'refund.modal.no-categories.admin':
    'Categorias de despesa precisam ser cadastradas antes da criação de despesas. Você deseja cadastrar uma categoria?',

  'expense.modal.title': 'Nenhum orçamento cadastrado',
  'expenses.modal.no-refunds': 'Você ainda não possui orçamentos cadastrados!',

  'refund.card.title': 'Adicionar um cartão',
  'refund.card.subtitle':
    'Para cadastrar um novo cartão, faça a leitura do QR Code que se encontra no verso do mesmo',
  'refund.card.connecting':
    'Estamos conectando o cartão de crédito com a sua conta...',
  'refund.card.replace_user':
    'Este cartão já está associado a outro usuário e precisamos remover as associações anteriores, tudo bem?',
  'refund.card.replace_user.action': 'Associar à mim',
  'refund.card.active-budgets':
    'Há orçamentos ativos de um usuário ({passenger}) neste cartão. Tais orçamentos devem ser sanados antes de que você possa vinculá-lo a você!',
  'refund.card.active-budgets.error':
    'Não foi possível validar os orçamentos deste cartão. Tente novamente ou entre em contato com nosso suporte!',

  'refund.anticipate-approval.text':
    'O passageiro solicitou um orçamento antecipado. Se aceito, o valor requerido será debitado, em sua totalidade, no cartão utilizado para a criação desse orçamento no dia de início informado pelo passageiro. Aprove, ou não, a antecipação clicando nos botões abaixo.',

  'expense.type.items': 'Despesa',
  'expense.type.km': 'Despesa por Km',

  'refund.card.create_pin': 'Crie uma nova senha para este cartão:',
  'refund.card.btn.done': 'Pronto!',
  'refund.card.btn.alright': 'Tudo bem!',

  'refund.card.cancel': 'Opa! Deu algo errado. Tente novamente, por favor.',

  'refund.card.sucess':
    'Yay! Deu tudo certo. Aproveite seu novo cartão Bank 3!',

  'refund.payment-method.title': 'Adicionar pagamento',
  'refund.payment-method.description':
    'Escolha qual método de pagamento você deseja adicionar em sua conta.',

  'refund.payment-method.PHYSICAL.title': 'Cartão físico',
  'refund.payment-method.PHYSICAL.description':
    'Um cartão físico que você recebeu pelos correios da Portão 3. Se ainda não tiver um, entre em contato com nosso time e solicite o seu.',

  'refund.payment-method.VIRTUAL.title': 'Cartão virtual',
  'refund.payment-method.VIRTUAL.description':
    'Tenha uma maior segurança ao realizar pagamentos e compras online utilizando cartões virtuais.',

  'refund.payment-method.PERSONAL.title': 'Conta pessoal',
  'refund.payment-method.PERSONAL.description':
    'Use essa conta para gerenciar os pagamentos que você fez pessoalmente, ideal para gerenciar solicitações de orçamentos.',

  'refund.payment-method.ADMINISTRATIVE.title': 'Conta administrativa',
  'refund.payment-method.ADMINISTRATIVE.description':
    'Permite pagar boletos e fazer transferências via PIX mantendo o gerenciamento dos orçamentos.',

  'refund.virtual-card.create.title': 'Criar cartão virtual',
  'refund.virtual-card.create.description':
    'Dê um nome ao seu cartão, escolha uma cor e um ícone para seu cartão virtual, se assim desejar.',

  'refund.virtual-card.create.card-name': 'Nome do cartão',
  'refund.virtual-card.create.color': 'Cor do cartão',
  'refund.virtual-card.create.icon': 'Ícone',

  'refund.virtual-card.create.too-bright': 'Escolha uma cor mais escura',

  'refund.virtual-card.create.do-create': 'Criar cartão',

  'refund.personal-account.create.title': 'Criar conta pessoal',

  'refund.personal-account.create.account-name': 'Nome da conta',
  'refund.personal-account.create.pix-type': 'Tipo da chave',
  'refund.personal-account.create.pix': 'Chave PIX',

  'refund.personal-account.create.do-create': 'Criar conta',

  'refund.administrative-account.create.title': 'Criar conta administrativa',
  'refund.administrative-account.create.do-create': 'Criar conta',

  'pix-types.CPF': 'CPF',
  'pix-types.CNPJ': 'CNPJ',
  'pix-types.EMAIL': 'E-mail',
  'pix-types.PHONE': 'Telefone',
  'pix-types.RANDOM': 'Chave aleatória',
  'pix-types.NONE': 'Nenhum',
  'pix-types.EVP': 'Chave aleatória',

  'pix.invalid': 'PIX inválido!',

  'personal-account.bank-info':
    'Banco {bank}, agência {branchCode}, conta {accountNumber}, tipo {accountType}',
  'personal-account.type.checking': 'Conta corrente',
  'personal-account.type.saving': 'Conta poupança',
  'personal-account.type.salary': 'Conta salário',
  'personal-account.type.payment': 'Conta pagamento',

  'cards.card-funds.total': 'Saldo disponível',

  'refund.my-cards.add-payment': 'Adicionar pagamento',
  'refund.my-cards.print-report': 'Imprimir Relatório',
  'refund.my-cards.search-card': 'Buscar por conta',
  'refund.my-cards.all-cards': 'Mostrar todos os cartões',
  'refund.my-cards.with-funds': 'Mostrar somente cartões com saldo',

  'refund.my-cards.empty.title':
    'Você não possui nenhum método de pagamento adicionado!',
  'refund.my-cards.empty.description':
    'Mas não se preocupe, você pode adicionar um rapidamente clicando no botão acima.',

  'refund.refund-budget-card.total': 'Total do orçamento',
  'refund.refund-budget-card.new-expense': 'Nova despesa',
  'refund.refund-budget-card.new-budget': 'Novo orçamento',
  'refund.refund-budget-card.create-budget': 'Criar orçamento',
  'refund.refund-budget-card.empty': 'Sem despesas vinculadas ao orçamento',
  'refund.refund-budget-card.new':
    'Crie um novo orçamento para liberar saldo nessa conta',
  'refund.refund-budget-card.remaining.one': '+ 1 despesa!',
  'refund.refund-budget-card.remaining.multiple':
    '+ {remainingExpenses} despesas!',

  'refund.km-expense.new-stop': 'Adicionar parada',
  'refund.km-expense.search-stop': 'Local de parada',
  'refund.km-expense.search-start': 'Local de saída',
  'refund.km-expense.search-end': 'Local de chegada',
  'refund.km-expense.pop-over':
    'Adicione uma parada procurando por uma cidade ou endereço na busca de pesquisa na parte superior do mapa ao lado',
  'refund.km-expense.outbound': 'Saída',
  'refund.km-expense.inbound': 'Chegada',

  'refund.km-expense.info': '{stops} paradas adicionadas nessa despesa',
  'refund.item-expense.singular-info': '1 item adicionado nessa despesa',
  'refund.item-expense.plural-info': '{items} itens adicionados nessa despesa',

  'refund.item-expense.refunded': 'Transação estornada',

  'expense-card.do-blocked': 'Bloquear cartão',
  'expense-card.do-unblock': 'Desbloquear cartão',

  'expense-card.show-details': 'Mostrar detalhes',
  'expense-card.hide-details': 'Esconder detalhes',
  'expense-card.copy': 'Copiar número do cartão',

  'change-pin.password': 'Digite a nova senha do cartão',
  'change-pin.confirmation': 'Confirme a senha digitada',
  'change-pin.not-same-password': 'As senhas digitadas são diferentes!',
  'change-pin.submit': 'Mudar senha',

  'invoice.key': 'Chave de acesso',
  'invoice.number': 'Número NFC-e',
  'invoice.series': 'Série',
  'invoice.protocol': 'Protocolo de autorização',
  'invoice.date': 'Data de autorização',
  'invoice.state': 'Estado',
  'invoice.supplier': 'Estabelecimento',
  'invoice.supplier.cnpj': 'CNPJ',
  'invoice.supplier.address': 'Endereço do estabelecimento',
  'invoice.supplier.cnae': 'CNAE',
  'invoice.consumer': 'Consumidor',
  'invoice.consumer.document': 'Documento',
  'invoice.mcc': 'Código MCC',
  'invoice.merchant-name': 'Nome da transação',
  'invoice.city': 'Cidade',
  'invoice.country': 'País',
  'invoice.value': 'Valor',
  'invoice.merchant-id': 'ID da loja',

  'receipt-expense.upload-stage': 'Salvando recibo',
  'receipt-expense.search-stage': 'Procurando por códigos de barra',
  'receipt-expense.scan-stage': 'Lendo recibo',

  'expense.modal.approval.text':
    'Você pode aprovar, reprovar ou então pedir a revisão dessa despesa, para que o solicitante possa corrigi-lá.',
  'expense.modal.pre-approval.text':
    'Você pode aprovar ou reprovar essa antecipação de orçamento!',
  'expense.comment-placeholder':
    'Escreva um comentário para que o solicitante possa entender o motivo dessa decisão, se desejar.',
  'expense.budget-total': 'de {budget}',

  'expense-item.refund.message': 'Esta transação foi estornada!',

  'refund.dashboard.cost-center': 'Centros de custo',
  'refund.dashboard.project': 'Projetos',
  'refund.dashboard.categories': 'Categorias',
  'refund.dashboard.users': 'Usuários',
  'refund.dashboard.vendors': 'Fornecedores',

  'refund.dashboard.empty.title': 'Nenhum item dessa categoria foi encontrado',
  'refund.dashboard.empty.description':
    'Tente aumentar o período definido ou adicionar novos orçamentos',

  'refund.card-type.physical': 'Físico',
  'refund.card-type.virtual': 'Virtual',
  'refund.card-type.personal': 'Pessoal',
  'refund.card-type.administrative': 'Administrativo',

  'refund.card.cards-used': 'Cartões utilizados',
  'refund.card.none-used': 'Nenhum cartão utilizado',
  'refund.card.single-used': '1 cartão utilizado',
  'refund.card.multiple-used': '{quantity} cartões utilizados',

  'refund.dashboard.single-item': '1 item',
  'refund.dashboard.multiple-items': '{quantity} itens',

  'refund.details.by-card': 'Exibir por cartões',
  'refund.details.by-budget': 'Exibir por orçamentos',

  'refund.action-btn.delete': 'Deletar recibo',
  'refund.action-btn.external-link': 'Abrir recibo',

  'budget.sort.title': 'Ordem',
  'budget.sort.description':
    'Selecione o tipo de ordenação desejado para os orçamentos desse cartão:',
  'budget.sort.ASC_DATE': 'Por data (ascendente)',
  'budget.sort.DESC_DATE': 'Por data (descendente)',
  'budget.sort.CHEAPEST': 'Mais barato',
  'budget.sort.EXPENSIVE': 'Mais caro',
  'budget.sort.STATUS': 'Por status',
  'budget.sort.NAME': 'Por nome',

  'budget.filter.title': 'Filtro',
  'budget.filter.description':
    'Selecione os status dos orçamentos que você deseja que sejam exibidos:',

  'budget.status.ACTIVE': 'Cartões ativos',
  'budget.status.ARCHIVED': 'Cartões arquivados',

  'budget.my-cards.activate-card': 'Ativar cartão',
  'budget.my-cards.activate-account': 'Ativar conta',
  'budget.my-cards.no-budgets.card':
    'Nenhum orçamento associado a esse cartão foi encontrado. Para adicionar novos, você deve ativar o cartão clicando no botão ao lado.',
  'budget.my-cards.no-budgets.account':
    'Nenhum orçamento associado a essa conta foi encontrado. Para adicionar novos, você deve ativar a conta clicando no botão ao lado.',
  'budget.my-cards.no-budgets':
    'Nenhum orçamento associado a essa conta foi encontrado.',
  'budget.my-cards.no-expenses':
    'Orçamentos sem despesas vinculadas não podem gerar relatório.',

  // Budget Name Tooltip
  'budget-name-tooltip.days.title': 'Dias',
  'budget-name-tooltip.days.description': `Para adicionar o dia do mês, utilize o cifrão seguido da letra 'd'. Existem algumas variações: a letra minúscula representa o dia do mês (número), já a maiúscula o dia da semana (por extenso):`,
  'budget-name-tooltip.days.result-base': 'Assinatura',
  'budget-name-tooltip.d.title': 'Assinatura $d',
  'budget-name-tooltip.D.title': 'Assinatura $D',
  'budget-name-tooltip.DD.title': 'Assinatura $DD',
  'budget-name-tooltip.months.title': 'Meses',
  'budget-name-tooltip.months.description': `Para adicionar o mês, utilize o cifrão seguido da letra 'm'. Existem três variações: a letra minúscula representa o mês como um número, já a maiúscula o nome do mês:`,
  'budget-name-tooltip.months.result-base': 'Assinatura',
  'budget-name-tooltip.m.title': 'Assinatura $m',
  'budget-name-tooltip.M.title': 'Assinatura $M',
  'budget-name-tooltip.MM.title': 'Assinatura $MM',
  'budget-name-tooltip.years.title': 'Anos',
  'budget-name-tooltip.years.description': `Para adicionar o ano, utilize o cifrão seguido da letra 'y' ou 'a'. Existe uma única variação: a letra minúscula representa o ano atual com dois dígitos. Já a repetição da mesma representa o ano atual com todos os dígitos:`,
  'budget-name-tooltip.years.result-base': 'Assinatura',
  'budget-name-tooltip.y.title': 'Assinatura $y',
  'budget-name-tooltip.Y.title': 'Assinatura $yy',
  'budget-name-tooltip.a.title': 'Assinatura $a',
  'budget-name-tooltip.A.title': 'Assinatura $aa',

  // Budget Recurrence
  'budget-recurrence.description':
    'Ao criar uma recorrência, um novo orçamento será criado no período escolhido. Seu nome, dias ativos, centro de custo e projeto serão mantidos. Além disso, todos os orçamentos deverão passar pela aprovação antes de serem ativados. Para nomes com variáveis, veja nosso guia',
  'budget-recurrence.interval.title': 'Em um intervalo específico',
  'budget-recurrence.interval.description':
    'Esse orçamento deve se repetir a cada',
  'budget-recurrence.interval.sufix.singular': 'dia',
  'budget-recurrence.interval.sufix.plural': 'dias',
  'budget-recurrence.day.title': 'Em um dia específico',
  'budget-recurrence.day.description':
    'Esse orçamento deve se repetir todo dia',
  'budget-recurrence.day.sufix.singular': 'de cada mês',
  'budget-recurrence.day.sufix.plural': 'de cada mês',

  'budget-recurrence.day.overflow':
    'Para meses que terminam antes do dia informado, o orçamento será criado no dia mais próximo daquele mês!',

  'budget-recurrence.creation.select-period':
    'Selecione o período do orçamento',
  'budget-recurrence.creation.interval':
    'Um orçamento de {period} dia(s) será criado de {days} em {days} dia(s)',
  'budget-recurrence.creation.date':
    'Um orçamento de {period} dia(s) será criado todo dia {days}',

  'budget-recurrence.time.today': 'Hoje',
  'budget-recurrence.time.tomorrow': 'Amanhã',
  'budget-recurrence.time.days': 'Faltam {days} dias',
  'budget-recurrence.desactivated.time.today': 'Desativado hoje',
  'budget-recurrence.desactivated.time.yesterday': 'Desativado ontem',
  'budget-recurrence.desactivated.time.days': 'Desativado há {days} dia(s)',

  // Recurrence budget
  'recurrence-budget.type.date': 'Todo dia {days}',
  'recurrence-budget.type.interval': 'De {days} em {days} dia(s)',
  'recurrence-budget.card': 'Cartão',
  'recurrence-budget.physical-card': 'Final {lastDigits}',

  'recurrence-budget.search': 'Buscar por orçamento',

  'recurrence-budget.empty.title':
    'Você não possui nenhum orçamento recorrente adicionado!',
  'recurrence-budget.empty.description':
    'Mas não se preocupe, você pode criar um definindo uma período de repetição na aba Recorrência ao criar um orçamento.',
  'recurrence-budget.empty-search.title':
    'Não encontrei nenhum orçamento recorrente com esse nome',
  'recurrence-budget.empty-search.description':
    'Verifique o nome desejado e tente novamente!',

  // Recurrence budget modal
  'recurrence-budget-modal.tabs.recurrence': 'Recorrência',
  'recurrence-budget-modal.tabs.history': 'Histórico',

  'recurrence-budget-modal.info.name': 'Nome',
  'recurrence-budget-modal.info.period': 'Perído',
  'recurrence-budget-modal.info.active-days': 'Dias ativos',
  'recurrence-budget-modal.info.active-days.description': '{days} dia(s)',
  'recurrence-budget-modal.info.card': 'Cartão',
  'recurrence-budget-modal.status.next-recorrence':
    'Próxima recorrência em {days} dia(s) ({date})',
  'recurrence-budget-modal.status.deactivated-at': 'Desativado no dia {date}',
  'recurrence-budget-modal.status.deactivated-at.disabled':
    'Desativado no dia {date} e não pode ser mais ativado: {message}',
  'recurrence-budget-modal.status.btn.activate': 'Reativar',
  'recurrence-budget-modal.status.btn.deactivate': 'Desativar',
  'recurrence-budget-modal.empty-history.title': 'Nenhuma recorrência criada',
  'recurrence-budget-modal.empty-history':
    'Nenhuma recorrência desse orçamento foi criada ainda, a primeira acontecerá no dia {date}.',

  // Administrative Budget Modal
  'administrative.payment-tab.description':
    'Escolha o tipo de pagamento que será efetuado na aprovação deste orçamento:',

  'administrative.payment-option.title': 'Tipo de pagamento',
  'administrative.payment-option.pix': 'PIX',
  'administrative.payment-option.boleto': 'Boleto',

  'administrative.boleto.number': 'Número do boleto',
  'administrative.boleto.payee': 'Beneficiário',
  'administrative.boleto.cnpj': 'Documento do beneficiário',
  'administrative.boleto.payer': 'Pagador',
  'administrative.boleto.payer-document': 'Documento',
  'administrative.boleto.due-date': 'Vencimento',
  'administrative.boleto.max-value': 'Valor máximo',
  'administrative.boleto.value': 'Valor a ser pago',
  'administrative.boleto.issuer': 'Emissor',
  'administrative.boleto.amount': 'Valor',
  'administrative.boleto.to-be-paid.amount': 'Valor do boleto',
  'administrative.boleto.to-be-paid.max-amount': 'Valor máximo de pagamento',
  'administrative.boleto.updated-amount': 'Valor com desconto',
  'administrative.boleto.paid-date': 'Data de pagamento',
  'administrative.boleto.auth-code': 'Código de autenticação',

  'administrative.boleto.error.fetch':
    'Não foi possível obter as informações desse boleto. Tente novamente!',

  'administrative.pix.error.invalid':
    'A chave informada não é válida. Revise-a e tente novamente!',
  'administrative.pix.error.fetch':
    'Não foi possível obter as informações dessa chave PIX. Tente novamente!',

  'administrative.pix.use-key': 'Utilizar chave',
  'administrative.pix.key': 'Chave PIX',
  'administrative.pix.value': 'Valor (R$)',
  'administrative.pix.description': 'Mensagem',

  'administrative.pix.type': 'Tipo da chave',
  'administrative.pix.bank-name': 'Instituição',
  'administrative.pix.tax-id': 'Documento',
  'administrative.pix.owner': 'Proprietário',
  'administrative.pix.branch-code': 'Agência',
  'administrative.pix.account-number': 'Conta',
  'administrative.pix.paid-date': 'Data de pagamento',
  'administrative.pix.auth-code': 'ID da transação',

  'administrative.pix.btn.CPF': 'Utilizar CPF',
  'administrative.pix.btn.CNPJ': 'Utilizar CNPJ',
  'administrative.pix.btn.EMAIL': 'Utilizar e-mail',
  'administrative.pix.btn.PHONE': 'Utilizar celular',
  'administrative.pix.btn.RANDOM': 'Utilizar chave aleatória',

  'administrative.creator-status.all-right': 'Tudo certo com seu pagamento!',
  'administrative.creator-status.select-payment':
    'Você precisa definir um tipo de pagamento!',
  'administrative.creator-status.select-total':
    'Você precisa definir um valor a ser pago!',

  // Administrative Expense Item
  'administrative-expense-item.not-paid': 'Pagamento ainda não efetuado',
  'administrative-expense-item.payment-info': 'Dados da despesa',
  'administrative-expense-item.paid-info': 'Dados do pagamento',

  // History item
  'history-item.status.success': 'Criado',
  'history-item.status.fail': 'Falha',

  // Recurrence budget history messages
  'recurrence-budget.history.msg.EXPENSE_CARD_ARCHIVED':
    'O cartão associado a esse orçamento foi arquivado',
  'recurrence-budget.history.msg.INVALID_BUDGET_VALUE':
    'O valor desse orçamento é inválido',
  'recurrence-budget.history.msg.MISSING_FIELDS':
    'Esse orçamento possui campos que foram deletados pela organização',

  // Status selector
  'status-selector.ACTIVE': 'Ativos',
  'status-selector.ARCHIVED': 'Arquivados',
  'status-selector.DEACTIVATED': 'Desativados',

  // Inbox
  'inbox.scan-email': 'Escanear e-mail',

  // Approvals
  'approval-orders.single': '1 análise pendente',
  'approval-orders.multiple': '{numOrders} aprovações pendentes',
  'approval-orders.none': 'Você não possui nenhuma aprovação pendente',
  'approval-orders.show': 'Mostrar mais',
  'approval-orders.hide': 'Esconder',

  /* 05.Pages */
  'pages.add-new': 'ADD NEW',
  'pages.add-new-modal-title': 'Add New Item',
  'pages.display-options': 'Display Options',
  'pages.orderby': 'Order By : ',
  'pages.product-name': 'Product Name',
  'pages.category': 'Category',
  'pages.description': 'Description',
  'pages.status': 'Status',
  'pages.cancel': 'Cancel',
  'pages.submit': 'Submit',
  'pages.delete': 'Delete',
  'pages.another-action': 'Another action',
  'pages.actions': 'ACTIONS',
  'pages.header': 'Header',
  'pages.details': 'DETAILS',
  'pages.orders': 'ORDERS',
  'pages.rating': 'Rating',
  'pages.price': 'Price',
  'pages.ingredients': 'Ingredients',
  'pages.is-vegan': 'Is Vegan',
  'pages.order-status': 'Order Status',
  'pages.bake-progress': 'Bake Progress',
  'pages.popularity': 'Popularity',
  'pages.comments': 'Comments',
  'pages.error-title': 'Ooops... looks like an error occurred!',
  'pages.error-code': 'Error code',
  'pages.go-back-home': 'GO BACK HOME',
  'pages.mailing-info':
    'Mailing templates use inline styling and table layout to be displayed well at various service providers. To provide better usability we include it as regular html with dangerouslySetInnerHTML.',
  'pages.invoice-info':
    'Invoice template has an inline styled version for usage outside of the project as well as React version.',
  'pages.react-version': 'React Version',
  'pages.inline-version': 'Inline Styled Html Version',

  /* Expense card */
  'expense-card-modal.no-category-balance':
    'Nenhuma categoria possui saldo disponível!',

  'expense-card-modal.change-password': 'Mudar senha',
  'expense-card-modal.unassign-user': 'Desvincular usuário',
  'expense-card-modal.assign-user': 'Vincular usuário',
  'expense-card-modal.block-card': 'Bloquear cartão',
  'expense-card-modal.unblock-card': 'Desbloquear cartão',

  'expense-card-modal.current-balance': 'Saldo atual',
  'expense-card-modal.category-balance': 'Saldo por categoria',

  'expense-card-modal.choose-user':
    'Escolha um usuário para associar a este cartão:',
  'expense-card-modal.assign': 'Vincular',

  /* 06.Date Selection */
  'dateSelection.lifetime': 'Vitalício',
  'dateSelection.pastMonth': 'Mês Passado',
  'dateSelection.today': 'Hoje',
  'dateSelection.yesterday': 'Ontem',
  'dateSelection.past7Days': 'Últimos 7 dias',
  'dateSelection.past14Days': 'Últimos 14 dias',
  'dateSelection.past28Days': 'Últimos 28 dias',
  'dateSelection.thisMonth': 'Este mês',

  /* 06.01.Chat */
  'chat.messages': 'Messages',
  'chat.contacts': 'Contacts',
  'chat.saysomething': 'Say something..',

  /* 06.02.Survey */
  'survey.delete': 'Delete',
  'survey.edit': 'Edit',
  'survey.add-new': 'ADD NEW',
  'survey.add-new-title': 'Add New Survey',
  'survey.title': 'Title',
  'survey.category': 'Category',
  'survey.label': 'Label',
  'survey.status': 'Status',
  'survey.cancel': 'Cancel',
  'survey.submit': 'Submit',
  'survey.another-action': 'Another action',
  'survey.display-options': 'Display Options',
  'survey.orderby': 'Order By : ',
  'survey.all-surveys': 'All Surveys',
  'survey.completed-surveys': 'Completed Surveys',
  'survey.categories': 'Categories',
  'survey.active-surveys': 'Active Surveys',
  'survey.labels': 'Labels',

  /* 06.03.Todo */
  'todo.add-new': 'ADD NEW',
  'todo.add-new-title': 'Add New Todo',
  'todo.title': 'Title',
  'todo.detail': 'Detail',
  'todo.category': 'Category',
  'todo.label': 'Label',
  'todo.status': 'Status',
  'todo.cancel': 'Cancel',
  'todo.submit': 'Submit',
  'todo.action': 'Action',
  'todo.another-action': 'Another action',
  'todo.display-options': 'Display Options',
  'todo.orderby': 'Order By : ',
  'todo.all-tasks': 'All Tasks',
  'todo.pending-tasks': 'Pending Tasks',
  'todo.completed-tasks': 'Completed Tasks',
  'todo.categories': 'Categories',
  'todo.labels': 'Labels',

  /* 07.UI */

  /* 07.01.Alerts */
  'alert.rounded': 'Rounded Alert',
  'alert.react-notifications': 'React Notifications',
  'alert.outline': 'Outline',
  'alert.primary': 'Primary',
  'alert.secondary': 'Secondary',
  'alert.info': 'Info',
  'alert.success': 'Success',
  'alert.warning': 'Warning',
  'alert.error': 'Error',
  'alert.filled': 'Filled',
  'alert.primary-text': 'This is a primary alert—check it out!',
  'alert.secondary-text': 'This is a secondary alert—check it out!',
  'alert.success-text': 'This is a success alert—check it out!',
  'alert.danger-text': 'This is a danger alert—check it out!',
  'alert.warning-text': 'This is a warning alert—check it out!',
  'alert.info-text': 'This is a info alert—check it out!',
  'alert.light-text': 'This is a light alert—check it out!',
  'alert.dark-text': 'This is a dark alert—check it out!',
  'alert.default': 'Default Alert',
  'alert.dismissing': 'Dismissing Alert',
  'alert.dismissing-text':
    'Holy guacamole! You should check in on some of those fields below.',
  'alert.dismissing-without-animate-text':
    'I am an alert and I can be dismissed without animating!',

  /* 07.02.Badges */
  'badge.sizes': 'Sizes',
  'badge.colors': 'Colors',
  'badge.outline': 'Outline',
  'badge.links': 'Links',
  'badge.counter-badges': 'Counter Badges',
  'badge.bootstrap-default': 'Bootstrap Default',
  'badge.primary': 'Primary',
  'badge.secondary': 'Secondary',
  'badge.success': 'Success',
  'badge.danger': 'Danger',
  'badge.warning': 'Warning',
  'badge.info': 'Info',
  'badge.light': 'Light',
  'badge.dark': 'Dark',

  /* 07.03.Buttons */
  'button.default': 'Bootstrap Default',
  'button.colors': 'Colors',
  'button.rounded': 'Rounded',
  'button.outline': 'Outline',
  'button.states': 'States',
  'button.sizes': 'Sizes',
  'button.button-groups': 'Button Groups',
  'button.large-button': 'Large Button',
  'button.small-button': 'Small Button',
  'button.extra-small-button': 'Extra Small Button',
  'button.block-button': 'Block Button',
  'button.active': 'Active',
  'button.disabled': 'Disabled',
  'button.basic': 'Basic',
  'button.toolbar': 'Toolbar',
  'button.nesting': 'Nesting',
  'button.vertical-variation': 'Vertical Variation',
  'button.checkbox-radio-button': 'Checkbox and Radio Button',
  'button.checkbox': 'Checkbox',
  'button.radio': 'Radio',
  'button.radio-button': 'Radio Button',
  'button.primary': 'Primary',
  'button.secondary': 'Secondary',
  'button.success': 'Success',
  'button.danger': 'Danger',
  'button.warning': 'Warning',
  'button.info': 'Info',
  'button.light': 'Light',
  'button.dark': 'Dark',
  'button.states-text':
    'This button shows a spinner for 2 seconds and an error icon for 3 seconds before switching to normal state. This states can be triggered by adding and removing classes.',
  'button.click-here': 'Click Here',
  'button.states-text-alternate':
    'This button shows a spinner for 2 seconds and an error icon for 3 seconds before switching to normal state. This states can be triggered by adding and removing classes.',
  'button.primary-link': 'Primary Link',
  'button.link': 'Link',
  'button.primary-button': 'Primary Button',
  'button.button': 'Button',
  'button.left': 'Left',
  'button.middle': 'Middle',
  'button.right': 'Right',
  'button.dropdown': 'Dropdown',
  'button.dropdown-link': 'Dropdown Link',

  'button.approval.ask-review': 'Pedir revisão',
  'button.approval.approve': 'Aprovar',
  'button.approval.approve-now': 'Aprovar imediatamente',
  'button.approval.approve-now.description':
    'Aprove esse orçamento imediatamente, sem a necessidade da aprovação dos outros aprovadores!',
  'button.approval.reprove': 'Reprovar',

  'refund.approval.title': 'Aprovar orçamento',

  'refund.location-message': 'Busque por usuários',
  'refund.location-searching': 'Buscando usuários...',

  /* 07.04.Cards */
  'cards.icon-card': 'Icon Card',
  'cards.image-card': 'Image Card',
  'cards.image-overlay-card': 'Image Overlay Card',
  'cards.image-card-list': 'Image Card List',
  'cards.tab-card': 'Tab Card',
  'cards.user-card': 'User Card',

  /* 07.05.Carousel */
  'carousel.basic': 'Carousel Basic',
  'carousel.single': 'Carousel Single',
  'carousel.without-controls': 'Carousel Without Controls',

  /* 07.06.Charts */
  'charts.line': 'Line Chart',
  'charts.polar': 'Polar Chart',
  'charts.area': 'Area Chart',
  'charts.scatter': 'Scatter Chart',
  'charts.bar': 'Bar Chart',
  'charts.radar': 'Radar Chart',
  'charts.pie': 'Pie Chart',
  'charts.doughnut': 'Doughnut Chart',
  'charts.shadow': 'Shadow',
  'charts.no-shadow': 'No Shadow',

  /* 07.07.Collapse */
  'collapse.basic': 'Basic',
  'collapse.toggle': 'Toggle',
  'collapse.accordion': 'Accordion',
  'collapse.controlled': 'Controlled',
  'collapse.uncontrolled': 'Uncontrolled',

  /* 07.08.Dropdowns */
  'dropdowns.basic': 'Basic',
  'dropdowns.controlled': 'Controlled',
  'dropdowns.uncontrolled': 'Uncontrolled',
  'dropdowns.dropdown': 'Dropdown',
  'dropdowns.header': 'Header',
  'dropdowns.action': 'Action',
  'dropdowns.another-action': 'Another Action',
  'dropdowns.right': 'Right',
  'dropdowns.left': 'Left',
  'dropdowns.drop-directions': 'Drop Directions',
  'dropdowns.dropright': 'Dropright',
  'dropdowns.dropleft': 'Dropleft',
  'dropdowns.small-button': 'Small Button',
  'dropdowns.large-button': 'Large Button',
  'dropdowns.sizing': 'Sizing',
  'dropdowns.split-button': 'Split Button Dropdowns',
  'dropdowns.dropup': 'Dropup',

  /* 07.09.Editors */
  'editors.draftjs': 'Draft.js',
  'editors.quill-standart': 'Quill Standard',
  'editors.quill-bubble': 'Quill Bubble',

  /* 07.10.Forms */
  'forms.action.skip': 'Pular',
  'forms.action.have_code': 'Já tenho um código',
  'forms.action.register': 'REGISTRAR',
  'forms.action.login': 'LOGIN',
  'forms.action.reset': 'RESETAR',
  'forms.action.change': 'ALTERAR',
  'forms.action.save': 'SALVAR',
  'forms.action.delete': 'REMOVER',
  'forms.action.invite': 'CONVIDAR',
  'forms.action.next': 'PRÓXIMO',
  'forms.action.create': 'CADASTRAR',

  /* 07.10.Forms Validation */
  'forms.validation.email.valid': 'E-mail não é válido',
  'forms.validation.email.required': 'E-mail obrigatório',
  'forms.validation.email.already-exists': 'E-mail já cadastrado',
  'forms.validation.password.required': 'Senha obrigatória',
  'forms.validation.password.weak': 'Senha deve conter ao menos 8 caracteres',
  'forms.validation.password.confirmation.required':
    'Confirmação não é igual a senha',
  'forms.validation.code.required': 'Código obrigatório',
  'forms.validation.firstName.required': 'Nome obrigatório',
  'forms.validation.firstName.invalid': 'Nome inválido',
  'forms.validation.lastName.required': 'Sobrenome obrigatório',
  'forms.validation.lastName.invalid': 'Sobrenome inválido',
  'forms.validation.phone.invalid': 'Número de telefone inválido',
  'forms.validation.gender.valid': 'Gênero inválido',
  'forms.validation.gender.required': 'Gênero obrigatório',
  'forms.validation.birthdate.valid': 'Data de nascimento inválida',
  'forms.validation.birthdate.required': 'Data de nascimento obrigatório',
  'forms.validation.rg.number.required': 'Identidade obrigatória',
  'forms.validation.rg.emitter.required': 'Órgão Emissão obrigatório',
  'forms.validation.cpf.required': 'CPF obrigatório',
  'forms.validation.cpf.number.valid': 'CPF inválido',
  'forms.validation.identity.number.valid': 'Identidade inválida',
  'forms.validation.rg.number.valid': 'RG inválido',
  'forms.validation.rg.number.empty': 'Número do RG necessário!',
  'forms.validation.emitter.number.empty': 'Orgão emissor é necessário!',
  'forms.validation.cnpj.required': 'CNPJ obrigatório',
  'forms.validation.cnpj.number.valid': 'CNPJ inválido',
  'forms.validation.cnh.validUntil.valid': 'Data inválida',
  'forms.validation.name.required': 'Nome obrigatório',
  'forms.validation.cardName.required': 'Nome obrigatório',
  'forms.validation.documentNumber.required': 'Documento obrigatório',
  'forms.validation.cardNumber.required': 'Número do Cartão obrigatório',
  'forms.validation.cardNumber.invalid': 'Número inválido',
  'forms.validation.cardNumber.invalid.length':
    'Número deve conter 15 ou 16 dígitos',
  'forms.validation.cardExpiry.valid': 'Data de Expiração inválida',
  'forms.validation.cardExpiry.required': 'Data de Expiração obrigatória',
  'forms.validation.creditCard.genericError':
    'Um erro aconteceu para salvar o cartão, tente novamente ou se o problema persistir, chame o nosso time de suporte.',
  'forms.validation.cvv.valid': 'CVV inválido',
  'forms.validation.priority.valid': 'Prioridade inválida',
  'forms.validation.cvv.required': 'CVV obrigatório',
  'forms.validation.priority.required': 'Prioridade obrigatória',
  'forms.validation.clientId.required': 'Informação obrigatória',
  'forms.validation.clientSecret.required': 'Informação obrigatória',
  'forms.validation.document.expired': 'Documento expirado',
  'forms.validation.phone.required': 'Telefone obrigatório',
  'forms.validation.phone.valid': 'Telefone inválido',
  'forms.validation.legalName.required': 'Razão Social obrigatória',
  'forms.validation.tradingName.required': 'Nome Fantasia obrigatório',
  'forms.validation.foundationDate.required': 'Data da Fundação obrigatória',
  'forms.validation.postalCode.valid': 'CEP inválido',
  'forms.validation.postalCode.required': 'CEP obrigatório',
  'forms.validation.street.required': 'Endereço obrigatório',
  'forms.validation.streetNumber.required': 'Número obrigatório',
  'forms.validation.neighborhood.required': 'Bairro obrigatório',
  'forms.validation.city.required': 'Cidade obrigatória',
  'forms.validation.uf.required': 'Estado obrigatório',
  'forms.validation.uf.invalid': 'Estado incorreto',
  'forms.validation.passport.number.valid': 'Número do passaporte inválido',
  'forms.validation.passport.validUntil.valid': 'Data inválida',

  'forms.validation.flight-company.required': 'Companhia aérea obrigatória',
  'forms.validation.fidelity-number.required':
    'Número de fidelidade obrigatório',
  'forms.validation.fidelity-number.invalid': 'Número de fidelidade inválido',
  'forms.validation.fidelity-name.required': 'Nome do programa obrigatório',
  'forms.validation.fidelity-name.invalid': 'Nome do programa inválido',

  'forms.validation.required': 'Campo obrigatório',
  'forms.validation.pixtype.invalid': 'Selecione uma opção válida',
  'forms.validation.pix.invalid': 'Insira uma chave pix válida',

  'forms.basic': 'Basic',
  'forms.email': 'E-mail',
  'forms.email-muted': "We'll never share your email with anyone else.",
  'forms.password': 'Password',
  'forms.submit': 'Submit',
  'forms.horizontal': 'Horizontal',
  'forms.radios': 'Radios',
  'forms.first-radio': 'First radio',
  'forms.second-radio': 'Second radio',
  'forms.third-radio-disabled': 'Third disabled radio',
  'forms.checkbox': 'Checkbox',
  'forms.signin': 'Sign in',
  'forms.top-labels-over-line': 'Top Labels Over Line',
  'forms.tags': 'Tags',
  'forms.date': 'Date',
  'forms.state': 'State',
  'forms.top-labels-in-input': 'Top Labels In Input',
  'forms.email-u': 'E-MAIL',
  'forms.password-u': 'PASSWORD',
  'forms.tags-u': 'TAGS',
  'forms.date-u': 'DATE',
  'forms.state-u': 'STATE',
  'forms.grid': 'Form Grid',
  'forms.address': 'Address',
  'forms.address2': 'Address 2',
  'forms.city': 'City',
  'forms.city-message': 'Please select a city!',
  'forms.state-message': 'Please select a state!',
  'forms.zip': 'Zip',
  'forms.signup': 'Signup',
  'forms.inline': 'Inline',
  'forms.validation-availity': 'Availity Reactstrap Validation',
  'forms.validation-formik': 'Formik Validation',
  'forms.default': 'Default',
  'forms.firstname': 'First name',
  'forms.firstname-message': 'Please enter your first name!',
  'forms.lastname': 'Last name',
  'forms.lastname-message': 'Please enter your last name!',

  /* 07.11.Form Components */
  'form-components.custom-inputs': 'Custom Inputs',
  'form-components.checkboxes': 'Checkboxes',
  'form-components.radios': 'Radios',
  'form-components.inline': 'Inline',
  'form-components.react-select': 'React Select',
  'form-components.state-single': 'State Single',
  'form-components.state-multiple': 'State Multiple',
  'form-components.date-picker': 'Date Picker',
  'form-components.date': 'Date',
  'form-components.date-range': 'Date Range',
  'form-components.date-with-time': 'Date with Time',
  'form-components.embedded': 'Embedded',
  'form-components.dropzone': 'Dropzone',
  'form-components.drop-files-here': 'Drop Files Here',
  'form-components.tags': 'Tags',
  'form-components.switch': 'Switch',
  'form-components.primary': 'Primary',
  'form-components.secondary': 'Secondary',
  'form-components.primary-inverse': 'Primary Inverse',
  'form-components.secondary-inverse': 'Secondary Inverse',
  'form-components.slider': 'Slider',
  'form-components.double-slider': 'Double Slider',
  'form-components.single-slider': 'Single Slider',
  'form-components.rating': 'Rating',
  'form-components.interactive': 'Interactive',
  'form-components.readonly': 'Readonly',
  'form-components.type-a-cake': 'Type a Cake',
  'form-components.start': 'Start',
  'form-components.end': 'End',
  'form-components.tables': 'Tables',
  /* 07.12.Icons */
  'icons.simplelineicons': 'Simple Line Icons',
  'icons.iconsmind': 'Iconsmind Icons',

  /* 07.13.Input Groups */
  'input-groups.basic': 'Basic',
  'input-groups.sizing': 'Sizing',
  'input-groups.small': 'Small',
  'input-groups.default': 'Default',
  'input-groups.large': 'Large',
  'input-groups.checkboxes-and-radios': 'Checkboxes and radios',
  'input-groups.multiple-inputs': 'Multiple Inputs',
  'input-groups.first-and-last-name': 'First and last name',
  'input-groups.multiple-addons': 'Multiple Addons',
  'input-groups.button-addons': 'Button Addons',
  'input-groups.button': 'Button',
  'input-groups.buttons-with-dropdowns': 'Buttons with Dropdowns',
  'input-groups.dropdown': 'Dropdown',
  'input-groups.header': 'Header',
  'input-groups.action': 'Action',
  'input-groups.another-action': 'Another Action',
  'input-groups.custom-select': 'Custom Select',
  'input-groups.options': 'Options',
  'input-groups.choose': 'Choose...',
  'input-groups.custom-file-input': 'Custom File Input',

  /* 07.14.Jumbotron */
  'jumbotron.hello-world': 'Hello, world!',
  'jumbotron.lead':
    'This is a simple hero unit, a simple jumbotron-style component for calling extra attention to featured content or information.',
  'jumbotron.lead-detail':
    'It uses utility classes for typography and spacing to space content out within the larger container.',
  'jumbotron.learn-more': 'Learn more',

  /* 07.15.Modal */
  'modal.basic': 'Basic',
  'modal.modal-title': 'Modal title',
  'modal.launch-demo-modal': 'Launch Demo Modal',
  'modal.scrolling-long-content': 'Scrolling Long Content',
  'modal.backdrop': 'Backdrop',
  'modal.backdrop-value': 'Backdrop value',
  'modal.right-modal': 'Right Modal',
  'modal.launch-right-modal': 'Launch Right Modal',
  'modal.nested-modal': 'Nested Modal',
  'modal.size': 'Size',
  'modal.launch-large-modal': 'Launch Large Modal',
  'modal.launch-small-modal': 'Launch Small Modal',

  'dialog.approval.approve.title': 'Aprovar Orçamento',
  'dialog.approval.reject.title': 'Reprovar Orçamento',
  'dialog.approval.review.title': 'Revisar Orçamento',

  'dialog.approval.approve.msg':
    'Tem certeza que deseja aprovar este orçamento?',
  'dialog.approval.reject.msg':
    'Tem certeza que deseja reprovar este orçamento?',
  'dialog.approval.review.msg':
    'Tem certeza que deseja pedir uma revisão deste orçamento?',
  'dialog.approval.review.msg.changed':
    'Você rejeitou {rejected} item(s) e escreveu {comments} comentário(s).',
  'dialog.approval.approve.msg.changed':
    'Você rejeitou {rejected} item(s) e o novo total da solicitação é de {total}.',

  /* 07.16.Navigation */
  'nav.basic': 'Nav Basic',
  'nav.active': 'Active',
  'nav.disabled': 'Disabled',
  'nav.disabled-link': 'Disabled Link',
  'nav.link': 'Link',
  'nav.longer-link': 'Longer nav link',
  'nav.another-link': 'Another Link',
  'nav.right': 'Right',
  'nav.dropdown': 'Dropdown',
  'nav.header': 'Header',
  'nav.action': 'Action',
  'nav.another-action': 'Another Action',
  'nav.horizontal-alignment': 'Nav Horizontal Alignment',
  'nav.vertical-alignment': 'Nav Vertical Alignment',
  'nav.pills': 'Nav Pills',
  'nav.fill-justify': 'Nav Fill and Justify',
  'nav.pills-dropdowns': 'Nav Pills with Dropdowns',
  'nav.pagination-basic': 'Pagination Basic',
  'nav.pagination-sizing': 'Pagination Sizing',
  'nav.large': 'Large',
  'nav.small': 'Small',
  'nav.center': 'Center',
  'nav.pagination-alignment': 'Pagination Alignment',
  'nav.breadcrumb-basic': 'Breadcrumb Basic',

  /* 07.17.Popover & Tooltip */
  'popover-tooltip.popover': 'Popover',
  'popover-tooltip.tooltip': 'Tooltip',

  /* 07.18.Sortable */
  'sortable.columns': 'Sorting Columns',
  'sortable.basic': 'Basic',
  'sortable.handles': 'Handles',

  /* 07.19.Maps */
  'maps.google': 'Google',
  'maps.yandex': 'Yandex',

  /* 07.20.Tables */
  'table.bootstrap-tables': 'Bootstrap Tables',
  'table.bootstrap-basic': 'Basic Table',
  'table.bootstrap-striped': 'Striped Rows',
  'table.bootstrap-bordered': 'Bordered Table',
  'table.bootstrap-borderless': 'Borderless Table',
  'table.bootstrap-hoverable': 'Hoverable Rows',
  'table.bootstrap-responsive': 'Responsive Table',
  'table.react-pagination': 'Pagination',
  'table.react-scrollable': 'Scrollable',
  'table.react-advanced': 'Filter, Length and Jump',

  'error.approval-info.credit-card.register':
    'Você precisa cadastrar um cartão antes de continuar',
  'error.approval-info.credit-card.error':
    'Não foi possível buscar pelos cartões desta organização. Tente novamente!',
  'error.approval-info.credit-card.select':
    'Selecione um cartão e a quantidade de parcelas',
  'error.approval-info.error': 'Não foi possível executar esta ação',

  // 08. Components
  'components.timeline.modal.reservation-data': 'Dados da Reserva',
  'components.timeline.modal.conditions': 'Descrição e condições',
  'components.flight.airportwait.wait':
    'espera longa de {hours} em {city} ({station})',

  'components.flight.baggages.bags': 'Mochila ou bolsa',
  'components.flight.baggages.fit.below':
    'Deve caber embaixo do assento dianteiro.',
  'components.flight.baggages.handluggage': 'Bagagem de mão',
  'components.flight.baggages.fit.above':
    'Deve caber no compartimento superior do avião.',
  'components.flight.baggages.fit.above.no': 'Não inclusa.',
  'components.flight.baggages.dispatch': 'Bagagem para despachar',
  'components.flight.baggages.included':
    'Incluso {baggageQuantity} de até {baggageWeight}. O despache é feito durante o check-in no aeroporto.',

  'components.bus.baggages.handluggage': 'Bagagem de mão',
  'components.bus.baggages.fit.above':
    'Deve caber no compartimento superior do ônibus, o porta-embrulhos.',
  'components.bus.baggages.dispatch': 'Bagagem para despachar',
  'components.bus.baggages.included':
    'Incluso {baggageQuantity} de até {baggageWeight} que deverá ser despachada no bagageiro e não pode ultrapassar as medidas 100cm x 50cm x 60cm.',

  'components.bus.description':
    'Deverá realizar a troca do voucher pelo bilhete munido dos documentos do passageiro • Os horários de embarque são apenas uma previsão • O cancelamento poderá ser feito até 5 horas antes do horário de embarque pela nossa central de suporte • Para bilhetes já impressos, você deve consultar a empresa de ônibus diretamente para efetuar o cancelamento • Após a data de embarque, fica a critério da empresa de ônibus realizar ou não o cancelamento das passagens • Nos termos da ANTT, fica a transportadora autorizada a reter até 5% (cinco por cento) do valor total pago pela passagem rodoviária',

  'components.navs.user-dropdown.profile': 'Perfil',
  'components.navs.user-dropdown.logout': 'Logout',

  'components.duplicity.title': 'Duplicidade de reserva',
  'components.duplicity.loading':
    'Verificando se há reservas emitidas parecidas com esta nesse mesmo período...',
  'components.duplicity.none':
    'Não existem outras reservas que acontecem no mesmo dia que esta!',
  'components.duplicity.has-duplicity':
    'Identifiquei uma reserva que acontece no mesmo período desta',
  'components.duplicity.show-duplicate': 'ir até a reserva',

  // 09. Containers
  'containers.active.crisisreport.no-traveler': 'Nenhum viajante impactado',
  'containers.active.crisisreport.emergency': 'Situações de Emergência',

  'containers.active.details.gate': 'Portão',

  'containers.dashboard.adoption-card.title': 'Adoção',
  'containers.dashboard.adoption-card.registered-travelers':
    'Viajantes Cadastrados',
  'containers.dashboard.adoption-card.amount-travelers':
    'Qtd. total de viajantes',
  'containers.dashboard.adoption-card.active-travelers': 'Viajantes Ativos',
  'containers.dashboard.adoption-card.with-travel': 'Viajantes com viagens',

  'containers.dashboard.card.summary': 'Resumo',

  'containers.dashboard.flight-card.title': 'Voos',
  'containers.dashboard.flight-card.best-stretch': 'Top Trechos',
  'containers.dashboard.flight-card.best-companies': 'Top Companhias',

  'containers.dashboard.card-summary.total-reservations': 'Total de Reservas',
  'containers.dashboard.card-summary.reservations-per-period':
    'Quantidade de reservas no período',

  'containers.dashboard.flight-card-summary.total-spent': 'Gasto Total',
  'containers.dashboard.flight-card-summary.flight-total': 'Total em aereo',
  'containers.dashboard.flight-card-summary.average-savings': 'Economia Média',
  'containers.dashboard.flight-card-summary.help-saving':
    'Quanto ajudamos a economizar',
  'containers.dashboard.flight-card-summary.average-fare': 'Tarifa média',
  'containers.dashboard.flight-card-summary.fare-average-value':
    'Valor médio de tarifa por reserva',

  'containers.dashboard.flight-card-top-airlines.stretches': 'trechos',

  'containers.dashboard.hotel-card.title': 'Hotéis',
  'containers.dashboard.hotel-card.best-hotels': 'Top Hotéis',
  'containers.dashboard.hotel-card.best-companies': 'Top Marcas',
  'containers.dashboard.hotel-card.best-cities': 'Top Cidades',

  'containers.dashboard.hotel-card-summary.total-spent': 'Gasto Total',
  'containers.dashboard.hotel-card-summary.total-daily': 'Total em diárias',
  'containers.dashboard.hotel-card-summary.average-savings': 'Economia Média',
  'containers.dashboard.hotel-card-summary.help-saving':
    'Quanto ajudamos a economizar',
  'containers.dashboard.hotel-card-summary.average-daily': 'Diária média',
  'containers.dashboard.hotel-card-summary.average-daily-value':
    'Valor médio por diária',

  'containers.dashboard.car-card.title': 'Carros',
  'containers.dashboard.car-card.best-companies': 'Top Companhias',

  'containers.dashboard.bus-card.title': 'Ônibus',
  'containers.dashboard.bus-card.best-stretch': 'Top Trechos',
  'containers.dashboard.bus-card.best-companies': 'Top Companhias',

  'containers.dashboard.small-line-bookings.reservations': 'Reservas',
  'containers.dashboard.small-line-economy.economy': 'Economia',
  'containers.dashboard.small-line-spent.total-spent': 'Total Gasto',

  'containers.dashboard.support-card.title': 'Suporte',
  'containers.dashboard.support-card.average-waiting-time':
    'Tempo médio de espera',
  'containers.dashboard.support-card.average-waiting-time-abbreviation': 'TME',
  'containers.dashboard.support-card.number-conversations':
    'Número de conversas',
  'containers.dashboard.support-card.total-chats': 'Total de chats realizados',
  'containers.dashboard.support-card.feeling': 'Sentimento',
  'containers.dashboard.support-card.suport-rating': 'Avaliação do atendimento',

  'containers.esg.card.flight': 'Voos',
  'containers.esg.card.car': 'Carros',
  'containers.esg.card.bus': 'Ônibus',

  'containers.navs.top-nav.my-travels': 'Viagens',
  'containers.navs.top-nav.refunds': 'Contas a pagar',
  'containers.navs.top-nav.data': 'Dados',
  'containers.navs.top-nav.admin': 'Admin',

  'containers.policy.group-data-list-view.users': 'usuário(s)',

  'containers.search.modal.fees': 'Impostos e taxas já inclusos.',
  'containers.search.modal.taxes': 'Impostos já inclusos.',
  'containers.search.modal.taxes.payment-at-destination':
    'Sujeito a pagamento de parte do valor na hora da entrega do veículo',
  'containers.search.modal.book-now': 'Reservar agora',
  'conatiner.issue.with.credit': 'Emitir passagem com crédito',

  'containers.search.car-results.title': 'Categoria do veículo',
  'containers.search.car-results.features': 'Características',
  'containers.search.car-results.rentals': 'Locadoras',

  'containers.search.flight-filters.prices': 'Preços',
  'containers.search.flight-filters.duration': 'Duração (h)',
  'containers.search.flight-filters.no-stop': 'Direto',
  'containers.search.flight-filters.stop': 'Parada',
  'containers.search.flight-filters.stops': 'Paradas',
  'containers.search.flight-filters.flight-companies': 'Cias Aéreas',
  'containers.search.flight-filters.seat-classes': 'Poltronas',
  'containers.search.flight-filters.departure': 'Horário de partida',
  'containers.search.flight-filters.outbound-connections': 'Conexões na Ida',
  'containers.search.flight-filters.inbound-connections': 'Conexões na Volta',
  'containers.search.flight-filters.connections': 'Conexões',

  'containers.search.bus-filters.companies': 'Companhias',

  'containers.search.flight-modal.taxes': '(+) {taxes} de taxa de serviço',
  'containers.search.flight-modal.credit':
    '(-) Créditos disponíveis do bilhete {ticket}',
  'containers.search.flight-modal.reservation':
    'Reservar para {numAdults} {adults}',
  'containers.search.flight-modal.valid-until': 'Reserva válida até {date}',
  'containers.search.flight-modal.baggages-not-included':
    'Não inclui bagagem para despachar.',
  'containers.search.flight-modal.cpf-birthdate':
    'O passageiro não adicionou CPF ou data de nascimento em sua conta!',

  'containers.search.hotel-filters.price': 'Preços',
  'containers.search.hotel-filters.hotel-name': 'Nome do Hotel',
  'containers.search.hotel-filters.category': 'Categoria',
  'containers.search.hotel-filters.no-ratings': 'Sem classificação',
  'containers.search.hotel-filters.amenities': 'Amenidades',

  'containers.search.hotel-modal.breakfast-included': 'Café da manhã incluso',
  'containers.search.hotel-modal.select-room':
    'Selecione um quarto para reservar',
  'containers.search.hotel-modal.room-category': 'Categoria do quarto',
  'containers.search.hotel-modal.main-amenities': 'Principais comodidades',
  'containers.search.hotel-modal.meal': 'Alimentação',
  'containers.search.hotel-modal.price-total': 'Preço (total)',
  'containers.search.hotel-modal.hotel-description': 'Descrição do hotel',
  'containers.search.hotel-modal.reservation-policies': 'Políticas da reserva',

  'containers.search.office-modal.select-room':
    'Selecione uma sala para reservar',
  'containers.search.office-modal.room-name': 'Tipo',
  'containers.search.office-modal.room-size': 'Qtd. Pessoas',
  'containers.search.office-modal.price-total': 'Preço (total)',
  'containers.search.office-modal.office-description': 'Descrição do espaço',

  'containers.search.hotel-proximity-search.address-placeholder':
    'Endereços, locais...',

  'containers.search.package-items.cheaper-option': 'Opção mais barata',
  'containers.search.package-items.stay-longer': 'Mais tempo no destino',
  'containers.search.package-items.arive-early': 'Chegue mais cedo',

  'containers.search.package-search.change-flight': 'Altere o voo abaixo',
  'containers.search.package-search.choose-another-flight':
    'Escolher outro voo',
  'containers.search.package-search.change-hotel': 'Altere o hotel abaixo',
  'containers.search.package-search.choose-another-hotel':
    'Escolher outro hotel',

  'containers.search.reservation-params.company-travel': 'Viagem pela empresa',
  'containers.search.reservation-params.personal-travel': 'Viagem pessoal',

  'containers.timeline.timeline.hello': 'Olá, {name}!',
  'containers.timeline.timeline.next-trips':
    'Acompanhe abaixo as<br/>próximas viagens.',
  'containers.timeline.timeline.add-trip':
    'Adicione uma reserva e comece<br/>a montar viagem.',
  'containers.timeline.timeline.app': 'Use também o nosso aplicativo',

  'containers.users.data-list-view.incomplete-registration':
    'Cadastro incompleto',
  'containers.users.data-list-view.admin': 'Administrador',
  'containers.timeline.timeline.add-refund':
    'Adicione uma despesa e comece<br/>a montar seus orçamentos.',
  'containers.timeline.timeline.next-refunds':
    'Acompanhe abaixo as<br/>suas despesas.',
  'containers.timeline.timeline.available-refunds':
    'Arraste os orçamentos<br/>que você deseja adicionar.',

  'containers.users.user-modal.profile': 'Perfil',
  'containers.users.user-modal.permissions': 'Permissões',
  'containers.users.user-modal.credit-card': 'Cartões de Crédito',
  'containers.users.user-modal.fidelity': 'Programas de Fidelidade',

  'containers.users.user-modal-credit-card.registered-cards':
    'Cartões de Crédito',
  'containers.users.user-modal-credit-card.new-card': 'Novo Cartão',

  'containers.users.user-modal-profile-tab.personal-data': 'Dados Pessoais',
  'containers.users.user-modal-profile-tab.company': 'Empresa',
  'containers.users.user-modal-profile-tab.documents': 'Documentos',
  'containers.users.user-modal-profile-tab.passport': 'Passaporte',

  'containers.users.user-modal-permissions-tab.admin': 'É administrador',
  'containers.users.user-modal-permissions-tab.data.access':
    'Pode ver a aba de Dados',
  'containers.users.user-modal-permissions-tab.data.budget': 'Contas a pagar',
  'containers.users.user-modal-permissions-tab.data.travel': 'Viagens',
  'containers.users.user-modal-permissions-tab.esg': 'ESG',
  'containers.users.user-modal-permissions-tab.data.statement': 'Extrato',
  'containers.users.user-modal-permissions-tab.timeline':
    'Pode ver a timeline de outros passageiros',
  'containers.users.user-modal-permissions-tab.budgets':
    'Pode ver os orçamentos de outros passageiros',

  'containers.version.version-card':
    'Hey! Acabou de sair uma nova versão do web app. Atualize a página ou clique no botão abaixo para aproveitar a nova versão:',

  'containers.version.version-card.button': 'Atualizar',

  // 10. Views
  'permission-toggle-fragment.description':
    'Nesta seção você pode escolher se deseja tornar a adição deste campo obrigatória na criação de reservas ou orçamentos, além de poder definir se o passageiro poderá editar o campo caso ele já esteja cadastrado em seu perfil:',
  'permission-toggle-fragment.refund-category.description':
    'Nesta seção você pode escolher se deseja tornar a adição de categoria obrigatória para enviar um orçamento para aprovação:',

  'permission-toggle-fragment.switch.required.title': 'Tornar obrigatório',
  'permission-toggle-fragment.switch.editable.title': 'Permitir edição',

  // 10.1 General View
  'general-view.active-users': 'Usuários ativos',

  'general-view.travel': 'Viagens',
  'general-view.travel.created-reservations': 'Reservas criadas',
  'general-view.travel.created-reservations.description': 'Criadas no período',
  'general-view.travel.total': 'Total das reservas',
  'general-view.travel.total.description': 'Emitidas ou não',
  'general-view.travel.total-spent': 'Gasto efetivo',
  'general-view.travel.total-spent.description': 'Somente reservas emitidas',

  'general-view.travel.created-reservations-credit': 'Bilhetes não voados',
  'general-view.travel.created-reservations-credit.description':
    'Total disponível',
  'general-view.travel.total-credit': 'Total em bilhetes',
  'general-view.travel.total-credit.description': 'Valor das tarifas originais',
  'general-view.active-users-credit': 'Usuários aplicados',

  'general-view.budget': 'Orçamento',
  'general-view.budget.created': 'Orçamentos criados',
  'general-view.budget.created.description': 'Criados no período',
  'general-view.budget.approved': 'Orçamentos aprovados',
  'general-view.budget.approved.description': 'Aprovados no período',
  'general-view.budget.total': 'Valor dos orçamentos',
  'general-view.budget.total.description': 'Cartões físicos e virtuais',
  'general-view.budget.total-spent': 'Total aprovado',
  'general-view.budget.total-spent.description': 'De todos os cartões',

  // service: [hotel, voos, carros]
  'views.travel.new-requests.searching':
    'Buscando por {service} para {location}',
  'service.package': 'voo e hotel',
  'service.flight': 'voo',
  'service.hotel': 'hotel',
  'service.car': 'carro',
  'service.bus': 'ônibus',
  'service.office': 'escritório',

  'views.user.onboarding.alright': 'Deu tudo certo!',
  'views.user.onboarding.verify-email':
    'Verifique seu e-mail para criar sua senha',
  'views.user.onboarding.sign-in':
    'Cadastre-se e garantimos que começa agora sua <b>melhor experiência</b> com gerenciamento de viagens.',

  // 11. Alts
  'alts.components.flight.airport-wait': 'espera longa no aeroporto',
  'alts.components.search.hotel-item.hotel-category': 'Categoria do hotel',
  'alts.containers.search.hotel-modal.breakfast': 'Café da manhã incluso',
  'alts.containers.search.hotel-modal.cancellation': 'Cancelamento gratuito',
  'alts.containers.search.hotel-proximity-search.erase': 'Apagar',
  'alts.containers.search.package-search.station': 'Apagar',
  'alts.components.flight.journey.journey': 'Jornada',
  'alts.components.flight.journey.duration': 'Duração',
  'alts.components.flight.journey.legs': 'conex.',
  'alts.components.travel.total': 'Valor final',
  'alts.components.travel.code': 'Code',
  'alts.components.travel.bookingCode': 'Localizador',
  'alts.components.search.no-results': 'Não encontramos nenhum resultado',
  'alts.components.timeline.timeline.download': 'Baixar na App Store',
  'alts.components.timeline.timeline.available': 'Disponível no Google Play',
  'alts.components.timeline.flight-card': 'Voos',
  'alts.components.timeline.hotel-card': 'Hotéis',
  'alts.components.timeline.office-card': 'Escritórios',
  'alts.components.timeline.car-card': 'Carro',
  'alts.components.timeline.bus-card': 'Ônibus',
  'alts.containers.search.car-form': 'Origem e Destino',
  'alts.containers.search.flight-item': 'Trecho',

  'alts.containers.search.favorite-hotel': 'Hotel Favorito',

  // 12. Placeholder
  'placeholders.components.reports.data-selection': 'Selecione as datas',
  'placeholders.components.reports.search': 'Buscar',

  // 13. Checklists
  'checklist.help': 'Precisa de ajuda?',
  'checklist.setup': 'Bem-vindo à P3!',
  'checklist.setup.1': 'Faça uma busca na plataforma',
  'checklist.setup.2': 'Cadastre um cartão de crédito',
  'checklist.setup.3': 'Cadastre um centro de custo',
  'checklist.setup.4': 'Convide usuários para a plataforma',

  // 14. Reports
  'reports.print.type': 'Tipo',
  'reports.print.details': 'Detalhes',
  'reports.print.generate': 'Gerar arquivo',

  'reports.filters': 'Filtros',
  'reports.clear-filters': 'Limpar filtros',

  'reports.excel.id': 'ID',
  'reports.excel.type': 'Produto',
  'reports.excel.passengerName': 'Passageiro',
  'reports.excel.passengerEmail': 'E-mail do passageiro',
  'reports.excel.requestorName': 'Solicitante',
  'reports.excel.requestorEmail': 'E-mail do solicitiante',
  'reports.excel.approvers': 'Aprovadores',
  'reports.excel.whoApproved': 'Quem aprovou',
  'reports.excel.createdAt': 'Data da reserva',
  'reports.excel.anticipatedPurchase': 'Antecedência de compra',
  'reports.excel.costCenter': 'Centro de custo',
  'reports.excel.costCenterInternalId':
    'Identificador externo - Centro de custo',
  'reports.excel.project': 'Projeto',
  'reports.excel.projectInternalId': 'Identificador externo - Projeto',
  'reports.excel.motive': 'Motivo',
  'reports.excel.motiveInternalId': 'Identificador externo - Motivo',
  'reports.excel.tags': 'Tags',
  'reports.excel.observation': 'Observação',
  'reports.excel.creditCardName': 'Cartão de crédito',
  'reports.excel.creditCardId': 'ID do cartão de crédito',
  'reports.excel.creditCardInstallments': 'Parcelas',
  'reports.excel.issueType': 'Tipo de emissão',
  'reports.excel.fare': 'Tarifa',
  'reports.excel.taxes': 'Taxas',
  'reports.excel.additional': 'Adicionais',
  'reports.excel.total': 'Total',
  'reports.excel.cheapestAlternative': 'Menor tarifa',
  'reports.excel.alternativeDifference': 'Diferença da menor tarifa',
  'reports.excel.alternativeDifferencePercentage':
    '% da diferença da menor tarifa',
  'reports.excel.bookingCode': 'Localizador',
  'reports.excel.bookingTicket': 'Bilhete',
  'reports.excel.esgCo2': 'ESG CO2 (Kg)',
  'reports.excel.esgKm': 'ESG Km',
  'reports.excel.flightCarrier': 'Cia aérea',
  'reports.excel.flightNumJourneys': 'Quantidade de trechos',
  'reports.excel.flightJourneys': 'Trechos',
  'reports.excel.flightFirstDepartureDate': 'Data do primeiro embarque',
  'reports.excel.flightFirstDepartureTime': 'Horário de partida da ida',
  'reports.excel.flightFirstArrivalTime': 'Horário de chegada da ida',
  'reports.excel.flightFirstDepartureAirport': 'Aeroporto do primeiro embarque',
  'reports.excel.flightFirstDepartureCity':
    'Cidade do aeroporto do primeiro embarque',
  'reports.excel.flightLastDepartureDate': 'Data do último embarque',
  'reports.excel.flightLastDepartureTime': 'Horário de partida da volta',
  'reports.excel.flightLastArrivalTime': 'Horário de chegada da volta',
  'reports.excel.flightLastDepartureAirport': 'Aeroporto do último embarque',
  'reports.excel.flightLastDepartureCity':
    'Cidade do aeroporto do último embarque',
  'reports.excel.flightBases': 'Classes tarifárias',
  'reports.excel.flightFamilyNames': 'Descrição da classe',
  'reports.excel.flightBaggages': 'Bagagem',
  'reports.excel.flightWithCredit': 'Com crédito',
  'reports.excel.flightCreditAmount': 'Valor do crédito usado',
  'reports.excel.hotelName': 'Nome do hotel',
  'reports.excel.hotelAddress': 'Endereço',
  'reports.excel.hotelCity': 'Cidade',
  'reports.excel.hotelCheckIn': 'Check-in',
  'reports.excel.hotelCheckOut': 'Check-out',
  'reports.excel.hotelDailyRate': 'Tarifa diária',
  'reports.excel.hotelHasBreakfast': 'Café da Manhã',
  'reports.excel.hotelBoardDescription': 'Pensão',
  'reports.excel.hotelAdults': 'Adultos',
  'reports.excel.carRental': 'Locadora do veículo',
  'reports.excel.carVehicle': 'Veículo',
  'reports.excel.carType': 'Tipo',
  'reports.excel.carVehicleClass': 'Classe',
  'reports.excel.carPickUp': 'Data da retirada',
  'reports.excel.carPickUpAddress': 'Local da retirada',
  'reports.excel.carDropOff': 'Data da devolução',
  'reports.excel.carDropOffLocation': 'Local da devolução',
  'reports.excel.carDailyRate': 'Tarifa Diária',
  'reports.excel.busCarriers': 'Companhias de ônibus',
  'reports.excel.busNumJourneys': 'Quantidade de trechos ônibus',
  'reports.excel.busJourneys': 'Trechos ônibus',
  'reports.excel.busFirstDepartureDate': 'Data do primeiro embarque ônibus',
  'reports.excel.busLastDepartureDate': 'Data do último embarque ônibus',
  'reports.excel.busClasses': 'Classes ônibus',
  'reports.excel.busFirstDepartureTime': 'Horário de partida da ida',
  'reports.excel.busFirstArrivalTime': 'Horário de chegada da ida',
  'reports.excel.busLastDepartureTime': 'Horário de partida da volta',
  'reports.excel.busLastArrivalTime': 'Horário de chegada da volta',

  'reports.excel.budget.id': 'ID do orçamento',
  'reports.excel.budget.name': 'Nome',
  'reports.excel.budget.passengerName': 'Usuário',
  'reports.excel.budget.cardType': 'Tipo',
  'reports.excel.budget.budgetCategory': 'Categoria',
  'reports.excel.budget.createdAt': 'Criado em',
  'reports.excel.budget.startDate': 'Início',
  'reports.excel.budget.endDate': 'Fim',
  'reports.excel.budget.anticipationDate': 'Antecedência de criação',
  'reports.excel.budget.budgetValue': 'Orçamento',
  'reports.excel.budget.total': 'Valor gasto',
  'reports.excel.budget.wasApproved': 'Foi aprovado?',
  'reports.excel.budget.statusCodeMessage': 'Status',
  'reports.excel.budget.cardId': 'ID do cartão',
  'reports.excel.budget.cardInfo': 'Cartão',
  'reports.excel.budget.costCenter': 'Centro de custo',
  'reports.excel.budget.costCenterInternalId':
    'Identificador externo - Centro de custo',
  'reports.excel.budget.project': 'Projeto',
  'reports.excel.budget.projectInternalId': 'Identificador externo - Projeto',
  'reports.excel.budget.motive': 'Motivo',
  'reports.excel.budget.motiveInternalId': 'Identificador externo - Motivo',
  'reports.excel.budget.tags': 'Tags',
  'reports.excel.budget.observation': 'Observação',
  'reports.excel.budget.itemId': 'ID do item',
  'reports.excel.budget.itemName': 'Nome do item',
  'reports.excel.budget.itemDate': 'Data do item',
  'reports.excel.budget.itemTotal': 'Valor do item',
  'reports.excel.budget.itemCategory': 'Categoria do item',
  'reports.excel.budget.itemCategoryInternalId':
    'Identificador externo - Categoria do item',
  'reports.excel.budget.itemType': 'Tipo do item',
  'reports.excel.budget.invoiceId': 'ID de Referência',
  'reports.excel.budget.receiptLink': 'Link do recibo (válido por 7 dias)',

  'reports.excel.budget.item-type.items': 'Itens',
  'reports.excel.budget.item-type.km': 'Por KM',

  'reports.excel.budget.approved-total': 'Valor aprovado',

  'reports.excel.short.start-date': 'Início',
  'reports.excel.short.end-date': 'Fim',
  'reports.excel.short.creation-date': 'Feita em',

  'reports.details.simple': 'Simples',
  'reports.details.detailed': 'Detalhado',
  'reports.details.by-cards': 'Por cartões',
  'reports.details.by-items': 'Por itens',

  'reports.excel.worksheet-name': 'Relatório P3',

  'reports.pdf.from': 'De',
  'reports.pdf.to': 'até',

  'reports.json.type': 'tipo',
  'reports.json.passenger': 'passageiro',
  'reports.json.reservation': 'reserva',
  'reports.json.cost-center': 'centroCusto',
  'reports.json.project': 'projeto',
  'reports.json.creation-date': 'criadoEm',
  'reports.json.start-date': 'dataInicio',
  'reports.json.end-date': 'dataFim',
  'reports.json.approvers': 'aprovadores',
  'reports.json.total': 'total',

  'reports.name': 'Relatório P3',
  'reports.credit.name': 'Relatório bilhetes não voados P3',

  'reports.total-value': 'Valor total',

  'reports.filter.products': 'Produtos',
  'reports.filter.passengers': 'Passageiros',
  'reports.filter.costCenters': 'Centros de Custo',
  'reports.filter.projects': 'Projetos',
  'reports.filter.prices': 'Preços',
  'reports.filter.statusCode': 'Status da reserva',
  'reports.filter.structure': 'Tipo de viagem',
  'reports.filter.approvers': 'Aprovadores',
  'reports.filter.reservation-type': 'Tipos de reserva',
  'reports.filter.total': 'Total',
  'reports.filter.project': 'Projeto',
  'reports.filter.costCenter': 'Centro de custo',
  'reports.filter.approver': 'Aprovador',
  'reports.filter.passenger': 'Passageiro',
  'reports.filter.card-type': 'Tipo de pagamento',
  'reports.filter.card-name': 'Nome do cartão',
  'reports.filter.budget': 'Orçamento',
  'reports.filter.user-name': 'Nome do usuário',
  'reports.filter.budget-status': 'Status do orçamento',
  'reports.filter.pending': 'Pendente',

  'reports.filter.status-code.30': 'Reservado',
  'reports.filter.status-code.40': 'Aprovando',
  'reports.filter.status-code.45': 'Emitindo',
  'reports.filter.status-code.46': 'Emitindo',
  'reports.filter.status-code.50': 'Emitido',
  'reports.filter.status-code.80': 'Erro',
  'reports.filter.status-code.85': 'Rejeitado',
  'reports.filter.status-code.95': 'Cancelando',
  'reports.filter.status-code.99': 'Cancelado Manualmente',
  'reports.filter.status-code.100': 'Cancelado',
  'reports.filter.status-code.101': 'Expirado',
  'reports.filter.status-code.150': 'Escondido',

  'reports.advanced-filter': 'Avançado',

  'reports.pdf.all-reservations': 'Todos as reservas',

  'reports.advanced-filter.departureIataCode': 'Saída',
  'reports.advanced-filter.arrivalIataCode': 'Chegada',
  'reports.advanced-filter.roundTrip': 'Ida e volta?',
  'reports.advanced-filter.category': 'Categoria',
  'reports.advanced-filter.chain': 'Rede',
  'reports.advanced-filter.hotelName': 'Nome',
  'reports.advanced-filter.city': 'Cidade',
  'reports.advanced-filter.carName': 'Modelo',
  'reports.advanced-filter.rental': 'Locadora',
  'reports.advanced-filter.airConditioning': 'Ar-condicionado',
  'reports.advanced-filter.baggageSize': 'Tamanho do bagageiro',
  'reports.advanced-filter.doors': 'Num. portas',
  'reports.advanced-filter.passengers': 'Num. passageiros',
  'reports.advanced-filter.traction': 'Tração',
  'reports.advanced-filter.transmission': 'Câmbio',
  'reports.advanced-filter.departureStation': 'Saída',
  'reports.advanced-filter.arrivalStation': 'Chegada',
  'reports.advanced-filter.carType': 'Tipo',
  'reports.advanced-filter.roomName': 'Tipo da reserva',

  'statement.excel.id': 'ID',
  'statement.excel.amount': 'Amount',
  'statement.excel.currency': 'Currency',
  'statement.excel.merchant_name': 'Merchant Name',
  'statement.excel.merchant_city': 'Merchant City',
  'statement.excel.merchant_country': 'Merchant Country',
  'statement.excel.merchant_id': 'Merchant ID',
  'statement.excel.mcc': 'MCC',
  'statement.excel.response_code': 'Response Code',
  'statement.excel.response_msg': 'Response Message',
  'statement.excel.date_time': 'Date Time',
  'statement.excel.msg_type': 'Message Type',
  'statement.excel.msg_type_description': 'Message Type Description',
  'statement.excel.txn_type': 'Transaction Type',
  'statement.excel.reverses_id': 'Reverses ID',
  'statement.excel.auth_code': 'Auth Code',
  'statement.excel.card_id': 'Card ID',
  'statement.excel.card_last_digits': 'Card Last Digits',
  'statement.excel.card_user_name': 'Card User Name',
  'statement.excel.user_cost_center': 'User Cost Center',
  'statement.excel.user_project': 'User Project',
  'statement.excel.budget_id': 'Budget',
  'statement.excel.budget_name': 'Budget Name',
  'statement.excel.budget_category': 'Budget Category',
  'statement.excel.budget_item_category': 'Budget Item Category',
  'statement.excel.budget_observation': 'Budget Observation',
  'statement.excel.budget_total_amount': 'Budget Total Amount',
  'statement.excel.budget_total_spent': 'Budget Total Spent',
  'statement.excel.order_id': 'Reservation',
  'statement.excel.order_cost_center': 'Reservation Cost Center',
  'statement.excel.original_amount': 'Original Amount',
  'statement.excel.original_currency': 'Original Currency',
  'statement.excel.activates_at': 'Activates At',
  'statement.excel.cancels_at': 'Cancels At',
  'statement.excel.status': 'Status',
  'statement.excel.custom_fields': 'Custom Fields',
  'statement.excel.external': 'External',

  // 15. Bulk Import
  'bulk.type': 'usuários',
  'bulk.columns.first-name': 'Nome',
  'bulk.columns.last-name': 'Sobrenome',
  'bulk.columns.birthdate': 'Data de nascimento',
  'bulk.columns.identity': 'Identificação',
  'bulk.successful': 'Usuários importados com sucesso!',

  'bulk.description':
    'No formato DD/MM/YYYY (Ao importar, o dia e mês podem se inverter, mas será considerado DD/MM/YYY)',

  'bulk.breadcrumbs.columns': ' colunas',
  'bulk.breadcrumbs.complete': 'Completar',
  'bulk.breadcrumbs.match': 'Associar',
  'bulk.breadcrumbs.problems': ' problemas',
  'bulk.breadcrumbs.repair': 'Revisar',
  'bulk.breadcrumbs.spreadsheet': ' seu spreadsheet',
  'bulk.breadcrumbs.upload': 'Upload',

  'bulk.errors.missingRequiredFields':
    'Ops! Você não associou nenhuma coluna aos seguintes campos: {{fields}}',

  'bulk.btns.back': 'Voltar',
  'bulk.btns.cancel': 'Cancelar',
  'bulk.btns.clear': 'Limpar',
  'bulk.btns.complete': 'Completar',
  'bulk.btns.confirm': 'Confirmar',
  'bulk.btns.confirmMapping': 'Confirmar mapeamento',
  'bulk.btns.continue': 'Continuar',
  'bulk.btns.customField': 'Incluir como campo personalizado',
  'bulk.btns.edit': 'Editar',
  'bulk.btns.ignore': 'Ignorar esta coluna',
  'bulk.btns.no': 'Não',
  'bulk.btns.ok': 'Ok',
  'bulk.btns.review': 'Revisar',
  'bulk.btns.yes': 'Sim',
  'bulk.btns.selectAnotherRow': 'Selecionar outra linha',
  'bulk.btns.noHeaderRow': 'Sem cabeçalho',

  'bulk.clearAllChanges':
    'Tem certeza que deseja limpar todas as mudanças feitas nos dados deste estágio?',
  'bulk.dropzone.accepted': '{{types}} spreadsheets aceitos.',
  'bulk.dropzone.button': 'Carregar dados de arquivo',
  'bulk.fileTypes':
    'Você pode fazer o upload de qualquer arquivo {{file}} com qualquer conjunto de colunas, contanto que ele tenha no mínimo 1 dado por linha. O próximo passo te permitirá associar os dados com as colunas pedidas. Você será capaz de limpar ou remover qualquer dado corrompido antes de finalizar o envio dos dados.',
  'bulk.header': 'Importar {{number}} {{thing}}',
  'bulk.header2': 'Importar {{thing}}',
  'bulk.lookupMatchingFields': 'Associe a uma coluna',
  'bulk.processingPleaseWait': 'Processando, aguarde',
  'bulk.readySubmit': 'Pronto para importar os dados?',
  'bulk.success': 'Sucesso!',
  'bulk.unableToMatch': 'Incapaz de associar a uma coluna',
  'bulk.manual': '...ou insira os dados manualmente abaixo:',
  'bulk.stepOneSuccess': 'Arquivo importado',
  'bulk.statusLoadedSheets': 'Sheets carregados',
  'bulk.selectSheet': 'Selecione um sheet',
  'bulk.stepThreeDefault': 'Selecione o {{dataSource}}',
  'bulk.stepThreeSuccess': 'Selecionado {{dataSource}} "{{dataSourceLabel}}"',
  'bulk.autoMatchedField1': 'Associado ao campo ',
  'bulk.autoMatchedField2': ' .',
  'bulk.rowsHaveValue': '{{rows}} das linhas possuem um valor para esta coluna',
  'bulk.validationPass': 'Todos os valores passaram na validação deste campo',
  'bulk.stepTwoDefault': 'Carregando {{dataSource}}',
  'bulk.question1': 'Esta linha selecionada contém os cabeçalhos dos dados?',
  'bulk.question2': 'Selecione a linha que contém os cabeçalhos dos dados',

  // 16. Travels
  'travels.favorite-place.title': 'Adicionar um lugar favorito',

  'travels.favorite-place.already-saved': 'Lugar Já Salvo',
  'travels.favorite-place.already-saved.description':
    'Você já adicionou este lugar antes. Selecione um lugar inédito para prosseguir!',
  'travels.favorite-place.selected': 'Lugar selecionado',
  'travels.favorite-place.selected.loading': 'Carregando endereço...',
  'travels.favorite-place.nothing': 'Nenhum Lugar Selecionado',
  'travels.favorite-place.nothing.description':
    'Você ainda não selecionou nenhum local. Procure por um endereço na barra de pesquisa ou clique duas vezes no lugar do mapa que você deseja favoritar.',

  'travels.favorite-hotels.title': 'Adicionar hotéis favoritos',
  'travels.favorite-hotels.show-favorites': 'Mostrar favoritos',

  'travels.favorite-hotels.none-selected': 'Nenhum hotel selecionado',
  'travels.favorite-hotels.none-selected.description':
    'Você ainda não selecionou nenhum hotel. Procure um endereço ou cidade no mapa e depois favorite os hotéis encontrados próximos a ele.',
  'travels.favorite-hotels.searching': 'Procurando hotéis',
  'travels.favorite-hotels.searching.description':
    'Procurando hotéis próximos de {address}',
  'travels.favorite-hotels.nothing-found': 'Nenhum hotel encontrado',
  'travels.favorite-hotels.nothing-found.description':
    'Tente pesquisar por outro endereço ou procure pelo nome do hotel desejado. Lembre-se sempre de adicionar a cidade desejada no final do endereço!',

  // 16.1 Itinerary
  'itinerary.hotel.title': 'Hospedagem',
  'itinerary.hotel.description':
    'Selecione o hotel e o quarto desejado. O valor da reserva pode diminuir de acordo com o dia de chegada no destino. O quarto selecionado aparecerá abaixo.',
  'itinerary.flight.outbound.description':
    'Selecione o voo de ida desejado ao lado.',
  'itinerary.flight.inbound.description':
    'Selecione o voo de volta desejado ao lado.',
  'itinerary.flight.description': 'O voo selecionado aparecerá abaixo.',

  'itinerary.select-outbound.title': 'Selecione um voo de ida',
  'itinerary.select-outbound.description':
    'É preciso selecionar o voo de ida antes.',

  'itinerary.flight-params.title': 'Aéreo',
  'itinerary.flight-params.description': 'Defina os parâmetros do voo:',

  'itinerary.hotel-params.title': 'Hotel',
  'itinerary.hotel-params.description': 'Defina os parâmetros do hotel:',

  'itinerary.itinerary-params.title': 'Itinerário',
  'itinerary.itinerary-params.description':
    'Defina os parâmetros do itinerário:',
  'itinerary.itinerary-params.radius': 'Distância máxima (em km)',
  'itinerary.itinerary-params.round-trip': 'Tipo de voo',
  'itinerary.itinerary-params.round-trip.yes': 'Ida e volta (mais barato)',
  'itinerary.itinerary-params.round-trip.no': 'Voos separados',

  // 17. Refund Categories
  'refund.category.placeholder': 'Selecione um ícone para a categoria',
  'refund.category.car': 'Carro',
  'refund.category.hotel': 'Hotel',
  'refund.category.flight': 'Voo',
  'refund.category.food': 'Alimentação',
  'refund.category.fuel': 'Combustível',
  'refund.category.lazer': 'Lazer',
  'refund.category.money': 'Dinheiro',
  'refund.category.apple': 'Fruta',
  'refund.category.bank': 'Banco',
  'refund.category.boat': 'Barco',
  'refund.category.building': 'Prédio',
  'refund.category.building2': 'Prédio 2',
  'refund.category.building3': 'Prédio 3',
  'refund.category.bus': 'Ônibus',
  'refund.category.bulb': 'Lâmpada',
  'refund.category.church': 'Igreja',
  'refund.category.clothes': 'Roupas',
  'refund.category.coffee': 'Café',
  'refund.category.coffee2': 'Café 2',
  'refund.category.coin': 'Moedas',
  'refund.category.doctor': 'Médico',
  'refund.category.drink': 'Bebida',
  'refund.category.globe': 'Globo',
  'refund.category.health': 'Saúde',
  'refund.category.industry': 'Industria',
  'refund.category.law': 'Balança',
  'refund.category.syringe': 'Seringa',
  'refund.category.taxi': 'Táxi',
  'refund.category.wine': 'Vinho',
  'refund.category.suitcase': 'Mala',

  'refund.expense.cancel': 'Cancela solicitação e deleta orçamentos',
  'refund.expense.undo': 'Cancela solicitação, mas mantém orçamentos',

  'refund.not-without-receipt':
    'Sua organização exige que todas as despesas de um orçamento possuam recibo',
  'refund.require-category':
    'Sua organização exige que todas as despesas possuam uma categoria',

  'expense.expense-items.reject': 'Exitem itens rejeitados nesse orçamento',
  'expense.expense-items.comment': 'Existe um comentário nesse orçamento',
  'refund.approver-comment': 'Comentário do aprovador',

  'approval-card.review':
    'Você precisa rejeitar um item ou fazer um comentário para pedir uma revisão!',
  'approval-card.all-rejected':
    'Você não pode rejeitar todos os itens e depois aprovar a solicitação. Tente reprovar a solicitação!',

  'refund.invalid': 'Esta despesa já está associada a um orçamento!',

  'budget-modal.download-receipts': 'Baixar todos os recibos deste orçamento',
  'budget-modal.administrative.download-receipts': 'Baixar recibo de pagamento',

  'notify-approvers.title': 'Reenviar solicitação de aprovação',
  'notify-approvers.title.success': 'Solicitações enviadas com sucesso!',

  'users.bulk-import.loading': 'Realizando alterações nos usuários',

  'reservation.invalid-fields':
    'Você não preencheu todos os campos obrigatórios. Certifique-se de que todos os campos com * (asterisco) estejam preenchidos!',

  'reservation.invalid-fare-access':
    'Esse passageiro não pode viajar com essa tarifa, por favor selecione a mais barata!',

  // 19. Modals
  'modal.yes': 'Sim',
  'modal.no': 'Não',
  'modal.ok': 'Ok',

  // 20. Budget
  'budget.modal.title': 'Adicionar orçamento',
  'budget.modal.new-item': '+ Nova despesa',

  'budget.category.food': 'Alimentação',
  'budget.category.fuel': 'Combustível',
  'budget.category.transport': 'Transporte',
  'budget.category.free': 'Livre',
  'budget.category.FOOD': 'Alimentação',
  'budget.category.FUEL': 'Combustível',
  'budget.category.TRANSPORT': 'Transporte',
  'budget.category.FREE': 'Livre',
  'budget.category.MOBILITY': 'Mobilidade',
  'budget.category.NATIONAL': 'Nacional',
  'budget.category.INTERNATIONAL': 'Internacional',

  'budget.specificity.none': 'Nenhuma',
  'budget.specificity.group': 'Grupo',
  'budget.specificity.costCenter': 'Centro de custo',
  'budget.specificity.project': 'Projeto',
  'budget.specificity.motive': 'Motivo',
  'budget.specificity.tag': 'Tag',

  'budget.aplliedTo': 'Aplicado a {type}',

  'budget.phrase.1': 'Ao aprovar',
  'budget.phrase.2': 'De um passageiro com a seguinte especificidade',
  'budget.phrase.3.singular': 'De nome',
  'budget.phrase.3.plural': 'De nomes',
  'budget.phrase.4': 'Criar o orçamento',

  'budget.phrase.daily': 'diários para',

  'budget.phrase.add-rule': 'Nova Regra',

  'budget.phrase.create-budget':
    'Crie regras orçamentárias definindo um valor que será adicionado ao cartão do passageiro todos os dias, de acordo com uma categoria MCC (Merchant category code).',

  'receipt-expense.items': 'Itens da despesas',
  'receipt-expense.invoice-info': 'Dados da nota fiscal',
  'receipt-expense.transaction-info': 'Dados da transação',
  'receipt-expense.supplier-info': 'Dados do estabelecimento',
  'receipt-expense.discount': 'Desconto de',

  'balance-card.balance': 'Saldo disponível',
  'balance-card.active-balance': 'Saldo bloqueado em orçamentos',
  'balance-card.needed-balance': 'Saldo necessário para orçamentos futuros',
  'balance-card.periods.next-days': 'Próximos {days} dias',

  'balance-card.cash-data.title': 'Dados monetários',

  'balance-card.empty-data.title': 'Não há orçamentos pendentes nesse período',
  'balance-card.empty-data.description':
    'Você pode selecionar outros períodos utilizando o campo acima',

  'balance-card.refresh': 'Atualizar valores',

  'budget.notifications.INTERNAL_ERROR':
    'Não foi possível ativar este orçamento, uma nova tentativa será feita em breve! Para maiores informações contate nosso suporte.',
  'budget.notifications.CREDIT_CARD_UNAUTHORIZED':
    'A conta associada não tinha fundos suficientes para que este orçamento fosse ativado!',
  'budget.notifications.RESERVATION_FAILED':
    'Um erro aconteceu com este orçamento. Entre em contato com nosso suporte para mais detalhes!',
  'budget.notifications.BUDGET_INTERSECTION':
    'Este orçamento ocorre no mesmo período de outro de mesmo estabelecimento!',
  'budget.notifications.CODE_429':
    'Não foi possível ativar este orçamento! Uma nova tentativa será feita em até uma hora.',
  'budget.notifications.CANCELLATION_FAILED':
    'Não foi possível cancelar este orçamento! Tente novamente ou entre em contato com nosso suporte.',
  'budget.notifications.SEND_FOR_APPROVAL_FAILED':
    'Não foi possível enviar este orçamento para a aprovação! Tente novamente ou entre em contato com nosso suporte.',
  'budget.notifications.INVALID_DATE':
    'Orçamentos de cartões físicos ou virtuais só podem ser criados se seu período for posterior ou igual a data atual.',
  'budget.notifications.CANCELATION_ERROR':
    'Não foi possível cancelar este orçamento. Faça uma nova tentativa ou entre em contato com nosso suporte!',
  'budget.notifications.FORBIDDEN_ADMINISTRATIVE':
    'Sua organização não permite a criação de orçamentos administrativos!',
  'budget.notifications.ADMINISTRATIVE_DUE_DATE':
    'A data limite de pagamento deste orçamento já passou. Seu pagamento não pôde ser efetuado!',
  'budget.notifications.PAYMENT_FAILED':
    'Não foi possível efetuar o pagamento desse orçamento. Tente novamente!',
  'budget.notifications.PUT_ON_HOLD_FAILED':
    'Não foi possível colocar este orçamento em espera. Tente novamente!',

  'budget.policy.anticipation-approval': 'Aprovação de Antecipação',
  'budget.policy.approval': 'Aprovação',

  // 21. Comparatives
  'comparatives.label.=': 'é igual a',
  'comparatives.label.!=': 'é diferente de',
  'comparatives.label.>': 'é maior que',
  'comparatives.label.>=': 'é maior ou igual a',
  'comparatives.label.<': 'é menor que',
  'comparatives.label.<=': 'é menor ou igual a',

  // 22. Filters
  'input-filter.add-new': 'Adicione filtros aos dados',
  'input-filter.field': 'Campo',
  'input-filter.comparisson': 'Comparador',
  'input-filter.value': 'Valor',

  // 23. Card options
  'card-option.block.blocked': 'Bloquear cartão',
  'card-option.block.unblocked': 'Desbloquear cartão',
  'card-option.password': 'Mudar senha',
  'card-option.pan.show': 'Mostrar informações',
  'card-option.pan.hide': 'Esconder informações',
  'card-option.options': 'Mais opções',
  'card-option.archive': 'Arquivar cartão',

  // 24. Inbox
  'read-qrcode': 'Ler QR Code',
  'num-attachments.empty': 'Sem anexos',
  'num-attachments.one': '1 anexo',
  'num-attachments.multiple': '{number} anexos',

  'sort-by.title': 'Ordenar por',
  'sort-by.most-recent': 'Mais recente',
  'sort-by.oldest': 'Mais antigo',
  'sort-by.read': 'Lidos',
  'sort-by.unread': 'Não lidos',

  'budget-selection.empty-account':
    'Você ainda não possui uma conta, mas fica tranquilo! Clica no botão abaixo que te levo para criar uma:',
  'budget-selection.title':
    'Selecione uma conta e um orçamento para criar uma nova despesa:',

  'empty-box.title': 'Caixa de e-mails vazia',
  'empty-box.description':
    'Envie ou encaminhe os e-mails desejados para seu endereço de despesas abaixo',

  // 25. CNAE
  'cnae.01': 'Agricultura, pecuária e afins',
  'cnae.02': 'Produção florestal',
  'cnae.03': 'Pesca e aquicultura',
  'cnae.05': 'Extração de carvão',
  'cnae.06': 'Extração de petróleo e gás natural',
  'cnae.07': 'Extração de minerais metálicos',
  'cnae.08': 'Extração de minerais não-metálicos',
  'cnae.09': 'Atividade de apoio à extração de minerais',
  'cnae.10': 'Fabricação de produtos alimentícios',
  'cnae.11': 'Fabricação de bebidas',
  'cnae.12': 'Fabricação de produtos do fumo',
  'cnae.13': 'Fabricação de produtos têxteis',
  'cnae.14': 'Confecção de artigos do vestuário e acessórios',
  'cnae.15':
    'Preparação de couros e fabricação de artefatos de couro, artigos para viagem e calçados',
  'cnae.16': 'Fabricação de produtos de madeira',
  'cnae.17': 'Fabricação de celulose, papel e produtos de papel',
  'cnae.18': 'Impressão e reprodução de gravações',
  'cnae.19':
    'Fabricação de coque, de produtos derivados do petróleo e de biocombustíveis',
  'cnae.20': 'Fabricação de produtos químicos',
  'cnae.21': 'Fabricação de produtos farmoquímicos e farmacêuticos',
  'cnae.22': 'Fabricação de produtos de borracha e de material plástico',
  'cnae.23': 'Fabricação de produtos de minerais não-metálicos',
  'cnae.24': 'Metalurgia',
  'cnae.25': 'Fabricação de produtos de metal, exceto máquinas e equipamentos',
  'cnae.26':
    'Fabricação de equipamentos de informática, produtos eletrônicos e ópticos',
  'cnae.27': 'Fabricação de máquinas, aparelhos e materiais elétricos',
  'cnae.28': 'Fabricação de máquinas e equipamentos',
  'cnae.29': 'Fabricação de veículos automotores, reboques e carrocerias',
  'cnae.30':
    'Fabricação de outros equipamentos de transporte, exceto veículos automotores',
  'cnae.31': 'Fabricação de móveis',
  'cnae.32': 'Fabricação de produtos diversos',
  'cnae.33': 'Manutenção, reparação e instalação de máquinas e equipamentos',
  'cnae.36': 'Captação, tratamento e distribuição de água',
  'cnae.37': 'Esgoto e atividades relacionadas',
  'cnae.38':
    'Coleta, tratamento e disposição de resíduos; recuperação de materiais',
  'cnae.39': 'Descontaminação e outros serviços de gestão de resíduos',
  'cnae.41': 'Construção de edifícios',
  'cnae.42': 'Obras de infra-estrutura',
  'cnae.43': 'Serviços especializados para construção',
  'cnae.45': 'Comércio e reparação de veículos automotores e motocicletas',
  'cnae.46': 'Comércio por atacado, exceto veículos automotores e motocicletas',
  'cnae.47': 'Comércio varejista',
  'cnae.49': 'Transporte terrestre',
  'cnae.50': 'Transporte aquaviário',
  'cnae.51': 'Transporte aéreo',
  'cnae.52': 'Armazenamento e atividades auxiliares dos transportes',
  'cnae.53': 'Correio e outras atividades de entrega',
  'cnae.55': 'Alojamento',
  'cnae.56': 'Alimentação',
  'cnae.58': 'Edição e edição integrada à impressão',
  'cnae.59':
    'Atividades cinematográficas, produção de vídeos e de programas de televisão; gravação de som e edição de música',
  'cnae.60': 'Atividades de rádio e de televisão',
  'cnae.61': 'Telecomunicações',
  'cnae.62': 'Atividades dos serviços de tecnologia da informação',
  'cnae.63': 'Atividades de prestação de serviços de informação',
  'cnae.64': 'Atividades de serviços financeiros',
  'cnae.65': 'Seguros, resseguros, previdência complementar e planos de saúde',
  'cnae.66':
    'Atividades auxiliares dos serviços financeiros, seguros, previdência complementar e planos de saúde',
  'cnae.68': 'Atividades imobiliárias',
  'cnae.69': 'Atividades jurídicas, de contabilidade e de auditoria',
  'cnae.70':
    'Atividades de sedes de empresas e de consultoria em gestão empresarial',
  'cnae.71': 'Serviços de arquitetura e engenharia; testes e análises técnicas',
  'cnae.72': 'Pesquisa e desenvolvimento científico',
  'cnae.73': 'Publicidade e pesquisa de mercado',
  'cnae.74': 'Outras atividades profissionais, científicas e técnicas',
  'cnae.75': 'Atividades veterinárias',
  'cnae.77':
    'Aluguéis não-imobiliários e gestão de ativos intangíveis não-financeiros',
  'cnae.78': 'Seleção, agenciamento e locação de mão-de-obra',
  'cnae.79':
    'Agências de viagens, operadores turísticos e serviços de reservas',
  'cnae.80': 'Atividades de vigilância, segurança e investigação',
  'cnae.81': 'Serviços para edifícios e atividades paisagísticas',
  'cnae.82':
    'Serviços de escritório, de apoio administrativo e outros serviços prestados principalmente às empresas',
  'cnae.84': 'Administração pública, defesa e seguridade social',
  'cnae.85': 'Educação',
  'cnae.86': 'Atividades de atenção à saúde humana',
  'cnae.87':
    'Atividades de atenção à saúde humana integradas com assistência social, prestadas em residências coletivas e particulares',
  'cnae.88': 'Serviços de assistência social sem alojamento',
  'cnae.90': 'Atividades artísticas, criativas e de espetáculos',
  'cnae.91': 'Atividades ligadas ao patrimônio cultural e ambiental',
  'cnae.92': 'Atividades de exploração de jogos de azar e apostas',
  'cnae.93': 'Atividades esportivas e de recreação e lazer',
  'cnae.94': 'Atividades de organizações associativas',
  'cnae.95':
    'Reparação e manutenção de equipamentos de informática e comunicação e de objetos pessoais e domésticos',
  'cnae.96': 'Outras atividades de serviços pessoais',
  'cnae.97': 'Serviços domésticos',
  'cnae.99':
    'Organismos internacionais e outras instituições extraterritoriais',
  'cnae.unknown': 'Desconhecido',

  // Cancellation motives
  'cancellation.motive.title':
    'Descreva o motivo do cancelamento para solicitar isenção da multa ao hotel:',
  'cancellation.motive.placeholder': 'Qual é o motivo do cancelamento?',

  'cancellation.error':
    'Não foi possível cancelar esta reserva. Tente novamente mais tarde ou entre em contato com nosso suporte!',

  'cancellation.input.placeholder': 'Selecione o motivo do cancelamento',
  'cancellation.button.free': 'Cancelar reserva gratuitamente',
  'cancellation.button.without-refund': 'Cancelar reserva sem reembolso',
  'cancellation.button.with-refund':
    'Cancelar reserva e solicitar a isenção de multa',

  'cancellation.other': 'Outro',

  'cancellation.hotel.health':
    'O passageiro não poderá realizar a viagem por motivos de saúde',
  'cancellation.hotel.unforeseen':
    'A programação da viagem foi alterada e o passageiro não estará na cidade nesse período',
  'cancellation.hotel.mistake':
    'A emissão foi realizada por engano e só percebemos isso depois do prazo de cancelamento',

  // Others
  'support.title': 'Suporte',
  'generate-vouchers.title': 'Gerar vouchers das reservas emitidas',

  // Open Booking
  'open-booking.providers.123MILHAS': '123 Milhas',
  'open-booking.providers.BOOKING': 'Booking.com',
  'open-booking.providers.KIWI': 'Kiwi',
  'open-booking.providers.MAX_MILHAS': 'Maxmilhas',
  'open-booking.providers.LATAM': 'Latam',
  'open-booking.providers.DECOLAR': 'Decolar.com',
  'open-booking.providers.EXPEDIA': 'Expedia',
  'open-booking.providers.GOL': 'Gol',
  'open-booking.providers.AZUL': 'Azul',
  'open-booking.providers.OTHER': 'Outro',

  'open-booking.providers.short.123MILHAS': 'milhas',
  'open-booking.providers.short.MAX_MILHAS': 'milhas',
  'open-booking.providers.short.DECOLAR': 'decolar',
  'open-booking.providers.short.GOL': 'GOL',
  'open-booking.providers.short.LATAM': 'LATAM',

  'open-booking.providers.offer.OTHER': 'Reserva feita em outro site',
  'open-booking.providers.offer': 'Oferta do site:',

  'open-booking.modal.badge,providers.title': 'Esta é uma oferta:',

  'weather.info':
    'Hey, {passengerName}! {nameLocation} fará {temperature}°C na sua viagem.',

  // File Uploader
  'file-uploader.invalid-types': 'Arquivos com formatos inválidos',
  'file-uploader.allowed-types': 'Tipos permitidos:',
  'file-uploader.drop-receipts': 'Solte os recibos desse orçamento aqui',
  'file-uploader.drag-receipts':
    'Clique aqui ou arraste os arquivos dos recibos desse orçamento',

  'search.hotelSuggestionModal.title':
    'Excelente, seu pedido de reserva foi enviado com sucesso!',
  'search.hotelSuggestionModal.options':
    'Veja também as opções de hospedagem em:',
  'search.hotelSuggestionModal.options.locale':
    'Selecione uma opção de hospedagem para realizar sua reserva.',
  'search.hotelSuggestionModal.reserve': 'Reservar com café',
  'search.clear-search': 'Limpar busca',

  //NPS
  'nps.questions':
    'Olá! Qual a chance de você recomendar a Portão 3 para seus colegas?',
  'nps.thanks': 'Obrigado por nos compartilhar seu feedback!',
  'nps.followUp': 'Você se importaria de nos dizer por que escolheu',
  'nps.placeholder': 'Nos conte um pouco mais se possível',
  'nps.dismiss': 'Dispensar',
  'nps.submit': 'Enviar',
  'nps.min.label': 'Pouco provável',
  'nps.max.label': 'Muito provável',

  // Search params
  'search-params.hotel.categories.0': 'Sem categoria',
  'search-params.hotel.categories.1': '1 estrela',
  'search-params.hotel.categories.2': '2 estrelas',
  'search-params.hotel.categories.3': '3 estrelas',
  'search-params.hotel.categories.4': '4 estrelas',
  'search-params.hotel.categories.5': '5 estrelas',

  'search-params.hotel.amenities.babá_ou_creche': 'Babá ou creche',
  'search-params.hotel.amenities.café_da_manhã': 'Café da manhã',
  'search-params.hotel.amenities.internet': 'Internet',
  'search-params.hotel.amenities.lavanderia': 'Lavanderia',
  'search-params.hotel.amenities.restaurante': 'Restaurante',
  'search-params.hotel.amenities.estacionamento': 'Estacionamento',
  'search-params.hotel.amenities.piscina': 'Piscina',
  'search-params.hotel.amenities.academia': 'Academia',
  'search-params.hotel.amenities.spa': 'Spa',
};
