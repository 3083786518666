import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import UsersList from '../../../components/UsersList';
import firebaseApp from '../../../services/Firebase';
import { getDocument } from '../../../utils/firebase';
import { stringToMoney } from '../../../utils/money';
import GeneralInfo from './GeneralInfo';

export default function BudgetsInfo({
  className = '',
  budgets,
  getData,
  showPriceInfo = false,
}) {
  const { messages } = useIntl();

  const {
    numBudgets,
    approvedBudgets,
    numUsers,
    budgetUsers = [],
    total,
    totalSpent,
  } = useMemo(() => {
    const users = [];
    let approvedBudgets = 0,
      total = 0,
      totalSpent = 0;

    budgets.forEach((budget) => {
      if (budget.wasApproved) approvedBudgets += 1;
      if (!users.includes(budget.passengerId)) users.push(budget.passengerId);

      total += budget.budget || 0;
      totalSpent += budget.wasApproved ? budget.fare.total || 0 : 0;
    });

    return {
      numBudgets: budgets.length,
      numUsers: users.length,
      budgetUsers: users,
      approvedBudgets,
      total: stringToMoney(total, 2),
      totalSpent: stringToMoney(totalSpent, 2),
    };
  }, [budgets]);

  // Functions
  const fetchUser = (userId) =>
    getData(`user-${userId}`, () =>
      getDocument(() => firebaseApp.getUserFromId(userId).get())
    );

  return (
    <GeneralInfo className={className} title={messages['general-view.budget']}>
      {(renderItem, renderDescription) => (
        <>
          {renderItem(messages['general-view.budget.created'], numBudgets, () =>
            renderDescription(
              messages['general-view.budget.created.description']
            )
          )}
          {renderItem(
            messages['general-view.budget.approved'],
            approvedBudgets,
            () =>
              renderDescription(
                messages['general-view.budget.approved.description']
              )
          )}
          {showPriceInfo ? (
            <>
              {renderItem(messages['general-view.budget.total'], total, () =>
                renderDescription(
                  messages['general-view.budget.total.description']
                )
              )}
              {renderItem(
                messages['general-view.budget.total-spent'],
                totalSpent,
                () =>
                  renderDescription(
                    messages['general-view.budget.total-spent.description']
                  )
              )}
            </>
          ) : null}
          {renderItem(messages['general-view.active-users'], numUsers, () => (
            <div className="mt-2">
              <UsersList users={budgetUsers} fetchFunction={fetchUser} />
            </div>
          ))}
        </>
      )}
    </GeneralInfo>
  );
}
