import React, { Component } from 'react';
import { Form, Row, Label } from 'reactstrap';
import { Colxx } from '../../components/common/CustomBootstrap';

import AirportLocationSearch from '../../components/search/LocationSearch';
import DateSearch from '../../components/search/DateSearch';
import SearchButton from '../../components/search/SearchButton';

import { getSearchCode, getProviders } from '../../utils/search';

import IntlMessages from '../../helpers/IntlMessages';

import SearchStatus from '../../constants/searchStatus';
import CustomErrorMessage from '../../components/CustomErrorMessage';
import { validateFields, objectValidator } from '../../utils/fieldValidation';
import { injectIntl } from 'react-intl';
import Sequence from '../../utils/sequence';

class PackageForm extends Component {
  outboundRef = React.createRef();
  inboundRef = React.createRef();
  originRef = React.createRef();
  destinationRef = React.createRef();

  constructor(props) {
    super(props);

    this.state = { errors: {} };
    this.sequence = new Sequence([
      [
        (currentProps, _) => currentProps.search.origin.value,
        () => {
          if (this.originRef.current)
            this.originRef.current.children[0].focus();
        },
      ],
      [
        (currentProps, _) => currentProps.search.destination.value,
        () => {
          if (this.destinationRef.current) {
            const currentRef = this.destinationRef.current;
            setTimeout(() => currentRef.children[0].focus(), 100);
          }
        },
      ],
      [
        (currentProps, _) => currentProps.search.outbound_date,
        () => {
          if (this.outboundRef.current) this.outboundRef.current.setFocus();
        },
      ],
      [
        (currentProps, _) => currentProps.search.inbound_date,
        () => {
          if (this.inboundRef.current) this.inboundRef.current.setFocus();
        },
      ],
    ]);
  }

  componentDidMount() {
    this.sequence.run(this.props, this.props);
  }

  componentDidUpdate(prevProps) {
    this.sequence.run(this.props, prevProps);
  }

  verifyFields = (search) => {
    const errors = validateFields(search, [
      ['origin', objectValidator],
      ['destination', objectValidator],
      ['inbound_date'],
      ['outbound_date'],
    ]);

    this.setState({ errors });

    return !Object.keys(errors).length;
  };

  removePropertyFromErrors = (property) => {
    if (property in this.state.errors) {
      const { [property]: value, ...errors } = this.state.errors;
      this.setState({ errors });
    }
  };

  // Do Search
  startSearch = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { search, startSearch } = this.props;

    if (search.status !== SearchStatus.LOADING && this.verifyFields(search)) {
      const { origin, destination, outbound_date, inbound_date, currency } =
        search;

      let searchCode = '';
      let payload = '';

      // Validate
      if (
        !origin.value ||
        !outbound_date ||
        !destination.value ||
        !inbound_date
      ) {
        return;
      }

      searchCode = getSearchCode(
        'package',
        origin.value,
        outbound_date,
        destination.value,
        inbound_date,
        currency
      );

      payload = {
        originCode: origin.value,
        originDate: outbound_date.format('DD/MM/YYYY'),
        destinationCode: destination.value,
        destinationDate: inbound_date.format('DD/MM/YYYY'),
        providers: getProviders('package'),
        isPackage: true,
        status: SearchStatus.LOADING,
      };

      startSearch(searchCode, payload);
    }
  };

  // Render
  render() {
    const { messages } = this.props.intl;
    const {
      search,
      setOriginLocation,
      setDestinationLocation,
      setOutboundDate,
      setInboundDate,
    } = this.props;
    const { errors } = this.state;

    return (
      <Form className="travel-search" onSubmit={this.startSearch}>
        <Row>
          <Colxx xxs="6">
            <div ref={this.originRef}>
              <Label
                className="form-group search has-top-label w-45 float-left"
                onFocus={() => this.removePropertyFromErrors('origin')}
              >
                <AirportLocationSearch
                  setValue={setOriginLocation}
                  value={search.origin}
                />
                <IntlMessages id="travel.origin" />
                <CustomErrorMessage error={errors.origin}>
                  {messages['forms.validation.required']}
                </CustomErrorMessage>
              </Label>
            </div>

            <Label className="form-group search has-top-label w-10 float-left addon-places">
              <img
                src="/assets/img/icons/search_icon.svg"
                alt={messages['alts.containers.search.car-form']}
              />
            </Label>

            <div ref={this.destinationRef}>
              <Label
                className="form-group search has-top-label w-45 float-left"
                onFocus={() => this.removePropertyFromErrors('destination')}
              >
                <AirportLocationSearch
                  setValue={setDestinationLocation}
                  value={search.destination}
                />
                <IntlMessages id="travel.destination" />
                <CustomErrorMessage
                  error={errors.destination}
                  style={{ animationDelay: '0.1s' }}
                >
                  {messages['forms.validation.required']}
                </CustomErrorMessage>
              </Label>
            </div>
          </Colxx>
          <Colxx xxs="4">
            <Label
              className="form-group search has-top-label float-left w-45 mr-2"
              onClick={(e) => e.preventDefault()}
              onFocus={() => this.removePropertyFromErrors('outbound_date')}
            >
              <DateSearch
                selectedDate={search.outbound_date}
                setValue={setOutboundDate}
                isStart
                defaultStartDate={search.outbound_date}
                defaultEndDate={search.inbound_date}
                ref={this.outboundRef}
              />
              <IntlMessages id="travel.outbound" />
              <CustomErrorMessage
                error={errors.outbound_date}
                style={{ animationDelay: '0.2s' }}
              >
                {messages['forms.validation.required']}
              </CustomErrorMessage>
            </Label>

            <Label
              className="form-group search has-top-label float-left w-45"
              onClick={(e) => e.preventDefault()}
              onFocus={() => this.removePropertyFromErrors('inbound_date')}
            >
              <DateSearch
                selectedDate={search.inbound_date}
                setValue={setInboundDate}
                defaultMinDate={search.outbound_date}
                isEnd
                defaultStartDate={search.outbound_date}
                defaultEndDate={search.inbound_date}
                ref={this.inboundRef}
              />
              <IntlMessages id="travel.inbound" />
              <CustomErrorMessage
                error={errors.inbound_date}
                style={{ animationDelay: '0.3s' }}
              >
                {messages['forms.validation.required']}
              </CustomErrorMessage>
            </Label>
          </Colxx>
          <SearchButton status={search.status} />
        </Row>
      </Form>
    );
  }
}

export default injectIntl(PackageForm);
