import { convertToFloat } from './money';

export function getBoletoMaxAmountBasedOnDueDate(
  boletoInfo,
  startDate = moment()
) {
  try {
    return convertToFloat(
      startDate.format('YYYY-MM-DD') > boletoInfo.due_date
        ? Math.min(boletoInfo.amount, boletoInfo.max_amount)
        : boletoInfo.max_amount
    );
  } catch (err) {
    console.error('Unable to get amount based on due date', err);
    return 0;
  }
}

export function formatBarcode(barcode) {
  return barcode.replace(
    /^(\d{5})(\d{5})(\d{5})(\d{6})(\d{5})(\d{6})(\d{1})(\d+)$/,
    '$1.$2 $3.$4 $5.$6 $7 $8'
  );
}
