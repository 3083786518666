import moment from 'moment';
import {
  FilterQueryTypes,
  TFilterQueryTypes,
} from 'src/portao3-legacy/hooks/useFilterItems/types';
import { ParamTypes } from 'src/portao3-legacy/hooks/useParams/types';
import {
  TBusGetDefaultParams,
  TBusParams,
} from 'src/portao3-legacy/types/orders/bus/busParams';
import { TBusSearchItem } from 'src/portao3-legacy/types/orders/busOrder';
import { makeContainsParamsFromDefaultSearchParams } from 'src/portao3-legacy/utils/params';

export const BusParamsConfig = [
  {
    name: 'price',
    type: ParamTypes.RANGE,
  },
  {
    name: 'stops',
    type: ParamTypes.CONTAINS,
  },
  {
    name: 'carriers',
    type: ParamTypes.CONTAINS,
    getDefaultValue: (defaultParams: TBusGetDefaultParams = {} as any) =>
      makeContainsParamsFromDefaultSearchParams({
        searchParam: defaultParams.searchParams?.carriers,
        defaultParam: defaultParams.defaultSearchParams?.carriers,
      }),
  },
  {
    name: 'seatClasses',
    type: ParamTypes.CONTAINS,
    getDefaultValue: (defaultParams: TBusGetDefaultParams = {} as any) =>
      makeContainsParamsFromDefaultSearchParams({
        searchParam: defaultParams.searchParams?.seatClasses,
        defaultParam: defaultParams.defaultSearchParams?.seatClasses,
        mapAllParams: true,
      }),
  },
  {
    name: 'departureTime',
    type: ParamTypes.RANGE_MAP,
  },
];

export const BusFilterQueries: TFilterQueryTypes<any, any, any>[] = [
  {
    name: 'price',
    type: FilterQueryTypes.RANGE,
    getValue: ({ fare = {} }: any) => fare.total,
  },
  {
    name: 'carriers',
    type: FilterQueryTypes.CONTAINS,
    getValue: ({ carrier }: TBusSearchItem) => carrier,
    allowUndefined: false,
    filterFalsy: true,
    skipIfEmpty: true,
  },
  {
    type: FilterQueryTypes.CUSTOM,
    getValue: (
      item: TBusSearchItem,
      { stops, departureTime, seatClasses }: TBusParams
    ) => {
      return item.journeys.every((journey, index) => {
        return journey.segments.some((segment) => {
          // Check for number of Stops
          const numStops = segment.legs.length - 1;

          if (stops && stops[numStops] === false) return false;

          // Check for seat classes
          if (
            !segment.legs.some(
              (leg) => seatClasses[leg.fare.treatedClass] !== false
            )
          )
            return false;

          // Check for departure time
          const departureHour = moment(segment.departureDateTime).hour(),
            segmentDepartureTime = departureTime[index];

          if (
            segmentDepartureTime &&
            (departureHour < segmentDepartureTime.min ||
              departureHour > segmentDepartureTime.max)
          )
            return false;

          return true;
        });
      });
    },
  },
];
