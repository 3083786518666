import React, { useMemo } from 'react';
import { CardBody, Row } from 'reactstrap';
import { Colxx } from '../../components/common/CustomBootstrap';
import filter from 'lodash/filter';
import find from 'lodash/find';
import isUndefined from 'lodash/isUndefined';
import forEach from 'lodash/forEach';
import orderBy from 'lodash/orderBy';
import map from 'lodash/map';
import slice from 'lodash/slice';
import { useIntl } from 'react-intl';

import { OrderTypes } from '../../constants/orderTypes';
import { registeredAirlines } from '../../constants/travelValues';

export default function HotelCardTopHotels({ orders, reservations }) {
  const { messages } = useIntl();

  const airlinesList = useMemo(() => {
    if (!(orders.length && reservations.length)) return [];

    const flightReservations = filter(reservations, (reservation) => {
      const orderExists = !isUndefined(
        find(orders, (order) => {
          return order.id === reservation.refs.orderId;
        })
      );

      return reservation.type === OrderTypes.FLIGHT && orderExists;
    });

    let airlinesList = {};

    forEach(flightReservations, (reservation) => {
      const airlineName = reservation.flightDetails.airlineOperating;

      airlinesList[airlineName] = {
        code: airlineName,
        qtd: 0,
        ...airlinesList[airlineName],
      };

      airlinesList[airlineName].qtd = airlinesList[airlineName].qtd + 1;
    });

    return slice(orderBy(airlinesList, 'qtd', ['desc']), 0, 5);
  }, [orders, reservations]);

  return (
    <CardBody>
      <Row>
        <Colxx sm="12">
          {map(airlinesList, (airline) => {
            return (
              <div
                key={airline.code}
                className="d-flex flex-row mb-3 pb-3 justify-content-between align-items-center"
              >
                <div
                  className="chain-image"
                  style={{
                    backgroundImage: `url(${
                      registeredAirlines[airline.code]
                        ? registeredAirlines[airline.code].logo
                        : ''
                    })`,
                  }}
                />
                <div className="flex-fill pl-4">
                  <p className="font-weight-medium mb-0">
                    {registeredAirlines[airline.code]
                      ? registeredAirlines[airline.code].name
                      : airline.code}
                  </p>
                </div>
                <div className="pr-2">
                  {airline.qtd}{' '}
                  {
                    messages[
                      'containers.dashboard.flight-card-top-airlines.stretches'
                    ]
                  }
                </div>
              </div>
            );
          })}
        </Colxx>
      </Row>
    </CardBody>
  );
}
