import React, { useMemo } from 'react';
import { sortByFareBasedOnProperty } from '../../../../utils/expensesDashboard';
import DefaultTab from './DefaultTab';

export default function ProjectTab({ items, ...props }) {
  const projects = useMemo(() => {
    return sortByFareBasedOnProperty(
      items,
      ({ project = {} }) => project.label
    );
  }, [items]);

  return <DefaultTab values={projects} {...props} />;
}
