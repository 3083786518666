import React from 'react';
import { ReactComponent as PersonalIcons } from '../../assets/img/expenses/personal_icon.svg';

import ContentLoader from 'react-content-loader';

export default function LoaderPersonalCard() {
  return (
    <div
      className="credit-card personal-card"
      style={{ borderColor: '#303030', backgroundColor: 'transparent' }}
    >
      <div className="content d-flex align-items-end">
        <div className="icon" style={{ width: 45 }}>
          <PersonalIcons />
        </div>
        <div className="texts">
          <ContentLoader
            speed={2}
            width={190}
            height={40}
            viewBox="0 0 190 40"
            backgroundColor="#303030"
            foregroundColor="#444444"
          >
            <rect x="0" y="0" rx="3" ry="3" width="20" height="20" />
            <rect x="25" y="0" rx="3" ry="3" width="155" height="20" />
            <rect x="0" y="25" rx="3" ry="3" width="100" height="15" />
          </ContentLoader>
        </div>
      </div>
    </div>
  );
}
