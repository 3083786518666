import { ReactComponent as YellowAlertIcon } from '../assets/img/icons/yellow-alert.svg';
import { ReactComponent as RedAlertIcon } from '../assets/img/icons/error-alert.svg';

export default function AlertBox({ type, message }) {
  const divStyle =
    type === 'warning'
      ? {
          height: '77px',
          width: '100%',
          borderRadius: '8px',
          border: '1.5px',
          padding: '16px',
          borderColor: '#EEBC40',
          borderStyle: 'solid',
          backgroundColor: '#FDF8EC',
        }
      : {
          height: '77px',
          width: '100%',
          borderRadius: '8px',
          border: '1.5px',
          padding: '16px',
          borderColor: '#A41F1F',
          borderStyle: 'solid',
          backgroundColor: '#F6E9E9',
        };

  const pTitleStyle =
    type === 'warning'
      ? {
          font: 'montserrat',
          fontWeight: '500',
          fontSize: '16px',
          lineHeight: '24px',
          color: '#A9852D',
          marginBottom: '2px',
        }
      : {
          font: 'montserrat',
          fontWeight: '500',
          fontSize: '16px',
          lineHeight: '24px',
          color: '#741616',
          marginBottom: '2px',
        };

  return (
    <div style={{ ...divStyle }}>
      <div style={{ display: 'flex' }}>
        {type === 'warning' ? <YellowAlertIcon /> : <RedAlertIcon />}
        <div style={{ marginLeft: '8px' }}>
          <div>
            <p style={{ ...pTitleStyle }}>
              {type === 'warning' ? 'Atenção' : 'Erro'}
            </p>
          </div>
          <div>
            <p
              style={{
                font: 'montserrat',
                fontWeight: '500',
                fontSize: '14px',
                lineHeight: '20px',
                color: '#7C7C82',
              }}
            >
              Caso não seja informado um motivo, a reserva será cancelada sem
              isenção de multa.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
