import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { stringToMoney } from '../../../utils/money';
import { Button } from 'reactstrap';
import SlimHotelAmenities from '../../order/SlimHotelAmenities';

import { ReactComponent as StarIcon } from '../../../assets/img/icons/star.svg';
import classNames from 'classnames';
import Category from '../../common/Category';

export default function HotelItineraryCard({ item, onClick, selected }) {
  const { messages } = useIntl();

  // States
  const { hotelName, hotelAddress, hotelImage, category, total } =
    useMemo(() => {
      const { name, mainPhoto, category, destination } = item.hotel;

      const cheapestRoom = item.rooms.sort((a, b) => a.fare - b.fare)[0];

      return {
        hotelName: name,
        hotelAddress: destination.address,
        hotelImage: mainPhoto,
        category,
        total: cheapestRoom
          ? stringToMoney(cheapestRoom.fare, 0, cheapestRoom.currency)
          : '',
      };
    }, [item]);

  return (
    <div
      className={classNames(
        'd-flex flex-column justify-content-between position-relative itinerary-card hotel',
        { selected }
      )}
      onClick={onClick}
    >
      {item.favorite && (
        <div
          className="favorite-bagde absolute d-flex align-items-center justify-content-center py-2"
          title={messages['alts.containers.search.favorite-hotel']}
          style={{ top: 10 }}
        >
          <StarIcon
            width={10}
            height={10}
            className="star-icon"
            title={messages['alts.containers.search.favorite-hotel']}
            fill="white"
          />
        </div>
      )}
      <img
        src={hotelImage}
        alt={hotelName}
        className="w-100 hotel-img"
        style={{ height: '35%', objectFit: 'cover' }}
      />
      <div
        className="p-3 d-flex flex-column justify-content-between position-relative"
        style={{ height: '65%' }}
      >
        <div>
          <div className="d-flex">
            <Category category={category} />
          </div>
          <div className="my-2">
            <h2 className="m-0 text-dark font-primary title fs-1rem">
              {hotelName}
            </h2>
            <p className=" m-0 p-0 text-muted fs-0-7rem address">
              {hotelAddress}
            </p>
          </div>
          <div className="position-absolute d-flex">
            <SlimHotelAmenities
              hotel={item.hotel}
              hotelId={item.hotelId}
              maxItems={5}
              iconSize={18}
            />
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <p className="m-0 font-primary one-line-height medium fs-0-6rem">
              {messages['general.from']}
            </p>
            <h3 className="m-0 font-primary text-dark font-weight-medium one-line-height mt-1 fs-1rem">
              {total}
            </h3>
          </div>
          <Button size="sm" className="mt-2 fs-0-7rem" color="primary">
            {messages['general.select']}
          </Button>
        </div>
      </div>
    </div>
  );
}
