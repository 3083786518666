import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { stringToMoney } from '../../../utils/money';
import Badge from '../Badge';
import { getPriceDifference, getSelectedClass } from '../utils';
import moment from 'moment';
import CurrencyWrapper from '../../common/CurrencyWrapper';

export default function SearchHotelAlternative({
  alternative,
  originalTotal,
  selectedItem,
  index,
  key,
  handleSelectHotel,
  handleCloseAlernatives,
}) {
  const { messages, formatMessage } = useIntl();

  const {
    hotelName,
    hotelImage,
    category,
    roomName,
    total,
    _badgeText,
    cancelUntil,
    hasBreakfast,
  } = useMemo(() => {
    const selectedRoom = alternative.rooms[0];

    const today = moment().format('YYYY-MM-DD');

    const _badgeText =
      index === 0
        ? messages['order.alternative.best-price']
        : formatMessage(
            {
              id: 'order.alternative.save',
            },
            {
              save: getPriceDifference(
                originalTotal,
                alternative.fare.total,
                alternative.fare.currency
              ),
            }
          );

    return {
      hotelName: alternative.hotel.name,
      hotelImage: alternative.hotel.mainPhoto,
      roomName: selectedRoom.description.toLowerCase(),
      category: new Array(alternative.hotel.category).fill(1),
      total: alternative.fare.total,
      _badgeText,
      cancelUntil:
        selectedRoom?.cancellation?.length > 0 &&
        today <= selectedRoom.cancellation[0].startDate
          ? formatMessage(
              {
                id: 'order.alternative.cancellation',
              },
              {
                date: moment(
                  selectedRoom.cancellation[0].startDate,
                  'YYYY-MM-DD'
                ).format('DD/MM/YYYY'),
              }
            )
          : null,
      hasBreakfast: selectedRoom.board?.breakfast,
    };
  }, [alternative]);

  return (
    <div
      style={{
        width: '220px',
        height: '300px',
        display: 'flex',
        flexDirection: 'column',
        marginRight: '10px',
        border: '1px solid #E5E5E5',
        borderRadius: '5px',
        cursor: 'pointer',
      }}
      onClick={() => {
        handleCloseAlernatives();
        handleSelectHotel(alternative);
      }}
    >
      {hotelImage != null && hotelImage != '' ? (
        <img
          src={hotelImage}
          alt={hotelName}
          style={{ height: '103px', objectFit: 'cover', minHeight: '103px' }}
        />
      ) : (
        <div
          style={{
            height: '103px',
            backgroundColor: '#E5E5E5',
          }}
        />
      )}
      <div
        style={{
          paddingBottom: '16px',
          paddingLeft: '16px',
          paddingRight: '16px',
          paddingTop: '12px',
        }}
      >
        <div>
          <div className="d-flex" style={{ marginBottom: '8px' }}>
            {category.map((_, index) => (
              <img
                key={`category-${index}`}
                src="/assets/img/hotel-star.svg"
                className="mr-1"
                width={10}
                height={10}
                alt={
                  messages['alts.containers.search.hotel-item.hotel-category']
                }
              />
            ))}
          </div>
          <h2
            style={{
              fontFamily: 'montserrat',
              color: '#000000',
              lineHeight: '24px',
              marginBottom: '4px',
              maxWidth: '200px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              fontSize: '20px',
            }}
          >
            {hotelName}
          </h2>
          <div
            style={{
              display: 'flex',
              marginBottom: '12px',
              height: '17px',
            }}
          >
            {hasBreakfast && (
              <img
                style={{ width: 17, marginRight: '8px' }}
                src="/assets/img/icons/amenities/meal-breakfast.svg"
                alt={
                  messages['containers.search.hotel-modal.breakfast-included']
                }
                title={
                  messages['containers.search.hotel-modal.breakfast-included']
                }
              />
            )}
            {cancelUntil && (
              <img
                style={{ width: 17, marginRight: '8px' }}
                src="/assets/img/icons/cancel.svg"
                alt={cancelUntil}
                title={cancelUntil}
              />
            )}
          </div>
          <div
            style={{
              width: '178px',
              maxHeight: '48px',
              borderRadius: '0px 32px 32px 0px',
              backgroundColor: '#ECECF4',
              overflow: 'hidden',
              padding: '8px 16px',
            }}
          >
            <p
              style={{
                fontFamily: 'montserrat',
                textTransform: 'capitalize',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                fontWeight: 500,
                margin: 0,
              }}
            >
              {roomName}
            </p>
          </div>
        </div>
        <div
          className="d-flex"
          style={{
            marginTop: '12px',
            justifyContent: 'flex-end',
          }}
        >
          <div
            style={{
              height: '20px',
              borderRadius: '8px',
              backgroundColor: '#5AA77E',
              color: 'white',
              textAlign: 'center',
            }}
          >
            <span style={{ padding: '8px' }}>{_badgeText}</span>
          </div>
          <p
            style={{
              marginTop: '4px',
              marginLeft: '5px',
              fontFamily: 'montserrat',
              fontWeight: 500,
              lineHeight: '16px',
              color: '#44444D',
              marginBottom: '4px',
            }}
          >
            {messages['general.total']}
          </p>
        </div>
        <h3
          style={{
            fontFamily: 'montserrat',
            fontWeight: 500,
            fontSize: '18px',
            lineHeight: '16px',
            color: '#44444D',
          }}
        >
          <CurrencyWrapper value={total} tooltipFill="black" decimalPlaces={0}>
            {({ formattedValue }) => <span>{formattedValue}</span>}
          </CurrencyWrapper>
        </h3>
      </div>
    </div>
  );
}
