import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useIntl } from 'react-intl';
import firebaseApp from '../../../../services/Firebase';
import useDataLoader from '../../../../hooks/useDataLoader';
import LoadingAlternative from '../../../alternatives/LoadingAlternative';
import { sortReservationJourneys } from '../../../../utils/reservation';
import AlternativeCards from '../../../alternatives/AlternativeCards';
import TravelDuplicityVerifier from '../../../travels/TravelDuplicityVerifier';
import { NEEDS_SORTED_RESERVATION } from '../consts';
import PaymentForm from '../Forms/PaymentForm';
import ApprovalForm from '../Forms/ApprovalForm';
import CommentInput from '../../../expensesTimeline/CommentInput';
import ReservationInformation from '../../ReservationInformation/ReservationInformation';

export default function OrganizationApproval({
  approvals,
  order,
  goForApproval,
  toggleModal,
  reservations,
  requestToApprove,
  requestToIssue,
  requestToReject,
  error,
  setError,
  ongoingAction,
  allowAction,
  shouldDisableButtons,
  isRejecting,
  setIsRejecting,
}) {
  const { messages } = useIntl();
  const { user } = useSelector((state) => state.auth);

  // Hooks
  const [alternatives, _, loadingAlternatives] = useDataLoader({
    fetchFunction: () => firebaseApp.getOrderAlternatives(order.id).get(),
    prepareData: (data) => data.sort((a, b) => a.fare.total - b.fare.total),
  });

  // States
  const [hasDuplicity, setHasDuplicity] = useState(false);
  const [credit, setCredit] = useState(null);
  const [comment, setComment] = useState('');

  const hasAlternatives = useMemo(
    () => alternatives.length > 0,
    [alternatives]
  );

  const sortedReservations = useMemo(() => {
    if (NEEDS_SORTED_RESERVATION.includes(order.type)) {
      return sortReservationJourneys(reservations);
    } else return reservations;
  }, [order, reservations]);

  const Component = useMemo(
    () => (user.admin ? PaymentForm : ApprovalForm),
    [user]
  );

  // Render
  const renderAlternatives = () => {
    if (loadingAlternatives) {
      return (
        <div className="d-flex flex-shrink-0">
          <LoadingAlternative style={{ marginRight: 10 }} />
          <LoadingAlternative style={{ marginRight: 10 }} />
          <LoadingAlternative />
        </div>
      );
    } else {
      return hasAlternatives ? (
        <AlternativeCards
          key={hasDuplicity ? 'duplicity' : 'without-duplicity'}
          alternatives={alternatives}
          order={order}
          reservations={sortedReservations}
        />
      ) : (
        <div>
          <div className="alternative-card d-flex flex-column justify-content-center align-items-center">
            <span role="img" aria-label="Woohoo!">
              😍
            </span>
            {messages['travel.best-option']}
          </div>
        </div>
      );
    }
  };

  const renderAlternativesContainer = () => (
    <div className="flex-grow-1 alternatives-container mw-0 mt-3">
      <h5>{messages['travel.alternatives']}</h5>
      <div className="content">{renderAlternatives()}</div>
    </div>
  );

  const renderReservationDetailsCards = () => (
    <div>
      <h5 className="hotel-subtitle mt-5">{messages['travel.details']}</h5>
      <ReservationInformation order={order} reservations={reservations} />
    </div>
  );

  const renderStandard = () => (
    <div className="d-flex mb-5 w-100 mw-0 flex-column">
      <TravelDuplicityVerifier
        order={order}
        reservations={sortedReservations}
        onDuplicity={setHasDuplicity}
        className="mr-3"
      />

      {renderAlternativesContainer()}
      {renderReservationDetailsCards()}
    </div>
  );

  const renderRejecting = () => {
    return (
      <div className="w-60 ml-auto mt-5">
        <CommentInput
          value={comment}
          onChange={setComment}
          placeholder={messages['travel.comment-placeholder']}
        />
      </div>
    );
  };

  const renderContent = () => {
    return isRejecting ? renderRejecting() : renderStandard();
  };

  return (
    <div className="approval-steps h-100">
      <PerfectScrollbar
        options={{
          suppressScrollX: true,
          wheelPropagation: false,
          useBothWheelAxes: false,
        }}
      >
        <h3 className="hotel-title">
          {messages[isRejecting ? 'travel.reject-trip' : 'travel.approve-trip']}
        </h3>

        {renderContent()}

        <Component
          approvals={approvals}
          order={order}
          goForApproval={goForApproval}
          toggleModal={toggleModal}
          requestToIssue={requestToIssue}
          requestToApprove={requestToApprove}
          requestToReject={() => requestToReject(comment)}
          ongoingAction={ongoingAction}
          error={error}
          setError={setError}
          allowAction={allowAction}
          shouldDisableButtons={shouldDisableButtons}
          credit={credit}
          setCredit={setCredit}
          isRejecting={isRejecting}
          setIsRejecting={setIsRejecting}
        />
      </PerfectScrollbar>
    </div>
  );
}
