import React from 'react';
import { Row, Card, CardBody } from 'reactstrap';
import { Colxx } from '../../common/CustomBootstrap';
import filter from 'lodash/filter';
import sumBy from 'lodash/sumBy';

import LoaderAnalyticsSingle from '../../loaders/LoaderAnalyticsSingle';

import { OrderTypes } from '../../../constants/orderTypes';

export default function ESGTopCarJourneys({ orders, loading }) {
  const carOrders = filter(orders, (o) => {
    return o.type === OrderTypes.CAR;
  });

  if (loading) {
    return (
      <Card className="dashboard-small-chart h-100">
        <CardBody>
          <Row className="w-100 mb-4">
            <Colxx xxs="6">
              <LoaderAnalyticsSingle />
            </Colxx>
            <Colxx xxs="6">
              <LoaderAnalyticsSingle />
            </Colxx>
          </Row>
        </CardBody>
      </Card>
    );
  }

  return (
    <Card className="dashboard-small-chart h-100">
      <CardBody>
        <Row className="w-100 mb-4">
          <Colxx xxs="6">
            <p className="lead color-theme-1 mb-1 value">
              {sumBy(carOrders, (o) => parseFloat(o.esg.carbon_kg)).toFixed(0)}{' '}
              Kg
            </p>
            <p className="text-muted">
              {(
                (sumBy(carOrders, (o) => parseFloat(o.esg.carbon_kg)) /
                  sumBy(orders, (o) => parseFloat(o.esg.carbon_kg))) *
                100
              ).toFixed(0)}
              % do total emitido
            </p>
          </Colxx>
        </Row>
      </CardBody>
    </Card>
  );
}
