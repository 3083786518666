import { Component, Fragment } from 'react';
import { Row } from 'reactstrap';
import { Colxx } from '../../components/common/CustomBootstrap';
import moment from 'moment';
import qs from 'query-string';

import { injectIntl } from 'react-intl';

import PackageForm from '../../containers/search/PackageForm';
import FlightForm from '../../containers/search/FlightForm';
import HotelForm from '../../containers/search/HotelForm';
import CarForm from '../../containers/search/CarForm';
import BusForm from '../../containers/search/BusForm';
import OfficeForm from '../../containers/search/OfficeForm';

import PackageSearch from '../../containers/search/PackageSearch';
import FlightSearch from '../../containers/search/FlightSearch.tsx';
import HotelSearch from 'src/portao3-legacy/containers/search/HotelSearch.tsx';
import CarSearch from '../../containers/search/CarSearch.tsx';
import BusSearch from '../../containers/search/BusSearch.tsx';
import OfficeSearch from '../../containers/search/OfficeSearch';

import firebaseApp from '../../services/Firebase';
import { trackPropertiesSegment } from '../../utils/segment';

import { connect } from 'react-redux';
import { DEFAULT_CURRENCY } from '../../utils/money';
import searchStatus from '../../constants/searchStatus';

import equals from 'fast-deep-equal';
import TravelTab from '../../components/travels/TravelTab';
import { getUserNationality } from '../../utils/nationality';
import Itinerary from '../../containers/search/Itinerary';
import { clearSearchCodesWithError } from '../../redux/actions';

let searchListener = () => {};

class NewRequest extends Component {
  constructor(props) {
    super(props);

    this.initialState = {
      search: {
        currency: props.currency || DEFAULT_CURRENCY,
        origin: {},
        destination: {},
        outbound_date: null,
        inbound_date: null,
        searchCode: '',
        status: searchStatus.NEW,
        hotel_params: {
          adults: 1,
          rooms: 1,
          nationality: getUserNationality({
            user: props.user,
            organization: props.organization,
          }),
        },
        flightParams: {
          baggages: 0,
          cabin: 'Economy',
        },
        twoWay: true,
        dropOffSamePickUp: true,
      },
      activeTab: 'flight',
      autoSearch: false,
    };

    this.state = this.initialState;
  }

  // Lifecycle
  componentWillUnmount() {
    searchListener();
  }

  componentDidUpdate(prevProps, prevState) {
    const oldLocation = prevProps.location;
    const location = this.props.location;
    const search = this.state.search;

    if (
      `${oldLocation.pathname}${oldLocation.search}` !==
      `${location.pathname}${location.search}`
    ) {
      this.updateParams();
    }

    if (
      prevState.search.status === search.status &&
      search.status !== searchStatus.NEW &&
      !equals(prevState.search, search)
    ) {
      return this.setState({
        search: {
          ...search,
          status: searchStatus.NEW,
          searchCode: '',
        },
      });
    }

    if (
      this.props.searchCodesWithError.includes(this.state.search.searchCode)
    ) {
      this.props.clearSearchCodesWithError(this.state.search.searchCode);

      return this.setState({
        autoSearch: true,
        search: {
          ...search,
          status: searchStatus.NEW,
        },
      });
    }
  }

  componentDidMount() {
    this.updateParams();
  }

  makeTitle = (service, search) => {
    const location = search.destination?.label
      ? search.destination.label
      : search.origin?.label;

    const {
      intl: { formatMessage, messages },
    } = this.props;

    return formatMessage(
      { id: 'views.travel.new-requests.searching' },
      { service: messages[`service.${service}`] || service, location }
    );
  };

  updateParams = () => {
    const query = qs.parse(this.props.location.search);

    if (query.params) {
      const params = JSON.parse(query.params);

      if (params.activeTab) {
        this.setState({
          autoSearch: Boolean(params.autoSearch),
          activeTab: params.activeTab,
          search: {
            ...params.search,
            outbound_date: moment(params.search.outbound_date),
            inbound_date: moment(params.search.inbound_date),
          },
        });

        document.title = this.makeTitle(params.activeTab, params.search);
      }
    }
  };

  // Form Control
  swapLocations = () => {
    const { origin, destination } = this.state.search;

    this.setState({
      search: {
        ...this.state.search,
        origin: destination,
        destination: origin,
      },
    });
  };

  setOriginLocation = (origin) => {
    const { search } = this.state;

    this.setState({
      search: {
        ...search,
        origin,
      },
    });
  };

  setDestinationLocation = (destination) => {
    const { search } = this.state;

    this.setState({
      search: {
        ...search,
        destination,
      },
    });
  };

  setOutboundDate = (outbound_date) => {
    const { search } = this.state;

    let inbound_date = search.inbound_date;
    if (moment(outbound_date).isAfter(inbound_date)) inbound_date = null;

    this.setState({
      search: {
        ...search,
        outbound_date: outbound_date ? moment(outbound_date) : '',
        inbound_date,
      },
    });
  };

  setInboundDate = (inbound_date) => {
    const { search } = this.state;

    this.setState({
      search: {
        ...search,
        inbound_date: inbound_date ? moment(inbound_date) : '',
      },
    });
  };

  setNationality = (nationality) => {
    const { search } = this.state;

    this.setState({
      search: {
        ...search,
        hotel_params: {
          ...search.hotel_params,
          nationality,
        },
      },
    });
  };

  setAdult = (adults) => {
    const { search } = this.state;

    this.setState({
      search: {
        ...search,
        hotel_params: {
          ...search.hotel_params,
          adults: adults,
        },
      },
    });
  };

  setBaggage = (e) => {
    const { search } = this.state;

    this.setState({
      search: {
        ...search,
        flightParams: {
          ...search.flightParams,
          baggages: parseInt(e.target.value),
        },
      },
    });
  };

  setCabin = (e) => {
    const { search } = this.state;

    this.setState({
      search: {
        ...search,
        flightParams: {
          ...search.flightParams,
          cabin: e.target.value,
        },
      },
    });
  };

  removeRoom = () => {
    const { search } = this.state;

    this.setState({
      search: {
        ...search,
        hotel_params: {
          ...search.hotel_params,
          rooms: search.hotel_params.rooms - 1,
        },
      },
    });
  };

  addRoom = () => {
    const { search } = this.state;

    this.setState({
      search: {
        ...search,
        hotel_params: {
          ...search.hotel_params,
          rooms: search.hotel_params.rooms + 1,
        },
      },
    });
  };

  toggleDropOffSamePickUp = () => {
    const { search } = this.state;

    this.setState({
      search: {
        ...search,
        dropOffSamePickUp: !search.dropOffSamePickUp,
        destinationCoord: {},
      },
    });
  };

  toggleTwoWay = () => {
    const { search } = this.state;

    this.setState({
      search: {
        ...search,
        twoWay: !search.twoWay,
        inbound_date: null,
      },
    });
  };

  // Search Actions
  startSearch = async (searchCode, payload) => {
    const { activeTab, search } = this.state;
    const { currency = DEFAULT_CURRENCY } = this.props;

    if (search?.searchCode === searchCode) return;

    payload = { ...payload, currency };

    await firebaseApp.createSearch(searchCode, payload);

    const params = encodeURIComponent(JSON.stringify({ activeTab, search }));
    const title = this.makeTitle(activeTab, search);

    window.history.replaceState(
      null,
      title,
      `/travel/search/${activeTab}?params=${params}`
    );
    document.title = title;

    trackPropertiesSegment('New Search', payload);

    console.log(`Search code: ${searchCode}`);

    this.setState(
      {
        search: { ...search, status: searchStatus.LOADING, searchCode },
        autoSearch: false,
      },
      () => {
        this.waitSearch();
      }
    );
  };

  waitSearch = async () => {
    const { search } = this.state;

    searchListener();

    searchListener = firebaseApp
      .getSearch(search.searchCode)
      .onSnapshot((searchSnap) => {
        // If search still exists
        if (searchSnap.data()) {
          this.setState({
            search: {
              ...search,
              status: searchSnap.data().status,
            },
          });
        } else {
          this.setState({
            search: {
              ...search,
              status: searchStatus.NEW,
              searchCode: '',
            },
          });

          searchListener();
        }
      });
  };

  // Tabs
  toggleTab = (activeTab) => {
    const { search, activeTab: oldActiveTab } = this.state;

    // Won't change tab while searching
    if (search.status === searchStatus.LOADING) return;

    if (search.status === searchStatus.DONE) {
      window.history.replaceState(null, 'Portão 3', `/travel`);
      document.title = 'Portão 3';
    }

    if (activeTab !== oldActiveTab) {
      this.setState({
        ...this.initialState,
        activeTab,
      });
    }
  };

  // Render
  renderResults() {
    const { search, activeTab, autoSearch } = this.state;

    if (activeTab === 'package') {
      return (
        <div>
          <PackageForm
            search={search}
            startSearch={this.startSearch}
            setOriginLocation={this.setOriginLocation}
            setDestinationLocation={this.setDestinationLocation}
            setOutboundDate={this.setOutboundDate}
            setInboundDate={this.setInboundDate}
          />

          <PackageSearch search={search} />
        </div>
      );
    }

    if (activeTab === 'flight') {
      return (
        <div>
          <FlightForm
            search={search}
            startSearch={this.startSearch}
            setOriginLocation={this.setOriginLocation}
            setDestinationLocation={this.setDestinationLocation}
            setOutboundDate={this.setOutboundDate}
            setInboundDate={this.setInboundDate}
            toggleTwoWay={this.toggleTwoWay}
            setBaggage={this.setBaggage}
            setCabin={this.setCabin}
            swapLocations={this.swapLocations}
            autoSearch={autoSearch}
            organization={this.props.organization}
          />

          <FlightSearch search={search} key={search.searchCode} />
        </div>
      );
    }

    if (activeTab === 'hotel') {
      return (
        <div>
          <HotelForm
            search={search}
            startSearch={this.startSearch}
            setDestinationLocation={this.setDestinationLocation}
            setOutboundDate={this.setOutboundDate}
            setInboundDate={this.setInboundDate}
            setNationality={this.setNationality}
            removeRoom={this.removeRoom}
            addRoom={this.addRoom}
            setAdult={this.setAdult}
            autoSearch={autoSearch}
            organization={this.props.organization}
          />

          <HotelSearch search={search} key={search.searchCode} />
        </div>
      );
    }

    if (activeTab === 'car') {
      return (
        <div>
          <CarForm
            search={search}
            startSearch={this.startSearch}
            setOriginLocation={this.setOriginLocation}
            setDestinationLocation={this.setDestinationLocation}
            setOutboundDate={this.setOutboundDate}
            setInboundDate={this.setInboundDate}
            toggleDropOffSamePickUp={this.toggleDropOffSamePickUp}
            autoSearch={autoSearch}
          />

          <CarSearch search={search} key={search.searchCode} />
        </div>
      );
    }

    if (activeTab === 'bus') {
      return (
        <div>
          <BusForm
            search={search}
            startSearch={this.startSearch}
            setOriginLocation={this.setOriginLocation}
            setDestinationLocation={this.setDestinationLocation}
            setOutboundDate={this.setOutboundDate}
            setInboundDate={this.setInboundDate}
            toggleTwoWay={this.toggleTwoWay}
            swapLocations={this.swapLocations}
            autoSearch={autoSearch}
          />

          <BusSearch search={search} key={search.searchCode} />
        </div>
      );
    }

    if (activeTab === 'office') {
      return (
        <div>
          <OfficeForm
            search={search}
            startSearch={this.startSearch}
            setOriginLocation={this.setOriginLocation}
            setOutboundDate={this.setOutboundDate}
          />

          <OfficeSearch search={search} />
        </div>
      );
    }

    if (activeTab === 'itinerary') {
      return (
        <div>
          <Itinerary />
        </div>
      );
    }
  }

  render() {
    const { activeTab } = this.state;

    return (
      <Fragment>
        <Row>
          <Colxx xxs="12" className="mb-4">
            <TravelTab activeTab={activeTab} toggleTab={this.toggleTab} />
          </Colxx>
        </Row>

        <div className="pb-5">{this.renderResults()}</div>
      </Fragment>
    );
  }
}

const mapToProps = ({ auth, timeline }) => {
  return {
    user: auth.user,
    organization: auth.organization,
    currency: auth.organization?.currency || DEFAULT_CURRENCY,
    searchCodesWithError: timeline.searchCodesWithError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    clearSearchCodesWithError: (searchCode) =>
      dispatch(clearSearchCodesWithError(searchCode)),
  };
};

export default connect(mapToProps, mapDispatchToProps)(injectIntl(NewRequest));
