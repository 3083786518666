import {
  SET_PASSENGER,
  SET_SEARCH_CODES_WITH_ERROR,
  CLEAR_SEARCH_CODES_WITH_ERROR,
} from '../actions';

export const setPassenger = (passenger) => ({
  type: SET_PASSENGER,
  payload: passenger,
});

export const setSearchCodesWithError = (searchCodes = []) => ({
  type: SET_SEARCH_CODES_WITH_ERROR,
  payload: searchCodes,
});

export const clearSearchCodesWithError = (searchCode) => ({
  type: CLEAR_SEARCH_CODES_WITH_ERROR,
  payload: searchCode,
});
