import React, { useMemo } from 'react';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { Label, Row } from 'reactstrap';
import { Colxx } from '../../../common/CustomBootstrap';

export default function BoletoInvoiceInfo({ item }) {
  const { messages } = useIntl();

  // States
  const { paidDate, transactionAuth } = useMemo(() => {
    if (!item) return {};

    const { authentication_api, updated_at } = item.invoice.transaction_info;

    return {
      paidDate: moment(updated_at)
        .utcOffset('-3000')
        .format('DD/MM/YYYY HH:mm:ss'),
      transactionAuth: authentication_api.BlocoCompleto,
    };
  }, [item]);

  // Render
  return (
    <>
      <div className="mt-4 mb-2 ml-2">
        <h5>{messages['administrative-expense-item.paid-info']}</h5>
      </div>

      <div className="reservation-params refund-params light">
        <Row>
          <Colxx sm="12">
            <Label className="form-group disabled search has-top-label">
              <p className="text-uppercase">{paidDate}</p>
              <span>{messages['administrative.boleto.paid-date']}</span>
            </Label>
          </Colxx>
          <Colxx sm="12">
            <Label className="form-group disabled search has-top-label">
              <p
                className="text-uppercase"
                style={{ overflowWrap: 'break-word' }}
              >
                {transactionAuth}
              </p>
              <span>{messages['administrative.boleto.auth-code']}</span>
            </Label>
          </Colxx>
        </Row>
      </div>
    </>
  );
}
