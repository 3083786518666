import classNames from 'classnames';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import GenericEmptyMessage from '../../containers/expenses/Recurrence/GenericEmptyMessage';
import { loadingSeats } from '../../constants/seats';

import { ReactComponent as SelectedSeat } from '../../assets/img/icons/close.svg';
import { ReactComponent as EmptyIcon } from '../../assets/img/expenses/no_search_recurrence.svg';
import { ReactComponent as FrontBus } from '../../assets/img/icons/frontBus.svg';
import { ReactComponent as BackBus } from '../../assets/img/icons/backBus.svg';
import FloorSelector from './FloorSelector';

export default function SeatMap({
  seats = [],
  loading,
  selectedSeat,
  onSelectSeat,
  withLegend = true,
}) {
  const { messages } = useIntl();

  const mapRef = useRef(null);
  const frontBusRef = useRef(null);
  const backBusRef = useRef(null);

  // States
  const [z, setZ] = useState(0);
  const [padding, setPadding] = useState({});

  const {
    floorSeats,
    columnSize,
    numFloors = 1,
  } = useMemo(() => {
    if (!seats.length) return { floorSeats: loadingSeats, columnSize: 5 };

    return {
      floorSeats: seats[z].seats,
      columnSize: seats[z].columnSize,
      numFloors: seats.length,
    };
  }, [seats, z]);

  const gridTemplateRows = useMemo(
    () => `repeat(${columnSize}, 1fr)`,
    [columnSize]
  );

  const legends = useMemo(
    () => [
      {
        id: 'occupied',
        className: 'occupied',
        title: messages['seat-map.legend.occupied'],
        Icon: SelectedSeat,
      },
      {
        id: 'available',
        className: 'available',
        title: messages['seat-map.legend.available'],
      },
      {
        id: 'selected',
        className: 'selected',
        title: messages['seat-map.legend.selected'],
      },
    ],
    []
  );

  // Effect
  useEffect(() => {
    if (mapRef.current) {
      const resizeObserver = new ResizeObserver(() => {
        setPadding({
          left: frontBusRef.current?.clientWidth || 0,
          right: backBusRef.current?.clientWidth || 0,
        });
      });

      resizeObserver.observe(mapRef.current);
    }
  }, []);

  // Render
  if (!loading && !seats)
    return (
      <GenericEmptyMessage
        Icon={EmptyIcon}
        title={messages['seat-map.empty.title']}
        description={messages['seat-map.empty.description']}
      />
    );

  const renderColumn = (seats) => {
    return seats.map(({ id, available, position }, index) => {
      const selected = id === selectedSeat;

      return (
        <div
          key={`seat-${id}-${index}`}
          className={classNames(
            'seat flex-center',
            loading
              ? 'fetching'
              : {
                  available,
                  selected,
                }
          )}
          style={{ gridRow: columnSize - position.y }}
          onClick={() => available && !selected && onSelectSeat(id)}
        >
          {available ? (
            <span className="seat-id">{id}</span>
          ) : (
            <SelectedSeat
              className="not-available-icon"
              width="40%"
              height="40%"
            />
          )}
        </div>
      );
    });
  };

  const renderContent = () => {
    return floorSeats.map((columnSeats, index) => (
      <div
        key={`column-${index}`}
        className="seat-column"
        style={{ gridTemplateRows }}
      >
        {renderColumn(columnSeats)}
      </div>
    ));
  };

  return (
    <div className="seat-map">
      <div className="d-flex align-items-center">
        <div
          className="bus d-flex"
          style={{ paddingLeft: padding.left, paddingRight: padding.right }}
        >
          <FrontBus
            className="bus-frame front"
            height="100%"
            ref={frontBusRef}
          />
          <div className="d-flex content py-3" ref={mapRef}>
            {renderContent()}
          </div>
          <BackBus className="bus-frame back" height="100%" ref={backBusRef} />
        </div>

        {numFloors > 1 ? (
          <FloorSelector
            className="ml-4"
            floor={z}
            setFloor={setZ}
            numFloors={numFloors}
          />
        ) : null}
      </div>

      {withLegend ? (
        <div className="d-flex justify-content-center mt-4 no-selection">
          {legends.map(({ id, className, title, Icon }) => (
            <div
              key={id}
              className={classNames(
                'legend d-flex align-items-center mx-3',
                className
              )}
            >
              <div className="circle flex-shrink-0 flex-center">
                {Icon ? (
                  <Icon className="icon" width="50%" height="50%" />
                ) : null}
              </div>
              <span className="ml-2">{title}</span>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
}
