import { useEffect, useMemo } from 'react';
import firebaseApp from '../services/Firebase';
import { useDispatch, useSelector } from 'react-redux';
import { OrderTypes } from '../constants/orderTypes';
import useApprovals from '../hooks/useApprovals';
import { RefundStatus } from '../constants/refundStatus';
import { setApprovalInfo } from '../redux/actions';

const NEEDS_RESERVATIONS = [
  OrderTypes.HOTEL,
  OrderTypes.CAR,
  OrderTypes.OFFICE,
];

const loadReservations = async (order) => {
  try {
    const snap = await firebaseApp
      .getReservationsFromOrderId(order.organizationId, order.id)
      .get();

    const reservations = [];

    snap.forEach((docRef) => {
      reservations.push({
        ...docRef.data(),
        id: docRef.id,
      });
    });

    return reservations;
  } catch (err) {
    console.error(err);
  }
};

const fetchTravelComplementary = (order) =>
  new Promise(async (res, _) => {
    if (NEEDS_RESERVATIONS.includes(order.type)) {
      const reservations = await loadReservations(order);
      order['reservations'] = reservations;
    }

    res(order);
  });

const checkBudgetStatusCode = (statusCode) =>
  [RefundStatus.APPROVING_ANTICIPATION, RefundStatus.APPROVING].includes(
    statusCode
  );

const getPassengerFullname = ({ firstName, lastName }) =>
  `${firstName} ${lastName}`;

export default function NotificationCenter() {
  const { user } = useSelector(({ auth }) => auth);
  const dispatch = useDispatch();

  const [travelApprovals, orders, loadingTravel] = useApprovals({
    user,
    fetchComplementary: fetchTravelComplementary,
  });

  const [budgetApprovals, budgets, loadingBudgets] = useApprovals({
    user,
    fetchApprovals: firebaseApp.getPendingBudgetApprovalsFromUser,
    fetchOrders: firebaseApp.getBudgetById,
    approvalType: 'expenses',
    checkStatusCode: checkBudgetStatusCode,
  });

  const fullyLoaded = useMemo(
    () => !(loadingTravel && loadingBudgets),
    [loadingTravel, loadingBudgets]
  );

  const numOrders = useMemo(() => {
    if (fullyLoaded)
      return [orders, budgets].reduce(
        (sum, obj) => sum + Object.keys(obj).length,
        0
      );
    else return 0;
  }, [fullyLoaded, orders, budgets]);

  const [approvals, lastIndex] = useMemo(() => {
    if (fullyLoaded) {
      const approvals = Object.keys(budgetApprovals).reduce(
        (obj, key) => {
          const approval = budgetApprovals[key];

          if (key in obj) {
            obj[key]['orders'] = [
              ...obj[key]['orders'],
              ...approval.orders,
            ].sort((a, b) => a.createdAt - b.createdAt);
          } else obj[key] = approval;

          return obj;
        },
        { ...travelApprovals }
      );

      const sortedApprovals = Object.values(approvals).sort((a, b) =>
        getPassengerFullname(a.passenger).localeCompare(
          getPassengerFullname(b.passenger)
        )
      );

      return [sortedApprovals, sortedApprovals.length];
    } else return [{}, 0];
  }, [fullyLoaded, budgetApprovals, travelApprovals]);

  useEffect(() => {
    if (user) {
      dispatch(
        setApprovalInfo({
          loading: !fullyLoaded,
          approvals,
          lastIndex,
          numOrders,
        })
      );
    }
  }, [user, fullyLoaded, approvals, lastIndex, numOrders]);

  return null;
}
