import map from 'lodash/map';
import axios from 'axios';

export async function findHotelLocation(token, value) {
  try {
    const request = `${process.env.REACT_APP_API_URL}/hotel/locations/autocomplete`;
    const res = await axios.get(request, {
      params: { search: value, removeHotels: true },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return res;
  } catch (err) {
    throw err;
  }
}

export function filterHotelLocations(locations) {
  return locations.filter((location) => location.type !== 'airport');
}

export function resultDestinations(filteredData, responseService, locale) {
  const result = map(
    filteredData.length ? filteredData : responseService,
    (hit) => {
      return {
        value: hit.destinationId,
        cityCode: hit.destinationId,
        label: hit.name[locale] || hit.name['pt-BR'],
        countryName: hit.countryId,
        type: hit.type,
      };
    }
  );

  return result;
}
