import React, { useMemo } from 'react';
import ImagePreview from './ImagePreview';
import PDFPreview from './PDFPreview';

export default function FilePreview({ selectedFile = {}, ...props }) {
  const Component = useMemo(
    () =>
      selectedFile.mimeType === 'application/pdf' ? PDFPreview : ImagePreview,
    [selectedFile]
  );

  return <Component selectedFile={selectedFile} {...props} />;
}
