export const AncillaryStatus = {
  CREATED: 0,
  BOOKING: 20,
  BOOKED: 25,
  CANCELLING: 45,
  CANCELLED: 50,
};

export const AncillaryStatusMessages = {
  [AncillaryStatus.CREATED]: 'travel.ancillary.msg.created',
  [AncillaryStatus.BOOKING]: 'travel.ancillary.msg.booking',
  [AncillaryStatus.BOOKED]: 'travel.ancillary.msg.booked',
  [AncillaryStatus.CANCELLING]: 'travel.ancillary.msg.cancelling',
  [AncillaryStatus.CANCELLED]: 'travel.ancillary.msg.cancelled',
};

export const AncillaryStatusClasses = {
  [AncillaryStatus.CREATED]: 'details-alert warning',
  [AncillaryStatus.BOOKING]: 'details-alert warning',
  [AncillaryStatus.BOOKED]: 'details-alert approved',
  [AncillaryStatus.CANCELLING]: 'details-alert danger',
  [AncillaryStatus.CANCELLED]: 'details-alert danger',
};

export const AncillaryTypes = {
  BAG: 'bag',
  SEAT: 'seat',
};
