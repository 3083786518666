import { parseJSONFromLocalStorage } from '../../utils/redux';
import {
  SET_AUTH,
  SET_USER,
  SET_ORGANIZATION,
  LOGOUT_USER,
  SET_HAS_EXPENSE_CARD,
} from '../actions';

const INIT_STATE = {
  auth: parseJSONFromLocalStorage('auth'),
  user: parseJSONFromLocalStorage('user'),
  organization: parseJSONFromLocalStorage('organization'),
  hasExpenseCard: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_AUTH:
      localStorage.setItem('auth', JSON.stringify(action.payload));
      return { ...state, auth: action.payload };

    case SET_USER:
      localStorage.setItem('user', JSON.stringify(action.payload));
      return { ...state, user: action.payload };

    case SET_ORGANIZATION:
      localStorage.setItem('organization', JSON.stringify(action.payload));
      return { ...state, organization: action.payload };

    case SET_HAS_EXPENSE_CARD:
      return { ...state, hasExpenseCard: action.payload };

    case LOGOUT_USER:
      localStorage.removeItem('auth');
      localStorage.removeItem('user');
      localStorage.removeItem('organization');
      return { ...state, auth: null, user: null, organization: null };

    default:
      return { ...state };
  }
};
