import React, { useMemo } from 'react';
import AlterationInfoModal from '../order/AlterationInfoModal';
import ApprovalInfo from '../order/ApprovalInfo/ApprovalInfo';
import CancellationModalInfo from '../order/CancellationInfoModal';
import OrderStatusFooter from '../order/OrderStatusFooter';
import OpenBookingIssueInfoModal from '../order/OpenBookingIssueInfoModal/OpenBookingIssueInfoModal';

export default function Footer({
  order,
  approvals,
  goForApproval,
  toggleModal,
  reservations,
  goForCancellation = () => {},
  goForAlteration,
  cancellations = [],
  isApproving = false,
  isCancelling = false,
  isAltering = false,
  isShowingOpenBookingSteps = false,
  goForOpenBookingSteps = () => {},
  canAlter = false,
  canCancel = false,
  DetailsComponent = null,
  showCancelButton = true,
}) {
  // States
  const shouldHideOpenBookingSteps = useMemo(
    () => order?.openBookingInfo?.receipts,
    []
  );

  // Render
  if (isApproving)
    return (
      <ApprovalInfo
        order={order}
        approvals={approvals}
        goForApproval={goForApproval}
        toggleModal={toggleModal}
        reservations={reservations}
        DetailsComponent={DetailsComponent}
      />
    );
  else if (isCancelling) {
    return (
      <CancellationModalInfo
        order={order}
        goForCancellation={goForCancellation}
        toggleModal={toggleModal}
        reservations={reservations}
        DetailsComponent={DetailsComponent}
      />
    );
  } else if (isAltering) {
    return (
      <AlterationInfoModal
        order={order}
        goForAlteration={goForAlteration}
        toggleModal={toggleModal}
        reservations={reservations}
        cancellations={cancellations}
        DetailsComponent={DetailsComponent}
        canCancel={canCancel}
        canAlter={canAlter}
      />
    );
  } else if (isShowingOpenBookingSteps) {
    return (
      <OpenBookingIssueInfoModal
        order={order}
        goForOpenBookingSteps={goForOpenBookingSteps}
        toggleModal={toggleModal}
        reservations={reservations}
      />
    );
  } else
    return (
      <OrderStatusFooter
        order={order}
        approvals={approvals}
        goForApproval={goForApproval}
        goForCancellation={goForCancellation}
        goForAlteration={goForAlteration}
        toggleModal={toggleModal}
        cancellations={cancellations}
        reservations={reservations}
        canAlter={canAlter}
        canCancel={canCancel}
        DetailsComponent={DetailsComponent}
        showCancelButton={showCancelButton}
        goForOpenBookingSteps={goForOpenBookingSteps}
        shouldHideOpenBookingSteps={shouldHideOpenBookingSteps}
      />
    );
}
