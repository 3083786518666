import { useRef } from 'react';
import { useEffect, useState } from 'react';
import firebaseApp from '../services/Firebase';
import compact from 'lodash/compact';
import orderStatus from '../constants/orderStatus';

const passengers = {};

export default function useApprovals({
  user,
  fetchApprovals = firebaseApp.getPendingApprovalsFromUser,
  parentIdField = 'orderId',
  fetchOrders = firebaseApp.getOrderFromId,
  approvalType = 'travel',
  fetchComplementary = (order) => order,
  checkStatusCode = (statusCode) => statusCode < orderStatus.ISSUING,
}) {
  const snapshotRef = useRef(() => {});

  const [loading, setLoading] = useState(true);
  const [approvals, setApprovals] = useState({});
  const [orders, setOrders] = useState({});

  // Effects
  useEffect(() => {
    if (user) {
      loadApprovals();
    }

    return () => {
      snapshotRef.current();
    };
  }, [user]);

  // Functions
  const loadApprovals = async () => {
    snapshotRef.current();

    snapshotRef.current = fetchApprovals(user).onSnapshot(
      (pendingApprovalsSnap) => {
        const approvals = [];

        pendingApprovalsSnap.forEach((approvalSnap) => {
          approvals.push({
            ...approvalSnap.data(),
            id: approvalSnap.id,
            [parentIdField]: approvalSnap.ref.parent.parent.id,
          });
        });

        loadComplementary(approvals);
      }
    );
  };

  const loadOrders = async (approvals) => {
    const newOrders = {};

    const promises = approvals.map((approval) => {
      return new Promise(async (resolve) => {
        try {
          const orderId = approval[parentIdField];

          if (orderId in orders) {
            newOrders[orderId] = orders[orderId];
            resolve(null);
          } else return resolve(fetchOrders(orderId).get());
        } catch (err) {
          console.error(err);
          resolve(null);
        }
      });
    });

    const ordersSnaps = compact(await Promise.all(promises));
    const ordersPromises = [];

    ordersSnaps.forEach((orderSnap) => {
      const order = orderSnap.data();

      if (checkStatusCode(order?.statusCode)) {
        const order = {
          ...orderSnap.data(),
          id: orderSnap.id,
          approvalType,
        };

        ordersPromises.push(fetchComplementary(order));
      }
    });

    const fetchedOrders = await Promise.all(ordersPromises);

    const finalOrders = fetchedOrders.reduce((obj, order) => {
      obj[order.id] = order;
      return obj;
    }, newOrders);

    setOrders(finalOrders);
    return Object.values(finalOrders);
  };

  const fetchPassenger = (id) => {
    return new Promise((res) =>
      firebaseApp
        .getUserFromId(id)
        .get()
        .then((snap) => {
          const passenger = {
            ...snap.data(),
            id,
          };

          passengers[id] = passenger;
          res(passenger);
        })
        .catch((err) => {
          console.error(err);
          res(null);
        })
    );
  };

  const loadPassengers = (orders) => {
    return Promise.all(
      orders.map((order) => {
        const passengerId = order.passengerId;
        return passengers[passengerId] || fetchPassenger(passengerId);
      })
    );
  };

  const sortOrders = (orders) => {
    const sorted = {};

    orders.forEach((order) => {
      const passengerId = order.passengerId;

      if (passengerId in sorted) sorted[passengerId].orders.push(order);
      else if (passengerId in passengers) {
        sorted[passengerId] = {
          passenger: passengers[passengerId],
          orders: [order],
        };
      }
    });

    return sorted;
  };

  const loadComplementary = async (approvals) => {
    const orders = await loadOrders(approvals);
    await loadPassengers(orders);

    const a = sortOrders(orders);

    setApprovals(a);
    setLoading(false);
  };

  return [approvals, orders, loading];
}
