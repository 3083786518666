import moment from 'moment';
import { ORDER_PROVIDERS } from '../constants/orderProviders';
import { OrderTypes } from '../constants/orderTypes';
import { analyzeReservationParams } from './reservation';
import { maxBy } from 'lodash';
import firebaseApp from '../services/Firebase';

function getFlightJourney({
  index,
  originIata,
  destinationIata,
  airports = [],
  date,
}) {
  const originAirport = airports.find(({ iata }) => originIata === iata),
    destinationAirport = airports.find(({ iata }) => destinationIata === iata);

  return {
    id: index,
    departureStation: {
      iataCode: originIata,
      name: originAirport?.city || originIata,
    },
    arrivalStation: {
      iataCode: destinationIata,
      name: destinationAirport?.city || destinationIata,
    },
    departureDate: date.format('YYYY-MM-DD'),
  };
}

function getFlightSegment({
  index,
  originIata,
  destinationIata,
  airports = [],
  date,
  airline = null,
}) {
  const originAirport = airports.find(({ iata }) => originIata === iata),
    destinationAirport = airports.find(({ iata }) => destinationIata === iata);

  return {
    baggage: '',
    bookingCode: '',
    airline: airline,
    airlineOperating: airline,
    validatingAirline: airline,
    flightNumber: '',
    departureDateTime: date.format('DD/MM/YYYY HH:mm'),
    departureStation: {
      iataCode: originIata,
      name: originAirport?.city || originIata,
    },
    arrivalDateTime: date.clone().add(1, 'hour').format('DD/MM/YYYY HH:mm'),
    arrivalStation: {
      iataCode: destinationIata,
      name: destinationAirport?.city || destinationIata,
    },
    fare: {
      baggage: 0,
      baggageWeight: 0,
      base: '',
      seatsAvailable: 1,
      familyName: '',
      family: '',
      ruleNumber: '',
      baggageWeight: 23,
      class: '',
    },
    journeyId: index,
    flightIndex: 1,
  };
}

export function getFlightJourneysAndReservations({
  travelParams,
  search,
  searchParams,
}) {
  const { outboundDate, inboundDate, airline } = travelParams;
  const airlineCode = airline?.value || null;
  const { origin, destination } = search;

  const journeys = [
      getFlightJourney({
        index: 0,
        originIata: origin.airportCode,
        destinationIata: destination.airportCode,
        date: outboundDate,
        airports: searchParams?.airports,
      }),
    ],
    reservations = [
      getFlightSegment({
        index: 0,
        originIata: origin.airportCode,
        destinationIata: destination.airportCode,
        date: outboundDate,
        airports: searchParams?.airports,
        airline: airlineCode,
      }),
    ];

  if (search.twoWay) {
    journeys.push(
      getFlightJourney({
        index: 1,
        originIata: destination.airportCode,
        destinationIata: origin.airportCode,
        date: inboundDate,
        airports: searchParams?.airports,
      })
    );

    reservations.push(
      getFlightSegment({
        index: 1,
        originIata: destination.airportCode,
        destinationIata: origin.airportCode,
        date: inboundDate,
        airports: searchParams?.airports,
        airline: airlineCode,
      })
    );
  }

  return {
    journeys,
    reservations,
  };
}

export async function makeFlightOpenBookingReservation({
  user,
  organization,
  search,
  searchParams,
  reservationParams,
  travelParams,
}) {
  const newReservationParams = await analyzeReservationParams(
    reservationParams
  );

  const { outboundDate, inboundDate, amount, airline, associate } =
    travelParams;
  const airlineCode = airline?.value || null;
  const { origin, destination } = search;

  const journeys = [
      getFlightJourney({
        index: 0,
        originIata: origin.airportCode,
        destinationIata: destination.airportCode,
        date: outboundDate,
        airports: searchParams?.airports,
      }),
    ],
    reservations = [
      getFlightSegment({
        index: 0,
        originIata: origin.airportCode,
        destinationIata: destination.airportCode,
        date: outboundDate,
        airports: searchParams?.airports,
        airline: airlineCode,
      }),
    ];

  if (search.twoWay) {
    journeys.push(
      getFlightJourney({
        index: 1,
        originIata: destination.airportCode,
        destinationIata: origin.airportCode,
        date: inboundDate,
        airports: searchParams?.airports,
      })
    );

    reservations.push(
      getFlightSegment({
        index: 1,
        originIata: destination.airportCode,
        destinationIata: origin.airportCode,
        date: inboundDate,
        airports: searchParams?.airports,
        airline: airlineCode,
      })
    );
  }

  // // Create Order and Reservations
  const orderDetails = {
    ...newReservationParams,
    journeys,
    provider: {
      origin: ORDER_PROVIDERS.FLIGHT_OPEN_BOOKING,
      associate: associate?.value,
    },
    fare: {
      currency: 'BRL',
      total: amount,
      fare: amount,
      additional: 0,
      quantity: 1,
      taxes: 0,
    },
    searchCode: search.searchCode,
    openBooking: true,
  };

  const latestReservation = maxBy(reservations, (r) => {
    return moment(r.arrivalDateTime, 'DD/MM/YYYY HH:mm').unix();
  });

  await firebaseApp.createFlightReservation({
    organizationId: user.organizationId,
    passengerId: newReservationParams.passengerId,
    passenger: newReservationParams.passenger,
    orderDetails,
    reservations,
    filterTimestamp: moment(
      latestReservation.arrivalDateTime,
      'DD/MM/YYYY HH:mm'
    )
      .endOf('day')
      .utc()
      .unix(),
    type: 'flight',
  });
}

export const OpenBookingReservationFunctions = {
  [OrderTypes.FLIGHT]: makeFlightOpenBookingReservation,
};
