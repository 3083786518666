import moment from 'moment';
import * as yup from 'yup';

const BulkUserSchema = yup.object().shape({
  firstName: yup.string(),
  lastName: yup.string(),
  birthdate: yup.string(),
  email: yup.string(),
  documents: yup.object({
    cpf: yup.object({
      number: yup.string(),
    }),
  }),
  costCenter: yup.object({
    id: yup.string(),
    name: yup.string(),
  }),
  project: yup.object({
    id: yup.string(),
    name: yup.string(),
  }),
});

const getDocumentsObj = (fields = {}) => {
  const document = {};

  if (fields.cpf) {
    document['cpf'] = { number: fields.cpf };
  }

  if (fields.identity) {
    document['identity'] = { number: fields.identity };
  }

  if (fields.rg && fields.rgEmitter) {
    document['rg'] = {
      emitter: fields.rgEmitter,
      number: fields.rg,
    };
  }

  if (fields.cnh && fields.cnhValidUntil) {
    document['cnh'] = {
      number: fields.cnh,
      validUntil: fields.cnhValidUntil,
    };
  }

  if (fields.passportNumber && fields.passaportValidUntil) {
    document['passport'] = {
      number: fields.passportNumber,
      validUntil: fields.passaportValidUntil,
    };
  }

  return document;
};

const setToModel = (user, dateFormat = 'DD/MM/YYYY', show) => {
  const {
    cpf,
    identity,
    rg,
    rgEmitter,
    cnh,
    cnhValidUntil,
    birthdate,
    passportNumber,
    passaportValidUntil,
    ...otherFields
  } = user;

  let _birthdate;

  if (birthdate) {
    let momentBirthdate = moment(birthdate, dateFormat);

    if (!momentBirthdate.isValid())
      momentBirthdate = moment(
        birthdate,
        dateFormat === 'DD/MM/YYYY' ? 'MM/DD/YYYY' : 'DD/MM/YYYY'
      );

    if (momentBirthdate.isAfter(moment(), 'year')) {
      const year = birthdate.split('/').pop();
      momentBirthdate.set('year', parseInt(`19${year}`));
    }

    _birthdate = momentBirthdate.format('DD/MM/YYYY');
  }

  return {
    ...otherFields,
    ...(_birthdate ? { birthdate: _birthdate } : {}),
    documents: getDocumentsObj({
      cpf,
      identity,
      rg,
      rgEmitter,
      cnh,
      cnhValidUntil,
      passportNumber,
      passaportValidUntil,
    }),
  };
};

const setDBUserToBulk = (user) => {
  return BulkUserSchema.cast(user);
};

export default BulkUserSchema;

export { setToModel, setDBUserToBulk };
