import React from 'react';

import { GoogleApiWrapper } from 'google-maps-react';
import { CustomInput } from 'reactstrap';

import config from '../../constants/GoogleMaps';

import debounce from 'lodash/debounce';
import map from 'lodash/map';
import { injectIntl } from 'react-intl';

const GOOGLE_API_KEY = config.key;

const PROXIMITY_ICONS = {
  street_address: '/assets/img/icons/proximity/search_city.svg',
  establishment: '/assets/img/icons/proximity/search_shop.svg',
  political: '/assets/img/icons/proximity/neighborhood.svg',
  administrative_area_level_2: '/assets/img/icons/proximity/search_other.svg',
  locality: '/assets/img/icons/proximity/search_other.svg',
  administrative_area_level_1: '/assets/img/icons/proximity/search_other.svg',
};

const EXCLUDENING_ADDRESS_TYPES = new Set([
  'administrative_area_level_1',
  'administrative_area_level_2',
  'locality',
]);

class HotelProximitySearch extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      address: '',
      places: null,
      isHidden: true,
      isLoading: false,
      geocoder: new props.google.maps.Geocoder(),
      maxResults: props.maxResults || 5,
      city: props.city || '',
      includeCity: props.includeCity,
      focused: false,
    };
  }

  getCoordinatesFromAddress = async (address) => {
    const geocoder = this.state.geocoder;

    return new Promise((resolve, reject) => {
      geocoder.geocode({ address }, (result, status) => {
        if (status === 'OK') {
          resolve(
            this.state.includeCity
              ? result
              : result.filter(
                  (obj) => !EXCLUDENING_ADDRESS_TYPES.has(obj.types[0])
                )
          );
        } else reject(null);
      });
    });
  };

  fetchResults = debounce(async (address = '') => {
    if (address.length > 1) {
      this.setState({ isLoading: true });
      const places = await this.getCoordinatesFromAddress(
        `${address}, ${this.state.city}`
      );
      this.setState({ isLoading: false });
      if (places) {
        if (places.length)
          this.setState({
            isHidden: false,
            places: places.slice(0, this.state.maxResults),
          });
        else this.setState({ isHidden: true, places: null });
      }
    } else this.clearStates();
  }, 400);

  clearStates = () => this.setState({ isHidden: true, places: null });

  handleAddressChange = (address) => {
    this.setState({ address });
    this.clearStates();
    this.fetchResults(address);
  };

  handlePlaceClick = (place) => {
    const { lat, lng } = place.geometry.location;

    const placeObj = {
      lat: lat(),
      lng: lng(),
    };

    if (this.props.fullLocation) placeObj['address'] = place.formatted_address;

    this.props.setHighlightPin(placeObj);
    this.setState({ places: null, address: '', isHidden: true });
  };

  render() {
    const {
      intl: { messages },
      placeholder,
      simple,
      selectedAddress = '',
      editable = true,
    } = this.props;

    const { address = '', places, isLoading, isHidden, focused } = this.state;

    return (
      <div className={`hotel-proximity ${simple ? 'simple' : ''}`}>
        <div className="proximity-card">
          <CustomInput
            id="proximity-address"
            type="text"
            placeholder={
              placeholder ||
              messages[
                'containers.search.hotel-proximity-search.address-placeholder'
              ]
            }
            value={editable && focused ? address : address || selectedAddress}
            onChange={(e) => this.handleAddressChange(e.target.value)}
            onFocus={() => this.setState({ focused: true })}
            onBlur={() => this.setState({ focused: false })}
            readOnly={!editable}
          />
          {isLoading && <div className="loading" />}
          {!isLoading && places && (
            <img
              className="cancel-btn"
              src="/assets/img/icons/proximity/cancel-a.svg"
              alt={
                messages['alts.containers.search.hotel-proximity-search.erase']
              }
              onClick={() => {
                this.setState({ address: '' });
                this.clearStates();
              }}
            />
          )}
        </div>

        <div className={`proximity-results ${isHidden && 'hidden'}`}>
          {map(places, (place, index) => (
            <div
              className="place"
              key={place.place_id}
              onClick={() => this.handlePlaceClick(place)}
              style={{ animationDelay: `${150 + index * 150}ms` }}
            >
              <object
                className="location-icon"
                data={
                  PROXIMITY_ICONS[place.types[0]] ||
                  '/assets/img/icons/proximity/neighborhood.svg'
                }
                type="image/svg+xml"
                fill="white"
              ></object>
              {place.formatted_address}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: GOOGLE_API_KEY,
})(injectIntl(HotelProximitySearch));
