import React, { useState } from 'react';
import appFunctions from '../../../../../../services/Functions';
import { OrderTypes } from '../../../../../../constants/orderTypes';
import searchStatus from '../../../../../../constants/searchStatus';
import firebaseApp from '../../../../../../services/Firebase';
import DateForm from '../DateForm';
import { getHotelSearchParams } from '../../../../../../utils/search';
import BottomButtons from '../BottomButtons';
import EmptyRooms from '../EmptyRooms';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSelector } from 'react-redux';

const getDate = (date) => date.format('YYYY-MM-DD');

const isSame = (firstDate, secondDate) =>
  getDate(firstDate) === getDate(secondDate);

export default function SearchTab({
  order,
  reservations,
  search,
  setSearch,
  onDaySelection,
  goBack,
  direction,
}) {
  const { organization } = useSelector(({ auth }) => auth);

  // States
  const [isEmpty, setIsEmpty] = useState(false);
  const [currentResults, setCurrentResults] = useState(null);

  // Functions
  const handleNewReservation = async (search, name) => {
    try {
      const { searchCode, payload } = getHotelSearchParams(
        search,
        organization
      );

      await firebaseApp.createSearch(searchCode, payload);

      const { data } = await appFunctions.getResultFromSearch(
        OrderTypes.HOTEL,
        searchCode,
        reservations[0].hotelDetails.hotelId
      );

      return {
        name,
        item: data[0],
        search,
      };
    } catch (err) {
      console.error(err);
      return {
        name,
        search,
        result: [],
      };
    }
  };

  const handleSubmit = async ({ outbound_date, inbound_date }) => {
    const promises = [];

    if (isEmpty) setIsEmpty(false);

    if (!isSame(search.outbound_date, outbound_date)) {
      promises.push(
        handleNewReservation(
          {
            ...search,
            outbound_date,
            inbound_date: search.outbound_date,
          },
          'before'
        )
      );
    }

    if (!isSame(search.inbound_date, inbound_date)) {
      promises.push(
        handleNewReservation(
          {
            ...search,
            outbound_date: search.inbound_date,
            inbound_date,
          },
          'after'
        )
      );
    }

    if (promises.length > 0) {
      setSearch({
        ...search,
        status: searchStatus.LOADING,
      });

      const results = await Promise.all(promises);

      setSearch({
        ...search,
        status: searchStatus.DONE,
      });

      if (results.every(({ item }) => item)) {
        onDaySelection(results);
      } else {
        setIsEmpty(true);
        setCurrentResults(results);
      }
    }
  };

  const handleGoForward = () => {
    onDaySelection(currentResults.filter(({ result }) => result));
  };

  if (search) {
    return (
      <div
        className={`search-tab d-flex flex-column justify-content-between h-100 show-up ${direction}`}
      >
        <div className="flex-grow-1 pt-3">
          <PerfectScrollbar
            options={{
              suppressScrollX: true,
              wheelPropagation: false,
            }}
          >
            <DateForm
              order={order}
              reservations={reservations}
              search={search}
              handleSubmit={handleSubmit}
            />
            {isEmpty && currentResults ? (
              <EmptyRooms
                results={currentResults}
                goForward={handleGoForward}
              />
            ) : null}
          </PerfectScrollbar>
        </div>
        <BottomButtons goBack={goBack} canGoForward={false} />
      </div>
    );
  } else return null;
}
