import React, { useState, useEffect, useMemo } from 'react';
import { Button } from 'reactstrap';

import PrintOrder from './PrintOrder';

import firebaseApp from '../../services/Firebase';

import { NotificationType } from '../../constants/notificationTypes';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { makeUrlSearch } from '../../constants/url';
import NotifyApprovers from './NotifyApprovers';

let notificationsEvent = () => {};

function NotificationItem({ order, reservations, notification, total }) {
  const { messages, formatMessage } = useIntl();
  const history = useHistory();

  // States
  const [loading, setLoading] = useState(false);

  const creditCardRelated = useMemo(
    () =>
      [
        NotificationType.CREDIT_CARD_UNAUTHORIZED,
        NotificationType.EMPTY_CARD,
        NotificationType.UNAVAILABLE_CHOOSEN_SEAT,
      ].includes(notification.type),
    [notification]
  );

  const btnMessage = useMemo(
    () =>
      messages[
        creditCardRelated
          ? 'orders.notifications.btn.ok'
          : 'orders.notifications.btn.search'
      ],
    [creditCardRelated]
  );

  const notificationMessage = useMemo(() => {
    const messageId = `orders.notifications.${notification.type}`;

    if (notification.params)
      return formatMessage(
        { id: `${messageId}.with-params` },
        notification.params
      );

    return messages[`orders.notifications.${notification.type}`];
  }, [notification]);

  // Functions
  const setToRead = async () => {
    await firebaseApp.setNotificationsFromOrderToRead(
      order.id,
      notification.id
    );
  };

  const handleSearch = async () => {
    const search = await makeUrlSearch(order, reservations);
    if (search) history.push(search);
  };

  const handleClick = async () => {
    try {
      setLoading(true);
      setToRead();

      if (!creditCardRelated) await handleSearch();
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  // Render
  return (
    <div className="notification-item">
      <div className="delete m-0">
        <div className="float-left">1/{total}</div>
        <Button
          name="close-notification"
          onClick={setToRead}
          disabled={loading}
          color="transparent"
        >
          x
        </Button>
      </div>
      <div className="content d-flex align-items-center justify-content-center flex-grow-1 pb-4">
        <div>
          <p className="m-0 p-0">{notificationMessage}</p>
          <Button size="sm" disabled={loading} onClick={handleClick}>
            {btnMessage}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default function OrderStatusHeader({ order, reservations }) {
  const [notifications, setNotifications] = useState([]);
  const [numNotifications, hasNotifications] = useMemo(() => {
    const length = notifications.length;
    return [length, length > 0];
  }, [notifications]);

  useEffect(() => {
    getNotifications();

    return () => {
      notificationsEvent();
    };
  }, []);

  const getNotifications = async () => {
    notificationsEvent = await firebaseApp
      .getNotificationsFromOrder(order.id)
      .onSnapshot((notificationsSnap) => {
        const notifications = [];
        notificationsSnap.forEach((notificationSnap) => {
          notifications.push({
            ...notificationSnap.data(),
            id: notificationSnap.id,
          });
        });

        setNotifications(
          notifications.filter((notification) => notification.type)
        );
      });
  };

  return (
    <div
      className={`order-header ${hasNotifications ? 'has-notification' : ''}`}
    >
      <PrintOrder order={order} />
      <NotifyApprovers order={order} />

      {hasNotifications && (
        <NotificationItem
          order={order}
          reservations={reservations}
          notification={notifications[0]}
          total={numNotifications}
        />
      )}
    </div>
  );
}
