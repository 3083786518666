import { useEffect, useMemo, useState } from 'react';
import {
  CustomInput,
  Row,
  Button,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { Colxx } from '../../components/common/CustomBootstrap';
import { useSelector } from 'react-redux';
import IntlMessages from '../../helpers/IntlMessages';
import firebaseApp from '../../services/Firebase';
import { trackPropertiesSegment } from '../../utils/segment';
import CategoryModal from '../../components/policy/CategoryModal';
import EmptyResults from '../../components/common/EmptyResults';
import { useIntl } from 'react-intl';
import mouseTrap from 'mousetrap';
import useDataSnapshot from 'src/portao3-legacy/hooks/useDataSnapshot';
import { getAuthRedux } from 'src/portao3-legacy/utils/redux';
import useShowToggle from 'src/portao3-legacy/hooks/useShowToggle';
import classNames from 'classnames';
import RefundCategoryItem from 'src/portao3-legacy/components/expenses/RefundCategoryItem';
import PermissionToggleFragment from 'src/portao3-legacy/components/common/PermissionToggleFragment';
import PolicyLoading from '../policy/PolicyLoading';

export default function RefundDataListView() {
  const { messages } = useIntl();
  const { user, organization } = useSelector(getAuthRedux);

  // Hooks
  const [categories, setCategories, isLoadingCategories] = useDataSnapshot({
    fetchFunction: () =>
      firebaseApp.getRefundCategoriesFromOrganization(user.organizationId),
  });

  // States
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const [showDropdown, setShowDropdown, toggleDropdown] = useShowToggle();
  const [showModal, setShowModal, toggleModal] = useShowToggle();

  const isEmpty = useMemo(
    () => !isLoadingCategories && !categories.length,
    [categories, isLoadingCategories]
  );

  const { isAllCategoriesSelected, dropdownClassName = '' } = useMemo(() => {
    if (!categories.length) return { isAllCategoriesSelected: false };

    return {
      isAllCategoriesSelected: categories.length === selectedItems.length,
      dropdownClassName:
        selectedItems.length < categories.length ? 'indeterminate' : '',
    };
  }, [categories, selectedItems, isLoadingCategories]);

  // Effects
  useEffect(() => {
    mouseTrap.bind(['ctrl+a', 'command+a'], onSelectAllItems);
    mouseTrap.bind(['ctrl+d', 'command+d'], () => setSelectedItems([]));

    return () => {
      mouseTrap.unbind('ctrl+a');
      mouseTrap.unbind('command+a');
      mouseTrap.unbind('ctrl+d');
      mouseTrap.unbind('command+d');
    };
  }, [categories]);

  // Functions
  const onSelectItem = (id) => {
    if (selectedItems.includes(id)) {
      setSelectedItems((items) => items.filter((_id) => _id !== id));
    } else {
      setSelectedItems((items) => items.concat(id));
    }
  };

  const onSelectAllItems = () => {
    setSelectedItems(
      isAllCategoriesSelected ? [] : categories.map(({ id }) => id)
    );

    document.activeElement.blur();

    return false;
  };

  const onNewCategory = () => {
    if (selectedCategory) setSelectedCategory(null);

    toggleModal();
  };

  const onDeleteCategories = async () => {
    if (!selectedItems.length) return;

    await Promise.all(
      selectedItems.map((item) =>
        firebaseApp.deleteRefundCategoryFromOrganizationAndId(
          user.organizationId,
          item
        )
      )
    );

    trackPropertiesSegment('Refund Categories deleted', {
      categories: selectedItems,
      user,
    });

    setSelectedItems([]);
  };

  //  Render
  const renderHeader = () => (
    <Row>
      <Colxx xxs="12" className="mt-5">
        <Button
          color="primary"
          className="m-0 pretty-btn"
          onClick={onNewCategory}
        >
          {messages['general.add']}
        </Button>

        <ButtonDropdown
          isOpen={showDropdown}
          toggle={toggleDropdown}
          className="float-right"
        >
          <div className="btn btn-primary btn-lg pl-4 pr-0 check-button check-all">
            <CustomInput
              className="custom-checkbox mb-0 d-inline-block"
              type="checkbox"
              id="checkAll"
              checked={isAllCategoriesSelected}
              onChange={onSelectAllItems}
              label={
                <span
                  className={classNames(
                    'custom-control-label',
                    dropdownClassName
                  )}
                />
              }
            />
          </div>
          <DropdownToggle
            caret
            color="primary"
            className="dropdown-toggle-split btn-lg"
          />
          <DropdownMenu right>
            <DropdownItem onClick={onDeleteCategories}>
              <IntlMessages id="admin.policy.delete" />
            </DropdownItem>
          </DropdownMenu>
        </ButtonDropdown>
      </Colxx>
    </Row>
  );

  const renderContent = () => {
    if (isLoadingCategories) return <PolicyLoading />;

    if (isEmpty)
      return (
        <div className="w-100 d-flex justify-content-center mt-5">
          <EmptyResults
            title={messages['admin.travels.empty.categories.title']}
            description={messages['admin.travels.empty.categories.description']}
            buttonText={messages['admin.travels.empty.categories.btn']}
            className="w-70"
            onClick={onNewCategory}
          />
        </div>
      );

    return (
      <>
        {renderHeader()}

        <Row className="mt-4 pb-5">
          {categories.map((category, index) => (
            <Colxx key={`category-${index}`} xxs="12" className="mb-3">
              <RefundCategoryItem
                category={category}
                isSelected={selectedItems.includes(category.id)}
                toggle={onSelectItem}
              />
            </Colxx>
          ))}

          <PermissionToggleFragment
            className="mx-3"
            fieldName="requireCategory"
            requiredValuePath="config.refunds"
            requiredValue={organization.config?.refunds?.requireCategory}
            organizationId={user.organizationId}
            messageId="permission-toggle-fragment.refund-category.description"
          />
        </Row>
      </>
    );
  };

  return (
    <div>
      {renderContent()}

      <CategoryModal
        modalOpen={showModal}
        toggleModal={toggleModal}
        user={user}
        selectedCategory={selectedCategory}
      />
    </div>
  );
}
