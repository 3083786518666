import React from 'react';
import CreditCard from './CreditCard';
import { ReactComponent as AdministrativeIcon } from '../../assets/img/expenses/administrative_icon.svg';
import AdministrativeCardInfo from './CreditCard/AdministrativeCardInfo';

export default function AdministrativeCard({ card = {} }) {
  const { color = '#030303' } = card;

  // Render
  return (
    <CreditCard
      card={card}
      Icon={() => <AdministrativeIcon className="icon" fill={color} />}
      className="administrative-card"
      InfoComponent={AdministrativeCardInfo}
    />
  );
}
