import React, { useEffect, useMemo, useRef, useState } from 'react';

import FlightAlternative from './FlightAlternative';
import HotelAlternative from './HotelAlternative';
import CarAlternative from './CarAlternative';
import BusAlternative from './BusAlternative';
import { OrderTypes } from '../../constants/orderTypes';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import useWindowSize from '../../hooks/useWindowSize';

const AllAlternativeCards = {
  [OrderTypes.FLIGHT]: FlightAlternative,
  [OrderTypes.HOTEL]: HotelAlternative,
  [OrderTypes.CAR]: CarAlternative,
  [OrderTypes.BUS]: BusAlternative,
};

export default function AlternativeCards({
  alternatives,
  order,
  reservations,
}) {
  const divRef = useRef(null);
  const { width } = useWindowSize();

  // States
  const [slidesToShow, setSlidesToShow] = useState(1);
  const AlternativeCard = useMemo(
    () => AllAlternativeCards[order.type],
    [order]
  );

  // Effects
  useEffect(() => {
    if (divRef.current) {
      setSlidesToShow(Math.floor(divRef.current.offsetWidth / 240) || 1);
    }
  }, [width]);

  return (
    <div className="d-block alternative-cards" ref={divRef}>
      <Slider
        className="pb-3"
        dots={true}
        infinite={false}
        arrows={false}
        slidesToShow={slidesToShow}
        slidesToScroll={slidesToShow}
        variableWidth={true}
        dotsClass="slick-dots"
        centerMode={false}
      >
        {alternatives.map((alternative, index) => (
          <AlternativeCard
            key={`alternative-${alternative.id}`}
            alternative={alternative}
            originalTotal={order.fare.total}
            order={order}
            index={index}
            reservations={reservations}
          />
        ))}
      </Slider>
    </div>
  );
}
