import React from 'react';
import classNames from 'classnames';
import { ReactComponent as CheckMark } from '../../assets/img/icons/bold_check.svg';
import { ReactComponent as Minus } from '../../assets/img/icons/checkbox-minus.svg';

export default function CustomAnimatedCheckbox({
  className = '',
  checked,
  onChange = () => {},
  white,
  purple,
  lightPurple,
  size = '1em',
  radio = true,
  disabled,
  isPartialCheck,
  borderWidth,
}) {
  // Function
  const handleClick = (e) => {
    if (disabled) return;

    e.stopPropagation();
    e.preventDefault();

    onChange(!checked);
  };

  // Render
  return (
    <div
      className={classNames('custom-animated-checkbox', className, {
        checked,
        white,
        purple,
        radio,
        'light-purple': lightPurple,
        disabled,
      })}
      onClick={handleClick}
      style={{ width: size, height: size, borderWidth }}
      role="checkbox"
    >
      {isPartialCheck ? (
        <Minus className="checkmark" />
      ) : (
        <CheckMark className="checkmark" />
      )}
    </div>
  );
}
