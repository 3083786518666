import React, { useMemo } from 'react';
import classNames from 'classnames';
import ContentLoader from 'react-content-loader';

export default function LoadingRecurrenceCard({
  className,
  backgroundColor = '#f3f3f3',
  foregroundColor = '#ecebeb',
  width = 350,
  height = 180,
}) {
  const viewBox = useMemo(() => `0 0 ${width} ${height}`, [width, height]);

  // Render
  return (
    <ContentLoader
      className={classNames('loading-recurrence-card', className)}
      speed={2}
      width={width}
      height={height}
      viewBox={viewBox}
      backgroundColor={backgroundColor}
      foregroundColor={foregroundColor}
    >
      <rect x="0" y="0" rx="10" ry="10" width="100%" height="100%" />
    </ContentLoader>
  );
}
