import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Row } from 'reactstrap';
import firebaseApp from '../../services/Firebase';
import { getOrganizationConfig } from '../../utils/organization';

import { ReactComponent as PermissionImg } from '../../assets/img/bulk/modified.svg';
import { Colxx } from '../../components/common/CustomBootstrap';
import SwitchItem from '../../components/SwitchItem';
import PermissionToggleFragment from '../../components/common/PermissionToggleFragment';

export default function ObservationsDataListView() {
  const { organization } = useSelector(({ auth }) => auth);
  const { messages } = useIntl();

  // States
  const [allow, setAllow] = useState(
    getOrganizationConfig(organization.config, 'observations')
  );

  // Functions
  const toggleAllow = async () => {
    try {
      setAllow(!allow);
      await firebaseApp.updateObservationsConfig(organization.id, !allow);
    } catch (err) {
      console.error(err);
    }
  };

  // Render
  return (
    <Row className="travel-data-list-view d-flex justify-content-between mt-5 ml-3 w-100 observation">
      <Colxx xxs="12" md="5" className="d-flex justify-content-center">
        <PermissionImg width={'85%'} />
      </Colxx>
      <Colxx xxs="12" md="7" className="mt-3 pl-3">
        <p className="text-one w-70 mt-3 slide-left font-weight-medium">
          {messages['admin.policy.observations.description']}
        </p>
        <div
          className="slide-left mt-4 mb-5"
          style={{ animationDelay: '25ms' }}
        >
          <SwitchItem
            title={messages[`admin.policy.observations.allow`]}
            titleClassName="fs-0-8rem"
            onChange={() => toggleAllow()}
            done={allow}
          />
          <PermissionToggleFragment
            fieldName="observation"
            requiredValue={getOrganizationConfig(
              organization.config,
              'required.observation'
            )}
            organizationId={organization.id}
            simple
          />
        </div>
      </Colxx>
    </Row>
  );
}
