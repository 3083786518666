import React, { useEffect, useState } from 'react';
import firebaseApp from '../../../../../services/Firebase';
import { sortByFareBasedOnProperty } from '../../../../../utils/expensesDashboard';
import {
  getDocument,
  getDocumentsFromCollection,
} from '../../../../../utils/firebase';
import DefaultTab from '../DefaultTab';

export default function VendorsTab({ items, getData, ...props }) {
  const [vendors, setVendors] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchVendors = async () => {
      try {
        setLoading(true);

        const budgetItems = (
          await Promise.all(
            items.map((budget) => {
              return getData(`bi-${budget.id}`, () =>
                getDocumentsFromCollection(() =>
                  firebaseApp.getItemsFromBudget(budget.id).get()
                )
              );
            })
          )
        ).flat();

        const vendors = sortByFareBasedOnProperty(
          budgetItems,
          ({ supplier }) => supplier
        );

        const vendorInfos = (
          await Promise.all(
            vendors.map(({ value }) =>
              getData(`v-${value}`, () =>
                getDocument(() => firebaseApp.getSupplierByCNPJ(value).get())
              )
            )
          )
        ).reduce((obj, vendor) => {
          if (vendor) {
            obj[vendor.id] = vendor.nomeFantasia || vendor.nomeEmpresarial;
          }

          return obj;
        }, {});

        setVendors(
          vendors.map(({ value, ...other }) => ({
            ...other,
            value: vendorInfos[value] || value,
          }))
        );

        setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };

    fetchVendors();
  }, [items]);

  return <DefaultTab values={vendors} loading={loading} {...props} />;
}
