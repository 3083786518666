export const DEFAULT_CURRENCY = 'BRL';

export const getCurrency = (organization) => {
  const { getOrganizationConfig } = require('./organization');

  return getOrganizationConfig(organization.config, 'currency');
};

const moneyRegex = /^[\d.,]+$/;

export const currencyToLocale = {
  USD: 'en-US',
  COP: 'es-CO',
  BRL: 'pt-BR',
  MXN: 'es-MX',
  ARS: 'es-AR',
  CLP: 'es-CL',
  AED: 'en-US',
};

export const stringToMoney = (string, fractions = 0, currency = 'BRL') => {
  return new Intl.NumberFormat(currencyToLocale[currency] || 'pt-BR', {
    style: 'currency',
    currency,
    minimumFractionDigits: fractions,
    maximumFractionDigits: fractions,
  }).format(string);
};

export const getCurrencyAndPrice = (
  string,
  currency = 'BRL',
  fractions = 2
) => {
  const [firstPart, secondPart] = stringToMoney(
    string,
    fractions,
    currency
  ).split(/\s/);

  if (moneyRegex.test(firstPart)) return [secondPart, firstPart];
  else return [firstPart, secondPart];
};

export const getCurrencySymbol = (currency = 'BRL') => {
  return getCurrencyAndPrice('10', currency, 0)[0];
};

export const convertToFloat = (value = 0) => {
  return value / 100;
};
