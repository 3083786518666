import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useIntl } from 'react-intl';
import firebaseApp from '../../../../services/Firebase';
import useDataLoader from '../../../../hooks/useDataLoader';
import LoadingAlternative from '../../../alternatives/LoadingAlternative';
import { sortReservationJourneys } from '../../../../utils/reservation';
import AlternativeCards from '../../../alternatives/search/SearchAlternativeCards';
import TravelDuplicityVerifier from '../../../travels/TravelDuplicityVerifier';
import { NEEDS_SORTED_RESERVATION } from '../consts';
import ReservationForm from '../Forms/ReservationForm';
import CommentInput from '../../../expensesTimeline/CommentInput';
import SearchInformation from '../../SearchInformation/SearchInformation';
import SearchAlternativeCards from '../../../alternatives/search/SearchAlternativeCards';
import useDataSnapshot from '../../../../hooks/useDataSnapshot';
import searchStatus from '../../../../constants/searchStatus';
import { getDataFromDocument } from '../../../../utils/firebase';
import { Card, Modal, ModalBody } from 'reactstrap';
import classNames from 'classnames';
import { Colxx } from 'src/portao3-legacy/components/common/CustomBootstrap';
import { getHotelAlternativesInsideRadius } from '../../../../utils/orders';

export default function ReservationReview({
  searchId,
  item,
  selectedRoom,
  type,
  createReservation,
  handleSelectHotel,
  handleCloseAlernatives,
  params,
}) {
  const { messages } = useIntl();
  const { user } = useSelector((state) => state.auth);

  const getInitialSearch = () => ({
    status: searchStatus.NEW,
  });

  const [search, setSearch] = useDataSnapshot(
    {
      fetchFunction: () => firebaseApp.getSearch(searchId),
      getSnapshotDataFunction: (snap) =>
        getDataFromDocument(snap, { status: searchStatus.DONE }),
      formatFunction: (data) => ({ ...data, searchId }),
      shouldLoad: searchId,
      initialData: getInitialSearch(),
    },
    [searchId]
  );

  // Hooks
  const [alternatives, _, loadingAlternatives] = useDataLoader({
    fetchFunction: () =>
      firebaseApp.getSearchAlternatives(searchId, selectedRoom.fare).get(),
    prepareData: (data) => data.sort((a, b) => a.fare.total - b.fare.total),
  });

  const hotelLocalization = item.hotel.localization;

  const alternativesInsideRadius = useMemo(
    () =>
      getHotelAlternativesInsideRadius(hotelLocalization, alternatives, params),
    [alternatives, hotelLocalization]
  );

  const hasAlternatives = useMemo(
    () => alternativesInsideRadius.length > 0,
    [alternatives]
  );

  // Render
  const renderAlternatives = () => {
    if (loadingAlternatives) {
      return (
        <div className="d-flex flex-shrink-0">
          <LoadingAlternative style={{ marginRight: 10 }} />
          <LoadingAlternative style={{ marginRight: 10 }} />
          <LoadingAlternative />
        </div>
      );
    } else {
      return hasAlternatives ? (
        <SearchAlternativeCards
          alternatives={alternativesInsideRadius}
          type={type}
          selectedItem={selectedRoom}
          handleSelectHotel={handleSelectHotel}
          handleCloseAlernatives={handleCloseAlernatives}
        />
      ) : (
        <div>
          <div className="alternative-card d-flex flex-column justify-content-center align-items-center">
            <span role="img" aria-label="Woohoo!">
              😍
            </span>
            {messages['travel.best-option']}
          </div>
        </div>
      );
    }
  };

  const renderAlternativesContainer = () => (
    <div className="flex-grow-1 alternatives-container mw-0 mt-3">
      <div>{renderAlternatives()}</div>
    </div>
  );

  const renderReservationDetailsCards = () => (
    <div>
      <h5 style={{ color: '#43418e' }} className="hotel-subtitle mt-3">
        {messages['travel.currentHotel.details']}
      </h5>
      <SearchInformation
        item={item}
        selectedRoom={selectedRoom}
        search={search}
      />
    </div>
  );

  const renderStandard = () => (
    <div className="mb-5 w-100 padding-0px16px">
      {renderAlternativesContainer()}
      {renderReservationDetailsCards()}
    </div>
  );

  const renderContent = () => {
    return renderStandard();
  };

  return (
    <div>
      <PerfectScrollbar
        options={{
          suppressScrollX: true,
          wheelPropagation: false,
          useBothWheelAxes: false,
        }}
      >
        <h2
          className="hotel-title mt-1 pl-16px pt-1 pb-1"
          style={{
            width: '100%',
          }}
        >
          {messages['travel.search.best-options']}
        </h2>

        {renderContent()}
      </PerfectScrollbar>
    </div>
  );
}
