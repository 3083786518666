import React, { useMemo } from 'react';
import { CustomInput } from 'reactstrap';
import moment from 'moment';
import minBy from 'lodash/minBy';
import { useIntl } from 'react-intl';
import { ReactComponent as CancelIcon } from '../../assets/img/icons/cancel.svg';
import CurrencyWrapper from '../../components/common/CurrencyWrapper';
import OfflineReservationBadge from '../../components/OfflineReservationBadge';
import RoomMeal from './RoomMeal';
import { ORDER_PROVIDERS } from '../../constants/orderProviders';

export default function RoomItem({
  roomsInfo,
  index,
  room,
  selectedRoom,
  selectCheckbox,
  white = false,
  numDays,
}) {
  const { messages } = useIntl();

  // States
  const amenities = useMemo(() => {
    const amenities = roomsInfo[room?.mediaId]?.amenities;
    return amenities && amenities.length > 0 ? amenities : null;
  }, [roomsInfo, room]);

  const dailyPrice = useMemo(() => {
    const dailyPrice = room.fare / numDays;
    return dailyPrice;
  }, [room, numDays]);

  const iconColor = useMemo(() => (white ? 'white' : '#43418e'), [white]);

  const isChecked = useMemo(
    () => selectedRoom.id === room.id,
    [selectedRoom, room]
  );

  const { hasFreeCancellation, offlineReservation } = useMemo(() => {
    let hasFreeCancellation = room?.cancellation?.length > 0,
      freeCancellationDate = null,
      offlineReservation = false;

    if (hasFreeCancellation) {
      freeCancellationDate = minBy(room?.cancellation, (c) => {
        return moment(c.startDate).unix();
      }).startDate;

      // Free cancelation is past date?
      if (moment(freeCancellationDate).isBefore(moment())) {
        hasFreeCancellation = false;
        freeCancellationDate = null;
      }
    }

    if (room.origin === ORDER_PROVIDERS.OFFLINE_HOTELS)
      offlineReservation = true;

    return { hasFreeCancellation, offlineReservation };
  }, [room]);

  return (
    <div key={`room-${index}`} className="w-100 px-3 py-1 room">
      <div className="top d-flex justify-content-between">
        <div className="room-info flex-center fs-0-8rem">
          <CustomInput
            type="checkbox"
            id={room.id}
            className={`custom-check w-100 h-auto ${
              isChecked ? 'checked' : ''
            } font-weight-medium`}
            label={room.description}
            title={room.description}
            checked={isChecked}
            onChange={selectCheckbox}
          />
          {offlineReservation ? (
            <OfflineReservationBadge className="mb-2 shrink-top" />
          ) : null}
        </div>
        <div className="flex-center icons">
          <RoomMeal room={room} iconColor={iconColor} />

          {hasFreeCancellation && (
            <CancelIcon
              alt={messages['alts.containers.search.hotel-modal.cancellation']}
              title={
                messages['alts.containers.search.hotel-modal.cancellation']
              }
              style={{ width: '16px' }}
              fill={iconColor}
            />
          )}
        </div>

        <div className="d-flex flex-column">
          <CurrencyWrapper
            value={room.fare}
            withTooltip={false}
            decimalPlaces={0}
          >
            {({ formattedValue }) => (
              <span
                className="fs-0-8 font-weight-medium"
                style={{ height: '13px' }}
              >{`${formattedValue}`}</span>
            )}
          </CurrencyWrapper>

          {numDays > 1 ? (
            <CurrencyWrapper
              data-testid="dailyValue"
              value={dailyPrice}
              withTooltip={false}
              decimalPlaces={0}
            >
              {({ formattedValue }) => (
                <span className="text-primary fs-0-6rem">{`${formattedValue} / ${messages['general.day']}`}</span>
              )}
            </CurrencyWrapper>
          ) : null}
        </div>
      </div>
      {isChecked && amenities && (
        <div className="bottom">
          <div
            className={`amenities w-100 ${
              selectedRoom.id === room.id ? 'selected' : ''
            }`}
          >
            {amenities.join(' • ')}
          </div>
        </div>
      )}
    </div>
  );
}
