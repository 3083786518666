import { useEffect, useState } from 'react';
import debounce from 'lodash/debounce';

const getWindowSize = (a) => {
  const { innerWidth: width, innerHeight: height } = window;

  return {
    width,
    height,
  };
};

export default function useWindowSize(debounceTime = 100) {
  const [size, setSize] = useState(getWindowSize());

  useEffect(() => {
    const refreshSize = debounce(() => {
      setSize(getWindowSize());
    }, debounceTime);

    window.addEventListener('resize', refreshSize);

    return () => window.removeEventListener('resize', refreshSize);
  }, []);

  return size;
}
