import moment from 'moment';
import { OrderTypes } from '../../../constants/orderTypes';
import { RefundStatus } from 'src/portao3-legacy/constants/refundStatus';

const getBasics = (obj) => {
  return {
    date: obj.createdAt,
    project: obj.project?.value,
    costCenter: obj.costCenter?.value,
    price: parseFloat(obj.fare.total) || 0,
    passengerName: `${obj.passengerInfo.firstName} ${obj.passengerInfo.lastName}`,
    statusCode: obj.statusCode,
    approvers: obj.approvers || [],
    pending: [RefundStatus.WAITING, RefundStatus.REVIEW].includes(
      obj.statusCode
    ),
  };
};

const getArrivalAndDepartureStation = (
  journeys,
  formatFunction = (value) => value
) => {
  const arrivalStations = {},
    departureStations = {};

  try {
    journeys = Array.isArray(journeys) ? journeys : Object.values(journeys);

    journeys.forEach(({ arrivalStation, departureStation }) => {
      arrivalStations[formatFunction(arrivalStation)] = true;
      departureStations[formatFunction(departureStation)] = true;
    });

    return {
      arrivalStation: Object.keys(arrivalStations),
      departureStation: Object.keys(departureStations),
    };
  } catch (err) {
    console.log('ERRO ====> ', journeys);
  }
};

const formatDate = (date, currentFormat = 'YYYY-MM-DD') => {
  return moment(date, currentFormat).format('DD/MM/YYYY');
};

const getStartAndEndDateFromFlight = (journeys, roundTrip) => {
  journeys = Array.isArray(journeys) ? journeys : Object.values(journeys);

  const length = journeys.length;

  if (roundTrip) {
    const sortedJourneys = journeys.sort((a, b) => a.id - b.id);

    return {
      startDate: formatDate(sortedJourneys[0].departureDate),
      endDate: formatDate(
        sortedJourneys[sortedJourneys.length - 1].departureDate
      ),
    };
  } else
    return {
      startDate: formatDate(journeys[0].departureDate),
      endDate: formatDate(journeys[length - 1].departureDate),
    };
};

const formatFlight = (flight) => {
  const { journeys } = flight;

  const roundTrip =
    journeys.length > 1
      ? journeys[0].departureStation.name ===
        journeys[journeys.length - 1].arrivalStation.name
      : false;

  return {
    roundTrip,
    journeys,
    ...getArrivalAndDepartureStation(
      journeys,
      (station) => `(${station.iataCode}) ${station.name}`
    ),
    ...getStartAndEndDateFromFlight(journeys, roundTrip),
  };
};

const formatHotel = (hotelObj) => {
  try {
    const { hotel, checkIn, checkOut } = hotelObj.reservations[0].hotelDetails;
    const { category, destination, chain, name } = hotel;

    return {
      category,
      chain: chain?.name,
      city: destination.city.pt,
      hotelName: name,
      startDate: checkIn,
      endDate: checkOut,
    };
  } catch (err) {
    console.error(err);
    return {};
  }
};

const formatCar = (car) => {
  if (car.reservations.length === 0)
    return {
      airConditioning: '',
      baggageSize: '',
      doors: '',
      passengers: '',
      traction: '',
      transmission: '',
      carType: '',
      carName: '',
      rental: '',
      startDate: '',
      endDate: '',
    };
  const { amenities, pickUp, dropOff, name, rental } =
    car.reservations[0]?.carDetails;
  const {
    airConditioning,
    baggageSize,
    doors,
    passengers,
    traction,
    transmission,
    type,
  } = amenities;

  return {
    airConditioning: airConditioning,
    baggageSize,
    doors,
    passengers,
    traction,
    transmission,
    carType: type,
    carName: name,
    rental: `(${rental.code}) ${rental.name}`,
    startDate: pickUp.split(' ')[0],
    endDate: dropOff.split(' ')[0],
  };
};

const formatBus = (bus) => {
  const { journeys } = bus;

  const roundTrip =
    journeys.length > 1
      ? journeys[0].departureStation ===
        journeys[journeys.length - 1].arrivalStation
      : false;

  return {
    roundTrip,
    journeys,
    ...getArrivalAndDepartureStation(journeys),
    ...getStartAndEndDateFromFlight(journeys, roundTrip),
  };
};

const formatOffice = (office) => {
  const {
    selectedRoom,
    office: officeInfo,
    date,
  } = office.reservations[0].officeDetails;

  return {
    selectedRoom,
    officeDate: officeInfo.date,
    officeName: officeInfo.name,
    roomName: selectedRoom.name,
    startDate: date,
  };
};

const FORMAT_FUNCTIONS = {
  [OrderTypes.FLIGHT]: formatFlight,
  [OrderTypes.HOTEL]: formatHotel,
  [OrderTypes.CAR]: formatCar,
  [OrderTypes.BUS]: formatBus,
  [OrderTypes.OFFICE]: formatOffice,
};

export const formatOrder = (order) => {
  const formatFunction = FORMAT_FUNCTIONS[order.type];
  const formattedObj = formatFunction ? formatFunction(order) : {};

  return {
    ...getBasics(order),
    ...formattedObj,
  };
};

export const formatBudget = (budget) => {
  return {
    ...getBasics(budget),
    ...budget,
  };
};
