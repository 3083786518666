import classNames from 'classnames';
import React from 'react';
import { useDropzone } from 'react-dropzone';
import { useIntl } from 'react-intl';

export default function FileUploader(props) {
  const { messages } = useIntl();

  // States
  const {
    acceptedFiles = {
      'image/*': ['.jpg', '.jpeg', '.png'],
      'application/pdf': [],
    },
    onDrop = () => {},
    white,
    dropMessage = messages['file-uploader.drop-receipts'],
    dragMessage = messages['file-uploader.drag-receipts'],
    allowedTypesMessage = 'pdf, png, jpeg, jpg',
  } = props;

  const { getRootProps, getInputProps, isDragActive, isDragReject } =
    useDropzone({
      accept: acceptedFiles,
      onDrop,
    });

  const renderDragMessage = () => {
    if (!isDragActive) {
      return (
        <div>
          <p className="text-center p-0 m-0">{dragMessage}</p>
        </div>
      );
    }

    if (isDragReject) {
      return (
        <div>
          <p className="text-center p-0 m-0">
            {messages['file-uploader.invalid-types']}
            <br />
            {messages['file-uploader.allowed-types']}
            <br />
            <span className="font-weight-semibold">{allowedTypesMessage}</span>
          </p>
        </div>
      );
    }

    return (
      <div>
        <p className="text-center p-0 m-0">{dropMessage}</p>
      </div>
    );
  };

  return (
    <div
      className={classNames(
        'file-uploader d-flex justify-content-center align-items-center',
        { white }
      )}
      {...getRootProps()}
    >
      <div className="container position-relative">
        <div className="position-absolute centralized drag-message">
          <input {...getInputProps()} />
          {renderDragMessage()}
        </div>
      </div>
    </div>
  );
}
