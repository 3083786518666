exports.organizationDefaultConfig = {
  allowOpenBooking: false,
  allowedTravelTypes: {
    bus: true,
    car: true,
    flight: true,
    hotel: true,
    office: true,
  },
  currency: 'BRL',
  editable: {
    costCenter: true,
    project: true,
  },
  connectors: {},
  locale: 'pt-BR',
  observations: false,
  openBooking: {
    allowed: false,
    daysBeforeCancellation: 1,
    daysBeforeExpiration: 1,
    providers: {
      flight: {
        '123MILHAS': false,
      },
    },
    requireReceipt: false,
  },
  providers: {
    ITERPEC_OPEN_BOOKING: false,
    OFFLINE_HOTELS: false,
  },
  refunds: {
    active: true,
    allowAdministrative: true,
    perKm: null,
    withoutReceipt: true,
  },
  required: {
    costCenter: false,
    motive: false,
    observation: false,
    project: false,
    tags: false,
  },
  roomsAdded: 0,
  travels: {
    organization: true,
    personal: true,
  },
  canAddOwnCard: true,
};
