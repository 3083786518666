import { ModalBody } from 'reactstrap';
import { useIntl } from 'react-intl';
import FareCard from './FareCard';

export default function FlightFareModal({ segments, updateFare, item }) {
  const { messages } = useIntl();
  return (
    <ModalBody className="flight">
      <div style={{ padding: '32px' }}>
        <div>
          <p
            style={{
              font: 'montserrat',
              fontWeight: 500,
              fontSize: '20px',
              color: '#43418E',
              lineHeight: '24.38px',
              marginBottom: 0,
            }}
          >
            {messages['flight.select-fare']}
          </p>
          <p
            style={{
              font: 'montserrat',
              fontWeight: 500,
              fontSize: '16px',
              color: '#7C7C82',
              marginTop: '6px',
              lineHeight: '19.5px',
            }}
          >
            {messages['flight.select-fare-description']}
          </p>
        </div>

        <div
          className="d-flex"
          style={{ marginTop: '40px', gap: '16px', maxWidth: '1000px' }}
        >
          <FareCard segments={segments} updateFare={updateFare} item={item} />
        </div>
      </div>
    </ModalBody>
  );
}
