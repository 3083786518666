import React from 'react';
import firebaseApp from '../../services/Firebase';
import CostCenterModal from '../../components/policy/CostCenterModal';
import DefaultDataListView from './DefaultDataListView';

export default function CostCenterDataListView() {
  return (
    <DefaultDataListView
      fieldName="costCenter"
      path="cost_centers"
      fetchFunction={firebaseApp.getCostCentersFromOrganization}
      deleteFunction={firebaseApp.deleteCostCenterFromOrganizationAndId}
      updateFunction={firebaseApp.updateCostCenterFromOrganization}
      createFunction={firebaseApp.createCostCenterFromOrganization}
      ItemModal={CostCenterModal}
      emptyResultsTitleId="admin.policy.empty.cost-center.title"
      emptyResultsDescriptionId="admin.policy.empty.cost-center.description"
      emptyResultsButtonTextId="admin.policy.empty.cost-center.btn"
      archiveKey="costCenters"
    />
  );
}
