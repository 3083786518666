import React from 'react';
import { useIntl } from 'react-intl';
import { Button } from 'reactstrap';
import AlterationFrame from '../../AlterationFrame';
import OptionCard from '../OptionCard';
import { FLIGHT_ALTERATION_TABS } from '..';
import orderStatus from '../../../../../constants/orderStatus';

import { ReactComponent as AlterDataIcon } from '../../../../../assets/img/icons/cancel.svg';
import { ReactComponent as LuggageIcon } from '../../../../../assets/img/icons/luggage.svg';
import { ReactComponent as WalletIcon } from '../../../../../assets/img/icons/wallet.svg';

export default function FlightAlterationMenu({
  order,
  goForAlteration,
  setCurrentTab,
}) {
  const { messages } = useIntl();

  // Render
  const renderBottom = () => {
    return (
      <div className="d-flex align-items-center justify-content-end">
        <Button color="link" onClick={goForAlteration} className="m-0">
          {messages['general.go-back']}
        </Button>
      </div>
    );
  };

  if (order.statusCode !== orderStatus.ISSUED) goForAlteration();

  return (
    <AlterationFrame
      title={messages['travel.alter.title']}
      subtitle={messages['travel.alter.subtitle']}
      BottomBar={renderBottom}
    >
      <div className="card-menu-display">
        {/* <OptionCard
          Icon={LuggageIcon}
          title={messages['travel.alter.flight.ancillary.title']}
          description={messages['travel.alter.flight.ancillary.description']}
          buttonText={messages['general.select']}
          onClick={() => setCurrentTab(FLIGHT_ALTERATION_TABS.GORDIAN)}
          className="show-up"
          style={{ animationDelay: '150ms' }}
        /> */}
        <OptionCard
          Icon={WalletIcon}
          title={messages['travel.alter.flight.cancellation.title']}
          description={messages['travel.alter.flight.cancellation.description']}
          buttonText={messages['general.select']}
          onClick={() => setCurrentTab(FLIGHT_ALTERATION_TABS.CANCELLATION)}
          className="show-up"
          style={{ animationDelay: '150ms' }}
        />
        <OptionCard
          Icon={AlterDataIcon}
          title={messages['travel.alter.flight.alteration.card.title']}
          description={
            messages['travel.alter.flight.alteration.card.description']
          }
          buttonText={messages['general.select']}
          onClick={() => setCurrentTab(FLIGHT_ALTERATION_TABS.ALTERATION)}
          className="show-up"
          style={{ animationDelay: '250ms' }}
        />
      </div>
    </AlterationFrame>
  );
}
