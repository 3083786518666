import { OrderTypes } from './orderTypes';

// Icons
import { ReactComponent as DecolarIcon } from '../assets/img/icons/openBooking/decolar-white.svg';
import { ReactComponent as Milhas123Icon } from '../assets/img/icons/openBooking/123milhas.svg';
import { ReactComponent as AzulIcon } from '../assets/img/icons/openBooking/azul.svg';
import { ReactComponent as ExpediaIcon } from '../assets/img/icons/openBooking/expedia.svg';
import { ReactComponent as GolIcon } from '../assets/img/icons/openBooking/gol.svg';
import { ReactComponent as KiwiIcon } from '../assets/img/icons/openBooking/kiwi.svg';
import { ReactComponent as LatamIcon } from '../assets/img/icons/openBooking/latam-white.svg';
import { ReactComponent as MaxmilhasIcon } from '../assets/img/icons/openBooking/maxmilhas-short.svg';
import { ReactComponent as OtherIcon } from '../assets/img/icons/openBooking/other.svg';

export const AllOpenBookingProviders = {
  KIWI: 'KIWI',
  '123MILHAS': '123MILHAS',
  MAX_MILHAS: 'MAX_MILHAS',
  LATAM: 'LATAM',
  DECOLAR: 'DECOLAR',
  EXPEDIA: 'EXPEDIA',
  GOL: 'GOL',
  AZUL: 'AZUL',
  OTHER: 'OTHER',
};

export const OpenBookingProvidersWebsites = {
  [AllOpenBookingProviders.KIWI]: 'https://www.kiwi.com/',
  [AllOpenBookingProviders['123MILHAS']]: 'https://123milhas.com/',
  [AllOpenBookingProviders.MAX_MILHAS]: 'https://www.maxmilhas.com.br/',
  [AllOpenBookingProviders.LATAM]: 'https://www.latamairlines.com/',
  [AllOpenBookingProviders.DECOLAR]: 'https://www.decolar.com/',
  [AllOpenBookingProviders.EXPEDIA]: 'https://www.expedia.com.br/',
  [AllOpenBookingProviders.GOL]: 'https://www.voegol.com.br/',
  [AllOpenBookingProviders.AZUL]: 'https://www.voeazul.com.br/',
};

export const FlightOpenBookingProviders = [
  AllOpenBookingProviders.KIWI,
  AllOpenBookingProviders['123MILHAS'],
  AllOpenBookingProviders.MAX_MILHAS,
  AllOpenBookingProviders.LATAM,
  AllOpenBookingProviders.DECOLAR,
  AllOpenBookingProviders.EXPEDIA,
  AllOpenBookingProviders.GOL,
  AllOpenBookingProviders.AZUL,
  AllOpenBookingProviders.OTHER,
];

export const OpenBookingProviders = {
  [OrderTypes.FLIGHT]: FlightOpenBookingProviders,
};

export const OpenBookingProvidersIcons = {
  [AllOpenBookingProviders.KIWI]: KiwiIcon,
  [AllOpenBookingProviders['123MILHAS']]: Milhas123Icon,
  [AllOpenBookingProviders.MAX_MILHAS]: MaxmilhasIcon,
  [AllOpenBookingProviders.LATAM]: LatamIcon,
  [AllOpenBookingProviders.DECOLAR]: DecolarIcon,
  [AllOpenBookingProviders.EXPEDIA]: ExpediaIcon,
  [AllOpenBookingProviders.GOL]: GolIcon,
  [AllOpenBookingProviders.AZUL]: AzulIcon,
  [AllOpenBookingProviders.OTHER]: OtherIcon,
};

export const OpenBookingProvidersStyle = {
  [AllOpenBookingProviders.DECOLAR]: {
    backgroundColor: '#4e15e4',
    color: 'white',
  },
  [AllOpenBookingProviders.EXPEDIA]: {
    backgroundColor: '#fddb32',
    color: '#191e3b',
  },
  [AllOpenBookingProviders.GOL]: {
    backgroundColor: '#ee7937',
    color: 'white',
  },
  [AllOpenBookingProviders.LATAM]: {
    backgroundColor: '#2a0088',
    color: 'white',
  },
  [AllOpenBookingProviders.MAX_MILHAS]: {
    backgroundColor: '#98da82',
    color: 'black',
  },
  [AllOpenBookingProviders.OTHER]: {
    backgroundColor: '#43418e',
    color: 'white',
  },
};
