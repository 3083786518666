import compact from 'lodash/compact';
import moment from 'moment';
import {
  getCityFromBusLocations,
  getCityFromCarLocations,
  getCityFromLatLong,
  getCityFromLocations,
} from '../utils/locations';
import { OrderTypes } from './orderTypes';

export const generateTravelUrl = (activeTab, search) => {
  const params = encodeURIComponent(
    JSON.stringify({ activeTab, search, autoSearch: true })
  );
  return `/travel/search/${activeTab}?params=${params}&timestamp=${moment().unix()}`;
};

export const getSearchObj = (inbound_date = null, outbound_date = null) => ({
  origin: {},
  destination: {},
  hotel_params: {
    adults: 1,
    rooms: 1,
  },
  flightParams: {
    baggages: 0,
    cabin: 'Economy',
  },
  twoWay: true,
  dropOffSamePickUp: true,
  inbound_date,
  outbound_date,
  searchCode: '',
  status: 'new',
});

export const getSearchMoment = (
  date,
  format = 'YYYY-MM-DD',
  outputFormat = null,
  doFormat = true
) => {
  const momentDate = moment(date, format);

  if (doFormat) {
    if (outputFormat) return momentDate.format(outputFormat);
    else return momentDate.toISOString();
  } else return momentDate;
};

export const getHotelMoment = (date, outputFormat, doFormat = true) =>
  getSearchMoment(date, 'DD/MM/YYYY hh:mm', outputFormat, doFormat);

export const getFlightCityObject = (iataCode, city) => ({
  airportCode: iataCode,
  cityCode: city,
  countryName: 'Brazil',
  label: `(${iataCode}) ${city}`,
  type: 'city',
  value: iataCode,
});

const getFlightCity = ({ iataCode, name }) => ({
  airportCode: iataCode,
  cityCode: name,
  countryName: 'Brazil',
  label: `(${iataCode}) ${name}`,
  type: 'city',
  value: iataCode,
});

const getBusCity = (station) => {
  const value = station
    .replace(/[^A-Za-z0-9_ ]/g, '')
    .toLowerCase()
    .split(' ')
    .filter((a) => a)
    .join('-');
  return {
    label: station,
    value,
    type: OrderTypes.bus,
  };
};

const getGenericJourneySearch = async (order, getCityFromStation) => {
  const searchObj = getSearchObj();

  const twoWay = order.journeys.length > 1;
  const outboundJourney = order.journeys[0];

  searchObj['origin'] = await getCityFromStation(
    outboundJourney.departureStation
  );
  searchObj['outbound_date'] = getSearchMoment(outboundJourney.departureDate);
  searchObj['twoWay'] = twoWay;

  // Is it two way?
  if (twoWay) {
    const inboundJourney = order.journeys[1];

    searchObj['destination'] = await getCityFromStation(
      inboundJourney.departureStation
    );
    searchObj['inbound_date'] = getSearchMoment(inboundJourney.departureDate);
  } else {
    searchObj['destination'] = await getCityFromStation(
      outboundJourney.arrivalStation
    );
  }

  return searchObj;
};

export const getFlightSearch = async (order, reservations) => {
  const searchObj = await getGenericJourneySearch(order, getFlightCity);
  const reservation = reservations[0];

  searchObj['flightParams'].baggages = reservation.flightDetails.fare.baggage;

  return searchObj;
};

export const getBusSearch = (order) =>
  getGenericJourneySearch(order, getCityFromBusLocations);

export const getHotelSearch = async (
  order,
  reservations,
  dateFormat,
  doFormat = true
) => {
  const searchObj = getSearchObj();
  const reservation = reservations[0];

  const cityPromise = getCityFromLocations(
    reservation.hotelDetails.hotel.destination.city.pt.split(',')[0]
  );

  searchObj['outbound_date'] = getHotelMoment(
    reservation.hotelDetails.checkIn,
    dateFormat,
    doFormat
  );
  searchObj['inbound_date'] = getHotelMoment(
    reservation.hotelDetails.checkOut,
    dateFormat,
    doFormat
  );
  searchObj['hotel_params'] = {
    adults: reservation.hotelDetails.selectedRoom.adults,
    rooms: 1,
  };

  searchObj['destination'] = await cityPromise;

  return searchObj;
};

export const getCarLocation = async (
  sameLocation,
  pickUpLocation,
  dropOffLocation
) => {
  if (sameLocation) {
    const cityName = await getCityFromLatLong(
      pickUpLocation.lat,
      pickUpLocation.lng
    );
    if (cityName) return Promise.all([getCityFromLocations(cityName)]);
  } else {
    const [pickUpCity, dropOffCity] = await Promise.all([
      getCityFromLatLong(pickUpLocation.lat, pickUpLocation.lng),
      getCityFromLatLong(dropOffLocation.lat, dropOffLocation.lng),
    ]);

    if (pickUpCity && dropOffCity) {
      if (pickUpCity === dropOffCity)
        return [getCityFromCarLocations(pickUpCity)];
      else
        return Promise.all([
          getCityFromCarLocations(pickUpCity),
          getCityFromCarLocations(dropOffCity),
        ]);
    }
  }

  return null;
};

export const getCarSearch = async (order, reservations) => {
  const searchObj = getSearchObj();
  const { carDetails } = reservations[0];
  const dropOffSamePickUp =
    carDetails.pickUpLocation.lat === carDetails.dropOffLocation.lat &&
    carDetails.pickUpLocation.lng === carDetails.dropOffLocation.lng;

  const cityPromises = getCarLocation(
    dropOffSamePickUp,
    carDetails.pickUpLocation,
    carDetails.dropOffLocation
  );

  searchObj['outbound_date'] = getSearchMoment(
    carDetails.pickUp,
    'DD/MM/YYYY HH:mm'
  );
  searchObj['inbound_date'] = getSearchMoment(
    carDetails.dropOff,
    'DD/MM/YYYY HH:mm'
  );

  const cities = compact(await cityPromises);
  const numCities = cities.length;

  if (numCities > 0) {
    if (numCities === 1) {
      searchObj['origin'] = cities[0];
      searchObj['dropOffSamePickUp'] = true;
    } else {
      searchObj['origin'] = cities[0];
      searchObj['destination'] = cities[1];
      searchObj['dropOffSamePickUp'] = false;
    }
  }

  return searchObj;
};

const GET_SEARCH = {
  [OrderTypes.FLIGHT]: getFlightSearch,
  [OrderTypes.BUS]: getBusSearch,
  [OrderTypes.HOTEL]: getHotelSearch,
  [OrderTypes.CAR]: getCarSearch,
};

export const makeUrlSearch = async (order, reservations) => {
  const type = order.type;
  if (type in GET_SEARCH) {
    const searchObj = await GET_SEARCH[type](order, reservations);

    return generateTravelUrl(type, searchObj);
  } else return null;
};
