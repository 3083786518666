import React from 'react';
import { Row } from 'reactstrap';
import { Colxx } from '../common/CustomBootstrap';
import moment from 'moment';
import { useIntl } from 'react-intl';
import CityName from './CityName';

export default function AirportWait({
  startTime,
  finishTime,
  station,
  params,
  cityName,
}) {
  const { messages, formatMessage } = useIntl();

  var start = new moment(startTime, [
    'DD/MM/YYYY HH:mm',
    'YYYY-MM-DD[T]HH:mm:ss',
  ]);
  var finish = new moment(finishTime, [
    'DD/MM/YYYY HH:mm',
    'YYYY-MM-DD[T]HH:mm:ss',
  ]);
  var duration = moment.duration(finish.diff(start));

  const hours = `${`${Math.floor(duration.asHours())}`.padStart(
    2,
    '0'
  )}:${`${duration.minutes()}`.padStart(2, '0')} hs`;

  const city = cityName ? (
    cityName
  ) : (
    <CityName station={station} params={params} />
  );

  if (duration.asMinutes() >= 60) {
    return (
      <Row className="pl-3">
        <Colxx xxs="12" className="alert">
          <img
            src="/assets/img/icons/time.svg"
            alt={messages['alts.components.flight.airport-wait']}
          />{' '}
          {formatMessage(
            { id: 'components.flight.airportwait.wait' },
            {
              hours,
              city,
              station: station?.iataCode ? station.iataCode : station,
            }
          )}
        </Colxx>
      </Row>
    );
  } else {
    return <div />;
  }
}
