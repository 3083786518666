import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Button } from 'reactstrap';
import { ALTERATION_TABS } from '..';
import { FlightTypes } from '../../../../../../constants/flight';
import appFunctions from '../../../../../../services/Functions';
import AlterationFrame from '../../../AlterationFrame';
import DateSelection from './DateSelection';
import ResultSelection from './ResultSelection';
import moment from 'moment';
import firebaseApp from '../../../../../../services/Firebase';
import searchStatus from '../../../../../../constants/searchStatus';

export default function FlightSelection({
  order,
  reservations,
  type,
  roundTrip,
  goToInitialTab,
  goToPrevious,
  setCurrentTab,
  selectFlight,
  flights,
  currentTab,
}) {
  const { messages } = useIntl();

  const [selectedDate, setSelectedDate] = useState(null);
  const [newSearchCode, setNewSearchCode] = useState(null);

  const [showResults, setShowResults] = useState(false);
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [error, setError] = useState(null);
  const [selectedFlight, setSelectedFlight] = useState(null);

  const journey = useMemo(
    () => order.journeys[type === FlightTypes.OUTBOUND ? 0 : 1],
    [order, type]
  );
  const ContentComponent = useMemo(
    () => (showResults ? ResultSelection : DateSelection),
    [showResults]
  );

  const frameSubtitle = useMemo(() => {
    if (loading)
      return messages[
        'travel.alter.flight.alteration.search.description.searching'
      ];
    if (showResults)
      return messages[
        'travel.alter.flight.alteration.search.description.select'
      ];
    return messages['travel.alter.flight.alteration.search.description.route'];
  }, [showResults, loading, type]);

  // Effects
  useEffect(() => {
    if (journey) {
      const today = moment();
      const date = moment(journey.departureDate, 'YYYY-MM-DD');

      setSelectedDate(date.isBefore(today) ? today.toDate() : date.toDate());
    }
  }, [journey]);

  useEffect(() => {
    if (newSearchCode) {
      setLoading(true);

      const unsub = firebaseApp
        .getSearch(newSearchCode)
        .onSnapshot((docSnap) => {
          const search = docSnap.data();

          if (search.status === searchStatus.DONE) {
            fetchResults();
            unsub();
          }
        });

      return () => {
        unsub && unsub();
      };
    }
  }, [newSearchCode]);

  // Functions
  const goToNext = (selectedFlight) => {
    selectFlight(type, selectedFlight);
    setCurrentTab(
      roundTrip && type === 'outbound'
        ? ALTERATION_TABS.INBOUND
        : ALTERATION_TABS.CHECKOUT
    );
  };

  const startSearch = async () => {
    if (selectedDate) {
      try {
        setLoading(true);
        setShowResults(true);

        const { data } = await appFunctions.createSearchBasedOnFlight(
          order.searchCode,
          moment(selectedDate).format('YYYY-MM-DD'),
          type
        );

        setNewSearchCode(data);
      } catch (err) {
        console.error(err);
        setError(messages['travel.alter.flight.alteration.searching.error']);
        setLoading(false);
      }
    }
  };

  const fetchResults = async () => {
    try {
      const results = [];
      const airline = reservations[0].flightDetails.airline;
      const {
        departureStation: { iataCode: originalDepartureStation },
        arrivalStation: { iataCode: originalArrivalStation },
      } = order.journeys[type === FlightTypes.OUTBOUND ? 0 : 1];

      const resultsSnap = await firebaseApp
        .getSearchResults(newSearchCode)
        .get();

      resultsSnap.forEach((resultRef) => {
        const result = {
          id: resultRef.id,
          ...resultRef.data(),
        };

        const { departureStation, arrivalStation } = result.journeys[0];

        if (
          result.airline === airline &&
          departureStation === originalDepartureStation &&
          arrivalStation === originalArrivalStation
        )
          results.push(result);
      });

      setResults(results.sort((a, b) => a.fare.total - b.fare.total));
      setLoading(false);
    } catch (err) {
      console.error(err);
      setError(messages['travel.alter.flight.alteration.searching.error']);
      setShowResults(false);
      setLoading(false);
    }
  };

  const goBack = () => {
    if (showResults) {
      return setShowResults(false);
    }

    if (currentTab === FlightTypes.OUTBOUND) return goToInitialTab();

    return goToPrevious();
  };

  // Render
  const renderBottom = () => (
    <div className="d-flex align-items-center justify-content-end">
      <Button color="link" onClick={goBack} className="m-0">
        {messages['general.go-back']}
      </Button>
      {roundTrip &&
      (currentTab === FlightTypes.OUTBOUND || flights.outbound) ? (
        <Button color="link" onClick={() => goToNext(null)} className="m-0">
          {messages['travel.alter.flight.alteration.btn.keep-reservation']}
        </Button>
      ) : null}
      {showResults ? (
        <Button
          color="primary"
          onClick={() => selectedFlight && goToNext(selectedFlight)}
          disabled={!selectedFlight || loading}
          className="m-0"
        >
          {
            messages[
              roundTrip && type === 'outbound'
                ? 'general.go-forward'
                : 'general.finish'
            ]
          }
        </Button>
      ) : (
        <Button
          color="primary"
          onClick={startSearch}
          disabled={!selectedDate || loading}
          className="m-0 ml-2"
        >
          {messages['general.search']}
        </Button>
      )}
    </div>
  );

  return (
    <AlterationFrame
      title={messages['travel.alter.flight.alteration.title']}
      subtitle={frameSubtitle}
      BottomBar={renderBottom}
      className="flight-selection"
    >
      {error ? <p className="error-badge mb-4">{error}</p> : null}
      <ContentComponent
        order={order}
        reservations={reservations}
        journey={journey}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        loading={loading}
        results={results}
        setSelectedFlight={setSelectedFlight}
        selectedFlight={selectedFlight}
        flights={flights}
        roundTrip={roundTrip}
        type={type}
      />
    </AlterationFrame>
  );
}
