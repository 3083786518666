export default [
  {
    sigla: 'AC',
    nome: 'Acre',
    codigo: 12,
  },
  {
    sigla: 'AL',
    nome: 'Alagoas',
    codigo: 27,
  },
  {
    sigla: 'AM',
    nome: 'Amazonas',
    codigo: 13,
  },
  {
    sigla: 'AP',
    nome: 'Amapá',
    codigo: 16,
  },
  {
    sigla: 'BA',
    nome: 'Bahia',
    codigo: 29,
  },
  {
    sigla: 'CE',
    nome: 'Ceará',
    codigo: 23,
  },
  {
    sigla: 'DF',
    nome: 'Distrito Federal',
    codigo: 53,
  },
  {
    sigla: 'ES',
    nome: 'Espírito Santo',
    codigo: 32,
  },
  {
    sigla: 'GO',
    nome: 'Goiás',
    codigo: 52,
  },
  {
    sigla: 'MA',
    nome: 'Maranhão',
    codigo: 21,
  },
  {
    sigla: 'MG',
    nome: 'Minas Gerais',
    codigo: 31,
  },
  {
    sigla: 'MS',
    nome: 'Mato Grosso do Sul',
    codigo: 50,
  },
  {
    sigla: 'MT',
    nome: 'Mato Grosso',
    codigo: 51,
  },
  {
    sigla: 'PA',
    nome: 'Pará',
    codigo: 15,
  },
  {
    sigla: 'PB',
    nome: 'Paraíba',
    codigo: 25,
  },
  {
    sigla: 'PE',
    nome: 'Pernambuco',
    codigo: 26,
  },
  {
    sigla: 'PI',
    nome: 'Piauí',
    codigo: 22,
  },
  {
    sigla: 'PR',
    nome: 'Paraná',
    codigo: 41,
  },
  {
    sigla: 'RJ',
    nome: 'Rio de Janeiro',
    codigo: 33,
  },
  {
    sigla: 'RN',
    nome: 'Rio Grande do Norte',
    codigo: 24,
  },
  {
    sigla: 'RO',
    nome: 'Rondônia',
    codigo: 11,
  },
  {
    sigla: 'RR',
    nome: 'Roraima',
    codigo: 14,
  },
  {
    sigla: 'RS',
    nome: 'Rio Grande do Sul',
    codigo: 43,
  },
  {
    sigla: 'SC',
    nome: 'Santa Catarina',
    codigo: 42,
  },
  {
    sigla: 'SE',
    nome: 'Sergipe',
    codigo: 28,
  },
  {
    sigla: 'SP',
    nome: 'São Paulo',
    codigo: 35,
  },
  {
    sigla: 'TO',
    nome: 'Tocantins',
    codigo: 17,
  },
];
