import firebaseApp from '../services/Firebase';
import { getDocument } from './firebase';
import { mapIntoObject } from './others';

export function generateField(field, collection) {
  return {
    field,
    getValue: (item = {}) => item[field]?.value,
    getFunction: async (item, value) =>
      value
        ? getDocument(() =>
            firebaseApp
              .getCommonFieldById(item.organizationId, collection, value)
              .get()
          )
        : null,
  };
}

const ORDER_FIELDS = [
  generateField('costCenter', 'cost_centers'),
  generateField('project', 'projects'),
  generateField('motive', 'motives'),
];

/**
 * Obtém os dados do arquivo
 *
 * @param {Array} items Itens
 * @param {Array<Object>} fields Campos
 * @return {Object} Objeto cuja chave é o campo e o valor é os campos únicos
 */
export async function getFieldsFromArchive(
  order,
  getDataFromArchive = () => null,
  fields = []
) {
  const values = {};

  const getFieldBasedOnConfig = async (item, fieldConfig) => {
    const fieldValue = fieldConfig.getValue(item);
    const data = fieldValue
      ? await getDataFromArchive(`${fieldConfig.field}-${fieldValue}`, () =>
          fieldConfig.getFunction(item, fieldValue)
        )
      : null;

    values[fieldConfig.field] = data;
  };

  await Promise.all(fields.map((field) => getFieldBasedOnConfig(order, field)));

  return values;
}

export async function getOrderCommonFields(
  order,
  getDataFromArchive = () => null
) {
  return getFieldsFromArchive(order, getDataFromArchive, ORDER_FIELDS);
}

export async function getBudgetItemsCategoriesFromArchive(
  items,
  organizationId,
  getDataFromArchive = () => null
) {
  const { categoryIds, valuePromises } = items.reduce(
    (obj, item) => {
      const category = item.category?.value;

      if (category && !obj.set.has(category)) {
        obj.set.add(category);
        obj.categoryIds.push(category);
        obj.valuePromises.push(
          getDataFromArchive(`category-${category}`, () =>
            getDocument(() =>
              firebaseApp
                .getCommonFieldById(
                  organizationId,
                  'refund_categories',
                  category
                )
                .get()
            ).catch((err) => null)
          )
        );
      }

      return obj;
    },
    {
      categoryIds: [],
      valuePromises: [],
      set: new Set(),
    }
  );

  return mapIntoObject(categoryIds, await Promise.all(valuePromises));
}
