import React from 'react';
import times from 'lodash/times';
import { useIntl } from 'react-intl';

export default function HotelStars({ hotelId, hotel }) {
  const { messages } = useIntl();

  return (
    <span className="ml-2">
      {times(hotel.category, (index) => {
        return (
          <img
            key={`hotel-${hotelId}-category-${index}`}
            src="/assets/img/hotel-star.svg"
            className="ml-1"
            alt={messages['alts.components.search.hotel-item.hotel-category']}
          />
        );
      })}
    </span>
  );
}
