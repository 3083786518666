import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import Spinner from '../../../../../common/Spinner';
import MessageWithIcon from '../../../../../common/MessageWithIcon';
import FlightAlterationItem from './FlightAlterationItem';
import sortBy from 'lodash/sortBy';

import { ReactComponent as ExclamationIcon } from '../../../../../../assets/img/icons/exclamation.svg';

const mergeAllSegmentsIntoOneItem = (
  results,
  highestPrice,
  maxSymbolItems = 3
) => {
  if (!results.length) return [];

  const arrSegments = [];

  results.forEach((item, itemIndex) => {
    item.journeys[0].segments.forEach((segment, segmentIndex) => {
      arrSegments.push({
        ...segment,
        itemIndex,
        segmentIndex,
        estimatedPrice: Math.ceil(
          item.fare.total / highestPrice / (1 / maxSymbolItems)
        ),
      });
    });
  });

  return [
    {
      ...results[0],
      journeys: [
        {
          ...results[0].journeys[0],
          segments: sortBy(arrSegments, [
            'estimatedPrice',
            'departureDateTime',
          ]),
        },
      ],
    },
  ];
};

export default function ResultSelection({
  loading,
  results,
  setSelectedFlight,
}) {
  const { messages } = useIntl();

  const { isEmpty, unifiedResults = [] } = useMemo(() => {
    const highestPrice = results.reduce(
      (value, result) =>
        value > result.fare.total ? value : result.fare.total,
      0
    );

    return {
      isEmpty: results.length === 0,
      highestPrice,
      unifiedResults: mergeAllSegmentsIntoOneItem(results, highestPrice),
    };
  }, [results]);

  // Functions
  const doSegmentSelection = (selectedSegment) => {
    const { itemIndex, segmentIndex, estimatedPrice, ...segment } =
      selectedSegment;

    const item = results[itemIndex];
    const { segments, ...journey } = item.journeys[0];

    setSelectedFlight({
      ...item,
      journeys: [
        {
          ...segment,
          ...journey,
        },
      ],
    });
  };

  // Render
  const renderResults = () => (
    <div className="py-3">
      {unifiedResults.map((result, index) => {
        return (
          <FlightAlterationItem
            key={`${result.id}`}
            index={index}
            isTwoWay={false}
            item={result}
            doSegmentSelection={doSegmentSelection}
          />
        );
      })}
    </div>
  );

  const renderEmpty = () => {
    return (
      <div>
        <MessageWithIcon
          className="mt-3 show-up"
          text={messages['travel.alter.flight.alteration.empty-data']}
          Icon={ExclamationIcon}
          iconStyle={{ width: '7%' }}
          style={{ animationDelay: '200ms' }}
        />
      </div>
    );
  };

  const renderContent = () => (isEmpty ? renderEmpty() : renderResults());

  const renderLoading = () => {
    return (
      <div className="w-100 h-100 flex-center">
        <Spinner white relative style={{ widht: 60, height: 60 }} />
      </div>
    );
  };

  return loading ? renderLoading() : renderContent();
}
