import { GoogleApiWrapper, IProvidedProps } from 'google-maps-react';

import config from '../../constants/GoogleMaps';
import classNames from 'classnames';
import { useCallback, useMemo, useState } from 'react';
import useCustomIntl from 'src/portao3-legacy/hooks/useCustomIntl';
import { debounce } from 'lodash';
import { TMapLocation } from 'src/portao3-legacy/types/maps';
import { TLocation, TObject } from 'src/portao3-legacy/types/others';

const PROXIMITY_ICONS: TObject<string> = {
  street_address: '/assets/img/icons/proximity/search_city.svg',
  establishment: '/assets/img/icons/proximity/search_shop.svg',
  political: '/assets/img/icons/proximity/neighborhood.svg',
  administrative_area_level_2: '/assets/img/icons/proximity/search_other.svg',
  locality: '/assets/img/icons/proximity/search_other.svg',
  administrative_area_level_1: '/assets/img/icons/proximity/search_other.svg',
};

const EXCLUDENING_ADDRESS_TYPES = new Set([
  'administrative_area_level_1',
  'administrative_area_level_2',
  'locality',
]);

interface IProps extends IProvidedProps {
  simple?: boolean;
  editable?: boolean;
  placeholder?: string;
  city?: string;
  includeCity?: boolean;
  maxResults?: number;
  fullLocation?: boolean;
  onPlaceSelection: (address: TLocation) => void;
}

function LocationSearch({
  simple,
  editable = true,
  placeholder,
  google,
  includeCity,
  city,
  maxResults = 5,
  fullLocation,
  onPlaceSelection,
}: IProps) {
  const { messages } = useCustomIntl();

  // States
  const [isFocused, setIsFocused] = useState(false);
  const [address, setAddress] = useState('');
  const [isHidden, setIsHidden] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [places, setPlaces] = useState<TMapLocation[]>([]);

  const finalPlaceholder = useMemo(
    () =>
      placeholder ||
      messages['containers.search.hotel-proximity-search.address-placeholder'],
    [placeholder]
  );

  // Functions
  const doClearStates = () => {
    setPlaces([]);
    setIsLoading(false);
    setIsHidden(true);
  };

  const onAddressChange = (address: string) => {
    setAddress(address);
    doClearStates();
    fetchAdress(address);
  };

  const getCoordinatesFromAddress = useCallback(
    (address: string): Promise<TMapLocation[] | null> => {
      const geocoder = new google.maps.Geocoder();

      if (!geocoder) return Promise.resolve(null);

      return new Promise((resolve, reject) => {
        geocoder.geocode({ address }, (result: any[], status: string) => {
          if (status !== 'OK') return reject(null);

          resolve(
            includeCity
              ? result
              : result.filter(
                  (obj) => !EXCLUDENING_ADDRESS_TYPES.has(obj.types[0])
                )
          );
        });
      });
    },
    [includeCity, google]
  );

  const fetchAdress = useCallback(
    debounce(async (address: string) => {
      try {
        if (address.length < 2) return null;

        setIsLoading(true);

        const places = await getCoordinatesFromAddress(`${address}, ${city}`);

        if (places) {
          if (places.length) {
            setIsHidden(false);
            setPlaces(places.slice(0, maxResults));
          } else {
            setIsHidden(true);
            setPlaces([]);
          }
        }
      } catch (err) {
        console.error('Unable to fetch address', err);
      } finally {
        setIsLoading(false);
      }
    }, 1000),
    [getCoordinatesFromAddress, city, maxResults]
  );

  const onPlaceClick = (place: TMapLocation) => {
    const { lat, lng } = place.geometry.location;

    const placeObj: TLocation = {
      lat: lat(),
      lng: lng(),
    };

    if (fullLocation) placeObj['address'] = place.formatted_address;

    onPlaceSelection(placeObj);

    setPlaces([]);
    setAddress('');
    setIsHidden(true);
  };

  // Render
  return (
    <div className={classNames('location-search', { simple })}>
      <div className="proximity-card">
        <input
          id="proximity-address"
          placeholder={finalPlaceholder}
          value={editable && isFocused ? address : address}
          onChange={(e) => onAddressChange(e.target.value)}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          readOnly={!editable}
        />

        {isLoading ? <div className="loading" /> : null}

        {!isLoading && places.length ? (
          <img
            className="cancel-btn"
            src="/assets/img/icons/proximity/cancel-a.svg"
            alt={
              messages['alts.containers.search.hotel-proximity-search.erase']
            }
            onClick={() => {
              setAddress('');
              doClearStates();
            }}
          />
        ) : null}
      </div>

      <div className={classNames('proximity-results', { hidden: isHidden })}>
        {places.map((place, index) => (
          <div
            className="place"
            key={place.place_id}
            onClick={() => onPlaceClick(place)}
            style={{ animationDelay: `${150 + index * 150}ms` }}
          >
            <object
              className="location-icon"
              data={
                PROXIMITY_ICONS[place.types[0]] ||
                '/assets/img/icons/proximity/neighborhood.svg'
              }
              type="image/svg+xml"
              fill="white"
            />
            {place.formatted_address}
          </div>
        ))}
      </div>
    </div>
  );
}

export default GoogleApiWrapper({
  apiKey: config.key,
})(LocationSearch);
