import classNames from 'classnames';
import React, { useMemo } from 'react';
import NumberFormat from 'react-number-format';
import CustomAnimatedCheckbox from '../../common/CustomCheckbox';

export default function RecurrenceItem({
  name,
  title,
  description,
  recurrence,
  setRecurrence,
  checkboxSize = '1.5rem',
  singularSuffix = '',
  pluralSuffix = '',
  maxDays = 1000,
  className,
  renderBottomMessage,
}) {
  const checked = useMemo(
    () => recurrence.type === name,
    [recurrence.type, name]
  );

  const { value, suffix } = useMemo(() => {
    const value = checked ? recurrence.days : 1;
    const suffix = value > 1 ? pluralSuffix : singularSuffix;

    return { value, suffix: ` ${suffix}` };
  }, [checked, singularSuffix, pluralSuffix, recurrence]);

  // Functions
  const onCheck = () => {
    if (recurrence.type === name) return setRecurrence({});

    setRecurrence({ type: name, days: 1 });
  };

  const onDaysChange = ({ floatValue }) => {
    if (recurrence.type !== name) return;

    setRecurrence((r) => ({
      ...r,
      days: floatValue > maxDays ? maxDays : floatValue,
    }));
  };

  // Render
  return (
    <section className={classNames('d-flex', className)}>
      <div className="flex-shrink-0 mr-3">
        <CustomAnimatedCheckbox
          checked={checked}
          size={checkboxSize}
          onChange={onCheck}
        />
      </div>
      <div className="flex-grow-1">
        <h4
          className="font-primary fs-0-95rem m-0 p-0 mb-1 pointer"
          onClick={onCheck}
        >
          {title}
        </h4>
        <div
          className="d-flex"
          style={{ pointerEvents: checked ? 'all' : 'none' }}
        >
          <span className="fs-0-8rem text-muted flex-shrink-0">
            {description}
          </span>
          <NumberFormat
            className="days-input ml-1 fs-0-8rem font-weight-medium text-primary flex-grow-1"
            value={value}
            onValueChange={onDaysChange}
            allowNegative={false}
            allowLeadingZeros={false}
            suffix={suffix}
          />
        </div>
        {renderBottomMessage ? renderBottomMessage(recurrence, checked) : null}
      </div>
    </section>
  );
}
