import React from 'react';
import { Nav, NavItem } from 'reactstrap';
import classnames from 'classnames';
import { NavLink } from 'react-router-dom';
import { useIntl } from 'react-intl';

export default function ExpenseTypeSwitch({
  expenseType,
  setExpenseType,
  isNew,
}) {
  const { messages } = useIntl();

  return isNew ? (
    <Nav tabs className="card-header-tabs">
      <div
        onClick={() => {
          setExpenseType('items');
        }}
        className={classnames({
          active: expenseType === 'items',
          'nav-link': true,
        })}
      >
        <h5>{messages['expense.type.items']}</h5>
      </div>
      <div
        className={classnames({
          active: expenseType === 'km',
          'nav-link': true,
        })}
        onClick={() => {
          setExpenseType('km');
        }}
      >
        <h5>{messages['expense.type.km']}</h5>
      </div>
    </Nav>
  ) : (
    <Nav tabs className="card-header-tabs" style={{ pointerEvents: 'none' }}>
      <NavItem>
        <NavLink
          to="#"
          location={{}}
          className={classnames({
            active: true,
            'nav-link': true,
          })}
        >
          <h5>{messages[`expense.type.${expenseType}`]}</h5>
        </NavLink>
      </NavItem>
    </Nav>
  );
}
