import React, { Component } from 'react';
import { Row } from 'reactstrap';
import { Colxx } from '../../components/common/CustomBootstrap';
import { connect } from 'react-redux';
import filter from 'lodash/filter';
import sortBy from 'lodash/sortBy';
import map from 'lodash/map';
import moment from 'moment';

import PackageFlightItem from './PackageFlightItem';
import FlightLoading from './FlightLoading';
import FlightFilters from './FlightFilters';

import Pagination from '../pages/Pagination';
import NoResults from '../../components/search/NoResults';
import LoadSearchStatus from '../../constants/loadSearchStatus';

class PackageFlightResults extends Component {
  constructor(props) {
    super(props);

    this.state = {
      price: {
        selected_min: null,
        selected_max: null,
      },
      stops: {},
      airlines: {},
      departureTime: [],
      itemsPerPage: 10,
      currentPage: 1,
    };

    this.baseState = this.state;
  }

  // Lifecycle
  componentDidUpdate(prevProps) {
    if (this.props.results.length !== prevProps.results.length) {
      if (this.props.results.length === 0) {
        this.setState(this.baseState);
      }
    }
  }

  // Filter
  filterResults = () => {
    const { results, status, isLoading, params } = this.props;
    const { price, stops, airlines, departureTime, itemsPerPage, currentPage } =
      this.state;

    let filtered = filter(results, function (o) {
      if (price.selected_min && o.fare.total < price.selected_min) return false;
      if (price.selected_max && o.fare.total > price.selected_max) return false;

      if (airlines[o.airline] === false) return false;

      for (let iJ = 0; iJ < o.journeys.length; iJ++) {
        for (let iS = 0; iS < o.journeys[iJ].segments.length; iS++) {
          // Check for number of Stops
          let numStops = o.journeys[iJ].segments[iS].flights.length - 1;

          if (stops[numStops] === false) return false;

          // Check for departure time
          let departureHour = moment(
            o.journeys[iJ].segments[iS].departureDateTime
          ).hour();
          if (departureTime[iJ] && departureHour < departureTime[iJ].min)
            return false;
          if (departureTime[iJ] && departureHour > departureTime[iJ].max)
            return false;
        }
      }

      return true;
    });

    const totalItems =
      results.length === params.totalItems
        ? filtered.length
        : params.totalItems || results.length;

    filtered = sortBy(filtered, 'fare.total').slice(
      (currentPage - 1) * itemsPerPage,
      currentPage * itemsPerPage
    );

    if (
      filtered.length < itemsPerPage &&
      status === LoadSearchStatus.NEW &&
      !isLoading
    ) {
      this.props.getRemainingFlightSearch();
    }

    return [filtered, totalItems];
  };

  // Filter Handlers
  onPriceChange = (selectedPrices) => {
    const { price } = this.state;

    const selected_min = selectedPrices[0];
    const selected_max = selectedPrices[1];

    this.setState({
      currentPage: 1,
      price: {
        ...price,
        selected_min,
        selected_max,
      },
    });
  };

  onAirlineChange = (e, airline) => {
    const { airlines } = this.state;

    airlines[airline] = e.target.checked;

    this.setState({ currentPage: 1, airlines });
  };

  onStopChange = (checked, stop) => {
    const { stops } = this.state;

    stops[stop] = checked;

    this.setState({ currentPage: 1, stops });
  };

  onHourChange = (index, selectedPrices) => {
    const { departureTime } = this.state;

    departureTime[index] = {};
    departureTime[index].min = selectedPrices[0];
    departureTime[index].max = selectedPrices[1];

    this.setState({
      currentPage: 1,
      departureTime,
    });
  };

  // Pagination
  onChangePage = (currentPage) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    this.setState({ currentPage });
  };

  // Render
  renderPagination(totalItems) {
    const { itemsPerPage, currentPage } = this.state;

    return (
      totalItems > itemsPerPage && (
        <Pagination
          currentPage={currentPage}
          totalPage={totalItems ? Math.ceil(totalItems / itemsPerPage) : 1}
          onChangePage={(i) => this.onChangePage(i)}
        />
      )
    );
  }

  renderResults() {
    const { params, status, isLoading, results } = this.props;
    const [filteredResults, totalItems] = this.filterResults();

    if (isLoading) {
      return <FlightLoading />;
    }

    if (status === LoadSearchStatus.EMPTY) {
      return <NoResults />;
    }

    return (
      <div>
        {map(filteredResults, (result, index) => {
          return (
            <PackageFlightItem
              key={`result-${index}`}
              index={index}
              item={result}
              params={params}
              changeFlight={this.props.changeFlight}
            />
          );
        })}

        {isLoading && <FlightLoading />}

        {this.renderPagination(totalItems)}
      </div>
    );
  }

  render() {
    return (
      <Row className="mt-5">
        <Colxx xxs="12" sm="3">
          <FlightFilters
            params={this.props.params}
            status={this.props.status}
            price={this.state.price}
            onPriceChange={this.onPriceChange}
            stops={this.state.stops}
            onStopChange={this.onStopChange}
            airlines={this.state.airlines}
            onAirlineChange={this.onAirlineChange}
            departureTime={this.state.departureTime}
            onHourChange={this.onHourChange}
          />
        </Colxx>
        <Colxx xxs="12" sm="9" className="pb-5 mb-5">
          {this.renderResults()}
        </Colxx>
      </Row>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { user } = auth;
  return { user };
};

export default connect(mapStateToProps)(PackageFlightResults);
