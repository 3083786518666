import { useParams } from 'react-router-dom';

import TopNav from '../containers/navs/Topnav';
import Timeline from '../containers/timeline/Timeline';
import OrderModal from '../containers/timeline/OrderModal';
import { TRAVEL_LOCATION } from '../constants/locations';

export default function TravelLayout(props) {
  const { orderId } = useParams();

  // Render
  return (
    <div id="app-container">
      <Timeline />

      {orderId && (
        <OrderModal key={orderId} orderId={orderId} path={TRAVEL_LOCATION} />
      )}

      <main>
        <TopNav history={props.history} />
        <div className="container-fluid">{props.children}</div>
      </main>
    </div>
  );
}
