import classNames from 'classnames';
import { useIntl } from 'react-intl';
import FixedSelect from 'src/portao3-legacy/components/common/FixedSelect';
import { MultiPurpleStyle } from 'src/portao3-legacy/constants/selectStyles';

export default function FixedSelectItem({
  title,
  value,
  options,
  onChange,
  className,
}) {
  const { messages } = useIntl();

  // Render
  return (
    <label className={classNames('w-100', className)}>
      <p className="text-one">{title}</p>

      <FixedSelect
        className="react-select select tags is-multi w-100"
        classNamePrefix="react-select"
        isClearable={false}
        placeholder=""
        value={value}
        noOptionsMessage={() => messages['general.no-options-found']}
        options={options}
        onChange={onChange}
        isMulti
        maxMenuHeight={150}
        styles={MultiPurpleStyle}
      />
    </label>
  );
}
