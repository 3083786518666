export const RecurrenceBudgetTypes = {
  date: 'date',
  interval: 'interval',
};

export const RecurrenceBudgetErrorIds = {
  EXPENSE_CARD_ARCHIVED: 'EXPENSE_CARD_ARCHIVED',
  INVALID_BUDGET_VALUE: 'INVALID_BUDGET_VALUE',
  MISSING_FIELDS: 'MISSING_FIELDS',
};

export const RecurrenceBudgetHistoryStatus = {
  SUCCESS: 'SUCCESS',
  FAIL: 'FAIL',
};
