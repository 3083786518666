import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import SeatSelectionItem from './SeatSelectionItem';

import { ReactComponent as OutboundIcon } from '../../../../../assets/img/icons/oneway.svg';
import { ReactComponent as InboundIcon } from '../../../../../assets/img/icons/inbound.svg';

export default function BusSegmentSeats({
  className,
  isRoundTrip,
  segmentIndex,
  reservations,
  onSeatSelection,
  seatsInfo = {},
}) {
  const { messages } = useIntl();

  // States
  const [title, Icon] = useMemo(
    () =>
      segmentIndex
        ? [messages['travel.inbound'], InboundIcon]
        : [messages['travel.outbound'], OutboundIcon],
    [segmentIndex]
  );

  return (
    <section className={classNames('segment-seats', className)}>
      {isRoundTrip ? (
        <div className="d-flex align-items-center mb-3">
          <Icon
            width="1.2rem"
            height="1.2rem"
            fill="white"
            style={{ opacity: 0.7 }}
          />
          <h3 className="m-0 p-0 ml-2 font-primary">{title}</h3>
        </div>
      ) : null}

      <div className="mx-3">
        {reservations.map((reservation, index) => {
          const sellKey = reservation.busDetails.fare.sellKey;

          return (
            <SeatSelectionItem
              key={`reservation-${index}`}
              reservation={reservation}
              sellKey={sellKey}
              seatInfo={seatsInfo[sellKey]}
              onSelect={(seatId) => onSeatSelection(sellKey, seatId)}
              onClear={() => onSeatSelection(sellKey)}
            />
          );
        })}
      </div>
    </section>
  );
}
