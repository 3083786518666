import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import OfficeResults from './OfficeResults';

import firebaseApp from '../../services/Firebase';
import { trackPropertiesSegment } from '../../utils/segment';

import SearchStatus from '../../constants/searchStatus';
import LoadSearchStatus from '../../constants/loadSearchStatus';

import { setPassenger } from '../../redux/timeline/actions';
import { analyzeReservationParams } from '../../utils/reservation';

class OfficeSearch extends Component {
  constructor(props) {
    super(props);

    this.initialState = {
      results: [],
      status: LoadSearchStatus.NEW,
      isLoading: true,
      lastOffice: null,
      params: {},
    };

    this.state = this.initialState;
  }

  // Search
  componentDidUpdate(prevProps) {
    if (
      prevProps.search.status !== SearchStatus.LOADING &&
      this.props.search.status === SearchStatus.LOADING
    ) {
      this.setState(this.initialState);
    }

    if (
      prevProps.search.status !== SearchStatus.DONE &&
      this.props.search.status === SearchStatus.DONE
    ) {
      this.getParams();
      this.getInitialOfficeSearch();
    }
  }

  getParams = async () => {
    const { search } = this.props;

    const paramSnap = await firebaseApp
      .getSearchOfficeParams(search.searchCode)
      .get();

    this.setState({ params: paramSnap.data() || {} });
  };

  getInitialOfficeSearch = async () => {
    const { search } = this.props;

    const resultsSnap = await firebaseApp
      .getInitialOfficeResults(search.searchCode)
      .get();

    if (resultsSnap.size === 0) {
      this.setState({
        ...this.initialState,
        isLoading: false,
        status: LoadSearchStatus.EMPTY,
      });
    }

    let newResults = [];
    let lastOffice = null;

    resultsSnap.forEach((resultSnap) => {
      const result = resultSnap.data();
      result.id = resultSnap.id;

      newResults.push(result);
      lastOffice = resultSnap;
    });

    const results = this.state.results.concat(newResults);

    this.setState({ isLoading: false, results, lastOffice });
  };

  getRemainingOfficeSearch = async () => {
    const { search } = this.props;
    const { lastOffice } = this.state;

    this.setState({ isLoading: true });

    const resultsSnap = await firebaseApp
      .getRemainingOfficeResults(search.searchCode, lastOffice)
      .get();

    let newResults = [];

    resultsSnap.forEach((resultSnap) => {
      const result = resultSnap.data();
      result.id = resultSnap.id;

      newResults.push(result);
    });

    const results = this.state.results.concat(newResults);

    this.setState({ results, isLoading: false, status: LoadSearchStatus.ALL });
  };

  // Selection
  reserveItem = async ({ reservationParams, item, selectedRoom }) => {
    const { user, setPassenger, search } = this.props;

    const newReservationParams = await analyzeReservationParams(
      reservationParams
    );

    const orderDetails = {
      ...newReservationParams,
      fare: {
        issueUntil: null,
        total: selectedRoom.amount,
      },
      provider: {
        origin: item.origin,
      },
      searchCode: search.searchCode,
    };

    const reservation = {
      office: item.office,
      officeId: item.officeId,
      selectedRoom,
      date: moment(search.outbound_date).format('DD/MM/YYYY'),
      confirmation: '',
    };

    trackPropertiesSegment('New Reservation', {
      ...orderDetails,
      type: 'office',
    });

    await firebaseApp.createOfficeReservation({
      organizationId: user.organizationId,
      passenger: newReservationParams.passenger,
      passengerId: newReservationParams.passengerId,
      orderDetails,
      reservations: [reservation],
      filterTimestamp: moment(search.outbound_date, 'DD/MM/YYYY')
        .endOf('day')
        .utc()
        .unix(),
      type: 'office',
    });

    // Change timeline to selected user
    setPassenger(reservationParams.passengerTimeline);
  };

  // Render
  render() {
    const { results, params, status, isLoading } = this.state;
    const { search } = this.props;

    if (search.status === SearchStatus.NEW) return <div />;

    return (
      <OfficeResults
        results={results}
        params={params}
        search={search}
        status={status}
        isLoading={isLoading}
        getRemainingOfficeSearch={this.getRemainingOfficeSearch}
        reserveItem={this.reserveItem}
        user={this.props.user}
      />
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { user } = auth;
  return { user };
};

export default connect(mapStateToProps, { setPassenger })(OfficeSearch);
