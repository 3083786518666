import React from 'react';
import moment from 'moment';
import map from 'lodash/map';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useIntl } from 'react-intl';

const DateSearch = React.forwardRef((props, ref) => {
  const { messages } = useIntl();

  // States
  const {
    selectedDate,
    priceRange,
    setValue,
    defaultMinDate,
    defaultMaxDate,
    defaultStartDate,
    defaultEndDate,
    isStart,
    isEnd,
    placeholder = '',
    className,
    ...others
  } = props;

  const highlightWithRanges = [];
  if (priceRange) {
    const lowDates = priceRange.slice(Math.max(priceRange.length - 8, 1));
    const highDates = priceRange.slice(0, 8);

    highlightWithRanges.push({
      'react-datepicker__day--low': map(lowDates, (lowDate) => {
        return moment(lowDate.PartialDate).toDate();
      }),
    });

    highlightWithRanges.push({
      'react-datepicker__day--high': map(highDates, (highDate) => {
        return moment(highDate.PartialDate).toDate();
      }),
    });
  }

  // Render
  return (
    <DatePicker
      className={className}
      selected={selectedDate ? moment(selectedDate).toDate() : ''}
      onChange={setValue}
      highlightDates={highlightWithRanges}
      minDate={defaultMinDate ? defaultMinDate.toDate() : moment().toDate()}
      maxDate={defaultMaxDate ? defaultMaxDate.toDate() : null}
      dateFormat={props.showTime ? `dd/MM/yyyy HH:mm` : `dd/MM/yyyy`}
      placeholderText={placeholder}
      selectsStart={isStart}
      selectsEnd={isEnd}
      startDate={defaultStartDate ? defaultStartDate.toDate() : null}
      endDate={defaultEndDate ? defaultEndDate.toDate() : null}
      showTimeSelect={props.showTime}
      timeFormat="HH:mm"
      timeIntervals={30}
      timeCaption={messages['general.time']}
      ref={ref}
      {...others}
    />
  );
});

export default DateSearch;
