import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';

import LoaderESG from '../loaders/LoaderESG';

import firebaseApp from '../../services/Firebase';

export default function CarCarbonDetail({ esg, sipp, days }) {
  const [carbon, setCarbon] = useState({});
  const [loading, setLoading] = useState(true);

  const { messages, formatMessage } = useIntl();

  useEffect(() => {
    (async () => {
      await loadCarbonData();
    })();
  }, []);

  // Carbon Data
  const loadCarbonData = async () => {
    if (esg) {
      setCarbon(esg);
    }

    if (sipp && days) {
      const carbon = await firebaseApp.getCarCarbonEmission(sipp, days);

      setCarbon(carbon);
    }

    setLoading(false);
  };

  if (loading) return <LoaderESG />;

  // Render
  if (carbon.carbon_kg) {
    return (
      <div className="esg-label">
        <i className="esg-icon" />
        <p>
          {formatMessage(
            { id: 'esg.car.estimate' },
            {
              carbon: parseInt(carbon.carbon_kg),
            }
          )}
        </p>
      </div>
    );
  }

  return <div />;
}
