import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import loadSearchStatus from '../constants/loadSearchStatus';

function scrollToTop() {
  window.scrollTo({ top: 0, behavior: 'smooth' });
}

interface IProps<I> {
  items: I[];
  numberOfItems: number;
  numberOfFetchedItems: number;
  initialPage?: number;
  itemsPerPage?: number;
  shouldScrollToTopOnPageChange?: boolean;
  getRemainingSearch?: () => void;
  status?: string;
  isLoading?: boolean;
}

export default function usePagination<I>({
  initialPage = 1,
  itemsPerPage = 10,
  items = [],
  shouldScrollToTopOnPageChange = true,
  getRemainingSearch,
  status,
  isLoading,
  numberOfItems,
  numberOfFetchedItems,
}: IProps<I>) {
  const hasInitialize = useRef(false);
  const [currentPage, setCurrentPage] = useState(() => initialPage);

  const itemsLength = useMemo(
    () =>
      status === loadSearchStatus.ALL || numberOfFetchedItems === numberOfItems
        ? items.length
        : numberOfItems || items.length,
    [numberOfItems, numberOfFetchedItems, items]
  );

  const numPages = useMemo(
    () => Math.ceil(itemsLength / itemsPerPage),
    [itemsLength, itemsPerPage]
  );

  const paginatedItems = useMemo(() => {
    const currentSlice = itemsPerPage * (currentPage - 1);
    return items.slice(currentSlice, currentSlice + itemsPerPage);
  }, [items, currentPage, itemsPerPage]);

  const shouldShowPagination = useMemo(
    () => itemsLength > itemsPerPage,
    [itemsLength, itemsPerPage]
  );

  // Effects
  useEffect(() => {
    if (!hasInitialize.current) hasInitialize.current = true;
    else if (shouldScrollToTopOnPageChange) scrollToTop();
  }, [currentPage, shouldScrollToTopOnPageChange]);

  useEffect(() => {
    if (
      getRemainingSearch &&
      !isLoading &&
      status === loadSearchStatus.NEW &&
      paginatedItems.length < itemsPerPage
    ) {
      getRemainingSearch();
    }
  }, [isLoading, paginatedItems.length, itemsPerPage, status]);

  // Functions
  const goToNextPage = useCallback(() => {
    const nextPage = currentPage + 1;

    setCurrentPage(Math.min(nextPage, numPages));
  }, [currentPage, numPages]);

  const goToPreviousPage = useCallback(() => {
    const previousPage = currentPage - 1;

    if (previousPage < 1) return;

    setCurrentPage(previousPage);
  }, [currentPage]);

  const goToPage = useCallback(
    (page: number) => {
      if (page === currentPage) return;

      setCurrentPage(Math.max(Math.min(numPages, page), 1));
    },
    [currentPage, numPages]
  );

  return {
    paginatedItems,
    currentPage,
    goToPage,
    goToNextPage,
    goToPreviousPage,
    shouldShowPagination,
    numberOfPages: numPages,
  };
}
