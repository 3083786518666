import React from 'react';
import Sidebar from '../containers/navs/Sidebar';
import TopNav from '../containers/navs/Topnav';
import DndWrapper from '../wrappers/DndWrapper';

const menu = [
  // {
  //   id: 'inbox',
  //   icon: 'iconsminds-envelope',
  //   label: 'refund-menu.inbox',
  //   to: '/payments/inbox',
  // },
  {
    id: 'cards',
    icon: 'iconsminds-box-with-folders',
    label: 'refund-menu.bills',
    to: '/payments/cards',
  },
  {
    id: 'recurrence',
    icon: 'iconsminds-repeat',
    label: 'refund-menu.recurrence',
    to: '/payments/recurrence',
  },
];

export default function ExpensesLayout(props) {
  return (
    <DndWrapper>
      <div id="app-container" className="expenses-container">
        <Sidebar items={menu} />

        <main style={{ overflowX: 'hidden' }} className="expenses-main">
          <TopNav history={props.history} />

          <div className="container-fluid">{props.children}</div>
        </main>
      </div>
    </DndWrapper>
  );
}
