import React from 'react';
import classNames from 'classnames';
import { Button } from 'reactstrap';

export default function OptionCard({
  title,
  description,
  itemDescription,
  BottomComponent,
  buttonText,
  onClick = () => {},
  className = '',
  Icon,
  disabled = false,
  titleMessage = '',
  ...props
}) {
  // Functions
  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (disabled) return;

    onClick();
  };

  // Render
  return (
    <div
      className={classNames(
        'option-card px-3 py-4 pointer d-flex flex-column',
        className
      )}
      {...props}
      onClick={handleClick}
      title={titleMessage}
    >
      <div className="flex-grow-1">
        {Icon ? (
          <Icon className="icon text-primary" width="100%" height="100%" />
        ) : null}
        <div className="pt-3 pb-4">
          <h3 className="title font-primary m-0 p-0 mb-1 font-weight-medium">
            {title}
          </h3>
          <p className="description m-0 p-0 text-muted lh-1-25">
            {description}
          </p>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-between flex-shrink-0">
        <span className="item-description font-weight-normal">
          {itemDescription}
        </span>
        {buttonText ? (
          <Button
            onClick={handleClick}
            color="primary"
            size="sm"
            className="m-0 px-3 with-transition"
            disabled={disabled}
          >
            {buttonText}
          </Button>
        ) : null}
      </div>
    </div>
  );
}
