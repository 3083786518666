import React, { useMemo } from 'react';
import { Input, Row } from 'reactstrap';
import { Colxx } from '../common/CustomBootstrap';
import CurrencyInput from 'react-currency-input-field';
import {
  DEFAULT_CURRENCY,
  getCurrencySymbol,
  stringToMoney,
} from '../../utils/money';
import Counter from '../common/Counter';
import { useIntl } from 'react-intl';

import { ReactComponent as TrashIcon } from '../../assets/img/icons/close.svg';
import { ReactComponent as ExclamationIcon } from '../../assets/img/icons/exclamation.svg';

const READONLY_SIZE = {
  name: 4,
  unity: 3,
  quantity: 2,
  total: 3,
};

const SIZE = {
  name: 4,
  unity: 3,
  quantity: 2,
  total: 3,
};

export function ExpenseItem({
  item,
  setItem,
  currencySymbol,
  currency,
  onDelete,
  hasRejectedItems,
  onItemBlur = () => {},
}) {
  const { messages } = useIntl();

  const total = useMemo(
    () => stringToMoney(item.price * (item.quantity || 1), 2, currency),
    [item]
  );

  const handleChange = (name, value) => {
    setItem({
      ...item,
      [name]: value,
    });
  };

  return (
    <Row className="py-2 expense-item">
      <Colxx xxs="3" className="d-flex align-items-center mr-0 pr-0">
        {item.rejected ? (
          <ExclamationIcon
            width={5}
            height={20}
            className="exclamation-icon mr-2"
            title={messages['refund.modal.item-reject']}
          />
        ) : null}
        <Input
          type="text"
          placeholder={messages['refund.placeholder.refund-item-name']}
          value={item.name}
          onChange={({ target }) => handleChange('name', target.value)}
          className="p-0 m-0"
          onBlur={onItemBlur}
        />
      </Colxx>
      <Colxx xxs="3">
        <CurrencyInput
          name="price"
          defaultValue={item.price}
          placeholder="0"
          prefix={currencySymbol}
          allowNegativeValue={false}
          decimalSeparator=","
          groupSeparator="."
          onValueChange={(value, name) => {
            const parsedValue =
              (value ? parseFloat(value.replace(',', '.')) : 0) || 0;
            handleChange(name, parsedValue);
          }}
          className="w-100 text-center p-0 m-0"
        />
      </Colxx>
      <Colxx xxs="2" className="text-center">
        <Counter
          value={item.quantity}
          setValue={(value) => handleChange('quantity', value)}
        />
      </Colxx>
      <Colxx xxs="4" className="text-right">
        <div className="d-flex align-items-center justify-content-end">
          {total}
          <TrashIcon
            width={12}
            height={12}
            onClick={onDelete}
            className="ml-3 trash-icon"
          />
        </div>
      </Colxx>
    </Row>
  );
}

export function ReadOnlyExpenseItem({
  item,
  currency,
  rejected,
  size = {
    name: 3,
    unity: 3,
    quantity: 2,
    total: 4,
  },
}) {
  const { messages } = useIntl();
  const { name, price, quantity, total } = useMemo(() => {
    return {
      name: item.name,
      price: stringToMoney(item.price, 2, currency),
      quantity: item.quantity,
      total: stringToMoney(item.quantity * item.price, 2, currency),
    };
  }, [item, currency]);

  return (
    <Row
      className={`expense-item py-2 ${rejected ? 'rejected' : ''}`}
      title={rejected ? messages['refund.modal.item-reject'] : ''}
    >
      <Colxx xxs={size.name}>{name}</Colxx>
      <Colxx xxs={size.unity} className="text-center">
        {price}
      </Colxx>
      <Colxx xxs={size.quantity} className="text-center">
        {quantity}
      </Colxx>
      <Colxx xxs={size.total} className="text-right">
        {total}
      </Colxx>
    </Row>
  );
}

export function ExpenseItems({
  items = [],
  onItemChange,
  onItemDelete,
  onItemBlur,
  currency = DEFAULT_CURRENCY,
  hasRejectedItems = false,
  editable,
}) {
  const { messages } = useIntl();

  const currencySymbol = useMemo(
    () => `${getCurrencySymbol(currency)} `,
    [currency]
  );

  const ItemComponent = useMemo(
    () => (editable ? ExpenseItem : ReadOnlyExpenseItem),
    [editable]
  );

  return (
    <div className="expense-items px-2">
      <Row className="font-weight-semibold mb-2 header">
        <Colxx xxs="3" className={hasRejectedItems ? 'pl-4 ml-1' : ''}>
          {messages['refund.modal.items.name']}
        </Colxx>
        <Colxx xxs="3" className="text-center m-0 p-0">
          {messages['refund.modal.items.unity-price']}
        </Colxx>
        <Colxx xxs="2" className="text-center m-0 p-0">
          {messages['refund.modal.items.quantity']}
        </Colxx>
        <Colxx xxs="4" className="text-right total-header">
          {messages['refund.modal.items.total']}
        </Colxx>
      </Row>
      <div>
        {items.map((item, index) => (
          <ItemComponent
            key={item.id || item.name}
            currencySymbol={currencySymbol}
            currency={currency}
            item={item}
            setItem={(item) => onItemChange(index, item)}
            onDelete={() => onItemDelete(index)}
            hasRejectedItems={hasRejectedItems}
            onItemBlur={() => onItemBlur(index)}
          />
        ))}
      </div>
    </div>
  );
}
