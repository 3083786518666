import React, { useMemo, useRef } from 'react';
import CircularOptionButton from '../../components/common/CircularOptionButton';
import BudgetSortPopup from './BudgetSortPopup';

// Icons
import { ReactComponent as AddIcon } from '../../assets/img/icons/small_add.svg';
import { ReactComponent as SortIcon } from '../../assets/img/icons/sort.svg';
import { ReactComponent as FilterIcon } from '../../assets/img/icons/filter_app.svg';
import BudgetStatusFilterPopup from './BudgetStatusFilterPopup';
import { BudgetReportCheckbox } from './Report/BudgetReportCheckbox';

export default function BudgetOptions({
  canCreateBudget,
  onCreateBudget,
  sort,
  setSort,
  isEmpty,
  statusFilters,
  setStatusFilters,
  statusFilterOptions,
  numBudgets,
  shouldInitialize,
  card,
  hasBudgets,
}) {
  // Refs
  const sortRef = useRef(null);

  // States
  const shouldHide = useMemo(
    () => !(canCreateBudget || numBudgets > 1),
    [canCreateBudget, numBudgets]
  );

  const isFilterPressed = useMemo(
    () => !!statusFilters.length,
    [statusFilters]
  );

  // Render
  if (shouldHide) return null;

  return (
    <div className="budget-options flex-center px-3 flex-column">
      {hasBudgets && <BudgetReportCheckbox card={card} />}
      {isEmpty || numBudgets < 1 || !shouldInitialize ? null : (
        <>
          <BudgetSortPopup sort={sort} setSort={setSort} target={sortRef}>
            <CircularOptionButton className="no-stroke my-2" Icon={SortIcon} />
          </BudgetSortPopup>

          <BudgetStatusFilterPopup
            statusFilters={statusFilters}
            setStatusFilters={setStatusFilters}
            statusFilterOptions={statusFilterOptions}
          >
            <CircularOptionButton
              className="no-stroke my-2"
              Icon={FilterIcon}
              pressed={isFilterPressed}
            />
          </BudgetStatusFilterPopup>
        </>
      )}

      {canCreateBudget ? (
        <CircularOptionButton
          className="my-2 no-stroke"
          Icon={AddIcon}
          onClick={onCreateBudget}
          name="budget-creation"
        />
      ) : null}
    </div>
  );
}
