import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Nav, NavItem } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { withRouter } from 'react-router-dom';
import classnames from 'classnames';
import map from 'lodash/map';

import IntlMessages from '../../helpers/IntlMessages';

import menuItems from '../../constants/menu';

class Sidebar extends Component {
  static defaultProps = {
    items: menuItems,
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedParentMenu: '',
      viewingParentMenu: '',
    };
  }

  handleDocumentClick = (e) => {
    const container = this.getContainer();
    let isMenuClick = false;
    if (
      e.target &&
      e.target.classList &&
      (e.target.classList.contains('menu-button') ||
        e.target.classList.contains('menu-button-mobile'))
    ) {
      isMenuClick = true;
    } else if (
      e.target.parentElement &&
      e.target.parentElement.classList &&
      (e.target.parentElement.classList.contains('menu-button') ||
        e.target.parentElement.classList.contains('menu-button-mobile'))
    ) {
      isMenuClick = true;
    } else if (
      e.target.parentElement &&
      e.target.parentElement.parentElement &&
      e.target.parentElement.parentElement.classList &&
      (e.target.parentElement.parentElement.classList.contains('menu-button') ||
        e.target.parentElement.parentElement.classList.contains(
          'menu-button-mobile'
        ))
    ) {
      isMenuClick = true;
    }
    if (container.contains(e.target) || container === e.target || isMenuClick) {
      return;
    }
    this.setState({
      viewingParentMenu: '',
    });
  };

  getMenuClassesForResize = (classes) => {
    let nextClasses = classes.split(' ').filter((x) => x !== '');

    nextClasses = nextClasses.filter((x) => x !== 'menu-mobile');
    if (
      nextClasses.includes('menu-default') &&
      nextClasses.includes('menu-sub-hidden')
    ) {
      nextClasses = nextClasses.filter((x) => x !== 'menu-sub-hidden');
    }

    return nextClasses;
  };

  getContainer = () => {
    return ReactDOM.findDOMNode(this);
  };

  handleProps = () => {
    this.addEvents();
  };

  addEvents = () => {
    document.addEventListener('click', this.handleDocumentClick, true);
  };

  removeEvents = () => {
    document.removeEventListener('click', this.handleDocumentClick, true);
  };

  setSelectedLiActive = () => {
    const oldli = document.querySelector('.sub-menu  li.active');
    if (oldli != null) {
      oldli.classList.remove('active');
    }

    /* set selected parent menu */
    const selectedlink = document.querySelector('.sub-menu  a.active');
    if (selectedlink != null) {
      selectedlink.parentElement.classList.add('active');
      this.setState({
        selectedParentMenu:
          selectedlink.parentElement.parentElement.getAttribute('data-parent'),
      });
    } else {
      var selectedParentNoSubItem = document.querySelector(
        '.main-menu  li a.active'
      );
      if (selectedParentNoSubItem != null) {
        this.setState({
          selectedParentMenu: selectedParentNoSubItem.getAttribute('data-flag'),
        });
      } else if (this.state.selectedParentMenu === '') {
        this.setState({
          selectedParentMenu: this.props.items[0].id,
        });
      }
    }
  };

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setSelectedLiActive();

      window.scrollTo(0, 0);
    }
    this.handleProps();
  }

  componentDidMount() {
    this.handleProps();
    this.setSelectedLiActive();
  }

  componentWillUnmount() {
    this.removeEvents();
  }

  openMenu = (e, menuItem) => {
    const selectedParent = menuItem.id;

    this.setState({
      viewingParentMenu: selectedParent,
      selectedParentMenu: selectedParent,
    });
  };

  render() {
    const { selectedParentMenu, viewingParentMenu } = this.state;
    const { items = menuItems } = this.props;

    return (
      <div className="sidebar">
        <div className="main-menu">
          <div className="scroll">
            <PerfectScrollbar
              options={{ suppressScrollX: true, wheelPropagation: false }}
            >
              <Nav vertical className="list-unstyled">
                {items &&
                  map(items, (item) => {
                    return (
                      <NavItem
                        key={item.id}
                        className={classnames({
                          active:
                            (selectedParentMenu === item.id &&
                              viewingParentMenu === '') ||
                            viewingParentMenu === item.id,
                          disabled: item.disabled,
                        })}
                      >
                        {item.disabled ? (
                          <a
                            data-testid={`menu-item-${item.id}`}
                            href="javascript;"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                            }}
                          >
                            <i className={item.icon} />{' '}
                            <IntlMessages id={item.label} />
                          </a>
                        ) : (
                          <NavLink
                            data-testid={`menu-item-${item.id}`}
                            to={item.to}
                            onClick={(e) => this.openMenu(e, item)}
                            data-flag={item.id}
                          >
                            <i className={item.icon} />{' '}
                            <IntlMessages id={item.label} />
                          </NavLink>
                        )}
                      </NavItem>
                    );
                  })}
              </Nav>
            </PerfectScrollbar>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Sidebar);
