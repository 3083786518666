const data = [
  {
    id: 'data.budget',
    icon: 'iconsminds-dollar',
    label: 'data-menu.expenses',
    to: '/data/payments',
  },
  {
    id: 'data.travel',
    icon: 'iconsminds-suitcase',
    label: 'data-menu.travels',
    to: '/data/travels',
  },
  {
    id: 'data.esg',
    icon: 'iconsminds-environmental-3',
    label: 'data-menu.esg',
    to: '/data/esg',
  },
  {
    id: 'data.statement',
    icon: 'iconsminds-testimonal',
    label: 'data-menu.finance',
    to: '/data/finance',
  },
];

export default data;
