import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Row } from 'reactstrap';
import { Colxx } from '../../components/common/CustomBootstrap';
import DateSelection from '../../components/reports/DateSelection';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import NoResults from '../../components/search/NoResults';
import { formatBudget } from '../../components/reports/filters/TypeSchemas';
import PrintDropdown from '../../components/common/PrintDropdown';
import {
  EXPENSE_FILE_TYPES,
  EXPENSE_PRINT_TYPES,
  generateBudgetReportFile,
} from '../../utils/reports';
import { downloadBlob } from '../../utils/fileGeneration';
import useLazyDataLoader from '../../hooks/useLazyDataLoader';
import firebaseApp from '../../services/Firebase';
import LoaderCardColumns from '../../components/loaders/LoaderCardColumns';
import { getApprovalsFromOrderId } from '../../utils/approvals';
import BudgetOrderModal from '../timeline/BudgetOrderModal';
import InputFilter from '../../components/reports/filters/InputFilter';
import { FILTER_TYPES } from '../../constants/filters';
import { generateFilterFunctions } from '../../utils/filters';
import NormalListView from './NormalListView';
import { getDocument } from '../../utils/firebase';
import { RefundTypes } from '../../constants/refundTypes';
import useHistoryWithScroll from '../../hooks/useHistoryWithScroll';
import BudgetDataInfo from './BudgetDataInfo';
import { RefundStatus } from 'src/portao3-legacy/constants/refundStatus';
import useDataArchive from 'src/portao3-legacy/hooks/useDataArchive';

const formatBudgets = async (orders) => {
  return Promise.all(
    orders.map(
      (order) =>
        new Promise(async (resolve, _) => {
          const approvals = await getApprovalsFromOrderId(
            order.id,
            firebaseApp.getApprovalsFromBudgetId
          );
          order['statusCode'] =
            order.statusCode === RefundStatus.DISMISSED &&
            order.previousStatusCode
              ? order.previousStatusCode
              : order.statusCode;
          order['approvers'] = approvals.map((approval) => approval.name);
          order['pending'] = [
            RefundStatus.WAITING,
            RefundStatus.REVIEW,
          ].includes(order.statusCode);

          resolve({
            ...order,
            filter: formatBudget(order),
          });
        })
    )
  );
};

export default function ExpensesDataListView({
  itemsPerPage = 15,
  path = '/app/reports/payments',
  getData,
}) {
  // Hooks
  const { organization, user } = useSelector(({ auth }) => auth);
  const { orderId, itemId } = useParams();
  const { messages } = useIntl();
  const { push } = useHistoryWithScroll();

  const { getDataFromArchive } = useDataArchive();

  // States
  const [dateSelection, setDateSelection] = useState({
    mode: 'month',
    startDate: moment().startOf('month').unix(),
    endDate: moment().endOf('month').unix(),
  });

  const [rawBudget, isLoading] = useLazyDataLoader({
    fetchFunction: () =>
      firebaseApp.getExpenseBudgetsFromOrganization(user.organizationId),
    interval: dateSelection,
    idName: 'id',
    formatFunction: formatBudgets,
  });

  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState([]);
  const [cards, setCards] = useState({});
  const [loadingCards, setLoadingCards] = useState(true);

  const budgets = useMemo(() => {
    return rawBudget.map((budget) => {
      const card = cards[budget.cardId] || {};

      return {
        ...budget,
        cardType: card?.type || RefundTypes.PHYSICAL_CARD,
        cardName: card?.name || '',
      };
    });
  }, [rawBudget, cards]);

  const loading = useMemo(
    () => isLoading || loadingCards,
    [loadingCards, isLoading]
  );

  const filterFunctions = useMemo(
    () => (filters.length > 0 ? generateFilterFunctions(filters) : []),
    [filters]
  );

  const DETAIL_TYPES = useMemo(
    () => [
      {
        value: EXPENSE_PRINT_TYPES.SIMPLE,
        label: messages['reports.details.simple'],
      },
      {
        value: EXPENSE_PRINT_TYPES.BY_CARDS,
        label: messages['reports.details.by-cards'],
      },
      {
        value: EXPENSE_PRINT_TYPES.BY_ITEMS,
        label: messages['reports.details.by-items'],
      },
    ],
    []
  );

  const config = useMemo(
    () => [
      {
        type: FILTER_TYPES.ARRAY,
        field: 'cardType',
        paramLabel: messages['reports.filter.card-type'],
        formatResult: (values) =>
          values.map((value) => ({
            value,
            label: messages[`refund.card-type.${value.toLowerCase()}`],
          })),
        filterFunctionConfig: {
          valueKey: 'value',
        },
      },
      {
        type: FILTER_TYPES.ARRAY,
        field: 'cardName',
        paramLabel: messages['reports.filter.card-name'],
        formatResult: (values) =>
          values.map((value) => ({
            value,
            label: value,
          })),
        filterFunctionConfig: {
          valueKey: 'value',
        },
      },
      {
        type: FILTER_TYPES.RANGE,
        field: 'fare',
        paramLabel: messages['reports.filter.total'],
        getField: ({ total = 0 }) => total,
      },
      {
        type: FILTER_TYPES.RANGE,
        field: 'budget',
        paramLabel: messages['reports.filter.budget'],
      },
      {
        type: FILTER_TYPES.OBJECT,
        field: 'project',
        key: 'label',
        paramLabel: messages['reports.filter.project'],
        formatResult: (value) => Object.values(value),
        filterFunctionConfig: {
          getField: (obj) => obj.label,
        },
      },
      {
        type: FILTER_TYPES.OBJECT,
        field: 'costCenter',
        key: 'label',
        paramLabel: messages['reports.filter.costCenter'],
        formatResult: (value) => Object.values(value),
        filterFunctionConfig: {
          getField: (obj) => obj.label,
        },
      },
      {
        type: FILTER_TYPES.ARRAY,
        paramName: 'passengerName',
        field: 'filter',
        key: 'label',
        getField: ({ passengerName = '' }) => passengerName,
        paramLabel: messages['reports.filter.user-name'],
        formatResult: (values) =>
          values.map((value) => ({
            value,
            label: value,
          })),
      },
      {
        type: FILTER_TYPES.ARRAY,
        field: 'statusCode',
        formatResult: (values) =>
          values
            .filter((value) => value !== 200)
            .map((value) => ({
              value,
              label: messages[`refund.status-code.${value}`],
            })),
        paramLabel: messages['reports.filter.budget-status'],
        filterFunctionConfig: {
          valueKey: 'value',
        },
      },
      {
        type: FILTER_TYPES.BOOLEAN,
        field: 'pending',
        paramLabel: messages['reports.filter.pending'],
      },
    ],
    []
  );

  const filteredOrders = useMemo(() => {
    if (filterFunctions.length > 0) {
      return budgets.filter((budget) =>
        filterFunctions.every((f) => f(budget))
      );
    } else return budgets;
  }, [budgets, filterFunctions]);

  const shouldDisableBtns = useMemo(
    () => loading || !filteredOrders || filteredOrders.length === 0,
    [loading, filteredOrders]
  );

  // Effects
  useEffect(() => {
    if (showFilters) setShowFilters(false);
  }, [dateSelection]);

  useEffect(() => {
    const fetchCards = async () => {
      try {
        setLoadingCards(true);

        const cardIds = Object.keys(
          rawBudget.reduce((obj, budget) => {
            obj[budget.cardId] = true;
            return obj;
          }, {})
        );

        const cards = await Promise.all(
          cardIds.map(
            (cardId) =>
              new Promise((res) =>
                getData(`expense-card/${cardId}`, () =>
                  getDocument(() =>
                    firebaseApp
                      .getExpenseCardsByIdFromOrganization(
                        user.organizationId,
                        cardId
                      )
                      .get()
                  )
                )
                  .then((value) => res(value))
                  .catch(() => res(null))
              )
          )
        );

        setCards(
          cards.reduce((obj, card) => {
            if (card) {
              obj[card.id] = card;
            }
            return obj;
          }, {})
        );

        setLoadingCards(false);
      } catch (err) {
        console.error(err);
      }
    };

    fetchCards();
  }, [rawBudget]);

  // Date Filters
  const setCustomDate = (start, end) => {
    setDateSelection({
      startDate: moment(start).startOf('day').unix(),
      endDate: moment(end).endOf('day').unix(),
    });
  };

  // Functions
  const handleFileGeneration = async (type, detailed) => {
    try {
      if (filteredOrders.length) {
        const blob = await generateBudgetReportFile({
          budgets: filteredOrders,
          cards,
          type: type.value,
          details: detailed.value,
          organization,
          messages,
          dateSelection,
          getDataFromArchive,
        });

        downloadBlob(blob, `${messages['reports.name']}.${type.value}`);
      }
    } catch (err) {
      console.error('Unable to generate report file', err);
    }
  };

  // Modal
  const openModal = async (budgetId) => {
    push(`${path}/${budgetId}`);
  };

  // Render
  return (
    <div className="summary-data-list mt-3">
      <div className="summary mb-5 pb-3">
        <Row className="top-container mx-0 mb-5">
          <Colxx xxs="12" className="d-flex m-0 p-0 align-items-center">
            <div className="filter-container d-flex flex-grow-1 p-3 bg-white">
              <div className="flex-grow-1">
                {loading ? null : (
                  <InputFilter
                    config={config}
                    filters={filters}
                    setFilters={setFilters}
                    orders={budgets}
                  />
                )}
              </div>
              <div className="ml-2">
                <DateSelection
                  dateSelection={dateSelection}
                  setDateSelection={setCustomDate}
                />
              </div>
            </div>
            <div className="ml-2 d-flex justify-content-center flex-shrink-0">
              <PrintDropdown
                arrow={true}
                typeOptions={EXPENSE_FILE_TYPES}
                detailOptions={DETAIL_TYPES}
                onPrint={handleFileGeneration}
                disabled={shouldDisableBtns}
              />
            </div>
          </Colxx>
        </Row>
        {loading ? (
          <div className="orders mt-3">
            <LoaderCardColumns />
            <LoaderCardColumns />
            <LoaderCardColumns />
            <LoaderCardColumns />
            <LoaderCardColumns />
          </div>
        ) : filteredOrders && filteredOrders.length ? (
          <div className="orders mt-3">
            <NormalListView
              budgets={filteredOrders}
              cards={cards}
              itemsPerPage={itemsPerPage}
              openModal={openModal}
              dateSelection={dateSelection}
              filters={filters}
              getData={getData}
            />
            <BudgetDataInfo
              className="mt-5"
              budgets={filteredOrders}
              getData={getData}
            />
          </div>
        ) : (
          <div className="d-flex justify-content-center align-items-center">
            <NoResults />
          </div>
        )}
      </div>

      {orderId && (
        <BudgetOrderModal
          budgetId={orderId}
          itemId={itemId}
          closeModalPath={path}
          path={path}
        />
      )}
    </div>
  );
}
