import React from 'react';

export default function FocusMask(props) {
  const { show } = props;

  return (
    <svg
      width="100vw"
      height="100vh"
      className={`focus-mask ${show ? 'show' : 'hide'}`}
    >
      <mask id="input-mask">
        <filter id="mask-filter">
          <feGaussianBlur stdDeviation="8" result="blur" />
        </filter>
        <rect width="100vw" height="100vh" fill="white" />
        <rect
          y="75px"
          x="400px"
          width="100vw"
          height="200px"
          fill="black"
          filter="url(#mask-filter)"
          className="black-mask"
        />
      </mask>

      <rect
        width="100vw"
        height="100vh"
        mask="url(#input-mask)"
        fill="rgba(0,0,0,0.25)"
      />
    </svg>
  );
}
