import React from 'react';

export default function InformationCard({
  Icon,
  title,
  value,
  search = false,
}) {
  return !search ? (
    <div className="information-card light-round d-flex mr-3 bg-white oveflow-hidden">
      <div className="icon text-primary information-card-icon h-100 flex-center bg-secondary-light">
        <Icon className="text-primary" />
      </div>
      <div className="text-primary d-flex flex-column py-3 px-3 justify-content-start">
        <h3
          className="title fs-0-6rem m-0 p-0 font-primary"
          style={{ opacity: '60%' }}
        >
          {title}
        </h3>
        <p className="value fs-1-1rem font-weight-medium lh-1 m-0 p-0 mt-1 align-self-start">
          {value}
        </p>
      </div>
    </div>
  ) : (
    <div className="information-card light-round d-flex mr-3 bg-white oveflow-hidden border">
      <div className="icon text-primary information-card-icon h-100 flex-center bg-secondary-light">
        <Icon className="text-primary" />
      </div>
      <div className="text-primary d-flex flex-column py-3 px-3 justify-content-start">
        <h3
          className="title fs-0-6rem m-0 p-0 font-primary"
          style={{ opacity: '60%' }}
        >
          {title}
        </h3>
        <p className="value fs-1-1rem font-weight-medium lh-1 m-0 p-0 mt-1 align-self-start">
          {value}
        </p>
      </div>
    </div>
  );
}
