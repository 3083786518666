import { numberToCnpj, numberToCpf } from './cpf';

export const getReceiptState = (url) => {
  const matched = url.match(/fazenda\.(.*)\.gov/);
  return matched ? matched[1].toUpperCase() : null;
};

export const analyseBarcodes = (barcodes) => {
  if (barcodes.length > 1) {
    const unique = [];

    barcodes.forEach((barcode) => {
      if (unique.includes(barcode)) unique.push(barcode);
    });

    return barcodes;
  } else return [barcodes[0]];
};

export const formatKey = (key) => {
  const length = key ? key.length : 0;
  if (length === 44) {
    return key.replace(
      /^(\d{4})(\d{4})(\d{4})(\d{4})(\d{4})(\d{4})(\d{4})(\d{4})(\d{4})(\d{4})(\d{4})$/,
      '$1 $2 $3 $4 $5 $6 $7 $8 $9 $10 $11'
    );
  } else return key;
};

export const formatSuppliersAddress = (address) => {
  if (address) {
    const {
      tipoLogradouro,
      logradouro,
      numero,
      complemento,
      bairro,
      municipio = {},
      uf,
      cep,
    } = address;

    const format = (arr) => arr.filter((v) => v).join(', ');

    const firstPart = format([
      `${tipoLogradouro ? `${tipoLogradouro} ` : ''}${logradouro}`,
      numero,
      complemento,
    ]);
    const secondPart = format([bairro, municipio.descricao]);
    const thirdPart = format([
      uf,
      cep ? cep.replace(/(\d{5})(\d{3})/, '$1-$2') : '',
    ]);

    return [firstPart, secondPart, thirdPart].join(' - ');
  } else return '-';
};

export const formatConsumerDocument = (document = '') => {
  const length = document.length;

  if (length === 11) {
    return numberToCpf(document);
  } else if (length === 14) {
    return numberToCnpj(document);
  } else return document;
};

export function getReceiptUrlFromReceipts({
  budgetId,
  budgetItemId,
  receipts = {},
}) {
  const budgetReceipts = receipts[budgetId];

  if (budgetReceipts) {
    return (budgetReceipts[budgetItemId] || [])[0];
  }

  return '';
}
