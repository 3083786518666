import moment from 'moment';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { RecurrenceBudgetTypes } from '../../constants/recurrenceBudgets';
import { HasBudget, RefundTypes } from '../../constants/refundTypes';
import { canCreateRefund } from '../../utils/expenses';
import { calculateActiveDays } from '../../utils/recurrenceBudget';
import { getOrganizationConfig } from '../../utils/organization';
import {
  checkRequiredFields,
  getRequiredFields,
} from '../../utils/reservation';

export default function BudgetCreationStatus({
  budget = {},
  onCreate = () => {},
  onRecurrenceClick = () => {},
  isSaving,
  cardType,
  recurrence,
}) {
  const { messages, formatMessage } = useIntl();
  const { organization } = useSelector(({ auth }) => auth);

  // States
  const requiredFields = useMemo(
    () =>
      getRequiredFields(getOrganizationConfig(organization.config, 'required')),
    [organization]
  );

  const isValid = useMemo(
    () =>
      budget.name &&
      budget.startDate &&
      budget.endDate &&
      (HasBudget.includes(cardType)
        ? budget.budget && budget.category?.value
        : true) &&
      (cardType === RefundTypes.PERSONAL_ACCOUNT ||
        budget.endDate.format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD')) &&
      checkRequiredFields(budget, requiredFields) &&
      (!recurrence?.type || recurrence.days),
    [budget, cardType, recurrence]
  );

  const allowRefundCreation = useMemo(
    () => canCreateRefund(organization),
    [organization]
  );

  const title = useMemo(
    () =>
      allowRefundCreation
        ? isValid
          ? ''
          : messages['refund.modal.invalid']
        : messages['refund.modal.cannot-create'],
    [allowRefundCreation, isValid]
  );

  const recurrencyMessage = useMemo(() => {
    const { type, days } = recurrence || {};

    if (!type) return null;

    const { startDate, endDate } = budget;

    if (!(startDate && endDate))
      return messages['budget-recurrence.creation.select-period'];

    const recurrencePeriod = calculateActiveDays(startDate, endDate) + 1;

    return formatMessage(
      {
        id: `budget-recurrence.creation.${
          type === RecurrenceBudgetTypes.interval ? 'interval' : 'date'
        }`,
      },
      {
        period: recurrencePeriod,
        days,
      }
    );
  }, [recurrence, budget]);

  // Render
  const renderBudgetWithoutRecurrence = () => (
    <div className="text order-status">
      <div className="mt-3">
        <Button
          name="create-budget"
          outline
          color="primary"
          className="m-0 mb-3"
          onClick={() => allowRefundCreation && onCreate()}
          disabled={!(isValid && allowRefundCreation) || isSaving}
          title={title}
        >
          {messages['refund.modal.create-refund']}
        </Button>
      </div>
    </div>
  );

  const renderBudgetWithRecurrence = () => {
    const disabled = !(isValid && allowRefundCreation) || isSaving;

    return (
      <div className="text order-status">
        {recurrencyMessage ? (
          <>
            <p className="color-alert">{recurrencyMessage}</p>
            <hr className="my-4" />
          </>
        ) : null}

        <div className="mt-3">
          <Button
            name="create-budget"
            outline
            color="primary"
            className="m-0 mb-3 break-title"
            onClick={() => allowRefundCreation && onCreate()}
            disabled={disabled}
            title={title}
          >
            {messages['refund.modal.create-recurrency-and-budget']}
          </Button>

          <Button
            name="create-only-recurrence"
            outline
            color="primary"
            className="m-0 mb-3 break-title"
            onClick={() => allowRefundCreation && onRecurrenceClick()}
            disabled={disabled}
            title={title}
          >
            {messages['refund.modal.create-just-recurrency']}
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div className="refund-status description h-100 d-flex align-items-center justify-content-center w-100">
      {recurrence?.type
        ? renderBudgetWithRecurrence()
        : renderBudgetWithoutRecurrence()}
    </div>
  );
}
