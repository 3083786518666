import React, { useEffect, useState } from 'react';

export default function CustomProgressBar({
  progress,
  thin = false,
  decimals = true,
}) {
  const [progressWidth, setProgressWidth] = useState('0%');
  const [overflow, setOverflow] = useState(false);

  useEffect(() => {
    const _progress = decimals ? progress * 100 : progress;
    const _overflow = decimals ? 1 : 100;

    setProgressWidth(`${_progress}%`);

    if (progress > _overflow) setOverflow(true);
    else setOverflow(false);
  }, [progress, decimals]);

  return (
    <div
      className={`custom-progress-bar ${thin ? 'thin' : ''} ${
        overflow ? 'overflow' : ''
      }`}
    >
      <div className="progress" style={{ width: progressWidth }}></div>
    </div>
  );
}
