import firebaseApp from '../../../../services/Firebase';
import { getDataFromDocument } from '../../../../utils/firebase';

export async function fetchCommonInfo(budget, getDataFromArchive) {
  const fields = [
    {
      id: 'cost-center',
      message: 'travel.booking.costCenter',
      propertyName: 'costCenter',
      fetchFunction: (organizationId, id) =>
        firebaseApp.getCostCentersFromId(organizationId, id).get(),
    },
    {
      id: 'project',
      message: 'travel.booking.project',
      propertyName: 'project',
      fetchFunction: (organizationId, id) =>
        firebaseApp.getProjectFromId(organizationId, id).get(),
    },
    {
      id: 'motive',
      message: 'travel.booking.motive',
      propertyName: 'motive',
      fetchFunction: (organizationId, id) =>
        firebaseApp.getMotiveFromId(organizationId, id).get(),
    },
  ];

  const commonInfo = (
    await Promise.all(
      fields.map(async ({ id, message, fetchFunction, propertyName }) => {
        const dataId = budget[propertyName];

        if (!dataId) return null;

        const data = await getDataFromArchive(
          `${id}/${dataId}`,
          () => fetchFunction(budget.organizationId, dataId),
          getDataFromDocument
        );

        return {
          id,
          message,
          data,
        };
      })
    )
  ).filter((value) => value?.data);

  const lastIndex = commonInfo.length % 2 === 0 ? null : commonInfo.length - 1;

  return commonInfo.map((info, index) => ({
    ...info,
    size: index === lastIndex ? 12 : 6,
  }));
}
