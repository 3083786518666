import React, { useEffect, useRef, useMemo } from 'react';
import { Button } from 'reactstrap';
import { stringToMoney } from '../../../utils/money';
import { useIntl } from 'react-intl';

export default function ExpenseBottom({
  total,
  budget,
  onCancel,
  onCreate,
  canCreate = true,
  canCancel = true,
  isNew,
  TotalAdditionalComponent,
  InfoComponent = () => null,
  setPaddingBottom = () => {},
  editable,
  atBottom,
  multiplier = 1,
}) {
  const formattedTotal = useMemo(
    () => stringToMoney(total, 2, budget.fare.currency),
    [total, budget]
  );
  const componentRef = useRef(null);
  const { messages } = useIntl();

  // Effects
  useEffect(() => {
    if (componentRef.current) {
      setPaddingBottom(componentRef.current.offsetHeight * multiplier);
    }
  }, []);

  // Render
  const renderTotal = (textAlign = 'text-left') => (
    <div className={`m-0 p-0 total-container ${textAlign}`}>
      <p className="m-0 p-0 font-primary label text-primary font-weight-medium">
        {messages['general.total']}
      </p>
      <h3 className="total m-0 p-0 font-primary text-primary font-weight-normal">
        {formattedTotal}
      </h3>
      {TotalAdditionalComponent && <TotalAdditionalComponent />}
    </div>
  );

  const renderContent = () => {
    if (editable) {
      return (
        <>
          {renderTotal()}
          <div className="d-flex flex-wrap justify-content-end">
            {canCancel ? (
              <Button size="sm" color="link" onClick={onCancel}>
                {messages['general.go-back']}
              </Button>
            ) : null}
            <Button
              size="sm"
              color="primary"
              disabled={!canCreate}
              onClick={onCreate}
              className="px-4 py-2"
            >
              {messages[isNew ? 'refund.modal.create-expense' : 'general.save']}
            </Button>
          </div>
        </>
      );
    } else {
      return (
        <>
          {renderTotal()}
          <InfoComponent />
        </>
      );
    }
  };

  return (
    <div
      className={`expense-bottom d-flex align-items-center justify-content-between bg-secondary-light ${
        atBottom ? 'bottom' : ''
      }`}
      ref={componentRef}
    >
      {renderContent()}
    </div>
  );
}
