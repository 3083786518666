import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Card } from 'reactstrap';
import { NavLink, useLocation } from 'react-router-dom';

import LoginEmail from '../../components/auth/LoginEmail';
import LoginPassword from '../../components/auth/LoginPassword';
import LoginGoogle from '../../components/auth/LoginGoogle';
import LoginSAML from '../../components/auth/LoginSAML';
import appFunctions from '../../services/Functions';
// import NewPassword from "../../components/auth/NewPassword"
// import MFASetup from "../../components/auth/MFASetup"
// import MFA from "../../components/auth/MFA"

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function Login() {
  const paramsRef = useRef({});

  const [step, setStep] = useState('auth-EMAIL');
  const [email, setEmail] = useState('');
  const [providerName, setProvider] = useState(null);

  const { user } = useSelector((state) => state.auth);
  const history = useHistory();
  const query = useQuery();

  // Effects
  useEffect(() => {
    if (query.get('email')) {
      if (!user && !query.get('ongoing')) {
        // Prevent login loop
        query.append('ongoing', 'true');
        history.push(`?${query.toString()}`);

        setEmail(query.get('email'));
      }

      paramsRef.current = {
        email: query.get('email'),
        mobileAuth: query.get('mba') !== null,
        url: query.get('url'),
      };
    }
  }, []);

  useEffect(() => {
    if (user?.uid) checkLogin();
  }, [user]);

  // Functions
  const checkLogin = async () => {
    const { mobileAuth, url } = paramsRef.current;

    if (mobileAuth) {
      const { data: deepLinkURL } = await appFunctions.getAuthLink(url);
      return window.open(deepLinkURL, '_self');
    }

    history.push('/');
  };

  // Render
  return (
    <Card className="auth-card">
      <NavLink to={`/`} className="black">
        <span className="logo-single mb-4" />
      </NavLink>
      <div>
        {step === 'auth-EMAIL' && (
          <LoginEmail
            email={email}
            setEmail={setEmail}
            setProvider={setProvider}
            setStep={setStep}
          />
        )}

        {step === 'auth-PASSWORD' && (
          <LoginPassword email={email} setStep={setStep} />
        )}

        {step === 'auth-GOOGLE' && (
          <LoginGoogle email={email} setStep={setStep} />
        )}

        {step === 'auth-SAML' && (
          <LoginSAML
            providerName={providerName}
            email={email}
            setStep={setStep}
          />
        )}

        {/* {step === 'new_password' && <NewPassword user={user} loginUser={this.loginUser} />} */}
        {/* {step === 'mfa_setup' && <MFASetup user={user} loginUser={this.loginUser} />} */}
        {/* {step === 'mfa' && <MFA user={user} loginUser={this.loginUser} />} */}
      </div>
    </Card>
  );
}
