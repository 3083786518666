import React from 'react';
import QrReader from 'react-qr-reader';

export default function ReceiptQrCodeReader({
  show = true,
  onScan = () => {},
  onError = () => {},
  className = '',
}) {
  return (
    <div className={`receipt-qr-code-reader ${className}`}>
      {show ? (
        <QrReader
          delay={300}
          onError={onError}
          onScan={(value) => value && onScan(value)}
          className="qrcode-video"
        />
      ) : null}
    </div>
  );
}
