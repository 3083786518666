import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Button, Input, Row } from 'reactstrap';
import { Colxx } from '../../components/common/CustomBootstrap';
import { ReactComponent as EmailImg } from '../../assets/img/bulk/modified.svg';
import firebaseApp from '../../services/Firebase';
import debounce from 'lodash/debounce';
import classNames from 'classnames';
import appFunctions from '../../services/Functions';

const re = /[a-zA-Z0-9\._-]/;

export default function AliasView() {
  const { messages } = useIntl();
  const { organization } = useSelector(({ auth }) => auth);

  const [alias, setAlias] = useState(organization.email_alias || '');
  const [width, setWidth] = useState(0);

  const [isValid, setIsValid] = useState(false);
  const [validating, setValidating] = useState(false);
  const [error, setError] = useState(null);
  const [saving, setSaving] = useState(false);

  const spanRef = useRef(null);
  const canChange = useMemo(
    () => !Boolean(organization.email_alias),
    [organization]
  );

  // Effects
  useEffect(() => {
    if (isValid) setIsValid(false);
    if (error) setError(null);

    if (spanRef.current) {
      setWidth(spanRef.current.offsetWidth + 10);
    }

    if (alias && canChange) validateEmail(alias);
  }, [alias]);

  useEffect(() => {
    setAlias(organization.email_alias);
  }, [organization.email_alias]);

  // Functions
  const _validateEmail = async (alias) => {
    if (alias && alias.length < 20) {
      try {
        setValidating(true);
        const { data } = await appFunctions.checkEmailAlias(alias);
        if (data) {
          setIsValid(true);
          setError(null);
        } else {
          setIsValid(false);
          setError(true);
        }
      } finally {
        setValidating(false);
      }
    }
  };

  const validateEmail = useCallback(
    debounce((alias) => _validateEmail(alias), 300),
    []
  );

  const onKeyPress = (e) => {
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  };

  const onChangeAlias = async () => {
    try {
      setSaving(true);
      await firebaseApp.updateAlias(
        organization.id,
        alias.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
      );
    } catch (err) {
      console.error(err);
    }
  };

  // Render
  const renderContent = () => {
    if (canChange) {
      return (
        <div>
          <p className="text-one w-70 mt-3 slide-left">
            {messages['admin.receipts.alias.description']}
          </p>
          <div className="my-4 slide-left">
            <div className="d-flex align-items-center email-container">
              <Input
                value={alias}
                onChange={(e) => setAlias(e.target.value)}
                readOnly={!canChange}
                maxLength={19}
                onKeyPress={onKeyPress}
                style={{ width, minWidth: 80 }}
                className="email-input text-right p-0 text-primary font-weight-medium"
                placeholder="example"
              />
              <p className="m-0 email-remaining">@nfe.portao3.com.br</p>
            </div>
            {error ? (
              <p className="error-msg font-weight-medium">
                Esse pseudônimo já está em uso!
              </p>
            ) : null}
          </div>
          <div className="d-flex align-items-center slide-left">
            <Button
              color="primary"
              disabled={!isValid || saving}
              onClick={onChangeAlias}
            >
              Criar pseudônimo
            </Button>
            <div className={classNames('ml-3', { loading: validating })} />
          </div>
          <span
            className="mirror"
            ref={spanRef}
            style={{ visibility: 'hidden' }}
          >
            {alias}
          </span>

          <p className="mt-4 slide-left">
            <span className="font-weight-medium text-primary">
              E-mail padrão:
            </span>{' '}
            {organization.id}@nfe.portao3.com.br
          </p>
        </div>
      );
    } else
      return (
        <div>
          <p className="text-one w-70 mt-3 slide-left">
            {messages['admin.receipts.alias.added.description']}
          </p>
          <div className="my-4 slide-left">
            <p
              className="m-0 p-0 mb-1 font-weight-medium"
              style={{ fontSize: '1.15rem' }}
            >
              <span>{alias}</span>@nfe.portao3.com.br
            </p>
            <p className="text-muted m-0 p-0" style={{ fontSize: '0.8rem' }}>
              ou {organization.id}@nfe.portao3.com.br
            </p>
          </div>
        </div>
      );
  };

  return (
    <Row className="alias-view d-flex justify-content-between mt-5 ml-3 w-100">
      <Colxx
        xxs="12"
        md="5"
        className="d-flex justify-content-center align-self-start"
      >
        <EmailImg width="80%" />
      </Colxx>
      <Colxx xxs="12" md="7" className="mt-3 pl-3">
        {renderContent()}
      </Colxx>
    </Row>
  );
}
