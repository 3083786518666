import { addMissingMessages } from '../locales';
import enMessages from '../locales/en_US';

const EnLang = {
  messages: addMissingMessages(enMessages),
  locale: 'en-US',
  name: 'English',
};

export default EnLang;
