import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Card, CardTitle, CardBody } from 'reactstrap';
import uniqBy from 'lodash/uniqBy';
import find from 'lodash/find';

import LoaderAnalytics from '../../components/loaders/LoaderAnalytics';
import ProfileImage from '../../components/ProfileImage';

import firebaseApp from '../../services/Firebase';

export default function AdoptionCard({ orders, parentLoading }) {
  const { messages } = useIntl();

  const [travelers, setTravelers] = useState([]);
  const [loading, setLoading] = useState(true);

  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    loadTravelers();
  }, []);

  const activeTravelers = uniqBy(orders, 'passengerId');

  const loadTravelers = async () => {
    setLoading(true);

    const snapshotTraveler = await firebaseApp
      .getUsersFromOrganization(user.organizationId)
      .get();

    const travelers = [];

    snapshotTraveler.forEach(function (doc) {
      travelers.push({ ...doc.data(), id: doc.id });
    });

    setTravelers(travelers);
    setLoading(false);
  };

  if (loading || parentLoading) {
    return (
      <Card className="mb-3">
        <CardBody>
          <CardTitle>
            {messages['containers.dashboard.support-card.title']}
          </CardTitle>
          <LoaderAnalytics />
          <LoaderAnalytics />
        </CardBody>
      </Card>
    );
  }

  return (
    <Card className="mb-3">
      <CardBody>
        <CardTitle>
          {messages['containers.dashboard.adoption-card.title']}
        </CardTitle>

        <div className="d-flex flex-row mb-2 pb-2 justify-content-between align-items-center">
          <div className="flex-fill">
            <p className="font-weight-medium mb-0">
              {
                messages[
                  'containers.dashboard.adoption-card.registered-travelers'
                ]
              }
            </p>
            <p className="text-muted mb-0 text-small">
              {messages['containers.dashboard.adoption-card.amount-travelers']}
            </p>
          </div>
          <div>{travelers.length}</div>
        </div>

        <div className="d-flex flex-row mb-1 justify-content-between align-items-center">
          <div className="flex-fill">
            <p className="font-weight-medium mb-0">
              {messages['containers.dashboard.adoption-card.active-travelers']}
            </p>
            <p className="text-muted mb-0 text-small">
              {messages['containers.dashboard.adoption-card.with-travel']}
            </p>
          </div>
          <div>{activeTravelers.length}</div>
        </div>
        <div className="mb-2 pb-2">
          {activeTravelers.slice(0, 10).map((order) => {
            const user = find(travelers, (t) => t.id === order.passengerId);

            return (
              <ProfileImage
                key={order.id}
                user={user}
                rounded
                xxsmall
                className={'float-left'}
              />
            );
          })}
        </div>
      </CardBody>
    </Card>
  );
}
