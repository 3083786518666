import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useParams, useHistory } from 'react-router-dom';
import {
  Card,
  Label,
  CustomInput,
  Row,
  Button,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { Colxx } from '../../components/common/CustomBootstrap';
import classnames from 'classnames';
import map from 'lodash/map';
import mouseTrap from 'mousetrap';

import IntlMessages from '../../helpers/IntlMessages';

import { useIntl } from 'react-intl';
import { trackPropertiesSegment } from '../../utils/segment';

import EmptyResults from '../../components/common/EmptyResults';
import PolicyBulkImportButton from './PolicyBulkImportButton';
import PolicyLoading from './PolicyLoading';
import BulkModal from '../users/BulkModal';
import { applyBulkImport, sortPolicyBulkData } from '../../utils/policy';
import PermissionToggleFragment from '../../components/common/PermissionToggleFragment';
import useToggleRequiredField from '../../hooks/useToggleRequiredField';
import LazyLoadingTrackerWrapper from '../../wrappers/LazyLoadingTrackerWrapper';
import useDataSnapshotPaginated from '../../hooks/useDataSnapshotPaginated';
import useSearchInfo from '../../hooks/useSearchInfo';
import { getAuthRedux } from '../../utils/redux';
import { getOrganizationConfig } from '../../utils/organization';
import useDataArchive from '../../hooks/useDataArchive';

export default function DefaultDataListView({
  fieldName,
  path,
  fetchFunction,
  deleteFunction,
  updateFunction,
  createFunction,
  ItemModal,
  emptyResultsTitleId,
  emptyResultsDescriptionId,
  emptyResultsButtonTextId,
  archiveKey,
}) {
  const { user, organization } = useSelector(getAuthRedux);
  const { resourceId } = useParams();
  const history = useHistory();
  const { messages } = useIntl();
  const { deleteDataFromArchive } = useDataArchive();

  // States
  const [selectedItems, setSelectedItems] = useState([]);
  const [dropdownSplitOpen, setDropdownSplitOpen] = useState(false);
  const [showBulkModal, setShowBulkModal] = useState(false);
  const [bulkData, setBulkData] = useState({});

  const { required, editable } = useMemo(() => {
    const required = getOrganizationConfig(organization.config, 'required');
    const editable = getOrganizationConfig(organization.config, 'editable');

    return {
      required: required[fieldName],
      editable: editable[fieldName],
    };
  }, [organization, fieldName]);

  const {
    data,
    atEnd,
    loading: isLoading,
    fetchMore,
    fetchAll,
  } = useDataSnapshotPaginated({
    initialData: [],
    initialLoading: true,
    fetchFunction: () => fetchFunction(user.organizationId).orderBy('name'),
  });

  const { isEmpty, isInitializing } = useSearchInfo({
    items: data,
    loading: isLoading,
  });

  useToggleRequiredField({
    name: fieldName,
    items: data,
    loading: isInitializing,
  });

  // Effects
  useEffect(() => {
    mouseTrap.bind(['ctrl+a', 'command+a'], () => {
      setSelectedItems(map(data, (x) => x.id));
      return false;
    });

    mouseTrap.bind(['ctrl+d', 'command+d'], () => {
      setSelectedItems([]);
      return false;
    });

    return () => {
      mouseTrap.unbind('ctrl+a');
      mouseTrap.unbind('command+a');
      mouseTrap.unbind('ctrl+d');
      mouseTrap.unbind('command+d');
    };
  }, [data]);

  const deleteItems = async () => {
    await Promise.all(
      selectedItems.map((item) => deleteFunction(user.organizationId, item))
    );

    trackPropertiesSegment(`Items deletes (${fieldName})`, {
      items: selectedItems,
      user,
    });

    setSelectedItems([]);
    deleteDataFromArchive(archiveKey);
  };

  // Split
  const toggleSplit = () => {
    setDropdownSplitOpen(!dropdownSplitOpen);
  };

  // Selection
  const onCheckItem = (id) => {
    if (selectedItems.includes(id)) {
      setSelectedItems(selectedItems.filter((x) => x !== id));
    } else {
      setSelectedItems([...selectedItems, id]);
    }
  };

  const handleChangeSelectAll = () => {
    if (selectedItems.length >= data.length) {
      setSelectedItems([]);
    } else {
      setSelectedItems(map(data, (x) => x.id));
    }
  };

  const handleBulkImport = async ({ data }) => {
    const allData = await fetchAll();

    setBulkData(sortPolicyBulkData(allData, data));
    setShowBulkModal(true);
  };

  const applyItemBulkImport = async (values) => {
    try {
      await applyBulkImport({
        ...values,
        onDelete: (value) => deleteFunction(user.organizationId, value.id),
        onModified: ({ id, ...values }) =>
          updateFunction(user.organizationId, id, values),
        onCreated: (value) => createFunction(user.organizationId, value),
      });

      setBulkData({});
      deleteDataFromArchive(archiveKey);
    } catch (err) {
      console.error(err);
    }
  };

  const toggleBulkModal = () => {
    setShowBulkModal(!showBulkModal);
  };

  // Modal
  const openModal = (ruleId) => {
    trackPropertiesSegment(`Item modal opened ${fieldName}`, { user });
    history.push(`/app/management/${path}/${ruleId}`);
  };

  const closeModal = () => {
    trackPropertiesSegment(`Item modal closed ${fieldName}`, { user });
    history.push(`/app/management/${path}`);
  };

  // Render
  if (isInitializing) {
    return <PolicyLoading />;
  }

  return (
    <div>
      {isEmpty ? (
        <div className="w-100 d-flex justify-content-center mt-5">
          <EmptyResults
            title={messages[emptyResultsTitleId]}
            description={messages[emptyResultsDescriptionId]}
            buttonText={messages[emptyResultsButtonTextId]}
            className="w-70"
            onClick={() => openModal('new')}
          />
        </div>
      ) : (
        <>
          <Row>
            <Colxx xxs="12" className="mt-5">
              <Button
                color="primary"
                className="m-0 pretty-btn"
                onClick={() => {
                  openModal('new');
                }}
              >
                {messages['general.add']}
              </Button>

              <ButtonDropdown
                isOpen={dropdownSplitOpen}
                toggle={toggleSplit}
                className="float-right"
              >
                <div className="btn btn-primary btn-lg pl-3 pr-0 check-button check-all">
                  <CustomInput
                    className="custom-checkbox mb-0 d-inline-block"
                    type="checkbox"
                    id="checkAll"
                    checked={selectedItems.length >= data.length}
                    onChange={handleChangeSelectAll}
                    label={
                      <span
                        className={`custom-control-label ${
                          selectedItems.length > 0 &&
                          selectedItems.length < data.length
                            ? 'indeterminate'
                            : ''
                        }`}
                      />
                    }
                  />
                </div>

                <DropdownToggle
                  caret
                  color="primary"
                  className="dropdown-toggle-split btn-lg"
                />
                <DropdownMenu right style={{ width: 180 }}>
                  <DropdownItem onClick={deleteItems}>
                    <IntlMessages id="admin.policy.delete" />
                  </DropdownItem>
                  <div className="bulk-import-container">
                    <PolicyBulkImportButton
                      user={user}
                      onData={handleBulkImport}
                      simple
                    />
                  </div>
                  <a
                    href="https://storage.googleapis.com/portao3-static/models/Planilha_Modelo.xlsx"
                    rel="noopener noreferer"
                    download
                  >
                    <DropdownItem
                      className="w-100 m-0 text-break"
                      style={{ whiteSpace: 'pre-wrap' }}
                    >
                      {messages['admin.users.spreadsheet-model']}
                    </DropdownItem>
                  </a>
                </DropdownMenu>
              </ButtonDropdown>
            </Colxx>
          </Row>

          <Row className="mt-4">
            <LazyLoadingTrackerWrapper
              onReachEnd={fetchMore}
              disabled={atEnd}
              loading={isLoading}
            >
              {data.map((item, index) => (
                <Colxx key={`item-${index}`} xxs="12" className="mb-3">
                  <Card
                    className={classnames('d-flex flex-row list', {
                      active: selectedItems.includes(item.id),
                    })}
                  >
                    <div className="pl-2 d-flex flex-grow-1 mw-0">
                      <div className="card-body align-self-center d-flex flex-column flex-lg-row justify-content-between mw-0 align-items-lg-center">
                        <div className="w-40 w-sm-100">
                          <p className="list-item-heading mb-1 truncate">
                            <NavLink to={`/app/management/${path}/${item.id}`}>
                              {item.name}
                            </NavLink>
                          </p>
                        </div>
                        <p className="mb-1 text-muted text-small w-15 w-sm-100">
                          {item.externalId}
                        </p>
                        <div className="w-15 w-sm-100"></div>
                      </div>
                      <div className="custom-control custom-checkbox pl-1 align-self-center pr-4">
                        <Label>
                          <CustomInput
                            className="item-check mb-0"
                            type="checkbox"
                            id={`check_${item.id}`}
                            checked={selectedItems.includes(item.id)}
                            onChange={() => onCheckItem(item.id)}
                          />
                        </Label>
                      </div>
                    </div>
                  </Card>
                </Colxx>
              ))}
            </LazyLoadingTrackerWrapper>
          </Row>

          <PermissionToggleFragment
            fieldName={fieldName}
            requiredValue={required}
            editableValue={editable}
            organizationId={organization.id}
            hasEditable
          />
        </>
      )}

      {resourceId && <ItemModal toggleModal={closeModal} id={resourceId} />}

      {showBulkModal && (
        <BulkModal
          toggleModal={toggleBulkModal}
          bulkUsers={bulkData}
          onCancel={() => {
            toggleBulkModal();
            setBulkData({});
          }}
          onApply={(values) => {
            toggleBulkModal();
            applyItemBulkImport(values);
          }}
          titleGenerator={(value) =>
            `${value.name} ${value.externalId ? `(${value.externalId})` : ''}`
          }
          keyGenerator={(value) => value.name}
          messagesId="policy"
        />
      )}
    </div>
  );
}
