import classNames from 'classnames';
import React from 'react';

import { ReactComponent as CloseIcon } from '../assets/img/icons/close.svg';

export default function TopMessage({
  show,
  description,
  toggle = () => {},
  direction = 'left',
  className = '',
  children,
  ...props
}) {
  return (
    <div
      className={classNames(
        'top-message d-flex flex-column px-3 py-3',
        className,
        direction,
        {
          show,
        }
      )}
      {...props}
    >
      <div className="icon pointer" onClick={toggle}>
        <CloseIcon width="100%" />
      </div>
      <p className="m-0 p-0 description">{description}</p>
      {children}
    </div>
  );
}
