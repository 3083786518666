import React, { Component } from 'react';
import { Button } from 'reactstrap';
import { Colxx } from '../common/CustomBootstrap';

import IntlMessages from '../../helpers/IntlMessages';

import SearchStatus from '../../constants/searchStatus';

class SearchButton extends Component {
  render() {
    const { status, size = '2', disabled = false } = this.props;

    return (
      <Colxx xxs={size} className="text-center search-btn">
        {status === SearchStatus.LOADING ? (
          <Button
            type="submit"
            color="primary"
            outline
            size="lg"
            className="search"
            disabled
          >
            <div className="loading" />
          </Button>
        ) : (
          <Button
            type="submit"
            color="primary"
            outline
            size="lg"
            className="search"
            disabled={disabled}
          >
            <div className="search-text font-weight-semibold">
              <IntlMessages id="travel.search" />
            </div>
          </Button>
        )}
      </Colxx>
    );
  }
}

export default SearchButton;
