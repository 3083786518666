import { ReactComponent as FlightIcon } from '../../../assets/img/icons/raw/plane.svg';
import { ReactComponent as HotelIcon } from '../../../assets/img/icons/raw/hotel.svg';
import { ReactComponent as OtherIcon } from '../../../assets/img/icons/black-clockwork.svg';

import FlightParams from './Params/FlightParams';
import HotelParams from './Params/HotelParams';
import CommonParams from './Params/CommonParams';

export const paramsComponents = [
  {
    id: 'flight',
    Icon: FlightIcon,
    Component: FlightParams,
    paramsName: 'flightParams',
  },
  {
    id: 'hotel',
    Icon: HotelIcon,
    Component: HotelParams,
    paramsName: 'hotelParams',
  },
  {
    id: 'others',
    Icon: OtherIcon,
    Component: CommonParams,
  },
];
