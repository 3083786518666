import { useCallback, useState } from 'react';

export default function useShowToggle(initialValue = false) {
  const [show, setShow] = useState(initialValue);

  const toggle = useCallback((value) => {
    let current = false;

    setShow((show) => {
      current =
        value !== undefined && typeof value === 'boolean' ? value : !show;
      return current;
    });

    return current;
  }, []);

  return [show, setShow, toggle];
}
