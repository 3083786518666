import React from 'react';
import { FLIGHT_ALTERATION_TABS } from '..';
import SlidingComponents from '../../../../SlidingComponents';
import FinalTab from './FinalTab';
import OptionsTab from './OptionsTab';

export const CANCELLATION_TABS = {
  OPTIONS: 'options',
  FINAL: 'final',
};

const COMPONENTS = {
  [CANCELLATION_TABS.OPTIONS]: OptionsTab,
  [CANCELLATION_TABS.FINAL]: FinalTab,
};

export default function CancellationTab({
  order,
  goForAlteration,
  setCurrentTab,
}) {
  return (
    <SlidingComponents
      initialTab={CANCELLATION_TABS.OPTIONS}
      components={COMPONENTS}
      goForAlteration={goForAlteration}
      goToInitialTab={() => setCurrentTab(FLIGHT_ALTERATION_TABS.INITIAL)}
      order={order}
      className="flight-alteration-tab h-100"
    />
  );
}
