import classNames from 'classnames';
import React, { useMemo } from 'react';

export default function StarCategories({ category, size = 10, className }) {
  const categoryArray = useMemo(() => new Array(category).fill(0), [category]);

  return (
    <div className={classNames('star-categories lh-1', className)}>
      {categoryArray.map((_, index) => (
        <img
          key={`category-${index}`}
          src="/assets/img/hotel-star.svg"
          className="star mr-1"
          width={size}
          height={size}
        />
      ))}
    </div>
  );
}
