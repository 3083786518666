import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import moment from 'moment';
import InformationCard from '../../InformationCard';
import CurrencyWrapper from '../../../common/CurrencyWrapper';
import { ReactComponent as MoneyIcon } from '../../../../assets/img/icons/money-minimalist.svg';
import { ReactComponent as CalenderIcon } from '../../../../assets/img/icons/calender.svg';

export default function BusReservationInformation({ order }) {
  const { messages } = useIntl();

  const { pricePerWay, anticipation } = useMemo(() => {
    const departureDate = moment(order.journeys[0].departureDate, 'YYYY-MM-DD');

    const createdAt = moment(createdAt).format('DD-MM-YYYY');

    const pricePerWay = (order.fare.total / order.journeys.length).toFixed(2);

    const anticipation = moment(departureDate, 'DD-MM-YYYY').diff(
      moment(createdAt, 'DD-MM-YYYY'),
      'days'
    );

    return { pricePerWay, anticipation };
  }, [order]);

  return (
    <div>
      <div className="d-flex mb-3">
        <InformationCard
          title={messages['travel.details.flightandbus.price-per-way']}
          value={
            <CurrencyWrapper value={pricePerWay} decimalPlaces={0}>
              {({ formattedValue }) => <span>{formattedValue}</span>}
            </CurrencyWrapper>
          }
          Icon={MoneyIcon}
        />
        <InformationCard
          title={messages['travel.details.advance']}
          value={`${anticipation} ${messages['general.days']}`}
          Icon={CalenderIcon}
        />
      </div>
    </div>
  );
}
