const {
  BudgetCategories,
  PhysicalCategories,
  VirtualCategories,
} = require('./budget');

export const RefundTypes = {
  REFUND: 'REFUND',
  EXPENSE: 'EXPENSE',
  CARD: 'CARD',
  PHYSICAL_CARD: 'PHYSICAL',
  VIRTUAL_CARD: 'VIRTUAL',
  PERSONAL_ACCOUNT: 'PERSONAL',
  ADMINISTRATIVE_ACCOUNT: 'ADMINISTRATIVE',
};

export const HasBudget = [RefundTypes.PHYSICAL_CARD, RefundTypes.VIRTUAL_CARD];

export const AllowBudgetItemCreation = [RefundTypes.PERSONAL_ACCOUNT];

export const ReceiptTypes = {
  CREATION: 'creation',
  STORED: 'stored',
  UPLOADED: 'uploaded',
};

export const defaultCategory = {
  value: 'none',
  label: '',
  icon: 'money.svg',
};

export const expenseTypes = {
  ITEMS: 'items',
  KM: 'km',
};

export const TypesOfRefunds = [
  BudgetCategories.FOOD,
  BudgetCategories.FUEL,
  BudgetCategories.TRANSPORT,
  BudgetCategories.FREE,
];

export const RefundTypesPriority = {
  [RefundTypes.ADMINISTRATIVE_ACCOUNT]: 1,
  [RefundTypes.PHYSICAL_CARD]: 2,
  [RefundTypes.VIRTUAL_CARD]: 3,
  [RefundTypes.PERSONAL_ACCOUNT]: 4,
};

export const MappedBudgetCategories = {
  [RefundTypes.PHYSICAL_CARD]: PhysicalCategories,
  [RefundTypes.VIRTUAL_CARD]: VirtualCategories,
};
