import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { isUserAllowed } from '../utils/permissions';

export default function AdminRoute({ to = '/', children, permissions = [] }) {
  const { user = {} } = useSelector(({ auth }) => auth);
  const hasPermission = useMemo(
    () => isUserAllowed(user, permissions),
    [user, permissions]
  );

  return hasPermission ? children : <Redirect to={to} />;
}
