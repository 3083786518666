import React, { useMemo, useState } from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import classNames from 'classnames';
import RecurrenceBudgetStatus from './RecurrenceBudgetStatus';
import TabSwitch from '../../../../components/expenses/TabSwitch';
import { useIntl } from 'react-intl';
import useDataSnapshot from '../../../../hooks/useDataSnapshot';
import firebaseApp from '../../../../services/Firebase';
import { getDataFromDocument } from '../../../../utils/firebase';
import { TABS } from './consts';
import RecurrenceTab from './Tabs/RecurrenceTab';
import HistoryTab from './Tabs/HistoryTab';
import PerfectScrollbar from 'react-perfect-scrollbar';
import useHistoryWithScroll from '../../../../hooks/useHistoryWithScroll';

export default function RecurrenceBudgetModal({
  budgetId,
  closeModal,
  closeModalPath = '/payments/recurrence',
}) {
  const { messages } = useIntl();

  // Navigation
  const { push } = useHistoryWithScroll();

  const toggle = useMemo(() => {
    return closeModal || (() => push(closeModalPath));
  }, [push, closeModal, closeModalPath]);

  // Snapshots
  const [recurrenceBudget, _, isLoading] = useDataSnapshot({
    fetchFunction: () => firebaseApp.getRecurrenceBudgetById(budgetId),
    getSnapshotDataFunction: getDataFromDocument,
  });

  // States
  const tabs = useMemo(
    () => [
      {
        key: TABS.RECURRENCE,
        label: messages['recurrence-budget-modal.tabs.recurrence'],
      },
      {
        key: TABS.HISTORY,
        label: messages['recurrence-budget-modal.tabs.history'],
      },
    ],
    []
  );

  const [currentTab, setCurrentTab] = useState(TABS.RECURRENCE);
  const Tab = useMemo(
    () => (currentTab === TABS.RECURRENCE ? RecurrenceTab : HistoryTab),
    [currentTab]
  );

  // Render
  if (isLoading) {
    return (
      <Modal isOpen={true} toggle={toggle} className="reservation small">
        <ModalBody>
          <div className="loading p-relative" />
        </ModalBody>
      </Modal>
    );
  }

  return (
    <Modal
      isOpen={true}
      toggle={toggle}
      className={classNames('recurrence-budget-modal', {
        reservation: true,
        small: true,
      })}
    >
      <ModalBody className="position-relative">
        <PerfectScrollbar
          options={{
            suppressScrollX: true,
            wheelPropagation: false,
          }}
          className="m-0 p-0"
        >
          <TabSwitch
            className="ml-2 mb-2"
            items={tabs}
            currentTab={currentTab}
            setTab={setCurrentTab}
            navLinkClassName="mx-2"
            navTitleClassName="font-primary fs-0-8rem"
          />

          <Tab budget={recurrenceBudget} />
        </PerfectScrollbar>
      </ModalBody>

      <ModalFooter>
        <RecurrenceBudgetStatus budget={recurrenceBudget} toggle={toggle} />
      </ModalFooter>
    </Modal>
  );
}
