import React from 'react';

import { ReactComponent as PlusSign } from '../../assets/img/icons/plus.svg';
import { ReactComponent as MinusSign } from '../../assets/img/icons/minus.svg';
import classNames from 'classnames';

export default function Counter({
  className = '',
  min = 0,
  max = 999,
  value,
  setValue,
}) {
  const handleSetValue = (value) => {
    if (value < min || value > max) return;
    else setValue(value);
  };

  const handleChange = ({ target }) => {
    handleSetValue(parseInt(target.value));
  };

  const handleBlur = () => {
    if (!value) setValue(0);
  };

  const increase = () => handleSetValue(value + 1);

  const decrease = () => handleSetValue(value - 1);

  return (
    <div
      className={classNames(
        'counter d-flex justify-content-between align-items-center',
        className
      )}
    >
      <div
        onClick={decrease}
        className="icon d-flex justify-content-center align-items-center"
        style={{ width: 20, height: 20 }}
      >
        <MinusSign width={15} fill="black" className="minus" />
      </div>
      <input
        type="number"
        min={min}
        max={max}
        value={value}
        onChange={handleChange}
        className="mw-0"
        onBlur={handleBlur}
      />
      <div
        onClick={increase}
        className="icon d-flex justify-content-center align-items-center"
        style={{ width: 20, height: 20 }}
      >
        <PlusSign width={15} fill="black" className="plus" />
      </div>
    </div>
  );
}
