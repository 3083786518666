import React, { useState, useRef } from 'react';
import { Popover, PopoverBody } from 'reactstrap';
import uniqueId from 'lodash/uniqueId';

import { useIntl } from 'react-intl';
import { triggerFilterChangeSegmentDebounce } from '../../utils/segment';
import classNames from 'classnames';

export default function Baggages({ className }) {
  const [showInfo, setShowInfo] = useState(false);
  const idRef = useRef(uniqueId('baggage-'));
  const { messages, formatMessage } = useIntl();

  return (
    <>
      <div
        id={idRef.current}
        className={classNames(
          'journey-baggage d-flex justify-content-end',
          className
        )}
        onMouseOver={() => {
          triggerFilterChangeSegmentDebounce('Hover over baggage component');
          setShowInfo(true);
        }}
        onMouseOut={() => setShowInfo(false)}
      >
        <img
          className="mr-1"
          src="/assets/img/icons/carryon.svg"
          alt={messages['components.bus.baggages.handluggage']}
        />
        <img
          src="/assets/img/icons/luggage.svg"
          alt={messages['travel.Baggage']}
        />
      </div>
      <Popover
        placement="bottom"
        isOpen={showInfo}
        target={idRef.current}
        popperClassName="baggage-info"
      >
        <PopoverBody>
          <div className="baggage-info-item d-flex flex-row justify-content-between">
            <img
              src="/assets/img/icons/carryon.svg"
              alt={messages['travel.backpack']}
            />
            <div className="d-flex flex-column">
              <span>{messages['components.bus.baggages.handluggage']}</span>
              <span>{messages['components.bus.baggages.fit.above']}</span>
            </div>
          </div>
          <div
            className={`baggage-info-item d-flex flex-row justify-content-between`}
          >
            <img
              src="/assets/img/icons/luggage.svg"
              alt={messages['components.bus.baggages.handluggage']}
            />
            <div className="d-flex flex-column">
              <span>{messages['components.bus.baggages.dispatch']}</span>
              <span>
                {formatMessage(
                  { id: 'components.bus.baggages.included' },
                  {
                    baggageQuantity: `1 ${messages['travel.baggage']}`,
                    baggageWeight: '30Kg',
                  }
                )}
              </span>
            </div>
          </div>
        </PopoverBody>
      </Popover>
    </>
  );
}
