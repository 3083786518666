import React from 'react';
import { Row, Card } from 'reactstrap';
import { Colxx } from '../common/CustomBootstrap';

import ContentLoader from 'react-content-loader';

export default function LoaderCardColumns() {
  return (
    <Card className="order-card mb-3 py-4 px-3">
      <Row>
        <Colxx xxs="4" className="d-flex align-items-center">
          <ContentLoader
            speed={2}
            width={40}
            height={40}
            viewBox="0 0 40 40"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
          >
            <rect x="10" y="10" rx="3" ry="3" width="20" height="20" />
          </ContentLoader>
          <div className="d-flex flex-column justify-content-center text-truncate">
            <ContentLoader
              speed={2}
              width={200}
              height={40}
              viewBox="0 0 200 40"
              backgroundColor="#f3f3f3"
              foregroundColor="#ecebeb"
            >
              <rect x="0" y="0" rx="3" ry="3" width="200" height="20" />
              <rect x="0" y="25" rx="3" ry="3" width="120" height="15" />
            </ContentLoader>
          </div>
        </Colxx>
        <Colxx
          xxs="4"
          className="d-flex align-items-center justify-content-center"
        >
          <ContentLoader
            speed={2}
            width={100}
            height={20}
            viewBox="0 0 100 20"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
          >
            <rect x="0" y="0" rx="3" ry="3" width="100" height="20" />
          </ContentLoader>
        </Colxx>
        <Colxx
          xxs="2"
          className="d-flex align-items-center justify-content-center text-dark"
        >
          <ContentLoader
            speed={2}
            width={80}
            height={20}
            viewBox="0 0 80 20"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
          >
            <rect x="0" y="0" rx="3" ry="3" width="80" height="20" />
          </ContentLoader>
        </Colxx>
        <Colxx
          xxs="2"
          className="d-flex align-items-center justify-content-center text-dark"
        >
          <ContentLoader
            speed={2}
            width={80}
            height={20}
            viewBox="0 0 80 20"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
          >
            <rect x="0" y="0" rx="3" ry="3" width="80" height="20" />
          </ContentLoader>
        </Colxx>
      </Row>
    </Card>
  );
}
