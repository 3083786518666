import { useMemo, useRef } from 'react';

export default function useSearchInfo({ items = [], loading }) {
  const didInitialize = useRef(false);

  const [isEmpty, isInitializing] = useMemo(() => {
    if (!loading && !didInitialize.current) didInitialize.current = true;

    return [!loading && !items.length, !didInitialize.current];
  }, [items, loading]);

  return {
    isEmpty,
    isInitializing,
  };
}
