import React from 'react';
import NoResults from '../search/NoResults';

export default function SelectOutboundMessage() {
  return (
    <div className="flex-grow-1 flex-center my-4">
      <NoResults
        className="mt-0"
        titleMessageID="itinerary.select-outbound.title"
        subtitleMessageID="itinerary.select-outbound.description"
      />
    </div>
  );
}
