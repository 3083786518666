import { useMemo, useState } from 'react';
import Select from 'react-select';

export default function FixedSelect({
  styles = {},
  options = [],
  showOnlyX,
  ...props
}) {
  // States
  const [doLimitItems, setDoLimitItems] = useState(() =>
    showOnlyX ? true : false
  );

  const mergedStyles = useMemo(() => {
    return {
      ...styles,
      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    };
  }, [styles]);

  const limitedItems = useMemo(
    () => (showOnlyX ? options.slice(0, showOnlyX) : options),
    [options, showOnlyX]
  );

  // Functions
  const onSearch = (value) => {
    if (!showOnlyX) return;

    const hasValue = !value;
    if (hasValue !== doLimitItems) setDoLimitItems(hasValue);
  };

  // Render
  return (
    <Select
      {...props}
      options={doLimitItems ? limitedItems : options}
      menuPortalTarget={document.body}
      menuShouldBlockScroll={true}
      styles={mergedStyles}
      onInputChange={onSearch}
    />
  );
}
