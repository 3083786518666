export const formatCardNumber = (cardNumber = '') => {
  return cardNumber.replace(/^(\d{4})(\d{4})(\d{4})(\d{4})$/g, '$1 $2 $3 $4');
};

export const sortCreditCards = (creditCards, defaultMissingPriority = 1) => {
  return creditCards.sort(
    (
      { priority: aPriority = defaultMissingPriority },
      { priority: bPriority = defaultMissingPriority }
    ) => aPriority - bPriority
  );
};

export const filterCreditCards = (creditCards, desiredAssociatedProperties) => {
  const associativeProperties = Object.keys(desiredAssociatedProperties);

  const mappedProperties = associativeProperties.reduce(
    (mappedProperties, key) => {
      mappedProperties[key] = desiredAssociatedProperties[key].reduce(
        (obj, value) => {
          if (value) obj[value] = true;
          return obj;
        },
        {}
      );

      return mappedProperties;
    },
    {}
  );

  const checkProperty = (propertyArray = [], propertyType) => {
    if (propertyArray.length) {
      const mappedProperty = mappedProperties[propertyType] || {};
      return propertyArray.some(({ value }) => mappedProperty[value]);
    } else return true;
  };

  return creditCards.filter((creditCard) =>
    associativeProperties.every((property) =>
      checkProperty(creditCard[property], property)
    )
  );
};
