import firebase from 'firebase/compat/app';
import axios from 'axios';
import clone from 'lodash/clone';

import firebaseApp from './Firebase';

function User() {
  if (!(this instanceof User)) {
    return new User();
  }
}

const buildAxios = async (options) => {
  const headers = {};
  const { authEndpoint } = options || { authEndpoint: false };

  if (firebaseApp.isUserLoggedIn()) {
    headers['Authorization'] = `Bearer ${await firebaseApp
      .auth()
      .currentUser.getIdToken()}`;
  }

  return axios.create({
    baseURL: authEndpoint
      ? process.env.REACT_APP_API_URL
      : process.env.REACT_APP_PUBLIC_URL,
    headers,
  });
};

User.accountLookup = async function (email) {
  const axiosP3 = await buildAxios();

  return await axiosP3.post('/account-lookup', {
    email,
  });
};

User.hasAccount = async function (email) {
  try {
    const methods = await firebaseApp.auth().fetchSignInMethodsForEmail(email);

    return !!methods.length;
  } catch (error) {
    if (error.code === 'auth/invalid-email') {
      return false;
    }

    console.error(error);
  }
};

User.create = async function (email, password, firstName, lastName) {
  try {
    const userData = await firebaseApp
      .auth()
      .createUserWithEmailAndPassword(email, password);

    // Save data into Firestore
    const user = clone(userData.user);
    user.firstName = firstName;
    user.lastName = lastName;

    // Add displayName to user
    const { currentUser } = firebaseApp.auth();
    await currentUser.updateProfile({
      displayName: `${firstName} ${lastName}`,
      firstName,
      lastName,
    });

    await firebaseApp.setNewUserData({
      uid: user.uid,
      lastName: user.lastName,
      firstName: user.firstName,
      createdAt: Math.floor(new Date().getTime() / 1000),
    });

    return true;
  } catch (error) {
    return false;
  }
};

User.passwordLogin = async function (email, password) {
  await firebaseApp.auth().signOut();
  await firebaseApp.auth().signInWithEmailAndPassword(email, password);

  return;
};

User.logOut = function () {
  return firebaseApp.auth().signOut();
};

User.reautenticate = function (credential) {
  return firebaseApp
    .auth()
    .currentUser.reauthenticateAndRetrieveDataWithCredential(credential);
};

User.checkResetCode = async function (code) {
  return await firebaseApp.auth().checkActionCode(code);
};

User.updatePassword = function (newPassword) {
  return firebaseApp.auth().currentUser.updatePassword(newPassword);
};

User.updateEmail = function (newEmail) {
  return firebaseApp.auth().currentUser.updateEmail(newEmail);
};

User.requestResetPassword = async function (email) {
  const axiosP3 = await buildAxios();

  return await axiosP3.post('/forgot-password', { email });
};

User.getToken = async function () {
  return await firebaseApp.auth().currentUser.getIdToken();
};

User.inviteUser = async function (payload) {
  const axiosP3 = await buildAxios({ authEndpoint: true });

  const { data } = await axiosP3.post('/users', payload);

  return data;
};

User.bulkInviteUsers = async function (users) {
  const axiosP3 = await buildAxios();

  try {
    return await axiosP3.post('/users/batch', users);
  } catch (err) {
    if (err.response.data) {
      return err.response.data;
    }

    return false;
  }
};

User.deleteUser = async function (userId) {
  const axiosP3 = await buildAxios();

  try {
    await axiosP3.delete(`/users/${userId}`);

    return true;
  } catch (err) {
    if (err.response.data) {
      return err.response.data;
    }

    throw err;
  }
};

User.resetPassword = async function (code, newPassword) {
  return await firebaseApp.auth().confirmPasswordReset(code, newPassword);
};

User.isAdmin = function (user) {
  return user?.admin;
};

User.createApiKey = async function (payload) {
  const axiosP3 = await buildAxios();

  try {
    const reponse = await axiosP3.post('/apikeys', payload);

    return reponse.data;
  } catch (err) {
    if (err.response.data) {
      return err.response.data;
    }

    return false;
  }
};

User.deleteApiKey = async function (apiKeyId) {
  const axiosP3 = await buildAxios();

  try {
    await axiosP3.delete(`/apikeys/${apiKeyId}`);

    return true;
  } catch (err) {
    if (err.response.data) {
      return err.response.data;
    }

    throw err;
  }
};

// Google Login
User.googleProvider = function (email) {
  const provider = new firebase.auth.GoogleAuthProvider();
  provider.setCustomParameters({
    login_hint: email,
    hd: email.match(/@(.+)$/g)[0].replace('@', ''),
  });

  return provider;
};

User.samlProvider = function (providerName) {
  return new firebase.auth.SAMLAuthProvider(providerName);
};

User.signInWithPopup = function (provider) {
  return firebase.auth().signInWithRedirect(provider);
};

User.getToken = async function () {
  return firebaseApp.auth().currentUser.getIdToken();
};

export default User;
