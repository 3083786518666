import React, { Component } from 'react';
import { connect } from 'react-redux';

import firebaseApp from '../../services/Firebase';

import PackageHotelResults from './PackageHotelResults';

import LoadSearchStatus from '../../constants/loadSearchStatus';
import SearchStatus from '../../constants/searchStatus';

class PackageHotelSearch extends Component {
  constructor(props) {
    super(props);

    this.initialState = {
      results: [],
      status: LoadSearchStatus.NEW,
      isLoading: true,
      lastHotel: null,
    };

    this.state = this.initialState;
  }

  // Search
  componentDidMount() {
    this.setState(this.initialState);

    if (this.props.search.status === SearchStatus.DONE) {
      this.getInitialHotelSearch();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.search.status !== SearchStatus.LOADING &&
      this.props.search.status === SearchStatus.LOADING
    ) {
      this.setState(this.initialState);
    }

    if (
      prevProps.search.status !== SearchStatus.DONE &&
      this.props.search.status === SearchStatus.DONE
    ) {
      this.getInitialHotelSearch();
    }
  }

  getInitialHotelSearch = async () => {
    const { search } = this.props;

    const resultsSnap = await firebaseApp
      .getInitialHotelResults(search.searchCode)
      .get();

    if (resultsSnap.size === 0) {
      this.setState({
        ...this.initialState,
        isLoading: false,
        status: LoadSearchStatus.EMPTY,
      });

      this.props.setEmpty();
    }

    let newResults = [];
    let lastHotel = null;

    resultsSnap.forEach((resultSnap) => {
      const result = resultSnap.data();
      result.id = resultSnap.id;

      newResults.push(result);
      lastHotel = resultSnap;
    });

    const results = this.state.results.concat(newResults);

    this.setState({ isLoading: false, results, lastHotel });
  };

  getRemainingHotelSearch = async () => {
    const { search } = this.props;
    const { lastHotel } = this.state;

    this.setState({ isLoading: true });

    const resultsSnap = await firebaseApp
      .getRemainingHotelResults(search.searchCode, lastHotel)
      .get();

    let newResults = [];

    resultsSnap.forEach((resultSnap) => {
      const result = resultSnap.data();
      result.id = resultSnap.id;

      newResults.push(result);
    });

    const results = this.state.results.concat(newResults);

    this.setState({ results, isLoading: false, status: LoadSearchStatus.ALL });
  };

  // Render
  render() {
    const { results, status, isLoading } = this.state;
    const { search, params } = this.props;

    if (search.status === SearchStatus.NEW) return <div />;

    return (
      <PackageHotelResults
        results={results}
        params={params}
        search={search}
        status={status}
        isLoading={isLoading}
        getRemainingHotelSearch={this.getRemainingHotelSearch}
        changeHotel={this.props.changeHotel}
      />
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { user } = auth;
  return { user };
};

export default connect(mapStateToProps)(PackageHotelSearch);
