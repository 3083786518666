import classNames from 'classnames';
import React from 'react';
import { Row } from 'reactstrap';
import { Colxx } from '../common/CustomBootstrap';

export default function DefaultItineraryContainer({
  Icon,
  title,
  description,
  iconSize = 30,
  children,
  ItemCard,
  className,
}) {
  return (
    <div className={classNames('default-itinerary-container w-100', className)}>
      <Row className="mx-0">
        <Colxx xxs="4" className="d-flex ml-0 px-0 pr-2">
          <div className="flex-grow-1 py-4">
            <div className="d-flex align-items-center mb-3">
              <div
                className="icon-container bg-primary flex-center"
                style={{ width: iconSize, height: iconSize }}
              >
                {Icon ? (
                  <Icon
                    className="icon"
                    fill="white"
                    width="50%"
                    height="50%"
                  />
                ) : null}
              </div>
              <h2 className="font-primary fs-1-1rem m-0 p-0 ml-2 font-weight-medium title">
                {title}
              </h2>
            </div>
            <p className="description m-0 p-0 text-muted fs-0-8rem">
              {description}
            </p>
            {ItemCard ? <ItemCard /> : null}
          </div>
        </Colxx>
        <Colxx xxs="8" className="mr-0 px-0 pl-3">
          {children}
        </Colxx>
      </Row>
    </div>
  );
}
