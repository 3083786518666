import { useState } from 'react';

export default function useExecuteAction({
  defaultErrorMessage,
  blockOnLoading,
} = {}) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const executeAction = async (action) => {
    try {
      if (blockOnLoading && loading) return;

      setLoading(true);
      if (error) setError(null);

      return await action;
    } catch (err) {
      console.error(err);
      setError(defaultErrorMessage || err.message);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  return { executeAction, loading, error };
}
