import React, { PureComponent } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import AppLayout from '../../layout/AppLayout';
import analytics from './analytics';
import policy from './policy';
import users from './users';
import finance from './finance';
import Travels from './travels';
import Expenses from './expenses';
import integrations from './integrations';
import AdminRoute from '../../components/AdminRoute';
import Others from './others';

export default class App extends PureComponent {
  componentDidMount() {
    document.title = 'Portão 3 - Admin';
  }

  render() {
    const { match } = this.props;

    return (
      <AdminRoute>
        <AppLayout>
          <Switch>
            <Redirect
              exact
              from={`${match.url}/`}
              to={`${match.url}/payments`}
            />

            <Route exact path={`${match.url}/travels`} component={Travels} />
            <Route
              exact
              path={`${match.url}/travels/:tabId`}
              component={Travels}
            />
            <Route
              exact
              path={`${match.url}/travels/:tabId/:resourceId`}
              component={Travels}
            />

            <Route exact path={`${match.url}/payments`} component={Expenses} />
            <Route
              exact
              path={`${match.url}/payments/:tabId`}
              component={Expenses}
            />
            <Route
              exact
              path={`${match.url}/payments/:tabId/:resourceId`}
              component={Expenses}
            />

            <Route path={`${match.url}/analytics`} component={analytics} />

            <Route exact path={`${match.url}/management`} component={policy} />
            <Route
              exact
              path={`${match.url}/management/:tabId`}
              component={policy}
            />
            <Route
              exact
              path={`${match.url}/management/:tabId/:resourceId`}
              component={policy}
            />

            <Route exact path={`${match.url}/users`} component={users} />
            <Route
              exact
              path={`${match.url}/users/:userId`}
              component={users}
            />

            <Route exact path={`${match.url}/finance`} component={finance} />
            <Route
              exact
              path={`${match.url}/finance/:tabId`}
              component={finance}
            />
            <Route
              exact
              path={`${match.url}/finance/:tabId/summary/:orderId`}
              component={finance}
            />
            <Route
              exact
              path={`${match.url}/finance/:tabId/:resourceId`}
              component={finance}
            />

            <Route
              exact
              path={`${match.url}/integrations`}
              component={integrations}
            />
            <Route
              exact
              path={`${match.url}/integrations/:tabId`}
              component={integrations}
            />
            <Route
              exact
              path={`${match.url}/integrations/:tabId/:resourceId`}
              component={integrations}
            />

            <Route exact path={`${match.url}/others`} component={Others} />
            <Route
              exact
              path={`${match.url}/others/:tabId`}
              component={Others}
            />

            <Redirect to="/error" />
          </Switch>
        </AppLayout>
      </AdminRoute>
    );
  }
}
