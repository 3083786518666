import React from 'react';
import { Card, CardBody } from 'reactstrap';
import moment from 'moment';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import sumBy from 'lodash/sumBy';

import { SmallLineChart } from '../../charts';
import LoaderSmallLine from '../../loaders/LoaderSmallLine';

export default function ESGSMallLineKms({ orders, loading }) {
  const results = groupBy(orders, (o) =>
    moment.unix(o.filterTimestamp).format('DD/MM/YYYY')
  );

  if (loading) {
    return (
      <Card className="dashboard-small-chart">
        <CardBody>
          <LoaderSmallLine />
        </CardBody>
      </Card>
    );
  }

  return (
    <Card className="dashboard-small-chart">
      <CardBody>
        <SmallLineChart
          data={{
            labels: Object.keys(results),
            datasets: [
              {
                data: map(results, (orders) =>
                  sumBy(orders, (o) => parseInt(o.esg.distance_km) || 0)
                ),
                label: 'Kms viajados',
                borderColor: '#43418e',
                pointBorderColor: '#43418e',
                pointHoverBackgroundColor: '#43418e',
                pointHoverBorderColor: '#43418e',
                pointRadius: 2,
                pointBorderWidth: 3,
                pointHoverRadius: 2,
                fill: false,
                borderWidth: 2,
                datalabels: {
                  align: 'end',
                  anchor: 'end',
                },
              },
            ],
          }}
        />
      </CardBody>
    </Card>
  );
}
