import {
  CLEAN_SELECTED_REFUND,
  TOGGLE_CREATION_MODE,
  TOGGLE_SELECTED_REFUND,
} from '../actions';

const INITIAL_STATE = {
  selectedRefunds: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TOGGLE_CREATION_MODE: {
      const { value } = action.payload;
      return {
        ...state,
        creationMode: value !== undefined ? value : !state.creationMode,
      };
    }
    case TOGGLE_SELECTED_REFUND: {
      const { refund } = action.payload;

      if (refund.id in state.selectedRefunds) {
        const { [refund.id]: selectedRefund, ...others } =
          state.selectedRefunds;

        return {
          ...state,
          selectedRefunds: others,
        };
      } else
        return {
          ...state,
          selectedRefunds: { ...state.selectedRefunds, [refund.id]: refund },
        };
    }
    case CLEAN_SELECTED_REFUND: {
      return {
        ...state,
        selectedRefunds: {},
      };
    }
    default:
      return state;
  }
};
