import React, { useMemo, useState } from 'react';
import HotelModal from '../../../containers/search/HotelModal';
import HotelItineraryCard from '../cards/HotelItineraryCard';

import { useIntl } from 'react-intl';

import { ReactComponent as HotelIcon } from '../../../assets/img/icons/raw/hotel.svg';
import ResultsWrapper from './ResultsWrapper';
import DefaultItineraryContainer from '../DefaultItineraryContainer';
import SelectedHotelCard from '../selectionCards/SelectHotelCard';

export default function HotelItineraryResults({
  className,
  searchCode,
  search,
  params,
  results,
  loading,
  onSelectItem,
  selectedItem,
  usedRooms = {},
}) {
  const { messages } = useIntl();

  // States
  const [title, description] = useMemo(
    () => [
      messages['itinerary.hotel.title'],
      messages['itinerary.hotel.description'],
    ],
    []
  );

  const [selectedHotel, setSelectedHotel] = useState(null);

  const selectedHotelId = useMemo(
    () => (selectedItem ? selectedItem.item?.id : null),
    [selectedItem]
  );

  // Render
  const renderItemCard = () => (
    <SelectedHotelCard
      className="mt-4"
      item={selectedItem}
      onClick={() => setSelectedHotel(selectedItem?.item)}
    />
  );

  return (
    <>
      <DefaultItineraryContainer
        Icon={HotelIcon}
        title={title}
        description={description}
        ItemCard={renderItemCard}
        className={className}
      >
        <ResultsWrapper loading={loading} results={results}>
          {results.map((item) => (
            <HotelItineraryCard
              key={item.hotelId}
              item={item}
              selected={item.id === selectedHotelId}
              onClick={() => {
                setSelectedHotel(item);
              }}
            />
          ))}
        </ResultsWrapper>
      </DefaultItineraryContainer>

      {selectedHotel ? (
        <HotelModal
          toggleModal={() => setSelectedHotel(null)}
          item={selectedHotel}
          selectItem={onSelectItem}
          invalidRooms={usedRooms[selectedHotel.hotelId]}
          selectedRoom={selectedItem?.selectedRoom}
        />
      ) : null}
    </>
  );
}
