import { TRAVEL_LOCATION } from '../constants/locations';

export function getModalPath({
  item,
  additionalPath,
  rootPath = TRAVEL_LOCATION,
}) {
  const path = `${rootPath}/${item.id}`;
  return additionalPath ? `${path}/${additionalPath}` : path;
}

export function getApprovalModalPath(item, rootPath) {
  return getModalPath({
    item,
    additionalPath: 'approval',
    rootPath,
  });
}

export function getAlterationModalPath(item, rootPath) {
  return getModalPath({
    item,
    additionalPath: 'alteration',
    rootPath,
  });
}
