import React from 'react';
import { Button } from 'reactstrap';
import TopMessage from '../../TopMessage';

export default function FailedToReadInvoiceMessage({
  show,
  toggle = () => {},
}) {
  return (
    <TopMessage
      description="Não consegui ler os dados da nota fiscal. Tente novamente mais tarde!"
      show={show}
      toggle={toggle}
    >
      <Button onClick={toggle} color="primary" className="mt-2">
        Tudo bem!
      </Button>
    </TopMessage>
  );
}
