import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Button, Row } from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { stringToMoney } from '../../../../../../utils/money';
import Spinner from '../../../../../common/Spinner';
import BaggageSection from '../Items/BaggageSection';
import SeatSection from '../Items/SeatSection';
import { FLIGHT_GORDIAN_TABS } from '../index';

import { ReactComponent as EmptyImg } from '../../../../../../assets/img/config/emptyResults_light.svg';
import { Colxx } from '../../../../../common/CustomBootstrap';
import AlterationFrame from '../../../AlterationFrame';

const GORDIAN_BTN_IDS = {
  SEAT: 'gordian-seat-selection',
  BAG: 'gordian-bag-selection',
};

export default function InitialTab({
  basket,
  loading,
  total,
  goForInitial,
  setCurrentTab,
  availableProducts,
}) {
  const { messages } = useIntl();

  const formattedTotal = useMemo(() => stringToMoney(total, 2), [total]);

  const hasAvailableProducts = useMemo(
    () => Object.values(availableProducts).some((value) => value),
    [availableProducts]
  );

  const items = useMemo(() => Object.values(basket.basket || {}), [basket]);
  const subtitle = useMemo(
    () =>
      messages[
        loading
          ? 'travel.ancillaries.subtitle.loading'
          : 'travel.ancillaries.subtitle'
      ],
    [loading]
  );

  // Functions
  const handleSectionClick = (btnId) => {
    if (btnId) {
      const btn = document.getElementById(btnId);
      if (btn) {
        btn.click();
      }
    }
  };

  const openSupport = () => {
    if (window.zE) {
      window.zE('messenger', 'open');
    }
  };

  // Render
  const renderEmpty = () => {
    return (
      <Row className="mt-4">
        <Colxx xxs="5">
          <EmptyImg width="100%" />
        </Colxx>
        <Colxx xxs="7">
          <h4 className="m-0 font-primary mb-2" style={{ fontSize: '1rem' }}>
            {messages['flight-alteration.unavailable.title']}
          </h4>
          <p className="m-0" style={{ opacity: 0.5, fontSize: '0.8rem' }}>
            {messages['flight-alteration.unavailable.description']}
          </p>
          <Button onClick={openSupport} style={{ fontSize: '0.7rem' }}>
            {messages['flight-alteration.unavailable.btn']}
          </Button>
        </Colxx>
      </Row>
    );
  };
  const renderContent = () => (
    <PerfectScrollbar
      options={{
        suppressScrollX: true,
        wheelPropagation: false,
      }}
      className="d-flex flex-wrap align-items-start"
    >
      {hasAvailableProducts ? (
        <>
          {availableProducts.seat ? (
            <SeatSection
              className="mr-3 mb-3 show-up"
              onClick={() => handleSectionClick(GORDIAN_BTN_IDS.SEAT)}
              basket={basket}
              items={items}
            />
          ) : null}
          {availableProducts.bag ? (
            <BaggageSection
              className="mb-3 show-up"
              onClick={() => handleSectionClick(GORDIAN_BTN_IDS.BAG)}
              basket={basket}
              items={items}
              style={{ animationDelay: '50ms' }}
            />
          ) : null}
        </>
      ) : (
        renderEmpty()
      )}
    </PerfectScrollbar>
  );

  const renderBottom = () => (
    <div className="d-flex align-items-center justify-content-between flex-shrink-0">
      {total > 0 ? (
        <div className="price-container">
          <p
            className="m-0 p-0 one-line-height title font-weight-semibold mb-1"
            style={{ fontSize: '0.7rem' }}
          >
            {messages['general.total']}
          </p>
          <p className="m-0 p-0 one-line-height total">{formattedTotal}</p>
        </div>
      ) : (
        <div />
      )}

      <div>
        <Button color="link" onClick={goForInitial} className="m-0">
          {messages['general.go-back']}
        </Button>
        {!loading && hasAvailableProducts ? (
          <Button
            color="primary"
            disabled={total === 0}
            className="m-0"
            onClick={() => setCurrentTab(FLIGHT_GORDIAN_TABS.CHECKOUT)}
          >
            {messages['general.done']}
          </Button>
        ) : null}
      </div>
    </div>
  );

  return (
    <AlterationFrame
      title={messages['travel.ancillaries.tab.title']}
      subtitle={subtitle}
      BottomBar={renderBottom}
    >
      {loading ? (
        <div className="d-flex align-items-center justify-content-center w-100 h-100">
          <Spinner relative white style={{ width: 60, height: 60 }} />
        </div>
      ) : (
        renderContent()
      )}
    </AlterationFrame>
  );
}
