import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Row, Modal, ModalBody, ModalFooter } from 'reactstrap';
import classnames from 'classnames';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Colxx } from '../common/CustomBootstrap';
import filter from 'lodash/filter';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';

import JourneyLarge from '../flight/JourneyLarge';
import JourneySmall from '../flight/JourneySmall';
import FlightCarbonDetail from '../flight/FlightCarbonDetail';

import firebaseApp from '../../services/Firebase';
import ReservationParams from './ReservationParams';
import { useIntl } from 'react-intl';
import { useMemo } from 'react';
import { registeredAirlines } from '../../constants/travelValues';
import Footer from './Footer';
import moment from 'moment';
import OrderIssueCard from '../order/OrderIssueCard';
import orderStatus from '../../constants/orderStatus';
import useModalStages from '../../hooks/useModalStages';
import { shouldShowOpenBookingSteps } from '../../utils/openBooking';
import Comment from '../expensesTimeline/Comment';
import useLocationMapping from '../../hooks/useLocationMapping/useLocationMapping';
import { canApproveReservation } from '../../utils/orders';

let snapshotApprovals = () => {};
let snapshotAncillaries = () => {};

export default function FlightModal({ toggleModal, order, path }) {
  const { user } = useSelector((state) => state.auth);
  const { messages } = useIntl();

  // States
  const {
    isApproving,
    toggleIsApproving,
    setIsApproving,
    isAltering,
    toggleIsAltering,
    setIsAltering,
    isShowingOpenBookingSteps,
    toggleIsShowingOpenBookingSteps,
  } = useModalStages({
    isShowingOpenBookingSteps: shouldShowOpenBookingSteps(order),
    item: order,
    path,
  });

  const [loading, setLoading] = useState(true);

  const [reservations, setReservations] = useState([]);
  const [approvals, setApprovals] = useState([]);
  const [ancillaries, setAncillaries] = useState(null);

  const [bookingCodes, canAlter] = useMemo(() => {
    const orderBookingCode = order.bookingCode,
      bookingCodes = {};
    let canAlter = false;

    reservations.forEach(({ flightDetails }) => {
      const { bookingCode } = flightDetails;

      if (bookingCode && orderBookingCode !== bookingCode) {
        const registeredAirline =
          registeredAirlines[flightDetails.airlineOperating];

        bookingCodes[bookingCode] = `${
          registeredAirline?.name || flightDetails.airlineOperating
        }: ${bookingCode}`;
      }
    });

    const lastReservationTimestamp = reservations.reduce(
      (timestamp, reservation) => {
        const reservationTimestamp = moment(
          reservation.flightDetails.arrivalDateTime,
          'DD/MM/YYYY HH:mm'
        ).unix();
        return reservationTimestamp > timestamp
          ? reservationTimestamp
          : timestamp;
      },
      0
    );

    if (!order.ongoingAction && lastReservationTimestamp > moment().unix())
      canAlter = true;

    return [Object.values(bookingCodes), canAlter];
  }, [order, reservations]);

  const { seat, bag } = useMemo(() => {
    return ancillaries
      ? ancillaries.items.reduce((obj, item) => {
          obj[item.type] = item;
          return obj;
        }, {})
      : {};
  }, [ancillaries]);

  const sortedSeats = useMemo(() => {
    return seat
      ? seat.items.reduce((obj, item) => {
          obj[item.relatedTo[0]] = item;
          return obj;
        }, {})
      : {};
  }, [seat]);

  // Hooks
  useLocationMapping(
    {
      paths: {
        approval: {
          onMatch: () => setIsApproving(true),
          validate: () => canApproveReservation({ approvals, user }),
          onFail: () => toggleIsApproving(false),
        },
        alteration: {
          onMatch: () => setIsAltering(true),
          validate: () => order.statusCode === orderStatus.ISSUED,
          onFail: () => toggleIsAltering(false),
        },
      },
    },
    [approvals, user, order]
  );

  // Effects
  useEffect(() => {
    loadData();

    return () => {
      snapshotApprovals();
    };
  }, []);

  useEffect(() => {
    if (order?.gordian?.purchased) {
      loadAncillaries();

      return () => {
        snapshotAncillaries();
      };
    }
  }, [order]);

  // Functions
  const loadData = async () => {
    await Promise.all([loadApprovals(), loadReservationsFromPassenger()]);

    setLoading(false);
  };

  const loadReservationsFromPassenger = async () => {
    const reservationsSnapshot = await firebaseApp
      .getReservationsofOrderIdFromPassengerId(
        order.id,
        order.passengerId,
        user.organizationId
      )
      .get();

    const reservations = [];
    reservationsSnapshot.forEach((reservationSnapshot) => {
      reservations.push({
        ...reservationSnapshot.data(),
        id: reservationSnapshot.id,
      });
    });

    setReservations(sortBy(reservations, 'flightDetails.flightIndex'));
  };

  const loadApprovals = async () => {
    snapshotApprovals = firebaseApp
      .getApprovalsFromOrderId(order?.id)
      .onSnapshot(function (querySnapshot) {
        const approvals = [];

        querySnapshot.forEach(function (doc) {
          approvals.push({
            id: doc.id,
            ...doc.data(),
          });
        });

        setApprovals(approvals.reverse());
      });
  };

  const loadAncillaries = async () => {
    snapshotAncillaries = firebaseApp
      .getBookedAncillariesFromOrder(order?.id)
      .onSnapshot((querySnapshot) => {
        const ancillaries = [];
        querySnapshot.forEach((docRef) => {
          ancillaries.push({
            ...docRef.data(),
            id: docRef.id,
          });
        });
        setAncillaries(ancillaries[0]);
      });
  };

  // Render
  const renderReservationsLocalizations = () => {
    return (
      <div>
        {bookingCodes.map((bookingCode, index) => (
          <p className="p-0 m-0 fs-0-6rem" key={`${bookingCode}-${index}`}>
            {bookingCode}
          </p>
        ))}
      </div>
    );
  };

  if (loading === true) {
    return (
      <Modal isOpen={true} toggle={toggleModal} className="reservation small">
        <ModalBody>
          <div className="loading p-relative" />
        </ModalBody>
      </Modal>
    );
  }

  return (
    <>
      <Modal
        isOpen={true}
        toggle={toggleModal}
        className={classnames({
          reservation: true,
          small: true,
          approving: isApproving || isAltering || isShowingOpenBookingSteps,
        })}
      >
        <ModalBody className="flight">
          <Row>
            <Colxx xxs="12">
              <div className="flight-content">
                <PerfectScrollbar
                  options={{
                    suppressScrollX: true,
                    wheelPropagation: false,
                  }}
                  className="position-relative"
                >
                  <div>
                    <h5 className="mt-4 ml-3">
                      {messages['components.timeline.modal.reservation-data']}
                    </h5>
                    <ReservationParams order={order} />
                  </div>

                  {order.comment ? (
                    <div className="mx-3 comments">
                      <h5 className="mt-4">
                        {messages['refund.modal.refund.comments']}
                      </h5>
                      <Comment comment={order.comment} />
                    </div>
                  ) : null}

                  <div className="mt-5">
                    {order.journeys &&
                      map(order.journeys, (journey, index) => {
                        if (isApproving) {
                          return (
                            <JourneySmall
                              key={`journey-${index}`}
                              journey={journey}
                              index={index}
                              params={{}}
                              flights={map(
                                filter(reservations, (reservation) => {
                                  return (
                                    reservation.flightDetails.journeyId ===
                                    journey.id
                                  );
                                }),
                                (o) => {
                                  return o.flightDetails;
                                }
                              )}
                            />
                          );
                        } else {
                          return (
                            <JourneyLarge
                              key={`journey-${index}`}
                              journey={journey}
                              index={index}
                              params={{}}
                              seat={sortedSeats}
                              bag={bag}
                              flights={map(
                                filter(reservations, (reservation) => {
                                  return (
                                    reservation.flightDetails.journeyId ===
                                    journey.id
                                  );
                                }),
                                (o) => {
                                  return { ...o.flightDetails, id: o.id };
                                }
                              )}
                              openBooking={order.openBooking}
                            />
                          );
                        }
                      })}
                  </div>

                  <FlightCarbonDetail esg={order.esg} />

                  <OrderIssueCard
                    show={isApproving}
                    order={order}
                    reservations={reservations}
                  />
                </PerfectScrollbar>
              </div>
            </Colxx>
          </Row>
        </ModalBody>

        <ModalFooter className="p-3">
          <Footer
            order={order}
            approvals={approvals}
            goForApproval={() => toggleIsApproving()}
            toggleModal={toggleModal}
            reservations={reservations}
            goForAlteration={() => toggleIsAltering()}
            isApproving={isApproving}
            isAltering={isAltering}
            isShowingOpenBookingSteps={isShowingOpenBookingSteps}
            goForOpenBookingSteps={toggleIsShowingOpenBookingSteps}
            canAlter={canAlter}
            DetailsComponent={renderReservationsLocalizations}
          />
        </ModalFooter>
      </Modal>
      <div id="flight-gordian-container" />
    </>
  );
}
