import React, { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Button } from 'reactstrap';
import classNames from 'classnames';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { registeredAirlines } from '../../../constants/travelValues';
import { ReactComponent as OneWayIcon } from '../../../assets/img/icons/oneway.svg';
import CustomAnimatedCheckbox from '../../common/CustomCheckbox';
import { getDuration } from '../../../utils/others';
import CurrencyWrapper from '../../common/CurrencyWrapper';

function FlightSegment({ segment, onSelect, selected }) {
  const { departureTime, arrivalTime, duration, numStops } = useMemo(() => {
    const { departureDateTime, arrivalDateTime, flights } = segment;

    const departureTime = moment(departureDateTime);
    const arrivalTime = moment(arrivalDateTime);
    const duration = getDuration(departureTime, arrivalTime);

    return {
      departureTime: departureTime.format('HH:mm'),
      arrivalTime: arrivalTime.format('HH:mm'),
      numStops: flights.length - 1,
      duration: `${duration.hours}h ${duration.minutes
        .toString()
        .padStart(2, '0')}m`,
    };
  }, [segment]);

  // Render
  return (
    <div className="flight-segment flex-between mb-3">
      <CustomAnimatedCheckbox
        checked={selected}
        onChange={onSelect}
        purple
        size="1.4rem"
        className="flex-shrink-0"
      />
      <div className="flex-between flex-grow-1 mx-3">
        <h4 className="departure time font-primary">{departureTime}</h4>
        <div className="stops flex-grow-1">
          <div className="line" />
          {numStops ? (
            <div className="number-stops flex-center">
              <p className="m-0 p-0 lh-1 fs-0-6rem white font-weight-medium">
                {numStops}
              </p>
            </div>
          ) : null}
        </div>
        <h4 className="arrival time font-primary">{arrivalTime}</h4>
      </div>
      <p className="m-0 p-0 fs-0-7rem text-muted duration">{duration}</p>
    </div>
  );
}

export default function FlightItineraryCard({
  item,
  onClick,
  selected,
  journey = 0,
  roundTrip = true,
  onSelectItem,
  hasSelected,
}) {
  const { messages } = useIntl();

  // States
  const {
    departureStation,
    arrivalStation,
    formattedDepartureDate,
    segments,
    total,
    airlineLogo,
  } = useMemo(() => {
    const { journeys } = item;

    const { departureStation, departureDate, arrivalStation, segments } =
      journeys[journey];

    const airline = registeredAirlines[item.airline];

    return {
      departureStation,
      departureDate,
      formattedDepartureDate: moment(departureDate, 'YYYY-MM-DD').format(
        'ddd DD MMM YYYY'
      ),
      arrivalStation,
      segments,
      total: roundTrip ? item.fare.total / 2 : item.fare.total,
      airlineLogo: airline?.logo ? `url(${airline.logo})` : null,
    };
  }, [item, journey, roundTrip]);

  const [selectedSegment, setSelectedSegment] = useState();

  // Functions
  const onSegmentSelection = (segment) => {
    setSelectedSegment(segment);
    onSelectItem(segment);
  };

  // Render
  return (
    <div
      className={classNames(
        'd-flex flex-column justify-content-between position-relative itinerary-card flight p-3',
        { selected, shrink: hasSelected && !selected }
      )}
    >
      <div className="d-flex align-items-center justify-content-between flex-shrink-0">
        <div>
          <div className="d-flex align-items-center">
            <h3 className="m-0 p-0 fs-1rem font-primary text-primary">
              {departureStation}
            </h3>
            <OneWayIcon width={15} height={15} className="text-primary mx-1" />
            <h3 className="m-0 p-0 fs-1rem font-primary text-primary">
              {arrivalStation}
            </h3>
          </div>
          <p className="m-0 p-0 fs-0-7rem text-muted lh-1 mt-1">
            {formattedDepartureDate}
          </p>
        </div>
        {airlineLogo ? (
          <div
            className="carrier"
            style={{
              backgroundImage: airlineLogo,
            }}
          />
        ) : null}
      </div>
      <div className="flex-grow-1 flex-shrink-1 py-4 mh-0 mw-0">
        <PerfectScrollbar
          options={{
            suppressScrollX: true,
            useBothWheelAxes: false,
          }}
          className="flex-grow-1"
        >
          {segments.map((segment, index) => (
            <FlightSegment
              key={`segment=${index}`}
              segment={segment}
              onSelect={() => onSegmentSelection(index)}
              selected={selected && index === selectedSegment}
            />
          ))}
        </PerfectScrollbar>
      </div>
      <div className="d-flex align-items-center justify-content-between flex-shrink-0">
        <div>
          <p className="m-0 font-primary one-line-height medium fs-0-6rem">
            {messages['general.total']}
          </p>
          <h3 className="m-0 font-primary text-dark font-weight-medium one-line-height mt-1 fs-1rem">
            <CurrencyWrapper
              value={total}
              decimalPlaces={0}
              tooltipFill="black"
            >
              {({ formattedValue }) => formattedValue}
            </CurrencyWrapper>
          </h3>
        </div>
        <Button
          size="sm"
          className="fs-0-7rem"
          color="primary"
          onClick={onClick}
          disabled={!selected}
        >
          {messages['general.view']}
        </Button>
      </div>
    </div>
  );
}
