import firebaseApp from '../../services/Firebase';
import { setupList } from '../../constants/checklists';

export const setupItemsIdentifiers = setupList.items;

export async function getSetupValidators(organizationId) {
  const organizationRef = firebaseApp.getOrganizationFromId(organizationId);

  const reservationsPromise = firebaseApp
    .getReservationsFromRequestorId(organizationId)
    .limit(1)
    .get();

  const costCentersPromise = organizationRef
    .collection('cost_centers')
    .limit(1)
    .get();
  const creditCardPromise = organizationRef
    .collection('credit_cards')
    .limit(1)
    .get();

  const usersPromise = firebaseApp
    .getUsersFromOrganization(organizationId)
    .limit(2)
    .get();

  return {
    [setupItemsIdentifiers.search]: (await reservationsPromise).size > 0,
    [setupItemsIdentifiers.creditCard]: !(await creditCardPromise).empty,
    [setupItemsIdentifiers.costCenter]: !(await costCentersPromise).empty,
    [setupItemsIdentifiers.invite]: (await usersPromise).size > 1,
  };
}

export function createSetupChecklist(validator = {}) {
  const { search, creditCard, costCenter, invite } = setupItemsIdentifiers;

  const items = {
    [search]: {
      done: validator[search],
    },
    [creditCard]: {
      done: validator[creditCard],
    },
    [costCenter]: {
      done: validator[costCenter],
    },
    [invite]: {
      done: validator[invite],
    },
  };

  return {
    items,
    completed: Object.values(validator).every((value) => value),
  };
}
