import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useParams, useHistory } from 'react-router-dom';
import {
  Card,
  Label,
  CustomInput,
  Row,
  Button,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { Colxx } from '../../components/common/CustomBootstrap';
import classnames from 'classnames';
import map from 'lodash/map';
import mouseTrap from 'mousetrap';

import IntlMessages from '../../helpers/IntlMessages';

import firebaseApp from '../../services/Firebase';
import { useIntl } from 'react-intl';
import useDataSnapshot from '../../hooks/useDataSnapshot';
import EmptyWrapper from '../../wrappers/EmptyWrapper';
import BudgetModal from '../../components/policy/BudgetModal';
import { BudgetCategoriesIcons } from '../../constants/budget';
import { stringToMoney } from '../../utils/money';
import { hashBudget } from '../../utils/budget';
import { getOrganizationConfig } from '../../utils/organization';
import BudgetCategoryDetails from '../../components/policy/BudgetCategoryDetails';
import KmDataListView from './KmDataListView';

export default function BudgetDataListView({}) {
  const { resourceId } = useParams();
  const history = useHistory();
  const { user, organization } = useSelector((state) => state.auth);
  const { messages, formatMessage } = useIntl();

  const [selectedItems, setSelectedItems] = useState([]);
  const [dropdownSplitOpen, setDropdownSplitOpen] = useState(false);

  const [budgets, _, isLoading] = useDataSnapshot({
    fetchFunction: () =>
      firebaseApp.getBudgetsFromOrganization(user.organizationId),
  });

  const isEmpty = useMemo(() => !budgets || budgets.length === 0, [budgets]);

  const hashedBudgets = useMemo(
    () =>
      budgets.reduce((obj, { id, product, rules, specificity }) => {
        obj[
          hashBudget(
            product,
            specificity,
            rules.map(({ category }) => category)
          )
        ] = id;

        return obj;
      }, {}),
    [budgets]
  );

  useEffect(() => {
    mouseTrap.bind(['ctrl+a', 'command+a'], () => {
      setSelectedItems(map(budgets, (x) => x.id));
      return false;
    });

    mouseTrap.bind(['ctrl+d', 'command+d'], () => {
      setSelectedItems([]);
      return false;
    });

    return () => {
      mouseTrap.unbind('ctrl+a');
      mouseTrap.unbind('command+a');
      mouseTrap.unbind('ctrl+d');
      mouseTrap.unbind('command+d');
    };
  }, [budgets]);

  // Handle Cost Centers
  const deleteCostCenters = async () => {
    for (let i = 0; i < selectedItems.length; i++) {
      await firebaseApp.deleteRefundBudget(
        user.organizationId,
        selectedItems[i]
      );
    }

    // trackPropertiesSegment('Cost center deleted', {
    //   budgets: selectedItems,
    //   user,
    // });

    setSelectedItems([]);
  };

  // Split
  const toggleSplit = () => {
    setDropdownSplitOpen(!dropdownSplitOpen);
  };

  // Selection
  const onCheckItem = (id) => {
    if (selectedItems.includes(id)) {
      setSelectedItems(selectedItems.filter((x) => x !== id));
    } else {
      setSelectedItems([...selectedItems, id]);
    }
  };

  const handleChangeSelectAll = () => {
    if (selectedItems.length >= budgets.length) {
      setSelectedItems([]);
    } else {
      setSelectedItems(map(budgets, (x) => x.id));
    }
  };

  // Modal
  const openModal = async (budgetId) => {
    // trackPropertiesSegment('Group modal opened', { user });
    history.push(`/app/payments/budget/${budgetId}`);
  };

  const closeModal = () => {
    // trackPropertiesSegment('Group modal closed', { user });
    history.push(`/app/payments/budget`);
  };

  const renderBudgetRules = (id, rules = []) => {
    return (
      <div className="budget-rules d-flex">
        {rules.map((rule, index) => {
          const category = rule.category.toUpperCase();
          const name = messages[`budget.category.${category}`];

          return (
            <div
              className="budget-rule d-flex justify-content-center align-items-center mr-5"
              key={`${id}-${index}`}
            >
              <img
                src={BudgetCategoriesIcons[category]}
                alt={name}
                title={name}
                width={25}
                height={25}
              />
              <span className="text-one text-dark ml-2">
                {stringToMoney(
                  rule.price,
                  2,
                  getOrganizationConfig(organization.config, 'currency')
                )}
              </span>
            </div>
          );
        })}
      </div>
    );
  };

  // Loading
  if (isLoading) {
    return <div className="loading" />;
  }

  return (
    <div>
      <EmptyWrapper
        isEmpty={isEmpty}
        messages={{
          title: messages['admin.refunds.empty.budget.title'],
          description: messages['admin.refunds.empty.budget.description'],
          button: messages['admin.refunds.empty.budget.btn'],
        }}
        onClick={() => openModal('new')}
      >
        <Row>
          <Colxx xxs="12" className="mt-5">
            <Button
              color="primary"
              className="m-0 pretty-btn"
              onClick={() => {
                openModal('new');
              }}
            >
              {messages['general.add']}
            </Button>

            <ButtonDropdown
              isOpen={dropdownSplitOpen}
              toggle={toggleSplit}
              className="float-right"
            >
              <div className="btn btn-primary btn-lg pl-4 pr-0 check-button check-all">
                <CustomInput
                  className="custom-checkbox mb-0 d-inline-block"
                  type="checkbox"
                  id="checkAll"
                  checked={selectedItems.length >= budgets.length}
                  onChange={handleChangeSelectAll}
                  label={
                    <span
                      className={`custom-control-label ${
                        selectedItems.length > 0 &&
                        selectedItems.length < budgets.length
                          ? 'indeterminate'
                          : ''
                      }`}
                    />
                  }
                />
              </div>
              <DropdownToggle
                caret
                color="primary"
                className="dropdown-toggle-split btn-lg"
              />
              <DropdownMenu right>
                <DropdownItem onClick={deleteCostCenters}>
                  <IntlMessages id="admin.policy.delete" />
                </DropdownItem>
              </DropdownMenu>
            </ButtonDropdown>
          </Colxx>
        </Row>
        <Row className="mt-4">
          {map(budgets, (budget, index) => {
            return (
              <Colxx key={`budget-${index}`} xxs="12" className="mb-3">
                <Card
                  className={classnames('d-flex flex-row list', {
                    active: selectedItems.includes(budget.id),
                  })}
                >
                  <div className="pl-2 d-flex flex-grow-1 mw-0">
                    <div className="card-body align-self-center d-flex flex-column flex-lg-row justify-content-between mw-0 align-items-lg-center">
                      <div className="w-40 w-sm-100">
                        <p className="list-item-heading m-0 mb-1 truncate">
                          <NavLink to={`/app/payments/budget/${budget.id}`}>
                            {messages[`travel.menu.${budget.product}`]}
                          </NavLink>
                        </p>
                        {budget.specificity && (
                          <div className="d-flex align-items-center">
                            <p className="m-0 text-muted mr-2">
                              {formatMessage(
                                { id: 'budget.aplliedTo' },
                                {
                                  type: `${
                                    messages[
                                      `budget.specificity.${budget.specificity.type}`
                                    ]
                                  }`.toLowerCase(),
                                }
                              )}
                            </p>
                            <BudgetCategoryDetails
                              id={budget.id}
                              categories={budget.specificity.values}
                            />
                          </div>
                        )}
                      </div>
                      <div className="w-15 w-sm-100 bg-primary"></div>
                    </div>
                    {renderBudgetRules(budget.id, budget.rules)}
                    <div className="custom-control custom-checkbox pl-1 align-self-center pr-4">
                      <Label>
                        <CustomInput
                          className="item-check mb-0"
                          type="checkbox"
                          id={`check_${budget.id}`}
                          checked={selectedItems.includes(budget.id)}
                          onChange={() => onCheckItem(budget.id)}
                        />
                      </Label>
                    </div>
                  </div>
                </Card>
              </Colxx>
            );
          })}
        </Row>
      </EmptyWrapper>

      <hr className="my-5" />

      <div className="mb-5">
        <h3>{messages['admin.refunds.km']}</h3>
        <KmDataListView />
      </div>

      {resourceId && (
        <BudgetModal
          toggleModal={closeModal}
          budgetId={resourceId}
          hashedBudgets={hashedBudgets}
        />
      )}
    </div>
  );
  x;
}
