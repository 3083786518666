import React, { useMemo } from 'react';
import SelectWrapper from './SelectWrapper';
import moment from 'moment';

import { ReactComponent as OneWayIcon } from '../../../assets/img/icons/oneway.svg';
import { registeredAirlines } from '../../../constants/travelValues';

export default function SelectedFlightCard({ className, item, onClick }) {
  const {
    departureStation,
    departureDate,
    arrivalStation,
    arrivalDate,
    airlineLogo,
    date,
  } = useMemo(() => {
    if (!item) return {};

    const { item: selectedItem, selectedJourney, selectedSegment } = item;

    const { departureStation, arrivalStation, segments } =
      selectedItem.journeys[selectedJourney];
    const { departureDateTime, arrivalDateTime } = segments[selectedSegment];

    const convertedDepartureDate = moment(departureDateTime);

    const airline = registeredAirlines[selectedItem.airline];

    return {
      departureStation,
      departureDate: convertedDepartureDate.format('HH:mm'),
      arrivalStation,
      arrivalDate: moment(arrivalDateTime).format('HH:mm'),
      airlineLogo: airline?.logo ? `url(${airline.logo})` : null,
      date: convertedDepartureDate.format('ddd DD MMM YYYY'),
    };
  }, [item]);

  // Render
  const renderItem = () => (
    <div
      className="itinerary-selected-card flight pointer flex-center"
      onClick={onClick}
    >
      <div
        className="carrier"
        style={{
          backgroundImage: airlineLogo,
        }}
      />
      <div className="ml-5">
        <div className="d-flex align-items-center">
          <div className="text-center">
            <h3 className="m-0 p-0 fs-1rem font-primary text-primary">
              {departureStation}
            </h3>
            <p className="m-0 p-0 fs-0-8rem lh-1 mt-1">{departureDate}</p>
          </div>
          <OneWayIcon width={20} height={20} className="text-primary mx-3" />
          <div className="text-center">
            <h3 className="m-0 p-0 fs-1rem font-primary text-primary">
              {arrivalStation}
            </h3>
            <p className="m-0 p-0 fs-0-8rem lh-1 mt-1">{arrivalDate}</p>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <SelectWrapper className={className} item={item}>
      {item ? renderItem() : null}
    </SelectWrapper>
  );
}
