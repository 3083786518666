import React, { useEffect, useState, useRef } from 'react';
import { ChecklistItem, setChecklistComplete } from '.';
import { ReactComponent as DropdownIcon } from '../../assets/img/icons/dropdown_arrow_bold.svg';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import lottie from 'lottie-web';

import ExplosionAnimation from '../../assets/lottie/explosion.json';
import useBlocker from '../../hooks/useBlocker';

export default function Checklist(props) {
  const {
    id: checklistId,
    items,
    links,
    onCheck = () => {},
    itemHeight = 40,
    maxItemsPerPage = 4,
    allowCheck = false,
    show = false,
    allowUncheck = false,
    onCompletion = () => {},
    showHelp = true,
    helpFunction = () => {
      if ((window, zE)) {
        window.zE('messenger', 'open');
      }
    },
  } = props;

  const { messages } = useIntl();
  const messagesPrefix = `checklist.${checklistId}`;

  // States
  const [isShowing, setIsShowing] = useState(show);
  const [itemKeys, setItemKeys] = useState([]);
  const [isComplete, setIsComplete] = useState(false);
  const history = useHistory();

  const lottieContainerRef = useRef(null);

  const [blockerFunction, updateTime] = useBlocker();

  // Effects
  useEffect(() => {
    const keys = Object.keys(items);
    setItemKeys(keys);

    if (!isComplete && keys.every((id) => items[id].done)) {
      setChecklistComplete(checklistId);
      setIsComplete(true);
      onCompletion();
    }
  }, [items]);

  useEffect(() => {
    if (isShowing !== show) setIsShowing(show);
  }, [show]);

  useEffect(() => {
    if (isComplete && lottieContainerRef.current) {
      setTimeout(
        () =>
          lottie.loadAnimation({
            container: lottieContainerRef.current,
            animationData: ExplosionAnimation,
            autoplay: true,
            loop: false,
          }),
        400
      );
    }
  }, [isComplete]);

  // Functions
  const handleItemClick = (url = '#') => {
    if (url !== '#') history.push(url);
  };

  const handleChecklistShowToggle = () => {
    if (isShowing) {
      blockerFunction(() => setIsShowing(false));
    } else {
      updateTime();
      setIsShowing(true);
    }
  };

  const onHelpClick = () => {
    handleChecklistShowToggle();
    helpFunction();
  };

  const numShownItems =
    itemKeys.length > maxItemsPerPage ? maxItemsPerPage : itemKeys.length;

  // Render
  return (
    <div className={`checklist-container ${isComplete ? 'complete' : ''}`}>
      {isComplete && (
        <div ref={lottieContainerRef} className="lottie-container"></div>
      )}

      <div
        className={`checklist ${isShowing && 'showing'} ${
          !allowCheck && 'uncheckable'
        }`}
      >
        <div className="top-container" onClick={handleChecklistShowToggle}>
          <h1 className="checklist-title">{messages[messagesPrefix]}</h1>
          <DropdownIcon className="dropdown-icon" />
        </div>
        <div
          className="checklist-items d-flex flex-column"
          style={
            isShowing
              ? {
                  height: `${
                    itemHeight * numShownItems + (showHelp ? 30 : -10)
                  }px`,
                }
              : { height: 0 }
          }
        >
          <PerfectScrollbar
            options={{ suppressScrollX: true, useBothWheelAxes: false }}
            style={{ maxHeight: '110px' }}
          >
            {itemKeys.map((id, index) => {
              const { done } = items[id];

              return (
                <ChecklistItem
                  title={messages[`${messagesPrefix}.${id}`]}
                  done={done}
                  key={id}
                  style={{ animationDelay: `${index * 100 + 250}ms` }}
                  handleCheck={
                    allowCheck && (allowUncheck || !done)
                      ? () => {
                          onCheck(id);
                        }
                      : () => {}
                  }
                  handleItemClick={() => handleItemClick(links[id])}
                  strike
                />
              );
            })}
          </PerfectScrollbar>
          {showHelp && (
            <div
              className="help align-self-end"
              style={{ transitionDelay: `${(numShownItems + 1) * 120}ms` }}
              onClick={onHelpClick}
            >
              {messages['checklist.help']}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
