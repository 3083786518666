import { FILTER_TYPES } from '../../../constants/filters';
import { OrderTypes } from '../../../constants/orderTypes';

export const TYPES_OF_FILTER = {
  SELECT: 0,
  RANGE: 1,
  MULTIPLE: 2,
  BINARY: 3,
};

export const getTypesOfFilter = (filter) =>
  filter.reduce((currentObj, value) => {
    currentObj[value.name] = value.type;
    return currentObj;
  }, {});

export const GENERAL_FILTERS = [
  {
    name: 'passengerName',
    type: TYPES_OF_FILTER.SELECT,
  },
  {
    name: 'price',
    type: TYPES_OF_FILTER.RANGE,
  },
  {
    name: 'costCenter',
    type: TYPES_OF_FILTER.SELECT,
  },
  {
    name: 'project',
    type: TYPES_OF_FILTER.SELECT,
  },
  {
    name: 'date',
    type: TYPES_OF_FILTER.RANGE,
  },
  {
    name: 'statusCode',
    type: TYPES_OF_FILTER.SELECT,
    message: 'reports.filter.status-code',
    filter: [150],
  },
  {
    name: 'approvers',
    type: TYPES_OF_FILTER.MULTIPLE,
  },
];

export const GENERAL_FILTER_TYPES = getTypesOfFilter(GENERAL_FILTERS);

export const PRODUCT_SPECIFIC_FILTERS = {
  [OrderTypes.FLIGHT]: [
    {
      name: 'departureStation',
      type: TYPES_OF_FILTER.MULTIPLE,
    },
    {
      name: 'arrivalStation',
      type: TYPES_OF_FILTER.MULTIPLE,
    },
    { name: 'roundTrip', type: TYPES_OF_FILTER.BINARY },
  ],
  [OrderTypes.HOTEL]: [
    {
      name: 'category',
      type: TYPES_OF_FILTER.RANGE,
    },
    {
      name: 'city',
      type: TYPES_OF_FILTER.SELECT,
    },
    {
      name: 'chain',
      type: TYPES_OF_FILTER.SELECT,
    },
    {
      name: 'hotelName',
      type: TYPES_OF_FILTER.SELECT,
    },
  ],
  [OrderTypes.CAR]: [
    { name: 'carName', type: TYPES_OF_FILTER.SELECT },
    { name: 'rental', type: TYPES_OF_FILTER.SELECT },
    { name: 'airConditioning', type: TYPES_OF_FILTER.BINARY },
    { name: 'baggageSize', type: TYPES_OF_FILTER.RANGE },
    { name: 'doors', type: TYPES_OF_FILTER.RANGE },
    { name: 'passengers', type: TYPES_OF_FILTER.RANGE },
    { name: 'traction', type: TYPES_OF_FILTER.SELECT },
    { name: 'transmission', type: TYPES_OF_FILTER.SELECT },
    { name: 'carType', type: TYPES_OF_FILTER.SELECT },
  ],
  [OrderTypes.BUS]: [
    { name: 'departureStation', type: TYPES_OF_FILTER.MULTIPLE },
    {
      name: 'arrivalStation',
      type: TYPES_OF_FILTER.MULTIPLE,
    },
    { name: 'roundTrip', type: TYPES_OF_FILTER.BINARY },
  ],
  [OrderTypes.OFFICE]: [
    {
      name: 'roomName',
      type: TYPES_OF_FILTER.SELECT,
    },
  ],
};

export const PRODUCT_FILTER_TYPES = Object.keys(
  PRODUCT_SPECIFIC_FILTERS
).reduce((currentObj, product) => {
  currentObj[product] = getTypesOfFilter(PRODUCT_SPECIFIC_FILTERS[product]);
  return currentObj;
}, {});

export function getFormattedFilters(filters, filterTypes = {}) {
  const formattedFilters = [];
  const keys = Object.keys(filters);

  for (let i = 0, length = keys.length; i < length; i++) {
    const key = keys[i];
    const value = filters[key];

    if (value) {
      const type = filterTypes[key];
      formattedFilters.push(
        type === TYPES_OF_FILTER.SELECT || type === TYPES_OF_FILTER.BINARY
          ? [key, value.map((obj) => obj.value), type]
          : [key, value, type]
      );
    }
  }

  return formattedFilters;
}

export const isOrderValid = (order, filters) => {
  if (filters) {
    return filters.every(([name, values, type]) => {
      if (type !== undefined) {
        const value = order.filter[name];

        if (type === TYPES_OF_FILTER.SELECT || type === TYPES_OF_FILTER.BINARY)
          return values.includes(value);
        else if (type === TYPES_OF_FILTER.MULTIPLE) {
          return values.some((option) => value.includes(option.value));
        } else return value >= values.min && value <= values.max;
      } else return true;
    });
  }

  return false;
};

function _addToArray(arr, value) {
  if (!arr.includes(value)) arr.push(value);

  return arr;
}

function addToArray(arr, value, name, params = {}) {
  if (value) {
    if (params.multiple) {
      value.forEach((item) => _addToArray(arr, item));
    } else _addToArray(arr, value);
  }

  return arr;
}

const addToObj = (obj = {}, value, key) => {
  if (key && !(key in obj)) obj[key] = value;
  return obj;
};

const addToRange = (obj, value) => {
  if (value < obj.min) obj.min = value;
  if (value > obj.max) obj.max = value;

  return obj;
};

const getGeneralObj = (order) => {
  return {
    passengerName: [],
    costCenter: {},
    project: {},
    price: {
      min: order.price,
      max: order.price,
    },
    statusCode: [],
    approvers: [],
  };
};

const getParamObjFromConfig = (config = [], first) => {
  const obj = {};

  for (let i = 0, length = config.length; i < length; i++) {
    const { name, type = 'array' } = config[i];
    if (type === TYPES_OF_FILTER.SELECT) obj[name] = [];
    else obj[name] = { min: first[name], max: first[name] };
  }

  return obj;
};

function getGeneralParamsFromOrder(order, paramsObj) {
  const {
    approvers = [],
    passengerName,
    price,
    costCenter,
    project,
    statusCode,
  } = order;

  addToArray(passengerName, paramsObj.passengerName);
  addToArray(statusCode, paramsObj.statusCode);

  approvers.forEach((approver) => addToArray(approver, paramsObj.approvers));

  if (costCenter) addToObj(paramsObj.costCenter, costCenter.label, costCenter);
  if (project) addToObj(paramsObj.project, project.label, project);

  if (price < paramsObj.price.min) paramsObj.price.min = price;
  else if (price > paramsObj.price.max) paramsObj.price.max = price;

  return paramsObj;
}

function getParamsByConfigFromOrder(order, config = [], params = {}) {
  for (let i = 0, length = config.length; i < length; i++) {
    const { name, type = TYPES_OF_FILTER.SELECT } = config[i];
    if (type === TYPES_OF_FILTER.SELECT) addToArray(order[name], params[name]);
    else addToRange(params[name], order[name]);
  }
}

function getBinaryFilter(messages) {
  return [
    { label: messages['general.yes'], value: true },
    { label: messages['general.no'], value: false },
  ];
}

function getSpecificFilters(params, paramsConfig, messages) {
  const newObj = {};

  for (let i = 0, length = paramsConfig.length; i < length; i++) {
    const { name, type, message, filter } = paramsConfig[i];
    if (params[name]) {
      if (type === TYPES_OF_FILTER.SELECT) {
        if (params[name].length > 1) {
          let values = params[name];
          values = filter
            ? values.filter((value) => !filter.includes(value))
            : values;

          newObj[name] = values.map((value) => ({
            label: message ? messages[`${message}.${value}`] : value,
            value,
          }));
        }
      } else if (type === TYPES_OF_FILTER.RANGE) {
        if (params[name].min !== params[name].max) newObj[name] = params[name];
      } else if (type === TYPES_OF_FILTER.BINARY)
        newObj[name] = getBinaryFilter(messages);
      else if (type === TYPES_OF_FILTER.MULTIPLE) {
        newObj[name] = params[name]
          .flat()
          .map((value) => ({ label: value, value }));
      }
    }
  }

  return newObj;
}

function getFilters(orders, paramsConfig, messages) {
  const generalParams = getGeneralObj(orders[0]);
  const params = getParamObjFromConfig(paramsConfig, orders[0]);

  for (let i = 0, length = orders.length; i < length; i++) {
    getParamsByConfigFromOrder(orders[i], paramsConfig, params);
    getGeneralParamsFromOrder(orders[i], generalParams);
  }

  return {
    ...getSpecificFilters(generalParams, GENERAL_FILTERS, messages),
    ...getSpecificFilters(params, paramsConfig, messages),
  };
}

function getFlightFilters(flights, messages) {
  const arrivalStations = [];
  const departureStations = [];
  const generalParams = getGeneralObj(flights[0]);

  for (let i = 0, length = flights.length; i < length; i++) {
    const { journeys } = flights[i];

    getGeneralParamsFromOrder(flights[i], generalParams);

    journeys.forEach(({ arrivalStation, departureStation }) => {
      addToArray(
        `(${arrivalStation.iataCode}) ${arrivalStation.name}`,
        arrivalStations
      );
      addToArray(
        `(${departureStation.iataCode}) ${departureStation.name}`,
        departureStations
      );
    });
  }

  return {
    arrivalStation: arrivalStations.map((value) => ({ label: value, value })),
    departureStation: departureStations.map((value) => ({
      label: value,
      value,
    })),
    roundTrip: getBinaryFilter(messages),
    ...getSpecificFilters(generalParams, GENERAL_FILTERS, messages),
  };
}

function getHotelFilters(hotels, messages) {
  return getFilters(
    hotels,
    PRODUCT_SPECIFIC_FILTERS[OrderTypes.HOTEL],
    messages
  );
}

function getCarFilters(cars, messages) {
  return getFilters(cars, PRODUCT_SPECIFIC_FILTERS[OrderTypes.CAR], messages);
}

function getBusFilters(buses, messages) {
  const arrivalStations = [];
  const departureStations = [];
  const generalParams = getGeneralObj(buses[0]);

  for (let i = 0, length = buses.length; i < length; i++) {
    const { journeys } = buses[i];

    getGeneralParamsFromOrder(buses[i], generalParams);

    journeys.forEach(({ arrivalStation, departureStation }) => {
      addToArray(arrivalStation, arrivalStations);
      addToArray(departureStation, departureStations);
    });
  }

  return {
    arrivalStation: arrivalStations.map((value) => ({ label: value, value })),
    departureStation: departureStations.map((value) => ({
      label: value,
      value,
    })),
    roundTrip: getBinaryFilter(messages),
    ...getSpecificFilters(generalParams, GENERAL_FILTERS, messages),
  };
}

function getOfficeFilters(office, messages) {
  return getFilters(
    office,
    PRODUCT_SPECIFIC_FILTERS[OrderTypes.OFFICE],
    messages
  );
}

const PRODUCT_FILTER_FUNCTIONS = {
  [OrderTypes.FLIGHT]: getFlightFilters,
  [OrderTypes.HOTEL]: getHotelFilters,
  [OrderTypes.CAR]: getCarFilters,
  [OrderTypes.BUS]: getBusFilters,
  [OrderTypes.OFFICE]: getOfficeFilters,
};

export function getFiltersFromSortedOrders(
  orders,
  messages,
  currentFilters = {}
) {
  const products = Object.keys(orders);

  products.forEach((product) => {
    if (!(product in currentFilters) && orders[product].length > 1) {
      currentFilters[product] = PRODUCT_FILTER_FUNCTIONS[product](
        orders[product],
        messages
      );
    }
  });

  return currentFilters;
}

export const getGeneralParams = (orders = []) => {
  if (orders.length === 0) return {};

  let minDate, maxDate, minPrice, maxPrice;
  minDate = maxDate = orders[0].createdAt;
  minPrice = maxPrice = orders[0].fare.total;

  const types = {};
  const projects = {};
  const costCenters = {};
  const statusCodes = [];
  const names = [];
  const allApprovers = [];

  for (let i = 0, length = orders.length; i < length; i++) {
    const {
      type,
      createdAt,
      project = {},
      costCenter = {},
      fare,
      filter,
      statusCode,
    } = orders[i];
    const { approvers = [] } = filter;

    if (createdAt < minDate) minDate = createdAt;
    else if (createdAt > maxDate) maxDate = createdAt;

    if (fare.total < minPrice) minPrice = fare.total;
    else if (fare.total > maxPrice) maxPrice = fare.total;

    addToObj(projects, project.label, project);
    addToObj(costCenters, costCenter.label, costCenter);
    addToObj(types, type, type);
    addToArray(statusCode, statusCodes);
    approvers.forEach((approver) => addToArray(approver, allApprovers));

    if (!names.includes(filter.passengerName)) names.push(filter.passengerName);
  }

  return {
    passengerName: names.map((name) => ({ value: name, label: name })),
    price: {
      min: minPrice,
      max: maxPrice,
    },
    date: {
      min: minDate,
      max: maxDate,
    },
    project: Object.values(projects),
    costCenter: Object.values(costCenters),
    statusCode: statusCodes.filter((value) => value !== 150),
    type: Object.values(types).map((type) => ({
      value: type,
      label: type,
    })),
    approvers: allApprovers.map((approver) => ({
      value: approver,
      label: approver,
    })),
  };
};

export const sortOrdersByType = (orders) => {
  const sortedOrders = {};

  for (let i = 0, length = orders.length; i < length; i++) {
    const { filter, type } = orders[i];
    if (type in sortedOrders) sortedOrders[type].push({ ...filter, type });
    else sortedOrders[type] = [{ ...filter, type }];
  }

  return sortedOrders;
};

export const InitialParamFunctions = {
  [FILTER_TYPES.ARRAY]: () => [],
  [FILTER_TYPES.OBJECT]: () => ({}),
  [FILTER_TYPES.RANGE]: () => ({
    min: Number.MAX_VALUE,
    max: Number.MIN_VALUE,
  }),
  [FILTER_TYPES.BOOLEAN]: () => [],
};

export const AddToParamFunctions = {
  [FILTER_TYPES.ARRAY]: addToArray,
  [FILTER_TYPES.OBJECT]: addToObj,
  [FILTER_TYPES.RANGE]: addToRange,
  [FILTER_TYPES.BOOLEAN]: addToArray,
};

export const generateInitialParams = (config) => {
  const params = {};

  config.forEach(({ paramName, type }) => {
    params[paramName] = InitialParamFunctions[type]();
  });

  return params;
};

export const handleConfig = (config) => {
  return config.map((value) => ({
    ...value,
    paramName: value.paramName || value.field,
  }));
};

export const getFilterParamsFromConfig = (values = [], _config) => {
  if (values.length === 0 || !_config) return {};

  const config = handleConfig(_config);

  const params = generateInitialParams(config);
  const configLength = config.length;

  for (let i = 0, length = values.length; i < length; i++) {
    const value = values[i];

    for (let j = 0; j < configLength; j++) {
      const {
        type,
        paramName,
        field,
        key,
        getField = (value) => value,
        options = {},
      } = config[j];

      const fieldValue = getField(value[field]);

      if (fieldValue) {
        params[paramName] = AddToParamFunctions[type](
          params[paramName],
          fieldValue,
          key ? fieldValue[key] : fieldValue,
          options
        );
      }
    }
  }

  return config.reduce((obj, configValue) => {
    const { paramName, formatResult, sortResults = (v) => v } = configValue;
    const value = params[paramName];

    obj[paramName] = sortResults(formatResult ? formatResult(value) : value);

    return obj;
  }, {});
};
