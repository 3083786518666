export const TYPES_OF_CLASS = {
  CONVENCIONAL: 'Convencional',
  EXECUTIVO: 'Executivo',
  SEMILEITO: 'Semi-leito',
  SLEITO: 'Semi-leito',
  SEMILTO: 'Semi-leito',
  SEMI: 'Semi-leito',
  SLEITO: 'Semi-leito',
  LEITO: 'Leito',
  CAMA: 'Cama',
};

export const getJourneyBusID = (journeys) => {
  const firstAirport = journeys[0].departureStation;
  const lastAirport = journeys[journeys.length - 1].arrivalStation;

  if (firstAirport === lastAirport) {
    const middleJourney = journeys[journeys.length / 2 - 1];
    return `_${firstAirport}${middleJourney.arrivalStation}`;
  } else return `${firstAirport}${lastAirport}`;
};
