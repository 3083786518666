import React, { useMemo, useState } from 'react';
import { Card, CardHeader, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import { useIntl } from 'react-intl';
import CostCenterTab from '../CostCenterTab';
import ProjectTab from '../ProjectTab';
import UsersTab from '../UsersTab';
import VendorsTab from './VendorsTab';
import CategoryTab from './CategoryTab';

const COMPONENTS = {
  costCenter: CostCenterTab,
  project: ProjectTab,
  users: UsersTab,
  vendors: VendorsTab,
  categories: CategoryTab,
};

export default function BudgetRelatedInfo({
  budgets = [],
  getData,
  limit = 10,
  showGraph = true,
}) {
  const { messages } = useIntl();

  const [activeTab, setActiveTab] = useState('costCenter');
  const Component = useMemo(() => COMPONENTS[activeTab], [activeTab]);

  return (
    <Card className="general-related-info">
      <CardHeader className="pb-0 px-4 ml-1">
        <Nav tabs className="card-header-tabs justify-content-end">
          <div className="card-title flex-fill align-self-center pt-3">
            Gastos
          </div>
          <NavItem>
            <NavLink
              to="#"
              location={{}}
              className={classnames({
                active: activeTab === 'costCenter',
                'nav-link': true,
              })}
              onClick={() => {
                setActiveTab('costCenter');
              }}
            >
              {messages['refund.dashboard.cost-center']}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              to="#"
              location={{}}
              className={classnames({
                active: activeTab === 'project',
                'nav-link': true,
              })}
              onClick={() => {
                setActiveTab('project');
              }}
            >
              {messages['refund.dashboard.project']}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              to="#"
              location={{}}
              className={classnames({
                active: activeTab === 'categories',
                'nav-link': true,
              })}
              onClick={() => {
                setActiveTab('categories');
              }}
            >
              {messages['refund.dashboard.categories']}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              to="#"
              location={{}}
              className={classnames({
                active: activeTab === 'users',
                'nav-link': true,
              })}
              onClick={() => {
                setActiveTab('users');
              }}
            >
              {messages['refund.dashboard.users']}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              to="#"
              location={{}}
              className={classnames({
                active: activeTab === 'vendors',
                'nav-link': true,
              })}
              onClick={() => {
                setActiveTab('vendors');
              }}
            >
              {messages['refund.dashboard.vendors']}
            </NavLink>
          </NavItem>
        </Nav>
      </CardHeader>

      <div className="p-3">
        <Component items={budgets} getData={getData} showGraph={showGraph} />
      </div>
    </Card>
  );
}
