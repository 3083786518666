import React, { useMemo, useState } from 'react';
import { ReactComponent as ExternalIcon } from '../assets/img/icons/external-link.svg';
import { ReactComponent as DeleteIcon } from '../assets/img/icons/delete.svg';
import { useIntl } from 'react-intl';

export default function PreviewActionButtons({
  editable,
  canDoAction,
  onOpenFile,
  onDelete,
  white,
}) {
  const { messages } = useIntl();
  const [doingAction, setDoingAction] = useState(false);

  // States
  const { backgroundColor, fill } = useMemo(
    () =>
      white
        ? { backgroundColor: 'white', fill: '#43418e' }
        : { backgroundColor: '#43418e', fill: 'white' },
    [white]
  );

  // Functions
  const handleDeleteImage = async () => {
    try {
      if (doingAction) return;

      setDoingAction(true);
      await onDelete();
      setDoingAction(false);
    } catch (err) {
      console.error(err);
    }
  };

  // Render
  return canDoAction ? (
    <>
      {editable ? (
        <div
          className="action-btn delete-btn p-2 show-up mr-2"
          onClick={handleDeleteImage}
          style={{
            pointerEvents: doingAction ? 'none' : 'all',
            backgroundColor,
          }}
          title={messages['refund.action-btn.delete']}
        >
          <DeleteIcon width="90%" height="90%" fill={fill} />
        </div>
      ) : null}
      <div
        className="action-btn open-btn p-2 show-up"
        onClick={onOpenFile}
        style={{ animationDelay: '50ms', backgroundColor }}
        title={messages['refund.action-btn.external-link']}
      >
        <ExternalIcon width="90%" height="90%" fill={fill} />
      </div>
    </>
  ) : null;
}
