import React from 'react';
import ContentLoader from 'react-content-loader';

export default function LoadingItineraryCard() {
  return (
    <div className="itinerary-card loading-card">
      <ContentLoader
        speed={2}
        height="100%"
        width="100%"
        viewBox="0 0 100 100"
        backgroundColor="white"
        foregroundColor="#f5f5f5"
        preserveAspectRatio="none"
      >
        <rect x="0" y="0" rx="0" ry="0" width="100%" height="100%" />
      </ContentLoader>
    </div>
  );
}
