import {
  ADD_BUDGETS,
  ADD_BUDGET_REPORT_EXPENSE,
  REMOVE_BUDGET_REPORT_EXPENSE,
  ADD_ALL_BUDGETS_REPORT_EXPENSE,
  REMOVE_ALL_BUDGETS_REPORT_EXPENSE_BY_CARD_ID,
  RESET_SELECTED_BUDGETS,
} from '../actions';

export const addBudgets = (budgets) => ({
  type: ADD_BUDGETS,
  payload: {
    budgets,
  },
});

export const addBudgetToReportExpense = (budget) => ({
  type: ADD_BUDGET_REPORT_EXPENSE,
  payload: {
    budget,
  },
});

export const removeBudgetToReportExpense = (budget) => ({
  type: REMOVE_BUDGET_REPORT_EXPENSE,
  payload: {
    budget,
  },
});

export const addAllBudgetsToReportExpense = (budgets) => ({
  type: ADD_ALL_BUDGETS_REPORT_EXPENSE,
  payload: {
    budgets,
  },
});

export const removeAllBudgetsToReportExpenseByCardId = (cardId) => ({
  type: REMOVE_ALL_BUDGETS_REPORT_EXPENSE_BY_CARD_ID,
  payload: {
    cardId,
  },
});

export const resetSelectedBudgets = () => ({
  type: RESET_SELECTED_BUDGETS,
});
