import React from 'react';
import firebaseApp from '../../services/Firebase';
import ProjectModal from '../../components/policy/ProjectModal';
import DefaultDataListView from './DefaultDataListView';

export default function ProjectDataListView() {
  return (
    <DefaultDataListView
      fieldName="project"
      path="projects"
      fetchFunction={firebaseApp.getProjectsFromOrganization}
      deleteFunction={firebaseApp.deleteProjectFromOrganizationAndId}
      updateFunction={firebaseApp.updateProjectFromOrganization}
      createFunction={firebaseApp.createProjectFromOrganization}
      ItemModal={ProjectModal}
      emptyResultsTitleId="admin.policy.empty.project.title"
      emptyResultsDescriptionId="admin.policy.empty.project.description"
      emptyResultsButtonTextId="admin.policy.empty.project.btn"
      archiveKey="projects"
    />
  );
}
