import { TObject } from 'src/portao3-legacy/types/others';

export enum ParamTypes {
  STRING,
  RANGE,
  RANGE_MAP,
  CONTAINS,
}

export type TParamConfig = {
  name: string;
  type: ParamTypes;
  getDefaultValue?: () => any;
  multiple?: boolean;
};

export type TString = string;

export type TStringValue = string;

export type TRange = {
  min: number;
  max: number | null;
};

export type TRangeValue = number[];

export type TRangeMap = TObject<TRange>;

export type TRangeMapValue = {
  name: string;
  value: number[];
};

export type TContains = TObject<boolean>;

export type TContainsValue = {
  name: string;
  value: boolean;
  invertValue?: boolean;
};

export type TParamTypes = TRange | TRangeMap | TContains | TString;

export type TParams = TObject<TParamTypes>;

export type TSetParamsValue =
  | TRangeValue
  | TContainsValue
  | TRangeMapValue
  | TStringValue;

export type TSetParam = (paramName: string, value: TSetParamsValue) => any;

export type TSetNamedParam = (name: string) => (value: any) => any;

export type TReplaceParam = (paramName: string, value: TParamTypes) => void;
