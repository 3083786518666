import React from 'react';

import config from '../../constants/GoogleMapsBasic';
const GOOGLE_API_KEY = config.key;

export default function FlightRouteMap({ route }) {
  return (
    <div
      className="hotel-image"
      style={{
        backgroundImage: `url(https://maps.googleapis.com/maps/api/staticmap?markers=color:red%7C${route?.departure?.latitude},${route?.departure?.longitude}%7C${route?.arrival?.latitude},${route?.arrival?.longitude}&size=300x200&key=${GOOGLE_API_KEY}&map_id=e462135989faefa)`,
      }}
    />
  );
}
