import React, { Component } from 'react';
import { Colxx } from '../../components/common/CustomBootstrap';
import { Card, Button, CustomInput } from 'reactstrap';
import moment from 'moment';
import clone from 'lodash/clone';
import find from 'lodash/find';
import map from 'lodash/map';
import { injectIntl } from 'react-intl';

import { registeredAirlines } from '../../constants/travelValues';
import { stringToMoney } from '../../utils/money';

class PackageFlightItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedSegments: map(this.props.item.journeys, (journey) => {
        return {
          ...journey.segments[0],
          index: 0,
        };
      }),
    };
  }

  selectSegment(journeyIndex, segmentIndex) {
    const { selectedSegments } = this.state;
    const { item } = this.props;

    const newSelectedSegments = clone(selectedSegments);
    newSelectedSegments[journeyIndex] = {
      ...item.journeys[journeyIndex].segments[segmentIndex],
      index: segmentIndex,
    };

    this.setState({
      selectedSegments: newSelectedSegments,
    });
  }

  // Render
  renderCityName(station) {
    if (this.props.params.airports) {
      return (
        <span>{find(this.props.params.airports, { iata: station }).city}</span>
      );
    }

    return <span>{station}</span>;
  }

  renderDuration(startTime, endTime) {
    var start = new moment(startTime);
    var end = new moment(endTime);
    var duration = moment.duration(end.diff(start));

    return `${Math.floor(duration.asHours())}h ${duration.minutes()}m`;
  }

  renderJourney(journey, iJ) {
    const {
      index,
      intl: { messages },
    } = this.props;

    return (
      <div className="journey" key={`journey-${index}-${iJ}`}>
        <div className="row header">
          <Colxx xxs="3">
            <div className="title">
              <img
                src="/assets/img/icons/journey_icon.svg"
                alt={messages['travel.outbound']}
              />
              {iJ === 0 && messages['travel.outbound']}
              {iJ === 1 && messages['travel.inbound']}
              <span>
                {moment(journey.departureDate).format('ddd DD MMM YYYY')}
              </span>
            </div>
          </Colxx>
          <Colxx xxs="2">
            <div className="station">
              {journey.departureStation}
              <span>{this.renderCityName(journey.departureStation)}</span>
            </div>
          </Colxx>
          <Colxx xxs="2"></Colxx>
          <Colxx xxs="2" className="station">
            <div className="station">
              {journey.arrivalStation}
              <span>{this.renderCityName(journey.arrivalStation)}</span>
            </div>
          </Colxx>
          <Colxx xxs="3"></Colxx>
        </div>

        {map(journey.segments, (segment, iS) => {
          return this.renderSegment(journey, iJ, segment, iS);
        })}
      </div>
    );
  }

  renderSegment(journey, iJ, segment, iS) {
    const { selectedSegments } = this.state;
    const {
      index,
      intl: { messages },
    } = this.props;

    return (
      <div key={`journey-${index}-${iJ}-${iS}`} className="row trip">
        <Colxx xxs="3" className="selection">
          <CustomInput
            type="checkbox"
            className="custom-check"
            id={`journey-${index}-${iJ}-${iS}`}
            checked={selectedSegments[iJ].index === iS}
            onChange={() => {
              this.selectSegment(iJ, iS);
            }}
          />
        </Colxx>

        <Colxx xxs="2" className="time">
          {moment(segment.departureDateTime).format('HH:mm')}
        </Colxx>

        <Colxx xxs="2" className="stops-chart">
          <div className="graph">
            <div className="line">
              <div className="dots">
                {map(segment.flights, (flightItem, iFI) => {
                  if (iFI === 0) return '';
                  return (
                    <div
                      key={`result-${index}-${iJ}-${iS}-${iFI}`}
                      className="block"
                    />
                  );
                })}
              </div>
            </div>
          </div>
          <div className="info">
            {segment.flights.length - 1 === 0
              ? messages['flight.no-stop']
              : segment.flights.length - 1 === 1
              ? `1 ${messages['flight.stop']}`
              : `${segment.flights.length - 1} ${messages['flight.stops']}`}
          </div>
        </Colxx>

        <Colxx xxs="2" className="time">
          {moment(segment.arrivalDateTime).format('HH:mm')}
        </Colxx>

        <Colxx xxs="2" className="duration">
          {this.renderDuration(
            segment.departureDateTime,
            segment.arrivalDateTime
          )}
        </Colxx>
      </div>
    );
  }

  render() {
    const {
      item,
      intl: { messages },
    } = this.props;
    const { selectedSegments } = this.state;

    return (
      <Card className="search-card flight mt-5">
        <Colxx xxs="12" lg="9" className="journeys">
          {map(item.journeys, (journey, i) => {
            return this.renderJourney(journey, i);
          })}
        </Colxx>
        <Colxx xxs="12" lg="3" className="total pt-3">
          {registeredAirlines[item.airline] && (
            <div
              className="carrier"
              style={{
                backgroundImage: `url(${
                  registeredAirlines[item.airline].logo
                })`,
              }}
            />
          )}
          <div className="text">
            <span>{messages['general.total']}</span>
            {stringToMoney(item.fare.total)}
            <hr />
          </div>
          <div className="description">{messages['general.taxes']}</div>

          <Button
            color="primary"
            onClick={() => {
              this.props.changeFlight(item, selectedSegments);
            }}
          >
            {messages['general.select']}
          </Button>
        </Colxx>
      </Card>
    );
  }
}

export default injectIntl(PackageFlightItem);
