import React, { useMemo } from 'react';

export default function SimpleCardInfo({ card, color, BankIcon }) {
  const { cardName, cardlastDigits, subtext } = useMemo(
    () => ({
      cardName: card.name || '',
      cardlastDigits: card.lastDigits
        ? `•••• •••• •••• ${card.lastDigits}`
        : '',
      subtext: card.subtext || '',
    }),
    [card]
  );

  return (
    <div className="simple-card-info">
      {cardlastDigits ? (
        <h3 className="last-digits p-0 m-0 font-primary" style={{ color }}>
          {cardlastDigits}
        </h3>
      ) : (
        <h3 className="last-digits p-0 m-0 font-primary" style={{ color }}>
          {BankIcon && <BankIcon />} {subtext}
        </h3>
      )}
      <h2 className="name" style={{ color }}>
        {cardName}
      </h2>
    </div>
  );
}
