exports.pixBanks = {
  10143499: {
    bank_ispb: '10143499',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE JATAÍ E REGIÃO LTDA.',
    short_name: 'CCLA JATAÍ E REGIÃO',
  },
  10143743: {
    bank_ispb: '10143743',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE ASSOCIADOS DO SUL DO BRASIL - CREVISC',
    short_name: 'CREVISC',
  },
  10175348: {
    bank_ispb: '10175348',
    name: 'COOPERATIVA DE CRéDITO MúTUO DE LIVRE ADMISSãO DA REGIãO ADMINISTRATIVA DE SOROCABA - SICOOB COOPERASO',
    short_name: 'CCMLA REGIãO ADMINISTRATIVA DE SOROCABA',
  },
  10209619: {
    bank_ispb: '10209619',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DA GRANDE GOIÂNIA LTDA.',
    short_name: 'CCLA DA GRANDE GOIÂNIA LTDA.',
  },
  10218474: {
    bank_ispb: '10218474',
    name: 'COOPERATIVA DE CRÉDITO DA REGIÃO DO CONTESTADO - CIVIA',
    short_name: 'CIVIA COOP CRED',
  },
  10262276: {
    bank_ispb: '10262276',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO ESTADO DE SÃO PAULO - SICOOB PAULISTA',
    short_name: 'CCLA DO ESTADO DE SÃO PAULO - SICOOB PAULISTA',
  },
  10311218: {
    bank_ispb: '10311218',
    name: 'COOPERATIVA DE CRÉDITO EVOLUA',
    short_name: 'COOP EVOLUA',
  },
  10319386: {
    bank_ispb: '10319386',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO CENTRO SUL DE MATO GROSSO DO SUL - SICOOB CENTRO SUL MS',
    short_name: 'CCLA DO CENTRO SUL DE MS',
  },
  10348181: {
    bank_ispb: '10348181',
    name: 'COOPERATIVA DE CRÉDITO DO VALE DO ITAJAÍ E LITORAL CATARINENSE - SICREDI VALE LITORAL SC',
    short_name: 'COOP SICREDI VALE LITORAL SC',
  },
  10398952: {
    bank_ispb: '10398952',
    name: 'CONFEDERAÇÃO NACIONAL DAS COOPERATIVAS CENTRAIS DE CRÉDITO E ECONOMIA FAMILIAR E SOLIDÁRIA - CRESOL CONFEDERAÇÃO',
    short_name: 'CRESOL CONFEDERAÇÃO',
  },
  10453077: {
    bank_ispb: '10453077',
    name: 'COOPERATIVA DE CRÉDITO RURAL COM INTERAÇÃO SOLIDÁRIA REALIZA - CRESOL REALIZA',
    short_name: 'COOP CRESOL REALIZA',
  },
  10520232: {
    bank_ispb: '10520232',
    name: 'COOPERATIVA DE CRÉDITO E INVESTIMENTO COM INTERAÇÃO SOLIDÁRIA DA AMAZÔNIA - CRESOL AMAZÔNIA',
    short_name: 'COOP CRESOL AMAZONIA',
  },
  10573521: {
    bank_ispb: '10573521',
    name: 'MERCADO PAGO INSTITUIÇÃO DE PAGAMENTO LTDA.',
    short_name: 'MERCADO PAGO IP LTDA.',
  },
  10664513: {
    bank_ispb: '10664513',
    name: 'BANCO AGIBANK S.A.',
    short_name: 'BCO AGIBANK S.A.',
  },
  10736214: {
    bank_ispb: '10736214',
    name: 'COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DO PLANALTO CENTRAL - SICREDI PLANALTO CENTRAL',
    short_name: 'COOP SICREDI PLAN CENTRAL',
  },
  10808037: {
    bank_ispb: '10808037',
    name: 'COOPERATIVA DE CRÉDITO DOS EMPRESÁRIOS DE MANAUS - SICOOB CREDEMPRESAS-AM',
    short_name: 'CC EMPRESÁRIOS DE MANAUS',
  },
  10868663: {
    bank_ispb: '10868663',
    name: 'ACG INSTITUIÇÃO DE PAGAMENTO S.A.',
    short_name: 'ACG IP S.A.',
  },
  10878448: {
    bank_ispb: '10878448',
    name: 'PAYPAL DO BRASIL INSTITUIÇÃO DE PAGAMENTO LTDA.',
    short_name: 'PAYPAL',
  },
  11165756: {
    bank_ispb: '11165756',
    name: 'GLOBAL FINANÇAS SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORTE LTDA.',
    short_name: 'GLOBAL SCM LTDA',
  },
  11275560: {
    bank_ispb: '11275560',
    name: 'RECARGAPAY INSTITUICAO DE PAGAMENTO LTDA.',
    short_name: 'RECARGAPAY IP LTDA.',
  },
  11476673: {
    bank_ispb: '11476673',
    name: 'BANCO RANDON S.A.',
    short_name: 'BANCO RANDON S.A.',
  },
  11581339: {
    bank_ispb: '11581339',
    name: 'BMP SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E A EMPRESA DE PEQUENO PORTE LTDA.',
    short_name: 'BMP SCMEPP LTDA',
  },
  11703662: {
    bank_ispb: '11703662',
    name: 'TRAVELEX BANCO DE CÂMBIO S.A.',
    short_name: 'TRAVELEX BANCO DE CÂMBIO S.A.',
  },
  11760553: {
    bank_ispb: '11760553',
    name: 'GAZINCRED S.A. SOCIEDADE DE CRÉDITO, FINANCIAMENTO E INVESTIMENTO',
    short_name: 'GAZINCRED S.A. SCFI',
  },
  11907520: {
    bank_ispb: '11907520',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DA PARAÍBA - SICOOB PARAÍBA',
    short_name: 'CCLA DA PARAÍBA - SICOOB PARAÍBA',
  },
  11969853: {
    bank_ispb: '11969853',
    name: 'COOPERATIVA DE CRÉDITO E INVESTIMENTO COM INTERAÇÃO SOLIDÁRIA CONEXÃO - CRESOL CONEXÃO',
    short_name: 'COOP CRESOL CONEXÃO',
  },
  11970623: {
    bank_ispb: '11970623',
    name: 'BANCO SENFF S.A.',
    short_name: 'BCO SENFF S.A.',
  },
  12109247: {
    bank_ispb: '12109247',
    name: 'CREDZ ADMINISTRADORA DE CARTOES S/A',
    short_name: 'CREDZ ADMINISTRADORA DE CARTOE',
  },
  12384953: {
    bank_ispb: '12384953',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE ASSOCIADOS DE JARAGUÁ DO SUL E REGIÃO - SICOOB CEJASCRED',
    short_name: 'CCLAA JARAGUÁ DO SUL E REGIÃO',
  },
  13009717: {
    bank_ispb: '13009717',
    name: 'BANCO DO ESTADO DE SERGIPE S.A.',
    short_name: 'BCO DO EST. DE SE S.A.',
  },
  13059145: {
    bank_ispb: '13059145',
    name: 'BEXS BANCO DE CÂMBIO S/A',
    short_name: 'BEXS BCO DE CAMBIO S.A.',
  },
  13140088: {
    bank_ispb: '13140088',
    name: 'ACESSO SOLUÇÕES DE PAGAMENTO S.A. - INSTITUIÇÃO DE PAGAMENTO',
    short_name: 'ACESSO SOLUÇÕES DE PAGAMENTO S.A. - INSTITUIÇÃO DE PAGAMENTO',
  },
  13203354: {
    bank_ispb: '13203354',
    name: 'FITBANK INSTITUIÇÃO DE PAGAMENTOS ELETRÔNICOS S.A.',
    short_name: 'FITBANK IP',
  },
  13207930: { bank_ispb: '13207930', name: 'SLED S/A', short_name: 'SLED' },
  13370835: {
    bank_ispb: '13370835',
    name: 'DOCK INSTITUIÇÃO DE PAGAMENTO S.A.',
    short_name: 'DOCK IP S.A.',
  },
  13720915: {
    bank_ispb: '13720915',
    name: 'BANCO WESTERN UNION DO BRASIL S.A.',
    short_name: 'BCO WESTERN UNION',
  },
  13884775: {
    bank_ispb: '13884775',
    name: 'HUB INSTITUIÇÃO DE PAGAMENTO S.A.',
    short_name: 'HUB IP S.A.',
  },
  13935893: {
    bank_ispb: '13935893',
    name: 'CELCOIN INSTITUICAO DE PAGAMENTO S.A.',
    short_name: 'CELCOIN IP S.A.',
  },
  14046512: {
    bank_ispb: '14046512',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DA MARGEM ESQUERDA DO URUCUIA E SÃO FRANCISCO LTDA - SICOOB CREDICHAPADA',
    short_name: 'CCLA MARGEM ESQUERDA DO URUCUI',
  },
  14388334: {
    bank_ispb: '14388334',
    name: 'PARANÁ BANCO S.A.',
    short_name: 'PARANA BCO S.A.',
  },
  14568725: {
    bank_ispb: '14568725',
    name: 'COOPERATIVA CENTRAL DE ECONOMIA E CRÉDITO SICOOB UNIMAIS RIO LTDA.',
    short_name: 'COOPCENTRAL DE ECON. E CRÉDITO SICOOB UNIMAIS RIO LTDA.',
  },
  14796606: {
    bank_ispb: '14796606',
    name: 'ADYEN DO BRASIL INSTITUICAO DE PAGAMENTO LTDA.',
    short_name: 'ADYEN LATIN AMERICA',
  },
  14913919: {
    bank_ispb: '14913919',
    name: 'COOPERATIVA DE CREDITO DO MERCADO IMOBILIÁRIO - SICOOB IMOB.VC',
    short_name: 'CC MERCADO IMOBILIÁRIO - SICOOB IMOB.VC',
  },
  15111975: {
    bank_ispb: '15111975',
    name: 'IUGU INSTITUIÇÃO DE PAGAMENTO S.A.',
    short_name: 'IUGU IP S.A.',
  },
  15173776: {
    bank_ispb: '15173776',
    name: 'SOCIAL BANK BANCO MÚLTIPLO S/A',
    short_name: 'SOCIAL BANK S/A',
  },
  15357060: {
    bank_ispb: '15357060',
    name: 'BANCO WOORI BANK DO BRASIL S.A.',
    short_name: 'BCO WOORI BANK DO BRASIL S.A.',
  },
  16501555: {
    bank_ispb: '16501555',
    name: 'STONE INSTITUIÇÃO DE PAGAMENTO S.A.',
    short_name: 'STONE IP S.A.',
  },
  16564240: {
    bank_ispb: '16564240',
    name: 'COOPERATIVA DE ECONOMIA E CRéDITO MúTUO DOS APOSENTADOS, PENSIONISTAS E IDOSOS - SICOOB COOPERNAPI',
    short_name: 'CECM APOS PENS E IDOSOS - SICOOB COOPERNAPI',
  },
  16651002: {
    bank_ispb: '16651002',
    name: 'COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS SERVIDORES MUNICIPAIS DE ITABIRA LTDA. - SICOOB COSEMI',
    short_name: 'CECM SERV MUN ITABIRA',
  },
  16695922: {
    bank_ispb: '16695922',
    name: 'ID CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.',
    short_name: 'ID CTVM',
  },
  16721078: {
    bank_ispb: '16721078',
    name: 'COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS FUNCIONÁRIOS DA SECRETARIA DE ESTADO DA FAZENDA  DO ESTADO DE MINAS GERAIS E DE LIVRE ADMISSÃO LTDA - SICOOB COOPSEF',
    short_name: 'CECMF SECRETARIA DA FAZENDA MG E LIVRE ADMISSÃO',
  },
  16779741: {
    bank_ispb: '16779741',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO ALTO VALE DO ITAJAÍ - VIACREDI ALTO VALE',
    short_name: 'COOP V. ALTO VALE',
  },
  16814330: {
    bank_ispb: '16814330',
    name: 'BK INSTITUICAO DE PAGAMENTO LTDA.',
    short_name: 'BK INSTITUICAO DE PAGAMENTO',
  },
  17079937: {
    bank_ispb: '17079937',
    name: 'PINBANK BRASIL INSTITUIÇÃO DE PAGAMENTO S.A.',
    short_name: 'PINBANK IP',
  },
  17184037: {
    bank_ispb: '17184037',
    name: 'BANCO MERCANTIL DO BRASIL S.A.',
    short_name: 'BCO MERCANTIL DO BRASIL S.A.',
  },
  17192451: {
    bank_ispb: '17192451',
    name: 'BANCO ITAUCARD S.A.',
    short_name: 'BCO ITAUCARD S.A.',
  },
  17343510: {
    bank_ispb: '17343510',
    name: 'COOPERATIVA DE CRÉDITO E INVESTIMENTO COM INTERAÇÃO SOLIDÁRIA RAIZ - CRESOL RAIZ',
    short_name: 'COOP CRESOL RAIZ',
  },
  17351180: {
    bank_ispb: '17351180',
    name: 'BANCO TRIANGULO S.A.',
    short_name: 'BCO TRIANGULO S.A.',
  },
  17361536: {
    bank_ispb: '17361536',
    name: 'COOPERATIVA DE CRÉDITO DA REGIÃO E COLAR METROPOLITANO DO VALE DO AÇO LTDA. - SICOOB COSMIPA',
    short_name: 'CC REG. COLAR METROPOLITANO VALE DO AÇO LTDA',
  },
  17502881: {
    bank_ispb: '17502881',
    name: 'COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS EMPREGADOS DA CEMIG E DAS INDÚSTRIAS METALÚRGICAS E DE MATERIAL ELÉTRICO E DOS SERVIDORES PÚBLICOS MUNICIPAIS DE JUIZ DE FORA LTDA. - SICOOB CECREMEC',
    short_name: 'CECM EMPR CEMIG IND METAL E SERV PUB MUN',
  },
  17819084: {
    bank_ispb: '17819084',
    name: 'PAGCERTO SOLUCOES EM PAGAMENTO S.A.',
    short_name: 'PAGCERTO',
  },
  17948578: {
    bank_ispb: '17948578',
    name: 'MAGALU PAGAMENTOS LTDA',
    short_name: 'MAGALU PAGAMENTOS',
  },
  18189547: {
    bank_ispb: '18189547',
    name: 'CLOUDWALK INSTITUIÇÃO DE PAGAMENTO E SERVICOS LTDA',
    short_name: 'CLOUDWALK IP LTDA',
  },
  18236120: {
    bank_ispb: '18236120',
    name: 'NU PAGAMENTOS S.A. - INSTITUIÇÃO DE PAGAMENTO',
    short_name: 'NU PAGAMENTOS - IP',
  },
  18727053: {
    bank_ispb: '18727053',
    name: 'PAGAR.ME INSTITUICAO DE PAGAMENTO S.A',
    short_name: 'PAGAR.ME INSTITUICAO DE PAGAME',
  },
  18966739: {
    bank_ispb: '18966739',
    name: 'COOPERATIVA DE CRéDITO COPERSUL LTDA - SICOOB COPERSUL',
    short_name: 'CC COPERSUL LTDA',
  },
  19402130: {
    bank_ispb: '19402130',
    name: 'COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS SERVIDORES DA FUNDAÇÃO HOSPITALAR E EMPREGADOS DOS ESTABELECIMENTOS HOSPITALARES DE BELO HORIZONTE, REGIÃO METROPOLITANA E ZONA DA MATA LTDA.- SICOOB CECREF',
    short_name: 'CECM EMPR EST HOSP BH RM ZM',
  },
  19449602: {
    bank_ispb: '19449602',
    name: 'COOPERATIVA DE CRÉDITO CREDCOOPER LTDA - SICOOB CREDCOOPER',
    short_name: 'CC CREDCOOPER LTDA',
  },
  19468242: {
    bank_ispb: '19468242',
    name: 'ZOOP TECNOLOGIA & INSTITUICAO DE PAGAMENTO S.A.',
    short_name: 'ZOOP MEIOS DE PAGAMENTO',
  },
  19495266: {
    bank_ispb: '19495266',
    name: 'COOPERATIVA DE CRÉDITO DOS EMPREGADOS DA COMPANHIA INDUSTRIAL CATAGUASES E DA BAUMINAS LTDA. - COOPECIC',
    short_name: 'CC EMP DA CIA INDUSTRIAL CATAGUASES E BAUMINAS LTDA.',
  },
  19540550: {
    bank_ispb: '19540550',
    name: 'ASAAS GESTÃO FINANCEIRA INSTITUIÇÃO DE PAGAMENTO S.A.',
    short_name: 'ASAAS IP S.A.',
  },
  19581142: { bank_ispb: '19581142', name: 'ATAR S/A', short_name: 'ATAR' },
  19869338: {
    bank_ispb: '19869338',
    name: 'COOPERATIVA DE CREDITO DE LIVRE ADMISSAO DO VALE DO AÇO LTDA - SICOOB VALE DO AÇO',
    short_name: 'CCLA VALE DO AÇO',
  },
  19875244: {
    bank_ispb: '19875244',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO - SICOOB COPESITA',
    short_name: 'CCLA SICOOB COPESITA',
  },
  19962468: {
    bank_ispb: '19962468',
    name: 'COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DA ADVOCACIA E SERVENTUÁRIOS DO PODER JUDICIÁRIO NO ESTADO DO RIO GRANDE DO SUL - SICREDI COOABCRED RS',
    short_name: 'COOP SICREDI COOABCRED RS',
  },
  20018183: {
    bank_ispb: '20018183',
    name: 'Stark Bank S.A.',
    short_name: 'STARK BANK S.A.',
  },
  20520298: {
    bank_ispb: '20520298',
    name: 'ADIQ INSTITUIÇÃO DE PAGAMENTO S.A.',
    short_name: 'ADIQ',
  },
  20855875: {
    bank_ispb: '20855875',
    name: 'NEON PAGAMENTOS S.A. - INSTITUIÇÃO DE PAGAMENTO',
    short_name: 'NEON PAGAMENTOS S.A. IP',
  },
  20961629: {
    bank_ispb: '20961629',
    name: 'COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS COLABORADORES DA VALE S.A LTDA. - SICOOB CREDIVALE',
    short_name: 'CECM COLABORADORES DA VALE SICOOB CREDIVALE',
  },
  21018182: {
    bank_ispb: '21018182',
    name: 'EBANX INSTITUICAO DE PAGAMENTOS LTDA.',
    short_name: 'EBANX IP LTDA.',
  },
  21110927: {
    bank_ispb: '21110927',
    name: 'COOPERATIVA DE CRÉDITO CLÁSSICA DOS FUNCIONÁRIOS E PRESTADORES DE SERVIÇOS DAS EMPRESAS LIGADAS AO GRUPO EUCATUR LTDA. - SICOOB EUCRED',
    short_name: 'CC CLASSICA FUNC PREST SERV LIG GRUPO EUCATUR',
  },
  21332862: {
    bank_ispb: '21332862',
    name: 'CARTOS SOCIEDADE DE CRÉDITO DIRETO S.A.',
    short_name: 'CARTOS SCD S.A.',
  },
  21661202: {
    bank_ispb: '21661202',
    name: 'COOPERATIVA DE CRÉDITO CREDICAMPO LTDA.- SICOOB CREDICAMPO',
    short_name: 'CC CREDICAMPO LTDA',
  },
  21670187: {
    bank_ispb: '21670187',
    name: 'COOPERATIVA DE CRÉDITO CREDIBOM LTDA. - SICOOB CREDIBOM',
    short_name: 'CC CREDIBOM',
  },
  21797311: {
    bank_ispb: '21797311',
    name: 'COOPERATIVA DE CRÉDITO DOS SERVIDORES DOS PODERES LEGISLATIVOS DO ESTADO DE MINAS GERAIS E DO SEU ÓRGÃO AUXILIAR E DE LIVRE ADMISSÃO LTDA. - SICOOB COFAL',
    short_name: 'CCLA SERV POD LEG MG - SICOOB COFAL',
  },
  21866694: {
    bank_ispb: '21866694',
    name: 'COOPERATIVA DE CRÉDITO CREDINOR LTDA - SICOOB CREDINOR',
    short_name: 'CC CREDINOR',
  },
  21995256: {
    bank_ispb: '21995256',
    name: 'MAG INSTITUICAO DE PAGAMENTO LTDA',
    short_name: 'MAG IP LTDA.',
  },
  22347623: {
    bank_ispb: '22347623',
    name: 'CELER PROCESSAMENTO COMERCIO E SERVICO LTDA.',
    short_name: 'CELER',
  },
  22371651: {
    bank_ispb: '22371651',
    name: 'DMCARD MEIOS DE PAGAMENTO LTDA',
    short_name: 'DMCARD',
  },
  22610500: {
    bank_ispb: '22610500',
    name: 'VORTX DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA.',
    short_name: 'VORTX DTVM LTDA.',
  },
  22656789: {
    bank_ispb: '22656789',
    name: 'COOPERATIVA DE CREDITO CREDISUDESTE LTDA. - SICOOB CREDISUDESTE',
    short_name: 'CC CREDISUDESTE LTDA',
  },
  22724710: {
    bank_ispb: '22724710',
    name: 'COOPERATIVA DE CRÉDITO CREDIVERTENTES LTDA. - SICOOB CREDIVERTENTES',
    short_name: 'CC CREDIVERTENTES',
  },
  22749014: {
    bank_ispb: '22749014',
    name: 'COOPERATIVA DE CRÉDITO DA REGIÃO CENTRAL DE MINAS LTDA. - SICOOB UNIÃO CENTRAL',
    short_name: 'CC REGIÃO CENTRAL MG',
  },
  22753982: {
    bank_ispb: '22753982',
    name: 'COOPERATIVA DE CRÉDITO CREDISETE LTDA. - SICOOB CREDISETE',
    short_name: 'CC CREDISETE LTDA',
  },
  22760839: {
    bank_ispb: '22760839',
    name: 'COOPERATIVA DE CRÉDITO NOSSOCRÉDITO LTDA - SICOOB NOSSOCRÉDITO',
    short_name: 'CC NOSSOCREDITO',
  },
  22896431: {
    bank_ispb: '22896431',
    name: 'PICPAY INSTITUIçãO DE PAGAMENTO S.A.',
    short_name: 'PICPAY',
  },
  23114447: {
    bank_ispb: '23114447',
    name: 'FLAGSHIP INSTITUICAO DE PAGAMENTOS LTDA',
    short_name: 'FLAGSHIP IP LTDA',
  },
  23273917: {
    bank_ispb: '23273917',
    name: 'BANK10 PAGAMENTOS S.A.',
    short_name: 'BANK10 INVESTIMENTOS',
  },
  23623636: {
    bank_ispb: '23623636',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO NORTE DE MATO GROSSO - SICOOB NORTE MT',
    short_name: 'CCLA NORTE DE MATO GROSSO - SICOOB NORTE MT',
  },
  23757411: {
    bank_ispb: '23757411',
    name: 'SOCIAL BANK INSTITUIÇÃO DE PAGAMENTO S.A',
    short_name: 'SOCIAL BANK IP SA',
  },
  23949522: {
    bank_ispb: '23949522',
    name: 'COOPERATIVA DE CRÉDITO CREDICARPA LTDA. - SICOOB CREDICARPA',
    short_name: 'CC CREDICARPA',
  },
  24048910: {
    bank_ispb: '24048910',
    name: 'COOPERATIVA DE CRÉDITO CREDINTER LTDA. - SICOOB CREDINTER',
    short_name: 'CC CREDINTER',
  },
  24313102: {
    bank_ispb: '24313102',
    name: '99PAY INSTITUICAO DE PAGAMENTO S.A.',
    short_name: '99PAY IP S.A.',
  },
  24431221: {
    bank_ispb: '24431221',
    name: 'COOPERATIVA DE CRÉDITO E INVESTIMENTO COM INTERAÇÃO SOLIDÁRIA TRANSAMAZÔNICA - CRESOL TRANSAMAZÔNICA',
    short_name: 'COOP CRESOL TRANSAMAZÔNICA',
  },
  24610065: {
    bank_ispb: '24610065',
    name: 'COOPERATIVA DE CRÉDITO SICOOB IPÊ - SICOOB IPÊ',
    short_name: 'CC SICOOB IPÊ',
  },
  24654881: {
    bank_ispb: '24654881',
    name: 'COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO UNIÃO DOS ESTADOS DE MATO GROSSO DO SUL, TOCANTINS E OESTE DA BAHIA - SICREDI UNIÃO MS/TO',
    short_name: 'COOP SICREDI UNIÃO MS/TO',
  },
  24795049: {
    bank_ispb: '24795049',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO SUDOESTE GOIANO',
    short_name: 'CCLA SUDOESTE GOIANO',
  },
  24799033: {
    bank_ispb: '24799033',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO VALE DO PARANAÍBA LTDA.',
    short_name: 'CCLA VALE DO PARANAÍBA',
  },
  24830879: {
    bank_ispb: '24830879',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO VALE DO ARAGUAIA LTDA.',
    short_name: 'CCLA VALE DO ARAGUAIA',
  },
  25021356: {
    bank_ispb: '25021356',
    name: 'DLOCAL BRASIL PAGAMENTOS LTDA. ',
    short_name: 'DLOCAL BRASIL PAGAMENTOS',
  },
  25104230: {
    bank_ispb: '25104230',
    name: 'PAGARE INSTITUICAO DE PAGAMENTOS S.A.',
    short_name: 'PAGARE IP',
  },
  25353939: {
    bank_ispb: '25353939',
    name: 'COOPERATIVA DE CRÉDITO CREDIALP LTDA - SICOOB CREDIALP',
    short_name: 'CC CREDIALP',
  },
  25363615: {
    bank_ispb: '25363615',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO SICOOB COOPJUS LTDA.',
    short_name: 'CCLA SICOOB COOPJUS',
  },
  25387655: {
    bank_ispb: '25387655',
    name: 'COOPERATIVA DE CRÉDITO CREDIVALE LTDA - SICOOB CREDIVALE',
    short_name: 'CC CREDIVALE LTDA. - SICOOB CREDIVALE',
  },
  25387671: {
    bank_ispb: '25387671',
    name: 'COOPERATIVA DE CREDITO DO ALTO PARANAIBA E REGIÃO LTDA. - SICOOB CREDIPATOS',
    short_name: 'CC ALTO PARANAIBA E REGIÃO',
  },
  25387713: {
    bank_ispb: '25387713',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DA REGIÃO DE  PARÁ DE MINAS LTDA - SICOOB CREDIRURAL',
    short_name: 'CCLA DA REGIÃO DE  PARÁ DE MINAS',
  },
  25395435: {
    bank_ispb: '25395435',
    name: 'COOPERATIVA DE CRÉDITO CREDICAF LTDA - SICOOB CREDICAF',
    short_name: 'CC CREDICAF LTDA',
  },
  25404542: {
    bank_ispb: '25404542',
    name: 'COOPERATIVA DE CREDITO DE LIVRE ADMISSÃO DO VALE DO GORUTUBA LTDA - SICOOB CREDIVAG',
    short_name: 'CCLA VALE DO GORUTUBA',
  },
  25420696: {
    bank_ispb: '25420696',
    name: 'COOPERATIVA DE CRéDITO CREDINACIONAL LTDA. - SICOOB CREDINACIONAL',
    short_name: 'CC CREDINACIONAL LTDA - SICOOB CREDINACIONAL',
  },
  25528753: {
    bank_ispb: '25528753',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DA REGIÃO DE DIVINÓPOLIS LTDA - SICOOB CREDIVERDE',
    short_name: 'CCLA DIVINÓPOLIS',
  },
  25536764: {
    bank_ispb: '25536764',
    name: 'COOPERATIVA DE CRÉDITO CREDILUZ LTDA. - SICOOB CREDILUZ',
    short_name: 'CC CREDILUZ',
  },
  25606237: {
    bank_ispb: '25606237',
    name: 'COOPERATIVA DE POUPANÇA E CRÉDITO DO VALE DO RIO DOCE LTDA - SICOOB CREDIRIODOCE',
    short_name: 'CPC VALE DO RIO DOCE',
  },
  25626490: {
    bank_ispb: '25626490',
    name: 'COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO ALTO RIO GRANDE MG - SICREDI CREDIGRANDE MG',
    short_name: 'COOP SICREDI CREDIGRANDE',
  },
  25683434: {
    bank_ispb: '25683434',
    name: 'COOPERATIVA CENTRAL DE CRÉDITO DE MINAS GERAIS LTDA. - SICOOB CENTRAL CREDIMINAS',
    short_name: 'CCC MINAS GERAIS - CREDIMINAS',
  },
  25683475: {
    bank_ispb: '25683475',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO VALE DO RIO GRANDE LTDA. - SICOOB UBERABA',
    short_name: 'CCLA VALE DO RIO GRANDE',
  },
  25743311: {
    bank_ispb: '25743311',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE CARMO DO RIO CLARO LTDA - SICOOB CREDICARMO',
    short_name: 'CCLA CARMO DO RIO CLARO',
  },
  25798596: {
    bank_ispb: '25798596',
    name: 'COOPERATIVA DE CRÉDITO CREDIVAR LTDA. - SICOOB CREDIVAR',
    short_name: 'CC CREDIVAR',
  },
  26014175: {
    bank_ispb: '26014175',
    name: 'COOPERATIVA DE CRÉDITO GUARANICREDI LTDA. - SICOOB GUARANICREDI',
    short_name: 'CC GUARANICREDI',
  },
  26178111: {
    bank_ispb: '26178111',
    name: 'COOPERATIVA DE CRÉDITO UNIÃO CENTRO OESTE LTDA. - SICOOB UNIÃO CENTRO OESTE',
    short_name: 'CC UNIÃO CENTRO OESTE LTDA',
  },
  26178533: {
    bank_ispb: '26178533',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO PONTAL DO TRIANGULO LTDA - SICOOB CREDIPONTAL',
    short_name: 'CCLA PONTAL DO TRIANGULO',
  },
  26191078: {
    bank_ispb: '26191078',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DA REGIÃO SUL DO MARANHÃO - SICREDI SUL DO MARANHÃO',
    short_name: 'COOP SICREDI SUL DO MARANHÃO',
  },
  26264220: {
    bank_ispb: '26264220',
    name: 'ZRO PAGAMENTOS S/A',
    short_name: 'ZRO PAGAMENTOS S/A',
  },
  26359745: {
    bank_ispb: '26359745',
    name: 'COOPERATIVA DE CRÉDITO SICOOBMAIS  LTDA. - SICOOBMAIS',
    short_name: 'CC SICOOBMAIS',
  },
  26408161: {
    bank_ispb: '26408161',
    name: 'COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DO CENTRO SUL DO MATO GROSSO DO SUL - SICREDI CENTRO-SUL MS',
    short_name: 'COOP SICREDI CENTRO SUL MS',
  },
  26408187: {
    bank_ispb: '26408187',
    name: 'COOPERATIVA DE CRÉDITO POUPANÇA E INVESTIMENTO PANTANAL DO MATO GROSSO DO SUL - SICREDI PANTANAL MS',
    short_name: 'COOP SICREDI PANTANAL',
  },
  26526166: {
    bank_ispb: '26526166',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO SUL MARANHENSE - SICOOB SUL MARANHENSE',
    short_name: 'CCLA SUL MARANHENSE - SICOOB SUL MARANHENSE',
  },
  26529420: {
    bank_ispb: '26529420',
    name: 'COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO OURO VERDE DO MATO GROSSO - SICREDI OURO VERDE MT',
    short_name: 'COOP SICREDI OURO VERDE MT',
  },
  26549311: {
    bank_ispb: '26549311',
    name: 'COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO INTEGRAÇÃO DO SUL DE MATO GROSSO, AMAPÁ E PARÁ - SICREDI INTEGRAÇÃO MT/AP/PA',
    short_name: 'COOP SICREDI INTEGRAÇÃO MT/AP/PA',
  },
  26555235: {
    bank_ispb: '26555235',
    name: 'COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO SORRISO - SICREDI CELEIRO MT/RR',
    short_name: 'COOP SICREDI CELEIRO DO MT',
  },
  26563270: {
    bank_ispb: '26563270',
    name: 'PRIMACREDI COOPERATIVA DE CRÉDITO DE PRIMAVERA DO LESTE',
    short_name: 'COOP DE PRIMAVERA DO LESTE',
  },
  26960328: {
    bank_ispb: '26960328',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO TOCANTINS LTDA',
    short_name: 'CCLA DO TOCANTINS',
  },
  27084098: {
    bank_ispb: '27084098',
    name: 'TRANSFEERA PAGAMENTOS S.A',
    short_name: 'TRANSFEERA IP S.A.',
  },
  27098060: {
    bank_ispb: '27098060',
    name: 'BANCO DIGIO S.A.',
    short_name: 'BANCO DIGIO',
  },
  27302181: {
    bank_ispb: '27302181',
    name: 'COOPERATIVA DE CREDITO DOS SERVIDORES DA UNIVERSIDADE FEDERAL DO ESPIRITO SANTO',
    short_name: 'CRED-UFES',
  },
  27351731: {
    bank_ispb: '27351731',
    name: 'REALIZE CRÉDITO, FINANCIAMENTO E INVESTIMENTO S.A.',
    short_name: 'REALIZE CFI S.A.',
  },
  28127603: {
    bank_ispb: '28127603',
    name: 'BANESTES S.A. BANCO DO ESTADO DO ESPIRITO SANTO',
    short_name: 'BCO BANESTES S.A.',
  },
  28195667: {
    bank_ispb: '28195667',
    name: 'BANCO ABC BRASIL S.A.',
    short_name: 'BCO ABC BRASIL S.A.',
  },
  28220872: {
    bank_ispb: '28220872',
    name: 'VALEPAY BRASIL LTDA',
    short_name: 'VALEPAY',
  },
  28505126: {
    bank_ispb: '28505126',
    name: 'LOGBANK INSTITUIÇÃO DE PAGAMENTO S.A.',
    short_name: 'LOGBANK IP S.A.',
  },
  28683892: {
    bank_ispb: '28683892',
    name: 'PAYMEE BRASIL SERVICOS DE PAGAMENTOS S/A',
    short_name: 'PAYMEE BRASIL',
  },
  29079725: {
    bank_ispb: '29079725',
    name: 'BIT CAPITAL LTDA',
    short_name: 'BIT CAPITAL',
  },
  29415342: {
    bank_ispb: '29415342',
    name: 'CONTA ZAP SOLUCOES EM PAGAMENTOS S.A.',
    short_name: 'CONTA ZAP',
  },
  29759316: {
    bank_ispb: '29759316',
    name: 'SERVNET INSTITUIÇÃO DE PAGAMENTO LTDA.',
    short_name: 'SERVNET IP LTDA.',
  },
  30153943: {
    bank_ispb: '30153943',
    name: 'MONEY CLOUD TECNOLOGIA LTDA',
    short_name: 'MONEY CLOUD',
  },
  30306294: {
    bank_ispb: '30306294',
    name: 'BANCO BTG PACTUAL S.A.',
    short_name: 'BANCO BTG PACTUAL S.A.',
  },
  30723871: {
    bank_ispb: '30723871',
    name: 'BANQI INSTITUICAO DE PAGAMENTO LTDA. ',
    short_name: 'BANQI',
  },
  30723886: {
    bank_ispb: '30723886',
    name: 'BANCO MODAL S.A.',
    short_name: 'BCO MODAL S.A.',
  },
  30765018: {
    bank_ispb: '30765018',
    name: 'GALAX PAY PAGAMENTOS ELETRONICOS LTDA',
    short_name: 'Galax Pay',
  },
  30980539: {
    bank_ispb: '30980539',
    name: 'U4C INSTITUIÇÃO DE PAGAMENTO S.A.',
    short_name: 'U4C INSTITUIÇÃO DE PAGAMENTO S.A.',
  },
  31531997: {
    bank_ispb: '31531997',
    name: 'CONPAY TECNOLOGIA E PAGAMENTOS S.A',
    short_name: 'CONPAY',
  },
  31680151: {
    bank_ispb: '31680151',
    name: 'SWAP MEIOS DE PAGAMENTOS INSTITUICAO DE PAGAMENTO S.A.',
    short_name: 'SWAP MP IP SA',
  },
  31711972: {
    bank_ispb: '31711972',
    name: 'S3 TECNOLOGIA E SOLUCOES DE PAGAMENTOS S.A.',
    short_name: 'S3 TECNOLOGIA E SOLUCOES DE PA',
  },
  31752308: {
    bank_ispb: '31752308',
    name: 'PHI SERVICOS DE PAGAMENTO S.A.',
    short_name: 'PHI PAGAMENTOS',
  },
  31804966: {
    bank_ispb: '31804966',
    name: 'COOPERATIVA DE CRÉDITO COOPERMAIS - SICOOB COOPERMAIS',
    short_name: 'SICOOB COOPERMAIS',
  },
  31815293: {
    bank_ispb: '31815293',
    name: 'COOPERATIVA DE CRÉDITO NORTE DO ESPÍRITO SANTO - SICOOB NORTE',
    short_name: 'SICOOB NORTE',
  },
  31818873: {
    bank_ispb: '31818873',
    name: 'QESH INSTITUICAO DE PAGAMENTO LTDA',
    short_name: 'QESH IP LTDA.',
  },
  31872495: {
    bank_ispb: '31872495',
    name: 'BANCO C6 S.A.',
    short_name: 'BCO C6 S.A.',
  },
  31880826: {
    bank_ispb: '31880826',
    name: 'BANCO GUANABARA S.A.',
    short_name: 'BCO GUANABARA S.A.',
  },
  31895683: {
    bank_ispb: '31895683',
    name: 'BANCO INDUSTRIAL DO BRASIL S.A.',
    short_name: 'BCO INDUSTRIAL DO BRASIL S.A.',
  },
  32024691: {
    bank_ispb: '32024691',
    name: 'COMPANHIA GLOBAL DE SOLUCOES E SERVICOS DE PAGAMENTOS S.A.',
    short_name: 'DOTZ',
  },
  32074986: {
    bank_ispb: '32074986',
    name: 'BEETELLERPAY INSTITUICAO DE PAGAMENTO LTDA',
    short_name: 'BEETELLERPAY IP',
  },
  32192325: {
    bank_ispb: '32192325',
    name: 'UZZIPAY INSTITUIÇÃO DE PAGAMENTO S.A.',
    short_name: 'UZZIPAY IP S.A.',
  },
  32219232: {
    bank_ispb: '32219232',
    name: 'NUPAY FOR BUSINESS INSTITUICAO DE PAGAMENTO LTDA.',
    short_name: 'NUPAY IP LTDA.',
  },
  32246162: {
    bank_ispb: '32246162',
    name: 'ALIPAY BRASIL MEIOS DE PAGAMENTO LTDA.',
    short_name: 'ALIPAY',
  },
  32402502: {
    bank_ispb: '32402502',
    name: 'QI SOCIEDADE DE CRÉDITO DIRETO S.A.',
    short_name: 'QI SCD S.A.',
  },
  32428294: {
    bank_ispb: '32428294',
    name: 'COOPERATIVA CENTRAL DE CREDITO DO ESPIRITO SANTO - SICOOB CENTRAL ES',
    short_name: 'CCC DO ESP.SANTO - SICOOB ES',
  },
  32430233: {
    bank_ispb: '32430233',
    name: 'COOPERATIVA DE CRÉDITO CONEXÃO - SICOOB CONEXÃO',
    short_name: 'SICOOB CONEXÃO',
  },
  32467086: {
    bank_ispb: '32467086',
    name: 'COOPERATIVA DE CRÉDITO SUL DO ESPÍRITO SANTO - SICOOB SUL',
    short_name: 'SICOOB SUL',
  },
  32474884: {
    bank_ispb: '32474884',
    name: 'COOPERATIVA DE CRÉDITO SUL-LITORÂNEA DO ESPÍRITO SANTO',
    short_name: 'SICOOB SUL-LITORÂNEO',
  },
  32615247: {
    bank_ispb: '32615247',
    name: 'COOPERATIVA DE CREDITO SICOOB COOPEC LTDA.',
    short_name: 'CC SICOOB COOPEC LTDA.',
  },
  32778350: {
    bank_ispb: '32778350',
    name: 'AME DIGITAL BRASIL INSTITUICAO DE PAGAMENTO LTDA',
    short_name: 'AME DIGITAL BRASIL IP LTDA.',
  },
  32914717: {
    bank_ispb: '32914717',
    name: 'ONBANK SOLUCOES FINANCEIRAS SA',
    short_name: 'ONBANK',
  },
  32983165: {
    bank_ispb: '32983165',
    name: 'COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO VALE DO CERRADO - SICREDI VALE DO CERRADO',
    short_name: 'COOP SICREDI VL DO CERRADO',
  },
  32995755: {
    bank_ispb: '32995755',
    name: 'COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DO SUDOESTE MT/PA - SICREDI SUDOESTE MT/PA',
    short_name: 'COOP SICREDI SUDOESTE MT/PA',
  },
  33021064: {
    bank_ispb: '33021064',
    name: 'COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DO ARAGUAIA E XINGU - SICREDI ARAXINGU',
    short_name: 'COOP SICREDI ARAXINGÚ',
  },
  33022690: {
    bank_ispb: '33022690',
    name: 'COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DO NOROESTE DE MATO GROSSO, ACRE E AMAZONAS - SICREDI BIOMAS',
    short_name: 'COOP SICREDI BIOMAS',
  },
  33053580: {
    bank_ispb: '33053580',
    name: 'ECOMOVI SOLUCOES E SERVICOS EM PAGAMENTOS LTDA',
    short_name: 'ECOMOVI',
  },
  33172537: {
    bank_ispb: '33172537',
    name: 'BANCO J.P. MORGAN S.A.',
    short_name: 'BCO J.P. MORGAN S.A.',
  },
  33264668: {
    bank_ispb: '33264668',
    name: 'BANCO XP S.A.',
    short_name: 'BCO XP S.A.',
  },
  33370115: {
    bank_ispb: '33370115',
    name: 'COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS EMPREGADOS DE FURNAS E DAS DEMAIS EMPRESAS DO SISTEMA ELETROBRÁS LTDA. - SICOOB CECREMEF',
    short_name: 'FURNAS',
  },
  33416108: {
    bank_ispb: '33416108',
    name: 'COOPERATIVA CENTRAL DE CRÉDITO DE GOIÁS, DISTRITO FEDERAL E TOCANTINS LTDA. - SICOOB NOVA CENTRAL',
    short_name: 'COOPCENTRAL SICOOB NOVA CENTRAL LTDA.',
  },
  33479023: {
    bank_ispb: '33479023',
    name: 'BANCO CITIBANK S.A.',
    short_name: 'BCO CITIBANK S.A.',
  },
  33579731: {
    bank_ispb: '33579731',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO CENTRO-SUL GOIANO LTDA',
    short_name: 'CCLA CENTRO-SUL GOIANO',
  },
  33615055: {
    bank_ispb: '33615055',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE ANÁPOLIS E REGIÃO LTDA',
    short_name: 'CCLA ANÁPOLIS E REGIÃO',
  },
  33923798: {
    bank_ispb: '33923798',
    name: 'BANCO MASTER S/A',
    short_name: 'BANCO MASTER',
  },
  33924028: {
    bank_ispb: '33924028',
    name: 'COOPERATIVA DE CRÉDITO CLÁSSICA DOS EMPREGADOS DA VALE S/A E ENTIDADES VINCULADAS LTDA. - SICOOB COOPVALE',
    short_name: 'SICOOB COOPVALE',
  },
  34088029: {
    bank_ispb: '34088029',
    name: 'LISTO SOCIEDADE DE CREDITO DIRETO S.A.',
    short_name: 'LISTO SCD S.A.',
  },
  34148882: {
    bank_ispb: '34148882',
    name: 'COOPERATIVA CENTRAL DE CRÉDITO DA BAHIA LTDA - SICOOB CENTRAL BA',
    short_name: 'CC SICOOB CENTRAL BA',
  },
  34335592: {
    bank_ispb: '34335592',
    name: 'ÓTIMO SOCIEDADE DE CRÉDITO DIRETO S.A.',
    short_name: 'ÓTIMO SCD S.A.',
  },
  34678263: {
    bank_ispb: '34678263',
    name: 'KREDIT BANK INSTITUICAO DE PAGAMENTOS S/A',
    short_name: 'KREDIT BANK',
  },
  34747388: {
    bank_ispb: '34747388',
    name: 'ISSUER INSTITUICAO DE PAGAMENTO LTDA.',
    short_name: 'ISSUER IP LTDA.',
  },
  34878543: {
    bank_ispb: '34878543',
    name: 'CLARO PAY S/A',
    short_name: 'CLARO PAY',
  },
  35210410: {
    bank_ispb: '35210410',
    name: 'MOOVE MONEY INSTITUICAO DE PAGAMENTO S.A.',
    short_name: '900PAY',
  },
  35210425: {
    bank_ispb: '35210425',
    name: 'SOLARPAY INSTITUICAO DE PAGAMENTOS S.A.',
    short_name: 'SOL E MAR',
  },
  35480428: {
    bank_ispb: '35480428',
    name: 'SKILOPAY INTERMEDIACOES E SOLUCOES FINANCEIRAS LTDA',
    short_name: 'SKILOPAY INTERMEDIACOES E SOLU',
  },
  35523352: {
    bank_ispb: '35523352',
    name: 'BEES INSTITUICAO DE PAGAMENTO LTDA.',
    short_name: 'BEES BANK INSTITUIÇÃO DE PAGAM',
  },
  35542555: {
    bank_ispb: '35542555',
    name: 'UNLIMINT BRASIL SERVICOS DE INTERNET LTDA.',
    short_name: 'CARDPAY',
  },
  35571249: {
    bank_ispb: '35571249',
    name: 'COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO SICREDI EVOLUÇÃO - SICREDI EVOLUÇÃO',
    short_name: 'COOP SICREDI EVOLUÇÃO',
  },
  35624319: {
    bank_ispb: '35624319',
    name: 'MK DIGITAL BANK S.A.',
    short_name: 'MK DIGITAL ',
  },
  35810871: {
    bank_ispb: '35810871',
    name: 'Z1 PAGAMENTOS E NEGOCIOS LTDA',
    short_name: 'Z1 IP LTDA.',
  },
  36272465: {
    bank_ispb: '36272465',
    name: 'WILL S.A. INSTITUICAO DE PAGAMENTO',
    short_name: 'WILL',
  },
  36586946: {
    bank_ispb: '36586946',
    name: 'BONUSPAGO SOCIEDADE DE CRÉDITO DIRETO S.A.',
    short_name: 'BONUSPAGO SCD S.A.',
  },
  36900256: {
    bank_ispb: '36900256',
    name: 'COOPERATIVA DE CRÉDITO DOS MÉDICOS, PROFISSIONAIS DA SAÚDE E EMPRESÁRIOS DE MATO GROSSO',
    short_name: 'CCM MED EMP MT',
  },
  36947229: {
    bank_ispb: '36947229',
    name: 'COBUCCIO S/A - SOCIEDADE DE CRÉDITO, FINANCIAMENTO E INVESTIMENTOS',
    short_name: 'COBUCCIO S.A. SCFI',
  },
  37076205: {
    bank_ispb: '37076205',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE ASSOCIADOS LTDA. - SICOOB JUDICIÁRIO',
    short_name: 'SICOOB JUDICIÁRIO',
  },
  37079720: {
    bank_ispb: '37079720',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO LTDA. - SICOOB CREDIJUSTRA',
    short_name: 'CCLA SICOOB CREDIJUSTRA',
  },
  37241230: {
    bank_ispb: '37241230',
    name: 'SUMUP SOCIEDADE DE CRÉDITO DIRETO S.A.',
    short_name: 'SUMUP SCD S.A.',
  },
  37255049: {
    bank_ispb: '37255049',
    name: 'SICOOB CREDIGOIÁS COOPERATIVA DE CRÉDITO LTDA.',
    short_name: 'SICOOB CREDIGOIÁS CC',
  },
  37395399: {
    bank_ispb: '37395399',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO CENTRO BRASILEIRA LTDA.',
    short_name: 'CCLA CENTRO BRASILEIRA LTDA.',
  },
  37442605: {
    bank_ispb: '37442605',
    name: 'COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO NORTE MATO-GROSSENSE E OESTE PARAENSE - SICREDI GRANDES RIOS MT/PA/AM',
    short_name: 'COOP SICREDI GRANDES RIOS',
  },
  37511258: {
    bank_ispb: '37511258',
    name: 'INOVANTI INSTITUICAO DE PAGAMENTOS S.A.',
    short_name: 'INOVANTI',
  },
  37554441: {
    bank_ispb: '37554441',
    name: 'COOPERATIVA DE CRÉDITO SICOOB UNI SUL MS',
    short_name: 'COOP SICOOB UNI SUL MS',
  },
  37631058: {
    bank_ispb: '37631058',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE PALMEIRAS E REGIÃO LTDA.',
    short_name: 'CCLA DE PALMEIRAS E REGIÃO',
  },
  37715993: {
    bank_ispb: '37715993',
    name: 'ACCREDITO - SOCIEDADE DE CRÉDITO DIRETO S.A.',
    short_name: 'ACCREDITO SCD S.A.',
  },
  37880206: {
    bank_ispb: '37880206',
    name: 'CORA SOCIEDADE DE CRÉDITO DIRETO S.A.',
    short_name: 'CORA SCD S.A.',
  },
  38129006: {
    bank_ispb: '38129006',
    name: 'NUMBRS SOCIEDADE DE CRÉDITO DIRETO S.A.',
    short_name: 'NUMBRS SCD S.A.',
  },
  38224857: {
    bank_ispb: '38224857',
    name: 'DELCRED SOCIEDADE DE CRÉDITO DIRETO S.A.',
    short_name: 'DELCRED SCD S.A.',
  },
  38406148: {
    bank_ispb: '38406148',
    name: 'CHRISTIAN FAMILY BANK S/A',
    short_name: 'CF BANK',
  },
  38460917: {
    bank_ispb: '38460917',
    name: 'MACRE EMPREENDIMENTOS FINANCEIROS LTDA',
    short_name: 'MACREBANK',
  },
  38588174: {
    bank_ispb: '38588174',
    name: 'COOPERATIVA DE CRéDITO CREDICOPE LTDA. - SICOOB CREDICOPE',
    short_name: 'CC CREDICOPE',
  },
  39231527: {
    bank_ispb: '39231527',
    name: 'UNICRED COSTA DO SOL RJ - COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO LTDA.',
    short_name: 'UNICRED COSTA DO SOL RJ',
  },
  39343350: {
    bank_ispb: '39343350',
    name: 'LAR COOPERATIVA DE CRÉDITO - LAR CREDI',
    short_name: 'CC LAR CREDI',
  },
  39587424: {
    bank_ispb: '39587424',
    name: 'UY3 SOCIEDADE DE CRÉDITO DIRETO S/A',
    short_name: 'UY3 SCD S/A',
  },
  39664698: {
    bank_ispb: '39664698',
    name: 'CREDSYSTEM SOCIEDADE DE CRÉDITO DIRETO S.A.',
    short_name: 'CREDSYSTEM SCD S.A.',
  },
  39676772: {
    bank_ispb: '39676772',
    name: 'CREDIFIT SOCIEDADE DE CRÉDITO DIRETO S.A.',
    short_name: 'CREDIFIT SCD S.A.',
  },
  39908427: {
    bank_ispb: '39908427',
    name: 'STARK SOCIEDADE DE CRÉDITO DIRETO S.A.',
    short_name: 'STARK SCD S.A.',
  },
  40083667: {
    bank_ispb: '40083667',
    name: 'CAPITAL CONSIG SOCIEDADE DE CRÉDITO DIRETO S.A.',
    short_name: 'CAPITAL CONSIG SCD S.A.',
  },
  40473435: {
    bank_ispb: '40473435',
    name: 'REPASSES FINANCEIROS E SOLUCOES TECNOLOGICAS INSTITUICAO DE PAGAMENTO S.A.',
    short_name: 'REPASSES FINANCEIROS',
  },
  40654622: {
    bank_ispb: '40654622',
    name: 'TRINUS SOCIEDADE DE CRÉDITO DIRETO S.A.',
    short_name: 'TRINUS SCD S.A.',
  },
  40820176: {
    bank_ispb: '40820176',
    name: 'DONA FAVORITA SERVICOS FINANCEIROS LTDA.',
    short_name: 'PAYBROKERS IP',
  },
  40867163: {
    bank_ispb: '40867163',
    name: 'N26 SOCIEDADE DE CRÉDITO DIRETO S.A.',
    short_name: 'N26 SCD',
  },
  41180092: {
    bank_ispb: '41180092',
    name: 'SICREDI EXPANSÃO - COOPERATIVA DE CRÉDITO',
    short_name: 'COOP SICREDI EXPANSÃO',
  },
  41255225: {
    bank_ispb: '41255225',
    name: 'COOPERATIVA DE CRÉDITO SICREDI CENTRO PERNAMBUCANA',
    short_name: 'COOP SICREDI CENT PERNAMBUCANA',
  },
  41669227: {
    bank_ispb: '41669227',
    name: 'COOPERATIVA DE CRÉDITO DE PRODUTORES RURAIS E DE LIVRE ADMISSÃO DO TRIÂNGULO LTDA    SICOOB CREDITRIL',
    short_name: 'CCLA PROD RURAIS TRIÂNGULO',
  },
  41697103: {
    bank_ispb: '41697103',
    name: 'COOPERATIVA DE CRÉDITO CREDILIVRE LTDA. - SICOOB CREDILIVRE',
    short_name: 'CC CREDILIVRE LTDA. - SICOOB CREDILIVRE',
  },
  41707258: {
    bank_ispb: '41707258',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE SANTO ANTÔNIO DO MONTE LTDA.   SICOOB CREDIMONTE',
    short_name: 'CCLA SANTO ANTONIO DO MONTE',
  },
  41753500: {
    bank_ispb: '41753500',
    name: 'COOPERATIVA DE CRÉDITO CARLOS CHAGAS LTDA. - SICOOB CARLOS CHAGAS',
    short_name: 'CC CARLOS CHAGAS',
  },
  41791005: {
    bank_ispb: '41791005',
    name: 'COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS SERVIDORES MUNICIPAIS DE POÇOS DE CALDAS LTDA. - COOPOÇOS',
    short_name: 'CECM SERV MUN POÇOS DE CALDAS',
  },
  41805003: {
    bank_ispb: '41805003',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO ALTO SÃO FRANCISCO LTDA.',
    short_name: 'CCLA ALTO SÃO FRANCISCO',
  },
  41931221: {
    bank_ispb: '41931221',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE PITANGUI, PAPAGAIOS, MARAVILHAS E REGIÃO LTDA. - SICOOB CREDICOOP',
    short_name: 'SICOOB CREDICOOP',
  },
  41931445: {
    bank_ispb: '41931445',
    name: 'COOPERATIVA DE CRÉDITO CREDIFOR LTDA - SICOOB CREDIFOR',
    short_name: 'CC CREDIFOR LTDA',
  },
  42873828: {
    bank_ispb: '42873828',
    name: 'COOPERATIVA DE CRÉDITO AGROCREDI LTDA. - SICOOB AGROCREDI',
    short_name: 'CC AGROCREDI LTDA',
  },
  42880617: {
    bank_ispb: '42880617',
    name: 'COOPERATIVA DE CRéDITO DE TIROS E MATUTINA LTDA.- SICOOB CREDITIROS',
    short_name: 'CC SICOOB CREDITIROS',
  },
  42887133: {
    bank_ispb: '42887133',
    name: 'COOPERATIVA DE CREDITO DE LIVRE ADMISSÃO DA REGIÃO SUDESTE DE MINAS GERAIS LTDA. - SICOOB CREDISUCESSO',
    short_name: 'CCLA REG SUDESTE MG',
  },
  42898825: {
    bank_ispb: '42898825',
    name: 'SICOOB CREDICOM - COOPERATIVA DE ECONOMIA E CREDITO MUTUO DOS MEDICOS E PROFISSIONAIS DA AREA DE SAUDE DO BRASIL LTDA.',
    short_name: 'CECM MEDICOS E PROFISSIONAIS AREA SAUDE DO BRASIL',
  },
  42934794: {
    bank_ispb: '42934794',
    name: 'COOPERATIVA DE CRÉDITO CREDIPIMENTA LTDA. - SICOOB CREDIPIMENTA',
    short_name: 'CC CREDIPIMENTA',
  },
  43180355: {
    bank_ispb: '43180355',
    name: 'PEFISA S.A. - CRÉDITO, FINANCIAMENTO E INVESTIMENTO',
    short_name: 'PEFISA S.A. - C.F.I.',
  },
  44373041: {
    bank_ispb: '44373041',
    name: 'COOPERATIVA DE CRÉDITO RURAL - CREDICANA',
    short_name: 'CCR CREDICANA',
  },
  44469161: {
    bank_ispb: '44469161',
    name: 'COOPECREDI GUARIBA - COOPERATIVA DE CREDITO',
    short_name: 'COOPECREDI GUARIBA - CC',
  },
  44683140: {
    bank_ispb: '44683140',
    name: 'MAGNUM SOCIEDADE DE CRÉDITO DIRETO S.A.',
    short_name: 'MAGNUM SCD',
  },
  45246410: {
    bank_ispb: '45246410',
    name: 'BANCO GENIAL S.A.',
    short_name: 'BANCO GENIAL',
  },
  45756448: {
    bank_ispb: '45756448',
    name: 'MICROCASH SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORTE LTDA.',
    short_name: 'MICROCASH SCMEPP LTDA.',
  },
  46642294: {
    bank_ispb: '46642294',
    name: 'COOPERATIVA DE CRÉDITO MÚTUO DOS EMPREGADOS DA EMBRAER - COOPEREMB',
    short_name: 'CCM EMPR DA EMBRAER',
  },
  47074323: {
    bank_ispb: '47074323',
    name: 'SICOOB CREDICONSUMO - COOPERATIVA DE CRéDITO',
    short_name: 'SICOOB CREDICONSUMO CC',
  },
  49389307: {
    bank_ispb: '49389307',
    name: 'COOPERATIVA DE CREDITO COOPLIVRE',
    short_name: 'CC COOPLIVRE',
  },
  51489318: {
    bank_ispb: '51489318',
    name: 'COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS MAGISTRADOS DE SÃO PAULO - MAGISCRED',
    short_name: 'CECM MAGISTRADOS DE SÃO PAULO',
  },
  53236808: {
    bank_ispb: '53236808',
    name: 'COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DE LIVRE ADMISSÃO DE ARARAQUARA E REGIÃO - SICOOB',
    short_name: 'CCLA DE ARARAQUARA E REGIÃO',
  },
  53518684: {
    bank_ispb: '53518684',
    name: 'BANCO HSBC S.A.',
    short_name: 'BCO HSBC S.A.',
  },
  53623781: {
    bank_ispb: '53623781',
    name: 'COOPERATIVA DE CRÉDITO COOPCRED',
    short_name: 'CC COOPCRED',
  },
  53935029: {
    bank_ispb: '53935029',
    name: 'COOPERATIVA DE CRÉDITO COOCRELIVRE',
    short_name: 'CCLA SICOOB COOCRELIVRE',
  },
  54037916: {
    bank_ispb: '54037916',
    name: 'COOPERATIVA DE CREDITO CREDICITRUS',
    short_name: 'CC CREDICITRUS',
  },
  54190525: {
    bank_ispb: '54190525',
    name: 'COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS SERVIDORES MUNICIPAIS DA REGIÃO METROPOLITANA DO VALE DO PARAÍBA E LITORAL NORTE - SICOOB-CRESSEM',
    short_name: 'CECM SERV MUN REG METR VALE PARAIBA E LITORAL NORTE',
  },
  54401286: {
    bank_ispb: '54401286',
    name: 'COOPERATIVA DE CRÉDITO COCRE',
    short_name: 'CC COCRE',
  },
  54403563: {
    bank_ispb: '54403563',
    name: 'BANCO ARBI S.A.',
    short_name: 'BCO ARBI S.A.',
  },
  54603022: {
    bank_ispb: '54603022',
    name: 'COOPERATIVA DE CREDITO CECRES - SICOOB CECRES',
    short_name: 'CC CECRES',
  },
  57563728: {
    bank_ispb: '57563728',
    name: 'COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS EMPREGADOS DAS EMPRESAS DO GRUPO ECONÔMICO RHODIA - CREDIRHODIA',
    short_name: 'CECME EMPR EMP GR ECON RHODIA',
  },
  57647653: {
    bank_ispb: '57647653',
    name: 'COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DA REGIÃO DAS FLORES, DAS ÁGUAS E DOS VENTOS SP - SICREDI FORÇA DOS VENTOS SP',
    short_name: 'COOP SICREDI FORÇA DOS VENTOS',
  },
  58022195: {
    bank_ispb: '58022195',
    name: 'COPERCRED - COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS EMPREGADOS DAS EMPRESAS ZILLO LORENZETTI',
    short_name: 'CECM EMPR EMP ZILLO LORENZETTI',
  },
  58160789: {
    bank_ispb: '58160789',
    name: 'BANCO SAFRA S.A.',
    short_name: 'BCO SAFRA S.A.',
  },
  58497702: {
    bank_ispb: '58497702',
    name: 'BANCO LETSBANK S.A.',
    short_name: 'BCO LETSBANK S.A.',
  },
  58616418: {
    bank_ispb: '58616418',
    name: 'BANCO FIBRA S.A.',
    short_name: 'BCO FIBRA S.A.',
  },
  59118133: {
    bank_ispb: '59118133',
    name: 'BANCO LUSO BRASILEIRO S.A.',
    short_name: 'BCO LUSO BRASILEIRO S.A.',
  },
  59285411: {
    bank_ispb: '59285411',
    name: 'BANCO PAN S.A.',
    short_name: 'BANCO PAN',
  },
  59588111: {
    bank_ispb: '59588111',
    name: 'BANCO VOTORANTIM S.A.',
    short_name: 'BCO VOTORANTIM S.A.',
  },
  59869560: {
    bank_ispb: '59869560',
    name: 'COOPERATIVA DE CREDITO NOSSO - SICOOB NOSSO',
    short_name: 'CC NOSSO - SICOOB NOSSO',
  },
  60701190: {
    bank_ispb: '60701190',
    name: 'ITAÚ UNIBANCO S.A.',
    short_name: 'ITAÚ UNIBANCO S.A.',
  },
  60746948: {
    bank_ispb: '60746948',
    name: 'BANCO BRADESCO S.A.',
    short_name: 'BCO BRADESCO S.A.',
  },
  60779196: {
    bank_ispb: '60779196',
    name: 'CREFISA S.A.-CREDITO, FINANCIAMENTO E INVESTIMENTOS',
    short_name: 'CREFISA S.A. CFI',
  },
  60850229: {
    bank_ispb: '60850229',
    name: 'OMNI BANCO S.A.',
    short_name: 'OMNI BANCO S.A.',
  },
  60889128: {
    bank_ispb: '60889128',
    name: 'BANCO SOFISA S.A.',
    short_name: 'BCO SOFISA S.A.',
  },
  61024352: {
    bank_ispb: '61024352',
    name: 'BANCO VOITER S.A.',
    short_name: 'BANCO VOITER',
  },
  61033106: {
    bank_ispb: '61033106',
    name: 'BANCO CREFISA S.A.',
    short_name: 'BCO CREFISA S.A.',
  },
  61186680: {
    bank_ispb: '61186680',
    name: 'BANCO BMG S.A.',
    short_name: 'BCO BMG S.A.',
  },
  62073200: {
    bank_ispb: '62073200',
    name: 'BANK OF AMERICA MERRILL LYNCH BANCO MÚLTIPLO S.A.',
    short_name: 'BOFA MERRILL LYNCH BM S.A.',
  },
  62109566: {
    bank_ispb: '62109566',
    name: 'CREDISAN COOPERATIVA DE CRÉDITO',
    short_name: 'CREDISAN CC',
  },
  62232889: {
    bank_ispb: '62232889',
    name: 'BANCO DAYCOVAL S.A.',
    short_name: 'BCO DAYCOVAL S.A',
  },
  62673470: {
    bank_ispb: '62673470',
    name: 'COOPERATIVA DE CRÉDITO SICOOB COOPMIL',
    short_name: 'CC SICOOB COOPMIL',
  },
  62931522: {
    bank_ispb: '62931522',
    name: 'CENTRAL DAS COOPERATIVAS DE CRÉDITO DO ESTADO DE SÃO PAULO - SICOOB CENTRAL CECRESP',
    short_name: 'CCC ESTADO SP - CECRESP',
  },
  63229629: {
    bank_ispb: '63229629',
    name: 'COOPERATIVA DE CREDITO RURAL ASCOOB SISAL',
    short_name: 'CCR ASCOOB SISAL',
  },
  63917579: {
    bank_ispb: '63917579',
    name: 'COOPERATIVA CENTRAL DE CRÉDITO DO ESTADO DE SÃO PAULO - SICOOB SÃO PAULO',
    short_name: 'CCC DO ESTADO DE SÃO PAULO',
  },
  64237530: {
    bank_ispb: '64237530',
    name: 'COOPERATIVA DE CRÉDITO CREDIUNA LTDA - SICOOB CREDIUNA',
    short_name: 'CC CREDIUNA LTDA',
  },
  64276058: {
    bank_ispb: '64276058',
    name: 'COOPERATIVA DE CRÉDITO CREDINORTE LTDA. - SICOOB CREDINORTE',
    short_name: 'CC CREDINORTE - SICOOB CREDINORTE',
  },
  64480833: {
    bank_ispb: '64480833',
    name: 'COOPERATIVA DE CREDITO DE LIVRE ADMISSÃO DE MARTINHO CAMPOS LTDA - SICOOB CREDIMAC',
    short_name: 'CCLA MARTINHO CAMPOS',
  },
  64739121: {
    bank_ispb: '64739121',
    name: 'SICOOB CRED COPERCANA COOPERATIVA DE CRÉDITO',
    short_name: 'SICOOB CRED COPERCANA',
  },
  65229254: {
    bank_ispb: '65229254',
    name: 'COOPERATIVA DE CRÉDITO COOPACREDI LTDA. - SICOOB COOPACREDI',
    short_name: 'CC COOPACREDI',
  },
  65239402: {
    bank_ispb: '65239402',
    name: 'COOPERATIVA DE CRÉDITO UNIÃO DO CENTRO OESTE DE MINAS LTDA. - SICOOB CENTRO UNIÃO',
    short_name: 'CC UNIÃO DO CENTRO OESTE DE MINAS',
  },
  66259110: {
    bank_ispb: '66259110',
    name: 'COOPERATIVA DE CRéDITO CREDIBAM LTDA. - SICOOB CREDIBAM',
    short_name: 'CC CREDIBAM LTDA.',
  },
  66262643: {
    bank_ispb: '66262643',
    name: 'COOPERATIVA DE CRÉDITO CREDIPEU LTDA.  - SICOOB CREDIPEU',
    short_name: 'CCLA DE POMPEU LTDA.',
  },
  66398496: {
    bank_ispb: '66398496',
    name: 'COOPERATIVA DE CRÉDITO CREDIARA LTDA - SICOOB CREDIARA',
    short_name: 'CC CREDIARA LTDA.',
  },
  66402207: {
    bank_ispb: '66402207',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE SÃO ROQUE DE MINAS LTDA.',
    short_name: 'CCLA DE SÃO ROQUE DE MINAS LTDA.',
  },
  66463407: {
    bank_ispb: '66463407',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE ITAÚNA E REGIÃO LTDA - SICOOB CENTRO-OESTE',
    short_name: 'CCLA DE ITAÚNA E REGIÃO LTDA',
  },
  66788142: {
    bank_ispb: '66788142',
    name: 'COOPERATIVA DE CREDITO CREDIMOTA   SICOOB CREDIMOTA',
    short_name: 'CC CREDIMOTA',
  },
  67096909: {
    bank_ispb: '67096909',
    name: 'COOPERATIVA DE CRÉDITO CREDICOCAPEC',
    short_name: 'CC CREDICOCAPEC',
  },
  67915868: {
    bank_ispb: '67915868',
    name: 'COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS TRABALHADORES DA CIA. DE PROCESSAMENTO DE DADOS DO ESTADO DE SÃO PAULO - CREDIPRODESP',
    short_name: 'CECM TRAB CIA PROC DADOS SP',
  },
  67960229: {
    bank_ispb: '67960229',
    name: 'COOPERATIVA DE CRÉDITO CREDIGUAÇU - SICOOB CREDIGUAÇU',
    short_name: 'CC CREDIGUAÇU',
  },
  68512748: {
    bank_ispb: '68512748',
    name: 'COOPERATIVA DE CRÉDITO CREDIESMERALDAS LTDA. - SICOOB CREDIESMERALDAS',
    short_name: 'CC CREDIESMERALDAS LTDA.',
  },
  68900810: {
    bank_ispb: '68900810',
    name: 'BANCO RENDIMENTO S.A.',
    short_name: 'BCO RENDIMENTO S.A.',
  },
  68969625: {
    bank_ispb: '68969625',
    name: 'COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS EMPREGADOS DAS EMPRESAS CONTROLADAS E COLIGADAS DA USINA SANTA ADELIA - USAGRO',
    short_name: 'CECM EMP USAGRO',
  },
  69346856: {
    bank_ispb: '69346856',
    name: 'COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS PROFISSIONAIS DA SAÚDE DA REGIÃO DA ALTA MOGIANA - SICOOB CREDIMOGIANA',
    short_name: 'CECM DOS PROFISSIONAIS DA SAUDE DA REG ALTA MOGIANA',
  },
  70038237: {
    bank_ispb: '70038237',
    name: 'COOPERATIVA DE CRÉDITO - SICREDI RIO GRANDE DO NORTE',
    short_name: 'COOP SICREDI RIO GRANDE DO NORTE',
  },
  70116611: {
    bank_ispb: '70116611',
    name: 'CENTRAL DAS COOPERATIVAS DE CRÉDITO DO NORDESTE - SICOOB CENTRAL NE',
    short_name: 'SICOOB CENTRAL NE',
  },
  70241658: {
    bank_ispb: '70241658',
    name: 'SICREDI RECIFE - COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO SICREDI RECIFE',
    short_name: 'SICREDI RECIFE',
  },
  70431630: {
    bank_ispb: '70431630',
    name: 'COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO UNIVALES - SICREDI UNIVALES MT/RO',
    short_name: 'COOP SICREDI UNIVALES MT/RO',
  },
  70937271: {
    bank_ispb: '70937271',
    name: 'COOPERATIVA DE CRÉDITO DOS PROFISSIONAIS DA ÁREA DA SAÚDE E DE LIVRE ADMISSÃO LTDA - UNICRED ALIANÇA',
    short_name: 'CCLA PROF SAÚDE UNICRED ALIANÇA',
  },
  71027866: {
    bank_ispb: '71027866',
    name: 'BANCO BS2 S.A.',
    short_name: 'BCO BS2 S.A.',
  },
  71069587: {
    bank_ispb: '71069587',
    name: 'COOPERATIVA DE CRÉDITO CREDPLUS LTDA. - SICOOB CREDPLUS',
    short_name: 'CC CREDPLUS - SICOOB CREDPLUS',
  },
  71146450: {
    bank_ispb: '71146450',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE CAMPOS GERAIS E CAMPO DO MEIO LTDA - SICOOB CREDCAM',
    short_name: 'CCLA CAMPOS GERAIS E CAMPO DO',
  },
  71154256: {
    bank_ispb: '71154256',
    name: 'COOPERATIVA DE CRÉDITO SACRAMENTO LTDA - SICOOB SACRAMENTO',
    short_name: 'CC SACRAMENTO',
  },
  71154876: {
    bank_ispb: '71154876',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO NOROESTE DE MINAS GERAIS LTDA.   SICOOB CREDIPINHO',
    short_name: 'CCLA NOROESTE DE MG CREDIPINHO',
  },
  71163315: {
    bank_ispb: '71163315',
    name: 'COOPERATIVA DE CRÉDITO MÚTUO DOS PROFISSIONAIS DE NÍVEL SUPERIOR DA SAÚDE, ARQUITETOS E URBANISTAS DE SETE LAGOAS E REGIÃO LTDA - UNICRED SETE LAGOAS',
    short_name: 'CCM PROF SAÚDE ARQ SETE LAGOAS',
  },
  71207740: {
    bank_ispb: '71207740',
    name: 'COOPERATIVA DE CRÉDITO RURAL DE IRAÍ DE MINAS LTDA -SICOOB CREDIMIL',
    short_name: 'CCR IRAI - SICOOB CREDIMIL',
  },
  71230338: {
    bank_ispb: '71230338',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE ARCOS LTDA. - SICOOB ARCOMCREDI',
    short_name: 'CCLA ARCOS',
  },
  71237184: {
    bank_ispb: '71237184',
    name: 'COOPERATIVA DE CRÉDITO CREDIBELO LTDA. - SICOOB CREDIBELO',
    short_name: 'CC CREDIBELO LTDA. - SICOOB CREDIBELO',
  },
  71238232: {
    bank_ispb: '71238232',
    name: 'COOPERATIVA DE CRÉDITO DO CENTRO SUL MINEIRO LTDA. - SICOOB CENTRO SUL MINEIRO',
    short_name: 'CC CENTRO SUL MINEIRO',
  },
  71243034: {
    bank_ispb: '71243034',
    name: 'COOPERATIVA DE CRÉDITO CREDIJEQUITINHONHA LTDA. - SICOOB CREDIJEQUITINHONHA',
    short_name: 'COOPERATIVA DE CRÉDITO CREDIJEQUITINHONHA LTDA.',
  },
  71261184: {
    bank_ispb: '71261184',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DA REGIÃO DO CAPARAÓ LTDA. - SICOOB SAÚDE',
    short_name: 'CCLA REGIÃO DO CAPARAÓ',
  },
  71297899: {
    bank_ispb: '71297899',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO OESTE MINEIRO LTDA. - SICOOB CREDICOPA',
    short_name: 'CCLA OESTE MINEIRO LTDA-SICOOB',
  },
  71328769: {
    bank_ispb: '71328769',
    name: 'SICOOB COCRED COOPERATIVA DE CRÉDITO',
    short_name: 'SICOOB COCRED CC',
  },
  71336432: {
    bank_ispb: '71336432',
    name: 'SICOOB CREDIMED - COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DA CIDADE DE UBERABA LTDA.',
    short_name: 'SICOOB CREDIMED - CCLA DE UBERABA LTDA',
  },
  71392047: {
    bank_ispb: '71392047',
    name: 'COOPERATIVA DE CRÉDITO MONTECREDI LTDA. - SICOOB MONTECREDI',
    short_name: 'CC MONTECREDI LTDA.',
  },
  71418784: {
    bank_ispb: '71418784',
    name: 'COOPERATIVA DE CRÉDITO DOS MÉDICOS, TÉCNICOS E DEMAIS PROFISSIONAIS DA SAÚDE DO SUL DE MINAS LTDA., UNICRED SUL DE MINAS',
    short_name: 'CC MED, TEC E DEMAIS PROF SAUDE DO SUL DE MINAS',
  },
  71419600: {
    bank_ispb: '71419600',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DA REGIÃO DE FRUTAL LTDA.   SICOOB FRUTAL',
    short_name: 'CCLA DA REGIÃO DE FRUTAL',
  },
  71432926: {
    bank_ispb: '71432926',
    name: 'COOPERATIVA DE CRÉDITO DOS PROFISSIONAIS DE SAÚDE E DE LIVRE ADMISSÃO DO TRIÂNGULO MINEIRO E SUL DE MINAS LTDA. - UNICRED PROGRESSO',
    short_name: 'CCPS E LA DO TRIANG MINEIRO E SUL DE MG LTDA',
  },
  71441406: {
    bank_ispb: '71441406',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE PEDRO LEOPOLDO LTDA - SICOOB CREDIPEL',
    short_name: 'CCLA PEDRO LEOPOLDO',
  },
  71479653: {
    bank_ispb: '71479653',
    name: 'COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS MÉDICOS E DEMAIS PROFISSIONAIS DA ÁREA DE SAÚDE DE NÍVEL SUPERIOR DE JUIZ DE FORA LTDA. - UNICRED CAMINHO NOVO',
    short_name: 'UNICRED CAMINHO NOVO',
  },
  71506513: {
    bank_ispb: '71506513',
    name: 'COOPERATIVA DE CRÉDITO SICOOB ITAPAGIPE LTDA - SICOOB ITAPAGIPE',
    short_name: 'CC SICOOB ITAPAGIPE',
  },
  71698674: {
    bank_ispb: '71698674',
    name: 'SICOOB UNIMAIS MANTIQUEIRA - COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO',
    short_name: 'SICOOB UNIMAIS MANTIQUEIRA CCLA',
  },
  71884498: {
    bank_ispb: '71884498',
    name: 'COOPERATIVA DE CRÉDITO DOS MÉDICOS, DENTISTAS, PROFISSIONAIS DA ÁREA DE SAÚDE E DE LIVRE ADMISSÃO DO ESTADO DE SÃO PAULO LTDA. - UNICRED DO ESTADO DE SÃO PAULO',
    short_name: 'CCLA UNICRED DO ESTADO DE SÃO PAULO',
  },
  72128440: {
    bank_ispb: '72128440',
    name: 'COOPERATIVA DE CREDITO, POUPANCA E INVESTIMENTO DO RIO DE JANEIRO - SICREDI RIO RJ',
    short_name: 'COOP SICREDI RIO RJ',
  },
  72257793: {
    bank_ispb: '72257793',
    name: 'SICREDI CEARÁ - COOPERATIVA DE CRÉDITO DO ESTADO DO CEARÁ',
    short_name: 'SICREDI CEARÁ',
  },
  73092827: {
    bank_ispb: '73092827',
    name: 'COOPERATIVA DE CRÉDITO MÚTUO DOS SERVIDORES DO MINISTERIO DA EDUCAÇÃO EM SÃO PAULO - COOPEMESP',
    short_name: 'CCM SERV MIN EDUC SÃO PAULO',
  },
  73113243: {
    bank_ispb: '73113243',
    name: 'COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DE ARARAQUARA E REGIÃO - SICREDI CENTRO NORTE SP',
    short_name: 'COOP SICREDI CENTRO NORTE SP',
  },
  73254757: {
    bank_ispb: '73254757',
    name: 'COOPERATIVA DE CREDITO E ECONOMIA COM INTERAÇÃO SOLIDARIA DE QUILOMBO - CRESOL QUILOMBO',
    short_name: 'COOP CRESOL QUILOMBO',
  },
  73326449: {
    bank_ispb: '73326449',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE ASSOCIADOS - SICOOB CRUZ ALTA',
    short_name: 'COOP LIVRE ADMISSÃO DE ASSOCIADOS SICOOB CRUZ ALTA LTDA',
  },
  73398646: {
    bank_ispb: '73398646',
    name: 'SOCIEDADE COOPERATIVA DE CRÉDITO COOPERE LTDA.  - SICOOB COOPERE',
    short_name: 'SOC CC SICOOB COOPERE',
  },
  73422792: {
    bank_ispb: '73422792',
    name: 'COOPERATIVA DE CREDITO DE LIVRE ADMISSAO DE RUBIATABA E REGIAO LTDA',
    short_name: 'CCLA RUBIATABA REGIAO',
  },
  73443863: {
    bank_ispb: '73443863',
    name: 'COOPERATIVA DE CRÉDITO UNICRED VALE LTDA - UNICRED VALE',
    short_name: 'COOP UNICRED VALE LTDA.',
  },
  73647935: {
    bank_ispb: '73647935',
    name: 'COOPERATIVA DE CRÉDITO UNIQUE BR',
    short_name: 'COOP UNIQUE BR LTDA.',
  },
  73750424: {
    bank_ispb: '73750424',
    name: 'COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO UNICRED INTEGRAÇÃO LTDA.',
    short_name: 'CECM UNICRED INTEGRAÇÃO',
  },
  74064502: {
    bank_ispb: '74064502',
    name: 'COOPERATIVA DE CRÉDITO UNICRED VALOR CAPITAL LTDA - UNICRED VALOR CAPITAL',
    short_name: 'CC UNICRED VALOR CAPITAL LTDA - UNICRED VALOR CAPITAL',
  },
  74114042: {
    bank_ispb: '74114042',
    name: 'COOPERATIVA DE CRÉDITO UNICRED UNIÃO LTDA - UNICRED UNIÃO',
    short_name: 'CC UNICRED UNIÃO LTDA - UNICRED UNIÃO',
  },
  74220948: {
    bank_ispb: '74220948',
    name: 'SICOOB UNISP - COOPERATIVA DE CRÉDITO',
    short_name: 'CC SICOOB UNISP',
  },
  76059997: {
    bank_ispb: '76059997',
    name: 'COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO PROGRESSO - SICREDI PROGRESSO PR/SP',
    short_name: 'COOP SICREDI PROGRESSO PR/SP',
  },
  76461557: {
    bank_ispb: '76461557',
    name: 'COOPERATIVA DE CRÉDITO RURAL COOPAVEL',
    short_name: 'CCR COOPAVEL',
  },
  77984870: {
    bank_ispb: '77984870',
    name: 'COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO TERRA DOS PINHEIRAIS DO PARANÁ E NOROESTE PAULISTA - SICREDI PLANALTO DAS ÁGUAS PR/SP',
    short_name: 'COOP SICREDI PLANALT DAS ÁGUAS',
  },
  78157146: {
    bank_ispb: '78157146',
    name: 'CREDIALIANÇA COOPERATIVA DE CRÉDITO RURAL',
    short_name: 'CREDIALIANÇA CCR',
  },
  78414067: {
    bank_ispb: '78414067',
    name: 'COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO VANGUARDA DA REGIÃO DAS CATARATAS DO IGUAÇU E VALE DO PARAÍBA - SICREDI VANGUARDA PR/SP/RJ',
    short_name: 'COOP SICREDI VANGUARDA',
  },
  78483310: {
    bank_ispb: '78483310',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE ASSOCIADOS ITAPIRANGA - SICOOB CREDITAPIRANGA SC/RS',
    short_name: 'CCLA ITAPIRANGA',
  },
  78626983: {
    bank_ispb: '78626983',
    name: 'BANCO VR S.A.',
    short_name: 'BCO VR S.A.',
  },
  78632767: {
    bank_ispb: '78632767',
    name: 'BANCO OURINVEST S.A.',
    short_name: 'BCO OURINVEST S.A.',
  },
  78825023: {
    bank_ispb: '78825023',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE ASSOCIADOS OESTE CATARINENSE',
    short_name: 'CCLAA OESTE CATARINENSE',
  },
  78825270: {
    bank_ispb: '78825270',
    name: 'COOPERATIVA DE CRÉDITO MAXI ALFA DE LIVRE ADMISSÃO DE ASSOCIADOS - SICOOB MAXICRÉDITO',
    short_name: 'CCLA MAXI ALFA',
  },
  78834975: {
    bank_ispb: '78834975',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE ASSOCIADOS DO VALE DO CANOINHAS - SICOOB CREDICANOINHAS/SC',
    short_name: 'CCLA DO VALE DO CANOINHAS',
  },
  78840071: {
    bank_ispb: '78840071',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE ASSOCIADOS DO ALTO URUGUAI CATARINENSE - SICOOB CREDIAUC',
    short_name: 'COOP SICOOB CREDIAUC',
  },
  78858107: {
    bank_ispb: '78858107',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE ASSOCIADOS AURIVERDE -  SICOOB - CREDIAL SC/RS',
    short_name: 'CCLAA AURIVERDE-SICOOB CREDIAL',
  },
  78862083: {
    bank_ispb: '78862083',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE ASSOCIADOS CAMPOS NOVOS - SICOOB CAMPOS NOVOS',
    short_name: 'CCLA CAMPOS NOVOS',
  },
  78865995: {
    bank_ispb: '78865995',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE ASSOCIADOS VALE DO RIO DO PEIXE - SICOOB CREDIRIO SC',
    short_name: 'CCLA VALE DO RIO DO PEIXE',
  },
  78907607: {
    bank_ispb: '78907607',
    name: 'COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO CENTRO SUL - SICREDI CENTRO SUL PR/SC/RJ',
    short_name: 'COOP SICREDI CENT SUL PR/SC/RJ',
  },
  79052122: {
    bank_ispb: '79052122',
    name: 'COOPERATIVA DE CREDITO, POUPANÇA E INVESTIMENTO ALIANÇA - SICREDI ALIANÇA PR/SP',
    short_name: 'COOP SICREDI ALIANÇA',
  },
  79063574: {
    bank_ispb: '79063574',
    name: 'COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DO NORTE DO PARANÁ E SUL DE SÃO PAULO - SICREDI NORTE SUL PR/SP',
    short_name: 'COOP SICREDI NORTE SUL',
  },
  79086997: {
    bank_ispb: '79086997',
    name: 'COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO PARANAPANEMA PR/SP - SICREDI PARANAPANEMA PR/SP',
    short_name: 'COOP SICREDI PARANAPANEMA PRSP',
  },
  79342069: {
    bank_ispb: '79342069',
    name: 'COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DEXIS - SICREDI DEXIS',
    short_name: 'COOP SICREDI DEXIS',
  },
  79457883: {
    bank_ispb: '79457883',
    name: 'COOPERATIVA DE CRÉDITO E INVESTIMENTO DE LIVRE ADMISSÃO AGROEMPRESARIAL - SICREDI AGROEMPRESARIAL PR/SP',
    short_name: 'COOP SICREDI AGROEMPRESARIAL',
  },
  80160260: {
    bank_ispb: '80160260',
    name: 'COOPERATIVA CENTRAL DE CRÉDITO DE SANTA CATARINA E RIO GRANDE DO SUL - SICOOB CENTRAL SC/RS',
    short_name: 'CCC SICOOB CENTRAL SC/RS',
  },
  80959612: {
    bank_ispb: '80959612',
    name: 'COOPERATIVA DE CRÉDITO ALTO VALE DO ITAJAÍ - SICOOB ALTO VALE',
    short_name: 'CC ALTO VALE DO ITAJAÍ',
  },
  80959638: {
    bank_ispb: '80959638',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE ASSOCIADOS DO MEIO OESTE CATARINENSE - SICOOB CREDIMOC SC',
    short_name: 'CCLA MEIO OESTE CATARINENSE',
  },
  81011686: {
    bank_ispb: '81011686',
    name: 'COOPERATIVA DE CRÉDITO DO NORTE CATARINENSE E SUL PARANAENSE - SICOOB CREDINORTE',
    short_name: 'CC DO NORTE CATARINENSE E SUL PARANAENSE',
  },
  81014060: {
    bank_ispb: '81014060',
    name: 'COOPERATIVA DE CREDITO DE LIVRE ADMISSÃO DE ASSOCIADOS ITAIPU SICOOB CREDITAIPU',
    short_name: 'CCLAA ITAIPU SICOOB CREDITAIPU',
  },
  81016131: {
    bank_ispb: '81016131',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE ASSOCIADOS DO VALE DO VINHO - SICOOB VALE DO VINHO',
    short_name: 'CCLA VALE DO VINHO',
  },
  81054686: {
    bank_ispb: '81054686',
    name: 'COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO INTEGRAÇÃO - SICREDI INTEGRAÇÃO PR/SC',
    short_name: 'COOP SICREDI INTEGRAÇÃO PR/SC',
  },
  81099491: {
    bank_ispb: '81099491',
    name: 'COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO VALE DO PIQUIRI ABCD - SICREDI VALE DO PIQUIRI ABCD PR/SP',
    short_name: 'COOP SICREDI VALE DO PIQUIRI',
  },
  81115149: {
    bank_ispb: '81115149',
    name: 'COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO GRANDES LAGOS DO PARANÁ E LITORAL PAULISTA - SICREDI GRANDES LAGOS PR/SP',
    short_name: 'COOP SICREDI GRANDES LAGOS',
  },
  81192106: {
    bank_ispb: '81192106',
    name: 'COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO NOSSA TERRA - SICREDI NOSSA TERRA PR/SP',
    short_name: 'COOP SICREDI NOSSA TERRA PR/SP',
  },
  81206039: {
    bank_ispb: '81206039',
    name: 'COOPERATIVA DE CREDITO, POUPANÇA E INVESTIMENTO RIO PARANA - SICREDI RIO PARANA PR/SP',
    short_name: 'COOP SICREDI RIO PARANÁ',
  },
  81292278: {
    bank_ispb: '81292278',
    name: 'COOPERATIVA DE CRÉDITO ORIGINAL - SICOOB ORIGINAL',
    short_name: 'CC ORIGINAL - SICOOB ORIGINAL',
  },
  81367880: {
    bank_ispb: '81367880',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE ASSOCIADOS SUL CATARINENSE - SICOOB CREDISULCA SC',
    short_name: 'CCLA SUL CATARINENSE',
  },
  81466286: {
    bank_ispb: '81466286',
    name: 'COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO CAMPOS GERAIS E GRANDE CURITIBA - SICREDI CAMPOS GERAIS E GRANDE CURITIBA PR/SP',
    short_name: 'SICREDI CAMPOS GERAIS E GRANDE CURITIBA PR/SP',
  },
  81607046: {
    bank_ispb: '81607046',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE ASSOCIADOS SÃO MIGUEL DO OESTE - SICOOB SÃO MIGUEL SC/PR/RS',
    short_name: 'CCLAA SAO MIGUEL DO OESTE',
  },
  81706616: {
    bank_ispb: '81706616',
    name: 'COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO VALOR SUSTENTÁVEL - SICREDI VALOR SUSTENTÁVEL PR/SP',
    short_name: 'COOP SICREDI VLR SUSTENT PR/SP',
  },
  81723108: {
    bank_ispb: '81723108',
    name: 'CREDICOAMO CREDITO RURAL COOPERATIVA',
    short_name: 'CREDICOAMO',
  },
  82065285: {
    bank_ispb: '82065285',
    name: 'COOPERATIVA DE CREDITO, POUPANÇA E INVESTIMENTO PARQUE DAS ARAUCARIAS - SICREDI PARQUE DAS ARAUCARIAS PR/SC/SP',
    short_name: 'COOP PARQ ARAUCARIAS PR/SC/SP',
  },
  82133182: {
    bank_ispb: '82133182',
    name: 'COOPERATIVA DE CRÉDITO DO VALE EUROPEU - SICOOB EURO VALE',
    short_name: 'CC DO VALE EUROPEU - SICOOB EURO VALE',
  },
  82527557: {
    bank_ispb: '82527557',
    name: 'COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO FRONTEIRAS DO PARANÁ, SANTA CATARINA E SÃO PAULO - SICREDI FRONTEIRAS PR/SC/SP',
    short_name: 'COOP SICREDI FRONTEIR PR/SC/SP',
  },
  82639451: {
    bank_ispb: '82639451',
    name: 'COOPERATIVA DE CREDITO VALE DO ITAJAI - VIACREDI',
    short_name: 'COOP VIACREDI',
  },
  83315408: {
    bank_ispb: '83315408',
    name: 'SICREDI NORTE - COOPERATIVA DE CRÉDITO',
    short_name: 'COOP SICREDI NORTE',
  },
  83836114: {
    bank_ispb: '83836114',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO ESTADO DO PARÁ - SICOOB COOESA',
    short_name: 'CCLA DO ESTADO DO PARÁ - SICOOB COOESA',
  },
  84156470: {
    bank_ispb: '84156470',
    name: 'COOPERATIVA DE CRÉDITO DOS INTEGRANTES DO MINISTÉRIO PÚBLICO E DO PODER JUDICIÁRIO NOS ESTADOS DO AMAPÁ E CEARÁ E LIVRE ADMISSÃO NO ESTADO DO PARÁ - SICOOB COIMPPA',
    short_name: 'CC INTEGRANTES MIN PUBL E PODER JUDIC AP E CE E CCLA PA',
  },
  84974278: {
    bank_ispb: '84974278',
    name: 'COOPERATIVA DE CREDITO, POUPANCA E INVESTIMENTO IGUACU - SICREDI IGUACU PR/SC/SP',
    short_name: 'COOP SICREDI IGUAÇU PR/SC/SP',
  },
  85291086: {
    bank_ispb: '85291086',
    name: 'COOPERATIVA DE CRÉDITO LITORÂNEA',
    short_name: 'CC LITORÂNEA',
  },
  86389236: {
    bank_ispb: '86389236',
    name: 'COOPERATIVA DE CRÉDITO SICOOB UNI SUDESTE',
    short_name: 'COOP SICOOB UNI SUDESTE',
  },
  86564051: {
    bank_ispb: '86564051',
    name: 'COOPERATIVA DE CRéDITO NOROESTE DE MINAS LTDA. - SICOOB NOROESTE DE MINAS',
    short_name: 'CC NOROESTE DE MINAS',
  },
  86585049: {
    bank_ispb: '86585049',
    name: 'COOPERATIVA DE CRÉDITO DA REGIÃO DO VALE DO RIO GRANDE LTDA. - SICOOB CREDILEITE',
    short_name: 'SICOOB CREDILEITE',
  },
  86791837: {
    bank_ispb: '86791837',
    name: 'COOPERATIVA DE CRÉDITO DO VALE DOS PINHAIS - SICOOB VALE DOS PINHAIS',
    short_name: 'SICOOB VALE DOS PINHAIS',
  },
  86829827: {
    bank_ispb: '86829827',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO VALE - SICOOB CREDIVALE/SC',
    short_name: 'CCLA DO VALE',
  },
  87067757: {
    bank_ispb: '87067757',
    name: 'COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO CENTRO SERRA - SICREDI CENTRO SERRA RS',
    short_name: 'COOP SICREDI CENTRO SERRA',
  },
  87510475: {
    bank_ispb: '87510475',
    name: 'COOPERATIVA DE CRÉDITO ROTA DAS TERRAS - SICREDI ROTA DAS TERRAS RS/MG',
    short_name: 'COOP SICREDI ROTA DAS TERRAS RS/MG',
  },
  87733077: {
    bank_ispb: '87733077',
    name: 'COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO ESSÊNCIA RS/ES - SICREDI ESSÊNCIA',
    short_name: 'COOP SICREDI ESSÊNCIA',
  },
  87733770: {
    bank_ispb: '87733770',
    name: 'COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO CONEXÃO - SICREDI CONEXÃO',
    short_name: 'COOP. CRED. POUP. INVEST. CONEXÃO',
  },
  87779625: {
    bank_ispb: '87779625',
    name: 'COOPERATIVA DE CRÉDITO COOPERAÇÃO - SICREDI COOPERAÇÃO',
    short_name: 'COOPERATIVA DE CRÉDITO COOPERAÇÃO - SICREDI COOPERAÇÃO',
  },
  87780268: {
    bank_ispb: '87780268',
    name: 'COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO UNIÃO DE ESTADOS RIO GRANDE DO SUL, SANTA CATARINA E MINAS GERAIS - SICREDI UNIESTADOS',
    short_name: 'COOP SICREDI UNIESTADOS',
  },
  87780284: {
    bank_ispb: '87780284',
    name: 'COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO VALE DO JAGUARI E ZONA DA MATA - SICREDI VALE DO JAGUARI E ZONA DA MATA RS/MG',
    short_name: 'COOP SICREDI VL JAGUA ZN MATA',
  },
  87781530: {
    bank_ispb: '87781530',
    name: 'COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO INTEGRAÇÃO DE ESTADOS DO RIO GRANDE DO SUL, SANTA CATARINA E MINAS GERAIS - SICREDI INTEGRAÇÃO DE ESTADOS RS/SC/MG',
    short_name: 'SICREDI INTEGRAÇÃO DE ESTADOS RS/SC/MG',
  },
  87784088: {
    bank_ispb: '87784088',
    name: 'COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO SUL MINAS DO RIO GRANDE DO SUL E MINAS GERAIS - SICREDI SUL MINAS RS/MG.',
    short_name: 'COOP SICREDI SUL MINAS RS/MG',
  },
  87795639: {
    bank_ispb: '87795639',
    name: 'COOPERATIVA DE CRÉDITO ALIANÇA RS/SC/ES - SICREDI ALIANÇA RS/SC/ES',
    short_name: 'COOP SICREDI ALIANÇA RS/SC',
  },
  87853206: {
    bank_ispb: '87853206',
    name: 'COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO OURO BRANCO - SICREDI OURO BRANCO RS/MG',
    short_name: 'COOP SICREDI OURO BRANCO RS/MG',
  },
  87900411: {
    bank_ispb: '87900411',
    name: 'COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DE ESPUMOSO - SICREDI ESPUMOSO RS/MG',
    short_name: 'COOP SICREDI ESPUMOSO RS/MG',
  },
  87900601: {
    bank_ispb: '87900601',
    name: 'COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO BOTUCARAÍ - SICREDI BOTUCARAÍ RS/MG',
    short_name: 'COOP SICREDI BOTUCARAÍ RS/MG',
  },
  88038260: {
    bank_ispb: '88038260',
    name: 'COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO PLANALTO - SICREDI PLANALTO RS/MG',
    short_name: 'COOP SICREDI PLANALTO RS/MG',
  },
  88099247: {
    bank_ispb: '88099247',
    name: 'COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO RAÍZES - SICREDI RAÍZES RS/SC/MG',
    short_name: 'COOP SICREDI RAIZES RS/SC/MG',
  },
  88471024: {
    bank_ispb: '88471024',
    name: 'COOPERATIVA DE CRÉDITO CENTRO LESTE - SICREDI CENTRO LESTE RS',
    short_name: 'COOP SICREDI CENTRO LESTE RS',
  },
  88530142: {
    bank_ispb: '88530142',
    name: 'COOPERATIVA DE CRÉDITO E INVESTIMENTO LIBERDADE - SICREDI LIBERDADE',
    short_name: 'COOP DE CRED E INVEST LIBERDADE - SICREDI LIBERDADE',
  },
  88894548: {
    bank_ispb: '88894548',
    name: 'COOPERATIVA DE CRéDITO, POUPANçA E INVESTIMENTO UNIãO - SICREDI UNIAO RS/ES',
    short_name: 'COOP SICREDI UNIÃO RS',
  },
  89049738: {
    bank_ispb: '89049738',
    name: 'COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO NOROESTE RS/MG - SICREDI NOROESTE RS/MG',
    short_name: 'COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO NOROESTE RS/',
  },
  89126130: {
    bank_ispb: '89126130',
    name: 'COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DA REGIÃO DOS VALES - SICREDI REGIÃO DOS VALES RS',
    short_name: 'COOP SICREDI REGIÃO DOS VALES',
  },
  89280960: {
    bank_ispb: '89280960',
    name: 'COOPERATIVA DE ECONOMIA E CREDITO MUTUO DOS EMPREGADOS DAS EMPRESAS RANDON',
    short_name: 'CECM DOS EMPR DAS EMP RANDON',
  },
  89468565: {
    bank_ispb: '89468565',
    name: 'COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DA REGIÃO DA PRODUÇÃO - SICREDI REGIÃO DA PRODUÇÃO RS/SC/MG',
    short_name: 'COOP SICREDI REG PROD RS/SC/MG',
  },
  89990501: {
    bank_ispb: '89990501',
    name: 'COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DE IBIRAIARAS - SICREDI IBIRAIARAS RS/MG',
    short_name: 'COOP SICREDI IBIRAIARAS RS/MG',
  },
  90400888: {
    bank_ispb: '90400888',
    name: 'BANCO SANTANDER (BRASIL) S.A.',
    short_name: 'BCO SANTANDER (BRASIL) S.A.',
  },
  90497256: {
    bank_ispb: '90497256',
    name: 'COOPERATIVA DE CRéDITO, POUPANçA E INVESTIMENTO INTERESTADOS - SICREDI INTERESTADOS RS/ES',
    short_name: 'COOP SICREDI INTERESTADOS RS/ES',
  },
  90608712: {
    bank_ispb: '90608712',
    name: 'COOPERATIVA DE CRÉDITO SICREDI SERRANA RS/ES',
    short_name: 'COOP SICREDI SERRANA RS',
  },
  90729369: {
    bank_ispb: '90729369',
    name: 'COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO REGIÕES DAS CULTURAS - SICREDI DAS CULTURAS RS/MG',
    short_name: 'COOP SICREDI CULTURAS RS/MG',
  },
  91159764: {
    bank_ispb: '91159764',
    name: 'COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DE LAJEADO - SICREDI INTEGRAÇÃO RS/MG',
    short_name: 'COOP SICREDI INTEGRAÇÃO RS/MG',
  },
  91586982: {
    bank_ispb: '91586982',
    name: 'COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO SICREDI PIONEIRA RS - SICREDI PIONEIRA RS',
    short_name: 'COOP SICREDI PIONEIRA RS',
  },
  92555150: {
    bank_ispb: '92555150',
    name: 'COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DA REGIÃO ALTOS DA SERRA - SICREDI ALTOS DA SERRA RS/SC',
    short_name: 'COOP SICREDI ALT SERRA RS/SC',
  },
  92702067: {
    bank_ispb: '92702067',
    name: 'BANCO DO ESTADO DO RIO GRANDE DO SUL S.A.',
    short_name: 'BCO DO ESTADO DO RS S.A.',
  },
  92796564: {
    bank_ispb: '92796564',
    name: 'COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO SUL RIOGRANDENSE - SICREDI UNIÃO METROPOLITANA RS',
    short_name: 'COOP SICREDI UNIÃO METROLITANA',
  },
  92874270: {
    bank_ispb: '92874270',
    name: 'BANCO DIGIMAIS S.A.',
    short_name: 'BCO DIGIMAIS S.A.',
  },
  92894922: {
    bank_ispb: '92894922',
    name: 'BANCO ORIGINAL S.A.',
    short_name: 'BANCO ORIGINAL',
  },
  92934215: {
    bank_ispb: '92934215',
    name: 'BANRISUL CARTOES S.A.',
    short_name: 'BANRISUL CARTOES S.A.',
  },
  94243839: {
    bank_ispb: '94243839',
    name: 'COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DO VALE DAS ANTAS LTDA. - UNICRED PIONEIRA',
    short_name: 'UNICRED PIONEIRA',
  },
  94433109: {
    bank_ispb: '94433109',
    name: 'COOPERATIVA DE ECONOMIA E CREDITO MUTUO DOS MEDICOS DE PORTO ALEGRE LTDA-UNICRED PORTO ALEGRE',
    short_name: 'CECM MÉD DE PORTO ALEGRE',
  },
  95163002: {
    bank_ispb: '95163002',
    name: 'COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO UNICRED ELEVA LTDA.',
    short_name: 'UNICRED ELEVA',
  },
  95213211: {
    bank_ispb: '95213211',
    name: 'COOPERATIVA DE CRéDITO, POUPANçA E INVESTIMENTO CAMINHO DAS ÁGUAS RS - SICREDI CAMINHO DAS ÁGUAS RS',
    short_name: 'COOP SICREDI CAMINHO DAS ÁGUAS RS',
  },
  95424891: {
    bank_ispb: '95424891',
    name: 'COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DO VALE DO RIO PARDO - SICREDI VALE DO RIO PARDO RS',
    short_name: 'COOP SICREDI VALE DO RIO PARDO',
  },
  95594941: {
    bank_ispb: '95594941',
    name: 'COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DAS REGIÕES CENTRO DO RS E MG - SICREDI REGIÃO CENTRO RS/MG',
    short_name: 'COOP SICREDI REG CENTRO RS/MG',
  },
  97259253: {
    bank_ispb: '97259253',
    name: 'COOPERATIVA E ECONOMIA E CRÉDITO MÚTUO DOS MÉDICOS DO PLANALTO MÉDIO DO RIO GRANDE DO SUL LTDA. - CREDISIS/CREDIPLAN',
    short_name: 'CECM MÉDICOS PLANALTO MÉDIO RS - CREDISIS/CREDIPLAN',
  },
  97489280: {
    bank_ispb: '97489280',
    name: 'COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO SICREDI INTEGRAÇÃO BAHIA - SICREDI INTEGRAÇÃO BAHIA',
    short_name: 'COOP SICREDI INTEGRAÇÃO BAHIA',
  },
  '00966246': {
    bank_ispb: '00966246',
    name: 'COOPERATIVA DE CREDITO CREDICERIPA - SICOOB CREDICERIPA',
    short_name: 'CC CREDICERIPA',
  },
  '03485130': {
    bank_ispb: '03485130',
    name: 'COOPERATIVA DE CRÉDITO E ECONOMIA COM INTERAÇÃO SOLIDÁRIA - CRESOL ENCOSTAS DA SERRA GERAL',
    short_name: 'COOP CRESOL ENCOSTAS DA SERRA GERAL',
  },
  '06078926': {
    bank_ispb: '06078926',
    name: 'COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DOS ESCRIVÃES, NOTÁRIOS E REGISTRADORES NO ESTADO DO PARANÁ - SICREDI CREDENOREG PR',
    short_name: 'COOP SICREDI CREDENOREG',
  },
  '07853842': {
    bank_ispb: '07853842',
    name: 'UNIPRIME OURO - COOPERATIVA DE CRÉDITO DE OURO',
    short_name: 'UNIPRIME OURO - COOP DE OURO',
  },
  '04663561': {
    bank_ispb: '04663561',
    name: 'COOPERATIVA DE CRÉDITO E INVESTIMENTO COM INTERAÇÃO SOLIDÁRIA VALE DAS ÁGUAS - CRESOL VALE DAS ÁGUAS',
    short_name: 'COOP CRESOL VALE DAS AGUAS',
  },
  '09552111': {
    bank_ispb: '09552111',
    name: 'COOPERATIVA DE CRÉDITO DOS MAGISTRADOS, SERVIDORES DA JUSTIÇA DO ESTADO DE GOIÁS E EMPREGADOS DA CELG LTDA.',
    short_name: 'CC JUST GOIAS E CELG',
  },
  '05425526': {
    bank_ispb: '05425526',
    name: 'COOPERATIVA DE CRÉDITO E ECONOMIA COM INTERAÇÃO SOLIDÁRIA - CRESOL DESENVOLVIMENTO',
    short_name: 'COOP CRESOL DESENVOLVIMENTO',
  },
  '06332931': {
    bank_ispb: '06332931',
    name: 'COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DO CERRADO DE GOIÁS - SICREDI CERRADO GO',
    short_name: 'COOP SICREDI CERRADO GO',
  },
  '05036532': {
    bank_ispb: '05036532',
    name: 'CENTRAL DAS COOPERATIVAS DE CREDITO UNICOOB - SICOOB CENTRAL UNICOOB',
    short_name: 'CCC UNICOOB-SICOOB CENTR UNIC',
  },
  '05545390': {
    bank_ispb: '05545390',
    name: 'SICREDI COOMAMP - COOPERATIVA DE CRÉDITO DOS MEMBROS DE INSTITUIÇÕES PÚBLICAS DAS CARREIRAS JURÍDICAS E DOS SERVIDORES PÚBLICOS FEDERAIS, ESTADUAIS E MUNICIPAIS EM SÃO LUÍS/MA E MUNICÍPIOS CIRCUNVIZIN',
    short_name: 'COOP SICREDI COOMAMP',
  },
  '01027058': {
    bank_ispb: '01027058',
    name: 'CIELO S.A. - INSTITUIÇÃO DE PAGAMENTO',
    short_name: 'CIELO IP S.A.',
  },
  '00106180': {
    bank_ispb: '00106180',
    name: 'CENTRAL DAS COOPERATIVAS DE CRÉDITO DOS ESTADOS DE MATO GROSSO, MATO GROSSO DO SUL E DO MUNICÍPIO DE CACOAL/RO - SICOOB CENTRAL RONDON',
    short_name: 'CCC DOS EST DE MT, MS E MUN DE CACOAL/RO',
  },
  '01657678': {
    bank_ispb: '01657678',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DA REGIÃO DE SÃO SEBASTIÃO DO PARAÍSO LTDA. - SICOOB PARAÍSOCRED',
    short_name: 'CCLA SEBASTIAO PARAISO',
  },
  '01644264': {
    bank_ispb: '01644264',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO MÉDIO PIRACICABA E DO CIRCUITO DO OURO LTDA. - SICOOB CREDIMEPI',
    short_name: 'CCLA MEDIO PIRACICABA E CIRCUITO DO OURO LTDA',
  },
  '02448310': {
    bank_ispb: '02448310',
    name: 'COOPERATIVA DE CRÉDITO E INVESTIMENTO COM INTERAÇÃO SOLIDÁRIA PROGRESSO - CRESOL PROGRESSO',
    short_name: 'COOP CRESOL PROGRESSO',
  },
  '07097064': {
    bank_ispb: '07097064',
    name: 'COOPERATIVA DE CRÉDITO SICOOB CONFIANÇA',
    short_name: 'COOP SICOOB CONFIANÇA',
  },
  '02335109': {
    bank_ispb: '02335109',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DA ZONA DA MATA LTDA   SICOOB COOPEMATA',
    short_name: 'CCLA ZONA DA MATA LTDA',
  },
  '02197569': {
    bank_ispb: '02197569',
    name: 'COOPERATIVA DE CRÉDITO CREDSAOPAULO - SICOOB CREDSAOPAULO',
    short_name: 'CCLA CREDSAOPAULO',
  },
  '04414354': {
    bank_ispb: '04414354',
    name: 'COOPERATIVA DE CRéDITO MúTUO DO ABC - COOPER 7',
    short_name: 'CCM DO ABC - COOPER 7',
  },
  '01667352': {
    bank_ispb: '01667352',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO SERTÃO DE MINAS GERAIS LTDA. - SICOOB SERTÃO MINAS',
    short_name: 'CCLA SERTÃO MINAS',
  },
  '03326437': {
    bank_ispb: '03326437',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO CENTRO NORTE DOS ESTADOS DE MATO GROSSO E MATO GROSSO DO SUL - SICOOB UNIÃO MT/MS',
    short_name: 'CCLA CENTRO NORTE MT/MS',
  },
  '02528151': {
    bank_ispb: '02528151',
    name: 'COOPERATIVA DE CRÉDITO SICOOB CREDCOOP LTDA.',
    short_name: 'CC SICOOB CREDCOOP',
  },
  '09343038': {
    bank_ispb: '09343038',
    name: 'COOPERATIVA DE CRÉDITO SICREDI ALTO SERTÃO PARAIBANO - SICREDI ALTO SERTÃO PARAIBANO',
    short_name: 'COOP SICREDI ALTSERTÃO PARAIBANO',
  },
  '04649337': {
    bank_ispb: '04649337',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DFMIL LTDA. - SICOOB DFMIL',
    short_name: 'SICOOB DFMIL',
  },
  '03535065': {
    bank_ispb: '03535065',
    name: 'COOPERATIVA DE CRÉDITO SICOOB CREDMETAL',
    short_name: 'CC SICOOB CREDMETAL',
  },
  '07512780': {
    bank_ispb: '07512780',
    name: 'COOPERATIVA DE CRÉDITO E INVESTIMENTO COM INTERAÇÃO SOLIDÁRIA DO VALE EUROPEU - CRESOL VALE EUROPEU',
    short_name: 'COOP CRESOL VALE EUROPEU',
  },
  '03042597': {
    bank_ispb: '03042597',
    name: 'COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DE CAMPO GRANDE E REGIÃO - SICREDI CAMPO GRANDE MS',
    short_name: 'COOP SICREDI CAMPO GRANDE',
  },
  '01042487': {
    bank_ispb: '01042487',
    name: 'COOPERATIVA DE CRÉDITO SICOOB UNIDAS',
    short_name: 'CC SICOOB UNIDAS',
  },
  '09464032': {
    bank_ispb: '09464032',
    name: 'MIDWAY S.A. - CRÉDITO, FINANCIAMENTO E INVESTIMENTO',
    short_name: 'MIDWAY S.A. - SCFI',
  },
  '07693858': {
    bank_ispb: '07693858',
    name: 'HSCM - SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORTE LTDA.',
    short_name: 'HSCM SCMEPP LTDA.',
  },
  '03612764': {
    bank_ispb: '03612764',
    name: 'COOPERATIVA DE CRÉDITO  DE FRONTEIRAS  LTDA. - SICOOB FRONTEIRAS',
    short_name: 'COOP FRONTEIRAS',
  },
  '02844024': {
    bank_ispb: '02844024',
    name: 'COOPERATIVA DE CRÉDITO E ECONOMIA COM INTERAÇÃO SOLIDÁRIA CRESOL EVOLUÇÃO',
    short_name: 'COOP CRESOL EVOLUÇAO',
  },
  '05203605': {
    bank_ispb: '05203605',
    name: 'COOPERATIVA DE CRÉDITO DA AMAZÔNIA - SICOOB AMAZÔNIA',
    short_name: 'SICOOB AMAZÔNIA',
  },
  '01062439': {
    bank_ispb: '01062439',
    name: 'COOPERATIVA DE ECONOMIA E CREDITO MUTUO DOS FUNCIONARIOS DA COMIGO',
    short_name: 'CECM DOS FUNCIONARIOS DA COMIGO',
  },
  '01205736': {
    bank_ispb: '01205736',
    name: 'COOPERATIVA DE CRÉDITO SICOOB COSTA DO DESCOBRIMENTO LTDA.',
    short_name: 'COOP DE CRÉDITO SICOOB COSTA DO DESCOBRIMENTO LTDA.',
  },
  '07070495': {
    bank_ispb: '07070495',
    name: 'COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO EMPREENDEDORES - SICREDI EMPREENDEDORES PR',
    short_name: 'COOP SICREDI EMPREENDEDORES',
  },
  '05979692': {
    bank_ispb: '05979692',
    name: 'COOPERATIVA DE CRÉDITO DOS PROFISSIONAIS DOS CREAS E DEMAIS ÁREAS TECNOLÓGICAS - CREDCREA',
    short_name: 'CREDCREA',
  },
  '09403026': {
    bank_ispb: '09403026',
    name: 'COOPERATIVA DE CRÉDITO CENTRO LESTE NORTE MARANHENSE - SICOOB CENTROLESTE',
    short_name: 'CC CENTRO LESTE NORTE MARANHENSE - SICOOB CENTROLESTE',
  },
  '07412987': {
    bank_ispb: '07412987',
    name: 'COOPERATIVA DE CRÉDITO E ECONOMIA COM INTERAÇÃO SOLIDÁRIA - CRESOL ALIANÇA',
    short_name: 'COOP CRESOL ALIANÇA',
  },
  '03941182': {
    bank_ispb: '03941182',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO CIRCUITO DAS ÁGUAS - SICOOB CIRCUITO DAS ÁGUAS',
    short_name: 'CCLA CIRCUITO DAS ÁGUAS',
  },
  '03632872': {
    bank_ispb: '03632872',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO SUDOESTE DA AMAZÔNIA LTDA. - SICOOB CREDISUL.',
    short_name: 'SICOOB CREDISUL',
  },
  '02923389': {
    bank_ispb: '02923389',
    name: 'COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO SICREDI ARACAJU - SICREDI ARACAJU',
    short_name: 'COOP SICREDI ARACAJU',
  },
  '00360305': {
    bank_ispb: '00360305',
    name: 'CAIXA ECONOMICA FEDERAL',
    short_name: 'CAIXA ECONOMICA FEDERAL',
  },
  '04281182': {
    bank_ispb: '04281182',
    name: 'COOPERATIVA DE CREDITO E ECONOMIA COM INTERACAO SOLIDARIA DE CHAPECO - CRESOL CHAPECO',
    short_name: 'COOP CRESOL CHAPECO',
  },
  '05269976': {
    bank_ispb: '05269976',
    name: 'COOPERATIVA DE CRÉDITO E ECONOMIA COM INTERAÇÃO SOLIDÁRIA DE FORMOSA DO SUL - CRESOL FORMOSA',
    short_name: 'COOP CRESOL FORMOSA DO SUL',
  },
  '04243780': {
    bank_ispb: '04243780',
    name: 'CENTRAL SICOOB UNI DE COOPERATIVAS DE CRÉDITO',
    short_name: 'CENTRAL SICOOB UNI DE CC',
  },
  '05241619': {
    bank_ispb: '05241619',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO MÉDIO LESTE DE MATO GROSSO - SICOOB PRIMAVERA MT',
    short_name: 'CCLA M LESTE MT SICOOB PRIMAVE',
  },
  '00694389': {
    bank_ispb: '00694389',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE ASSOCIADOS - SICOOB CREDICARU SC/RS',
    short_name: 'CCLA SICOOB CREDICARU SC/RS',
  },
  '07564184': {
    bank_ispb: '07564184',
    name: 'COOPERATIVA DE CRÉDITO RURAL ASCOOB SERTÕES - ASCOOB SERTÕES',
    short_name: 'COOP ASCOOB SERTÕES',
  },
  '00429890': {
    bank_ispb: '00429890',
    name: 'COOPERATIVA DE CREDITO CREDIAGRO LTDA. - SICOOB CREDIAGRO',
    short_name: 'CC CREDIAGRO',
  },
  '02466552': {
    bank_ispb: '02466552',
    name: 'COOPERATIVA DE CRÉDITO SICOOB VALE SUL',
    short_name: 'CC SICOOB VALE SUL',
  },
  '04632856': {
    bank_ispb: '04632856',
    name: 'CREDISIS - CENTRAL DE COOPERATIVAS DE CRÉDITO LTDA.',
    short_name: 'CREDISIS CENTRAL DE COOPERATIVAS DE CRÉDITO LTDA.',
  },
  '02137018': {
    bank_ispb: '02137018',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE RORAIMA LTDA.',
    short_name: 'CCLA DE RORAIMA LTDA.',
  },
  '08742188': {
    bank_ispb: '08742188',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO UNIÃO E NEGÓCIOS - SICOOB INTEGRAÇÃO',
    short_name: 'CCLA UNIÃO E NEGÓCIOS  - SICOOB INTEGRAÇÃO',
  },
  '03427097': {
    bank_ispb: '03427097',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO SUL CATARINENSE - ACENTRA',
    short_name: 'CCLA DO SUL CATARINENSE',
  },
  '02931668': {
    bank_ispb: '02931668',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO ESTADO DO RIO DE JANEIRO - SICOOB FLUMINENSE',
    short_name: 'SICOOB FLUMINENSE',
  },
  '04853988': {
    bank_ispb: '04853988',
    name: 'COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO BANDEIRANTES - SICREDI BANDEIRANTES SP',
    short_name: 'COOP SICREDI BANDEIRANTES',
  },
  '01060307': {
    bank_ispb: '01060307',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DA UNIÃO DOS VALES DO PIRANGA E MATIPÓ LTDA.   SICOOB UNIÃO',
    short_name: 'CCLA VALES DO PIRANGA E MATIPÓ',
  },
  '09463721': {
    bank_ispb: '09463721',
    name: 'COOPERATIVA DE CREDITO E ECONOMIA COM INTERAÇÃO SOLIDÁRIA - CRESOL JACINTO MACHADO',
    short_name: 'COOP CRESOL JACINTO MACHADO',
  },
  '02309070': {
    bank_ispb: '02309070',
    name: 'CREDISIS JICRED - COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO JICRED',
    short_name: 'CCLA CREDISIS JICRED',
  },
  '01090027': {
    bank_ispb: '01090027',
    name: 'COOPERATIVA DE ECONOMIA E CREDITO MUTUO DOS MEDICOS E CATEGORIAS AFINS-UNICRED NITEROI LTDA',
    short_name: 'CECMME CATEG AFINS-UNIC.NITERO',
  },
  '07122321': {
    bank_ispb: '07122321',
    name: 'COOPERATIVA DE CRÉDITO INTEGRADO - SICOOB INTEGRADO',
    short_name: 'CC INTEGRADO - SICOOB INTEGRADO',
  },
  '07946451': {
    bank_ispb: '07946451',
    name: 'COOPERATIVA DE CRÉDITO E INVESTIMENTO COM INTERAÇÃO SOLIDÁRIA MEIO OESTE - CRESOL MEIO OESTE',
    short_name: 'COOP CRESOL MEIO OESTE',
  },
  '02883398': {
    bank_ispb: '02883398',
    name: 'COOPERATIVA DE CRÉDITO DO VALE DO ITAJAÍ E VALE DO ITAPOCÚ - SICOOB MULTICREDI',
    short_name: 'CC VALE ITAJAÍ E ITAPOCÚ',
  },
  '01705236': {
    bank_ispb: '01705236',
    name: 'COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO UNICRED PROSPERAR LTDA. - UNICRED PROSPERAR',
    short_name: 'UNICRED PROSPERAR',
  },
  '04445917': {
    bank_ispb: '04445917',
    name: 'UNICRED REGIONAL NORTE LAGOS - COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO REGIONAL NORTE LAGOS LTDA',
    short_name: 'CECM REGIONAL NORTE LAGOS LTDA',
  },
  '03965737': {
    bank_ispb: '03965737',
    name: 'COOPERATIVA DE CRÉDITO E INVESTIMENTO COM INTERAÇÃO SOLIDÁRIA VANGUARDA - CRESOL VANGUARDA',
    short_name: 'COOP CRESOL VANGUARDA',
  },
  '09433005': {
    bank_ispb: '09433005',
    name: 'COOPERATIVA DE CRÉDITO RURAL COM INTERAÇÃO SOLIDÁRIA VALE DO CONTESTADO - CRESOL VALE DO CONTESTADO',
    short_name: 'COOP CRESOL VALE DO CONTESTADO',
  },
  '02446089': {
    bank_ispb: '02446089',
    name: 'COOPERATIVA DE CRÉDITO E INVESTIMENTO COM INTERAÇÃO SOLIDÁRIA UNIÃO - CRESOL UNIÃO',
    short_name: 'COOP CRESOL UNIAO',
  },
  '05211129': {
    bank_ispb: '05211129',
    name: 'COOPERATIVA DE CRÉDITO E INVESTIMENTO COM INTERAÇÃO SOLIDÁRIA CENTRO NORTE - CRESOL CENTRO NORTE',
    short_name: 'COOP CRESOL CENTRO NORTE',
  },
  '01526924': {
    bank_ispb: '01526924',
    name: 'COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO UNICRED HORIZONTES LTDA - UNICRED HORIZONTES',
    short_name: 'UNICRED HORIZONTES',
  },
  '07320890': {
    bank_ispb: '07320890',
    name: 'COOPERATIVA DE CRÉDITO E ECONOMIA COM INTERAÇÃO SOLIDÁRIA CRESOL VALE',
    short_name: 'COOP CRESOL VALE',
  },
  '03222753': {
    bank_ispb: '03222753',
    name: 'CREDISIS CREDIARI - COOPERATIVA DE CRÉDITO LTDA.',
    short_name: 'CREDISIS CREDIARI COOP LTDA.',
  },
  '02876918': {
    bank_ispb: '02876918',
    name: 'COOPERATIVA DE CRÉDITO SICOOB NORTE SUL LTDA',
    short_name: 'CC SICOOB NORTE SUL',
  },
  '00259231': {
    bank_ispb: '00259231',
    name: 'SICOOB UNIMAIS METROPOLITANA - COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO',
    short_name: 'CCLA SICOOB UNIMAIS METROPOLITANA',
  },
  '01848322': {
    bank_ispb: '01848322',
    name: 'UNIPRIME DO IGUAÇU - COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO',
    short_name: 'UNIPRIME DO IGUAÇU - CC POUP INV',
  },
  '04985665': {
    bank_ispb: '04985665',
    name: 'CREDISIS OESTE - COOPERATIVA DE CRÉDITO E INVESTIMENTO DO OESTE',
    short_name: 'CREDISIS OESTE - CCI DO OESTE',
  },
  '02318507': {
    bank_ispb: '02318507',
    name: 'BANCO KEB HANA DO BRASIL S.A.',
    short_name: 'BCO KEB HANA DO BRASIL S.A.',
  },
  '00075847': {
    bank_ispb: '00075847',
    name: 'COOPERATIVA DE CRÉDITO UNICRED CENTRO-SUL LTDA - UNICRED CENTRO-SUL',
    short_name: 'CC UNICRED CENTRO-SUL LTDA - UNICRED CENTRO-SUL',
  },
  '01739375': {
    bank_ispb: '01739375',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE  LAGOA DA PRATA E REGIÃO LTDA.   SICOOB LAGOACRED GERAIS',
    short_name: 'CCLA LAGOA DA PRATA',
  },
  '05392810': {
    bank_ispb: '05392810',
    name: 'COOPERATIVA DE CRÉDITO DA REGIÃO MERIDIONAL DO BRASIL - SICOOB UNICOOB MERIDIONAL',
    short_name: 'CC REGIÃO MERIDIONAL BRASIL',
  },
  '02480577': {
    bank_ispb: '02480577',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO E DOS ADVOGADOS LTDA. - SICOOB CREDIJUR',
    short_name: 'CCLA E DOS ADV',
  },
  '01259518': {
    bank_ispb: '01259518',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO SICOOB UNIMAIS CENTRO LESTE PAULISTA - SICOOB UNIMAIS CENTRO LESTE PAULISTA',
    short_name: 'SICOOB UNIMAIS CENTRO LESTE PAULISTA',
  },
  '01559914': {
    bank_ispb: '01559914',
    name: 'SICOOB AC CREDI - COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO LESTE E NORDESTE MINEIRO LTDA.',
    short_name: 'CCLA LESTE E NORDESTE MINEIRO',
  },
  '09330158': {
    bank_ispb: '09330158',
    name: 'COOPERATIVA DE CREDITO E ECONOMIA COM INTERAÇÃO SOLIDÁRIA - CRESOL TREZE DE MAIO',
    short_name: 'COOP CRESOL TREZE DE MAIO',
  },
  '03459850': {
    bank_ispb: '03459850',
    name: 'COOPERATIVA DE CRÉDITO SICOOB METROPOLITANO',
    short_name: 'CC SICOOB METROPOLITANO',
  },
  '05667301': {
    bank_ispb: '05667301',
    name: 'COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO - COOPERSERV',
    short_name: 'CECM COOPERSERV',
  },
  '02447120': {
    bank_ispb: '02447120',
    name: 'COOPERATIVA DE CRÉDITO SICOOB EXTREMO SUL LTDA.',
    short_name: 'CC SICOOB EXTREMO SUL LTDA.',
  },
  '08844074': {
    bank_ispb: '08844074',
    name: 'COOPERATIVA DE CRÉDITO DE ITAPETININGA - SICOOB CRED-ACI',
    short_name: 'CC DE ITAPETININGA - SICOOB CRED-ACI',
  },
  '01073966': {
    bank_ispb: '01073966',
    name: 'COOPERATIVA DE CRÉDITO RURAL DE ABELARDO LUZ - SULCREDI/CREDILUZ',
    short_name: 'CCR DE ABELARDO LUZ',
  },
  '08044854': {
    bank_ispb: '08044854',
    name: 'COOPERATIVA DE CREDITO DO CENTRO DO ESTADO DE RONDONIA - SICOOB CENTRO',
    short_name: 'C.C. DO CENTRO DO ESTADO DE RONDÔNIA',
  },
  '03856113': {
    bank_ispb: '03856113',
    name: 'FREEDOM2BUY.COM BRASIL LTDA.',
    short_name: 'F2B',
  },
  '01858774': {
    bank_ispb: '01858774',
    name: 'BANCO BV S.A.',
    short_name: 'BCO BV S.A.',
  },
  '00556603': {
    bank_ispb: '00556603',
    name: 'BANCO BARI DE INVESTIMENTOS E FINANCIAMENTOS S.A.',
    short_name: 'BANCO BARI S.A.',
  },
  '09437293': {
    bank_ispb: '09437293',
    name: 'BRINKS PAY INSTITUICAO DE PAGAMENTO LTDA',
    short_name: 'BRINKS PAY IP LTDA.',
  },
  '04388688': {
    bank_ispb: '04388688',
    name: 'COOPERATIVA DE CRÉDITO SICOOB ENGECRED LTDA.',
    short_name: 'CC SICOOB ENGECRED',
  },
  '02924977': {
    bank_ispb: '02924977',
    name: 'COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DOS PROFISSIONAIS MÉDICOS E DA SAÚDE DE CURITIBA E REGIÃO METROPOLITANA - SICREDI MEDICRED PR',
    short_name: 'COOP SICREDI MEDICRED',
  },
  '09210106': {
    bank_ispb: '09210106',
    name: 'SOCRED S.A. - SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORTE',
    short_name: 'SOCRED SA - SCMEPP',
  },
  '09488496': {
    bank_ispb: '09488496',
    name: 'COOPERATIVA DE CREDITO E ECONOMIA COM INTERAÇÃO SOLIDARIA - CRESOL SÃO JOÃO DO SUL',
    short_name: 'COOP CRESOL SAO JOAO DO SUL',
  },
  '01727929': {
    bank_ispb: '01727929',
    name: 'COOPERATIVA DE CRÉDITO UNICRED EVOLUÇÃO LTDA. - UNICRED EVOLUÇÃO',
    short_name: 'CC UNICRED EVOLUÇÃO LTDA.',
  },
  '02000895': {
    bank_ispb: '02000895',
    name: 'COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS SERVIDORES DO PODER JUDICIÁRIO, DO TRIBUNAL DE CONTAS ESTADUAL E DEMAIS ÓRGÃOS PÚBLICOS MUNICIPAIS, ESTADUAIS E FEDERAIS NO ESTADO DO RIO DE JANEIRO LTDA. -',
    short_name: 'CECM SERV POD JUD TCE ORG PUBL MUN EST FED RJ',
  },
  '03575699': {
    bank_ispb: '03575699',
    name: 'MEDCRED COOPERATIVA DE CRÉDITO',
    short_name: 'MEDCRED CC',
  },
  '05277312': {
    bank_ispb: '05277312',
    name: 'COOPERATIVA DE CRÉDITO E INVESTIMENTO COM INTERAÇÃO SOLIDÁRIA HORIZONTE - CRESOL HORIZONTE',
    short_name: 'COOP CRESOL HORIZONTE',
  },
  '03461243': {
    bank_ispb: '03461243',
    name: 'COOPERATIVA DE CRÉDITO DO NORTE CATARINENSE - ACREDICOOP',
    short_name: 'CC DO NORTE CATARINENSE',
  },
  '00795423': {
    bank_ispb: '00795423',
    name: 'BANCO SEMEAR S.A.',
    short_name: 'BANCO SEMEAR',
  },
  '09089356': {
    bank_ispb: '09089356',
    name: 'EFÍ S.A. - INSTITUIÇÃO DE PAGAMENTO',
    short_name: 'EFÍ S.A. - IP',
  },
  '01722480': {
    bank_ispb: '01722480',
    name: 'QUERO-QUERO VERDECARD INSTITUIÇÃO DE PAGAMENTO S.A.',
    short_name: 'QUERO-QUERO VERDECARD IP S.A.',
  },
  '04181542': {
    bank_ispb: '04181542',
    name: 'COOPERATIVA DE CREDITO DE LIVRE ADMISSAO DE PITANGUI E REGIAO LTDA. - SICOOB CREDPIT',
    short_name: 'CCLA DE PITANGUI E REGIAO LTDA',
  },
  '03620772': {
    bank_ispb: '03620772',
    name: 'COOPERATIVA DE CRÉDITO MÚTUO DO CEARÁ - SICOOB CEARÁ',
    short_name: 'CCM DO CEARÁ - SICOOB CEARÁ',
  },
  '09313766': {
    bank_ispb: '09313766',
    name: 'CARUANA S.A. - SOCIEDADE DE CRÉDITO, FINANCIAMENTO E INVESTIMENTO',
    short_name: 'CARUANA SCFI',
  },
  '08253539': {
    bank_ispb: '08253539',
    name: 'COOPERATIVA DE CRÉDITO RURAL DE SÃO MIGUEL DO OESTE - SULCREDI/SÃO MIGUEL',
    short_name: 'CCR DE SÃO MIGUEL DO OESTE',
  },
  '05192316': {
    bank_ispb: '05192316',
    name: 'VIA CERTA FINANCIADORA S.A. - CRÉDITO, FINANCIAMENTO E INVESTIMENTOS',
    short_name: 'VIA CERTA FINANCIADORA S.A. - CFI',
  },
  '07789195': {
    bank_ispb: '07789195',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO E DOS TRANSPORTADORES RODOVIÁRIOS DE VEÍCULOS - SICOOB CREDCEG',
    short_name: 'CCLA TRANSPORT RODOV VEICULOS',
  },
  '03502131': {
    bank_ispb: '03502131',
    name: 'COOPERATIVA CENTRAL DE CRÉDITO DO NORTE DO BRASIL - SICOOB NORTE',
    short_name: 'CCC DO NORTE DO BRASIL',
  },
  '06174009': {
    bank_ispb: '06174009',
    name: 'COOPERATIVA DE CRÉDITO SICOOB ALIANÇA',
    short_name: 'CC SICOOB ALIANÇA',
  },
  '05222094': {
    bank_ispb: '05222094',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE PIRACANJUBA LTDA',
    short_name: 'CCLA PIRACANJUBA',
  },
  '07494300': {
    bank_ispb: '07494300',
    name: 'COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS TRABALHADORES NA ÁREA DE SAÚDE, AFINS E EMPRESÁRIOS VINCULADOS À ACCI DE ERECHIM-RS-CREDISUL',
    short_name: 'CECM SAÚDE EMP ACCI ERECHIM',
  },
  '00204963': {
    bank_ispb: '00204963',
    name: 'COOPERATIVA DE CREDITO RURAL SEARA - CREDISEARA',
    short_name: 'CCR SEARA',
  },
  '01760242': {
    bank_ispb: '01760242',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE BELO HORIZONTE E CIDADES POLO DO ESTADO DE MINAS GERAIS LTDA. - SICOOB NOSSACOOP',
    short_name: 'CCLA DE BH E CIDADES POLO DE MG',
  },
  '03750034': {
    bank_ispb: '03750034',
    name: 'COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DOS JUÍZES E CARREIRAS PÚBLICAS DO DIREITO DO ESTADO DO RIO GRANDE DO SUL - SICREDI AJURIS RS',
    short_name: 'COOP SICREDI AJURIS',
  },
  '03862898': {
    bank_ispb: '03862898',
    name: 'COOPERATIVA DE ECONOMIA E CRÉDITO DE LIVRE ADMISSÃO DOS EMPREGADOS DOS CORREIOS LTDA. - SICOOB COOPERCORREIOS',
    short_name: 'CCLA EMPREG DOS CORREIOS - SICOOB COOPERCORREIOS',
  },
  '02072790': {
    bank_ispb: '02072790',
    name: 'COOPERATIVA DE ECONOMIA CRÉDITO MÚTUO - SICOOB CREDSAÚDE',
    short_name: 'CECM SICOOB CREDSAÚDE',
  },
  '01692448': {
    bank_ispb: '01692448',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE ASSOCIADOS DO PLANALTO SERRANO - SICOOB CREDISERRA SC',
    short_name: 'CCLA DO PLANALTO SERRANO',
  },
  '01330295': {
    bank_ispb: '01330295',
    name: 'COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS SERVIDORES PÚBLICOS NO ESTADO DO ESPÍRITO SANTO',
    short_name: 'SICOOB SERVIDORES',
  },
  '02398976': {
    bank_ispb: '02398976',
    name: 'SISPRIME DO BRASIL - COOPERATIVA DE CRÉDITO',
    short_name: 'SISPRIME DO BRASIL - COOP',
  },
  '04529074': {
    bank_ispb: '04529074',
    name: 'COOPERATIVA DE CRÉDITO DE CASCAVEL E REGIÃO - SICOOB CREDICAPITAL',
    short_name: 'CC CASCAVEL REG SICOOB CREDICAPITAL',
  },
  '00329598': {
    bank_ispb: '00329598',
    name: 'ÍNDIGO INVESTIMENTOS DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.',
    short_name: 'ÍNDIGO INVESTIMENTOS DTVM LTDA.',
  },
  '04490531': {
    bank_ispb: '04490531',
    name: 'COOPERATIVA DE CRÉDITO E INVESTIMENTO COM INTERAÇÃO SOLIDÁRIA DE GOIÁS - CRESOL GOIÁS',
    short_name: 'COOP CRESOL GOIAS',
  },
  '00714671': {
    bank_ispb: '00714671',
    name: 'EWALLY INSTITUIÇÃO DE PAGAMENTO S.A.',
    short_name: 'EWALLY IP S.A.',
  },
  '07502031': {
    bank_ispb: '07502031',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO CENTRO OESTE LTDA.',
    short_name: 'CCLA CENTRO OESTE LTDA.',
  },
  '05745533': {
    bank_ispb: '05745533',
    name: 'COOPERATIVA DE CRÉDITO E ECONOMIA COM INTERAÇÃO SOLIDÁRIA DE ITATIBA DO SUL - CRESOL ITATIBA DO SUL',
    short_name: 'COOP CRESOL ITATIBA DO SUL',
  },
  '04862600': {
    bank_ispb: '04862600',
    name: 'PORTOSEG S.A. - CREDITO, FINANCIAMENTO E INVESTIMENTO',
    short_name: 'PORTOSEG S.A. CFI',
  },
  '09554480': {
    bank_ispb: '09554480',
    name: 'SUPERDIGITAL INSTITUIÇÃO DE PAGAMENTO S.A.',
    short_name: 'SUPERDIGITAL I.P. S.A.',
  },
  '08560508': {
    bank_ispb: '08560508',
    name: 'COOPERATIVA DE CRÉDITO E INVESTIMENTO COM INTERAÇÃO SOLIDÁRIA TRIUNFO - CRESOL TRIUNFO',
    short_name: 'COOP CRESOL TRIUNFO',
  },
  '02587492': {
    bank_ispb: '02587492',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE ASSOCIADOS URUBICI - SICOOB CREDIARAUCÁRIA/SC',
    short_name: 'CCLA URUBICI',
  },
  '00803287': {
    bank_ispb: '00803287',
    name: 'COOPERATIVA DE CRéDITO CREDIVAZ LTDA. - SICOOB CREDIVAZ',
    short_name: 'CC CREDIVAZ LTDA',
  },
  '04886317': {
    bank_ispb: '04886317',
    name: 'COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DOS INTEGRANTES DA MAGISTRATURA E DO MINISTÉRIO PÚBLICO NO ESTADO DO PARANÁ - SICREDI CREDJURIS',
    short_name: 'COOP SICREDI CREDJURIS',
  },
  '05247312': {
    bank_ispb: '05247312',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO BURITIS - SICOOB BURITIS',
    short_name: 'COOP SICOOB BURITIS',
  },
  '04247370': {
    bank_ispb: '04247370',
    name: 'COOPERATIVA DE CRÉDITO DE EMPRESÁRIOS - SICOOB/TRANSCREDI',
    short_name: 'CC DE EMPRESÁRIOS',
  },
  '03566655': {
    bank_ispb: '03566655',
    name: 'COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO CELEIRO CENTRO OESTE - SICREDI CELEIRO CENTRO OESTE',
    short_name: 'COOP SICREDI CELEIRO OESTE',
  },
  '02843443': {
    bank_ispb: '02843443',
    name: 'COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DO NORTE E NORDESTE DE SANTA CATARINA - SICREDI NORTE SC',
    short_name: 'COOP SICREDI NORTE SC',
  },
  '03033469': {
    bank_ispb: '03033469',
    name: 'COOPERATIVA DE CREDITO DO PLANALTO SUL - SICOOB CREDISSERRANA',
    short_name: 'CC DO PLANALTO SUL',
  },
  '01181521': {
    bank_ispb: '01181521',
    name: 'BANCO COOPERATIVO SICREDI S.A.',
    short_name: 'BCO COOPERATIVO SICREDI S.A.',
  },
  '03358914': {
    bank_ispb: '03358914',
    name: 'COOPERATIVA DE CREDITO CREDIROCHAS - SICOOB CREDIROCHAS',
    short_name: 'SICOOB CREDIROCHAS',
  },
  '01736516': {
    bank_ispb: '01736516',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DA REGIÃO CENTRAL E OESTE MINEIRO LTDA. - SICOOB DIVICRED',
    short_name: 'CCLA REG. CTR. E OESTE MINEIRO',
  },
  '01152097': {
    bank_ispb: '01152097',
    name: 'COOPERATIVA DE CRÉDITO CREDIMATA LTDA. - SICOOB CREDIMATA',
    short_name: 'CC CREDIMATA',
  },
  '05241145': {
    bank_ispb: '05241145',
    name: 'COOPERATIVA DE CRÉDITO RURAL E ECONOMIA COM INTERAÇÃO SOLIDÁRIA DE GETÚLIO VARGAS - CRESOL GETÚLIO VARGAS',
    short_name: 'COOP CRESOL GETULIO VARGAS',
  },
  '04622657': {
    bank_ispb: '04622657',
    name: 'COOPERATIVA DE CRÉDITO RURAL COM INTERAÇÃO SOLIDÁRIA GERAÇÕES - CRESOL GERAÇÕES',
    short_name: 'COOP CRESOL GERACOES',
  },
  '05861932': {
    bank_ispb: '05861932',
    name: 'COOPERATIVA DE CRÉDITO MÚTUO DOS ADVOGADOS DE SANTA CATARINA',
    short_name: 'CCM DOS ADVOGADOS DE SC',
  },
  '07714057': {
    bank_ispb: '07714057',
    name: 'COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS PROFISSIONAIS DA ÁREA NOTARIAL E REGISTRAL - COOPNORE',
    short_name: 'CECM PROF ÁREA NOTARIAL REGISTRAL',
  },
  '03015152': {
    bank_ispb: '03015152',
    name: 'COOPERATIVA DE CRÉDITO E ECONOMIA COM INTERAÇÃO SOLIDÁRIA DE SÃO VALENTIM - CRESOL SÃO VALENTIM',
    short_name: 'COOP CRESOL SÃO VALENTIM',
  },
  '04833655': {
    bank_ispb: '04833655',
    name: 'COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO SICOOB METALCRED',
    short_name: 'CECM SICOOB METALCRED',
  },
  '08624548': {
    bank_ispb: '08624548',
    name: 'COOPERATIVA DE CRÉDITO E INVESTIMENTO COM INTERAÇÃO SOLIDÁRIA DE MINAS GERAIS - CRESOL MINAS GERAIS',
    short_name: 'COOP CRESOL MINAS GERAIS',
  },
  '08075352': {
    bank_ispb: '08075352',
    name: 'COOPERATIVA DE CRÉDITO DOS EMPRESÁRIOS E EMPREGADOS DOS TRANSPORTES E CORREIOS DO SUL DO BRASIL - TRANSPOCRED',
    short_name: 'COOP TRANSPOCRED',
  },
  '05477038': {
    bank_ispb: '05477038',
    name: 'COOPERATIVA DE CRÉDITO NO PIAUÍ - SICOOB PIAUÍ',
    short_name: 'CC NO PIAUÍ - SICOOB PIAUÍ',
  },
  '04079285': {
    bank_ispb: '04079285',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE ITAJUBÁ LTDA - SICOOB SUDESTE MAIS',
    short_name: 'CCLA DE ITAJUBÁ - SICOOB SUDESTE MAIS',
  },
  '03323840': {
    bank_ispb: '03323840',
    name: 'BANCO ALFA S.A.',
    short_name: 'BCO ALFA S.A.',
  },
  '04876393': {
    bank_ispb: '04876393',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO TRÊS FRONTEIRAS - SICOOB TRÊS FRONTEIRAS',
    short_name: 'CCLA TRÊS FRONTEIRAS',
  },
  '06126780': {
    bank_ispb: '06126780',
    name: 'COOPERATIVA DE CRÉDITO E INVESTIMENTO COM INTERAÇÃO SOLIDÁRIA GRANDES LAGOS PR/SP - CRESOL GRANDES LAGOS PR/SP',
    short_name: 'COOP CRESOL GRANDES LAGOS PR/SP',
  },
  '04355489': {
    bank_ispb: '04355489',
    name: 'COOPERATIVA DE CRÉDITO UNICRED COOMARCA LTDA. - COOMARCA',
    short_name: 'CC UNICRED COOMARCA',
  },
  '02025743': {
    bank_ispb: '02025743',
    name: 'COOPERATIVA DE CRÉDITO E INVESTIMENTO COM INTERAÇÃO SOLIDÁRIA VALE DO OURO - CRESOL VALE DO OURO',
    short_name: 'COOP CRESOL VALE DO OURO',
  },
  '02794761': {
    bank_ispb: '02794761',
    name: 'COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS SERVIDORES DA UNIVERSIDADE FEDERAL DE VIÇOSA LTDA. - SICOOB UFVCREDI',
    short_name: 'CECM SERV UNIV FED VIÇOSA',
  },
  '09579249': {
    bank_ispb: '09579249',
    name: 'COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS EMPRESÁRIOS DE AMERICANA, LIMEIRA E REGIÃO - SICOOB ACICRED',
    short_name: 'CECM EMP AMERICANA, LIMEIRA E',
  },
  '06031727': {
    bank_ispb: '06031727',
    name: 'COOPERATIVA DE CRÉDITO E INVESTIMENTO COM INTERAÇÃO SOLIDÁRIA ESSÊNCIA - CRESOL ESSÊNCIA',
    short_name: 'COOP CRESOL ESSENCIA',
  },
  '02144899': {
    bank_ispb: '02144899',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DA REGIÃO CENTRAL DE RONDÔNIA   SICOOB OUROCREDI',
    short_name: 'CCLA DA REGIÃO CENTRAL DE RONDÔNIA',
  },
  '04350225': {
    bank_ispb: '04350225',
    name: 'COOPERATIVA DE CRÉDITO E INVESTIMENTO COM INTERAÇÃO SOLIDÁRIA TRADIÇÃO - CRESOL TRADIÇÃO',
    short_name: 'COOP CRESOL TRADICAO',
  },
  '03732359': {
    bank_ispb: '03732359',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE PERNAMBUCO - SICOOB PERNAMBUCO',
    short_name: 'CCLA DE PERNAMBUCO - SICOOB PERNAMBUCO',
  },
  '01664968': {
    bank_ispb: '01664968',
    name: 'COOPERATIVA  DE CRÉDITO DE LIVRE ADMISSÃO DE ASSOCIADOS UNIRONDÔNIA LTDA',
    short_name: 'CCLA DE ASSOCIADOS UNIRONDÔNIA LTDA',
  },
  '00869687': {
    bank_ispb: '00869687',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE BOA ESPERANÇA LTDA. - SICOOB BELCREDI',
    short_name: 'CCLA BOA ESPERANÇA',
  },
  '00655522': {
    bank_ispb: '00655522',
    name: 'ASSOCIACAO DE POUPANCA E EMPRESTIMO - POUPEX',
    short_name: 'APE POUPEX',
  },
  '01896779': {
    bank_ispb: '01896779',
    name: 'CSU CARDSYSTEM S/A',
    short_name: 'CSU',
  },
  '02254376': {
    bank_ispb: '02254376',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE ASSOCIADOS DE DORES DO INDAIÁ LTDA - COOPCREDI',
    short_name: 'CCLA DE DORES DO INDAIA',
  },
  '07465539': {
    bank_ispb: '07465539',
    name: 'COOPERATIVA DE CRÉDITO RURAL COM INTERAÇÃO SOLIDÁRIA ALTO VALE - CRESOL ALTO VALE',
    short_name: 'COOP CRESOL ALTO VALE',
  },
  '07599206': {
    bank_ispb: '07599206',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DA REGIÃO METROPOLITANA DE GOIÂNIA LTDA.',
    short_name: 'CCLA DA REGIÃO METROPOLITANA DE GOIÂNIA LTDA.',
  },
  '03662047': {
    bank_ispb: '03662047',
    name: 'COOPERATIVA DE CRéDITO, POUPANçA E INVESTIMENTO DAS CARREIRAS DO MINISTéRIO PúBLICO, TRIBUNAL DE CONTAS E SECRETARIA DA FAZENDA DO RIO GRANDE DO SUL - SICREDI MP RS',
    short_name: 'COOP SICREDI MP',
  },
  '00804046': {
    bank_ispb: '00804046',
    name: 'COOPERATIVA DE CRÉDITO CREDICAPI LTDA - SICOOB CREDICAPI',
    short_name: 'COOP CREDICAPI LTDA - SICOOB CREDICAPI',
  },
  '05463212': {
    bank_ispb: '05463212',
    name: 'COOPERATIVA CENTRAL DE CRÉDITO - AILOS',
    short_name: 'COOPCENTRAL AILOS',
  },
  '01796302': {
    bank_ispb: '01796302',
    name: 'COOPERATIVA DE CRÉDITO UNICRED REGIÃO DOS VALES LTDA. - UNICRED REGIÃO DOS VALES',
    short_name: 'UNICRED REGIÃO DOS VALES',
  },
  '01389651': {
    bank_ispb: '01389651',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE ASSOCIADOS DO PLANALTO CATARINENSE SICOOB CREDIPLANALTO SC/RS',
    short_name: 'CCLA DO PLANALTO CATARINENSE',
  },
  '04913711': {
    bank_ispb: '04913711',
    name: 'BANCO DO ESTADO DO PARÁ S.A.',
    short_name: 'BCO DO EST. DO PA S.A.',
  },
  '00815319': {
    bank_ispb: '00815319',
    name: 'COOPERATIVA DE CRÉDITO SUL-SERRANA DO ESPÍRITO SANTO',
    short_name: 'SICOOB SUL-SERRANO',
  },
  '04463602': {
    bank_ispb: '04463602',
    name: 'COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DA REGIÃO CENTRO OESTE PAULISTA - SICREDI CENTRO OESTE PAULISTA',
    short_name: 'COOP SICREDI CEN OEST PAULISTA',
  },
  '04902979': {
    bank_ispb: '04902979',
    name: 'BANCO DA AMAZONIA S.A.',
    short_name: 'BCO DA AMAZONIA S.A.',
  },
  '07215632': {
    bank_ispb: '07215632',
    name: 'COOPERATIVA DE CRÉDITO RURAL COM INTERAÇÃO SOLIDÁRIA ALTERNATIVA - CRESOL ALTERNATIVA',
    short_name: 'COOP CRESOL ALTERNATIVA',
  },
  '01635462': {
    bank_ispb: '01635462',
    name: 'COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS MÉDICOS DOS VALES DO TAQUARI E RIO PARDO E REGIÃO DA PRODUÇÃO LTDA. - UNICRED PREMIUM',
    short_name: 'CECM DOS MÉDICOS DOS V TAQUARI E RIO PARDO E REG PRO',
  },
  '07509426': {
    bank_ispb: '07509426',
    name: 'COOPERATIVA DE CRÉDITO E ECONOMIA COM INTERAÇÃO SOLIDÁRIA - CRESOL XANXERÊ',
    short_name: 'COOP CRESOL XANXERE',
  },
  '00971300': {
    bank_ispb: '00971300',
    name: 'COOPERATIVA DE CREDITO E INVESTIMENTO COM INTERACAO SOLIDARIA PIONEIRA - CRESOL PIONEIRA',
    short_name: 'COOP CRESOL PIONEIRA',
  },
  '09515813': {
    bank_ispb: '09515813',
    name: 'ABASTECE AI CLUBE AUTOMOBILISTA PAYMENT LTDA.',
    short_name: 'ABASTECE AÍ',
  },
  '03320525': {
    bank_ispb: '03320525',
    name: 'COOPERATIVA DE CRÉDITO ARACOOP LTDA. - SICOOB ARACOOP',
    short_name: 'COOP ARACOOP LTDA. - SICOOB ARACOOP',
  },
  '02015588': {
    bank_ispb: '02015588',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO CENTRO SUL RONDONIENSE - SICOOB CREDIP.',
    short_name: 'CCLA DO CENTRO SUL RONDONIENSE',
  },
  '00707903': {
    bank_ispb: '00707903',
    name: 'COOPERATIVA DE CRÉDITO VALE DO CANOAS - SICOOB/SC CREDICANOAS',
    short_name: 'CC VALE DO CANOAS',
  },
  '05231945': {
    bank_ispb: '05231945',
    name: 'COOPERATIVA DE CRÉDITO E INVESTIMENTO COM INTERAÇÃO SOLIDÁRIA UNIÃO DOS VALES - CRESOL UNIÃO DOS VALES',
    short_name: 'COOP CRESOL UNIAO DOS VALES',
  },
  '03816413': {
    bank_ispb: '03816413',
    name: 'PAGUEVELOZ INSTITUIÇÃO DE PAGAMENTO LTDA.',
    short_name: 'PAGUEVELOZ IP LTDA.',
  },
  '00394460': {
    bank_ispb: '00394460',
    name: 'Secretaria do Tesouro Nacional - STN',
    short_name: 'STN',
  },
  '02766672': {
    bank_ispb: '02766672',
    name: 'COOPERATIVA DE CRÉDITO RURAL COM INTERAÇÃO SOLIDÁRIA PLANALTO SERRANO - CRESOL PLANALTO SERRANO',
    short_name: 'COOP CRESOL PLANALTO SERRANO',
  },
  '06975532': {
    bank_ispb: '06975532',
    name: 'COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS PEQUENOS EMPRESÁRIOS, MICROEMPRESÁRIOS E MICROEMPREENDEDORES DA REGIÃO METROPOLITANA DE PORTO ALEGRE - COOPESA',
    short_name: 'CCPEMM DA RM DE PORTO ALEGRE',
  },
  '05863726': {
    bank_ispb: '05863726',
    name: 'COOPERATIVA DE CRÉDITO E ECONOMIA COM INTERAÇÃO SOLIDÁRIA DO PLANALTO SERRA DO RIO GRANDE DO SUL - CRESOL PLANALTO SERRA',
    short_name: 'COOP CRESOL PLANALTO SERRA',
  },
  '06910457': {
    bank_ispb: '06910457',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE SANTA CRUZ DAS PALMEIRAS E REGIÃO',
    short_name: 'CCLA SANTA CRUZ PALMEIRAS',
  },
  '04572960': {
    bank_ispb: '04572960',
    name: 'COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS MILITARES ESTADUAIS DE SANTA CATARINA - CREDPOM',
    short_name: 'CECM MILITARES ESTADUAIS DE SC',
  },
  '03519240': {
    bank_ispb: '03519240',
    name: 'COOPERATIVA DE CRÉDITO DOS INTEGRANTES DO PODER JUDICIÁRIO E DO MINISTÉRIO PÚBLICO DO ESTADO DE MINAS GERAIS LTDA. - SICOOB JUS-MP',
    short_name: 'CC PODER JUD E MP MG',
  },
  '02641969': {
    bank_ispb: '02641969',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE ASSOCIADOS NOVA TRENTO   SICOOB TRENTOCREDI SC',
    short_name: 'CCLAA NOVA TRENTO',
  },
  '07206072': {
    bank_ispb: '07206072',
    name: 'COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO NOVOS HORIZONTES - SICREDI NOVOS HORIZONTES PR/SP',
    short_name: 'COOP SICREDI NOVOS HORIZONTES',
  },
  '04261151': {
    bank_ispb: '04261151',
    name: 'COOPERATIVA DE CRÉDITO RURAL COM INTERAÇÃO SOLIDÁRIA TRANSFORMAÇÃO - CRESOL TRANSFORMAÇÃO',
    short_name: 'COOP CRESOL TRANSFORMACAO',
  },
  '02031139': {
    bank_ispb: '02031139',
    name: 'SICOOB CREDIMONTES - COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO REGIONAL DE MONTES CLAROS LTDA',
    short_name: 'CCLA MONTES CLAROS',
  },
  '02038232': {
    bank_ispb: '02038232',
    name: 'BANCO COOPERATIVO SICOOB S.A. - BANCO SICOOB',
    short_name: 'BANCO SICOOB S.A.',
  },
  '08055016': {
    bank_ispb: '08055016',
    name: 'COOPERATIVA DE CRÉDITO E ECONOMIA COM INTERAÇÃO SOLIDÁRIA - CRESOL INTERAÇÃO',
    short_name: 'COOP CRESOL INTERAÇÃO',
  },
  '04406371': {
    bank_ispb: '04406371',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO ESTADO DE GOIÁS',
    short_name: 'COOP DO ESTADO DE GOIÁS',
  },
  '05428587': {
    bank_ispb: '05428587',
    name: 'COOPERATIVA DE CREDITO E ECONOMIA COM INTERAÇÃO SOLIDARIA - CRESOL OLIVEIRA DOS BREJINHOS',
    short_name: 'COOP CRESOL OLIVEIRA DOS BREJINHOS',
  },
  '07469260': {
    bank_ispb: '07469260',
    name: 'COOPERATIVA DE CRÉDITO CREDIFIEMG LTDA. - SICOOB CREDIFIEMG',
    short_name: 'CC CREDIFIEMG',
  },
  '01637949': {
    bank_ispb: '01637949',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO VALE DO PARANAPANEMA',
    short_name: 'CCLA VALE DO PARANAPANEMA',
  },
  '02833202': {
    bank_ispb: '02833202',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE VITÓRIA DA CONQUISTA',
    short_name: 'UNICRED SUDOESTE DA BAHIA',
  },
  '04751713': {
    bank_ispb: '04751713',
    name: 'COOPERATIVA DE CRÉDITO E INVESTIMENTOS DE RONDÔNIA - SICOOB CREDJURD',
    short_name: 'CC INV DE RONDÔNIA - SICOOB CREDJURD',
  },
  '07958405': {
    bank_ispb: '07958405',
    name: 'COOPERATIVA DE CREDITO E ECONOMIA COM INTERAÇÃO SOLIDÁRIA - CRESOL SERRA MAR',
    short_name: 'COOP CRESOL SERRA MAR',
  },
  '03311443': {
    bank_ispb: '03311443',
    name: 'PARATI - CREDITO, FINANCIAMENTO E INVESTIMENTO S.A.',
    short_name: 'PARATI - CFI S.A.',
  },
  '02602922': {
    bank_ispb: '02602922',
    name: 'COOPERATIVA DE CRÉDITO DOS MÉDICOS, SERVIDORES PÚBLICOS E EMPRESÁRIOS DO NORDESTE LTDA - UNICRED DO NORDESTE',
    short_name: 'CC MÉD, SERV PUBL E EMP DO NE LTDA.',
  },
  '04814563': {
    bank_ispb: '04814563',
    name: 'BANCO AFINZ S.A. - BANCO MÚLTIPLO',
    short_name: 'BCO AFINZ S.A. - BM',
  },
  '06324872': {
    bank_ispb: '06324872',
    name: 'COOPERATIVA DE CRÉDITO CREDINOSSO LTDA - SICOOB CREDINOSSO',
    short_name: 'CC CREDINOSSO',
  },
  '02179673': {
    bank_ispb: '02179673',
    name: 'COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS MÉDICOS E DEMAIS PROFISSIONAIS DE NÍVEL SUPERIOR DA ÁREA DE SAÚDE DE OURINHOS - OURICRED',
    short_name: 'CECM MÉD OURINHOS',
  },
  '02090126': {
    bank_ispb: '02090126',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE ASSOCIADOS VALE DO CHAPECOZINHO - SICOOB VALCREDI SUL',
    short_name: 'CCLA SICOOB VALCREDI SUL',
  },
  '08795285': {
    bank_ispb: '08795285',
    name: 'COOPERATIVA DE CRéDITO DE LIVRE ADMISSãO SICOOB CREDIACIL',
    short_name: 'CCLA SICOOB CREDIACIL',
  },
  '01566038': {
    bank_ispb: '01566038',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE ASSOCIADOS DE MANAUS',
    short_name: 'CCLA MANAUS',
  },
  '02173447': {
    bank_ispb: '02173447',
    name: 'COOPERATIVA DE CREDITO DE LIVRE ADMISSAO DO CENTRO NORDESTE MINEIRO LTDA - SICOOB CREDICENM',
    short_name: 'CCLA CENTRO NORDESTE MINEIRO',
  },
  '07252614': {
    bank_ispb: '07252614',
    name: 'COOPERATIVA DE CRÉDITO E INVESTIMENTO COM INTERAÇÃO SOLIDÁRIA CONFIANÇA - CRESOL CONFIANÇA',
    short_name: 'COOP CRESOL CONFIANÇA',
  },
  '02282165': {
    bank_ispb: '02282165',
    name: 'COOPERATIVA DE CRÉDITO DO SUDOESTE BAIANO LTDA - SICOOB CREDICONQUISTA',
    short_name: 'CC SICOOB CREDICONQUISTA',
  },
  '05856736': {
    bank_ispb: '05856736',
    name: 'COOPERATIVA DE ECONOMIA E CRÉDITO DE LIVRE ADMISSÃO LTDA. - SICOOB EMPRESARIAL',
    short_name: 'SICOOB EMPRESARIAL',
  },
  '01703620': {
    bank_ispb: '01703620',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO UNIÃO DO CENTRO OESTE DE MINAS LTDA - SICOOB CREDESP',
    short_name: 'CCLA UNIÃO CENTRO OESTE MINAS',
  },
  '00309024': {
    bank_ispb: '00309024',
    name: 'CENTRAL DAS COOPERATIVAS DE ECONOMIA E CREDITO DO ESTADO DE MINAS GERAIS LTDA. - SICOOB CENTRAL CECREMGE',
    short_name: 'CCC ESTADO MG - CECREMGE',
  },
  '04565791': {
    bank_ispb: '04565791',
    name: 'COOPERATIVA DE CRÉDITO E ECONOMIA COM INTERAÇÃO SOLIDÁRIA DE ARATIBA - CRESOL ARATIBA',
    short_name: 'COOP CRESOL ARATIBA',
  },
  '05494591': {
    bank_ispb: '05494591',
    name: 'COOPERATIVA DE CRÉDITO E INVESTIMENTO COM INTERAÇÃO SOLIDÁRIA NASCENTE - CRESOL NASCENTE',
    short_name: 'COOP CRESOL NASCENTE',
  },
  '08647473': {
    bank_ispb: '08647473',
    name: 'COOPERATIVA DE CRÉDITO E ECONOMIA COM INTERAÇÃO SOLIDÁRIA DE CORONEL FREITAS - CRESOL CORONEL FREITAS',
    short_name: 'COOP CRESOL CORONEL FREITAS',
  },
  '02663426': {
    bank_ispb: '02663426',
    name: 'COOPERATIVA DE CRÉDITO E ECONOMIA COM INTERAÇÃO SOLIDÁRIA - CRESOL NOROESTE',
    short_name: 'COOP CRESOL NOROESTE',
  },
  '03985375': {
    bank_ispb: '03985375',
    name: 'CREDISIS SUDOESTE/RO - COOPERATIVA DE CRÉDITO E INVESTIMENTO DO SUDOESTE DE RONDÔNIA',
    short_name: 'CREDISIS SUDOESTE/RO',
  },
  '09004796': {
    bank_ispb: '09004796',
    name: 'COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO SICOOB CRED-ACILPA',
    short_name: 'CECM SICOOB CRED-ACILPA',
  },
  '01330387': {
    bank_ispb: '01330387',
    name: 'COOPERATIVA DE CRÉDITO RURAL DE PEQUENOS AGRICULTORES E DA REFORMA AGRÁRIA DO CENTRO OESTE DO PARANÁ - CREHNOR LARANJEIRAS',
    short_name: 'CREHNOR LARANJEIRAS',
  },
  '04307598': {
    bank_ispb: '04307598',
    name: 'FIDÚCIA SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORTE LIMITADA.',
    short_name: 'FIDUCIA SCMEPP LTDA',
  },
  '08041950': {
    bank_ispb: '08041950',
    name: 'COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DO SISTEMA DE JUSTIÇA NO ESTADO DO CEARÁ - SICREDI COOPERJURIS',
    short_name: 'COOP SICREDI COOPERJURIS',
  },
  '01286361': {
    bank_ispb: '01286361',
    name: 'UNIPRIME PIONEIRA COOPERATIVA DE CRÉDITO',
    short_name: 'UNIPRIME PIONEIRA CC',
  },
  '02904138': {
    bank_ispb: '02904138',
    name: 'COOPERATIVA DE CRÉDITO RURAL E ECONOMIA COM INTERAÇÃO SOLIDÁRIA DE ÁUREA - CRESOL ÁUREA',
    short_name: 'COOP CRESOL ÁUREA',
  },
  '00517645': {
    bank_ispb: '00517645',
    name: 'BANCO RIBEIRAO PRETO S.A.',
    short_name: 'BCO RIBEIRAO PRETO S.A.',
  },
  '00512839': {
    bank_ispb: '00512839',
    name: 'COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS SERVIDORES DO MUNICIPIO DE SANTO ANDRÉ - SP - SICOOB CECRESA',
    short_name: 'CECM SERV MUNIC SANTO ANDRE',
  },
  '09424988': {
    bank_ispb: '09424988',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO OESTE MARANHENSE - SICOOB OESTE MARANHENSE',
    short_name: 'CCLA DO OESTE MARANHENSE - SICOOB OESTE MARANHENSE',
  },
  '02448839': {
    bank_ispb: '02448839',
    name: 'COOPERATIVA DE CRÉDITO DE RIO RUFINO - SICOOB CREDIUNIÃO',
    short_name: 'SICOOB CREDIUNIÃO',
  },
  '00000208': {
    bank_ispb: '00000208',
    name: 'BRB - BANCO DE BRASILIA S.A.',
    short_name: 'BRB - BCO DE BRASILIA S.A.',
  },
  '01709266': {
    bank_ispb: '01709266',
    name: 'COOPERATIVA DE CRéDITO MúTUO DOS PROFISSIONAIS DA SAúDE E LIVRE ADMISSãO DE TEóFILO OTONI LTDA. - UNICRED TRêS VALES',
    short_name: 'CCM PROF SAUDE E LIVRE ADMISSÃO TEOFILO OTONI LTDA',
  },
  '04120633': {
    bank_ispb: '04120633',
    name: 'COOPERATIVA DE CREDITO CLASSICA DO ESTADO DO RIO DE JANEIRO - SICOOB EMPRESAS RJ',
    short_name: 'SICOOB EMPRESAS RJ',
  },
  '01609345': {
    bank_ispb: '01609345',
    name: 'COOPERATIVA DE CRÉDITO CREDICAMPINA LTDA. - SICOOB CREDICAMPINA',
    short_name: 'CC CREDICAMPINA',
  },
  '00952415': {
    bank_ispb: '00952415',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO CREDFAZ LTDA. - SICOOB CREDFAZ',
    short_name: 'SICOOB CREDFAZ',
  },
  '04715685': {
    bank_ispb: '04715685',
    name: 'COOPERATIVA DE CRÉDITO MÚTUO DOS DESPACHANTES DE TRÂNSITO DE SANTA CATARINA E RIO GRANDE DO SUL - SICOOB CREDITRAN',
    short_name: 'CCM DESP TRÂNS SC E RS',
  },
  '06139650': {
    bank_ispb: '06139650',
    name: 'COOPERATIVA DE CRÉDITO E INVESTIMENTO COM INTERAÇÃO SOLIDÁRIA DO RIO GRANDE DO SUL - CRESOL RIO GRANDE DO SUL',
    short_name: 'COOP CRESOL RIO GRANDE DO SUL',
  },
  '00000000': {
    bank_ispb: '00000000',
    name: 'BANCO DO BRASIL S.A.',
    short_name: 'BCO DO BRASIL S.A.',
  },
  '02935307': {
    bank_ispb: '02935307',
    name: 'COOPERATIVA DE CRéDITO SICOOB CREDSEGURO LTDA.',
    short_name: 'CC SICOOB CREDSEGURO LTDA.',
  },
  '02275781': {
    bank_ispb: '02275781',
    name: 'COOPERATIVA DE CRÉDITO MÚTUO DOS SERVIDORES FEDERAIS NA PARAÍBA - SICOOB COOPERCRET',
    short_name: 'CCM SERV FEDERAIS NA PARAÍBA - SICOOB COOPERCRET',
  },
  '01864151': {
    bank_ispb: '01864151',
    name: 'COOPERATIVA  ECONOMIA CRÉDITO MÚTUO  SERVS E EMPREGADOS PÚBLICOS MUNIC DE BELO HORIZONTE BETIM BRUMADINHO CONTAGEM IBIRITÉ NOVA LIMA RIBEIRÃO DAS NEVES SABARÁ SANTA LUZIA E VESPASIANO SICOOB CREDISERV',
    short_name: 'CECM SERV E EMPREG PUBL MUN BH BETIM BRUMAD CONTAG IBI NL RN',
  },
  '08357240': {
    bank_ispb: '08357240',
    name: 'BANCO CSF S.A.',
    short_name: 'BCO CSF S.A.',
  },
  '03269540': {
    bank_ispb: '03269540',
    name: 'COOPERATIVA DE CRÉDITO DOS SERVIDORES MILITARES, POLÍCIA CIVIL E DA SECRETARIA DE EDUCAÇÃO DO ESTADO DE MINAS GERAIS LTDA - SICOOB COOPEMG',
    short_name: 'CC SERV MIL POL CIVIL SEC ED',
  },
  '00315557': {
    bank_ispb: '00315557',
    name: 'CONFEDERAÇÃO NACIONAL DAS COOPERATIVAS CENTRAIS UNICRED LTDA. - UNICRED DO BRASIL.',
    short_name: 'CONF NAC COOP CENTRAIS UNICRED',
  },
  '08071414': {
    bank_ispb: '08071414',
    name: 'COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO - SICOOB COOPERAC',
    short_name: 'CECM SICOOB COOPERAC',
  },
  '02217068': {
    bank_ispb: '02217068',
    name: 'COOPERATIVA DE CRÉDITO DOS CONTADORES, ADMINISTRADORES DE EMPRESAS, ECONOMISTAS E CORRETORES DE SEGUROS LTDA. - SICOOB CREDITABIL',
    short_name: 'CC CONTADORES, ADMINISTRADORES, ECONOMISTAS E CORRET SEGUROS',
  },
  '05070112': {
    bank_ispb: '05070112',
    name: 'COOPERATIVA DE CRÉDITO E ECONOMIA COM INTERAÇÃO SOLIDÁRIA - CRESOL INOVAÇÃO',
    short_name: 'COOP CRESOL INOVAÇAO',
  },
  '03102185': {
    bank_ispb: '03102185',
    name: 'COOPERATIVA DE CRÉDITO DOS SERVIDORES DA UNIÃO, EMPRESÁRIOS, PROFISSIONAIS AUTÔNOMOS E LIBERAIS - SICOOB CENTRO NORDESTE',
    short_name: 'CC DOS SERVIDORES, EMPRESARIOS, AUTONOMOS E LIBERAIS',
  },
  '07318874': {
    bank_ispb: '07318874',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO MÉDIO OESTE - SICOOB MÉDIO OESTE',
    short_name: 'CCLA MÉDIO OESTE',
  },
  '01572667': {
    bank_ispb: '01572667',
    name: 'COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO UNICRED ERECHIM LTDA',
    short_name: 'UNICRED ERECHIM',
  },
  '09590601': {
    bank_ispb: '09590601',
    name: 'COOPERATIVA DE CRÉDITO DA SERRA CATARINENSE - CREDICOMIN',
    short_name: 'CC DA SERRA CATARINENSE',
  },
  '01187961': {
    bank_ispb: '01187961',
    name: 'COOPERATIVA DE CRÉDITO DO DISTRITO FEDERAL E ENTORNO LTDA',
    short_name: 'CC DISTRITO FEDERAL E ENTORNO',
  },
  '02904125': {
    bank_ispb: '02904125',
    name: 'COOPERATIVA DE CRÉDITO E ECONOMIA COM INTERAÇÃO SOLIDÁRIA DE JACUTINGA - CRESOL JACUTINGA',
    short_name: 'COOP CRESOL JACUTINGA',
  },
  '08143326': {
    bank_ispb: '08143326',
    name: 'COOPERATIVA DE CRÉDITO DOS MÉDICOS E PROFISSIONAIS DE SAÚDE DE SÃO LUÍS - SICREDI SÃO LUÍS',
    short_name: 'COOP SICREDI SÃO LUÍS',
  },
  '04174720': {
    bank_ispb: '04174720',
    name: 'COOPERATIVA DE CRÉDITO SICOOB COOPEMAR LTDA',
    short_name: 'CC SICOOB COOPEMAR',
  },
  '07268499': {
    bank_ispb: '07268499',
    name: 'COOPERATIVA DE CRÉDITO E INVESTIMENTO COM INTERAÇÃO SOLIDÁRIA INTEGRAÇÃO - CRESOL INTEGRAÇÃO',
    short_name: 'COOP CRESOL INTEGRACAO',
  },
  '07194313': {
    bank_ispb: '07194313',
    name: 'COOPERATIVA DE CRÉDITO HORIZONTE - SICOOB HORIZONTE',
    short_name: 'CC SICOOB HORIZONTE',
  },
  '03046391': {
    bank_ispb: '03046391',
    name: 'UNIPRIME CENTRAL NACIONAL - CENTRAL NACIONAL DE COOPERATIVA DE CREDITO',
    short_name: 'UNIPRIME COOPCENTRAL LTDA.',
  },
  '01667766': {
    bank_ispb: '01667766',
    name: 'SICOOB CREDINOVA - COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE NOVA SERRANA E REGIÃO CENTRO-OESTE LTDA.',
    short_name: 'CCLA NOVA SERRANA E REGIÃO CO',
  },
  '05158093': {
    bank_ispb: '05158093',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DA REGIÃO LESTE DA BACIA DO RIO DOCE LTDA   SICOOB CREDILESTE',
    short_name: 'CCLA REG LESTE BACIA RIO DOCE',
  },
  '08030602': {
    bank_ispb: '08030602',
    name: 'COOPERATIVA DE CRÉDITO MÚTUO DOS CORRETORES DE SEGUROS DO ESTADO DE SÃO PAULO - SICOOB CREDICOR/SP',
    short_name: 'CCM CORRET SEG EST SAO PAULO',
  },
  '05463934': {
    bank_ispb: '05463934',
    name: 'COOPERATIVA DE CREDITO CREDLIDER - SICOOB CREDLIDER',
    short_name: 'CC CREDLIDER',
  },
  '05439425': {
    bank_ispb: '05439425',
    name: 'CREDISIS CAPITALCREDI - COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO ACRE LTDA',
    short_name: 'CREDISIS CAPITALCREDI - CCLA DO ACRE LTDA.',
  },
  '08488377': {
    bank_ispb: '08488377',
    name: 'COOPERATIVA DE CRÉDITO RURAL COM INTERAÇÃO SOLIDÁRIA MISSÕES FRONTEIRA RS - CRESOL MISSÕES FRONTEIRA RS',
    short_name: 'COOP CRESOL MISSÕES FRONTEIRA RS',
  },
  '02232383': {
    bank_ispb: '02232383',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DA REGIÃO DO CIRCUITO CAMPOS DAS VERTENTES LTDA - SICOOB COPERMEC',
    short_name: 'CCLA CIRCUITO CAMPOS VERTENTES',
  },
  '07237373': {
    bank_ispb: '07237373',
    name: 'BANCO DO NORDESTE DO BRASIL S.A.',
    short_name: 'BCO DO NORDESTE DO BRASIL S.A.',
  },
  '07669921': {
    bank_ispb: '07669921',
    name: 'COOPERATIVA DE CRéDITO MúTUO - SICOOB CREDIACISC',
    short_name: 'CCM SICOOB CREDIACISC',
  },
  '01608685': {
    bank_ispb: '01608685',
    name: 'COOPERATIVA DE CRéDITO DE LIVRE ADMISSãO DO ESTADO DO ACRE LTDA.',
    short_name: 'COOP DO ESTADO DO ACRE LTDA.',
  },
  '00698609': {
    bank_ispb: '00698609',
    name: 'COOPERATIVA DE CRÉDITO DO NORTE E NOROESTE DE MINAS GERAIS LTDA. - SICOOB CREDIGERAIS',
    short_name: 'CC NORTE E NOROESTE DE MINAS GERAIS',
  },
  '00604122': {
    bank_ispb: '00604122',
    name: 'TRIVALE ADMINISTRACAO LTDA',
    short_name: 'TRIVALE',
  },
  '09576849': {
    bank_ispb: '09576849',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE CERES E RIALMA LTDA',
    short_name: 'CCLA CERES E RIALMA LTDA',
  },
  '07925729': {
    bank_ispb: '07925729',
    name: 'COOPERATIVA DE CRÉDITO E INVESTIMENTO COM INTERAÇÃO SOLIDÁRIA NORTE PARANAENSE - CRESOL NORTE PARANAENSE',
    short_name: 'COOP CRESOL NORTE PARANAENSE',
  },
  '03000142': {
    bank_ispb: '03000142',
    name: 'COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DOS POLICIAIS FEDERAIS DO RIO GRANDE DO SUL E SANTA CATARINA - SICREDI POL RS/SC',
    short_name: 'COOP POL FED - SICREDI POL',
  },
  '04152107': {
    bank_ispb: '04152107',
    name: 'COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS SERVIDORES DA POLÍCIA MILITAR DO ESTADO DE SÃO PAULO DA REGIÃO CENTRO-OESTE PAULISTA - CREDMIL',
    short_name: 'CECM SERV POL MIL SP REGIÃO CE',
  },
  '02338666': {
    bank_ispb: '02338666',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO CREDIEMBRAPA LTDA - SICOOB.',
    short_name: 'CCLA CREDIEMBRAPA',
  },
  '03212823': {
    bank_ispb: '03212823',
    name: 'COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DOS MILITARES ESTADUAIS DO RIO GRANDE DO SUL - SICREDI MIL RS',
    short_name: 'COOP SICREDI MIL',
  },
  '00968602': {
    bank_ispb: '00968602',
    name: 'COOPERATIVA DE CREDITO DE LIVRE ADMISSÃO DO VALE DO SÃO PATRICIO LTDA.',
    short_name: 'CCLA VALE SÃO PATRICIO LTDA',
  },
  '04694278': {
    bank_ispb: '04694278',
    name: 'COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS INTEGRANTES DO MINISTÉRIO PÚBLICO E DA DEFENSORIA PÚBLICA NO ESTADO DO RIO DE JANEIRO LIMITADA. - SICOOB COMPERJ',
    short_name: 'CECM INTEG. MF DP ESTADO RJ',
  },
  '03419786': {
    bank_ispb: '03419786',
    name: 'COOPERATIVA DE CRÉDITO NOSSA SENHORA DO DESTERRO - SICOOB CREDISC',
    short_name: 'CC NOSSA SENHORA DO DESTERRO',
  },
  '05597773': {
    bank_ispb: '05597773',
    name: 'CREDISIS CREDIBRÁS COOPERATIVA DE CRÉDITO E INVESTIMENTO DE LIVRE ADMISSÃO DO OESTE DE RONDÔNIA',
    short_name: 'CC INVESTIMENTO LIVRE ADMISSÃO DO OESTE DE RONDÔNIA',
  },
  '03645752': {
    bank_ispb: '03645752',
    name: 'COOPERATIVA DE CRÉDITO CREDISG LTDA. - SICOOB CREDISG',
    short_name: 'CC CREDISG',
  },
  '07946216': {
    bank_ispb: '07946216',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO SICOOB UNIÃO SUDESTE',
    short_name: 'CCLA SICOOB UNIÃO SUDESTE',
  },
  '02934201': {
    bank_ispb: '02934201',
    name: 'COOPERATIVA DE CRÉDITO E INVESTIMENTO COM INTERAÇÃO SOLIDÁRIA LIDERANÇA - CRESOL LIDERANÇA',
    short_name: 'COOP CRESOL LIDERANCA',
  },
  '05220243': {
    bank_ispb: '05220243',
    name: 'COOPERATIVA DE CREDITO RURAL COM INTERACAO SOLIDARIA ORIGENS -CRESOL ORIGENS',
    short_name: 'COOP CRESOL ORIGENS',
  },
  '05351887': {
    bank_ispb: '05351887',
    name: 'ZEMA CRÉDITO, FINANCIAMENTO E INVESTIMENTO S/A',
    short_name: 'ZEMA CFI S/A',
  },
  '04013172': {
    bank_ispb: '04013172',
    name: 'COOPERATIVA DE CRÉDITO SICOOB CRED-ACIF',
    short_name: 'CC SICOOB CRED-ACIF',
  },
  '01155801': {
    bank_ispb: '01155801',
    name: 'COOPERATIVA DE CRÉDITO RURAL COM INTERAÇÃO SOLIDÁRIA CENTRO SERRA - CRESOL CENTRO SERRA',
    short_name: 'COOP CRESOL CENTRO SERRA',
  },
  '03603683': {
    bank_ispb: '03603683',
    name: 'COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS SERVIDORES DA SECRETARIA DE ESTADO DE FAZENDA DO DISTRITO FEDERAL - SICOOB CREDSEF',
    short_name: 'CECM SERV SEC EST FAZENDA DF',
  },
  '02093154': {
    bank_ispb: '02093154',
    name: 'COOPERATIVA DE CRÉDITO MÚTUO DOS EMPREGADOS DO MAGAZINE LUIZA, EMPRESAS CONTROLADAS E COLIGADAS - COOPLUIZA',
    short_name: 'CCM EMPR MAGAZINE LUIZA',
  },
  '04321309': {
    bank_ispb: '04321309',
    name: 'COOPERATIVA DE CRÉDITO DOS SERVIDORES PÚBLICOS NO ESTADO DA BAHIA LTDA. - SICOOB CRED EXECUTIVO',
    short_name: 'CC SICOOB CRED EXECUTIVO',
  },
  '03130170': {
    bank_ispb: '03130170',
    name: 'BRASIL CARD INSTITUIÇÃO DE PAGAMENTOS LTDA',
    short_name: 'BRASIL CARD IP LTDA',
  },
  '02493000': {
    bank_ispb: '02493000',
    name: 'COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO NOS ESTADOS DE ALAGOAS, SERGIPE E BAHIA - SICOOB LESTE',
    short_name: 'SICOOB LESTE',
  },
  '02282709': {
    bank_ispb: '02282709',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO CENTRO NORTE BRASILEIRO',
    short_name: 'COOP CENTRO NORTE BRASILEIRO',
  },
  '01699652': {
    bank_ispb: '01699652',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DA REGIÃO DE GUAXUPÉ LTDA. - SICOOB ACICREDI',
    short_name: 'CCLA REGIÃO GUAXUPÉ',
  },
  '05442029': {
    bank_ispb: '05442029',
    name: 'CASA DO CRÉDITO S.A. SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR',
    short_name: 'CASA CREDITO S.A. SCM',
  },
  '01039011': {
    bank_ispb: '01039011',
    name: 'COOPERATIVA DE CRÉDITO UNICRED DESBRAVADORA LTDA - UNICRED DESBRAVADORA',
    short_name: 'CC UNICRED DESBRAVADORA LTDA - UNICRED DESBRAVADORA',
  },
  '09516419': {
    bank_ispb: '09516419',
    name: 'PICPAY BANK - BANCO MÚLTIPLO S.A',
    short_name: 'PICPAY BANK - BANCO MÚLTIPLO S.A',
  },
  '00694877': {
    bank_ispb: '00694877',
    name: 'COOPERATIVA DE CRéDITO DE LIVRE ADMISSãO DO BRASIL CENTRAL LTDA. - SICOOB EXECUTIVO',
    short_name: 'COOP SICOOB EXECUTIVO LTDA.',
  },
  '04237413': {
    bank_ispb: '04237413',
    name: 'COOPERATIVA DE CRÉDITO DO VALE DO SÃO FRANCISCO - SICREDI VALE DO SÃO FRANCISCO',
    short_name: 'COOP SICREDI VALE SÃO FRANCISCO',
  },
  '07679404': {
    bank_ispb: '07679404',
    name: 'BANCO TOPÁZIO S.A.',
    short_name: 'BANCO TOPÁZIO S.A.',
  },
  '03428338': {
    bank_ispb: '03428338',
    name: 'SICREDI CREDUNI - COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS SERV DAS INST PÚBLICAS DE ENSINO SUPERIOR DO ESTADO DA PARAÍBA E DAS DEMAIS INSTITUIÇÕES E ÓRGÃOS PÚBLICOS NO ESTADO DA PARAIBA LTDA.',
    short_name: 'COOP SICREDI CREDUNI',
  },
  '04138455': {
    bank_ispb: '04138455',
    name: 'COOPERATIVA DE CRÉDITO DO RIO GRANDE DO NORTE - SICOOB RIO GRANDE DO NORTE',
    short_name: 'CC DO RIO GRANDE DO NORTE - SICOOB RIO GRANDE DO NORTE',
  },
  '02057584': {
    bank_ispb: '02057584',
    name: 'COOPERATIVA DE CRÉDITO SICOOB SERTÃO LTDA',
    short_name: 'CC SICOOB SERTÃO',
  },
  '01604998': {
    bank_ispb: '01604998',
    name: 'COOPERATIVA DE CREDITO DE LIVRE ADMISSÃO DO SUL DE MINAS LTDA - SICOOB CREDIVASS',
    short_name: 'CCLA SUL MINAS-SICOOB CREDIVAS',
  },
  '03881423': {
    bank_ispb: '03881423',
    name: 'SOCINAL S.A. - CRÉDITO, FINANCIAMENTO E INVESTIMENTO',
    short_name: 'SOCINAL S.A. CFI',
  },
  '05969937': {
    bank_ispb: '05969937',
    name: 'COOPERATIVA DE CRÉDITO MÚTUO DOS SERVIDORES MUNICIPAIS DE BEBEDOURO - COOCRESB.',
    short_name: 'CCMS MUNICIPAIS DE BEBEDOURO',
  },
  '05276770': {
    bank_ispb: '05276770',
    name: 'COOPERATIVA DE CRÉDITO E INVESTIMENTO COM INTERAÇÃO SOLIDÁRIA FRONTEIRAS PR/SC/SP/ES - CRESOL FRONTEIRAS PR/SC/SP/ES',
    short_name: 'COOP CRESOL FRONTEIRAS PR/SC/SP/ES',
  },
  '08850613': {
    bank_ispb: '08850613',
    name: 'COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS EMPREGADOS DA CENTRAIS ELÉTRICAS DE SANTA CATARINA - CREDELESC',
    short_name: 'CECM EMPR CENT ELÉTRICAS/SC',
  },
  '03793242': {
    bank_ispb: '03793242',
    name: 'COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DO SUL DO ESTADO DE SANTA CATARINA - SICREDI SUL SC',
    short_name: 'COOP SICREDI SUL SC',
  },
  '01374196': {
    bank_ispb: '01374196',
    name: 'COOPERATIVA DE CRÉDITO DE SÃO FRANCISCO DE SALES LTDA. - SICOOB CREDISALES',
    short_name: 'CC SÃO FRANCISCO DE SALES LTDA.',
  },
  '03412878': {
    bank_ispb: '03412878',
    name: 'COOPERATIVA DE CRÉDITO CREDIRAMA LTDA - SICOOB CREDIRAMA',
    short_name: 'CC CREDIRAMA',
  },
  '01009908': {
    bank_ispb: '01009908',
    name: 'COOPERATIVA DE CREDITO DE LIVRE ADMISSAO DE PARA DE MINAS LTDA - SICOOB ASCICRED',
    short_name: 'CCLA PARA DE MINAS',
  },
  '00416968': {
    bank_ispb: '00416968',
    name: 'BANCO INTER S.A.',
    short_name: 'BANCO INTER',
  },
  '04484490': {
    bank_ispb: '04484490',
    name: 'COOPERATIVA DE CRÉDITO POUPANÇA E INVESTIMENTO DA ALTA NOROESTE DE SÃO PAULO - SICREDI ALTA NOROESTE SP',
    short_name: 'COOP SICREDI ALTA NOROESTE SP',
  },
  '02405189': {
    bank_ispb: '02405189',
    name: 'COOPERATIVA DE CRÉDITO DA GRANDE FLORIANÓPOLIS - ÚNILOS',
    short_name: 'COOP ÚNILOS',
  },
  '07026923': {
    bank_ispb: '07026923',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO SICOOB ARENITO',
    short_name: 'CCLA SICOOB ARENITO',
  },
  '05983995': {
    bank_ispb: '05983995',
    name: 'COOPERATIVA DE CRÉDITO RURAL COM INTERAÇÃO SOLIDÁRIA COOPERAR - CRESOL COOPERAR',
    short_name: 'COOP CRESOL COOPERAR',
  },
  '09033698': {
    bank_ispb: '09033698',
    name: 'COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE RIO VERDE E REGIÃO LTDA.',
    short_name: 'CCLA DE RIO VERDE E REGIÃO',
  },
  '09512539': {
    bank_ispb: '09512539',
    name: 'COOPERATIVA DE CRÉDITO DA FOZ DO RIO ITAJAÍ AÇU - CREDIFOZ',
    short_name: 'CC DA FOZ DO RIO ITAJAÍ AÇU',
  },
  '02641032': {
    bank_ispb: '02641032',
    name: 'COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO UNICRED PONTO CAPITAL - UNICRED PONTO CAPITAL.',
    short_name: 'UNICRED PONTO CAPITAL',
  },
  '04866275': {
    bank_ispb: '04866275',
    name: 'BANCO INBURSA S.A.',
    short_name: 'BANCO INBURSA',
  },
  '02674113': {
    bank_ispb: '02674113',
    name: 'COOPERATIVA DE CRÉDITO DE SERVIDORES PÚBLICOS COOPERPLAN LTDA. - SICOOB COOPERPLAN',
    short_name: 'CC SERV PUBL COOPERPLAN',
  },
  '03612679': {
    bank_ispb: '03612679',
    name: 'COOPERATIVA DE CRÉDITO MÚTUO DOS SERVIDORES PÚBLICOS MUNICIPAIS DE SÃO BERNARDO DO CAMPO - CREDIABC',
    short_name: 'CCM SERV PUBL MUN SÃO BERNARDO',
  },
  '00068987': {
    bank_ispb: '00068987',
    name: 'COOPERATIVA DE CRÉDITO SICOOB ARACREDI LTDA - SICOOB ARACREDI',
    short_name: 'CC SICOOB ARACREDI',
  },
  '03047549': {
    bank_ispb: '03047549',
    name: 'COOPERATIVA DE CRÉDITO E CAPTAÇÃO SICOOB UNICIDADES',
    short_name: 'CC CAP UNICIDADES',
  },
  '05410056': {
    bank_ispb: '05410056',
    name: 'COOPERATIVA DE CRÉDITO E ECONOMIA COM INTERAÇÃO SOLIDÁRIA - CRESOL JAGUARUNA',
    short_name: 'COOP CRESOL JAGUARUNA',
  },
  '08561701': {
    bank_ispb: '08561701',
    name: 'PAGSEGURO INTERNET INSTITUIÇÃO DE PAGAMENTO S.A.',
    short_name: 'PAGSEGURO INTERNET IP S.A.',
  },
  '02910987': {
    bank_ispb: '02910987',
    name: 'COOPERATIVA DE CRÉDITO RURAL COM INTERAÇÃO SOLIDÁRIA CENTRO-SUL RS/MS - CRESOL CENTRO-SUL RS/MS',
    short_name: 'COOP CRESOL CENTRO SUL RS/MS',
  },
  '05888589': {
    bank_ispb: '05888589',
    name: 'COOPERATIVA DE CRÉDITO SUL - SICOOB SUL',
    short_name: 'COOP SUL',
  },
  '04525997': {
    bank_ispb: '04525997',
    name: 'COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DOS PROFESSORES, FUNCIONÁRIOS E ALUNOS DA UNIVERSIDADE DE CAXIAS DO SUL - SICREDI COOPERUCS',
    short_name: 'COOP SICREDI COOPERUCS',
  },
  '08744817': {
    bank_ispb: '08744817',
    name: 'DOCK SOLUCOES EM MEIOS DE PAGAMENTO S A',
    short_name: 'DOCK SOLUCOES',
  },
  '02382755': {
    bank_ispb: '02382755',
    name: 'COOPERATIVA DE CREDITO POTIGUAR - SICOOB POTIGUAR',
    short_name: 'SICOOB POTIGUAR',
  },
  '03065046': {
    bank_ispb: '03065046',
    name: 'COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DO NOROESTE DO ESTADO DE SÃO PAULO - SICREDI NOROESTE SP',
    short_name: 'COOP SICREDI NOROESTE SP',
  },
  '05582619': {
    bank_ispb: '05582619',
    name: 'COOPERATIVA DE CRÉDITO SICOOB OURO VERDE',
    short_name: 'CC SICOOB OURO VERDE',
  },
};
