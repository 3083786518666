import React, { useMemo } from 'react';
import { Row } from 'reactstrap';
import { Colxx } from '../common/CustomBootstrap';
import moment from 'moment';
import { useIntl } from 'react-intl';

import AirportName from './AirportName';
import CityName from './CityName';
import { registeredAirlines } from '../../constants/travelValues';

export default function OpenBookingJourneySmall({ journey, params, segments }) {
  const { messages } = useIntl();

  const { logo, departureDate, departureTime, arrivalDate } = useMemo(() => {
    const departureDateTime = moment(
        segments[0].departureDateTime,
        'DD/MM/YYYY HH:mm'
      ),
      arrivalDateTime = moment(segments[0].arrivalDateTime, 'DD/MM/YYYY HH:mm');

    return {
      logo: registeredAirlines[segments[0].airline]?.logo,
      departureDate: departureDateTime.format('DD/MM'),
      departureTime: departureDateTime.format('HH:mm'),
      arrivalDate: arrivalDateTime.format('DD/MM'),
      arrivalTime: arrivalDateTime.format('HH:mm'),
    };
  }, [segments]);

  // Render
  return (
    <div className="journey pb-3 mb-3 mt-1">
      <div>
        <div className="d-flex justify-content-between">
          <div className="d-flex justify-content-start">
            <div className="journey-title d-flex align-items-center">
              <img
                src="/assets/img/icons/search_icon.svg"
                alt={messages['alts.components.flight.journey.journey']}
              />
              <h3>
                <CityName station={journey.departureStation} params={params} />{' '}
                • <CityName station={journey.arrivalStation} params={params} />
              </h3>
            </div>
          </div>
        </div>
        <Row className="px-3 mt-3 origin">
          <Colxx
            xxs="12"
            className="d-flex align-items-center justify-content-between"
          >
            <div className="d-flex align-items-center">
              {logo ? (
                <div
                  className="carrier"
                  style={{
                    backgroundImage: `url(${logo})`,
                  }}
                />
              ) : null}
            </div>
          </Colxx>
        </Row>
        <Row className="pl-3 pr-3 mt-3">
          <Colxx xxs="4" className="origin">
            <p className="text-small">{departureDate}</p>
            <p>{departureTime}</p>
            <p className="highlight">
              <AirportName
                station={segments[0].departureStation}
                params={params}
              />
            </p>
          </Colxx>
          <Colxx xxs="4" className="duration text-center">
            <img
              src="/assets/img/icons/search_icon.svg"
              alt={messages['alts.components.flight.journey.duration']}
            />
          </Colxx>
          <Colxx xxs="4" className="destination">
            <p className="text-small">{arrivalDate}</p>
            <p>--:--</p>
            <p className="highlight">
              <AirportName
                station={segments[0].arrivalStation}
                params={params}
              />
            </p>
          </Colxx>
        </Row>
      </div>
    </div>
  );
}
