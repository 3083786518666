import React, { useState, useRef } from 'react';
import { Popover, PopoverBody } from 'reactstrap';

export default function BudgetCategoryDetails({ id, categories }) {
  const [showInfo, setShowInfo] = useState(false);
  const ref = useRef(null);

  return (
    <>
      <div
        className="budget-category-detail"
        onMouseOver={() => {
          setShowInfo(true);
        }}
        onMouseOut={() => setShowInfo(false)}
        ref={ref}
      >
        <div className="glyph-icon simple-icon-question" />
      </div>
      <Popover
        placement="top"
        isOpen={showInfo}
        target={ref.current}
        popperClassName="ticket-info"
      >
        <PopoverBody className="journey-ticket-detail">
          <ul className="m-0 p-0 pl-3">
            {categories.map((category) => (
              <li key={category.value} className="m-0">
                {category.label}
              </li>
            ))}
          </ul>
        </PopoverBody>
      </Popover>
    </>
  );
}
