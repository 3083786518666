import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { FlightFilterQueries } from 'src/portao3-legacy/constants/search/flight';
import { getAuthRedux } from 'src/portao3-legacy/utils/redux';
import SearchResultWrapper from './SearchResultWrapper';
import LoadingFlight from 'src/portao3-legacy/components/search/LoadingFlight';
import OpenBookingAction from 'src/portao3-legacy/components/OpenBookingAction';
import { getOrganizationConfig } from 'src/portao3-legacy/utils/organization';
import useShowToggle from 'src/portao3-legacy/hooks/useShowToggle';
import FlightItem from './FlightItem';
import { trackPropertiesSegment } from 'src/portao3-legacy/utils/segment';
import FlightModal from './FlightModal';
import OpenBookingCreationModal from 'src/portao3-legacy/components/OpenBookingCreationModal/OpenBookingCreationModal';

import { OrderTypes } from 'src/portao3-legacy/constants/orderTypes';
import searchStatus from 'src/portao3-legacy/constants/searchStatus';
import loadSearchStatus from 'src/portao3-legacy/constants/loadSearchStatus';

import { TFlightSearchItem } from 'src/portao3-legacy/types/orders/flight/flightOrder';
import {
  TFlightParams,
  TFlightSearchParams,
} from 'src/portao3-legacy/types/orders/flight/flightParams';
import { ITravelResultComponentProps } from 'src/portao3-legacy/types/search';
import { TObject } from 'src/portao3-legacy/types/others';
import { TRange } from 'src/portao3-legacy/hooks/useParams/types';
import { TFlightReservationParams } from 'src/portao3-legacy/hooks/useReserveTravelItem/types';
import { filterTrueValues } from 'src/portao3-legacy/utils/filters';

function formatParams(data: TFlightParams) {
  return {
    ...data,
    arrivalConnections: filterTrueValues(data.arrivalConnections, null as any),
    departureConnections: filterTrueValues(
      data.departureConnections,
      null as any
    ),
    stops: filterTrueValues(data.stops, null as any),
  };
}

export default function FlightResults({
  searchParams,
  params,
  status,
  isLoading,
  search,
  items,
  getRemainingSearch,
  reserveItem,
}: ITravelResultComponentProps<
  TFlightSearchItem,
  TFlightSearchParams,
  TFlightParams,
  TFlightReservationParams
>) {
  const { user, organization } = useSelector(getAuthRedux);

  // States
  const isOpenBookingAllowed = useMemo(
    () => getOrganizationConfig(organization.config, 'openBooking.allowed'),
    [organization]
  );
  const [showOpenBookingModal, _, toggleShowOpenBookingModal] = useShowToggle();
  const [selectedItem, setSelectedItem] = useState<TObject<any> | null>(null);

  // Params
  const departureConnections = useMemo(
    () => filterTrueValues(params.departureConnections),
    [params?.departureConnections]
  );
  const arrivalConnections = useMemo(
    () => filterTrueValues(params.arrivalConnections),
    [params?.arrivalConnections]
  );
  const stops = useMemo(() => filterTrueValues(params.stops), [params?.stops]);

  // Functions
  const onSelectedDetails = useCallback(
    (selectedFlight: any, selectedSegments: any) => {
      trackPropertiesSegment('User selected flight item', {
        selectedFlight,
        selectedSegments,
        user,
      });

      setSelectedItem({ selectedFlight, selectedSegments });
    },
    [user]
  );

  // Render
  return (
    <>
      <OpenBookingAction
        show={
          search?.status === searchStatus.DONE &&
          status !== loadSearchStatus.EMPTY
        }
        allowed={isOpenBookingAllowed}
        onCreateReservation={toggleShowOpenBookingModal}
        className="mb-4"
      />

      <SearchResultWrapper
        LoadingComponent={LoadingFlight}
        filterQueries={FlightFilterQueries as any}
        items={items}
        params={params}
        searchParams={searchParams}
        isLoading={isLoading}
        status={status}
        getRemainingSearch={getRemainingSearch}
        orderType={OrderTypes.FLIGHT}
        formatParams={formatParams}
      >
        {({ paginatedItems }) =>
          paginatedItems.map((item, index) => (
            <FlightItem
              key={`result-${index}`}
              index={index}
              isTwoWay={search.twoWay}
              item={item}
              params={searchParams}
              showDetails={onSelectedDetails}
              stops={stops}
              departureDuration={
                (params.departureDuration as TRange).max
                  ? params.departureDuration
                  : null
              }
              arrivalDuration={
                (params.arrivalDuration as TRange).max
                  ? params.arrivalDuration
                  : null
              }
              departureConnections={departureConnections}
              arrivalConnections={arrivalConnections}
              departureTime={params.departureTime}
            />
          ))
        }
      </SearchResultWrapper>

      {selectedItem ? (
        <FlightModal
          toggleModal={() => setSelectedItem(null)}
          item={selectedItem.selectedFlight}
          segments={selectedItem.selectedSegments}
          params={searchParams}
          reserveFlight={reserveItem}
          selectMode={null}
          reservationBtnMessage=""
        />
      ) : null}

      <OpenBookingCreationModal
        show={showOpenBookingModal}
        toggleModal={toggleShowOpenBookingModal}
        search={search}
        params={searchParams}
        travelType={OrderTypes.FLIGHT}
      />
    </>
  );
}
