import React from 'react';
import { useSelector } from 'react-redux';
import recordStatus from '../constants/recordStatus';
import useDataSnapshot from '../hooks/useDataSnapshot';
import firebaseApp from '../services/Firebase';

export default function FavoritePlacesHOC({ children }) {
  const organizationId = useSelector(({ auth }) => auth.user.organizationId);
  const [favoritePlaces] = useDataSnapshot({
    fetchFunction: () =>
      firebaseApp
        .getFavoritePlacesFromOrganization(organizationId)
        .where('status', '==', recordStatus.ACTIVE),
  });

  return React.cloneElement(children, {
    favoritePlaces,
    showFavoritePlaces: true,
  });
}
