import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import User from '../../services/User';
import TopnavApprovals from '../../components/navs/Approvals';
import UserDropdown from '../../components/navs/UserDropdown';
import { useIntl } from 'react-intl';
import { hasExpenses } from '../../utils/organization';
import { isUserAllowed } from '../../utils/permissions';
import { PermissionTypes } from '../../constants/permissions';

export default function TopNav() {
  const { user, organization } = useSelector((state) => state.auth);
  const { messages } = useIntl();

  // States
  const { isAdmin, canAccessDataTab } = useMemo(
    () => ({
      isAdmin: User.isAdmin(user),
      canAccessDataTab: isUserAllowed(user, [PermissionTypes.DATA_ACCESS]),
    }),
    [user]
  );

  const showExpenseRoute = useMemo(
    () => hasExpenses(organization),
    [organization]
  );

  if (!user) {
    return <nav className="navbar" />;
  }

  // Render
  return (
    <nav className="navbar">
      <div className="d-flex justify-content-center navbar-center">
        {showExpenseRoute ? (
          <NavLink to="/payments" className="navbar-item payments-nav">
            {messages['containers.navs.top-nav.refunds']}
          </NavLink>
        ) : null}
        <NavLink to="/travel" className="navbar-item travel-nav">
          {messages['containers.navs.top-nav.my-travels']}
        </NavLink>
        {canAccessDataTab ? (
          <NavLink
            data-testid="data-tab-link"
            to="/data"
            className="navbar-item data-nav"
          >
            {messages['containers.navs.top-nav.data']}
          </NavLink>
        ) : null}
      </div>

      <div className="navbar-right">
        {isAdmin && (
          <div className="header-icons d-inline-block align-middle">
            <NavLink to="/app" className="navbar-item">
              <div className="gear" />
            </NavLink>
          </div>
        )}
        <div className="header-icons d-inline-block align-middle">
          <TopnavApprovals />
        </div>
        <div className="user d-inline-block">
          <UserDropdown />
        </div>
      </div>
    </nav>
  );
}
