import classNames from 'classnames';
import throttle from 'lodash/throttle';
import React, { useCallback, useRef } from 'react';
import { useIntl } from 'react-intl';

export default function CopiableTextWrapper({
  children,
  text,
  copiedTextClassName,
}) {
  const { messages } = useIntl();

  // Refs
  const messageRef = useRef(null);

  // Functions
  const startAnimation = useCallback(
    throttle(() => {
      messageRef.current.classList.remove('message-show-up');
      messageRef.current.offsetWidth;
      messageRef.current.classList.add('message-show-up');
    }, 1000),
    []
  );

  const onCopy = () => {
    navigator.clipboard.writeText(text);

    startAnimation();
  };

  // Render
  return (
    <div className="copiable-text" onClick={onCopy}>
      {children}

      <div className="copied-message px-3 py-1" ref={messageRef}>
        <p
          className={classNames(
            'm-0 p-0 text-primary fs-0-7rem',
            copiedTextClassName
          )}
        >
          {messages['general.copied']}
        </p>
      </div>
    </div>
  );
}
