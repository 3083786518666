import find from 'lodash/find';
import maxBy from 'lodash/maxBy';
import moment from 'moment';
import { TBusReservationParams } from '../types';
import { TBusSearchReservation } from 'src/portao3-legacy/types/orders/busOrder';

function makeOrderDetails({
  reservationParams,
  item,
  selectedSegments,
  search,
}: TBusReservationParams) {
  return {
    ...reservationParams,
    journeys: item.journeys.map((journey, index) => {
      const segment = selectedSegments[index];

      return {
        id: index,
        departureStation: journey.departureStation,
        arrivalStation: journey.arrivalStation,
        departureDate: journey.departureDate,
        sellKey: segment.sellKey,
      };
    }),
    provider: {
      origin: item.origin,
      schedules: selectedSegments.map(({ sellKey }) => sellKey),
    },
    fare: item.fare,
    searchCode: search.searchCode,
  };
}

function makeReservations({ item, selectedSegments }: TBusReservationParams) {
  const reservations: TBusSearchReservation[] = [];

  item.journeys.forEach((_, index) => {
    const segment = selectedSegments[index];

    segment.legs.forEach((leg, legIndex) => {
      reservations.push({
        bookingCode: '',
        carrier: leg.carrier,
        departureDateTime: moment(leg.departureDateTime).format(
          'DD/MM/YYYY HH:mm'
        ),
        departureStation: leg.departureStation,
        departureStationId: leg.departureStationId,
        arrivalDateTime: moment(leg.arrivalDateTime).format('DD/MM/YYYY HH:mm'),
        arrivalStation: leg.arrivalStation,
        arrivalStationId: leg.arrivalStationId,
        fare: leg.fare,
        journeyId: index,
        legIndex,
      });
    });
  });

  return reservations;
}

export async function makeBusParams(params: TBusReservationParams) {
  const orderDetails = makeOrderDetails(params);

  const reservations = makeReservations(params);

  const latestReservation = maxBy(reservations, (r) => {
    return moment(r.arrivalDateTime, 'DD/MM/YYYY HH:mm').unix();
  }) as TBusSearchReservation;

  return {
    order: orderDetails,
    reservations,
    filterTimestamp: moment(
      latestReservation.arrivalDateTime,
      'DD/MM/YYYY HH:mm'
    )
      .endOf('day')
      .utc()
      .unix(),
  };
}
