import moment from 'moment';
import React, { useState } from 'react';
import searchStatus from '../../constants/searchStatus';
import { DEFAULT_CURRENCY } from '../../utils/money';
import ItineraryForm from './ItineraryForm';
import { getItinerarySearchParams } from '../../utils/itinerary';
import { OrderTypes } from '../../constants/orderTypes';
import appFunctions from '../../services/Functions';
import ItineraryResults from './ItineraryResults';
import { getUserNationality } from '../../utils/nationality';
import { useSelector } from 'react-redux';

export default function Itinerary({ currency = DEFAULT_CURRENCY }) {
  const { user, organization } = useSelector(({ auth }) => auth);

  // States
  const [search, setSearch] = useState({
    currency,
    origin: {},
    destination: {},
    outboundDate: null,
    inboundDate: null,
    status: searchStatus.NEW,
    params: {
      // Search radius (km)
      radius: 30,
      // Search mobility
      mobility: false,
      // Travel types for search (flight, bus, all), can be a string or array of types
      travelTypes: OrderTypes.FLIGHT,
      // Use round trip instead of search for outbound and inbound
      roundTrip: true,
      hotelParams: {
        adults: 1,
        rooms: 1,
        nationality: getUserNationality({
          user,
          organization,
        }),
      },
      flightParams: {
        baggages: 0,
        cabin: 'Economy',
      },
    },
  });

  // Functions
  const doSetSearch = (obj) => {
    setSearch(({ searchCodes, ...search }) => ({
      ...search,
      status: searchStatus.NEW,
      ...obj,
    }));
  };

  const setValue = (name) => (value) => doSetSearch({ [name]: value });

  const setParamValue = ({ paramsName, name, value }) => {
    try {
      const params = { ...search.params };

      if (paramsName && paramsName in params) {
        params[paramsName] = { ...params[paramsName], [name]: value };
      } else params[name] = value;

      doSetSearch({ params });
    } catch (err) {
      console.error('Unable to set param value', err);
    }
  };

  const startSearch = async () => {
    setSearch((search) => ({
      ...search,
      status: searchStatus.LOADING,
      searchCodes: null,
    }));

    const { payload } = getItinerarySearchParams(search);

    return null;

    const searchCodes = await appFunctions.getItinerary(payload);

    setSearch((search) => ({
      ...search,
      status: searchStatus.DONE,
      searchCodes,
    }));
  };

  const setOutboundDate = (outboundDate) => {
    let inboundDate = search.inboundDate;

    if (moment(outboundDate).isAfter(inboundDate)) inboundDate = null;

    doSetSearch({
      outboundDate: outboundDate ? moment(outboundDate) : '',
      inboundDate,
    });
  };

  const setInboundDate = (inboundDate) => {
    doSetSearch({
      inboundDate: inboundDate ? moment(inboundDate) : '',
    });
  };

  // Render
  return (
    <>
      <ItineraryForm
        search={search}
        setParamValue={setParamValue}
        startSearch={startSearch}
        setOriginLocation={setValue('origin')}
        setDestinationLocation={setValue('destination')}
        setOutboundDate={setOutboundDate}
        setInboundDate={setInboundDate}
      />

      <ItineraryResults search={search} />
    </>
  );
}
