import debounce from 'lodash/debounce';
import moment from 'moment';

export const identifySegment = async ({ user, organization }) => {
  console.debug('User information set on Segment');
  window.analytics.identify(user.uid, {
    email: user.email,
    username: user.email,
    lastName: user.lastName,
    firstName: user.firstName,
    birthday: moment(user.birthdate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
    age: moment().diff(moment(user.birthdate, 'DD/MM/YYYY'), 'years'),
    title: user.role,
    phone: user.mobilePhoneNumber,
    avatar: user.avatar,
    pushToken: user.pushToken,
    status: user.status,
    isAdmin: user.isAdmin,
    company: {
      name: organization.name,
      id: organization.id,
      employee_count: organization.userCount || 0,
    },
    createdAt: moment.unix(user.createdAt).format(),
  });

  window.analytics.group({
    userId: user.uid,
    groupId: organization.id,
    traits: {
      name: organization.name,
      cnpj: organization.cnpj,
      address: `${organization.address.street}. ${organization.address.district}. ${organization.address.city} - ${organization.address.state}. ${organization.address.zipCode}`,
    },
  });

  return true;
};

export const clearSegment = async () => {
  console.debug('User information cleared on Segment');
  window.analytics.reset();

  return true;
};

export const trackSegment = async (eventName) => {
  console.debug(`Event information ${eventName} sent to Segment`);
  window.analytics.track(eventName);

  return true;
};

export const trackPropertiesSegment = async (eventName, properties) => {
  console.debug(`Event information ${eventName} sent to Segment`);
  window.analytics.track(eventName, properties);

  return true;
};

export const seenSegment = async (screenName) => {
  console.debug(`Page information ${screenName} sent to Segment`);
  window.analytics.page(screenName);

  return true;
};

// Triggers
export const triggerFilterChangeSegment = (
  filterName,
  newValue,
  filterObject
) => {
  trackPropertiesSegment(`Filter changed (${filterName})`, {
    newValue,
    filter: filterObject,
  });
};

export const triggerFilterChangeSegmentDebounce = debounce(
  (filterName, newValue = {}, filterObject = {}) =>
    triggerFilterChangeSegment(filterName, newValue, filterObject),
  1000
);
