import React, { useEffect, useMemo, useRef } from 'react';
import { Row, Label, CustomInput } from 'reactstrap';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { Colxx } from '../common/CustomBootstrap';
import DateSearch from '../search/DateSearch';
import NumberFormat from 'react-number-format';
import CommonParams from '../../containers/search/CommonParams';
import ReadOnlyBudgetParams from './ReadOnlyBudgetParams';
import FixedSelect from '../common/FixedSelect';
import { useSelector } from 'react-redux';
import { replaceVariables } from '../../constants/variableName';
import { getOrganizationConfig } from '../../utils/organization';
import AnimateHeight from 'react-animate-height';
import useShowToggle from '../../hooks/useShowToggle';
import BudgetNameDocumentationModal from '../expenses/BudgetNameDocumentationModal/BudgetNameDocumentationModal';
import { RefundTypes } from '../../constants/refundTypes';

export default function BudgetParams({
  budget = {},
  onBudgetChange = () => {},
  editable = false,
  hasBudget,
  budgetCategories = [],
  setBudget,
  administrative,
  maxDate,
  cardType,
}) {
  const { organization } = useSelector(({ auth }) => auth);
  const { messages } = useIntl();

  // Refs
  const endDateRef = useRef(null);
  const nameRef = useRef(null);

  // States
  const [
    showNameDocumentation,
    setShowNameDocumentation,
    toggleNameDocumentation,
  ] = useShowToggle();

  const { requiredFields = {}, editableFields = {} } = useMemo(
    () => ({
      requiredFields: getOrganizationConfig(organization.config, 'required'),
      editableFields: getOrganizationConfig(organization.config, 'editable'),
    }),
    [organization]
  );

  const [parsedName, showParsed] = useMemo(() => {
    if (!budget.name || !editable) return ['', false];

    const parsedName = replaceVariables(budget.name);

    return [parsedName, parsedName !== budget.name];
  }, [budget.name, editable]);

  const endMinDate = useMemo(() => {
    if (editable) {
      const today = moment();

      if (budget.startDate) {
        if (cardType === RefundTypes.PERSONAL_ACCOUNT) return budget.startDate;

        return budget.startDate?.isBefore(today, 'day')
          ? today
          : budget.startDate;
      } else {
        return moment.unix(0);
      }
    }

    return null;
  }, [budget.startDate]);

  // Effects
  useEffect(() => {
    if (budget.startDate && !budget.endDate && endDateRef.current) {
      endDateRef.current.setFocus();
    }
  }, [budget.startDate]);

  // Functions
  const onChange = (name, value) => {
    onBudgetChange({
      [name]: value,
    });
  };

  const onDateChange = (name, value) => {
    onChange(name, value ? moment(value) : '');
  };

  const handleNameClick = (e) => {
    e.stopPropagation();
    e.preventDefault();

    if (setShowNameDocumentation) setShowNameDocumentation(true);
  };

  // Render
  const renderPeriodSelection = () => {
    if (administrative)
      return (
        <DateSearch
          selectedDate={budget.startDate}
          setValue={(value) => onDateChange('startDate', value)}
          isStart
          defaultStartDate={budget.startDate}
          defaultMaxDate={maxDate}
          defaultMinDate={null}
          className="refund-date form-purple-value"
          placeholder="DD/MM/YY"
          name="budget-start-date"
        />
      );

    return (
      <>
        <DateSearch
          selectedDate={budget.startDate}
          setValue={(value) => onDateChange('startDate', value)}
          isStart
          defaultStartDate={budget.startDate}
          defaultMinDate={
            budget.budget && budget.withExpenseCard ? undefined : moment.unix(0)
          }
          defaultEndDate={budget.endDate}
          className="refund-date form-purple-value"
          placeholder="DD/MM/YY"
          name="budget-start-date"
        />
        <p className="dash p-0">-</p>
        <DateSearch
          selectedDate={budget.endDate}
          setValue={(value) => onDateChange('endDate', value)}
          isEnd
          defaultMinDate={endMinDate}
          defaultStartDate={budget.startDate}
          defaultEndDate={budget.endDate}
          className="refund-date end-date form-purple-value text-primary"
          ref={endDateRef}
          placeholder="DD/MM/YY"
          name="budget-end-date"
        />
      </>
    );
  };

  const renderBudget = () => {
    if (editable) {
      return (
        <Row>
          <Colxx xxs="6">
            <Label className="form-group search reservation has-top-label">
              <NumberFormat
                name="budget-value"
                prefix={'R$ '}
                suffix={''}
                thousandSeparator="."
                decimalSeparator=","
                decimalScale="2"
                fixedDecimalScale="2"
                className="currency-input w-100 form-purple-value"
                defaultValue={budget.budget || ''}
                onValueChange={(v) => {
                  onChange('budget', v.floatValue);
                }}
              />

              <span className="d-flex align-items-center">
                {messages['refund.modal.refund.budget']} *
              </span>
            </Label>
          </Colxx>
          <Colxx xxs="6">
            <Label
              className="form-group search reservation has-top-label"
              name="budget-category"
            >
              <FixedSelect
                className="react-select mt-1"
                classNamePrefix="react-select"
                isClearable={false}
                placeholder=""
                value={budget.category}
                noOptionsMessage={() => {
                  return messages['refund.modal.refund.no-budget-category'];
                }}
                options={budgetCategories}
                onChange={(category) => onChange('category', category)}
                maxMenuHeight={150}
              />
              <span className="d-flex align-items-center">
                {messages['refund.modal.refund.budget-category']} *
              </span>
            </Label>
          </Colxx>
        </Row>
      );
    }
  };

  const renderEditable = () => (
    <div className="px-3" name="budget-modal">
      <Row>
        <Label className="form-group disabled search has-top-label w-100">
          <CustomInput
            id="budget-name-input"
            name="budget-name"
            type="text"
            placeholder=""
            value={budget.name || ''}
            onChange={(e) => onChange('name', e.target.value)}
            className="form-input mt-4 w-100 refund-name px-3 pb-2 form-purple-value without-transform"
            maxLength={50}
          />

          <span
            ref={nameRef}
            className={setShowNameDocumentation ? 'pointer' : ''}
            onClick={handleNameClick}
          >
            {messages['refund.modal.items.name']} *
          </span>
        </Label>

        <AnimateHeight height={showParsed ? 'auto' : 0} easing="ease">
          <p className="m-0 mx-2 px-1 my-1 text-primary font-weight-semibold fs-0-7rem">
            {parsedName}
          </p>
        </AnimateHeight>
      </Row>
      <Row>
        <Colxx xxs="12">
          <Label
            className={`form-group disabled search has-top-label spans ${
              editable ? '' : 'read-only'
            }`}
            onClick={(e) => e.preventDefault()}
          >
            <div className="d-flex align-items-center refund-dates">
              {renderPeriodSelection()}
            </div>
            <span>
              {
                messages[
                  administrative
                    ? 'refund.params.payment-date'
                    : 'refund.params.time-interval'
                ]
              }{' '}
              *{' '}
            </span>
          </Label>
        </Colxx>
      </Row>

      <CommonParams
        commonParams={budget}
        setCommonParams={setBudget}
        editableFields={editableFields}
        requiredFields={requiredFields}
      />

      {hasBudget ? renderBudget() : null}

      {administrative ? null : (
        <Row>
          <Colxx xxs="12">
            <span className="d-block mx-2 fs-0-6rem text-primary font-weight-semibold mb-2">
              * {messages['general.required-fields']}
            </span>
          </Colxx>
        </Row>
      )}
    </div>
  );

  return (
    <div className="reservation-params refund-params py-2 light">
      {editable ? (
        <>
          {renderEditable()}

          <BudgetNameDocumentationModal
            show={showNameDocumentation}
            toggle={toggleNameDocumentation}
            target={nameRef}
            calculateStyleOnShow
          />
        </>
      ) : (
        <div className="px-1">
          <ReadOnlyBudgetParams budget={budget} />
        </div>
      )}
    </div>
  );
}
