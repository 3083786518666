import {
  SET_AUTH,
  SET_USER,
  SET_ORGANIZATION,
  LOGOUT_USER,
  SET_HAS_EXPENSE_CARD,
} from '../actions';

export const setAuth = (auth) => ({
  type: SET_AUTH,
  payload: auth,
});

export const setUser = (user) => ({
  type: SET_USER,
  payload: user,
});

export const setOrganization = (organization) => ({
  type: SET_ORGANIZATION,
  payload: organization,
});

export const setHasExpenseCard = (value) => ({
  type: SET_HAS_EXPENSE_CARD,
  payload: value,
});

export const logoutUser = () => ({
  type: LOGOUT_USER,
});
