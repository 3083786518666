import React from 'react';
import ContentLoader from 'react-content-loader';

export default function LoaderAnalyticsSingle() {
  return (
    <div className="w-100 d-flex flex-row justify-content-between">
      <ContentLoader
        speed={2}
        width={200}
        height={55}
        viewBox="0 0 200 55"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
      >
        <rect x="0" y="0" rx="3" ry="3" width="120" height="25" />
        <rect x="0" y="30" rx="3" ry="3" width="200" height="15" />
      </ContentLoader>
    </div>
  );
}
