import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import CustomCollapse from 'src/portao3-legacy/components/common/CustomCollapse';
import useDebounceStoreData from 'src/portao3-legacy/hooks/useDebounceStoreData';
import firebaseApp from 'src/portao3-legacy/services/Firebase';
import { getAuthRedux } from 'src/portao3-legacy/utils/redux';
import FixedSelectItem from '../FixedSelectItem';
import useDataArchive from 'src/portao3-legacy/hooks/useDataArchive';

export default function GenericParamsOptions({
  className,
  title,
  params,
  paramType,
  initialData,
}) {
  const { user } = useSelector(getAuthRedux);

  // Hooks
  const { deleteDataFromArchive } = useDataArchive();

  // States
  const { data, storeData, setData } = useDebounceStoreData({
    initialState: initialData,
    storeFunction: (key, value) => {
      firebaseApp.updateOrganizationSearchParam({
        organizationId: user.organizationId,
        searchParamsType: paramType,
        searchParamsKey: key,
        searchParamsValue: value,
      });
    },
  });

  // Effects
  useEffect(() => {
    if (initialData) {
      setData((data) => ({ ...data, ...initialData }));
    }
  }, [initialData]);

  // Functions
  const handleOnChange = (key, data) => {
    storeData(key, data);
    deleteDataFromArchive(`default-search-params-${paramType}`);
  };

  // Render
  return (
    <CustomCollapse className={className} title={title}>
      {params.map(({ key, options, title }, index) => (
        <FixedSelectItem
          className={index ? 'mt-3' : ''}
          key={key}
          title={title}
          value={data[key] || []}
          options={options}
          onChange={(data) => handleOnChange(key, data)}
        />
      ))}
    </CustomCollapse>
  );
}
