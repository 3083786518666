import { useEffect, useState } from 'react';

const defaultGetDataFunction = (querySnapshot) => {
  const data = [];

  querySnapshot.forEach((doc) => {
    data.push({
      ...doc.data(),
      id: doc.id,
    });
  });

  return data;
};

export default function useDataLoader({
  loadInitalState = true,
  fetchFunction = () => {},
  initialDataState = [],
  prepareData = (data) => data,
  snapshot = false,
  getDataFunction = defaultGetDataFunction,
}) {
  const [loading, setLoading] = useState(loadInitalState);
  const [data, setData] = useState(initialDataState);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!fetchFunction) return;

    if (snapshot) {
      setLoading(true);
      const unsubscribe = fetchFunction().onSnapshot((querySnapshot) => {
        setData(prepareData(getDataFunction(querySnapshot)));
        setLoading(false);
      });
      return () => {
        unsubscribe();
      };
    } else loadData();
  }, []);

  const loadData = async () => {
    try {
      if (!loading) setLoading(true);

      const snap = await fetchFunction();

      setData(prepareData(getDataFunction(snap)));
    } catch (err) {
      console.error(err);
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  return [data, setData, loading, error];
}
