import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { ADMINISTRATIVE_BUDGET_TYPES } from '../../../../constants/administrativeBudgets';
import { sortAlphabetically } from '../../../../utils/sort';
import classNames from 'classnames';
import PixTab from './PaymentTabs/PixTab';
import BoletoTab from './PaymentTabs/BoletoTab';
import { Label, Row } from 'reactstrap';
import FixedSelect from '../../../common/FixedSelect';
import { Colxx } from '../../../common/CustomBootstrap';

const COMPONENTS = {
  [ADMINISTRATIVE_BUDGET_TYPES.PIX]: PixTab,
  [ADMINISTRATIVE_BUDGET_TYPES.BOLETO]: BoletoTab,
};

export default function PaymentTab({
  className,
  budget,
  paymentInfo,
  setPaymentInfo,
  cardId,
}) {
  const { messages } = useIntl();

  // States
  const paymentType = useMemo(() => paymentInfo?.type, [paymentInfo]);

  const paymentsOptions = useMemo(
    () =>
      sortAlphabetically(
        Object.values(ADMINISTRATIVE_BUDGET_TYPES).map((value) => ({
          value,
          label: messages[`administrative.payment-option.${value}`],
        })),
        'label'
      ),
    []
  );

  const Component = useMemo(
    () => COMPONENTS[paymentType?.value],
    [paymentType]
  );

  // Functions
  const addToPaymentInfo = (name, value, shouldOnlyKeepType) => {
    setPaymentInfo((obj = {}) =>
      shouldOnlyKeepType
        ? {
            type: obj?.type,
            [name]: value,
          }
        : { ...obj, [name]: value }
    );
  };

  const resetPaymentInfo = (additionalInfo = {}) => {
    setPaymentInfo({ type: paymentType, ...additionalInfo });
  };

  // Render
  return (
    <div className={classNames('payment-tab', className)}>
      <div className="reservation-params refund-params light">
        <Row className="m-0">
          <Label className="form-group search reservation has-top-label w-100">
            <FixedSelect
              className="react-select"
              classNamePrefix="react-select"
              isClearable={false}
              placeholder=""
              value={paymentType}
              options={paymentsOptions}
              onChange={(value) => addToPaymentInfo('type', value, true)}
              maxMenuHeight={150}
              noOptionsMessage={() => messages['general.no-options-found']}
            />
            <span>{messages['administrative.payment-option.title']} *</span>
          </Label>
        </Row>
      </div>

      {Component ? (
        <Component
          budget={budget}
          paymentInfo={paymentInfo}
          setPaymentInfo={setPaymentInfo}
          addToPaymentInfo={addToPaymentInfo}
          resetPaymentInfo={resetPaymentInfo}
          cardId={cardId}
        />
      ) : null}

      <div className="reservation-params refund-params light">
        <Row className="mx-2">
          <Colxx xxs="12">
            <span className="d-block mx-2 fs-0-6rem text-primary font-weight-semibold mb-2">
              * {messages['general.required-fields']}
            </span>
          </Colxx>
        </Row>
      </div>
    </div>
  );
}
