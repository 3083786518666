import classNames from 'classnames';
import React, { useMemo } from 'react';
import { Row } from 'reactstrap';
import SeatSelector from '../../../../bus/SeatSelector';
import { Colxx } from '../../../../common/CustomBootstrap';

function formatStation(station) {
  return station.split(' -')[0];
}

export default function SeatSelectionItem({
  className,
  reservation,
  sellKey,
  seatInfo,
  onSelect,
  onClear,
}) {
  // States
  const { name, date } = useMemo(() => {
    const { departureDateTime, departureStation, arrivalStation } =
      reservation.busDetails;

    return {
      name: `${formatStation(departureStation)} • ${formatStation(
        arrivalStation
      )}`,
      date: departureDateTime,
    };
  }, [reservation]);

  // Render
  return (
    <Row className={classNames('seat-selection-index m-0', className)}>
      <Colxx md="12" lg="6" className="m-0 p-0">
        <h4 className="m-0 p-0 font-primary fs-0-95rem font-weight-normal">
          {name}
        </h4>
        <p className="m-0 p-0 fs-0-85rem text-white-muted mt-1 mb-2">{date}</p>
      </Colxx>

      <Colxx md="12" lg="6" className="seat-container m-0 p-0">
        <SeatSelector
          className="m-0 p-0 white"
          seat={seatInfo}
          onSelect={onSelect}
          scheduleId={sellKey}
        />
      </Colxx>
    </Row>
  );
}
