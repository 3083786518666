import React, { useMemo, useState } from 'react';

export default function BubblePagination({
  numPages,
  selectedPage,
  onPageChange,
}) {
  const pages = useMemo(() => [...new Array(numPages).keys()]);

  return (
    <div className="bubble-pagination d-flex justify-content-center align-items-center">
      {pages.map((page) => (
        <div
          className={`page-item p-0 mx-2 ${
            page === selectedPage ? 'selected' : ''
          } pointer`}
          key={`page-${page}`}
          onClick={() => onPageChange(page)}
        />
      ))}
    </div>
  );
}
