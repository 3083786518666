import React from 'react';
import { useMemo } from 'react';
import { useDragLayer } from 'react-dnd';
import { ReactComponent as PurplePin } from '../../assets/img/icons/purple_pin.svg';

const layerStyles = {
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: 10000,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
};

function getItemStyles(initialOffset, currentOffset) {
  if (!initialOffset || !currentOffset) {
    return {
      display: 'none',
    };
  }

  let { x, y } = currentOffset;

  const transform = `translate(${x}px, ${y}px) rotate(3deg)`;

  return {
    transform,
    WebkitTransform: transform,
    backgroundColor: 'white',
    boxShadow: '0 0 15px rgba(67, 65, 142, 0.1)',
    width: 'fit-content',
    borderRadius: '5px',
    padding: '10px 15px',
    cursor: 'grabbing',
  };
}

export default function RouteStopDragLayer() {
  const { isDragging, item, itemType, initialOffset, currentOffset } =
    useDragLayer((monitor) => ({
      item: monitor.getItem(),
      itemType: monitor.getItemType(),
      initialOffset: monitor.getInitialSourceClientOffset(),
      currentOffset: monitor.getSourceClientOffset(),
      isDragging: monitor.isDragging(),
    }));

  const itemStyle = useMemo(
    () =>
      itemType === 'ROUTE' ? getItemStyles(initialOffset, currentOffset) : null,
    [itemType, initialOffset, currentOffset]
  );

  if (!(isDragging && itemStyle)) return null;

  return (
    <div style={layerStyles}>
      <div
        style={itemStyle}
        className="d-flex align-items-center justify-content-center"
      >
        <PurplePin height="20px" className="text-primary" />
        <p
          className="m-0 p-0 ml-2 font-weight-medium"
          style={{ maxWidth: '250px', fontSize: '0.8rem', lineHeight: 1.4 }}
        >
          {item.stop.address}
        </p>
      </div>
    </div>
  );
}
