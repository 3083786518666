import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useParams } from 'react-router-dom';
import { Row, Nav, NavItem } from 'reactstrap';
import classnames from 'classnames';
import IntlMessages from '../../helpers/IntlMessages';
import { Colxx, Separator } from '../../components/common/CustomBootstrap';
import Breadcrumb from '../../containers/navs/Breadcrumb';

import CreditCardsDataListView from '../../containers/finance/CreditCardsDataListView';
import InvoicesDataListView from '../../containers/finance/InvoicesDataListView';
import { getOrganizationConfig } from '../../utils/organization';

export default function Finance(props) {
  const { tabId } = useParams();

  const { organization } = useSelector(({ auth }) => ({
    organization: auth.organization,
  }));

  return (
    <Fragment>
      <Row>
        <Colxx xxs="12">
          <Breadcrumb heading="admin.finance.title" match={props.match} />
          <Separator className="mb-5" />
        </Colxx>
      </Row>
      <Row>
        <Colxx xxs="12" className="mb-4">
          <Nav className="admin-tabs">
            <NavItem>
              <NavLink
                to="/app/finance/invoice"
                className={classnames({
                  active: !tabId || tabId === 'invoice',
                  'nav-link': true,
                })}
              >
                <IntlMessages id="admin.finance.invoice" />
              </NavLink>
            </NavItem>
            {getOrganizationConfig(organization.config, 'canAddOwnCard') ? (
              <NavItem>
                <NavLink
                  to="/app/finance/credit_cards"
                  className={classnames({
                    active: tabId === 'credit_cards',
                    'nav-link': true,
                  })}
                >
                  <IntlMessages id="admin.finance.credit_cards" />
                </NavLink>
              </NavItem>
            ) : null}
          </Nav>

          {!tabId || tabId === 'invoice' ? <InvoicesDataListView /> : null}
          {tabId === 'credit_cards' ? <CreditCardsDataListView /> : null}
        </Colxx>
      </Row>
    </Fragment>
  );
}
