import React, { useCallback, useRef, useState } from 'react';
import debounce from 'lodash/debounce';

export default function useDebounceStoreData({
  initialState = {},
  storeFunction,
  ms = 1000,
} = {}) {
  // Ref
  const debouncesRef = useRef({});

  // States
  const [data, setData] = useState(() =>
    typeof initialState === 'function' ? initialState() : initialState
  );

  // Functions
  const executeFunction = useCallback(
    (key, data, storeFunction) => {
      if (!(key in debouncesRef.current)) {
        debouncesRef.current[key] = debounce(storeFunction, ms);
      }

      debouncesRef.current[key](key, data);
    },
    [ms]
  );

  const storeData = (key, data) => {
    executeFunction(key, data, storeFunction);
    setData((oldData) => ({
      ...oldData,
      [key]: data,
    }));
  };

  return {
    data,
    storeData,
    setData,
  };
}
