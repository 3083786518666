import moment from 'moment';
import clone from 'lodash/clone';

import { THotelReservationParams } from '../types';

function makeOrderDetails({
  reservationParams,
  selectedRoom,
  item,
  search,
  suggestionHotel = false,
}: THotelReservationParams) {
  return {
    ...reservationParams,
    fare: {
      hasBreakfast: selectedRoom.board.breakfast,
      issueUntil: null,
      total: selectedRoom.fare,
      currency: selectedRoom.currency,
    },
    provider: {
      origin: item.origin,
      token: item.token,
    },
    searchCode: search.searchCode,
    suggestionHotel,
  };
}

function makeReservations({
  selectedRoom,
  item,
  search,
}: THotelReservationParams) {
  const cancellation = [...selectedRoom.cancellation];

  return [
    {
      hotel: item.hotel,
      hotelId: item.hotelId,
      selectedRoom: selectedRoom,
      cancellation,
      checkIn: moment(search.outbound_date).format('DD/MM/YYYY'),
      checkOut: moment(search.inbound_date).format('DD/MM/YYYY'),
      confirmation: '',
    },
  ];
}

export function makeHotelParams(params: THotelReservationParams) {
  params.selectedRoom = clone(params.selectedRoom);

  const orderDetails = makeOrderDetails(params);

  const reservations = makeReservations(params);

  return {
    order: orderDetails,
    reservations,
    filterTimestamp: moment(reservations[0].checkIn, 'DD/MM/YYYY')
      .endOf('day')
      .utc()
      .unix(),
  };
}
