import PerfectScrollbar from 'react-perfect-scrollbar';
import React from 'react';
import { useMemo } from 'react';
import { stringToMoney } from '../../../../../../utils/money';
import BottomButtons from '../BottomButtons';
import SelectedRoomCard from './SelectedRoomCard';
import {
  getReservationParamsFromOrder,
  reserveHotelItem,
} from '../../../../../../utils/reservation';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { getHotelSearchParams } from '../../../../../../utils/search';
import { useIntl } from 'react-intl';

export default function CheckoutTab({
  reservations,
  selectedRooms,
  goBack,
  results,
  order,
  goForAlteration,
  direction,
}) {
  const { user, organization } = useSelector(({ auth }) => auth);
  const { messages } = useIntl();

  const [reservating, setReservating] = useState(false);

  const { total, multiple } = useMemo(() => {
    const total = selectedRooms.reduce((sum, room) => {
      return room ? room.fare : sum;
    }, 0);

    return {
      total: stringToMoney(total, 0),
      multiple: selectedRooms.length > 1,
    };
  }, [selectedRooms]);

  // Functions
  const reservateRooms = async () => {
    try {
      setReservating(true);

      const reservationParams = getReservationParamsFromOrder(order);
      const organizationId = user.organizationId;
      const orderParams = {
        extensionOf: order.id,
      };

      results.forEach(({ item, search }, index) => {
        const selectedRoom = selectedRooms[index];
        const { searchCode } = getHotelSearchParams(search, organization);

        reserveHotelItem({
          organizationId,
          search: {
            ...search,
            searchCode,
          },
          reservationParams,
          item,
          selectedRoom,
          doUpdateUser: false,
          orderParams,
        });
      });

      goForAlteration();
    } catch (err) {
      console.error(err);
    } finally {
      setReservating(false);
    }
  };

  return (
    <div
      className={`d-flex flex-column justify-content-between h-100 show-up ${direction}`}
    >
      <div className="flex-grow-1 d-flex align-items-center">
        <PerfectScrollbar
          options={{
            suppressScrollY: true,
            wheelPropagation: false,
          }}
          style={{ height: 250 }}
        >
          <div className="d-flex flex-grow-1">
            {selectedRooms.map((room, index) => (
              <SelectedRoomCard
                key={`selected-room-${index}`}
                room={room}
                reservation={reservations[0]}
                result={results[index]}
                style={{ animationDelay: `${index * 100 + 200}ms` }}
                className="mr-4"
              />
            ))}
            <div style={{ width: 1, height: 100, paddingRight: 5 }}></div>
          </div>
        </PerfectScrollbar>
      </div>
      <div className="d-flex justify-content-between align-items-center">
        <div>
          {multiple ? (
            <>
              <p className="m-0" style={{ fontSize: '0.95rem' }}>
                <span className="font-weight-semibold">
                  {messages['general.total']}
                </span>{' '}
                {total}
              </p>
            </>
          ) : null}
        </div>
        <div style={{ pointerEvents: reservating ? 'none' : 'all' }}>
          <BottomButtons
            forwardText="Reservar"
            goBack={goBack}
            goForward={reservateRooms}
          />
        </div>
      </div>
    </div>
  );
}
