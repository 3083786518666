import moment from 'moment';
import { RefundTypes } from '../constants/refundTypes';

export function calculateActiveDays(startDate, endDate) {
  return endDate.startOf('day').diff(startDate.startOf('day'), 'days');
}

export function calculateNextRecurrenceInDay(nextRecurrence) {
  const date = moment(nextRecurrence, 'YYYY-MM-DD').startOf('day');
  return date.diff(moment().startOf('day'), 'days');
}

export function getCardNameFromMessages(expenseCard, formatMessage) {
  if (!expenseCard) return '';

  if (expenseCard.type === RefundTypes.PERSONAL_ACCOUNT) {
    return 'PIX';
  }

  return expenseCard.type === RefundTypes.PHYSICAL_CARD
    ? formatMessage(
        { id: 'recurrence-budget.physical-card' },
        { lastDigits: expenseCard.lastDigits || '****' }
      )
    : expenseCard.name;
}
