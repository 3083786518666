import { useMemo } from 'react';
import classNames from 'classnames';

import { IComponent } from 'src/portao3-legacy/types/others';

interface IProps {
  Component: IComponent;
  numberOfLoading?: number;
  className?: string;
}

export default function SearchLoading({
  numberOfLoading = 5,
  Component,
  className,
}: IProps) {
  const items = useMemo(
    () => new Array(numberOfLoading).fill(true),
    [numberOfLoading]
  ) as boolean[];

  // Render
  return (
    <div className={classNames('search-loading', className)}>
      {items.map((_, index) => (
        <Component key={`item-${index}`} />
      ))}
    </div>
  );
}
