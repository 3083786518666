import {
  FilterQueryTypes,
  TFilterQueryTypes,
} from 'src/portao3-legacy/hooks/useFilterItems/types';
import { ParamTypes } from 'src/portao3-legacy/hooks/useParams/types';
import { TCarSearchItem } from 'src/portao3-legacy/types/orders/car/carOrder';
import { TCarGetDefaultParams } from 'src/portao3-legacy/types/orders/car/carParams';
import { THotelSearchItem } from 'src/portao3-legacy/types/orders/hotel/hotelOrder';
import { makeContainsParamsFromDefaultSearchParams } from 'src/portao3-legacy/utils/params';

export const CarParamsConfig = [
  {
    name: 'price',
    type: ParamTypes.RANGE,
  },
  {
    name: 'rentals',
    type: ParamTypes.CONTAINS,
    getDefaultValue: (defaultParams: TCarGetDefaultParams = {} as any) =>
      makeContainsParamsFromDefaultSearchParams({
        searchParam: defaultParams.searchParams?.rentals,
        defaultParam: defaultParams.defaultSearchParams?.rentals,
      }),
  },
  {
    name: 'categories',
    type: ParamTypes.CONTAINS,
    getDefaultValue: (defaultParams: TCarGetDefaultParams = {} as any) =>
      makeContainsParamsFromDefaultSearchParams({
        searchParam: defaultParams.searchParams?.types,
        defaultParam: defaultParams.defaultSearchParams?.categories,
      }),
  },
  {
    name: 'transmissions',
    type: ParamTypes.CONTAINS,
    getDefaultValue: (defaultParams: TCarGetDefaultParams = {} as any) =>
      makeContainsParamsFromDefaultSearchParams({
        searchParam: defaultParams.searchParams?.transmissions,
        defaultParam: defaultParams.defaultSearchParams?.transmissions,
      }),
  },
  {
    name: 'tractions',
    type: ParamTypes.CONTAINS,
  },
  {
    name: 'selectedRentals',
    type: ParamTypes.CONTAINS,
  },
];

export const CarFilterQueries: TFilterQueryTypes<any, any, any>[] = [
  {
    name: 'price',
    type: FilterQueryTypes.RANGE,
    getValue: ({ fare }: THotelSearchItem) => fare.total,
  },
  {
    name: 'rentals',
    type: FilterQueryTypes.CONTAINS,
    getValue: ({ rental }: TCarSearchItem) => rental?.code,
    allowUndefined: false,
    filterFalsy: true,
    skipIfEmpty: true,
  },
  {
    name: 'categories',
    type: FilterQueryTypes.CONTAINS,
    getValue: ({ amenities }: TCarSearchItem) => amenities.type,
    allowUndefined: false,
    filterFalsy: true,
    skipIfEmpty: true,
  },
  {
    name: 'transmissions',
    type: FilterQueryTypes.CONTAINS,
    getValue: ({ amenities }: TCarSearchItem) => amenities.transmission,
    allowUndefined: false,
    filterFalsy: true,
    skipIfEmpty: true,
  },
  {
    name: 'tractions',
    type: FilterQueryTypes.CONTAINS,
    getValue: ({ amenities }: TCarSearchItem) => amenities.traction,
    allowUndefined: false,
    filterFalsy: true,
    skipIfEmpty: true,
  },
];

export const AllowedOrganizations = [
  '8dxTLMrutixVK0Ty5BMb',
  'T6yoloZvm7z2UUFqjcgp',
  'wFHg8FuQSUgs03z4oaQt',
  'IloSV0tCjgiBfw9UB8rc',
  '3WZ8uCcjNeMYIMkFrl0B',
  'Lyq0fss3kVl9xGQUAaXr',
  'derm2RMFUlLHPmcR6EB9',
];
