import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { Button } from 'reactstrap';
import { useIntl } from 'react-intl';
import moment from 'moment';
import { stringToMoney } from '../../utils/money';
import firebaseApp from '../../services/Firebase';
import appFunctions from '../../services/Functions';
import Spinner from '../common/Spinner';
import {
  CancellationTimeOffset,
  NeedsCancellationMotive,
} from '../../constants/cancellation';
import AlterationFrame from './Alteration/AlterationFrame';
import debounce from 'lodash/debounce';
import CancellationMotive from './CancellationMotive';
import useExecuteAction from '../../hooks/useExecuteAction';

const getMomentFromDate = (date, dateFormat = 'YYYY-MM-DD HH:mm') =>
  moment(date, dateFormat);

const getDateAndHour = (momentDate) => [
  momentDate.format('DD MMM YYYY'),
  momentDate.format('HH:mm'),
];

const formatCancellation = ({ startDate, endDate, fare }) => ({
  startDate: getMomentFromDate(startDate),
  endDate: getMomentFromDate(endDate),
  formattedAmount: stringToMoney(fare.total, 2, fare.currency),
  fare,
});

const findCancellationIndex = (cancellations) => {
  const today = moment();

  if (!cancellations.length) return 0;

  if (today.isBefore(cancellations[0].startDate)) return 0;

  const index = cancellations.findIndex(
    (cancellation) =>
      today.isAfter(cancellation.startDate) &&
      today.isBefore(cancellation.endDate)
  );

  return index === -1 ? cancellations.length + 1 : index + 1;
};

const getObjectFromDate = (startAt, endAt) => {
  const [startDate, startHour] = getDateAndHour(startAt);
  const [endDate, endHour] = getDateAndHour(endAt);

  return {
    startDate,
    startHour,
    endDate,
    endHour,
  };
};

const getFreeUntil = (cancelPolicies = [], orderType) => {
  if (
    cancelPolicies.length > 0 &&
    cancelPolicies.every((cancelPolicy) => cancelPolicy.fare.total !== 0)
  ) {
    const timeOffset = CancellationTimeOffset[orderType];

    if (timeOffset) {
      return moment(cancelPolicies[0].startDate, 'YYYY-MM-DD HH:mm').subtract(
        ...timeOffset
      );
    } else {
      return moment(cancelPolicies[0].startDate, 'YYYY-MM-DD HH:mm')
        .subtract(1, 'days')
        .endOf('days');
    }
  } else return null;
};

function CancellationBubble({ cancellation, isPast, isCurrent, style = {} }) {
  const { formatMessage } = useIntl();

  const title = useMemo(
    () =>
      cancellation.endDate
        ? formatMessage(
            { id: 'travel.cancel.cancel-dates' },
            getObjectFromDate(cancellation.startDate, cancellation.endDate)
          )
        : formatMessage(
            { id: 'travel.cancel.free-until' },
            {
              date: cancellation.startDate.format('DD MMM YYYY'),
              hour: cancellation.startDate.format('HH:mm'),
            }
          ),
    [cancellation]
  );

  return (
    <div
      className={`cancellation-bubble ${isPast ? 'past' : ''} ${
        isCurrent ? 'current' : ''
      }`}
      style={style}
    >
      <div className="bubble" />
      <div className="ml-3">
        <h3 className="m-0 font-primary title mb-1 font-weight-medium">
          {title}
        </h3>
        <p className="m-0 price">{cancellation.formattedAmount}</p>
      </div>
    </div>
  );
}

function CancellationBubbles({
  cancellations,
  cancellationIndex,
  numCancellations,
  canCancel,
}) {
  const { messages } = useIntl();

  const lineAnimationDuration = useMemo(
    () => cancellations.length * 400,
    [cancellations]
  );

  // Render
  return (
    <>
      <div className="cancellation-bubbles">
        <div className="all-bubbles">
          {cancellations.map((cancellation, index) => (
            <CancellationBubble
              cancellation={cancellation}
              isPast={index < cancellationIndex}
              isCurrent={index === cancellationIndex}
              key={`bubble-${index}`}
              style={{ animationDelay: `${index * 150}ms` }}
            />
          ))}
        </div>
        <div
          className="line"
          style={{ animationDuration: `${lineAnimationDuration}ms` }}
        />
      </div>
      {canCancel ? null : (
        <p
          className="cancellation-msg w-70"
          style={{ animationDelay: `${(numCancellations + 1) * 150}ms` }}
        >
          {messages['travel.cancel.cannot-cancel']}
        </p>
      )}
    </>
  );
}

export default function CancellationModalTab({
  order,
  goToInitial = () => {},
  closeTab = () => {},
}) {
  const { messages } = useIntl();

  const [loadingPolicies, setLoadingPolicies] = useState(true);
  const [policies, setPolicies] = useState([]);
  const [motive, setMotive] = useState(null);
  const [customMotive, setCustomMotive] = useState('');

  const {
    executeAction,
    loading: doingAction,
    error,
  } = useExecuteAction({
    defaultErrorMessage: messages['cancellation.error'],
  });

  const canCancel = useMemo(() => {
    const numPolicies = policies.length;
    return numPolicies > 0
      ? !moment().isAfter(policies[numPolicies - 1].endDate)
      : false;
  }, [policies]);

  const {
    cancellations = [],
    cancellationIndex,
    numCancellations = 0,
    currentCancellation,
  } = useMemo(() => {
    if (policies.length) {
      const freeUntil = getFreeUntil(policies, order.type);

      const cancellations = freeUntil
        ? [
            {
              startDate: freeUntil,
              fare: {
                currency: policies[0].fare.currency,
                total: 0,
              },
              isFree: true,
              formattedAmount: messages['travel.cancel.free'],
            },
            ...policies,
          ]
        : policies;

      const cancellationIndex = findCancellationIndex(policies);

      return {
        cancellations,
        cancellationIndex,
        numCancellations: policies.length,
        currentCancellation: cancellations[cancellationIndex],
      };
    } else return {};
  }, [order, policies]);

  const needsMotive = useMemo(
    () =>
      NeedsCancellationMotive.includes(order.type) &&
      !cancellations[cancellationIndex]?.isFree,
    [order, cancellations, cancellationIndex]
  );

  const cancelButtonText = useMemo(() => {
    if (currentCancellation) {
      if (currentCancellation.isFree)
        return messages['cancellation.button.free'];

      return motive && (!motive?.needsInput || customMotive)
        ? messages['cancellation.button.with-refund']
        : messages['cancellation.button.without-refund'];
    }

    return messages['travel.cancel.title'];
  }, [currentCancellation, motive, customMotive]);

  // Effects
  useEffect(() => {
    fetchCancelPolicies();
  }, [order]);

  useEffect(() => {
    if (customMotive) setCustomMotive('');
  }, [motive]);

  // Functions
  const fetchCancelPolicies = async () => {
    try {
      setLoadingPolicies(true);

      const {
        data: { cancelPolicies },
      } = await appFunctions.getCancelPolicies(order.id);

      setPolicies(cancelPolicies.map((cancel) => formatCancellation(cancel)));

      setLoadingPolicies(false);
    } catch (err) {
      console.error(err);
    }
  };

  const doCancelReservation = async (
    currentCancellation,
    motive,
    customMotive
  ) => {
    if (!currentCancellation) return;

    await firebaseApp.requestToCancel(order.id, {
      motive: (motive?.needsInput ? customMotive : motive?.label) || null,
    });

    closeTab();
  };

  const cancelReservation = useCallback(
    debounce((...props) => {
      executeAction(doCancelReservation(...props));
    }, 300),
    []
  );

  // Render
  const renderBottom = () => (
    <div className="ml-3 text-right btns">
      <Button color="link" onClick={goToInitial} className="mb-0">
        {messages['general.go-back']}
      </Button>
      {currentCancellation ? (
        <Button
          className="mb-0"
          onClick={() =>
            cancelReservation(currentCancellation, motive, customMotive)
          }
          disabled={!canCancel || doingAction}
        >
          {cancelButtonText}
        </Button>
      ) : null}
    </div>
  );

  return (
    <AlterationFrame
      title={messages['travel.cancel.title']}
      subtitle={messages['travel.cancel.subtitle']}
      BottomBar={renderBottom}
      className="cancellation-modal"
    >
      {error ? <p className="error-badge my-2">{error}</p> : null}

      {loadingPolicies ? (
        <div className="flex-center h-100">
          <Spinner white relative style={{ width: 60, height: 60 }} />
        </div>
      ) : (
        <div className="mx-3 pt-3">
          <CancellationBubbles
            cancellations={cancellations}
            cancellationIndex={cancellationIndex}
            numCancellations={numCancellations}
            canCancel={canCancel}
          />
          {needsMotive && currentCancellation ? (
            <CancellationMotive
              className="mt-4 pt-1 w-100 show-up"
              orderType={order.type}
              motive={motive}
              setMotive={setMotive}
              customMotive={customMotive}
              setCustomMotive={setCustomMotive}
              style={{ animationDelay: `${(numCancellations + 1) * 150}ms` }}
            />
          ) : null}
        </div>
      )}
    </AlterationFrame>
  );
}
