import { SET_APPROVAL_INFO } from '../actions';

const initialState = {
  approvalInfo: {
    approvals: [],
    numOrders: 0,
    loading: true,
    lastIndex: null,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_APPROVAL_INFO: {
      return {
        ...state,
        approvalInfo: {
          ...state.approvalInfo,
          ...action.payload,
        },
      };
    }
    default:
      return { ...state };
  }
};
