import md5 from 'md5';
import moment from 'moment';
import { ADMINISTRATIVE_BUDGET_TYPES } from '../constants/administrativeBudgets';
import { BudgetProviders } from '../constants/budget';
import {
  AdminstrativeBudgetStatusMessages,
  BudgetStatusMessages,
  RefundStatus,
} from '../constants/refundStatus';
import { RefundTypes } from '../constants/refundTypes';
import { replaceVariables } from '../constants/variableName';
import firebaseApp from '../services/Firebase';
import appFunctions from '../services/Functions';
import { getPassengerInfo } from './firebase';
import { DEFAULT_CURRENCY } from './money';
import { sortArrayAlphabetically } from './sort';
import { pixBanks } from '../constants/pixBanks';
import { getOrganizationConfig } from '../utils/organization';

export const hashBudget = (product, specificity, categories) => {
  return `${product}-${
    specificity
      ? `${specificity.type}-${sortArrayAlphabetically(
          specificity.values.map(({ value }) => value)
        ).join('-')}`
      : ''
  }-${sortArrayAlphabetically(categories).join('-')}`;
};

export function calculateTotal(items = []) {
  return items.reduce(
    (sum, { quantity = 0, price = 0 }) => sum + quantity * price,
    0
  );
}

export const formatItems = (items) =>
  items
    .filter((item) => item.quantity && item.price)
    .map(({ name, quantity, price }) => ({ name, quantity, price }));

export const formatReceiptBudgetValues = (
  { name, location, date, category, description },
  partial = false
) =>
  partial
    ? { name, category, description }
    : {
        name,
        location: location.label,
        date: moment(date).toISOString(true),
        category,
        description,
      };

export const formatKmBudgetValues = ({ name, date }) => ({
  name,
  date: moment(date).format(),
});

export const getItemBudgetValues = ({
  name,
  date,
  location,
  category,
  description,
}) => ({
  name,
  location: {
    label: location,
  },
  category,
  date: moment(date),
  description,
});

export const createBudget = async ({
  user,
  type,
  currency = DEFAULT_CURRENCY,
  additional = {},
  fareAdditional = {},
  values,
  items: rawItems,
  budgetId,
}) => {
  const items = formatItems(rawItems);
  const total = calculateTotal(items);

  return firebaseApp.createBudgetItem(budgetId, {
    ...values,
    fare: {
      ...fareAdditional,
      total,
      currency,
    },
    type,
    passengerId: user.uid,
    passengerInfo: getPassengerInfo(user),
    createdAt: moment().unix(),
    organizationId: user.organizationId,
    items,
    ...additional,
  });
};

export const modifyBudgetItem = async ({
  user,
  budgetId,
  budgetItemId,
  values,
  additional = {},
  items: rawItems,
  total,
}) => {
  const items = formatItems(rawItems);

  const payload = {
    ...additional,
    items,
    ...values,
    'fare.total': total || calculateTotal(items),
  };

  if (user) {
    payload['passengerInfo'] = getPassengerInfo(user);
  }

  return firebaseApp.updateBudgetItem(budgetId, budgetItemId, payload);
};

export const turnRouteIntoItems = (route, perKm = 1) => {
  return [
    {
      name: `${route.distance}km`,
      quantity: 1,
      price: perKm * route.distance,
    },
  ];
};

export const uploadBudgetItemReceipts = async (
  budgetId,
  budgetItemId,
  uploadedReceipts
) => {
  if (!uploadedReceipts) return [];

  const storeResponse = await appFunctions.storeReceipts({
    files: uploadedReceipts,
    budgetId,
    budgetItemId,
    onUploadProgress: (e) => {
      console.debug((e.loaded / e.total) * 100);
    },
  });

  const receipts = storeResponse.data || [];

  return receipts;
};

export const tagItems = (items = []) =>
  items.map((item) => ({
    id: md5(item.name),
    ...item,
  }));

export const formatUserValue = (user) => ({
  value: user.value,
  label: user.label,
  avatar: user.avatar,
});

export const formatDate = (date, input = 'YYYY-MM-DD', output = 'DD/MM/YYYY') =>
  moment(date, input).format(output);

export const getBudgetTotal = (budget) =>
  (!budget.administrative && budget.statusCode < RefundStatus.ACTIVE
    ? budget.budget
    : budget.fare?.total) || 0;

export const formatAttachmentAsReceipt = (attachments, emailId) => {
  return attachments.map(({ filename, mimeType }) => ({
    filename: `${emailId}/${filename}`,
    mimeType,
    attachment: true,
  }));
};

export const getCrucialInfoAboutCard = (card, messages, substitution = '-') => {
  switch (card.type) {
    case RefundTypes.PERSONAL_ACCOUNT: {
      if (card.pix) {
        const { bankName, accountType, accountNumber, branchCode } = card.pix;
        let accountInfo = '';

        if (bankName && accountType && accountNumber && branchCode) {
          accountInfo = `(banco ${bankName}, agência ${branchCode}, conta ${accountNumber}, ${(
            messages[`personal-account.type.${accountType}`] || ''
          ).toLowerCase()})`;
        }

        return `${card.pix.id} (${
          messages[`pix-types.${card.pix.type.toUpperCase()}`]
        }) ${accountInfo}`;
      } else substitution;
    }
    default: {
      return card.lastDigits
        ? `${messages['travel.booking.creditCard.ends-with']} ${card.lastDigits}`
        : substitution;
    }
  }
};

export function createBudgetPayload({
  passenger,
  organization,
  budget,
  parseName = false,
  administrative,
}) {
  const { uid, organizationId } = passenger;
  const { expenses = [] } = budget;

  return {
    ...budget,
    expenses,
    createdAt: moment().unix(),
    statusCode: RefundStatus.START,
    passengerInfo: getPassengerInfo(passenger),
    passengerId: uid,
    requestorId: firebaseApp.getCurrentUser().uid || '',
    organizationId,
    fare: {
      total: 0,
      currency: getOrganizationConfig(organization.config, 'currency'),
    },
    ...(parseName ? { name: replaceVariables(budget.name) } : {}),
    provider: {
      origin: administrative
        ? BudgetProviders.ADMINISTRATIVE
        : BudgetProviders.BUDGET,
    },
  };
}

export function createAdminstrativeBudgetPayload({
  passenger,
  organization,
  budget,
  parseName = false,
  payment,
}) {
  const budgetPayload = createBudgetPayload({
    passenger,
    organization,
    budget,
    parseName,
    administrative: true,
  });

  budgetPayload.payment = payment;
  budgetPayload.fare.total = payment.total;
  budgetPayload.administrative = true;

  return budgetPayload;
}

export function createPaymentPayload(paymentInfo) {
  if (!paymentInfo) throw new Error('Invalid payment info!');

  const { type: typeObj, total, info, description = '' } = paymentInfo;
  const type = typeObj.value;

  const payload = {
    type,
    total,
  };

  if (type === ADMINISTRATIVE_BUDGET_TYPES.PIX) {
    const { credit_party, raw } = info;
    const { bank_ispb, document, name } = credit_party;

    payload.info = {
      description,
      bankName: pixBanks[bank_ispb].name || '',
      bankIspb: bank_ispb,
      dict: raw.rawKey,
      dictType: raw.type,
      owner: name,
      ownerDocument: document,
    };
  }

  if (type === ADMINISTRATIVE_BUDGET_TYPES.BOLETO) {
    const {
      amount,
      barcode,
      due_date,
      issuer,
      max_amount,
      min_amount,
      payee,
      payer_document,
      updated_amount,
      final_payment_date,
      id,
    } = info;

    payload.info = {
      amount,
      barcode,
      dueDate: due_date,
      issuer,
      maxAmount: max_amount,
      minAmount: min_amount,
      payee,
      payerDocument: payer_document,
      updatedAmount: updated_amount,
      finalPaymentDate: final_payment_date,
      swapId: id,
    };
  }

  return payload;
}

export function getBudgetStatusMessages(budget) {
  return budget.administrative
    ? AdminstrativeBudgetStatusMessages
    : BudgetStatusMessages;
}
