import { useState } from 'react';
import firebaseApp from '../services/Firebase';
import { formatToLabelValueObject } from '../utils/others';
import useDataLoaderWithArchive from './useDataLoaderWithArchive';

function formatFunction(data = []) {
  return data
    .filter((item) => item.name)
    .map(formatToLabelValueObject)
    .sort((a, b) => a.label.localeCompare(b.label));
}

export default function useCommonInformation(
  organizationId,
  {
    shouldLoadCostCenters = true,
    shouldLoadProjects = true,
    shouldLoadMotives = true,
    shouldLoadTags = true,
    initialLoading = true,
  } = {}
) {
  const [timestamp, setTimestamp] = useState(() => new Date().getTime());

  const [costCenters, setCostCenter, loadingCostCenters] =
    useDataLoaderWithArchive(
      {
        fetchFunction: () =>
          firebaseApp.getCostCentersFromOrganization(organizationId),
        formatFunction,
        archiveKey: 'costCenters',
        shouldLoad: shouldLoadCostCenters,
        initialLoading,
      },
      [timestamp]
    );

  const [projects, setProjects, loadingProjects] = useDataLoaderWithArchive(
    {
      fetchFunction: () =>
        firebaseApp.getProjectsFromOrganization(organizationId),
      formatFunction,
      archiveKey: 'projects',
      shouldLoad: shouldLoadProjects,
      initialLoading,
    },
    [timestamp]
  );

  const [motives, setMotives, loadingMotives] = useDataLoaderWithArchive(
    {
      fetchFunction: () =>
        firebaseApp.getMotivesFromOrganization(organizationId),
      formatFunction,
      archiveKey: 'motives',
      shouldLoad: shouldLoadMotives,
      initialLoading,
    },
    [timestamp]
  );

  const [tags, setTags, loadingTags] = useDataLoaderWithArchive(
    {
      fetchFunction: () => firebaseApp.getTagsFromOrganization(organizationId),
      formatFunction,
      archiveKey: 'tags',
      shouldLoad: shouldLoadTags,
      initialLoading,
    },
    [timestamp]
  );

  // Functions
  const reloadData = () => {
    setTimestamp(new Date().getTime());
  };

  return {
    reloadData,
    costCenters,
    setCostCenter,
    loadingCostCenters,
    projects,
    setProjects,
    loadingProjects,
    motives,
    setMotives,
    loadingMotives,
    tags,
    setTags,
    loadingTags,
    loadingAll:
      loadingCostCenters || loadingProjects || loadingMotives || loadingTags,
  };
}
