import React, { useMemo } from 'react';
import ContentLoader from 'react-content-loader';
import classNames from 'classnames';

export default function LoadingBlock({
  className,
  backgroundColor = '#f3f3f3',
  foregroundColor = '#ecebeb',
  width = 100,
  height = 20,
  speed = 2,
  rx = '1',
  ry = '1',
}) {
  const viewBox = useMemo(() => `0 0 ${width} ${height}`, [width, height]);

  // Render
  return (
    <div className={classNames('loading-block', className)}>
      <ContentLoader
        speed={speed}
        height={height}
        viewBox={viewBox}
        backgroundColor={backgroundColor}
        foregroundColor={foregroundColor}
      >
        <rect x="0" y="0" rx={rx} ry={ry} width="100%" height="100%" />
      </ContentLoader>
    </div>
  );
}
