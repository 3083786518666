import React, { useMemo } from 'react';
import { Label, Row } from 'reactstrap';
import IntlMessages from '../../helpers/IntlMessages';
import useDataLoader from '../../hooks/useDataLoader';
import firebaseApp from '../../services/Firebase';
import { numberToCnpj } from '../../utils/cnpj';
import { formatSuppliersAddress } from '../../utils/receipts';
import { Colxx } from '../common/CustomBootstrap';
import SimpleSpinner from '../common/SimpleSpinner';

const orSymbol = (value, symbol = '-') => value || symbol;

export default function SupplierParams({ cnpj }) {
  const [supplier, _, loading] = useDataLoader({
    fetchFunction: () => firebaseApp.getSupplierByCNPJ(cnpj).get(),
    getDataFunction: (snap) => snap.data(),
    initialDataState: null,
  });

  const { formattedCNPJ, name, cnae, address } = useMemo(() => {
    if (supplier) {
      const { ni, nomeFantasia, nomeEmpresarial, cnaePrincipal, endereco } =
        supplier;

      return {
        formattedCNPJ: numberToCnpj(ni),
        name: orSymbol(nomeFantasia || nomeEmpresarial),
        cnae: orSymbol(
          cnaePrincipal
            ? `${cnaePrincipal.codigo} - ${cnaePrincipal.descricao}`
            : null
        ),
        address: formatSuppliersAddress(endereco),
      };
    } else return {};
  }, [supplier]);

  return (
    <div className="reservation-params refund-params invoice-params px-4">
      {loading ? (
        <div className="d-flex align-items-center justify-content-center my-3">
          <SimpleSpinner style={{ width: 25, height: 25 }} />
        </div>
      ) : (
        <>
          <Row>
            <Colxx xxs="12">
              <Label className="form-group disabled search has-top-label">
                <p className="text-uppercase">{formattedCNPJ}</p>
                <IntlMessages id="invoice.supplier.cnpj" />
              </Label>
            </Colxx>
          </Row>
          <Row>
            <Colxx xxs="12">
              <Label className="form-group disabled search has-top-label">
                <p className="text-uppercase">{name}</p>
                <IntlMessages id="invoice.supplier" />
              </Label>
            </Colxx>
          </Row>
          <Row>
            <Colxx xxs="12">
              <Label className="form-group disabled search has-top-label">
                <p className="text-uppercase">{address}</p>
                <IntlMessages id="invoice.supplier.address" />
              </Label>
            </Colxx>
          </Row>
          <Row>
            <Colxx xxs="12">
              <Label className="form-group disabled search has-top-label">
                <p className="text-uppercase">{cnae}</p>
                <IntlMessages id="invoice.supplier.cnae" />
              </Label>
            </Colxx>
          </Row>
        </>
      )}
    </div>
  );
}
