import React from 'react';

import ContentLoader from 'react-content-loader';

export default function LoaderChart() {
  return (
    <ContentLoader
      speed={2}
      width={190}
      height={190}
      viewBox="0 0 190 190"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="10" y="10" rx="3" ry="3" width="170" height="170" />
    </ContentLoader>
  );
}
