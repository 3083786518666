import React from 'react';
import ContentLoader from 'react-content-loader';

export default function LoaderAnalytics() {
  return (
    <div className="w-100 d-flex flex-row justify-content-between">
      <ContentLoader
        speed={2}
        width={220}
        height={45}
        viewBox="0 0 220 45"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
      >
        <rect x="0" y="0" rx="3" ry="3" width="220" height="15" />
        <rect x="0" y="20" rx="3" ry="3" width="120" height="10" />
      </ContentLoader>
      <ContentLoader
        speed={2}
        width={30}
        height={45}
        viewBox="0 0 30 45"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
      >
        <rect x="0" y="0" rx="3" ry="3" width="30" height="30" />
      </ContentLoader>
    </div>
  );
}
