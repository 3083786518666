import React from 'react';

export default function UsageTermsView() {
  return (
    <div className="usage-terms">
      <p>
        <strong>TERMOS E CONDIÇÕES GERAIS DE USO DA PLATAFORMA PORTÃO 3</strong>
      </p>
      <p>&nbsp;</p>
      <p>Atualizado em setembro de 2022.</p>
      <p>&nbsp;</p>
      <p>
        Estes Termos e Condi&ccedil;&otilde;es Gerais de Uso da Plataforma
        Port&atilde;o 3 (&ldquo;Termos de Uso&rdquo;) regulam a
        rela&ccedil;&atilde;o contratual entre a Port&atilde;o 3 e os
        Usu&aacute;rios que acessam e utilizam a plataforma digital da
        Port&atilde;o 3 (&ldquo;Plataforma&rdquo; ou &ldquo;Plataforma
        Port&atilde;o 3&rdquo;).
      </p>
      <p>&nbsp;</p>
      <p>
        A Plataforma &eacute; disponibilizada e gerenciada
        pela&nbsp;PORT&Atilde;O 3 VIAGENS LTDA., sociedade com sede na Rua Av.
        dos Vinhedos, 21, cidade de Uberl&acirc;ndia, Estado de Minas Gerais,
        CEP 38411-159, inscrita no CNPJ/ME sob o n&ordm; 28.352.868/0001-70, por
        si ou por meio de suas Afiliadas (&ldquo;Port&atilde;o 3&rdquo; ou
        &ldquo;N&oacute;s&rdquo;).
      </p>
      <p>&nbsp;&nbsp;</p>
      <p>
        O USO DA PLATAFORMA PELOS USU&Aacute;RIOS EST&Aacute; SUJEITO &Agrave;
        SUA CONCORD&Acirc;NCIA E ACEITE A ESTES TERMOS DE USO E SEUS ANEXOS,
        JUNTAMENTE COM A POL&Iacute;TICA DE PRIVACIDADE DA PORT&Atilde;O 3, QUE
        PODE SER ENCONTRADA EM (
        <a href="https://portao3.com.br/">
          &quot;POL&Iacute;TICA DE PRIVACIDADE&quot;
        </a>
        ) E TODAS AS OUTRAS POL&Iacute;TICAS E PRINC&Iacute;PIOS QUE REGEM A
        PORT&Atilde;O 3 E QUE S&Atilde;O INCORPORADOS NESTE DOCUMENTO POR
        REFER&Ecirc;NCIA.
      </p>
      <p>&nbsp;</p>
      <p>
        Estes Termos de Uso se aplicam a todas as plataformas da Port&atilde;o
        3. Sem preju&iacute;zo, os servi&ccedil;os poder&atilde;o estar sujeitos
        a termos de contrata&ccedil;&atilde;o, acordos, pol&iacute;ticas,
        diretrizes, normas e regulamenta&ccedil;&otilde;es espec&iacute;ficas,
        que dever&atilde;o ser aceitas por ocasi&atilde;o da
        contrata&ccedil;&atilde;o ou utiliza&ccedil;&atilde;o do respectivo
        servi&ccedil;o. Salvo disposi&ccedil;&atilde;o expressa em
        contr&aacute;rio nestes Termos de Uso, quaisquer novos recursos que
        sejam implementados na Plataforma estar&atilde;o sujeitos a estes Termos
        de Uso.
      </p>
      <p>&nbsp;</p>
      <p>CL&Aacute;USULA PRIMEIRA &ndash; NATUREZA JUR&Iacute;DICA</p>
      <p>&nbsp;</p>
      <ul>
        <li>
          <p>
            O Usu&aacute;rio entende e reconhece que estes Termos de Uso e a
            Pol&iacute;tica de Privacidade t&ecirc;m a natureza jur&iacute;dica
            de um contrato e concorda que o aceite implicar&aacute; na
            vincula&ccedil;&atilde;o do Usu&aacute;rio aos seus termos e
            condi&ccedil;&otilde;es. Caso n&atilde;o concorde com estes Termos
            de Uso ou com a Pol&iacute;tica de Privacidade, o Usu&aacute;rio
            deve abster-se de se cadastrar e utilizar a Plataforma.
          </p>
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li>
          <p>
            O Usu&aacute;rio declara ter lido e compreendido todas as
            condi&ccedil;&otilde;es estabelecidas na Pol&iacute;tica de
            Privacidade e nestes Termos de Uso, e declara sua concord&acirc;ncia
            e aceita&ccedil;&atilde;o no momento de cadastro e/ou
            realiza&ccedil;&atilde;o de solicita&ccedil;&atilde;o pela
            &nbsp;Plataforma. Caso o Usu&aacute;rio tenha qualquer d&uacute;vida
            sobre estes Termos de Uso e/ou sobre a Pol&iacute;tica de
            Privacidade, recomendamos que entre em contato com a Port&atilde;o 3
            antes de realizar seu aceite, por meio dos canais de atendimento ao
            Usu&aacute;rio ou do e-mail oi@portao3.com.br. Teremos a
            satisfa&ccedil;&atilde;o de esclarecer-lhe eventuais
            d&uacute;vidas.&nbsp;
          </p>
        </li>
      </ul>
      <p>
        <br />
      </p>
      <ul>
        <li>
          <p>
            QUALQUER PESSOA QUE N&Atilde;O ACEITE OU N&Atilde;O CONCORDE COM
            ESTES TERMOS DE USO OU COM A POL&Iacute;TICA DE PRIVACIDADE DEVE SE
            ABSTER DE SE CADASTRAR, ACESSAR OU UTILIZAR A PLATAFORMA.
          </p>
        </li>
      </ul>
      <p>&nbsp;</p>
      <p>CL&Aacute;USULA SEGUNDA &ndash; DEFINI&Ccedil;&Otilde;ES</p>
      <p>&nbsp;</p>
      <p>
        Os termos abaixo utilizados nestes Termos de Uso ter&atilde;o o seguinte
        significado:
      </p>
      <p>&nbsp;</p>
      <ul>
        <li>
          <p>
            &ldquo;Usu&aacute;rio&rdquo;. Qualquer pessoa f&iacute;sica ou
            jur&iacute;dica que acesse a Plataforma e efetue o cadastro como
            Usu&aacute;rio Gestor ou Usu&aacute;rio ColaboradorCliente.
          </p>
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li>
          <p>
            &ldquo;Usu&aacute;rio Gestor&rdquo;. Qualquer pessoa jur&iacute;dica
            de direito privado ou pessoa f&iacute;sica que se cadastra na
            Plataforma em nome de uma pessoa jur&iacute;dica, a partir da
            Ades&atilde;o a estes Termos de Uso e &agrave; Pol&iacute;tica de
            Privacidade, para usufruir das solu&ccedil;&otilde;es corporativas
            de gest&atilde;o de viagens, gest&atilde;o de despesas e
            otimiza&ccedil;&atilde;o dos processos de pagamento a partir dos
            Servi&ccedil;os prestados pela Port&atilde;o 3.
          </p>
        </li>
      </ul>
      <p>
        <br />{' '}
      </p>
      <ul>
        <li>
          <p>
            &ldquo;Usu&aacute;rio Colaborador&rdquo;. Qualquer pessoa
            f&iacute;sica vinculada a um Usu&aacute;rio Gestor enquanto
            funcion&aacute;ria, prestadora de servi&ccedil;os ou colaboradora de
            qualquer natureza, que se cadastra na Plataforma, a partir da
            Ades&atilde;o a estes Termos de Uso e &agrave; Pol&iacute;tica de
            Privacidade, para usufruir dos Servi&ccedil;os associados ao
            referido Usu&aacute;rio Gestor.
          </p>
        </li>
      </ul>
      <p>
        <br />{' '}
      </p>
      <ul>
        <li>
          <p>
            &ldquo;Servi&ccedil;o&rdquo;: Outorga do direito de uso da
            Plataforma aos Usu&aacute;rios, de forma n&atilde;o exclusiva,
            limitada, tempor&aacute;ria, revog&aacute;vel e
            intransfer&iacute;vel no formato &ldquo;as a service&quot;, para o
            oferecimento de solu&ccedil;&otilde;es corporativas de gest&atilde;o
            de viagens, gest&atilde;o de despesas gerais e de mobilidade urbana,
            automatiza&ccedil;&atilde;o da gest&atilde;o de cupons fiscais e
            otimiza&ccedil;&atilde;o dos processos de pagamento entre os
            Usu&aacute;rios Gestores, os Usu&aacute;rios Colaboradores e os
            Estabelecimentos a partir da emiss&atilde;o de cart&otilde;es
            pr&eacute;-pagos e tags de ped&aacute;gio e entradas em
            estacionamentos.&nbsp;
          </p>
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li>
          <p>
            &ldquo;Chargeback&rdquo;: &eacute; o procedimento de
            contesta&ccedil;&atilde;o de d&eacute;bito por meio do qual o
            Usu&aacute;rio declara n&atilde;o reconhecer uma despesa efetuada
            com seu Cart&atilde;o Corporativo. Uma vez aceito o&nbsp;Chargeback,
            os valores contestados ser&atilde;o devolvidos ao Usu&aacute;rio,
            nos termos estabelecidos pela Bandeira e pela Parceira.
          </p>
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li>
          <p>
            &ldquo;Dados Pessoais&rdquo;. Dados que identifiquem ou tornem
            identific&aacute;vel uma pessoa natural.
          </p>
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li>
          <p>
            &ldquo;Estabelecimentos&rdquo; Estabelecimentos comerciais que
            oferecem seus produtos por interm&eacute;dio da Plataforma,
            incluindo, mas n&atilde;o se limitando a companhias a&eacute;reas,
            locadoras,&nbsp;co-workings, companhias de &ocirc;nibus,
            hot&eacute;is, etc.
          </p>
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li>
          <p>
            &ldquo;Legisla&ccedil;&atilde;o de Prote&ccedil;&atilde;o de
            Dados&rdquo;. Enquanto permanecer em vigor, a Lei n&ordm;
            13.709/2018 (&ldquo;Lei Geral de Prote&ccedil;&atilde;o de Dados
            Pessoais&rdquo; ou &ldquo;LGPD&rdquo;), suas
            altera&ccedil;&otilde;es posteriores, e quaisquer outras leis e
            regulamentos em rela&ccedil;&atilde;o ao Tratamento,
            prote&ccedil;&atilde;o e privacidade de Dados Pessoais que sejam
            aplic&aacute;veis e, se aplic&aacute;veis, todas as
            orienta&ccedil;&otilde;es, normas, regras, portarias, regulamentos e
            c&oacute;digos de pr&aacute;tica e conduta emitidos pela Autoridade
            Nacional de Prote&ccedil;&atilde;o de Dados (&ldquo;ANPD&rdquo;) ou
            outra autoridade de supervis&atilde;o ou prote&ccedil;&atilde;o de
            Dados Pessoais pertinente.
          </p>
        </li>
      </ul>
      <p>
        <br />{' '}
      </p>
      <ul>
        <li>
          <p>
            &ldquo;Parceira&rdquo; &eacute; a institui&ccedil;&atilde;o de
            pagamento integrante do Sistema de Pagamentos Brasileiros (SPB),
            parceira da Port&atilde;o 3, respons&aacute;vel pela abertura das
            contas e pela emiss&atilde;o dos cart&otilde;es, dispondo das
            prerrogativas legais para atua&ccedil;&atilde;o.
          </p>
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li>
          <p>
            &ldquo;Plataforma Port&atilde;o 3&rdquo; ou
            &ldquo;Plataforma&rdquo;. Plataforma virtual, acess&iacute;vel via
            web ou aplicativo, cujo direito de acesso e uso &eacute;
            disponibilizado aos Usu&aacute;rios na modalidade &ldquo;as a
            service&rdquo; para a presta&ccedil;&atilde;o dos Servi&ccedil;os.
          </p>
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li>
          <p>
            &ldquo;Pol&iacute;tica de Privacidade&rdquo;. &Eacute; a
            Pol&iacute;tica de Privacidade da Plataforma Port&atilde;o 3 que
            regula o Tratamento de Dados Pessoais pela Port&atilde;o 3. A
            Pol&iacute;tica de Privacidade est&aacute; dispon&iacute;vel por
            meio do&nbsp;<a href="https://portao3.com.br/">link</a>.
          </p>
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li>
          <p>
            &ldquo;Legisla&ccedil;&atilde;o de Defesa do Consumidor&rdquo;.
            S&atilde;o todas as leis, normas, regulamentos, requisitos e
            diretrizes relacionados &agrave; prote&ccedil;&atilde;o do
            consumidor aplic&aacute;veis aos Usu&aacute;rios, incluindo, mas
            n&atilde;o se limitando &agrave; Lei n&ordm; 8.078/1990
            (&ldquo;C&oacute;digo de Defesa do Consumidor&rdquo; ou
            &ldquo;CDC&rdquo;).
          </p>
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li>
          <p>
            &ldquo;Tratamento&rdquo;. &Eacute; qualquer opera&ccedil;&atilde;o
            ou conjunto de opera&ccedil;&otilde;es realizadas com Dados
            Pessoais, seja por meios automatizados ou n&atilde;o, tais como, mas
            n&atilde;o se limitando a coleta, armazenamento, uso, acesso,
            organiza&ccedil;&atilde;o, consulta, produ&ccedil;&atilde;o,
            altera&ccedil;&atilde;o, recep&ccedil;&atilde;o,
            classifica&ccedil;&atilde;o, utiliza&ccedil;&atilde;o,
            reprodu&ccedil;&atilde;o, comunica&ccedil;&atilde;o,
            transmiss&atilde;o, circula&ccedil;&atilde;o,
            distribui&ccedil;&atilde;o, processamento, registro,
            estrutura&ccedil;&atilde;o, adapta&ccedil;&atilde;o,
            recupera&ccedil;&atilde;o, transfer&ecirc;ncia,
            disponibiliza&ccedil;&atilde;o, combina&ccedil;&atilde;o,
            restri&ccedil;&atilde;o, avalia&ccedil;&atilde;o, controle,
            extra&ccedil;&atilde;o, elimina&ccedil;&atilde;o ou exclus&atilde;o.
          </p>
        </li>
      </ul>
      <p>&nbsp;&nbsp;</p>
      <ul>
        <li>
          <p>
            &ldquo;Afiliadas&rdquo;. Qualquer pessoa, organiza&ccedil;&atilde;o
            ou sociedade controladora, controlada ou sob controle comum de uma
            das Partes.
          </p>
        </li>
      </ul>
      <p>&nbsp;&nbsp;</p>
      <p>CL&Aacute;USULA TERCEIRA &ndash; SOBRE A PLATAFORMA</p>
      <p>&nbsp;</p>
      <p>
        A Port&atilde;o 3 nasceu em 2020 com a proposta de revolucionar a
        gest&atilde;o de processos nas empresas, seja ela pequena, m&eacute;dia
        ou grande, de qualquer lugar do mundo. A Plataforma da Port&atilde;o 3
        &eacute; uma ferramenta tecnol&oacute;gica que visa trazer
        solu&ccedil;&otilde;es que simplificam o dia a dia no ambiente
        corporativo, oferecendo solu&ccedil;&otilde;es de gest&atilde;o de
        viagens, gest&atilde;o de despesas corporativas
        e&nbsp;otimiza&ccedil;&atilde;o dos processos de pagamento entre as
        empresas e seus funcion&aacute;rios, prestadores de servi&ccedil;os ou
        colaboradores de qualquer natureza.
      </p>
      <p>&nbsp;</p>
      <p>Gest&atilde;o de Viagens&nbsp;</p>
      <p>&nbsp;</p>
      <ul>
        <li>
          <p>
            A Plataforma disponibiliza um sistema completo com
            relat&oacute;rios, reservas de passagens de avi&atilde;o e de
            &ocirc;nibus, di&aacute;rias em hot&eacute;is, loca&ccedil;&atilde;o
            de escrit&oacute;rios, carros e&nbsp;co-workings&nbsp;e
            contrata&ccedil;&atilde;o de seguros. Isso &eacute; feito por meio
            de plano,s Gold, Black e Platinum, por meio dos quais os
            Usu&aacute;rios, a depender do plano selecionado pelo Usu&aacute;rio
            Gestor, podem usufruir das funcionalidades da Plataforma e verificar
            hist&oacute;rico de viagens, tarifas dispon&iacute;veis, BI para
            an&aacute;lise de relat&oacute;rios, pol&iacute;ticas de
            aprova&ccedil;&atilde;o, tela de viagens ativas, pol&iacute;ticas
            (centro de custo, projetos), incluir acordos, conferir e realizar
            reservas, organizar cr&eacute;ditos e reembolsos dos produtos
            tur&iacute;sticos e conferir informa&ccedil;&otilde;es de
            neutraliza&ccedil;&atilde;o de carbono.&nbsp;
          </p>
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li>
          <p>
            Al&eacute;m disso, tamb&eacute;m a depender do plano selecionado, a
            Plataforma disponibiliza assist&ecirc;ncia e atendimento
            personalizado aos Usu&aacute;rios, que poder&atilde;o tirar suas
            d&uacute;vidas e solicitar aux&iacute;lio na resolu&ccedil;&atilde;o
            de quest&otilde;es relacionadas &agrave; presta&ccedil;&atilde;o dos
            Servi&ccedil;os e ao uso da Plataforma. A Port&atilde;o 3 n&atilde;o
            garante, contudo, que conseguir&aacute; atender todas as
            solicita&ccedil;&otilde;es e resolver todos os problemas relatados
            pelos Usu&aacute;rios, em especial quando relacionados aos
            Servi&ccedil;os de Terceiros, sobre os quais a Port&atilde;o 3
            n&atilde;o tem diretaqualquer responsabilidade.&nbsp;
          </p>
        </li>
      </ul>
      <p>&nbsp;&nbsp;</p>
      <ul>
        <li>
          <p>
            A Plataforma poder&aacute; incluir a oferta de produtos e
            servi&ccedil;os dos Estabelecimentos, os quais ser&atilde;o
            oferecidos de acordo com termos e condi&ccedil;&otilde;es
            determinados por tais Estabelecimentos e sob responsabilidade
            exclusiva destes (&ldquo;Servi&ccedil;os de Terceiros&rdquo;).
            &nbsp;O Usu&aacute;rio declara-se ciente e concorda que a
            Port&atilde;o 3 apenas disponibiliza a Plataforma para aproximar os
            Usu&aacute;rio de companhias a&eacute;reas,
            locadoras,&nbsp;co-workings, companhias de &ocirc;nibus,
            hot&eacute;is e demais Estabelecimentos, possibilitando que os
            Usu&aacute;rios consultem e solicitem reservas. Assim, a
            Port&atilde;o 3 n&atilde;o tem qualquer participa&ccedil;&atilde;o,
            inger&ecirc;ncia ou responsabilidade sobre a rela&ccedil;&atilde;o
            estabelecida entre os Usu&aacute;rios e os Estabelecimentos,
            incluindo, mas n&atilde;o se limitando &agrave; qualquera qualquer
            responsabilidade pela qualidade dos produtos e servi&ccedil;os, a
            pontualidade de voos, altera&ccedil;&atilde;o de malha a&eacute;rea,
            a correspond&ecirc;ncia entre as imagens do site e o local em si,
            entre outros.
          </p>
        </li>
      </ul>
      <p>&nbsp;</p>
      <p>Gest&atilde;o de Despesas Corporativas</p>
      <p>&nbsp;</p>
      <ul>
        <li>
          <p>
            A Plataforma da Port&atilde;o 3 tamb&eacute;m disponibiliza um
            sistema de centraliza&ccedil;&atilde;o de despesas corporativas de
            uma forma simples e r&aacute;pida, unificando todos os processos de
            pagamentosreembolsos, aprova&ccedil;&otilde;es e controle do fluxo
            financeiro em um s&oacute; lugar. Ao possibilitar a emiss&atilde;o
            de relat&oacute;rios em tempo real, a Plataforma permite que os
            Usu&aacute;rios tenham total visualiza&ccedil;&atilde;o das despesas
            com a&eacute;reos, hospedagem, aluguel de carros, cart&otilde;es de
            cr&eacute;dito corporativos pr&oacute;prios. e reembolsos.
          </p>
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li>
          <p>
            A Plataforma tamb&eacute;m oferece sistemas de
            automatiza&ccedil;&atilde;o da leitura de notas e cupons fiscais,
            facilitando o controle dos gastos e a preven&ccedil;&atilde;o a
            fraudes, al&eacute;m de automatizar e sincronizar, a partir das
            prefer&ecirc;ncias do Usu&aacute;rio Gestor, o fluxo de recebimento,
            aprova&ccedil;&atilde;o e pagamento de notas.
          </p>
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li>
          <p>
            Caber&aacute; aos Usu&aacute;rios organizar os processos de captura
            das notas, recibos e vouchers, submet&ecirc;-los na Plataforma para
            aprova&ccedil;&atilde;o do Usu&aacute;rio Gestor e realizar os
            pagamentos das despesas corporativas. A Port&atilde;o 3 n&atilde;o
            se responsabiliza, direta ou indiretamente, pelo n&atilde;o
            pagamento de quaisquer despesas.
          </p>
        </li>
      </ul>
      <p>&nbsp;</p>
      <p>Otimiza&ccedil;&atilde;o de Processos de Pagamento</p>
      <p>
        <br />{' '}
      </p>
      <ul>
        <li>
          <p>
            A Port&atilde;o 3 oferece, diretamente ou por meio da Parceira, uma
            s&eacute;rie de ferramentas para gerir e facilitar a
            realiza&ccedil;&atilde;o de pagamentos pelos Usu&aacute;rios.
            S&atilde;o eles:&nbsp;
          </p>
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li>
          <p>
            Cart&atilde;o Virtual &ndash; cart&otilde;es de cr&eacute;dito
            virtuais pr&eacute;-pagos ou p&oacute;s-pagos, cujo limite de
            gastos, bloqueio e desbloqueio podem ser estabelecidos pelo
            Usu&aacute;rio Gestor. Seu uso &eacute; exclusivo para o mundo
            digital;
          </p>
        </li>
        <li>
          <p>
            Cart&atilde;o Business &ndash; cart&otilde;es de cr&eacute;dito
            f&iacute;sicos pr&eacute;-pagos ou p&oacute;s-pagos, cujo limite de
            gastos, bloqueio e desbloqueio tamb&eacute;m podem ser estabelecidos
            pelo Usu&aacute;rio Gestor. Diferentemente dos Cart&otilde;es
            Virtuais, s&atilde;o livremente aceitos em maquininhas em
            territ&oacute;rio nacional e internacional. &Eacute; permitido ao
            Usu&aacute;rio Gestor adquirir servi&ccedil;os adicionais ao
            cart&atilde;o business, como: seguro viagem internacional, acesso a
            salas VIPs, chip de telefonia para uso no exterior, entre outros;
          </p>
        </li>
        <li>
          <p>
            Cart&atilde;o Commander &ndash; cart&otilde;es de cr&eacute;dito
            f&iacute;sicos pr&eacute;-pagos, cujo limite de gastos, bloqueio e
            desbloqueio tamb&eacute;m podem ser estabelecidos pelo
            Usu&aacute;rio Gestor. Diferencia-se do Cart&atilde;o Business por
            se destinar aos Usu&aacute;rios Colaboradores que ocupam cargos de
            lideran&ccedil;a, oferecendo-lhes benef&iacute;cios exclusivos como
            seguros de viagem internacionais, acesso a salas VIPs em aeroportos
            e chips internacionais de viagem.
          </p>
        </li>
        <li>
          <p>
            Tag Ped&aacute;gio &ndash; ferramenta pr&eacute;-paga ou
            p&oacute;s-paga voltada a centralizar o pagamento de ped&aacute;gios
            e entradas em estacionamentos pelos Usu&aacute;rios Colaboradores. A
            Tag Ped&aacute;gio, uma vez instalada nos ve&iacute;culos dos
            Usu&aacute;rios Colaboradores, faz um controle dos pontos de
            ped&aacute;gio utilizados pelo ve&iacute;culo, assim como o trajeto
            realizado e o hor&aacute;rio em cada ponto.a quilometragem rodada e
            oferece uma sugest&atilde;o de reembolso a ser pago pelos
            Usu&aacute;rios Gestores com base na referida
            informa&ccedil;&atilde;o.
          </p>
        </li>
      </ul>
      <p>
        <br />{' '}
      </p>
      <ul>
        <li>
          <p>
            Todos os gastos realizados no Cart&atilde;o Virtual, no
            Cart&atilde;o Business, no Cart&atilde;o Commander (coletivamente,
            os &ldquo;Cart&otilde;es Coorporativos&rdquo;) e na Tag
            Ped&aacute;gio podem ser acompanhados em tempo real por meio de
            relat&oacute;rios emitidos instantaneamente na Plataforma.
          </p>
        </li>
      </ul>
      <p>
        <br />{' '}
      </p>
      <ul>
        <li>
          <p>
            Os Cart&otilde;es Corporativos e a Tag Ped&aacute;gio podem ser
            oferecidos pela Port&atilde;o 3 diretamente ou por meio da Parceira,
            de forma a oferecer a movimenta&ccedil;&atilde;o de recursos
            financeiros e a realiza&ccedil;&atilde;o de transa&ccedil;&otilde;es
            de pagamento na esfera corporativa. Diversos Cart&otilde;es
            Corporativos podem ser emitidos em cada uma das suas tr&ecirc;s
            modalidades, a depender do plano contratado pelos Usu&aacute;rios
            Gestores.
          </p>
        </li>
      </ul>
      <p>
        <br />{' '}
      </p>
      <ul>
        <li>
          <p>
            Os Usu&aacute;rios s&atilde;o integralmente respons&aacute;veis
            pelos dados dos Cart&otilde;es Corporativos e t&ecirc;m
            ci&ecirc;ncia que n&atilde;o dever&atilde;o
            compartilh&aacute;-los.&nbsp;
          </p>
        </li>
      </ul>
      <p>
        <br />{' '}
      </p>
      <ul>
        <li>
          <p>
            Em caso de perda, extravio, furto ou roubo do cart&atilde;o, os
            Usu&aacute;rios devem imediatamente entrar em contato com qualquer
            um dos canais de comunica&ccedil;&atilde;o da Port&atilde;o 3.
            Ap&oacute;s seu contato, o uso e acesso da conta poder&atilde;o ser
            temporariamente bloqueados, at&eacute; que o Usu&aacute;rio receba
            novo login e senha. Novos Cart&otilde;es Corporativos ser&atilde;o
            preparados e entregues nos prazos informados pelos canais de
            comunica&ccedil;&atilde;o. Poder&atilde;o ser cobradas tarifas
            adicionais para a emiss&atilde;o de novos cart&otilde;es.
          </p>
        </li>
      </ul>
      <p>
        <br />{' '}
      </p>
      <ul>
        <li>
          <p>
            Caso alguma opera&ccedil;&atilde;o com o Cart&atilde;o Corporativo
            n&atilde;o seja reconhecida pelo Usu&aacute;rio, &eacute; preciso
            seguir as orienta&ccedil;&otilde;es para realiza&ccedil;&atilde;o do
            procedimento de&nbsp;Chargeback,&nbsp;em at&eacute; 90 dias
            corridos. O procedimento e a documenta&ccedil;&atilde;o exigida para
            o&nbsp;Chargeback&nbsp;seguir&atilde;o as regras estabelecidas pela
            Bandeira e pela Parceira, de forma que a aprova&ccedil;&atilde;o
            do&nbsp;Chargeback&nbsp;s&oacute; ser&aacute; efetuada quando
            comprovado o erro ou desacordo comercial.&nbsp;
          </p>
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li>
          <p>
            Para garantir a qualidade e o sucesso da Plataforma, novas
            funcionalidades podem ser inclu&iacute;das em
            atualiza&ccedil;&otilde;es e novas vers&otilde;es da Plataforma,
            assim como funcionalidades hoje existentes podem deixar de existir
            e/ou sofrer limita&ccedil;&otilde;es. A Port&atilde;o 3 n&atilde;o
            se compromete a manter ativa qualquer ferramenta, funcionalidade ou
            servi&ccedil;o oferecido atualmente ou que j&aacute; tenha sido
            oferecido no passado, independentemente de ter sido oferecido de
            forma onerosa ou gratuita, pela Port&atilde;o 3 ou pela Parceira.
          </p>
        </li>
      </ul>
      <p>
        <br />{' '}
      </p>
      <ul>
        <li>
          <p>
            Ao aceitar estes Termos de Uso, o Usu&aacute;rio outorga mandato
            &agrave; Port&atilde;o 3, constituindo-a como seu fiel procurador,
            nos termos dos artigos 653 e seguintes do C&oacute;digo Civil, para
            o fim de efetuar, em conjunto com a Parceira, conforme
            aplic&aacute;vel, ordens de pagamento, transfer&ecirc;ncias,
            resgates de recursos, opera&ccedil;&otilde;es de desconto de
            receb&iacute;veis, pagamento de taxas e tributos,
            negocia&ccedil;&otilde;es, repasses e/ou quaisquer outros
            neg&oacute;cios jur&iacute;dicos decorrentes de, necess&aacute;rios
            ou convenientes &agrave; execu&ccedil;&atilde;o dos Servi&ccedil;os
            objeto destes Termos de Uso.&nbsp;O mandato previsto nesta
            cl&aacute;usula &eacute; outorgado como condi&ccedil;&atilde;o do
            neg&oacute;cio, na forma do artigo 684 e do par&aacute;grafo
            &uacute;nico do artigo 686 do C&oacute;digo Civil.
          </p>
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li>
          <p>
            Para usar a Plataforma e gozar dos Servi&ccedil;os prestados pela
            Port&atilde;o 3, os Usu&aacute;rios dever&atilde;o ler atentamente e
            concordar com o teor do Anexo [●] destes Termos de Uso, em que
            est&atilde;o detalhadas as condi&ccedil;&otilde;es para a
            presta&ccedil;&atilde;o de servi&ccedil;os financeiros pela
            Port&atilde;o 3 e/ou por sua Parceira, conforme
            aplic&aacute;vel.&nbsp;
          </p>
        </li>
      </ul>
      <p>&nbsp;</p>
      <p>
        CL&Aacute;USULA QUARTA &ndash; REQUISITOS T&Eacute;CNICOS PARA USO DA
        PLATAFORMA&nbsp;
      </p>
      <p>&nbsp;</p>
      <ul>
        <li>
          <p>
            O uso da Plataforma depender&aacute; necessariamente de equipamentos
            e dispositivos conectados a uma rede internet, que dever&atilde;o
            ser independentemente obtidos e contratados pelo Usu&aacute;rio. O
            acesso e a utiliza&ccedil;&atilde;o da Plataforma podem ser
            prejudicados em raz&atilde;o da vers&atilde;o do sistema operacional
            do dispositivo do Usu&aacute;rio ou das pr&oacute;prias
            condi&ccedil;&otilde;es t&eacute;cnicas dos dispositivos que o
            Usu&aacute;rio utiliza. A adequa&ccedil;&atilde;o e
            atualiza&ccedil;&atilde;o de dispositivos, navegadores ou
            vers&otilde;es de sistemas operacionais para o funcionamento da
            Plataforma &eacute; responsabilidade do Usu&aacute;rio. A
            Port&atilde;o 3 n&atilde;o se responsabiliza por eventuais danos
            sofridos em raz&atilde;o da descontinua&ccedil;&atilde;o e/ou
            altera&ccedil;&atilde;o de vers&otilde;es antigas da Plataforma.
            Dessa forma, recomendamos que o Usu&aacute;rio acesse a Plataforma
            com frequ&ecirc;ncia para verificar as novas
            atualiza&ccedil;&otilde;es.
          </p>
        </li>
      </ul>
      <p>&nbsp;</p>
      <p>CL&Aacute;USULA QUINTA &ndash; ACESSO E USO DA PLATAFORMA</p>
      <p>&nbsp;</p>
      <ul>
        <li>
          <p>
            O acesso &agrave; Plataforma &eacute; pago pelo Usu&aacute;rio
            Gestor, seguindo os valores do plano escolhido (Gold, Black ou
            Platinum), e requer cadastro de todos os Usu&aacute;rios.
          </p>
        </li>
      </ul>
      <p>&nbsp;&nbsp;</p>
      <ul>
        <li>
          <p>
            Para realizar solicita&ccedil;&otilde;es, o Usu&aacute;rio
            precisar&aacute; preencher todos os campos do formul&aacute;rio com
            dados v&aacute;lidos, os quais ficar&atilde;o sujeitos &agrave;
            Pol&iacute;tica de Privacidade. O Usu&aacute;rio ser&aacute;
            respons&aacute;vel por checar se todos os seus dados fornecidos no
            formul&aacute;rio de solicita&ccedil;&atilde;o est&atilde;o
            corretos, mesmo no caso de preenchimento autom&aacute;tico de dados
            pela Port&atilde;o 3. Ao preencher formul&aacute;rios, o
            Usu&aacute;rio garante a exatid&atilde;o, clareza, autenticidade,
            legalidade, validade e atualiza&ccedil;&atilde;o das
            informa&ccedil;&otilde;es fornecidas.
          </p>
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li>
          <p>
            A Port&atilde;o 3 n&atilde;o ser&aacute; obrigada a policiar ou
            fiscalizar os dados do Usu&aacute;rio ou quaisquer
            informa&ccedil;&otilde;es adicionais fornecidas pelo Usu&aacute;rio,
            mas poder&aacute;, ao seu exclusivo crit&eacute;rio, excluir ou
            questionar os dados que lhe pare&ccedil;am inver&iacute;dicos. A
            Port&atilde;o 3 se reserva o direito de utilizar todos os meios
            v&aacute;lidos e poss&iacute;veis para confirmar a identidade do
            Usu&aacute;rio e verificar o cumprimento dos requisitos para uso da
            Plataforma, embora n&atilde;o tenha qualquer obriga&ccedil;&atilde;o
            de faz&ecirc;-lo.
          </p>
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li>
          <p>
            A Port&atilde;o 3 reserva-se a possibilidade de bloquear o
            Usu&aacute;rio e o acesso ao seu Cart&atilde;o Corporativo, por
            motivos de seguran&ccedil;a, em caso de suspeita de fraude, ato
            il&iacute;cito, utiliza&ccedil;&atilde;o de dados de terceiros, ou
            qualquer outra viola&ccedil;&atilde;o dos presentes Termos de Uso,
            da Pol&iacute;tica de Privacidade ou da legisla&ccedil;&atilde;o
            aplic&aacute;vel, sem a necessidade de qualquer prova ou aviso
            pr&eacute;vio ao Usu&aacute;rio.
          </p>
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li>
          <p>
            A Port&atilde;o 3 pode rejeitar e/ou cancelar qualquer
            solicita&ccedil;&atilde;o do Usu&aacute;rio, no caso de identificar
            uma viola&ccedil;&atilde;o destes Termos de Uso da Pol&iacute;tica
            de Privacidade ou da legisla&ccedil;&atilde;o aplic&aacute;vel,
            mediante simples notifica&ccedil;&atilde;o ao Usu&aacute;rio, sem a
            necessidade de qualquer prova.
          </p>
        </li>
      </ul>
      <p>&nbsp;</p>
      <p>CL&Aacute;USULA S&Eacute;TIMA &ndash; USO DA PLATAFORMA</p>
      <p>&nbsp;</p>
      <ul>
        <li>
          <p>
            O Usu&aacute;rio concorda em fazer uso adequado e l&iacute;cito da
            Plataforma, de acordo com a legisla&ccedil;&atilde;o
            aplic&aacute;vel, com estes Termos de Uso e com a Pol&iacute;tica de
            Privacidade. Assim, o Usu&aacute;rio concorda em:
          </p>
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li>
          <p>
            Cumprir com toda a legisla&ccedil;&atilde;o aplic&aacute;vel e
            n&atilde;o usar a Plataforma para fins ou efeitos ilegais que sejam
            contr&aacute;rios &agrave; legisla&ccedil;&atilde;o
            aplic&aacute;vel, a estes Termos de Uso ou &agrave; Pol&iacute;tica
            de Privacidade, que sejam prejudiciais aos interesses ou direitos da
            Port&atilde;o 3, de outros Usu&aacute;rios ou de terceiros, ou que
            de qualquer forma possam prejudicar, desabilitar ou deteriorar a
            Plataforma ou impedir o uso normal da Plataforma por outros
            Usu&aacute;rios;
          </p>
        </li>
        <li>
          <p>
            N&atilde;o destruir, alterar, desabilitar ou, de qualquer outra
            forma, danificar os dados, programas ou documentos eletr&ocirc;nicos
            e outros dispon&iacute;veis na Plataforma;
          </p>
        </li>
        <li>
          <p>
            N&atilde;o penetrar ou testar a vulnerabilidade da Plataforma ou de
            qualquer sistema ou rede social da Port&atilde;o 3, bem como
            n&atilde;o quebrar, ou tentar quebrar, suas medidas de
            seguran&ccedil;a ou autentica&ccedil;&atilde;o;
          </p>
        </li>
        <li>
          <p>
            N&atilde;o utilizar a Plataforma para, a t&iacute;tulo de
            refer&ecirc;ncia, mas n&atilde;o se limitando a, enviar e-mails em
            massa (spam) ou e-mails com conte&uacute;do ilegal;
          </p>
        </li>
        <li>
          <p>
            N&atilde;o replicar informa&ccedil;&otilde;es para fins comerciais
            sem consentimento pr&eacute;vio;
          </p>
        </li>
        <li>
          <p>
            Respeitar os direitos autorais e a propriedade intelectual da
            Port&atilde;o 3, de outros Usu&aacute;rios e de terceiros, e
            n&atilde;o se apropriar de propriedade intelectual ou
            conte&uacute;do da Port&atilde;o 3 ou de outros Usu&aacute;rios, por
            meio de qualquer ferramenta ou mecanismo;
          </p>
        </li>
        <li>
          <p>
            Revisar periodicamente as atualiza&ccedil;&otilde;es destes Termos
            de Uso e da Pol&iacute;tica de Privacidade;
          </p>
        </li>
        <li>
          <p>
            N&atilde;o copiar, ceder, licenciar, sublicenciar, vender, alugar,
            dar em loca&ccedil;&atilde;o ou em garantia, distribuir,
            compartilhar, reproduzir, doar, alienar de qualquer forma,
            disponibilizar, dar acesso &agrave; Plataforma ou transferir total
            ou parcialmente, sob quaisquer modalidades, gratuita ou
            onerosamente, provis&oacute;ria ou permanentemente, o software da
            Plataforma, assim como seus m&oacute;dulos, partes, manuais ou
            quaisquer informa&ccedil;&otilde;es relativas a ele, incluindo
            qualquer conte&uacute;do da Port&atilde;o 3 ou de outros
            Usu&aacute;rios;
          </p>
        </li>
        <li>
          <p>
            N&atilde;o decompilar ou promover, e/ou facilitar, qualquer
            engenharia reversa, acesso indevido, ou tentativa de engenharia
            reversa ou acesso indevido, ao c&oacute;digo fonte da Plataforma;
          </p>
        </li>
        <li>
          <p>
            N&atilde;o reproduzir, adaptar, modificar e/ou empregar, no todo ou
            em parte, para qualquer fim, a Plataforma ou qualquer
            conte&uacute;do da Port&atilde;o 3 ou de outros Usu&aacute;rios, sem
            a autoriza&ccedil;&atilde;o expressa; e
          </p>
        </li>
        <li>
          <p>
            N&atilde;o utilizar a Plataforma com o objetivo de desenvolver ou
            operar produto semelhante ou concorrente &agrave; Plataforma ou
            qualquer outro produto ou servi&ccedil;o da Port&atilde;o 3, ou
            facilitar o acesso &agrave; Plataforma por um concorrente da
            Port&atilde;o 3.
          </p>
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li>
          <p>
            A Port&atilde;o 3 reserva-se o direito e o poder de determinar, a
            seu exclusivo crit&eacute;rio, quando considera ter havido uma
            viola&ccedil;&atilde;o de qualquer uma das disposi&ccedil;&otilde;es
            estabelecidas nestes Termos de Uso, na Pol&iacute;tica de
            Privacidade e na legisla&ccedil;&atilde;o aplic&aacute;vel, sem
            necessidade de prova, hip&oacute;teses em que adotar&aacute; as
            seguintes medidas (dependendo da gravidade ou recorr&ecirc;ncia da
            infra&ccedil;&atilde;o, a seu exclusivo crit&eacute;rio, e sem
            preju&iacute;zo de outras medidas que a Port&atilde;o 3 entenda
            necess&aacute;rias): advert&ecirc;ncia ao Usu&aacute;rio,
            suspens&atilde;o ou limita&ccedil;&atilde;o de acesso &agrave;
            Plataforma, tempor&aacute;ria ou permanente, e/ou
            a&ccedil;&otilde;es judiciais visando a atribui&ccedil;&atilde;o de
            responsabilidades e a repara&ccedil;&atilde;o de danos.
          </p>
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li>
          <p>Ao usar a Plataforma, o Usu&aacute;rio concorda que:</p>
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li>
          <p>
            S&oacute; usar&aacute; a Plataforma para uso pessoal e
            compat&iacute;vel com a finalidade para a qual a Plataforma foi
            disponibilizada pela Port&atilde;o 3;
          </p>
        </li>
        <li>
          <p>
            N&atilde;o usar&aacute; a Plataforma em benef&iacute;cio de
            terceiros e n&atilde;o autorizar&aacute; terceiros n&atilde;o
            autorizados a usar o seu acesso &agrave; Plataforma;
          </p>
        </li>
        <li>
          <p>
            N&atilde;o atribuir&aacute;, permitir&aacute; ou de outra forma
            transferir&aacute; seu acesso &agrave; Plataforma a qualquer outra
            pessoa f&iacute;sica ou jur&iacute;dica, exceto quando expressamente
            permitido pela Port&atilde;o 3;
          </p>
        </li>
        <li>
          <p>
            N&atilde;o utilizar&aacute; acessos de outros Usu&aacute;rios ou
            tentar&aacute; se passar por outros Usu&aacute;rios;
          </p>
        </li>
        <li>
          <p>
            N&atilde;o utilizar&aacute; a Plataforma para fins il&iacute;citos e
            ilegais, contr&aacute;rio &agrave;s disposi&ccedil;&otilde;es destes
            Termos de Uso, da Pol&iacute;tica de Privacidade, ou de qualquer
            legisla&ccedil;&atilde;o aplic&aacute;vel, ou praticar&aacute;
            quaisquer atos prejudiciais aos direitos e interesses da
            Port&atilde;o 3, de outros Usu&aacute;rios ou de terceiros,
            incluindo, sem limita&ccedil;&atilde;o, o fornecimento de material
            ilegal, para fins fraudulentos ou em desconformidade com a
            legisla&ccedil;&atilde;o aplic&aacute;vel;
          </p>
        </li>
        <li>
          <p>
            N&atilde;o tentar&aacute; danificar a Plataforma de nenhuma forma,
            acessar&aacute; recursos restritos na Plataforma ou praticar&aacute;
            quaisquer condutas que possam interferir no bom funcionamento da
            Plataforma;
          </p>
        </li>
        <li>
          <p>
            N&atilde;o usar&aacute; a Plataforma com um dispositivo
            incompat&iacute;vel ou n&atilde;o autorizado;
          </p>
        </li>
        <li>
          <p>
            N&atilde;o tentar&aacute; acessar, usar e/ou manipular os dados da
            Port&atilde;o 3, de outros Usu&aacute;rios ou de terceiros;
          </p>
        </li>
        <li>
          <p>
            N&atilde;o ir&aacute; falsificar, omitir ou simular endere&ccedil;os
            IPs, de rede ou de correio eletr&ocirc;nico, na tentativa de ocultar
            identidade ou autoria ou ainda responsabilizar terceiros inocentes;
          </p>
        </li>
        <li>
          <p>
            N&atilde;o introduzir&aacute; ou espalhar&aacute; v&iacute;rus de
            computador ou quaisquer outros sistemas f&iacute;sicos ou
            l&oacute;gicos e n&atilde;o praticar&aacute; atos que possam causar
            danos ou prejudicar a Plataforma, a Port&atilde;o 3, outros
            Usu&aacute;rios ou terceiros, inclusive por meio da
            publica&ccedil;&atilde;o ou transmiss&atilde;o de qualquer arquivo
            que contenha v&iacute;rus,&nbsp;worms, malwares, bot, backdoor,
            spyware, rootkit, cavalos de Tr&oacute;ia ou qualquer outro programa
            contaminante ou destrutivo;
          </p>
        </li>
        <li>
          <p>
            N&atilde;o empregar&aacute; softwares, t&eacute;cnicas e/ou
            artif&iacute;cios com o intuito de utilizar indevidamente a
            Plataforma para pr&aacute;ticas nocivas &agrave; Port&atilde;o 3, a
            outros Usu&aacute;rios ou a terceiros, tais como&nbsp;hacking,
            scraping, crawling, exploits, spamming, flooding, spoofing,
            crashing, root kits, etc.
          </p>
        </li>
        <li>
          <p>
            N&atilde;o violar&aacute; ou amea&ccedil;ar&aacute; os direitos e os
            interesses da Port&atilde;o 3, de outros Usu&aacute;rios ou de
            terceiros; e
          </p>
        </li>
        <li>
          <p>
            N&atilde;o realizar atos contr&aacute;rios &agrave; moral, &agrave;
            lei e &agrave; ordem p&uacute;blica e, incluindo, mas n&atilde;o se
            limitando a, pornografia, corrup&ccedil;&atilde;o, lavagem de
            dinheiro, entre outros.
          </p>
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li>
          <p>
            O Usu&aacute;rio concorda em defender, indenizar e manter indene a
            Port&atilde;o 3 e suas Afiliadas, diretores, empregados e agentes,
            de e contra quaisquer encargos, a&ccedil;&otilde;es ou demandas,
            incluindo, mas n&atilde;o limitado a custos judiciais e
            honor&aacute;rios advocat&iacute;cios razo&aacute;veis, resultantes:
            (i) da eventual utiliza&ccedil;&atilde;o indevida da Plataforma;
            (ii) da viola&ccedil;&atilde;o destes Termos de Uso, da
            Pol&iacute;tica de Privacidade ou das leis e
            regulamenta&ccedil;&otilde;es aplic&aacute;veis; (iii) da
            utiliza&ccedil;&atilde;o de Dados Pessoais em desacordo com a
            Legisla&ccedil;&atilde;o de Prote&ccedil;&atilde;o de Dados, ou das
            condi&ccedil;&otilde;es pactuadas nestes Termos de Uso ou na
            Pol&iacute;tica de Privacidade; e (iv) de quaisquer demandas
            relacionadas a qualquer a&ccedil;&atilde;o ou omiss&atilde;o ou de
            qualquer outra forma relacionada &agrave; utiliza&ccedil;&atilde;o,
            da Plataforma.
          </p>
        </li>
      </ul>
      <p>&nbsp;</p>
      <p>CL&Aacute;USULA OITAVA &ndash; PAGAMENTO</p>
      <p>&nbsp;</p>
      <ul>
        <li>
          <p>
            Para finalizar a solicita&ccedil;&atilde;o na Plataforma, o
            Usu&aacute;rio dever&aacute; realizar o pagamento via cart&atilde;o
            de cr&eacute;dito ou utilizar o meio de cart&atilde;o de pagamentos
            da pr&oacute;pria Port&atilde;o 3, como os cart&otilde;es
            f&iacute;sicos e virtuais.ou Bank3 (
            <a href="https://bank3.com.br/">https://bank3.com.br/</a>).
          </p>
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li>
          <p>
            Fica o Usu&aacute;rio proibido de burlar, tentar burlar, incentivar
            ou auxiliar terceiro para que burle ou tente burlar as
            condi&ccedil;&otilde;es descritas nesta cl&aacute;usula,
            especialmente quando tal ato se destinar a reduzir ou eliminar os
            valores de taxas e comiss&otilde;es a serem pagos aao Port&atilde;o
            3 ou Estabelecimentos. Caso a Port&atilde;o 3 tenha qualquer
            raz&atilde;o para suspeitar que um Usu&aacute;rio burlou as
            limita&ccedil;&otilde;es previstas neste item, poder&aacute; cobrar
            as devidas taxas e comiss&otilde;es, mesmo sobre
            transa&ccedil;&otilde;es realizadas fora da Plataforma, sem
            preju&iacute;zo de tomar as medidas necess&aacute;rias para
            suspender ou bloquear, tempor&aacute;ria ou definitivamente, a conta
            do Usu&aacute;rio em quest&atilde;o.
          </p>
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li>
          <p>
            O Usu&aacute;rio desde j&aacute; autoriza a Port&atilde;o 3 a
            proceder a toda e qualquer reten&ccedil;&atilde;o que seja (i)
            necess&aacute;ria para cumprimento de obriga&ccedil;&otilde;es
            tribut&aacute;rias; (ii) relacionada &agrave;s comiss&otilde;es e
            &agrave;s taxas devidas &agrave; Port&atilde;o 3; ou (iii)
            relacionada a multas devidas pelo Usu&aacute;rio que tenha atuado em
            viola&ccedil;&atilde;o aos presentes Termos de Uso.
          </p>
        </li>
      </ul>
      <p>&nbsp;</p>
      <p>CL&Aacute;USULA NONA &ndash; RESPONSABILIDADE</p>
      <p>&nbsp;</p>
      <ul>
        <li>
          <p>
            O uso da Plataforma pelo Usu&aacute;rio &eacute; de inteira
            responsabilidade e risco do Usu&aacute;rio, sendo que a Plataforma
            &eacute; fornecida no estado em que se encontra. Em sendo assim, a
            Port&atilde;o 3 n&atilde;o garante que:
          </p>
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li>
          <p>
            o acesso &agrave; Plataforma, seu conte&uacute;do e/ou sua
            opera&ccedil;&atilde;o ser&atilde;o ininterruptos ou livres de
            erros;
          </p>
        </li>
        <li>
          <p>
            a Plataforma e os servi&ccedil;os atender&atilde;o &agrave;s
            necessidades e expectativas do Usu&aacute;rio;
          </p>
        </li>
        <li>
          <p>a Plataforma funcionar&aacute; de maneira pontual e segura;</p>
        </li>
        <li>
          <p>
            o conte&uacute;do dispon&iacute;vel ou gerado na Plataforma
            ser&aacute; preciso ou totalmente confi&aacute;vel; e/ou
          </p>
        </li>
        <li>
          <p>
            quaisquer erros no conte&uacute;do ou na Plataforma ser&atilde;o
            efetivamente corrigidos.
          </p>
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li>
          <p>
            O Usu&aacute;rio est&aacute; ciente e aceita que todas as
            transa&ccedil;&otilde;es realizadas na Plataforma s&atilde;o de sua
            pr&oacute;pria conta e risco. Da mesma forma, embora a Port&atilde;o
            3 colete informa&ccedil;&otilde;es de Estabelecimentos ou das
            transa&ccedil;&otilde;es corporativas realizadas, ela n&atilde;o
            garante sua idoneidade, legalidade ou exist&ecirc;ncia. Assim, a
            Port&atilde;o 3 n&atilde;o ser&aacute; respons&aacute;vel, em
            nenhuma hip&oacute;tese e a qualquer t&iacute;tulo, pelos
            Servi&ccedil;os de Terceiros e quaisquer outros produtos e
            servi&ccedil;os ofertados pelos Estabelecimentos, pela
            rela&ccedil;&atilde;o ou intera&ccedil;&atilde;o entre os
            Usu&aacute;rios Gestores e os Usu&aacute;rios Colaboradores, nem
            tampouco pelos danos que possam ser causados pelo Estabelecimento ao
            Usu&aacute;rio ou a terceiros, e vice e versa. A Port&atilde;o 3
            n&atilde;o ser&aacute; respons&aacute;vel por lucros cessantes, ou
            por qualquer outro dano e/ou preju&iacute;zo que o Usu&aacute;rio ou
            Estabelecimento possa sofrer em conex&atilde;o com os produtos e
            servi&ccedil;os comercializados ou com o seu uso da Plataforma.
          </p>
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li>
          <p>
            A Port&atilde;o 3 recomenda aos Usu&aacute;rios agir com
            prud&ecirc;ncia e bom senso ao contratar os produtos ou
            servi&ccedil;os de um Estabelecimento. Em caso de
            controv&eacute;rsias, lit&iacute;gios ou disputas, judiciais ou
            extrajudiciais, entre o Usu&aacute;rio e um Estabelecimento ou em
            rela&ccedil;&atilde;o a qualquer transa&ccedil;&atilde;o realizada
            por meio da Plataforma, o Usu&aacute;rio se obriga desde j&aacute; a
            isentar a Port&atilde;o 3 e suas Afiliadas, diretores, gerentes,
            funcion&aacute;rios, agentes, operadores, representantes e
            procuradores de qualquer responsabilidade.
          </p>
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li>
          <p>
            A Plataforma poder&aacute; conter links ou conte&uacute;dos de
            outros sites da internet ou de terceiros parceiros ou n&atilde;o da
            Port&atilde;o 3, o que n&atilde;o significa que esses sites sejam de
            propriedade ou operados pela Port&atilde;o 3. A Port&atilde;o 3
            n&atilde;o possui controle sobre esses sites de terceiros e
            n&atilde;o ser&aacute; respons&aacute;vel pelos conte&uacute;dos,
            pr&aacute;ticas e servi&ccedil;os ofertados por quaisquer terceiros,
            ainda quando constantes na sua Plataforma. A presen&ccedil;a de
            conte&uacute;do de terceiros na Plataforma n&atilde;o implica
            rela&ccedil;&atilde;o de endosso, aprova&ccedil;&atilde;o,
            sociedade, de supervis&atilde;o, de cumplicidade ou solidariedade da
            Port&atilde;o 3 para com esses sites e seus conte&uacute;dos.
          </p>
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li>
          <p>
            A Port&atilde;o 3 n&atilde;o ser&aacute; respons&aacute;vel em
            nenhuma hip&oacute;tese, ainda que em car&aacute;ter
            solid&aacute;rio ou subsidi&aacute;rio:
          </p>
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li>
          <p>Por atos dos Estabelecimentos ou de outros Usu&aacute;rios;</p>
        </li>
        <li>
          <p>Por atos da Parceira;</p>
        </li>
        <li>
          <p>
            Por qualquer transa&ccedil;&atilde;o que n&atilde;o seja
            conclu&iacute;da por motivos fora do controle da Port&atilde;o 3;.
          </p>
        </li>
        <li>
          <p>
            Por eventuais preju&iacute;zos sofridos pelo Usu&aacute;rio em
            raz&atilde;o da indisponibilidade ou instabilidade da Plataforma,
            suspens&atilde;o do acesso &agrave; Plataforma, falhas no sistema de
            inform&aacute;tica ou nos servidores da Plataforma ou em sua
            conectividade com a internet de modo geral, devendo o Usu&aacute;rio
            manter, &agrave;s suas expensas, linha de
            telecomunica&ccedil;&atilde;o, acesso &agrave; internet, software de
            comunica&ccedil;&atilde;o, endere&ccedil;o de correio
            eletr&ocirc;nico e outros recursos necess&aacute;rios &agrave;
            comunica&ccedil;&atilde;o com a Port&atilde;o 3;
          </p>
        </li>
        <li>
          <p>
            Por situa&ccedil;&otilde;es de Caso Fortuito ou For&ccedil;a Maior,
            nos termos do artigo 393 do C&oacute;digo Civil Brasileiro;
          </p>
        </li>
        <li>
          <p>
            Por danos causados por programas nocivos &agrave; Plataforma, tais
            como, mas sem se limitar a v&iacute;rus,&nbsp;trojans e hackers,
            malwares e ransomwares;
          </p>
        </li>
        <li>
          <p>
            Danos diretos ou indiretos, lucros cessantes, perdas ou despesas, em
            decorr&ecirc;ncia do uso ou do desempenho da Plataforma, da
            indisponibilidade ou instabilidade da Plataforma e da
            suspens&atilde;o do acesso &agrave; Plataforma;
          </p>
        </li>
        <li>
          <p>
            Danos ou preju&iacute;zos decorrentes da demora ou incapacidade de
            usar a Plataforma;
          </p>
        </li>
        <li>
          <p>
            Perda ou uso inadequado de informa&ccedil;&otilde;es enviadas por
            meio da Plataforma; e/ou
          </p>
        </li>
        <li>
          <p>
            Danos resultantes da confian&ccedil;a depositada pelo Usu&aacute;rio
            em qualquer informa&ccedil;&atilde;o fornecida por Estabelecimentos
            ou por outros Usu&aacute;rios.
          </p>
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li>
          <p>
            A Port&atilde;o 3 N&Atilde;O GARANTE, ENDOSSA OU ASSUME QUALQUER
            RESPONSABILIDADE POR INFORMA&Ccedil;&Otilde;ES FORNECIDAS POR OUTROS
            USU&Aacute;RIOS, POR PARCEIROS OU TERCEIROS POR MEIO DA PLATAFORMA,
            NEM TAMPOUCO TEM QUALQUER PARTICIPA&Ccedil;&Atilde;O NA
            RELA&Ccedil;&Atilde;O ESTABELECIDA ENTRE OS ESTABELECIMENTOS E OS
            USU&Aacute;RIOS. O USU&Aacute;RIO RECONHECE E CONCORDA QUE A
            PORT&Atilde;O 3 N&Atilde;O POSSUI QUALQUER RESPONSABILIDADE POR
            MONITORAR TRANSA&Ccedil;&Otilde;ES OU COMUNICA&Ccedil;&Otilde;ES COM
            OUTROS USU&Aacute;RIOS OU TERCEIROS. OS USU&Aacute;RIOS S&Atilde;O
            OS &Uacute;NICOS RESPONS&Aacute;VEIS POR TODAS AS
            COMUNICA&Ccedil;&Otilde;ES, TRANSA&Ccedil;&Otilde;ES,
            CONTRATA&Ccedil;&Otilde;ES E INTERA&Ccedil;&Otilde;ES QUE TIVEREM
            COM OUTRAS POR MEIO DA PLATAFORMA. N&Atilde;O OBSTANTE, A
            PORT&Atilde;O 3 SE RESERVA O DIREITO DE REMOVER, A SEU EXCLUSIVO
            CRIT&Eacute;RIO, POR QUALQUER RAZ&Atilde;O E SEM AVISO
            PR&Eacute;VIO, QUAISQUER MENSAGENS, INFORMA&Ccedil;&Otilde;ES,
            DOCUMENTOS E/OU CONTE&Uacute;DO, CASO HAJA SUSPEITA OU
            COMPROVA&Ccedil;&Atilde;O DE FRAUDE OU TENTATIVA DE ATO
            IL&Iacute;CITO NA PLATAFORMA.
          </p>
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li>
          <p>
            EM NENHUM CASO A PORT&Atilde;O 3 SER&Aacute; RESPONS&Aacute;VEL POR
            DANOS INDIRETOS OU A TERCEIROS DECORRENTES DESTES TERMOS DE USO,
            INCLUSIVE POR MORTE, LES&Atilde;O OU PREJU&Iacute;ZOS DE QUALQUER
            NATUREZA OCASIONADOS A TERCEIROS, BEM COMO POR LUCROS CESSANTES OU
            PERDA DE RECEITA DE QUALQUER NATUREZA PELOS USU&Aacute;RIOS,
            INCLUINDO, AINDA, AQUELES OCASIONADOS POR CORRUP&Ccedil;&Atilde;O OU
            PERDA DE DADOS, FALHA DE TRANSMISS&Atilde;O OU
            RECEP&Ccedil;&Atilde;O DE DADOS, N&Atilde;O CONTINUIDADE DO
            NEG&Oacute;CIO OU QUALQUER OUTRO PREJU&Iacute;ZO OU PERDA COMERCIAL,
            DECORRENTES OU RELACIONADOS AO USO OU INABILIDADE EM USAR A
            PLATAFORMA, POR QUALQUER OUTRO MOTIVO.
          </p>
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li>
          <p>
            Nada do que est&aacute; escrito nestes Termos de Uso tem como
            inten&ccedil;&atilde;o excluir ou limitar qualquer
            condi&ccedil;&atilde;o, garantia, direito ou responsabilidade que
            n&atilde;o possa ser legalmente exclu&iacute;da ou limitada. Algumas
            jurisdi&ccedil;&otilde;es n&atilde;o permitem a exclus&atilde;o de
            certas garantias ou condi&ccedil;&otilde;es ou a
            limita&ccedil;&atilde;o ou exclus&atilde;o de responsabilidade por
            perdas e danos. Consequentemente, apenas ser&atilde;o
            aplic&aacute;veis as limita&ccedil;&otilde;es que forem permitidas
            por lei na jurisdi&ccedil;&atilde;o do Usu&aacute;rio. Nos casos em
            que exclus&atilde;o de responsabilidade n&atilde;o se mostrar
            poss&iacute;vel, mas a limita&ccedil;&atilde;o de responsabilidade
            for legalmente aplic&aacute;vel, a responsabilidade total da
            Port&atilde;o 3 estar&aacute; limitada a R$ 1.000,00 (mil reais).
          </p>
        </li>
      </ul>
      <p>&nbsp;</p>
      <p>CL&Aacute;USULA D&Eacute;CIMA &ndash; USO E GARANTIA DA PLATAFORMA</p>
      <p>&nbsp;</p>
      <ul>
        <li>
          <p>
            O Usu&aacute;rio concorda e aceita que o uso da Plataforma e de
            qualquer de seus conte&uacute;dos &eacute; de sua &uacute;nica e
            exclusiva responsabilidade.
          </p>
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li>
          <p>
            A Port&atilde;o 3 se esfor&ccedil;a ao m&aacute;ximo para prevenir
            infec&ccedil;&otilde;es, altera&ccedil;&otilde;es ou v&iacute;rus em
            seus pr&oacute;prios sistemas (hardware e software), mas n&atilde;o
            pode garantir a aus&ecirc;ncia destes ou de outros elementos
            potencialmente nocivos, que possam causar altera&ccedil;&otilde;es
            em programas, aplicativos, dispositivos ou dispositivos de seus
            visitantes, nem assume responsabilidade por danos que possam ser
            gerados como resultado.
          </p>
        </li>
      </ul>
      <p>&nbsp;&nbsp;</p>
      <ul>
        <li>
          <p>
            A Port&atilde;o 3 n&atilde;o se responsabiliza por danos de qualquer
            esp&eacute;cie resultantes de atos ilegais praticados por terceiros
            por meio do nossa Plataforma, tais como,&nbsp;hacking&nbsp;de
            contas,&nbsp;hacking&nbsp;da web, falsifica&ccedil;&atilde;o de
            identidade de Usu&aacute;rios, viola&ccedil;&atilde;o a leis de
            qualquer tipo, entre outras.
          </p>
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li>
          <p>
            DE MANEIRA GERAL E NA M&Aacute;XIMA EXTENS&Atilde;O PERMITIDA PELA
            LEI, A PORT&Atilde;O 3 SE EXIME DE QUAISQUER GARANTIAS E/OU
            RESPONSABILIDADES, EXPRESSAS OU IMPL&Iacute;CITAS, DECORRENTES OU
            RELATIVAS &Agrave; UTILIZA&Ccedil;&Atilde;O DA PLATAFORMA, INCLUINDO
            GARANTIAS DE POSS&Iacute;VEL ADEQUA&Ccedil;&Atilde;O &Agrave; UMA
            FINALIDADE ESPEC&Iacute;FICA.
          </p>
        </li>
      </ul>
      <p>&nbsp;</p>
      <p>
        CL&Aacute;USULA D&Eacute;CIMA PRIMEIRA &ndash; DIREITOS DA PORT&Atilde;O
        3
      </p>
      <p>&nbsp;</p>
      <ul>
        <li>
          <p>
            Poder&aacute; a Port&atilde;o 3, a seu exclusivo crit&eacute;rio:
          </p>
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li>
          <p>
            Suspender, modificar ou encerrar, parcial ou totalmente, as
            funcionalidades ou atividades da Plataforma;
          </p>
        </li>
        <li>
          <p>
            Realizar toda e qualquer averigua&ccedil;&atilde;o,
            investiga&ccedil;&atilde;o, an&aacute;lise interna de dados
            relacionados ao uso da Plataforma pelo Usu&aacute;rio e
            ado&ccedil;&atilde;o de quaisquer medidas corretivas com a
            finalidade de corrigir problemas, evitar fraudes, atender
            solicita&ccedil;&otilde;es do Usu&aacute;rio e qualquer outro fim
            necess&aacute;rio para preserva&ccedil;&atilde;o da integridade da
            Plataforma e dos Usu&aacute;rios;
          </p>
        </li>
        <li>
          <p>
            Bloquear ou suspender, sem necessidade de comunica&ccedil;&atilde;o
            pr&eacute;via, o acesso do Usu&aacute;rio &agrave; Plataforma,
            tempor&aacute;ria ou definitivamente, em caso de inobserv&acirc;ncia
            ou suspeita de inobserv&acirc;ncia de quaisquer das
            obriga&ccedil;&otilde;es ora assumidas nestes Termos de Uso, na
            Pol&iacute;tica de Privacidade ou nos diplomas legais
            aplic&aacute;veis, a exclusivo crit&eacute;rio da Port&atilde;o 3;
          </p>
        </li>
        <li>
          <p>
            Definir pre&ccedil;os e comiss&otilde;es para oferecimento de
            determinadas funcionalidades e/ou servi&ccedil;os, ainda que
            inicialmente tenham sido ofertados de forma gratuita, mediante
            comunica&ccedil;&atilde;o pr&eacute;via por meio do endere&ccedil;o
            de e-mail informado pelo Usu&aacute;rio ou aviso na pr&oacute;pria
            Plataforma, sendo a utiliza&ccedil;&atilde;o destes, ap&oacute;s o
            referido aviso, considerada como concord&acirc;ncia do
            Usu&aacute;rio com tais pre&ccedil;os; e/ou
          </p>
        </li>
        <li>
          <p>
            Efetuar em nome dos Usu&aacute;rios, isoladamente ou em conjunto com
            a Parceira, conforme aplic&aacute;vel, ordens de pagamento,
            transfer&ecirc;ncias, resgates de recursos, opera&ccedil;&otilde;es
            de desconto de receb&iacute;veis, pagamento de taxas e tributos,
            negocia&ccedil;&otilde;es, repasses e/ou quaisquer outros
            neg&oacute;cios jur&iacute;dicos decorrentes de, necess&aacute;rios
            ou convenientes &agrave; execu&ccedil;&atilde;o dos Servi&ccedil;os
            objeto destes Termos de Uso.
          </p>
        </li>
      </ul>
      <p>&nbsp;</p>
      <p>
        CL&Aacute;USULA D&Eacute;CIMA SEGUNDA &ndash; DIREITOS DE PROPRIEDADE
        INTELECTUAL
      </p>
      <p>&nbsp;</p>
      <ul>
        <li>
          <p>
            O Usu&aacute;rio reconhece e aceita que todos os direitos de
            propriedade intelectual e industrial relacionados &agrave;
            Plataforma e aos conte&uacute;dos e/ou quaisquer outros elementos
            inseridos na Plataforma (incluindo, sem limita&ccedil;&atilde;o,
            marcas, logotipos, nomes comerciais, slogans comerciais, textos,
            imagens, ilustra&ccedil;&otilde;es, fotografias, gr&aacute;ficos,
            designs, trade-dress, sons, dados, bases de dados, &iacute;cones,
            software, programas de computador, c&oacute;digos-fonte,
            fluxogramas, apresenta&ccedil;&atilde;o, &aacute;udio e v&iacute;deo
            e/ou quaisquer outros direitos de propriedade intelectual e
            industrial de qualquer natureza) pertencem e continuar&atilde;o
            pertencendo exclusivamente &agrave; Port&atilde;o 3 e/ou &agrave;s
            Afiliadas, sendo que a aceita&ccedil;&atilde;o destes Termos de Uso
            pelo Usu&aacute;rio consiste apenas na concess&atilde;o, por parte
            da Port&atilde;o 3, de uma autoriza&ccedil;&atilde;o limitada,
            tempor&aacute;ria, revog&aacute;vel, n&atilde;o-exclusiva e
            intransfer&iacute;vel para usar a Plataformas, na modalidade
            &ldquo;as a service&rdquo;, sempre de acordo com estes Termos de
            Uso.
          </p>
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li>
          <p>
            Consequentemente, todo o conte&uacute;do usado na Plataforma, como
            c&oacute;digo html, scripts, texto, gr&aacute;ficos, logotipos,
            &iacute;cones de bot&atilde;o, imagens, v&iacute;deo ou programas,
            aplicativos m&oacute;veis e &aacute;udio (juntos, os
            &ldquo;Materiais&rdquo;) s&atilde;o propriedade da Port&atilde;o 3 e
            est&atilde;o protegidos pelas pela lei brasileira no que se refere
            &agrave; propriedade intelectual e aos direitos autorais.
          </p>
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li>
          <p>
            Em nenhuma hip&oacute;tese se entender&aacute; que o acesso &agrave;
            Plataforma e/ou a aceita&ccedil;&atilde;o dos Termos de Uso geram
            qualquer cess&atilde;o ou transfer&ecirc;ncia de direitos em favor
            dos Usu&aacute;rios, dos Estabelecimentos ou de terceiros. Nada
            nestes Termos de Uso deve ser entendido como uma ren&uacute;ncia de
            direitos pela Port&atilde;o 3, que seguir&aacute; detentora de todos
            os direitos, t&iacute;tulos, interesses e propriedade relativos
            &agrave; Plataforma.
          </p>
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li>
          <p>
            Nenhum direito ou t&iacute;tulo de nossos Materiais &eacute;
            transferido, cedido ou licenciado ao Usu&aacute;rio, exceto conforme
            aqui expressamente previsto. O Usu&aacute;rio n&atilde;o pode baixar
            ou modificar a Plataforma, ou qualquer parte dela, a menos que o
            Usu&aacute;rio tenha o consentimento expresso por escrito da
            Port&atilde;o 3.
          </p>
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li>
          <p>
            A autoriza&ccedil;&atilde;o de uso outorgada aos Usu&aacute;rios
            n&atilde;o cobre qualquer revenda ou uso comercial da Plataforma ou
            de seus materiais e n&atilde;o concede ao Usu&aacute;rio o direito
            de usar a Plataforma para qualquer prop&oacute;sito que n&atilde;o
            esteja previsto expressamente nestes Termos de Uso. A Plataforma ou
            qualquer parte dela n&atilde;o pode ser reproduzida, duplicada,
            copiada, modificada, vendida, revendida, distribu&iacute;da,
            transferida, publicada, exibida ou explorada, sem o consentimento
            expresso por escrito da Port&atilde;o 3. A Port&atilde;o 3
            reserva-se todos os direitos que n&atilde;o tenham sido
            expressamente concedidos.
          </p>
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li>
          <p>
            A utiliza&ccedil;&atilde;o da Plataforma pelo Usu&aacute;rio possui
            car&aacute;ter pessoal e intransfer&iacute;vel, unicamente para fins
            l&iacute;citos relacionados ao prop&oacute;sito a que a Plataforma
            se destina, conforme previsto nestes Termos de Uso. Em nenhuma
            hip&oacute;tese o Usu&aacute;rio ter&aacute; acesso ao
            c&oacute;digo-fonte do software usado na Plataforma, j&aacute; que
            esse &eacute;, e permanecer&aacute; sendo, de propriedade
            intelectual &uacute;nica e exclusiva da Port&atilde;o 3.
          </p>
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li>
          <p>
            &Eacute; expressamente proibido ao Usu&aacute;rio, por si, seus
            s&oacute;cios, funcion&aacute;rios, colaboradores e representantes:
          </p>
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li>
          <p>
            Transmitir, divulgar, reproduzir, copiar ou explorar, com intuito
            comercial ou n&atilde;o, o conte&uacute;do da Plataforma, em parte
            ou no todo;
          </p>
        </li>
        <li>
          <p>
            Usar dispositivo de minera&ccedil;&atilde;o de dados e/ou que tenha
            funcionalidade similar para coletar e/ou extrair dados da
            Plataforma;
          </p>
        </li>
        <li>
          <p>
            Manipular ou exibir a Plataforma e/ou os seu respectivo
            conte&uacute;do usando enquadramento ou tecnologia de
            navega&ccedil;&atilde;o afim;
          </p>
        </li>
        <li>
          <p>Realizar engenharia reversa na Plataforma; e/ou</p>
        </li>
        <li>
          <p>
            Criar obras derivadas da Plataforma, seus c&oacute;digos-fonte ou
            demais conte&uacute;dos e bancos de dados.
          </p>
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li>
          <p>
            Caso o Usu&aacute;rio venha a desenvolver um novo m&oacute;dulo ou
            produto que caracterize c&oacute;pia, no todo ou em parte, quer seja
            do banco de dados, quer seja do software, esse ser&aacute;
            considerado como parte do software usado na Plataforma, ficando,
            portanto, sua propriedade incorporada pela Port&atilde;o 3 e seu uso
            condicionado a estas cl&aacute;usulas contratuais, sem que qualquer
            remunera&ccedil;&atilde;o seja devida pela Port&atilde;o 3 e ficando
            o Usu&aacute;rio, por si, seus s&oacute;cios, funcion&aacute;rios,
            colaboradores e representantes, impedido de utilizar, comercializar
            ou, de outra forma, explorar referido m&oacute;dulo ou produto.
          </p>
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li>
          <p>
            O uso da express&atilde;o &ldquo;Port&atilde;o 3&rdquo; como marca,
            nome empresarial ou nome de dom&iacute;nio, bem como os
            conte&uacute;dos das telas relativas &agrave; Plataforma, assim como
            os programas, bancos de dados, redes e arquivos, s&atilde;o, e
            permanecer&atilde;o sendo, de propriedade exclusiva da Port&atilde;o
            3, e est&atilde;o protegidos pelas leis e tratados internacionais de
            direito autoral, marcas, patentes, modelos de utilidade,
            inven&ccedil;&otilde;es, desenhos industriais e outros direitos de
            propriedade intelectual. O uso indevido e a reprodu&ccedil;&atilde;o
            total ou parcial dos referidos conte&uacute;dos s&atilde;o
            proibidos, salvo a autoriza&ccedil;&atilde;o pr&eacute;via e
            expressa da Port&atilde;o 3.
          </p>
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li>
          <p>
            Sem o nosso consentimento expresso por escrito, o Usu&aacute;rio
            n&atilde;o pode fazer o seguinte: (a) criar um link para qualquer
            site da nossa Plataforma ou seus sites; (b) usar qualquer meta tags
            ou &quot;texto oculto&quot; usando nosso nome ou marca; ou (c) frame
            data, frame, gerar links autom&aacute;ticos ou usar outras
            t&eacute;cnicas para associar ou justapor qualquer uma de nossas
            marcas, logotipos ou outros materiais com an&uacute;ncios e/ou
            outras informa&ccedil;&otilde;es que n&atilde;o tenham origem em
            nossa Plataforma.
          </p>
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li>
          <p>
            Qualquer uso n&atilde;o autorizado resulta no cancelamento de sua
            autoriza&ccedil;&atilde;o de uso da Plataforma. Podemos revogar esta
            autoriza&ccedil;&atilde;o a qualquer momento, com ou sem motivo. Em
            caso de viola&ccedil;&atilde;o destes Termos de Uso, a Port&atilde;o
            3 reserva-se o direito de exercer todos os recursos legais
            dispon&iacute;veis. A Port&atilde;o 3 reserva-se o direito, a seu
            exclusivo crit&eacute;rio, de negar a qualquer pessoa o acesso
            &agrave; Plataforma, a qualquer momento, com ou sem motivo,
            inclusive, sem limita&ccedil;&atilde;o, devido &agrave;
            viola&ccedil;&atilde;o destes Termos de Uso. Da mesma forma, o
            Usu&aacute;rio est&aacute; informado de que a Port&atilde;o 3
            far&aacute; cumprir vigorosamente seus direitos de propriedade
            intelectual com o mais amplo escopo previsto por lei, incluindo a
            interposi&ccedil;&atilde;o de medidas judiciais de acordo com a lei
            civil e a&ccedil;&otilde;es de natureza criminal.
          </p>
        </li>
      </ul>
      <p>&nbsp;&nbsp;</p>
      <ul>
        <li>
          <p>
            Ao inserir qualquer tipo de conte&uacute;do na Plataforma, o
            Usu&aacute;rio e os Estabelecimentos (i) concedem uma licen&ccedil;a
            gratuita, mundial e ilimitada, enquanto durar a
            rela&ccedil;&atilde;o com a Port&atilde;o 3 ou enquanto a conta for
            mantida, sobre o conte&uacute;do do Usu&aacute;rio ou
            Estabelecimento; (ii) garante que o conte&uacute;do publicado
            (incluindo imagens) &eacute; de sua pr&oacute;pria autoria e/ou que
            possui todos os direitos necess&aacute;rios para usar e licenciar
            tal conte&uacute;do &agrave; Port&atilde;o 3; (iii) garante que o
            conte&uacute;do veiculado na Plataforma n&atilde;o viola qualquer
            direito ou propriedade intelectual de terceiros; e (iv)
            responsabiliza-se integralmente por qualquer dano ou
            preju&iacute;zo, de qualquer natureza, decorrente da
            viola&ccedil;&atilde;o de tais obriga&ccedil;&otilde;es e garantias.
          </p>
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li>
          <p>
            O Usu&aacute;rio est&aacute; ciente e concorda que todo
            conte&uacute;do enviado para a Port&atilde;o 3 ou para a Plataforma
            (incluindo avalia&ccedil;&otilde;es, feedbacks, depoimentos,
            sugest&otilde;es, reclama&ccedil;&otilde;es, ideias,
            informa&ccedil;&otilde;es, coment&aacute;rios, contatos e
            intera&ccedil;&otilde;es por qualquer meio com a Port&atilde;o 3 ou
            com outros Usu&aacute;rios por meio da Plataforma) passa a pertencer
            automaticamente &agrave; Port&atilde;o 3, a qual ser&aacute; a
            &uacute;nica e exclusiva titular dos direitos de propriedade
            intelectual sobre referido conte&uacute;do. Caso a cess&atilde;o dos
            direitos de propriedade intelectual sobre o conte&uacute;do criado
            pelo Usu&aacute;rio e compartilhado com a Port&atilde;o 3 ou com
            outros Usu&aacute;rios por meio da Plataforma seja vedada pelas leis
            aplic&aacute;veis, o Usu&aacute;rio, desde j&aacute;, concede
            &agrave; Port&atilde;o 3 uma licen&ccedil;a exclusiva, global,
            gratuita, ilimitada, n&atilde;o revog&aacute;vel e v&aacute;lida por
            todo o prazo de prote&ccedil;&atilde;o legal, para uso e
            explora&ccedil;&atilde;o do conte&uacute;do, da forma como melhor
            lhe aprouver, e a seu exclusivo crit&eacute;rio.
          </p>
        </li>
      </ul>
      <p>&nbsp;</p>
      <p>
        CL&Aacute;USULA D&Eacute;CIMA TERCEIRA &ndash; PROTE&Ccedil;&Atilde;O DE
        DADOS
      </p>
      <p>&nbsp;</p>
      <ul>
        <li>
          <p>
            O ACESSO E UTILIZA&Ccedil;&Atilde;O DA PLATAFORMA SUJEITA O
            USU&Aacute;RIO AO TRATAMENTO DE DETERMINADAS
            INFORMA&Ccedil;&Otilde;ES DE SUA TITULARIDADE, INCLUINDO DADOS
            PESSOAIS NOS TERMOS DA LEI GERAL DE PROTE&Ccedil;&Atilde;O DE DADOS.
            O USU&Aacute;RIO ENTENDE E CONCORDA QUE SEUS DADOS PESSOAIS
            SER&Atilde;O TRATADOS EM CONFORMIDADE COM A POL&Iacute;TICA DE
            PRIVACIDADE. A POL&Iacute;TICA DE PRIVACIDADE &Eacute; INCORPORADA A
            ESTES TERMOS E CONDI&Ccedil;&Otilde;ES POR REFER&Ecirc;NCIA.
          </p>
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li>
          <p>
            Os Usu&aacute;rios declaram que os Dados Pessoais foram fornecidos
            de forma absolutamente livre e volunt&aacute;ria, sem qualquer tipo
            de press&atilde;o, obriga&ccedil;&atilde;o ou condi&ccedil;&atilde;o
            envolvida.
          </p>
        </li>
      </ul>
      <p>&nbsp;</p>
      <p>
        CL&Aacute;USULA D&Eacute;CIMA QUARTA &ndash; COMUNICA&Ccedil;&Atilde;O
        COM A PORT&Atilde;O 3
      </p>
      <p>&nbsp;</p>
      <ul>
        <li>
          <p>
            O Usu&aacute;rio declara e aceita que a Port&atilde;o 3 pode
            notific&aacute;-lo de assuntos relacionados &agrave; Plataforma, por
            meio da pr&oacute;pria Plataforma, de mensagens de texto, ou do
            endere&ccedil;o de e-mail fornecido pelo Usu&aacute;rio. Ainda, o
            Usu&aacute;rio autoriza a Port&atilde;o 3 a enviar-lhe
            comunica&ccedil;&otilde;es eletr&ocirc;nicas por v&aacute;rios
            meios, tais como e-mail, mensagens de texto (sms), aplicativos de
            mensagens (como WhatsApp), mensagens &ldquo;push&rdquo; ou
            informa&ccedil;&otilde;es por aplicativo.
          </p>
        </li>
      </ul>
      <p>&nbsp;</p>
      <p>CL&Aacute;USULA D&Eacute;CIMA QUINTA &ndash; CESS&Atilde;O</p>
      <p>&nbsp;</p>
      <ul>
        <li>
          <p>
            O Usu&aacute;rio n&atilde;o pode ceder os direitos e
            obriga&ccedil;&otilde;es decorrentes destes Termos de Uso sem o
            consentimento pr&eacute;vio por escrito da Port&atilde;o 3. A
            Port&atilde;o 3 pode ceder, sem a necessidade de obter o
            consentimento pr&eacute;vio do Usu&aacute;rio, mediante simples
            comunica&ccedil;&atilde;o. Ainda, a Port&atilde;o 3 poder&aacute;
            efetuar qualquer altera&ccedil;&atilde;o de seu controle
            societ&aacute;rio sem a necessidade de uma autoriza&ccedil;&atilde;o
            do Usu&aacute;rio ou de envio de uma comunica&ccedil;&atilde;o a
            este.
          </p>
        </li>
      </ul>
      <p>&nbsp;</p>
      <p>
        CL&Aacute;USULA D&Eacute;CIMA SEXTA
        &ndash;&nbsp;DISPOSI&Ccedil;&Atilde;O GERAIS
      </p>
      <p>&nbsp;</p>
      <ul>
        <li>
          <p>
            O Usu&aacute;rio que n&atilde;o desejar mais fazer uso da Plataforma
            dever&aacute; se descadastrar da Plataforma.&nbsp;
          </p>
        </li>
        <li>
          <p>
            Estes Termos de Uso poder&atilde;o ser alterados periodicamente.
            Caso haja mudan&ccedil;as significativas, informaremos o
            Usu&aacute;rio atrav&eacute;s dos meios de comunica&ccedil;&atilde;o
            dispon&iacute;veis e/ou destacaremos essas altera&ccedil;&otilde;es
            na parte superior destes Termos de Uso e forneceremos, por
            per&iacute;odo razo&aacute;vel, um link destacado para tais
            altera&ccedil;&otilde;es. O Usu&aacute;rio deve verificar essa
            p&aacute;gina e rever estes Termos de Uso periodicamente para
            garantir que concorda com as modifica&ccedil;&otilde;es. Exceto
            quando o Consentimento expresso for obrigat&oacute;rio, a
            continuidade da utiliza&ccedil;&atilde;o da Plataforma e/ou a falta
            de oposi&ccedil;&atilde;o ou revoga&ccedil;&atilde;o do
            consentimento do Usu&aacute;rio implicar&aacute; na
            aceita&ccedil;&atilde;o de eventuais modifica&ccedil;&otilde;es a
            estes Termos de Uso.
          </p>
        </li>
        <li>
          <p>
            A Port&atilde;o 3 n&atilde;o ser&aacute; responsabilizada por
            descumprimento ou cumprimento inadequado causado por uma
            situa&ccedil;&atilde;o de caso fortuito ou de for&ccedil;a maior,
            nos termos da legisla&ccedil;&atilde;o aplic&aacute;vel.
          </p>
        </li>
        <li>
          <p>
            Caso qualquer disposi&ccedil;&atilde;o destes Termos de Uso seja
            considerada inexequ&iacute;vel ou inv&aacute;lida, essa
            disposi&ccedil;&atilde;o ser&aacute; isoladamente invalidada e
            n&atilde;o atingir&aacute; o restante das disposi&ccedil;&otilde;es
            destes Termos de Uso.
          </p>
        </li>
      </ul>
      <p>&nbsp;</p>
      <p>
        CL&Aacute;USULA D&Eacute;CIMA S&Eacute;TIMA &ndash; LEI APLIC&Aacute;VEL
        E JURISDI&Ccedil;&Atilde;O
      </p>
      <p>&nbsp;</p>
      <ul>
        <li>
          <p>
            Estes Termos de Uso ser&atilde;o regidos e interpretados
            exclusivamente segundo as leis do Brasil.
          </p>
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li>
          <p>
            Anteriormente a qualquer in&iacute;cio de processo litigioso, as
            Partes se comprometem a tentar resolver o conflito amigavelmente.
            Quando tal procedimento n&atilde;o for bem-sucedido, as Partes
            poder&atilde;o submeter qualquer demanda relacionada a estes Termos
            de Uso, &agrave; Pol&iacute;tica de Privacidade ou &agrave;
            Plataforma ao Poder Judici&aacute;rio Brasileiro, elegendo o Foro da
            Comarca de S&atilde;o Paulo, Estado de S&atilde;o Paulo, como o
            &uacute;nico competente para dirimir tais lit&iacute;gios, com
            exclus&atilde;o de qualquer outro, por mais privilegiado que seja.
          </p>
        </li>
      </ul>
      <p>&nbsp;</p>
      <p>CL&Aacute;USULA D&Eacute;CIMA OITAVA &ndash; ACEITE</p>
      <p>&nbsp;</p>
      <ul>
        <li>
          <p>
            Caso o Usu&aacute;rio tenha d&uacute;vidas,
            reclama&ccedil;&otilde;es ou sugest&otilde;es acerca das Plataforma
            ou de suas funcionalidades, deseje obter maiores
            informa&ccedil;&otilde;es ou esclarecimentos sobre a
            aplica&ccedil;&atilde;o destes Termos de Uso ou da Pol&iacute;tica
            de Privacidade, ou para qualquer assunto relacionado a estes Termos
            de Uso, &agrave; Pol&iacute;tica de Privacidade ou &agrave;
            Plataforma, o Usu&aacute;rio poder&aacute; entrar em contato com a
            Port&atilde;o 3 por meio do e-mail oi@portao3.com.br. A
            Port&atilde;o 3 ter&aacute; a satisfa&ccedil;&atilde;o de
            esclarecer-lhe eventuais d&uacute;vidas e/ou atender &agrave; sua
            solicita&ccedil;&atilde;o.
          </p>
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li>
          <p>
            AO ACEITAR ESTES TERMOS DE USO, O Usu&aacute;rio DECLARA TER
            CAPACIDADE LEGAL E RECONHECE DE FORMA EXPRESSA E INEQU&Iacute;VOCA
            TER LIDO, COMPREENDIDO E ACEITO INTEGRALMENTE SEUS TERMOS E
            CONDI&Ccedil;&Otilde;ES.
          </p>
        </li>
      </ul>
    </div>
  );
}
