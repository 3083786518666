import { useRef } from 'react';
import { TObject } from '../types/others';

export type TArchive = TObject<Promise<any>>;

export type TGetDataFromArchive<I, T> = (
  key: string,
  fetchFunction: () => Promise<I>,
  formatData?: (data: I) => T
) => Promise<T | null>;

const dataArchive: TArchive = {};

export default function useDataArchive(initialData = {}, global = true) {
  const data = useRef<TArchive>(initialData);

  const getDataFromArchive = (
    key: string,
    fetchFunction: () => Promise<any>,
    formatData = (data: any) => data
  ) => {
    if (fetchFunction) {
      const archive = global ? dataArchive : data.current;
      let promise = archive[key];

      if (!promise) {
        promise = new Promise((res, rej) => {
          fetchFunction()
            .then((data) => {
              res(formatData(data));
            })
            .catch((err) => rej(err));
        });

        archive[key] = promise;
      }

      return promise;
    } else return Promise.resolve(null);
  };

  const deleteDataFromArchive = (key: string) => {
    const archive = global ? dataArchive : data.current;
    delete archive[key];
  };

  return { getDataFromArchive, deleteDataFromArchive };
}
