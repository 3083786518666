import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Row,
  Button,
  InputGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Alert,
} from 'reactstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import MaskedInput from 'react-text-mask';
import * as Yup from 'yup';
import { cardDateMask, cpfMask } from '../../utils/masks';

import Cards from 'react-credit-cards';
import Payment from 'payment';
import 'react-credit-cards/es/styles-compiled.css';

import IntlMessages from '../../helpers/IntlMessages';
import { useIntl } from 'react-intl';

import firebaseApp from '../../services/Firebase';
import { setupList } from '../../constants/checklists';
import { openChecklistAndToggleItem } from '../checklist';
import { trackPropertiesSegment } from '../../utils/segment';
import { loadCostCenters } from '../../utils/costCenters';
import FixedSelect from '../common/FixedSelect';
import { Colxx } from '../common/CustomBootstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { getOrderTypesAsOptions } from '../../constants/orderTypes';

const getValidationSchema = (creditCard, messages) => {
  if (creditCard) {
    return Yup.object().shape({
      cardName: Yup.string().required(
        messages['forms.validation.cardName.required']
      ),
      documentNumber: Yup.string(),
      priority: Yup.number()
        .min(1, messages['forms.validation.priority.valid'])
        .max(1000000, messages['forms.validation.priority.valid'])
        .required(messages['forms.validation.priority.required']),
    });
  } else
    return Yup.object().shape({
      cardName: Yup.string().required(
        messages['forms.validation.cardName.required']
      ),
      documentNumber: Yup.string(),
      number: Yup.string()
        .matches(/^\d+$/, messages['forms.validation.cardNumber.invalid'])
        .test(
          'card length',
          messages['forms.validation.cardNumber.invalid.length'],
          (value = '') =>
            value.length === 16 ||
            (value.length === 15 && ['34', '37'].includes(value.slice(0, 2)))
        )
        .required(messages['forms.validation.cardNumber.required']),
      name: Yup.string().required(messages['forms.validation.name.required']),
      expiry: Yup.string()
        .matches(
          /^(0[1-9]|1[012])\/\d{2}$/,
          messages['forms.validation.cardExpiry.valid']
        )
        .required(messages['forms.validation.cardExpiry.required']),
      cvv: Yup.string()
        .matches(/^\d{3,4}$/, messages['forms.validation.cvv.valid'])
        .required(messages['forms.validation.cvv.required']),
      priority: Yup.number()
        .min(1, messages['forms.validation.priority.valid'])
        .max(1000000, messages['forms.validation.priority.valid'])
        .required(messages['forms.validation.priority.required']),
    });
};

const getInitialValues = (creditCard) => {
  if (creditCard) {
    const {
      priority = 1,
      costCenters = [],
      products = [],
      documentNumber = '',
      name = '',
    } = creditCard;

    return {
      cardName: name,
      documentNumber,
      priority,
      costCenters,
      products,
    };
  } else {
    return {
      cvv: '',
      expiry: '',
      name: '',
      number: '',
      cardName: '',
      documentNumber: '',
      priority: 1,
      costCenters: [],
      products: [],
    };
  }
};

export default function CreditCardModal({
  toggleModal,
  creditCardId,
  creditCard,
}) {
  const { messages } = useIntl();
  const [focus, setFocus] = useState('number');
  const [error, setError] = useState(null);
  const [allCostCenters, setAllCostCenters] = useState([]);

  const productOptions = useMemo(() => getOrderTypesAsOptions(messages), []);
  const { initalValues, validationSchema } = useMemo(
    () => ({
      initalValues: getInitialValues(creditCard),
      validationSchema: getValidationSchema(creditCard, messages),
    }),
    [creditCard]
  );

  const {
    auth: { user, organization },
    checklist: { setup },
  } = useSelector(({ auth, checklist }) => ({
    auth,
    checklist,
  }));
  const dispatch = useDispatch();

  const handleInputFocus = (e) => {
    setFocus(e.target.id);
  };

  // Effects
  useEffect(() => {
    if (organization) {
      const fetchCostCenters = async () => {
        try {
          const costCenters = await loadCostCenters(organization.id, true);
          setAllCostCenters(
            costCenters.sort((a, b) => a.label.localeCompare(b.label))
          );
        } catch (err) {
          console.log(err);
        }
      };

      fetchCostCenters();
    }
  }, [organization]);

  // Handler
  const createCreditCard = async (values, setSubmitting) => {
    setError(null);

    try {
      const issuer = Payment.fns.cardType(values.number);

      await firebaseApp.createCreditCardFromOrganization(user.organizationId, {
        cc: {
          name: values.name,
          cvv: values.cvv,
          expiry: values.expiry,
          number: values.number,
          issuer,
        },
        name: values.cardName,
        documentNumber: values.documentNumber,
        priority: values.priority,
        costCenters: values.costCenters,
        products: values.products,
      });

      setSubmitting(false);
      trackPropertiesSegment('Credit card added', {
        user,
        cardName: values.cardName,
        documentNumber: values.documentNumber,
        priority: values.priority,
        costCenters: values.costCenters,
      });

      if (setup && !setup.items[setupList.items.creditCard]?.done) {
        openChecklistAndToggleItem(
          dispatch,
          setupList.id,
          setupList.items.creditCard
        );
      }

      toggleModal();
    } catch (err) {
      if (err.message) setError(err.message);
      else setError(messages['forms.validation.creditCard.genericError']);

      setSubmitting(false);
    }
  };

  const updateCreditCard = async (values, setSubmitting) => {
    setError(null);

    try {
      await firebaseApp.updateCreditCardFromOrganization(
        user.organizationId,
        creditCardId,
        {
          name: values.cardName,
          documentNumber: values.documentNumber,
          priority: values.priority,
          costCenters: values.costCenters,
          products: values.products,
        }
      );

      setSubmitting(false);
      trackPropertiesSegment('Credit card updated', {
        creditCardId,
      });

      toggleModal();
    } catch (err) {
      if (err.message) setError(err.message);
      else setError(messages['forms.validation.creditCard.genericError']);

      setSubmitting(false);
    }
  };

  // Render
  return (
    <Modal
      isOpen={true}
      toggle={toggleModal}
      className="admin credit-card-modal"
    >
      <ModalHeader toggle={toggleModal}>
        <IntlMessages
          id={
            creditCardId
              ? 'admin.policy.creditCard.Modal.Update'
              : 'admin.policy.creditCard.Modal.Add'
          }
        />
      </ModalHeader>
      <Formik
        initialValues={initalValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          creditCard
            ? updateCreditCard(values, setSubmitting)
            : createCreditCard(values, setSubmitting);
        }}
      >
        {({
          values,
          isSubmitting,
          handleChange,
          handleBlur,
          setFieldValue,
        }) => (
          <Form>
            <ModalBody>
              <PerfectScrollbar
                options={{
                  suppressScrollX: true,
                  wheelPropagation: false,
                  useBothWheelAxes: false,
                }}
                style={{ maxHeight: 400 }}
              >
                {error && (
                  <Row>
                    <Alert>{error}</Alert>
                  </Row>
                )}
                <Row className="mx-0">
                  {creditCard ? null : (
                    <Colxx>
                      <Cards
                        cvc={values.cvv}
                        expiry={values.expiry}
                        focused={focus}
                        name={values.name}
                        number={values.number}
                        placeholders={{
                          name: messages['admin.policy.placeholder.name'],
                        }}
                        locale={{
                          valid: messages['admin.policy.placeholder.valid'],
                        }}
                      />
                      <div className="mt-4">
                        <Label className="form-group has-top-label mb-1 w-100">
                          <Field
                            type="input"
                            name="number"
                            id="number"
                            className="form-control"
                            onFocus={handleInputFocus}
                          />
                          <span>
                            <IntlMessages id="admin.policy.creditCard.number" />{' '}
                            *
                          </span>
                          <ErrorMessage
                            name="number"
                            component="div"
                            className="invalid-feedback d-block"
                          />
                        </Label>
                        <Label className="form-group has-top-label mb-1 w-100">
                          <Field
                            type="input"
                            name="name"
                            id="name"
                            className="form-control"
                            onFocus={handleInputFocus}
                            maxLength={30}
                          />
                          <span>
                            <IntlMessages id="admin.policy.creditCard.name" /> *
                          </span>
                          <ErrorMessage
                            name="name"
                            component="div"
                            className="invalid-feedback d-block"
                          />
                        </Label>
                        <InputGroup>
                          <Label className="form-group has-top-label mb-0">
                            <Field name="expiry">
                              {({ field }) => (
                                <MaskedInput
                                  type="text"
                                  {...field}
                                  mask={cardDateMask}
                                  id="expiry"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  onFocus={handleInputFocus}
                                  className="form-control"
                                />
                              )}
                            </Field>
                            <span>
                              <IntlMessages id="admin.policy.creditCard.expiry" />{' '}
                              *
                            </span>
                            <ErrorMessage
                              name="expiry"
                              component="div"
                              className="invalid-feedback d-block"
                            />
                          </Label>
                          <Label className="form-group has-top-label mb-0">
                            <Field
                              type="input"
                              name="cvv"
                              id="cvc"
                              className="form-control"
                              onFocus={handleInputFocus}
                            />
                            <span>
                              <IntlMessages id="admin.policy.creditCard.cvv" />{' '}
                              *
                            </span>
                            <ErrorMessage
                              name="cvv"
                              component="div"
                              className="invalid-feedback d-block"
                            />
                          </Label>
                        </InputGroup>
                      </div>
                    </Colxx>
                  )}
                  <Colxx>
                    <Label className="form-group has-top-label mb-1 w-100">
                      <Field
                        type="input"
                        name="cardName"
                        id="cardName"
                        className="form-control"
                        onFocus={handleInputFocus}
                      />
                      <span>
                        <IntlMessages id="admin.policy.creditCard.cardName" /> *
                      </span>
                      <ErrorMessage
                        name="cardName"
                        component="div"
                        className="invalid-feedback d-block"
                      />
                    </Label>
                    <Label className="form-group has-top-label mb-1 w-100">
                      <Field name="documentNumber">
                        {({ field }) => (
                          <MaskedInput
                            type="text"
                            {...field}
                            mask={cpfMask}
                            id="documentNumber"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            onFocus={handleInputFocus}
                            className="form-control"
                          />
                        )}
                      </Field>
                      <span>
                        <IntlMessages id="admin.policy.creditCard.documentNumber" />{' '}
                      </span>
                      <ErrorMessage
                        name="documentNumber"
                        component="div"
                        className="invalid-feedback d-block"
                      />
                    </Label>
                    <div className="mt-4">
                      <Label className="form-group has-top-label mb-0 w-100 mb-1">
                        <Field
                          type="number"
                          name="priority"
                          id="priority"
                          className="form-control"
                          min={1}
                          max={1000000}
                          onFocus={handleInputFocus}
                        />
                        <IntlMessages id="admin.policy.creditCard.priority" />
                        <ErrorMessage
                          name="priority"
                          component="div"
                          className="invalid-feedback d-block"
                        />
                      </Label>
                      <Label className="form-group has-top-label mb-1 w-100 cost-centers">
                        <Field name="costCenters">
                          {({ field }) => (
                            <FixedSelect
                              className="react-select"
                              classNamePrefix="react-select"
                              isClearable={false}
                              placeholder=""
                              value={field.value}
                              noOptionsMessage={() => {
                                return messages['admin.costCenter.empty'];
                              }}
                              options={allCostCenters}
                              onChange={(values) =>
                                setFieldValue(field.name, values)
                              }
                              maxMenuHeight={150}
                              isMulti
                            />
                          )}
                        </Field>
                        <IntlMessages id="admin.policy.creditCard.cost-centers" />
                        <ErrorMessage
                          name="costCenters"
                          component="div"
                          className="invalid-feedback d-block"
                        />
                      </Label>
                      <Label className="form-group has-top-label mb-0 w-100 cost-centers">
                        <Field name="products">
                          {({ field }) => (
                            <FixedSelect
                              className="react-select"
                              classNamePrefix="react-select"
                              isClearable={false}
                              placeholder=""
                              value={field.value}
                              options={productOptions}
                              onChange={(values) =>
                                setFieldValue(field.name, values)
                              }
                              maxMenuHeight={150}
                              isMulti
                            />
                          )}
                        </Field>
                        <IntlMessages id="admin.policy.creditCard.products" />
                        <ErrorMessage
                          name="products"
                          component="div"
                          className="invalid-feedback d-block"
                        />
                      </Label>
                    </div>
                    <div className="mt-2">
                      <p className="font-weight-semibold fs-0-6rem ml-1 text-primary">
                        * {messages['general.required-fields']}
                      </p>
                    </div>
                  </Colxx>
                </Row>
              </PerfectScrollbar>
            </ModalBody>
            <ModalFooter>
              <div className="d-flex justify-content-between align-items-center">
                <Button
                  type="submit"
                  color="primary"
                  outline
                  size="lg"
                  disabled={isSubmitting}
                >
                  <IntlMessages id="forms.action.save" />
                </Button>
              </div>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
