import { TSearch } from 'src/portao3-legacy/types/orders/orders';
import TravelSearch from './TravelSearch';
import { OrderTypes } from 'src/portao3-legacy/constants/orderTypes';
import { BusParamsConfig } from 'src/portao3-legacy/constants/search/bus';
import BusFilters from './BusFilters.tsx';
import BusResults from './BusResults.tsx';

export default function BusSearch({ search }: { search: TSearch }) {
  return (
    <TravelSearch
      search={search}
      orderType={OrderTypes.BUS}
      paramsConfig={BusParamsConfig}
      FilterComponent={BusFilters}
      ResultsComponent={BusResults as any}
    />
  );
}
