import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import {
  Card,
  TabPane,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  CardBody,
  Row,
} from 'reactstrap';
import { Colxx } from '../../components/common/CustomBootstrap';
import classnames from 'classnames';
import isUndefined from 'lodash/isUndefined';
import map from 'lodash/map';

import FlightCardSummary from './FlightCardSummary';
import FlightCardTopRoutes from './FlightCardTopRoutes';
import FlightCardTopAirlines from './FlightCardTopAirlines';
import TypeAnalyticsLoading from './TypeAnalyticsLoading';

import { OrderTypes } from '../../constants/orderTypes';

import firebaseApp from '../../services/Firebase';

export default function FlightCard({ orders, reservations, parentLoading }) {
  const { messages } = useIntl();

  const [activeTab, setActiveTab] = useState('summary');
  const [airports, setAirports] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadAirports();
  }, [orders]);

  useEffect(() => {
    if (parentLoading) setLoading(true);
  }, [parentLoading]);

  const loadAirports = async () => {
    setLoading(true);

    const airports = {};
    const promises = [];

    for (let i = 0; i < orders.length; i++) {
      const order = orders[i];

      if (order.type === OrderTypes.FLIGHT) {
        for (let j = 0; j < order.journeys.length; j++) {
          const journey = order.journeys[j];

          if (isUndefined(airports[journey.departureStation.iataCode])) {
            promises.push(
              firebaseApp
                .getLocationFromId(journey.departureStation.iataCode)
                .get()
            );

            airports[journey.departureStation.iataCode] = true;
          }

          if (isUndefined(airports[journey.arrivalStation.iataCode])) {
            promises.push(
              firebaseApp
                .getLocationFromId(journey.arrivalStation.iataCode)
                .get()
            );

            airports[journey.arrivalStation.iataCode] = true;
          }
        }
      }
    }

    const results = await Promise.all(promises);

    setAirports(map(results, (result) => result.data()));

    setLoading(false);
  };

  if (parentLoading || loading) {
    return (
      <Card className="mb-4">
        <CardHeader>
          <Nav tabs className="card-header-tabs justify-content-end">
            <div className="card-title flex-fill align-self-center pt-3">
              {messages['containers.dashboard.flight-card.title']}
            </div>
          </Nav>
        </CardHeader>

        <TabContent activeTab={'loading'}>
          <TabPane tabId="loading">
            <CardBody>
              <Row>
                <Colxx sm="12">
                  <TypeAnalyticsLoading />
                </Colxx>
              </Row>
            </CardBody>
          </TabPane>
        </TabContent>
      </Card>
    );
  }

  return (
    <Card className="mb-4">
      <CardHeader>
        <Nav tabs className="card-header-tabs justify-content-end">
          <div className="card-title flex-fill align-self-center pt-3">
            {messages['containers.dashboard.flight-card.title']}
          </div>
          <NavItem>
            <NavLink
              to="#"
              location={{}}
              className={classnames({
                active: activeTab === 'summary',
                'nav-link': true,
              })}
              onClick={() => {
                setActiveTab('summary');
              }}
            >
              {messages['containers.dashboard.card.summary']}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              to="#"
              location={{}}
              className={classnames({
                active: activeTab === 'top_routes',
                'nav-link': true,
              })}
              onClick={() => {
                setActiveTab('top_routes');
              }}
            >
              {messages['containers.dashboard.flight-card.best-stretch']}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              to="#"
              location={{}}
              className={classnames({
                active: activeTab === 'top_airline',
                'nav-link': true,
              })}
              onClick={() => {
                setActiveTab('top_airline');
              }}
            >
              {messages['containers.dashboard.flight-card.best-companies']}
            </NavLink>
          </NavItem>
        </Nav>
      </CardHeader>

      <TabContent activeTab={activeTab}>
        <TabPane tabId="summary">
          <FlightCardSummary
            orders={orders}
            reservations={reservations}
            airports={airports}
          />
        </TabPane>
        <TabPane tabId="top_routes">
          <FlightCardTopRoutes
            orders={orders}
            reservations={reservations}
            airports={airports}
          />
        </TabPane>
        <TabPane tabId="top_airline">
          <FlightCardTopAirlines orders={orders} reservations={reservations} />
        </TabPane>
      </TabContent>
    </Card>
  );
}
