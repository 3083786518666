import { useCallback, useMemo, useState } from 'react';
import SearchResultWrapper from './SearchResultWrapper';
import LoadingHotel from 'src/portao3-legacy/components/search/LoadingHotel';
import { useSelector } from 'react-redux';
import { getAuthRedux } from 'src/portao3-legacy/utils/redux';
import { trackPropertiesSegment } from 'src/portao3-legacy/utils/segment';
import CarItem from './CarItem';
import CarModal from './CarModal';
import ConditionalRenderWrapper from 'src/portao3-legacy/wrappers/ConditionalRenderWrapper';
import FavoritePlacesHOC from 'src/portao3-legacy/hoc/FavoritePlacesHOC';
import SearchMap from './SearchMap/SearchMap';
import useMapHighlightedPin from 'src/portao3-legacy/hooks/useMapHighlightedPin';
import LoadingMap from './SearchMap/LoadingMap';

import { CarFilterQueries } from 'src/portao3-legacy/constants/search/car';
import loadSearchStatus from 'src/portao3-legacy/constants/loadSearchStatus';

import { TCarReservationParams } from 'src/portao3-legacy/hooks/useReserveTravelItem/types';
import { TCarSearchItem } from 'src/portao3-legacy/types/orders/car/carOrder';
import {
  TCarParams,
  TCarSearchParams,
} from 'src/portao3-legacy/types/orders/car/carParams';
import { ITravelResultComponentProps } from 'src/portao3-legacy/types/search';
import {
  TCoordsObject,
  TLocationWithId,
} from 'src/portao3-legacy/types/others';
import { OrderTypes } from 'src/portao3-legacy/constants/orderTypes';

function getRentalsCoords(items: TCarSearchItem[] = []) {
  return items.reduce((coords, item) => {
    if (!(item.rental.code in coords)) {
      coords[item.rental.code] = [item.pickUp.lat, item.pickUp.lng];
    }

    return coords;
  }, {} as TCoordsObject);
}

export default function CarResults({
  searchParams,
  replaceParam,
  status,
  isLoading,
  params,
  reserveItemWithToggle,
  search,
  getRemainingSearch,
  items,
  setParam,
}: ITravelResultComponentProps<
  TCarSearchItem,
  TCarSearchParams,
  TCarParams,
  TCarReservationParams
>) {
  const { user } = useSelector(getAuthRedux);

  // States
  const [selectedItem, setSelectedItem] = useState<TCarSearchItem | null>(null);

  const rentalsCoords = useMemo(() => getRentalsCoords(items), [items]);

  // Hooks
  const { highlightedPin, onSelectHighlightedPin } = useMapHighlightedPin({
    coords: rentalsCoords,
    setSelectedLocationIds: (locations) =>
      replaceParam('selectedRentals', locations),
  });

  // Functions
  const onSelectedDetails = useCallback(
    (carItem: TCarSearchItem) => {
      trackPropertiesSegment('User selected car item', {
        carItem,
        user,
      });

      setSelectedItem(carItem);
    },
    [user]
  );

  const onPinSelect = useCallback((item: TLocationWithId) => {
    setParam('selectedRentals', {
      name: item.id,
      value: true,
      invertValue: true,
    });
  }, []);

  // Render
  return (
    <>
      <ConditionalRenderWrapper show={status !== loadSearchStatus.EMPTY}>
        <div className="search-map mb-5">
          {isLoading ? (
            <LoadingMap />
          ) : (
            <FavoritePlacesHOC>
              <SearchMap
                loading={isLoading}
                pointCoords={rentalsCoords}
                selectedPoints={params.selectedRentals}
                onPinSelect={onPinSelect}
                highlightedPin={highlightedPin as any}
                onLocationSelection={onSelectHighlightedPin}
                withLocationSearch
                city={search.origin?.label}
                selectedPinIconSrc="/assets/img/icons/car_pin.svg"
                pinIconSrc="/assets/img/icons/light_car_pin.svg"
                highlightedPinIconSrc="/assets/img/icons/red_pin.svg"
              />
            </FavoritePlacesHOC>
          )}
        </div>
      </ConditionalRenderWrapper>

      <SearchResultWrapper
        LoadingComponent={LoadingHotel}
        filterQueries={CarFilterQueries as any}
        getRemainingSearch={getRemainingSearch}
        isLoading={isLoading}
        items={items}
        params={params}
        searchParams={searchParams}
        status={status}
        orderType={OrderTypes.CAR}
      >
        {({ paginatedItems }) =>
          paginatedItems.map((item, index) => (
            <CarItem
              key={`result-${item.id}`}
              index={index}
              item={item}
              showDetails={onSelectedDetails}
            />
          ))
        }
      </SearchResultWrapper>

      {selectedItem ? (
        <CarModal
          toggleModal={() => setSelectedItem(null)}
          item={selectedItem}
          reserveItem={reserveItemWithToggle(() => setSelectedItem(null))}
          search={search}
        />
      ) : null}
    </>
  );
}
