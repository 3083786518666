import classNames from 'classnames';
import React from 'react';

export default function MessageWithIcon({
  className = '',
  text,
  Icon,
  iconContainerWidth = '15%',
  iconStyle = {},
  ...props
}) {
  return (
    <div className={classNames('message-with-icon', className)} {...props}>
      <div
        className="icon-container flex-shrink-0 flex-center"
        style={{ width: iconContainerWidth }}
      >
        {Icon ? <Icon className="icon" style={iconStyle} /> : null}
      </div>
      <p className="m-0 p-3 message flex-grow-1 text-primary font-weight-normal">
        {text}
      </p>
    </div>
  );
}
