import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useParams, useHistory } from 'react-router-dom';
import {
  Card,
  Label,
  CustomInput,
  Row,
  Button,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { Colxx } from '../../components/common/CustomBootstrap';
import classnames from 'classnames';
import map from 'lodash/map';
import mouseTrap from 'mousetrap';

import IntlMessages from '../../helpers/IntlMessages';

import firebaseApp from '../../services/Firebase';

import PolicyModal from '../../components/policy/PolicyModal';
import PolicyLoading from './PolicyLoading';
import { trackPropertiesSegment } from '../../utils/segment';
import EmptyResults from '../../components/common/EmptyResults';
import { useIntl } from 'react-intl';
import { policyTypes } from '../../constants/policyOptions';
import { sortByValue } from 'src/portao3-legacy/utils/sort';
import useHistoryWithScroll from 'src/portao3-legacy/hooks/useHistoryWithScroll';

let loadPoliciesEvent = () => {};

export default function PolicyDataListView({ tabId = 'travels' }) {
  const { resourceId } = useParams();
  const { push } = useHistoryWithScroll();
  const { messages } = useIntl();

  const [selectedItems, setSelectedItems] = useState([]);
  const [dropdownSplitOpen, setDropdownSplitOpen] = useState(false);

  const [policies, setPolicies] = useState([]);
  const [isLoading, setLoading] = useState(true);

  const isEmpty = useMemo(() => !policies || policies.length === 0, [policies]);

  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    loadPolicies();

    return () => {
      loadPoliciesEvent();
    };
  }, []);

  useEffect(() => {
    mouseTrap.bind(['ctrl+a', 'command+a'], () => {
      setSelectedItems(map(policies, (x) => x.id));
      return false;
    });

    mouseTrap.bind(['ctrl+d', 'command+d'], () => {
      setSelectedItems([]);
      return false;
    });

    return () => {
      mouseTrap.unbind('ctrl+a');
      mouseTrap.unbind('command+a');
      mouseTrap.unbind('ctrl+d');
      mouseTrap.unbind('command+d');
    };
  }, [policies]);

  // Handle Policies
  const loadPolicies = async () => {
    loadPoliciesEvent = firebaseApp
      .getPoliciesFromOrganization(user.organizationId)
      .where('type', '==', policyTypes[tabId])
      .onSnapshot((policiesSnap) => {
        const policies = [];
        policiesSnap.forEach((policySnap) => {
          policies.push({
            ...policySnap.data(),
            id: policySnap.id,
          });
        });

        setPolicies(sortByValue(policies, 'sort'));
        setLoading(false);
      });
  };

  const deletePolicies = async () => {
    for (let i = 0; i < selectedItems.length; i++) {
      await firebaseApp.deletePolicyFromOrganizationAndId(
        user.organizationId,
        selectedItems[i]
      );
    }

    trackPropertiesSegment('Policies deleted', {
      policies: selectedItems,
      user,
    });

    setSelectedItems([]);
  };

  // Split
  const toggleSplit = () => {
    setDropdownSplitOpen(!dropdownSplitOpen);
  };

  // Selection
  const onCheckItem = (id) => {
    if (selectedItems.includes(id)) {
      setSelectedItems(selectedItems.filter((x) => x !== id));
    } else {
      setSelectedItems([...selectedItems, id]);
    }
  };

  const handleChangeSelectAll = () => {
    if (selectedItems.length >= policies.length) {
      setSelectedItems([]);
    } else {
      setSelectedItems(map(policies, (x) => x.id));
    }
  };

  // Modal
  const openModal = (ruleId) => {
    trackPropertiesSegment('Policy modal opened', { ruleId, user });
    push(`/app/${tabId}/rules/${ruleId}`);
  };

  const closeModal = () => {
    trackPropertiesSegment('Policy modal closed', { user });
    push(`/app/${tabId}/rules`);
  };

  // Render
  if (isLoading) {
    return <PolicyLoading />;
  }

  return (
    <div>
      {isEmpty ? (
        <div className="w-100 d-flex justify-content-center mt-5">
          <EmptyResults
            title={messages['admin.travels.empty.policy.title']}
            description={messages['admin.travels.empty.policy.description']}
            buttonText={messages['admin.travels.empty.policy.btn']}
            className="w-70"
            onClick={() => openModal('new')}
          />
        </div>
      ) : (
        <>
          <Row>
            <Colxx xxs="12" className="mt-5">
              <Button
                color="primary"
                className="m-0 pretty-btn"
                onClick={() => {
                  openModal('new');
                }}
              >
                {messages['general.add']}
              </Button>

              <ButtonDropdown
                isOpen={dropdownSplitOpen}
                toggle={toggleSplit}
                className="float-right"
              >
                <div className="btn btn-primary btn-lg pl-4 pr-0 check-button check-all">
                  <CustomInput
                    className="custom-checkbox mb-0 d-inline-block"
                    type="checkbox"
                    id="checkAll"
                    checked={selectedItems.length >= policies.length}
                    onChange={handleChangeSelectAll}
                    label={
                      <span
                        className={`custom-control-label ${
                          selectedItems.length > 0 &&
                          selectedItems.length < policies.length
                            ? 'indeterminate'
                            : ''
                        }`}
                      />
                    }
                  />
                </div>
                <DropdownToggle
                  caret
                  color="primary"
                  className="dropdown-toggle-split btn-lg"
                />
                <DropdownMenu right>
                  <DropdownItem onClick={deletePolicies}>
                    <IntlMessages id="admin.policy.delete" />
                  </DropdownItem>
                </DropdownMenu>
              </ButtonDropdown>
            </Colxx>
          </Row>
          <Row className="mt-4 mb-5">
            {map(policies, (policy, index) => {
              return (
                <Colxx key={`policy-${index}`} xxs="12" className="mb-3">
                  <Card
                    className={classnames('d-flex flex-row list', {
                      active: selectedItems.includes(policy.id),
                    })}
                  >
                    <div className="pl-2 d-flex flex-grow-1 mw-0">
                      <div className="card-body align-self-center d-flex flex-column flex-lg-row justify-content-between mw-0 align-items-lg-center">
                        <div className="w-10 w-sm-20">{policy['sort']}</div>
                        <div className="w-90 w-sm-80">
                          <p
                            className="a list-item-heading mb-1 truncate pointer"
                            onClick={() =>
                              push(`/app/${tabId}/rules/${policy.id}`)
                            }
                            role="button"
                          >
                            {policy.name}
                          </p>
                        </div>
                      </div>
                      <div className="custom-control custom-checkbox pl-1 align-self-center pr-4">
                        <Label>
                          <CustomInput
                            className="item-check mb-0"
                            type="checkbox"
                            id={`check_${policy.id}`}
                            checked={selectedItems.includes(policy.id)}
                            onChange={() => onCheckItem(policy.id)}
                          />
                        </Label>
                      </div>
                    </div>
                  </Card>
                </Colxx>
              );
            })}
          </Row>
        </>
      )}

      {resourceId && (
        <PolicyModal
          toggleModal={closeModal}
          policyId={resourceId}
          type={tabId}
        />
      )}
    </div>
  );
}
