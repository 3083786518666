import React from 'react';
import { NavLink } from 'react-router-dom';
import { Card, Label, CustomInput } from 'reactstrap';
import classnames from 'classnames';
import { Colxx } from '../common/CustomBootstrap';
import GoogleMaps from '../../constants/GoogleMaps';

function FavoritePlaceImage({ favoritePlace }) {
  return (
    <div className="d-flex active w-10">
      <img
        alt={favoritePlace.name}
        src={`https://maps.googleapis.com/maps/api/staticmap?center=${favoritePlace.address}&zoom=15&size=300x200&maptype=roadmap&key=${GoogleMaps.key}`}
        className="responsive border-0 w-100"
        style={{ objectFit: 'cover' }}
      />
    </div>
  );
}

export default function FavoritePlaceCard({
  selectedItems,
  favoritePlace,
  onCheckItem,
}) {
  return (
    <Colxx xxs="12" className="mb-3">
      <Card
        className={classnames('d-flex flex-row list', {
          active: selectedItems.includes(favoritePlace.id),
        })}
      >
        <div className="d-flex flex-grow-1 mw-0 inherit-radius">
          <FavoritePlaceImage favoritePlace={favoritePlace} />
          <div className="pl-2 card-body align-self-center d-flex flex-column flex-lg-row justify-content-between mw-0 align-items-lg-center">
            <div className="w-60 w-sm-100">
              <p className="list-item-heading small-medium-title font-weight-medium mb-1 truncate">
                <NavLink
                  to={`/app/travels/favorite-places/${favoritePlace.id}`}
                >
                  {favoritePlace.name}
                </NavLink>
              </p>
              <p className="mb-1 text-muted">{favoritePlace.address}</p>
            </div>
            <p className="mb-1 text-muted text-small w-15 w-sm-100">
              {favoritePlace.externalId}
            </p>
            <div className="w-15 w-sm-100"></div>
          </div>
          <div className="custom-control custom-checkbox pl-1 align-self-center pr-4">
            <Label>
              <CustomInput
                className="item-check mb-0"
                type="checkbox"
                id={`check_${favoritePlace.id}`}
                checked={selectedItems.includes(favoritePlace.id)}
                onChange={() => onCheckItem(favoritePlace.id)}
              />
            </Label>
          </div>
        </div>
      </Card>
    </Colxx>
  );
}
