export const getCNAEFromSupplier = (supplier, messages) => {
  const { codigo = '', descricao } = supplier.cnaePrincipal || {};

  if (descricao) {
    if (descricao.startsWith('Comércio varejista de')) {
      return descricao.slice(22);
    } else if (descricao.startsWith('Comércio varejista especializado de')) {
      return descricao.slice(36);
    }
  }

  if (messages) {
    return messages[`cnae.${codigo.slice(0, 2)}`] || messages['cnae.unknown'];
  }

  return '';
};
