import React, { useState } from 'react';
import { stringToMoney } from '../../utils/money';

import { useIntl } from 'react-intl';

import { ReactComponent as ExclamationIcon } from '../../assets/img/icons/exclamation.svg';
import { ReactComponent as CommentIcon } from '../../assets/img/icons/chat.svg';
import { ReactComponent as TrashIcon } from '../../assets/img/icons/close.svg';
import NoItemsImg from '../../assets/img/expenses/no-items.png';
import EmptyResults from '../common/EmptyResults';
import { useMemo } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { getBudgetItemPayload } from '../../utils/budgetItems';

function ExpenseItem({
  item,
  deleteItems,
  onClick,
  onDelete,
  selected,
  editable,
  allowDeletion = true,
}) {
  const { messages } = useIntl();

  const {
    name,
    hasRejectItems,
    total,
    date,
    iconSrc,
    iconAlt,
    canDelete,
    refunded,
  } = useMemo(() => {
    return {
      ...getBudgetItemPayload(item, messages),
      hasRejectItems: item.items?.some((item) => item.rejected),
      canDelete: !item.invoice,
      refunded: item.refunded,
    };
  }, [item]);

  const deleted = useMemo(() => deleteItems.includes(item.id), [deleteItems]);

  // Functions
  const handleDelete = (e) => {
    e.preventDefault();
    e.stopPropagation();

    onDelete();
  };

  // Render
  const renderIcon = () => {
    if (hasRejectItems) {
      return (
        <ExclamationIcon
          className="mr-2"
          width={20}
          height={25}
          title={messages['expense.expense-items.reject']}
        />
      );
    } else if (item.comment) {
      return (
        <CommentIcon
          className="mr-2 text-primary"
          width={20}
          height={25}
          title={messages['expense.expense-items.comment']}
        />
      );
    } else {
      return (
        <img
          src={iconSrc}
          alt={iconAlt}
          style={{ width: 20 }}
          className="mr-2"
        />
      );
    }
  };

  return (
    <div
      className={classNames(
        'item py-3 px-3 d-flex align-items-center justify-content-between pointer',
        {
          deleted,
          selected,
          refunded,
        }
      )}
      onClick={() => onClick(item)}
    >
      <div className="d-flex align-items-center">
        {renderIcon()}
        <div>
          <p
            className="m-0 p-0 text-muted text-uppercase date"
            style={{ fontSize: '0.6rem', lineHeight: 1.5 }}
          >
            {date}
          </p>
          <h2 className="m-0 title font-primary text-dark font-weight-regular">
            {name}
          </h2>
          <p className="bottom-total m-0 p-0">{total}</p>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-between">
        <p className="p-0 m-0 price">{total}</p>
        {editable && canDelete && allowDeletion ? (
          <TrashIcon
            className="ml-3 remove-icon"
            onClick={handleDelete}
            width="0.8rem"
            height="0.8rem"
          />
        ) : null}
      </div>
    </div>
  );
}

export default function BudgetItems({
  items: expenses,
  onClick = () => {},
  removeExpenseFromRefund = () => {},
  selectedExpense = {},
  small,
  editable,
  onCreate,
  allowBudgetItemCreation,
  allowDeletion = true,
  showHeaderDate = true,
}) {
  const { messages } = useIntl();

  // States
  const [deleteItems, setDeleteItems] = useState([]);

  const sortedExpenses = useMemo(() => {
    const sorted = expenses
      .sort((a, b) => b.date.localeCompare(a.date))
      .reduce((obj, expense) => {
        const date = expense.date.split('T')[0];

        if (date in obj) obj[date].items.push(expense);
        else
          obj[date] = {
            date,
            formattedDate: moment(date, 'YYYY-MM-DD').format('DD MMMM YYYY'),
            items: [expense],
          };

        return obj;
      }, {});

    return Object.values(sorted);
  }, [expenses]);

  // Functions
  const handleItemDelete = async (expense) => {
    if (editable) {
      try {
        const oldDeletedItems = deleteItems;
        setDeleteItems([...deleteItems, expense.id]);

        await removeExpenseFromRefund(expense);

        setDeleteItems(oldDeletedItems);
      } catch (err) {
        console.error(err);
      }
    }
  };

  // Render
  return (
    <div className={classNames('expense-items', { small })}>
      {expenses.length === 0 ? (
        <EmptyResults
          title={messages['refund.no-items.title']}
          description={messages['refund.no-items.description']}
          className="w-90 mx-auto my-5"
          buttonText="Criar despesa"
          onClick={onCreate}
          canClickBtn={allowBudgetItemCreation}
        >
          <img
            src={NoItemsImg}
            className="w-100 px-4"
            alt={messages['expenses.empty.none-added']}
            title={messages['expenses.empty.none-added']}
          />
        </EmptyResults>
      ) : (
        sortedExpenses.map(({ formattedDate, items, date }) => (
          <div className="expenses-container" key={date}>
            {showHeaderDate && (
              <h4 className="date fs-0-8rem m-0 p-0 ml-3 mb-2 text-uppercase font-weight-medium">
                {formattedDate}
              </h4>
            )}
            <div key={date} className="expense-items mb-5 pb-2">
              {items.map((expense) => (
                <ExpenseItem
                  item={expense}
                  key={expense.id}
                  onClick={onClick}
                  onDelete={() => handleItemDelete(expense)}
                  deleteItems={deleteItems}
                  selected={expense.id === selectedExpense.id}
                  editable={editable}
                  dateFormat={'HH:mm'}
                  allowDeletion={allowDeletion}
                />
              ))}
            </div>
          </div>
        ))
      )}
    </div>
  );
}
