import React from 'react';
import ContentLoader from 'react-content-loader';

export default function LoadingTotal() {
  return (
    <ContentLoader
      speed={2}
      width={90}
      height={190}
      viewBox="0 0 90 190"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="50" y="60" rx="3" ry="3" width="40" height="30" />
      <rect x="10" y="100" rx="3" ry="3" width="80" height="12" />
      <rect x="0" y="120" rx="3" ry="3" width="90" height="30" />
    </ContentLoader>
  );
}
