import React from 'react';
import { useIntl } from 'react-intl';
import HotelFormParams from '../../Params/HotelFormParams';

export default function HotelParams({ params = {}, onParamChange = () => {} }) {
  const { messages } = useIntl();

  // Functions
  const handleParamChange = (name) => (value) => onParamChange(name, value);

  // Render
  return (
    <div className="hotel-params">
      <h4 className="font-primary title">
        {messages['itinerary.hotel-params.title']}
      </h4>
      <p className="font-primary description">
        {messages['itinerary.itinerary-params.description']}
      </p>

      <div className="mt-3">
        <HotelFormParams
          adults={params.adults}
          setAdults={handleParamChange('adults')}
          nationality={params.nationality}
          setNationality={handleParamChange('nationality')}
        />
      </div>
    </div>
  );
}
