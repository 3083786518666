import { expenseTypes } from '../../../constants/refundTypes';
import { getOrganizationConfig } from '../../../utils/organization';

export function isBudgetItemsValid({
  budget = {},
  expenses = [],
  messages = {},
  organization = {},
}) {
  const filteredExpenses = expenses.filter(({ refunded }) => !refunded);

  if (!filteredExpenses.length) return { isValid: false };

  if (budget.administrative) return { isValid: true };

  const filters = [
    {
      label: 'refund.not-without-receipt',
      value: !getOrganizationConfig(
        organization.config,
        'refunds.withoutReceipt'
      ),
      checkFunction: ({ receipts = [], type }) =>
        type !== expenseTypes.KM && !receipts.length,
    },
    {
      label: 'refund.require-category',
      value: getOrganizationConfig(
        organization.config,
        'refunds.requireCategory'
      ),
      checkFunction: ({ category = {}, type }) => type !== expenseTypes.KM && !category.value,
    },
  ].filter(({ value }) => value);

  if (filters.length) {
    for (
      let i = 0,
        length = filteredExpenses.length,
        expense = filteredExpenses[0];
      i < length;
      i++, expense = filteredExpenses[i]
    ) {
      const invalidExpenseFilter = filters.find(({ checkFunction }) =>
        checkFunction(expense)
      );

      if (invalidExpenseFilter) {
        return { isValid: false, title: messages[invalidExpenseFilter.label] };
      }
    }
  }

  return { isValid: true };
}
