import { useCallback, useState } from 'react';
import pickBy from 'lodash/pickBy';
import useDataArchive from 'src/portao3-legacy/hooks/useDataArchive';
import HotelResults from './HotelResults.tsx';
import { getFavoriteHotels } from 'src/portao3-legacy/utils/getFavoriteHotel';
import { XOR } from 'src/portao3-legacy/utils/others.js';
import { useSelector } from 'react-redux';
import { getAuthRedux } from 'src/portao3-legacy/utils/redux';
import TravelSearch from './TravelSearch.tsx';
import HotelFilters from './HotelFilters.tsx';

import { OrderTypes } from 'src/portao3-legacy/constants/orderTypes';
import { HotelParamsConfig } from 'src/portao3-legacy/constants/search/hotel.ts';

import { TSearch } from 'src/portao3-legacy/types/orders/orders';
import { THotelSearchItem } from 'src/portao3-legacy/types/orders/hotel/hotelOrder';
import { TFavoriteHotel } from 'src/portao3-legacy/types/orders/hotel/others';
import { THotelSearchParams } from 'src/portao3-legacy/types/orders/hotel/hotelParams';
import { TFormatResult } from 'src/portao3-legacy/hooks/useTravelSearch/useTravelSearch.ts';

export default function HotelSearch({ search }: { search: TSearch }) {
  const { user } = useSelector(getAuthRedux);

  // Hooks
  const { getDataFromArchive } = useDataArchive();

  // States
  const [favoriteHotelIds, setFavoriteHotelIds] = useState<string[]>([]);

  // Search
  const formatResult = useCallback(
    async ({ results }: TFormatResult<THotelSearchItem>) => {
      const favoriteHotels = (await getFavoriteHotels({
        user,
        getDataFromArchive,
      })) as TFavoriteHotel[];

      const favoriteHotelIds = new Set(
        favoriteHotels.map(({ iterpec }) => iterpec.toString())
      );

      setFavoriteHotelIds([...favoriteHotelIds]);

      return results
        .map((result) => {
          if (favoriteHotelIds.has(result.hotelId)) result.favorite = true;
          return result;
        })
        .sort((a, b) => {
          if (XOR(a.favorite, b.favorite)) return a.favorite ? 1 : -1;
          else return a.fare.total - b.fare.total;
        }) as THotelSearchItem[];
    },
    [user]
  );

  const formatParams = useCallback(async (data: THotelSearchParams) => {
    if (data.coords)
      data.coords = pickBy(data.coords, (coord) => !coord.includes(null));

    return data;
  }, []);

  // Render
  return (
    <TravelSearch
      orderType={OrderTypes.HOTEL}
      search={search}
      paramsConfig={HotelParamsConfig}
      formatParams={formatParams}
      formatResult={formatResult}
      FilterComponent={HotelFilters}
      ResultsComponent={HotelResults as any}
      favoriteHotelIds={favoriteHotelIds}
    />
  );
}
