import React from 'react';

export default function Badge({
  children,
  color,
  className = '',
  style = {},
  ...props
}) {
  return (
    <div
      className={`badge ${className}`}
      style={{ ...style, backgroundColor: color }}
      {...props}
    >
      {children}
    </div>
  );
}
