import moment from 'moment';
import { useMemo } from 'react';

export default function Duration({ duration }) {
  const durationTravel = useMemo(() => {
    if (duration) {
      const hours = Math.floor(duration);
      const minutes = Math.floor((duration - Math.floor(duration)) * 60);

      return `${hours}h ${minutes}m`;
    }
  }, [duration]);

  return <div>{durationTravel}</div>;
}
