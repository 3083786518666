import moment from 'moment';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { stringToMoney } from '../../../../../../utils/money';

export default function SelectedRoomCard({
  reservation,
  room,
  style = {},
  result,
  className = '',
}) {
  const { messages, formatMessage } = useIntl();

  const {
    roomName,
    roomImage,
    total,
    hasFreeCancel,
    cancelMessage,
    hasBreakfast,
    reservationPeriod,
  } = useMemo(() => {
    const today = moment().format('YYYY-MM-DD');
    const search = result.search;
    const hasFreeCancel = room.cancellation
      ? room.cancellation[0].startDate >= today
      : false;

    return {
      roomName: room.description,
      roomImage:
        room.info?.photos?.length > 0
          ? room.info.photos[0]
          : reservation.hotelDetails.hotel.mainPhoto,
      total: stringToMoney(room.fare, 0, room.currency),
      hasFreeCancel,
      cancelMessage: hasFreeCancel
        ? formatMessage(
            {
              id: 'order.alternative.cancellation',
            },
            {
              date: moment(room.cancellation[0].startDate, 'YYYY-MM-DD').format(
                'DD/MM/YYYY'
              ),
            }
          )
        : '',
      hasBreakfast: room.board?.breakfast,
      reservationPeriod: `${search.outbound_date.format(
        'DD MMM'
      )} - ${search.inbound_date.format('DD MMM')}`,
    };
  }, [reservation, room, result]);

  return (
    <div
      className={`selected-room-card flex-shrink-0 ${className}`}
      style={style}
    >
      <img src={roomImage} alt={roomName} className="room-image bg-secondary" />
      <div className="room-text d-flex flex-column justify-content-between">
        <div>
          <p className="text-primary time m-0 font-weight-semibold text-uppercase">
            {reservationPeriod}
          </p>
          <h2 className="room-name text-dark m-0 font-primary">{roomName}</h2>
        </div>
        <div className="d-flex justify-content-between">
          <div>
            {hasBreakfast && (
              <img
                className="mr-2"
                style={{ width: 17 }}
                src="/assets/img/icons/amenities/meal-breakfast.svg"
                alt={
                  messages['containers.search.hotel-modal.breakfast-included']
                }
                title={
                  messages['containers.search.hotel-modal.breakfast-included']
                }
              />
            )}
            {hasFreeCancel && (
              <img
                style={{ width: 17 }}
                src="/assets/img/icons/cancel.svg"
                alt={cancelMessage}
                title={cancelMessage}
              />
            )}
          </div>
          <p className="m-0 text-dark total font-weight-medium">{total}</p>
        </div>
      </div>
    </div>
  );
}
