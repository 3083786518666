import classNames from 'classnames';
import React from 'react';
import { Row } from 'reactstrap';
import { Colxx } from '../../components/common/CustomBootstrap';
import BudgetsInfo from '../expenses/dashboard/BudgetInfo';
import BudgetRelatedInfo from '../expenses/dashboard/RelatedInfo/BudgetRelatedInfo';

export default function BudgetDataInfo({
  className = '',
  budgets = [],
  getData,
}) {
  return (
    <Row className={classNames('general-data-info', className)}>
      <Colxx md="12" lg="4" className="mb-5">
        <BudgetsInfo budgets={budgets} getData={getData} showPriceInfo />
      </Colxx>
      <Colxx md="12" lg="8" className="mb-5">
        <BudgetRelatedInfo budgets={budgets} getData={getData} />
      </Colxx>
    </Row>
  );
}
