import React from 'react';
import { useIntl } from 'react-intl';
import { Input, Label } from 'reactstrap';
import { SliderTooltip } from '../../../../components/common/SliderTooltips';
import IntlMessages from '../../../../helpers/IntlMessages';

export default function CommonParams({
  params = {},
  onParamChange = () => {},
}) {
  const { messages } = useIntl();

  // Functions
  const handleParamChange = (name) => (value) => onParamChange(name, value);

  // Render
  return (
    <div className="commom-params">
      <h4 className="font-primary title">
        {messages['itinerary.itinerary-params.title']}
      </h4>
      <p className="font-primary description">
        {messages['itinerary.itinerary-params.description']}
      </p>

      <Label
        className="form-group has-top-label w-100 position-relative"
        style={{ left: 0 }}
      >
        <div className="form-control range-slider">
          <div className="pb-4 mb-3">
            <SliderTooltip
              min={1}
              max={500}
              allowCross={false}
              pushable
              onAfterChange={handleParamChange('radius')}
              defaultValue={params.radius}
            />
          </div>
        </div>

        <IntlMessages id="itinerary.itinerary-params.radius" />
      </Label>

      <div className="w-100 mt-2">
        <Label
          className="form-group has-top-label w-100 position-relative"
          style={{ left: 0 }}
        >
          <Input
            type="select"
            onChange={(e) =>
              handleParamChange('roundTrip')(
                e.target.value === 'true' ? true : false
              )
            }
            value={params.roundTrip}
          >
            <option value={true}>
              {messages['itinerary.itinerary-params.round-trip.yes']}
            </option>
            <option value={false}>
              {messages['itinerary.itinerary-params.round-trip.no']}
            </option>
          </Input>
          <IntlMessages id="itinerary.itinerary-params.round-trip" />
        </Label>
      </div>
    </div>
  );
}
