import React, { useState } from 'react';
import { useMemo } from 'react';
import Room from './Room';

export default function RoomsTab({
  reservations,
  results,
  goBack,
  onRoomsSelection,
  selectedRooms: alreadySelectedRooms,
  direction,
}) {
  const [currentRoom, setCurrentRoom] = useState(0);

  const selectedRoom = useMemo(
    () => reservations[0].hotelDetails.selectedRoom,
    reservations
  );
  const [selectedRooms, setSelectedRooms] = useState(
    alreadySelectedRooms || new Array(results.length).fill(null)
  );

  // Functions
  const handleGoBack = () => {
    if (currentRoom === 0) {
      goBack();
    } else setCurrentRoom(currentRoom - 1);
  };

  const handleGoForward = (selectedRoom) => {
    const newSelectedRooms = [...selectedRooms];
    newSelectedRooms[currentRoom] = selectedRoom;
    setSelectedRooms(newSelectedRooms);

    const nextCurrentRoom = currentRoom + 1;

    if (nextCurrentRoom >= results.length) {
      onRoomsSelection(newSelectedRooms);
    } else setCurrentRoom(nextCurrentRoom);
  };

  return (
    <div className={`h-100 show-up ${direction}`}>
      <Room
        key={`room-${currentRoom}`}
        room={results[currentRoom]}
        alreadySelectedRoom={selectedRooms[currentRoom]}
        reservations={reservations}
        reservationSelectedRoom={selectedRoom}
        goBack={handleGoBack}
        goForward={handleGoForward}
      />
    </div>
  );
}
