import React from 'react';
import { Label, Row } from 'reactstrap';
import { Colxx } from '../../components/common/CustomBootstrap';

import { ReactComponent as TravelImg } from '../../assets/img/config/travels.svg';
import { useIntl } from 'react-intl';
import LocaleSelector from '../../components/navs/LocaleSelector';
import { useSelector } from 'react-redux';
import { validateLanguage } from '../../lang';
import { getOrganizationConfig } from '../../utils/organization';
import { useMemo } from 'react';
import { languages } from '../../constants/languages';
import firebaseApp from '../../services/Firebase';
import debounce from 'lodash/debounce';

const updateLocale = debounce(async (organizationId, locale) => {
  return firebaseApp.updateOrganizationLocaleConfig(organizationId, locale);
}, 1000);

export default function LanguageView() {
  const { organization } = useSelector(({ auth }) => auth);
  const { messages } = useIntl();

  const defaultLanguage = useMemo(() => {
    const locale = validateLanguage(
      getOrganizationConfig(organization.config, 'locale')
    );
    return languages.find(({ value }) => value === locale);
  }, []);

  // Functions
  const onLocaleChange = ({ value }) => {
    updateLocale(organization.id, value);
  };

  // Render
  return (
    <Row className="travel-data-list-view d-flex justify-content-between mt-5 ml-3 w-100">
      <Colxx
        xxs="12"
        md="5"
        className="d-flex justify-content-center align-items-start"
      >
        <TravelImg width={'85%'} title="" />
      </Colxx>
      <Colxx xxs="12" md="7" className="mt-3 pl-3">
        <p className="text-one w-70 mt-3 slide-left font-weight-medium">
          {messages['admin.others.languages.description']}
        </p>
        <div
          className="slide-left mt-4 mb-5"
          style={{ animationDelay: '25ms' }}
        >
          <Label className="d-block mb-3 d-flex align-items-center">
            <span className="fs-0-9rem mr-2">
              {messages['admin.others.languages.options.title']}:
            </span>
            <LocaleSelector
              className="fs-0-9rem bottom-purple-line"
              options={languages}
              defaultValue={defaultLanguage}
              onChange={onLocaleChange}
            />
          </Label>
        </div>
      </Colxx>
    </Row>
  );
}
