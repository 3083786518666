import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import moment from 'moment';
import InformationCard from '../../InformationCard';
import CurrencyWrapper from '../../../common/CurrencyWrapper';
import { ReactComponent as MoneyIcon } from '../../../../assets/img/icons/money-minimalist.svg';
import { ReactComponent as CalenderIcon } from '../../../../assets/img/icons/calender.svg';
import { update } from 'lodash';

export default function HotelSearchInformantion({
  item,
  selectedRoom,
  search,
}) {
  const { messages } = useIntl();

  const { dailyValue, anticipation } = useMemo(() => {
    const checkOut = moment(search.destinationDate, 'DD/MM/YYYY').format(
      'DD-MM-YYYY'
    );
    const checkIn = moment(search.originDate, 'DD/MM/YYYY').format(
      'DD-MM-YYYY'
    );

    const dailyValue = (
      selectedRoom.fare /
      Number(
        moment(checkOut, 'DD-MM-YYYY').diff(
          moment(checkIn, 'DD-MM-YYYY'),
          'days'
        )
      )
    ).toFixed(2);

    const anticipation = moment(checkIn, 'DD-MM-YYYY').diff(moment(), 'days');

    return { dailyValue, anticipation };
  }, [selectedRoom, search]);

  return (
    <div className="d-flex">
      <InformationCard
        title={messages['travel.details.hotel.dailyValue']}
        value={
          <CurrencyWrapper value={dailyValue} decimalPlaces={0}>
            {({ formattedValue }) => formattedValue}
          </CurrencyWrapper>
        }
        Icon={MoneyIcon}
        search={true}
      />
      <InformationCard
        title={messages['travel.details.advance']}
        value={`${anticipation} ${messages['general.days']}`}
        Icon={CalenderIcon}
        search={true}
      />
    </div>
  );
}
