import React from 'react';
import InitialTab from './InitialTab';
import IncreaseDaysTab from './IncreaseDays/Tabs/IncreaseDaysTab';
import SlidingComponents from '../../../SlidingComponents';
import CancellationModalTab from '../../CancellationInfoTab';

export const TABS = {
  INITIAL: 'initial',
  INCREASE_DAYS: 'increase',
  CANCEL: 'cancel',
};

const COMPONENTS = {
  [TABS.INITIAL]: InitialTab,
  [TABS.INCREASE_DAYS]: IncreaseDaysTab,
  [TABS.CANCEL]: ({ goForAlteration, setCurrentTab, ...props }) => (
    <CancellationModalTab
      goToInitial={() => setCurrentTab(TABS.INITIAL)}
      closeTab={goForAlteration}
      {...props}
    />
  ),
};

export default function HotelAlteration(props) {
  return (
    <SlidingComponents
      components={COMPONENTS}
      initialTab={TABS.INITIAL}
      className="hotel-alteration h-100"
      {...props}
    />
  );
}
