export default [
  { value: 'ADP', label: '(ADP) Andorran Peseta' },
  { value: 'AED', label: '(AED) UAE Dirham' },
  { value: 'AFA', label: '(AFA) Afghani' },
  { value: 'AFN', label: '(AFN) Afghani' },
  { value: 'ALK', label: '(ALK) Old Lek' },
  { value: 'ALL', label: '(ALL) Lek' },
  { value: 'AMD', label: '(AMD) Armenian Dram' },
  { value: 'ANG', label: '(ANG) Netherlands Antillean Guilder' },
  { value: 'AOA', label: '(AOA) Kwanza' },
  { value: 'AOK', label: '(AOK) Kwanza' },
  { value: 'AON', label: '(AON) New Kwanza' },
  { value: 'AOR', label: '(AOR) Kwanza Reajustado' },
  { value: 'ARA', label: '(ARA) Austral' },
  { value: 'ARP', label: '(ARP) Peso Argentino' },
  { value: 'ARS', label: '(ARS) Argentine Peso' },
  { value: 'ARY', label: '(ARY) Peso' },
  { value: 'ATS', label: '(ATS) Schilling' },
  { value: 'AUD', label: '(AUD) Australian Dollar' },
  { value: 'AWG', label: '(AWG) Aruban Florin' },
  { value: 'AYM', label: '(AYM) Azerbaijan Manat' },
  { value: 'AZM', label: '(AZM) Azerbaijanian Manat' },
  { value: 'AZN', label: '(AZN) Azerbaijan Manat' },
  { value: 'BAD', label: '(BAD) Dinar' },
  { value: 'BAM', label: '(BAM) Convertible Mark' },
  { value: 'BBD', label: '(BBD) Barbados Dollar' },
  { value: 'BDT', label: '(BDT) Taka' },
  { value: 'BEC', label: '(BEC) Convertible Franc' },
  { value: 'BEF', label: '(BEF) Belgian Franc' },
  { value: 'BEL', label: '(BEL) Financial Franc' },
  { value: 'BGJ', label: '(BGJ) Lev A/52' },
  { value: 'BGK', label: '(BGK) Lev A/62' },
  { value: 'BGL', label: '(BGL) Lev' },
  { value: 'BGN', label: '(BGN) Bulgarian Lev' },
  { value: 'BHD', label: '(BHD) Bahraini Dinar' },
  { value: 'BIF', label: '(BIF) Burundi Franc' },
  { value: 'BMD', label: '(BMD) Bermudian Dollar' },
  { value: 'BND', label: '(BND) Brunei Dollar' },
  { value: 'BOB', label: '(BOB) Boliviano' },
  { value: 'BOP', label: '(BOP) Peso boliviano' },
  { value: 'BOV', label: '(BOV) Mvdol' },
  { value: 'BRB', label: '(BRB) Cruzeiro' },
  { value: 'BRC', label: '(BRC) Cruzado' },
  { value: 'BRE', label: '(BRE) Cruzeiro' },
  { value: 'BRL', label: '(BRL) Real Brasileiro' },
  { value: 'BRN', label: '(BRN) New Cruzado' },
  { value: 'BRR', label: '(BRR) Cruzeiro Real' },
  { value: 'BSD', label: '(BSD) Bahamian Dollar' },
  { value: 'BTN', label: '(BTN) Ngultrum' },
  { value: 'BUK', label: '(BUK) Kyat' },
  { value: 'BWP', label: '(BWP) Pula' },
  { value: 'BYB', label: '(BYB) Belarusian Ruble' },
  { value: 'BYN', label: '(BYN) Belarusian Ruble' },
  { value: 'BYR', label: '(BYR) Belarusian Ruble' },
  { value: 'BZD', label: '(BZD) Belize Dollar' },
  { value: 'CAD', label: '(CAD) Canadian Dollar' },
  { value: 'CDF', label: '(CDF) Congolese Franc' },
  { value: 'CHC', label: '(CHC) WIR Franc (for electronic)' },
  { value: 'CHE', label: '(CHE) WIR Euro' },
  { value: 'CHF', label: '(CHF) Swiss Franc' },
  { value: 'CHW', label: '(CHW) WIR Franc' },
  { value: 'CLF', label: '(CLF) Unidad de Fomento' },
  { value: 'CLP', label: '(CLP) Chilean Peso' },
  { value: 'CNY', label: '(CNY) Yuan Renminbi' },
  { value: 'COP', label: '(COP) Colombian Peso' },
  { value: 'COU', label: '(COU) Unidad de Valor Real' },
  { value: 'CRC', label: '(CRC) Costa Rican Colon' },
  { value: 'CSD', label: '(CSD) Serbian Dinar' },
  { value: 'CSJ', label: '(CSJ) Krona A/53' },
  { value: 'CSK', label: '(CSK) Koruna' },
  { value: 'CUC', label: '(CUC) Peso Convertible' },
  { value: 'CUP', label: '(CUP) Cuban Peso' },
  { value: 'CVE', label: '(CVE) Cabo Verde Escudo' },
  { value: 'CYP', label: '(CYP) Cyprus Pound' },
  { value: 'CZK', label: '(CZK) Czech Koruna' },
  { value: 'DDM', label: '(DDM) Mark der DDR' },
  { value: 'DEM', label: '(DEM) Deutsche Mark' },
  { value: 'DJF', label: '(DJF) Djibouti Franc' },
  { value: 'DKK', label: '(DKK) Danish Krone' },
  { value: 'DOP', label: '(DOP) Dominican Peso' },
  { value: 'DZD', label: '(DZD) Algerian Dinar' },
  { value: 'ECS', label: '(ECS) Sucre' },
  { value: 'ECV', label: '(ECV) Unidad de Valor Constante (UVC)' },
  { value: 'EEK', label: '(EEK) Kroon' },
  { value: 'EGP', label: '(EGP) Egyptian Pound' },
  { value: 'ERN', label: '(ERN) Nakfa' },
  { value: 'ESA', label: '(ESA) Spanish Peseta' },
  { value: 'ESP', label: '(ESP) Spanish Peseta' },
  { value: 'ETB', label: '(ETB) Ethiopian Birr' },
  { value: 'EUR', label: '(EUR) Euro' },
  { value: 'FIM', label: '(FIM) Markka' },
  { value: 'FJD', label: '(FJD) Fiji Dollar' },
  { value: 'FKP', label: '(FKP) Falkland Islands Pound' },
  { value: 'FRF', label: '(FRF) French Franc' },
  { value: 'GBP', label: '(GBP) Pound Sterling' },
  { value: 'GEK', label: '(GEK) Georgian Coupon' },
  { value: 'GEL', label: '(GEL) Lari' },
  { value: 'GHC', label: '(GHC) Cedi' },
  { value: 'GHP', label: '(GHP) Ghana Cedi' },
  { value: 'GHS', label: '(GHS) Ghana Cedi' },
  { value: 'GIP', label: '(GIP) Gibraltar Pound' },
  { value: 'GMD', label: '(GMD) Dalasi' },
  { value: 'GNE', label: '(GNE) Syli' },
  { value: 'GNF', label: '(GNF) Guinean Franc' },
  { value: 'GNS', label: '(GNS) Syli' },
  { value: 'GQE', label: '(GQE) Ekwele' },
  { value: 'GRD', label: '(GRD) Drachma' },
  { value: 'GTQ', label: '(GTQ) Quetzal' },
  { value: 'GWE', label: '(GWE) Guinea Escudo' },
  { value: 'GWP', label: '(GWP) Guinea-Bissau Peso' },
  { value: 'GYD', label: '(GYD) Guyana Dollar' },
  { value: 'HKD', label: '(HKD) Hong Kong Dollar' },
  { value: 'HNL', label: '(HNL) Lempira' },
  { value: 'HRD', label: '(HRD) Croatian Dinar' },
  { value: 'HRK', label: '(HRK) Kuna' },
  { value: 'HTG', label: '(HTG) Gourde' },
  { value: 'HUF', label: '(HUF) Forint' },
  { value: 'IDR', label: '(IDR) Rupiah' },
  { value: 'IEP', label: '(IEP) Irish Pound' },
  { value: 'ILP', label: '(ILP) Pound' },
  { value: 'ILR', label: '(ILR) Old Shekel' },
  { value: 'ILS', label: '(ILS) New Israeli Sheqel' },
  { value: 'INR', label: '(INR) Indian Rupee' },
  { value: 'IQD', label: '(IQD) Iraqi Dinar' },
  { value: 'IRR', label: '(IRR) Iranian Rial' },
  { value: 'ISJ', label: '(ISJ) Old Krona' },
  { value: 'ISK', label: '(ISK) Iceland Krona' },
  { value: 'ITL', label: '(ITL) Italian Lira' },
  { value: 'JMD', label: '(JMD) Jamaican Dollar' },
  { value: 'JOD', label: '(JOD) Jordanian Dinar' },
  { value: 'JPY', label: '(JPY) Yen' },
  { value: 'KES', label: '(KES) Kenyan Shilling' },
  { value: 'KGS', label: '(KGS) Som' },
  { value: 'KHR', label: '(KHR) Riel' },
  { value: 'KMF', label: '(KMF) Comorian Franc' },
  { value: 'KPW', label: '(KPW) North Korean Won' },
  { value: 'KRW', label: '(KRW) Won' },
  { value: 'KWD', label: '(KWD) Kuwaiti Dinar' },
  { value: 'KYD', label: '(KYD) Cayman Islands Dollar' },
  { value: 'KZT', label: '(KZT) Tenge' },
  { value: 'LAJ', label: '(LAJ) Pathet Lao Kip' },
  { value: 'LAK', label: '(LAK) Lao Kip' },
  { value: 'LBP', label: '(LBP) Lebanese Pound' },
  { value: 'LKR', label: '(LKR) Sri Lanka Rupee' },
  { value: 'LRD', label: '(LRD) Liberian Dollar' },
  { value: 'LSL', label: '(LSL) Loti' },
  { value: 'LSM', label: '(LSM) Loti' },
  { value: 'LTL', label: '(LTL) Lithuanian Litas' },
  { value: 'LTT', label: '(LTT) Talonas' },
  { value: 'LUC', label: '(LUC) Luxembourg Convertible Franc' },
  { value: 'LUF', label: '(LUF) Luxembourg Franc' },
  { value: 'LUL', label: '(LUL) Luxembourg Financial Franc' },
  { value: 'LVL', label: '(LVL) Latvian Lats' },
  { value: 'LVR', label: '(LVR) Latvian Ruble' },
  { value: 'LYD', label: '(LYD) Libyan Dinar' },
  { value: 'MAD', label: '(MAD) Moroccan Dirham' },
  { value: 'MDL', label: '(MDL) Moldovan Leu' },
  { value: 'MGA', label: '(MGA) Malagasy Ariary' },
  { value: 'MGF', label: '(MGF) Malagasy Franc' },
  { value: 'MKD', label: '(MKD) Denar' },
  { value: 'MLF', label: '(MLF) Mali Franc' },
  { value: 'MMK', label: '(MMK) Kyat' },
  { value: 'MNT', label: '(MNT) Tugrik' },
  { value: 'MOP', label: '(MOP) Pataca' },
  { value: 'MRO', label: '(MRO) Ouguiya' },
  { value: 'MRU', label: '(MRU) Ouguiya' },
  { value: 'MTL', label: '(MTL) Maltese Lira' },
  { value: 'MTP', label: '(MTP) Maltese Pound' },
  { value: 'MUR', label: '(MUR) Mauritius Rupee' },
  { value: 'MVQ', label: '(MVQ) Maldive Rupee' },
  { value: 'MVR', label: '(MVR) Rufiyaa' },
  { value: 'MWK', label: '(MWK) Malawi Kwacha' },
  { value: 'MXN', label: '(MXN) Mexican Peso' },
  { value: 'MXP', label: '(MXP) Mexican Peso' },
  { value: 'MXV', label: '(MXV) Mexican Unidad de Inversion (UDI)' },
  { value: 'MYR', label: '(MYR) Malaysian Ringgit' },
  { value: 'MZE', label: '(MZE) Mozambique Escudo' },
  { value: 'MZM', label: '(MZM) Mozambique Metical' },
  { value: 'MZN', label: '(MZN) Mozambique Metical' },
  { value: 'NAD', label: '(NAD) Namibia Dollar' },
  { value: 'NGN', label: '(NGN) Naira' },
  { value: 'NIC', label: '(NIC) Cordoba' },
  { value: 'NIO', label: '(NIO) Cordoba Oro' },
  { value: 'NLG', label: '(NLG) Netherlands Guilder' },
  { value: 'NOK', label: '(NOK) Norwegian Krone' },
  { value: 'NPR', label: '(NPR) Nepalese Rupee' },
  { value: 'NZD', label: '(NZD) New Zealand Dollar' },
  { value: 'OMR', label: '(OMR) Rial Omani' },
  { value: 'PAB', label: '(PAB) Balboa' },
  { value: 'PEH', label: '(PEH) Sol' },
  { value: 'PEI', label: '(PEI) Inti' },
  { value: 'PEN', label: '(PEN) Sol' },
  { value: 'PES', label: '(PES) Sol' },
  { value: 'PGK', label: '(PGK) Kina' },
  { value: 'PHP', label: '(PHP) Philippine Peso' },
  { value: 'PKR', label: '(PKR) Pakistan Rupee' },
  { value: 'PLN', label: '(PLN) Zloty' },
  { value: 'PLZ', label: '(PLZ) Zloty' },
  { value: 'PTE', label: '(PTE) Portuguese Escudo' },
  { value: 'PYG', label: '(PYG) Guarani' },
  { value: 'QAR', label: '(QAR) Qatari Rial' },
  { value: 'RHD', label: '(RHD) Rhodesian Dollar' },
  { value: 'ROK', label: '(ROK) Leu A/52' },
  { value: 'ROL', label: '(ROL) Old Leu' },
  { value: 'RON', label: '(RON) Romanian Leu' },
  { value: 'RSD', label: '(RSD) Serbian Dinar' },
  { value: 'RUB', label: '(RUB) Russian Ruble' },
  { value: 'RUR', label: '(RUR) Russian Ruble' },
  { value: 'RWF', label: '(RWF) Rwanda Franc' },
  { value: 'SAR', label: '(SAR) Saudi Riyal' },
  { value: 'SBD', label: '(SBD) Solomon Islands Dollar' },
  { value: 'SCR', label: '(SCR) Seychelles Rupee' },
  { value: 'SDD', label: '(SDD) Sudanese Dinar' },
  { value: 'SDG', label: '(SDG) Sudanese Pound' },
  { value: 'SDP', label: '(SDP) Sudanese Pound' },
  { value: 'SEK', label: '(SEK) Swedish Krona' },
  { value: 'SGD', label: '(SGD) Singapore Dollar' },
  { value: 'SHP', label: '(SHP) Saint Helena Pound' },
  { value: 'SIT', label: '(SIT) Tolar' },
  { value: 'SKK', label: '(SKK) Slovak Koruna' },
  { value: 'SLL', label: '(SLL) Leone' },
  { value: 'SOS', label: '(SOS) Somali Shilling' },
  { value: 'SRD', label: '(SRD) Surinam Dollar' },
  { value: 'SRG', label: '(SRG) Surinam Guilder' },
  { value: 'SSP', label: '(SSP) South Sudanese Pound' },
  { value: 'STD', label: '(STD) Dobra' },
  { value: 'STN', label: '(STN) Dobra' },
  { value: 'SUR', label: '(SUR) Rouble' },
  { value: 'SVC', label: '(SVC) El Salvador Colon' },
  { value: 'SYP', label: '(SYP) Syrian Pound' },
  { value: 'SZL', label: '(SZL) Lilangeni' },
  { value: 'THB', label: '(THB) Baht' },
  { value: 'TJR', label: '(TJR) Tajik Ruble' },
  { value: 'TJS', label: '(TJS) Somoni' },
  { value: 'TMM', label: '(TMM) Turkmenistan Manat' },
  { value: 'TMT', label: '(TMT) Turkmenistan New Manat' },
  { value: 'TND', label: '(TND) Tunisian Dinar' },
  { value: 'TOP', label: "(TOP) Pa'anga" },
  { value: 'TPE', label: '(TPE) Timor Escudo' },
  { value: 'TRL', label: '(TRL) Old Turkish Lira' },
  { value: 'TRY', label: '(TRY) Turkish Lira' },
  { value: 'TTD', label: '(TTD) Trinidad and Tobago Dollar' },
  { value: 'TWD', label: '(TWD) New Taiwan Dollar' },
  { value: 'TZS', label: '(TZS) Tanzanian Shilling' },
  { value: 'UAH', label: '(UAH) Hryvnia' },
  { value: 'UAK', label: '(UAK) Karbovanet' },
  { value: 'UGS', label: '(UGS) Uganda Shilling' },
  { value: 'UGW', label: '(UGW) Old Shilling' },
  { value: 'UGX', label: '(UGX) Uganda Shilling' },
  { value: 'USD', label: '(USD) US Dollar' },
  { value: 'USN', label: '(USN) US Dollar (Next day)' },
  { value: 'USS', label: '(USS) US Dollar (Same day)' },
  { value: 'UYI', label: '(UYI) Uruguay Peso en Unidades Indexadas (UI)' },
  { value: 'UYN', label: '(UYN) Old Uruguay Peso' },
  { value: 'UYP', label: '(UYP) Uruguayan Peso' },
  { value: 'UYU', label: '(UYU) Peso Uruguayo' },
  { value: 'UYW', label: '(UYW) Unidad Previsional' },
  { value: 'UZS', label: '(UZS) Uzbekistan Sum' },
  { value: 'VEB', label: '(VEB) Bolivar' },
  { value: 'VEF', label: '(VEF) Bolivar Fuerte' },
  { value: 'VES', label: '(VES) Bolívar Soberano' },
  { value: 'VNC', label: '(VNC) Old Dong' },
  { value: 'VND', label: '(VND) Dong' },
  { value: 'VUV', label: '(VUV) Vatu' },
  { value: 'WST', label: '(WST) Tala' },
  { value: 'XAF', label: '(XAF) CFA Franc BEAC' },
  { value: 'XAG', label: '(XAG) Silver' },
  { value: 'XAU', label: '(XAU) Gold' },
  {
    value: 'XBA',
    label: '(XBA) Bond Markets Unit European Composite Unit (EURCO)',
  },
  {
    value: 'XBB',
    label: '(XBB) Bond Markets Unit European Monetary Unit (E.M.U.-6)',
  },
  {
    value: 'XBC',
    label: '(XBC) Bond Markets Unit European Unit of Account 9 (E.U.A.-9)',
  },
  {
    value: 'XBD',
    label: '(XBD) Bond Markets Unit European Unit of Account 17 (E.U.A.-17)',
  },
  { value: 'XCD', label: '(XCD) East Caribbean Dollar' },
  { value: 'XDR', label: '(XDR) SDR (Special Drawing Right)' },
  { value: 'XEU', label: '(XEU) European Currency Unit (E.C.U)' },
  { value: 'XFO', label: '(XFO) Gold-Franc' },
  { value: 'XFU', label: '(XFU) UIC-Franc' },
  { value: 'XOF', label: '(XOF) CFA Franc BCEAO' },
  { value: 'XPD', label: '(XPD) Palladium' },
  { value: 'XPF', label: '(XPF) CFP Franc' },
  { value: 'XPT', label: '(XPT) Platinum' },
  { value: 'XRE', label: '(XRE) RINET Funds Code' },
  { value: 'XSU', label: '(XSU) Sucre' },
  {
    value: 'XTS',
    label: '(XTS) Codes specifically reserved for testing purposes',
  },
  { value: 'XUA', label: '(XUA) ADB Unit of Account' },
  { value: 'YDD', label: '(YDD) Yemeni Dinar' },
  { value: 'YER', label: '(YER) Yemeni Rial' },
  { value: 'YUD', label: '(YUD) New Yugoslavian Dinar' },
  { value: 'YUM', label: '(YUM) New Dinar' },
  { value: 'YUN', label: '(YUN) Yugoslavian Dinar' },
  { value: 'ZAL', label: '(ZAL) Financial Rand' },
  { value: 'ZAR', label: '(ZAR) Rand' },
  { value: 'ZMK', label: '(ZMK) Zambian Kwacha' },
  { value: 'ZMW', label: '(ZMW) Zambian Kwacha' },
  { value: 'ZRN', label: '(ZRN) New Zaire' },
  { value: 'ZRZ', label: '(ZRZ) Zaire' },
  { value: 'ZWC', label: '(ZWC) Rhodesian Dollar' },
  { value: 'ZWD', label: '(ZWD) Zimbabwe Dollar (old)' },
  { value: 'ZWL', label: '(ZWL) Zimbabwe Dollar' },
  { value: 'ZWN', label: '(ZWN) Zimbabwe Dollar (new)' },
  { value: 'ZWR', label: '(ZWR) Zimbabwe Dollar' },
];
