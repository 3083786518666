import React, { Component } from 'react';
import { Colxx } from '../../components/common/CustomBootstrap';
import { Card, Button } from 'reactstrap';

import { registeredCompanies } from '../../constants/travelValues';
import { stringToMoney } from '../../utils/money';
import { injectIntl } from 'react-intl';
import CurrencyWrapper from '../../components/common/CurrencyWrapper';

class CarItem extends Component {
  handleClick = () => {
    this.props.showDetails(this.props.item);
  };

  // Render
  render() {
    const {
      item,
      intl: { messages },
    } = this.props;
    const { type, doors, passengers, transmission } = item.amenities;

    return (
      <Card className="search-card hotel mt-4">
        <Colxx xxs="12" lg="10" className="pl-0">
          <div className="row ml-0 h-100">
            <Colxx xxs="4" className="pl-0">
              <div
                className="car-image"
                style={{ backgroundImage: `url(${item.mainPhoto})` }}
              />
            </Colxx>
            <Colxx xxs="8" className="pt-2">
              <h3 className="hotel-title pointer" onClick={this.handleClick}>
                {item.name}
              </h3>

              <p className="text-muted mb-0">{type}</p>
              <div className="text-muted address">
                {item.pickUp.code !== item.dropOff.code ? (
                  <div className="glyph-icon iconsminds-right" />
                ) : (
                  <div className="glyph-icon iconsminds-sync" />
                )}
                {item.pickUp.address.toLowerCase()}
              </div>

              {item.pickUp.code !== item.dropOff.code && (
                <div className="text-muted address">
                  <div className="glyph-icon iconsminds-left" />
                  {item.dropOff.address.toLowerCase()}
                </div>
              )}

              <ul className="car-itens mt-3">
                {doors && (
                  <li>
                    <img
                      src={`/assets/img/icons/amenities/doors.svg`}
                      alt={messages['car.doors']}
                      title={messages['car.doors']}
                    />
                    {doors} {messages['car.doors']}
                  </li>
                )}
                {passengers && (
                  <li>
                    <img
                      src={`/assets/img/icons/amenities/passengers.svg`}
                      alt={messages['car.passengers']}
                      title={messages['car.passengers']}
                    />
                    {passengers} {messages['car.passengers']}
                  </li>
                )}

                {transmission && (
                  <li>
                    <img
                      src={`/assets/img/icons/amenities/transmission.svg`}
                      alt={messages['car.transmission']}
                      title={messages['car.transmission']}
                    />
                    {transmission}
                  </li>
                )}
              </ul>
            </Colxx>
          </div>
        </Colxx>
        <Colxx xxs="12" lg="2" className="total pt-3 pr-3">
          {registeredCompanies[item.rental.code] && (
            <div
              className="carrier"
              style={{
                backgroundImage: `url(${
                  registeredCompanies[item.rental.code].logo
                })`,
              }}
            />
          )}
          <div className="text">
            <span>{messages['general.total']}</span>
            <CurrencyWrapper value={item.fare.total} decimalPlaces={0}>
              {({ formattedValue }) => formattedValue}
            </CurrencyWrapper>
            <hr />
          </div>
          <div className="description"></div>

          <Button color="primary" onClick={this.handleClick}>
            {messages['general.select']}
          </Button>
        </Colxx>
      </Card>
    );
  }
}

export default injectIntl(CarItem);
