import React from 'react';
import { Row } from 'reactstrap';
import { Colxx } from '../common/CustomBootstrap';
import moment from 'moment';
import { useIntl } from 'react-intl';

export default function JourneySmall({ journey, index, legs }) {
  const { messages } = useIntl();

  return (
    <div key={`journey-${index}`} className="journey pb-3 mb-3 mt-1">
      <div>
        <div className="d-flex justify-content-between">
          <div className="d-flex justify-content-start">
            <div className="journey-title d-flex align-items-center">
              <img
                src="/assets/img/icons/search_icon.svg"
                alt={messages['alts.components.flight.journey.journey']}
              />
              <h3>
                {journey.departureStation} • {journey.arrivalStation}
              </h3>
            </div>
          </div>
        </div>
        <Row className="pl-3 pr-3 mt-3">
          <Colxx xxs="4" className="origin">
            <p className="text-small">
              {moment(legs[0].departureDateTime, [
                'DD/MM/YYYY HH:mm',
                'YYYY-MM-DD[T]HH:mm:ss',
              ]).format('DD/MM')}
            </p>
            <p>
              {moment(legs[0].departureDateTime, [
                'DD/MM/YYYY HH:mm',
                'YYYY-MM-DD[T]HH:mm:ss',
              ]).format('HH:mm')}
            </p>
            <p className="highlight">
              <span>{legs[0].departureStation}</span>
            </p>
          </Colxx>
          <Colxx xxs="4" className="duration text-center">
            <img
              src="/assets/img/icons/search_icon_bus.svg"
              alt={messages['alts.components.flight.journey.duration']}
            />
            {legs.length === 1 && `Direto`}
            {legs.length >= 2 &&
              `${legs.length - 1} ${
                messages['alts.components.flight.journey.legs']
              }`}
          </Colxx>
          <Colxx xxs="4" className="destination">
            <p className="text-small">
              {moment(legs[0].arrivalDateTime, [
                'DD/MM/YYYY HH:mm',
                'YYYY-MM-DD[T]HH:mm:ss',
              ]).format('DD/MM')}
            </p>
            <p>
              {moment(legs[0].arrivalDateTime, [
                'DD/MM/YYYY HH:mm',
                'YYYY-MM-DD[T]HH:mm:ss',
              ]).format('HH:mm')}
            </p>
            <p className="highlight">
              <span>{legs[legs.length - 1].arrivalStation}</span>
            </p>
          </Colxx>
        </Row>
      </div>
    </div>
  );
}
