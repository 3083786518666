import { useEffect, useState } from 'react';

export default function useDataSwitcher({
  selectedOption = '',
  fetchFunctions = {},
  initialData = [],
  formatFunction = () => {},
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [fetchedData, setFetchedData] = useState({});
  const [selectedData, setSelectedData] = useState(initialData);

  useEffect(() => {
    fetchData(selectedOption);
  }, [selectedOption]);

  const fetchData = async (option) => {
    const fetchFunction = fetchFunctions[option];
    if (fetchFunction) {
      if (option in fetchedData) setSelectedData(fetchedData[option]);
      else {
        setIsLoading(true);

        const data = await fetchFunction();
        const formattedData = formatFunction(data);
        setSelectedData(formattedData);
        setFetchedData({
          ...fetchedData,
          [option]: formattedData,
        });

        setIsLoading(false);
      }
    } else setSelectedData(initialData);
  };

  return [selectedData, isLoading];
}
