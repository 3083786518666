import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { parseFontSize } from '../utils/style';

export default function AdjustableText({
  className,
  text,
  textClassName,
  minFontSize = 14,
  overflowClass = 'wrapped',
}) {
  // Refs
  const containerRef = useRef(null);
  const mockupRef = useRef(null);

  const initialFontSize = useRef(null);

  // States
  const [containerWidth, setContainerWidth] = useState(null);
  const [fontSize, setFontSize] = useState(null);

  // Functions
  const onContainerResize = (entry) => {
    const { contentBoxSize } = entry[0];
    const newWidth = Math.trunc(contentBoxSize[0].inlineSize);
    if (newWidth !== containerWidth) setContainerWidth(newWidth);
  };

  const findBestFontSize = ({ currentValue, limitValue, asc = false }) => {
    const numIterations = Math.abs(currentValue - limitValue),
      increase = asc ? 1 : -1;

    for (
      let i = 0, testFontSize = currentValue;
      i < numIterations;
      i++, testFontSize += increase
    ) {
      mockupRef.current.style.fontSize = `${testFontSize}px`;
      const mockupWidth = mockupRef.current.getBoundingClientRect().width;

      if (mockupWidth <= containerWidth) {
        return setFontSize(testFontSize);
      }
    }

    setFontSize(limitValue);
  };

  const onResize = () => {
    let mockupWidth = mockupRef.current.getBoundingClientRect().width;
    const currentFontSize =
      fontSize !== null
        ? fontSize
        : parseFontSize(getComputedStyle(mockupRef.current).fontSize);

    if (!initialFontSize.current) {
      initialFontSize.current = currentFontSize;
    }

    if (mockupWidth > containerWidth) {
      if (currentFontSize <= minFontSize) return;

      findBestFontSize({
        currentValue: currentFontSize,
        limitValue: minFontSize,
        asc: false,
      });
    } else {
      if (currentFontSize >= initialFontSize.current) return;

      findBestFontSize({
        currentValue: initialFontSize.current,
        limitValue: currentFontSize,
        asc: false,
      });
    }
  };

  // Effects
  useEffect(() => {
    const resizeObserver = new ResizeObserver(onContainerResize);
    resizeObserver.observe(containerRef.current);

    return () => resizeObserver.disconnect();
  }, []);

  useEffect(() => {
    if (!containerWidth) return;

    onResize();
  }, [containerWidth, text]);

  // Render
  return (
    <div
      ref={containerRef}
      className={classNames('adjustable-text-container w-100', className)}
    >
      <span
        className={classNames('adjustable-text', textClassName, {
          [overflowClass]: fontSize === minFontSize,
        })}
        style={{ fontSize }}
      >
        {text}
      </span>

      <span
        ref={mockupRef}
        className={classNames('mockup-text', textClassName)}
      >
        {text}
      </span>
    </div>
  );
}
