import React from 'react';
import { useIntl } from 'react-intl';
import EmptyResults from '../components/common/EmptyResults';

export default function EmptyWrapper({
  isEmpty,
  onClick,
  children,
  messages = {
    title: '',
    description: '',
    button: '',
  },
}) {
  return (
    <>
      {isEmpty ? (
        <div className="w-100 d-flex justify-content-center mt-5">
          <EmptyResults
            title={messages.title}
            description={messages.description}
            buttonText={messages.button}
            className="w-70"
            onClick={onClick}
          />
        </div>
      ) : (
        children
      )}
    </>
  );
}
