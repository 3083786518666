import React from 'react';
import { Row } from 'reactstrap';
import { Colxx } from '../common/CustomBootstrap';
import moment from 'moment';
import { useIntl } from 'react-intl';

import AirportName from './AirportName';
import CityName from './CityName';

export default function JourneySmall({ journey, index, params, flights }) {
  const { messages } = useIntl();

  const departureDate = moment(flights[0].departureDateTime, [
    'DD/MM/YYYY HH:mm',
    'YYYY-MM-DD[T]HH:mm:ss',
  ]);
  const arrivalDate = moment(flights[flights.length - 1].arrivalDateTime, [
    'DD/MM/YYYY HH:mm',
    'YYYY-MM-DD[T]HH:mm:ss',
  ]);

  return (
    <div key={`journey-${index}`} className="journey pb-3 mb-3 mt-1">
      <div>
        <div className="d-flex justify-content-between">
          <div className="d-flex justify-content-start">
            <div className="journey-title d-flex align-items-center">
              <img
                src="/assets/img/icons/search_icon.svg"
                alt={messages['alts.components.flight.journey.journey']}
              />
              <h3>
                <CityName station={journey.departureStation} params={params} />{' '}
                • <CityName station={journey.arrivalStation} params={params} />
              </h3>
            </div>
          </div>
        </div>
        <Row className="pl-3 pr-3 mt-3">
          <Colxx xxs="4" className="origin">
            <p className="text-small">{departureDate.format('DD/MM')}</p>
            <p>{departureDate.format('HH:mm')}</p>
            <p className="highlight">
              <AirportName
                station={flights[0].departureStation}
                params={params}
              />
            </p>
          </Colxx>
          <Colxx xxs="4" className="duration text-center">
            <img
              src="/assets/img/icons/search_icon.svg"
              alt={messages['alts.components.flight.journey.duration']}
            />
            {flights.length === 1 && messages['flight.no-stop']}
            {flights.length >= 2 && `${flights.length - 1} conex.`}
          </Colxx>
          <Colxx xxs="4" className="destination">
            <p className="text-small">{arrivalDate.format('DD/MM')}</p>
            <p>{arrivalDate.format('HH:mm')}</p>
            <p className="highlight">
              <AirportName
                station={flights[flights.length - 1].arrivalStation}
                params={params}
              />
            </p>
          </Colxx>
        </Row>
      </div>
    </div>
  );
}
