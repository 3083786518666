import { useCallback, useEffect, useMemo, useState } from 'react';
import { TParamConfig, TParamTypes, TParams, TSetParamsValue } from './types';
import { getParamsTypes, handleSetParam, setupParams } from './utils';

interface IProps {
  params: TParamConfig[];
  defaultParams?: any;
}

export default function useParams({
  params: _params = [],
  defaultParams,
}: IProps) {
  // States
  const [params, setParams] = useState<TParams>(() =>
    setupParams(_params, defaultParams)
  );

  const paramTypes = useMemo(() => getParamsTypes(_params), [_params]);

  // Effects
  useEffect(() => {
    setParams(setupParams(_params, defaultParams));
  }, [_params, defaultParams]);

  // Functions
  const setParam = useCallback(
    (paramName: string, value: TSetParamsValue) => {
      const paramType = paramTypes[paramName];

      if (paramType === undefined) return null;

      setParams((params) => ({
        ...params,
        [paramName]: handleSetParam(paramType, value, params[paramName]),
      }));
    },
    [paramTypes]
  );

  const setNamedParam = useCallback(
    (name: string) => (value: any) => setParam(name, value),
    [setParam]
  );

  const replaceParam = useCallback((paramName: string, value: TParamTypes) => {
    setParams((params) => ({
      ...params,
      [paramName]: value,
    }));
  }, []);

  return {
    params,
    paramTypes,
    setAllParams: setParams,
    setParam,
    setNamedParam,
    replaceParam,
  };
}
