import React from 'react';
import RecurrenceBudgetListing from './RecurrenceBudgetListing';

export default function Recurrence() {
  return (
    <div className="pb-5 with-padding">
      <RecurrenceBudgetListing />
    </div>
  );
}
