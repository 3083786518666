import { useEffect, useRef, useState } from 'react';

export default function useIntervalFetch({
  fetchFunction = () => {},
  refresh = 60000,
  shouldLoad = true,
}) {
  const timeout = useRef(null);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  // Effects
  useEffect(() => {
    return () => {
      if (timeout.current) clearTimeout(timeout.current);
    };
  }, []);

  useEffect(() => {
    if (shouldLoad && !timeout.current) doFetchData();
  }, [shouldLoad]);

  // Functions
  const doFetchData = async () => {
    try {
      setData(await fetchFunction());

      if (loading) setLoading(false);
    } catch (err) {
      console.error('Unable to fetch in interval', err);
    } finally {
      timeout.current = setTimeout(() => doFetchData(), refresh);
    }
  };

  return [data, loading, doFetchData];
}
