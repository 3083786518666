import moment from 'moment';
import { useMemo } from 'react';

export default function Duration({ startTime, finishTime }) {
  // States
  const duration = useMemo(() => {
    var start = new moment(startTime, [
      'DD/MM/YYYY HH:mm',
      'YYYY-MM-DD[T]HH:mm:ss',
    ]);
    var finish = new moment(finishTime, [
      'DD/MM/YYYY HH:mm',
      'YYYY-MM-DD[T]HH:mm:ss',
    ]);

    var duration = moment.duration(finish.diff(start));

    return `${Math.floor(duration.asHours())
      .toString()
      .padStart(2, '0')}:${duration.minutes().toString().padStart(2, '0')} hs`;
  }, [startTime, finishTime]);

  // Render
  return duration;
}
