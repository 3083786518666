import { STORE_RESERVATION_ACTION, RESET_RESERVATION_ACTION } from '../actions';

export const storeReservationAction = (reservationId) => ({
  type: STORE_RESERVATION_ACTION,
  payload: {
    reservationId,
  },
});

export const resetReservationAction = (reservationId) => ({
  type: RESET_RESERVATION_ACTION,
  payload: {
    reservationId,
  },
});
