import React, { useMemo, useState, useEffect } from 'react';
import { Row, Modal, ModalBody, ModalFooter } from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Colxx } from '../../components/common/CustomBootstrap';
import filter from 'lodash/filter';
import map from 'lodash/map';
import moment from 'moment';
import JourneyLarge from '../../components/flight/JourneyLarge';
import firebaseApp from '../../services/Firebase';

import { stringToMoney } from '../../utils/money';
import ReservationParams from './ReservationParams/ReservationParams';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import ReservationButton from './ReservationButton';
import useIsReservationValid from '../../hooks/useIsReservationValid';
import CurrencyWrapper from '../../components/common/CurrencyWrapper';
import HotelInfo from '../../components/itinerary/HotelInfo';
import OrderStructures from '../../constants/orderStructure';

function ItineraryTotal({
  orderStructure,
  items,
  segments,
  createReservation,
  serviceCharge = 0,
  reservationParams,
  availableCredits = [],
  canReservate = true,
  selectMode,
}) {
  const { messages, formatMessage } = useIntl();

  // const hasBaggage = useMemo(() => {
  //   let hasBaggage = false;
  //
  //   segments.forEach((segment) => {
  //     if (filter(segment.flights, (f) => f.fare.baggage > 0).length > 0) {
  //       hasBaggage = true;
  //     }
  //   });
  //
  //  return hasBaggage;
  // }, [segments]);

  const total = useMemo(() => {
    const { outbound, hotel, inbound } = items;

    let total = hotel.selectedRoom.fare;

    // If it's round trip
    if (outbound.item.id === inbound.item.id) {
      total += outbound.item.fare.total;
    } else {
      total += outbound.item.fare.total + inbound.item.fare.total;
    }

    return total;
  }, [items]);

  const { isPersonal, additionalInfo } = useMemo(() => {
    const isPersonal = orderStructure === OrderStructures.PERSONAL;

    const additionalInfo = isPersonal
      ? formatMessage(
          { id: 'containers.search.flight-modal.taxes' },
          { taxes: stringToMoney(serviceCharge, 0) }
        )
      : null;

    return {
      isPersonal,
      additionalInfo,
    };
  }, [orderStructure, serviceCharge]);

  const { isValid, disabled, title } = useIsReservationValid({
    orderStructure,
    reservationParams,
  });

  return (
    <ModalFooter>
      <div>
        <div className="text mt-3 pr-3">
          <span className="d-flex align-items-center justify-content-end">
            {messages['general.total']}
          </span>
          <CurrencyWrapper value={total} tooltipFill="white" decimalPlaces={0}>
            {({ formattedValue }) => formattedValue}
          </CurrencyWrapper>
          {additionalInfo ? (
            <span style={{ fontSize: '0.5em' }} className="text-theme-3 mb-2">
              {additionalInfo}
            </span>
          ) : null}
          <hr />
        </div>
        <div className="description pr-3">
          {/* <p>
            {formatMessage(
              { id: 'containers.search.flight-modal.reservation' },
              {
                numAdults: item.fare.quantity,
                adults:
                  item.fare.quantity > 1 ? messages['general.adults'] : messages['general.adult'],
              }
            )}
          </p>

          {item.fare.issueUntil && (
            <p className="color-alert">
              {formatMessage(
                { id: 'containers.search.flight-modal.valid-until' },
                {
                  date: moment(item.fare.issueUntil).format('DD/MM/YYYY HH:mm'),
                }
              )}
            </p>
          )}

          {!hasBaggage && (
            <p className="mb-0 text-small">
              {messages['containers.search.flight-modal.baggages-not-included']}
            </p>
          )}
          <p className="mb-0 text-small">
            {
              messages[
                isPersonal ? 'containers.search.modal.taxes' : 'containers.search.modal.fees'
              ]
            }
          </p> */}
        </div>

        <ReservationButton
          onClick={createReservation}
          disabled={!canReservate || disabled}
          title={title}
          isValid={isValid}
        />
      </div>
    </ModalFooter>
  );
}

export default function ItineraryModal({
  toggleModal,
  items,
  outboundParams,
  inboundParams,
  reserveItems,
  nationality,
}) {
  const serviceCharge = useSelector(({ auth }) =>
    parseFloat(auth.organization.fee?.flight || 0)
  );

  // States
  const [reservationParams, setReservationParams] = useState({});
  const [orderStructure, setStructure] = useState(OrderStructures.ORGANIZATION);

  const { outboundJourney, outboundFlights, inboundJourney, inboundFlights } =
    useMemo(() => {
      const { outbound, inbound } = items;

      return {
        outboundJourney: outbound.item.journeys[outbound.selectedJourney],
        outboundFlights:
          outbound.item.journeys[outbound.selectedJourney].segments[
            outbound.selectedSegment
          ].flights,
        inboundJourney: inbound.item.journeys[inbound.selectedJourney],
        inboundFlights:
          inbound.item.journeys[inbound.selectedJourney].segments[
            inbound.selectedSegment
          ].flights,
      };
    }, [items]);

  // Functions
  const onCreateItems = () => {
    toggleModal();
    reserveItems({ items, reservationParams, orderStructure });
  };

  // Render
  return (
    <Modal
      isOpen={true}
      toggle={toggleModal}
      className="itinerary-modal reservation small"
    >
      <ModalBody className="flight">
        <Row>
          <Colxx xxs="12">
            <div className="flight-content">
              <PerfectScrollbar
                options={{
                  suppressScrollX: true,
                  wheelPropagation: false,
                }}
              >
                <ReservationParams
                  orderStructure={orderStructure}
                  setStructure={setStructure}
                  setReservationParams={setReservationParams}
                  hasNationality
                  nationality={nationality}
                />

                <div className="py-3 mt-4">
                  <JourneyLarge
                    journey={outboundJourney}
                    index={0}
                    params={outboundParams}
                    flights={outboundFlights}
                  />

                  <div className="px-3">
                    <HotelInfo
                      hotel={items.hotel.item}
                      selectedRoom={items.hotel.selectedRoom}
                    />
                  </div>

                  <JourneyLarge
                    journey={inboundJourney}
                    index={0}
                    params={inboundParams}
                    flights={inboundFlights}
                  />
                </div>
              </PerfectScrollbar>
            </div>
          </Colxx>
        </Row>
      </ModalBody>

      <ItineraryTotal
        orderStructure={orderStructure}
        items={items}
        serviceCharge={serviceCharge}
        reservationParams={reservationParams}
        createReservation={onCreateItems}
      />
    </Modal>
  );
}
