import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Row, Modal, Label, ModalBody, ModalFooter } from 'reactstrap';
import { useIntl } from 'react-intl';
import { Colxx } from '../common/CustomBootstrap';
import classnames from 'classnames';
import IntlMessages from '../../helpers/IntlMessages';
import PerfectScrollbar from 'react-perfect-scrollbar';

import ApprovalInfo from '../order/ApprovalInfo/ApprovalInfo';
import OrderStatusFooter from '../order/OrderStatusFooter';
import OfficePhotos from '../order/OfficePhotos';
import OfficeAmenities from '../order/OfficeAmenities';

import firebaseApp from '../../services/Firebase';
import ReservationParams from './ReservationParams';
import Comment from '../expensesTimeline/Comment';

let snapshotApprovals = () => {};

export default function OfficeModal({ toggleModal, order }) {
  const { messages } = useIntl();
  const [isApproving, setIsApproving] = useState(false);
  const [loading, setLoading] = useState(true);

  const [reservation, setReservation] = useState({});
  const [approvals, setApprovals] = useState([]);

  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    loadData();

    return () => {
      snapshotApprovals();
    };
  }, []);

  // Load Data
  const loadData = async () => {
    await Promise.all([loadApprovals(), loadReservationsFromPassenger()]);

    setLoading(false);
  };

  const loadReservationsFromPassenger = async () => {
    const reservationsSnapshot = await firebaseApp
      .getReservationsofOrderIdFromPassengerId(
        order.id,
        order.passengerId,
        user.organizationId
      )
      .get();

    if (reservationsSnapshot.size === 0) {
      toggleModal();
    }

    let reservation = {};
    reservationsSnapshot.forEach((reservationSnapshot) => {
      reservation = {
        ...reservationSnapshot.data(),
        id: reservationSnapshot.id,
      };
    });

    setReservation(reservation);
  };

  const loadApprovals = async () => {
    snapshotApprovals = firebaseApp
      .getApprovalsFromOrderId(order.id)
      .onSnapshot(function (querySnapshot) {
        const approvals = [];

        querySnapshot.forEach(function (doc) {
          approvals.push({
            id: doc.id,
            ...doc.data(),
          });
        });

        setApprovals(approvals.reverse());
      });
  };

  const goForApproval = () => {
    setIsApproving(!isApproving);
  };

  // Render
  if (loading === true) {
    return (
      <Modal isOpen={true} toggle={toggleModal} className="reservation small">
        <ModalBody>
          <div className="loading p-relative" />
        </ModalBody>
      </Modal>
    );
  }

  return (
    <Modal
      isOpen={true}
      toggle={toggleModal}
      className={classnames({
        reservation: true,
        small: true,
        approving: isApproving,
      })}
    >
      <ModalBody className="hotel">
        <Row>
          <Colxx sm="12" md="5" className="pr-0">
            <OfficePhotos
              office={reservation.officeDetails.office}
              officeId={reservation.officeDetails.officeId}
            />
          </Colxx>
          <Colxx sm="12" md="7" className="pl-0">
            <div className="hotel-content">
              <PerfectScrollbar
                options={{
                  suppressScrollX: true,
                  wheelPropagation: false,
                }}
              >
                <h3 className="hotel-title ml-3">
                  {reservation.officeDetails.office.name}
                </h3>

                <p className="text-muted-darke ml-3 text-capitalize">
                  {reservation.officeDetails.office.destination.address.toLowerCase()}
                </p>

                <OfficeAmenities
                  office={reservation.officeDetails.office}
                  officeId={reservation.officeDetails.officeId}
                />

                <h5 className="mt-5 ml-3">
                  <IntlMessages id="components.timeline.modal.reservation-data" />
                </h5>
                <ReservationParams order={order} />

                {order.comment ? (
                  <div className="mx-3 comments">
                    <h5 className="mt-4">
                      {messages['refund.modal.refund.comments']}
                    </h5>
                    <Comment comment={order.comment} />
                  </div>
                ) : null}

                <div className="reservation-params primary pl-3">
                  <Row>
                    <Colxx xxs="10">
                      <Label className="form-group disabled search has-top-label">
                        <p>{reservation.officeDetails.selectedRoom.name}</p>
                        <IntlMessages id="travel.room_name" />
                      </Label>
                    </Colxx>
                  </Row>
                  <Row>
                    <Colxx xxs="5">
                      <Label className="form-group disabled search has-top-label">
                        <p>{reservation.officeDetails.date}</p>
                        <IntlMessages id="travel.date" />
                      </Label>
                    </Colxx>
                    <Colxx xxs="5">
                      <Label className="form-group disabled search has-top-label">
                        <p>
                          {reservation.officeDetails.selectedRoom.size}{' '}
                          {reservation.officeDetails.selectedRoom.size > 1
                            ? messages['general.people']
                            : messages['general.person']}
                        </p>
                        <IntlMessages id="travel.room_size" />
                      </Label>
                    </Colxx>
                  </Row>
                </div>

                <h5 className="mt-4 ml-3">
                  {
                    messages[
                      'containers.search.office-modal.office-description'
                    ]
                  }
                </h5>
                <div
                  className="ml-3 mr-3 mb-5"
                  dangerouslySetInnerHTML={{
                    __html: reservation.officeDetails.office.description['pt'],
                  }}
                />
              </PerfectScrollbar>
            </div>
          </Colxx>
        </Row>
      </ModalBody>
      <ModalFooter className="p-3">
        {isApproving ? (
          <ApprovalInfo
            order={order}
            approvals={approvals}
            goForApproval={goForApproval}
            toggleModal={toggleModal}
            reservations={[reservation]}
          />
        ) : (
          <OrderStatusFooter
            order={order}
            approvals={approvals}
            goForApproval={goForApproval}
            toggleModal={toggleModal}
            reservations={[reservation]}
          />
        )}
      </ModalFooter>
    </Modal>
  );
}
