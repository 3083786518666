import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Row, Modal, Label, ModalBody, ModalFooter } from 'reactstrap';
import { Colxx } from '../common/CustomBootstrap';
import classnames from 'classnames';
import IntlMessages from '../../helpers/IntlMessages';
import PerfectScrollbar from 'react-perfect-scrollbar';

import CarAmenities from '../car/CarAmenities';
import CarPhotos from '../car/CarPhotos';

import firebaseApp from '../../services/Firebase';
import ReservationParams from './ReservationParams';
import { useIntl } from 'react-intl';
import Footer from './Footer';
import { canApproveReservation, canOrderBeCancelled } from '../../utils/orders';
import orderStatus from '../../constants/orderStatus';
import OrderIssueCard from '../order/OrderIssueCard';
import Comment from '../expensesTimeline/Comment';
import useModalStages from '../../hooks/useModalStages';
import useLocationMapping from '../../hooks/useLocationMapping/useLocationMapping';

let snapshotApprovals = () => {};

export default function CarModal({ toggleModal, order, path }) {
  const { user } = useSelector((state) => state.auth);
  const { messages } = useIntl();

  const {
    isApproving,
    toggleIsApproving,
    setIsApproving,
    isAltering,
    toggleIsAltering,
    setIsAltering,
  } = useModalStages({
    item: order,
    path,
  });

  const [isCancelling, setIsCancelling] = useState(false);
  const [loading, setLoading] = useState(true);

  const [reservation, setReservation] = useState({});
  const [approvals, setApprovals] = useState([]);

  const cancellations = useMemo(
    () => reservation.carDetails?.cancellation,
    [reservation]
  );
  const canCancel = useMemo(
    () => canOrderBeCancelled(cancellations),
    [cancellations]
  );
  const showCancelButton = useMemo(
    () => order.statusCode !== orderStatus.ISSUED,
    [order]
  );

  // Effects
  useEffect(() => {
    loadData();

    return () => {
      snapshotApprovals();
    };
  }, []);

  // Hooks
  useLocationMapping(
    {
      paths: {
        approval: {
          onMatch: () => setIsApproving(true),
          validate: () => canApproveReservation({ approvals, user }),
          onFail: () => toggleIsApproving(false),
        },
        alteration: {
          onMatch: () => setIsAltering(true),
          validate: () => order.statusCode === orderStatus.ISSUED,
          onFail: () => toggleIsAltering(false),
        },
      },
    },
    [approvals, user, order]
  );

  // Load Data
  const loadData = async () => {
    await Promise.all([loadApprovals(), loadReservationsFromPassenger()]);

    setLoading(false);
  };

  const loadReservationsFromPassenger = async () => {
    const reservationsSnapshot = await firebaseApp
      .getReservationsofOrderIdFromPassengerId(
        order.id,
        order.passengerId,
        user.organizationId
      )
      .get();

    let reservation = {};
    reservationsSnapshot.forEach((reservationSnapshot) => {
      reservation = {
        ...reservationSnapshot.data(),
        id: reservationSnapshot.id,
      };
    });

    setReservation(reservation);
  };

  const loadApprovals = async () => {
    snapshotApprovals = firebaseApp
      .getApprovalsFromOrderId(order.id)
      .onSnapshot(function (querySnapshot) {
        const approvals = [];

        querySnapshot.forEach(function (doc) {
          approvals.push({
            id: doc.id,
            ...doc.data(),
          });
        });

        setApprovals(approvals.reverse());
      });
  };

  const goForCancellation = () => {
    setIsCancelling(!isCancelling);
  };

  // Render
  if (loading === true) {
    return (
      <Modal isOpen={true} toggle={toggleModal} className="reservation small">
        <ModalBody>
          <div className="loading p-relative" />
        </ModalBody>
      </Modal>
    );
  }

  return (
    <Modal
      isOpen={true}
      toggle={toggleModal}
      className={classnames({
        reservation: true,
        small: true,
        approving: isApproving || isCancelling || isAltering,
      })}
    >
      <ModalBody className="hotel">
        <Row>
          <Colxx sm="12" md="5" className="pr-0">
            <CarPhotos car={reservation.carDetails} />
          </Colxx>
          <Colxx sm="12" md="7" className="pl-0">
            <div className="hotel-content">
              <PerfectScrollbar
                options={{
                  suppressScrollX: true,
                  wheelPropagation: false,
                }}
              >
                <h3 className="hotel-title ml-3">
                  {reservation.carDetails.name}
                </h3>

                <p className="text-muted-darke ml-3">
                  {reservation.carDetails.amenities.type}
                </p>

                <CarAmenities car={reservation.carDetails} />

                <h5 className="mt-4 ml-3">
                  {messages['components.timeline.modal.reservation-data']}
                </h5>
                <ReservationParams order={order} />

                {order.comment ? (
                  <div className="mx-3 comments">
                    <h5 className="mt-4">
                      {messages['refund.modal.refund.comments']}
                    </h5>
                    <Comment comment={order.comment} />
                  </div>
                ) : null}

                <div className="reservation-params primary pl-3">
                  <Row>
                    <Colxx xxs="10">
                      <Label className="form-group disabled search has-top-label">
                        <p>{reservation.carDetails.rental.name}</p>
                        <IntlMessages id="travel.rental" />
                      </Label>
                    </Colxx>
                  </Row>
                  <Row>
                    <Colxx sm="12" md="7">
                      <Label className="form-group disabled search has-top-label">
                        <p>{reservation.carDetails.pickUpLocation.address}</p>
                        <IntlMessages id="travel.pickUpLocation" />
                      </Label>
                    </Colxx>
                    <Colxx sm="12" md="4">
                      <Label className="form-group disabled search has-top-label">
                        <p>{reservation.carDetails.pickUp}</p>
                        <IntlMessages id="travel.pickUpTime" />
                      </Label>
                    </Colxx>
                  </Row>
                  <Row>
                    <Colxx sm="12" md="7">
                      <Label className="form-group disabled search has-top-label">
                        <p>{reservation.carDetails.dropOffLocation.address}</p>
                        <IntlMessages id="travel.dropOffLocation" />
                      </Label>
                    </Colxx>
                    <Colxx sm="12" md="4">
                      <Label className="form-group disabled search has-top-label">
                        <p>{reservation.carDetails.dropOff}</p>
                        <IntlMessages id="travel.dropOffTime" />
                      </Label>
                    </Colxx>
                  </Row>
                </div>

                {!isApproving && (
                  <div>
                    <h5 className="mt-4 ml-3">
                      {messages['components.timeline.modal.conditions']}
                    </h5>
                    <div
                      className="text-muted-darker others ml-3 mr-3 mb-5"
                      dangerouslySetInnerHTML={{
                        __html: reservation.carDetails.conditions,
                      }}
                    />
                  </div>
                )}

                <OrderIssueCard
                  show={isApproving}
                  order={order}
                  reservations={[reservation]}
                />
              </PerfectScrollbar>
            </div>
          </Colxx>
        </Row>
      </ModalBody>
      <ModalFooter className="p-3">
        <Footer
          order={order}
          approvals={approvals}
          goForApproval={() => toggleIsApproving()}
          goForAlteration={() => toggleIsAltering()}
          toggleModal={toggleModal}
          reservations={[reservation]}
          goForCancellation={goForCancellation}
          cancellations={cancellations}
          isApproving={isApproving}
          isAltering={isAltering}
          isCancelling={isCancelling}
          canAlter={canCancel}
          canCancel={canCancel}
          showCancelButton={showCancelButton}
        />
      </ModalFooter>
    </Modal>
  );
}
