import { useMemo } from 'react';
import { getGraphLabelAndValues } from '../../../utils/expensesDashboard';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { RefundTypes } from '../../../constants/refundTypes';
import { useIntl } from 'react-intl';
import { stringToMoney } from '../../../utils/money';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const getOrCreateTooltip = (chart) => {
  let tooltipEl = chart.canvas.parentNode.querySelector('div');

  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)';
    tooltipEl.style.borderRadius = '3px';
    tooltipEl.style.color = 'white';
    tooltipEl.style.opacity = 1;
    tooltipEl.style.pointerEvents = 'none';
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.transform = 'translate(-50%, 0)';
    tooltipEl.style.transition = 'all .1s ease';

    const table = document.createElement('table');
    table.style.margin = '0px';
    table.style.minWidth = '150px';

    tooltipEl.appendChild(table);
    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
};

const externalTooltipHandler = (context) => {
  // Tooltip Element
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart);

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  // Set Text
  if (tooltip.body) {
    const titleLines = tooltip.title || [];
    const bodyLines = tooltip.body.map((b) =>
      b.lines.map((line) => {
        const [title, price] = line.split(': ');
        return `${title}: ${stringToMoney(price.replace(',', ''), 2)}`;
      })
    );

    const tableHead = document.createElement('thead');

    titleLines.forEach((title) => {
      const tr = document.createElement('tr');
      tr.style.borderWidth = 0;

      const th = document.createElement('th');
      th.style.borderWidth = 0;
      th.style.textAlign = 'center';
      const text = document.createTextNode(title);

      th.appendChild(text);
      tr.appendChild(th);
      tableHead.appendChild(tr);
    });

    const tableBody = document.createElement('tbody');
    bodyLines.forEach((body, i) => {
      const colors = tooltip.labelColors[i];

      const span = document.createElement('span');
      span.style.background = colors.backgroundColor;
      span.style.borderColor = colors.borderColor;
      span.style.borderWidth = '2px';
      span.style.marginRight = '10px';
      span.style.height = '10px';
      span.style.width = '10px';
      span.style.display = 'inline-block';

      const tr = document.createElement('tr');
      tr.style.backgroundColor = 'inherit';
      tr.style.borderWidth = 0;

      const td = document.createElement('td');
      td.style.borderWidth = 0;

      const text = document.createTextNode(body);

      td.appendChild(span);
      td.appendChild(text);
      tr.appendChild(td);
      tableBody.appendChild(tr);
    });

    const tableRoot = tooltipEl.querySelector('table');

    // Remove old children
    while (tableRoot.firstChild) {
      tableRoot.firstChild.remove();
    }

    // Add new children
    tableRoot.appendChild(tableHead);
    tableRoot.appendChild(tableBody);
  }

  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1;
  tooltipEl.style.zIndex = 100;
  tooltipEl.style.left = positionX + tooltip.caretX + 'px';
  tooltipEl.style.top = positionY + tooltip.caretY + 'px';
  tooltipEl.style.font = tooltip.options.bodyFont.string;
  tooltipEl.style.padding =
    tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
};

export default function SpendGraph({ sortedBudgets, budgets, ...props }) {
  const { messages } = useIntl();

  const graphInfo = useMemo(
    () => getGraphLabelAndValues(budgets, sortedBudgets, true),
    [budgets, sortedBudgets]
  );

  const data = useMemo(() => {
    const {
      labels,
      [RefundTypes.PHYSICAL_CARD]: physicalData = [],
      [RefundTypes.VIRTUAL_CARD]: virtualData = [],
      [RefundTypes.PERSONAL_ACCOUNT]: personalData = [],
      [RefundTypes.ADMINISTRATIVE_ACCOUNT]: administrativeData = [],
    } = graphInfo;

    return {
      labels,
      datasets: [
        {
          label: messages['refund.card-type.physical'],
          data: physicalData,
          borderColor: '#43418e',
          // backgroundColor: 'rgba(67, 65, 137, 0.3)',
          backgroundColor: '#43418e',
          fill: true,
        },
        {
          label: messages['refund.card-type.virtual'],
          data: virtualData,
          borderColor: '#9F4893',
          // backgroundColor: 'rgba(170, 170, 170, 0.3)',
          backgroundColor: '#9F4893',
          fill: true,
        },
        {
          label: messages['refund.card-type.personal'],
          data: personalData,
          borderColor: '#E05B83',
          // backgroundColor: 'rgba(248, 185, 0, 0.3)',
          backgroundColor: '#E05B83',
          fill: true,
        },
        {
          label: messages['refund.card-type.administrative'],
          data: administrativeData,
          borderColor: '#FF866B',
          // backgroundColor: 'rgba(248, 185, 0, 0.3)',
          backgroundColor: '#FF866B',
          fill: true,
        },
      ],
    };
  }, [graphInfo]);

  return (
    <Line
      {...props}
      data={data}
      options={{
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            labels: {
              usePointStyle: true,
              boxWidth: 8,
            },
          },
          tooltip: {
            enabled: false,
            position: 'nearest',
            external: externalTooltipHandler,
          },
        },
        elements: {
          line: {
            tension: 0,
          },
          point: {
            borderWidth: 1,
          },
        },
        scales: {
          x: {
            grid: { display: false },
          },
          y: {
            // grid: { display: false },
            stacked: true,
            ticks: {
              // Include a dollar sign in the ticks
              callback: function (value) {
                return 'R$' + value;
              },
            },
          },
        },
      }}
    />
  );
}
