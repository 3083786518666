import React, { Fragment } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { Row, Nav, NavItem } from 'reactstrap';
import classnames from 'classnames';
import IntlMessages from '../../helpers/IntlMessages';
import { Colxx, Separator } from '../../components/common/CustomBootstrap';
import Breadcrumb from '../../containers/navs/Breadcrumb';

import DndWrapper from '../../wrappers/DndWrapper';
import ExpensesDataListView from '../../containers/reports/ExpensesDataListView';
import DashboardView from '../../containers/expenses/DashboardView';
import useDataArchive from '../../hooks/useDataArchive';
import SuppliersView from '../../containers/expenses/SuppliersView';

export default function Expenses(props) {
  const { tabId } = useParams();
  const { getDataFromArchive: getData } = useDataArchive();

  // Render
  return (
    <Fragment>
      <Row>
        <Colxx xxs="12">
          <Breadcrumb heading="admin.refunds.title" match={props.match} />
          <Separator className="mb-5" />
        </Colxx>
      </Row>
      <Row>
        <Colxx xxs="12" className="mb-4">
          <div>
            <Nav className="admin-tabs">
              <NavItem>
                <NavLink
                  to="/data/payments/dashboard"
                  className={classnames({
                    active: !tabId || tabId === 'dashboard',
                    'nav-link': true,
                  })}
                >
                  <IntlMessages id="admin.travels.dashboard" />
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  to="/data/payments/suppliers"
                  className={classnames({
                    active: tabId === 'suppliers',
                    'nav-link': true,
                  })}
                >
                  <IntlMessages id="admin.data.suppliers" />
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  to="/data/payments/details"
                  className={classnames({
                    active: tabId === 'details',
                    'nav-link': true,
                  })}
                >
                  <IntlMessages id="admin.data.details" />
                </NavLink>
              </NavItem>
            </Nav>

            {!tabId || tabId === 'dashboard' ? (
              <DashboardView getData={getData} />
            ) : null}
            {tabId === 'suppliers' ? <SuppliersView getData={getData} /> : null}
            {tabId === 'details' ? (
              <DndWrapper>
                <ExpensesDataListView
                  path="/data/payments/details"
                  getData={getData}
                />
              </DndWrapper>
            ) : null}
          </div>
        </Colxx>
      </Row>
    </Fragment>
  );
}
