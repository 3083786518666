import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import {
  Button,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  ModalFooter,
} from 'reactstrap';
import { Colxx } from '../../common/CustomBootstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import IntlMessages from '../../../helpers/IntlMessages';

import CustomColorPicker from '../../CustomColorPicker/CustomColorPicker';
import { CompaniesInfo, companiesKeywords } from '../../../constants/companies';
import VirtualCard from '../VirtualCard';
import { colorBrightness, hexToRgb } from '../../../utils/colors';

import appFunctions from '../../../services/Functions';
import { getPassengerRedux } from '../../../utils/redux';

const matchCompany = (name) => {
  const arrayCompanies = Object.keys(companiesKeywords);
  const nameCompany = arrayCompanies.find(
    (result) => result === name.toLowerCase()
  );
  const company = companiesKeywords[nameCompany];

  return company ?? null;
};

export default function VirtualCardCreationModal({ closeModal }) {
  const { messages } = useIntl();
  const { passenger } = useSelector(getPassengerRedux);

  // Functions
  const tryFindCompany = (name, setFieldValue) => {
    const company = matchCompany(name);
    if (company) {
      setFieldValue('icon', company);
      setFieldValue('color', CompaniesInfo[company].color);
    } else {
      setFieldValue('icon', null);
      setFieldValue('color', '#43418e');
    }
  };

  const onNameChange = (name, setFieldValue) => {
    tryFindCompany(name, setFieldValue);
  };

  const createCard = async (values, setSubmitting) => {
    try {
      setSubmitting(true);

      await appFunctions.createExpensesCard(passenger.organizationId, {
        card_id: 'virtual',
        name: values.name,
        color: values.color,
        icon: values.icon,
        user_id: passenger.uid,
      });

      closeModal();
    } catch (err) {
      console.error(err);
    } finally {
      setSubmitting(false);
    }
  };

  const validationSchema = () => {
    return Yup.object().shape({
      name: Yup.string(messages['forms.validation.required']).required(
        messages['forms.validation.required']
      ),
      icon: Yup.string(messages['forms.validation.required']).nullable(),
      color: Yup.string(messages['forms.validation.required'])
        .required(messages['forms.validation.required'])
        .test(
          'too-bright',
          messages['refund.virtual-card.create.too-bright'],
          (v) => {
            return colorBrightness(hexToRgb(v)) < 200;
          }
        ),
    });
  };

  // Render
  return (
    <Modal
      isOpen={true}
      toggle={closeModal}
      size="lg"
      className="admin refund-new-card-modal virtual-card-modal"
      name="virtual-card-modal"
    >
      <ModalHeader toggle={closeModal}>
        {messages['refund.virtual-card.create.title']}
      </ModalHeader>
      <Formik
        initialValues={{ name: '', icon: null, color: '#43418e' }}
        validationSchema={validationSchema()}
        onSubmit={(values, { setSubmitting }) => {
          createCard(values, setSubmitting);
        }}
      >
        {({ isSubmitting, values, setFieldValue }) => (
          <Form>
            <ModalBody>
              <Row className="px-3 mt-4">
                <Colxx
                  xxs="5"
                  className="d-flex align-items-center justify-content-center flex-column px-4"
                >
                  <VirtualCard
                    card={{
                      name: values.name,
                      icon: values.icon,
                      color: values.color,
                      lastDigits: '••••',
                    }}
                    rgbColor={hexToRgb(values.color)}
                    withHover={false}
                    blocked={false}
                  />
                </Colxx>
                <Colxx xxs="7" className="px-4">
                  <Label className="form-group has-top-label mb-1">
                    <Field
                      name="name"
                      className="form-control"
                      maxLength={21}
                      onKeyUp={(e) =>
                        onNameChange(e.target.value, setFieldValue)
                      }
                    />
                    <IntlMessages id="refund.virtual-card.create.card-name" />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="invalid-feedback d-block"
                    />
                  </Label>
                  <Label className="form-group has-top-label mb-1">
                    <CustomColorPicker
                      color={values.color}
                      setColor={(v) => setFieldValue('color', v)}
                    />
                    <IntlMessages id="refund.virtual-card.create.color" />
                    <ErrorMessage
                      name="color"
                      component="div"
                      className="invalid-feedback d-block"
                    />
                  </Label>
                </Colxx>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button
                type="submit"
                color="primary"
                disabled={isSubmitting}
                outline
                size="lg"
              >
                {messages['refund.virtual-card.create.do-create']}
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
