import React, { useMemo } from 'react';

import { ReactComponent as FlightIcon } from '../../assets/img/icons/raw/plane.svg';
import { ReactComponent as HotelIcon } from '../../assets/img/icons/raw/hotel.svg';
import CurrencyWrapper from '../../components/common/CurrencyWrapper';
import classNames from 'classnames';
import { useIntl } from 'react-intl';

export default function ItineraryTooltip({ itemsTotal = {}, iconSize = 18 }) {
  const { messages } = useIntl();

  // States
  const { outbound, inbound, hotel } = itemsTotal;

  const { outboundTitle, inboundTitle, hotelTitle } = useMemo(
    () => ({
      outboundTitle: `${messages['travel.menu.flight']} (${messages['travel.outbound']})`,
      inboundTitle: `${messages['travel.menu.flight']} (${messages['travel.inbound']})`,
      hotelTitle: `${messages['itinerary.hotel.title']}`,
    }),
    []
  );

  // Render
  const renderItem = (Icon, title, total, className) => (
    <div className={classNames('item d-flex align-items-center', className)}>
      <Icon width={iconSize} height={iconSize} className="text-primary" />
      <div className="ml-3 text-left">
        <h5 className="m-0 p-0 text-primary font-primary fs-0-75rem lh-1">
          {title}
        </h5>
        <CurrencyWrapper value={total} atRight={false}>
          {({ formattedValue }) => (
            <p className="m-0 p-0 mt-1 fs-0-9rem lh-1">{formattedValue}</p>
          )}
        </CurrencyWrapper>
      </div>
    </div>
  );

  return (
    <div className="itinerary-tooltip p-3">
      {outbound ? renderItem(FlightIcon, inboundTitle, outbound) : null}
      {hotel ? renderItem(HotelIcon, hotelTitle, hotel, 'mt-3') : null}
      {inbound ? renderItem(FlightIcon, outboundTitle, inbound, 'mt-3') : null}
    </div>
  );
}
