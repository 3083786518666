import moment from 'moment';
import { capitalize } from '../utils/string';

export const variableLookupTable = {
  $d: 'DD',
  $D: 'ddd',
  $DD: 'dddd',
  $m: 'MM',
  $M: 'MMM',
  $MM: 'MMMM',
  $y: 'YY',
  $yy: 'YYYY',
  $a: 'YY',
  $aa: 'YYYY',
};

export const variableFormatFunctions = {
  ddd: capitalize,
  dddd: capitalize,
  MMM: capitalize,
  MMMM: capitalize,
};

export const variableRegex = /\$[dDmMya]+/g;

export function parseVariable({ match, date = moment(), noMatch = '' }) {
  const variable = variableLookupTable[match];

  if (variable) {
    const formatFunction = variableFormatFunctions[variable];
    const value = date.format(variable);

    return formatFunction ? formatFunction(value) : value;
  }

  return noMatch;
}

export function replaceVariables(value = '', date = moment()) {
  return value.replace(variableRegex, (match) =>
    parseVariable({ match, date })
  );
}
