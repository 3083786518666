import { useCallback } from 'react';
import firebaseApp from '../../services/Firebase.js';
import useDataArchive, {
  TGetDataFromArchive,
} from 'src/portao3-legacy/hooks/useDataArchive';
import { getDocumentsFromCollection } from 'src/portao3-legacy/utils/firebase';
import TravelSearch from './TravelSearch.tsx';
import FlightFilters from './FlightFilters.tsx';
import FlightResults from './FlightResults.tsx';

import { OrderTypes } from 'src/portao3-legacy/constants/orderTypes';
import { FlightParamsConfig } from 'src/portao3-legacy/constants/search/flight';

import { TSearch } from 'src/portao3-legacy/types/orders/orders';
import {
  TFlightAirportDetails,
  TFlightSearchItem,
} from 'src/portao3-legacy/types/orders/flight/flightOrder';

async function getAirportDetails(
  iataCode: string,
  getDataFromArchive: TGetDataFromArchive<
    TFlightAirportDetails[],
    TFlightAirportDetails
  >
) {
  return getDataFromArchive(
    `airport-${iataCode}`,
    () =>
      getDocumentsFromCollection(() =>
        (firebaseApp as any).getAirPortInfo(iataCode).limit(1).get()
      ) as Promise<TFlightAirportDetails[]>,
    ([airport]) => airport
  ).catch(() => []);
}

export default function FlightSearch({ search }: { search: TSearch }) {
  // Hooks
  const { getDataFromArchive } = useDataArchive();

  // Search
  const formatResultItem = useCallback(async (item: TFlightSearchItem) => {
    const airportDetails = await Promise.all(
      item.journeys.map(async ({ arrivalStation }) =>
        getAirportDetails(arrivalStation, getDataFromArchive)
      )
    );

    return {
      ...item,
      airportDetails,
    } as TFlightSearchItem;
  }, []);

  // Render
  return (
    <TravelSearch
      orderType={OrderTypes.FLIGHT}
      paramsConfig={FlightParamsConfig}
      search={search}
      formatResultItem={formatResultItem}
      FilterComponent={FlightFilters}
      ResultsComponent={FlightResults as any}
    />
  );
}
