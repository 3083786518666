export const sortPolicyBulkData = (currentData, newData) => {
  const oldData = currentData.reduce((obj, value) => {
    obj[value.name] = value;
    return obj;
  }, {});

  const createdData = [];
  const modifiedData = [];
  const invalidData = [];

  for (let i = 0, length = newData.length; i < length; i++) {
    const value = newData[i];

    if (value.name === '') {
      invalidData.push(value);
    } else if (value.name in oldData) {
      if (oldData[value.name]?.externalId !== value.externalId)
        modifiedData.push({
          ...value,
          id: oldData[value.name].id,
          externalId: value.externalId || null,
        });
      oldData[value.name] = undefined;
    } else createdData.push(value);
  }

  return {
    created: createdData,
    modified: modifiedData,
    deleted: Object.values(oldData).filter((value) => value),
    invalid: invalidData,
  };
};

export const applyBulkImport = ({
  deleted,
  modified,
  created,
  onDelete,
  onModified,
  onCreated,
}) => {
  const promises = [];

  if (deleted.length) {
    promises.push(Promise.all(deleted.map((value) => onDelete(value))));
  }

  if (modified.length) {
    promises.push(Promise.all(modified.map((value) => onModified(value))));
  }

  if (created.length) {
    promises.push(
      Promise.all(
        created.map((value) => {
          if (!value.externalId) delete value['externalId'];
          onCreated(value);
        })
      )
    );
  }

  return Promise.all(promises);
};

export function formatActions(actions = [], withHierarchy) {
  if (!withHierarchy) return actions.map(({ hierarchy, ...action }) => action);

  return actions.map((action) => ({
    ...action,
    hierarchy: parseInt(action.hierarchy) || 0,
  }));
}
