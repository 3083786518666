import React, { useMemo } from 'react';
import GlideComponent from '../carousel/GlideComponent';
import map from 'lodash/map';

export default function CarPhotos({ car }) {
  const hasPhotos = useMemo(() => car.photos?.length > 0, [car]);

  if (!hasPhotos) return null;

  return (
    <GlideComponent
      settings={{
        gap: 1,
        perView: 1,
        type: 'carousel',
        breakpoints: {
          600: { perView: 1 },
          1400: { perView: 1 },
        },
        hideNav: true,
        reservationNav: true,
      }}
    >
      {map(car.photos, (photo, index) => {
        return (
          <div
            key={`car-photo-${index}`}
            className="image-slide-car"
            style={{
              backgroundImage: `url(${photo})`,
            }}
          />
        );
      })}
    </GlideComponent>
  );
}
