import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { RefundStatus } from '../../constants/refundStatus';
import ApprovalTimeline from '../common/ApprovalTimeline';
import { canApproveReservation } from '../../utils/orders';

const MESSAGES = {
  [RefundStatus.APPROVING]: 'refund.expense.approving',
  [RefundStatus.APPROVED]: 'refund.expense.approved',
  [RefundStatus.REJECTED]: 'refund.expense.rejected',
  [RefundStatus.CANCELLING]: 'refund.expense.cancelling',
  [RefundStatus.CANCELLED]: 'refund.expense.cancelled',
  [RefundStatus.UNDO]: 'refund.expense.cancelled',
  [RefundStatus.DISMISSED]: 'refund.expense.dismissed',
  [RefundStatus.ERROR]: 'refund.expense.error',
  [RefundStatus.ACTIVATING]: 'refund.timeline.title.activating',
  [RefundStatus.WAITING_PAYMENT]: 'refund.expense.waiting-payment',
  [RefundStatus.PROCESSING]: 'refund.expense.processing',
  [RefundStatus.PAID]: 'refund.expense.paid',
};

const ADMINSTRATIVE_MESSAGES = {
  [RefundStatus.APPROVING]: 'refund.expense.approving',
  [RefundStatus.APPROVED]: 'refund.administrative-expense.approved',
  [RefundStatus.REJECTED]: 'refund.expense.rejected',
  [RefundStatus.CANCELLING]: 'refund.expense.cancelling',
  [RefundStatus.CANCELLED]: 'refund.administrative-expense.cancelled',
  [RefundStatus.UNDO]: 'refund.expense.cancelled',
  [RefundStatus.DISMISSED]: 'refund.expense.dismissed',
  [RefundStatus.ERROR]: 'refund.expense.error',
  [RefundStatus.ACTIVATING]: 'refund.administrative-expense.activating',
  [RefundStatus.WAITING_PAYMENT]: 'refund.expense.waiting-payment',
  [RefundStatus.PROCESSING]: 'refund.expense.processing',
  [RefundStatus.PAID]: 'refund.expense.paid',
};

export default function BudgetStatusInfo({
  budget,
  items = [],
  approvals = [],
  isValid = true,
  onDismiss = () => {},
  onCancel = () => {},
  onSendForApproval = () => {},
  onPutOnHold = () => {},
  goForApproval = () => {},
  payBudget = () => {},
  disableActions,
  title,
}) {
  const { messages } = useIntl();
  const { user } = useSelector(({ auth }) => auth);

  // States
  const StatusMessages = useMemo(
    () => (budget?.administrative ? ADMINSTRATIVE_MESSAGES : MESSAGES),
    [budget]
  );

  const canApprove = useMemo(
    () => canApproveReservation({ approvals, user }),
    [user, approvals]
  );

  const canCancel = useMemo(
    () =>
      !budget.budget || items.filter(({ refunded }) => !refunded).length === 0,
    [budget, items]
  );

  const approving = useMemo(
    () =>
      [RefundStatus.APPROVING_ANTICIPATION, RefundStatus.APPROVING].includes(
        budget.statusCode
      ),
    [budget]
  );

  // Render
  const renderCancel = () => {
    if (canCancel) {
      return (
        <Button
          outline
          color="primary"
          className="mb-3 mt-0"
          onClick={() => onCancel()}
          style={{ whiteSpace: 'normal' }}
          title={messages['refund.expense.undo']}
          disabled={disableActions}
          name="reject-budget"
        >
          {messages['travel.order.cancellation.cancel']}
        </Button>
      );
    } else return null;
  };

  const renderMessage = () => {
    if (items.length === 0) return 'refund.expense.no-items';
    if (!isValid) return 'refund.expense.complete-fields';
    else return 'refund.expense.review-done';
  };

  const renderActiveButton = (title, message, onClick, disabled) => (
    <Button
      outline
      color="primary"
      onClick={onClick}
      style={{ whiteSpace: 'normal' }}
      className="mb-3 mt-0"
      disabled={disabled}
      title={title}
    >
      {message}
    </Button>
  );

  const renderStatus = () => {
    const applyAlertColorText = items.length === 0 || !isValid;

    if (approving) {
      return (
        <>
          <p className="my-3">{messages['refund.timeline.title.approval']}</p>
          <ul>
            <li className="done">{messages['refund.timeline.created']}</li>
            <ApprovalTimeline approvals={approvals} />

            <li className="next">{messages['refund.timeline.approved']}</li>
          </ul>
          {canApprove === true || canApprove.length > 0 ? (
            <div className="mt-3">
              <Button
                outline
                color="primary"
                className="mt-0"
                onClick={goForApproval}
                disabled={disableActions}
                name="approve-budget"
              >
                {messages['button.approval.approve']}
              </Button>
            </div>
          ) : null}
        </>
      );
    } else if (budget.statusCode === RefundStatus.ANTICIPATION_APPROVED) {
      return (
        <>
          <p className="my-3">{messages['refund.timeline.title.waiting']}</p>
          <ul>
            <li className="done">{messages['refund.timeline.created']}</li>
            <ApprovalTimeline approvals={approvals} showOnlyApproved />

            <li className="next mb-3">{messages['refund.timeline.waiting']}</li>
          </ul>
          {renderCancel()}
        </>
      );
    } else if (budget.statusCode === RefundStatus.ACTIVATING) {
      return (
        <>
          <p className="my-3">
            {messages[StatusMessages[RefundStatus.ACTIVATING]]}
          </p>
        </>
      );
    } else if (budget.statusCode < RefundStatus.APPROVING) {
      return (
        <>
          <p className={`my-3 ${applyAlertColorText && 'color-alert'}`}>
            {messages[renderMessage()]}
          </p>
          <hr />
          <div className="mt-4">
            {renderActiveButton(
              title,
              messages['refund.modal.btn.send-for-approval'],
              onSendForApproval,
              !isValid || disableActions
            )}

            {budget.fare?.total && budget.withAnticipation
              ? renderActiveButton(
                  messages['refund.modal.btn.put-on-hold.title'],
                  messages['refund.modal.btn.put-on-hold'],
                  onPutOnHold,
                  disableActions
                )
              : null}

            {renderCancel()}
          </div>
        </>
      );
    } else if (budget.statusCode === RefundStatus.WAITING_PAYMENT) {
      return (
        <>
          <p className="my-3">{messages[StatusMessages[budget.statusCode]]}</p>
          <hr />
          <Button
            outline
            color="primary"
            className="mt-3"
            onClick={payBudget}
            name="dismiss-budget"
          >
            {messages['refund.modal.btn.pay']}
          </Button>
        </>
      );
    } else if (
      [
        RefundStatus.CANCELLING,
        RefundStatus.PROCESSING,
        RefundStatus.PAID,
      ].includes(budget.statusCode)
    ) {
      return (
        <>
          <p className="my-3">{messages[StatusMessages[budget.statusCode]]}</p>
        </>
      );
    } else if (budget.statusCode <= RefundStatus.ERROR) {
      return (
        <>
          <p className="my-3">{messages[StatusMessages[budget.statusCode]]}</p>
          <hr />
          {!['wJnrK5D53CqBhyAgKRIG', 'EoGypKxtc2l8FUInJc3q'].includes(
            user.organizationId
          ) && (
            <Button
              outline
              color="primary"
              className="mt-3"
              onClick={() => {
                onDismiss();
              }}
              name="dismiss-budget"
            >
              {messages['travel.order.cancellation.dismiss']}
            </Button>
          )}
        </>
      );
    } else
      return (
        <p className="my-3">{messages[StatusMessages[budget.statusCode]]}</p>
      );
  };

  return (
    <div className="expense-status-info w-100">
      <hr />
      {renderStatus()}
    </div>
  );
}
