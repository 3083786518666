import React, { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Button } from 'reactstrap';
import appFunctions from '../../services/Functions';
import { downloadBlob, downloadFromURL } from '../../utils/fileGeneration';
import { ReactComponent as FileIcon } from '../../assets/img/icons/download-file.svg';

export default function DownloadReceipts({ budget, budgetItems = [] }) {
  const { messages } = useIntl();

  const [isLoading, setIsLoading] = useState(false);
  const { isValid, btnTitle } = useMemo(
    () => ({
      isValid:
        budget.administrative ||
        budgetItems.some((item = {}) => item.receipts?.length > 0),
      btnTitle: budget.administrative
        ? 'budget-modal.administrative.download-receipts'
        : 'budget-modal.download-receipts',
    }),
    [budget, budgetItems]
  );

  // Get Receipt
  const downloadFromReceiptBudget = async () => {
    const { data: link } = await appFunctions.getReceiptsFromBudget(
      budget.id,
      true
    );

    if (link) {
      downloadFromURL(link, `${budget.id}.zip`);
    }
  };

  const downloadFromAdministrativeBudget = async () => {
    const { data } = await appFunctions.makeReceiptForAdministrativeBudget(
      budget.id
    );

    downloadBlob(
      new Blob([data], { type: 'application/pdf' }),
      `${budget.id}.pdf`
    );
  };

  const downloadReceipts = async () => {
    try {
      setIsLoading(true);

      const downloadFunction = budget.administrative
        ? downloadFromAdministrativeBudget
        : downloadFromReceiptBudget;

      await downloadFunction();
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  if (isValid) {
    return (
      <div className="print">
        <Button
          onClick={downloadReceipts}
          disabled={isLoading}
          title={messages[btnTitle]}
          className="flex-center"
        >
          <FileIcon className="icon" />
        </Button>
      </div>
    );
  }

  return <div />;
}
