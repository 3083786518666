import React, { createRef, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { ReactComponent as CloseIcon } from '../../assets/img/icons/close.svg';

export default function Documentation({ className, items, toggle }) {
  // States
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [caretInfo, setCaretInfo] = useState({
    top: 0,
    height: 20,
  });

  const itemRefs = useMemo(() => items.map(() => createRef()), [items]);

  const Component = useMemo(
    () => items[selectedIndex]?.Component,
    [items, selectedIndex]
  );

  // Effects
  useEffect(() => {
    const ref = itemRefs[selectedIndex];

    if (ref?.current) {
      const { offsetTop, offsetHeight } = ref.current;
      setCaretInfo({
        top: offsetTop,
        height: offsetHeight,
      });
    }
  }, [selectedIndex]);

  // Functions

  // Render
  return (
    <div className={classNames('documentation-modal d-flex', className)}>
      <nav className="tabs flex-shrink-0 py-4">
        <ul className="m-0 p-0">
          {items.map(({ id, name }, index) => (
            <li
              ref={itemRefs[index]}
              key={id}
              className={classNames(
                'tab-name text-white fs-0-9rem text-center py-3 w-100 pointer',
                { selected: index === selectedIndex }
              )}
              onClick={() => setSelectedIndex(index)}
            >
              {name}
            </li>
          ))}
        </ul>

        <div
          className="caret"
          style={{ top: caretInfo.top, height: caretInfo.height }}
        >
          <div className="indicator"></div>
        </div>
      </nav>
      <div className="content flex-grow-1 position-relative">
        <div className="close-icon pointer flex-center" onClick={toggle}>
          <CloseIcon className="icon text-primary" width="1rem" height="1rem" />
        </div>

        <PerfectScrollbar
          options={{ suppressScrollX: true, useBothWheelAxes: false }}
          className="m-0 p-0 px-4 pt-5"
        >
          <Component />
        </PerfectScrollbar>
      </div>
    </div>
  );
}
