export function hasExpenses(organization) {
  return (
    getOrganizationConfig(organization?.config, 'refunds.active') !== false
  );
}

export function canGoToExpenseRoute(organization) {
  return (
    getOrganizationConfig(organization?.config, 'refunds.active') !== false ||
    getOrganizationConfig(organization?.config, 'openBooking.allowed')
  );
}

export function getOrganizationConfig(organizationConfig, configPath) {
  const get = require('lodash/get');
  const {
    organizationDefaultConfig,
  } = require('../constants/organizationConfig');

  return get(organizationConfig, configPath) !== undefined
    ? get(organizationConfig, configPath)
    : get(organizationDefaultConfig, configPath);
}
