import md5 from 'md5';
import { ReceiptTypes } from '../constants/refundTypes';

export const createFormData = (files) => {
  const data = new FormData();

  files.forEach((file) => {
    data.append('file', file.file, file.name);
  });

  return data;
};

export const formatUploadedReceiptFile = (
  file,
  type = ReceiptTypes.UPLOADED
) => {
  return {
    file,
    name: file.name,
    uploaded: true,
    previewUrl: URL.createObjectURL(file),
    progress: 0,
    id: md5(file.name),
    mimeType: file.type,
    type,
  };
};

export const downloadFromBlobUrl = (blobUrl, fileName) => {
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = blobUrl;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};
