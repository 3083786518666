import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Nav, NavItem, NavLink, Tooltip } from 'reactstrap';
import classNames from 'classnames';
import { OrderTypes } from '../../constants/orderTypes';
import { useSelector } from 'react-redux';
import WithShouldRender from '../../hoc/withShouldRender';
import { DEFAULT_CURRENCY } from '../../utils/money';
import { getOrganizationConfig } from '../../utils/organization';
import { getUserNationality } from 'src/portao3-legacy/utils/nationality';
import { AllowedOrganizations } from 'src/portao3-legacy/constants/search/car';

const TYPES_ORDER = [
  OrderTypes.FLIGHT,
  OrderTypes.HOTEL,
  OrderTypes.CAR,
  OrderTypes.BUS,
  OrderTypes.OFFICE,
];

function TravelTab({ activeTab, toggleTab = () => {} }) {
  const { messages } = useIntl();
  const { organization, user } = useSelector(({ auth }) => auth);

  const { allowedTravelTypes, organizationCurrency } = useMemo(
    () => ({
      allowedTravelTypes: getOrganizationConfig(
        organization.config,
        'allowedTravelTypes'
      ),
      organizationCurrency: getOrganizationConfig(
        organization.config,
        'currency'
      ),
    }),
    [organization]
  );

  const nationality = useMemo(
    () => getUserNationality({ user, organization }),
    [user, organization]
  );

  const [showToolTip, setShowToolTip] = useState(false);

  // Effects
  useEffect(() => {
    const activeTab = TYPES_ORDER.find(
      (key) => allowedTravelTypes[key] !== false
    );
    toggleTab(activeTab || OrderTypes.FLIGHT);
  }, []);

  const toggleToolTip = (value = !showToolTip) => {
    setShowToolTip(value);
  };

  // Render
  return (
    <Nav className="travel-tabs" pills>
      <WithShouldRender value={OrderTypes.FLIGHT} object={allowedTravelTypes}>
        <NavItem>
          <NavLink
            to="#"
            className={classNames({
              active: activeTab === 'flight',
              'nav-link': true,
              'flight-nav': true,
            })}
            onClick={() => {
              toggleTab('flight');
            }}
          >
            <span>{messages['travel.menu.flight']}</span>
          </NavLink>
        </NavItem>
      </WithShouldRender>
      <WithShouldRender value={OrderTypes.HOTEL} object={allowedTravelTypes}>
        <NavItem>
          <NavLink
            to="#"
            className={classNames({
              active: activeTab === 'hotel',
              'nav-link': true,
              'hotel-nav': true,
            })}
            onClick={() => {
              toggleTab('hotel');
            }}
          >
            <span>{messages['travel.menu.hotel']}</span>
          </NavLink>
        </NavItem>
      </WithShouldRender>
      {AllowedOrganizations.includes(organization.id) && (
        <WithShouldRender value={OrderTypes.CAR} object={allowedTravelTypes}>
          <NavItem
            id="car-nav-item"
            onMouseEnter={() => toggleToolTip(true)}
            onMouseLeave={() => toggleToolTip(false)}
          >
            <NavLink
              to="#"
              className={classNames({
                active: activeTab === 'car',
                'nav-link': true,
                'car-nav': true,
              })}
              // onClick={() => {
              //   toggleTab('car');
              // }}
            >
              <span>{messages['travel.menu.car']}</span>
            </NavLink>
          </NavItem>
          <Tooltip
            target={'car-nav-item'}
            isOpen={showToolTip}
            toggle={toggleToolTip}
          >
            <p className="m-0 p-0 small warning">
              Para reservas de carros entre em contato com o suporte.
            </p>
          </Tooltip>
        </WithShouldRender>
      )}

      {nationality === 'BR' ? (
        <>
          <WithShouldRender value={OrderTypes.BUS} object={allowedTravelTypes}>
            <NavItem>
              <NavLink
                to="#"
                className={classNames({
                  active: activeTab === 'bus',
                  'nav-link': true,
                  'bus-nav': true,
                })}
                onClick={() => {
                  toggleTab('bus');
                }}
              >
                <span>{messages['travel.menu.bus']}</span>
              </NavLink>
            </NavItem>
          </WithShouldRender>
          <WithShouldRender
            value={OrderTypes.OFFICE}
            object={allowedTravelTypes}
          >
            <NavItem>
              <NavLink
                to="#"
                className={classNames({
                  active: activeTab === 'office',
                  'nav-link': true,
                  'office-nav': true,
                })}
                onClick={() => {
                  toggleTab('office');
                }}
              >
                <span>{messages['travel.menu.office']}</span>
              </NavLink>
            </NavItem>
          </WithShouldRender>
        </>
      ) : null}
      {/* <WithShouldRender value={OrderTypes.ITINERARY} object={allowedTravelTypes}>
        <NavItem>
          <NavLink
            to="#"
            className={classNames({
              active: activeTab === 'itinerary',
              'nav-link': true,
            })}
            onClick={() => {
              toggleTab('itinerary');
            }}
          >
            <span>{messages['travel.menu.itinerary']}</span>
          </NavLink>
        </NavItem>
      </WithShouldRender> */}
    </Nav>
  );
}

export default React.memo(TravelTab);
