import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';
import classNames from 'classnames';

export default function Spinner({
  white = false,
  className = '',
  style = {},
  relative = false,
}) {
  const lottieRef = useRef(null);

  useEffect(() => {
    if (lottieRef.current) {
      const LoadingAnimation = require(white
        ? '../../assets/lottie/white_spinner.json'
        : '../../assets/lottie/spinner.json');

      lottie.loadAnimation({
        autoplay: true,
        loop: true,
        container: lottieRef.current,
        animationData: LoadingAnimation,
      });
    }
  }, []);

  return (
    <div
      className={classNames('lottie-spinner', className, { relative })}
      ref={lottieRef}
      style={style}
    />
  );
}
