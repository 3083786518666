import React, { Component } from 'react';
import { Row, Modal, ModalBody, Label } from 'reactstrap';
import { Colxx } from '../../components/common/CustomBootstrap';
import IntlMessages from '../../helpers/IntlMessages';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { connect } from 'react-redux';

import { stringToMoney } from '../../utils/money';
import HotelAmenities from '../../components/order/HotelAmenities';
import HotelPhotos from '../../components/order/HotelPhotos';
import HotelStars from '../../components/order/HotelStars';
import { injectIntl } from 'react-intl';

class PackageHotelModal extends Component {
  render() {
    const {
      item,
      intl: { messages },
    } = this.props;

    return (
      <Modal
        isOpen={this.props.showModal}
        toggle={this.props.toggleModal}
        className="reservation small"
      >
        <ModalBody className="hotel">
          <Row>
            <Colxx xxs="5" className="pr-0">
              <HotelPhotos hotelId={item.hotelId} hotel={item.hotel} />
            </Colxx>
            <Colxx xxs="7" className="pl-0">
              <div className="hotel-content">
                <PerfectScrollbar
                  options={{
                    suppressScrollX: true,
                    wheelPropagation: false,
                  }}
                >
                  <h3 className="hotel-title ml-3">
                    {item.hotel.name}
                    <HotelStars hotel={item.hotel} hotelId={item.hotelId} />
                  </h3>

                  <p className="text-muted-darke ml-3 text-capitalize">
                    {item.hotel.destination.address.toLowerCase()}
                  </p>

                  <HotelAmenities hotel={item.hotel} hotelId={item.hotelId} />

                  <h5 className="mt-4 ml-3">
                    {
                      messages[
                        'containers.search.hotel-modal.hotel-description'
                      ]
                    }
                  </h5>
                  <div
                    className="ml-3 mr-3 mb-5"
                    dangerouslySetInnerHTML={{
                      __html: item.hotel.description['pt'],
                    }}
                  />

                  <div className="reservation-params primary pl-3">
                    <Row>
                      <Colxx xxs="10">
                        <Label className="form-group disabled search has-top-label">
                          <p>{item.selectedRoom.description}</p>
                          <p className="pt-0">
                            {item.selectedRoom.adults === 1
                              ? `1 ${messages['general.adult']}`
                              : `${item.selectedRoom.adults} ${messages['general.adults']}`}
                            . {item.selectedRoom.board.description}
                          </p>
                          <IntlMessages id="travel.rooms" />
                        </Label>
                      </Colxx>
                    </Row>
                    <Row>
                      <Colxx xxs="10">
                        <Label className="form-group disabled search has-top-label">
                          <p>
                            {stringToMoney(
                              item.fare.total,
                              0,
                              item.fare.currency
                            )}
                          </p>
                          <IntlMessages id="travel.total" />
                        </Label>
                      </Colxx>
                    </Row>
                  </div>
                </PerfectScrollbar>
              </div>
            </Colxx>
          </Row>
        </ModalBody>
      </Modal>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { user } = auth;
  return { user };
};

export default connect(mapStateToProps)(injectIntl(PackageHotelModal));
