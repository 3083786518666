import { useMemo, useState, useEffect } from 'react';
import moment from 'moment';

export default function useItems(storedItems = [], initialQuantity = 1) {
  const [items, setItems] = useState(
    storedItems.map((item, id) => ({
      ...item,
      id,
      total: item.quantity * item.price,
    }))
  );

  const [total, isValid] = useMemo(() => {
    let total = 0,
      isValid = items.length > 0;

    items.forEach((item) => {
      total += item.total || item.quantity * item.price;
      if (isValid) {
        const isItemValid = item.name
          ? item.quantity && item.price
          : !item.price;
        if (!isItemValid) isValid = false;
      }
    });

    return [total, isValid && total > 0];
  }, [items]);

  // Effects
  useEffect(() => {
    if (items.length === 0) {
      addItem();
    }
  }, [items]);

  const addItem = (itemInfo = {}, id = moment().unix(), forced = false) => {
    const length = items.length;

    if (forced || length === 0 || items[length - 1].name) {
      const {
        quantity = initialQuantity,
        price = 0,
        name = '',
        ...other
      } = itemInfo;

      setItems([
        ...items,
        { id, name, quantity, price, total: quantity * price, ...other },
      ]);
    }
  };

  const bulkAddItems = (items = []) => {
    if (items.length) {
      const startID = moment().unix();

      setItems(
        items.map((item, index) => ({
          ...item,
          id: startID + index,
        }))
      );

      return true;
    } else return false;
  };

  const createEmptyItem = () => {
    return {
      id: moment().unix(),
      name: '',
      quantity: 1,
      price: 0,
      total: 0,
    };
  };

  // Functions
  const removeItem = (index) => {
    const newItems = [...items];
    newItems.splice(index, 1);
    setItems(newItems);
  };

  const modifyItem = (index, item) => {
    const newItems = [...items];
    item.total = (item.quantity || 1) * item.price;
    newItems[index] = item;

    if (newItems[newItems.length - 1].name) {
      newItems.push(createEmptyItem());
    }

    setItems(newItems);
  };

  const onItemBlur = (index) => {
    const item = items[index];
    if (!(item.name || item.price)) {
      if (index < items.length - 1) {
        removeItem(index);
      }
    }
  };

  return {
    items,
    setItems,
    addItem,
    removeItem,
    modifyItem,
    total,
    isValid,
    onItemBlur,
    bulkAddItems,
  };
}
