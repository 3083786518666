import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import Switch from 'rc-switch';
import { useIntl } from 'react-intl';
import { Label, Row } from 'reactstrap';
import { Colxx } from './CustomBootstrap';

import firebaseApp from '../../services/Firebase';

export default function PermissionToggleFragment({
  className,
  requiredValuePath = 'config.required',
  editableValuePath = 'config.editable',
  hasEditable = false,
  fieldName,
  requiredValue,
  editableValue,
  organizationId,
  messageId = 'permission-toggle-fragment.description',
  requiredMessageId = 'permission-toggle-fragment.switch.required.title',
  editableMessageId = 'permission-toggle-fragment.switch.editable.title',
  simple,
}) {
  const { messages } = useIntl();

  // States
  const [required, setRequired] = useState(
    requiredValue === undefined ? false : requiredValue
  );
  const [editable, setEditable] = useState(
    editableValue === undefined ? true : editableValue
  );

  // Effects
  useEffect(() => {
    setRequired(requiredValue === undefined ? false : requiredValue);
  }, [requiredValue]);

  useEffect(() => {
    setEditable(editableValue === undefined ? true : editableValue);
  }, [editableValue]);

  // Functions
  const setDBValue = useCallback(
    async (valuePath, fieldName, value) => {
      try {
        if (!(valuePath && fieldName && organizationId)) return;

        await firebaseApp.updateConfigField(
          organizationId,
          `${valuePath}.${fieldName}`,
          value
        );
      } catch (err) {
        console.error(
          'Unable to set field',
          err,
          `${valuePath}.${fieldName}`,
          value
        );
      }
    },
    [organizationId]
  );

  const handleRequiredValueChange = (value) => {
    setDBValue(requiredValuePath, fieldName, value);
    setRequired(value);
  };

  const handleEditableValueChange = (value) => {
    setDBValue(editableValuePath, fieldName, value);
    setEditable(value);
  };

  // Render
  const renderSwitches = () => (
    <>
      <Label className="d-block">
        <Switch
          checked={required}
          className="mr-2 custom-switch"
          onChange={handleRequiredValueChange}
        />
        {messages[requiredMessageId]}
      </Label>

      {hasEditable ? (
        <Label className="d-block mt-3">
          <Switch
            checked={editable}
            className="mr-2 custom-switch"
            onChange={handleEditableValueChange}
          />
          {messages[editableMessageId]}
        </Label>
      ) : null}
    </>
  );

  return simple ? (
    <div
      className={classNames(
        'permission-toggle-fragment simple w-100',
        className
      )}
    >
      {renderSwitches()}
    </div>
  ) : (
    <Row
      className={classNames(
        'permission-toggle-fragment full m-0 p-0 mt-3 mb-5 p-4 w-100',
        className
      )}
    >
      <Colxx sm="12" className="m-0 p-0">
        <p className="m-0 p-0 fs-0-9rem font-weight-medium">
          {messages[messageId]}
        </p>
        <div className="mt-4">{renderSwitches()}</div>
      </Colxx>
    </Row>
  );
}
