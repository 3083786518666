import React, { useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import TravelLayout from '../../layout/TravelLayout';
import newRequest from './new-request';

function Travel(props) {
  const { match } = props;

  useEffect(() => {
    document.title = 'Portão 3';
  }, []);

  return (
    <TravelLayout>
      <Switch>
        <Route path={`${match.url}/`} component={newRequest} />
        <Redirect to="/error" />
      </Switch>
    </TravelLayout>
  );
}

export default Travel;
