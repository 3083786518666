import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Row, Modal, ModalBody, ModalFooter } from 'reactstrap';
import classnames from 'classnames';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Colxx } from '../common/CustomBootstrap';
import filter from 'lodash/filter';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';

import JourneyLarge from '../bus/JourneyLarge';
import JourneySmall from '../bus/JourneySmall';
import BusCarbonDetail from '../bus/BusCarbonDetail';

import firebaseApp from '../../services/Firebase';
import ReservationParams from './ReservationParams';
import { useIntl } from 'react-intl';
import Footer from './Footer';
import { useMemo } from 'react';
import OrderIssueCard from '../order/OrderIssueCard';
import { getAlterableReservations } from '../../utils/bus';
import orderStatus from '../../constants/orderStatus';
import Comment from '../expensesTimeline/Comment';
import useLocationMapping from '../../hooks/useLocationMapping/useLocationMapping';
import { canApproveReservation } from '../../utils/orders';
import useModalStages from '../../hooks/useModalStages';

let snapshotApprovals = () => {};

export default function BusModal({ toggleModal, order, path }) {
  const { user } = useSelector((state) => state.auth);
  const { messages } = useIntl();

  // States
  const {
    isApproving,
    toggleIsApproving,
    setIsApproving,
    isAltering,
    toggleIsAltering,
    setIsAltering,
  } = useModalStages({
    item: order,
    path,
  });

  const [loading, setLoading] = useState(true);

  const [reservations, setReservations] = useState([]);
  const [approvals, setApprovals] = useState([]);

  const canAlter = useMemo(
    () => Boolean(getAlterableReservations(reservations).length),
    [reservations]
  );

  // Effects
  useEffect(() => {
    loadData();

    return () => {
      snapshotApprovals();
    };
  }, []);

  // Hooks
  useLocationMapping(
    {
      paths: {
        approval: {
          onMatch: () => setIsApproving(true),
          validate: () => canApproveReservation({ approvals, user }),
          onFail: () => toggleIsApproving(false),
        },
        alteration: {
          onMatch: () => setIsAltering(true),
          validate: () => order.statusCode === orderStatus.ISSUED,
          onFail: () => toggleIsAltering(false),
        },
      },
    },
    [approvals, user]
  );

  // Functions
  const loadData = async () => {
    await Promise.all([loadApprovals(), loadReservationsFromPassenger()]);

    setLoading(false);
  };

  const loadReservationsFromPassenger = async () => {
    const reservationsSnapshot = await firebaseApp
      .getReservationsofOrderIdFromPassengerId(
        order.id,
        order.passengerId,
        user.organizationId
      )
      .get();

    const reservations = [];
    reservationsSnapshot.forEach((reservationSnapshot) => {
      reservations.push({
        ...reservationSnapshot.data(),
        id: reservationSnapshot.id,
      });
    });

    setReservations(sortBy(reservations, 'flightDetails.flightIndex'));
  };

  const loadApprovals = async () => {
    snapshotApprovals = firebaseApp
      .getApprovalsFromOrderId(order?.id)
      .onSnapshot(function (querySnapshot) {
        const approvals = [];

        querySnapshot.forEach(function (doc) {
          approvals.push({
            id: doc.id,
            ...doc.data(),
          });
        });

        setApprovals(approvals.reverse());
      });
  };

  // Render
  if (loading === true) {
    return (
      <Modal isOpen={true} toggle={toggleModal} className="reservation small">
        <ModalBody>
          <div className="loading p-relative" />
        </ModalBody>
      </Modal>
    );
  }

  return (
    <Modal
      isOpen={true}
      toggle={toggleModal}
      className={classnames({
        reservation: true,
        small: true,
        approving: isApproving || isAltering,
      })}
    >
      <ModalBody className="flight">
        <Row>
          <Colxx xxs="12">
            <div className="flight-content">
              <PerfectScrollbar
                options={{
                  suppressScrollX: true,
                  wheelPropagation: false,
                }}
              >
                <div>
                  <h5 className="mt-4 ml-3">
                    {messages['components.timeline.modal.reservation-data']}
                  </h5>
                  <ReservationParams order={order} />
                </div>

                {order.comment ? (
                  <div className="mx-3 comments">
                    <h5 className="mt-4">
                      {messages['refund.modal.refund.comments']}
                    </h5>
                    <Comment comment={order.comment} />
                  </div>
                ) : null}

                <div className="mt-5">
                  {order.journeys &&
                    map(order.journeys, (journey, index) => {
                      const legs = map(
                        filter(reservations, (reservation) => {
                          return (
                            reservation.busDetails.journeyId === journey.id
                          );
                        }),
                        (o) => {
                          return o.busDetails;
                        }
                      );

                      if (isApproving || isAltering) {
                        return (
                          <JourneySmall
                            key={`journey-${index}`}
                            journey={journey}
                            index={index}
                            params={{}}
                            legs={legs}
                          />
                        );
                      } else {
                        return (
                          <JourneyLarge
                            key={`journey-${index}`}
                            journey={journey}
                            index={index}
                            legs={legs}
                          />
                        );
                      }
                    })}
                </div>
                <h5 className="mt-2 ml-3">
                  {messages['components.timeline.modal.conditions']}
                </h5>
                <p className="text-muted-darker mx-3 others mb-5">
                  {messages['components.bus.description']}
                </p>

                <BusCarbonDetail esg={order.esg} />

                <OrderIssueCard
                  show={isApproving}
                  order={order}
                  reservations={reservations}
                />
              </PerfectScrollbar>
            </div>
          </Colxx>
        </Row>
      </ModalBody>

      <ModalFooter className="p-3">
        <Footer
          order={order}
          approvals={approvals}
          goForApproval={() => toggleIsApproving()}
          goForAlteration={() => toggleIsAltering()}
          toggleModal={toggleModal}
          reservations={reservations}
          isApproving={isApproving}
          isAltering={isAltering}
          isCancelling={false}
          canAlter={canAlter}
        />
      </ModalFooter>
    </Modal>
  );
}
