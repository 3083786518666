import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';

import LoaderESG from '../loaders/LoaderESG';

import firebaseApp from '../../services/Firebase';

export default function FlightCarbonDetail({ esg, segments }) {
  const [carbon, setCarbon] = useState({});
  const [loading, setLoading] = useState(true);

  const { messages, formatMessage } = useIntl();

  useEffect(() => {
    (async () => {
      await loadCarbonData();
    })();
  }, []);

  // Carbon Data
  const loadCarbonData = async () => {
    if (esg) {
      setCarbon(esg);
    }

    if (segments) {
      const airports = segments
        .map((segment, sIndex) => {
          return segment.flights.map((flight, fIndex) => {
            if (fIndex === 0 && sIndex === 0) {
              return `${flight.departureStation},${flight.arrivalStation}`;
            }

            return `${flight.arrivalStation}`;
          });
        })
        .join(',');

      const carbon = await firebaseApp.getFlightCarbonEmission(airports);

      setCarbon(carbon);
    }

    setLoading(false);
  };

  if (loading) return <LoaderESG />;

  // Render
  if (carbon.carbon_kg) {
    return (
      <div className="esg-label">
        <i className="esg-icon" />
        <p>
          {formatMessage(
            { id: 'esg.flight.estimate' },
            {
              miles: parseInt(carbon.distance_miles),
              carbon: carbon.carbon_kg.toFixed(0),
            }
          )}
        </p>
      </div>
    );
  }

  return <div />;
}
