import React from 'react';
import { Card } from 'reactstrap';
import { Colxx } from '../common/CustomBootstrap';

import ContentLoader from 'react-content-loader';

export default function LoaderCardWithImage() {
  return (
    <Colxx xxs="12" className="mb-3">
      <Card className="d-flex flex-row list">
        <div className="d-flex flex-grow-1 mw-0 inherit-radius">
          <ContentLoader
            speed={2}
            width={150}
            height={100}
            viewBox="0 0 150 100"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
          >
            <rect x="0" y="0" rx="3" ry="3" width="150" height="100" />
          </ContentLoader>
          <div className="pl-2 card-body align-self-center d-flex flex-column flex-lg-row justify-content-between mw-0 align-items-lg-center">
            <ContentLoader
              speed={2}
              width={300}
              height={40}
              viewBox="0 0 300 40"
              backgroundColor="#f3f3f3"
              foregroundColor="#ecebeb"
            >
              <rect x="0" y="0" rx="3" ry="3" width="300" height="20" />
              <rect x="0" y="25" rx="3" ry="3" width="200" height="15" />
            </ContentLoader>
          </div>
          <div className="custom-control custom-checkbox pl-1 align-self-center pr-4">
            <ContentLoader
              speed={2}
              width={50}
              height={90}
              viewBox="0 0 50 90"
              backgroundColor="#f3f3f3"
              foregroundColor="#ecebeb"
            >
              <rect x="15" y="35" rx="3" ry="3" width="20" height="20" />
            </ContentLoader>
          </div>
        </div>
      </Card>
    </Colxx>
  );
}
