import equal from 'fast-deep-equal/es6';

import {
  AdminstrativeBudgetStatusMessages,
  BudgetStatusMessages,
  RefundStatus,
} from '../../../../constants/refundStatus';
import { RefundTypes } from '../../../../constants/refundTypes';
import recordStatus from '../../../../constants/recordStatus';

export function getStatusFilterOptions(card, budgets, messages) {
  const options = {};

  const statusMessages =
    card.type === RefundTypes.ADMINISTRATIVE_ACCOUNT
      ? AdminstrativeBudgetStatusMessages
      : BudgetStatusMessages;

  budgets.forEach(({ statusCode }) => {
    if (!(statusCode in options)) {
      options[statusCode] = {
        id: statusCode,
        value: statusCode,
        label: messages[statusMessages[statusCode]],
      };
    }
  });

  return {
    statusFilterOptions: Object.values(options).sort(
      (a, b) => a.value - b.value
    ),
    options,
  };
}

export function getDataTestId({ name = '', type = '', lastDigits = '' } = {}) {
  return `${type.toLowerCase()}-${
    name.split(' ').join('-').toLowerCase() || lastDigits
  }`;
}

export function filterBudgets(budgets = [], filterKeys = {}) {
  return budgets.filter(
    ({ statusCode, id }) => filterKeys[statusCode] || filterKeys[id]
  );
}

export function getNewStatusFilters({
  statusFilters = [],
  card,
  budgets,
  messages,
} = {}) {
  const { statusFilterOptions, options } = getStatusFilterOptions(
    card,
    budgets,
    messages
  );

  let newStatusFilters = statusFilters.filter((value) => value in options);

  if (!newStatusFilters.length) {
    newStatusFilters =
      RefundStatus.ACTIVE in options ? [RefundStatus.ACTIVE] : [];
  }

  return {
    statusFilterOptions,
    statusFilters: newStatusFilters,
    areStatusEqual: equal(newStatusFilters, statusFilters),
  };
}

export function getOldAndNewBudgetIds({
  budgets = [],
  oldBudgetIds: _oldBudgetIds = [],
  newBudgetIds: _newBudgetIds = [],
}) {
  if (!_oldBudgetIds.length)
    return {
      oldBudgetIds: budgets.map(({ id }) => id),
      newBudgetIds: [],
    };

  const oldBudgetIds = [];
  const newBudgetIds = [..._newBudgetIds];

  budgets.forEach(({ id }) => {
    if (!_oldBudgetIds.includes(id)) newBudgetIds.push(id);

    oldBudgetIds.push(id);
  });

  return {
    oldBudgetIds,
    newBudgetIds,
  };
}

export function handleStatusFilters({
  statusFilters,
  budgets,
  card,
  messages,
  setStatusFilterOptions,
  setStatusFilters,
}) {
  const {
    statusFilterOptions,
    statusFilters: newStatusFilters,
    areStatusEqual,
  } = getNewStatusFilters({
    statusFilters,
    budgets,
    card,
    messages,
  });

  setStatusFilterOptions(statusFilterOptions);

  if (!areStatusEqual) {
    setStatusFilters(newStatusFilters);
  }

  return {
    statusFilterOptions,
    statusFilters: newStatusFilters,
    areStatusEqual,
  };
}

export function handleOldAndNewBudgets({
  budgets = [],
  card = {},
  oldBudgetIds: _oldBudgetIds = [],
  newBudgetIds: _newBudgetIds = [],
  setNewBudgetIds = () => {},
  setOldBudgetIds = () => {},
  statusFilters = [],
}) {
  if (card.status !== recordStatus.ACTIVE) return;

  const { oldBudgetIds, newBudgetIds } = getOldAndNewBudgetIds({
    budgets,
    oldBudgetIds: _oldBudgetIds,
    newBudgetIds: _newBudgetIds,
  });

  setOldBudgetIds(oldBudgetIds);

  // If it's sorted
  if (statusFilters.length) {
    setNewBudgetIds(newBudgetIds);
  }

  return {
    oldBudgetIds,
    newBudgetIds,
  };
}

export function getFilterKeys(statusFilters = [], newBudgetIds = []) {
  const joinedArrays = statusFilters.concat(newBudgetIds);

  return joinedArrays.length
    ? joinedArrays.reduce((obj, key) => {
        obj[key] = true;
        return obj;
      }, {})
    : null;
}
