import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { NavLink, useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import find from 'lodash/find';
import map from 'lodash/map';

import LoadingCard from '../../components/timeline/LoadingCard';
import FlightCard from '../../components/timeline/FlightCard';
import HotelCard from '../../components/timeline/HotelCard';
import CarCard from '../../components/timeline/CarCard';
import BusCard from '../../components/timeline/BusCard';
import OfficeCard from '../../components/timeline/OfficeCard';
import OpenBookingFlightCard from '../../components/timeline/OpenBookingFlightCard';
import TimelineCardDecider from '../../components/timeline/TimelineCardDecider';

import UserSearch from '../../components/timeline/UserSearch';
import { OrderTypes } from '../../constants/orderTypes';
import { handleLocations, getLocations } from '../../utils/locations';
import firebaseApp from '../../services/Firebase';
import User from '../../services/User';
import { getWeather } from '../../services/Temperature';
import sanitizeHtml from 'sanitize-html';

import OrderStatus from '../../constants/orderStatus';

import {
  setPassenger,
  setSearchCodesWithError,
} from '../../redux/timeline/actions';
import { trackPropertiesSegment } from '../../utils/segment';
import { weathers } from '../../utils/weathers';

let snapshotReservation = () => {};
let snapshotOrders = () => {};

export default function Timeline() {
  const history = useHistory();
  const { messages, formatMessage } = useIntl();

  const { user, passenger } = useSelector(({ auth, timeline }) => ({
    user: auth?.user,
    passenger: timeline?.passenger,
  }));
  const dispatch = useDispatch();

  // Refs
  const orderPreviousStatus = useRef({});

  // States
  const [passengerName, setPassengerName] = useState('');
  const [loading, setLoading] = useState(true);
  const [locations, setLocations] = useState(null);
  const [temperature, setTemperature] = useState('');
  const [weatherDescription, setWeatherDescription] = useState('');
  const [nameLocation, setNameLocation] = useState('');
  const [hasAFlightInFewDays, setHasAFlightInFewDays] = useState(false);

  const [reservations, setReservations] = useState([]);
  const [orders, setOrders] = useState([]);
  const [displayReservations, setDisplayReservations] = useState([]);

  const hasAFlightInFewDaysFn = (days) => {
    const type = reservations[0]?.type;
    let dataString = '';

    if (type === 'hotel') {
      dataString = reservations[0].hotelDetails.checkIn;
      // Resto do código para manipular dataString em caso de hotel
    } else if (type === 'flight') {
      dataString = reservations[0].flightDetails.arrivalDateTime.split(' ')[0];
      // Resto do código para manipular dataString em caso de voo
    } else if (type === 'bus') {
      dataString = reservations[0].busDetails.arrivalDateTime.split(' ')[0];
    }

    const refactorDates = dataString.split('/');
    const data = new Date(
      refactorDates[2],
      refactorDates[1] - 1,
      refactorDates[0]
    );

    const dateGreaterThanDays = new Date();
    dateGreaterThanDays.setDate(dateGreaterThanDays.getDate() + days);
    setHasAFlightInFewDays(dateGreaterThanDays > data);

    return dateGreaterThanDays > data;
  };

  const getLatLong = async (iataCode) => {
    const snap = await firebaseApp.showLatLong(iataCode).get();
    const data = snap.data();

    const objReturn = {
      lat: data.latitude,
      long: data.longitude,
    };

    return objReturn;
  };

  const getDescriptionWeather = (code) => {
    const weathers = {
      '200, 201, 202, 210, 211, 212, 221, 230, 231, 232': '⛈️',
      '300, 301, 302, 310, 311, 312, 313, 314, 321': '⛅',
      '500, 501, 502, 503, 504, 511, 520, 521, 522, 530': '🌧️',
      '600, 601, 602, 611,612, 613, 615, 616, 620, 621, 622': '❄️',
      800: '☀️',
      '801, 802, 803, 804': '⛅',
    };

    const key = Object.keys(weathers).find((weather) => weather.includes(code));
    return weathers[key] || '';
  };

  // Effects
  useEffect(() => {
    setAsyncLocations();
  }, []);

  useEffect(() => {
    loadPassenger();
  }, [user]);

  useEffect(() => {
    if (passenger?.uid) {
      console.debug(`Loading timeline from passenger ${passenger.label}`);

      loadOrders();
      loadNextReservations();
    }

    return () => {
      snapshotReservation();
      snapshotOrders();
    };
  }, [passenger]);

  useEffect(() => {
    async function fetchData() {
      await filterReservations();
      await callAsyncTemperature();
    }
    fetchData();
  }, [reservations, orders]);

  // Load Passenger
  const loadPassenger = async () => {
    const passengerSnap = await firebaseApp.getUserFromId(user.uid).get();

    dispatch(setPassenger(passengerSnap.data()));
  };

  // Load Photos
  const setAsyncLocations = async () => {
    await handleLocations();
    const asyncLocations = await getLocations();

    setLocations(asyncLocations);
  };

  // Change Passenger
  const changeUser = async (passenger) => {
    const passengerSnap = await firebaseApp
      .getUserFromId(passenger.value)
      .get();

    dispatch(setPassenger(passengerSnap.data()));
    setLoading(true);
  };

  // Rules temperature
  const callAsyncTemperature = () => {
    const days = 3;
    const codeSuccess = [0, 30, 40, 45, 46, 47, 50];

    if (orders.length > 0) {
      const order = orders[0];
      const reservation = reservations[0];
      const typeReservation = reservation?.type;
      const Type = {
        HOTEL: 'hotel',
        FLIGHT: 'flight',
        BUS: 'bus',
      };
      const statusCode = order.statusCode;
      let locationName = '';

      if (hasAFlightInFewDaysFn(days) && codeSuccess.includes(statusCode)) {
        switch (typeReservation) {
          case Type.HOTEL:
            locationName =
              reservation.hotelDetails.hotel.destination.city.pt.split(',')[0];
            const { lat, lng } = reservation.hotelDetails.hotel.localization;
            getTemperature(lat, lng);
            break;
          case Type.BUS:
            locationName = reservation.busDetails.arrivalStation.split(',')[0];
            getTemperature(0, 0, locationName);
            break;
          case Type.FLIGHT:
            locationName = order?.journeys[0]?.arrivalStation.name;
            const iataCode = order?.journeys[0]?.arrivalStation?.iataCode;
            getLatLong(iataCode).then(({ lat, long }) => {
              getTemperature(lat, long);
            });
            break;
        }

        setPassengerName(passenger?.firstName);
        setNameLocation(locationName);
      }
    }
  };

  const getTemperature = (lat, long, city = '') => {
    getWeather(lat, long, city).then(({ temperature, weatherCode }) => {
      setTemperature(temperature.toString().slice(0, 2));
      setWeatherDescription(getDescriptionWeather(weatherCode));
    });
  };
  const AddReservationText = () => (
    <h3>
      <UserSearch setValue={changeUser} defaultUser={passenger} />
      <span
        dangerouslySetInnerHTML={{
          __html: sanitizeHtml(
            messages['containers.timeline.timeline.add-trip'],
            {
              allowedTags: ['br'],
            }
          ),
        }}
      />
      {''}
      🥰
    </h3>
  );

  const LoadingText = () => (
    <span
      dangerouslySetInnerHTML={{
        __html: sanitizeHtml(messages['general.loading'], {
          allowedTags: ['br'],
        }),
      }}
    />
  );

  // Load Reservations
  const loadNextReservations = () => {
    snapshotReservation();

    snapshotReservation = firebaseApp
      .getNextReservationsFromUserId(user.organizationId, passenger.uid)
      .onSnapshot(function (querySnapshot) {
        const reservations = [];

        querySnapshot.forEach(function (doc) {
          const orderId = doc.ref.parent.parent.id;

          reservations.push({
            ...doc.data(),
            refs: {
              orderId,
            },
          });
        });

        setReservations(
          reservations.sort((a, b) => {
            return a.orderTimestamp - b.orderTimestamp;
          })
        );
      });
  };

  const loadOrders = async () => {
    snapshotOrders();

    const previousStatusCodes = orderPreviousStatus.current,
      searchCodesWithError = [];

    snapshotOrders = firebaseApp
      .getNextOrdersFromUserId(user.organizationId, passenger.uid)
      .onSnapshot(function (querySnapshot) {
        const orders = [];

        querySnapshot.forEach(function (doc) {
          const order = {
            id: doc.id,
            ...doc.data(),
          };

          // Do not show dismissed
          if (order.statusCode >= OrderStatus.DISMISSED) return;

          // Check for orders with error
          const lastStatusCode = previousStatusCodes[order.id];
          if (
            lastStatusCode &&
            lastStatusCode !== OrderStatus.ERROR &&
            order.statusCode === OrderStatus.ERROR
          ) {
            searchCodesWithError.push(order.searchCode);
          }

          previousStatusCodes[order.id] = order.statusCode;

          orders.push(order);
        });

        setOrders(
          orders.sort((a, b) => {
            return a.orderTimestamp - b.orderTimestamp;
          })
        );

        // Handle searcch codes with error
        orderPreviousStatus.current = previousStatusCodes;
        if (searchCodesWithError.length)
          dispatch(setSearchCodesWithError(searchCodesWithError));
      });
  };

  const filterReservations = () => {
    const newDisplayReservations = [];

    reservations.forEach((reservation) => {
      if (find(orders, { id: reservation.refs.orderId })) {
        newDisplayReservations.push(reservation);
      }
    });

    setDisplayReservations(newDisplayReservations);
    setLoading(false);
  };

  // Modal
  const openModal = (orderId) => {
    trackPropertiesSegment('Opened order card', { orderId });
    history.push(`/travel/orders/${orderId}`);
  };

  // Render
  const renderLoading = () => {
    return (
      <div>
        <LoadingCard />
        <LoadingCard />
        <LoadingCard />
        <LoadingCard />
        <LoadingCard />
        <LoadingCard />
      </div>
    );
  };

  const renderTimeline = () => {
    return (
      <div className="timeline-items">
        {map(displayReservations, (reservation, index) => {
          const order = find(orders, { id: reservation.refs.orderId }) || null;

          if (order === null) {
            return;
          }

          if (reservation.type === OrderTypes.FLIGHT) {
            return (
              <TimelineCardDecider
                key={`timeline-card-${index}`}
                locations={locations}
                reservation={reservation}
                order={order}
                openFlight={() => openModal(order.id)}
                DefaultCardComponent={FlightCard}
                OpenBookingCardComponent={OpenBookingFlightCard}
              />
            );
          }

          if (reservation.type === OrderTypes.HOTEL) {
            return (
              <HotelCard
                key={`timeline-card-${index}`}
                reservation={reservation}
                order={order}
                openHotel={() => openModal(order.id)}
              />
            );
          }

          if (reservation.type === OrderTypes.CAR) {
            return (
              <CarCard
                key={`timeline-card-${index}`}
                reservation={reservation}
                order={order}
                openCar={() => openModal(order.id)}
              />
            );
          }

          if (reservation.type === OrderTypes.BUS) {
            return (
              <BusCard
                key={`timeline-card-${index}`}
                reservation={reservation}
                order={order}
                openBus={() => openModal(order.id)}
              />
            );
          }

          if (reservation.type === OrderTypes.OFFICE) {
            return (
              <OfficeCard
                key={`timeline-card-${index}`}
                reservation={reservation}
                order={order}
                openOffice={() => openModal(order.id)}
              />
            );
          }

          return null;
        })}
      </div>
    );
  };

  const renderTimelineTitle = () => {
    const canChangePassenger = useMemo(
      () => User.isAdmin(user) || user.permissions?.timeline === true,
      [user]
    );

    // Renders
    if (canChangePassenger) {
      if (loading) {
        return (
          <h3>
            <LoadingText />
          </h3>
        );
      }

      if (reservations.length) {
        return (
          <h3>
            <UserSearch setValue={changeUser} defaultUser={passenger} />
            {hasAFlightInFewDays ? (
              weatherDescription ? (
                <span>
                  {formatMessage(
                    { id: 'weather.info' },
                    {
                      passengerName,
                      nameLocation,
                      temperature,
                    }
                  )}{' '}
                  {weatherDescription}
                </span>
              ) : (
                ''
              )
            ) : (
              <span>
                Acompanhe abaixo as
                <br />
                próximas viagens.
              </span>
            )}
          </h3>
        );
      } else {
        return <AddReservationText />;
      }
    } else {
      return <AddReservationText />;
    }
  };

  return (
    <div className="sidebar">
      <div className="timeline-menu">
        <div className="scroll">
          <PerfectScrollbar
            options={{
              suppressScrollX: true,
              wheelPropagation: false,
            }}
          >
            <NavLink to="/" className="sidebar-logo">
              <span className="logo" />
            </NavLink>

            <div className="download-app">
              <p>{messages['containers.timeline.timeline.app']}</p>
              <a
                className="ios"
                href="https://apps.apple.com/br/app/port%C3%A3o-3/id1502054193"
              >
                <img
                  src="/assets/img/app-store.svg"
                  alt={messages['alts.components.timeline.timeline.download']}
                />
              </a>
              <a
                className="android"
                href="https://play.google.com/store/apps/details?id=com.portao3.portao3&hl=pt_BR"
              >
                <img
                  src="/assets/img/play-store.svg"
                  alt={messages['alts.components.timeline.timeline.available']}
                />
              </a>
            </div>

            <div className="item title">
              <div className="date smile">
                <img src="/assets/img/icons/timeline_smile.svg" alt="Smile" />
              </div>
              <div className="details unclickable">{renderTimelineTitle()}</div>
            </div>

            {loading ? renderLoading() : renderTimeline()}

            <div className="item filler">
              <div className="date" />
              <div className="details"></div>
            </div>
          </PerfectScrollbar>
        </div>
      </div>
    </div>
  );
}
