import React, { useState, useMemo } from 'react';
import { Button, CardBody, Collapse, Label, Row } from 'reactstrap';
import { Colxx } from '../../components/common/CustomBootstrap';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import firebaseApp from '../../services/Firebase';
import { getOrganizationConfig } from '../../utils/organization';
import Switch from 'rc-switch';
import { PROVIDERS } from '../../utils/search';
import { ReactComponent as TravelImg } from '../../assets/img/config/connectors.svg';

const getLabelAndValueFromProvider = (provider, messages) => {
  return Object.keys(provider).map((value) => ({
    value,
    label: messages[`admin.others.connectors.provider.${value}`] || value,
  }));
};

export default function ConnectorsView() {
  const { organization } = useSelector(({ auth }) => auth);
  const { messages } = useIntl();

  // States
  const [toggleValues, setToggleValues] = useState({});
  const [connectors, setConnectors] = useState(() =>
    getOrganizationConfig(organization.config, 'connectors')
  );

  const options = useMemo(
    () => [
      {
        id: 'flight',
        name: messages['admin.others.connectors.flight.title'],
        values: getLabelAndValueFromProvider(PROVIDERS.flight, messages),
      },
      {
        id: 'hotel',
        name: messages['admin.others.connectors.hotel.title'],
        values: getLabelAndValueFromProvider(
          PROVIDERS.offline_hotels,
          messages
        ),
      },
      {
        id: 'car',
        name: messages['admin.others.connectors.car.title'],
        values: getLabelAndValueFromProvider(PROVIDERS.car, messages),
      },
      {
        id: 'bus',
        name: messages['admin.others.connectors.bus.title'],
        values: getLabelAndValueFromProvider(PROVIDERS.bus, messages),
      },
    ],
    []
  );

  // Functions
  const onToggle = (id) =>
    setToggleValues((values) => ({
      ...values,
      [id]: !values[id],
    }));

  const onConnectorChange = async (connector, value) => {
    setConnectors((values) => ({
      ...values,
      [connector]: value,
    }));

    await firebaseApp.updateOrganizationConnectorsConfig(
      organization.id,
      connector,
      value
    );
  };

  // Render
  return (
    <Row className="travel-data-list-view d-flex justify-content-between mt-5 ml-3 w-100">
      <Colxx
        xxs="12"
        md="5"
        className="d-flex justify-content-center align-items-start"
      >
        <TravelImg width={'85%'} title="" />
      </Colxx>
      <Colxx xxs="12" md="7" className="pl-3">
        <p className="text-one w-70 slide-left font-weight-medium">
          {messages['admin.others.connectors.description']}
        </p>
        <div className="slide-left" style={{ animationDelay: '25ms' }}>
          {options.map(({ id, name, values }) => {
            const isOpen = toggleValues[id],
              disableSwitches = values.length === 1;

            return (
              <div key={id} className="border mt-4">
                <Button
                  color="link"
                  onClick={() => onToggle(id)}
                  aria-expanded={isOpen}
                >
                  {name}
                </Button>
                <Collapse isOpen={isOpen}>
                  <Row>
                    <Colxx sm="12">
                      <CardBody>
                        {values.map(({ value, label }) => {
                          const connectorValue = connectors[value];
                          const checked =
                            connectorValue === undefined
                              ? true
                              : connectorValue;

                          return (
                            <Label
                              key={`${id}-${value}`}
                              className="d-block mb-2"
                            >
                              <Switch
                                checked={checked}
                                onChange={() =>
                                  onConnectorChange(value, !checked)
                                }
                                disabled={disableSwitches}
                                className="mr-2 custom-switch"
                              />
                              {label}
                            </Label>
                          );
                        })}
                      </CardBody>
                    </Colxx>
                  </Row>
                </Collapse>
              </div>
            );
          })}
        </div>
      </Colxx>
    </Row>
  );
}
