import React, { useMemo, useState } from 'react';
import IconsMenu from '../../../components/common/IconsMenu';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { paramsComponents } from './constants';

export default function ItineraryParams({ params = {}, setParamValue }) {
  const [selectedItem, setSelectedItem] = useState(paramsComponents[0]);

  // States
  const [Component, paramsName] = useMemo(() => {
    const Component = selectedItem?.Component || paramsComponents[0].Component;
    return [Component, selectedItem.paramsName];
  }, [selectedItem]);

  // Render
  return (
    <div
      className="itinerary-params d-flex h-100 w-100"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <div className="menu-container p-2 mr-2 flex-center">
        <IconsMenu
          items={paramsComponents}
          selectedItem={selectedItem}
          onSelect={(item) => setSelectedItem(item)}
        />
      </div>
      <div className="p-3 component-container flex-grow-1">
        <PerfectScrollbar
          options={{
            suppressScrollX: true,
            wheelPropagation: false,
            useBothWheelAxes: false,
          }}
        >
          <Component
            params={paramsName ? params[paramsName] : params}
            onParamChange={(name, value) =>
              setParamValue({ name, value, paramsName })
            }
          />
        </PerfectScrollbar>
      </div>
    </div>
  );
}
