import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import {
  Card,
  TabPane,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  CardBody,
  Row,
} from 'reactstrap';
import { Colxx } from '../../components/common/CustomBootstrap';
import classnames from 'classnames';

import CarCardSummary from './CarCardSummary';
import TypeAnalyticsLoading from './TypeAnalyticsLoading';
import CarCardTopCompanies from './CarCardTopCompanies';

export default function CarCard({ orders, reservations, parentLoading }) {
  const { messages } = useIntl();

  const [activeTab, setActiveTab] = useState('summary');

  if (parentLoading) {
    return (
      <Card className="mb-4">
        <CardHeader>
          <Nav tabs className="card-header-tabs justify-content-end">
            <div className="card-title flex-fill align-self-center pt-3">
              {messages['containers.dashboard.car-card.title']}
            </div>
          </Nav>
        </CardHeader>

        <TabContent activeTab={'loading'}>
          <TabPane tabId="loading">
            <CardBody>
              <Row>
                <Colxx sm="12">
                  <TypeAnalyticsLoading />
                </Colxx>
              </Row>
            </CardBody>
          </TabPane>
        </TabContent>
      </Card>
    );
  }

  return (
    <Card className="mb-4">
      <CardHeader>
        <Nav tabs className="card-header-tabs justify-content-end">
          <div className="card-title flex-fill align-self-center pt-3">
            {messages['containers.dashboard.car-card.title']}
          </div>
          <NavItem>
            <NavLink
              to="#"
              location={{}}
              className={classnames({
                active: activeTab === 'summary',
                'nav-link': true,
              })}
              onClick={() => {
                setActiveTab('summary');
              }}
            >
              {messages['containers.dashboard.card.summary']}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              to="#"
              location={{}}
              className={classnames({
                active: activeTab === 'top_companies',
                'nav-link': true,
              })}
              onClick={() => {
                setActiveTab('top_companies');
              }}
            >
              {messages['containers.dashboard.car-card.best-companies']}
            </NavLink>
          </NavItem>
        </Nav>
      </CardHeader>

      <TabContent activeTab={activeTab}>
        <TabPane tabId="summary">
          <CarCardSummary orders={orders} reservations={reservations} />
        </TabPane>
        <TabPane tabId="top_companies">
          <CarCardTopCompanies orders={orders} reservations={reservations} />
        </TabPane>
      </TabContent>
    </Card>
  );
}
