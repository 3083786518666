import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { getAuthRedux } from '../../../../utils/redux';
import { getDefaultCommonParams } from '../../../../utils/reservation';
import {
  getUserNationality,
  isUserNational,
} from '../../../../utils/nationality';
import OrderStructures from '../../../../constants/orderStructure';
import firebaseApp from '../../../../services/Firebase';
import { isCpfValid } from '../../../../utils/cpf';
import { isIdentityValid } from '../../../../utils/identity';
import { Input, Label, Row } from 'reactstrap';
import { Colxx } from '../../../../components/common/CustomBootstrap';
import { useIntl } from 'react-intl';
import CustomErrorMessage from '../../../../components/CustomErrorMessage';
import { cpfMask, dateMask } from '../../../../utils/masks';
import MaskedInput from 'react-text-mask';
import CommonParams from '../../CommonParams';
import PassportParams from '../../PassportParams';
import moment from 'moment';
import classNames from 'classnames';
import FixedSelect from '../../../../components/common/FixedSelect';
import useDataLoaderWithArchive from '../../../../hooks/useDataLoaderWithArchive';
import { isEqual } from 'lodash';

function formatUsers(users) {
  return users.map((user) => ({
    value: user.id,
    label: `${user.firstName} ${user.lastName}`,
    costCenter: user.costCenter || {},
    project: user.project || {},
    info: user,
  }));
}

export default function OrganizationParams({
  setReservationParams,
  reservationParams,
  requiredFields,
  editableFields,
  nationality = DEFAULT_NATIONALITY,
  hasNationality,
  orderStructure = OrderStructures.ORGANIZATION,
  className = 'pt-2',
  needPassport,
}) {
  const { user, organization } = useSelector(getAuthRedux);
  const { messages } = useIntl();
  // States
  const [doLoadUsers, setDoLoadUsers] = useState(false);
  const [users, _, isLoadingUsers] = useDataLoaderWithArchive({
    archiveKey: 'users',
    fetchFunction: () =>
      firebaseApp.getUsersFromOrganization(user.organizationId),
    formatFunction: formatUsers,
    shouldLoad: doLoadUsers,
    initialLoading: false,
  });

  const [cpf, setCpf] = useState(user.documents?.cpf?.number || '');
  const [identity, setIdentity] = useState(
    user.documents?.identity?.number || ''
  );
  const [passport, setPassport] = useState(user.documents?.passport || '');
  const [birthdate, setBirthdate] = useState(user.birthdate || '');
  const [errors, setErrors] = useState({});

  const [pax, setPassenger] = useState(() => ({
    value: user.uid,
    label: `${user.firstName} ${user.lastName}`,
    info: user,
  }));

  const [commonParams, setCommonParams] = useState(
    getDefaultCommonParams(user)
  );

  const filteredUsers = useMemo(
    () =>
      hasNationality
        ? users.filter((user) => {
            const userNationality = user?.info?.nationality;
            return !userNationality || userNationality === nationality;
          })
        : users,
    [users, nationality, hasNationality]
  );

  const { hasCpf, hasIdentity, hasBirthdate } = useMemo(() => {
    if (!pax)
      return {
        hasCpf: true,
        hasIdentity: true,
        hasBirthdate: true,
      };

    const isNational = isUserNational(pax.info, organization);

    return {
      hasCpf: isNational ? pax?.info?.documents?.cpf?.number : true,
      hasIdentity: isNational ? true : pax?.info?.documents?.identity?.number,
      hasBirthdate: pax?.info?.birthdate,
      isNational,
    };
  }, [pax]);

  // Effects
  useEffect(() => {
    if (
      hasNationality &&
      getUserNationality({ user, organization }) !== nationality
    ) {
      setPax(filteredUsers[0]);
    }
  }, [filteredUsers, hasNationality, orderStructure]);

  useEffect(() => {
    if (orderStructure !== OrderStructures.ORGANIZATION) return;

    const updatedReservationParams = {
      ...reservationParams,
      ...commonParams,
      needPassport,
      structure: OrderStructures.ORGANIZATION,
      passenger: pax?.info,
      passengerId: pax?.value,
      passengerTimeline: pax?.info,
      cpf: pax?.info?.documents?.cpf?.number || cpf,
      identity,
      passport,
      birthdate: pax?.info?.birthdate || birthdate,
      nationality: pax?.info?.nationality || nationality,
    };

    if (!isEqual(reservationParams, updatedReservationParams)) {
      setReservationParams(updatedReservationParams);
    }
  }, [
    pax,
    commonParams,
    cpf,
    identity,
    birthdate,
    nationality,
    passport,
    orderStructure,
    reservationParams,
  ]);

  // Functions
  const setPax = (passenger = {}) => {
    if (!passenger) {
      setPassenger(null);
      setCommonParams(getDefaultCommonParams({}));

      setCpf('');
      setIdentity('');
      setBirthdate('');
      setPassport('');
    } else {
      setPassenger(passenger);
      setCommonParams(getDefaultCommonParams(passenger));

      setPassport(passenger?.documents?.passport);
      setCpf(passenger?.documents?.cpf?.number || '');
      setIdentity(passenger?.documents?.identity?.number || '');
      setBirthdate(passenger?.birthdate || '');
    }

    setErrors({});
  };

  const handleErrors = (name, value = true) => {
    setErrors({
      ...errors,
      [name]: value,
    });
  };

  const handleCpf = (e) => {
    const cpf = e.target.value;

    if (cpf && cpf[13] !== '_') {
      if (isCpfValid(cpf)) {
        setCpf(cpf);
        handleErrors('cpf', false);
      } else handleErrors('cpf', true);
    } else {
      if (errors.cpf) handleErrors('cpf', false);
      if (cpf) setCpf('');
    }
  };

  const handleIdentity = (e) => {
    const identity = e.target.value;

    if (identity) {
      if (isIdentityValid(identity)) {
        setIdentity(identity);
        handleErrors('identity', false);
      } else handleErrors('identity', true);
    } else {
      if (errors.identity) handleErrors('identity', false);
      setIdentity('');
    }
  };

  const handleBirthdate = (e) => {
    const birthdate = e.target.value;
    try {
      if (birthdate && birthdate[9] !== '_') {
        if (moment().diff(moment(birthdate, 'DD/MM/YYYY'), 'years') > 12) {
          setBirthdate(birthdate);
          handleErrors('birthdate', false);
        } else handleErrors('birthdate', true);
      } else {
        if (errors.birthdate) handleErrors('birthdate', false);
        if (birthdate) setBirthdate('');
      }
    } catch (err) {
      console.error(err);
      handleErrors('birthdate');
    }
  };

  // Render
  return (
    <div className={classNames('reservation-params pl-3 pr-4', className)}>
      <Row>
        <Colxx xxs="12">
          <Label className="form-group search reservation has-top-label">
            <FixedSelect
              className="react-select"
              classNamePrefix="react-select"
              isClearable={false}
              placeholder=""
              value={pax}
              noOptionsMessage={() => messages['travel.booking.no-passenger']}
              loadingMessage={() =>
                messages['travel.booking.loading-passengers']
              }
              options={filteredUsers}
              onChange={setPax}
              maxMenuHeight={150}
              showOnlyX={50}
              isLoading={isLoadingUsers}
              onMenuOpen={() => !doLoadUsers && setDoLoadUsers(true)}
            />
            <span>
              {messages['travel.booking.passenger']}
              {hasNationality ? ` (${nationality})` : ''}
            </span>
          </Label>
        </Colxx>
      </Row>
      <Row>
        {!hasCpf ? (
          <Colxx xxs={hasBirthdate ? '12' : '6'}>
            <Label className="form-group search reservation has-top-label">
              <MaskedInput
                type="text"
                mask={cpfMask}
                id="documents.cpf.number"
                className="form-control"
                onChange={handleCpf}
              />
              <CustomErrorMessage error={errors.cpf}>
                {messages['forms.validation.cpf.number.valid']}
              </CustomErrorMessage>
              <span>{messages['user.cpf']} *</span>
            </Label>
          </Colxx>
        ) : null}

        {!hasIdentity ? (
          <Colxx xxs={hasBirthdate ? '12' : '6'}>
            <Label className="form-group search reservation has-top-label">
              <Input
                type="text"
                name="documents.identity.number"
                className="form-control"
                onChange={handleIdentity}
              />
              <CustomErrorMessage error={errors.identity}>
                {messages['forms.validation.identity.number.valid']}
              </CustomErrorMessage>
              <span>{messages['user.identity']} *</span>
            </Label>
          </Colxx>
        ) : null}

        {!hasBirthdate ? (
          <Colxx xxs={hasCpf && hasIdentity ? '12' : '6'}>
            <Label className="form-group search reservation has-top-label mr-2">
              <MaskedInput
                type="text"
                mask={dateMask}
                id="birthdate"
                className="form-control"
                onChange={handleBirthdate}
              />
              <CustomErrorMessage error={errors.birthdate}>
                {messages['forms.validation.birthdate.valid']}
              </CustomErrorMessage>
              <span>{messages['user.birthdate']} *</span>
            </Label>
          </Colxx>
        ) : null}
      </Row>

      <CommonParams
        commonParams={commonParams}
        setCommonParams={setCommonParams}
        requiredFields={requiredFields}
        editableFields={editableFields}
        selectedPax={pax?.info}
      />

      <div style={{ marginTop: 20, marginBottom: 20 }}>
        {needPassport && (
          <PassportParams passport={passport} setPassport={setPassport} />
        )}
      </div>
    </div>
  );
}
