import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import IntlMessages from '../../helpers/IntlMessages';

import UsageTermsView from '../../containers/settings/UsageTermsView';
import PrivacyPolicyView from '../../containers/settings/PrivacyPolicyView';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function TermsModal() {
  const query = useQuery();
  const history = useHistory();

  if (!query.has('terms-of-service') && !query.has('privacy-policy')) {
    return <></>;
  }

  const closeModal = () => {
    query.delete('terms-of-service');
    query.delete('privacy-policy');

    history.replace({
      search: query.toString(),
    });
  };

  return (
    <Modal isOpen={true} toggle={closeModal} size="lg">
      <ModalHeader toggle={closeModal}>
        <IntlMessages id="menu.about" />
      </ModalHeader>
      <ModalBody>
        {query.has('terms-of-service') ? <UsageTermsView /> : null}
        {query.has('privacy-policy') ? <PrivacyPolicyView /> : null}
      </ModalBody>
    </Modal>
  );
}
