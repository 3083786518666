import { RESET_RESERVATION_ACTION, STORE_RESERVATION_ACTION } from '../actions';

const INITIAL_STATE = {};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case STORE_RESERVATION_ACTION: {
      const { reservationId } = action.payload;
      return {
        ...state,
        [reservationId]: new Date().getTime(),
      };
    }
    case RESET_RESERVATION_ACTION: {
      const { reservationId } = action.payload;
      return {
        ...state,
        [reservationId]: 0,
      };
    }
    default: {
      return { ...state };
    }
  }
};
