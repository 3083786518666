import React, { useRef, useState } from 'react';
import { Tooltip } from 'reactstrap';
import classNames from 'classnames';
import { useIntl } from 'react-intl';

export default function OfflineReservationBadge({ className }) {
  const { messages } = useIntl();
  const pRef = useRef(null);

  // States
  const [showTooltip, setShowTooltip] = useState(false);

  // Render
  return (
    <div className={classNames('offline-reservation-badge', className)}>
      <p ref={pRef}>{messages['travel.room.offline-reservation.title']}</p>
      <Tooltip
        isOpen={showTooltip}
        toggle={() => setShowTooltip((v) => !v)}
        target={pRef}
        innerClassName="fs-0-7rem"
      >
        {messages['travel.room.offline-reservation.description']}
      </Tooltip>
    </div>
  );
}
