import React, { useMemo } from 'react';
import { stringToMoney } from '../../../../../../utils/money';
import { ReactComponent as ArrowIcon } from '../../../../../../assets/img/icons/oneway.svg';
import { ReactComponent as SeatIcon } from '../../../../../../assets/img/icons/seat.svg';
import classNames from 'classnames';
import { useIntl } from 'react-intl';

const getFlightId = ({ flightDetails }) => {
  return parseInt(`${flightDetails.journeyId}${flightDetails.flightId}`);
};

function SeatItem({ item, reservations }) {
  const { formatMessage } = useIntl();

  const { outbound, inbound, total, name } = useMemo(() => {
    const { details, fare, relatedTo } = item;
    const reservation = reservations[relatedTo[0]];
    const { departureStation, arrivalStation } = reservation.flightDetails;

    return {
      outbound: departureStation.iataCode,
      inbound: arrivalStation.iataCode,
      total: stringToMoney(fare.total, 2, fare.currency),
      name: formatMessage(
        { id: 'flight-alteration.seats.item' },
        { seat: `${details.row}${details.column}` }
      ),
    };
  }, [item]);

  return (
    <div className="mr-4">
      <p className="m-0 p-0 d-flex align-items-center header">
        <span>{outbound}</span>
        <ArrowIcon fill="white" width={15} className="mx-1" />
        <span>{inbound}</span>
      </p>
      <p className="m-0 p-0 name">{name}</p>
      <p className="m-0 p-0 total">{total}</p>
    </div>
  );
}

export default function SeatItems({
  items,
  reservations,
  className = '',
  style = {},
}) {
  const { messages } = useIntl();

  const sortedItems = useMemo(() => {
    return items.items.sort(
      (a, b) =>
        getFlightId(reservations[a.relatedTo[0]]) -
        getFlightId(reservations[b.relatedTo[0]])
    );
  }, [items]);

  return (
    <div className={classNames('mb-4', className)} style={style}>
      <div className="d-flex align-items-center mb-2">
        <SeatIcon
          fill="white"
          width="1.1rem"
          height="1.1rem"
          className="mr-2 icon"
        />
        <h3 className="font-primary title">
          {messages['flight-alteration.seats']}
        </h3>
      </div>
      <div className="d-flex align-items-center flex-wrap mx-4">
        {sortedItems.map((item, index) => (
          <SeatItem key={index} item={item} reservations={reservations} />
        ))}
      </div>
    </div>
  );
}
