import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  Button,
  Modal,
  ModalBody,
  Row,
  Popover,
  PopoverBody,
} from 'reactstrap';
import CustomSelect from '../common/CustomSelect';
import {
  Products,
  Specificities,
  BudgetCategories,
  PhysicalCategories,
} from '../../constants/budget';
import { loadCostCenters } from '../../utils/costCenters';
import { loadProjects } from '../../utils/projects';
import { useSelector } from 'react-redux';
import useDataSwitcher from '../../hooks/useDataSwitcher';
import { sortAlphabetically } from '../../utils/sort';
import { Colxx } from '../common/CustomBootstrap';
import BudgetRule from './BudgetRule';
import { getCurrencyAndPrice } from '../../utils/money';
import BudgetImg from '../../assets/img/config/budget.png';
import firebaseApp from '../../services/Firebase';
import Spinner from '../common/Spinner';
import { diff } from 'deep-object-diff';
import { hashBudget } from '../../utils/budget';
import { loadMotives } from '../../utils/motives';
import { loadTags } from '../../utils/tags';
import { getOrganizationConfig } from '../../utils/organization';
import useBudgetCategories from 'src/portao3-legacy/hooks/useBudgetCategories';
import { RefundTypes } from 'src/portao3-legacy/constants/refundTypes';

const categoriesLength = Object.keys(BudgetCategories).length;

function BudgetCategoryDetails() {
  const [showInfo, setShowInfo] = useState(false);
  const { messages } = useIntl();

  return (
    <>
      <div
        id="budget-category-details-mmc"
        className="budget-category-detail ml-1"
        onMouseOver={() => {
          setShowInfo(true);
        }}
        onMouseOut={() => setShowInfo(false)}
      >
        <div className="glyph-icon simple-icon-question" />
      </div>
      <Popover
        placement="top"
        isOpen={showInfo}
        target="budget-category-details-mmc"
        popperClassName="ticket-info"
      >
        <PopoverBody className="journey-ticket-detail">
          <p className="m-0 m-2 text-justify">
            {messages['budget.phrase.create-budget']}
          </p>
        </PopoverBody>
      </Popover>
    </>
  );
}

export default function BudgetModal({
  budgetId = 'new',
  toggleModal,
  hashedBudgets = [],
}) {
  const { messages } = useIntl();
  const { user, organization } = useSelector(({ auth }) => auth);

  const [budget, setBudget] = useState(null);
  const [isLoading, setIsLoading] = useState(budgetId !== 'new');

  const productOptions = useMemo(
    () =>
      Products.map((product) => ({
        value: product,
        label: messages[`travel.menu.${product}`],
      })),
    []
  );

  const specificityOptions = useMemo(
    () =>
      Object.values(Specificities).map((specificity) => ({
        value: specificity,
        label: messages[`budget.specificity.${specificity}`],
      })),
    []
  );

  const categoriesOptions = useBudgetCategories({
    cardType: RefundTypes.PHYSICAL_CARD,
  });

  const specificitiesFunctions = useMemo(
    () => ({
      [Specificities.COST_CENTER]: () =>
        loadCostCenters(user.organizationId, true),
      [Specificities.PROJECT]: () => loadProjects(user.organizationId, true),
      [Specificities.MOTIVE]: () => loadMotives(user.organizationId, true),
      [Specificities.TAG]: () => loadTags(user.organizationId, true),
    }),
    [user]
  );

  const currency = useMemo(
    () =>
      getCurrencyAndPrice(
        '10',
        getOrganizationConfig(organization.config, 'currency')
      )[0],
    []
  );

  const [product, setProduct] = useState(null);
  const [specificity, setSpecificity] = useState(specificityOptions[0]);
  const [selectedSpecificity, setSelectedSpecificity] = useState([]);
  const [rules, setRules] = useState([
    {
      price: '',
      category: null,
    },
  ]);
  const [isSaving, setIsSaving] = useState(false);

  const selectedRules = useMemo(
    () =>
      rules
        ? rules.map((rule) => rule.category?.value).filter((value) => value)
        : [],
    [rules]
  );

  const [specificityData, isLoadingSpecificity] = useDataSwitcher({
    selectedOption: specificity?.value,
    fetchFunctions: specificitiesFunctions,
    formatFunction: (arr) => sortAlphabetically(arr, 'label'),
  });

  const [isValid, error] = useMemo(() => {
    try {
      if (product?.value) {
        if (
          !specificity ||
          specificity.value === 'none' ||
          (selectedSpecificity && selectedSpecificity.length > 0)
        ) {
          if (
            rules &&
            rules.length > 0 &&
            rules.every(
              ({ price, category }) => category && parseFloat(price) > 0
            )
          ) {
            const hash = hashBudget(
              product.value,
              specificity && specificity.value !== 'none'
                ? { type: specificity.value, values: selectedSpecificity }
                : null,
              rules.map(({ category }) => category.value)
            );
            const id = hashedBudgets[hash];

            return !id || id === budgetId ? [true, null] : [false, 'duplicate'];
          }
        }
      }
      return [false, null];
    } catch (err) {
      return [false, err];
    }
  }, [product, specificity, selectedSpecificity, rules]);

  // Effects
  useEffect(() => {
    if (budgetId !== 'new') fetchBudget();
  }, [budgetId]);

  useEffect(() => {
    if (budget) {
      const { product, rules, specificity } = budget;

      setProduct({
        label: messages[`travel.menu.${product}`],
        value: product,
      });

      if (specificity) {
        setSpecificity({
          value: specificity.type,
          label: messages[`budget.specificity.${specificity.type}`],
        });
        setSelectedSpecificity(specificity.values);
      } else {
        setSpecificity(null);
        setSelectedSpecificity(null);
      }

      setRules(
        rules.map(({ price, category }) => ({
          price,
          category: {
            value: category,
            label: messages[`budget.category.${category}`],
          },
        }))
      );
    }
  }, [budget]);

  // Functions
  const fetchBudget = async () => {
    try {
      setIsLoading(true);

      const budgetSnap = await firebaseApp
        .getBudgetByIdFromOrganization(user.organizationId, budgetId)
        .get();

      if (budgetSnap.exists) {
        setBudget({
          ...budgetSnap.data(),
          id: budgetSnap.id,
        });
      } else toggleModal();
    } finally {
      setIsLoading(false);
    }
  };

  const handleRuleChange = (index, rule) => {
    const newRules = [...rules];
    newRules[index] = rule;
    setRules(newRules);
  };

  const addRule = () => {
    setRules([...rules, { price: '' }]);
  };

  const deleteRule = (index) => {
    const newRules = [...rules];
    newRules.splice(index, 1);
    setRules(newRules);
  };

  const setToModel = () => {
    return {
      product: product.value,
      specificity:
        !specificity || specificity.value === Specificities.NONE
          ? null
          : {
              type: specificity.value,
              values: selectedSpecificity,
            },
      rules: rules.map(({ price, category }) => ({
        price: parseFloat(price),
        category: category.value,
      })),
    };
  };

  const handleBudgetCreation = async () => {
    try {
      setIsSaving(true);
      const newBudget = setToModel();

      if (budgetId === 'new') {
        await firebaseApp.createRefundBudget(user.organizationId, newBudget);
      } else {
        const { id, product, rules, specificity } = budget;
        const differences = diff({ product, rules, specificity }, newBudget);

        if (Object.keys(differences).length > 0) {
          if ('specificity' in differences)
            differences['specificity'] = newBudget['specificity'];
          if ('rules' in differences) differences['rules'] = newBudget['rules'];

          await firebaseApp.updateRefundBudget(
            user.organizationId,
            id,
            differences
          );
        }
      }

      setIsSaving(false);
      toggleModal();
    } catch (err) {
      console.debug(err);
    }
  };

  const handleSpecificityChange = (newSpecificity) => {
    if (!specificity || specificity.value !== newSpecificity.value) {
      setSpecificity(newSpecificity);
      setSelectedSpecificity([]);
    }
  };

  return (
    <Modal isOpen={true} toggle={toggleModal} className="budget-modal">
      <ModalBody className="d-flex align-items-center justify-content-center">
        {isLoading ? (
          <div className="w-100 h-100 position-relative">
            <Spinner />
          </div>
        ) : (
          <Row>
            <Colxx
              xxs="5"
              className="d-flex align-items-center justify-content-center"
            >
              <img src={BudgetImg} width="90%" />
            </Colxx>
            <Colxx xxs="7">
              <h1 className="medium-title">{messages['budget.modal.title']}</h1>
              <div>
                <p className="m-0 mb-2 font-weight-medium">
                  {messages['budget.phrase.1']}
                </p>
                <CustomSelect
                  value={product}
                  options={productOptions}
                  isMulti={false}
                  className="w-100"
                  onChange={(values) => setProduct(values)}
                  noOptionsMessage={() => messages['travel.nodata.title']}
                  placeholder={messages['general.none-selected']}
                />
              </div>
              <div>
                <p className="m-0 mt-3 mb-2 font-weight-medium">
                  {messages['budget.phrase.2']}
                </p>
                <CustomSelect
                  value={specificity}
                  options={specificityOptions}
                  isMulti={false}
                  className="w-100"
                  onChange={handleSpecificityChange}
                  noOptionsMessage={() => messages['travel.nodata.title']}
                  placeholder={messages['general.none-selected']}
                />
              </div>
              {specificity && specificity.value !== Specificities.NONE && (
                <div>
                  <p className="m-0 mt-3 mb-2 font-weight-medium">
                    {
                      messages[
                        `budget.phrase.3.${
                          !selectedSpecificity ||
                          selectedSpecificity?.length < 2
                            ? 'singular'
                            : 'plural'
                        }`
                      ]
                    }
                  </p>
                  <CustomSelect
                    value={selectedSpecificity}
                    options={specificityData}
                    isMulti={true}
                    className="w-100"
                    onChange={(value) => setSelectedSpecificity(value)}
                    noOptionsMessage={() => messages['travel.nodata.title']}
                    placeholder={
                      messages[
                        isLoadingSpecificity
                          ? 'general.loading-options'
                          : 'general.none-selected'
                      ]
                    }
                  />
                </div>
              )}
              <div>
                <div className="d-flex justify-content-between align-items-center mt-3 mb-2">
                  <div className="d-flex align-items-center">
                    <span className="m-0 font-weight-medium">
                      {messages['budget.phrase.4']}
                    </span>
                    <BudgetCategoryDetails />
                  </div>
                  <Button
                    color="primary"
                    size="sm"
                    className="new-rule-btn px-3 py-1"
                    disabled={
                      selectedRules.length >= categoriesLength ||
                      rules.length >= categoriesLength
                    }
                    onClick={addRule}
                    style={{ transition: 'all 0.3s ease' }}
                  >
                    {messages['budget.phrase.add-rule']}
                  </Button>
                </div>
                <div className="rules mt-2">
                  {rules.map((rule, index) => (
                    <BudgetRule
                      key={`budget-rule-${index}`}
                      rule={rule}
                      setRule={(rule) => handleRuleChange(index, rule)}
                      currency={currency}
                      categoriesOptions={categoriesOptions}
                      selectedRules={selectedRules}
                      className="mb-2"
                      onDelete={() => deleteRule(index)}
                      showDelete={rules.length > 1}
                    />
                  ))}
                </div>
              </div>
              <div className="btns d-flex justify-content-end mt-4">
                <Button
                  size="md"
                  className="mr-3"
                  color="empty"
                  onClick={toggleModal}
                >
                  {messages['travel.order.cancellation.cancel']}
                </Button>
                <Button
                  color="primary"
                  size="lg"
                  className="font-weight-medium"
                  disabled={isSaving || !isValid}
                  onClick={handleBudgetCreation}
                  style={{ transition: 'all 0.3s ease' }}
                >
                  {
                    messages[
                      budgetId === 'new'
                        ? 'admin.policy.costCenter.Create'
                        : 'admin.policy.policy.Save'
                    ]
                  }
                </Button>
              </div>
            </Colxx>
          </Row>
        )}
      </ModalBody>
    </Modal>
  );
}
