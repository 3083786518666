import React, { useMemo } from 'react';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import SelectableContainer from '../../SelectableContainer';

export default function CreditCardItem({
  className,
  selected,
  value,
  onClick = () => {},
  children,
}) {
  const { messages } = useIntl();

  // States
  const [title, description] = useMemo(
    () => [
      messages[`refund.payment-method.${value}.title`],
      messages[`refund.payment-method.${value}.description`],
    ],
    [value]
  );

  // Render
  return (
    <SelectableContainer
      onClick={onClick}
      selected={selected}
      className={classNames('credit-card-item pointer', value, className)}
      role="button"
      name={value}
    >
      <div className="d-flex align-items-center">
        {children}
        <h2 className="title fixed font-primary">{title}</h2>
        <section className="text-container px-3">
          <h2 className="title font-primary">{title}</h2>
          <p className="description">{description}</p>
        </section>
      </div>
    </SelectableContainer>
  );
}
