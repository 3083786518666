import moment from 'moment';
import recordStatus from '../constants/recordStatus';
import firebaseApp from '../services/Firebase';

let lastUpdated = null;

const shouldRenewProjects = async () => {
  if (moment().isAfter(moment(lastUpdated).add(1, 'days'))) {
    return true;
  }

  return false;
};

const getNewProjects = async (orgId, onlyActive = false) => {
  let snap = firebaseApp.getProjectsFromOrganization(orgId);

  if (onlyActive) snap = snap.where('status', '==', recordStatus.ACTIVE);

  return await snap.get({ source: 'server' });
};

export const getProjects = async (orgId, onlyActive) => {
  const storageSnapshot = await firebaseApp
    .getProjectsFromOrganization(orgId)
    .get({ source: 'cache' });

  if (storageSnapshot.exists) {
    const shouldRenew = shouldRenewProjects();
    if (shouldRenew) {
      lastUpdated = moment();
      return await getNewProjects(orgId, onlyActive);
    }

    return storageSnapshot;
  }

  return await getNewProjects(orgId, onlyActive);
};

export const loadProjects = async (orgId, onlyActive = true) => {
  const projectsSnap = await firebaseApp
    .getProjectsFromOrganization(orgId, onlyActive)
    .get();

  const projects = [];
  projectsSnap.forEach((projectSnap) => {
    const label = projectSnap.data().name;

    if (typeof label === 'string') {
      projects.push({
        value: projectSnap.id,
        label,
      });
    }
  });

  return projects;
};

export const addMultipleProjects = (orgId, projectsKeys) => {
  const promises = projectsKeys.map(async (key) => {
    if (typeof key === 'string') {
      const docRef = await firebaseApp.createProjectFromOrganization(orgId, {
        name: key,
      });
      return { label: key, value: docRef.id };
    } else return null;
  });

  return Promise.all(promises);
};
