import React, { useMemo } from 'react';
import CardHoverComponent from '../../../containers/expenses/CardContainers/CardHoverComponent';
import SimpleCardInfo from './SimpleCardInfo';
import DetailedCardInfo from './DetailedCardInfo';

export default function CreditCard({
  card,
  backgroundColor = '#43418e',
  textColor,
  Icon,
  BankIcon,
  className = '',
  blocked = false,
  toggleBlock = () => {},
  showDetails = false,
  details,
  loadingDetails,
  InfoComponent: _InfoComponent,
}) {
  const { cardColor, cardTextColor } = useMemo(() => {
    const cardColor = card.color || '#43418e';

    return {
      cardColor,
      cardTextColor: textColor || cardColor,
    };
  }, [card, textColor]);

  const InfoComponent = useMemo(
    () => _InfoComponent || (showDetails ? DetailedCardInfo : SimpleCardInfo),
    [showDetails, _InfoComponent]
  );

  // Render
  const renderHover = () => {
    if (blocked) {
      return (
        <div className="card-hover fixed">
          <CardHoverComponent
            canChangePassword={false}
            blocked={true}
            onBlock={toggleBlock}
            toggleBlock={toggleBlock}
            canShowDetails={false}
            card={card}
          />
        </div>
      );
    }
  };

  return (
    <div
      className={`credit-card ${className}`}
      style={{ borderColor: cardColor, backgroundColor }}
    >
      <div className="content d-flex align-items-end">
        {renderHover()}
        {Icon && <Icon />}
        <div className="mb-2 texts">
          <InfoComponent
            card={card}
            color={cardTextColor}
            details={details}
            BankIcon={BankIcon}
            loadingDetails={loadingDetails}
          />
        </div>
      </div>
    </div>
  );
}
