import React from 'react';

export default function NameCityInfo(props) {
  const { name, city, address } = props;

  return (
    <div className="name-city-info d-flex flex-column align-items-center text-dark text-center">
      <div className="name">{name}</div>
      <div className="city text-muted">{city || address}</div>
    </div>
  );
}
