import md5 from 'md5';
import moment from 'moment';
import { OrderTypes } from '../constants/orderTypes';
import searchStatus from '../constants/searchStatus';
import { DEFAULT_CURRENCY } from './money';

export const getItinerarySearchCode = ({
  currency = DEFAULT_CURRENCY,
  origin,
  destination,
  outboundDate,
  inboundDate,
  params: {
    // Search radius (km)
    radius = 30,
    // Search mobility
    mobility = false,
    // Travel types for search (flight, bus, all), can be a string or array of types
    travelTypes = OrderTypes.FLIGHT,
    // Use round trip instead of search for outbound and inbound
    roundTrip = true,
  } = {},
}) => {
  const code = `${origin.value}-${outboundDate.format('YYYYMMDD')}-${
    destination.value
  }-${inboundDate.format(
    'YYYYMMDD'
  )}-${radius}-${mobility}-${travelTypes}-${roundTrip}`;

  return md5(`${code}-${currency}`);
};

export const getItinerarySearchParams = (search) => {
  const {
    origin,
    destination,
    outboundDate,
    inboundDate,
    params = {},
  } = search;

  // Validate
  if (!origin.value || !destination.value || !outboundDate || !inboundDate) {
    return {};
  }

  const searchCode = getItinerarySearchCode(search);

  const payload = {
    origin,
    originDate: outboundDate.format('DD/MM/YYYY'),
    destination,
    destinationDate: inboundDate.format('DD/MM/YYYY'),
    status: searchStatus.LOADING,
    params,
  };

  return { searchCode, payload };
};

export function getTotalFromItinerary({
  selectedItems = {},
  withFlight = true,
  roundTrip = true,
} = {}) {
  const {
    outbound = {},
    inbound = {},
    [OrderTypes.HOTEL]: hotel = {},
  } = selectedItems;
  const itemsTotal = {};
  let total = 0;

  const setTotal = (key, value = 0) => {
    total += value;
    itemsTotal[key] = value;
  };

  if (hotel.selectedRoom) {
    setTotal(OrderTypes.HOTEL, hotel.selectedRoom.fare);
  }

  if (withFlight) {
    if (roundTrip) {
      if (outbound.item) {
        const flightTotal = outbound.item.fare.total / 2;
        setTotal('outbound', flightTotal);
        setTotal('inbound', flightTotal);
      }
    } else {
      if (outbound.item) {
        setTotal('outbound', outbound.item.fare.total);
      }

      if (inbound.item) {
        setTotal('inbound', inbound.item.fare.total);
      }
    }
  }

  return {
    total,
    itemsTotal,
  };
}

export function getSegmentFromFlightItem({
  item,
  selectedJourney,
  selectedSegment,
}) {
  return item.journeys[selectedJourney].segments[selectedSegment];
}

export function getHotelOriginDateBasedOnOutboundSegment(
  hotelSearch,
  flightSegment
) {
  const { originDate } = hotelSearch;

  const arrivalMoment = moment(flightSegment.arrivalDateTime);
  const arrivalDate = arrivalMoment.format('DD/MM/YYYY');

  if (originDate === arrivalDate) return originDate;

  if (arrivalMoment.hours() >= 15) return arrivalDate;

  return arrivalMoment.subtract(1, 'day').format('DD/MM/YYYY');
}
