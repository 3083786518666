import React, { Fragment } from 'react';
import DndWrapper from 'src/portao3-legacy/wrappers/DndWrapper';
import { useSelector } from 'react-redux';
import { NavLink, useParams } from 'react-router-dom';
import { Row, Nav, NavItem } from 'reactstrap';
import classnames from 'classnames';
import IntlMessages from '../../helpers/IntlMessages';
import { Colxx, Separator } from '../../components/common/CustomBootstrap';
import Breadcrumb from '../../containers/navs/Breadcrumb';

import VcnBank3View from '../../containers/finance/VcnBank3View';
import KYCModal from '../../components/finance/KYCModal';
import OrganizationStatus from '../../constants/organizationStatus';

export default function Finance(props) {
  const { tabId } = useParams();

  const { organization } = useSelector((state) => state.auth);

  return (
    <Fragment>
      <Row>
        <Colxx xxs="12">
          <Breadcrumb heading="admin.finance.title" match={props.match} />
          <Separator className="mb-5" />
        </Colxx>
      </Row>
      <Row>
        <Colxx xxs="12" className="mb-4">
          <Nav className="admin-tabs">
            <NavItem>
              <NavLink
                to="/data/payments/details"
                className={classnames({
                  active: !tabId || tabId === 'details',
                  'nav-link': true,
                })}
              >
                <IntlMessages id="admin.data.details" />
              </NavLink>
            </NavItem>
          </Nav>

          {!tabId || tabId === 'details' ? (
            <DndWrapper>
              <VcnBank3View />
            </DndWrapper>
          ) : null}
        </Colxx>
      </Row>

      {organization.status !== OrganizationStatus.ACTIVE && <KYCModal />}
    </Fragment>
  );
}
