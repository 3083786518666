import React, { useMemo } from 'react';
import IntlMessages from '../../helpers/IntlMessages';

import OrderStatus from '../../constants/orderStatus';
import orderStructure from '../../constants/orderStructure';
import {
  openBookingOrderStatusMessageIds,
  orderStatusMessageIds,
  personalOrderStatusMessageIds,
} from '../../constants/orderStatusMessages';

export default function AlertCard({ order }) {
  if (!order) return <div />;

  const messageIds = useMemo(() => {
    if (order.openBooking) return openBookingOrderStatusMessageIds;
    return order.structure === orderStructure.PERSONAL
      ? personalOrderStatusMessageIds
      : orderStatusMessageIds;
  }, [order]);

  if (
    order.statusCode >= OrderStatus.START &&
    order.statusCode < OrderStatus.RESERVED
  ) {
    return (
      <div className="details-alert warning">
        <IntlMessages id={messageIds[OrderStatus.START]} />
      </div>
    );
  }

  if (
    order.statusCode >= OrderStatus.RESERVED &&
    order.statusCode < OrderStatus.ISSUING
  ) {
    return (
      <div className="details-alert warning">
        <IntlMessages id={messageIds[OrderStatus.RESERVED]} />
      </div>
    );
  }

  if (order.statusCode === OrderStatus.ISSUING) {
    return (
      <div className="details-alert warning">
        <IntlMessages id={messageIds[OrderStatus.ISSUING]} />
      </div>
    );
  }

  if (order.statusCode === OrderStatus.WAITING_ISSUE) {
    return (
      <div className="details-alert warning">
        <IntlMessages id={messageIds[OrderStatus.WAITING_ISSUE]} />
      </div>
    );
  }

  if (order.statusCode === OrderStatus.ALTERING) {
    return (
      <div className="details-alert warning">
        <IntlMessages id={messageIds[OrderStatus.ALTERING]} />
      </div>
    );
  }

  if (order.statusCode === OrderStatus.MANUAL_ISSUING) {
    return (
      <div className="details-alert warning">
        <IntlMessages id={messageIds[OrderStatus.MANUAL_ISSUING]} />
      </div>
    );
  }

  if (order.statusCode === OrderStatus.EXPIRED) {
    return (
      <div className="details-alert danger">
        <IntlMessages id={messageIds[OrderStatus.EXPIRED]} />
      </div>
    );
  }

  if (order.statusCode === OrderStatus.CANCELLING) {
    return (
      <div className="details-alert danger">
        <IntlMessages id={messageIds[OrderStatus.CANCELLING]} />
      </div>
    );
  }

  if (order.statusCode === OrderStatus.DENIED) {
    return (
      <div className="details-alert danger">
        <IntlMessages id={messageIds[OrderStatus.DENIED]} />
      </div>
    );
  }

  if (order.statusCode >= OrderStatus.CANCELLED) {
    return (
      <div className="details-alert danger">
        <IntlMessages id={messageIds[OrderStatus.CANCELLED]} />
      </div>
    );
  }

  if (order.statusCode >= OrderStatus.ERROR) {
    return (
      <div className="details-alert danger">
        <IntlMessages id={messageIds[OrderStatus.ERROR]} />
      </div>
    );
  }

  return <div />;
}
