import React from 'react';
import moment from 'moment';
import { stringToMoney } from '../../utils/money';

import Bank3TransactionCard from './Bank3TransactionCard';
import Bank3TransferCard from './Bank3TransferCard';
import { useIntl } from 'react-intl';

export default function Bank3DateView({
  dateIndex,
  date,
  style,
  onRequestToOpenResource,
}) {
  const { messages } = useIntl();

  // Render
  return (
    <div className="day-card mb-3" style={{ ...style, cursor: 'default' }}>
      <div className="pl-2 d-flex flex-grow-1 mw-0">
        <div className="card-body align-self-center d-flex flex-column flex-lg-row justify-content-between mw-0 align-items-lg-center">
          <div className="w-30 w-sm-100">
            <p className="list-item-heading mb-1 truncate">
              {moment(date.date).format('DD [de] MMMM [de] YYYY')}
            </p>
          </div>
        </div>
        <div className="custom-control custom-checkbox pl-1 align-self-center pr-4">
          <span className="text-weigth-500 text-small">
            {messages['admin.finance.bank3.final-balance']}
          </span>
          <br />
          {stringToMoney(Math.abs(date.balance / 100), 2)}
        </div>
      </div>
      <div>
        {date.items.map((item, index) => {
          if (item.transfer) {
            return (
              <Bank3TransferCard
                transfer={item}
                key={`date-${dateIndex}-transfer-${index}`}
              />
            );
          } else {
            return (
              <Bank3TransactionCard
                transaction={item}
                style={''}
                key={`date-${dateIndex}-transaction-${index}`}
                onClick={onRequestToOpenResource}
              />
            );
          }
        })}
      </div>
    </div>
  );
}
