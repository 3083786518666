import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { useIntl } from 'react-intl';

import OrderStatus from '../../constants/orderStatus';
import OrderStructure from '../../constants/orderStructure';
import filter from 'lodash/filter';
import ApprovalTimeline from '../common/ApprovalTimeline';
import ValidUntil from './ValidUntil';
import firebaseApp from '../../services/Firebase';
import OpenBookingIssueStatusInfo from './OpenBookingIssueStatusInfo';
import { canApproveReservation } from '../../utils/orders';

function ApprovalAction({ order, approvals, goForApproval }) {
  const { messages } = useIntl();
  const { user } = useSelector((state) => state.auth);

  // States
  const canApprove = useMemo(
    () => canApproveReservation({ user, approvals }),
    [user, approvals]
  );

  // Render
  if (order.structure === OrderStructure.PERSONAL) {
    return (
      <Button outline color="primary" className="my-0" onClick={goForApproval}>
        {messages['travel.order.approval.issue']}
      </Button>
    );
  }

  if (canApprove) {
    return (
      <Button outline color="primary" className="my-0" onClick={goForApproval}>
        {messages['travel.order.aproval.review']}
      </Button>
    );
  }

  return <div />;
}

export default function StatusInfo({
  order,
  approvals,
  goForApproval,
  toggleModal,
  goForOpenBookingSteps,
  shouldHideOpenBookingSteps,
}) {
  const { user } = useSelector(({ auth }) => auth);
  const { messages } = useIntl();

  // Functions
  const dismissReservation = async () => {
    await firebaseApp.requestToDismiss(order.id);

    toggleModal();
  };

  // Render
  const renderCancellationButton = () =>
    user?.tester ? (
      <Button
        name="cancel"
        outline
        color="primary"
        className="mt-2"
        onClick={dismissReservation}
      >
        {messages['travel.order.cancellation.cancel']}
      </Button>
    ) : null;

  if (!order) return <div />;

  if (
    order.statusCode >= OrderStatus.START &&
    order.statusCode < OrderStatus.RESERVED
  ) {
    return (
      <div>
        {messages['travel.order.status.title']}
        <ul>
          <li className="active">
            {messages['travel.order.status.reserving']}
          </li>
          <li className="next">{messages['travel.order.status.approval']}</li>
          <li className="next">{messages['travel.order.status.issue']}</li>
        </ul>

        {renderCancellationButton()}
      </div>
    );
  }

  if (
    order.statusCode >= OrderStatus.RESERVED &&
    order.statusCode < OrderStatus.ISSUING
  ) {
    return (
      <div>
        {messages['travel.order.status.title.approval']}{' '}
        <ValidUntil order={order} />
        <ul>
          <li className="done">{messages['travel.order.status.reserved']}</li>
          <ApprovalTimeline approvals={approvals} />

          <li className="next">{messages['travel.order.status.issue']}</li>
        </ul>
        <div className="mt-2 d-flex flex-column align-items-end">
          <ApprovalAction
            order={order}
            approvals={approvals}
            goForApproval={goForApproval}
          />

          {renderCancellationButton()}
        </div>
      </div>
    );
  }

  if (
    order.openBooking &&
    order.statusCode >= OrderStatus.ISSUING &&
    order.statusCode <= OrderStatus.ISSUED
  ) {
    return (
      <OpenBookingIssueStatusInfo
        order={order}
        approvals={approvals}
        goForApproval={goForApproval}
        toggleModal={toggleModal}
        goForOpenBookingSteps={goForOpenBookingSteps}
        shouldHideOpenBookingSteps={shouldHideOpenBookingSteps}
      />
    );
  }

  if (
    order.statusCode >= OrderStatus.ISSUING &&
    order.statusCode < OrderStatus.ISSUED
  ) {
    return (
      <div>
        {messages['travel.order.status.title.issuing']}
        <ul>
          <li className="done">{messages['travel.order.status.reserved']}</li>
          <ApprovalTimeline approvals={approvals} />
          <li className="next">{messages['travel.order.status.issue']}</li>
        </ul>
      </div>
    );
  }

  if (order.statusCode === OrderStatus.ISSUED) {
    return <div>{messages['travel.order.status.title.issued']}</div>;
  }

  if (order.statusCode === OrderStatus.ALTERING) {
    return (
      <div className="color-alert">
        {messages['travel.order.status.title.issued.altering']}
      </div>
    );
  }

  if (order.statusCode === OrderStatus.CANCELLING) {
    return <div>{messages['travel.order.status.title.cancelling']}</div>;
  }

  if (order.statusCode === OrderStatus.DENIED) {
    return (
      <div className="color-alert">
        {messages['travel.order.status.title.denied']}
      </div>
    );
  }

  if (order.statusCode === OrderStatus.ERROR) {
    return <div>{messages['travel.order.status.title.error']}</div>;
  }

  if (order.statusCode === OrderStatus.DISMISSED) {
    return (
      <div className="color-alert">
        {messages['travel.order.cancellation.dismissed']}
      </div>
    );
  }

  if (order.statusCode >= OrderStatus.CANCELLED) {
    return (
      <div className="color-alert">
        {messages['travel.order.cancellation.cancelled']}
      </div>
    );
  }

  return <div />;
}
