import React, { useMemo } from 'react';
import { ADMINISTRATIVE_BUDGET_ITEM_TYPE } from '../../constants/administrativeBudgets';
import AdministrativeExpenseItem from './AdministrativeExpenseItem';
import ExpenseItem from './ExpenseItem';

export default function ExpenseItemDecider({ item, ...props }) {
  const Component = useMemo(
    () =>
      item?.type === ADMINISTRATIVE_BUDGET_ITEM_TYPE
        ? AdministrativeExpenseItem
        : ExpenseItem,
    [item]
  );

  return Component ? <Component item={item} {...props} /> : null;
}
