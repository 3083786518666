import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import UserLayout from '../../layout/UserLayout';

import login from './login';
import forgotPassword from './forgot-password';
import resetPassword from './reset-password';

export default function User({ match }) {
  return (
    <UserLayout>
      <Switch>
        <Redirect exact from={`${match.url}/`} to={`${match.url}/login`} />
        <Route path={`${match.url}/login`} component={login} />
        <Route
          path={`${match.url}/forgot-password`}
          component={forgotPassword}
        />
        <Route path={`${match.url}/reset-password`} component={resetPassword} />
        <Redirect to="/error" />
      </Switch>
    </UserLayout>
  );
}
