import React, { useMemo } from 'react';
import { Row, Card, CardBody, CardTitle } from 'reactstrap';
import { Colxx } from '../../components/common/CustomBootstrap';
import moment from 'moment';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import sumBy from 'lodash/sumBy';

import { stringToMoney } from '../../utils/money';

import { AreaChart } from '../../components/charts';
import SpentLoading from './SpentLoading';

import { ThemeColors } from '../../helpers/ThemeColors';
const colors = ThemeColors();

export default function SmallLineSpent({ orders, loading }) {
  const results = useMemo(
    () =>
      groupBy(
        orders.sort((a, b) => a.filterTimestamp - b.filterTimestamp),
        (o) => moment.unix(o.filterTimestamp).format('DD/MM/YY')
      ),
    [orders]
  );

  if (loading) {
    return (
      <Card className="dashboard-small-chart h-100">
        <CardBody>
          <CardTitle>Financeiro</CardTitle>
          <SpentLoading />
        </CardBody>
      </Card>
    );
  }

  let count = 0;

  return (
    <Card className="dashboard-small-chart h-100">
      <CardBody>
        <CardTitle>Financeiro</CardTitle>
        <Row className="w-100">
          <Colxx xxs="6" className="mt-5 pt-5">
            <p className="lead color-theme-1 mb-1 value">
              {stringToMoney(
                sumBy(orders, (o) => parseFloat(o.fare.total)),
                2
              )}
            </p>
            <p className="text-muted">Total emitido no período</p>
          </Colxx>
          <Colxx xxs="6" className="mt-3">
            {Object.keys(results).length && (
              <AreaChart
                data={{
                  labels: Object.keys(results),
                  datasets: [
                    {
                      data: map(results, (items) => {
                        count =
                          count + sumBy(items, (i) => parseFloat(i.fare.total));
                        return count;
                      }),
                      borderColor: colors.themeColor1,
                      pointBackgroundColor: colors.foregroundColor,
                      pointBorderColor: colors.themeColor1,
                      pointHoverBackgroundColor: colors.themeColor1,
                      pointHoverBorderColor: colors.foregroundColor,
                      pointRadius: 0,
                      pointBorderWidth: 2,
                      pointHoverRadius: 5,
                      fill: true,
                      borderWidth: 2,
                      backgroundColor: colors.themeColor1,
                    },
                  ],
                }}
              />
            )}
          </Colxx>
        </Row>
      </CardBody>
    </Card>
  );
}
