import { Card, TabContent, TabPane, Row } from 'reactstrap';
import { Colxx } from '../../../components/common/CustomBootstrap';
import OrganizationParams from './StructureParams/OrganizationParams';
import PersonalParams from './StructureParams/PersonalParams';
import ReservationParamsNav from './ReservationParamsNav';
import useReservationParamsInfo from '../../../hooks/useReservationParamsInfo';
import classNames from 'classnames';

import OrderStructures from '../../../constants/orderStructure';
import { loadCostCenters as _loadCostCenters } from '../../../utils/costCenters';
import { loadProjects as _loadProjects } from '../../../utils/projects';
import { loadMotives as _loadMotives } from '../../../utils/motives';
import { loadTags as _loadTags } from '../../../utils/tags';
import ConditionalRenderWrapper from 'src/portao3-legacy/wrappers/ConditionalRenderWrapper';

export default function ReservationParams({
  orderStructure,
  setStructure,
  setReservationParams,
  reservationParams,
  nationality: _nationality,
  hasNationality,
  allowPersonalTravel = true,
  allowOrganizationTravel = true,
  className = 'my-2',
  needPassport = false,
}) {
  // States
  const { editableFields, nationality, requiredFields } =
    useReservationParamsInfo(_nationality);

  // Render
  return (
    <Card className={classNames('reservation-type-tab', className)}>
      <ReservationParamsNav
        allowOrganizationTravel={allowOrganizationTravel}
        allowPersonalTravel={allowPersonalTravel}
        orderStructure={orderStructure}
        setStructure={setStructure}
      />

      {orderStructure && (
        <TabContent activeTab={orderStructure}>
          <TabPane tabId={OrderStructures.ORGANIZATION}>
            <ConditionalRenderWrapper
              show={orderStructure === OrderStructures.ORGANIZATION}
            >
              <Row>
                <Colxx sm="12">
                  <OrganizationParams
                    setReservationParams={setReservationParams}
                    reservationParams={reservationParams}
                    requiredFields={requiredFields}
                    editableFields={editableFields}
                    nationality={nationality}
                    hasNationality={hasNationality}
                    orderStructure={orderStructure}
                    needPassport={needPassport}
                  />
                </Colxx>
              </Row>
            </ConditionalRenderWrapper>
          </TabPane>
          <TabPane tabId={OrderStructures.PERSONAL}>
            <ConditionalRenderWrapper
              show={orderStructure === OrderStructures.PERSONAL}
            >
              <Row>
                <Colxx sm="12">
                  <PersonalParams
                    setReservationParams={setReservationParams}
                    reservationParams={reservationParams}
                    nationality={nationality}
                    hasNationality={hasNationality}
                    orderStructure={orderStructure}
                    needPassport={needPassport}
                  />
                </Colxx>
              </Row>
            </ConditionalRenderWrapper>
          </TabPane>
        </TabContent>
      )}
    </Card>
  );
}
