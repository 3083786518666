import React, { useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import SlidingComponents from '../../../SlidingComponents';
import AlterationTab from './Alteration';
import CancellationTab from './Cancellation';
import FlightGordianAlteration from './Gordian';
import FlightAlterationMenu from './Menu';

export const FLIGHT_ALTERATION_TABS = {
  INITIAL: 'initial',
  GORDIAN: 'ancillaries',
  ALTERATION: 'date',
  CANCELLATION: 'cancellation',
};

const COMPONENTS = {
  [FLIGHT_ALTERATION_TABS.INITIAL]: FlightAlterationMenu,
  [FLIGHT_ALTERATION_TABS.GORDIAN]: FlightGordianAlteration,
  [FLIGHT_ALTERATION_TABS.ALTERATION]: AlterationTab,
  [FLIGHT_ALTERATION_TABS.CANCELLATION]: CancellationTab,
};

export default function FlightAlteration({
  order,
  reservations,
  goForAlteration,
}) {
  const { alterId } = useParams();
  const history = useHistory();

  // States
  const currentTab = useMemo(() => FLIGHT_ALTERATION_TABS[alterId], [alterId]);
  const initialLocation = useMemo(() => `/travel/orders/${order.id}`, [order]);

  // Functions
  const onChangeTab = (tab) => {
    history.push(`${initialLocation}/alteration/${tab}`);
  };

  // Render
  return (
    <SlidingComponents
      components={COMPONENTS}
      initialTab={FLIGHT_ALTERATION_TABS.INITIAL}
      currentTab={currentTab}
      goForAlteration={goForAlteration}
      order={order}
      reservations={reservations}
      onChangeToInitialTab={() => history.push(initialLocation)}
      onChangeTab={onChangeTab}
      className="flight-alteration h-100"
      initialLocation={initialLocation}
    />
  );
}
