import moment from 'moment';
import React, { useEffect, useMemo } from 'react';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import NumberFormat from 'react-number-format';
import { FILTER_TYPES } from '../../../../constants/filters';
import { getComparativeLabelValue } from '../../../../utils/filters';
import CustomSelect from '../../../common/CustomSelect';

import { ReactComponent as CloseIcon } from '../../../../assets/img/icons/close.svg';
import { ReactComponent as AddIcon } from '../../../../assets/img/icons/add.svg';
import { ReactComponent as CheckIcon } from '../../../../assets/img/icons/check-mark.svg';

function RangeValueInput({ value = '', onChange, className = '', ...props }) {
  return (
    <NumberFormat
      prefix={'R$ '}
      suffix={''}
      thousandSeparator="."
      decimalSeparator=","
      decimalScale="2"
      fixedDecimalScale="2"
      className={`currency-input w-100 px-2 ${className}`}
      defaultValue={''}
      onValueChange={(v) => {
        onChange(v);
      }}
      {...props}
    />
  );
}

function SelectInput({ values, value, onChange, className, ...props }) {
  return (
    <CustomSelect
      className={`${className} select`}
      options={values}
      value={value}
      onChange={(value) => onChange(value)}
      isMulti
      {...props}
    />
  );
}

function BooleanInput({ values, value, onChange, className, ...props }) {
  const { messages } = useIntl();

  const options = useMemo(
    () => [
      {
        value: true,
        label: messages['general.yes'],
      },
      {
        value: false,
        label: messages['general.no'],
      },
    ],
    []
  );

  // Render
  return (
    <CustomSelect
      className={`${className} select`}
      options={options}
      value={value}
      onChange={(value) => onChange(value)}
      {...props}
    />
  );
}

const VALUE_COMPONENTS = {
  [FILTER_TYPES.RANGE]: RangeValueInput,
  [FILTER_TYPES.BOOLEAN]: BooleanInput,
};

const STYLE = {
  menu: (base) => ({
    ...base,
    zIndex: 100,
  }),
};

export default function Input({
  filters,
  config,
  filter,
  onCreate = () => {},
}) {
  const { messages } = useIntl();

  const [field, setField] = useState(null);
  const [fieldComparatives, setFieldComparatives] = useState(null);
  const [comparative, setComparative] = useState(null);
  const [value, setValue] = useState(null);
  const [values, setValues] = useState([]);

  const [show, setShow] = useState(false);

  const comparatives = useMemo(() => getComparativeLabelValue(messages), []);
  const ValueComponent = useMemo(
    () => (field ? VALUE_COMPONENTS[field.config.type] || SelectInput : null),
    [field]
  );

  const { fields } = useMemo(() => {
    return {
      fields: config
        .map((config) => ({
          value: config.paramName || config.field,
          label: config.paramLabel,
          config,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    };
  }, [config]);

  const isValid = useMemo(
    () => field && comparative && value,
    [field, comparative, value]
  );

  const hasSomeFilter = useMemo(() => filters.length > 0, [filters]);

  // Effects
  useEffect(() => {
    if (field) {
      setFieldComparatives(comparatives[field.config.type]);
      setValues(filter[field.config.paramName || field.config.field]);
    } else {
      setFieldComparatives(null);
      setValue(null);
      setValues(null);
      setComparative(null);
    }
  }, [field]);

  // Functions
  const handleCreate = () => {
    const { config, ...others } = field;

    onCreate({
      config,
      field: others,
      comparative,
      value: config.type === FILTER_TYPES.RANGE ? value.floatValue : value,
      id: moment().unix(),
    });

    handleClean();
  };

  const handleClean = () => {
    setField(null);
    setShow(false);
  };

  // Render
  return (
    <div className={`input-filter-input flex-grow-1 ${show ? 'show' : ''}`}>
      {show ? (
        <div className="input-components d-flex w-100">
          <div className="flex-grow-1 d-flex">
            <CustomSelect
              value={field}
              options={fields}
              onChange={(value) => setField(value)}
              className={
                field ? 'w-30 mr-2 input select' : 'w-100 input select'
              }
              placeholder={messages['input-filter.field']}
              style={STYLE}
            />
            {fieldComparatives ? (
              <CustomSelect
                value={comparative}
                options={fieldComparatives}
                onChange={setComparative}
                className="w-30 mr-2 input select"
                key={field}
                placeholder={messages['input-filter.comparisson']}
                style={STYLE}
              />
            ) : null}
            {ValueComponent ? (
              <ValueComponent
                values={values}
                value={value}
                onChange={setValue}
                className="w-40 input"
                placeholder={messages['input-filter.value']}
                style={STYLE}
              />
            ) : null}
          </div>
          <div className="d-flex justify-content-start ml-2">
            <div className="squared-btn pointer mr-2" onClick={handleClean}>
              <CloseIcon className="icon" width="40%" />
            </div>
            {isValid ? (
              <div className="squared-btn pointer" onClick={handleCreate}>
                <CheckIcon className="icon" width="65%" />
              </div>
            ) : null}
          </div>
        </div>
      ) : (
        <div
          className="d-flex align-items-center justify-content-start pointer"
          onClick={() => setShow(true)}
        >
          <div className="squared-btn py-2">
            <AddIcon className="icon" width="45%" />
          </div>
          {hasSomeFilter ? null : (
            <p className="m-0 p-0 ml-2 pl-1 font-weight-medium">
              {messages['input-filter.add-new']}
            </p>
          )}
        </div>
      )}
    </div>
  );
}
