import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import UsersList from '../../../components/UsersList';
import orderStatus from '../../../constants/orderStatus';
import useDataArchive from '../../../hooks/useDataArchive';
import firebaseApp from '../../../services/Firebase';
import { getDocument } from '../../../utils/firebase';
import { stringToMoney } from '../../../utils/money';
import GeneralInfo from './GeneralInfo';

export default function TravelCreditInfo({ className = '', items }) {
  const { messages } = useIntl();
  const { getDataFromArchive: getData } = useDataArchive();

  // States
  const {
    numItems,
    numUsers,
    users = [],
    total,
    totalSpent,
  } = useMemo(() => {
    const usersObj = {};
    let total = 0,
      totalSpent = 0;

    items.forEach(({ passengerId, fare, statusCode }) => {
      if (!usersObj[passengerId]) usersObj[passengerId] = true;
      if (statusCode < orderStatus.ERROR) totalSpent += fare.total;

      total += fare.total;
    });

    const users = Object.keys(usersObj);

    return {
      numItems: items.length,
      numUsers: users.length,
      users,
      total: stringToMoney(total, 2),
      totalSpent: stringToMoney(totalSpent, 2),
    };
  }, [items]);

  // Functions
  const fetchUser = (userId) =>
    getData(`user-${userId}`, () =>
      getDocument(() => firebaseApp.getUserFromId(userId).get())
    );

  return (
    <GeneralInfo className={className} title={messages['general-view.travel']}>
      {(renderItem, renderDescription) => (
        <>
          {renderItem(
            messages['general-view.travel.created-reservations-credit'],
            numItems,
            () =>
              renderDescription(
                messages[
                  'general-view.travel.created-reservations-credit.description'
                ]
              )
          )}
          {renderItem(messages['general-view.travel.total-credit'], total, () =>
            renderDescription(
              messages['general-view.travel.total-credit.description']
            )
          )}
          {renderItem(
            messages['general-view.active-users-credit'],
            numUsers,
            () => (
              <div className="mt-2">
                <UsersList users={users} fetchFunction={fetchUser} />
              </div>
            )
          )}
        </>
      )}
    </GeneralInfo>
  );
}
