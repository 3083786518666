import React, { useEffect, useRef, useState } from 'react';
import BubbleButton from './BubbleButton';

import { ReactComponent as SearchIcon } from '../../assets/img/icons/search.svg';
import classNames from 'classnames';
import { CustomInput } from 'reactstrap';
import { useIntl } from 'react-intl';

export default function RetractableSearchBar({
  className,
  placeholder,
  value,
  onChange,
  width = 200,
  disabled,
}) {
  const { messages } = useIntl();

  // Refs
  const inputRef = useRef(null);
  const timeoutRef = useRef(null);

  // States
  const [show, setShow] = useState(false);
  const [noEffect, setNoEffect] = useState(false);

  // Effects
  useEffect(() => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);

    if (show) {
      if (inputRef.current) {
        inputRef.current.focus();
      }

      setNoEffect(true);
    } else timeoutRef.current = setTimeout(() => setNoEffect(false), 500);
  }, [show]);

  // Render
  return (
    <div
      className={classNames('retractable-search-bar d-flex', className, {
        show,
      })}
    >
      <BubbleButton
        onClick={() => {
          setShow((value) => !value);
        }}
        noEffect={noEffect}
        title={messages['admin.reports.search-title']}
        disabled={disabled}
      >
        <SearchIcon width={25} height={25} />
      </BubbleButton>
      <CustomInput
        type="text"
        id="search-input"
        placeholder={placeholder}
        className="search-input p-0 m-0"
        value={value}
        onChange={onChange}
        style={{
          width: show ? width : 0,
        }}
        innerRef={inputRef}
        onBlur={() => {
          if (!value) setShow(false);
        }}
      />
    </div>
  );
}
