import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Row, Modal, Label, ModalBody } from 'reactstrap';
import IntlMessages from '../../helpers/IntlMessages';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Colxx } from '../../components/common/CustomBootstrap';
import moment from 'moment';
import map from 'lodash/map';

import { connect } from 'react-redux';

import CityName from '../../components/flight/CityName';
import Duration from '../../components/flight/Duration';
import AirportWait from '../../components/flight/AirportWait';

import { stringToMoney } from '../../utils/money';
import { registeredAirlines } from '../../constants/travelValues';

class PackageFlightModal extends Component {
  // Render
  renderJourney(journey, index) {
    const {
      params,
      intl: { messages },
    } = this.props;
    const segment = this.props.segments[index];

    return (
      <div key={`journey-${index}`} className="journey mb-5 mt-1">
        <div className="journey-title">
          <img
            src="/assets/img/icons/search_icon.svg"
            alt={messages['alts.components.flight.journey.journey']}
          />
          <h3>
            <CityName station={journey.departureStation} params={params} /> •{' '}
            <CityName station={journey.arrivalStation} params={params} />
          </h3>
        </div>

        {map(segment.flights, (flight, i) => {
          var nextFlight = segment.flights[i + 1];

          return (
            <div key={`journey-${index}-flight-${i}`}>
              <Row className="pl-3 pr-3 mt-3">
                <Colxx xxs="4" className="origin">
                  {registeredAirlines[flight.airline] && (
                    <div
                      className="carrier"
                      style={{
                        backgroundImage: `url(${
                          registeredAirlines[flight.airline].logo
                        })`,
                      }}
                    />
                  )}
                  <p>
                    {moment(flight.departureDateTime).format(
                      'ddd, DD MMM YYYY'
                    )}
                  </p>
                  <p className="highlight">
                    {moment(flight.departureDateTime).format('HH:mm')}
                  </p>
                  <p className="mt-2">
                    <CityName
                      station={flight.departureStation}
                      params={params}
                    />
                  </p>
                  <p className="highlight">{flight.departureStation}</p>
                </Colxx>
                <Colxx xxs="4" className="duration">
                  <img
                    src="/assets/img/icons/search_icon.svg"
                    alt={messages['alts.components.flight.journey.duration']}
                  />
                  {messages['general.duration']}:{' '}
                  <Duration duration={flight.duration} />
                </Colxx>
                <Colxx xxs="4" className="destination">
                  <p className="title">{`Voo ${flight.airline} ${flight.flightNumber} / ${flight.fare.familyName}`}</p>
                  <p>
                    {moment(flight.arrivalDateTime).format('ddd, DD MMM YYYY')}
                  </p>
                  <p className="highlight">
                    {moment(flight.arrivalDateTime).format('HH:mm')}
                  </p>
                  <p className="mt-2">
                    <CityName station={flight.arrivalStation} params={params} />
                  </p>
                  <p className="highlight">{flight.arrivalStation}</p>
                </Colxx>
              </Row>

              {nextFlight && (
                <AirportWait
                  startTime={flight.arrivalDateTime}
                  finishTime={nextFlight.departureDateTime}
                  station={flight.arrivalStation}
                  params={params}
                />
              )}
            </div>
          );
        })}
      </div>
    );
  }

  render() {
    const { item } = this.props;

    return (
      <Modal
        isOpen={this.props.showModal}
        toggle={this.props.toggleModal}
        className="reservation small smaller"
      >
        <ModalBody className="flight">
          <Row>
            <Colxx xxs="12">
              <div className="flight-content">
                <PerfectScrollbar
                  options={{
                    suppressScrollX: true,
                    wheelPropagation: false,
                  }}
                >
                  <div className="reservation-params primary p-3">
                    <Row>
                      <Colxx xxs="10">
                        <Label className="form-group disabled search has-top-label">
                          <p>{stringToMoney(item.fare.total)}</p>
                          <IntlMessages id="travel.total" />
                        </Label>
                      </Colxx>
                    </Row>
                  </div>

                  <div className="mt-5">
                    {map(item.journeys, (journey, index) => {
                      return this.renderJourney(journey, index);
                    })}
                  </div>
                </PerfectScrollbar>
              </div>
            </Colxx>
          </Row>
        </ModalBody>
      </Modal>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { user } = auth;
  return { user };
};

export default injectIntl(connect(mapStateToProps)(PackageFlightModal));
