import React, { useMemo } from 'react';
import { stringToMoney } from '../../../../../../utils/money';
import { ReactComponent as LuggageIcon } from '../../../../../../assets/img/icons/luggage.svg';
import classNames from 'classnames';
import { useIntl } from 'react-intl';

function BagItem({ item, isRoundtrip, journeyId }) {
  const { messages, formatMessage } = useIntl();

  const [name, price] = useMemo(() => {
    const { details, fare, quantity } = item;
    const { weight_unit = 'kg', weight = '23' } = details;

    return [
      formatMessage(
        { id: 'flight-alteration.bags.item' },
        {
          bags:
            quantity > 1
              ? formatMessage(
                  { id: 'flight-alteration.bags.plural' },
                  { bags: quantity }
                )
              : messages['flight-alteration.bags.single'],
          weight: `${weight}${weight_unit}`,
        }
      ),
      stringToMoney(fare.total, 2, fare.currency),
    ];
  }, [item]);

  return (
    <div className="mb-2 mr-5">
      {isRoundtrip ? (
        <p className="m-0 p-0 header">
          {messages[journeyId ? 'travel.inbound' : 'travel.outbound']}
        </p>
      ) : null}
      <p className="m-0 p-0 name">{name}</p>
      <p className="m-0 p-0 total">{price}</p>
    </div>
  );
}

export default function BaggageItems({
  items,
  reservations,
  order,
  className = '',
  style = {},
}) {
  const { messages } = useIntl();

  const [isRoundtrip, sortedItems] = useMemo(() => {
    const isRoundtrip = order.journeys.length > 1;

    const sortedItems = isRoundtrip
      ? items.items.sort((a, b) => {
          const aReservation = a.relatedTo[0];
          const bReservation = b.relatedTo[0];
          return (
            reservations[aReservation].flightDetails.journeyId -
            reservations[bReservation].flightDetails.journeyId
          );
        })
      : items.items;

    return [isRoundtrip, sortedItems];
  }, [items]);

  return (
    <div className={classNames('mb-4', className)} style={style}>
      <div className="d-flex align-items-center mb-2">
        <LuggageIcon
          fill="white"
          width="1.1rem"
          height="1.1rem"
          className="mr-2 icon"
        />
        <h3 className="font-primary title">
          {messages['flight-alteration.bags']}
        </h3>
      </div>
      <div className="d-flex align-items-center flex-wrap mx-4">
        {sortedItems.map((item, index) => (
          <BagItem
            key={index}
            item={item}
            isRoundtrip={isRoundtrip}
            reservations={reservations}
            journeyId={index}
          />
        ))}
      </div>
    </div>
  );
}
