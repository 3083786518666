import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { Card } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import qs from 'query-string';

import User from '../../services/User';

import { useIntl } from 'react-intl';

import LoadingReset from '../../components/auth/LoadingReset';
import ErrorReset from '../../components/auth/ErrorReset';
import CodeReset from '../../components/auth/CodeReset';
import CodeNew from '../../components/auth/CodeNew';

export default function ForgotPassword() {
  const [step, setStep] = useState('loading');
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [error, setError] = useState('');

  const { user } = useSelector((state) => state.auth);

  const { messages } = useIntl();
  const { search } = useLocation();
  const history = useHistory();

  useEffect(() => {
    checkCode();
  }, []);

  useEffect(() => {
    if (user?.uid) {
      history.push('/');
    }
  }, [user]);

  // Handle Steps
  const checkCode = async () => {
    try {
      const params = qs.parse(search);
      const code = await User.checkResetCode(params?.oobCode);

      setStep(code.operation === 'VERIFY_EMAIL' ? 'new' : 'reset');
      setEmail(code.data.email);
      setCode(params?.oobCode);
    } catch (err) {
      if (
        err.code === 'auth/expired-action-code' ||
        err.code === 'auth/invalid-action-code'
      ) {
        setError(messages['user.ExpiredCodeException']);
      } else if (err.code === 'auth/user-disabled') {
        setError(messages['user.UserDisabledException']);
      } else if (err.code === 'auth/user-not-found') {
        setError(messages['user.UserNotFoundException']);
      } else {
        // Generic error
        setError(`${messages['user.GeneralException']} ${err.message}`);
      }

      setStep('error_reset');
    }
  };

  const resetSuccess = async (password) => {
    await User.passwordLogin(email, password);
  };

  // Render
  return (
    <Card className="auth-card">
      <NavLink to={`/`} className="black">
        <span className="logo-single mb-4" />
      </NavLink>
      <div className="form-side">
        {step === 'loading' && <LoadingReset />}
        {step === 'error_reset' && <ErrorReset error={error} />}
        {step === 'reset' && (
          <CodeReset resetSuccess={resetSuccess} code={code} />
        )}
        {step === 'new' && (
          <CodeNew
            changeStep={setStep}
            resetSuccess={resetSuccess}
            code={code}
          />
        )}
      </div>
    </Card>
  );
}
