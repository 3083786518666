import React from 'react';

import ContentLoader from 'react-content-loader';

export default function LoaderButton() {
  return (
    <ContentLoader
      speed={2}
      width={120}
      height={40}
      viewBox="0 0 120 40"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="0" y="0" rx="3" ry="3" width="120" height="40" />
    </ContentLoader>
  );
}
