import { useCallback, useEffect, useRef, useState } from 'react';
import {
  getDefaultSearchParamsByType,
  getInitialSearchResults,
  getRemainingResults,
  getSearchParamsByType,
} from '../../services/Firebase';
import { getDocument, getDocumentsFromCollection } from '../../utils/firebase';

import loadSearchStatus from '../../constants/loadSearchStatus';
import searchStatus from '../../constants/searchStatus';

import { TSearch } from '../../types/orders/orders';
import useDataArchive from '../useDataArchive';
import { useSelector } from 'react-redux';
import { getAuthRedux } from 'src/portao3-legacy/utils/redux';

export type TFormatResult<TSearchItem> = {
  results: TSearchItem[];
  search: TSearch;
};

export interface IUseTravelSearchProps<TSearchItem, TParams> {
  search: TSearch;
  orderType: string;
  initialSearchLimit?: number;
  formatResultItem?: (data: TSearchItem) => Promise<TSearchItem>;
  formatResult?: ({
    results,
    search,
  }: TFormatResult<TSearchItem>) => Promise<TSearchItem[]>;
  formatParams?: (data: TParams) => Promise<TParams>;
  initialStatus?: string;
  initialResults?: TSearchItem[];
  initialParams?: TParams;
  initialLoading?: boolean;
}

export default function useTravelSearch<
  TSearchItem,
  TParams,
  TDefaultSearchParams
>({
  search,
  orderType,
  initialSearchLimit = 20,
  formatResultItem = async (data) => data,
  formatResult = async ({ results }) => results,
  formatParams = async (data) => data,
  initialStatus = searchStatus.NEW,
  initialResults = [],
  initialParams = {} as TParams,
  initialLoading = true,
}: IUseTravelSearchProps<TSearchItem, TParams>) {
  // Refs
  const lastSearchCode = useRef<string>('');
  const statusRef = useRef<string>(initialStatus);
  const lastSnapItem = useRef(null);

  // Hooks
  const { getDataFromArchive } = useDataArchive();
  const { user } = useSelector(getAuthRedux);

  // States
  const [status, setStatus] = useState(initialStatus);
  const [results, setResults] = useState<TSearchItem[]>(initialResults);
  const [params, setParams] = useState<TParams>(initialParams);
  const [defaultSearchParams, setDefaultSearchParams] =
    useState<TDefaultSearchParams>({} as any);
  const [isLoading, setIsLoading] = useState(initialLoading);

  // Effects
  useEffect(() => {
    if (statusRef.current !== search.status) {
      if (search.status === searchStatus.NEW) {
        resetSearch();
      } else if (search.status === searchStatus.LOADING) {
        setParams({} as TParams);
        setResults([]);
      } else if (search.status === searchStatus.DONE) {
        doInitialSearch(search);
      }

      statusRef.current = search.status;
    }
  }, [search.status]);

  useEffect(() => {
    if (lastSearchCode.current !== search.searchCode) {
      resetSearch();
      lastSearchCode.current = search.searchCode;
    }
  }, [search.searchCode]);

  // Functions
  const resetSearch = useCallback((status = loadSearchStatus.NEW) => {
    setResults([]);
    setStatus(status);
    setIsLoading(true);
    setParams({} as TParams);
  }, []);

  const getParams = useCallback(
    async (search: TSearch) => {
      try {
        const [params, defaultSearchParams] = await Promise.all([
          getDocument(
            () => getSearchParamsByType(search.searchCode, orderType).get(),
            {} as any
          ).then(formatParams),
          getDataFromArchive(`default-search-params-${orderType}`, () =>
            getDocument(
              () =>
                getDefaultSearchParamsByType(
                  user.organizationId,
                  orderType
                ).get(),
              {} as any
            )
          ),
        ]);

        setParams(params);
        setDefaultSearchParams(defaultSearchParams);
      } catch (err) {
        console.error({
          err,
          message: 'Unable to get params from search',
          orderType,
          searchCode: search.searchCode,
        });
      }
    },
    [orderType, formatParams, user]
  );

  const getResults = useCallback(
    async ({
      query,
      newStatus,
      search,
      useLoading = true,
    }: {
      query: any;
      newStatus: string;
      search: TSearch;
      useLoading?: boolean;
    }) => {
      try {
        if (useLoading) setIsLoading(true);

        const { data: newResults, snap } = (await getDocumentsFromCollection(
          query,
          true
        )) as { data: TSearchItem[]; snap: any };

        lastSnapItem.current = snap.docs[snap.size - 1];

        const formattedResultsItems = (await Promise.all(
          newResults.map((result) => formatResultItem(result))
        )) as TSearchItem[];

        const formattedResults = await formatResult({
          results: formattedResultsItems,
          search,
        });

        setResults((results) => results.concat(formattedResults));
        setStatus(
          newResults.length < initialSearchLimit
            ? loadSearchStatus.ALL
            : newStatus
        );

        return formattedResults;
      } catch (err) {
        console.error('Unable to fetch results', err);

        setResults([]);
        setStatus(newStatus);

        return [];
      } finally {
        if (useLoading) setIsLoading(false);
      }
    },
    [formatResultItem, formatResult, initialSearchLimit]
  );

  const getInitialSearch = useCallback(
    async (search: TSearch) => {
      const results = await getResults({
        query: () =>
          getInitialSearchResults(
            search.searchCode,
            orderType,
            initialSearchLimit
          ).get(),
        newStatus: loadSearchStatus.NEW,
        search,
        useLoading: false,
      });

      if (!results.length) {
        return resetSearch(loadSearchStatus.EMPTY);
      }
    },
    [orderType, initialSearchLimit, resetSearch, getResults]
  );

  const doInitialSearch = useCallback(
    async (search: TSearch) => {
      try {
        setIsLoading(true);
        await Promise.all([getInitialSearch(search), getParams(search)]);
      } catch (err) {
        console.error('Unable to do initial search', err);
      } finally {
        setIsLoading(false);
      }
    },
    [getInitialSearch, getParams]
  );

  const getRemainingSearch = useCallback(
    async () =>
      getResults({
        query: () =>
          getRemainingResults(
            search.searchCode,
            orderType,
            lastSnapItem.current
          ).get(),
        newStatus: loadSearchStatus.ALL,
        search,
      }),
    [orderType, getResults, search.searchCode]
  );

  return {
    status,
    results,
    isLoading,
    params,
    defaultSearchParams,
    getRemainingSearch,
  };
}
