import React, { useEffect, useState } from 'react';
import { Label, Button, Alert, CardTitle } from 'reactstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { NavLink } from 'react-router-dom';

import IntlMessages from '../../helpers/IntlMessages';
import { useIntl } from 'react-intl';

import { AUTH_TYPE } from '../../constants/authType';
import User from '../../services/User';

export default function LoginEmail({ email, setEmail, setProvider, setStep }) {
  const [error, setError] = useState('');
  const { messages } = useIntl();

  useEffect(() => {
    if (email) {
      checkNextStep(email);
    }
  }, [email]);

  const checkNextStep = async (email) => {
    const signInMethod = await User.accountLookup(email);

    setEmail(email);
    setProvider(signInMethod.data.authType.provider);
    setStep(`auth-${AUTH_TYPE[signInMethod.data.authType.method]}`);
  };

  const submitForm = async (values, setSubmitting) => {
    setSubmitting(true);

    try {
      await checkNextStep(values.email);
    } catch (err) {
      console.error(err);
      setError(messages['user.authType.error']);
    }

    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={{ email }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .nullable()
          .email(messages['forms.validation.email.valid'])
          .required(messages['forms.validation.email.required']),
      })}
      onSubmit={(values, { setSubmitting }) => {
        submitForm(values, setSubmitting);
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <CardTitle className="mb-4">
            <IntlMessages id="user.login" />
          </CardTitle>

          {error && (
            <Alert
              color="danger"
              name="alert-login-error"
              className="mt-2 mb-2"
            >
              {error}
            </Alert>
          )}

          <Label className="form-group has-top-label mb-4">
            <Field
              type="email"
              name="email"
              className="form-control"
              autoComplete="username"
            />
            <IntlMessages id="user.username" />
            <ErrorMessage
              name="email"
              component="div"
              className="invalid-feedback d-block"
            />
          </Label>
          <div className="d-flex justify-content-between align-items-center">
            <NavLink to={`/user/forgot-password`}>
              <IntlMessages id="user.forgot-password-question" />
            </NavLink>
            <Button
              type="submit"
              color="primary"
              outline
              size="lg"
              disabled={isSubmitting}
            >
              <IntlMessages id="forms.action.next" />
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
