import { useRef, useEffect, useState, useCallback } from 'react';
import appFunctions from '../services/Functions';
import debounce from 'lodash/debounce';

const isCardBlocked = (cardDetails = {}) => {
  return cardDetails?.status === 'ACTIVE' && cardDetails?.blocked;
};

export default function useCreditCard({
  organizationId,
  cardNumber,
  cardExternalId,
  refreshEvery = 1000 * 60 * 5,
  fetchFunction = appFunctions.getExpenseCardDetails,
  blockFunction = appFunctions.putExpenseCardBlockStatus,
  detailsInitialState = null,
  shouldLoad = true,
}) {
  const intervalRef = useRef(null);
  const canRefresh = useRef(false);

  const [details, setDetails] = useState(detailsInitialState);
  const [loading, setLoading] = useState(true);
  const [blocked, setBlocked] = useState(false);
  const [loadingPan, setLoadingPan] = useState(true);
  const [pan, setPan] = useState(null);
  const [showPan, setShowPan] = useState(false);

  // Effects
  useEffect(() => {
    if (shouldLoad) {
      if (cardNumber && organizationId) setupDetails();
      else setDetails(detailsInitialState);

      return doClearInterval;
    }
  }, [shouldLoad, cardNumber, organizationId]);

  useEffect(() => {
    if (showPan && !pan) getPan();
  }, [showPan]);

  useEffect(() => {
    canRefresh.current = !loading && shouldLoad;
  }, [loading, shouldLoad]);

  // Functions
  const checkDetails = (details) => {
    if (details) {
      const isBlocked = isCardBlocked(details);
      if (isBlocked !== blocked) setBlocked(isBlocked);
    }
  };

  const updateDetails = async () => {
    try {
      setLoading(true);

      const { data: details } = await fetchFunction(
        organizationId,
        cardExternalId || cardNumber,
        !!cardExternalId
      );
      checkDetails(details);

      setDetails(details);
      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  const doClearInterval = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };

  const refreshInterval = () => {
    doClearInterval();
    intervalRef.current = setInterval(updateDetails, refreshEvery);
  };

  const setupDetails = async () => {
    await updateDetails();
    refreshInterval();
  };

  const toggleBlock = async () => {
    try {
      setBlocked((state) => !state);
      await blockFunction(organizationId, cardNumber, !blocked);
    } catch (err) {
      console.error(err);
      setBlocked(blocked);
    }
  };

  const getPan = async () => {
    if (pan) return pan;
    else {
      try {
        setLoadingPan(true);

        const { data } = await appFunctions.getExpenseCardPan(
          organizationId,
          cardNumber
        );

        setPan(data);
        return data;
      } catch (err) {
        console.error(err);
      } finally {
        setLoadingPan(false);
      }
    }
  };

  const togglePan = () => {
    setShowPan((state) => !state);
  };

  const refresh = useCallback(
    debounce(() => {
      if (canRefresh.current) {
        setupDetails();
      }
    }, 5000),
    []
  );

  return {
    details,
    setDetails,
    loading,
    blocked,
    toggleBlock,
    pan,
    loadingPan,
    togglePan,
    showPan,
    refresh,
  };
}
