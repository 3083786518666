import React from 'react';
import { useIntl } from 'react-intl';
import axios from 'axios';
import classnames from 'classnames';
import map from 'lodash/map';
import filter from 'lodash/filter';

import { components } from 'react-select';
import AsyncSelect from 'react-select/async';

import User from '../../services/User';
import { locationTypes } from '../../constants/travelValues';
import { useSelector } from 'react-redux';
import { useCallback } from 'react';
import classNames from 'classnames';

const Option = (props) => {
  const locationClass = locationTypes[props.data.type] || locationTypes.city;

  return (
    <div
      className={classnames({
        'react-select__menu_option': true,
        active: props.isFocused,
      })}
    >
      <div className={`option-icon ${locationClass.style}`} />
      <components.Option {...props} />
    </div>
  );
};

export default function CarLocationSearch({ value, setValue, className }) {
  const { messages } = useIntl();
  const locale = useSelector(({ settings }) => {
    const { locale } = settings;
    return locale ? locale.split('-')[0] : '';
  });

  // Functions
  const findLocation = useCallback(
    (inputValue) => {
      return new Promise(async (resolve, fail) => {
        try {
          const token = await User.getToken();

          const res = await axios.get(
            `${process.env.REACT_APP_API_URL}/car/locations/autocomplete`,
            {
              params: { search: inputValue, removeHotels: true },
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          if (res.data.size > 0) {
            const results = map(
              filter(res.data.data, (item) => item.type !== 'airport'),
              (hit) => {
                return {
                  value: hit.destinationId,
                  cityCode: hit.destinationId,
                  label: hit.name[locale] || hit.name['pt-BR'],
                  countryName: hit.countryId,
                  type: hit.type,
                };
              }
            );

            resolve(results);
          }

          resolve([]);
        } catch (err) {
          console.error(err);
          resolve([]);
        }
      });
    },
    [locale]
  );

  // Render
  return (
    <AsyncSelect
      className={classNames('react-select', className)}
      classNamePrefix="react-select"
      components={{ Option }}
      isClearable={false}
      value={value}
      cacheOptions
      placeholder=""
      noOptionsMessage={() => {
        return messages['travel.location-message'];
      }}
      loadingMessage={() => {
        return messages['travel.location-searching'];
      }}
      onChange={(selects) => {
        setValue(selects);
      }}
      loadOptions={findLocation}
    />
  );
}
