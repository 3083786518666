import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import sumBy from 'lodash/sumBy';

import { stringToMoney } from '../../utils/money';
import { ReactComponent as MapIcon } from '../../assets/img/icons/map.svg';
import { ReactComponent as ClockIcon } from '../../assets/img/icons/compass.svg';
import { ReactComponent as TollIcon } from '../../assets/img/icons/toll.svg';

function Info({ title, description, Icon, className = '' }) {
  return (
    <div className={classNames('info d-flex align-items-center', className)}>
      {Icon ? (
        <div className="icon" style={{ marginRight: 5 }}>
          <Icon width={25} height={25} />
        </div>
      ) : null}
      <div>
        <h4 className="title font-primary text-primary m-0 p-0 font-weight-medium">
          {title}
        </h4>
        <p className="description m-0 p-0">{description}</p>
      </div>
    </div>
  );
}

export default function SelectedRouteInfo({
  selectedRoute,
  className = '',
  itemClassName = '',
}) {
  const { messages } = useIntl();

  const { duration, distance } = useMemo(() => {
    if (selectedRoute) {
      const duration = selectedRoute.duration;
      const hours = Math.floor(duration);
      const minutes = Math.floor((duration - Math.floor(duration)) * 60);

      return {
        duration: `${hours}h ${minutes}m`,
        distance: `${selectedRoute.distance} km`,
      };
    } else return {};
  }, [selectedRoute]);

  const { tollCount, tollPrice } = useMemo(() => {
    if (selectedRoute.tolls) {
      const totalCost = sumBy(selectedRoute.tolls, (toll) => toll.amount);

      return {
        tollCount: `${selectedRoute.tolls.length}`,
        tollPrice: `${stringToMoney(totalCost, 2)}`,
      };
    } else return {};
  }, [selectedRoute]);

  // Render
  if (!selectedRoute) return null;

  return (
    <>
      <div
        className="d-flex justify-content-between align-items-center mx-3 mb-3 mt-3"
        style={{ zIndex: 100 }}
      >
        <div className="d-flex align-items-center w-50">
          <h5 className="m-0">{messages['refund.modal.km.details']}</h5>
        </div>
      </div>
      <div className={classNames('selected-route-info', className)}>
        <Info
          title="Duração"
          className={itemClassName}
          description={duration}
          Icon={ClockIcon}
        />
        <Info
          title="Distância"
          className={itemClassName}
          description={distance}
          Icon={MapIcon}
        />
        {tollCount && (
          <Info
            title={`${tollCount} Pedágios`}
            className={itemClassName}
            description={tollPrice}
            Icon={TollIcon}
          />
        )}
      </div>
    </>
  );
}
