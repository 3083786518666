import React, { useMemo } from 'react';
import { CardBody, Row } from 'reactstrap';
import { Colxx } from '../../components/common/CustomBootstrap';
import moment from 'moment';
import filter from 'lodash/filter';
import find from 'lodash/find';
import isUndefined from 'lodash/isUndefined';
import forEach from 'lodash/forEach';
import orderBy from 'lodash/orderBy';
import map from 'lodash/map';
import slice from 'lodash/slice';
import { useIntl } from 'react-intl';

import HotelCityMap from './HotelCityMap';

import { OrderTypes } from '../../constants/orderTypes';

export default function HotelCardTopHotels({ orders, reservations }) {
  const { messages } = useIntl();

  const citiesList = useMemo(() => {
    if (!(orders.length && reservations.length)) return [];

    const hotelReservations = filter(reservations, (reservation) => {
      const orderExists = !isUndefined(
        find(orders, (order) => {
          return order.id === reservation.refs.orderId;
        })
      );

      return reservation.type === OrderTypes.HOTEL && orderExists;
    });

    let citiesList = {};
    forEach(hotelReservations, (reservation) => {
      const cityName = reservation.hotelDetails.hotel.destination.city.pt;

      citiesList[cityName] = {
        ...reservation.hotelDetails.hotel,
        ...citiesList[cityName],
      };

      citiesList[cityName].qtd = isUndefined(citiesList[cityName].qtd)
        ? 1
        : citiesList[cityName].qtd + 1;

      const nights = moment(
        reservation.hotelDetails.checkOut,
        'DD/MM/YYYY'
      ).diff(moment(reservation.hotelDetails.checkIn, 'DD/MM/YYYY'), 'days');

      citiesList[cityName].nights = isUndefined(citiesList[cityName].nights)
        ? nights
        : citiesList[cityName].nights + nights;
    });

    return slice(orderBy(citiesList, 'qtd', ['desc']), 0, 5);
  }, [orders, reservations]);

  return (
    <CardBody>
      <Row>
        <Colxx sm="12">
          {map(citiesList, (city) => {
            return (
              <div
                key={city.iterpec}
                className="d-flex flex-row mb-3 pb-3 justify-content-between align-items-center"
              >
                <HotelCityMap city={city} />
                <div className="flex-fill pl-2">
                  <p className="font-weight-medium mb-0">
                    {city.destination.city.pt}
                  </p>
                </div>
                <div className="pr-2 fit-content">
                  {city.qtd} {messages['general.reservations']}
                </div>
                <div className="pl-2 fit-content">
                  {city.nights} {messages['general.nights']}
                </div>
              </div>
            );
          })}
        </Colxx>
      </Row>
    </CardBody>
  );
}
