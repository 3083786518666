export const dictBankNames = {
  90400888: 'santander',
  '00000000': 'bb',
  60746948: 'bradesco',
  30306294: 'btg',
  31872495: 'c6',
  '00360305': 'caixa',
  '00416968': 'inter',
  60701190: 'itau',
  10573521: 'mercadopago',
  18236120: 'nubank',
  '08561701': 'pagseguro',
  22896431: 'picpay',
  58160789: 'safra',
};
