import React, { useState, useEffect } from 'react';
import {
  Row,
  InputGroup,
  Button,
  ModalBody,
  Card,
  CardImg,
  Label,
} from 'reactstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import MaskedInput from 'react-text-mask';
import { cardDateMask } from '../../utils/masks';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';

import Cards from 'react-credit-cards';
import Payment from 'payment';
import 'react-credit-cards/es/styles-compiled.css';

import IntlMessages from '../../helpers/IntlMessages';

import firebaseApp from '../../services/Firebase';
import { trackPropertiesSegment } from '../../utils/segment';

let loadCreditCardsEvent = () => {};

function CreditCardList({ editUser }) {
  const [creditCards, setCreditCards] = useState([]);
  const { messages } = useIntl();

  useEffect(() => {
    loadCreditCards();

    return () => {
      loadCreditCardsEvent();
    };
  }, []);

  // Get References
  const loadCreditCards = async () => {
    loadCreditCardsEvent = firebaseApp
      .getCreditCardsFromUser(editUser.uid)
      .onSnapshot((creditCardsSnap) => {
        const creditCards = [];

        creditCardsSnap.forEach((creditCardSnap) => {
          creditCards.push({
            id: creditCardSnap.id,
            ...creditCardSnap.data(),
          });
        });

        setCreditCards(creditCards);
      });
  };

  // Events
  const deleteCreditCard = async (cardId) => {
    await firebaseApp.deleteCreditCardsFromUserAndId(editUser.uid, cardId);

    trackPropertiesSegment('User credit card deleted', {
      cardId,
      user: editUser,
    });
  };

  if (!creditCards.length) return <div />;

  return (
    <div>
      <h5 className="mt-4">
        {messages['containers.users.user-modal-credit-card.registered-cards']}
      </h5>
      {creditCards.map((creditCard) => {
        return (
          <Card
            className="d-flex flex-row list credit-card-list"
            key={`user-credit-card-id-${creditCard.id}`}
          >
            <div className="pl-2 d-flex flex-grow-1 mw-0">
              <div className="card-body align-self-center d-flex flex-column flex-lg-row justify-content-between mw-0 align-items-lg-center">
                <CardImg
                  top
                  src={`/assets/img/logos/${creditCard.issuer}.svg`}
                  alt={creditCard.issuer}
                  className="img-thumbnail border-0 rounded-circle list-thumbnail"
                />
                <p className="mb-1 text-muted text-small w-15 w-sm-100">
                  {messages['travel.booking.creditCard.ends-with']}{' '}
                  {creditCard.lastDigits}
                </p>
                <div className="w-15 w-sm-100 text-right">
                  <Button
                    size="xs"
                    color="primary"
                    onClick={() => {
                      deleteCreditCard(creditCard.id);
                    }}
                  >
                    <div className="glyph-icon simple-icon-close" />
                  </Button>
                </div>
              </div>
            </div>
          </Card>
        );
      })}
    </div>
  );
}

function CreditCardForm({ editUser }) {
  const { messages } = useIntl();
  const [focus, setFocus] = useState('number');

  // Events
  const handleInputFocus = (e) => {
    setFocus(e.target.id);
  };

  const createCreditCard = async (values, setSubmitting, resetForm) => {
    const issuer = Payment.fns.cardType(values.number);

    await firebaseApp.createCreditCardForUser(editUser.uid, {
      cc: {
        name: values.name,
        cvv: values.cvv,
        expiry: values.expiry,
        number: values.number,
        issuer,
      },
      name: '',
      documentNumber: editUser.documents?.cpf?.number || '',
    });

    resetForm();
    setSubmitting(false);

    trackPropertiesSegment('New user credit card created', { user: editUser });
  };

  return (
    <div>
      <h5 className="mt-4">
        {messages['containers.users.user-modal-credit-card.new-card']}
      </h5>
      <Formik
        initialValues={{
          cvv: '',
          expiry: '',
          name: '',
          number: '',
        }}
        validationSchema={Yup.object().shape({
          number: Yup.string().required(
            messages['forms.validation.cardNumber.required']
          ),
          name: Yup.string().required(
            messages['forms.validation.name.required']
          ),
          expiry: Yup.string()
            .matches(
              /^(0[1-9]|1[012])\/\d{2}$/,
              messages['forms.validation.cardExpiry.valid']
            )
            .required(messages['forms.validation.cardExpiry.required']),
          cvv: Yup.string()
            .matches(/^\d{3,4}$/, messages['forms.validation.cvv.valid'])
            .required(messages['forms.validation.cvv.required']),
        })}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          createCreditCard(values, setSubmitting, resetForm);
        }}
      >
        {({ values, isSubmitting, handleChange, handleBlur }) => (
          <Form>
            <ModalBody>
              <Row>
                <Cards
                  cvc={values.cvv}
                  expiry={values.expiry}
                  focused={focus}
                  name={values.name}
                  number={values.number}
                  placeholders={{
                    name: messages['admin.policy.placeholder.name'],
                  }}
                  locale={{
                    valid: messages['admin.policy.placeholder.valid'],
                  }}
                />
              </Row>
              <Row className="mt-4 mb-1">
                <Label className="form-group has-top-label mb-0 w-100">
                  <Field
                    type="input"
                    name="number"
                    id="number"
                    className="form-control"
                    onFocus={handleInputFocus}
                  />
                  <IntlMessages id="admin.policy.creditCard.number" />
                  <ErrorMessage
                    name="number"
                    component="div"
                    className="invalid-feedback d-block"
                  />
                </Label>
              </Row>
              <Row className="mb-1">
                <Label className="form-group has-top-label mb-0 w-100">
                  <Field
                    type="input"
                    name="name"
                    id="name"
                    className="form-control"
                    onFocus={handleInputFocus}
                    maxLength={30}
                  />
                  <IntlMessages id="admin.policy.creditCard.name" />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="invalid-feedback d-block"
                  />
                </Label>
              </Row>
              <Row className="mb-1">
                <InputGroup>
                  <Label className="form-group has-top-label mb-0">
                    <Field name="expiry">
                      {({ field }) => (
                        <MaskedInput
                          type="text"
                          {...field}
                          mask={cardDateMask}
                          id="expiry"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          onFocus={handleInputFocus}
                          className="form-control"
                        />
                      )}
                    </Field>
                    <IntlMessages id="admin.policy.creditCard.expiry" />
                    <ErrorMessage
                      name="expiry"
                      component="div"
                      className="invalid-feedback d-block"
                    />
                  </Label>
                  <Label className="form-group has-top-label mb-0">
                    <Field
                      type="input"
                      name="cvv"
                      id="cvc"
                      className="form-control"
                      onFocus={handleInputFocus}
                    />
                    <IntlMessages id="admin.policy.creditCard.cvv" />
                    <ErrorMessage
                      name="cvv"
                      component="div"
                      className="invalid-feedback d-block"
                    />
                  </Label>
                </InputGroup>
              </Row>
              <Row className="mt-3 mb-5 pb-5">
                <div className="text-right w-100">
                  <Button
                    type="submit"
                    color="primary"
                    outline
                    size="lg"
                    disabled={isSubmitting}
                  >
                    <IntlMessages id="forms.action.create" />
                  </Button>
                </div>
              </Row>
            </ModalBody>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default function CreditCardTab({ editUser }) {
  return (
    <div>
      <ModalBody className="mt-0 pt-1">
        <CreditCardList editUser={editUser} />

        <CreditCardForm editUser={editUser} />
      </ModalBody>
    </div>
  );
}
