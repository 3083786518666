import React from 'react';
import SeatMap from '../../SeatMap';

export default function SeatMapTab({
  seats,
  loading,
  selectedSeat,
  onSelectSeat,
}) {
  return (
    <section className="seat-map-tab flex-grow-1 flex-center">
      <SeatMap
        seats={seats}
        loading={loading}
        selectedSeat={selectedSeat}
        onSelectSeat={onSelectSeat}
      />
    </section>
  );
}
