import { DEFAULT_NATIONALITY } from '../constants/countries';

export function getUserNationality({
  user = {},
  organization = {},
  defaultNationality = DEFAULT_NATIONALITY,
}) {
  return user.nationality || organization.nationality || defaultNationality;
}

export function isUserNational(user, organization) {
  return getUserNationality({ user, organization }) === DEFAULT_NATIONALITY;
}
