import { useState } from 'react';

export default function useBlocker(interval = 950) {
  const [lastRun, setLastRun] = useState(10);

  const run = (callback = () => {}) => {
    const currentTime = new Date().getTime();

    if (currentTime - lastRun >= interval) {
      callback();
      setLastRun(currentTime);
    }
  };

  const update = () => setLastRun(new Date().getTime());

  return [run, update];
}
