import React from 'react';
import { Card, CardBody } from 'reactstrap';
import moment from 'moment';
import countBy from 'lodash/countBy';
import { useIntl } from 'react-intl';

import { SmallLineChart } from '../../components/charts';
import LoaderSmallLine from '../../components/loaders/LoaderSmallLine';

export default function SmallLineBookings({ orders, loading }) {
  const { messages } = useIntl();

  const results = countBy(orders, (o) =>
    moment.unix(o.filterTimestamp).format('DD/MM/YYYY')
  );

  if (loading) {
    return (
      <Card className="dashboard-small-chart">
        <CardBody>
          <LoaderSmallLine />
        </CardBody>
      </Card>
    );
  }

  return (
    <Card className="dashboard-small-chart">
      <CardBody>
        <SmallLineChart
          data={{
            labels: Object.keys(results),
            datasets: [
              {
                data: Object.values(results),
                label:
                  messages[
                    'containers.dashboard.small-line-bookings.reservations'
                  ],
                borderColor: '#43418e',
                pointBorderColor: '#43418e',
                pointHoverBackgroundColor: '#43418e',
                pointHoverBorderColor: '#43418e',
                pointRadius: 2,
                pointBorderWidth: 3,
                pointHoverRadius: 2,
                fill: false,
                borderWidth: 2,
                datalabels: {
                  align: 'end',
                  anchor: 'end',
                },
              },
            ],
          }}
        />
      </CardBody>
    </Card>
  );
}
