import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import axios from 'axios';
import classnames from 'classnames';
import map from 'lodash/map';

import { components } from 'react-select';
import AsyncSelect from 'react-select/async';

import User from '../../services/User';
import { locationTypes } from '../../constants/travelValues';

const TYPES = {
  airport: 1,
};

const ALLOWED_LOCATIONS = ['city', 'airport'];

const Option = (props) => {
  const locationClass = locationTypes[props.data.type] || locationTypes.city;

  return (
    <div
      className={classnames({
        'react-select__menu_option': true,
        active: props.isFocused,
      })}
    >
      <div className={`option-icon ${locationClass.style}`} />
      <components.Option {...props} />
    </div>
  );
};

class CityLocationSearch extends Component {
  // Search Locations
  findLocation = (inputValue) => {
    return new Promise(async (resolve, fail) => {
      try {
        const token = await User.getToken();

        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}/hotel/locations/autocomplete`,
          {
            params: { search: inputValue, removeHotels: true },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (res.data.size > 0) {
          const filteredData = res.data.data
            .filter(
              (item) => item.type && ALLOWED_LOCATIONS.includes(item.type)
            )
            .sort((a, b) => {
              return TYPES[a.type] || 0 - TYPES[b.type] || 0;
            });

          const results = map(
            filteredData.length ? filteredData : res.data.data,
            (hit) => {
              return {
                value: hit.destinationId,
                cityCode: hit.destinationId,
                label: hit.name['pt-BR'],
                countryName: hit.countryId,
                type: hit.type,
              };
            }
          );

          resolve(results);
        }

        resolve([]);
      } catch (err) {
        console.error(err);
        resolve([]);
      }
    });
  };

  // Render
  render() {
    const {
      intl: { messages },
      value,
      setValue,
      readOnly = false,
    } = this.props;

    return (
      <AsyncSelect
        className="react-select"
        classNamePrefix="react-select"
        components={{ Option }}
        isClearable={false}
        value={value}
        cacheOptions
        placeholder=""
        noOptionsMessage={() => {
          return messages['travel.location-message'];
        }}
        loadingMessage={() => {
          return messages['travel.location-searching'];
        }}
        onChange={(selects) => {
          setValue(selects);
        }}
        loadOptions={this.findLocation}
        isDisabled={readOnly}
      />
    );
  }
}

export default injectIntl(CityLocationSearch);
