export const isItemsEqual = (originalItems, newItems) => {
  const _newItems = newItems.filter(({ name }) => name);

  if (originalItems.length === _newItems.length) {
    const getKey = ({ name, quantity, price }) =>
      `${name}-${quantity}-${price}`;

    const hashedItems = originalItems.reduce((obj, item) => {
      if (item.name) {
        obj[getKey(item)] = true;
      }
      return obj;
    }, {});

    return _newItems.every((item) => hashedItems[getKey(item)]);
  }

  return false;
};
