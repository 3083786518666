import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Button } from 'reactstrap';
import AlterationFrame from '../../AlterationFrame';
import orderStatus from '../../../../../constants/orderStatus';
import useExecuteAction from '../../../../../hooks/useExecuteAction';
import firebaseApp from '../../../../../services/Firebase';

import { ReactComponent as AlterDataIcon } from '../../../../../assets/img/icons/seat.svg';
import { ReactComponent as WalletIcon } from '../../../../../assets/img/icons/wallet.svg';
import { BUS_ALTERATION_TABS } from '..';
import OptionCard from '../../FlightAlteration/OptionCard';

export default function BusAlterationMenu({
  order,
  goForAlteration,
  setCurrentTab,
  alterableReservations = [],
  reservations = [],
}) {
  const { messages } = useIntl();

  const { executeAction, loading } = useExecuteAction();

  // States
  const canCancel = useMemo(
    () => alterableReservations.length === reservations.length,
    [alterableReservations, reservations]
  );

  // Functions
  const cancelReservation = () => {
    executeAction(
      (async () => {
        await firebaseApp.requestToCancel(order.id);
        goForAlteration();
      })()
    );
  };

  // Render
  const renderBottom = () => {
    return (
      <div className="d-flex align-items-center justify-content-end">
        <Button color="link" onClick={goForAlteration} className="m-0">
          {messages['general.go-back']}
        </Button>
      </div>
    );
  };

  if (order.statusCode !== orderStatus.ISSUED) goForAlteration();

  return (
    <AlterationFrame
      title={messages['travel.alter.title']}
      subtitle={messages['travel.alter.subtitle']}
      BottomBar={renderBottom}
    >
      <div className="card-menu-display">
        <OptionCard
          Icon={AlterDataIcon}
          title={messages['travel.alter.bus.seats.title']}
          description={messages['travel.alter.bus.seats.description']}
          buttonText={messages['general.select']}
          onClick={() => setCurrentTab(BUS_ALTERATION_TABS.SEAT)}
          className="show-up"
          style={{ animationDelay: '250ms' }}
          disabled={loading}
        />

        {canCancel ? (
          <OptionCard
            Icon={WalletIcon}
            title={messages['travel.alter.bus.cancellation.title']}
            description={messages['travel.alter.bus.cancellation.description']}
            buttonText={messages['general.cancel']}
            onClick={cancelReservation}
            className="show-up"
            style={{ animationDelay: '350ms' }}
            disabled={loading}
          />
        ) : null}
      </div>
    </AlterationFrame>
  );
}
