import md5 from 'md5';
import { RefundTypes, RefundTypesPriority } from '../constants/refundTypes';
const { RefundStatus } = require('../constants/refundStatus');
const { getOrganizationConfig } = require('../utils/organization');
const { default: firebaseApp } = require('../services/Firebase');

const hasApprover = async (expenseRef, userId) => {
  const approvalSnap = await expenseRef
    .collection('approvals')
    .where('userId', '==', userId)
    .get();

  return approvalSnap.size > 0;
};

export const getApprovalsFromApprover = async (organizationId, userId) => {
  const expenseSnap = await firebaseApp
    .getExpensesWithStatusCode(organizationId, RefundStatus.APPROVING)
    .get();

  const approvals = [];

  expenseSnap.forEach((doc) => {
    hasApprover(doc.ref, userId).then((isApprover) => {
      if (isApprover) approvals.push(doc.data());
    });
  });

  return approvals;
};

export const isUserRefundApprover = async (organizationId, userId) => {
  const policiesRef = firebaseApp.getPoliciesFromOrganization();
};

export const canCreateRefund = (organization) =>
  getOrganizationConfig(organization.config, 'refunds');

export const formatExpenseItems = (items) => {
  return items.map(({ name, price, quantity }) => ({
    name,
    price: parseFloat(price),
    quantity,
  }));
};

export const formatExpenseRoute = (route) => {
  return {
    ...route,
    stops: route.stops.map(({ lat, lng, address }) => ({ lat, lng, address })),
  };
};

export const getRandomString = (numLetters = 10) => {
  return Math.random()
    .toString(36)
    .slice(2, 2 + numLetters);
};

export const createRouteStop = (stop = {}) => {
  return {
    ...stop,
    id: stop.address ? md5(stop.address) : getRandomString(),
  };
};

export const fetchIcon = async (icon = {}) => {
  const { default: Icon } = await import(
    `!@svgr/webpack!../assets/img/companies/${icon.value || icon}.svg`
  );
  return Icon;
};

export const sortExpenseCards = (cardA, cardB) => {
  const cardAPriority = RefundTypesPriority[cardA.type],
    cardBPriority = RefundTypesPriority[cardB.type];

  if (cardAPriority !== cardBPriority) return cardAPriority - cardBPriority;

  if (cardA.type === RefundTypes.PHYSICAL_CARD)
    return `${cardA.lastDigits}`.localeCompare(`${cardB.lastDigits}`);

  if (cardA.type === RefundTypes.VIRTUAL_CARD)
    return `${cardA.name}`.localeCompare(`${cardB.name}`);

  return cardA.created_at - cardB.created_at;
};
