import React from 'react';
import { Card } from 'reactstrap';
import { Colxx } from '../common/CustomBootstrap';

import ContentLoader from 'react-content-loader';

export default function LoaderCardWithText() {
  return (
    <Colxx xxs="12" className="mb-3 mx-0 px-0">
      <Card className="d-flex flex-row list">
        <div className="pl-2 d-flex flex-grow-1 mw-0">
          <div className="card-body align-self-center d-flex flex-column flex-lg-row justify-content-between mw-0 align-items-lg-center">
            <ContentLoader
              speed={2}
              width={300}
              height={20}
              viewBox="0 0 300 20"
              backgroundColor="#f3f3f3"
              foregroundColor="#ecebeb"
            >
              <rect x="0" y="0" rx="3" ry="3" width="300" height="20" />
            </ContentLoader>
          </div>
          <div className="custom-control custom-checkbox pl-1 align-self-center pr-4">
            <ContentLoader
              speed={2}
              width={50}
              height={80}
              viewBox="0 0 50 80"
              backgroundColor="#f3f3f3"
              foregroundColor="#ecebeb"
            >
              <rect x="15" y="30" rx="3" ry="3" width="20" height="20" />
            </ContentLoader>
          </div>
        </div>
      </Card>
    </Colxx>
  );
}
