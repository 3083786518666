import React, { useMemo } from 'react';
import moment from 'moment';
import { useIntl } from 'react-intl';
import AlertCard from './AlertCard';

import { imageUrl } from '../../utils/image';
import { registeredAirlines } from '../../constants/travelValues';
import OpenBookingProviderBadge from '../common/OpenBookingProviderBadge';

export default function OpenBookingFlightCard({
  reservation,
  locations,
  order,
  openFlight,
}) {
  const { messages } = useIntl();

  // States
  const airlineData = useMemo(
    () => registeredAirlines[reservation.flightDetails.airline],
    [reservation]
  );

  return (
    <>
      <div className="item" data-testid={order.id}>
        <div className="date">
          <div className="content">
            <span className="day">
              {moment(
                reservation.flightDetails.departureDateTime,
                'DD/MM/YYYY HH:mm'
              ).format('DD')}
            </span>
            <span className="month">
              {moment(
                reservation.flightDetails.departureDateTime,
                'DD/MM/YYYY HH:mm'
              ).format('MMM')}
            </span>
          </div>
        </div>
        <div
          className="details"
          onClick={() => {
            openFlight(reservation);
          }}
        >
          <div
            className="details-content"
            style={{
              backgroundImage: `url(${imageUrl(
                reservation.type,
                reservation.flightDetails.arrivalStation.iataCode,
                locations
              )})`,
            }}
          >
            <div className="gradient">
              <div className="header">
                <div className="d-flex align-items-center justify-content-between m-0">
                  <div className="m-0">
                    {airlineData && (
                      <img src={airlineData.logoBlack} alt={airlineData.name} />
                    )}
                  </div>

                  <OpenBookingProviderBadge
                    provider={order.provider.associate}
                  />
                </div>
              </div>

              <div className="route clearfix">
                <div className="airport">
                  <span>{reservation.flightDetails.departureStation.name}</span>
                  {reservation.flightDetails.departureStation.iataCode}
                </div>
                <div className="to text-center">
                  <img
                    src="/assets/img/icons/timeline_flight_white.svg"
                    alt={messages['alts.components.timeline.flight-card']}
                  />
                </div>
                <div className="airport text-right">
                  <span>{reservation.flightDetails.arrivalStation.name}</span>
                  {reservation.flightDetails.arrivalStation.iataCode}
                </div>
              </div>
            </div>
          </div>

          <AlertCard order={order} />
        </div>
      </div>
    </>
  );
}
