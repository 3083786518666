import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import map from 'lodash/map';
import approvalStatus from '../../constants/approvalStatus';

export default function ApprovalTimeline({
  approvals,
  showOnlyApproved = false,
}) {
  const { messages } = useIntl();

  const [filteredApprovals, groups] = useMemo(() => {
    let filteredApprovals = [],
      groups = [];

    approvals
      .sort((a, b) => {
        if (a.hierarchy !== b.hierarchy) return a.hierarchy - b.hierarchy;

        return a.status === approvalStatus.APPROVED ? -1 : 0;
      })
      .forEach((approval) => {
        if (approval.groupName && groups.includes(approval.groupId)) {
          return;
        }

        filteredApprovals.push(approval);
        groups.push(approval.groupId);
      });

    if (showOnlyApproved) {
      filteredApprovals = filteredApprovals.filter(
        (approval) => approval.status === approvalStatus.APPROVED
      );
    }

    return [filteredApprovals, groups];
  }, [approvals]);

  return (
    <>
      {map(filteredApprovals, (approval, index) => {
        return (
          <li
            key={`approval-${index}`}
            className={approval.status === 'APPROVED' ? 'done' : 'active'}
          >
            {approval.groupName
              ? approval.groupName
              : approval.name
              ? approval.name.split(' ').shift()
              : messages['travel.order.status.approval']}
          </li>
        );
      })}
    </>
  );
}
