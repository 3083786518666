import {
  ParamTypes,
  TContains,
  TContainsValue,
  TParamConfig,
  TParamTypes,
  TParams,
  TRange,
  TRangeMap,
  TRangeMapValue,
  TRangeValue,
  TSetParamsValue,
  TString,
  TStringValue,
} from './types';
import { TObject } from 'src/portao3-legacy/types/others';

// Default value
export function getStringDefaultValue(defaultConfig?: any): TString {
  return '';
}

export function getRangeDefaultValue(defaultConfig?: any): TRange {
  return {
    min: 0,
    max: null,
  };
}

export function getRangeMapDefaultValue(defaultConfig?: any): TRangeMap {
  return {};
}

export function getContainsDefaultValue(defaultConfig?: any): TContains {
  return {};
}

const GetDefaultValueFunctions = {
  [ParamTypes.STRING]: getStringDefaultValue,
  [ParamTypes.RANGE]: getRangeDefaultValue,
  [ParamTypes.RANGE_MAP]: getRangeMapDefaultValue,
  [ParamTypes.CONTAINS]: getContainsDefaultValue,
};

export function setupParams(params: TParamConfig[], defaultConfig: any) {
  return params.reduce((obj, param) => {
    try {
      const getDefaultValue =
        param.getDefaultValue || GetDefaultValueFunctions[param.type];

      if (getDefaultValue) obj[param.name] = getDefaultValue(defaultConfig);
    } catch (err) {
      console.error('Unable to setup param', param, err);
    } finally {
      return obj;
    }
  }, {} as TParams);
}

// Types
export function getParamsTypes(params: TParamConfig[]) {
  return params.reduce((obj, { name, type }) => {
    if (type in ParamTypes) obj[name] = type;
    return obj;
  }, {} as TObject<ParamTypes>);
}

export function paramsToObject(params: TParamConfig[]) {
  return params.reduce((obj, param) => {
    obj[param.name] = param;
    return obj;
  }, {} as TObject<TParamConfig>);
}

// Format
export function formatStringValue(value: TStringValue): TString {
  return value;
}

export function formatRangeValue(value: TRangeValue): TRange {
  return {
    min: value[0],
    max: value[1],
  };
}

export function formatRangeMapValue(value: TRangeMapValue): TRangeMap {
  const [min, max] = value.value;

  return {
    [value.name]: {
      min,
      max,
    },
  };
}

export function formatContainsValue(
  { name, value, invertValue }: TContainsValue,
  oldParams: TContains
): TContains {
  const finalValue = invertValue ? !oldParams[name] : value;

  return {
    [name]: finalValue,
  };
}

const FormatFunctions: any = {
  [ParamTypes.STRING]: formatStringValue,
  [ParamTypes.RANGE]: formatRangeValue,
  [ParamTypes.RANGE_MAP]: formatRangeMapValue,
  [ParamTypes.CONTAINS]: formatContainsValue,
};

export function formatValue(
  type: ParamTypes,
  value: TSetParamsValue,
  oldParams: TParamTypes
) {
  return FormatFunctions[type](value, oldParams);
}

// Merge
export function mergeContainsValue(
  value: TContains,
  params: TContains
): TContains {
  return {
    ...params,
    ...value,
  };
}

const MergeFunctions: any = {
  [ParamTypes.RANGE_MAP]: mergeContainsValue,
  [ParamTypes.CONTAINS]: mergeContainsValue,
  default: (data: any, _: any) => data,
};

// Handlers
export function handleSetParam(
  type: ParamTypes,
  value: TSetParamsValue,
  oldParam: TParamTypes
): TRange | TContains | TRangeMap | TString {
  const mergeFunc = MergeFunctions[type] || MergeFunctions.default;
  return mergeFunc(formatValue(type, value, oldParam), oldParam);
}
