import React from 'react';
import ContentLoader from 'react-content-loader';

import LoaderAnalytics from '../../components/loaders/LoaderAnalytics';

export default function TypeAnalyticsLoading() {
  return (
    <div className="w-100 d-flex flex-row justify-content-left">
      <div className="w-50 mr-3">
        <LoaderAnalytics />
        <LoaderAnalytics />
        <LoaderAnalytics />
      </div>
      <ContentLoader
        speed={2}
        width={400}
        height={200}
        viewBox="0 0 400 200"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
      >
        <rect x="0" y="0" rx="3" ry="3" width="400" height="200" />
      </ContentLoader>
    </div>
  );
}
