import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { canMakeReservation, getRequiredFields } from '../utils/reservation';
import { getOrganizationConfig } from '../utils/organization';

export default function useIsReservationValid({
  orderStructure,
  reservationParams,
}) {
  const { organization = {} } = useSelector(({ auth }) => auth);
  const { messages } = useIntl();

  const requiredFields = useMemo(() => {
    const required = getOrganizationConfig(organization.config, 'required');
    const observations = getOrganizationConfig(
      organization.config,
      'observations'
    );

    // Prevent cases where observations are not allowed, but required
    return getRequiredFields(required, observations ? [] : ['observation']);
  }, [organization]);

  const [isValid, disabled, title] = useMemo(() => {
    const isValid = canMakeReservation({
      orderStructure,
      reservationParams,
      requiredFields,
      organization,
    });

    const disabled = !(orderStructure && isValid);
    const title = disabled ? messages['reservation.invalid-fields'] : '';

    return [isValid, disabled, title];
  }, [orderStructure, reservationParams, requiredFields]);

  return {
    isValid,
    disabled,
    title,
  };
}
