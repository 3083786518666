import React, { useState, useRef } from 'react';
import { Popover, PopoverBody } from 'reactstrap';
import uniqueId from 'lodash/uniqueId';
import map from 'lodash/map';

import { triggerFilterChangeSegmentDebounce } from '../../utils/segment';

export default function TicketDetails({ order, reservations = [] }) {
  const [showInfo, setShowInfo] = useState(false);
  const idRef = useRef(uniqueId('ticketDetail-'));

  return (
    <>
      <div
        id={idRef.current}
        className="journey-ticket"
        onMouseOver={() => {
          triggerFilterChangeSegmentDebounce('Hover over ticket component');
          setShowInfo(true);
        }}
        onMouseOut={() => setShowInfo(false)}
      >
        <div className="glyph-icon simple-icon-question" />
      </div>
      <Popover
        placement="bottom"
        isOpen={showInfo}
        target={idRef.current}
        popperClassName="ticket-info"
      >
        <PopoverBody className="journey-ticket-detail">
          {order.bookingCode && (
            <div>
              <span>Localizador:</span> {order.bookingCode} <br />
            </div>
          )}
          {order.bookingTicket && (
            <div>
              <span>Bilhete:</span> {order.bookingTicket} <br />
            </div>
          )}

          {map(reservations, (reservation, index) => {
            if (reservation?.busDetails?.bookingCode) {
              return (
                <div className="mt-2" key={index}>
                  <strong>Reserva {index + 1}</strong>
                  {reservation.busDetails.bookingCode && (
                    <div>
                      <span>Localizador:</span>{' '}
                      {reservation.busDetails.bookingCode} <br />
                    </div>
                  )}
                  {reservation.busDetails.bookingTicket && (
                    <div>
                      <span>Bilhete:</span>{' '}
                      {reservation.busDetails.bookingTicket} <br />
                    </div>
                  )}
                </div>
              );
            }
          })}
        </PopoverBody>
      </Popover>
    </>
  );
}
