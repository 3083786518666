import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { OrderTypes } from '../../../constants/orderTypes';
import { stringToMoney } from '../../../utils/money';

import { ReactComponent as OneWayIcon } from '../../../assets/img/icons/oneway.svg';
import { ReactComponent as RoundTripIcon } from '../../../assets/img/icons/roundtrip.svg';
import { getStationAbbreviation } from '../../alternatives/utils';

import { ReactComponent as FlightIcon } from '../../../assets/img/icons/raw/plane.svg';
import { ReactComponent as HotelIcon } from '../../../assets/img/icons/raw/hotel.svg';
import { ReactComponent as CarIcon } from '../../../assets/img/icons/raw/car.svg';
import { ReactComponent as OfficeIcon } from '../../../assets/img/icons/raw/office.svg';
import { ReactComponent as BusIcon } from '../../../assets/img/icons/raw/bus.svg';
import { ReactComponent as MoneyIcon } from '../../../assets/img/expenses/money.svg';
import { useHistory } from 'react-router';
import { useIntl } from 'react-intl';
import { RefundStatus } from '../../../constants/refundStatus';

const getFormatedDate = (
  date,
  inputFormat = 'YYYY-MM-DD',
  outputFormat = 'DD/MM'
) => {
  return moment(date, inputFormat).format(outputFormat);
};

function GeneralOrder({
  title,
  TitleComponent,
  subtitle,
  total,
  orderId,
  Icon,
  className,
  historyPath = '/travel/orders',
  ...props
}) {
  const history = useHistory();

  const handleClick = () => {
    history.push(`${historyPath}/${orderId}`);
  };

  return (
    <div
      className={`general-order-card  d-flex pointer ${className}`}
      onClick={handleClick}
      {...props}
    >
      <Icon width={15} height={15} className="mt-1 mr-3 text-primary" />
      <div className="d-flex justify-content-between flex-grow-1">
        <div>
          {TitleComponent ? (
            <TitleComponent />
          ) : (
            <h3 className="title">{title}</h3>
          )}
          <p className="subtitle m-0 text-muted">{subtitle}</p>
        </div>
        <p className="total ml-3">{total}</p>
      </div>
    </div>
  );
}

function FlightOrder({ order }) {
  const { departureStation, arrivalStation, JourneyIcon, subtitle, total } =
    useMemo(() => {
      const { journeys } = order;
      const twoWay = journeys.length > 1;
      const { departureStation, arrivalStation } = journeys[0];

      const outboundDate = getFormatedDate(journeys[0].departureDate);

      return {
        departureStation: departureStation.iataCode,
        arrivalStation: arrivalStation.iataCode,
        subtitle: twoWay
          ? `${outboundDate} - ${getFormatedDate(journeys[1].departureDate)}`
          : outboundDate,
        total: stringToMoney(order.fare.total, 0, order.fare.currency),
        JourneyIcon: twoWay ? RoundTripIcon : OneWayIcon,
      };
    }, [order]);

  return (
    <GeneralOrder
      TitleComponent={() => (
        <h3 className="d-flex align-items-center title">
          {departureStation}
          <JourneyIcon width={12} height={12} className="mx-1" />
          {arrivalStation}
        </h3>
      )}
      subtitle={subtitle}
      total={total}
      orderId={order.id}
      Icon={FlightIcon}
    />
  );
}

function BusOrder({ order }) {
  const { departureStation, arrivalStation, JourneyIcon, subtitle, total } =
    useMemo(() => {
      const { journeys } = order;
      const twoWay = journeys.length > 1;
      const { departureStation, arrivalStation } = journeys[0];

      const outboundDate = getFormatedDate(
        journeys[0].departureDate,
        'YYYY-MM-DD'
      );

      return {
        departureStation: getStationAbbreviation(departureStation),
        arrivalStation: getStationAbbreviation(arrivalStation),
        subtitle: twoWay
          ? `${outboundDate} - ${getFormatedDate(
              journeys[1].departureDate,
              'YYYY-MM-DD'
            )}`
          : outboundDate,
        total: stringToMoney(order.fare.total, 0, order.fare.currency),
        JourneyIcon: twoWay ? RoundTripIcon : OneWayIcon,
      };
    }, [order]);

  return (
    <GeneralOrder
      TitleComponent={() => (
        <h3 className="d-flex align-items-center font-primary title">
          {departureStation}
          <JourneyIcon width={10} height={10} className="mx-1" />
          {arrivalStation}
        </h3>
      )}
      subtitle={subtitle}
      total={total}
      orderId={order.id}
      Icon={BusIcon}
    />
  );
}

function HotelOrder({ order }) {
  const { title, subtitle, total } = useMemo(() => {
    const hotelDetails = order.reservations[0].hotelDetails;

    return {
      title: hotelDetails.hotel.name,
      subtitle: `${getFormatedDate(
        hotelDetails.checkIn,
        'DD/MM/YYYY'
      )} - ${getFormatedDate(hotelDetails.checkOut, 'DD/MM/YYYY')}`,
      total: stringToMoney(order.fare.total, 0, order.fare.currency),
    };
  }, [order]);

  return (
    <GeneralOrder
      title={title}
      subtitle={subtitle}
      total={total}
      orderId={order.id}
      Icon={HotelIcon}
    />
  );
}

function OfficeOrder({ order }) {
  const { title, subtitle, total } = useMemo(() => {
    const officeDetails = order.reservations[0].officeDetails;

    return {
      title: officeDetails.office.name,
      subtitle: getFormatedDate(officeDetails.date, 'DD/MM/YYYY'),
      total: stringToMoney(order.fare.total, 0, order.fare.currency),
    };
  }, [order]);

  return (
    <GeneralOrder
      title={title}
      subtitle={subtitle}
      total={total}
      orderId={order.id}
      Icon={OfficeIcon}
    />
  );
}

function CarOrder({ order }) {
  const { title, subtitle, total } = useMemo(() => {
    const carDetails = order.reservations[0].carDetails;

    return {
      title: carDetails.name,
      subtitle: `${getFormatedDate(
        carDetails.pickUp,
        'DD/MM/YYYY HH:mm'
      )} - ${getFormatedDate(carDetails.dropOff, 'DD/MM/YYYY HH:mm')}`,
      total: stringToMoney(order.fare.total, 0, order.fare.currency),
    };
  }, [order]);

  return (
    <GeneralOrder
      title={title}
      subtitle={subtitle}
      total={total}
      orderId={order.id}
      Icon={CarIcon}
    />
  );
}

const TravelOrderComponents = {
  [OrderTypes.FLIGHT]: FlightOrder,
  [OrderTypes.HOTEL]: HotelOrder,
  [OrderTypes.CAR]: CarOrder,
  [OrderTypes.BUS]: BusOrder,
  [OrderTypes.OFFICE]: OfficeOrder,
};

function TravelApprovalOrder({ order, className = '' }) {
  const Component = useMemo(() => TravelOrderComponents[order.type], [order]);

  if (Component) return <Component order={order} />;
  else return null;
}

function BudgetApprovalOrder({ order, className = '' }) {
  const [total, date] = useMemo(() => {
    return [
      stringToMoney(
        order.statusCode === RefundStatus.APPROVING_ANTICIPATION
          ? order.budget || order.fare.total || 0
          : order.fare.total,
        2,
        order.fare.currency
      ),
      `${getFormatedDate(order.startDate)} - ${getFormatedDate(order.endDate)}`,
    ];
  }, [order]);

  return (
    <GeneralOrder
      title={order.name}
      subtitle={date}
      total={total}
      orderId={order.id}
      Icon={MoneyIcon}
      className={className}
      historyPath={'/payments/cards'}
    />
  );
}

const OrderComponents = {
  travel: TravelApprovalOrder,
  expenses: BudgetApprovalOrder,
};

function ApprovalOrder({ order, className = '' }) {
  const Component = useMemo(() => OrderComponents[order.approvalType], [order]);

  if (Component) return <Component order={order} className={className} />;
  else return null;
}

export default function ApprovalOrders({
  orders: ordersObj,
  className = '',
  style = {},
  showAtMost = 3,
}) {
  const { messages, formatMessage } = useIntl();

  const headerRef = useRef(null);

  const {
    name,
    numOrders,
    orders,
    mainPhoto,
    message = '',
    canCollapse,
  } = useMemo(() => {
    const { passenger, orders } = ordersObj;
    const numOrders = orders.length;

    return {
      name: `${passenger.firstName} ${passenger.lastName}`,
      mainPhoto: passenger.avatar || '/assets/img/profile-pic-l.png',
      numOrders,
      canCollapse: numOrders > showAtMost,
      orders,
      message: formatMessage(
        {
          id:
            numOrders > 1
              ? 'approval-orders.multiple'
              : 'approval-orders.single',
        },
        { numOrders }
      ),
    };
  }, [ordersObj, showAtMost]);

  const [collapse, setCollapse] = useState(numOrders > showAtMost);
  const [wasCollapsed, setWasCollapsed] = useState(false);

  const [selectedOrders, lastOrderIndex] = useMemo(
    () =>
      collapse
        ? [orders.slice(0, showAtMost), showAtMost - 1]
        : [orders, numOrders - 1],
    [collapse, orders, showAtMost]
  );

  useEffect(() => {
    if (wasCollapsed && headerRef.current && collapse) {
      headerRef.current.scrollIntoView({ inline: 'nearest', block: 'nearest' });
    }
  }, [wasCollapsed, collapse]);

  return (
    <div
      className={`approval-orders ${className}`}
      style={style}
      ref={headerRef}
    >
      <div className="d-flex align-items-center approval-header pb-3">
        <img src={mainPhoto} alt={name} className="passenger-photo" />
        <div>
          <h2 className="font-primary passenger-name m-0 text-dark">{name}</h2>
          <p className="m-0 medium mt-1 text-muted subtitle">{message}</p>
        </div>
      </div>
      <div className="px-2 mt-2">
        <div>
          {selectedOrders.map((order, index) => (
            <div
              key={`order-${order.approvalType}-${order.id}-${
                collapse ? 'c' : ''
              }`}
            >
              <ApprovalOrder order={order} />
              {index < lastOrderIndex ? <hr style={{ opacity: 0.4 }} /> : null}
            </div>
          ))}
        </div>
        {canCollapse ? (
          <p
            className="text-center text-primary font-weight-medium pointer mt-3"
            onClick={() => {
              setCollapse((state) => !state);
              setWasCollapsed(true);
            }}
          >
            {
              messages[
                collapse ? 'approval-orders.show' : 'approval-orders.hide'
              ]
            }
          </p>
        ) : null}
      </div>
    </div>
  );
}
