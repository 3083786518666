import { useState } from 'react';
import { getFareOptionsFromSegments } from '../../utils/search';
import { ReactComponent as FareIcon } from '../../assets/img/icons/fare-ticket.svg';
import { ReactComponent as CheckIcon } from '../../assets/img/icons/check-fare.svg';
import { ReactComponent as ChargebackIcon } from '../../assets/img/icons/chargeback-fare.svg';
import { Button } from 'reactstrap';
import { useIntl } from 'react-intl';
import { FLIGHT_FARE_RULES } from '../../constants/fareRulesByCarrier';

const iconColors = ['#3D3B81', '#087C3E', '#EEBC40'];
const iconBackgroundColors = ['#ECECF4', '#E6F2EC', '#FDF8EC'];

export default function FareCard({ segments, updateFare, item }) {
  const fareOptions = getFareOptionsFromSegments(segments, item);
  const { messages } = useIntl();

  const [selectedFare, setSelectedFare] = useState(fareOptions[0]);

  const updateFareHandler = (fareOption) => {
    setSelectedFare(fareOption);
    updateFare(fareOption);
  };

  return fareOptions.map((fareOption, index) => {
    const fareRules =
      FLIGHT_FARE_RULES?.[fareOption.airline]?.[fareOption.name];
    return (
      <div
        className="d-flex"
        style={{
          border:
            fareOption.name === selectedFare.name ? '1.5px solid' : '1px solid',
          borderColor:
            fareOption.name === selectedFare.name ? '#a9a8cb' : '#DFDFE1',
          borderRadius: '4px',
          width: '100%',
          maxWidth: '255px',
          height: '502px',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <div
            style={{
              background: '#F5F5F6',
              height: '48px',
              alignItems: 'center',
            }}
            className="d-flex"
          >
            <div
              className="d-flex"
              style={{
                marginLeft: '8px',
                marginRight: '8px',
                background: `${iconBackgroundColors[index]}`,
                width: '32px',
                height: '32px',
                alignSelf: 'center',
                alignItems: 'center',
                borderRadius: '4px',
              }}
            >
              <FareIcon
                style={{ with: '24px', height: '24px', marginLeft: '4px' }}
                fill={`${iconColors[index]}`}
              />
            </div>

            <p
              style={{
                marginTop: '11px',
                fontFamily: 'montserrat',
                fontWeight: 500,
                fontSize: '16px',
                color: `${iconColors[index]}`,
              }}
            >
              {fareOption.name}
            </p>

            <div
              className="d-flex"
              style={{
                width: '63px',
                height: '19px',
                borderRadius: '16px',
                background: '#99999E',
                marginLeft: 'auto',
                marginRight: '16px',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <p
                style={{
                  fontFamily: 'montserrat',
                  fontWeight: 500,
                  fontSize: '9px',
                  color: '#FFFFFF',
                  marginBottom: '0px',
                  textAlign: 'center',
                }}
              >
                {segments.length > 1
                  ? messages['flight.select-fare-card-out-return']
                  : messages['flight.select-fare-card-out']}
              </p>
            </div>
          </div>
          <div>
            <div style={{ margin: '16px' }}>
              {fareRules?.descriptions?.map((description) => (
                <div
                  className="d-flex"
                  style={{ alignItems: 'flex-start', marginBottom: '8px' }}
                >
                  {description ? (
                    <CheckIcon
                      style={{
                        marginTop: '2px',
                        height: '16px',
                        with: '16px',
                        flexShrink: 0,
                      }}
                    />
                  ) : null}
                  <p
                    style={{
                      fontFamily: 'montserrat',
                      fontWeight: 500,
                      fontSize: '14px',
                      color: '#5F5F67',
                      marginBottom: '0px',
                      marginLeft: '8px',
                    }}
                  >
                    {description ? description : ''}
                  </p>
                </div>
              ))}
            </div>
            <div
              style={{
                width: '221px',
                height: '1px',
                background: '#DFDFE1',
                border: '1px',
                marginLeft: '16px',
              }}
            ></div>
            {fareRules?.rules?.map((rule) => {
              return (
                <div
                  className="d-flex"
                  style={{
                    alignContent: 'start',
                    paddingLeft: '16px',
                    alignItems: 'flex-start',
                    marginTop: '4px',
                  }}
                >
                  {rule ? (
                    <ChargebackIcon
                      style={{
                        width: '16px',
                        height: '16px',
                        flexShrink: 0,
                        marginTop: '2px',
                      }}
                    />
                  ) : null}
                  <p
                    style={{
                      fontFamily: 'montserrat',
                      fontWeight: 500,
                      fontSize: '14px',
                      color: '#5F5F67',
                      paddingLeft: '8px',
                      marginBottom: '2px',
                      paddingRight: '10px',
                      maxWidth: '215px',
                    }}
                  >
                    {rule ? rule : ''}
                  </p>
                </div>
              );
            })}
          </div>
        </div>

        <div>
          <div
            className="d-flex"
            style={{
              justifyItems: 'end',
              alignItems: 'end',
              marginTop: 'auto',
            }}
          >
            <div
              style={{
                marginLeft: '16px',
                marginRight: '16px',
              }}
            >
              <p
                style={{
                  marginBottom: '0px',
                  fontFamily: 'montserrat',
                  fontWeight: 500,
                  fontSize: '12px',
                  color: '#99999E',
                }}
              >
                {messages['flight.select-fare-card-total']}
              </p>
              <p
                style={{
                  fontFamily: 'montserrat',
                  fontWeight: 500,
                  fontSize: '16px',
                  color: '#44444D',
                  marginBottom: '12px',
                }}
              >{`R$ ${fareOption.total.toFixed(2)}`}</p>
            </div>
          </div>
          <div
            className="d-flex"
            style={{
              height: '56px',
              borderRadius: '4px, 4px, 0px, 0px',
              alignSelf: 'end',
              background: '#FAFAFA',
              borderColor: '#F5F5F6',
              alignItems: 'center',
            }}
          >
            <button
              onClick={() => {
                fareOptions.length > 1 ? updateFareHandler(fareOption) : null;
              }}
              style={{
                width: '159px',
                height: '32px',
                borderRadius: '5px',
                border: 'none',
                background: '#43418E',
                fontWeight: 500,
                color: '#FBFBFC',
                fontSize: '12px',
                fontFamily: 'montserrat',
                marginLeft: '16px',
                cursor: 'pointer',
              }}
            >
              {fareOption.total === selectedFare.total
                ? messages['flight.select-fare-card-btn-continue']
                : messages['flight.select-fare-card-btn-select']}
            </button>
          </div>
        </div>
      </div>
    );
  });
}
