import classNames from 'classnames';
import React, { useEffect, useMemo, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import HotelProximitySearch from '../../containers/search/HotelProximitySearch';
import { ReactComponent as CloseIcon } from '../../assets/img/icons/close.svg';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { useIntl } from 'react-intl';

export default function RouteStop({
  stop = {},
  isStart,
  isEnd,
  onChange,
  onDelete,
  onSwap,
  style = {},
  editable = true,
  className = '',
  placeholder,
  index,
  canDelete,
  keepAddress = true,
}) {
  const { messages } = useIntl();
  const itemRef = useRef(null);

  const { routePlaceholder } = useMemo(() => {
    let routePlaceholder = placeholder;

    if (!routePlaceholder) {
      routePlaceholder = isStart
        ? messages['refund.km-expense.search-start']
        : isEnd
        ? messages['refund.km-expense.search-end']
        : messages['refund.km-expense.search-stop'];
    }

    return {
      routePlaceholder,
    };
  }, [stop, isStart, isEnd, placeholder]);

  const canDrag = useMemo(() => editable && stop.address, [editable, stop]);

  // Drag 'n drop
  const [{ isOver }, dropRef] = useDrop(
    {
      accept: 'ROUTE',
      drop: (item) => {
        onSwap(index, item.index);
      },
      canDrop: (item) => stop.address && item.index !== index,
      collect: (monitor) => ({
        isOver: monitor.isOver(),
      }),
    },
    [index, stop, onSwap, stop]
  );

  const [{ isDragging }, dragRef, preview] = useDrag(
    {
      type: 'ROUTE',
      item: { index, stop },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      canDrag: () => canDrag,
    },
    [index, stop, canDrag]
  );

  dragRef(dropRef(itemRef));

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, []);

  const customStyle = useMemo(() => {
    if (isDragging) {
      return {
        ...style,
        cursor: 'grabbing',
      };
    } else
      return {
        ...style,
        cursor: canDrag ? 'grab' : 'default',
      };
  }, [style, isDragging, editable, canDrag]);

  // Render
  return (
    <div
      className={classNames('route-stop w-100 d-flex', className, {
        start: isStart,
        end: isEnd,
      })}
      style={customStyle}
      ref={itemRef}
    >
      <div className="circle-container d-flex align-items-center justify-content-center">
        <div className="circle" />
      </div>
      <div className="info w-100 d-flex align-items-center">
        <div className="flex-grow-1">
          <div
            className="input"
            style={{
              opacity: isDragging ? 0 : isOver ? 0.2 : 1,
              cursor: 'grab',
            }}
          >
            <HotelProximitySearch
              setHighlightPin={onChange}
              includeCity
              fullLocation
              selectedAddress={keepAddress ? stop.address : ''}
              placeholder={routePlaceholder}
              editable={isDragging ? false : editable}
            />
          </div>
        </div>
        {canDelete ? (
          <div
            className="delete-btn d-flex align-items-center justify-content-center pointer ml-1"
            onClick={onDelete}
            title="Deletar"
          >
            <CloseIcon width="40%" className="icon" />
          </div>
        ) : null}
      </div>
    </div>
  );
}
