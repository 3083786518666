import React, { useMemo } from 'react';
import { FILTER_TYPES } from '../../../../constants/filters';
import { ReactComponent as DeleteIcon } from '../../../../assets/img/icons/close.svg';

const generateStringValue = (values) => {
  const length = values.length;
  const labels = values.map(({ label }) => label);

  if (length < 3) {
    return labels.join(' e ');
  } else {
    const lastIndex = length - 1;

    return `${labels.slice(0, lastIndex).join(', ')} ou ${labels[lastIndex]}`;
  }
};

function makeValues(item) {
  switch (item.config.type) {
    case FILTER_TYPES.BOOLEAN:
      return item.value.label;
    case FILTER_TYPES.OBJECT:
    case FILTER_TYPES.ARRAY:
      return generateStringValue(item.value);
    default:
      return item.value;
  }
}

export default function FilterItem({ item, onDelete }) {
  const values = useMemo(() => makeValues(item), [item.value]);

  return (
    <div className="filter-item py-2 px-3 d-flex align-items-center">
      <p className="m-0 p-0 text">
        {item.field.label} {item.comparative.label} {values}
      </p>
      <div
        onClick={onDelete}
        className="icon ml-2 pointer d-flex align-items-center justify-content-center"
      >
        <DeleteIcon height="100%" />
      </div>
    </div>
  );
}
