import React, { useMemo } from 'react';
import map from 'lodash/map';

import { amenities } from '../../constants/hotels';
import classNames from 'classnames';

export default function SlimHotelAmenities({
  className,
  hotelId,
  hotel,
  maxItems,
  iconSize = 16,
}) {
  const hotelAmenities = useMemo(() => {
    const { amenities = [] } = hotel;
    return maxItems ? amenities.slice(0, maxItems) : amenities;
  }, [hotel, maxItems]);

  // Render
  return (
    <div
      className={classNames(
        'amenities slim d-flex align-items-center',
        className
      )}
    >
      {map(hotelAmenities, (amenity, index) => {
        if (!amenities[amenity]) return;

        return (
          <img
            key={`hotel-${hotelId}-amenity-${index}`}
            src={`/assets/img/icons/amenities/${amenities[amenity]}`}
            alt={amenity}
            title={amenity}
            className={index ? 'ml-2' : ''}
            width={iconSize}
            height={iconSize}
          />
        );
      })}
    </div>
  );
}
