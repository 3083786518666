import { PhysicalCategories, VirtualCategories } from './budget';

import { ReactComponent as FlightIcon } from '../assets/img/expenses/flight.svg';
import { ReactComponent as HotelIcon } from '../assets/img/expenses/hotel.svg';
import { ReactComponent as CarIcon } from '../assets/img/expenses/car.svg';
import { ReactComponent as FoodIcon } from '../assets/img/expenses/food.svg';
import { ReactComponent as GasIcon } from '../assets/img/expenses/gas.svg';
import { ReactComponent as MoneyIcon } from '../assets/img/expenses/money.svg';
import { ReactComponent as GlobeIcon } from '../assets/img/expenses/globe.svg';
import { ReactComponent as AdsIcon } from '../assets/img/expenses/ads.svg';
import { ReactComponent as CartIcon } from '../assets/img/expenses/cart.svg';

export const CardCategoriesIcons = {
  [VirtualCategories.NATIONAL]: MoneyIcon,
  [VirtualCategories.INTERNATIONAL]: GlobeIcon,
  [VirtualCategories.ADS]: AdsIcon,
  [VirtualCategories.SAAS]: CartIcon,
  [VirtualCategories.FLIGHT]: FlightIcon,
  [VirtualCategories.HOTEL]: HotelIcon,
  [VirtualCategories.MOBILITY]: CarIcon,
  [PhysicalCategories.FOOD]: FoodIcon,
  [PhysicalCategories.FUEL]: GasIcon,
  [PhysicalCategories.MOBILITY]: CarIcon,
  [PhysicalCategories.NATIONAL]: MoneyIcon,
  [PhysicalCategories.INTERNATIONAL]: GlobeIcon,
  [PhysicalCategories.NATIONAL_WITHOUT_WITHDRAWALS]: MoneyIcon,
  default: MoneyIcon,
};
