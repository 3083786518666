import React, { PureComponent } from 'react';

import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import config from '../../constants/GoogleMaps';
import map from 'lodash/map';
import pickBy from 'lodash/pickBy';
import HotelProximitySearch from '../../containers/search/HotelProximitySearch';
import equal from 'fast-deep-equal';

const GOOGLE_API_KEY = config.key;

const makeRouteKeys = (locations) => {
  const locs = locations.map(({ lat, lng }) => `${lat},${lng}`);
  return [locs.join('|'), locs.reverse().join('|')];
};

const savedRoutes = {};

class RouteMap extends PureComponent {
  constructor(props) {
    super(props);

    this.resultMap = React.createRef();
    this.directionsRenderer = new window.google.maps.DirectionsRenderer({
      suppressMarkers: true,
      polylineOptions: { strokeColor: '#6c6ba8' },
    });
    this.directionsService = new window.google.maps.DirectionsService();

    this.state = {
      defaultBounds: null,
    };
  }

  //Lifecycle
  componentDidUpdate(prevProps) {
    if (
      prevProps.stops.length !== this.props.stops.length ||
      (!equal(prevProps.stops, this.props.stops) && this.checkStops())
    ) {
      this.defineBounds();
      this.calculateRoute();
    }
  }

  checkStops() {
    return this.props.stops.every((stop) => stop.address);
  }

  filterCoords(coords) {
    return pickBy(coords, (coord) => !coord.includes(null));
  }

  mapLoaded(mapProps, map) {
    map.setOptions({ styles: config.mapStyle });

    this.directionsRenderer.setMap(map);

    if (this.checkStops()) {
      this.defineBounds();
      if (this.props.stops.length > 1) this.calculateRoute();
    }
  }

  defineBounds() {
    const { stops } = this.props;

    if (stops.length > 0 && this.checkStops()) {
      const bounds = new window.google.maps.LatLngBounds();
      map(stops, (stop) => {
        bounds.extend(new window.google.maps.LatLng(stop.lat, stop.lng));

        return true;
      });

      this.resultMap.current.map.fitBounds(bounds);
      this.setState({ defaultBounds: bounds });
    }
  }

  storeRoute = (locations, route) => {
    const [firstKey, secondKey] = makeRouteKeys(locations);
    if (!(firstKey in savedRoutes)) {
      savedRoutes[firstKey] = route;
      savedRoutes[secondKey] = route;
    }
  };

  getRoute = (locations) => {
    return new Promise((res, rej) => {
      const firstKey = makeRouteKeys(locations)[0];

      if (firstKey in savedRoutes) {
        res(savedRoutes[firstKey]);
      } else {
        const origin = locations[0];
        const destination = locations[locations.length - 1];
        const waypoints = locations.slice(1, -1).map((location) => ({
          location,
          stopover: true,
        }));

        this.directionsService.route(
          {
            origin,
            destination,
            waypoints,
            travelMode: 'DRIVING',
            provideRouteAlternatives: true,
          },
          (result, status) => {
            if (status === 'OK') {
              this.storeRoute(locations, result);
              res(result);
            } else rej(status);
          }
        );
      }
    });
  };

  joinLegs = (legs) => {
    let distance = 0,
      duration = 0;

    legs.forEach((leg) => {
      distance += leg.distance.value;
      duration += leg.duration.value;
    });

    return {
      distance,
      duration,
    };
  };

  calculateRoute = async () => {
    const { stops } = this.props;

    if (this.directionsRenderer)
      this.directionsRenderer.set('directions', null);

    if (stops.length > 1 && this.checkStops()) {
      try {
        const locations = stops.map(({ lat, lng }) => ({ lat, lng }));
        const routes = await this.getRoute(locations);
        const defaultRoute = routes.routes[0];
        this.directionsRenderer.setDirections(routes);

        const polyline = new window.google.maps.Polyline({
          path: [],
          strokeColor: '#FF0000',
          strokeWeight: 3,
        });

        var legs = defaultRoute.legs;
        for (let i = 0; i < legs.length; i++) {
          var steps = legs[i].steps;
          for (let j = 0; j < steps.length; j++) {
            var nextSegment = steps[j].path;
            for (let k = 0; k < nextSegment.length; k++) {
              polyline.getPath().push(nextSegment[k]);
            }
          }
        }

        const encoding = window.google.maps.geometry.encoding.encodePath(
          polyline.getPath()
        );

        this.props.setSelectedRoute(
          this.joinLegs(defaultRoute.legs),
          stops,
          encoding
        );
      } catch (err) {
        console.error(err);
      }
    }
  };

  // Render
  render() {
    const {
      pinIcon = '/assets/img/icons/car_pin.svg',
      stops = [],
      addStop,
      editable,
      mapOptions = {
        zoomControl: true,
      },
    } = this.props;

    return (
      <div className="hotel-map route-map">
        {editable && stops.length < 11 && (
          <HotelProximitySearch
            setHighlightPin={addStop}
            includeCity
            fullLocation
            placeholder="Adicionar parada"
          />
        )}

        <Map
          google={this.props.google}
          zoom={14}
          scrollwheel={false}
          keyboardShortcuts={false}
          disableDoubleClickZoom
          mapTypeControl={false}
          maxZoom={15}
          scaleControl={false}
          streetViewControl={false}
          panControl={false}
          rotateControl={false}
          fullscreenControl={false}
          onReady={(mapProps, map) => this.mapLoaded(mapProps, map)}
          ref={this.resultMap}
          {...mapOptions}
        >
          {map(stops, (stop, index) => {
            return stop.address ? (
              <Marker
                key={`stop-${index}`}
                stopId={index}
                position={{ lat: stop.lat, lng: stop.lng }}
                icon={{
                  url: pinIcon,
                }}
              />
            ) : null;
          })}
        </Map>
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: GOOGLE_API_KEY,
})(RouteMap);
