export const ORDER_REPORTS_FIELDS_DETAILED = [
  'id',
  'type',
  'passengerName',
  'passengerEmail',
  'requestorName',
  'requestorEmail',
  'approvers',
  'whoApproved',
  'createdAt',
  'costCenter',
  'costCenterInternalId',
  'project',
  'projectInternalId',
  'motive',
  'motiveInternalId',
  'tags',
  'observation',
  'creditCardId',
  'creditCardName',
  'creditCardInstallments',
  'esgCo2',
  'esgKm',
  'issueType',
  'fare',
  'taxes',
  'additional',
  'total',
  'anticipatedPurchase',
  'cheapestAlternative',
  'alternativeDifference',
  'alternativeDifferencePercentage',
  'bookingCode',
  'bookingTicket',
  'flightCarrier',
  'flightNumJourneys',
  'flightJourneys',
  'flightFirstDepartureDate',
  'flightFirstDepartureTime',
  'flightFirstArrivalTime',
  'flightFirstDepartureAirport',
  'flightFirstDepartureCity',
  'flightLastDepartureDate',
  'flightLastDepartureTime',
  'flightLastArrivalTime',
  'flightLastDepartureAirport',
  'flightLastDepartureCity',
  'flightBases',
  'flightFamilyNames',
  'flightWithCredit',
  'flightBaggages',
  'flightCreditAmount',
  'hotelName',
  'hotelAddress',
  'hotelCity',
  'hotelCheckIn',
  'hotelCheckOut',
  'hotelDailyRate',
  'hotelHasBreakfast',
  'hotelBoardDescription',
  'hotelAdults',
  'carRental',
  'carVehicle',
  'carType',
  'carVehicleClass',
  'carPickUp',
  'carPickUpAddress',
  'carDropOff',
  'carDropOffLocation',
  'carDailyRate',
  'busCarriers',
  'busNumJourneys',
  'busJourneys',
  'busFirstDepartureDate',
  'busFirstDepartureTime',
  'busFirstArrivalTime',
  'busLastDepartureDate',
  'busLastDepartureTime',
  'busLastArrivalTime',
  'busClasses',
];

export const ORDER_REPORTS_FIELDS_SIMPLE = [
  'passengerName',
  'whoApproved',
  'createdAt',
  'costCenter',
  'project',
  'tags',
  'creditCardName',
  'total',
  'bookingCode',
];

export const BUDGET_REPORTS_FIELDS = [
  'id',
  'name',
  'passengerName',
  'costCenter',
  'costCenterInternalId',
  'project',
  'projectInternalId',
  'motive',
  'motiveInternalId',
  'tags',
  'observation',
  'cardType',
  'budgetValue',
  'budgetCategory',
  'createdAt',
  'startDate',
  'endDate',
  'anticipationDate',
  'total',
  'wasApproved',
  'statusCodeMessage',
  'cardId',
  'cardInfo',
];

export const BUDGET_ITEMS_REPORTS_FIELDS = [
  'itemId',
  'itemName',
  'itemDate',
  'itemTotal',
  'itemCategory',
  'itemCategoryInternalId',
  'itemType',
  'invoiceId',
  'receiptLink',
];

export const STATEMENT_REPORTS_FIELDS = [
  'id',
  'amount',
  'currency',
  'merchant_name',
  'merchant_city',
  'merchant_country',
  'merchant_id',
  'mcc',
  'response_code',
  'response_msg',
  'date_time',
  'msg_type',
  'msg_type_description',
  'txn_type',
  'reverses_id',
  'auth_code',
  'card_id',
  'card_last_digits',
  'card_user_name',
  'user_cost_center',
  'user_project',
  'budget_id',
  'budget_name',
  'budget_category',
  'budget_item_category',
  'budget_observation',
  'budget_total_amount',
  'budget_total_spent',
  'order_id',
  'order_cost_center',
  'original_amount',
  'original_currency',
  'activates_at',
  'cancels_at',
  'status',
  'custom_fields',
  'external',
  'tipo',
  'passageiro',
  'reserva',
  'administrative',
];
