import React, { PureComponent } from 'react';

import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import config from '../../constants/GoogleMaps';
import map from 'lodash/map';
import pickBy from 'lodash/pickBy';
import HotelProximitySearch from './HotelProximitySearch';

const GOOGLE_API_KEY = config.key;

class OfficeMap extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      coords: this.filterCoords(props.coords),
      defaultBounds: null,
    };
  }

  //Lifecycle
  componentDidUpdate(prevProps) {
    const { highlightedPin: oldHighlightedPin } = prevProps;
    const { highlightedPin } = this.props;

    if (JSON.stringify(oldHighlightedPin) !== JSON.stringify(highlightedPin)) {
      if (highlightedPin) {
        const bounds = new window.google.maps.LatLngBounds(highlightedPin);
        this.refs.resultMap.map.fitBounds(bounds);
      } else {
        if (this.state.defaultBounds)
          this.refs.resultMap.map.fitBounds(this.state.defaultBounds);
        else this.defineBounds();
      }
    }
  }

  filterCoords(coords) {
    return pickBy(coords, (coord) => !coord.includes(null));
  }

  mapLoaded(mapProps, map) {
    map.setOptions({ styles: config.mapStyle });

    this.defineBounds();
  }

  defineBounds() {
    const { coords } = this.state;

    const bounds = new window.google.maps.LatLngBounds();
    map(Object.keys(coords), (officeId) => {
      bounds.extend(
        new window.google.maps.LatLng(coords[officeId][0], coords[officeId][1])
      );

      return true;
    });

    this.refs.resultMap.map.fitBounds(bounds);
    this.setState({ defaultBounds: bounds });
  }

  filterMarker = (officeId) => {
    this.props.toggleSelectedHotels(officeId);
  };

  // Render
  render() {
    const {
      highlightedPin,
      toggleHighlightedPin,
      withProximitySearch,
      city = '',
    } = this.props;
    const { coords } = this.state;

    return (
      <div className="office-map">
        {withProximitySearch && (
          <HotelProximitySearch
            setHighlightPin={toggleHighlightedPin}
            city={city}
          />
        )}

        <Map
          google={this.props.google}
          zoom={14}
          scrollwheel={false}
          keyboardShortcuts={false}
          disableDoubleClickZoom
          zoomControl
          mapTypeControl={false}
          maxZoom={15}
          scaleControl={false}
          streetViewControl={false}
          panControl={false}
          rotateControl={false}
          fullscreenControl={false}
          onReady={(mapProps, map) => this.mapLoaded(mapProps, map)}
          ref="resultMap"
        >
          {map(Object.keys(coords), (officeId) => {
            let iconUrl = '/assets/img/icons/location_pin_unselected.svg';
            if (this.props.selected.includes(officeId)) {
              iconUrl = '/assets/img/icons/location_pin_selected.svg';
            }

            return (
              <Marker
                key={`office-map-${officeId}`}
                officeId={officeId}
                position={{
                  lat: coords[officeId][0],
                  lng: coords[officeId][1],
                }}
                onClick={() => {
                  this.filterMarker(officeId);
                }}
                icon={{
                  url: iconUrl,
                }}
              />
            );
          })}

          {highlightedPin && (
            <Marker
              key="highlighted-pin"
              position={{ lat: highlightedPin.lat, lng: highlightedPin.lng }}
              onClick={() => toggleHighlightedPin(null)}
              icon={{
                url: '/assets/img/icons/highlighted_pin.svg',
              }}
            />
          )}
        </Map>
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: GOOGLE_API_KEY,
})(OfficeMap);
