module.exports = {
  /* 01.General */
  'general.copyright': 'Portão 3 © 2021 All rights reserved.',
  'general.loading': 'Loading ...',
  'general.go-back': 'Back',
  'general.go-forward': 'Next',
  'general.delete': 'Delete',
  'general.cancel': 'Cancel',
  'general.save': 'Save',
  'general.send': 'Send',
  'general.reservations': 'reservations',
  'general.nights': 'nights',
  'general.total': 'Total',
  'general.new-total': 'New Total',
  'general.view': 'Visualize',
  'general.select': 'Select',
  'general.selected': 'Selected',
  'general.taxes': 'Taxes, fees and charges already included',
  'general.adult': 'adult',
  'general.adults': 'adults',
  'general.star': 'star',
  'general.stars': 'stars',
  'general.from': 'from',
  'general.duration': 'Duration',
  'general.price': 'Price',
  'general.more-details': 'More Details',
  'general.book': 'Book',
  'general.slogan': 'Simple,<br/>intelligent<br/>and effective.',
  'general.person': 'person',
  'general.people': 'people',
  'general.search': 'Search',
  'general.prices': 'Prices',
  'general.categories': 'Categories',
  'general.all-selected': 'All selected',
  'general.none-selected': 'None Selected',
  'general.loading-options': 'Loading Options',
  'general.none': 'None',
  'general.yes': 'Yes',
  'general.no': 'No',
  'general.alright': 'All Right.',
  'general.ok': 'Okay',
  'general.saved-successfully': 'Saved successfully!',
  'general.show-all': 'Show all',
  'general.num-items': 'N. Itens',
  'general.close': 'Close',
  'general.permissions': 'Permissions',
  'general.config': 'Settings',
  'general.realod': 'Update',
  'general.done': 'Ok!',
  'general.add': 'Add',
  'general.items.singular': '1 item',
  'general.items.plural': '{number} itens',
  'general.do-cancel': 'Yes, cancel',
  'general.and': 'and',
  'general.request': 'Request',
  'general.finish': 'Finish',
  'general.price-estimate': 'Rate estimate',
  'general.reject': 'Reject',
  'general.required': 'Required',
  'general.required-fields': 'Required fields',
  'general.clean': 'Clean',
  'general.time': 'Time',
  'general.options': 'Options',
  'general.disable': 'Deactivate',
  'general.activate': 'Activate',
  'general.no-options-found': 'No option found!',
  'general.none-a': 'None',
  'general.copied': 'Copied!',
  'general.day': 'day',
  'general.days': 'days',
  'general.fetch-more': 'Load more',
  'general.see-more': 'See more',
  'general.favorite': 'Favorite',
  'general.cheapest': 'Cheapest',

  /* 02.User Login, Logout, Register */
  'user.simple': 'Simple',
  'user.inteligent': 'Smart',
  'user.direct': 'Effective',
  'user.login': 'Log in',
  'user.login.page': 'Login',
  'user.authType.error':
    "We could't identify you, this means you don't have an account or youfr accout is blocked. Please try again with a different e-mail or contact the admin. ",
  'user.newPassword': 'Your password has expired, create a new one to continue',
  'user.shouldInvite': 'Send invitation to access Portão 3',
  'user.setupMFA': 'Add more security to your account',
  'user.setupMFA_desc':
    'Two-step authentication is a simple practice that adds an extra layer of protection to your user and password.Read the QR Code with an appropriate app.',
  'user.username': 'Email or user',
  'user.password': 'Enter your password',
  'user.reset_code': 'Verification code',
  'user.mfa_code': 'Token Code',
  'user.new_password': 'New password',
  'user.new_password_confirm': 'Confirm password',
  'user.forgot-password-question': 'Forgot the password?',
  'user.forgot-password': 'Forgot the password',
  'user.forgot-password_desc': 'Create a new password for your account.',
  'user.authMFA': 'Verification Code',
  'user.authMFA_desc': 'Enter the verification code configured in your app.',
  'user.forgot-password-request-success':
    'A password exchange link has been sent to your email.',
  'user.forgot-password-request-error':
    'The link used is no longer valid.Please try to request the reset again.',
  'user.new-password': 'Welcome to Portão 3!',
  'user.new-password_desc': 'Create a new password to start using the platform',
  'user.CodeMismatchException':
    'Invalid validation code.Please check the code we emailed you and try again.',
  'user.EnableSoftwareTokenMFAException':
    'Invalid Token Code.Please try again.',
  'user.NotAuthorizedException':
    'Incorrect password.Try again or click "Forgot your password?" to reset it.',
  'user.UserNotFoundException': 'Your account at Portão 3 could not be found.',
  'user.UserSSOOrganization': `Your organization uses SSO, your password must be changed there. Consult your organization's technical team, please!`,
  'user.UserDisabledException':
    'User locked.Contact support for more information.',
  'user.LimitExceededException':
    'Reset attempts exceeded.Please try again later.',
  'user.InvalidParameterException':
    'Your new password is very simple.Enter a more complex password to continue.',
  'user.WeakPasswordException': 'Password should be more complex',
  'user.ExpiredCodeException':
    'Verification code has expired.Please request again.',
  'user.GeneralException':
    'An internal error has occurred.Our team has already been warned.',
  'user.register.error':
    'Unable to register this user. Try again or contact our support!',
  'user.register.error.email-taken':
    'The email is already in use. Please choose another one!',
  'user.register.error.invalid-params':
    'There are invalid parameters. Validate them and try again!',

  'user.fullName': 'Full Name',
  'user.firstName': 'Name',
  'user.lastName': 'Last Name',
  'user.gender': 'Gender',
  'user.gender.MALE': 'Male',
  'user.gender.FEMALE': 'Female',
  'user.birthdate': 'Date of birth',
  'user.email': 'E-mail',
  'user.mobilePhoneNumber': 'Mobile',
  'user.nationality': 'Nationality',
  'user.phoneNumber': 'Phone',
  'user.role': 'Cargo',
  'user.companyId': 'Registration',
  'user.costCenter': 'Cost Center',
  'user.project': 'Project',
  'user.rgNumber': 'Identity',
  'user.rgEmitter': 'Issuing Body',
  'user.cpf': 'CPF',
  'user.identity': 'Identity',
  'user.cnpj': 'CNPJ',
  'user.cnhNumber': 'CNH',
  'user.cnhValidUntil': 'Expiration date',
  'user.passportCountry': 'Passport issuing country',
  'user.passportNumber': 'Number',
  'user.passportValidUntil': 'Expiration date',

  /* 03.Menu */
  'menu.app': 'Administrator',
  'menu.dashboard': 'Dashboard',
  'menu.active': 'Active Trips',
  'menu.reports': 'Reports',
  'menu.analytics': 'Analytics',
  'menu.management': 'Policies',
  'menu.travels': 'Travels',
  'menu.expenses': 'Expenses',
  'menu.payments': 'Expenses',
  'menu.items': 'Itens',
  'menu.finance': 'Financial',
  'menu.integrations': 'Integrations',
  'menu.users': 'Users',
  'menu.about': 'About',
  'menu.kyc': 'Register',
  'menu.add-user': 'Add user',
  'menu.others': 'Others',
  'menu.pending': 'Pending',
  'menu.approval': 'Approvals',
  'active.travelersInit': 'You own',
  'active.travelersEnd': 'active travellers',
  'active.mapBrazil': 'Brazil',
  'active.mapWorld': 'World',
  'menu.data': 'Data',
  'menu.esg': 'ESG',
  'menu.summary': 'Summary',

  /* 03.1.Menu */
  'refund-menu.cards': 'Cards',
  'refund-menu.bills': 'Accounts',
  'refund-menu.inbox': 'Inbox',
  'refund-menu.recurrence': 'Recurrences',

  /* 03.1. Data Menu */
  'data-menu.travels': 'Travels',
  'data-menu.expenses': 'Expenses',
  'data-menu.esg': 'ESG',
  'data-menu.finance': 'Statement',

  /* 04.Travel */
  'travel.menu.package': 'Flight + Hotel',
  'travel.menu.flight': 'Flight',
  'travel.menu.hotel': 'Hotel',
  'travel.menu.car': 'Car',
  'travel.menu.bus': 'Bus',
  'travel.menu.office': 'Office',
  'travel.menu.itinerary': 'Road map',
  'travel.next': 'Next Trips',
  'travel.flight': 'Flight',
  'travel.origin': 'Origin',
  'travel.destination': 'Destination',
  'travel.dropOffSamePickUp': 'Same drop-off location',
  'travel.isTwoWay': 'Round trip',
  'travel.rental': 'Rental company',
  'travel.pickUpLocation': 'Pick-Off Location',
  'travel.pickUpTime': 'Pick-up Date and Time',
  'travel.dropOffLocation': 'Drop-off Location',
  'travel.dropOffTime': 'Drop-off Date and Time',
  'travel.officeLocation': 'Location',
  'travel.officeDate': 'Date',
  'travel.outbound': 'Outbound',
  'travel.inbound': 'Inbound',
  'travel.baggage': 'suitcase',
  'travel.baggages': 'suitcases',
  'travel.Baggage': 'Baggage',
  'travel.no_baggage': 'No baggage',
  'travel.with_baggage': 'With baggage',
  'travel.backpack': 'Backpack',
  'travel.nationality': 'Nationality',
  'travel.class': 'Class',
  'travel.Economy': 'Economy',
  'travel.PremiumEconomy': 'Premium Economy',
  'travel.Business': 'Business',
  'travel.FirstClass': 'First Class',
  'travel.stay': 'Hosting',
  'travel.checkin': 'Check-in',
  'travel.checkout': 'Check-out',
  'travel.location-message': 'Type to search',
  'travel.location-searching': 'Searching...',
  'travel.peopleRooms': 'People and rooms',
  'travel.baggageCabin': 'Baggage and Cabin',
  'travel.office_name': 'Office',
  'travel.room_name': 'Room',
  'travel.date': 'Date',
  'travel.room_size': '# People',
  'travel.total': 'Total',
  'travel.search': 'Search',
  'travel.Room': 'Room',
  'travel.room': 'room',
  'travel.rooms': 'rooms',
  'travel.Rooms': 'Rooms',
  'travel.room.simple': 'single',
  'travel.room.double': 'double',
  'travel.room.triple': 'triple',
  'travel.room.quadruple': 'quadruple',
  'travel.room.offline-reservation.title': 'Offline reservation',
  'travel.room.offline-reservation.description': `It forms part of your organization's hotel agreements. It is done offline, by contacting the hotel. Therefore, the time for making the reservation is longer!`,
  'travel.board': 'Board',
  'travel.bus.carrier': 'Carrier',
  'travel.car': 'Car',
  'travel.car.pickup': 'Pick up',
  'travel.car.dropoff': 'Drop off',
  'travel.timeline.loading': 'Loading your timeline',
  'travel.search.loading': 'Loading',
  'travel.booking.passenger': 'Passenger name',
  'travel.booking.no-passenger': 'No passenger found',
  'travel.booking.loading-passengers': 'Loading passengers',
  'travel.booking.costCenter': 'Cost Center',
  'travel.booking.no-costCenter': 'No cost center found',
  'travel.booking.project': 'Project',
  'travel.booking.no-project': 'No project found',
  'travel.booking.motive': 'Motive',
  'travel.booking.no-motive': 'No motives found',
  'travel.booking.tag': 'Tags',
  'travel.booking.no-tag': 'No tags found',
  'travel.booking.observation': 'Comments',
  'travel.booking.creditCard': 'Credit Card',
  'travel.booking.no-creditCard': 'No credit card found',
  'travel.booking.creditCard.ends-with': 'Final',
  'travel.booking.creditCard.insufficient-balance':
    'Insufficient balance to issue this reservation.',
  'travel.booking.installments': 'Installment',
  'travel.booking.loading-installments': 'Loading...',
  'travel.empty.title': 'There are no results for your search.',
  'travel.empty.subtitle': 'Try to redo your search with new parameters.',
  'travel.nodata.title': 'No results found',
  'travel.nodata.subtitle': 'Try making new reservations.',
  'travel.order.status.title': 'Track your booking status below.',
  'travel.order.status.reserving': 'Booking',
  'travel.order.status.timeline.reserving': 'Booking...',
  'travel.order.status.reserved': 'Booked',
  'travel.order.status.timeline.approval': 'Awaiting approval',
  'travel.order.status.timeline.toissue': 'Awaiting issue',
  'travel.order.status.timeline.issuing': 'Issuing...',
  'travel.order.status.timeline.open-booking.issuing': 'Generating card...',
  'travel.order.status.timeline.open-booking.waiting': 'Issue your reservation',
  'travel.order.status.timeline.altering': 'Altering...',
  'travel.order.status.timeline.manualissuing': 'Issuing...',
  'travel.order.status.timeline.cancelling': 'Canceling...',
  'travel.order.status.timeline.cancelled': 'Cancelled',
  'travel.order.status.timeline.error': 'An error has happened',
  'travel.order.status.timeline.denied': 'Denied',
  'travel.order.status.timeline.expired': 'Expired',
  'travel.order.status.approval': 'Approval',
  'travel.order.status.issue': 'Issue',
  'travel.order.status.title.approval':
    'Check out the approvals for this reservation.',
  'travel.order.status.title.validUntil': 'Valid until',
  'travel.order.status.title.hasNoValidUntil':
    'Unconfirmed booking, values may change.',
  'travel.order.status.title.issuing': "We're issuing your reservation...",
  'travel.order.status.title.open-booking.issuing':
    'We are creating the virtual card for this reservation...',
  'travel.order.status.title.open-booking.waiting':
    'Now you need to issue this booking on your chosen website',
  'travel.order.status.title.open-booking.receipt':
    'We identify the reservation on the chosen website, but you still need to attach the proof of reservation',
  'travel.order.status.title.issued': 'Ok! Everything set with your booking.',
  'travel.order.status.title.issued.altering':
    'Your reservation was issued, but an alteration is beeing processed! ',
  'travel.order.status.title.cancelling': 'We are canceling this reservation.',
  'travel.order.status.title.error':
    'A mistake happened with this booking. Please try again.',
  'travel.order.status.title.denied':
    'Unfortunately this reservation was rejected by one of the approvers',
  'travel.order.cancellation.date': 'Free cancellation up to',
  'travel.order.cancellation.issue': 'Free cancellation until issue',
  'travel.order.open-booking.show-steps': 'Show steps',
  'travel.order.open-booking.show-transaction': 'Go to transaction',
  'travel.order.cancellation.cancel': 'Cancel',
  'travel.order.cancellation.cancelled':
    'This booking has been cancelled or the issue period has expired.',
  'travel.order.cancellation.dismissed': 'This reservation is cancelled.',
  'travel.order.cancellation.alter': 'Change',
  'travel.order.cancellation.dismiss': 'Hide',
  'travel.order.approval.approve': 'Approve',
  'travel.order.aproval.review': 'Review',
  'travel.order.approval.to-approve': 'Approve',
  'travel.order.approval.approve-immediately': 'Approve immediately',
  'travel.order.approval.generate-card': 'Generate card',
  'travel.order.approval.just-approve': 'Just Approve.',
  'travel.order.approval.issue': 'Issue',
  'travel.order.use-credits': 'Use credits from ticket',
  'travel.order.status.timeline.refund.new': 'New',
  'travel.order.status.timeline.refund.start': 'Created',
  'travel.order.status.timeline.refund.approving-anticipation': 'Approving',
  'travel.order.status.timeline.refund.approved-anticipation': 'Schedulled',
  'travel.order.status.timeline.refund.activating': 'Activating',
  'travel.order.status.timeline.refund.active': 'Active',
  'travel.order.status.timeline.refund.waiting': 'On Hold',
  'travel.order.status.timeline.refund.review': 'Review',
  'travel.order.status.timeline.refund.approving': 'Approving',
  'travel.order.status.timeline.refund.approved': 'Approved',
  'travel.order.status.timeline.refund.rejected': 'Rejected',
  'travel.order.status.timeline.refund.cancelling': 'Canceling',
  'travel.order.status.timeline.refund.cancelled': 'Cancelled',
  'travel.order.status.timeline.refund.undone': 'Undone',
  'travel.order.status.timeline.refund.dismissed': 'Dismissed',
  'travel.order.status.timeline.refund.error': 'Error',
  'travel.order.status.timeline.refund.processing': 'Processingo',
  'travel.order.status.timeline.refund.waiting-payment': 'Waiting payment',
  'travel.order.status.timeline.refund.paid': 'Paid',

  'travel.order.status.timeline.administrative-budget.activating': 'Paying',
  'travel.order.status.timeline.administrative-budget.approved': 'Paid',

  'travel.timeout': 'Wait a few minutes before doing this action again!',
  'travel.payment-at-destination': ' + {value} on the pickup',
  'travel.approval.payment-at-destination':
    'An amount {value} should be paid on the vehicle pickup. Remember the passenger to bring a paying method to avoid possible trouble!',

  'travel.bus.choose-seat': 'Choose seat',
  'travel.bus.seat': 'Seat {seat}',

  'travel.structure.organization': 'Company travel',
  'travel.structure.personal': 'Personal travel',

  'travel.open-booking.flight-number': 'Flight number',
  'travel.open-booking.amount': 'Amount',
  'travel.open-booking.airline': 'Airline',
  'travel.open-booking.associate': 'Reservation website',

  'travel.comment-placeholder':
    'Write a comment so that the passenger can understand the reason for this decision, if he wishes.',

  // Bus seat
  'bus-seat.tabs.seat': 'Seat',
  'bus-seat.tabs.availability': 'Availability',
  'bus-seat.availability.title':
    'What should be the behavior if the selected seat is no longer available when this reservation is issued?',
  'bus-seat.availability.similar.title':
    'Find the highest similarity seat available',
  'bus-seat.availability.similar.description':
    'If the seat chosen is a window seat, try to find another one with the same features. If not, select the closest available seat to the chosen one.',
  'bus-seat.availability.cancel.title': 'Cancel the reservation',

  // Seat map
  'seat-map.legend.occupied': 'Occupied',
  'seat-map.legend.available': 'Available',
  'seat-map.legend.selected': 'Selected',

  'seat-map.empty.title': 'No seat found',
  'seat-map.empty.description':
    'Could not find the bus seats. Try again later!',

  'refund.status-code.0': 'New',
  'refund.status-code.10': 'Waiting for anticipation',
  'refund.status-code.20': 'Antecipation aproved',
  'refund.status-code.45': 'Activating',
  'refund.status-code.50': 'Active',
  'refund.status-code.55': 'Waiting',
  'refund.status-code.60': 'Revision',
  'refund.status-code.65': 'Approving',
  'refund.status-code.70': 'Approved',
  'refund.status-code.80': 'Awaiting payment',
  'refund.status-code.85': 'Rejected',
  'refund.status-code.90': 'Paid',
  'refund.status-code.95': 'Canceling',
  'refund.status-code.100': 'Canceled',
  'refund.status-code.125': 'Undone',
  'refund.status-code.150': 'Hiden',
  'refund.status-code.200': 'Error',
  'travel.duration': 'Duration',
  'travel.approve-trip': 'Approve travel',
  'travel.reject-trip': 'Reject travel',
  'travel.alternatives': 'Alternatives',
  'travel.duplicity': 'Duplicity',
  'travel.best-option': "That's the best option!",
  'travel.payment': 'Payment',
  'travel.issue-trip': 'Issue travel',
  'travel.details': 'Booking information',
  'travel.currentHotel.details': 'Current selected hotel information',
  'travel.details.advance': 'Advance',
  'travel.details.car.dailyValue': 'Daily value',
  'travel.details.hotel.dailyValue': 'Value per night',
  'travel.details.flightandbus.price-per-way': 'Average per trip',
  'travel.cancel.title': 'Cancel reservation',
  'travel.cancel.description':
    'You can cancel the reservation for this booking',
  'travel.cancel.subtitle': 'How much does it cost to cancel this reservation?',
  'travel.cancel.free': 'Free',
  'travel.cancel.free-until': 'Untill {date} at {hour}',
  'travel.cancel.cancel-dates':
    'From {startDate} at {startHour} to {endDate} at {endHour}',
  'travel.cancel.cannot-cancel':
    'Unfortunately you can no longer cancel this reservation!',
  'travel.alter.title': 'Chance reservation',
  'travel.alter.subtitle': 'Select what would you like to do',
  'travel.ancillaries.tab.title': 'Ancillaries',
  'travel.ancillaries.subtitle.loading': 'Loading ancillaries',
  'travel.ancillaries.subtitle':
    'Select what would you like to add to your reservation',
  'travel.alter.checkout.title': 'Make purchase',
  'travel.alter.checkout.subtitle': 'Select the requested payment method',
  'travel.alter.checkout.cancellation':
    'Once purchased, ancillaries can no longer be cancelled.',
  'travel.alter.flight.has-ancillaries':
    'This reservation has ancillaries in the basket. Cancel those first before proceeding.',
  'travel.alter.flight.ancillary.title': 'Ancillaries',
  'travel.alter.flight.ancillary.description':
    'Select seats and bags for this reservation',
  'travel.alter.flight.cancellation.title': 'Cancel flight',
  'travel.alter.flight.cancellation.description':
    'Request the reservation to be cancelled',
  'travel.alter.flight.cancellation.options-tab.description':
    'Request the flight reservation to be cancel. Subject to extra charges from the airline according to your ticket policy.',
  'travel.alter.flight.cancellation.options-tab.credit.title':
    'Keep it as credit',
  'travel.alter.flight.cancellation.options-tab.credit.description':
    'The credit will be attached to this ticket and will be available to use with the same airline and passenger for future usage. Airlines will always charge less to keep amount in credit instead of a refund.',
  'travel.alter.flight.cancellation.options-tab.refund.title':
    'Credit Card Refund',
  'travel.alter.flight.cancellation.options-tab.refund.description':
    'The refund will be provided by the airline in the same credit card, usually within 7 days. Charges are usually greater to request the refund.',

  'travel.alter.flight.cancellation.options-tab.void.title': 'Full refund',
  'travel.alter.flight.cancellation.options-tab.void.description':
    'A full refund can be requested on same-day bookings, with no additional costs or penalties applied.',

  'travel.alter.flight.cancellation.options-tab.dialog.description':
    'Are you sure will want to make this request?',
  'travel.alter.flight.cancellation.error':
    'It was not possible to cancel this reservation. Please try again or contact support!',
  'travel.alter.flight.cancellation.final.title':
    'We requested the reservation to be canceled',
  'travel.alter.flight.cancellation.final.description':
    'Our team is in contact with the airline to request the reservation cancellation.',
  'travel.alter.flight.cancellation.final.successful':
    'Your reservation is still not cancelled, our team is in contact with the airline to wrap it as requested. We will let you know via email when we have more information about it.',
  'travel.alter.flight.alteration.card.title': 'Change date',
  'travel.alter.flight.alteration.card.description':
    'Change fly dates for this reservation',
  'travel.alter.flight.alteration.title': 'Change date',
  'travel.alter.flight.alteration.search.description.searching':
    'Searching all flights for this date',
  'travel.alter.flight.alteration.search.description.select':
    'Select the flight you would like to get instead',
  'travel.alter.flight.alteration.search.description.outbound':
    'Would you like to change the outbound flight?',
  'travel.alter.flight.alteration.search.description.inbound':
    'Would you like to change the inbound flight?',
  'travel.alter.flight.alteration.search.description.route':
    'Request flight change',
  'travel.alter.flight.alteration.search.journey': 'Journey',
  'travel.alter.flight.alteration.search.select-date': 'Select a new date',
  'travel.alter.flight.alteration.search.estimated-value': 'Estimate rate',
  'travel.alter.flight.alteration.final.title': 'Request date change',
  'travel.alter.flight.alteration.final.description':
    'Request date change for your reservation',
  'travel.alter.flight.alteration.final.successful':
    'No changes were made at this point. Our team will get in touch with the airine and get back to you via email soon.',
  'travel.alter.flight.alteration.searching.error':
    'It was not possible to search with the current parameters. Please try again or contact us.',
  'travel.alter.flight.alteration.empty-data':
    'No flights were found in this date. Please try a new search.',
  'travel.alter.flight.alteration.error':
    'It was not possible to change this reservation. Please try again later our contact us.',
  'travel.alter.flight.alteration.btn.keep-reservation': 'Keep current flight',
  'travel.ancillary.title': 'Booking changes',
  'travel.ancillary.subtitle': 'You have made changes to this reservation',
  'travel.ancillary.cancel.title': 'Cancel changes',
  'travel.ancillary.cancel.loading-subtitle': 'Checking what can be cancelled',
  'travel.ancillary.cancel.subtitle': 'The items below will be cancelled',
  'travel.ancillary.cancel.not':
    'Sorry! We cannot cancel the ancillary at this time',
  'travel.ancillary.msg.created': 'Finishing ancillaries',
  'travel.ancillary.msg.booking': 'Purchasing ancillaries',
  'travel.ancillary.msg.booked': 'Ancillaries booked',
  'travel.ancillary.msg.cancelling': 'Cancelling ancillaries',
  'travel.ancillary.msg.cancelled': 'Ancillaries cancelled',
  'travel.alter.hotel.increase-days.yes': 'Yes, please!',
  'travel.alter.hotel.increase-days.title': 'Add new days',
  'travel.alter.hotel.increase-days.description':
    'You can request more dates to your stay here.',
  'travel.alter.hotel.increase-days.reservation':
    'Reservation from {startDate} to {endDate}',
  'travel.alter.hotel.increase-days.single-day': '1 night will be added',
  'travel.alter.hotel.increase-days.multiple-days':
    '{days} nights will be added',
  'travel.alter.hotel.increase-days.has-room':
    'The room you requested is available on those dates. Click Next to keep the same room or select a new option below.',
  'travel.alter.hotel.increase-days.similar-room':
    'The original room is not available in the new dates, we tried to find one close to it. Click Next to use that one or select a new option below.',
  'travel.alter.hotel.increase-days.no-room':
    'The room your selected is not available in the new dates. Please choose a new one below.',
  'travel.alter.hotel.increase-days.fully-empty':
    'The hotel does not have more rooms available in the new dates.',
  'travel.alter.hotel.increase-days.partially-empty':
    'The hotel does not have rooms available. Would you like to try with another date?',
  'travel.alter.hotel.increase-days.empty-data': '{startDate} to {endDate}',
  'travel.alter.bus.seats.title': 'Seats',
  'travel.alter.bus.seats.description': 'Choose the seats for your trip',

  'travel.alter.bus.alteration.seats.title': 'Select seat',
  'travel.alter.bus.alteration.seats.description': `Choose reservation seats. You don't have to change them all if you don't want to!`,
  'travel.alter.bus.alteration.seats.error':
    'It was not possible to change the seats for this reservation. Try again!',

  'travel.alter.bus.cancellation.title': 'Cancel reservation',
  'travel.alter.bus.cancellation.description': 'Cancel this reservation',

  'travel.with-breakfast': 'With breakfast',
  'travel.without-breakfast': 'Without breakfast',
  'orders.notifications.FARE_UPDATED':
    'Unfortunately this rate has expired and the booking could not be made.Do a new search to continue.',
  'orders.notifications.FARE_UPDATED.with-params':
    'Unfortunately this rate has expired and the booking could not be made.Do a new search to continue.',
  'orders.notifications.CREDIT_CARD_UNAUTHORIZED':
    'There was a problem with your card and the booking cannot be confirmed.',
  'orders.notifications.EMPTY_CARD':
    'No credid card compatible with the reservation was found. Ask the administrator to add new cards or contact the support for more informations!',
  'orders.notifications.RESERVATION_FAILED':
    'There was an internal error and the booking could not be made.',
  'orders.notifications.EXPIRED_RESERVATION':
    'Saddly your reservation expired before beeing issued. ',
  'orders.notifications.UNAVAILABLE_SEAT':
    'Saddly all seats avaliable are occupied. Make a new search to continue. ',
  'orders.notifications.UNAVAILABLE_CHOOSEN_SEAT':
    'Unfortunately, the seat chosen in the change is no longer available. Try booking another one please!',
  'orders.notifications.ITEM_UNAVAILABLE':
    'Saddly this item is no longer avaliable to issue. You can make a new search and secure a new reservation.',
  'orders.notifications.FREQUENT_FLYER_NUMBER':
    'The frequent flyer number provided does not match with the name of the passenger on the airline. Please check the information and try again!',
  'orders.notifications.FREQUENT_FLYER_NUMBER.with-params':
    'The frequent flyer number provided ({frequentFlyerNumber}) does not match with the name of the passenger ({passengerName}) on the airline. Please check the information and try again!',
  'orders.notifications.OPEN_BOOKING_REFUNDED':
    'The transaction made on the reservation website was reversed after the maximum reservation issue date',

  'orders.notifications.btn.ok': 'All Right!',
  'orders.notifications.btn.search': 'Search again',
  'payment.single-installment': 'At sight. 1x of {quantity}.',
  'payment.installment-with-interest':
    '{installment} installments. 1x of {firstInstallmentAmount} and {remainingInstallments}x of {remainingInstallmentsAmount} (with interest)',
  'payment.installment-without-interest':
    '{installment} installments. 1x of {firstInstallmentAmount} and {remainingInstallments}x of {remainingInstallmentsAmount}',

  /* 04.1. Travel: Car */
  'car.door': 'Door',
  'car.doors': 'Doors',
  'car.passenger': 'Passenger',
  'car.passengers': 'Passengers',
  'car.baggage-size': 'Baggage',
  'car.baggages-size': 'Baggage',
  'car.air-conditioning': 'Air Conditioning',
  'car.transmission': 'Transmission',

  /* 04.1. Travel: Flight */
  'flight.select-fare': 'Select your fare',
  'flight.select-fare-description':
    'Customize your trip and choose one of the options below:',
  'flight.select-fare-card-out-return': 'Round trip',
  'flight.select-fare-card-out': 'Just one way',
  'flight.select-fare-card-total': 'Total',
  'flight.select-fare-card-btn-select': 'Select fare',
  'flight.select-fare-card-btn-continue': 'Continue with fare',
  'flight.select-fare-card-btn-goToReservation': 'Next step',
  'flight.no-stop': 'Nonstop',
  'flight.stop': 'Stop',
  'flight.stops': 'Stops',
  'flight.checkout-page.organization': 'Organization',
  'flight.checkout-page.personal': 'Personal',
  'flight.with-selected-seat': 'With selected seat',
  'flight.with-luggage': 'With adicional luggage ',
  'flight-alteration.unavailable.title': 'Service not avaliable',
  'flight-alteration.unavailable.description':
    "The airline doesn't seem to provide the service in question, but you can contact our support to try to select seats and add luggage.",
  'flight-alteration.unavailable.btn': 'Contact support',
  'flight-alteration.baggage-sec.title': 'Luggage',
  'flight-alteration.baggage-sec.description':
    'Add luggage in your reservation in advance',
  'flight-alteration.seat-sec.title': 'seat',
  'flight-alteration.seat-sec.description': 'Reserve your seats in advance',
  'flight-alteration.checkout': 'Checkout',
  'flight-alteration.checkout.organization.title':
    'Requested changes are covered by your company policies. You can complete the purchase by clicking the button below, or you can choose to pay for the changes yourself in the "Personal" tab.',
  'flight-alteration.checkout.personal.title':
    "Your organization hasn't set any policies for the required changes, but relax you still can pay for them if you want to.",
  'flight-alteration.checkout.personal.title.allow':
    "Required changes are covered by your organization's policies, but you can choose to pay for them if you like.",
  'flight-alteration.bags': 'Luggage',
  'flight-alteration.bag': 'Luggage',
  'flight-alteration.bags.single': '1 Bag',
  'flight-alteration.bags.plural': '{bags} bags',
  'flight-alteration.bags.item': '{bags} up to {weight}',
  'flight-alteration.seats': 'Seats',
  'flight-alteration.seat': 'Seats',
  'flight-alteration.seats.item': 'Seat {seat}',
  'flight-alteration.refund-total': 'Amount to be refounded',
  'flight-alteration.btn.cancel': 'I want to cancel',
  'flight-alteration.error.cancel':
    'It was not possible to cancel this change. Please contact our support!',
  'flight-alteration.checkout.error.cancel':
    'Unable to purchase the changes. Try again later!',
  'flight-alteration.cancellation.unable-to':
    'Unable to cancel changes made! Contact our support for more information',
  'flight-alteration.cancellation.total-value': 'Value paid for the changes',
  'flight-alteration.cancellation.refund-value': 'Value to be refounded',
  'flight-alteration.cancellation.confirmation':
    'Are you sure that you want to cancel the changes done in this reservation?',

  /* 04.1. Travel: ESG */
  'esg.flight.estimate':
    'You will fly approximately {miles} miles and this trip will generate {carbon} kg of CO2 and other greenhouse gases.',
  'esg.car.estimate':
    'Using this vehicle will issue around {carbon} kg of CO2 and other greenhouse gases.',
  'esg.bus.estimate':
    'You will travel through {km} km and this trip will issue around {carbon} kg of CO2 and ther greenhouse gases.',
  'esg.empty.title': 'No elegible reservations',
  'esg.empty.subtitle': 'Try making new ones.',
  'esg.form.kg': 'Total neutralization\n',
  'esg.form.card': 'Credit card',
  'esg.form.purchase': 'Neutralize ',

  /* Order Alternatives */
  'order.alternative.best-price': 'Best price',
  'order.alternative.duplicity': 'Duplicate issue',
  'order.alternative.save': 'Save {save}',
  'order.alternative.arrive-early': 'Early arrival',
  'order.alternative.loading': 'Loading alternatives',
  'order.alternative.cancellation': 'Free canceling untill {date}',

  /* Admin */
  'admin.active.mapWorld': 'Overview of confirmed cases',
  'admin.active.mapBrazil': 'Brazil view of confirmed cases',
  'admin.active.travelersInit': 'You have ',
  'admin.active.travelersEnd': 'active travellers',
  'admin.users.invite': 'INVITE',
  'admin.users.bulkImport': 'BULK IMPORT',
  'admin.users.bulkImport.lower': 'Batch Import',
  'admin.users.bulkImportSuccess':
    "File imported successfully! It's being treated and will be available soon.",
  'admin.users.invite-traveler': 'INVITE TRAVELER',
  'admin.users.no-pending': "You haven't invited anyone yet.",
  'admin.users.emails': 'Emails (enter one per line)',
  'admin.users.submit': 'Invite',
  'admin.users.remove-user.title': 'Remove user',
  'admin.users.remove-user.description':
    'This action will remove the user from the platform, to reactivate it will be necessary to call support. Do you want to proceed?',
  'admin.users.invite-btn': 'Invite users',
  'admin.users.spreadsheet-model': 'Download template sheet',
  'admin.users.bulk.created.title': 'Invite users',
  'admin.users.bulk.created.description.singular':
    'One new user was found! Would you like to invite him to the platform?',
  'admin.users.bulk.created.description.plural':
    '{number} new users were found! Would you like to invite them to the platform?',
  'admin.users.bulk.modified.title': 'Modify users',
  'admin.users.bulk.modified.description.singular':
    'Found 1 user that is already on the platform! Would you like to updated it?',
  'admin.users.bulk.modified.description.plural':
    'Found {number} users that are already on the platform ! Would you like to updated them?',
  'admin.users.bulk.deleted.title': 'Delete users',
  'admin.users.bulk.deleted.description.singular':
    'Found 1 user that is not in the file, but was found on the platform! Would you like to remove him from the platform?',
  'admin.users.bulk.deleted.description.plural':
    'Found {number} users that are not on the file, but were found on the platform! Would you like to remove them from the platform?',
  'admin.users.bulk.invalid.title': 'Invalid users',
  'admin.users.bulk.invalid.description.singular':
    "One user's invalid document was found or the full name exceeds 30 characters! Make sure the number is correct or the full name's lenght is respected before adding.",
  'admin.users.bulk.invalid.description.plural':
    "{number} user's invalid document was found or the full name exceeds 30 characters! Make sure the number is correct or the maximum length of the full name is respected before adding it.",
  'admin.users.bulk.invalid.btn.download': 'Download users',
  'admin.users.bulk.card.create.title': 'Users created',
  'admin.users.bulk.card.create.title.loading': 'Creating users',
  'admin.users.bulk.card.create.result.without-fail':
    '{successful} users added sucessfully',
  'admin.users.bulk.card.create.result.with-fail':
    '{successful} users added sucessfull and {failed}  failed',
  'admin.users.bulk.card.modify.title': 'Users modified',
  'admin.users.bulk.card.modify.title.loading': 'Modifying users',
  'admin.users.bulk.card.modify.result.without-fail':
    '{successful} users modified',
  'admin.users.bulk.card.modify.result.with-fail':
    '{successful} users modified and {failed} failed',
  'admin.users.bulk.card.delete.title': 'Users removed',
  'admin.users.bulk.card.delete.title.loading': 'Removing users',
  'admin.users.bulk.card.delete.result.without-fail':
    '{successful} user(s) removed',
  'admin.users.bulk.card.delete.result.with-fail':
    '{successful} user(s) removed and {failed} failed',
  'invalid-users.xlsx.name': 'Invalid users',
  'invalid-users.xlsx.filename': 'Invalid-users',
  'invalid-users.columns.first-name': 'Name',
  'invalid-users.columns.last-name': 'Last name',
  'invalid-users.columns.cpf': 'Document',
  'invalid-users.columns.email': 'Email',
  'invalid-users.columns.invalid-motive': 'Invalid',
  'invalid-users.motives.cpf': 'Document',
  'invalid-users.motives.name': 'Complete Name',
  'admin.users.bulk.finish.title': 'Summary',
  'admin.users.bulk.finish.description': 'The following changes will occur:',
  'admin.users.bulk.finish.created.none': 'No users will be created!',
  'admin.users.bulk.finish.created.singular':
    'A new user will be invited to the platform!',
  'admin.users.bulk.finish.created.plural':
    '{number} new users will be invited to the platform!',
  'admin.users.bulk.finish.deleted.none': 'No users will be deleted!',
  'admin.users.bulk.finish.deleted.singular':
    'One user will be deleted from the platform!',
  'admin.users.bulk.finish.deleted.plural':
    '{number} users will be removed deleted from the platform!',
  'admin.users.bulk.finish.modified.none': 'No users will be modified!',
  'admin.users.bulk.finish.modified.singular':
    'One user will be modified on the platform!',
  'admin.users.bulk.finish.modified.plural':
    '{number} users will be modified on the platform!',
  'admin.users.bulk.finish.btn.apply': 'Apply changes',
  'admin.users.bulk.finish.btn.cancel': 'Cancel changes',
  'admin.users.bulk.rgNumber': 'ID number',
  'admin.users.bulk.cnhValidUntil': 'Expiration date (Drivers Licence)',
  'admin.users.passport.number': 'Passport number',
  'admin.users.passport.validUntil': 'Passport expiry date',
  'admin.dashboard.title': 'Dashboard',
  'admin.dates.month': 'Current month',
  'admin.dates.year': 'This year',
  'admin.dates.all': 'All',
  'admin.policy.title': 'Policies',
  'admin.policy.costCenters': 'Cost Centers',
  'admin.policy.projects': 'Projects',
  'admin.policy.groups': 'Groups',
  'admin.policy.observations': 'Observations',
  'admin.policy.approvalRule': 'Approval rule',
  'admin.policy.approvalRules': 'Approval rules',
  'admin.policy.merchants': 'Merchants',
  'admin.policy.motives': 'Motives',
  'admin.policy.tags': 'Tags',
  'admin.policy.delete': 'Delete',
  'admin.policy.costCenter.Add': 'Add',
  'admin.policy.costCenter.Modal.Add': 'Create Cost Center',
  'admin.policy.costCenter.Name': 'Name',
  'admin.policy.costCenter.ExternalId': 'External Identifier',
  'admin.policy.costCenter.Create': 'Create',
  'admin.costCenter.empty': 'Without cost centers',
  'admin.integrations.apiKey.Add': 'Create',
  'admin.integrations.apiKey.Modal.Add': 'API key',
  'admin.integrations.apiKey.Name': 'Name',
  'admin.integrations.apiKey.Description':
    'Save the informations below, because they will only be visible now.',
  'admin.integrations.apiKey.ClientID': 'Client ID',
  'admin.integrations.apiKey.ClientSecret': 'Client Secret',
  'admin.policy.project.Add': 'Add',
  'admin.policy.project.Modal.Add': 'Create Project',
  'admin.policy.project.Name': 'Name',
  'admin.policy.project.ExternalId': 'External Identifier',
  'admin.policy.project.Create': 'Create',
  'admin.policy.refund.Modal.Add': 'Create refund category',
  'admin.policy.refund.Icon': 'Category icon',
  'admin.policy.group.Add': 'Add',
  'admin.policy.group.Modal.Add': 'Manage Group',
  'admin.policy.group.Name': 'Name',
  'admin.policy.group.Username': 'User',
  'admin.policy.motive.Add': 'Add',
  'admin.policy.motive.Modal.Add': 'Create Motive',
  'admin.policy.motive.Name': 'Name',
  'admin.policy.motive.ExternalId': 'External Identified',
  'admin.policy.motive.Create': 'Create',
  'admin.policy.tag.Modal.Add': 'Create Tag',
  'admin.policy.policy': 'Policy',
  'admin.policy.policy.Add': 'Add',
  'admin.policy.policy.Modal.Add': 'Create Policy',
  'admin.policy.policy.Name': 'Name',
  'admin.policy.policy.Sort': 'Priority',
  'admin.policy.policy.Field': 'Information',
  'admin.policy.policy.Comparison': 'Comparative',
  'admin.policy.policy.Hierarchy': 'Hierarchy',
  'admin.policy.policy.Hierarchy.use': 'Use hierarchy',
  'admin.policy.policy.Value': 'Value',
  'admin.policy.policy.Save': 'Save',
  'admin.policy.policy.Group.Add': 'Add Group',
  'admin.policy.policy.Rule.Add': 'Add Rule',
  'admin.policy.policy.Approval.Add': 'Add Approver',
  'admin.policy.empty.cost-center.title': 'No cost centers',
  'admin.policy.empty.cost-center.description':
    "You haven't add any cost center. Add by clicking on the button bellow. ",
  'admin.policy.empty.cost-center.btn': 'Add cost center',
  'admin.policy.empty.project.title': 'No projects added',
  'admin.policy.empty.project.description':
    "You haven't add any cost center. Add by clicking on the button bellow.",
  'admin.policy.empty.project.btn': 'Add project',
  'admin.policy.empty.motive.title': 'No reasons added',
  'admin.policy.empty.motive.description':
    "You haven't add any reason. Add by clicking on the button bellow.",
  'admin.policy.empty.motive.btn': 'Add reasons',
  'admin.policy.empty.tag.title': 'No tag added',
  'admin.policy.empty.tag.description':
    "You haven't any tag added. Add clicking on the button bellow.",
  'admin.policy.empty.tag.btn': 'Add tag',
  'admin.integrations.empty.api-key.title': 'No API Key Added',
  'admin.integrations.empty.api-key.description':
    "You haven't add any API Key. Add by clicking on the button bellow.",
  'admin.integrations.empty.api-key.btn': 'Add API Key',
  'admin.finance.title': 'Financial',
  'admin.finance.invoice': 'Tax Documents',
  'admin.finance.credit_cards': 'Credit Cards',
  'admin.finance.bank3': 'Virtual Credit Card Bank 3',
  'admin.finance.bank3.funds-additon': 'Funds Addition',
  'admin.finance.bank3.available-balance': 'Available Balance',
  'admin.finance.bank3.add-b3-connector': 'Add Bank 3 connector',
  'admin.finance.bank3.final-balance': 'Final Balance',
  'admin.policy.creditCard.Modal.Add': 'Create Credit Card',
  'admin.policy.creditCard.Modal.Update': 'Modify credit card',
  'admin.finance.creditCards.Add': 'Add',
  'admin.policy.creditCard.cardName': 'Internal name',
  'admin.policy.creditCard.documentNumber': "Owner's CPF",
  'admin.policy.creditCard.number': 'Card Number',
  'admin.policy.creditCard.name': 'Name Printed on Card',
  'admin.policy.creditCard.expiry': 'Expiration Date',
  'admin.policy.creditCard.cvv': 'CVV',
  'admin.policy.creditCard.priority': 'Priority',
  'admin.policy.creditCard.cost-centers': 'Associated cost centers',
  'admin.policy.creditCard.products': 'Associated products',
  'admin.policy.placeholder.name': 'YOUR NAME',
  'admin.policy.placeholder.valid': 'valid until',
  'admin.policy.fidelity-number.name': 'Fidelity number',
  'admin.policy.fidelity-name.name': 'Fidelity program',
  'admin.policy.flight-company.name': 'Airline',
  'admin.policy.bank3.Modal.Add': 'Connect to Bank 3',
  'admin.policy.bank3.clientId': 'Client ID',
  'admin.policy.bank3.clientSecret': 'Client Secret',
  'admin.policy.observations.description':
    'Do you allow passengers to write observations when making reservations?',
  'admin.policy.observations.allow': 'Allow observations',
  'admin.policy.bulk.type': 'Values',
  'admin.policy.bulk.name': 'Name',
  'admin.policy.bulk.identifier': 'External identifier',
  'admin.policy.bulk.successful': 'Data imported sucessfully',
  'admin.policy.bulk.created.title': 'Add value',
  'admin.policy.bulk.created.description.singular':
    '1 new value found! Do you want to add it to the platform?',
  'admin.policy.bulk.created.description.plural':
    '{number} new values found! Do you want to add them to the platform?',
  'admin.policy.bulk.modified.title': 'Modify values',
  'admin.policy.bulk.modified.description.singular':
    '1 Value found is already registered! Do you wish to update it?',
  'admin.policy.bulk.modified.description.plural':
    '{number} values found is already registered! Do you wish to update them?',
  'admin.policy.bulk.deleted.title': 'Remove value',
  'admin.policy.bulk.deleted.description.singular':
    '1 value was found that is not in the files, but is registered! Do you want to remove it from the platform?',
  'admin.policy.bulk.deleted.description.plural':
    '{number} values were found that are not in the files, but are registered! Do you want to remove them from the platform?',
  'admin.policy.bulk.finish.title': 'Summary',
  'admin.policy.bulk.finish.description': 'The following changes will be done:',
  'admin.policy.bulk.finish.created.none': 'No value will be added!',
  'admin.policy.bulk.finish.created.singular':
    'A new value will be added to the platform.',
  'admin.policy.bulk.finish.created.plural':
    '{number} values will be added to the platform.',
  'admin.policy.bulk.finish.deleted.none': 'No values will be removed.',
  'admin.policy.bulk.finish.deleted.singular':
    'A value will be removed from the platform!',
  'admin.policy.bulk.finish.deleted.plural':
    '{number} values will be removed from the platform!',
  'admin.policy.bulk.finish.modified.none': 'No values will be modified.',
  'admin.policy.bulk.finish.modified.singular':
    'A value will be modified on the platform!',
  'admin.policy.bulk.finish.modified.plural':
    '{number} values will be modified on the platform!',
  'admin.policy.bulk.invalid.description.singular':
    'Found 1 item whose name is invalid! Make sure it is correct before adding it.',
  'admin.policy.bulk.invalid.description.plural':
    'Found {number} items whose names are invalid! Make sure they are correct before adding them.',
  'admin.kyc.companyTabTitle': 'Company',
  'admin.kyc.companyTabDesc': 'Register',
  'admin.kyc.setupTabTitle': 'Setup',
  'admin.kyc.setupTabDesc': 'Payment',
  'admin.kyc.documentNumber': 'Company Document Number',
  'admin.kyc.legalName': 'Legal Name',
  'admin.kyc.tradingName': 'Trading Name',
  'admin.kyc.foundationDate': 'Foundation Data',
  'admin.kyc.postalCode': 'Postal Code',
  'admin.kyc.street': 'Street',
  'admin.kyc.streetNumber': 'Street Number',
  'admin.kyc.complement': 'Complement',
  'admin.kyc.neighborhood': 'Neighborhood',
  'admin.kyc.city': 'City',
  'admin.kyc.uf': 'UF',
  'admin.kyc.billingEmail': 'Billing E-mail',
  'admin.kyc.phone': 'Phone',
  'admin.kyc.partner.documentNumber': 'Partner Document Number',
  'admin.kyc.partner.legalName': 'Partner Legal Name',
  'admin.kyc.partner.birthdate': 'Birthdate',
  'admin.kyc.partner.phone': 'Phone',
  'admin.kyc.partner.email': 'E-mail',
  'admin.kyc.payment.download': 'Download Instructions',
  'admin.reports.title': 'Reports',
  'admin.reports.summary': 'Summary',
  'admin.reports.expenses': 'Expenses',
  'admin.reports.esg': 'ESG',
  'admin.reports.search-title': 'Search',
  'admin.reports.filter-title': 'Filters',
  'admin.reports.print-title': 'Export report',
  'admin.reports.esg.totalEmittedCo2': 'Total CO<sub>2</sub> emissions',
  'admin.reports.esg.totalSpent': 'Total spent with CO<sub>2</sub> offsetting',
  'admin.reports.esg.totalSpentWithCo2':
    '% of total spent with CO<sub>2</sub> offsetting based on total spent',
  'admin.reports.esg.topSections':
    'Airline streches with most CO<sub>2</sub> emissions',
  'admin.reports.esg.topCostCenter':
    'Cost centers with most CO<sub>2</sub> emissions (t)',
  'admin.reports.esg.topProjects':
    'Projects with most CO<sub>2</sub> emissions (t)',
  'admin.reports.esg.reservations':
    'Reservations and its CO<sub>2</sub> emissions',
  'admin.travels.title': 'Travels',
  'admin.travels.dashboard': 'Dashboard',
  'admin.travels.active': 'Active Travellers',
  'admin.travels.travels': 'Settings',
  'admin.travels.favorite-places': 'Favorite Places',
  'admin.travels.favorite-hotels': 'Favorite Hotels',
  'admin.integrations.api-keys': 'API Keys',
  'admin.others.language': 'Language',
  'admin.others.connectors': 'Connectors',
  'admin.others.open-booking': 'Open Booking',
  'admin.others.params': 'Search params',

  'admin.travels.permissions.description':
    'Select what kind of bookings can a user do in the platform',
  'admin.travels.permissions.description.extra':
    'At least one options must be enabled',
  'admin.travels.advanced_options.products': 'Products',
  'admin.travels.advanced_options.currency': 'Currency',
  'admin.travels.advanced_options.nationality': 'Nationality',
  'admin.travels.advanced_options.currency.description':
    'This setting changes the currency that will be displayed when searching for airfare, hotel and car rental rates using an online quote and approximate values. The amounts charged to your credit card will always be in Brazilian Reais. The reservation, voucher and policies will still be set up in Brazilian Reais',
  'admin.travels.advanced_options.nationality.description':
    'This setting changes the default nationality of passengers when searching for airfare, hotel and car rental rates',
  'admin.data.details': 'Details',
  'admin.data.travel_credits': 'Unused tickets',
  'admin.data.suppliers': 'Suppliers',
  'admin.refunds.title': 'Expenses',
  'admin.refunds.receipts': 'Receipts',
  'admin.refunds.travels': 'Permissions',
  'admin.refunds.categories': 'Budget categories',
  'admin.refunds.cards': 'Cards',
  'admin.refunds.budget': 'Travel expenses',
  'admin.refunds.km': 'Km expenses',
  'admin.refunds.advanced_options': 'Advanced options',
  'admin.refunds.alias': 'Email',
  'admin.esg.title': 'ESG',
  'admin.refunds.permissions.description':
    'In this section you will choose if you wish to allow that users in this organization can use the Expenses module:',
  'admin.refunds.permissions.allow': 'Enable the Expenses module',
  'admin.receipts.permissions.description':
    'Do users need to add a receipt or invoice before they submit a budget for approval?',
  'admin.receipts.permissions.allow': 'Allow for budgets without receipts',
  'admin.receipts.km.description':
    'What is the amount the company will pay per Km:',
  'admin.receipts.card.description':
    'Would you like to use Portão 3 credit cards?',
  'admin.receipts.card.config': 'Configure the connector for card management',

  'admin.receipts.administrative.description':
    'Do you want to allow users to create administrative accounts and pay slips and PIX?',
  'admin.receipts.administrative.config':
    'Allow creation of administrative accounts',

  'admin.refunds.merchants.description':
    'In this section you can choose which establishments your employees can use to create their quotes:',
  'admin.refunds.merchants.PHYSICAL.title': 'Physical cards',
  'admin.refunds.merchants.VIRTUAL.title': 'Virtual cards',

  'admin.automatic.payments.description':
    'Do you want to enable automatic payment of approved refunds via Pix?',
  'admin.automatic.payments.config':
    'Enable automatic payment of approved refunds via Pix',

  'admin.receipts.alias.description':
    'Use an email alias for expense management, making it the easiest way that suppliers can reach you. You can only change it once.',
  'admin.receipts.alias.added.description':
    'You have already added an alias to your email.',
  'admin.settings.title': 'Settings',
  'admin.settings.terms': 'Usage Terms',
  'admin.settings.policy': 'Privacy Policies',
  'admin.settings.esg': 'ESG',
  'admin.settings.travels.description':
    'In this section you can choose whether you want to allow, or not, company or personal travels:',
  'admin.settings.travels.organization-travel': 'Allow company travels',
  'admin.settings.travels.personal-travel': 'Allow personal travels',
  'admin.travels.empty.favorite-places.title': 'No favorite places found',
  'admin.travels.empty.favorite-places.description':
    'You do not have any favorite places added. Add one clicking on the button below.',
  'admin.travels.empty.favorite-places.btn': 'Add a favorite place',
  'admin.travels.empty.favorite-hotels.title': 'No favorite hotels found',
  'admin.travels.empty.favorite-hotels.description':
    'You do not have any favorite hotels added. Add one clicking on the button below.',
  'admin.travels.empty.favorite-hotels.btn': 'Add a favorite hotel',
  'admin.travels.empty.policy.title': 'No policies added',
  'admin.travels.empty.policy.description':
    'You do not have any policies added. There are not approvals configuration for your organization. Add one clicking on the button below:',
  'admin.travels.empty.policy.btn': 'Add policy',
  'admin.travels.empty.group.title': 'No group added',
  'admin.travels.empty.group.description':
    'You do not have any groupd added. Add one clicking on the button below.',
  'admin.travels.empty.group.btn': 'Add group',
  'admin.travels.empty.categories.title': 'No categories added',
  'admin.travels.empty.categories.description':
    'You do not have any budget categories added. Add one clicking on the button below.',
  'admin.travels.empty.categories.btn': 'Add category',

  'admin.travels.open-booking.description':
    'Open Booking allows its employees to make reservations on other sites using a virtual card created after the reservation is approved. Guarantee the best price without losing control of your trips!',
  'admin.travels.open-booking.permissions.allow': 'Allow Open Booking',

  'admin.travels.params.description':
    'Select the parameters that will be selected when a passenger makes a search',

  'admin.travels.params.flight.companies':
    'Which airlines should be selected initially?',

  'admin.travels.params.hotel.categories':
    'Which categories to be selected initially?',
  'admin.travels.params.hotel.amenities':
    'Which amenities to select initially?',

  'admin.travels.params.car.rentals':
    'Quais locadoras devem ser selecionadas inicialmente?',
  'admin.travels.params.car.categories':
    'Which characteristics should be selected initially?',
  'admin.travels.params.car.amenities':
    'Which types should be selected initially?',

  'admin.travels.params.bus.carriers':
    'Which companies should be selected initially?',
  'admin.travels.params.bus.categories':
    'Which characteristics should be selected initially?',

  'admin.travels.open-booking.days-before-expiration':
    'How many days will the employee have to issue the reservation on the chosen site?',

  'admin.refunds.empty.budget.title': 'No budgets added',
  'admin.refunds.empty.budget.description':
    'You do not have any budgets added. Add one clicking on the button below',
  'admin.refunds.empty.budget.btn': 'Add budget',
  'admin.credit-cards.associated-items.title': 'Attached to',
  'admin.credit-cards.associated-items.costCenters': 'Cost Centers',
  'admin.credit-cards.associated-items.products': 'Products',
  'admin.others.languages.description':
    'In this section you can choose the default language shown to this organization passengers:',
  'admin.others.languages.options.title': 'Defaut language',

  'admin.others.connectors.description':
    'In this section you can choose which connectors are allowed in this organization:',

  'admin.others.connectors.flight.title': 'Flight',
  'admin.others.connectors.hotel.title': 'Hotel',
  'admin.others.connectors.car.title': 'Car',
  'admin.others.connectors.bus.title': 'Bus',

  'admin.others.connectors.provider.IDEASFRACTAL': 'P3 Flights',
  'admin.others.connectors.provider.ITERPEC': 'P3 Hotels',
  'admin.others.connectors.provider.OFFLINE_HOTELS': 'Offline reservation',
  'admin.others.connectors.provider.ITERPECCAR': 'P3 Vehicles',
  'admin.others.connectors.provider.CLICKBUS': 'P3 Buses',

  /* Open Booking */
  'open-booking.skip-steps': `I don't want to see this the next time I make an Open Booking reservation`,
  'open-booking.generate-card.description':
    'Skips the approval stage and goes straight to generating the virtual card',
  'open-booking.approval-info.description':
    'This is an Open Booking reservation. Upon approval, a virtual card will be generated and the passenger must go to the website of the chosen company and issue it on their own. Are you sure you want to approve this reservation?',

  'open-booking.action.title': 'Did you find it cheaper on another site?',
  'open-booking.action.description': `We don't want you to lose money. How about using our Open Booking and, in addition to guaranteeing the lowest price, still manage to keep track of your company's trips?`,
  'open-booking.action.btn': 'Create reservation',

  'open-booking.issue-days.singular':
    'After approval, you will have 1 day to issue',
  'open-booking.issue-days.plural':
    'After approval, you will have {days} days to issue',

  'open-booking.modal.description':
    'You are booking an Open Booking offer. Its operation is different from the reservations offered by Portão 3:',

  'open-booking.steps.approval.title': 'Approval',
  'open-booking.steps.approval.description':
    'This reservation will normally go through approval, just like a reservation offered by Portão 3',
  'open-booking.steps.issue.title': 'Issue and payment',
  'open-booking.steps.issue.description':
    'After your approval, a virtual card will be created and you will have to issue it on the partner site. When we receive card transaction notification, the status of this reservation will change to Issued',
  'open-booking.steps.done.title': 'All set!',
  'open-booking.steps.done.description':
    'Finally, after issuing, you must attach a proof of reservation made on the partner site and you can add information regarding the reservation',

  'open-booking.steps.reservation': 'Reservation',
  'open-booking.steps.approval': 'Approval',
  'open-booking.steps.issue': 'Issue',
  'open-booking.steps.receipt': 'Receipt',

  'open-booking.issue-steps.issue.title':
    'Access the <a href="#" target="_blank" id="issue-step-provider-link">chosen website</a> and make the desired reservation',
  'open-booking.issue-steps.use-card.title':
    'Use the card details below to make your reservation:',
  'open-booking.issue-steps.attachment.title':
    'We will be monitoring this card. When we identify that a purchase has been made, the status of that reservation will change to Issued. In addition, you must attach a proof of purchase made on the reservation website.',

  'open-booking.final-steps.generate-receipt.required.title':
    'Now that the issue has been made, you must go to the <a href="#" target="_blank" id="final-step-provider-link">reservation website</a> and generate a receipt containing the data of the same. Your organization has set this step as mandatory, you will be reminded daily until you attach the receipt.',
  'open-booking.final-steps.generate-receipt.title':
    'Now that the issue has been made, you can go to the <a href="#" target="_blank" id="final-step-provider-link">booking website</a> and generate a receipt to attach. it below if you want',
  'open-booking.final-steps.attach-receipt.title':
    'To add the booking voucher, drag it to the component below or click on it to open the file picker',

  'open-booking.final-steps.receipt.drag': 'Reservation receipt',
  'open-booking.final-steps.receipt.drop': 'Drop it here!',

  /* Refund */
  'refund.nav.expenses': 'Requests',
  'refund.nav.refunds': 'Budgets',
  'refund.nav.approvals': 'Approvals',
  'refund.modal.name': 'Name',
  'refund.modal.location': 'Location',
  'refund.modal.description': 'Description',
  'refund.modal.date': 'Date',
  'refund.modal.category': 'Category',
  'refund.modal.all-data': 'Refund info',
  'refund.modal.items': 'Refund items',
  'refund.modal.add-items': 'Add items',
  'refund.modal.no-items-added': 'No items added',
  'refund.modal.start-at': 'Starts in',
  'refund.modal.end-at': 'Ends in',
  'refund.modal.account': 'Personal account',
  'refund.modal.budget': 'Budget',
  'refund.modal.km.items': 'Stops',
  'refund.modal.km.add-items': 'Add Stops',
  'refund.modal.km.no-items-added': 'No stop was added',
  'refund.modal.km.details': 'Details',
  'refund.modal.tabs.budget': 'Budget',
  'refund.modal.tabs.recurrence': 'Recurrence',
  'refund.modal.tabs.payment': 'Payment',
  'refund.modal.refund.all-data': 'Budget informations',
  'refund.modal.refund.items': 'Linked expenses\n',
  'refund.modal.refund.comments': 'Comments',
  'refund.modal.refund.name': 'Budget name',
  'refund.modal.refund.budget': 'Value (BRL)',
  'refund.modal.refund.budget-category': 'Establishment',
  'refund.modal.refund.no-budget-category': 'No Establishments',
  'refund.modal.refund.spend-limit': 'Spending ceiling',
  'refund.modal.refund.add-credit-card': 'Add to credit card',
  'refund.modal.items.refund-type': 'Budget type',
  'refund.modal.items.refund-type.food': 'Food',
  'refund.modal.items.refund-type.fuel': 'Fuel',
  'refund.modal.items.refund-type.transport': 'Transport',
  'refund.modal.items.refund-type.free': 'Free',
  'refund.budget-category.PHYSICAL.FOOD': 'Food',
  'refund.budget-category.PHYSICAL.FUEL': 'Fuel',
  'refund.budget-category.PHYSICAL.MOBILITY': 'Mobility',
  'refund.budget-category.PHYSICAL.NATIONAL': 'National',
  'refund.budget-category.PHYSICAL.NATIONAL_WITHOUT_WITHDRAWALS':
    'National (no withdrawals)',
  'refund.budget-category.PHYSICAL.INTERNATIONAL': 'International',
  'refund.budget-category.VIRTUAL.NATIONAL': 'National',
  'refund.budget-category.VIRTUAL.INTERNATIONAL': 'International',
  'refund.budget-category.VIRTUAL.ADS': 'Ads',
  'refund.budget-category.VIRTUAL.SAAS': 'Saas',
  'refund.budget-category.VIRTUAL.FLIGHT': 'Plane tickets',
  'refund.budget-category.VIRTUAL.HOTEL': 'Hotel',
  'refund.budget-category.VIRTUAL.MOBILITY': 'Mobility',
  'refund.modal.create-refund': 'Create refund',
  'refund.modal.create-payment': 'Create payment',
  'refund.modal.create-recurrency-and-budget': 'Create refund and recurrence',
  'refund.modal.create-just-recurrency': 'Create recurrence',
  'refund.modal.create-expense': 'Create expense',
  'refund.modal.store-modifications': 'Save changes',
  'refund.modal.undo-expense': 'Undo request',
  'refund.modal.expense-items': 'Expense items',
  'refund.modal.cannot-create':
    "Your organization doesn't allow budgets creation",
  'refund.modal.invalid':
    'You need to fill in all required fields to create this budget',
  'refund.budget.description': 'Limit the value a budget can have',
  'refund.modal.alright-refund': 'Everything is alright with your refund!',
  'refund.modal.incomplete-refund': 'Incomplete refund!',
  'refund.modal.alright-expense': 'Everything is okay with your expense!',
  'refund.modal.incomplete-expense': 'Your expense is incomplete!',
  'refund.empty.title': 'No refunds found!',
  'refund.empty.description':
    "You still didn't add a refund. Start using this functionality creating a new refund clicking on the button below:",
  'expenses.empty.title': 'No refund request found!',
  'expenses.empty.none-added': 'No expenses added!',
  'expenses.empty.description':
    "You didn't create a refund request yet. Making your first request by clicking on the button below:",
  'approvals.empty.title': 'No refund request found!',
  'approvals.empty.description':
    'No refund request waiting for approval. Go back later!',
  'refund.modal.items.name': 'Name',
  'refund.modal.items.owner': 'Belongs to',
  'refund.modal.items.unity-price': 'Unit Price',
  'refund.modal.items.quantity': 'Qty',
  'refund.modal.items.total': 'Total',
  'refund.params.start-date': 'Starts in',
  'refund.params.end-date': 'Ends in',
  'refund.params.time-interval': 'Period',
  'refund.params.administrative.time-interval': 'Set date for payment',
  'refund.params.payment-date': 'Payment date',
  'refund.num-items': 'Num. items',
  'expense.modal.all-data': 'Budget informations',
  'expenses.num-items': 'Number of Refund Items',
  'expenses.status': 'Request Status',
  'expense.modal.km.title': 'No stops added',
  'expense.modal.km.description':
    'You can add new stops by searching for an address or city in the map search field on the top of the map!',
  'expenses.placeholder.num-items.singular': '{number} request item',
  'expenses.placeholder.num-items.plural': '{number} request items',
  'expenses.no-refund-added': 'No budget added',
  'expenses.refunds-added': '{refunds} budget(s) added',
  'refund.placeholder.refund-item-name': 'New refund item',
  'refund.placeholder.no-categories': 'No category added',
  'refund.placeholder.no-account': 'No account registered',
  'refund.placeholder.no-budget': 'No budget registered',
  'approval-refund-item.placeholder.commentary':
    'Write a comment about this refund for the passenger',
  'refund.modal.btn.new-expense': 'Create request',
  'refund.modal.btn.cancel-expense': 'Cancel request',
  'refund.modal.btn.send-for-approval': 'Ask for approval',
  'refund.modal.btn.pay': 'Pay',
  'refund.modal.btn.put-on-hold': 'Put on hold',
  'refund.modal.btn.put-on-hold.title':
    'Withdraw the balance of the payment and place it on hold, allowing the creation of another of the same period and category',
  'refund.modal.not-associated': 'This refund is not assigned to any request!',
  'refund.modal.associated':
    'This refund is assigned to the following request:',
  'refund.modal.item-reject': 'This item was rejected by the approver',
  'refund.dialog.send-for-approval.title': 'Request approval',
  'refund.dialog.send-for-approval':
    'This budget is still active! Upon submission for approval, any remaining balance of this quote will be taken from the card associated with it and further transactions will be blocked. Are you sure you want to send it to approval?',
  'refund.expense.approving': 'This request is waiting for approval!',
  'refund.expense.review':
    "There's items in this refund that needs to be revised!",
  'refund.expense.no-items': 'No expenses added to this budget!',
  'refund.expense.review-done':
    'Everything is alright with this request! Send it for approval.',
  'refund.expense.approved': 'Alright! This request was accepted!',
  'refund.expense.rejected': 'Urg! This request was rejected!',
  'refund.expense.cancelling': 'We are cancelling this budget!',
  'refund.expense.cancelled': 'This request was cancelled!',
  'refund.expense.dismissed': 'This request was dismissed!',
  'refund.expense.error': 'An error occurred with this request!',
  'refund.expense.complete-fields':
    'Complete the required fields for your expenses to submit for approval!',
  'refund.expense.waiting-payment': 'This request is awaiting payment!',
  'refund.expense.processing': 'This request is in processing!',
  'refund.expense.paid': 'This request was paid!',

  'refund.administrative-expense.approved':
    'Everything is OK! This payment has been made!',
  'refund.administrative-expense.cancelled': 'This payment could not be made!',
  'refund.administrative-expense.activating': 'We are making the payment...',

  'refund.timeline.title.approval': 'Follow the approval of your budget below.',
  'refund.timeline.title.waiting': 'Follow the status of your budget below.\n',
  'refund.timeline.title.activating': 'Your budget is being activated.',
  'refund.timeline.created': 'Created',
  'refund.timeline.approved': 'Approved',
  'refund.timeline.waiting': 'Waiting for date',
  'refund.expense.overflow':
    'The total exceeds the spending ceiling of the budget!',
  'refund.expense.perKm': '({price} / km)',
  'refund.no-items.title': 'No expense added',
  'refund.no-items.description':
    "You haven't added any expenses to this budget yet. Click on the expense you want to add and drag it to the budget card, which can be found at the top of the page.",
  'refund.no-budget': "This budget don't have a spending limit.",
  'expense.detailed-item.single-item': '{number} item',
  'expense.detailed-item.multiple-items': '{number} items',
  'refund.expense.review-requested':
    'A review for this refund request was requested!',
  'refund.expense.km.review-requested':
    "This route wasn't approved! Change it or cancel the expense",
  'refund.expense.without-expense':
    'This refund is not assigned to any request!',
  'refund.expense.no-refund': "You don't have any unassigned request!",
  'refund.expense.available-refund': 'You have 1 unassigned request!',
  'refund.expense.available-refunds': 'You have {refunds} unassigned requests!',
  'refund.expense.good-score':
    'Well done! {percentage}% of your requests have been approved.',
  'refund.expense.bad-score':
    'Ugh! Only {percentage}% of your requests have been approved.',
  'refund.expense.worse-score':
    'Ugh! None of your requests have been approved.',
  'refund.expense.no-score':
    "You doesn't have any approved or rejected requests yet!",
  'refund.modal.no-receipt': 'No receipt added',
  'refund.modal.no-receipt.description':
    "You didn't add any receipts for this refund",
  'refund.modal.no-categories':
    'Refund categories must be inserted before a refund can be created.<br/>Ask for an admin to insert one in the <b>Policies</b> tab on the administrator panel!',
  'refund.modal.no-categories.admin':
    'Refund categories must be inserted before a refund can be created. Do you want to insert one now?',
  'expense.modal.title': 'No budget registered',
  'expenses.modal.no-refunds': "You doesn't have any refunds created yet!",
  'refund.card.title': 'Add a card',
  'refund.card.subtitle':
    "To add a new card, please read the QR Code on it's back",
  'refund.card.connecting':
    'We are sycing the credit card with your account...',
  'refund.card.replace_user':
    "This card is already associated with another user. We will remove past associations and empty it's budgets. Is that ok?",
  'refund.card.replace_user.action': 'Sync card with me',
  'refund.card.active-budgets':
    'There are active quotes from a user ({passenger}) on this card. Such budgets must be fixed before you can bind him to you!',
  'refund.card.active-budgets.error':
    'It was not possible to validate the budgets for this card. Try again or contact our support!',

  'refund.anticipate-approval.text':
    'The user requested a budget in advance. If accepted, the amount requested will be added in full on the dates he selected. You can approve it or not using the actions below.',
  'expense.type.items': 'Expenses',
  'expense.type.km': 'Km expense',
  'refund.card.create_pin': 'Create a new ping for this card:',
  'refund.card.btn.done': 'Ready!',
  'refund.card.btn.alright': 'Ok!',
  'refund.card.cancel': 'Oops! Something went wrong. Please try again.',
  'refund.card.sucess': 'Yay! Everything is set. Please enjoy your new card!',
  'refund.payment-method.title': 'Add account',
  'refund.payment-method.description':
    'Choose the account method you would like to add to your account.',
  'refund.payment-method.PHYSICAL.title': 'Physical card',
  'refund.payment-method.PHYSICAL.description':
    'A physical card you received via mail by Portão 3. If you still do not have one, please contact our supporr team and request yours now.',
  'refund.payment-method.VIRTUAL.title': 'Virtual card',
  'refund.payment-method.VIRTUAL.description':
    'Virtual cards are automatcally generated cards and the best way to manage your online purchases.',
  'refund.payment-method.PERSONAL.title': 'Personal account',
  'refund.payment-method.PERSONAL.description':
    'Use a personal account to manage payments you did personally, this is ideal to manage expense and refund reports.',

  'refund.payment-method.ADMINISTRATIVE.title': 'Administrative account',
  'refund.payment-method.ADMINISTRATIVE.description':
    'Allows you to pay slips and make transfers via PIX while maintaining budget management.',

  'refund.virtual-card.create.title': 'Create a virtual card',
  'refund.virtual-card.create.description':
    "Give your card a name and choose it's color.",
  'refund.virtual-card.create.card-name': 'Card name',
  'refund.virtual-card.create.color': 'Card color',
  'refund.virtual-card.create.icon': 'Icon',
  'refund.virtual-card.create.too-bright': 'Please choose a darker color',
  'refund.virtual-card.create.do-create': 'Create card',
  'refund.personal-account.create.title': 'Create personal account',
  'refund.personal-account.create.description':
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  'refund.personal-account.create.account-name': 'Account name',
  'refund.personal-account.create.pix-type': 'Key type',
  'refund.personal-account.create.pix': 'PIX Key',
  'refund.personal-account.create.do-create': 'Create account',
  'refund.administrative-account.create.title': 'Create administrative account',
  'refund.administrative-account.create.do-create': 'Create account',
  'pix-types.CPF': 'CPF',
  'pix-types.CNPJ': 'CNPJ',
  'pix-types.EMAIL': 'E-mail',
  'pix-types.PHONE': 'Phone',
  'pix-types.RANDOM': 'Random Key',
  'pix-types.NONE': 'Nenhum',
  'pix-types.EVP': 'Random Key',
  'pix.invalid': 'Invalid PIX',
  'personal-account.bank-info':
    'Bank {bank}, agency {branchCode}, account {accountNumber}, type {accountType}',
  'personal-account.type.checking': 'Conta corrente',
  'personal-account.type.saving': 'Conta poupança',
  'personal-account.type.salary': 'Conta salário',
  'personal-account.type.payment': 'Conta pagamento',
  'cards.card-funds.total': 'Balance',
  'refund.my-cards.add-payment': 'Add account',
  'refund.my-cards.print-report': 'Print report',
  'refund.my-cards.search-card': 'Search for an account',
  'refund.my-cards.all-cards': 'Show all accounts',
  'refund.my-cards.with-funds': 'Show accounts with balance',
  'refund.my-cards.empty.title': 'You do not have any accounts',
  'refund.my-cards.empty.description':
    'Do not worry, you can add a new one on the button above.',
  'refund.refund-budget-card.total': 'Budget total',
  'refund.refund-budget-card.new-expense': 'New expense',
  'refund.refund-budget-card.new-budget': 'New budget',
  'refund.refund-budget-card.create-budget': 'Create budget',
  'refund.refund-budget-card.empty': 'There are no budget items in this budget',
  'refund.refund-budget-card.new':
    'Create a new budget to add balance to this account',
  'refund.refund-budget-card.remaining.one': '+ 1 expense!',
  'refund.refund-budget-card.remaining.multiple':
    '+ {remainingExpenses} expenses!',
  'refund.km-expense.new-stop': 'Add stop',
  'refund.km-expense.search-stop': 'Stop point',
  'refund.km-expense.search-start': 'Starting point',
  'refund.km-expense.search-end': 'Stopping point',
  'refund.km-expense.pop-over':
    'Add a new stop while looking for a city or address in the search field above the map',
  'refund.km-expense.outbound': 'Outbound',
  'refund.km-expense.inbound': 'Inbound',
  'refund.km-expense.info': '{stops} stops added in this item',
  'refund.item-expense.singular-info': '1 item added in this budget',
  'refund.item-expense.plural-info': '{items} items added in this budget',
  'refund.item-expense.refunded': 'Reversed transaction',
  'expense-card.do-blocked': 'Lock card',
  'expense-card.do-unblock': 'Unlock card',
  'expense-card.show-details': 'Show details',
  'expense-card.hide-details': 'Hide details',
  'expense-card.copy': 'Copy credit card number',
  'change-pin.password': 'Type the new pin',
  'change-pin.confirmation': 'Confirm the new pin',
  'change-pin.not-same-password': 'The pins were different',
  'change-pin.submit': 'Change ping',
  'invoice.key': 'Chave de acesso',
  'invoice.number': 'Número NFC-e',
  'invoice.series': 'Série',
  'invoice.protocol': 'Protocolo de autorização',
  'invoice.date': 'Data de autorização',
  'invoice.state': 'Estado',
  'invoice.supplier': 'Estabelecimento',
  'invoice.supplier.cnpj': 'CNPJ',
  'invoice.supplier.address': 'Endereço do estabelecimento',
  'invoice.supplier.cnae': 'CNAE',
  'invoice.consumer': 'Consumidor',
  'invoice.consumer.document': 'Documento',
  'invoice.mcc': 'Código MCC',
  'invoice.merchant-name': 'Nome da transação',
  'invoice.city': 'Cidade',
  'invoice.country': 'País',
  'invoice.value': 'Valor',
  'invoice.merchant-id': 'ID da loja',
  'receipt-expense.upload-stage': 'Saving receipt',
  'receipt-expense.search-stage': 'Searching for barcode',
  'receipt-expense.scan-stage': 'Reading receipt',
  'expense.modal.approval.text':
    'You can either approve, decline or ask for budget review so the user can correct it.',
  'expense.modal.pre-approval.text':
    'You can either approve or decline this budget',
  'expense.comment-placeholder':
    'Write a comment so the user can understand the reason for making this decision',
  'expense.budget-total': 'de {budget}',
  'expense-item.refund.message': 'This transaction has been reversed!',
  'refund.dashboard.cost-center': 'Cost center',
  'refund.dashboard.project': 'Project',
  'refund.dashboard.categories': 'Categories',
  'refund.dashboard.users': 'Users',
  'refund.dashboard.vendors': 'Suppliers',
  'refund.dashboard.empty.title': 'No item of this category was found',
  'refund.dashboard.empty.description':
    'Try to change the dates or add new budgets',
  'refund.card-type.physical': 'Physical',
  'refund.card-type.virtual': 'Virtual',
  'refund.card-type.personal': 'Personal',
  'refund.card-type.administrative': 'Administrative',
  'refund.card.cards-used': 'Accounts used',
  'refund.card.none-used': 'No cards used',
  'refund.card.single-used': '1 card used',
  'refund.card.multiple-used': '{quantity} cards used',
  'refund.dashboard.single-item': '1 item',
  'refund.dashboard.multiple-items': '{quantity} itens',
  'refund.details.by-card': 'Show by accounts',
  'refund.details.by-budget': 'Show by budgets',
  'refund.action-btn.delete': 'Delete receipt',
  'refund.action-btn.external-link': 'Open receipt',

  'budget.sort.title': 'Sort',
  'budget.sort.description':
    'Select the desired sorting type for budgets on this card:',
  'budget.sort.ASC_DATE': 'By date (ascending)',
  'budget.sort.DESC_DATE': 'By date (descending)',
  'budget.sort.CHEAPEST': 'By total (ascending)',
  'budget.sort.EXPENSIVE': 'By total (descending)',
  'budget.sort.STATUS': 'By status',
  'budget.sort.NAME': 'By name',

  'budget.filter.title': 'Filter',
  'budget.filter.description':
    'Select the budget statuses you want to be displayed:',

  'budget.status.ACTIVE': 'Active cards',
  'budget.status.ARCHIVED': 'Archived cards',

  'budget.my-cards.activate-card': 'Activate card',
  'budget.my-cards.activate-account': 'Activate account',
  'budget.my-cards.no-budgets.card':
    'No budgets associated with this card were found. To add new ones, you must activate the card by clicking on the button on the side.',
  'budget.my-cards.no-budgets.account':
    'No budgets associated with this account were found. To add new ones, you must activate the account by clicking on the button on the side.',
  'budget.my-cards.no-budgets':
    'No budgets associated with this account were found.',
  'budget.my-cards.no-expenses':
    'Orçamentos sem despesas vinculadas não podem gerar relatório.',

  // Budget Name Tooltip
  'budget-name-tooltip.days.title': 'Days',
  'budget-name-tooltip.days.description': `To add the day of the month, use the dollar sign followed by the letter 'd'. There are some variations: the lowercase letter represents the day of the month (number), the uppercase the day of the week (in full):`,
  'budget-name-tooltip.days.result-base': 'Subscription',
  'budget-name-tooltip.d.title': 'Subscription $d',
  'budget-name-tooltip.D.title': 'Subscription $D',
  'budget-name-tooltip.DD.title': 'Subscription $DD',
  'budget-name-tooltip.months.title': 'Months',
  'budget-name-tooltip.months.description': `To add the month, use the dollar sign followed by the letter 'm'. There are three variations: the lowercase letter represents the month as a number, the uppercase the name of the month:`,
  'budget-name-tooltip.months.result-base': 'Subscription',
  'budget-name-tooltip.m.title': 'Subscription $m',
  'budget-name-tooltip.M.title': 'Subscription $M',
  'budget-name-tooltip.MM.title': 'Subscription $MM',
  'budget-name-tooltip.years.title': 'Years',
  'budget-name-tooltip.years.description': `To add the year, use the dollar sign followed by the letter 'y' or 'a'. There is only one variation: the lowercase letter represents the current year with two digits. The repetition of the same represents the current year with all the digits:`,
  'budget-name-tooltip.years.result-base': 'Subscription',
  'budget-name-tooltip.y.title': 'Subscription $y',
  'budget-name-tooltip.Y.title': 'Subscription $yy',
  'budget-name-tooltip.a.title': 'Subscription $a',
  'budget-name-tooltip.A.title': 'Subscription $aa',

  // Budget Recurrence
  'budget-recurrence.description':
    'When creating a recurrence, a new budget will be created in the chosen period. Your name, active days, cost center and project will be retained. Additionally, all budgets must pass approval before being activated. For variable names, see our guide',
  'budget-recurrence.interval.title': 'In a specific interval',
  'budget-recurrence.interval.description':
    'This budget must be repeated every',
  'budget-recurrence.interval.sufix.singular': 'day',
  'budget-recurrence.interval.sufix.plural': 'days',
  'budget-recurrence.day.title': 'On a specific day',
  'budget-recurrence.day.description': 'This budget must be repeated every',
  'budget-recurrence.day.sufix.singular': 'of each month',
  'budget-recurrence.day.sufix.plural': 'of each month',

  'budget-recurrence.day.overflow':
    'For months ending before the given day, the budget will be created on the closest day of that month!',

  'budget-recurrence.creation.select-period': 'Select budget period',
  'budget-recurrence.creation.interval':
    'A {period} day budget will be created every {days} day(s)',
  'budget-recurrence.creation.date':
    'A budget of {period} day(s) will be created every {days}',

  'budget-recurrence.time.today': 'Today',
  'budget-recurrence.time.tomorrow': 'Tomorrow',
  'budget-recurrence.time.days': '{days} days left',
  'budget-recurrence.desactivated.time.today': 'Disabled today',
  'budget-recurrence.desactivated.time.yesterday': 'Disabled yesterday',
  'budget-recurrence.desactivated.time.days': 'Disabled {days} days ago',

  // Recurrence budget
  'recurrence-budget.type.date': 'Every {days} of every month',
  'recurrence-budget.type.interval': 'Every {days} day(s)',
  'recurrence-budget.card': 'Card',
  'recurrence-budget.physical-card': 'Last {lastDigits}',

  'recurrence-budget.search': 'Search for budget',

  'recurrence-budget.empty.title': 'You have no recurring budget added!',
  'recurrence-budget.empty.description': `But don't worry, you can create one by setting a repeating period in the Recurrence tab when creating a budget.`,
  'recurrence-budget.empty-search.title': `I didn't find any recurring budget with that name`,
  'recurrence-budget.empty-search.description':
    'Check the desired name and try again!',

  // Recurrence budget modal
  'recurrence-budget-modal.tabs.recurrence': 'Recurrence',
  'recurrence-budget-modal.tabs.history': 'Historic',

  'recurrence-budget-modal.info.name': 'Name',
  'recurrence-budget-modal.info.period': 'Period',
  'recurrence-budget-modal.info.active-days': 'Active days',
  'recurrence-budget-modal.info.active-days.description': '{days} day(s)',
  'recurrence-budget-modal.info.card': 'Card',
  'recurrence-budget-modal.status.next-recorrence':
    'Next recurrence in {days} days(s) ({date})',
  'recurrence-budget-modal.status.deactivated-at': 'Disabled on {date}',
  'recurrence-budget-modal.status.deactivated-at.disabled':
    'Deactivated on {date} and can no longer be activated: {message}',
  'recurrence-budget-modal.status.btn.activate': 'Activate',
  'recurrence-budget-modal.status.btn.deactivate': 'Disable',
  'recurrence-budget-modal.empty-history.title': 'No recurrence created',
  'recurrence-budget-modal.empty-history':
    'No recurrence of this budget has been created yet, the first one will happen on {date}.',

  // Administrative Budget Modal
  'administrative.payment-tab.description':
    'Choose the type of payment that will be made upon approval of this budget:',

  'administrative.payment-option.title': 'Type of payment',
  'administrative.payment-option.pix': 'PIX',
  'administrative.payment-option.boleto': 'Bill',

  'administrative.boleto.number': 'Bill number',
  'administrative.boleto.payee': 'Payee',
  'administrative.boleto.cnpj': 'Payee document',
  'administrative.boleto.payer': 'Payer',
  'administrative.boleto.payer-document': 'Payer document',
  'administrative.boleto.due-date': 'Due date',
  'administrative.boleto.max-value': 'Max amount',
  'administrative.boleto.value': 'Amount to be paid',
  'administrative.boleto.issuer': 'Issue',
  'administrative.boleto.amount': 'Amount',
  'administrative.boleto.to-be-paid.amount': 'Bill value',
  'administrative.boleto.to-be-paid.max-amount': 'Maximum payment amount',
  'administrative.boleto.updated-amount': 'Discounted amount',
  'administrative.boleto.paid-date': 'Paid at',
  'administrative.boleto.auth-code': 'Authentication code',

  'administrative.boleto.error.fetch':
    'It was not possible to obtain the information for this bill. Try again!',

  'administrative.pix.error.invalid':
    'The entered key is not valid. Review it and try again!',
  'administrative.pix.error.fetch':
    'Unable to get this PIX key information. Try again!',

  'administrative.pix.use-key': 'Use key',
  'administrative.pix.key': 'PIX key',
  'administrative.pix.value': 'Amount (R$)',
  'administrative.pix.description': 'Description',

  'administrative.pix.type': 'Key type',
  'administrative.pix.bank-name': 'Bank',
  'administrative.pix.tax-id': 'Tax id',
  'administrative.pix.owner': 'Owner',
  'administrative.pix.branch-code': 'Branch code',
  'administrative.pix.account-number': 'Account number',
  'administrative.pix.paid-date': 'Paid at',
  'administrative.pix.auth-code': 'Transaction ID',

  'administrative.pix.btn.CPF': 'Use CPF',
  'administrative.pix.btn.CNPJ': 'Use CNPJ',
  'administrative.pix.btn.EMAIL': 'Use e-mail',
  'administrative.pix.btn.PHONE': 'Use phone',
  'administrative.pix.btn.RANDOM': 'Use random key',

  'administrative.creator-status.all-right': 'All right with your payment!',
  'administrative.creator-status.select-payment': 'You need to set a payment!',
  'administrative.creator-status.select-total':
    'You need to define an amount to be paid!',

  // Administrative Expense Item
  'administrative-expense-item.not-paid': 'Payment not yet made',
  'administrative-expense-item.payment-info': 'Expense info',
  'administrative-expense-item.paid-info': 'Payment info',

  // History item
  'history-item.status.success': 'Created',
  'history-item.status.fail': 'Failed',

  // Recurrence budget history messages
  'recurrence-budget.history.msg.EXPENSE_CARD_ARCHIVED':
    'The card associated with this budget has been archived',
  'recurrence-budget.history.msg.INVALID_BUDGET_VALUE':
    'The value of this budget is invalid',
  'recurrence-budget.history.msg.MISSING_FIELDS':
    'This budget has fields that were deleted by the organization',

  // Status selector
  'status-selector.ACTIVE': 'Actives',
  'status-selector.ARCHIVED': 'Archived',
  'status-selector.DEACTIVATED': 'Disabled',

  /* Inbox */
  'inbox.scan-email': 'Scan e-mail',

  /* Approvals */
  'approval-orders.single': '1 pending approval',
  'approval-orders.multiple': '{numOrders} pending approvals',
  'approval-orders.none': 'You do not have any pending approvals',
  'approval-orders.show': 'Show more',
  'approval-orders.hide': 'Hide',

  /* 05.Pages */
  'pages.add-new': 'ADD NEW',
  'pages.add-new-modal-title': 'Add New Item',
  'pages.display-options': 'Display Options',
  'pages.orderby': 'Order By :',
  'pages.product-name': 'Product Name',
  'pages.category': 'Category',
  'pages.description': 'Description',
  'pages.status': 'Status',
  'pages.cancel': 'Cancel',
  'pages.submit': 'Submit',
  'pages.delete': 'Delete',
  'pages.another-action': 'Another action',
  'pages.actions': 'ACTIONS',
  'pages.header': 'Header',
  'pages.details': 'DETAILS',
  'pages.orders': 'ORDERS',
  'pages.rating': 'Rating',
  'pages.price': 'Price',
  'pages.ingredients': 'Ingredients',
  'pages.is-vegan': 'Is Vegan',
  'pages.order-status': 'Order Status',
  'pages.bake-progress': 'Bake Progress',
  'pages.popularity': 'Popularity',
  'pages.comments': 'Comments',
  'pages.error-title': 'Ooops...looks like an error occurred!',
  'pages.error-code': 'Error code',
  'pages.go-back-home': 'GO BACK HOME',
  'pages.mailing-info':
    'Mailing templates use inline styling and table layout to be displayed well at various service providers.To provide better usability we include it as regular html with dangerouslySetInnerHTML.',
  'pages.invoice-info':
    'Invoice template has an inline styled version for usage outside of the project as well as React version.',
  'pages.react-version': 'React Version',
  'pages.inline-version': 'Inline Styled Html Version',

  /* Expense card */
  'expense-card-modal.no-category-balance':
    'No categories have an available balance',
  'expense-card-modal.change-password': 'Change password',
  'expense-card-modal.unassign-user': 'Unlink user',
  'expense-card-modal.assign-user': 'Link user',
  'expense-card-modal.block-card': 'Block card',
  'expense-card-modal.unblock-card': 'Unblock card',
  'expense-card-modal.current-balance': 'Current balance',
  'expense-card-modal.category-balance': 'Balance by category',
  'expense-card-modal.choose-user': 'Choose an user to link to this card',
  'expense-card-modal.assign': 'Link',

  /* 06.Date Selection */
  'dateSelection.lifetime': 'Lifetime',
  'dateSelection.pastMonth': 'Last month',
  'dateSelection.today': 'Today',
  'dateSelection.yesterday': 'Yesterday',
  'dateSelection.past7Days': 'Last 7 days',
  'dateSelection.past14Days': 'Last 14 days',
  'dateSelection.past28Days': 'Last 28 days',
  'dateSelection.thisMonth': 'This month',

  /* 06.01.Chat */
  'chat.messages': 'Messages',
  'chat.contacts': 'Contacts',
  'chat.saysomething': 'Say something..',

  /* 06.02.Survey */
  'survey.delete': 'Delete',
  'survey.edit': 'Edit',
  'survey.add-new': 'ADD NEW',
  'survey.add-new-title': 'Add New Survey',
  'survey.title': 'Title',
  'survey.category': 'Category',
  'survey.label': 'Label',
  'survey.status': 'Status',
  'survey.cancel': 'Cancel',
  'survey.submit': 'Submit',
  'survey.another-action': 'Another action',
  'survey.display-options': 'Display Options',
  'survey.orderby': 'Order By :',
  'survey.all-surveys': 'All Surveys',
  'survey.completed-surveys': 'Completed Surveys',
  'survey.categories': 'Categories',
  'survey.active-surveys': 'Active Surveys',
  'survey.labels': 'Labels',

  /* 06.03.Todo */
  'todo.add-new': 'ADD NEW',
  'todo.add-new-title': 'Add New Todo',
  'todo.title': 'Title',
  'todo.detail': 'Detail',
  'todo.category': 'Category',
  'todo.label': 'Label',
  'todo.status': 'Status',
  'todo.cancel': 'Cancel',
  'todo.submit': 'Submit',
  'todo.action': 'Action',
  'todo.another-action': 'Another action',
  'todo.display-options': 'Display Options',
  'todo.orderby': 'Order By : ',
  'todo.all-tasks': 'All Tasks',
  'todo.pending-tasks': 'Pending Tasks',
  'todo.completed-tasks': 'Completed Tasks',
  'todo.categories': 'Categories',
  'todo.labels': 'Labels',

  /* 07.01.Alerts */
  'alert.rounded': 'Rounded Alert',
  'alert.react-notifications': 'React Notifications',
  'alert.outline': 'Outline',
  'alert.primary': 'Primary',
  'alert.secondary': 'Secondary',
  'alert.info': 'Info',
  'alert.success': 'Success',
  'alert.warning': 'Warning',
  'alert.error': 'Error',
  'alert.filled': 'Filled',
  'alert.primary-text': 'This is a primary alert—check it out!',
  'alert.secondary-text': 'This is a secondary alert—check it out!',
  'alert.success-text': 'This is a success alert—check it out!',
  'alert.danger-text': 'This is a danger alert—check it out!',
  'alert.warning-text': 'This is a warning alert—check it out!',
  'alert.info-text': 'This is an info alert—check it out!',
  'alert.light-text': 'This is a light alert—check it out!',
  'alert.dark-text': 'This is a dark alert—check it out!',
  'alert.default': 'Default Alert',
  'alert.dismissing': 'Dismissing Alert',
  'alert.dismissing-text':
    'Holy guacamole! You should check in on some of those fields below.',
  'alert.dismissing-without-animate-text':
    'I am an alert and I can be dismissed without animating!',

  /* 07.02.Badges */
  'badge.sizes': 'Sizes',
  'badge.colors': 'Colors',
  'badge.outline': 'Outline',
  'badge.links': 'Links',
  'badge.counter-badges': 'Counter Badges',
  'badge.bootstrap-default': 'Bootstrap Default',
  'badge.primary': 'Primary',
  'badge.secondary': 'Secondary',
  'badge.success': 'Success',
  'badge.danger': 'Danger',
  'badge.warning': 'Warning',
  'badge.info': 'Info',
  'badge.light': 'Light',
  'badge.dark': 'Dark',

  /* 07.03.Buttons */
  'button.default': 'Bootstrap Default',
  'button.colors': 'Colors',
  'button.rounded': 'Rounded',
  'button.outline': 'Outline',
  'button.states': 'States',
  'button.sizes': 'Sizes',
  'button.button-groups': 'Button Groups',
  'button.large-button': 'Large Button',
  'button.small-button': 'Small Button',
  'button.extra-small-button': 'Extra Small Button',
  'button.block-button': 'Block Button',
  'button.active': 'Active',
  'button.disabled': 'Disabled',
  'button.basic': 'Basic',
  'button.toolbar': 'Toolbar',
  'button.nesting': 'Nesting',
  'button.vertical-variation': 'Vertical Variation',
  'button.checkbox-radio-button': 'Checkbox and Radio Button',
  'button.checkbox': 'Checkbox',
  'button.radio': 'Radio',
  'button.radio-button': 'Radio Button',
  'button.primary': 'Primary',
  'button.secondary': 'Secondary',
  'button.success': 'Success',
  'button.danger': 'Danger',
  'button.warning': 'Warning',
  'button.info': 'Info',
  'button.light': 'Light',
  'button.dark': 'Dark',
  'button.states-text':
    'This button shows a spinner for 2 seconds and an error icon for 3 seconds before switching to normal state.This states can be triggered by adding and removing classes.',
  'button.click-here': 'Click Here',
  'button.states-text-alternate':
    'This button shows a spinner for 2 seconds and an error icon for 3 seconds before switching to normal state.This states can be triggered by adding and removing classes.',
  'button.primary-link': 'Primary Link',
  'button.link': 'Link',
  'button.primary-button': 'Primary Button',
  'button.button': 'Button',
  'button.left': 'Left',
  'button.middle': 'Middle',
  'button.right': 'Right',
  'button.dropdown': 'Dropdown',
  'button.dropdown-link': 'Dropdown Link',
  'button.approval.ask-review': 'Request review',
  'button.approval.approve': 'Approve',
  'button.approval.approve-now': 'Approve immediately',
  'button.approval.approve-now.description':
    'Approve immediately, without the need of approve of any of the others approvers!',
  'button.approval.reprove': 'Reject',
  'refund.approval.title': 'Approve budget',
  'refund.location-message': 'Search for users',
  'refund.location-searching': 'Searching users...',

  /* 07.04.Cards */
  'cards.icon-card': 'Icon Card',
  'cards.image-card': 'Image Card',
  'cards.image-overlay-card': 'Image Overlay Card',
  'cards.image-card-list': 'Image Card List',
  'cards.tab-card': 'Tab Card',
  'cards.user-card': 'User Card',

  /* 07.05.Carousel */
  'carousel.basic': 'Carousel Basic',
  'carousel.single': 'Carousel Single',
  'carousel.without-controls': 'Carousel Without Controls',

  /* 07.06.Charts */
  'charts.line': 'Line Chart',
  'charts.polar': 'Polar Chart',
  'charts.area': 'Area Chart',
  'charts.scatter': 'Scatter Chart',
  'charts.bar': 'Bar Chart',
  'charts.radar': 'Radar Chart',
  'charts.pie': 'Pie Chart',
  'charts.doughnut': 'Doughnut Chart',
  'charts.shadow': 'Shadow',
  'charts.no-shadow': 'No Shadow',

  /* 07.07.Collapse */
  'collapse.basic': 'Basic',
  'collapse.toggle': 'Toggle',
  'collapse.accordion': 'Accordion',
  'collapse.controlled': 'Controlled',
  'collapse.uncontrolled': 'Uncontrolled',

  /* 07.08.Dropdowns */
  'dropdowns.basic': 'Basic',
  'dropdowns.controlled': 'Controlled',
  'dropdowns.uncontrolled': 'Uncontrolled',
  'dropdowns.dropdown': 'Dropdown',
  'dropdowns.header': 'Header',
  'dropdowns.action': 'Action',
  'dropdowns.another-action': 'Another Action',
  'dropdowns.right': 'Right',
  'dropdowns.left': 'Left',
  'dropdowns.drop-directions': 'Drop Directions',
  'dropdowns.dropright': 'Dropright',
  'dropdowns.dropleft': 'Dropleft',
  'dropdowns.small-button': 'Small Button',
  'dropdowns.large-button': 'Large Button',
  'dropdowns.sizing': 'Sizing',
  'dropdowns.split-button': 'Split Button Dropdowns',
  'dropdowns.dropup': 'Dropup',

  /* 07.09.Editors */
  'editors.draftjs': 'Draft.js',
  'editors.quill-standart': 'Quill Standard',
  'editors.quill-bubble': 'Quill Bubble',

  /* 07.10.Forms */
  'forms.action.skip': 'Skip',
  'forms.action.have_code': 'I already have a code',
  'forms.action.register': 'REGISTER',
  'forms.action.login': 'LOGIN',
  'forms.action.reset': 'RESET',
  'forms.action.change': 'CHANGE',
  'forms.action.save': 'SAVE',
  'forms.action.delete': 'REMOVE',
  'forms.action.invite': 'INVITE',
  'forms.action.next': 'NEXT',
  'forms.action.create': 'REGISTER',

  /* 07.10.Forms Validation */
  'forms.validation.email.valid': 'Email is not valid',
  'forms.validation.email.required': 'Email required',
  'forms.validation.email.already-exists': 'Email already exists',
  'forms.validation.password.required': 'Password required',
  'forms.validation.password.weak':
    'Password must contain at least 8 characters',
  'forms.validation.password.confirmation.required':
    'Confirmation is not equal to password',
  'forms.validation.code.required': 'Required code',
  'forms.validation.firstName.required': 'Required name',
  'forms.validation.firstName.invalid': 'Invalid name',
  'forms.validation.lastName.required': 'Last name required',
  'forms.validation.lastName.invalid': 'Invalid last name',
  'forms.validation.phone.invalid': 'Invalid phone number',
  'forms.validation.gender.valid': 'Invalid gender',
  'forms.validation.gender.required': 'Mandatory gender',
  'forms.validation.birthdate.valid': 'Invalid date of birth',
  'forms.validation.birthdate.required': 'Mandatory date of birth',
  'forms.validation.rg.number.required': 'Required identity',
  'forms.validation.rg.emitter.required': 'Mandatory Issuing Body',
  'forms.validation.cpf.required': 'CPF required',
  'forms.validation.cpf.number.valid': 'Invalid CPF',
  'forms.validation.identity.number.valid': 'Invalid ID',
  'forms.validation.rg.number.valid': 'Invalid document',
  'forms.validation.rg.number.empty': 'ID necessary!',
  'forms.validation.emitter.number.empty': 'Issuing organ is required!',
  'forms.validation.cnpj.required': 'CNPJ required',
  'forms.validation.cnpj.number.valid': 'INVALID CNPJ',
  'forms.validation.cnh.validUntil.valid': 'Invalid date',
  'forms.validation.passport.validUntil.valid': 'Invalid date',
  'forms.validation.name.required': 'Required name',
  'forms.validation.cardName.required': 'Required name',
  'forms.validation.documentNumber.required': 'Required document',
  'forms.validation.cardNumber.required': 'Required Card Number',
  'forms.validation.cardNumber.invalid': 'Invalid number',
  'forms.validation.cardNumber.invalid.length':
    'The number must contain 15 or 16 digits',
  'forms.validation.cardExpiry.valid': 'Invalid Expiration Date',
  'forms.validation.cardExpiry.required': 'Required Expiration Date',
  'forms.validation.creditCard.genericError':
    'An error occured while saving the credit card, try again and if the problem persists, contact our support team.',
  'forms.validation.cvv.valid': 'invalid CVV',
  'forms.validation.priority.valid': 'Invalid priority',
  'forms.validation.cvv.required': 'CVV required',
  'forms.validation.priority.required': 'Priority mandatory',
  'forms.validation.clientId.required': 'Required information',
  'forms.validation.clientSecret.required': 'Required information',
  'forms.validation.document.expired': 'Expired document',
  'forms.validation.phone.required': 'Required phone',
  'forms.validation.phone.valid': 'Invalid phone',
  'forms.validation.legalName.required': 'Legal Name required',
  'forms.validation.tradingName.required': 'Trading Name required',
  'forms.validation.foundationDate.required': 'Foundation Date required',
  'forms.validation.postalCode.valid': 'Invalid Postal Code',
  'forms.validation.postalCode.required': 'Postal Code required',
  'forms.validation.street.required': 'Street required',
  'forms.validation.streetNumber.required': 'Street Number required',
  'forms.validation.neighborhood.required': 'Neighborhood required',
  'forms.validation.city.required': 'City required',
  'forms.validation.uf.required': 'UF required',
  'forms.validation.uf.invalid': 'Invalid UF',
  'forms.validation.passport.number.valid': 'Invalid passport number',
  'forms.validation.flight-company.required': 'Airline is required',
  'forms.validation.fidelity-number.required': 'Fidelity number required',
  'forms.validation.fidelity-number.invalid': 'Invalid fidelity number',
  'forms.validation.fidelity-name.required': 'Fidelity program required',
  'forms.validation.fidelity-name.invalid': 'Invalid fidelity program',
  'forms.validation.required': 'Required field',
  'forms.validation.pixtype.invalid': 'Select a valid option',
  'forms.validation.pix.invalid': 'Insert a valid pix key',
  'forms.basic': 'Basic',
  'forms.email': 'E-mail',
  'forms.email-muted': "We'll never share your email with anyone else.",
  'forms.password': 'Password',
  'forms.submit': 'Submit',
  'forms.horizontal': 'Horizontal',
  'forms.radios': 'Radios',
  'forms.first-radio': 'First radio',
  'forms.second-radio': 'Second radio',
  'forms.third-radio-disabled': 'Third disabled radio',
  'forms.checkbox': 'Checkbox',
  'forms.signin': 'Sign in',
  'forms.top-labels-over-line': 'Top Labels Over Line',
  'forms.tags': 'Tags',
  'forms.date': 'Date',
  'forms.state': 'State',
  'forms.top-labels-in-input': 'Top Labels In Input',
  'forms.email-u': 'E-MAIL',
  'forms.password-u': 'PASSWORD',
  'forms.tags-u': 'TAGS',
  'forms.date-u': 'DATE',
  'forms.state-u': 'STATE',
  'forms.grid': 'Form Grid',
  'forms.address': 'Address',
  'forms.address2': 'Address 2',
  'forms.city': 'City',
  'forms.city-message': 'Please select a city!',
  'forms.state-message': 'Please select a state!',
  'forms.zip': 'Zip',
  'forms.signup': 'Signup',
  'forms.inline': 'Inline',
  'forms.validation-availity': 'Availity Reactstrap Validation',
  'forms.validation-formik': 'Formik Validation',
  'forms.default': 'Default',
  'forms.firstname': 'First name',
  'forms.firstname-message': 'Please enter your first name!',
  'forms.lastname': 'Last name',
  'forms.lastname-message': 'Please enter your last name!',

  /* 07.11.Form Components */
  'form-components.custom-inputs': 'Custom Inputs',
  'form-components.checkboxes': 'Checkboxes',
  'form-components.radios': 'Radios',
  'form-components.inline': 'Inline',
  'form-components.react-select': 'React Select',
  'form-components.state-single': 'State Single',
  'form-components.state-multiple': 'State Multiple',
  'form-components.date-picker': 'Date Picker',
  'form-components.date': 'Date',
  'form-components.date-range': 'Date Range',
  'form-components.date-with-time': 'Date with Time',
  'form-components.embedded': 'Embedded',
  'form-components.dropzone': 'Dropzone',
  'form-components.drop-files-here': 'Drop Files Here',
  'form-components.tags': 'Tags',
  'form-components.switch': 'Switch',
  'form-components.primary': 'Primary',
  'form-components.secondary': 'Secondary',
  'form-components.primary-inverse': 'Primary Inverse',
  'form-components.secondary-inverse': 'Secondary Inverse',
  'form-components.slider': 'Slider',
  'form-components.double-slider': 'Double Slider',
  'form-components.single-slider': 'Single Slider',
  'form-components.rating': 'Rating',
  'form-components.interactive': 'Interactive',
  'form-components.readonly': 'Readonly',
  'form-components.type-a-cake': 'Type a Cake',
  'form-components.start': 'Start',
  'form-components.end': 'End',
  'form-components.tables': 'Tables',

  /* 07.12.Icons */
  'icons.simplelineicons': 'Simple Line Icons',
  'icons.iconsmind': 'Iconsmind Icons',

  /* 07.13.Input Groups */
  'input-groups.basic': 'Basic',
  'input-groups.sizing': 'Sizing',
  'input-groups.small': 'Small',
  'input-groups.default': 'Default',
  'input-groups.large': 'Large',
  'input-groups.checkboxes-and-radios': 'Checkboxes and radios',
  'input-groups.multiple-inputs': 'Multiple Inputs',
  'input-groups.first-and-last-name': 'First and last name',
  'input-groups.multiple-addons': 'Multiple Addons',
  'input-groups.button-addons': 'Button Addons',
  'input-groups.button': 'Button',
  'input-groups.buttons-with-dropdowns': 'Buttons with Dropdowns',
  'input-groups.dropdown': 'Dropdown',
  'input-groups.header': 'Header',
  'input-groups.action': 'Action',
  'input-groups.another-action': 'Another Action',
  'input-groups.custom-select': 'Custom Select',
  'input-groups.options': 'Options',
  'input-groups.choose': 'Choose...',
  'input-groups.custom-file-input': 'Custom File Input',

  /* 07.14.Jumbotron */
  'jumbotron.hello-world': 'Hello, world!',
  'jumbotron.lead':
    'This is a simple hero unit, a simple jumbotron-style component for calling extra attention to featured content or information.',
  'jumbotron.lead-detail':
    'It uses utility classes for typography and spacing to space content out within the larger container.',
  'jumbotron.learn-more': 'Learn more',

  /* 07.15.Modal */
  'modal.basic': 'Basic',
  'modal.modal-title': 'Modal title',
  'modal.launch-demo-modal': 'Launch Demo Modal',
  'modal.scrolling-long-content': 'Scrolling Long Content',
  'modal.backdrop': 'Backdrop',
  'modal.backdrop-value': 'Backdrop value',
  'modal.right-modal': 'Right Modal',
  'modal.launch-right-modal': 'Launch Right Modal',
  'modal.nested-modal': 'Nested Modal',
  'modal.size': 'Size',
  'modal.launch-large-modal': 'Launch Large Modal',
  'modal.launch-small-modal': 'Launch Small Modal',
  'dialog.approval.approve.title': 'Approve Request',
  'dialog.approval.reject.title': 'Reprove Request',
  'dialog.approval.review.title': 'Review Request',
  'dialog.approval.approve.msg': 'Do you want to approve this refund?',
  'dialog.approval.reject.msg': 'Do you want to reject this refund?',
  'dialog.approval.review.msg':
    'Do you want to request a review for this refund?',
  'dialog.approval.review.msg.changed':
    'You rejected {rejected} item(s) and wrote {comments} comment(s).',
  'dialog.approval.approve.msg.changed':
    'You rejected {rejected} item(s) and the new request total is {total}.',

  /* 07.16.Navigation */
  'nav.basic': 'Nav Basic',
  'nav.active': 'Active',
  'nav.disabled': 'Disabled',
  'nav.disabled-link': 'Disabled Link',
  'nav.link': 'Link',
  'nav.longer-link': 'Longer nav link',
  'nav.another-link': 'Another Link',
  'nav.right': 'Right',
  'nav.dropdown': 'Dropdown',
  'nav.header': 'Header',
  'nav.action': 'Action',
  'nav.another-action': 'Another Action',
  'nav.horizontal-alignment': 'Nav Horizontal Alignment',
  'nav.vertical-alignment': 'Nav Vertical Alignment',
  'nav.pills': 'Nav Pills',
  'nav.fill-justify': 'Nav Fill and Justify',
  'nav.pills-dropdowns': 'Nav Pills with Dropdowns',
  'nav.pagination-basic': 'Pagination Basic',
  'nav.pagination-sizing': 'Pagination Sizing',
  'nav.large': 'Large',
  'nav.small': 'Small',
  'nav.center': 'Center',
  'nav.pagination-alignment': 'Pagination Alignment',
  'nav.breadcrumb-basic': 'Breadcrumb Basic',

  /* 07.17.Popover & Tooltip */
  'popover-tooltip.popover': 'Popover',
  'popover-tooltip.tooltip': 'Tooltip',

  /* 07.18.Sortable */
  'sortable.columns': 'Sorting Columns',
  'sortable.basic': 'Basic',
  'sortable.handles': 'Handles',

  /* 07.19.Maps */
  'maps.google': 'Google',
  'maps.yandex': 'Yandex',

  /* 07.20.Tables */
  'table.bootstrap-tables': 'Bootstrap Tables',
  'table.bootstrap-basic': 'Basic Table',
  'table.bootstrap-striped': 'Striped Rows',
  'table.bootstrap-bordered': 'Bordered Table',
  'table.bootstrap-borderless': 'Borderless Table',
  'table.bootstrap-hoverable': 'Hoverable Rows',
  'table.bootstrap-responsive': 'Responsive Table',
  'table.react-pagination': 'Pagination',
  'table.react-scrollable': 'Scrollable',
  'table.react-advanced': 'Filter, Length and Jump',
  'error.approval-info.credit-card.register':
    'You need to register a card before continuing',
  'error.approval-info.credit-card.error':
    "The search for this organization cards wasn't possible. Try again!",
  'error.approval-info.credit-card.select':
    'Select a card and the number of parcels',
  'error.approval-info.error': 'Could not perform this action',

  /* 08. Components */
  'components.timeline.modal.reservation-data': 'Booking Data',
  'components.timeline.modal.conditions': 'Description and conditions',
  'components.flight.airportwait.wait':
    'long wait of {hours} in {city} ({station})',
  'components.flight.baggages.bags': 'Backpack or bag',
  'components.flight.baggages.fit.below': 'It should fit under the front seat.',
  'components.flight.baggages.handluggage': 'Hand baggage',
  'components.flight.baggages.fit.above':
    'It must fit in the top compartment of the aircraft.',
  'components.flight.baggages.fit.above.no': 'Not included',
  'components.flight.baggages.dispatch': 'Checked baggage',
  'components.flight.baggages.included':
    'Included {baggageQuantity} from up to {baggageWeight}.Dispatch is done during check-in at the airport.',
  'components.bus.baggages.handluggage': 'Hand luggage',
  'components.bus.baggages.fit.above':
    'The luggage must fit above the passenger seat.',
  'components.bus.baggages.dispatch': 'Luggage to dispatch',
  'components.bus.baggages.included':
    'Included {baggageQuantity} of at most {baggageWeight} which must be checked in the trunk and cannot exceed 100cm x 50cm x 60cm..',
  'components.bus.description': `You must exchange the voucher for the ticket with the passenger's documents • Boarding times are only a prediction • Cancellation can be made up to 5 hours before boarding time by our support center • For tickets already printed, you should consult the bus company directly to make the cancellation • After the boarding date, it is up to the bus company to cancel the tickets or not • Under the terms of ANTT, the carrier is authorized to withhold up to 5% (five percent) of the total amount paid for the road ticket`,
  'components.navs.user-dropdown.profile': 'Profile',
  'components.navs.user-dropdown.logout': 'Logout',
  'components.duplicity.title': 'Double booking',
  'components.duplicity.loading':
    'Checking if there are reservations issued that similar to this one in the same period...',
  'components.duplicity.none':
    'There are no other bookings that happen on the same day as this one!',
  'components.duplicity.has-duplicity':
    'I have identified a booking that takes place at the same period as this',
  'components.duplicity.show-duplicate': 'Go to the reservation',

  /* 09. Containers */
  'containers.active.crisisreport.no-traveler': 'No impacted traveler',
  'containers.active.crisisreport.emergency': 'Emergency Situations',
  'containers.active.details.gate': 'Gate',
  'containers.dashboard.adoption-card.title': 'Adoption',
  'containers.dashboard.adoption-card.registered-travelers':
    'Registered Travelers',
  'containers.dashboard.adoption-card.amount-travelers':
    'Quantity of travellers',
  'containers.dashboard.adoption-card.active-travelers': 'Active Travelers',
  'containers.dashboard.adoption-card.with-travel': 'Travellers with travel',
  'containers.dashboard.card.summary': 'Summary',
  'containers.dashboard.flight-card.title': 'Flights',
  'containers.dashboard.flight-card.best-stretch': 'Top Stretches',
  'containers.dashboard.flight-card.best-companies': 'Top Companies',
  'containers.dashboard.card-summary.total-reservations': 'Total Reserves',
  'containers.dashboard.card-summary.reservations-per-period':
    'Number of bookings in the period',
  'containers.dashboard.flight-card-summary.total-spent': 'Total spend',
  'containers.dashboard.flight-card-summary.flight-total': 'Total in aereo',
  'containers.dashboard.flight-card-summary.average-savings': 'Average Economy',
  'containers.dashboard.flight-card-summary.help-saving':
    'How much we help save',
  'containers.dashboard.flight-card-summary.average-fare': 'Average fare',
  'containers.dashboard.flight-card-summary.fare-average-value':
    'Average rate per booking',
  'containers.dashboard.flight-card-top-airlines.stretches': 'stretches',
  'containers.dashboard.hotel-card.title': 'Hotels',
  'containers.dashboard.hotel-card.best-hotels': 'Top Hotels',
  'containers.dashboard.hotel-card.best-companies': 'Top Brands',
  'containers.dashboard.hotel-card.best-cities': 'Top Cities',
  'containers.dashboard.hotel-card-summary.total-spent': 'Total Spend',
  'containers.dashboard.hotel-card-summary.total-daily': 'Total in daily',
  'containers.dashboard.hotel-card-summary.average-savings': 'Average Economy',
  'containers.dashboard.hotel-card-summary.help-saving':
    'How much we help save',
  'containers.dashboard.hotel-card-summary.average-daily': 'Average daily',
  'containers.dashboard.hotel-card-summary.average-daily-value':
    'Average value per night',
  'containers.dashboard.car-card.title': 'Cars',
  'containers.dashboard.car-card.best-companies': 'Top Airlines',
  'containers.dashboard.bus-card.title': 'Bus',
  'containers.dashboard.bus-card.best-stretch': 'Top journeys',
  'containers.dashboard.bus-card.best-companies': 'Top Airlines',
  'containers.dashboard.small-line-bookings.reservations': 'Reservations',
  'containers.dashboard.small-line-economy.economy': 'Economy',
  'containers.dashboard.small-line-spent.total-spent': 'Total Spent',
  'containers.dashboard.support-card.title': 'Support',
  'containers.dashboard.support-card.average-waiting-time':
    'Average waiting time',
  'containers.dashboard.support-card.average-waiting-time-abbreviation': 'TME',
  'containers.dashboard.support-card.number-conversations':
    'Number of conversations',
  'containers.dashboard.support-card.total-chats': 'Total chats made',
  'containers.dashboard.support-card.feeling': 'Feeling',
  'containers.dashboard.support-card.suport-rating': 'Service rating',
  'containers.esg.card.flight': 'Flights',
  'containers.esg.card.car': 'Cars',
  'containers.esg.card.bus': 'Buses',
  'containers.navs.top-nav.my-travels': 'Travels',
  'containers.navs.top-nav.refunds': 'Expenses',
  'containers.navs.top-nav.data': 'Data',
  'containers.navs.top-nav.admin': 'Admin',
  'containers.policy.group-data-list-view.users': 'user(s)',
  'containers.search.modal.fees': 'Taxes and fees and already included',
  'containers.search.modal.taxes': 'Taxes already included.',
  'containers.search.modal.taxes.payment-at-destination':
    'Subject to payment of part of the value at the time of delivery of the vehicle',
  'conatiner.issue.with.credit': 'Generate ticket with credit',
  'containers.search.modal.book-now': 'Book now',
  'containers.search.car-results.title': 'Vehicle category',
  'containers.search.car-results.features': 'Features',
  'containers.search.car-results.rentals': 'Rentals',
  'containers.search.flight-filters.prices': 'Rates',
  'containers.search.flight-filters.duration': 'Duration (h)',
  'containers.search.flight-filters.no-stop': 'Right',
  'containers.search.flight-filters.stop': 'Stop',
  'containers.search.flight-filters.stops': 'Stops',
  'containers.search.flight-filters.flight-companies': 'Airlines',
  'containers.search.flight-filters.seat-classes': 'Seats',
  'containers.search.flight-filters.departure': 'Departure time',
  'containers.search.flight-filters.outbound-connections':
    'Outbound Connections',
  'containers.search.flight-filters.inbound-connections': 'Inbound Connections',
  'containers.search.flight-filters.connections': 'Connections',
  'containers.search.bus-filters.companies': 'Company',
  'containers.search.flight-modal.taxes': '+ {taxes} of service charges',
  'containers.search.flight-modal.credit': '(-) Credits from ticket {ticket}',
  'containers.search.flight-modal.reservation': 'Book for {numAdults} {adults}',
  'containers.search.flight-modal.valid-until':
    'Reservation valid until {date}',
  'containers.search.flight-modal.baggages-not-included':
    'Does not include checked baggage.',
  'containers.search.flight-modal.cpf-birthdate':
    "The passenger didn't add document or date or birth in his/her account!",
  'containers.search.hotel-filters.price': 'Prices',
  'containers.search.hotel-filters.hotel-name': 'Hotel Name',
  'containers.search.hotel-filters.category': 'Category',
  'containers.search.hotel-filters.no-ratings': 'Unrated',
  'containers.search.hotel-filters.amenities': 'Amenities',
  'containers.search.hotel-modal.breakfast-included': 'Breakfast included',
  'containers.search.hotel-modal.select-room': 'Select a room to book',
  'containers.search.hotel-modal.room-category': 'Room category',
  'containers.search.hotel-modal.main-amenities': 'Main amenities',
  'containers.search.hotel-modal.meal': 'Meals',
  'containers.search.hotel-modal.price-total': 'Rate (total)',
  'containers.search.hotel-modal.hotel-description': 'Hotel description',
  'containers.search.hotel-modal.reservation-policies': 'Reservation policies',
  'containers.search.office-modal.select-room': 'Select a room to book',
  'containers.search.office-modal.room-name': 'Type',
  'containers.search.office-modal.room-size': 'Qtd. People',
  'containers.search.office-modal.price-total': 'Rate (total)',
  'containers.search.office-modal.office-description': 'Office description',
  'containers.search.hotel-proximity-search.address-placeholder':
    'Addresses, locations...',
  'containers.search.package-items.cheaper-option': 'Cheaper option',
  'containers.search.package-items.stay-longer': 'More time at destination',
  'containers.search.package-items.arive-early': 'Arrive early',
  'containers.search.package-search.change-flight': 'Change the flight below',
  'containers.search.package-search.choose-another-flight':
    'Choose another flight',
  'containers.search.package-search.change-hotel': 'Change the hotel below',
  'containers.search.package-search.choose-another-hotel':
    'Choose another hotel',
  'containers.search.reservation-params.company-travel': 'Company travel',
  'containers.search.reservation-params.personal-travel': 'Personal travel',
  'containers.timeline.timeline.hello': 'Hello, {name}!',
  'containers.timeline.timeline.next-trips':
    'Follow your<br/>next trips below.',
  'containers.timeline.timeline.add-trip':
    'Add a trip and start<br/> to ride the trip.',
  'containers.timeline.timeline.app': 'Use our app too',
  'containers.users.data-list-view.incomplete-registration':
    'Incomplete registration',
  'containers.users.data-list-view.admin': 'Admin',
  'containers.timeline.timeline.add-refund':
    'Add a refund and<br/>start creating requests.',
  'containers.timeline.timeline.next-refunds': 'Follow your refunds<br/>below.',
  'containers.timeline.timeline.available-refunds':
    'Select the refunds<br/>you want to add.',
  'containers.users.user-modal.profile': 'Profile',
  'containers.users.user-modal.permissions': 'Permissions',
  'containers.users.user-modal.credit-card': 'Credit Cards',
  'containers.users.user-modal.fidelity': 'Fidelity programs',
  'containers.users.user-modal-credit-card.registered-cards': 'Credit Cards',
  'containers.users.user-modal-credit-card.new-card': 'New Card',
  'containers.users.user-modal-profile-tab.personal-data': 'Personal Data',
  'containers.users.user-modal-profile-tab.company': 'Company',
  'containers.users.user-modal-profile-tab.documents': 'Documents',
  'containers.users.user-modal-profile-tab.passport': 'Passport',
  'containers.users.user-modal-permissions-tab.admin': 'Is admin',
  'containers.users.user-modal-permissions-tab.data.access':
    'May access Data tab',
  'containers.users.user-modal-permissions-tab.data.budget': 'Budgets',
  'containers.users.user-modal-permissions-tab.data.travel': 'Travels',
  'containers.users.user-modal-permissions-tab.esg': 'ESG',
  'containers.users.user-modal-permissions-tab.data.statement': 'Statement',
  'containers.users.user-modal-permissions-tab.timeline':
    'Can see the timeline of other passengers',
  'containers.users.user-modal-permissions-tab.budgets': `Can see other passengers budgets`,
  'containers.version.version-card':
    'Hey! A new version of the web app was launched. Refresh the page or click on the button below to enjoy the new version:',
  'containers.version.version-card.button': 'Refresh',

  // 10. Views
  'permission-toggle-fragment.description':
    'In this section you can choose whether you want to make the addition of this field mandatory when creating reservations or budgets, in addition to being able to define whether the passenger will be able to edit the field if he is already registered:',
  'permission-toggle-fragment.refund-category.description':
    'In this section you can choose whether you want to make category addition mandatory to submit a quote for approval:',
  'permission-toggle-fragment.switch.required.title': 'Make mandatory',
  'permission-toggle-fragment.switch.editable.title': 'Allow editing',

  // 10.1 General View
  'general-view.active-users': 'Active users',
  'general-view.travel': 'Travels',
  'general-view.travel.created-reservations': 'Created reservations',
  'general-view.travel.created-reservations.description': 'Created at the time',
  'general-view.travel.total': 'Total of reservations',
  'general-view.travel.total.description': 'Issued or not',
  'general-view.travel.total-spent': 'Effective spent',
  'general-view.travel.total-spent.description': 'Only issued bookings',
  'general-view.travel.created-reservations-credit': 'Tickets not flown',
  'general-view.travel.created-reservations-credit.description':
    'Total avaliable',
  'general-view.travel.total-credit': 'Total in tickets',
  'general-view.travel.total-credit.description': 'Cost of the original fare',
  'general-view.active-users-credit': 'Applied users',
  'general-view.budget': 'Budget',
  'general-view.budget.created': 'Budgets created',
  'general-view.budget.created.description': 'Created at the period',
  'general-view.budget.approved': 'Approved budgets',
  'general-view.budget.approved.description': 'Approved at the period',
  'general-view.budget.total': 'Budgets cost',
  'general-view.budget.total.description': 'Fisical and virtual cards',
  'general-view.budget.total-spent': 'Total approved',
  'general-view.budget.total-spent.description': 'Of all cards',

  // service: [hotel, voos, carros]
  'views.travel.new-requests.searching':
    'Searching for {service} in {location}',
  'service.package': 'flight and hotel',
  'service.flight': 'flight',
  'service.hotel': 'hotel',
  'service.car': 'car',
  'service.bus': 'bus',
  'service.office': 'office',
  'views.user.onboarding.alright': 'It all worked out!',
  'views.user.onboarding.verify-email':
    'Check your email to create your password',
  'views.user.onboarding.sign-in':
    'Sign up and make sure you start your <b>best experience</b> with travel management.',

  // 11. Alts
  'alts.components.flight.airport-wait': 'long wait at the airport',
  'alts.components.search.hotel-item.hotel-category': 'Hotel category',
  'alts.containers.search.hotel-modal.breakfast': 'Breakfast included',
  'alts.containers.search.hotel-modal.cancellation': 'Free cancellation',
  'alts.containers.search.hotel-proximity-search.erase': 'Delete',
  'alts.containers.search.package-search.station': 'Delete',
  'alts.components.flight.journey.journey': 'Journey',
  'alts.components.flight.journey.duration': 'Duration',
  'alts.components.flight.journey.legs': 'legs',
  'alts.components.travel.total': 'Final rate',
  'alts.components.travel.code': 'Código',
  'alts.components.travel.bookingCode': 'Booking Code',
  'alts.components.search.no-results': 'We found no results',
  'alts.components.timeline.timeline.download': 'Download from the App Store',
  'alts.components.timeline.timeline.available': 'Available on Google Play',
  'alts.components.timeline.flight-card': 'Flights',
  'alts.components.timeline.hotel-card': 'Hotels',
  'alts.components.timeline.office-card': 'Office',
  'alts.components.timeline.car-card': 'Car',
  'alts.components.timeline.bus-card': 'Bus',
  'alts.containers.search.car-form': 'Origin and Destination',
  'alts.containers.search.flight-item': 'Excerpt',
  'alts.containers.search.favorite-hotel': 'Favorite hotel',

  // 12. Placeholder
  'placeholders.components.reports.data-selection': 'Select dates',
  'placeholders.components.reports.search': 'Names, cost centers, projects...',

  // 13. Checklists
  'checklist.help': 'Need help?',
  'checklist.setup': 'Welcome to P3!',
  'checklist.setup.1': 'Make a search in the website',
  'checklist.setup.2': 'Register a credit card',
  'checklist.setup.3': 'Register a cost center',
  'checklist.setup.4': 'Invite users to the website',

  // 14. Reports
  'reports.print.type': 'Type',
  'reports.print.details': 'Details',
  'reports.print.generate': 'Generate file',
  'reports.filters': 'Filters',
  'reports.clear-filters': 'Clean filters',
  'reports.excel.id': 'ID',
  'reports.excel.type': 'Product',
  'reports.excel.passengerName': 'Passenger',
  'reports.excel.passengerEmail': "Passenger's email",
  'reports.excel.requestorName': 'Requester',
  'reports.excel.requestorEmail': "Requester's email",
  'reports.excel.approvers': 'Approvers',
  'reports.excel.whoApproved': 'Who approved',
  'reports.excel.createdAt': 'Date of the reservation',
  'reports.excel.anticipatedPurchase': 'Advance of purchase',
  'reports.excel.costCenter': 'Cost center',
  'reports.excel.costCenterInternalId': 'External id - Cost center',
  'reports.excel.project': 'Project',
  'reports.excel.projectInternalId': 'External id - Project',
  'reports.excel.motive': 'Motive',
  'reports.excel.motiveInternalId': 'External id - Motive',
  'reports.excel.tags': 'Tags',
  'reports.excel.observation': 'Observations',
  'reports.excel.creditCardName': 'Credit card',
  'reports.excel.creditCardId': 'Credit card ID',
  'reports.excel.creditCardInstallments': 'Installments',
  'reports.excel.issueType': 'Type of issue',
  'reports.excel.fare': 'Fare',
  'reports.excel.taxes': 'Fees',
  'reports.excel.additional': 'Additionals',
  'reports.excel.total': 'Total',
  'reports.excel.cheapestAlternative': 'Lowest fare',
  'reports.excel.alternativeDifference': 'Lowest fare difference',
  'reports.excel.alternativeDifferencePercentage':
    '% of lowest fare difference',
  'reports.excel.bookingCode': 'Locator',
  'reports.excel.bookingTicket': 'Ticket ',
  'reports.excel.esgCo2': 'ESG CO2(KG)',
  'reports.excel.esgKm': 'ESG Km',
  'reports.excel.flightCarrier': 'Airline',
  'reports.excel.flightNumJourneys': 'Amount of journeys',
  'reports.excel.flightJourneys': 'Journeys',
  'reports.excel.flightFirstDepartureDate': 'Date of the fist departure',
  'reports.excel.flightFirstDepartureTime': 'Outbound departure time',
  'reports.excel.flightFirstArrivalTime': 'Outbound arrival time',
  'reports.excel.flightFirstDepartureAirport':
    'Airport city of first departure',
  'reports.excel.flightFirstDepartureCity':
    'City of the airport of the fist departure',
  'reports.excel.flightLastDepartureDate': 'Date of the last departure',
  'reports.excel.flightLastDepartureAirport': 'Airport of the last departure',
  'reports.excel.flightLastDepartureTime': 'Inbound departure time',
  'reports.excel.flightLastArrivalTime': 'Inbound arrival time',
  'reports.excel.flightLastDepartureCity':
    'City of the airport of the last departure',
  'reports.excel.flightBases': 'tariff classes',
  'reports.excel.flightFamilyNames': "Classe's decription",
  'reports.excel.flightBaggages': 'Luggage',
  'reports.excel.busFirstDepartureTime': 'Outbound departure time',
  'reports.excel.busFirstArrivalTime': 'Outbound arrival time',
  'reports.excel.busLastDepartureTime': 'Inbound departure time',
  'reports.excel.busLastArrivalTime': 'Inbound arrival time',
  'reports.excel.hotelName': 'Name of the hotel',
  'reports.excel.hotelAddress': 'Address',
  'reports.excel.hotelCity': 'City',
  'reports.excel.hotelCheckIn': 'Check-in',
  'reports.excel.hotelCheckOut': 'Check-out',
  'reports.excel.hotelDailyRate': 'Daily fee',
  'reports.excel.hotelHasBreakfast': 'Breakfast',
  'reports.excel.hotelBoardDescription': 'Pension',
  'reports.excel.hotelAdults': 'Adults',
  'reports.excel.carRental': 'Rental company',
  'reports.excel.carVehicle': 'Vehicle',
  'reports.excel.carType': 'Type ',
  'reports.excel.carVehicleClass': 'Class',
  'reports.excel.carPickUp': 'Date of withdrawal',
  'reports.excel.carPickUpAddress': 'Place of withdrawal',
  'reports.excel.carDropOff': 'Return date\n',
  'reports.excel.carDropOffLocation': 'Return Place',
  'reports.excel.carDailyRate': 'Daily fee',
  'reports.excel.busCarriers': 'Bus company',
  'reports.excel.busNumJourneys': 'Number of bus journeys',
  'reports.excel.busJourneys': 'Bus journeys',
  'reports.excel.busFirstDepartureDate': 'Date of the first bus departure',
  'reports.excel.busLastDepartureDate': 'Late of the last bus departure',
  'reports.excel.busClasses': 'Bus Class',
  'reports.excel.budget.id': 'Budget ID',
  'reports.excel.budget.name': 'Name ',
  'reports.excel.budget.passengerName': 'User',
  'reports.excel.budget.cardType': 'Type',
  'reports.excel.budget.budgetCategory': 'Category',
  'reports.excel.budget.createdAt': 'Created at',
  'reports.excel.budget.startDate': 'Beginnig',
  'reports.excel.budget.endDate': 'End ',
  'reports.excel.budget.anticipationDate': "Creation's advance",
  'reports.excel.budget.budgetValue': 'Budget',
  'reports.excel.budget.total': 'Total spent',
  'reports.excel.budget.wasApproved': 'Approved',
  'reports.excel.budget.statusCodeMessage': 'Status',
  'reports.excel.budget.cardId': 'Cards ID',
  'reports.excel.budget.costCenter': 'Cost center',
  'reports.excel.budget.costCenterInternalId': 'External id - Cost center',
  'reports.excel.budget.project': 'Project',
  'reports.excel.budget.projectInternalId': 'External id - Project',
  'reports.excel.budget.motive': 'Motive',
  'reports.excel.budget.motiveInternalId': 'External id - Motive',
  'reports.excel.budget.tags': 'Tags',
  'reports.excel.budget.observation': 'Observation',
  'reports.excel.budget.cardInfo': 'Card',
  'reports.excel.budget.itemId': 'Item ID',
  'reports.excel.budget.itemName': "Item's Name",
  'reports.excel.budget.itemDate': "Item's date",
  'reports.excel.budget.itemTotal': "Item's cost",
  'reports.excel.budget.itemCategory': "Item's category",
  'reports.excel.budget.itemCategoryInternalId': `External id - "Item's category`,
  'reports.excel.budget.itemType': 'Type of the item',
  'reports.excel.budget.invoiceId': 'ID Ref',
  'reports.excel.budget.receiptLink': 'Receipt link (valid for 7 days)',
  'reports.excel.budget.item-type.items': 'Items',
  'reports.excel.budget.item-type.km': 'Per Km',
  'reports.excel.budget.approved-total': 'Approved value',
  'reports.excel.short.start-date': 'Start',
  'reports.excel.short.end-date': 'End',
  'reports.excel.short.creation-date': 'Done in',
  'reports.details.simple': 'Simple',
  'reports.details.detailed': 'Detailed',
  'reports.details.by-cards': 'By cards',
  'reports.details.by-items': 'By Items',
  'reports.excel.worksheet-name': 'P3 Report',
  'reports.pdf.from': 'From',
  'reports.pdf.to': 'to',
  'reports.json.type': 'type',
  'reports.json.passenger': 'passenger',
  'reports.json.reservation': 'reservation',
  'reports.json.cost-center': 'costCenter',
  'reports.json.project': 'project',
  'reports.json.creation-date': 'creationDate',
  'reports.json.start-date': 'startDate',
  'reports.json.end-date': 'endDate',
  'reports.json.approvers': 'Approvers',
  'reports.json.total': 'total',
  'reports.name': 'P3 Report',
  'reports.credit.name': 'P3 Credit Report',
  'reports.total-value': 'Amount',

  'reports.filter.products': 'Products',
  'reports.filter.passengers': 'Passengers',
  'reports.filter.costCenters': 'Cost Centers',
  'reports.filter.projects': 'Projects',
  'reports.filter.prices': 'Prices',
  'reports.filter.statusCode': 'Status code',
  'reports.filter.structure': 'Travel type',
  'reports.filter.approvers': 'Approvers',
  'reports.filter.reservation-type': 'Reservation types',
  'reports.filter.total': 'Total',
  'reports.filter.project': 'Project',
  'reports.filter.costCenter': 'Cost center',
  'reports.filter.approver': 'Approver',
  'reports.filter.passenger': 'Passenger',
  'reports.filter.card-type': 'Payment type',
  'reports.filter.card-name': 'Card name',
  'reports.filter.budget': 'Budget',
  'reports.filter.user-name': 'User name',
  'reports.filter.budget-status': 'Budget status',
  'reports.filter.pending': 'Pending',

  'reports.filter.status-code.30': 'Reserved',
  'reports.filter.status-code.40': 'Approving',
  'reports.filter.status-code.45': 'Issuing',
  'reports.filter.status-code.46': 'Issuing',
  'reports.filter.status-code.50': 'Issued',
  'reports.filter.status-code.80': 'Error',
  'reports.filter.status-code.85': 'Denied',
  'reports.filter.status-code.95': 'Canceling',
  'reports.filter.status-code.99': 'Cancelled',
  'reports.filter.status-code.100': 'Canceled',
  'reports.filter.status-code.101': 'Expired',
  'reports.filter.status-code.150': 'Dismissed',
  'reports.advanced-filter': 'Advanced',
  'reports.pdf.all-reservations': 'All Reservations',
  'reports.advanced-filter.departureIataCode': 'Departure',
  'reports.advanced-filter.arrivalIataCode': 'Arrival',
  'reports.advanced-filter.roundTrip': 'Round trip?',
  'reports.advanced-filter.category': 'Category',
  'reports.advanced-filter.chain': 'Chain',
  'reports.advanced-filter.hotelName': 'Name',
  'reports.advanced-filter.city': 'City',
  'reports.advanced-filter.carName': 'Model',
  'reports.advanced-filter.rental': 'Rental',
  'reports.advanced-filter.airConditioning': 'Air conditioning',
  'reports.advanced-filter.baggageSize': 'Baggage size',
  'reports.advanced-filter.doors': 'Num. doors',
  'reports.advanced-filter.passengers': 'Num. passengers',
  'reports.advanced-filter.traction': 'Traction',
  'reports.advanced-filter.transmission': 'Transmission',
  'reports.advanced-filter.departureStation': 'Departure',
  'reports.advanced-filter.arrivalStation': 'Arrival',
  'reports.advanced-filter.carType': 'Type',
  'reports.advanced-filter.roomName': 'Reservation type',

  'statement.excel.id': 'ID',
  'statement.excel.amount': 'Amount',
  'statement.excel.currency': 'Currency',
  'statement.excel.merchant_name': 'Merchant Name',
  'statement.excel.merchant_city': 'Merchant City',
  'statement.excel.merchant_country': 'Merchant Country',
  'statement.excel.merchant_id': 'Merchant ID',
  'statement.excel.mcc': 'MCC',
  'statement.excel.response_code': 'Response Code',
  'statement.excel.response_msg': 'Response Message',
  'statement.excel.date_time': 'Date Time',
  'statement.excel.msg_type': 'Message Type',
  'statement.excel.msg_type_description': 'Message Type Description',
  'statement.excel.txn_type': 'Transaction Type',
  'statement.excel.reverses_id': 'Reverses ID',
  'statement.excel.auth_code': 'Auth Code',
  'statement.excel.card_id': 'Card ID',
  'statement.excel.card_last_digits': 'Card Last Digits',
  'statement.excel.card_user_name': 'Card User Name',
  'statement.excel.user_cost_center': 'User Cost Center',
  'statement.excel.user_project': 'User Project',
  'statement.excel.budget_id': 'Budget',
  'statement.excel.budget_name': 'Budget Name',
  'statement.excel.budget_category': 'Budget Category',
  'statement.excel.budget_item_category': 'Budget Item Category',
  'statement.excel.budget_observation': 'Budget Observation',
  'statement.excel.budget_total_amount': 'Budget Total Amount',
  'statement.excel.budget_total_spent': 'Budget Total Spent',
  'statement.excel.order_id': 'Reservation',
  'statement.excel.order_cost_center': 'Reservation Cost Center',
  'statement.excel.original_amount': 'Original Amount',
  'statement.excel.original_currency': 'Original Currency',
  'statement.excel.activates_at': 'Activates At',
  'statement.excel.cancels_at': 'Cancels At',
  'statement.excel.status': 'Status',
  'statement.excel.custom_fields': 'Custom Fields',
  'statement.excel.external': 'External',

  // 15. Bulk Import
  'bulk.type': 'users',
  'bulk.columns.first-name': 'Name',
  'bulk.columns.last-name': 'Last name',
  'bulk.columns.birthdate': 'Birthdate',
  'bulk.columns.identity': 'Identity',
  'bulk.successful': 'Users imported sucessfully!',
  'bulk.description':
    'On format DD/MM/YYYY (After import, day and month are inverted, but DD/MM/YYY will be considered)',
  'bulk.breadcrumbs.columns': ' columns',
  'bulk.breadcrumbs.complete': 'Complete',
  'bulk.breadcrumbs.match': 'Match',
  'bulk.breadcrumbs.problems': ' problems',
  'bulk.breadcrumbs.repair': 'Review',
  'bulk.breadcrumbs.spreadsheet': ' your spreadsheet',
  'bulk.breadcrumbs.upload': 'Upload',
  'bulk.errors.missingRequiredFields':
    'Sorry, there are one or more required fields unmatched: {{fields}}',
  'bulk.btns.back': 'Go back',
  'bulk.btns.cancel': 'Cancel',
  'bulk.btns.clear': 'Clear data',
  'bulk.btns.complete': 'Complete',
  'bulk.btns.confirm': 'Confirm',
  'bulk.btns.confirmMapping': 'Confirm mapping',
  'bulk.btns.continue': 'Continue',
  'bulk.btns.customField': 'Include as custom field',
  'bulk.btns.edit': 'Edit',
  'bulk.btns.ignore': 'Ignore this column',
  'bulk.btns.no': 'No',
  'bulk.btns.ok': 'Ok',
  'bulk.btns.review': 'Review',
  'bulk.btns.yes': 'Yes',
  'bulk.btns.selectAnotherRow': 'Select another row',
  'bulk.btns.noHeaderRow': 'No header row',
  'bulk.clearAllChanges':
    'Are you sure you want to clear all changes to data in progress in this stage?',
  'bulk.dropzone.accepted': '{{types}} spreadsheets accepted.',
  'bulk.dropzone.button': 'Upload data from file',
  'bulk.fileTypes':
    "You can upload any {{file}} file with any set of columns as long as it has 1 record per row. The next step will allow you to match your spreadsheet columns to the right data points. You'll be able to clean up or remove any corrupted data before finalizing your report.",
  'bulk.header': 'Bulk add {{number}} {{thing}}',
  'bulk.header2': 'Bulk add {{thing}}',
  'bulk.lookupMatchingFields': 'Lookup matching fields',
  'bulk.processingPleaseWait': 'Processing, please wait',
  'bulk.readySubmit': 'Are you ready to submit?',
  'bulk.success': 'Success!',
  'bulk.unableToMatch': 'Unable to automatically match',
  'bulk.manual': '...or just manually enter your data here:',
  'bulk.stepOneSuccess': 'File uploaded',
  'bulk.statusLoadedSheets': 'Loaded sheets',
  'bulk.selectSheet': 'Select a sheet',
  'bulk.stepThreeDefault': 'Select {{dataSource}}',
  'bulk.stepThreeSuccess': 'Selected {{dataSource}} "{{dataSourceLabel}}"',
  'bulk.autoMatchedField1': 'Matched to the ',
  'bulk.autoMatchedField2': ' field.',
  'bulk.rowsHaveValue': '{{rows}} of your rows have a value for this column',
  'bulk.validationPass': 'All values pass validation for this field',
  'bulk.stepTwoDefault': 'Loading {{dataSource}}',
  'bulk.question1': 'Does the selected row contain column headers?',
  'bulk.question2': 'Select the row containing column headers',

  // 16. Travels
  'travels.favorite-place.title': 'Add a favorite place',
  'travels.favorite-place.already-saved': 'Place already saved',
  'travels.favorite-place.already-saved.description':
    "You've added this place before. Select an unprecedented place to proceed!",
  'travels.favorite-place.selected': 'Place selected',
  'travels.favorite-place.selected.loading': 'Loading address',
  'travels.favorite-place.nothing': 'Nowhere selected',
  'travels.favorite-place.nothing.description':
    "You haven't selected any places. Seach for an address at the searchbar or click twice in the spot you want to select on the map.",
  'travels.favorite-hotels.title': 'Add favorite hotels',
  'travels.favorite-hotels.show-favorites': 'Show favorites',
  'travels.favorite-hotels.none-selected': 'No hotels selected',
  'travels.favorite-hotels.none-selected.description':
    'You have not selected any hotel yet. Search for an address or city on the map and then bookmark the hotels found near it.',
  'travels.favorite-hotels.searching': 'Seaching for hotels',
  'travels.favorite-hotels.searching.description':
    'Seaching for hotels near {address}',
  'travels.favorite-hotels.nothing-found': 'No hotels found',
  'travels.favorite-hotels.nothing-found.description':
    'Try searching for another address or search for the desired hotel name. Always remember to add the desired city at the end of the address!',

  // 17. Refund Categories
  'refund.category.placeholder': 'Select an icon for this category',
  'refund.category.car': 'Car',
  'refund.category.hotel': 'Hotel',
  'refund.category.flight': 'Flight',
  'refund.category.food': 'Meal',
  'refund.category.fuel': 'Fuel',
  'refund.category.lazer': 'Lazer',
  'refund.category.money': 'Money',
  'refund.category.apple': 'Fruit',
  'refund.category.bank': 'Bank',
  'refund.category.boat': 'Boat',
  'refund.category.building': 'Building',
  'refund.category.building2': 'Building 2',
  'refund.category.building3': 'Building 3',
  'refund.category.bus': 'Bus',
  'refund.category.bulb': 'Bulb',
  'refund.category.church': 'Church',
  'refund.category.clothes': 'Clothes',
  'refund.category.coffee': 'Coffee',
  'refund.category.coffee2': 'Coffee 2',
  'refund.category.coin': 'Coin',
  'refund.category.doctor': 'Doctor',
  'refund.category.drink': 'Drink',
  'refund.category.globe': 'Globe',
  'refund.category.health': 'Health',
  'refund.category.industry': 'Industry',
  'refund.category.law': 'Law',
  'refund.category.syringe': 'Syringe',
  'refund.category.taxi': 'Taxi',
  'refund.category.wine': 'Wine',
  'refund.category.suitcase': 'Suitcase',
  'refund.expense.cancel': 'Cancel request and deletes the budget',
  'refund.expense.undo': 'Cancel request but keep budgets',
  'refund.not-without-receipt':
    'Your organization demands that all expenses must have a receipt',
  'refund.require-category':
    'Your organization requires that all expenses have a category',
  'expense.expense-items.reject': 'Exitem itens rejeitados nesse orçamento',
  'expense.expense-items.comment': 'Existe um comentário nesse orçamento',
  'refund.approver-comment': 'Comentário do aprovador',
  'approval-card.review':
    'Você precisa rejeitar um item ou fazer um comentário para pedir uma revisão!',
  'approval-card.all-rejected':
    'Você não pode rejeitar todos os itens e depois aprovar a solicitação. Tente reprovar a solicitação!',
  'refund.invalid': 'Esta despesa já está associada a um orçamento!',
  'budget-modal.download-receipts': 'Baixar todos os recibos deste orçamento',
  'budget-modal.administrative.download-receipts': 'Download payment receipt',
  'notify-approvers.title': 'Reenviar solicitação de aprovação',
  'notify-approvers.title.success': 'Solicitações enviadas com sucesso!',
  'users.bulk-import.loading': 'Making changes to users',
  'reservation.invalid-fields':
    'You have not filled in all required fields. Make sure all fields with * (asterisk) are filled in!',
  'reservation.invalid-fare-access':
    'This passenger cannot travel with this fare, please select the cheapest one!',

  // 19. Modals
  'modal.yes': 'Yes',
  'modal.no': 'No',
  'modal.ok': 'Ok',

  // 20. Budget
  'budget.modal.title': 'Adicionar orçamento',
  'budget.modal.new-item': '+ Nova despesa',
  'budget.category.food': 'Food',
  'budget.category.fuel': 'Gas',
  'budget.category.transport': 'Transportation',
  'budget.category.free': 'Free',
  'budget.category.FOOD': 'Food',
  'budget.category.FUEL': 'Gas',
  'budget.category.TRANSPORT': 'Transportation',
  'budget.category.FREE': 'Free',
  'budget.category.MOBILITY': 'Mobility',
  'budget.category.NATIONAL': 'National',
  'budget.category.INTERNATIONAL': 'International',
  'budget.specificity.none': 'None',
  'budget.specificity.group': 'Group',
  'budget.specificity.costCenter': 'Cost center',
  'budget.specificity.project': 'Project',
  'budget.specificity.motive': 'Motive',
  'budget.specificity.tag': 'Tag',
  'budget.aplliedTo': 'Applied to {type}',
  'budget.phrase.1': 'Ao aprovar',
  'budget.phrase.2': 'De um passageiro com a seguinte especificidade',
  'budget.phrase.3.singular': 'De nome',
  'budget.phrase.3.plural': 'De nomes',
  'budget.phrase.4': 'Criar o orçamento',
  'budget.phrase.daily': 'daily to',
  'budget.phrase.add-rule': 'New rule',
  'budget.phrase.create-budget':
    'Crie regras orçamentárias definindo um valor que será adicionado ao cartão do passageiro todos os dias, de acordo com uma categoria MCC (Merchant category code).',
  'receipt-expense.items': 'Itens da despesas',
  'receipt-expense.invoice-info': 'Dados da nota fiscal',
  'receipt-expense.transaction-info': 'Dados da transação',
  'receipt-expense.supplier-info': 'Dados do estabelecimento',
  'receipt-expense.discount': 'Desconto de',
  'balance-card.balance': 'Balance',
  'balance-card.active-balance': 'Balance in accounts',
  'balance-card.needed-balance': 'Required balance for future budgets',
  'balance-card.periods.next-days': 'Next {days} days',
  'balance-card.cash-data.title': 'Dados monetários',
  'balance-card.empty-data.title': 'Não há orçamentos pendentes nesse período',
  'balance-card.empty-data.description':
    'Você pode selecionar outros períodos utilizando o campo acima',
  'balance-card.refresh': 'Atualizar valores',
  'budget.notifications.INTERNAL_ERROR':
    'Não foi possível ativar este orçamento, uma nova tentativa será feita em breve! Para maiores informações contate nosso suporte.',
  'budget.notifications.CREDIT_CARD_UNAUTHORIZED':
    'A conta associada não tinha fundos suficientes para que este orçamento fosse ativado!',
  'budget.notifications.RESERVATION_FAILED':
    'Um erro aconteceu com este orçamento. Entre em contato com nosso suporte para mais detalhes!',
  'budget.notifications.BUDGET_INTERSECTION':
    'Este orçamento ocorre no mesmo período de outro de mesmo estabelecimento!',
  'budget.notifications.CODE_429':
    'Não foi possível ativar este orçamento! Uma nova tentativa será feita em até uma hora.',
  'budget.notifications.CANCELLATION_FAILED':
    'Não foi possível cancelar este orçamento! Tente novamente ou entre em contato com nosso suporte.',
  'budget.notifications.SEND_FOR_APPROVAL_FAILED':
    'Não foi possível enviar este orçamento para a aprovação! Tente novamente ou entre em contato com nosso suporte.',
  'budget.notifications.INVALID_DATE':
    'Orçamentos de cartões físicos ou virtuais só podem ser criados se seu período for posterior ou igual a data atual.',
  'budget.notifications.CANCELATION_ERROR':
    'Não foi possível cancelar este orçamento. Faça uma nova tentativa ou entre em contato com nosso suporte!',

  'budget.notifications.FORBIDDEN_ADMINISTRATIVE':
    'Your organization does not allow the creation of administrative budgets!',
  'budget.notifications.ADMINISTRATIVE_DUE_DATE':
    'The payment deadline for this budget has passed. Your payment could not be made!',
  'budget.notifications.PAYMENT_FAILED':
    'It was not possible to pay this budget. Try again!',
  'budget.notifications.PUT_ON_HOLD_FAILED':
    'We were unable to put this budget on hold. Try again!',

  'budget.policy.anticipation-approval': 'Aprovação de Antecipação',
  'budget.policy.approval': 'Aprovação',

  // 21. Comparatives
  'comparatives.label.=': 'equals to',
  'comparatives.label.!=': 'different than',
  'comparatives.label.>': 'greater than',
  'comparatives.label.>=': 'greather than or equals to',
  'comparatives.label.<': 'lower than',
  'comparatives.label.<=': 'lower than or equals to',

  // 22. Filters
  'input-filter.add-new': 'Add filters',
  'input-filter.field': 'Field',
  'input-filter.comparisson': 'Comparison',
  'input-filter.value': 'Value',

  // 23. Card options
  'card-option.block.blocked': 'Lock cartão',
  'card-option.block.unblocked': 'Unlock cartão',
  'card-option.password': 'Change password',
  'card-option.pan.show': 'Show information',
  'card-option.pan.hide': 'Hide information',
  'card-option.options': 'More options',
  'card-option.archive': 'Archive account',

  // 24. Inbox
  'read-qrcode': 'Read QR Code',
  'num-attachments.empty': 'No attachment',
  'num-attachments.one': '1 attachment',
  'num-attachments.multiple': '{number} attachements',
  'sort-by.title': 'Order by',
  'sort-by.most-recent': 'Newest',
  'sort-by.oldest': 'Oldest',
  'sort-by.read': 'Read',
  'sort-by.unread': 'Unred',
  'budget-selection.empty-account':
    'You do not have an account. That is ok, click on the button below to add one:',
  'budget-selection.title':
    'Select an account and budget to create the expense:',
  'empty-box.title': 'Inbox empty',
  'empty-box.description': 'Send ot forward emails to your address',

  // 25. CNAE
  'cnae.01': 'Agriculture, livestock and related',
  'cnae.02': 'Forest production\n',
  'cnae.03': 'fishing and aquaculture',
  'cnae.05': 'Coal extraction',
  'cnae.06': 'Extraction of oil and natural gas',
  'cnae.07': 'Extraction of metallic minerals',
  'cnae.08': 'Extraction of non-metallic minerals',
  'cnae.09': 'Activity to support mineral extraction',
  'cnae.10': 'manufacturing of food products',
  'cnae.11': 'beverage manufacturing',
  'cnae.12': 'Manufacture of tobacco products',
  'cnae.13': 'Manufacture of textile products',
  'cnae.14': 'Manufacture of clothing and accessories',
  'cnae.15':
    'Preparation of hides and manufacture of leather goods, travel items and footwear',
  'cnae.16': 'Manufacture of wooden products',
  'cnae.17': 'Manufacture of pulp, paper and paper products',
  'cnae.18': 'Printing and playing back recordings',
  'cnae.19': 'Manufacture of coke, petroleum products and biofuels',
  'cnae.20': 'Chemicals manufacturing',
  'cnae.21': 'Manufacture of pharmochemicals and pharmaceuticals',
  'cnae.22': 'Manufacture of rubber and plastic material products',
  'cnae.23': 'Manufacture of non-metallic mineral products',
  'cnae.24': 'Metallurgy',
  'cnae.25': 'Manufacture of metal products, except machinery and equipment',
  'cnae.26':
    'Manufacture of computer equipment, electronic and optical products',
  'cnae.27': 'Manufacture of machines, appliances and electrical materials',
  'cnae.28': 'Manufacture of machinery and equipment',
  'cnae.29': 'Manufacture of motor vehicles, trailers and bodies',
  'cnae.30': 'Manufacture of other transport equipment, except motor vehicles',
  'cnae.31': 'furniture manufacturing',
  'cnae.32': 'Manufacture of various products',
  'cnae.33': 'Maintenance, repair and installation of machinery and equipment',
  'cnae.36': 'Collection, treatment and distribution of water',
  'cnae.37': 'Sewage and related activities',
  'cnae.38': 'Collection, treatment and disposal of waste; material recovery',
  'cnae.39': 'Decontamination and other waste management services',
  'cnae.41': 'building construction',
  'cnae.42': 'Infrastructure works',
  'cnae.43': 'Specialized services for construction',
  'cnae.45': 'Sale and repair of motor vehicles and motorcycles',
  'cnae.46': 'Wholesale trade, except motor vehicles and motorcycles',
  'cnae.47': 'Retail business',
  'cnae.49': 'Ground transportation',
  'cnae.50': 'Water transportation',
  'cnae.51': 'Air Transport',
  'cnae.52': 'Storage and auxiliary transport activities',
  'cnae.53': 'Mail and other delivery activities',
  'cnae.55': 'Accommodation',
  'cnae.56': 'Food',
  'cnae.58': 'Print-integrated editing and editing',
  'cnae.59':
    'Film activities, video and television program production; sound recording and music editing',
  'cnae.60': 'Radio and television activities',
  'cnae.61': 'Telecommunications',
  'cnae.62': 'Information technology services activities',
  'cnae.63': 'Information service provision activities',
  'cnae.64': 'financial services activities',
  'cnae.65': 'Insurance, reinsurance, supplementary pension and health plans',
  'cnae.66':
    'Auxiliary activities of financial services, insurance, supplementary pension and health plans',
  'cnae.68': 'real estate activities',
  'cnae.69': 'Legal, accounting and auditing activities',
  'cnae.70':
    'Corporate headquarters and business management consulting activities',
  'cnae.71':
    'architectural and engineering services; technical testing and analysis',
  'cnae.72': 'Scientific research and development',
  'cnae.73': 'Advertising and market research',
  'cnae.74': 'Other professional, scientific and technical activities',
  'cnae.75': 'veterinary activities',
  'cnae.77':
    'Non-real estate rentals and management of non-financial intangible assets',
  'cnae.78': 'Selection, agency and leasing of labor',
  'cnae.79': 'Travel agencies, tour operators and booking services',
  'cnae.80': 'Surveillance, security and investigation activities',
  'cnae.81': 'Services for buildings and landscape activities',
  'cnae.82':
    'Office services, administrative support and other services provided primarily to businesses',
  'cnae.84': 'Public administration, defense and social security',
  'cnae.85': 'Education',
  'cnae.86': 'Human health care activities',
  'cnae.87':
    'Human health care activities integrated with social assistance, provided in collective and private residences',
  'cnae.88': 'Welfare services without housing',
  'cnae.90': 'Artistic, creative and entertainment activities',
  'cnae.91': 'Activities related to cultural and environmental heritage',
  'cnae.92': 'Gambling and betting exploration activities',
  'cnae.93': 'Sports, recreation and leisure activities',
  'cnae.94': 'Activities of associative organizations',
  'cnae.95':
    'Repair and maintenance of computer and communication equipment and personal and domestic objects',
  'cnae.96': 'Other personal service activities',
  'cnae.97': 'Domestic services',
  'cnae.99': 'International bodies and other extraterritorial institutions',
  'cnae.unknown': 'Unknown',

  // Cancellation motives
  'cancellation.motive.title':
    'Describe the reason you want to can the hotel so we can try for the charges to be dismissed:',
  'cancellation.motive.placeholder': 'What is the reason for the cancel?',
  'cancellation.error':
    'It was not possible to cancel this reservation. Please try again later or contact our support.',
  'cancellation.input.placeholder': 'Selec the reason for the cancellation',
  'cancellation.button.free': 'Cancel booking for free',
  'cancellation.button.without-refund': 'Cancel booking with no refunds',
  'cancellation.button.with-refund':
    'Cancel booking and request fees to be dismissed',
  'cancellation.other': 'Other',
  'cancellation.hotel.health':
    'The passenger will not be able to fly because of health issues',
  'cancellation.hotel.unforeseen':
    'The schedule for this travel have changed and the passenger will no longer be in town for these dates',
  'cancellation.hotel.mistake':
    'We issued this by mistake and we only realized it now',

  // Others
  'support.title': 'Support',
  'generate-vouchers.title': 'Generate vouchers for issued bookings',

  // Open Booking
  'open-booking.providers.123MILHAS': '123 Milhas',
  'open-booking.providers.BOOKING': 'Booking.com',
  'open-booking.providers.KIWI': 'Kiwi',
  'open-booking.providers.MAX_MILHAS': 'Maxmilhas',
  'open-booking.providers.LATAM': 'Latam',
  'open-booking.providers.DECOLAR': 'Decolar.com',
  'open-booking.providers.EXPEDIA': 'Expedia',
  'open-booking.providers.GOL': 'Gol',
  'open-booking.providers.AZUL': 'Azul',
  'open-booking.providers.OTHER': 'Outro',

  'open-booking.providers.short.123MILHAS': 'milhas',
  'open-booking.providers.short.MAX_MILHAS': 'milhas',
  'open-booking.providers.short.DECOLAR': 'decolar',
  'open-booking.providers.short.GOL': 'GOL',
  'open-booking.providers.short.LATAM': 'LATAM',

  'open-booking.providers.offer.OTHER': 'Reservation made on another website',
  'open-booking.providers.offer': 'Website offer:',

  'open-booking.modal.badge,providers.title': 'This is an offer:',

  // File Uploader
  'file-uploader.invalid-types': 'Files with invalid formats',
  'file-uploader.allowed-types': 'Allowed types:',
  'file-uploader.drop-receipts': 'Drop the receipts for that budget here',
  'file-uploader.drag-receipts':
    'Click here or drag the files of receipts for this budget',

  'search.hotelSuggestionModal.title':
    'Excellent, your reservation request has been sent successfully!',
  'search.hotelSuggestionModal.options': 'See also accommodation options at:',
  'search.hotelSuggestionModal.options.locale':
    'Select an accommodation option to make your reservation.',
  'search.hotelSuggestionModal.reserve': 'Book with coffee',
  'search.clear-search': 'Clear search',

  //NPS
  'nps.questions': 'How likely are you to recommend Portão 3 to others?',
  'nps.thanks': 'Thank you for sharing your feedback with us!',
  'nps.followUp': 'Would you mind telling us why you chose a ',
  'nps.placeholder': 'Tell us a little better if possible',
  'nps.dismiss': 'Dismiss',
  'nps.submit': 'Submit',
  'nps.min.label': 'Not likely',
  'nps.max.label': 'Very likely',

  // Search params
  'search-params.hotel.categories.0': 'Without category',
  'search-params.hotel.categories.1': '1 star',
  'search-params.hotel.categories.2': '2 stars',
  'search-params.hotel.categories.3': '3 stars',
  'search-params.hotel.categories.4': '4 stars',
  'search-params.hotel.categories.5': '5 stars',

  'search-params.hotel.amenities.babá_ou_creche': 'Nanny or daycare',
  'search-params.hotel.amenities.café_da_manhã': 'Breakfast',
  'search-params.hotel.amenities.internet': 'Internet',
  'search-params.hotel.amenities.lavanderia': 'Laundry',
  'search-params.hotel.amenities.restaurante': 'Restaurant',
  'search-params.hotel.amenities.estacionamento': 'Parking',
  'search-params.hotel.amenities.piscina': 'Pool',
  'search-params.hotel.amenities.academia': 'Gym',
  'search-params.hotel.amenities.spa': 'Spa',
};
