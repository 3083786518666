import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Row, Label, Button, Alert } from 'reactstrap';
import { Colxx } from '../../../common/CustomBootstrap';
import IntlMessages from '../../../../helpers/IntlMessages';
import Select from 'react-select';
import map from 'lodash/map';
import find from 'lodash/find';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useIntl } from 'react-intl';
import firebaseApp from '../../../../services/Firebase';
import { getInstallmentsText } from '../utils';
import PaymentAtDestinationMessage from '../Others/PaymentAtDestinationMessage';

export default function PersonalApproval({
  order,
  goForApproval,
  requestToIssue,
  requestToReject,
  error,
  setError,
  ongoingAction,
  shouldDisableButtons,
}) {
  const { messages, formatMessage } = useIntl();
  const { user } = useSelector((state) => state.auth);

  const [creditCards, setCreditCards] = useState([]);
  const [selectedCard, setSelectedCard] = useState(null);
  const [paymentOptions, setPaymentOption] = useState([]);
  const [installments, setInstallments] = useState(1);

  useEffect(() => {
    getCreditCards();
  }, []);

  useEffect(() => {
    if (selectedCard) loadPayments();
  }, [selectedCard]);

  // Credit Cards
  const getCreditCards = async () => {
    try {
      const creditCardsSnap = await firebaseApp
        .getCreditCardsFromUser(user.uid)
        .get();

      const creditCards = [];
      creditCardsSnap.forEach((creditCardSnap) => {
        creditCards.push({
          ...creditCardSnap.data(),
          id: creditCardSnap.id,
        });
      });

      setCreditCards(creditCards);

      if (creditCards.length > 0) {
        setSelectedCard(
          find(creditCards, (card) => {
            return card.default === true;
          })?.id || creditCards[0].id
        );
      } else {
        setError(messages['error.approval-info.credit-card.register']);
      }
    } catch (err) {
      console.error(err);
      setError(messages['error.approval-info.credit-card.register']);
    }
  };

  // Payments
  const loadPayments = async () => {
    const paymentOptionsResponse =
      await firebaseApp.getPaymentOptionsFromOrderAndCardId({
        orderId: order.id,
        cardId: selectedCard,
      });

    setPaymentOption(paymentOptionsResponse.options);
  };

  // Render
  return (
    <div className="approval-steps h-100">
      <PerfectScrollbar
        options={{
          suppressScrollX: true,
          wheelPropagation: false,
        }}
      >
        <h3 className="hotel-title">{messages['travel.issue-trip']}</h3>

        <h5 className="mt-4">{messages['travel.payment']}</h5>
        {error && <Alert>{error}</Alert>}
        <Row className="payment-options">
          <Colxx xxs="6">
            <Label className="form-group search reservation has-top-label">
              <Select
                className="react-select"
                classNamePrefix="react-select"
                isClearable={false}
                placeholder=""
                value={{
                  value: selectedCard || '',
                  label: selectedCard
                    ? `${messages['travel.booking.creditCard.ends-with']} ${
                        find(creditCards, (card) => {
                          return card.id === selectedCard;
                        })?.lastDigits
                      }`
                    : '',
                }}
                noOptionsMessage={() => {
                  return messages['travel.booking.no-creditCard'];
                }}
                options={map(creditCards, (creditCard) => {
                  return {
                    value: creditCard.id,
                    label: `${messages['travel.booking.creditCard.ends-with']} ${creditCard.lastDigits}`,
                  };
                })}
                onChange={(selectedCard) => {
                  setSelectedCard(selectedCard.value);
                }}
              />
              <IntlMessages id="travel.booking.creditCard" />
            </Label>
          </Colxx>
          <Colxx xxs="6">
            <Label className="form-group search reservation has-top-label">
              <Select
                className="react-select"
                classNamePrefix="react-select"
                isClearable={false}
                placeholder=""
                value={{
                  value: installments || 0,
                  label:
                    getInstallmentsText(
                      find(paymentOptions, (paymentOption) => {
                        return paymentOption.installment === installments;
                      }),
                      formatMessage,
                      order.fare.currency
                    ) || '',
                }}
                noOptionsMessage={() => {
                  return messages['general.loading'];
                }}
                options={map(paymentOptions, (paymentOption) => {
                  return {
                    value: paymentOption.installment,
                    label: getInstallmentsText(
                      paymentOption,
                      formatMessage,
                      order.fare.currency
                    ),
                  };
                })}
                onChange={(installments) => {
                  setInstallments(installments.value);
                }}
              />
              <IntlMessages id="travel.booking.installments" />
            </Label>
          </Colxx>
        </Row>

        <PaymentAtDestinationMessage order={order} />

        <div
          className="ml-3 mr-3 text-right btns mt-3"
          style={{
            pointerEvents: ongoingAction ? 'none' : 'auto',
            opacity: ongoingAction ? 0.6 : 1,
          }}
        >
          <Button
            color="link"
            disabled={shouldDisableButtons}
            onClick={goForApproval}
            className="my-0 px-2"
          >
            {messages['general.go-back']}
          </Button>
          <Button
            color="link"
            onClick={requestToReject}
            disabled={shouldDisableButtons}
            title={shouldDisableButtons ? messages['travel.timeout'] : ''}
            className="my-0 px-2 mx-2"
          >
            {messages['general.reject']}
          </Button>
          <Button
            onClick={() => requestToIssue(selectedCard, installments)}
            disabled={shouldDisableButtons}
            title={shouldDisableButtons ? messages['travel.timeout'] : ''}
            className="my-0 ml-2"
          >
            {messages['travel.order.approval.to-approve']}
          </Button>
        </div>
      </PerfectScrollbar>
    </div>
  );
}
