import { TOGGLE_MASK } from '../actions';

const INITIAL_STATE = {
  showMask: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TOGGLE_MASK:
      return { showMask: action.payload };
    default:
      return state;
  }
};
