import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Button } from 'reactstrap';
import BudgetTotalStatus from './BudgetTotalStatus';

function checkPaymentInfo(paymentInfo) {
  return paymentInfo?.type && paymentInfo?.info && paymentInfo?.total;
}

export default function AdministrativeBudgetCreatorStatus({
  onCreate,
  isSaving,
  paymentInfo,
  allowRefundCreation,
  isValid: _isValid,
  title: _title,
}) {
  const { messages } = useIntl();

  // States
  const isPaymentInfoValid = useMemo(
    () => checkPaymentInfo(paymentInfo),
    [paymentInfo]
  );

  const isValid = useMemo(
    () => _isValid && isPaymentInfoValid,
    [_isValid, isPaymentInfoValid]
  );

  const title = useMemo(
    () =>
      _title ||
      (isPaymentInfoValid ? '' : 'Você deve informar um tipo de pagamento'),
    [_title, isPaymentInfoValid]
  );

  const [topMessage, messageClass = ''] = useMemo(() => {
    if (!paymentInfo?.type || !paymentInfo?.info)
      return [
        messages['administrative.creator-status.select-payment'],
        'color-alert',
      ];

    if (!paymentInfo?.total)
      return [
        messages['administrative.creator-status.select-total'],
        'color-alert',
      ];

    return [];
  }, [paymentInfo]);

  const temporaryBudget = useMemo(
    () => ({
      fare: {
        total: paymentInfo?.total,
      },
    }),
    [paymentInfo?.total]
  );

  // Render
  const renderTop = () => {
    if (isPaymentInfoValid)
      return <BudgetTotalStatus budget={temporaryBudget} />;

    return topMessage ? <div className={messageClass}>{topMessage}</div> : null;
  };

  return (
    <div className="text order-status">
      {renderTop()}

      <hr className="my-4" />

      <Button
        name="create-budget"
        outline
        color="primary"
        className="m-0"
        onClick={() => allowRefundCreation && onCreate()}
        disabled={!(isValid && allowRefundCreation) || isSaving}
        title={title}
      >
        {messages['refund.modal.create-payment']}
      </Button>
    </div>
  );
}
