import React from 'react';
import classNames from 'classnames';
import { Card, CardBody, CardTitle } from 'reactstrap';

export default function GeneralInfo({ className = '', title, children }) {
  // Render
  const renderDescription = (description) => (
    <p className="m-0 p-0 details text-muted">{description}</p>
  );

  const renderItem = (title, value, Details = null) => (
    <div className="d-flex align-items-center justify-content-between list-item my-2">
      <div>
        <h4 className="m-0 p-0 font-primary title">{title}</h4>
        {Details ? <Details /> : null}
      </div>
      <p className="m-0 p-0 price">{value}</p>
    </div>
  );

  return (
    <Card
      className={classNames(
        'general-info general-related-info h-100',
        className
      )}
    >
      <CardBody>
        <CardTitle className="d-block mb-3 p-0">{title}</CardTitle>
        <div className="default-tab d-flex flex-column justify-content-center">
          {children(renderItem, renderDescription)}
        </div>
      </CardBody>
    </Card>
  );
}
