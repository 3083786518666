import React from 'react';
import Select from 'react-select';

const customStyles = {
  option: (provided, { isSelected, isFocused }) => ({
    ...provided,
    backgroundColor: isSelected ? '#43418e' : 'white',
    ':hover': {
      backgroundColor: isSelected ? '#43418e' : 'rgba(67, 65, 142, 0.1)',
      color: isSelected ? 'white' : 'black',
    },
    color: isSelected ? 'white' : 'black',
  }),
  singleValue: (base) => ({
    ...base,
    color: 'black',
  }),
  control: (base, { isFocused }) => {
    return {
      ...base,
      borderColor: isFocused ? '#43418e' : '#e0e0e0',
      boxShadow: 'none',
      ':hover': {
        borderColor: '#43418e',
        boxShadow: '0 0 0 1px rgba(67, 65, 142, 0.2)',
      },
      ':focus': {
        borderColor: '#43418e',
        boxShadow: '0 0 0 1px rgba(67, 65, 142, 0.2)',
      },
    };
  },
};

const formatOptionLabel = ({ creditCard = {} }) => {
  const { name, lastDigits, issuer } = creditCard;

  return (
    <div className="d-flex align-items-center">
      <img
        src={`/assets/img/logos/${issuer}.svg`}
        width={25}
        height={20}
        style={{ objectFit: 'contain' }}
      />
      <div className="ml-1">
        {name ? (
          <>
            <p
              className="m-0 p-0 mb-1"
              style={{ fontSize: '0.8rem', lineHeight: 1 }}
            >
              {name}
            </p>
            <p
              className="m-0 p-0"
              style={{ fontSize: '0.7rem', lineHeight: 1, opacity: 0.6 }}
            >
              Final {lastDigits}
            </p>
          </>
        ) : (
          <p className="m-0 p-0" style={{ fontSize: '0.75rem', lineHeight: 1 }}>
            Final {lastDigits}
          </p>
        )}
      </div>
    </div>
  );
};

const CreditCardSelect = React.forwardRef((props, ref) => {
  return <Select ref={ref} formatOptionLabel={formatOptionLabel} {...props} />;
});

export default CreditCardSelect;
