import React, { useMemo } from 'react';
import { sortByFareBasedOnProperty } from '../../../../utils/expensesDashboard';
import DefaultTab from './DefaultTab';

export default function UsersTab({ items, ...props }) {
  const users = useMemo(() => {
    return sortByFareBasedOnProperty(
      items,
      ({ passengerId }) => passengerId
    ).map((item) => {
      const { firstName, lastName } = item.items[0].passengerInfo;

      return {
        ...item,
        value: `${firstName} ${lastName}`,
      };
    });
  }, [items]);

  return <DefaultTab values={users} {...props} />;
}
