import React, { useEffect, useMemo, useRef } from 'react';
import { Row } from 'reactstrap';
import { Colxx } from '../common/CustomBootstrap';
import { ReactComponent as StarIcon } from '../../assets/img/icons/star.svg';
import lottie from 'lottie-web';
import StarAnimation from '../../assets/lottie/star.json';
import AnimatedToggle from '../common/AnimatedToggle';

export default function HotelItem({
  hotel,
  onClick = () => {},
  onFavorite = () => {},
  favorite,
}) {
  const { address, city } = hotel.destination;

  const categories = useMemo(
    () => [...new Array(hotel.category).keys()],
    [hotel.category]
  );

  const heartRef = useRef(null);

  useEffect(() => {
    if (favorite && heartRef.current) {
      lottie.loadAnimation({
        container: heartRef.current,
        loop: false,
        autoplay: true,
        animationData: HeartAnimation,
      });
    }
  }, [favorite]);

  return (
    <Row className="hotel-item m-0 mb-4 w-100 p-0">
      <Colxx xxs="3" className="p-0">
        <img
          src={hotel.mainPhoto}
          alt={hotel.name}
          className="hotel-img light-round"
        />
      </Colxx>
      <Colxx
        xxs="8"
        className="m-0 p-0 pl-2 d-flex flex-column justify-content-center"
      >
        <h3
          className="small-medium-title text-dark m-0 pointer"
          onClick={onClick}
        >
          {hotel.name}
        </h3>
        <div className="categories mb-1">
          {categories.map((value) => (
            <StarIcon
              key={value}
              width={10}
              height={10}
              className="mr-1 text-primary"
            />
          ))}
        </div>
        <p className="m-0 medium text-muted">
          {address} - {city.pt}
        </p>
      </Colxx>
      <Colxx
        xxs="1"
        className="d-flex align-items-center justify-content-center p-1 pointer"
      >
        <AnimatedToggle
          animationData={StarAnimation}
          toggle={onFavorite}
          value={favorite}
        />
      </Colxx>
    </Row>
  );
}
