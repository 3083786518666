/**
 * @param elementsArray = [[validationFunction, focusFunction]]
 * @param onCompletion = 'callback' function when complete
 * @param currentAt = Current element index
 */

export default class Sequence {
  constructor(elementsArray, onCompletion = () => {}, currentAt = null) {
    this.elementsArray = elementsArray;
    this.currentAt = currentAt;
    this.onCompletion = onCompletion;
  }

  run = (currentProps, prevProps, startAt = 0) => {
    for (let i = startAt, length = this.elementsArray.length; i < length; i++) {
      const [validationFunction, focusFunction] = this.elementsArray[i];

      if (!validationFunction(currentProps, prevProps)) {
        if (!this.currentAt !== i) {
          focusFunction();
          this.currentAt = i;
        }

        return true;
      }
    }

    this.onCompletion();
  };
}
