const colorful = true;

export const CompaniesInfo = {
  twilio: { color: '#F22E45' },
  zoom: { color: '#008DFF' },
  atlassian: { color: '#0052CC' },
  pipedrive: { color: '#203232' },
  office: { color: '#ED3F01' },
  github: { color: '#181C1F' },
  slack: { color: '#4A154A', colorful },
  calendly: { color: '#016AFF' },
  figma: { color: '#1E1E1E', colorful },
  webflow: { color: '#4253FF' },
  adobe: { color: '#DA1F26' },
  sentry: { color: '#310F32' },
  fiverr: { color: '#1CBF73' },
  typeform: { color: '#F1ECE3' },
  zapier: { color: '#FF4A00' },
  mailchimp: { color: '#ffdd00' },
  dropbox: { color: '#0160FE' },
  circleCi: { color: '#3AC948' },
  hubspot: { color: '#FF7A59' },
  apollo: { color: '#ffc127' },
  stitch: { color: '#0675C0' },
  heroku: { color: '#410099' },
  jetbrains: { color: '#27282C' },
  sendgrid: { color: '#1A82E2', colorful },
  browserstack: { color: '#E86F32', colorful },
  '1Password': { color: '#198CFF', colorful },
  intercom: { color: '#1F8DED' },
  notion: { color: '#E06258' },
  zendesk: { color: '#03363D' },
  auth0: { color: '#EB5424' },
  google: { color: '#5F6368', colorful },
  'google-cloud': { color: '#5F6368', colorful },
  'google-play': { color: '#5F6368', colorful },
  linkedin: { color: '#0a66c2' },
  miro: { color: '#FFD02F' },
  AWS: { color: '#252F3E', colorful },
  facebook: { color: '#1877F2' },
  spotify: { color: '#1DD660' },
  shopify: { color: '#5F8C3E', colorful },
  wix: { color: '#FFCC01' },
  canva: { color: '#23BEC7' },
  microsoft: { color: '#737373', colorful },
  gitlab: { color: '#F96424', colorful },
  zenvia: { color: '#000000' },
  loggi: { color: '#0095ff' },
  99: { color: '#ffdd00', colorful },
  uber: { color: '#010202' },
  asana: { color: '#F06A6A' },
  amplitude: { color: '#2c87f0' },
  mixpanel: { color: '#9378ff' },
  segment: { color: '#52BD94' },
  docker: { color: '#2496ed' },
  contentful: { color: '#0b6ae6', colorful },
  heap: { color: '#ED485F' },
  hotjar: { color: '#FF3C00' },
  logrocket: { color: '#764ABC' },
  looker: { color: '#5F6368', colorful },
  apple: { color: '#333333' },
  airtable: { color: '#0768f8', colorful },
  pipefy: { color: '#0b66ff' },
  stripe: { color: '#635bff' },
  newrelic: { color: '#00838f', colorful },
  salesforce: { color: '#00A1E0' },
  rocketchat: { color: '#F5455C' },
  arquivei: { color: '#0B3CF6' },
  contaazul: { color: '#00AFF0' },
  semrush: { color: '#FF642D' },
  oracle: { color: '#C74634' },
  totvs: { color: '#333333' },
  sap: { color: '#027db8', colorful },
  sankhya: { color: '#66CB66' },
  rdsation: { color: '#07e5eb' },
  olist: { color: '#0c29d0' },
  unico: { color: '#CF0080', colorful },
  hotmart: { color: '#F04E23' },
};

export const companiesKeywords = {
  twilio: 'twilio',
  zoom: 'zoom',
  atlassian: 'atlassian',
  'bit bucket': 'atlassian',
  jira: 'atlassian',
  statuspage: 'atlassian',
  confluence: 'atlassian',
  trello: 'atlassian',
  pipedrive: 'pipedrive',
  office: 'office',
  microsoft: 'microsoft',
  'microsoft office': 'microsoft',
  github: 'github',
  gitlab: 'gitlab',
  slack: 'slack',
  calendly: 'calendly',
  figma: 'figma',
  webflow: 'webflow',
  adobe: 'adobe',
  'adobe creative cloud': 'adobe',
  'creative cloud': 'adobe',
  sentry: 'sentry',
  fiverr: 'fiverr',
  typeform: 'typeform',
  zapier: 'zapier',
  mailchimp: 'mailchimp',
  dropbox: 'dropbox',
  circleci: 'circleCi',
  'circle ci': 'circleCi',
  hubspot: 'hubspot',
  apollo: 'apollo',
  'hub spot': 'hubspot',
  stitch: 'stitch',
  heroku: 'heroku',
  jetbrains: 'jetbrains',
  sendgrid: 'sendgrid',
  browserstack: 'browserstack',
  '1password': '1Password',
  '1 password': '1Password',
  intercom: 'intercom',
  notion: 'notion',
  zendesk: 'zendesk',
  auth0: 'auth0',
  'auth 0': 'auth0',
  google: 'google',
  'google cloud': 'google-cloud',
  'google cloud platform': 'google-cloud',
  'google play': 'google-play',
  'play store': 'google-play',
  miro: 'miro',
  aws: 'AWS',
  facebook: 'facebook',
  spotify: 'spotify',
  shopify: 'shopify',
  wix: 'wix',
  canva: 'canva',
  linkedin: 'linkedin',
  zenvia: 'zenvia',
  loggi: 'loggi',
  99: '99',
  '99 taxis': '99',
  uber: 'uber',
  asana: 'asana',
  amplitude: 'amplitude',
  mixpanel: 'mixpanel',
  segment: 'segment',
  docker: 'docker',
  contentful: 'contentful',
  wordpress: 'wordpress',
  heap: 'heap',
  hotjar: 'hotjar',
  logrocket: 'logrocket',
  looker: 'looker',
  apple: 'apple',
  airtable: 'airtable',
  pipefy: 'pipefy',
  stripe: 'stripe',
  'new relic': 'newrelic',
  newrelic: 'newrelic',
  salesforce: 'salesforce',
  'sales force': 'salesforce',
  'rocket.chat': 'rocketchat',
  'rocket chat': 'rocketchat',
  rocketchat: 'rocketchat',
  arquivei: 'arquivei',
  'conta azul': 'contaazul',
  contaazul: 'contaazul',
  semrush: 'semrush',
  oracle: 'oracle',
  totvs: 'totvs',
  sap: 'sap',
  sankhya: 'sankhya',
  rd: 'rdsation',
  'rd station': 'rdsation',
  rdsation: 'rdsation',
  olist: 'olist',
  unico: 'unico',
  hotmart: 'hotmart',
};

export const getCompanyIconPath = (icon, extension = 'svg') => {
  return `/assets/img/companies/${icon}.${extension}`;
};
