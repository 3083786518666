import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import {
  convertToLabelValueWithMessages,
  mapRegisteredItems,
} from 'src/portao3-legacy/utils/others';
import GenericParamsOptions from './GenericParamsOptions';

import { OrderTypes } from 'src/portao3-legacy/constants/orderTypes';
import {
  CarTransmissions,
  CarTypes,
} from 'src/portao3-legacy/constants/searchParams';
import { registeredCompanies } from 'src/portao3-legacy/constants/travelValues';

export default function CarParamsOptions({ className, initialData }) {
  const { messages } = useIntl();

  // States
  const params = useMemo(() => {
    return [
      {
        key: 'rentals',
        title: messages['admin.travels.params.car.rentals'],
        options: mapRegisteredItems(registeredCompanies),
      },
      {
        key: 'transmissions',
        title: messages['admin.travels.params.car.categories'],
        options: convertToLabelValueWithMessages({
          items: CarTransmissions,
        }),
      },
      {
        key: 'categories',
        title: messages['admin.travels.params.car.amenities'],
        options: convertToLabelValueWithMessages({
          items: CarTypes,
        }),
      },
    ];
  }, []);

  // Render
  return (
    <GenericParamsOptions
      className={className}
      paramType={OrderTypes.CAR}
      params={params}
      initialData={initialData}
      title={messages['travel.menu.car']}
    />
  );
}
