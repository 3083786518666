import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Label, Row } from 'reactstrap';
import { StarkPixTypes } from '../../../../constants/pix';
import { formatPix } from '../../../../utils/pix';
import { Colxx } from '../../../common/CustomBootstrap';

export default function PixPaymentInfo({ item }) {
  const { messages } = useIntl();

  // States
  const { dict, dictType, owner, ownerDocument, bankName } = useMemo(() => {
    if (!item) return {};

    const { dict, dictType, owner, ownerDocument, bankName } =
      item.payment.info;

    const normalizedPixType = StarkPixTypes[dictType];

    return {
      dict: formatPix(normalizedPixType, dict),
      dictType: messages[`pix-types.${normalizedPixType}`],
      owner,
      ownerDocument,
      bankName,
    };
  }, [item]);

  // Render
  return (
    <>
      <div className="mt-4 mb-2 ml-2">
        <h5>{messages['administrative-expense-item.payment-info']}</h5>
      </div>

      <div className="reservation-params refund-params light">
        <Row>
          <Colxx md="6" sm="12">
            <Label className="form-group disabled search has-top-label">
              <p className="text-uppercase">{dict}</p>
              <span>{messages['administrative.pix.key']}</span>
            </Label>
          </Colxx>
          <Colxx md="6" sm="12">
            <Label className="form-group disabled search has-top-label">
              <p className="text-uppercase">{dictType}</p>
              <span>{messages['administrative.pix.type']}</span>
            </Label>
          </Colxx>
        </Row>
        <Row>
          <Colxx md="6" sm="12">
            <Label className="form-group disabled search has-top-label">
              <p className="text-uppercase">{owner}</p>
              <span>{messages['administrative.pix.owner']}</span>
            </Label>
          </Colxx>
          <Colxx md="6" sm="12">
            <Label className="form-group disabled search has-top-label">
              <p className="text-uppercase">{ownerDocument}</p>
              <span>{messages['administrative.pix.tax-id']}</span>
            </Label>
          </Colxx>
        </Row>
        <Row>
          <Colxx sm="12">
            <Label className="form-group disabled search has-top-label">
              <p className="text-uppercase">{bankName}</p>
              <span>{messages['administrative.pix.bank-name']}</span>
            </Label>
          </Colxx>
        </Row>
      </div>
    </>
  );
}
