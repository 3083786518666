import React from 'react';
import { Row } from 'reactstrap';
import { Colxx } from '../../components/common/CustomBootstrap';

import ContentLoader from 'react-content-loader';
import LoaderCardWithText from '../../components/loaders/LoaderCardWithText';

export default function PolicyLoading() {
  return (
    <>
      <Row>
        <Colxx xxs="12" className="mt-5">
          <ContentLoader
            speed={2}
            width={150}
            height={42}
            viewBox="0 0 150 42"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
          >
            <rect x="0" y="0" rx="3" ry="3" width="150" height="42" />
          </ContentLoader>

          <div className="float-right">
            <ContentLoader
              speed={2}
              width={80}
              height={42}
              viewBox="0 0 80 42"
              backgroundColor="#f3f3f3"
              foregroundColor="#ecebeb"
            >
              <rect x="0" y="0" rx="3" ry="3" width="80" height="42" />
            </ContentLoader>
          </div>
        </Colxx>
      </Row>
      <Row className="mt-4 pb-5 mx-0">
        <LoaderCardWithText />
        <LoaderCardWithText />
        <LoaderCardWithText />
        <LoaderCardWithText />
        <LoaderCardWithText />
      </Row>
    </>
  );
}
