import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import moment from 'moment';

export default function ValidUntil({ order }) {
  const { messages } = useIntl();

  const message = useMemo(() => {
    if (order.fare.issueUntil) {
      return `${messages['travel.order.status.title.validUntil']} ${moment
        .utc(order.fare.issueUntil)
        .local()
        .format('DD/MM HH:mm')}.`;
    }

    if (order.fare.issueUntil === null) {
      return `${messages['travel.order.status.title.hasNoValidUntil']}`;
    }
  }, [order]);

  return message ? message : null;
}
