import moment from 'moment';
import React from 'react';
import { Row, Card } from 'reactstrap';

import Icons from '../../constants/icons';
import { OrderTypes } from '../../constants/orderTypes';
import { stringToMoney } from '../../utils/money';
import { Colxx } from '../common/CustomBootstrap';

import TripInfo from './TripInfo';
import NameCityInfo from './NameCityInfo';
import FlightTripInfo from './FlightTripInfo';

export default function OrderCard({
  order,
  esgInsteadOfTotal = false,
  onClick = () => {},
}) {
  const {
    type,
    createdAt,
    fare: { total, currency },
    passengerInfo: { firstName, lastName },
    costCenter,
    project,
    esg,
  } = order;

  const costAndProject = [costCenter?.label, project?.label]
    .filter((value) => value)
    .join(' | ');

  // Reservation Info Components
  const getNameInfoComponent = (obj) => {
    try {
      const {
        name,
        destination: { city, address },
      } = obj;

      return <NameCityInfo name={name} city={city?.pt} address={address} />;
    } catch (err) {
      return <div />;
    }
  };

  const getHotelReservationInfo = () =>
    getNameInfoComponent(order.reservations[0]?.hotelDetails?.hotel);

  const getOfficeReservationInfo = () =>
    getNameInfoComponent(order.reservations[0]?.officeDetails?.office);

  const getFlightReservationInfo = () => {
    return <FlightTripInfo journeys={order.journeys} />;
  };

  const getCarReservationInfo = () => {
    try {
      return (
        <div className="car-reservation-info text-truncate text-nowrap">
          {order.reservations[0].carDetails.name.replace(/ *\([^)]*\) */g, '')}
        </div>
      );
    } catch (err) {
      return <div className="car-reservation-info text-truncate text-nowrap" />;
    }
  };

  const getBusReservationInfo = () => <TripInfo journeys={order.journeys} />;

  const RESERVATION_INFO_COMPONENTS = {
    [OrderTypes.FLIGHT]: getFlightReservationInfo,
    [OrderTypes.HOTEL]: getHotelReservationInfo,
    [OrderTypes.CAR]: getCarReservationInfo,
    [OrderTypes.BUS]: getBusReservationInfo,
    [OrderTypes.OFFICE]: getOfficeReservationInfo,
  };

  const getReservationInfo = () => {
    const fun = RESERVATION_INFO_COMPONENTS[type];
    return fun && fun();
  };

  return (
    <Card className="order-card mb-3 py-4 px-3" onClick={onClick}>
      <Row>
        <Colxx xxs="4" className="d-flex align-items-center">
          <img
            src={`/assets/img/icons/${Icons[type] || Icons[OrderTypes.FLIGHT]}`}
            alt="Icon"
            className="mr-4 ml-2"
          />
          <div className="d-flex flex-column justify-content-center text-truncate">
            <h2
              className="passenger-name p-0 m-0 text-capitalize text-dark h5 text-nowrap text-truncate"
              style={{ fontSize: '1.1rem' }}
            >
              {firstName} {lastName}
            </h2>
            {costAndProject && (
              <p className="p-0 m-0 text-muted">{costAndProject}</p>
            )}
          </div>
        </Colxx>
        <Colxx
          xxs="4"
          className="d-flex align-items-center justify-content-center"
        >
          {getReservationInfo()}
        </Colxx>
        <Colxx
          xxs="2"
          className="d-flex align-items-center justify-content-center text-dark"
        >
          {moment.unix(createdAt).format('DD/MM/YYYY')}
        </Colxx>
        <Colxx
          xxs="2"
          className="d-flex align-items-center justify-content-center text-dark"
        >
          {esgInsteadOfTotal
            ? `${esg ? esg.carbon_kg.toFixed(0) : 0} kg`
            : stringToMoney(total, 2, currency).replace(/^(\D+)/, '$1 ')}
        </Colxx>
      </Row>
    </Card>
  );
}
