import React, { useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { getAlterableReservations } from '../../../../utils/bus';
import SlidingComponents from '../../../SlidingComponents';
import AlterationTab from './Alteration';
import BusAlterationMenu from './Menu';

export const BUS_ALTERATION_TABS = {
  INITIAL: 'initial',
  SEAT: 'seats',
  CANCELLATION: 'cancellation',
};

const COMPONENTS = {
  [BUS_ALTERATION_TABS.INITIAL]: BusAlterationMenu,
  [BUS_ALTERATION_TABS.SEAT]: AlterationTab,
};

export default function BusAlteration({
  order,
  reservations,
  goForAlteration,
}) {
  const { alterId } = useParams();
  const history = useHistory();

  // States
  const currentTab = useMemo(
    () => (COMPONENTS[alterId] ? alterId : null),
    [alterId]
  );
  const initialLocation = useMemo(() => `/travel/orders/${order.id}`, [order]);

  const alterableReservations = useMemo(
    () => getAlterableReservations(reservations),
    [reservations]
  );

  // Functions
  const onChangeTab = (tab) => {
    history.push(`${initialLocation}/alteration/${tab}`);
  };

  // Render
  return (
    <SlidingComponents
      components={COMPONENTS}
      initialTab={BUS_ALTERATION_TABS.INITIAL}
      currentTab={currentTab}
      goForAlteration={goForAlteration}
      order={order}
      reservations={reservations}
      onChangeToInitialTab={() => history.push(initialLocation)}
      onChangeTab={onChangeTab}
      className="flight-alteration h-100"
      initialLocation={initialLocation}
      alterableReservations={alterableReservations}
    />
  );
}
