import React, { useEffect, useMemo, useRef, useState } from 'react';
import useDataSnapshot from '../../../../hooks/useDataSnapshot';
import firebaseApp from '../../../../services/Firebase';
import { useDispatch, useSelector } from 'react-redux';
import BudgetCard from '../../../../components/expenses/Expenses/BudgetCard';
import PerfectScrollbar from 'react-perfect-scrollbar';
import LoadingBudget from '../../../../components/expenses/Expenses/LoadingBudget';
import NewBudgetCard from '../../../../components/expenses/Expenses/NewBudgetCard';
import { getPassengerRedux } from '../../../../utils/redux';
import {
  ActiveBudgetStatus,
  RefundStatus,
  BudgetStatusMessages,
} from '../../../../constants/refundStatus';
import UserWithName from '../../../../components/common/UserWithName';
import BudgetOptions from '../../BudgetOptions';
import { BudgetSortingFunctions } from '../../../../utils/budgetSorting';
import { BudgetSortOptions } from '../../../../constants/budget';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { RefundTypes } from '../../../../constants/refundTypes';
import { addBudgets } from '../../../../redux/actions';
import {
  filterBudgets,
  getDataTestId,
  getFilterKeys,
  handleOldAndNewBudgets,
  handleStatusFilters,
} from './utils';

const REFRESH_STATUS_CODES = [
  RefundStatus.ACTIVE,
  RefundStatus.CANCELLED,
  RefundStatus.WAITING,
];

export default function DefaultCardContainer({
  className,
  children,
  card,
  canCreateBudget = true,
  canCreateExpense = true,
  onCreateBudget = () => {},
  onRefundClick = () => {},
  onExpenseClick = () => {},
  onCreateBudgetItem = () => {},
  shouldInitialize = true,
  style = {},
  lastCreatedBudget = null,
  onBudgetUpdate = () => {},
  showUser,
  getData,
  setCanArchive,
  doesOrganizationHasExpenses = true,
}) {
  const { messages } = useIntl();
  const { passenger } = useSelector(getPassengerRedux);
  const dispatch = useDispatch();

  // Refs
  const oldBudgetIds = useRef([]);

  // Hooks
  const [budgets, _, loadingBudgets] = useDataSnapshot(
    {
      fetchFunction: () =>
        firebaseApp.getAllBudgetsFromCard(
          passenger.organizationId,
          showUser ? null : passenger.id || passenger.uid,
          card.id
        ),
      formatFunction: async (data) => {
        const snapshot = data.map(async (budget) => {
          const itemsSnap = await firebaseApp
            .getItemsFromBudget(budget.id)
            .get();
          const expenses = itemsSnap.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          return {
            ...budget,
            expenses,
          };
        });

        const budgetsWithExpenses = await Promise.all(snapshot);
        return budgetsWithExpenses;
      },
      onUpdate: (data = []) => {
        if (setCanArchive) {
          setCanArchive(
            data.every(
              (budget) => !ActiveBudgetStatus.includes(budget.statusCode)
            )
          );
        }

        if (
          data.some((budget) =>
            REFRESH_STATUS_CODES.includes(budget.statusCode)
          )
        ) {
          onBudgetUpdate();
        }
      },
      shouldLoad: shouldInitialize,
    },
    [passenger]
  );

  // States
  const [sort, setSort] = useState(BudgetSortOptions.ASC_DATE);
  const [statusFilterOptions, setStatusFilterOptions] = useState([]);
  const [statusFilters, setStatusFilters] = useState([]);
  const [newBudgetIds, setNewBudgetIds] = useState([]);

  const sortingFunction = useMemo(
    () =>
      BudgetSortingFunctions[sort] ||
      BudgetSortingFunctions[BudgetSortOptions.ASC_DATE],
    [sort]
  );

  const [filterKeys, shouldFilter] = useMemo(() => {
    const filterKeys = getFilterKeys(statusFilters, newBudgetIds);
    return [filterKeys || {}, !!filterKeys];
  }, [newBudgetIds, statusFilters]);

  const filteredBudgets = useMemo(() => {
    const filtered = shouldFilter
      ? filterBudgets(budgets, filterKeys)
      : budgets;

    return filtered.sort(sortingFunction);
  }, [budgets, sortingFunction, filterKeys, shouldFilter]);

  useEffect(() => {
    if (filteredBudgets.length > 0) dispatch(addBudgets(filteredBudgets));
  }, [filteredBudgets]);

  const [numBudgets, isEmpty] = useMemo(() => {
    const length = budgets.length;
    return [length, length === 0];
  }, [budgets]);

  const dataTestId = useMemo(() => getDataTestId(card), [card]);

  // Effects
  useEffect(() => {
    if (budgets.length) {
      const { statusFilters: newStatusFilters } = handleStatusFilters({
        budgets,
        card,
        messages,
        setStatusFilterOptions,
        setStatusFilters,
        statusFilters,
      });

      handleOldAndNewBudgets({
        budgets,
        card,
        newBudgetIds,
        oldBudgetIds: oldBudgetIds.current,
        setNewBudgetIds,
        setOldBudgetIds: (data) => (oldBudgetIds.current = data),
        statusFilters: newStatusFilters,
      });
    } else {
      setStatusFilterOptions([]);
      setStatusFilters([]);
      oldBudgetIds.current = [];
    }
  }, [budgets]);

  useEffect(() => {
    if (newBudgetIds.length) {
      setNewBudgetIds([]);
    }
  }, [statusFilters]);

  useEffect(() => {
    if (lastCreatedBudget) {
      const element = document.getElementById(`budget-${lastCreatedBudget}`);
      if (element) {
        setTimeout(() => {
          element.scrollIntoView({ inline: 'center', block: 'nearest' });
        }, 100);
      }
    }
  }, [lastCreatedBudget]);

  // Render
  const renderEmpty = (message) => (
    <div className="w-60 flex-center mx-auto">
      <p className="text-center text-muted">{message}</p>
    </div>
  );

  const renderBudgets = () => {
    if (loadingBudgets) {
      return (
        <>
          <LoadingBudget />
          <LoadingBudget />
          <LoadingBudget />
        </>
      );
    } else {
      if (numBudgets > 0) {
        return (
          <>
            {filteredBudgets.map((budget, index) => (
              <BudgetCard
                key={budget.id}
                budget={budget}
                card={card}
                id={`budget-${budget.id}`}
                className={classNames('flex-shrink-0', { 'ml-4': index })}
                onClick={() => onRefundClick(budget.id)}
                canCreateExpense={canCreateExpense}
                onCreateBudgetItem={() => onCreateBudgetItem(budget.id)}
                onExpenseClick={onExpenseClick}
              />
            ))}

            <div className="flex-shrink-0 spacer" />
          </>
        );
      }

      if (!canCreateBudget)
        return renderEmpty(
          messages[
            card?.type === RefundTypes.PERSONAL_ACCOUNT
              ? 'budget.my-cards.no-budgets.account'
              : 'budget.my-cards.no-budgets.card'
          ]
        );

      if (!doesOrganizationHasExpenses)
        return renderEmpty(messages['budget.my-cards.no-budgets']);

      return <NewBudgetCard onCreate={onCreateBudget} />;
    }
  };

  return (
    <div
      className={`default-card-container mb-4 ${className}`}
      style={style}
      data-testid={dataTestId}
    >
      {shouldInitialize && showUser ? (
        <UserWithName userId={card.user_id} getData={getData} />
      ) : null}
      {children}

      <div className="budgets">
        <PerfectScrollbar
          className="refund-cards-bar mx-0"
          options={{
            suppressScrollY: true,
            useBothWheelAxes: false,
          }}
        >
          <div className="d-flex h-100">{renderBudgets()}</div>
        </PerfectScrollbar>
      </div>
      {card.openBooking || !doesOrganizationHasExpenses ? null : (
        <BudgetOptions
          canCreateBudget={canCreateBudget}
          onCreateBudget={onCreateBudget}
          card={card}
          hasBudgets={!!budgets.length}
          sort={sort}
          setSort={setSort}
          isEmpty={isEmpty}
          numBudgets={numBudgets}
          statusFilters={statusFilters}
          setStatusFilters={setStatusFilters}
          statusFilterOptions={statusFilterOptions}
          shouldInitialize={shouldInitialize}
        />
      )}
    </div>
  );
}
