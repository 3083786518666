import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { getAuthRedux } from '../../../../utils/redux';

export default function OpenBookingApproval({
  shouldDisableButtons,
  goForApproval,
  requestToReject,
  ongoingAction,
  requestToApprove,
  requestToIssue,
  approvals,
}) {
  const { user } = useSelector(getAuthRedux);
  const { messages } = useIntl();

  // States
  const canApprove = useMemo(() =>
    approvals.find(({ userId }) => userId === user.uid, [user, approvals])
  );

  // Render
  return (
    <div className="approval-steps h-100">
      <PerfectScrollbar
        options={{
          suppressScrollX: true,
          wheelPropagation: false,
          useBothWheelAxes: false,
        }}
        className="d-flex flex-column justify-content-between"
      >
        <div>
          <h3 className="hotel-title m-0 p-0">
            {messages['travel.approve-trip']}
          </h3>

          <p className="m-0 p-0 text-white-muted mt-2">
            {messages['open-booking.approval-info.description']}
          </p>
        </div>

        <div
          className="ml-3 mr-3 text-right"
          style={{
            pointerEvents: ongoingAction ? 'none' : 'auto',
            opacity: ongoingAction ? 0.6 : 1,
          }}
        >
          <Button
            color="link"
            disabled={shouldDisableButtons}
            className="my-0 px-2"
            onClick={goForApproval}
          >
            {messages['general.go-back']}
          </Button>

          {canApprove || user.admin ? (
            <Button
              color="link"
              onClick={requestToReject}
              disabled={shouldDisableButtons}
              title={shouldDisableButtons ? messages['travel.timeout'] : ''}
              className="my-0 px-2 mx-2"
            >
              {messages['general.reject']}
            </Button>
          ) : null}

          {canApprove ? (
            <Button
              onClick={() => requestToApprove()}
              disabled={shouldDisableButtons}
              title={shouldDisableButtons ? messages['travel.timeout'] : ''}
              className="my-0 ml-2"
            >
              {messages['travel.order.approval.to-approve']}
            </Button>
          ) : null}

          {user.admin ? (
            <Button
              onClick={() => requestToIssue()}
              disabled={shouldDisableButtons}
              title={
                shouldDisableButtons
                  ? messages['travel.timeout']
                  : messages['open-booking.generate-card.description']
              }
              className="my-0 ml-2"
            >
              {messages['travel.order.approval.approve-immediately']}
            </Button>
          ) : null}
        </div>
      </PerfectScrollbar>
    </div>
  );
}
