import React from 'react';
import ContentLoader from 'react-content-loader';

export default function LoadingBudget() {
  return (
    <ContentLoader
      speed={2}
      width={300}
      height={288}
      viewBox="0 0 300 288"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      className="flex-shrink-0 mr-3"
    >
      <rect x="0" y="0" rx="5" ry="5" width="298" height="288" />
    </ContentLoader>
  );
}
