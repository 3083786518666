import React, { useMemo } from 'react';
import classNames from 'classnames';
import { map } from 'lodash';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { Colxx } from 'src/portao3-legacy/components/common/CustomBootstrap';

interface IProps {
  show?: boolean;
  onChangePage: (page: number) => void;
  totalPage?: number;
  currentPage?: number;
  numberLimit?: number;
  lastIsActive?: boolean;
  firstIsActive?: boolean;
}

export default React.memo(
  ({
    show = true,
    onChangePage,
    totalPage = 0,
    currentPage = 1,
    numberLimit = 5,
    firstIsActive = true,
    lastIsActive = true,
  }: IProps) => {
    // States
    const {
      points,
      firstPageButtonClassName,
      lastPageButtonClassName,
      prevPageButtonClassName,
      nextPageButtonClassName,
    } = useMemo(() => {
      if (totalPage <= 1) return {};

      let startPoint = 1;
      let endPoint = numberLimit;

      const half = Math.trunc(numberLimit / 2);

      if (numberLimit > totalPage) {
        startPoint = 1;
        endPoint = totalPage;
      } else if (currentPage <= half) {
        startPoint = 1;
        endPoint = numberLimit;
      } else if (currentPage + half <= totalPage) {
        startPoint = currentPage - half;
        endPoint = currentPage + half;
      } else {
        startPoint = totalPage - (numberLimit - 1);
        endPoint = totalPage;
      }

      startPoint = startPoint === 0 ? 1 : startPoint;

      const points = [];
      for (var i = startPoint; i <= endPoint; i++) {
        points.push(i);
      }

      return {
        points,
        firstPageButtonClassName: currentPage <= 1 ? 'disabled' : '',
        lastPageButtonClassName: currentPage >= totalPage ? 'disabled' : '',
        prevPageButtonClassName: currentPage <= 1 ? 'disabled' : '',
        nextPageButtonClassName: currentPage >= totalPage ? 'disabled' : '',
      };
    }, [numberLimit, totalPage]);

    // Render
    return show && totalPage > 1 ? (
      <Colxx xxs="12" className="mt-3">
        <Nav className="pagination justify-content-center">
          {firstIsActive && (
            <NavItem
              className={classNames('page-item', firstPageButtonClassName)}
            >
              <NavLink
                className="page-link first"
                onClick={() => onChangePage(1)}
              >
                <i className="simple-icon-control-start" />
              </NavLink>
            </NavItem>
          )}

          <NavItem className={classNames('page-item', prevPageButtonClassName)}>
            <NavLink
              className="page-link prev"
              onClick={() => onChangePage(currentPage - 1)}
            >
              <i className="simple-icon-arrow-left" />
            </NavLink>
          </NavItem>

          {map(points, (i) => {
            return (
              <NavItem
                key={i}
                className={`page-item ${currentPage === i && 'active'}`}
              >
                <NavLink className="page-link" onClick={() => onChangePage(i)}>
                  {i}
                </NavLink>
              </NavItem>
            );
          })}

          <NavItem className={classNames('page-item', nextPageButtonClassName)}>
            <NavLink
              className="page-link next"
              onClick={() => onChangePage(currentPage + 1)}
            >
              <i className="simple-icon-arrow-right" />
            </NavLink>
          </NavItem>

          {lastIsActive && (
            <NavItem
              className={classNames('page-item', lastPageButtonClassName)}
            >
              <NavLink
                className="page-link last"
                onClick={() => onChangePage(totalPage)}
              >
                <i className="simple-icon-control-end" />
              </NavLink>
            </NavItem>
          )}
        </Nav>
      </Colxx>
    ) : (
      <Colxx xxs="12" className="mt-2" />
    );
  }
);
