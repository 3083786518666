import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Row,
  Card,
  TabPane,
  TabContent,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';
import { useIntl } from 'react-intl';
import classnames from 'classnames';
import moment from 'moment';
import OrderStatus from '../../constants/orderStatus';

import DateSelection from '../../components/reports/DateSelection';
import { Colxx } from '../../components/common/CustomBootstrap';
import OrderModal from '../timeline/OrderModal';
import co2 from '../../constants/co2';
import Pagination from '../pages/Pagination';
import { useHistory, useParams } from 'react-router';
import NoResults from '../../components/search/NoResults';

import OrderCard from '../../components/reports/OrderCard';

import ESGTopFlightJourneys from '../../components/reports/esg/ESGTopFlightJourneys';
import ESGTopBusJourneys from '../../components/reports/esg/ESGTopBusJourneys';
import ESGTopCarJourneys from '../../components/reports/esg/ESGTopCarJourneys';
import ESGTopCostCenter from '../../components/reports/esg/ESGTopCostCenter';
import ESGTopProjects from '../../components/reports/esg/ESGTopProjects';
import ESGPurchase from '../../components/reports/esg/ESGPurchase';
import ESGSmallLineSpent from '../../components/reports/esg/ESGSmallLineTotal';
import ESGSMallLineKms from '../../components/reports/esg/ESGSmallLineKms';
import LoaderCardColumns from '../../components/loaders/LoaderCardColumns';

import firebaseApp from '../../services/Firebase';

export default function ESGDataListView({
  itemsPerPage = 8,
  path = '/app/reports/esg',
}) {
  const [loading, setIsLoading] = useState(true);
  const [orders, setOrders] = useState([]);
  const [dateSelection, setDateSelection] = useState({
    startDate: moment().startOf('month').unix(),
    endDate: moment().endOf('month').unix(),
  });

  const [activeTypeTab, setActiveTypeTab] = useState('flight');

  // Pagination States
  const [currentPage, setCurrentPage] = useState(1);
  const [indexInterval, setIndexInterval] = useState([0, 0]);

  // Redux
  const { user } = useSelector(({ auth }) => auth);

  // Router
  const history = useHistory();
  const { orderId } = useParams();

  // Intl
  const { messages } = useIntl();

  useEffect(() => {
    setIsLoading(true);
    getOrders(dateSelection);
    setCurrentPage(1);
  }, [dateSelection]);

  useEffect(() => {
    setIsLoading(false);
  }, [orders]);

  useEffect(() => {
    const startAt = (currentPage - 1) * itemsPerPage;
    setIndexInterval([startAt, startAt + itemsPerPage]);
  }, [currentPage]);

  const getOrders = async (dateSelection) => {
    const snapshotOrder = await firebaseApp
      .getOrdersFromOrganizationIdAndDate(
        user.organizationId,
        dateSelection.startDate,
        dateSelection.endDate
      )
      .get();

    const orders = [];

    snapshotOrder.forEach(function (doc) {
      if (doc.data().statusCode === OrderStatus.ISSUED && doc.data().esg)
        orders.push({
          ...doc.data(),
          id: doc.id,
        });
    });

    setOrders(orders);
  };

  // Modal
  const openModal = async (orderId) => {
    history.push(`${path}/${orderId}`);
  };

  // Date Filters
  const setCustomDate = (start, end) => {
    setDateSelection({
      startDate: moment(start).startOf('day').unix(),
      endDate: moment(end).endOf('day').unix(),
    });
  };

  // Pagination
  const renderPagination = (currentOrders) => {
    const totalItems = currentOrders.length;

    return (
      totalItems > itemsPerPage && (
        <Pagination
          currentPage={currentPage}
          totalPage={totalItems ? Math.ceil(totalItems / itemsPerPage) : 1}
          onChangePage={(i) => setCurrentPage(i)}
        />
      )
    );
  };

  return (
    <div className="esg reports mt-3">
      <Row>
        <ESGPurchase />
      </Row>

      <Row className="mt-5">
        <Colxx xxs="12" className="mb-4 text-right">
          <DateSelection
            dateSelection={dateSelection}
            setDateSelection={setCustomDate}
          />
        </Colxx>
      </Row>

      <Row className="mb-3">
        <Colxx xxs="8" className="mb-7 align-items-stretch">
          <ESGSmallLineSpent loading={loading} orders={orders} />
        </Colxx>
        <Colxx xxs="4" className="mb-7 align-items-stretch">
          <Row>
            <Colxx xxs="12" className="mb-3">
              <ESGSMallLineKms loading={loading} orders={orders} />
            </Colxx>
          </Row>
          <Row>
            <Colxx xxs="12">
              {/* <SmallLineEconomy loading={loading} orders={orders} reservations={reservations} /> */}
            </Colxx>
          </Row>
        </Colxx>
      </Row>
      <Row className="mb-2">
        <Colxx xxs="6">
          <Card className="mb-4">
            <CardHeader>
              <Nav tabs className="card-header-tabs justify-content-start">
                <NavItem>
                  <NavLink
                    to="#"
                    className={classnames({
                      active: activeTypeTab === 'flight',
                      'nav-link': true,
                    })}
                    onClick={() => {
                      setActiveTypeTab('flight');
                    }}
                  >
                    {messages['containers.esg.card.flight']}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    to="#"
                    className={classnames({
                      active: activeTypeTab === 'car',
                      'nav-link': true,
                    })}
                    onClick={() => {
                      setActiveTypeTab('car');
                    }}
                  >
                    {messages['containers.esg.card.car']}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    to="#"
                    className={classnames({
                      active: activeTypeTab === 'bus',
                      'nav-link': true,
                    })}
                    onClick={() => {
                      setActiveTypeTab('bus');
                    }}
                  >
                    {messages['containers.esg.card.bus']}
                  </NavLink>
                </NavItem>
              </Nav>
            </CardHeader>

            <TabContent activeTab={activeTypeTab}>
              <TabPane tabId="flight">
                <ESGTopFlightJourneys loading={loading} orders={orders} />
              </TabPane>
              <TabPane tabId="car">
                <ESGTopCarJourneys loading={loading} orders={orders} />
              </TabPane>
              <TabPane tabId="bus">
                <ESGTopBusJourneys loading={loading} orders={orders} />
              </TabPane>
            </TabContent>
          </Card>
        </Colxx>
        <Colxx xxs="6">
          <ESGTopCostCenter loading={loading} orders={orders} />

          <ESGTopProjects loading={loading} orders={orders} />
        </Colxx>
      </Row>

      <Row className="d-flex my-5 flex-column justify-content-center">
        <h4 className="text-left mt-5 text-weigth-900">
          {messages['admin.reports.esg.reservations']}
        </h4>
        {loading ? (
          <div className="orders mt-3">
            <LoaderCardColumns />
            <LoaderCardColumns />
            <LoaderCardColumns />
            <LoaderCardColumns />
            <LoaderCardColumns />
          </div>
        ) : orders && orders.length ? (
          <div className="orders mt-3">
            {orders.slice(...indexInterval).map((order) => (
              <OrderCard
                order={order}
                key={order.id}
                onClick={() => openModal(order.id)}
                esgInsteadOfTotal
                tons
              />
            ))}

            {renderPagination(orders)}
          </div>
        ) : (
          <div className="d-flex justify-content-center pb-5 mb-5">
            <NoResults
              titleMessageID="esg.empty.title"
              subtitleMessageID="esg.empty.subtitle"
            />
          </div>
        )}
      </Row>

      {orderId && <OrderModal orderId={orderId} closeModalPath={path} />}
    </div>
  );
}
