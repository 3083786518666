import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Button, Label } from 'reactstrap';
import AlterationFrame from '../order/Alteration/AlterationFrame';
import StepsBulletPoints from '../StepsBulletPoints';
import WaitBeforeRenderWrapper from '../../wrappers/WaitBeforeRenderWrapper';
import CustomAnimatedCheckbox from '../common/CustomCheckbox';
import useShowToggle from '../../hooks/useShowToggle';

export default function OpenBookingSteps({ toggle, onCreate, isReserving }) {
  const { messages } = useIntl();

  // States
  const [dontShowStepsNextTime, _, toggleDontShowStepsNextTime] =
    useShowToggle(true);

  const steps = useMemo(
    () => [
      {
        title: messages['open-booking.steps.approval.title'],
        description: messages['open-booking.steps.approval.description'],
      },
      {
        title: messages['open-booking.steps.issue.title'],
        description: messages['open-booking.steps.issue.description'],
      },
      {
        title: messages['open-booking.steps.done.title'],
        description: messages['open-booking.steps.done.description'],
      },
    ],
    []
  );

  // Render
  const renderBottom = () => {
    return (
      <div className="d-flex justify-content-between">
        <Label
          className="pointer d-flex align-items-center mr-3"
          onClick={() => !isReserving && toggleDontShowStepsNextTime()}
        >
          <CustomAnimatedCheckbox
            className="flex-shrink-0"
            checked={dontShowStepsNextTime}
            white
            size={20}
            onChange={toggleDontShowStepsNextTime}
            disabled={isReserving}
          />
          <span className="fs-0-7rem text-left ml-2">
            {messages['open-booking.skip-steps']}
          </span>
        </Label>
        <div className="d-flex align-items-center justify-content-end flex-shrink-0">
          <Button
            onClick={toggle}
            className="m-0 pretty-btn link white"
            disabled={isReserving}
          >
            {messages['general.go-back']}
          </Button>

          <Button
            color="link"
            onClick={() => onCreate(dontShowStepsNextTime)}
            className="m-0 ml-3"
            disabled={isReserving}
          >
            {messages['open-booking.action.btn']}
          </Button>
        </div>
      </div>
    );
  };

  return (
    <AlterationFrame
      className="w-100 h-100 p-3"
      subtitle={messages['open-booking.modal.description']}
      BottomBar={renderBottom}
    >
      <div className="card-menu-display text-left px-3 d-flex align-items-center">
        <WaitBeforeRenderWrapper waitFor={300}>
          <StepsBulletPoints steps={steps} />
        </WaitBeforeRenderWrapper>
      </div>
    </AlterationFrame>
  );
}
