import { OrderTypes } from '../constants/orderTypes';

export const imageUrl = (type, ref, locations) => {
  if (type === OrderTypes.FLIGHT) {
    if (locations) {
      const parsedLocations = locations
        ? JSON.parse(locations)
        : { locations: [] };
      const location = parsedLocations.locations.find((item) => {
        return item.iata === ref;
      });

      if (location) return location.photos.x1;
    }

    return '/assets/img/default-flight.png';
  } else if (type === OrderTypes.HOTEL) {
    if (ref) {
      return ref;
    }

    return '/assets/img/default-hotel.png';
  }
  return '/assets/img/default-flight.png';
};
