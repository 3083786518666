import React from 'react';
import { useIntl } from 'react-intl';
import BulkImportButton from '../users/BulkImportButton';

export default function PolicyBulkImportButton({ user, onData, simple }) {
  const { messages } = useIntl();

  return (
    <BulkImportButton
      user={user}
      onData={onData}
      simple={simple}
      type={messages['admin.policy.bulk.type']}
      fields={[
        {
          label: messages['admin.policy.bulk.name'],
          key: 'name',
          validators: [{ validate: 'required', validate: 'unique' }],
        },
        { label: messages['admin.policy.bulk.identifier'], key: 'externalId' },
      ]}
    />
  );
}
