import React, { useMemo, useState } from 'react';
import CustomSelect from '../common/CustomSelect';
import { DEFAULT_CURRENCY } from '../../utils/money';
import { useIntl } from 'react-intl';
import CurrencyInput from 'react-currency-input-field';
import { ReactComponent as TrashIcon } from '../../assets/img/icons/trash.svg';

export default function BudgetRule({
  currency = DEFAULT_CURRENCY,
  rule,
  setRule,
  categoriesOptions,
  selectedRules = [],
  className = '',
  onDelete = () => {},
  showDelete = false,
  ...props
}) {
  const { messages } = useIntl();

  const { price, category = null } = rule;

  const filteredRules = useMemo(
    () =>
      categoriesOptions.filter(
        (category) => !selectedRules.includes(category.value)
      ),
    [selectedRules]
  );

  const handleChange = (name, value) => {
    setRule({
      ...rule,
      [name]: value,
    });
  };

  return (
    <div
      className={`budget-rule d-flex align-items-center ${className}`}
      {...props}
    >
      <CurrencyInput
        placeholder={`${currency} 0.00`}
        name="price"
        value={price}
        onValueChange={(value) => handleChange('price', value)}
        className="price-input px-2"
        prefix={`${currency} `}
      />
      <span className="mx-2">{messages['budget.phrase.daily']}</span>
      <CustomSelect
        value={category}
        options={filteredRules}
        isMulti={false}
        className="w-100"
        onChange={(value) => handleChange('category', value)}
        noOptionsMessage={() => messages['travel.nodata.title']}
        placeholder={messages['general.none-selected']}
      />
      {showDelete && (
        <TrashIcon
          width={45}
          height={45}
          className="ml-2 pointer"
          onClick={onDelete}
        />
      )}
    </div>
  );
}
