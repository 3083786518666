import React, { useMemo, useRef, useState } from 'react';
import ContentLoader from 'react-content-loader';
import { useSelector } from 'react-redux';
import useCurrencyQuotation from '../../hooks/useCurrencyQuotation';
import { Tooltip } from 'reactstrap';
import classNames from 'classnames';
import { getOrganizationConfig } from '../../utils/organization';

import { DEFAULT_CURRENCY, stringToMoney } from '../../utils/money';
import { ReactComponent as AlertIcon } from '../../assets/img/icons/alert-circle.svg';

export default function CurrencyWrapper({
  children,
  className,
  value,
  loadingHeight = 25,
  loadingBackgroundColor = '#f2f2f9',
  loadingForegroundColor = 'transparent',
  withTooltip = true,
  decimalPlaces = 2,
  tooltipSize = 15,
  tooltipFill = '#423F90',
  atRight = true,
}) {
  const { organization } = useSelector(({ auth }) => auth);
  const { quote, loading, error } = useCurrencyQuotation({
    currency: getOrganizationConfig(organization.config, 'currency'),
  });

  // States
  const tooltipRef = useRef(null);
  const [showToolTip, setShowToolTip] = useState(false);

  const currency = useMemo(
    () => getOrganizationConfig(organization.config, 'currency'),
    [(organization, loading, error)]
  );
  const convertedValue = useMemo(
    () => (error ? value : quote * value),
    [quote, value, error]
  );

  const formattedConvertedValue = useMemo(
    () => stringToMoney(convertedValue, decimalPlaces, currency),
    [convertedValue, currency, decimalPlaces]
  );

  const rawValue = useMemo(
    () => stringToMoney(value, decimalPlaces),
    [value, decimalPlaces]
  );

  const displayTooltip = useMemo(
    () => currency !== DEFAULT_CURRENCY && withTooltip,
    [currency, withTooltip]
  );

  // Render
  const renderLoading = () => (
    <ContentLoader
      speed={2}
      height={loadingHeight}
      viewBox="0 0 100 100"
      backgroundColor={loadingBackgroundColor}
      foregroundColor={loadingForegroundColor}
      className="w-100 loading-currency"
      preserveAspectRatio="none"
    >
      <rect x="0" y="0" rx="0" ry="0" width="100%" height="100%" />
    </ContentLoader>
  );

  const renderToolTip = () => (
    <>
      <div
        className="tooltip-icon ml-1"
        ref={tooltipRef}
        onMouseEnter={() => setShowToolTip(true)}
        onMouseLeave={() => setShowToolTip(false)}
      >
        <AlertIcon
          width={tooltipSize}
          height={tooltipSize}
          fill={tooltipFill}
        />
      </div>
      <Tooltip isOpen={showToolTip} target={tooltipRef}>
        {rawValue}
      </Tooltip>
    </>
  );

  const renderContent = () => (
    <>
      {children({
        value: convertedValue,
        currency,
        formattedValue: formattedConvertedValue,
      })}
      {displayTooltip ? renderToolTip() : null}
    </>
  );

  return (
    <div
      className={classNames(
        'currency-wrapper d-flex',
        atRight ? 'justify-content-end' : 'justify-content-start',
        className
      )}
    >
      {loading ? renderLoading() : renderContent()}
    </div>
  );
}
