import classNames from 'classnames';
import React, { createRef, useEffect, useMemo, useRef, useState } from 'react';

export default function StepsBulletPoints({
  steps,
  bulletSize = 25,
  bulletColor = 'white',
  bulletBorderColor,
  bulletBorderWidth = 3,
  lineColor = 'rgba(255, 255, 255, 0.5)',
  lineWidth = 2,
  animationDuration = 500,
  animationDelay = 100,
  rightOriented,
  spaceBetweenItems = 10,
  innerBulletBorderWidth = 3,
  innerBulletColor = '#43418e',
}) {
  // Refs
  const containerRef = useRef(null);

  // States
  const [lineHeight, setLineHeight] = useState(0);

  const lineStyle = useMemo(() => {
    const lineTop = Math.ceil(bulletSize / 2);
    const horizontalPosition = Math.floor((bulletSize - lineWidth / 2) / 2);

    return {
      backgroundColor: lineColor,
      top: lineTop,
      paddingBottom: lineTop,
      ...(rightOriented
        ? { right: horizontalPosition }
        : { left: horizontalPosition }),
    };
  }, [bulletSize, lineWidth, lineColor, lineHeight, rightOriented]);

  const {
    stepRefs,
    animationDelays,
    parsedAnimationDuration,
    lineAnimationDuration,
  } = useMemo(() => {
    const stepRefs = [],
      animationDelays = [],
      halfAnimationDuration = animationDuration / 2;

    steps.forEach((_, index) => {
      const currentAnimationDelay = halfAnimationDuration * index;

      stepRefs.push(createRef());
      animationDelays.push({
        bullet: `${currentAnimationDelay}ms`,
        title: `${currentAnimationDelay + 50}ms`,
        description: `${currentAnimationDelay + 100}ms`,
      });
    });

    return {
      stepRefs,
      animationDelays,
      parsedAnimationDuration: `${animationDuration}ms`,
      lineAnimationDuration: `${animationDuration * (steps.length - 1)}ms`,
    };
  }, [steps, animationDuration, animationDelay]);

  const innerBulletSize = useMemo(
    () => bulletSize - 2 * bulletBorderWidth - innerBulletBorderWidth,
    [innerBulletBorderWidth, bulletSize, bulletBorderWidth]
  );

  // Effects
  useEffect(() => {
    const lineHeight =
      stepRefs[stepRefs.length - 1].current.getBoundingClientRect().top -
      containerRef.current.getBoundingClientRect().top;

    setLineHeight(Math.ceil(lineHeight - bulletSize / 2));
  }, [bulletSize]);

  // Render
  return (
    <ul
      className={classNames('steps-bullet-points', { right: rightOriented })}
      ref={containerRef}
    >
      <div
        className="line"
        style={{
          height: lineHeight,
          transitionDuration: lineAnimationDuration,
          ...lineStyle,
        }}
      />

      {steps.map(({ title, description, openBullet }, index) => {
        const stepAnimationDelay = animationDelays[index];

        return (
          <li
            key={`bullet-point-${index}`}
            className="bullet-point d-flex"
            ref={stepRefs[index]}
            style={{ marginTop: index ? spaceBetweenItems : 0 }}
          >
            <div
              className="bullet flex-shrink-0"
              style={{
                width: bulletSize,
                height: bulletSize,
                backgroundColor: bulletColor,
                animationDelay: stepAnimationDelay.bullet,
                animationDuration: parsedAnimationDuration,
                borderColor: bulletBorderColor,
                borderWidth: bulletBorderWidth,
              }}
            >
              {openBullet ? (
                <div
                  className="inner-bullet"
                  style={{
                    width: innerBulletSize,
                    height: innerBulletSize,
                    backgroundColor: innerBulletColor,
                  }}
                />
              ) : null}
            </div>

            <section
              className="text-section d-flex flex-column justify-content-center"
              style={{ minHeight: bulletSize }}
            >
              {title ? (
                <h3
                  className="point-title font-primary fs-0-9rem m-0"
                  style={{
                    animationDuration: parsedAnimationDuration,
                    animationDelay: stepAnimationDelay.title,
                  }}
                >
                  {title}
                </h3>
              ) : null}
              {description ? (
                <p
                  className="point-description font-primary fs-0-7rem m-0 text-white-muted lh-1-5 mt-1"
                  style={{
                    animationDuration: parsedAnimationDuration,
                    animationDelay: stepAnimationDelay.description,
                  }}
                >
                  {description}
                </p>
              ) : null}
            </section>
          </li>
        );
      })}
    </ul>
  );
}
