import React, { useMemo } from 'react';
import { getFlightJourneysAndReservations } from '../../../../utils/openBookingReservations';
import OpenBookingJourneyLarge from '../../../../components/flight/OpenBookingJourneyLarge';
import OpenBookingJourneySmall from '../../../../components/flight/OpenBookingJourneySmall';

export default function FlightDetails({
  travelParams,
  search,
  searchParams,
  showingSteps,
}) {
  // States
  const { journeys, reservations } = useMemo(
    () =>
      getFlightJourneysAndReservations({
        travelParams,
        search,
        searchParams,
      }),
    [
      search,
      searchParams,
      travelParams.outboundDate,
      travelParams.inboundDate,
      travelParams.airline,
    ]
  );

  const JourneyComponent = useMemo(
    () => (showingSteps ? OpenBookingJourneySmall : OpenBookingJourneyLarge),
    [showingSteps]
  );

  // Render
  const renderJourney = (index, journey, reservation) => {
    return (
      <JourneyComponent
        key={`journey-${index}`}
        journey={journey}
        params={searchParams}
        segments={[reservation]}
      />
    );
  };

  return (
    <div className="flight-details">
      {journeys.map((journey, index) =>
        renderJourney(index, journey, reservations[index])
      )}
    </div>
  );
}
