import React, { useEffect } from 'react';
import { useState } from 'react';
import { useRef } from 'react';
import QrReader from 'react-qr-reader';
import lottie from 'lottie-web';
import CreditCardAnimation from '../../../assets/lottie/creditCard.json';
import { useIntl } from 'react-intl';

export default function QrCodeModal({ setHeight, handleScan, handleError }) {
  const { messages } = useIntl();
  const lottieContainer = useRef(null);
  const lottieAnimation = useRef(null);
  const containerRef = useRef(null);

  const [showScanner, setShowScanner] = useState(false);

  // Effects
  useEffect(() => {
    setHeight(320);

    if (containerRef.current) {
      setTimeout(() => setHeight(containerRef.current.offsetHeight), 200);
    }

    if (lottieContainer.current) {
      lottieAnimation.current = lottie.loadAnimation({
        animationData: CreditCardAnimation,
        autoplay: false,
        loop: false,
        container: lottieContainer.current,
      });

      setTimeout(() => lottieAnimation.current.play(), 400);
      setTimeout(() => setShowScanner(true), 2300);
    }

    return () => setShowScanner(false);
  }, []);

  return (
    <div className="qr-code-modal" ref={containerRef}>
      <p className="description text-muted">
        {messages['refund.card.subtitle']}
      </p>
      <div className="mt-3 position-relative">
        <div
          ref={lottieContainer}
          style={{ width: '60%', maxWidth: 300 }}
          className="mx-auto"
        />
        {showScanner ? (
          <QrReader
            delay={300}
            onError={handleError}
            onScan={handleScan}
            className="refund-credit-card-qrcode-video"
          />
        ) : null}
      </div>
    </div>
  );
}
