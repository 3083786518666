import React from 'react';
import SlidingComponents from '../../../SlidingComponents';
import CancellationModalTab from '../../CancellationInfoTab';
import InitialTab from './tabs/InitialTab';

export const CAR_ALTERATION_TABS = {
  INITIAL: 'initial',
  CANCEL: 'cancel',
};

const COMPONENTS = {
  [CAR_ALTERATION_TABS.INITIAL]: InitialTab,
  [CAR_ALTERATION_TABS.CANCEL]: ({
    goForAlteration,
    setCurrentTab,
    ...props
  }) => (
    <CancellationModalTab
      goToInitial={() => setCurrentTab(CAR_ALTERATION_TABS.INITIAL)}
      closeTab={goForAlteration}
      {...props}
    />
  ),
};

export default function CarAlteration({
  order,
  reservations,
  goForAlteration,
  canCancel,
}) {
  return (
    <SlidingComponents
      components={COMPONENTS}
      initialTab={CAR_ALTERATION_TABS.INITIAL}
      goForAlteration={goForAlteration}
      canCancel={canCancel}
      order={order}
      reservations={reservations}
      className="flight-alteration h-100"
    />
  );
}
