import moment from 'moment';
import React, { useMemo } from 'react';
import { Label, Row } from 'reactstrap';
import IntlMessages from '../../helpers/IntlMessages';
import { stringToMoney } from '../../utils/money';
import {
  formatConsumerDocument,
  formatKey,
  formatSuppliersAddress,
} from '../../utils/receipts';
import { Colxx } from '../common/CustomBootstrap';

function NFEParams({ invoice }) {
  const {
    formattedKey,
    date,
    supplierName,
    supplierCNPJ,
    supplierAddress,
    consumerName,
    consumerDocument,
  } = useMemo(
    () => ({
      formattedKey: formatKey(invoice.key),
      date: moment(invoice.date).format('DD/MM/YYYY  HH:mm:ss'),
      supplierName: invoice.supplier?.name || '-',
      supplierCNPJ: invoice.supplier?.documents?.cnpj?.number || '-',
      supplierAddress: invoice.supplier?.address
        ? formatSuppliersAddress(invoice.supplier.address)
        : '-',
      consumerName: invoice.consumer?.nome || '-',
      consumerDocument: invoice.consumer?.documents
        ? formatConsumerDocument(
            invoice.consumer.documents.cpf?.number ||
              invoice.consumer.documents.cnpj?.number
          )
        : '-',
    }),
    [invoice]
  );

  return (
    <div className="reservation-params refund-params invoice-params px-4">
      <Row>
        <Colxx xxs="12">
          <Label className="form-group disabled search has-top-label">
            <p className="text-uppercase">{formattedKey}</p>
            <IntlMessages id="invoice.key" />
          </Label>
        </Colxx>
      </Row>
      <Row>
        <Colxx xxs="4">
          <Label className="form-group disabled search has-top-label">
            <p className="text-uppercase">{invoice.number}</p>
            <IntlMessages id="invoice.number" />
          </Label>
        </Colxx>
        <Colxx xxs="4">
          <Label className="form-group disabled search has-top-label">
            <p className="text-uppercase">{invoice.series}</p>
            <IntlMessages id="invoice.series" />
          </Label>
        </Colxx>
        <Colxx xxs="4">
          <Label className="form-group disabled search has-top-label">
            <p className="text-uppercase">{invoice.state}</p>
            <IntlMessages id="invoice.state" />
          </Label>
        </Colxx>
      </Row>
      <Row>
        <Colxx xxs="6">
          <Label className="form-group disabled search has-top-label">
            <p className="text-uppercase">{invoice.protocol}</p>
            <IntlMessages id="invoice.protocol" />
          </Label>
        </Colxx>
        <Colxx xxs="6">
          <Label className="form-group disabled search has-top-label">
            <p className="text-uppercase">{date}</p>
            <IntlMessages id="invoice.date" />
          </Label>
        </Colxx>
      </Row>
      <Row>
        <Colxx xxs="6">
          <Label className="form-group disabled search has-top-label">
            <p className="text-uppercase">{consumerName}</p>
            <IntlMessages id="invoice.consumer" />
          </Label>
        </Colxx>
        <Colxx xxs="6">
          <Label className="form-group disabled search has-top-label">
            <p className="text-uppercase">{consumerDocument}</p>
            <IntlMessages id="invoice.consumer.document" />
          </Label>
        </Colxx>
      </Row>
    </div>
  );
}

function TransactionParams({ invoice }) {
  const { name, city, country, merchantId, mcc, value } = useMemo(() => {
    const { card_transaction = {} } = invoice;

    if (!card_transaction) return {};

    const { original_amount, original_currency } =
      card_transaction.original_fare;

    return {
      name: card_transaction.name,
      city: card_transaction.merchant_city,
      country: card_transaction.merchant_country,
      merchantId: card_transaction.merchant_id,
      mcc: card_transaction.mcc,
      value: stringToMoney(original_amount, 2, original_currency),
    };
  }, [invoice]);

  return (
    <div className="reservation-params refund-params invoice-params px-4">
      <Row>
        <Colxx xxs="6">
          <Label className="form-group disabled search has-top-label">
            <p className="text-uppercase">{name}</p>
            <IntlMessages id="invoice.merchant-name" />
          </Label>
        </Colxx>
        <Colxx xxs="6">
          <Label className="form-group disabled search has-top-label">
            <p className="text-uppercase">{value}</p>
            <IntlMessages id="invoice.value" />
          </Label>
        </Colxx>
      </Row>
      <Row>
        <Colxx xxs="12">
          <Label className="form-group disabled search has-top-label">
            <p className="text-uppercase">{merchantId}</p>
            <IntlMessages id="invoice.merchant-id" />
          </Label>
        </Colxx>
      </Row>
      <Row>
        <Colxx xxs="3">
          <Label className="form-group disabled search has-top-label">
            <p className="text-uppercase">{mcc}</p>
            <IntlMessages id="invoice.mcc" />
          </Label>
        </Colxx>
        <Colxx xxs="5">
          <Label className="form-group disabled search has-top-label">
            <p className="text-uppercase">{city}</p>
            <IntlMessages id="invoice.city" />
          </Label>
        </Colxx>
        <Colxx xxs="4">
          <Label className="form-group disabled search has-top-label">
            <p className="text-uppercase">{country}</p>
            <IntlMessages id="invoice.country" />
          </Label>
        </Colxx>
      </Row>
    </div>
  );
}

export default function InvoiceParams({ invoice }) {
  const cardTransaction = useMemo(() => invoice?.card_transaction, [invoice]);

  return cardTransaction ? (
    <TransactionParams invoice={invoice} />
  ) : (
    <NFEParams invoice={invoice} />
  );
}
