import React from 'react';

import ContentLoader from 'react-content-loader';

export default function LoaderLabelData() {
  return (
    <ContentLoader
      speed={2}
      width={190}
      height={55}
      viewBox="0 0 190 55"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="0" y="5" rx="3" ry="3" width="170" height="15" />
      <rect x="0" y="25" rx="3" ry="3" width="100" height="20" />
    </ContentLoader>
  );
}
