import React from 'react';
import { useMemo } from 'react';
import Select from 'react-select';

export const CustomSelectStyle = {
  option: (provided, { isSelected, isFocused }) => ({
    ...provided,
    backgroundColor: isSelected ? '#43418e' : 'white',
    ':hover': {
      backgroundColor: isSelected ? '#43418e' : 'rgba(67, 65, 142, 0.1)',
      color: isSelected ? 'white' : 'black',
    },
    color: isSelected ? 'white' : 'black',
  }),
  singleValue: (base) => ({
    ...base,
    color: 'inherit',
  }),
  control: (base, { isFocused }) => {
    return {
      ...base,
      borderColor: isFocused ? '#43418e' : '#e0e0e0',
      boxShadow: 'none',
      ':hover': {
        borderColor: '#43418e',
        boxShadow: '0 0 0 1px rgba(67, 65, 142, 0.2)',
      },
      ':focus': {
        borderColor: '#43418e',
        boxShadow: '0 0 0 1px rgba(67, 65, 142, 0.2)',
      },
    };
  },
};

const CustomSelect = React.forwardRef(({ style, ...props }, ref) => {
  const _styles = useMemo(() => ({ ...CustomSelectStyle, ...style }), [style]);

  return <Select styles={_styles} ref={ref} {...props} />;
});

export default CustomSelect;
