import React, { useMemo, useState, useEffect } from 'react';
import { Row, Modal, ModalBody, ModalFooter, CustomInput } from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Colxx } from '../../components/common/CustomBootstrap';
import filter from 'lodash/filter';
import map from 'lodash/map';
import moment from 'moment';
import JourneyLarge from '../../components/flight/JourneyLarge';
import FlightCarbonDetail from '../../components/flight/FlightCarbonDetail';
import firebaseApp from '../../services/Firebase';

import { stringToMoney } from '../../utils/money';
import ReservationParams from './ReservationParams/ReservationParams';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import ReservationButton from './ReservationButton';
import useIsReservationValid from '../../hooks/useIsReservationValid';
import CurrencyWrapper from '../../components/common/CurrencyWrapper';
import OrderStructures from '../../constants/orderStructure';
import { getAuthRedux } from '../../utils/redux';

import HotelSuggestionModal from './HotelSuggestionModal';
import { countriesNoNeedPassport } from 'src/portao3-legacy/utils/countriesNoNeedPassport';
import FlightFareModal from './FlightFareModal';
import {
  isCheapestFare,
  isPassengerAllowedToHaveReservationWithDifferentFare,
  updateSegmentFlightFare,
  userHasSingleSearch,
} from '../../utils/search';
import { ReactComponent as ArrowLeft } from '../../assets/img/icons/arrow-left.svg';

function FlightTotal({
  orderStructure,
  item,
  segments,
  createReservation,
  serviceCharge = 0,
  reservationParams,
  availableCredits = [],
  canReservate = true,
  selectMode,
  reservationBtnMessage,
  checkBuyWithCredit,
  setCheckBuyWithCredit,
  showButtonReserve,
  setShowFareModal,
  showFareModal,
  shoulDoReservation,
  setShouldDoReservation,
  fareTotalState,
  hasSingleSearch,
}) {
  const { messages, formatMessage } = useIntl();
  const { user } = useSelector(getAuthRedux);

  const hasBaggage = useMemo(() => {
    let hasBaggage = false;

    segments.forEach((segment) => {
      if (filter(segment.flights, (f) => f.fare.baggage > 0).length > 0) {
        hasBaggage = true;
      }
    });

    return hasBaggage;
  }, [segments]);

  const isAllowedToReserveWithDifferentFare = useMemo(() => {
    if (hasSingleSearch) {
      const passengerEmail = reservationParams?.passenger?.email;

      if (passengerEmail) {
        if (
          isPassengerAllowedToHaveReservationWithDifferentFare(passengerEmail)
        ) {
          return true;
        } else return isCheapestFare(item.fare.total, item);
      } else return false;
    } else return true;
  }, [reservationParams]);

  const [isGoBackButtonHovered, setIsGoBackButtonHovered] = useState(false);

  if (!hasSingleSearch) {
    setShowFareModal(false);
  }

  const handleMouseEnter = () => {
    setIsGoBackButtonHovered(true);
  };

  const handleMouseLeave = () => {
    setIsGoBackButtonHovered(false);
  };

  const { isPersonal, additionalInfo } = useMemo(() => {
    const isPersonal = orderStructure === OrderStructures.PERSONAL;
    const additionalInfo = isPersonal
      ? formatMessage(
          { id: 'containers.search.flight-modal.taxes' },
          { taxes: stringToMoney(serviceCharge, 0, item.fare.currency) }
        )
      : null;

    return {
      isPersonal,
      additionalInfo,
    };
  }, [item, orderStructure, serviceCharge]);

  const { isValid, disabled, title } = useIsReservationValid({
    orderStructure,
    reservationParams,
  });

  const handleModalChange = () => {
    setShouldDoReservation(true);
    setShowFareModal(false);
  };

  const handleGoBackToFareModal = () => {
    setShouldDoReservation(false);
    setShowFareModal(true);
  };

  return (
    <ModalFooter>
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: '16px',
        }}
      >
        {shoulDoReservation && (
          <button
            style={{
              background: !isGoBackButtonHovered ? 'none' : '#302E65',
              border: 'none',
              borderRadius: !isGoBackButtonHovered ? 'none' : '32px',
              display: 'flex',
              cursor: 'pointer',
              height: '24px',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            onMouseOver={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={() => handleGoBackToFareModal()}
          >
            <ArrowLeft />
            <p
              style={{
                fontFamily: 'montserrat',
                fontWeight: 500,
                fontSize: '12px',
                color: '#ECECF4',
                marginLeft: '4px',
                marginBottom: 0,
              }}
            >
              Voltar para tarifas
            </p>
          </button>
        )}
      </div>
      <div>
        <div className="text mt-3 pr-3">
          <span
            style={{ paddingBottom: '8px' }}
            className="d-flex align-items-center justify-content-end"
          >
            {messages['general.total']}
          </span>
          <CurrencyWrapper
            value={fareTotalState !== null ? fareTotalState : item.fare.total}
            tooltipFill="white"
            decimalPlaces={0}
          >
            {({ formattedValue }) => formattedValue}
          </CurrencyWrapper>
          {additionalInfo ? (
            <span style={{ fontSize: '0.5em' }} className="text-theme-3 mb-2">
              {additionalInfo}
            </span>
          ) : null}
          {availableCredits.map((availableCredit, index) => {
            return (
              <span
                style={{ fontSize: '0.5em' }}
                className="text-theme-3 mb-2"
                key={`credit-${index}`}
              >
                {formatMessage(
                  { id: 'containers.search.flight-modal.credit' },
                  { ticket: availableCredit.bookingCode }
                )}
              </span>
            );
          })}
          <hr />
        </div>
        <div className="description pr-3">
          <p>{`Tarifa: ${segments[0].flights[0].fare.familyName}`}</p>
          <p>
            {formatMessage(
              { id: 'containers.search.flight-modal.reservation' },
              {
                numAdults: item.fare.quantity,
                adults:
                  item.fare.quantity > 1
                    ? messages['general.adults']
                    : messages['general.adult'],
              }
            )}
          </p>

          {item.fare.issueUntil && (
            <p className="color-alert">
              {formatMessage(
                { id: 'containers.search.flight-modal.valid-until' },
                {
                  date: moment(item.fare.issueUntil).format('DD/MM/YYYY HH:mm'),
                }
              )}
            </p>
          )}

          {!hasBaggage && (
            <p className="mb-0 text-small">
              {messages['containers.search.flight-modal.baggages-not-included']}
            </p>
          )}
          <p className="mb-0 text-small">
            {
              messages[
                isPersonal
                  ? 'containers.search.modal.taxes'
                  : 'containers.search.modal.fees'
              ]
            }
          </p>
        </div>

        {!!availableCredits.length && (
          <div className="checkbox-container-flight-modal">
            <CustomInput
              type="checkbox"
              id="withCredit"
              checked={checkBuyWithCredit}
              onChange={() => {
                setCheckBuyWithCredit(!checkBuyWithCredit);
              }}
            />
            <label class="label-container-flight-modal" for="withCredit">
              {messages['conatiner.issue.with.credit']}
            </label>
          </div>
        )}

        {showButtonReserve && (
          <ReservationButton
            onClick={
              hasSingleSearch && showFareModal
                ? () => handleModalChange()
                : createReservation
            }
            disabled={
              showFareModal
                ? false
                : isAllowedToReserveWithDifferentFare
                ? !selectMode && (!canReservate || disabled)
                : true
            }
            title={title}
            isValid={selectMode ? true : isValid}
            selectMode={selectMode}
            text={
              hasSingleSearch && showFareModal
                ? messages['flight.select-fare-card-btn-goToReservation']
                : reservationBtnMessage
            }
            showFareModal={showFareModal}
            isAllowedToReserveWithDifferentFare={
              isAllowedToReserveWithDifferentFare
            }
          />
        )}
      </div>
    </ModalFooter>
  );
}

export default function FlightModal({
  toggleModal,
  item,
  segments,
  params,
  reserveFlight,
  selectMode,
  reservationBtnMessage,
}) {
  const [reservationParams, setReservationParams] = useState({});
  const [orderStructure, setStructure] = useState(OrderStructures.ORGANIZATION);
  const [checkBuyWithCredit, setCheckBuyWithCredit] = useState(false);
  const [showButtonReserve, setShowButtonReserve] = useState(true);
  const [showSuggestionHotels, setShowSuggestionHotels] = useState(false);
  const [modalFlight, setModalFlight] = useState(true);
  const { user } = useSelector(getAuthRedux);
  const [needPassport, setNeedPassport] = useState(false);
  const [showFareModal, setShowFareModal] = useState(true);
  const [fareTotal, setFareTotal] = useState(null);
  const [shoulDoReservation, setShouldDoReservation] = useState(false);

  const hasSingleSearch = userHasSingleSearch(user);

  const updateFare = (fareOption) => {
    item.fare.total = fareOption.total;
    item.fare.taxes = fareOption.taxes;
    item.fare.fare = fareOption.fare;
    setFareTotal(item.fare.total);
    updateSegmentFlightFare(segments, fareOption);
  };

  // States
  const serviceCharge = useSelector(({ auth }) =>
    parseFloat(auth.organization.fee?.flight || 0)
  );

  const [availableCredits, setAvailableCredits] = useState([]);

  useEffect(() => {
    if (!selectMode) {
      loadCredits();
    }
  }, [reservationParams.passengerId]);

  useEffect(() => {
    compareCountry();
  }, []);

  // Functions
  const loadCredits = async () => {
    if (reservationParams.passengerId) {
      const creditsSnap = await firebaseApp
        .getCreditsFromOrganizationAndUserAndCarrier(
          user.organizationId,
          reservationParams.passengerId,
          item.validatingAirline,
          true
        )
        .get();

      const credits = [];
      creditsSnap.forEach((creditSnap) => {
        credits.push(creditSnap.data());
      });

      setAvailableCredits(credits);
    }
  };

  const getAirport = (iataCode) =>
    new Promise(async (res, rej) => {
      const snap = await firebaseApp.getAirportFromIATA(iataCode).get();
      res(snap);
    });

  const compareCountry = async () => {
    const arrivalStation = item?.journeys?.[0]?.arrivalStation;
    const departureStation = item?.journeys?.[0]?.departureStation;

    let arrivalStationCountry = '';
    let departureStationCountry = '';

    await getAirport(arrivalStation).then((resp) => {
      arrivalStationCountry = resp.data().country;
    });

    await getAirport(departureStation).then((resp) => {
      departureStationCountry = resp.data().country;
    });

    const needPassportValidation = () => {
      const userNationality = user?.nationality;

      const southAmericaFromSouthAmerica =
        countriesNoNeedPassport.includes(arrivalStationCountry) &&
        countriesNoNeedPassport.includes(departureStationCountry);

      const southAmericaNationality =
        countriesNoNeedPassport.includes(userNationality);

      const userIsInYourCountry =
        userNationality === arrivalStationCountry &&
        userNationality === departureStationCountry;

      return false;

      if (userIsInYourCountry) return false;
      if (southAmericaFromSouthAmerica && southAmericaNationality) return false;

      return arrivalStationCountry !== departureStationCountry;
    };

    setNeedPassport(needPassportValidation);
  };

  // Make Reservation
  const createReservation = () => {
    if (selectMode) return toggleModal();

    setShowButtonReserve(false);
    setShowSuggestionHotels(true);

    if (orderStructure) {
      reserveFlight({
        buyWithCredit: checkBuyWithCredit,
        reservationParams,
        item,
        selectedSegments: segments,
      });
    }
  };

  const closeReserveHotel = (closeSuggestionHotels) => {
    toggleModal();
    setShowSuggestionHotels(closeSuggestionHotels);
  };

  // Render
  return (
    <Modal
      isOpen={modalFlight}
      toggle={toggleModal}
      className="reservation medium"
    >
      {!hasSingleSearch || shoulDoReservation ? (
        <ModalBody className="flight">
          {!showSuggestionHotels ? (
            <Row>
              <Colxx xxs="12">
                <div className="flight-content">
                  <PerfectScrollbar
                    options={{
                      suppressScrollX: true,
                      wheelPropagation: false,
                    }}
                  >
                    {selectMode ? null : (
                      <ReservationParams
                        needPassport={needPassport}
                        orderStructure={orderStructure}
                        setStructure={setStructure}
                        reservationParams={reservationParams}
                        setReservationParams={setReservationParams}
                        nationality={user.nationality}
                      />
                    )}
                    <div className="mt-5">
                      {map(item.journeys, (journey, index) => {
                        return (
                          <JourneyLarge
                            key={`journey-${index}`}
                            journey={journey}
                            index={index}
                            params={params}
                            flights={segments[index].flights}
                          />
                        );
                      })}
                    </div>

                    {selectMode ? null : (
                      <FlightCarbonDetail segments={segments} />
                    )}
                  </PerfectScrollbar>
                </div>
              </Colxx>
            </Row>
          ) : (
            <HotelSuggestionModal
              closeReserveHotel={closeReserveHotel}
              reservationParams={reservationParams}
            />
          )}
        </ModalBody>
      ) : (
        <FlightFareModal
          segments={segments}
          updateFare={updateFare}
          item={item}
        />
      )}

      <FlightTotal
        orderStructure={orderStructure}
        item={item}
        setCheckBuyWithCredit={setCheckBuyWithCredit}
        checkBuyWithCredit={checkBuyWithCredit}
        segments={segments}
        availableCredits={availableCredits}
        createReservation={createReservation}
        serviceCharge={serviceCharge}
        reservationParams={reservationParams}
        selectMode={selectMode}
        reservationBtnMessage={reservationBtnMessage}
        showButtonReserve={showButtonReserve}
        setShowFareModal={setShowFareModal}
        showFareModal={showFareModal}
        shoulDoReservation={shoulDoReservation}
        setShouldDoReservation={setShouldDoReservation}
        fareTotalState={fareTotal}
        hasSingleSearch={hasSingleSearch}
      />
    </Modal>
  );
}
