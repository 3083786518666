import enLang from './entries/en-US';
import esLang from './entries/es-ES';
import ptLang from './entries/pt-BR';

const AppLocale = {
  'en-US': enLang,
  'es-ES': esLang,
  'pt-BR': ptLang,
};

export const MomentLocales = {
  'es-ES': 'es',
  'pt-BR': 'pt-br',
};

export function validateLanguage(language) {
  return language in AppLocale ? language : 'pt-BR';
}

export default AppLocale;
