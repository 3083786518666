import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

export default function AdministrativeCardInfo({ card, color }) {
  const { messages } = useIntl();

  // States
  const { cardName, subtitle } = useMemo(
    () => ({
      cardName: card.name,
      subtitle: messages['refund.payment-method.ADMINISTRATIVE.title'],
    }),
    [card]
  );

  // Render
  return (
    <div className="administrative-card-info">
      <h3 className="last-digits p-0 m-0 font-primary" style={{ color }}>
        {cardName}
      </h3>
      <h2 className="name p-0" style={{ color }}>
        {subtitle}
      </h2>
    </div>
  );
}
