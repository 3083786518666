import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Label, Button, Collapse, Row, CardBody } from 'reactstrap';
import { Colxx } from '../../components/common/CustomBootstrap';
import { useIntl } from 'react-intl';
import useDataSnapshot from '../../hooks/useDataSnapshot';
import { getOrganizationConfig } from '../../utils/organization';

import Switch from 'rc-switch';
import 'rc-switch/assets/index.css';

import Bank3Modal from '../../components/finance/Bank3Modal';
import { ReactComponent as PermissionImg } from '../../assets/img/expenses/permissions.svg';

import firebaseApp from '../../services/Firebase';

export default function RefundConfigDataListView() {
  const { messages } = useIntl();
  const { user, organization } = useSelector(({ auth }) => auth);
  const [accordion, setAccordion] = useState(false);
  const [vcnConfig, setVcnConfig] = useState(false);

  // Get Bank 3 Connector
  const [bank3Connector, setConnector, isLoadingConnector] = useDataSnapshot({
    fetchFunction: () => {
      return firebaseApp.getBank3CardsFromOrganization(
        user.organizationId,
        user.uid
      );
    },
  });

  /**
   * Extract organization details
   */
  const {
    active = false,
    allowAdministrative = false,
    automaticPaymentByPix = false,
    withoutReceipt = false,
  } = organization.config?.refunds || {};

  // Update Config
  const updateExpenses = async (checked) => {
    try {
      await firebaseApp.updateRefundsConfig(organization.id, checked);
    } catch (err) {
      console.error(err);
    }
  };

  const updateReceipt = async (checked) => {
    try {
      await firebaseApp.updateReceiptsConfig(organization.id, checked);
    } catch (err) {
      console.error(err);
    }
  };

  const updateAdministrative = async (checked) => {
    try {
      await firebaseApp.updateAdministrativeConfig(organization.id, checked);
    } catch (err) {
      console.error(err);
    }
  };

  const updateAutomaticPayment = async (checked) => {
    try {
      await firebaseApp.updateAutomaticPaymentConfig(organization.id, checked);
    } catch (err) {
      console.error(err);
    }
  };

  const toggleAccordion = () => {
    setAccordion(!accordion);
  };

  return (
    <Row className="travel-data-list-view d-flex justify-content-between mt-5 ml-3 w-100">
      <Colxx
        xxs="12"
        md="5"
        className="d-flex justify-content-center align-self-start"
      >
        <PermissionImg width={'85%'} />
      </Colxx>
      <Colxx xxs="12" md="7" className="mt-3 pl-3">
        <p className="text-one w-70 mt-3 slide-left">
          {messages['admin.refunds.permissions.description']}
        </p>
        <div
          className="slide-left mt-4 mb-5"
          style={{ animationDelay: '25ms' }}
        >
          <Label>
            <Switch
              checked={active}
              onChange={updateExpenses}
              className="mr-2 custom-switch"
            />
            {messages[`admin.refunds.permissions.allow`]}
          </Label>

          <p className="text-one w-70 mt-3">
            {messages['admin.automatic.payments.description']}
          </p>
          <div className="mt-4 mb-5">
            <Label>
              <Switch
                className="mr-2 custom-switch"
                checked={automaticPaymentByPix}
                onChange={updateAutomaticPayment}
              />
              {messages['admin.automatic.payments.config']}
            </Label>
          </div>

          <div className="border mt-4">
            <Button
              color="link"
              onClick={toggleAccordion}
              aria-expanded={accordion}
            >
              {messages['admin.refunds.advanced_options']}
            </Button>
            <Collapse isOpen={accordion}>
              <Row>
                <Colxx sm="12">
                  <CardBody>
                    <p className="text-one w-70 mt-3">
                      {messages['admin.receipts.permissions.description']}
                    </p>
                    <div className="mt-4 mb-5">
                      <Label>
                        <Switch
                          checked={withoutReceipt}
                          onChange={updateReceipt}
                          className="mr-2 custom-switch"
                        />
                        {messages[`admin.receipts.permissions.allow`]}
                      </Label>
                    </div>

                    <p className="text-one w-70 mt-3">
                      {messages['admin.receipts.card.description']}
                    </p>
                    <div className="mt-4 mb-5">
                      <Label>
                        {bank3Connector.length ? (
                          <Switch
                            checked={true}
                            className="mr-2 custom-switch"
                            disabled={true}
                          />
                        ) : (
                          <Switch
                            checked={false}
                            className="mr-2 custom-switch"
                            onClick={() => setVcnConfig(true)}
                          />
                        )}
                        {messages[`admin.receipts.card.config`]}
                      </Label>
                    </div>

                    <p className="text-one w-70 mt-3">
                      {messages['admin.receipts.administrative.description']}
                    </p>
                    <div className="mt-4 mb-5">
                      <Label>
                        <Switch
                          className="mr-2 custom-switch"
                          checked={allowAdministrative}
                          onChange={updateAdministrative}
                        />
                        {messages['admin.receipts.administrative.config']}
                      </Label>
                    </div>
                  </CardBody>
                </Colxx>
              </Row>
            </Collapse>
          </div>
        </div>
      </Colxx>

      {vcnConfig && <Bank3Modal toggleModal={() => setVcnConfig(false)} />}
    </Row>
  );
}
