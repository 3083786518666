import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Alert, Modal, ModalHeader, ModalBody } from 'reactstrap';

import firebaseApp from '../../../services/Firebase';
import appFunctions from '../../../services/Functions';
import QrCodeModal from './QrCodeModal';

import { useMemo } from 'react';
import CancelModal from './CancelModal';
import ValidatingModal from './ValidatingModal';
import ReplaceUserModal from './ReplaceUserModal';
import CreatePinModal from './CreatePinModal';
import SuccessModal from './SuccessModal';
import { getPassengerRedux } from '../../../utils/redux';

const CONTENT_COMPONENTS = {
  QR_READER: QrCodeModal,
  VALIDATION: ValidatingModal,
  REPLACE_USER: ReplaceUserModal,
  CREATE_PIN: CreatePinModal,
  CANCEL: CancelModal,
  SUCCESS: SuccessModal,
};

export default function CreditCardNewModal({ closeModal }) {
  const { messages } = useIntl();
  const { passenger } = useSelector(getPassengerRedux);

  const [step, setStep] = useState('QR_READER');
  const [cardId, setCardId] = useState(null);
  const [error, setError] = useState(null);
  const [height, setHeight] = useState(100);

  const ContentComponent = useMemo(() => CONTENT_COMPONENTS[step], [step]);

  // QR Code Reader
  const handleScan = (cardId) => {
    if (cardId) {
      if (step !== 'QR_READER') setStep('QR_READER');
      setCardId(cardId);
    }
  };

  useEffect(() => {
    if (cardId) validateCard();
  }, [cardId]);

  const handleError = (err) => {
    console.error(err);
    setError(err);
  };

  // Validate Card
  const validateCard = async () => {
    setStep('VALIDATION');
    const cardSnap = await firebaseApp
      .getExpenseCardFromId(passenger.organizationId, cardId)
      .get();

    if (cardSnap.exists) {
      setStep('REPLACE_USER');
    } else {
      createCard();
    }
  };

  // Create and Move the Card
  const createCard = async () => {
    try {
      await appFunctions.createExpensesCard(passenger.organizationId, {
        card_id: cardId,
        user_id: passenger.uid,
      });

      setStep('CREATE_PIN');
    } catch (err) {
      console.error(err);
      setStep('CANCEL');
      setError(messages['forms.validation.creditCard.genericError']);
    }
  };

  // Update a card pin
  const updateCardPin = async (pin) => {
    try {
      await appFunctions.updateExpenseCardPin(
        passenger.organizationId,
        cardId,
        pin
      );
      setStep('SUCCESS');
    } catch (err) {
      console.error(err);
      setStep('CANCEL');
      setError(messages['forms.validation.creditCard.genericError']);
    }
  };

  const replaceUser = async () => {
    await appFunctions.updateExpenseCardUserId(
      passenger.organizationId,
      cardId,
      passenger.uid
    );
    setStep('CREATE_PIN');
  };

  // Render
  return (
    <Modal
      isOpen={true}
      toggle={closeModal}
      size="lg"
      className="refund-new-card-modal"
      name="physical-card-modal"
    >
      <ModalHeader toggle={closeModal}>
        {messages['refund.physical-card.create.title']}
      </ModalHeader>
      <ModalBody className="pt-1">
        {error && error.message && (
          <Alert color="danger" className="mt-2 mb-2">
            {error.name}: {error.message}
          </Alert>
        )}

        <div
          className="content d-flex align-items-center justify-content-center"
          style={{ height }}
        >
          {ContentComponent && (
            <ContentComponent
              handleError={handleError}
              handleScan={handleScan}
              closeModal={closeModal}
              replaceUser={replaceUser}
              updateCardPin={updateCardPin}
              setHeight={setHeight}
              setStep={setStep}
              setError={setError}
              cardId={cardId}
            />
          )}
        </div>
      </ModalBody>
    </Modal>
  );
}
