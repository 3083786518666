import React, { useMemo, useState } from 'react';
import AlterationFrame from '../../AlterationFrame';
import { useIntl } from 'react-intl';
import { Button } from 'reactstrap';
import { sortReservationJourneys } from '../../../../../utils/reservation';
import useExecuteAction from '../../../../../hooks/useExecuteAction';
import BusSegmentSeats from './BusSegmentSeats';
import firebaseApp from '../../../../../services/Firebase';

export default function AlterationTab({
  order,
  goToPrevious,
  alterableReservations,
  goForAlteration,
}) {
  const { messages } = useIntl();

  const { executeAction, error, loading } = useExecuteAction();

  // States
  const [seatsInfo, setSeatsInfo] = useState({});

  const cannotRequestSeats = useMemo(
    () => Object.keys(seatsInfo).length === 0,
    [seatsInfo]
  );

  const { sortedReservations, isRoundTrip } = useMemo(() => {
    const sortedReservations = sortReservationJourneys(alterableReservations);
    return {
      sortedReservations,
      isRoundTrip: sortedReservations.length > 1,
    };
  }, [alterableReservations]);

  // Functions
  const onSeatSelection = (sellKey, seatId) => {
    setSeatsInfo((seats) => {
      if (seatId) {
        return { ...seats, [sellKey]: seatId };
      } else {
        const { [sellKey]: item, ...others } = seats;
        return others;
      }
    });
  };

  const requestSeatChange = () => {
    executeAction(
      (async () => {
        await firebaseApp.requestToSelectSeat(order.id, seatsInfo);
        goForAlteration();
      })()
    );
  };

  // Render
  const renderBottom = () => (
    <div className="d-flex align-items-center justify-content-end">
      <Button color="link" onClick={goToPrevious} className="m-0">
        {messages['general.go-back']}
      </Button>
      <Button
        color="primary"
        onClick={requestSeatChange}
        disabled={cannotRequestSeats || loading}
        className="m-0 ml-2"
      >
        {messages['general.done']}
      </Button>
    </div>
  );

  return (
    <AlterationFrame
      title={messages['travel.alter.bus.alteration.seats.title']}
      subtitle={messages['travel.alter.bus.alteration.seats.description']}
      BottomBar={renderBottom}
      className="flight-selection"
    >
      {error ? (
        <p className="error-badge mb-4">
          {messages['travel.alter.bus.alteration.seats.error']}
        </p>
      ) : null}

      <div className="mt-3">
        {sortedReservations.map((reservations, index) => (
          <BusSegmentSeats
            className={index ? 'mt-4' : ''}
            key={`segment-${index}`}
            isRoundTrip={isRoundTrip}
            segmentIndex={index}
            reservations={reservations}
            onSeatSelection={onSeatSelection}
            seatsInfo={seatsInfo}
          />
        ))}
      </div>
    </AlterationFrame>
  );
}
