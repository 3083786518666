import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import ProfileImage from '../ProfileImage';
import User from '../../services/User';

import AsyncSelect from 'react-select/async';
import { components } from 'react-select';

import axios from 'axios';
import map from 'lodash/map';
import { useIntl } from 'react-intl';
import { setPassenger } from '../../redux/actions';
import firebaseApp from '../../services/Firebase';

const passengers = {};

const Option = (props) => {
  return (
    <div
      className={classNames({
        'react-select__menu_option': true,
        active: props.isFocused,
      })}
    >
      <components.Option {...props} />
    </div>
  );
};

export default function UserSwitcher({
  className = '',
  width = 300,
  onUserChange,
}) {
  const passenger = useSelector(
    ({ auth, timeline }) => timeline.passenger || auth.user
  );
  const dispatch = useDispatch();

  const { messages } = useIntl();

  const [show, setShow] = useState(false);
  const [focused, setFocused] = useState(false);
  const [token, setToken] = useState(null);
  const [block, setBlock] = useState(false);

  useEffect(() => {
    (async () => {
      setToken(await User.getToken());
    })();
  }, []);

  useEffect(() => {
    if (show) {
      setBlock(true);
      const timeout = setTimeout(() => setBlock(false), 400);
      return () => clearTimeout(timeout);
    } else if (block) {
      setBlock(false);
    }
  }, [show]);

  // Functions
  const findUser = (inputValue) => {
    return new Promise(async (resolve) => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}/users/search`,
          {
            params: { q: inputValue },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (res.data.users.length > 0) {
          const results = map(res.data.users, (user) => {
            return {
              value: user.value,
              label: user.label,
              avatar: user.avatar,
            };
          });

          resolve(results);
        }

        resolve([]);
      } catch (err) {
        console.error(err);
        resolve([]);
      }
    });
  };

  const changePassenger = async ({ value }) => {
    let passenger = passengers[value];

    if (!passenger) {
      const passengerSnap = await firebaseApp.getUserFromId(value).get();

      passenger = {
        ...passengerSnap.data(),
        id: passengerSnap.id,
      };
    }

    dispatch(setPassenger(passenger));

    if (onUserChange) onUserChange(passenger);
  };

  // Render
  return (
    <div
      className={classNames(
        'user-switcher d-flex align-items-center',
        className,
        { show, focused, block }
      )}
    >
      <div className="switcher-container">
        <div
          className="users pointer"
          onClick={() => setShow(!show)}
          role="button"
        >
          <ProfileImage user={passenger} />
        </div>
        <div
          className="content flex-shrink-0"
          style={{ width: show ? width : 0 }}
        >
          <AsyncSelect
            className="react-select"
            classNamePrefix="react-select"
            isClearable={false}
            components={{ Option }}
            cacheOptions
            placeholder={messages['refund.location-message']}
            noOptionsMessage={() => {
              return messages['refund.location-message'];
            }}
            loadingMessage={() => {
              return messages['refund.location-searching'];
            }}
            onChange={(user) => {
              changePassenger(user);
              setShow(false);
            }}
            loadOptions={findUser}
            onMenuOpen={() => !focused && setFocused(true)}
            onMenuClose={() => focused && setFocused(false)}
          />
        </div>
      </div>
      <div className="background" />
    </div>
  );
}
