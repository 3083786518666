import * as yup from 'yup';
import messages from '../lang/messages';
import { isRGValid } from '../utils/rg';

const isValidDateFormat = (date) => {
  const moment = require('moment');

  if (!date) return true;

  return moment(date, ['DD/MM/YYYY'], true).isValid();
};

const { isCpfValid } = require('../utils/cpf');

const UserSchema = yup.object().shape({
  firstName: yup
    .string()
    .matches(
      /^[A-Za-z][A-Za-z ]+$/,
      messages['forms.validation.firstName.invalid']
    )
    .required(messages['forms.validation.firstName.required']),
  lastName: yup
    .string()
    .matches(
      /^[A-Za-z][A-Za-z ]+$/,
      messages['forms.validation.lastName.invalid']
    )
    .required(messages['forms.validation.lastName.required']),
  gender: yup.object().shape({
    value: yup
      .string()
      .matches(/(MALE|FEMALE)/, messages['forms.validation.gender.valid']),
    label: yup.string(),
  }),
  birthdate: yup
    .string()
    .test(
      'valid-date',
      messages['forms.validation.birthdate.valid'],
      isValidDateFormat
    )
    .required(messages['forms.validation.birthdate.required']),
  nationality: yup.object().shape({
    value: yup.string(),
    label: yup.string(),
  }),
  email: yup
    .string()
    .email(messages['forms.validation.email.valid'])
    .required(messages['forms.validation.email.required']),
  mobilePhoneNumber: yup
    .string()
    .matches(/^[\d|\+| |(|)|-]*$/, messages['forms.validation.phone.invalid']),
  role: yup.string(),
  companyId: yup.string(),
  documents: yup.object({
    rg: yup.object().shape(
      {
        number: yup
          .string()
          .test(
            'rg-validator',
            messages['forms.validation.rg.number.valid'],
            function (number) {
              if (number) {
                if (isRGValid(number)) {
                  return this.parent.emitter
                    ? true
                    : this.createError({
                        message:
                          messages['forms.validation.emitter.number.empty'],
                      });
                } else return false;
              } else return true;
            }
          ),
        emitter: yup
          .string()
          .test(
            'emitter-validator',
            messages['forms.validation.emitter.number.empty'],
            function (emitter) {
              if (emitter) {
                return this.parent.number
                  ? true
                  : this.createError({
                      message: messages['forms.validation.rg.number.empty'],
                    });
              } else return true;
            }
          ),
      },
      ['number', 'emitter']
    ),
    cpf: yup.object({
      number: yup
        .string()
        .test(
          'valid-date',
          messages['forms.validation.cpf.number.valid'],
          (value) => !value || isCpfValid(value)
        ),
    }),
    identity: yup.object({
      number: yup
        .string()
        .test(
          'valid-identity',
          messages['forms.validation.identity.number.valid'],
          (value = '') => /^[A-z0-9]*$/.test(value)
        ),
    }),
    cnh: yup.object({
      number: yup.string(),
      validUntil: yup
        .string()
        .test(
          'valid-date',
          messages['forms.validation.cnh.validUntil.valid'],
          isValidDateFormat
        ),
    }),
    passport: yup.object({
      country: yup.string(),
      number: yup.string(),
      validUntil: yup
        .string()
        .test(
          'valid-date',
          messages['forms.validation.passport.validUntil.valid'],
          isValidDateFormat
        ),
    }),
  }),
  costCenter: yup.object().shape({
    value: yup.string(),
    label: yup.string(),
  }),
  project: yup.object().shape({
    value: yup.string(),
    label: yup.string(),
  }),
});

export default UserSchema;
