import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { stringToMoney } from '../../utils/money';

export default function Bank3TransferCard({ transfer }) {
  const { messages } = useIntl();

  // States
  const receivedFrom = useMemo(
    () => transfer?.incoming_origin?.name,
    [transfer]
  );

  // Render
  return (
    <div className="order-card" style={{ cursor: 'default' }}>
      <div className="pl-2 d-flex flex-grow-1 mw-0">
        <div className="card-body align-self-center d-flex flex-column flex-lg-row justify-content-between mw-0 align-items-lg-center">
          <div className="w-30 w-sm-100">
            <p className="list-item-heading mb-1 truncate">
              <span className="text-weigth-500">
                {messages['admin.finance.bank3.funds-additon']}
              </span>
              <br />
              {receivedFrom ? (
                <span className="text-muted text-small text-uppercase">
                  {receivedFrom}
                </span>
              ) : null}
            </p>
          </div>
        </div>
        <div className="custom-control custom-checkbox pl-1 align-self-center pr-4">
          {stringToMoney(transfer.amount / 100, 2)}
        </div>
      </div>
    </div>
  );
}
