import classNames from 'classnames';
import React, { useMemo } from 'react';

export default function Category({
  className,
  category,
  size = 15,
  alt,
  categorySrc = '/assets/img/hotel-star.svg',
}) {
  const categoryArr = useMemo(() => new Array(category).fill(0), [category]);

  // Render
  return (
    <div className={classNames('category d-flex', className)}>
      {categoryArr.map((_, index) => (
        <img
          key={`category-${index}`}
          src={categorySrc}
          className="mr-1"
          width={size}
          height={size}
          alt={alt}
        />
      ))}
    </div>
  );
}
