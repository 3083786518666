import React, { useMemo, useRef } from 'react';
import { useIntl } from 'react-intl';
import { Button } from 'reactstrap';
import classNames from 'classnames';
import useShowToggle from '../../hooks/useShowToggle';
import SeatSelectorModal from './SeatSelectorModal/SeatSelectorModal';

export default function SeatSelector({
  className,
  seat,
  scheduleId,
  onSelect,
}) {
  const { messages, formatMessage } = useIntl();

  const targetRef = useRef(null);

  // States
  const [show, _, toggle] = useShowToggle(false);

  const title = useMemo(
    () =>
      seat
        ? formatMessage({ id: 'travel.bus.seat' }, { seat })
        : messages['travel.bus.choose-seat'],
    [seat]
  );

  // Render
  return (
    <>
      <div ref={targetRef}>
        <Button
          className={classNames(
            'pretty-btn primary fs-0-7rem pill px-3 py-2',
            className
          )}
          onClick={toggle}
        >
          {title}
        </Button>
      </div>

      <SeatSelectorModal
        show={show}
        toggle={toggle}
        scheduleId={scheduleId}
        target={targetRef}
        onSelect={onSelect}
      />
    </>
  );
}
