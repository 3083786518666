const data = [
  {
    id: 'refunds',
    icon: 'iconsminds-dollar',
    label: 'menu.expenses',
    to: '/app/payments',
  },
  {
    id: 'travels',
    icon: 'iconsminds-suitcase',
    label: 'menu.travels',
    to: '/app/travels',
  },
  {
    id: 'policy',
    icon: 'iconsminds-check',
    label: 'menu.management',
    to: '/app/management',
  },
  {
    id: 'users',
    icon: 'iconsminds-business-man-woman',
    label: 'menu.users',
    to: '/app/users',
  },
  {
    id: 'finance',
    icon: 'iconsminds-wallet',
    label: 'menu.finance',
    to: '/app/finance',
  },
  {
    id: 'integrations',
    icon: 'iconsminds-usb',
    label: 'menu.integrations',
    to: '/app/integrations',
  },
  {
    id: 'others',
    icon: 'iconsminds-gear',
    label: 'menu.others',
    to: '/app/others',
  },
];
export default data;
