import React, { useMemo, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import classNames from 'classnames';
import ExpenseBottom from '../ExpenseItem/ExpenseBottom';
import ItemTab from './ItemTab';
import { RefundStatus } from '../../../constants/refundStatus';
import { useIntl } from 'react-intl';

export default function AdministrativeExpenseItem({ budget, item, toggle }) {
  const { messages } = useIntl();

  // States
  const [atBottom, setAtBottom] = useState(false);

  const infoText = useMemo(() => {
    return budget.statusCode < RefundStatus.APPROVED
      ? messages['administrative-expense-item.not-paid']
      : '';
  }, [budget]);

  // Render
  const renderInfo = () => {
    return (
      <p className="m-0 p-0 w-40 info text-right text-warning font-weight-medium">
        {infoText}
      </p>
    );
  };

  return (
    <>
      <PerfectScrollbar
        options={{
          suppressScrollX: true,
          wheelPropagation: false,
        }}
        className={classNames('expense-item pb-4 mr-0 pr-0')}
        style={{ position: 'static' }}
        onScrollUp={() => atBottom && setAtBottom(false)}
        onYReachEnd={() => !atBottom && setAtBottom(true)}
      >
        <ItemTab
          budget={budget}
          item={item}
          toggle={toggle}
          atBottom={atBottom}
        />

        <ExpenseBottom
          budget={budget}
          total={item?.fare?.total}
          canCreate={false}
          editable={false}
          isNew={false}
          atBottom={atBottom}
          multiplier={1.5}
          InfoComponent={renderInfo}
        />
      </PerfectScrollbar>
    </>
  );
}
