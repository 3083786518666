import React, { useMemo } from 'react';
import DateSearch from '../../../../../search/DateSearch';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { FlightTypes } from '../../../../../../constants/flight';

import { ReactComponent as OneWayIcon } from '../../../../../../assets/img/icons/oneway.svg';
import { ReactComponent as CancelIcon } from '../../../../../../assets/img/icons/cancel.svg';
import { ReactComponent as FlightIcon } from '../../../../../../assets/img/icons/flight.svg';
import { ReactComponent as ArrowIcon } from '../../../../../../assets/img/icons/dropdown_arrow_bold.svg';

export default function DateSelection({
  order,
  flights,
  journey,
  selectedDate,
  setSelectedDate,
  roundTrip,
  type,
}) {
  const { messages } = useIntl();

  const { isEnd, startDate } = useMemo(() => {
    if (type === FlightTypes.INBOUND) {
      return {
        isEnd: true,
        startDate: moment(
          (flights.outbound || order).journeys[0].departureDate,
          'YYYY-MM-DD'
        ),
      };
    } else return {};
  }, [flights, order]);

  return (
    <div className="mt-3">
      <h3 className="text-white font-primary fs-1rem">
        <FlightIcon
          width="1.2rem"
          height="1.2rem"
          fill="white"
          className="mr-2"
        />
        {messages['travel.alter.flight.alteration.search.journey']}
        {roundTrip ? (
          <span className="ml-1">{type === FlightTypes.OUTBOUND ? 1 : 2}</span>
        ) : null}
      </h3>
      <p className="d-flex align-items-center text-white-muted">
        <span>
          {journey.departureStation.name} ({journey.departureStation.iataCode})
        </span>
        <OneWayIcon width="1.2rem" height="1.2rem" className=" mx-2" />
        <span>
          {journey.arrivalStation.name} ({journey.arrivalStation.iataCode})
        </span>
      </p>
      <h3 className="text-white font-primary fs-1rem d-flex align-items-center mt-4">
        <CancelIcon
          width="1.2rem"
          height="1.2rem"
          fill="white"
          className="mr-2"
        />
        {messages['travel.alter.flight.alteration.search.select-date']}
      </h3>
      <div className="date-container">
        <DateSearch
          selectedDate={selectedDate}
          setValue={(value) => setSelectedDate(value)}
          placeholder="DD/MM/YY"
          defaultStartDate={startDate}
          isEnd={isEnd}
          defaultMinDate={startDate}
          portalId="root"
        />
        <ArrowIcon className="arrow-icon" fill="white" />
      </div>
    </div>
  );
}
