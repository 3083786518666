import classNames from 'classnames';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import CustomSelect from '../../../components/common/CustomSelect';
import recordStatus from '../../../constants/recordStatus';

const Style = {
  control: (base, { isFocused }) => {
    return {
      ...base,
      borderColor: 'transparent',
      backgroundColor: '#43418e',
      color: 'white',
      borderRadius: 100,
      paddingLeft: 8,
      paddingRight: 8,
      boxShadow: 'none',
      ':hover': {
        borderColor: 'transparent',
        boxShadow: 'none',
      },
      ':focus': {
        borderColor: 'transparent',
        boxShadow: 'none',
      },
    };
  },
  dropdownIndicator: (base) => ({
    ...base,
    color: 'white',
    ':hover': {
      color: 'white',
    },
    ':focus': {
      color: 'green',
    },
  }),
};

export default function StatusSelector({
  className,
  status,
  setStatus,
  editable = false,
  isSearchable = false,
  allowedStatus = [recordStatus.ACTIVE, recordStatus.DEACTIVATED],
  messagePrefix = 'status-selector',
}) {
  const { messages } = useIntl();

  // States
  const options = useMemo(
    () =>
      allowedStatus.map((value) => ({
        value,
        label: messages[`${messagePrefix}.${value}`],
      })),
    [allowedStatus, messagePrefix]
  );

  const value = useMemo(
    () => options.find(({ value }) => value === status),
    [options, status]
  );

  // Render
  return (
    <CustomSelect
      className={classNames('status-selector', className)}
      options={options}
      value={value}
      onChange={({ value }) => setStatus(value)}
      editable={editable}
      isSearchable={isSearchable}
      components={{
        IndicatorSeparator: () => null,
      }}
      style={Style}
    />
  );
}
