/* SETTINGS */
export const CHANGE_LOCALE = 'CHANGE_LOCALE';

/* AUTH */
export const SET_AUTH = 'SET_AUTH';
export const SET_USER = 'SET_USER';
export const SET_ORGANIZATION = 'SET_ORGANIZATION';
export const LOGOUT_USER = 'LOGOUT_USER';
export const SET_HAS_EXPENSE_CARD = 'SET_HAS_EXPENSE_CARD';

/* LOCATIONS */
export const SET_LOCATION = 'SET_LOCATION';

/* TIMELINE */
export const SET_PASSENGER = 'SET_PASSENGER';
export const SET_SEARCH_CODES_WITH_ERROR = 'SET_SEARCH_CODES_WITH_ERROR';
export const CLEAR_SEARCH_CODES_WITH_ERROR = 'CLEAR_SEARCH_CODES_WITH_ERROR';

/* CHECKLIST */
export const SET_CHECKLIST = 'SET_CHECKLIST';
export const TOGGLE_ITEM = 'TOGGLE_ITEM';
export const SET_CHECKLIST_OPEN = 'SET_CHECKLIST_OPEN';
export const RESET_CHECKLIST = 'RESET_CHECKLIST';
export const SET_CHECKLIST_COMPLETE = 'SET_CHECKLIST_COMPLETE';

/* ANIMATION */
export const TOGGLE_MASK = 'TOGGLE_MASK';

/* REFUNDS */
export const TOGGLE_CREATION_MODE = 'TOGGLE_CREATION_MODE';
export const TOGGLE_SELECTED_REFUND = 'TOGGLE_SELECTED_REFUND';
export const CLEAN_SELECTED_REFUND = 'CLEAN_SELECTED_REFUND';

/* APPROVALS */
export const SET_APPROVAL_INFO = 'SET_APPROVAL_INFO';

/* RESERVATIONS */
export const STORE_RESERVATION_ACTION = 'STORE_RESERVATION';
export const RESET_RESERVATION_ACTION = 'RESET_RESERVATION';

/* BULK IMPORT */
export const STORE_BULK_DATA = 'STORE_BULK_DATA';
export const SET_BULK_LOADING = 'SET_BULK_LOADING';
export const CLEAR_BULK_DATA = 'CLEAR_BULK_DATA';

/* RECURRENCE BUDGETS */
export const TOGGLE_BUDGET_DRAGGING = 'TOGGLE_BUDGET_DRAGGING';

/* REPORT EXPENSES */
export const ADD_BUDGETS = 'ADD_BUDGETS';
export const ADD_BUDGET_REPORT_EXPENSE = 'ADD_BUDGET_REPORT_EXPENSE';
export const REMOVE_BUDGET_REPORT_EXPENSE = 'REMOVE_BUDGET_REPORT_EXPENSE';
export const ADD_ALL_BUDGETS_REPORT_EXPENSE = 'ADD_ALL_BUDGETS_REPORT_EXPENSE';
export const REMOVE_ALL_BUDGETS_REPORT_EXPENSE_BY_CARD_ID =
  'REMOVE_ALL_BUDGETS_REPORT_EXPENSE_BY_CARD_ID';
export const RESET_SELECTED_BUDGETS = 'RESET_SELECTED_BUDGETS';

export const UPDATE_SEARCH_HOTEL = 'UPDATE_SEARCH_HOTEL';

export * from './settings/actions';
export * from './auth/actions';
export * from './locations/actions';
export * from './timeline/actions';
export * from './checklist/actions';
export * from './animation/actions';
export * from './refunds/actions';
export * from './approvals/actions';
export * from './reservations/actions';
export * from './recurrenceBudgets/actions';
export * from './reportExpenses/actions';
export * from './searchHotel/actions';
