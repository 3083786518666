import { useIntl } from 'react-intl';

export default function VitallyComponent() {
  window.VitallyStatus = true;
  const vitallyImport = window.Vitally;
  const { messages } = useIntl();
  vitallyImport.init(process.env.VIALLY_KEY);

  vitallyImport.nps('survey', {
    productName: 'Portão 3',
    primaryColor: '#43418e',
    npsQuestion: messages['nps.questions'],
    autoLoadSegment: true,
    thanksTitle: messages['nps.thanks'],
    followUpTitle: ({ score }) => `${messages['nps.followUp']} ${score}?`,
    followUpSubtitle: '',
    placeholderText: messages['nps.placeholder'],
    dismissText: messages['nps.dismiss'],
    submitText: messages['nps.submit'],
    minLabel: messages['nps.min.label'],
    maxLabel: messages['nps.max.label'],
    delay: 5000,
  });
}
