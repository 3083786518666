// Types
export const cabinTypes = [
  'Economy',
  'PremiumEconomy',
  'Business',
  'FirstClass',
];

export const locationTypes = {
  city: {
    style: 'city',
    type: 'CITY',
  },
  airport: {
    style: 'airport',
    type: 'AIRPORT',
  },
  other: {
    style: 'other',
    type: 'OTHER',
  },
  store: {
    style: 'store',
    type: 'STORE',
  },
  bus_station: {
    style: 'bus',
    type: 'BUS',
  },
  bus: {
    style: 'bus',
    type: 'BUS',
  },
  locality: {
    style: 'city',
    type: 'CITY',
  },
  grocery_or_supermarket: {
    style: 'store',
    type: 'STORE',
  },
  point_of_interest: {
    style: 'other',
    type: 'OTHER',
  },
  electronics_store: {
    style: 'store',
    type: 'STORE',
  },
  restaurant: {
    style: 'store',
    type: 'STORE',
  },
  neighborhood: {
    style: 'neighborhood',
    type: 'NEIGHBORHOOD',
  },
  street_address: {
    style: 'neighborhood',
    type: 'NEIGHBORHOOD',
  },
};

// Airlines
export const registeredAirlines = {
  LA: {
    name: 'LATAM',
    logo: '/assets/img/logos/latam.svg',
    logoBlack: '/assets/img/logos/latam_black.svg',
  },
  JJ: {
    name: 'LATAM',
    logo: '/assets/img/logos/latam.svg',
    logoBlack: '/assets/img/logos/latam_black.svg',
  },
  G3: {
    name: 'GOL',
    logo: '/assets/img/logos/gol.svg',
    logoBlack: '/assets/img/logos/gol_black.svg',
  },
  AD: {
    name: 'Azul',
    logo: '/assets/img/logos/azul.svg',
    logoBlack: '/assets/img/logos/azul_black.svg',
  },
  '2F': {
    name: 'Azul Conecta',
    logo: '/assets/img/logos/azulconecta.svg',
    logoBlack: '/assets/img/logos/azulconecta_black.svg',
  },
  '8I': {
    name: 'Itapemirim',
    logo: '/assets/img/logos/itapemirim.svg',
    logoBlack: '/assets/img/logos/itapemirim_black.svg',
  },
  '2Z': {
    name: 'Voepass',
    logo: '/assets/img/logos/voepass.svg',
    logoBlack: '/assets/img/logos/voepass_black.svg',
  },
  AA: {
    name: 'American Airlines',
    logo: '/assets/img/logos/american.svg',
    logoBlack: '/assets/img/logos/american_black.svg',
  },
  AC: {
    name: 'Air Canada',
    logo: '/assets/img/logos/aircanada.svg',
    logoBlack: '/assets/img/logos/aircanada_black.svg',
  },
  AM: {
    name: 'Aeroméxico',
    logo: '/assets/img/logos/aeromexico.svg',
    logoBlack: '/assets/img/logos/aeromexico_black.svg',
  },
  AR: {
    name: 'Aerolíneas Argentinas',
    logo: '/assets/img/logos/aerolineas.svg',
    logoBlack: '/assets/img/logos/aerolineas_black.svg',
  },
  AS: {
    name: 'Alaska Airlines',
    logo: '/assets/img/logos/alaska.svg',
    logoBlack: '/assets/img/logos/alaska_black.svg',
  },
  AV: {
    name: 'Avianca',
    logo: '/assets/img/logos/avianca.svg',
    logoBlack: '/assets/img/logos/avianca_black.svg',
  },
  AZ: {
    name: 'Alitalia',
    logo: '/assets/img/logos/alitalia.svg',
    logoBlack: '/assets/img/logos/alitalia_black.svg',
  },
  CA: {
    name: 'Air China',
    logo: '/assets/img/logos/airchina.svg',
    logoBlack: '/assets/img/logos/airchina_black.svg',
  },
  CM: {
    name: 'Copa Airlines',
    logo: '/assets/img/logos/copa.svg',
    logoBlack: '/assets/img/logos/copa_black.svg',
  },
  DL: {
    name: 'Delta Airlines',
    logo: '/assets/img/logos/delta.svg',
    logoBlack: '/assets/img/logos/delta_black.svg',
  },
  UA: {
    name: 'United Airlines',
    logo: '/assets/img/logos/united.svg',
    logoBlack: '/assets/img/logos/united_black.svg',
  },
  F9: {
    name: 'Frontier Airlines',
    logo: '/assets/img/logos/frontier.svg',
    logoBlack: '/assets/img/logos/frontier_black.svg',
  },
  AF: {
    name: 'Air France',
    logo: '/assets/img/logos/airfrance.svg',
    logoBlack: '/assets/img/logos/airfrance_black.svg',
  },
  BA: {
    name: 'British Airways',
    logo: '/assets/img/logos/british.svg',
    logoBlack: '/assets/img/logos/british_black.svg',
  },
  IB: {
    name: 'Iberia',
    logo: '/assets/img/logos/iberia.svg',
    logoBlack: '/assets/img/logos/iberia_black.svg',
  },
  KL: {
    name: 'KLM',
    logo: '/assets/img/logos/klm.svg',
    logoBlack: '/assets/img/logos/klm_black.svg',
  },
  LH: {
    name: 'Lufthansa',
    logo: '/assets/img/logos/lufthansa.svg',
    logoBlack: '/assets/img/logos/lufthansa_black.svg',
  },
  TK: {
    name: 'Turkish Airlines',
    logo: '/assets/img/logos/turkish.svg',
    logoBlack: '/assets/img/logos/turkish_black.svg',
  },
  TP: {
    name: 'TAP Air Portugal',
    logo: '/assets/img/logos/tap.svg',
    logoBlack: '/assets/img/logos/tap_black.svg',
  },
  EK: {
    name: 'Emirates',
    logo: '/assets/img/logos/emirates.svg',
    logoBlack: '/assets/img/logos/emirates_black.svg',
  },
  QR: {
    name: 'Qatar Airways',
    logo: '/assets/img/logos/qatar.svg',
    logoBlack: '/assets/img/logos/qatar_black.svg',
  },
  EY: {
    name: 'Etihad',
    logo: '/assets/img/logos/etihad.svg',
    logoBlack: '/assets/img/logos/etihad_black.svg',
  },
  QF: {
    name: 'Qantas',
    logo: '/assets/img/logos/qantas.svg',
    logoBlack: '/assets/img/logos/qantas_black.svg',
  },
  NH: {
    name: 'ANA',
    logo: '/assets/img/logos/ana.svg',
    logoBlack: '/assets/img/logos/ana_black.svg',
  },
  UX: {
    name: 'Air Europa',
    logo: '/assets/img/logos/aireuropa.svg',
    logoBlack: '/assets/img/logos/aireuropa_black.svg',
  },
  SA: {
    name: 'South African Airways',
    logo: '/assets/img/logos/south_african.svg',
    logoBlack: '/assets/img/logos/south_african_black.svg',
  },
  JL: {
    name: 'Japan Airlines',
    logo: '/assets/img/logos/japan.svg',
    logoBlack: '/assets/img/logos/japan_black.svg',
  },
  LX: {
    name: 'Swiss',
    logo: '/assets/img/logos/swiss.svg',
    logoBlack: '/assets/img/logos/swiss_black.svg',
  },
  SN: {
    name: 'Brussels Airlines',
    logo: '/assets/img/logos/brussels.svg',
    logoBlack: '/assets/img/logos/brussels_black.svg',
  },
  H2: {
    name: 'Sky Airline',
    logo: '/assets/img/logos/sky.svg',
    logoBlack: '/assets/img/logos/sky_black.svg',
  },
  FR: {
    name: 'Ryanair',
    logo: '/assets/img/logos/ryanair.svg',
    logoBlack: '/assets/img/logos/ryanair_black.svg',
  },
  DT: {
    name: 'TAAG',
    logo: '/assets/img/logos/taag.svg',
    logoBlack: '/assets/img/logos/taag_black.svg',
  },
  Y4: {
    name: 'Volaris',
    logo: '/assets/img/logos/volaris.svg',
    logoBlack: '/assets/img/logos/volaris_black.svg',
  },
};

// Car Companies
export const registeredCompanies = {
  MO: {
    name: 'Movida',
    logo: '/assets/img/logos/movida.svg',
    logoBlack: '/assets/img/logos/movida_black.svg',
  },
  AC: {
    name: 'ACE',
    logo: '/assets/img/logos/ace.svg',
    logoBlack: '/assets/img/logos/ace_black.svg',
  },
  AD: {
    name: 'Advantage Rent-A-Car',
    logo: '/assets/img/logos/advantage_car.svg',
    logoBlack: '/assets/img/logos/advantage_car_black.svg',
  },
  AL: {
    name: 'Alamo',
    logo: '/assets/img/logos/alamo.svg',
    logoBlack: '/assets/img/logos/alamo_black.svg',
  },
  EP: {
    name: 'Europcar',
    logo: '/assets/img/logos/europcar.svg',
    logoBlack: '/assets/img/logos/europcar_black.svg',
  },
  ET: {
    name: 'Enterprise',
    logo: '/assets/img/logos/enterprise.svg',
    logoBlack: '/assets/img/logos/enterprise_black.svg',
  },
  LL: {
    name: 'Localiza',
    logo: '/assets/img/logos/localiza.svg',
    logoBlack: '/assets/img/logos/localiza_black.svg',
  },
  SX: {
    name: 'Sixt',
    logo: '/assets/img/logos/sixt.svg',
    logoBlack: '/assets/img/logos/sixt_black.svg',
  },
  UN: {
    name: 'Unidas',
    logo: '/assets/img/logos/unidas.svg',
    logoBlack: '/assets/img/logos/unidas_black.svg',
  },
  ZD: {
    name: 'Budget',
    logo: '/assets/img/logos/budget.svg',
    logoBlack: '/assets/img/logos/budget_black.svg',
  },
  ZE: {
    name: 'Hertz',
    logo: '/assets/img/logos/hertz.svg',
    logoBlack: '/assets/img/logos/hertz_black.svg',
  },
  ZI: {
    name: 'AVIS',
    logo: '/assets/img/logos/avis.svg',
    logoBlack: '/assets/img/logos/avis_black.svg',
  },
  ZL: {
    name: 'NATIONAL',
    logo: '/assets/img/logos/national.svg',
    logoBlack: '/assets/img/logos/national_black.svg',
  },
  ZR: {
    name: 'DOLLAR',
    logo: '/assets/img/logos/dollar.svg',
    logoBlack: '/assets/img/logos/dollar_black.svg',
  },
  ZT: {
    name: 'Thrifty',
    logo: '/assets/img/logos/thrifty.svg',
    logoBlack: '/assets/img/logos/thrifty_black.svg',
  },
  FOCO: {
    name: 'FOCO',
    logo: '/assets/img/logos/foco.svg',
    logoBlack: '/assets/img/logos/foco_black.svg',
  },
};

/*
AO: ACO
AT: ATESA
BW: BLUEWAY
CI: CITER
DS: DISCOUNT RENTALS
EY: ECONOMY RAC
EZ: EZ
FF: FIREFLY

FX: FOX
GM: GREENMOTION
MAGGI: MAGGI
NU: NU CAR RENTALS
RK: REZLINK
SC: SILVERCAR
SV: USAVE AUTO
ZA: PAYLESS
AMIGOS: AMIGOS
*/

// Hotel Companies
export const registeredHotels = {
  'Thompson Hotels': {
    name: 'Thompson Hotels',
    logo: '/assets/img/logos/hotels/thompson.svg',
    logoBlack: '/assets/img/logos/hotels/thompson_black.svg',
  },
  Andaz: {
    name: 'Andaz',
    logo: '/assets/img/logos/hotels/andaz.svg',
    logoBlack: '/assets/img/logos/hotels/andaz_black.svg',
  },
  'Grand Hyatt': {
    name: 'Grand Hyatt',
    logo: '/assets/img/logos/hotels/grand-hyatt.svg',
    logoBlack: '/assets/img/logos/hotels/grand-hyatt_black.svg',
  },
  'Hyatt Zilara': {
    name: 'Hyatt Zilara',
    logo: '/assets/img/logos/hotels/zilara.svg',
    logoBlack: '/assets/img/logos/hotels/zilara_black.svg',
  },
  'Hyatt Hotels': {
    name: 'Hyatt Hotels',
    logo: '/assets/img/logos/hotels/hyatt.svg',
    logoBlack: '/assets/img/logos/hotels/hyatt_black.svg',
  },
  'Hyatt Place': {
    name: 'Hyatt Place',
    logo: '/assets/img/logos/hotels/hyattplace.svg',
    logoBlack: '/assets/img/logos/hotels/hyattplace_black.svg',
  },
  'HYATT house': {
    name: 'Hyatt house',
    logo: '/assets/img/logos/hotels/hyatthouse.svg',
    logoBlack: '/assets/img/logos/hotels/hyatthouse_black.svg',
  },
  'Hilton Hotels': {
    name: 'Hilton Hotels',
    logo: '/assets/img/logos/hotels/hilton.svg',
    logoBlack: '/assets/img/logos/hotels/hilton_black.svg',
  },
  'Hilton International': {
    name: 'Hilton International',
    logo: '/assets/img/logos/hotels/hilton.svg',
    logoBlack: '/assets/img/logos/hotels/hilton_black.svg',
  },
  Conrad: {
    name: 'Conrad',
    logo: '/assets/img/logos/hotels/conrad.svg',
    logoBlack: '/assets/img/logos/hotels/conrad_black.svg',
  },
  'Hilton Grand Vacations': {
    name: 'Hilton Grand Vacations',
    logo: '/assets/img/logos/hotels/hiltongrand.svg',
    logoBlack: '/assets/img/logos/hotels/hiltongrand_black.svg',
  },
  'Home2 Suites by Hilton': {
    name: 'Home2 Suites by Hilton',
    logo: '/assets/img/logos/hotels/home2.svg',
    logoBlack: '/assets/img/logos/hotels/home2_black.svg',
  },
  'Homewood Suites': {
    name: 'Homewood Suites',
    logo: '/assets/img/logos/hotels/homewood.svg',
    logoBlack: '/assets/img/logos/hotels/homewood_black.svg',
  },
  'Tru by Hilton': {
    name: 'Tru by Hilton',
    logo: '/assets/img/logos/hotels/tru.svg',
    logoBlack: '/assets/img/logos/hotels/tru_black.svg',
  },
  'Hampton Inn': {
    name: 'Hampton Inn',
    logo: '/assets/img/logos/hotels/hampton.svg',
    logoBlack: '/assets/img/logos/hotels/hilton_black.svg',
  },
  'Hilton Garden Inn': {
    name: 'Hilton Garden Inn',
    logo: '/assets/img/logos/hotels/gardeninn.svg',
    logoBlack: '/assets/img/logos/hotels/gardeninn_black.svg',
  },
  'Embassy Suites': {
    name: 'Embassy Suites',
    logo: '/assets/img/logos/hotels/embassy.svg',
    logoBlack: '/assets/img/logos/hotels/embassy_black.svg',
  },
  'Tapestry Collection by Hilton': {
    name: 'Tapestry Collection by Hilton',
    logo: '/assets/img/logos/hotels/tapestry.svg',
    logoBlack: '/assets/img/logos/hotels/tapestry_black.svg',
  },
  Doubletree: {
    name: 'Doubletree',
    logo: '/assets/img/logos/hotels/doubletree.svg',
    logoBlack: '/assets/img/logos/hotels/doubletree_black.svg',
  },
  'Waldorf Astoria': {
    name: 'Waldorf Astoria',
    logo: '/assets/img/logos/hotels/waldorf.svg',
    logoBlack: '/assets/img/logos/hotels/waldorf_black.svg',
  },
  'Autograph Collection Hotels & ': {
    name: 'Autograph Collection Hotels',
    logo: '/assets/img/logos/hotels/autograph.svg',
    logoBlack: '/assets/img/logos/hotels/autograph_black.svg',
  },
  aloft: {
    name: 'aloft',
    logo: '/assets/img/logos/hotels/aloft.svg',
    logoBlack: '/assets/img/logos/hotels/aloft_black.svg',
  },
  'SpringHill Suites': {
    name: 'SpringHill Suites',
    logo: '/assets/img/logos/hotels/springhill.svg',
    logoBlack: '/assets/img/logos/hotels/springhill_black.svg',
  },
  'Four Points': {
    name: 'Four Points',
    logo: '/assets/img/logos/hotels/fourpoints.svg',
    logoBlack: '/assets/img/logos/hotels/fourpoints_black.svg',
  },
  'Gaylord Entertainment': {
    name: 'Four Points',
    logo: '/assets/img/logos/hotels/gaylord.svg',
    logoBlack: '/assets/img/logos/hotels/gaylord_black.svg',
  },
  Courtyard: {
    name: 'Courtyard',
    logo: '/assets/img/logos/hotels/courtyard.svg',
    logoBlack: '/assets/img/logos/hotels/courtyard_black.svg',
  },
  Westin: {
    name: 'Westin',
    logo: '/assets/img/logos/hotels/westin.svg',
    logoBlack: '/assets/img/logos/hotels/westin_black.svg',
  },
  'Le Meridien': {
    name: 'Le Meridien',
    logo: '/assets/img/logos/hotels/lemeridien.svg',
    logoBlack: '/assets/img/logos/hotels/lemeridien_black.svg',
  },
  'Sheraton Hotel': {
    name: 'Sheraton Hotel',
    logo: '/assets/img/logos/hotels/sheraton.svg',
    logoBlack: '/assets/img/logos/hotels/sheraton_black.svg',
  },
  Edition: {
    name: 'Edition',
    logo: '/assets/img/logos/hotels/edition.svg',
    logoBlack: '/assets/img/logos/hotels/edition_black.svg',
  },
  'W Hotel': {
    name: 'W Hotels',
    logo: '/assets/img/logos/hotels/whotels.svg',
    logoBlack: '/assets/img/logos/hotels/whotels_black.svg',
  },
  'JW Marriott': {
    name: 'JW Marriott',
    logo: '/assets/img/logos/hotels/jw.svg',
    logoBlack: '/assets/img/logos/hotels/jw_black.svg',
  },
  'Marriott Hotels & Resorts': {
    name: 'Marriott Hotels & Resorts',
    logo: '/assets/img/logos/hotels/marriott.svg',
    logoBlack: '/assets/img/logos/hotels/marriott_black.svg',
  },
  'Luxury Collection': {
    name: 'Luxury Collection',
    logo: '/assets/img/logos/hotels/luxurycollection.svg',
    logoBlack: '/assets/img/logos/hotels/luxurycollection_black.svg',
  },
  'Ritz-Carlton': {
    name: 'Ritz-Carlton',
    logo: '/assets/img/logos/hotels/ritz.svg',
    logoBlack: '/assets/img/logos/hotels/ritz_black.svg',
  },
  Swissotel: {
    name: 'Swissotel',
    logo: '/assets/img/logos/hotels/swissotel.svg',
    logoBlack: '/assets/img/logos/hotels/swiss_black.svg',
  },
  Fairmont: {
    name: 'Fairmont',
    logo: '/assets/img/logos/hotels/fairmont.svg',
    logoBlack: '/assets/img/logos/hotels/fairmont_black.svg',
  },
  'M Gallery': {
    name: 'M Gallery',
    logo: '/assets/img/logos/hotels/mgallery.svg',
    logoBlack: '/assets/img/logos/hotels/mgallery_black.svg',
  },
  Sofitel: {
    name: 'Sofitel',
    logo: '/assets/img/logos/hotels/sofitel.svg',
    logoBlack: '/assets/img/logos/hotels/sofitel_black.svg',
  },
  'SO Sofitel': {
    name: 'SO Sofitel',
    logo: '/assets/img/logos/hotels/sosofitel.svg',
    logoBlack: '/assets/img/logos/hotels/sosofitel_black.svg',
  },
  'Sofitel Legend': {
    name: 'Sofitel Legend',
    logo: '/assets/img/logos/hotels/sofitellegend.svg',
    logoBlack: '/assets/img/logos/hotels/sofitellegend_black.svg',
  },
  'Grand Mercure': {
    name: 'Grand Mercure',
    logo: '/assets/img/logos/hotels/grandmercure.svg',
    logoBlack: '/assets/img/logos/hotels/grandmercure_black.svg',
  },
  'Pullman Hotels and Resorts': {
    name: 'Pullman Hotels and Resorts',
    logo: '/assets/img/logos/hotels/pullman.svg',
    logoBlack: '/assets/img/logos/hotels/pullman_black.svg',
  },
  'Adagio by Accor': {
    name: 'Adagio',
    logo: '/assets/img/logos/hotels/adagio.svg',
    logoBlack: '/assets/img/logos/hotels/adagio_black.svg',
  },
  Mercure: {
    name: 'Mercure',
    logo: '/assets/img/logos/hotels/mercure.svg',
    logoBlack: '/assets/img/logos/hotels/mercure_black.svg',
  },
  Novotel: {
    name: 'Novotel',
    logo: '/assets/img/logos/hotels/novotel.svg',
    logoBlack: '/assets/img/logos/hotels/novotel_black.svg',
  },
  Ibis: {
    name: 'Ibis',
    logo: '/assets/img/logos/hotels/ibis.svg',
    logoBlack: '/assets/img/logos/hotels/ibis.svg',
  },
  'Ibis Budget': {
    name: 'Ibis Budget',
    logo: '/assets/img/logos/hotels/ibisbudget.svg',
    logoBlack: '/assets/img/logos/hotels/ibisbudget.svg',
  },
  'Ibis Styles': {
    name: 'Ibis Styles',
    logo: '/assets/img/logos/hotels/ibisstyles.svg',
    logoBlack: '/assets/img/logos/hotels/ibisstyles.svg',
  },
  'Nobile Express': {
    name: 'Nobile Express',
    logo: '/assets/img/logos/hotels/nobile.svg',
    logoBlack: '/assets/img/logos/hotels/novotel_black.svg',
  },
  'Nobile Hoteis': {
    name: 'Nobile Hoteis',
    logo: '/assets/img/logos/hotels/nobile.svg',
    logoBlack: '/assets/img/logos/hotels/novotel_black.svg',
  },
  'Nobile Inn': {
    name: 'Nobile Inn',
    logo: '/assets/img/logos/hotels/nobile.svg',
    logoBlack: '/assets/img/logos/hotels/novotel_black.svg',
  },
  'Nobile Suites': {
    name: 'Nobile Suites',
    logo: '/assets/img/logos/hotels/nobile.svg',
    logoBlack: '/assets/img/logos/hotels/novotel_black.svg',
  },
  'San Diego Suites': {
    name: 'San Diego Suites',
    logo: '/assets/img/logos/hotels/nobile.svg',
    logoBlack: '/assets/img/logos/hotels/novotel_black.svg',
  },
  'San Diego Express': {
    name: 'San Diego Express',
    logo: '/assets/img/logos/hotels/sandiego.svg',
    logoBlack: '/assets/img/logos/hotels/sandiego_black.svg',
  },
  Radisson: {
    name: 'Radisson',
    logo: '/assets/img/logos/hotels/radisson.svg',
    logoBlack: '/assets/img/logos/hotels/radisson_black.svg',
  },
  'Radisson Blu': {
    name: 'Radisson Blu',
    logo: '/assets/img/logos/hotels/radissonblu.svg',
    logoBlack: '/assets/img/logos/hotels/radissonblu_black.svg',
  },
  'Radisson Collection': {
    name: 'Radisson Collection',
    logo: '/assets/img/logos/hotels/radissoncollection.svg',
    logoBlack: '/assets/img/logos/hotels/radissoncollection_black.svg',
  },
  'Radisson Edwardian': {
    name: 'Radisson',
    logo: '/assets/img/logos/hotels/radisson.svg',
    logoBlack: '/assets/img/logos/hotels/radisson_black.svg',
  },
  'Radisson RED': {
    name: 'Radisson RED',
    logo: '/assets/img/logos/hotels/radissonred.svg',
    logoBlack: '/assets/img/logos/hotels/radissonred.svg',
  },
  'Park Inn': {
    name: 'Park Inn',
    logo: '/assets/img/logos/hotels/parkinn.svg',
    logoBlack: '/assets/img/logos/hotels/parkinn_black.svg',
  },
  Ramada: {
    name: 'Ramada',
    logo: '/assets/img/logos/hotels/ramada.svg',
    logoBlack: '/assets/img/logos/hotels/ramada_black.svg',
  },
  'TRYP by Wyndham': {
    name: 'TRYP',
    logo: '/assets/img/logos/hotels/tryp.svg',
    logoBlack: '/assets/img/logos/hotels/tryp_black.svg',
  },
  'Tryp Hotels': {
    name: 'TRYP',
    logo: '/assets/img/logos/hotels/tryp.svg',
    logoBlack: '/assets/img/logos/hotels/tryp_black.svg',
  },
  'TRYP Hotels': {
    name: 'TRYP',
    logo: '/assets/img/logos/hotels/tryp.svg',
    logoBlack: '/assets/img/logos/hotels/tryp_black.svg',
  },
  'Microtel Inn': {
    name: 'Microtel Inn',
    logo: '/assets/img/logos/hotels/microtel.svg',
    logoBlack: '/assets/img/logos/hotels/microtel_black.svg',
  },
  Americinn: {
    name: 'Americinn',
    logo: '/assets/img/logos/hotels/americinn.svg',
    logoBlack: '/assets/img/logos/hotels/americinn_black.svg',
  },
  'Wingate Inn': {
    name: 'Wingate Inn',
    logo: '/assets/img/logos/hotels/wingate.svg',
    logoBlack: '/assets/img/logos/hotels/wingate_black.svg',
  },
  Trademark: {
    name: 'Trademark',
    logo: '/assets/img/logos/hotels/trademark.svg',
    logoBlack: '/assets/img/logos/hotels/trademark_black.svg',
  },
  'Wyndham Hotels': {
    name: 'Wyndham Hotels',
    logo: '/assets/img/logos/hotels/wyndham.svg',
    logoBlack: '/assets/img/logos/hotels/wyndham_black.svg',
  },
  'Dolce Hotels and Resorts': {
    name: 'Dolce Hotels and Resorts',
    logo: '/assets/img/logos/hotels/dolce.svg',
    logoBlack: '/assets/img/logos/hotels/dolce_black.svg',
  },
  'Gran Melia': {
    name: 'Gran Melia',
    logo: '/assets/img/logos/hotels/granmelia.svg',
    logoBlack: '/assets/img/logos/hotels/granmelia_black.svg',
  },
  'Me by Melia': {
    name: 'Me by Melia',
    logo: '/assets/img/logos/hotels/me.svg',
    logoBlack: '/assets/img/logos/hotels/me_black.svg',
  },
  Paradisus: {
    name: 'Paradisus',
    logo: '/assets/img/logos/hotels/paradisus.svg',
    logoBlack: '/assets/img/logos/hotels/paradisus_black.svg',
  },
  Innside: {
    name: 'Innside',
    logo: '/assets/img/logos/hotels/innside.svg',
    logoBlack: '/assets/img/logos/hotels/innside_black.svg',
  },
  Melia: {
    name: 'Meliã',
    logo: '/assets/img/logos/hotels/melia.svg',
    logoBlack: '/assets/img/logos/hotels/melia_black.svg',
  },
  'Meliã Hotels & Resorts': {
    name: 'Meliã',
    logo: '/assets/img/logos/hotels/melia.svg',
    logoBlack: '/assets/img/logos/hotels/melia_black.svg',
  },
  'Sleep Inn': {
    name: 'Sleep Inn',
    logo: '/assets/img/logos/hotels/sleepinn.svg',
    logoBlack: '/assets/img/logos/hotels/sleepinn.svg',
  },
  Quality: {
    name: 'Quality',
    logo: '/assets/img/logos/hotels/qualityinn.svg',
    logoBlack: '/assets/img/logos/hotels/qualityinn.svg',
  },
  'Comfort Inn': {
    name: 'Comfort Inn',
    logo: '/assets/img/logos/hotels/comfort.svg',
    logoBlack: '/assets/img/logos/hotels/comfort.svg',
  },
  Clarion: {
    name: 'Clarion',
    logo: '/assets/img/logos/hotels/clarion.svg',
    logoBlack: '/assets/img/logos/hotels/clarion.svg',
  },
  'Slaviero Conceptual': {
    name: 'Slaviero Conceptual',
    logo: '/assets/img/logos/hotels/slaviero.svg',
    logoBlack: '/assets/img/logos/hotels/slaviero_black.svg',
  },
  'Slaviero Essential': {
    name: 'Slaviero Essential',
    logo: '/assets/img/logos/hotels/slaviero.svg',
    logoBlack: '/assets/img/logos/hotels/slaviero_black.svg',
  },
  'Slaviero Executive': {
    name: 'Slaviero Executive',
    logo: '/assets/img/logos/hotels/slaviero.svg',
    logoBlack: '/assets/img/logos/hotels/slaviero_black.svg',
  },
  'Slaviero Fast Sleep': {
    name: 'Slaviero Fast Sleep',
    logo: '/assets/img/logos/hotels/slaviero.svg',
    logoBlack: '/assets/img/logos/hotels/slaviero_black.svg',
  },
  'Slaviero Hoteis': {
    name: 'Slaviero Hoteis',
    logo: '/assets/img/logos/hotels/slaviero.svg',
    logoBlack: '/assets/img/logos/hotels/slaviero_black.svg',
  },
  'Slaviero Slim': {
    name: 'Slaviero Slim',
    logo: '/assets/img/logos/hotels/slaviero.svg',
    logoBlack: '/assets/img/logos/hotels/slaviero_black.svg',
  },
  'Slaviero Suites': {
    name: 'Slaviero Suites',
    logo: '/assets/img/logos/hotels/slaviero.svg',
    logoBlack: '/assets/img/logos/hotels/slaviero_black.svg',
  },
  'Holiday Inn': {
    name: 'Holiday Inn',
    logo: '/assets/img/logos/hotels/holiday.svg',
    logoBlack: '/assets/img/logos/hotels/holiday_black.svg',
  },
  'Comfort Suites': {
    name: 'Comfort Suites',
    logo: '/assets/img/logos/hotels/comfortsuites.svg',
    logoBlack: '/assets/img/logos/hotels/comfortsuites.svg',
  },
  Intercity: {
    name: 'Intercity',
    logo: '/assets/img/logos/hotels/intercity.svg',
    logoBlack: '/assets/img/logos/hotels/intercity_black.svg',
  },
  InterCityHotels: {
    name: 'InterCityHotels',
    logo: '/assets/img/logos/hotels/intercity.svg',
    logoBlack: '/assets/img/logos/hotels/intercity_black.svg',
  },
  'Blue Tree Park': {
    name: 'Blue Tree Park',
    logo: '/assets/img/logos/hotels/bluetree.svg',
    logoBlack: '/assets/img/logos/hotels/bluetree_black.svg',
  },
  'Blue Tree Premium': {
    name: 'Blue Tree Premium',
    logo: '/assets/img/logos/hotels/bluetree.svg',
    logoBlack: '/assets/img/logos/hotels/bluetree_black.svg',
  },
  'Blue Tree Towers': {
    name: 'Blue Tree Towers',
    logo: '/assets/img/logos/hotels/bluetree.svg',
    logoBlack: '/assets/img/logos/hotels/bluetree_black.svg',
  },
  'B&B Hotels': {
    name: 'B&B Hotels',
    logo: '/assets/img/logos/hotels/bb.svg',
    logoBlack: '/assets/img/logos/hotels/bb.svg',
  },
  'Best Western': {
    name: 'Best Western',
    logo: '/assets/img/logos/hotels/bestwestern.svg',
    logoBlack: '/assets/img/logos/hotels/bestwestern_black.svg',
  },
};

// Bus Companies
export const registeredBus = {
  1001: {
    name: '1001',
    logo: '/assets/img/logos/bus/1001.svg',
    logoBlack: '/assets/img/logos/1001_black.svg',
  },
  Açailândia: {
    name: 'Açailândia',
    logo: '/assets/img/logos/bus/acailandia.svg',
    logoBlack: '/assets/img/logos/bus/acailandia_black.svg',
  },
  'Águia Branca': {
    name: 'Águia Branca',
    logo: '/assets/img/logos/bus/aguiabranca.svg',
    logoBlack: '/assets/img/logos/bus/aguiabranca_black.svg',
  },
  Aguiar: {
    name: 'Aguiar',
    logo: '/assets/img/logos/bus/aguiar.svg',
    logoBlack: '/assets/img/logos/bus/aguiar_black.svg',
  },
  'Alfa Luz': {
    name: 'Alfa Luz',
    logo: '/assets/img/logos/bus/alfaluz.svg',
    logoBlack: '/assets/img/logos/bus/alfaluz_black.svg',
  },
  Amarelinho: {
    name: 'Amarelinho',
    logo: '/assets/img/logos/bus/amarelinho.svg',
    logoBlack: '/assets/img/logos/bus/amarelinho_black.svg',
  },
  Andorinha: {
    name: 'Andorinha',
    logo: '/assets/img/logos/bus/andorinha.svg',
    logoBlack: '/assets/img/logos/bus/andorinha_black.svg',
  },
  Araguarina: {
    name: 'Araguarina',
    logo: '/assets/img/logos/bus/araguarina.svg',
    logoBlack: '/assets/img/logos/bus/araguarina_black.svg',
  },
  'Áries Transportes': {
    name: 'Áries Transportes',
    logo: '/assets/img/logos/bus/aries.svg',
    logoBlack: '/assets/img/logos/bus/aries_black.svg',
  },
  Asatur: {
    name: 'Asatur',
    logo: '/assets/img/logos/bus/asatur.svg',
    logoBlack: '/assets/img/logos/bus/asatur_black.svg',
  },
  Atual: {
    name: 'Atual',
    logo: '/assets/img/logos/bus/atual.svg',
    logoBlack: '/assets/img/logos/bus/atual_black.svg',
  },
  'Boa Esperança': {
    name: 'Boa Esperança',
    logo: '/assets/img/logos/bus/boaesperanca.svg',
    logoBlack: '/assets/img/logos/bus/boaesperanca_black.svg',
  },
  Bragança: {
    name: 'Bragança',
    logo: '/assets/img/logos/bus/braganca.svg',
    logoBlack: '/assets/img/logos/bus/braganca_black.svg',
  },
  'Brasil Sul': {
    name: 'Brasil Sul',
    logo: '/assets/img/logos/bus/brasilsul.svg',
    logoBlack: '/assets/img/logos/bus/brasilsul_black.svg',
  },
  Brasileiro: {
    name: 'Brasileiro',
    logo: '/assets/img/logos/bus/brasileiro.svg',
    logoBlack: '/assets/img/logos/bus/brasileiro_black.svg',
  },
  'Breda Transportes': {
    name: 'Breda Transportes',
    logo: '/assets/img/logos/bus/breda.svg',
    logoBlack: '/assets/img/logos/bus/breda_black.svg',
  },
  Brisa: {
    name: 'Brisa',
    logo: '/assets/img/logos/bus/brisa.svg',
    logoBlack: '/assets/img/logos/bus/brisa_black.svg',
  },
  Camurujipe: {
    name: 'Camurujipe',
    logo: '/assets/img/logos/bus/camurujipe.svg',
    logoBlack: '/assets/img/logos/bus/camurujipe_black.svg',
  },
  Cantelle: {
    name: 'Cantelle',
    logo: '/assets/img/logos/bus/cantelle.svg',
    logoBlack: '/assets/img/logos/bus/cantelle_black.svg',
  },
  Catarinense: {
    name: 'Catarinense',
    logo: '/assets/img/logos/bus/catarinense.svg',
    logoBlack: '/assets/img/logos/bus/catarinense_black.svg',
  },
  'Cidade do Aço': {
    name: 'Cidade do Aço',
    logo: '/assets/img/logos/bus/cidadedoaco.svg',
    logoBlack: '/assets/img/logos/bus/cidadedoaco_black.svg',
  },
  'Cidade Sol': {
    name: 'Cidade Sol',
    logo: '/assets/img/logos/bus/cidadesol.svg',
    logoBlack: '/assets/img/logos/bus/cidadesol_black.svg',
  },
  Citral: {
    name: 'Citral',
    logo: '/assets/img/logos/bus/citral.svg',
    logoBlack: '/assets/img/logos/bus/citral_black.svg',
  },
  ClickBusX: {
    name: 'ClickBusX',
    logo: '/assets/img/logos/bus/clickbusx.svg',
    logoBlack: '/assets/img/logos/bus/clickbusx.svg',
  },
  Cometa: {
    name: 'Cometa',
    logo: '/assets/img/logos/bus/cometa.svg',
    logoBlack: '/assets/img/logos/bus/cometa_black.svg',
  },
  Continental: {
    name: 'Continental',
    logo: '/assets/img/logos/bus/continental.svg',
    logoBlack: '/assets/img/logos/bus/continental_black.svg',
  },
  'Costa Verde': {
    name: 'Costa Verde',
    logo: '/assets/img/logos/bus/costaverde.svg',
    logoBlack: '/assets/img/logos/bus/costaverde_black.svg',
  },
  Cruzeiro: {
    name: 'Cruzeiro',
    logo: '/assets/img/logos/bus/cruzeiro.svg',
    logoBlack: '/assets/img/logos/bus/cruzeiro_black.svg',
  },
  'Cruzeiro do Sul': {
    name: 'Cruzeiro do Sul',
    logo: '/assets/img/logos/bus/cruzeirodosul.svg',
    logoBlack: '/assets/img/logos/bus/cruzeirodosul_black.svg',
  },
  'Danúbio Azul': {
    name: 'Danúbio Azul',
    logo: '/assets/img/logos/bus/danubioazul.svg',
    logoBlack: '/assets/img/logos/bus/danubioazul_black.svg',
  },
  Eldorado: {
    name: 'Eldorado',
    logo: '/assets/img/logos/bus/eldorado.svg',
    logoBlack: '/assets/img/logos/bus/eldorado_black.svg',
  },
  'Empresa Cruz': {
    name: 'Empresa Cruz',
    logo: '/assets/img/logos/bus/empresacruz.svg',
    logoBlack: '/assets/img/logos/bus/empresacruz_black.svg',
  },
  Emtram: {
    name: 'Emtram',
    logo: '/assets/img/logos/bus/emtram.svg',
    logoBlack: '/assets/img/logos/bus/emtram_black.svg',
  },
  Estrela: {
    name: 'Estrela',
    logo: '/assets/img/logos/bus/estrela.svg',
    logoBlack: '/assets/img/logos/bus/estrela_black.svg',
  },
  Eucatur: {
    name: 'Eucatur',
    logo: '/assets/img/logos/bus/eucatur.svg',
    logoBlack: '/assets/img/logos/bus/eucatur_black.svg',
  },
  'Evolução Transportes': {
    name: 'Evolução Transportes',
    logo: '/assets/img/logos/bus/evolucao.svg',
    logoBlack: '/assets/img/logos/bus/evolucao_black.svg',
  },
  'Expresso Adamantina': {
    name: 'Expresso Adamantina',
    logo: '/assets/img/logos/bus/adamantina.svg',
    logoBlack: '/assets/img/logos/bus/adamantina_black.svg',
  },
  'Expresso Araguari': {
    name: 'Expresso Araguari',
    logo: '/assets/img/logos/bus/araguari.svg',
    logoBlack: '/assets/img/logos/bus/araguari_black.svg',
  },
  'Expresso de Prata': {
    name: 'Expresso de Prata',
    logo: '/assets/img/logos/bus/prata.svg',
    logoBlack: '/assets/img/logos/bus/prata_black.svg',
  },
  'Expresso De Prata': {
    name: 'Expresso de Prata',
    logo: '/assets/img/logos/bus/prata.svg',
    logoBlack: '/assets/img/logos/bus/prata_black.svg',
  },
  'Expresso do Sul': {
    name: 'Expresso do Sul',
    logo: '/assets/img/logos/bus/expressodosul.svg',
    logoBlack: '/assets/img/logos/bus/expressodosul_black.svg',
  },
  'Expresso Do Sul': {
    name: 'Expresso do Sul',
    logo: '/assets/img/logos/bus/expressodosul.svg',
    logoBlack: '/assets/img/logos/bus/expressodosul_black.svg',
  },
  'Expresso Guanabara': {
    name: 'Expresso Guanabara',
    logo: '/assets/img/logos/bus/guanabara.svg',
    logoBlack: '/assets/img/logos/bus/guanabara_black.svg',
  },
  'Expresso Itamarati': {
    name: 'Expresso Itamarati',
    logo: '/assets/img/logos/bus/itamarati.svg',
    logoBlack: '/assets/img/logos/bus/itamarati_black.svg',
  },
  'Expresso Jóia': {
    name: 'Expresso Jóia',
    logo: '/assets/img/logos/bus/expressojoia.svg',
    logoBlack: '/assets/img/logos/bus/expressojoia_black.svg',
  },
  'Expresso Kaiowa': {
    name: 'Expresso Kaiowa',
    logo: '/assets/img/logos/bus/kaiowa.svg',
    logoBlack: '/assets/img/logos/bus/kaiowa_black.svg',
  },
  'Expresso Luxo': {
    name: 'Expresso Luxo',
    logo: '/assets/img/logos/bus/expressoluxo.svg',
    logoBlack: '/assets/img/logos/bus/expressoluxo_black.svg',
  },
  'Expresso Maia': {
    name: 'Expresso Maia',
    logo: '/assets/img/logos/bus/maia.svg',
    logoBlack: '/assets/img/logos/bus/maia_black.svg',
  },
  'Expresso Maringá': {
    name: 'Expresso Maringá',
    logo: '/assets/img/logos/bus/maringa.svg',
    logoBlack: '/assets/img/logos/bus/maringa_black.svg',
  },
  'Expresso São José': {
    name: 'Expresso São José',
    logo: '/assets/img/logos/bus/saojose.svg',
    logoBlack: '/assets/img/logos/bus/saojose_black.svg',
  },
  'Expresso Setelagoano': {
    name: 'Expresso Setelagoano',
    logo: '/assets/img/logos/bus/setelagoano.svg',
    logoBlack: '/assets/img/logos/bus/setelagoano_black.svg',
  },
  'Expresso União': {
    name: 'Expresso União',
    logo: '/assets/img/logos/bus/expressouniao.svg',
    logoBlack: '/assets/img/logos/bus/expressouniao_black.svg',
  },
  Fretcar: {
    name: 'Fretcar',
    logo: '/assets/img/logos/bus/fretcar.svg',
    logoBlack: '/assets/img/logos/bus/fretcar_black.svg',
  },
  Garcia: {
    name: 'Garcia',
    logo: '/assets/img/logos/bus/viacaogarcia.svg',
    logoBlack: '/assets/img/logos/bus/viacaogarcia_black.svg',
  },
  Gardenia: {
    name: 'Gardenia',
    logo: '/assets/img/logos/bus/gardenia.svg',
    logoBlack: '/assets/img/logos/bus/gardenia_black.svg',
  },
  Goiânia: {
    name: 'Goiânia',
    logo: '/assets/img/logos/bus/goiania.svg',
    logoBlack: '/assets/img/logos/bus/goiania_black.svg',
  },
  Gontijo: {
    name: 'Gontijo',
    logo: '/assets/img/logos/bus/gontijo.svg',
    logoBlack: '/assets/img/logos/bus/gontijo_black.svg',
  },
  Graciosa: {
    name: 'Graciosa',
    logo: '/assets/img/logos/bus/graciosa.svg',
    logoBlack: '/assets/img/logos/bus/graciosa_black.svg',
  },
  'Guerino Seiscento': {
    name: 'Guerino Seiscento',
    logo: '/assets/img/logos/bus/guerinoseiscento.svg',
    logoBlack: '/assets/img/logos/bus/guerinoseiscento_black.svg',
  },
  Helios: {
    name: 'Helios',
    logo: '/assets/img/logos/bus/helios.svg',
    logoBlack: '/assets/img/logos/bus/helios_black.svg',
  },
  'Ingá Tur': {
    name: 'Ingá Tur',
    logo: '/assets/img/logos/bus/ingatur.svg',
    logoBlack: '/assets/img/logos/bus/ingatur_black.svg',
  },
  'Ipe Transporte': {
    name: 'Ipe Transporte',
    logo: '/assets/img/logos/bus/ipe.svg',
    logoBlack: '/assets/img/logos/bus/ipe_black.svg',
  },
  Itapemirim: {
    name: 'Itapemirim',
    logo: '/assets/img/logos/bus/itapemirim.svg',
    logoBlack: '/assets/img/logos/bus/itapemirim_black.svg',
  },
  'J Araujo': {
    name: 'J Araujo',
    logo: '/assets/img/logos/bus/j-araujo.svg',
    logoBlack: '/assets/img/logos/bus/j-araujo_black.svg',
  },
  Jaó: {
    name: 'Jaó',
    logo: '/assets/img/logos/bus/jao.svg',
    logoBlack: '/assets/img/logos/bus/jao_black.svg',
  },
  'JBL Turismo': {
    name: 'JBL Turismo',
    logo: '/assets/img/logos/bus/jbl.svg',
    logoBlack: '/assets/img/logos/bus/jbl_black.svg',
  },
  Kaissara: {
    name: 'Kaissara',
    logo: '/assets/img/logos/bus/kaissara.svg',
    logoBlack: '/assets/img/logos/bus/kaissara_black.svg',
  },
  'Lira Bus': {
    name: 'Lira Bus',
    logo: '/assets/img/logos/bus/lirabus.svg',
    logoBlack: '/assets/img/logos/bus/lirabus_black.svg',
  },
  Litorânea: {
    name: 'Litorânea',
    logo: '/assets/img/logos/bus/litoranea.svg',
    logoBlack: '/assets/img/logos/bus/litoranea_black.svg',
  },
  'Lopes Sul': {
    name: 'Lopes Sul',
    logo: '/assets/img/logos/bus/lopesul.svg',
    logoBlack: '/assets/img/logos/bus/lopesul_black.svg',
  },
  Lopestur: {
    name: 'Lopestur',
    logo: '/assets/img/logos/bus/lopestur.svg',
    logoBlack: '/assets/img/logos/bus/lopestur_black.svg',
  },
  Macaense: {
    name: 'Macaense',
    logo: '/assets/img/logos/bus/macaense.svg',
    logoBlack: '/assets/img/logos/bus/macaense_black.svg',
  },
  'Manoel Rodrigues': {
    name: 'Manoel Rodrigues',
    logo: '/assets/img/logos/bus/manoelrodrigues.svg',
    logoBlack: '/assets/img/logos/bus/manoelrodrigues_black.svg',
  },
  'Mingoti Tur': {
    name: 'Mingoti Tur',
    logo: '/assets/img/logos/bus/mingoti.svg',
    logoBlack: '/assets/img/logos/bus/mingoti_black.svg',
  },
  Motta: {
    name: 'Motta',
    logo: '/assets/img/logos/bus/motta.svg',
    logoBlack: '/assets/img/logos/bus/motta_black.svg',
  },
  Nacional: {
    name: 'Nacional',
    logo: '/assets/img/logos/bus/nacional.svg',
    logoBlack: '/assets/img/logos/bus/nacional_black.svg',
  },
  'Nacional Expresso': {
    name: 'Nacional Expresso',
    logo: '/assets/img/logos/bus/nacionalexpresso.svg',
    logoBlack: '/assets/img/logos/bus/nacionalexpresso_black.svg',
  },
  Nasser: {
    name: 'Nasser',
    logo: '/assets/img/logos/bus/nasser.svg',
    logoBlack: '/assets/img/logos/bus/nasser_black.svg',
  },
  Nobre: {
    name: 'Nobre',
    logo: '/assets/img/logos/bus/nobre.svg',
    logoBlack: '/assets/img/logos/bus/nobre_black.svg',
  },
  'Nova Integração': {
    name: 'Nova Integração',
    logo: '/assets/img/logos/bus/novaintegracao.svg',
    logoBlack: '/assets/img/logos/bus/novaintegracao_black.svg',
  },
  Orion: {
    name: 'Orion',
    logo: '/assets/img/logos/bus/orion.svg',
    logoBlack: '/assets/img/logos/bus/orion_black.svg',
  },
  'Ouro e Prata': {
    name: 'Ouro e Prata',
    logo: '/assets/img/logos/bus/ouroprata.svg',
    logoBlack: '/assets/img/logos/bus/ouroprata_black.svg',
  },
  'Ouro Verde': {
    name: 'Ouro Verde',
    logo: '/assets/img/logos/bus/ouroverde.svg',
    logoBlack: '/assets/img/logos/bus/ouroverde_black.svg',
  },
  Paraibuna: {
    name: 'Paraibuna',
    logo: '/assets/img/logos/bus/paraibuna.svg',
    logoBlack: '/assets/img/logos/bus/paraibuna_black.svg',
  },
  'Pássaro Marron': {
    name: 'Pássaro Marron',
    logo: '/assets/img/logos/bus/passaromarron.svg',
    logoBlack: '/assets/img/logos/bus/passaromarron_black.svg',
  },
  'Pássaro Verde': {
    name: 'Pássaro Verde',
    logo: '/assets/img/logos/bus/passaroverde.svg',
    logoBlack: '/assets/img/logos/bus/passaroverde_black.svg',
  },
  Penha: {
    name: 'Penha',
    logo: '/assets/img/logos/bus/penha.svg',
    logoBlack: '/assets/img/logos/bus/penha_black.svg',
  },
  Petroacre: {
    name: 'Petroacre',
    logo: '/assets/img/logos/bus/petroacre.svg',
    logoBlack: '/assets/img/logos/bus/petroacre_black.svg',
  },
  Piracicabana: {
    name: 'Piracicabana',
    logo: '/assets/img/logos/bus/piracicabana.svg',
    logoBlack: '/assets/img/logos/bus/piracicabana_black.svg',
  },
  Planalto: {
    name: 'Planalto',
    logo: '/assets/img/logos/bus/planalto.svg',
    logoBlack: '/assets/img/logos/bus/planalto_black.svg',
  },
  Planeta: {
    name: 'Planeta',
    logo: '/assets/img/logos/bus/planeta.svg',
    logoBlack: '/assets/img/logos/bus/planeta_black.svg',
  },
  Pluma: {
    name: 'Pluma',
    logo: '/assets/img/logos/bus/pluma.svg',
    logoBlack: '/assets/img/logos/bus/pluma_black.svg',
  },
  'Princesa do Ivai': {
    name: 'Princesa do Ivai',
    logo: '/assets/img/logos/bus/princesaivai.svg',
    logoBlack: '/assets/img/logos/bus/princesaivai_black.svg',
  },
  'Princesa do Norte': {
    name: 'Princesa do Norte',
    logo: '/assets/img/logos/bus/princesanorte.svg',
    logoBlack: '/assets/img/logos/bus/princesanorte_black.svg',
  },
  'Princesa dos Campos': {
    name: 'Princesa dos Campos',
    logo: '/assets/img/logos/bus/princesadoscampos.svg',
    logoBlack: '/assets/img/logos/bus/princesadoscampos_black.svg',
  },
  'Princesa dos Inhamuns': {
    name: 'Princesa dos Inhamuns',
    logo: '/assets/img/logos/bus/princesa.svg',
    logoBlack: '/assets/img/logos/bus/princesa_black.svg',
  },
  'Princesa Morena': {
    name: 'Princesa Morena',
    logo: '/assets/img/logos/bus/princesamorena.svg',
    logoBlack: '/assets/img/logos/bus/princesamorena_black.svg',
  },
  Progresso: {
    name: 'Progresso',
    logo: '/assets/img/logos/bus/progresso.svg',
    logoBlack: '/assets/img/logos/bus/progresso_black.svg',
  },
  'Rápido Brasil': {
    name: 'Rápido Brasil',
    logo: '/assets/img/logos/bus/rapidobrasil.svg',
    logoBlack: '/assets/img/logos/bus/rapidobrasil_black.svg',
  },
  'Rápido Campinas': {
    name: 'Rápido Campinas',
    logo: '/assets/img/logos/bus/rapidocampinas.svg',
    logoBlack: '/assets/img/logos/bus/rapidocampinas_black.svg',
  },
  'Rapido Federal': {
    name: 'Rápido Federal',
    logo: '/assets/img/logos/bus/rapidofederal.svg',
    logoBlack: '/assets/img/logos/bus/rapidofederal_black.svg',
  },
  'Rápido Marajó': {
    name: 'Rápido Marajó',
    logo: '/assets/img/logos/bus/rapidomarajo.svg',
    logoBlack: '/assets/img/logos/bus/rapidomarajo_black.svg',
  },
  'Rápido Ribeirão': {
    name: 'Rápido Ribeirão',
    logo: '/assets/img/logos/bus/rapidoribeirao.svg',
    logoBlack: '/assets/img/logos/bus/rapidoribeirao_black.svg',
  },
  'Rápido Sumaré': {
    name: 'Rápido Sumaré',
    logo: '/assets/img/logos/bus/rapidosumare.svg',
    logoBlack: '/assets/img/logos/bus/rapidosumare_black.svg',
  },
  'RD Transportes': {
    name: 'RD Transportes',
    logo: '/assets/img/logos/bus/rd.svg',
    logoBlack: '/assets/img/logos/bus/rd_black.svg',
  },
  'Real Alagoas': {
    name: 'Real Alagoas',
    logo: '/assets/img/logos/bus/realalagoas.svg',
    logoBlack: '/assets/img/logos/bus/realalagoas_black.svg',
  },
  'Real Expresso': {
    name: 'Real Expresso',
    logo: '/assets/img/logos/bus/realexpresso.svg',
    logoBlack: '/assets/img/logos/bus/realexpresso_black.svg',
  },
  'Real Transportes': {
    name: 'Real Transportes',
    logo: '/assets/img/logos/bus/real.svg',
    logoBlack: '/assets/img/logos/bus/real_black.svg',
  },
  Reunidas: {
    name: 'Reunidas',
    logo: '/assets/img/logos/bus/reunidas.svg',
    logoBlack: '/assets/img/logos/bus/reunidas_black.svg',
  },
  'Reunidas Paulista': {
    name: 'Reunidas Paulista',
    logo: '/assets/img/logos/bus/reunidaspaulista.svg',
    logoBlack: '/assets/img/logos/bus/reunidaspaulista_black.svg',
  },
  Riodoce: {
    name: 'Riodoce',
    logo: '/assets/img/logos/bus/riodoce.svg',
    logoBlack: '/assets/img/logos/bus/riodoce_black.svg',
  },
  'Rio doce': {
    name: 'Riodoce',
    logo: '/assets/img/logos/bus/riodoce.svg',
    logoBlack: '/assets/img/logos/bus/riodoce_black.svg',
  },
  'Rio Novo': {
    name: 'Rio Novo',
    logo: '/assets/img/logos/bus/rionovo.svg',
    logoBlack: '/assets/img/logos/bus/rionovo_black.svg',
  },
  Roderotas: {
    name: 'Roderotas',
    logo: '/assets/img/logos/bus/roderotas.svg',
    logoBlack: '/assets/img/logos/bus/roderotas_black.svg',
  },
  'Rota Transportes': {
    name: 'Rota Transportes',
    logo: '/assets/img/logos/bus/rotatransportes.svg',
    logoBlack: '/assets/img/logos/bus/rotatransportes_black.svg',
  },
  'Rotas do Triângulo': {
    name: 'Rotas do Triângulo',
    logo: '/assets/img/logos/bus/rotas.svg',
    logoBlack: '/assets/img/logos/bus/rotas_black.svg',
  },
  Salutaris: {
    name: 'Salutaris',
    logo: '/assets/img/logos/bus/salutaris.svg',
    logoBlack: '/assets/img/logos/bus/salutaris_black.svg',
  },
  Sampaio: {
    name: 'Sampaio',
    logo: '/assets/img/logos/bus/sampaio.svg',
    logoBlack: '/assets/img/logos/bus/sampaio_black.svg',
  },
  'Viação Sampaio': {
    name: 'Viação Sampaio',
    logo: '/assets/img/logos/bus/sampaio.svg',
    logoBlack: '/assets/img/logos/bus/sampaio_black.svg',
  },
  Sandra: {
    name: 'Sandra',
    logo: '/assets/img/logos/bus/sandra.svg',
    logoBlack: '/assets/img/logos/bus/sandra_black.svg',
  },
  'Santa Cruz': {
    name: 'Santa Cruz',
    logo: '/assets/img/logos/bus/santacruz.svg',
    logoBlack: '/assets/img/logos/bus/santacruz_black.svg',
  },
  'Santa Maria': {
    name: 'Santa Maria',
    logo: '/assets/img/logos/bus/santamaria.svg',
    logoBlack: '/assets/img/logos/bus/santamaria_black.svg',
  },
  Santafé: {
    name: 'Santafé',
    logo: '/assets/img/logos/bus/santafe.svg',
    logoBlack: '/assets/img/logos/bus/santafe_black.svg',
  },
  Santana: {
    name: 'Santana',
    logo: '/assets/img/logos/bus/santana.svg',
    logoBlack: '/assets/img/logos/bus/santana_black.svg',
  },
  'Santo Anjo': {
    name: 'Santo Anjo',
    logo: '/assets/img/logos/bus/santoanjo.svg',
    logoBlack: '/assets/img/logos/bus/santoanjo_black.svg',
  },
  'São Bento': {
    name: 'São Bento',
    logo: '/assets/img/logos/bus/saobento.svg',
    logoBlack: '/assets/img/logos/bus/saobento_black.svg',
  },
  'São Cristovão': {
    name: 'São Cristovão',
    logo: '/assets/img/logos/bus/saocristovao.svg',
    logoBlack: '/assets/img/logos/bus/saocristovao_black.svg',
  },
  'São Paulo São Pedro': {
    name: 'São Paulo São Pedro',
    logo: '/assets/img/logos/bus/saopaulo.svg',
    logoBlack: '/assets/img/logos/bus/saopaulo_black.svg',
  },
  Saritur: {
    name: 'Saritur',
    logo: '/assets/img/logos/bus/saritur.svg',
    logoBlack: '/assets/img/logos/bus/saritur_black.svg',
  },
  'Satélite Norte': {
    name: 'Satélite Norte',
    logo: '/assets/img/logos/bus/satelitenorte.svg',
    logoBlack: '/assets/img/logos/bus/satelitenorte_black.svg',
  },
  'Serra Azul': {
    name: 'Serra Azul',
    logo: '/assets/img/logos/bus/serraazul.svg',
    logoBlack: '/assets/img/logos/bus/serraazul_black.svg',
  },
  Serro: {
    name: 'Serro',
    logo: '/assets/img/logos/bus/serro.svg',
    logoBlack: '/assets/img/logos/bus/serro_black.svg',
  },
  Sertaneja: {
    name: 'Sertaneja',
    logo: '/assets/img/logos/bus/sertaneja.svg',
    logoBlack: '/assets/img/logos/bus/sertaneja_black.svg',
  },
  'Sol Nascente': {
    name: 'Sol Nascente',
    logo: '/assets/img/logos/bus/solnascente.svg',
    logoBlack: '/assets/img/logos/bus/solnascente_black.svg',
  },
  'Solimões Transportes': {
    name: 'Solimões Transportes',
    logo: '/assets/img/logos/bus/solimoes.svg',
    logoBlack: '/assets/img/logos/bus/solimoes_black.svg',
  },
  Suassuí: {
    name: 'Suassuí',
    logo: '/assets/img/logos/bus/suassui.svg',
    logoBlack: '/assets/img/logos/bus/suassui_black.svg',
  },
  'Sudoeste Transportes': {
    name: 'Sudoeste Transportes',
    logo: '/assets/img/logos/bus/sudoeste.svg',
    logoBlack: '/assets/img/logos/bus/sudoeste_black.svg',
  },
  Teresópolis: {
    name: 'Teresópolis',
    logo: '/assets/img/logos/bus/teresopolis.svg',
    logoBlack: '/assets/img/logos/bus/teresopolis_black.svg',
  },
  Total: {
    name: 'Total',
    logo: '/assets/img/logos/bus/total.svg',
    logoBlack: '/assets/img/logos/bus/total_black.svg',
  },
  Transacreana: {
    name: 'Transacreana',
    logo: '/assets/img/logos/bus/transacreana.svg',
    logoBlack: '/assets/img/logos/bus/transacreana_black.svg',
  },
  Transbrasiliana: {
    name: 'Transbrasiliana',
    logo: '/assets/img/logos/bus/transbrasiliana.svg',
    logoBlack: '/assets/img/logos/bus/transbrasiliana_black.svg',
  },
  Transnorte: {
    name: 'Transnorte',
    logo: '/assets/img/logos/bus/transnorte.svg',
    logoBlack: '/assets/img/logos/bus/transnorte_black.svg',
  },
  Transpen: {
    name: 'Transpen',
    logo: '/assets/img/logos/bus/transpen.svg',
    logoBlack: '/assets/img/logos/bus/transpen_black.svg',
  },
  TTL: {
    name: 'TTL',
    logo: '/assets/img/logos/bus/ttl.svg',
    logoBlack: '/assets/img/logos/bus/ttl_black.svg',
  },
  Ultra: {
    name: 'Ultra',
    logo: '/assets/img/logos/bus/ultra.svg',
    logoBlack: '/assets/img/logos/bus/ultra_black.svg',
  },
  Umuarama: {
    name: 'Umuarama',
    logo: '/assets/img/logos/bus/umuarama.svg',
    logoBlack: '/assets/img/logos/bus/umuarama_black.svg',
  },
  Unesul: {
    name: 'Unesul',
    logo: '/assets/img/logos/bus/unesul.svg',
    logoBlack: '/assets/img/logos/bus/unesul_black.svg',
  },
  União: {
    name: 'União',
    logo: '/assets/img/logos/bus/uniao.svg',
    logoBlack: '/assets/img/logos/bus/uniao_black.svg',
  },
  'União Santa Cruz': {
    name: 'União Santa Cruz',
    logo: '/assets/img/logos/bus/uniaosantacruz.svg',
    logoBlack: '/assets/img/logos/bus/uniaosantacruz_black.svg',
  },
  'Unida Mansur': {
    name: 'Unida Mansur',
    logo: '/assets/img/logos/bus/unida.svg',
    logoBlack: '/assets/img/logos/bus/unida_black.svg',
  },
  UTIL: {
    name: 'UTIL',
    logo: '/assets/img/logos/bus/util.svg',
    logoBlack: '/assets/img/logos/bus/util_black.svg',
  },
  Vallesul: {
    name: 'Vallesul',
    logo: '/assets/img/logos/bus/valle.svg',
    logoBlack: '/assets/img/logos/bus/valle_black.svg',
  },
  'Valtur Turismo': {
    name: 'Valtur Turismo',
    logo: '/assets/img/logos/bus/valtur.svg',
    logoBlack: '/assets/img/logos/bus/valtur_black.svg',
  },
  Vat: {
    name: 'Vat',
    logo: '/assets/img/logos/bus/vat.svg',
    logoBlack: '/assets/img/logos/bus/vat_black.svg',
  },
  'VB Transportes': {
    name: 'VB Transportes',
    logo: '/assets/img/logos/bus/vb.svg',
    logoBlack: '/assets/img/logos/bus/vb_black.svg',
  },
  'Verde Transportes': {
    name: 'Verde Transportes',
    logo: '/assets/img/logos/bus/verde.svg',
    logoBlack: '/assets/img/logos/bus/verde_black.svg',
  },
  Viasul: {
    name: 'Viasul',
    logo: '/assets/img/logos/bus/viasul.svg',
    logoBlack: '/assets/img/logos/bus/viasul_black.svg',
  },
  Xavante: {
    name: 'Xavante',
    logo: '/assets/img/logos/bus/xavante.svg',
    logoBlack: '/assets/img/logos/bus/xavante_black.svg',
  },
};
