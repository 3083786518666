import React, { useEffect, useMemo, useState } from 'react';
import { OrderTypes } from '../../constants/orderTypes';
import {
  getBusDuplicity,
  getCarDuplicity,
  getFlightDuplicity,
  getHotelDuplicity,
} from '../../utils/reservationDuplicities';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import firebaseApp from '../../services/Firebase';
import { getDocumentsFromCollection } from '../../utils/firebase';
import { AllAlternativeCards } from '../../constants/alternativeCards';
import moment from 'moment';
import classNames from 'classnames';

const DUPLICITY_FUNCTIONS = {
  [OrderTypes.FLIGHT]: getFlightDuplicity,
  [OrderTypes.HOTEL]: getHotelDuplicity,
  [OrderTypes.CAR]: getCarDuplicity,
  [OrderTypes.BUS]: getBusDuplicity,
};

const formatFlightOrder = (order) => {
  const { createdAt, fare, id, type, journeys, reservations, provider } = order;
  const { airline, validatingAirline } = reservations[0].flightDetails;

  const sortedReservations = reservations.reduce((obj, reservation) => {
    const {
      journeyId,
      departureDateTime,
      arrivalDateTime,
      departureStation,
      arrivalStation,
      ...props
    } = reservation.flightDetails;

    const formattedReservation = {
      ...props,
      journeyId,
      departureDateTime: moment(departureDateTime, 'DD/MM/YYYY HH:mm').format(),
      arrivalDateTime: moment(arrivalDateTime, 'DD/MM/YYYY HH:mm').format(),
      departureStation: departureStation.iataCode,
      arrivalStation: arrivalStation.iataCode,
    };

    if (journeyId in obj) obj[journeyId].push(formattedReservation);
    else obj[journeyId] = [formattedReservation];

    return obj;
  }, {});

  return {
    airline,
    created_at: createdAt,
    fare,
    id,
    journeys: journeys.map((journey, index) => {
      const reservations = sortedReservations[index].sort(
        (a, b) => a.flightIndex - b.flightIndex
      );
      const lastReservationIndex = reservations.length - 1;

      return {
        ...journey,
        id: journey.id + 1,
        arrivalStation: journey.arrivalStation.iataCode,
        departureStation: journey.departureStation.iataCode,
        segments: [
          {
            departureDateTime: reservations[0].departureDateTime,
            departureStation: reservations[0].departureStation,
            arrivalDateTime: reservations[lastReservationIndex].arrivalDateTime,
            arrivalStation: reservations[lastReservationIndex].arrivalStation,
            flights: reservations,
          },
        ],
      };
    }),
    origin: provider.origin,
    source: provider.source,
    type,
    validatingAirline,
  };
};

const formatHotelOrder = (order) => {
  const { createdAt, fare, id, provider, type, reservations } = order;
  const { hotelId, hotel, selectedRoom, cancellation } =
    reservations[0].hotelDetails;

  return {
    created_at: createdAt,
    fare,
    hotel,
    hotelId,
    id,
    origin: provider.origin,
    rooms: [
      {
        ...selectedRoom,
        cancellation,
      },
    ],
    token: provider.token,
    type,
  };
};

const formatCarOrder = (order) => {
  const { createdAt, fare, id, provider, type, reservations } = order;
  const { carDetails } = reservations[0];

  return {
    created_at: createdAt,
    fare,
    id,
    origin: provider.origin,
    token: provider.token,
    type,
    ...carDetails,
  };
};

const formatBusOrder = (order) => {
  const { id, createdAt, fare, type, provider, reservations, journeys } = order;
  const { carrier } = reservations[0].busDetails;

  const sortedReservations = reservations.reduce((obj, reservation) => {
    const {
      journeyId,
      departureDateTime,
      arrivalDateTime,
      departureStation,
      arrivalStation,
      ...props
    } = reservation.busDetails;

    const formattedReservation = {
      ...props,
      journeyId,
      id: journeyId + 1,
      departureDateTime: moment(departureDateTime, 'DD/MM/YYYY HH:mm').format(),
      arrivalDateTime: moment(arrivalDateTime, 'DD/MM/YYYY HH:mm').format(),
      departureStation,
      arrivalStation,
    };

    if (journeyId in obj) obj[journeyId].push(formattedReservation);
    else obj[journeyId] = [formattedReservation];

    return obj;
  }, {});

  return {
    carrier,
    created_at: createdAt,
    fare,
    id,
    journeys: journeys.map((journey, index) => {
      const reservations = sortedReservations[index].sort(
        (a, b) => a.legIndex - b.legIndex
      );
      const lastReservationIndex = reservations.length - 1;

      return {
        ...journey,
        id: journey.id + 1,
        arrivalCity: journey.arrivalStation.split(',')[0],
        arrivalStation: journey.arrivalStation,
        departureCity: journey.departureStation.split(',')[0],
        departureStation: journey.departureStation,
        segments: [
          {
            departureDateTime: reservations[0].departureDateTime,
            departureStation: reservations[0].departureStation,
            arrivalDateTime: reservations[lastReservationIndex].arrivalDateTime,
            arrivalStation: reservations[lastReservationIndex].arrivalStation,
            legs: reservations,
          },
        ],
      };
    }),
    origin: provider.origin,
    type,
  };
};

const FORMAT_FUNCTIONS = {
  [OrderTypes.FLIGHT]: formatFlightOrder,
  [OrderTypes.HOTEL]: formatHotelOrder,
  [OrderTypes.CAR]: formatCarOrder,
  [OrderTypes.BUS]: formatBusOrder,
};

export default function TravelDuplicityVerifier({
  order,
  reservations,
  className = '',
  withTitle = true,
  onDuplicity,
}) {
  const history = useHistory();
  const { messages } = useIntl();

  const [duplicity, setDuplicity] = useState(null);
  const { duplicityFunction, DuplicityCard, formatFunction } = useMemo(
    () => ({
      duplicityFunction: DUPLICITY_FUNCTIONS[order.type],
      DuplicityCard: AllAlternativeCards[order.type],
      formatFunction: FORMAT_FUNCTIONS[order.type],
    }),
    [order]
  );

  // Effects
  useEffect(() => {
    if (duplicityFunction) {
      const checkDuplicity = async () => {
        try {
          let duplicity = await duplicityFunction(order);

          if (duplicity) {
            const reservations = await getDocumentsFromCollection(() =>
              firebaseApp
                .getReservationsFromOrderId(
                  duplicity.organizationId,
                  duplicity.id
                )
                .get()
            );

            duplicity.reservations = reservations;

            if (formatFunction) duplicity = formatFunction(duplicity);
          }

          setDuplicity(duplicity);
        } catch (err) {
          console.error(err);
        }
      };

      checkDuplicity();
    }
  }, [order]);

  useEffect(() => {
    if (duplicity) onDuplicity(duplicity);
  }, [duplicity]);

  // Functions
  const goToReservation = () => {
    duplicity && history.push(`/travel/orders/${duplicity.id}`);
  };

  // Render
  const renderCard = () => (
    <div className={classNames('travel-duplicity', className)}>
      {withTitle ? <h5>{messages['travel.duplicity']}</h5> : null}
      <DuplicityCard
        alternative={duplicity}
        originalTotal={order.fare.total}
        order={order}
        index={0}
        reservations={reservations}
        onClick={goToReservation}
        badgeText={messages['order.alternative.duplicity']}
      />
    </div>
  );

  return duplicityFunction && duplicity ? renderCard() : null;
}
