import React from 'react';

import config from '../../constants/GoogleMapsBasic';
const GOOGLE_API_KEY = config.key;

export default function HotelCardTopHotels({ city }) {
  return (
    <div
      className="hotel-image"
      style={{
        backgroundImage: `url(https://maps.googleapis.com/maps/api/staticmap?center=${city.localization.lat},${city.localization.lng}&zoom=10&size=600x300&key=${GOOGLE_API_KEY}&map_id=e462135989faefa)`,
      }}
    />
  );
}
