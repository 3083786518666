exports.amenities = {
  'Internet de alta velocidade': 'internet.svg',
  'Sala de reuniões': 'meeting-rooms.svg',
  'Cozinha/copa': 'kitchen.svg',
  'Endereço comercial (para correspondência)': 'mail-address.svg',
  'Serviço de impressão': 'print.svg',
  'Aceita cartões de crédito/débito': 'credit-cards.svg',
  'Café grátis': 'coffee.svg',
  'Espaço para convivência': 'lounge.svg',
  'Armário privado': 'locker.svg',
  'Ar-condicionado': 'air-conditioning.svg',
  'Serviço de secretariado': 'secretary.svg',
  'Endereço fiscal': 'mail-address.svg',
  'Acessível para cadeirante': 'wheelchairs.svg',
  'Atendimento em inglês': 'multi-language.svg',
  'Estacionamento conveniado': 'free-parking.svg',
  'Eventos para membros': 'conference.svg',
  'Cadeiras ergonômicas': 'chair.svg',
  Bicicletário: 'bycicle.svg',
  'Telefone privado': 'phone.svg',
  'Atendimento 24 horas': '24hr.svg',
  'Internet redundante': 'high-speed-internet.svg',
  'Atendimento em espanhol': 'multi-language.svg',
  Biblioteca: 'books.svg',
  'Estacionamento privado': 'free-parking.svg',
  'Permite animais': 'pet.svg',
  'Aluga computadores': 'laptop.svg',
  'Cabine telefônica': 'phone-booth.svg',
  'Estrutura para crianças': 'kids-club.svg',
  'Standing Desks': 'study.svg',
  'Atendimento em Libras': 'sign-language.svg',
};
