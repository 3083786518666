import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { TravelParamsValidationFunctions } from '../utils/openBooking';

export default function useIsTravelParamsValid({ travelType, travelParams }) {
  const { messages } = useIntl();

  const validationFunction = useMemo(
    () => TravelParamsValidationFunctions[travelType],
    [travelType]
  );

  const [isValid, disabled, title] = useMemo(() => {
    const isValid = validationFunction
      ? validationFunction(travelParams)
      : false;

    const disabled = !isValid;
    const title = disabled ? messages['reservation.invalid-fields'] : '';

    return [isValid, disabled, title];
  }, [validationFunction, travelParams]);

  return {
    isValid,
    disabled,
    title,
  };
}
