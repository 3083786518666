export const NotificationType = {
  FARE_UPDATED: 'FARE_UPDATED',
  CREDIT_CARD_UNAUTHORIZED: 'CREDIT_CARD_UNAUTHORIZED',
  EMPTY_CARD: 'EMPTY_CARD',
  RESERVATION_FAILED: 'RESERVATION_FAILED',
  EXPIRED_RESERVATION: 'EXPIRED_RESERVATION',
  BUDGET_INTERSECTION: 'BUDGET_INTERSECTION',
  CANCELATION_ERROR: 'CANCELATION_ERROR',
  INVALID_DATE: 'INVALID_DATE',
  CODE_429: 'CODE_429',
  CANCELLATION_FAILED: 'CANCELLATION_FAILED',
  SEND_FOR_APPROVAL_FAILED: 'SEND_FOR_APPROVAL_FAILED',
  UNAVAILABLE_SEAT: 'UNAVAILABLE_SEAT',
  UNAVAILABLE_CHOOSEN_SEAT: 'UNAVAILABLE_CHOOSEN_SEAT',
  ITEM_UNAVAILABLE: 'ITEM_UNAVAILABLE',
  INTERNAL_ERROR: 'INTERNAL_ERROR',
  FREQUENT_FLYER_NUMBER: 'FREQUENT_FLYER_NUMBER',
  FORBIDDEN_ADMINISTRATIVE: 'FORBIDDEN_ADMINISTRATIVE',
  ADMINISTRATIVE_DUE_DATE: 'ADMINISTRATIVE_DUE_DATE',
  PAYMENT_FAILED: 'PAYMENT_FAILED',
  PAYMENT_REFUNDED: 'PAYMENT_REFUNDED',
  OPEN_BOOKING_REFUNDED: 'OPEN_BOOKING_REFUNDED',
  REPEATED_FLIGHT: 'REPEATED_FLIGHT',
};

export const NotificationStatus = {
  NEW: 'NEW',
  READ: 'READ',
};
