import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { Card, Row, Button } from 'reactstrap';
import { Colxx } from '../../components/common/CustomBootstrap';
import map from 'lodash/map';

import IntlMessages from '../../helpers/IntlMessages';

import firebaseApp from '../../services/Firebase';
import GroupModal from '../../components/policy/GroupModal';
import PolicyLoading from './PolicyLoading';
import { useIntl } from 'react-intl';
import { trackPropertiesSegment } from '../../utils/segment';
import EmptyResults from '../../components/common/EmptyResults';
import recordStatus from '../../constants/recordStatus';
import UsersList from '../../components/UsersList';

let loadGroupsEvent = () => {};

export default function GroupDataListView({ tabId = 'management' }) {
  const { resourceId } = useParams();
  const history = useHistory();
  const { user } = useSelector((state) => state.auth);
  const { messages } = useIntl();

  const [groups, setGroups] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const isEmpty = useMemo(() => !groups || groups.length === 0, [groups]);

  useEffect(() => {
    loadGroups();

    return () => {
      loadGroupsEvent();
    };
  }, []);

  // Load Users
  const loadGroups = async () => {
    loadGroupsEvent = firebaseApp
      .getGroupsFromOrganization(user.organizationId)
      .onSnapshot((groupsSnap) => {
        const groups = [];
        groupsSnap.forEach((groupSnap) => {
          groups.push({
            ...groupSnap.data(),
            id: groupSnap.id,
          });
        });

        setGroups(groups);
        setLoading(false);
      });
  };

  // Modal
  const openModal = async (groupId) => {
    trackPropertiesSegment('Group modal opened', { user });
    history.push(`/app/${tabId}/groups/${groupId}`);
  };

  const closeModal = () => {
    trackPropertiesSegment('Group modal closed', { user });
    history.push(`/app/${tabId}/groups`);
  };

  // Loading
  if (isLoading) {
    return <PolicyLoading />;
  }

  return (
    <div>
      {isEmpty ? (
        <div className="w-100 d-flex justify-content-center mt-5">
          <EmptyResults
            title={messages['admin.travels.empty.group.title']}
            description={messages['admin.travels.empty.group.description']}
            buttonText={messages['admin.travels.empty.group.btn']}
            className="w-70"
            onClick={() => openModal('new')}
          />
        </div>
      ) : (
        <>
          <Row>
            <Colxx xxs="12" className="mt-5">
              <Button
                color="primary"
                className="m-0 pretty-btn"
                onClick={() => {
                  openModal('new');
                }}
              >
                {messages['general.add']}
              </Button>
            </Colxx>
          </Row>
          <Row className="mt-4 mb-5">
            {map(groups, (group, index) => {
              return (
                <Colxx key={`group-${index}`} xxs="12" className="mb-3">
                  <Card
                    onClick={() => openModal(group.id)}
                    className="d-flex flex-row list cursor-pointer"
                  >
                    <div className="pl-2 d-flex flex-grow-1 mw-0">
                      <div className="card-body align-self-center d-flex flex-column flex-lg-row justify-content-between mw-0 align-items-lg-center">
                        <div className="w-40 w-sm-100">
                          <p className="list-item-heading mb-1 truncate">
                            {group.name}
                          </p>
                        </div>
                        <p className="mb-1 text-muted text-small w-15 w-sm-100"></p>
                        <div className="w-25 w-sm-100 d-flex justify-content-end align-items-center pr-2">
                          <UsersList users={group.users} />
                        </div>
                      </div>
                    </div>
                  </Card>
                </Colxx>
              );
            })}
          </Row>
        </>
      )}

      {resourceId && (
        <GroupModal
          toggleModal={closeModal}
          groupId={resourceId}
          tabId={tabId}
        />
      )}
    </div>
  );
}
