import React, { Fragment } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { Row, Nav, NavItem } from 'reactstrap';
import classnames from 'classnames';
import IntlMessages from '../../helpers/IntlMessages';
import { Colxx, Separator } from '../../components/common/CustomBootstrap';
import Breadcrumb from '../../containers/navs/Breadcrumb';

import LanguageView from '../../containers/others/LanguageView';
import ConnectorsView from '../../containers/others/ConnectorsView';

export default function Others(props) {
  const { tabId } = useParams();

  // Render
  return (
    <Fragment>
      <Row>
        <Colxx xxs="12">
          <Breadcrumb heading="menu.others" match={props.match} />
          <Separator className="mb-5" />
        </Colxx>
      </Row>
      <Row>
        <Colxx xxs="12" className="mb-4">
          <div>
            <Nav className="admin-tabs">
              <NavItem>
                <NavLink
                  to="/app/others/language"
                  className={classnames({
                    active: !tabId || tabId === 'language',
                    'nav-link': true,
                  })}
                >
                  <IntlMessages id="admin.others.language" />
                </NavLink>
              </NavItem>
              {/* <NavItem>
                <NavLink
                  to="/app/others/connectors"
                  className={classnames({
                    active: tabId === 'connectors',
                    'nav-link': true,
                  })}
                >
                  <IntlMessages id="admin.others.connectors" />
                </NavLink>
              </NavItem> */}
            </Nav>

            {!tabId || tabId === 'language' ? <LanguageView /> : null}
            {/* {tabId === 'connectors' ? <ConnectorsView /> : null} */}
          </div>
        </Colxx>
      </Row>
    </Fragment>
  );
}
