import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import {
  CustomInput,
  Row,
  Button,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { Colxx } from '../../components/common/CustomBootstrap';
import map from 'lodash/map';
import mouseTrap from 'mousetrap';

import IntlMessages from '../../helpers/IntlMessages';

import firebaseApp from '../../services/Firebase';

import { trackPropertiesSegment } from '../../utils/segment';
import recordStatus from '../../constants/recordStatus';
import FavoriteHotelModal from '../../components/travels/FavoriteHotelModal';
import EmptyResults from '../../components/common/EmptyResults';
import { useIntl } from 'react-intl';
import FavoriteHotelCard from '../../components/travels/FavoriteHotelCard';
import FavoritePlacesLoading from './FavoritePlacesLoading';

let favoriteHotelSnap = () => {};

export default function FavoriteHotelsDataListView() {
  const { resourceId } = useParams();
  const history = useHistory();
  const { user } = useSelector((state) => state.auth);
  const { messages } = useIntl();

  const [selectedItems, setSelectedItems] = useState([]);
  const [dropdownSplitOpen, setDropdownSplitOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [favoriteHotels, setFavoriteHotels] = useState([]);
  const [storedHotels, setStoredHotels] = useState({});

  // Ref
  const hotelLookupTableRef = useRef({});

  // Memo
  const isEmpty = useMemo(
    () => !favoriteHotels || favoriteHotels.length === 0,
    [favoriteHotels]
  );

  useEffect(() => {
    fetchFavoriteHotels();
  }, []);

  useEffect(() => {
    mouseTrap.bind(['ctrl+a', 'command+a'], () => {
      setSelectedItems(map(favoriteHotels, (x) => x.id));
      return false;
    });

    mouseTrap.bind(['ctrl+d', 'command+d'], () => {
      setSelectedItems([]);
      return false;
    });

    return () => {
      mouseTrap.unbind('ctrl+a');
      mouseTrap.unbind('command+a');
      mouseTrap.unbind('ctrl+d');
      mouseTrap.unbind('command+d');
    };
  }, [favoriteHotels]);

  // Firebase
  const fetchFavoriteHotels = () => {
    favoriteHotelSnap();

    favoriteHotelSnap = firebaseApp
      .getFavoriteHotelsFromOrganization(user.organizationId, false)
      .onSnapshot(async (snap) => {
        const activeHotelsPromises = [];
        const deletedHotels = [];
        const newStoredHotels = {};

        snap.forEach((doc) => {
          const hotelInfo = {
            ...doc.data(),
            id: doc.id,
          };

          newStoredHotels[hotelInfo.iterpec] = hotelInfo.id;

          if (hotelInfo.status !== recordStatus.ACTIVE)
            deletedHotels.push(hotelInfo);
          else {
            activeHotelsPromises.push(
              new Promise(async (res, rej) => {
                try {
                  const hotelData =
                    hotelLookupTableRef.current[hotelInfo.hotelId];
                  if (hotelData) {
                    res(hotelData);
                  } else {
                    const hotelSnap = await firebaseApp
                      .getHotelFromId(hotelInfo.hotelId)
                      .get();
                    res({
                      ...hotelInfo,
                      ...hotelSnap.data(),
                    });
                  }
                } catch (err) {
                  rej();
                }
              })
            );
          }
        });

        const activeHotels = await Promise.all(activeHotelsPromises);

        // Update lookup table
        const newHotelLookupTable = activeHotels.reduce((obj, hotel) => {
          obj[hotel.hotelId] = hotel;
          return obj;
        }, {});

        hotelLookupTableRef.current = {
          ...hotelLookupTableRef.current,
          ...newHotelLookupTable,
        };

        setFavoriteHotels(activeHotels);
        setStoredHotels({
          ...storedHotels,
          ...newStoredHotels,
        });
        setIsLoading(false);
      });
  };

  // Handle Places
  const sortHotels = (hotels) => {
    const storedHotels = favoriteHotels.reduce((obj, hotel) => {
      obj[hotel.hotelId] = hotel;
      return obj;
    }, {});
    const newHotels = [];

    hotels.forEach((hotel) => {
      if (hotel.id in storedHotels) delete storedHotels[hotel.id];
      else newHotels.push(hotel);
    });

    return [Object.values(storedHotels), newHotels];
  };

  const saveHotel = async (hotel) => {
    const hotelId = storedHotels[hotel.iterpec];

    if (hotelId) {
      return await firebaseApp.makeFavoriteHotelActive(
        user.organizationId,
        hotelId
      );
    } else {
      const doc = await firebaseApp.addFavoriteHotel(user.organizationId, {
        iterpec: hotel.iterpec,
        hotelId: hotel.hotelId || hotel.id,
      });

      setStoredHotels({
        ...storedHotels,
        [hotel.iterpec]: doc.id,
      });

      return doc;
    }
  };

  const deleteHotel = (hotel) => {
    const docId = storedHotels[hotel.iterpec];
    if (docId)
      return firebaseApp.deleteFavoriteHotel(user.organizationId, docId);
  };

  const deleteFavoriteHotels = async () => {
    for (let i = 0; i < selectedItems.length; i++) {
      await firebaseApp.deleteFavoriteHotel(
        user.organizationId,
        selectedItems[i]
      );
    }

    trackPropertiesSegment('Favorite hotel deleted', {
      favoriteHotels: selectedItems,
      user,
    });

    setSelectedItems([]);
  };

  // Split
  const toggleSplit = () => {
    setDropdownSplitOpen(!dropdownSplitOpen);
  };

  // Selection
  const onCheckItem = (id) => {
    if (selectedItems.includes(id)) {
      setSelectedItems(selectedItems.filter((x) => x !== id));
    } else {
      setSelectedItems([...selectedItems, id]);
    }
  };

  const handleChangeSelectAll = () => {
    if (selectedItems.length >= favoriteHotels.length) {
      setSelectedItems([]);
    } else {
      setSelectedItems(map(favoriteHotels, (x) => x.id));
    }
  };

  // Modal
  const openModal = (ruleId) => {
    // trackPropertiesSegment('Cost center modal opened', { user });
    history.push(`/app/travels/favorite-hotels/${ruleId}`);
  };

  const closeModal = () => {
    // trackPropertiesSegment('Cost center model closed', { user });
    history.push('/app/travels/favorite-hotels');
  };

  // Render
  if (isLoading) {
    return <FavoritePlacesLoading />;
  }

  return (
    <div>
      {isEmpty ? (
        <div className="w-100 d-flex justify-content-center mt-5">
          <EmptyResults
            title={messages['admin.travels.empty.favorite-hotels.title']}
            description={
              messages['admin.travels.empty.favorite-hotels.description']
            }
            buttonText={messages['admin.travels.empty.favorite-hotels.btn']}
            onClick={() => openModal('new')}
          />
        </div>
      ) : (
        <>
          <Row>
            <Colxx xxs="12" className="mt-5">
              <Button
                color="primary"
                className="m-0 pretty-btn"
                onClick={() => {
                  openModal('new');
                }}
              >
                {messages['general.add']}
              </Button>

              <ButtonDropdown
                isOpen={dropdownSplitOpen}
                toggle={toggleSplit}
                className="float-right"
              >
                <div className="btn btn-primary btn-lg pl-4 pr-0 check-button check-all">
                  <CustomInput
                    className="custom-checkbox mb-0 d-inline-block"
                    type="checkbox"
                    id="checkAll"
                    checked={selectedItems.length >= favoriteHotels.length}
                    onChange={handleChangeSelectAll}
                    label={
                      <span
                        className={`custom-control-label ${
                          selectedItems.length > 0 &&
                          selectedItems.length < favoriteHotels.length
                            ? 'indeterminate'
                            : ''
                        }`}
                      />
                    }
                  />
                </div>
                <DropdownToggle
                  caret
                  color="primary"
                  className="dropdown-toggle-split btn-lg"
                />
                <DropdownMenu right>
                  <DropdownItem onClick={deleteFavoriteHotels}>
                    <IntlMessages id="admin.policy.delete" />
                  </DropdownItem>
                </DropdownMenu>
              </ButtonDropdown>
            </Colxx>
          </Row>
          <Row className="mt-4 pb-5">
            {map(favoriteHotels, (favoritePlace, index) => {
              return (
                <FavoriteHotelCard
                  key={`favoritePlace-${index}`}
                  favoritePlace={favoritePlace}
                  selectedItems={selectedItems}
                  onCheckItem={onCheckItem}
                />
              );
            })}
          </Row>
        </>
      )}

      {resourceId && (
        <FavoriteHotelModal
          toggleModal={closeModal}
          saveHotel={saveHotel}
          deleteHotel={deleteHotel}
          selectedHotels={favoriteHotels}
        />
      )}
    </div>
  );
}
