import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

export default function ItemClipper({ items, startSize = 4, children }) {
  const [showAll, setShowAll] = useState(items.length <= startSize);
  const clipItems = useMemo(
    () => (showAll ? items : items.slice(0, startSize)),
    [items, showAll]
  );
  const { messages } = useIntl();

  return (
    <div className="item-clipper">
      {children(clipItems)}

      {!showAll && (
        <p
          className="m-0 text-primary font-weight-semibold pointer medium mt-1"
          onClick={() => setShowAll(true)}
        >
          {messages['general.show-all']}
        </p>
      )}
    </div>
  );
}
