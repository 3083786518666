import classNames from 'classnames';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { getNextRecurrenceMessageInfo } from './utils';

import { ReactComponent as TimeIcon } from '../../../assets/img/icons/time.svg';

export default React.memo(({ className, budget }) => {
  const { formatMessage } = useIntl();

  const { nextRecurrenceText, badgetClassName } = useMemo(() => {
    const nextRecurrenceInfo = getNextRecurrenceMessageInfo(budget);

    return {
      nextRecurrenceText: formatMessage(
        { id: nextRecurrenceInfo.id },
        nextRecurrenceInfo
      ),
      badgetClassName: nextRecurrenceInfo.className,
    };
  }, [budget]);

  // Render
  return (
    <div
      className={classNames(
        'next-recurrence flex-center',
        className,
        badgetClassName
      )}
    >
      <TimeIcon className="icon" width="1.25rem" height="1.25rem" />
      <span className="ml-2 fs-0-7rem">{nextRecurrenceText}</span>
    </div>
  );
});
