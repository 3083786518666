import React, { useMemo, useState } from 'react';
import { Modal, Button } from 'reactstrap';
import BubblePagination from '../../components/common/BubblePagination';

import { ReactComponent as CloseIcon } from '../../assets/img/icons/close.svg';

import { ChecklistItem } from '../../components/checklist';

import { ReactComponent as ModifiedIcon } from '../../assets/img/bulk/modified.svg';
import { ReactComponent as CreatedIcon } from '../../assets/img/bulk/created.svg';
import { ReactComponent as DeletedIcon } from '../../assets/img/bulk/deleted.svg';
import { ReactComponent as InvalidIcon } from '../../assets/img/bulk/invalid.svg';
import { useIntl } from 'react-intl';

import PerfectScrollbar from 'react-perfect-scrollbar';
import { useCallback } from 'react';
import { generateInvalidUsersExcel } from '../../utils/reports';
import { downloadBlob } from '../../utils/fileGeneration';

const IMAGES = {
  modified: ModifiedIcon,
  created: CreatedIcon,
  deleted: DeletedIcon,
};

const getSelected = (values) =>
  values.reduce((arr, { selected, ...value }) => {
    if (selected) arr.push(value);
    return arr;
  }, []);

function applySelectField(values) {
  return values.map((value) => ({ ...value, selected: true }));
}

function ChoicePage({
  id,
  values,
  setValues,
  onYes,
  onNo,
  titleGenerator,
  messagesId,
  keyGenerator,
}) {
  const ImgComponent = IMAGES[id];

  const [selectedValues, setSelectedValues] = useState(values.length);
  const allUnselected = useMemo(
    () => values.every((value) => !value.selected),
    [values]
  );

  const toggleValueSelection = (value, index) => {
    const newValues = [...values];
    const selected = !value.selected;
    newValues[index].selected = !value.selected;
    setSelectedValues(selected ? selectedValues + 1 : selectedValues - 1);
    setValues(newValues);
  };

  const { messages, formatMessage } = useIntl();

  return (
    <div
      key={id}
      className="choice-page d-flex justify-content-center align-items-center h-100 pb-5"
    >
      <div className="w-50 d-flex justify-content-center align-items-center">
        <ImgComponent width={'80%'} className="img" />
      </div>
      <div className="w-50">
        <div>
          <h1 className="title big-title m-0 p-0">
            {messages[`admin.${messagesId}.bulk.${id}.title`]}
          </h1>
          <p className="m-0 mt-2 description">
            {formatMessage(
              {
                id: `admin.${messagesId}.bulk.${id}.description.${
                  values.length > 1 ? 'plural' : 'singular'
                }`,
              },
              {
                number: values.length,
              }
            )}
          </p>
          <PerfectScrollbar
            options={{ suppressScrollX: true, useBothWheelAxes: false }}
            style={{ maxHeight: '100px' }}
            className="users mt-4 mb-5 mr-1"
          >
            {values.map((value, index) => (
              <div key={`${id}-${keyGenerator(value)}`} className="mb-2">
                <ChecklistItem
                  done={value.selected}
                  title={titleGenerator(value)}
                  handleItemClick={() => toggleValueSelection(value, index)}
                  handleCheck={() => toggleValueSelection(value, index)}
                />
              </div>
            ))}
          </PerfectScrollbar>
          <div className="buttons">
            <Button
              color="white"
              size="lg"
              className="px-4 py-2"
              onClick={onNo}
            >
              {messages['general.no']}
            </Button>
            <Button
              color="primary"
              size="lg"
              className="ml-3 px-4 py-2"
              onClick={onYes}
              disabled={allUnselected}
            >
              {messages['general.yes']}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

function InvalidPage({ id, values, titleGenerator, messagesId, onOk }) {
  const { messages, formatMessage } = useIntl();

  const formattedMessage = useMemo(() => {
    const messageId = `admin.${messagesId}.bulk.${id}.description.${
      values.length > 1 ? 'plural' : 'singular'
    }`;

    const messageValues = {
      number: values.length,
    };

    return formatMessage({ id: messageId }, messageValues);
  }, [messagesId, id, values]);

  // Functions
  const downloadInvalidUsers = useCallback(async (values) => {
    const file = await generateInvalidUsersExcel(values, messages);
    downloadBlob(file, `${messages['invalid-users.xlsx.filename']}.xlsx`);
  }, []);

  // Render
  return (
    <div
      key={id}
      className="choice-page d-flex justify-content-center align-items-center h-100 pb-5"
    >
      <div className="w-50 d-flex justify-content-center align-items-center">
        <InvalidIcon width={'80%'} className="img" />
      </div>
      <div className="w-50">
        <div>
          <h1 className="title big-title m-0 p-0">
            {messages[`admin.${messagesId}.bulk.${id}.title`]}
          </h1>
          <p className="m-0 mt-2 description">{formattedMessage}</p>
          <PerfectScrollbar
            options={{ suppressScrollX: true, useBothWheelAxes: false }}
            style={{ maxHeight: '100px' }}
            className="users mt-4 mb-5 mr-1"
          >
            {values.map((value, index) => (
              <div key={`invalid-${index}`} className="mb-2">
                <div
                  className="checklist-item"
                  style={{ gridTemplateColumns: '0 auto', cursor: 'default' }}
                >
                  <div />
                  <span className="checkbox-name">{titleGenerator(value)}</span>
                </div>
              </div>
            ))}
          </PerfectScrollbar>
          <div className="buttons">
            <Button
              color="primary"
              size="lg"
              className="px-4 py-2"
              onClick={onOk}
            >
              {messages['general.alright']}
            </Button>
            <Button
              color="white"
              size="lg"
              className="ml-3 px-4 py-2"
              onClick={() => downloadInvalidUsers(values)}
            >
              {messages['admin.users.bulk.invalid.btn.download']}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

function FinishPage({
  shouldApply,
  deleted,
  modified,
  created,
  onCancel,
  onApply,
  messagesId,
  finalQuestions,
  finalAnswers,
  onQuestionChange,
}) {
  const { messages, formatMessage } = useIntl();

  const selectedDeleted = useMemo(() => getSelected(deleted), [deleted]);
  const selectedModified = useMemo(() => getSelected(modified), [modified]);
  const selectedCreated = useMemo(() => getSelected(created), [created]);

  const hasSomeApply = useMemo(
    () => Object.values(shouldApply).some((value) => value),
    [shouldApply]
  );

  const shouldDisplayQuestions = useMemo(
    () => finalQuestions.length > 0,
    [finalQuestions]
  );

  const renderUser = (values, selectedValues, name) => {
    const length = values.filter((value) => value.selected).length;

    if (length > 0) {
      let message = '';

      if (shouldApply[name]) {
        if (length > 1)
          message = formatMessage(
            { id: `admin.${messagesId}.bulk.finish.${name}.plural` },
            { number: selectedValues.length }
          );
        else
          message = formatMessage(
            { id: `admin.${messagesId}.bulk.finish.${name}.singular` },
            { number: selectedValues.length }
          );
      } else message = messages[`admin.${messagesId}.bulk.finish.${name}.none`];

      return <li className="mb-2">{message}</li>;
    } else return null;
  };

  return (
    <div className="finish-page h-100 text-center py-4 d-flex flex-column justify-content-center align-items-center">
      <h1 className="title big-title m-0 p-0">
        {messages[`admin.${messagesId}.bulk.finish.title`]}
      </h1>
      <p className="description m-0 pt-1">
        {messages[`admin.${messagesId}.bulk.finish.description`]}
      </p>

      <ul className="py-3 list">
        {renderUser(created, selectedCreated, 'created')}
        {renderUser(modified, selectedModified, 'modified')}
        {renderUser(deleted, selectedDeleted, 'deleted')}
      </ul>

      {shouldDisplayQuestions && (
        <div className="questions">
          {finalQuestions.map(({ name, message }) => (
            <ChecklistItem
              key={name}
              done={finalAnswers[name]}
              title={message}
              handleItemClick={() =>
                onQuestionChange(name, !finalAnswers[name])
              }
              handleCheck={() => onQuestionChange(name, !finalAnswers[name])}
            />
          ))}
        </div>
      )}

      <div
        className="buttons d-flex justify-content-center align-items-center"
        onClick={onCancel}
      >
        <Button color="white" size="lg" className="px-4 py-2">
          {messages['admin.users.bulk.finish.btn.cancel']}
        </Button>
        <Button
          color="primary"
          size="lg"
          className="ml-3 px-4 py-2"
          onClick={onApply}
          disabled={!hasSomeApply}
        >
          {messages['admin.users.bulk.finish.btn.apply']}
        </Button>
      </div>
    </div>
  );
}

export default function BulkModal({
  toggleModal,
  bulkUsers = {},
  onCancel,
  onApply,
  titleGenerator = (value) =>
    `${value.firstName} ${value.lastName} (${value.email})`,
  keyGenerator = (value) => value.email,
  messagesId = 'users',
  finalQuestions = [],
}) {
  const [selectedPage, setSelectedPage] = useState(0);

  const [deleted, setDeleted] = useState(
    applySelectField(bulkUsers.deleted || [])
  );
  const [modified, setModified] = useState(
    applySelectField(bulkUsers.modified || [])
  );
  const [created, setCreated] = useState(
    applySelectField(bulkUsers.created || [])
  );
  const [finalItems, setFinalItems] = useState(
    finalQuestions.reduce((obj, question) => {
      obj[question.name] = question.initialValue || false;
      return obj;
    }, {})
  );

  const [shouldApply, setShouldApply] = useState({
    deleted: deleted.length > 0,
    modified: modified.length > 0,
    created: created.length > 0,
  });

  const pages = useMemo(() => {
    const pages = ['created', 'modified', 'deleted', 'invalid'].filter(
      (value) => bulkUsers[value] && bulkUsers[value].length > 0
    );
    pages.push('end');
    return pages;
  }, [bulkUsers]);

  const handleOptionSelection = (name, value) => {
    setSelectedPage(selectedPage + 1);
    setShouldApply({ ...shouldApply, [name]: value });
  };

  const handleApplyChanges = () => {
    onApply({
      deleted: shouldApply['deleted'] ? getSelected(deleted) : [],
      modified: shouldApply['modified'] ? getSelected(modified) : [],
      created: shouldApply['created'] ? getSelected(created) : [],
      finalQuestions: finalItems,
    });
  };

  const renderPage = () => {
    const pageName = pages[selectedPage];

    if (pageName === 'deleted')
      return (
        <ChoicePage
          id="deleted"
          values={deleted}
          setValues={setDeleted}
          onYes={() => handleOptionSelection('deleted', true)}
          onNo={() => handleOptionSelection('deleted', false)}
          titleGenerator={titleGenerator}
          messagesId={messagesId}
          keyGenerator={keyGenerator}
        />
      );
    else if (pageName === 'created')
      return (
        <ChoicePage
          id="created"
          values={created}
          setValues={setCreated}
          onYes={() => handleOptionSelection('created', true)}
          onNo={() => handleOptionSelection('created', false)}
          titleGenerator={titleGenerator}
          messagesId={messagesId}
          keyGenerator={keyGenerator}
        />
      );
    else if (pageName === 'modified')
      return (
        <ChoicePage
          id="modified"
          values={modified}
          setValues={setModified}
          onYes={() => handleOptionSelection('modified', true)}
          onNo={() => handleOptionSelection('modified', false)}
          titleGenerator={titleGenerator}
          messagesId={messagesId}
          keyGenerator={keyGenerator}
        />
      );
    else if (pageName === 'invalid') {
      return (
        <InvalidPage
          id="invalid"
          messagesId={messagesId}
          values={bulkUsers.invalid}
          titleGenerator={titleGenerator}
          onOk={() => setSelectedPage((value) => value + 1)}
        />
      );
    } else
      return (
        <FinishPage
          deleted={deleted}
          created={created}
          modified={modified}
          shouldApply={shouldApply}
          onCancel={onCancel}
          onApply={handleApplyChanges}
          messagesId={messagesId}
          finalQuestions={finalQuestions}
          finalAnswers={finalItems}
          onQuestionChange={(question, value) =>
            setFinalItems({ ...finalItems, [question]: value })
          }
        />
      );
  };

  return (
    <Modal
      isOpen={true}
      toggle={toggleModal}
      className="position-relative bulk-modal"
      style={{ marginTop: 50, maxWidth: 900 }}
    >
      <div className="closable-icon pointer" onClick={toggleModal}>
        <CloseIcon width={15} height={15} />
      </div>
      <div className="px-3 py-4">
        <div style={{ height: 400 }}>{renderPage()}</div>
        <div>
          <BubblePagination
            numPages={pages.length}
            selectedPage={selectedPage}
            onPageChange={(page) => setSelectedPage(page)}
          />
        </div>
      </div>
    </Modal>
  );
}
