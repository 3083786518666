import React from 'react';
import { useIntl } from 'react-intl';

export default function OrganizationPayment() {
  const { messages } = useIntl();

  return (
    <div>
      <p className="text-warning description">
        {messages['flight-alteration.checkout.organization.title']}
      </p>
    </div>
  );
}
