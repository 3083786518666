import moment from 'moment';
import approvalStatus from '../constants/approvalStatus';

export function canOrderBeCancelled(cancellations = []) {
  const numCancellations = cancellations.length;

  if (numCancellations > 0) {
    const today = moment().format('YYYY-MM-DD');
    return today <= cancellations[numCancellations - 1].endDate;
  } else return false;
}

export function sortReservations(
  reservations,
  detailsProperty,
  journeyIdProperty,
  segmentIndexProperty
) {
  return reservations.sort((a, b) =>
    a[detailsProperty][journeyIdProperty] <
    b[detailsProperty][journeyIdProperty]
      ? -1
      : a[detailsProperty][segmentIndexProperty] -
        b[detailsProperty][segmentIndexProperty]
  );
}

export function sortFlightReservations(reservations) {
  return sortReservations(
    reservations,
    'flightDetails',
    'journeyId',
    'flightIndex'
  );
}

export function sortReservationsByJourney(
  reservations,
  detailsKey = 'flightDetails'
) {
  return reservations.reduce((obj, reservation) => {
    if (!(reservation[detailsKey].journeyId in obj))
      obj[reservation[detailsKey].journeyId] = [];

    obj[reservation[detailsKey].journeyId].push(reservation);

    return obj;
  }, {});
}

export function getReservationTime(
  reservations,
  detailsField = 'flightDetails'
) {
  try {
    if (!reservations) return {};

    const initialFlight = reservations[0],
      lastFlight = reservations[reservations.length - 1];

    return {
      departure: initialFlight[detailsField].departureDateTime.split(' ').pop(),
      arrival: lastFlight[detailsField].arrivalDateTime.split(' ').pop(),
    };
  } catch (err) {
    console.error('Unable to get reservation time', err);
    return {};
  }
}

export function sortBusReservations(reservations) {
  return sortReservations(reservations, 'busDetails', 'journeyId', 'legIndex');
}

export function canApproveReservation({ approvals = [], user }) {
  return (
    user.admin ||
    approvals.filter(
      (a) => a.status === approvalStatus.PENDING && a.userId === user.uid
    )
  );
}

export function getNumberOfSearchItems(results = [], searchParams = {}) {
  const length = results.length;

  return length === searchParams.totalItems
    ? null
    : searchParams.totalItems || length;
}

export function getHotelAlternativesInsideRadius(
  currentHotelLocalization,
  alternatives,
  params
) {
  let newAlternatives = [];

  if (params?.amenities?.['Café da manhã'] === true) {
    alternatives.forEach((alternative) => {
      if (alternative.hotel.amenities.includes('Café da manhã')) {
        let newAlternative;

        let rooms = alternative.rooms.filter((room) => {
          return room?.board?.breakfast === true;
        });

        if (rooms.length) {
          newAlternative = { ...alternative, rooms: rooms };
          newAlternatives.push(newAlternative);
        }
      }
    });
  }

  return newAlternatives.filter((alternative) => {
    const alternativeLocalization = alternative.hotel.localization;

    const latDistance =
      alternativeLocalization.lat * -1 - currentHotelLocalization.lat * -1;
    const lngDistance =
      alternativeLocalization.lng * -1 - currentHotelLocalization.lng * -1;

    return (
      latDistance < 0.0488039 &&
      latDistance > -0.0488039 &&
      lngDistance < 0.0449389 &&
      lngDistance > -0.0449389 &&
      latDistance !== 0 &&
      lngDistance !== 0
    );
  });
}

export function getBreakfastMinRoomFare(hotel) {
  const minFare = Math.min(
    hotel.rooms
      .filter((room) => room.board?.breakfast === true)
      .map((room) => room.fare)
  );

  hotel = { ...hotel, minFare };

  return hotel;
}
