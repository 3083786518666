import React from 'react';

import ContentLoader from 'react-content-loader';

export default function LoaderTotal() {
  return (
    <ContentLoader
      speed={2}
      width={90}
      height={45}
      viewBox="0 0 90 45"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="40" y="0" rx="3" ry="3" width="50" height="15" />
      <rect x="0" y="20" rx="3" ry="3" width="90" height="25" />
    </ContentLoader>
  );
}
