import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import useDataLoaderWithArchive from '../../../hooks/useDataLoaderWithArchive';
import firebaseApp from '../../../services/Firebase';
import { stringToMoney } from '../../../utils/money';
import LoadingBlock from '../../../components/common/LoadingBlock';
import NextRecurrenceBadge from './NextRecurrenceBadge';
import classNames from 'classnames';
import { getCardNameFromMessages } from '../../../utils/recurrenceBudget';
import CustomAnimatedCheckbox from '../../../components/common/CustomCheckbox';

import { CardCategoriesIcons } from '../../../constants/cardIcons';
import { RecurrenceBudgetTypes } from '../../../constants/recurrenceBudgets';

export default function RecurrenceCard({
  className,
  budget,
  onClick,
  width = 350,
  height = 180,
  selected,
  toggle,
}) {
  const { messages, formatMessage } = useIntl();

  // States
  const [expenseCard, _, isLoadingExpenseCard] = useDataLoaderWithArchive({
    fetchFunction: () =>
      firebaseApp.getExpenseCardsByIdFromOrganization(
        budget.organizationId,
        budget.cardId
      ),
    archiveKey: `expense-card/${budget.cardId}`,
  });

  const { name, title, budgetValue, category, period, deactivatedMotive } =
    useMemo(() => {
      const { name, formattedName, recurrence } = budget;
      const { days, type } = recurrence;

      const hasVariables = formattedName !== name;

      return {
        name: formattedName,
        title: hasVariables ? name : '',
        category: budget.category,
        budgetValue: budget.budgetValue
          ? stringToMoney(budget.budgetValue, 2)
          : null,
        period: formatMessage(
          {
            id:
              type === RecurrenceBudgetTypes.date
                ? 'recurrence-budget.type.date'
                : 'recurrence-budget.type.interval',
          },
          { days }
        ),
        deactivatedMotive: budget.deactivatedMotive,
      };
    }, [budget]);

  const [CategoryIcon, categoryLabel] = useMemo(() => {
    const {
      value = 'default',
      label = messages['refund.modal.items.refund-type.free'],
    } = category;
    return [CardCategoriesIcons[value] || CardCategoriesIcons.default, label];
  }, [category]);

  const cardNumber = useMemo(
    () => getCardNameFromMessages(expenseCard, formatMessage),
    [expenseCard]
  );

  // Render
  return (
    <div
      className={classNames('recurrence-card p-4', className)}
      onClick={onClick}
      style={{ width, height }}
    >
      <div className="content d-flex flex-column justify-content-between h-100">
        <div className="d-flex align-items-start">
          <div
            className="icon-container flex-center flex-shrink-0"
            title={categoryLabel}
          >
            {CategoryIcon ? (
              <CategoryIcon
                title={categoryLabel}
                width="50%"
                height="50%"
                className="text-primary"
              />
            ) : null}
          </div>
          <div className="ml-3 flex-grow-1">
            <p className="m-0 p-0 text-muted fs-0-7rem lh-1-25 mb-1">
              {period}
            </p>
            <h3 className="font-primary m-0 p-0 fs-1rem name" title={title}>
              {name}
            </h3>
            <p className="m-0 p-0 fs-0-9rem">{budgetValue}</p>
          </div>
          {deactivatedMotive ? null : (
            <CustomAnimatedCheckbox
              size="1.5rem"
              radio={false}
              purple
              checked={selected}
              onChange={toggle}
            />
          )}
        </div>
        <div className="t-3 flex-between">
          <div>
            <p className="m-0 p-0 text-muted fs-0-7rem lh-1-1">
              {messages['recurrence-budget.card']}
            </p>
            {isLoadingExpenseCard ? (
              <LoadingBlock height={24} width={100} rx="5" ry="5" />
            ) : (
              <p className="m-0 p-0 font-weight-medium fs-1rem lh-1-5 card-number">
                {cardNumber}
              </p>
            )}
          </div>

          <NextRecurrenceBadge
            className="py-2 px-3 flex-shrink-0 ml-1"
            budget={budget}
          />
        </div>
      </div>
    </div>
  );
}
