import React, { useMemo } from 'react';
import NoResults from '../../search/NoResults';
import PerfectScrollbar from 'react-perfect-scrollbar';
import LoadingItineraryCard from '../cards/LoadingItineraryCard';

const loadingArr = new Array(5).fill(0);

function _EmptyComponent() {
  return (
    <div className="flex-grow-1 flex-center my-4">
      <NoResults className="mt-0" />
    </div>
  );
}

export default function ResultsWrapper({
  loading,
  results = [],
  children,
  EmptyComponent = _EmptyComponent,
}) {
  const isEmpty = useMemo(() => results.length === 0, [results]);

  if (loading)
    return (
      <div
        className="flex-grow-1 w-100 py-4 px-2 d-flex"
        style={{ overflow: 'hidden' }}
      >
        {loadingArr.map((_, index) => (
          <LoadingItineraryCard key={`loading-${index}`} index={index} />
        ))}
      </div>
    );

  if (isEmpty) return <EmptyComponent />;

  return (
    <PerfectScrollbar
      options={{
        suppressScrollY: true,
        useBothWheelAxes: false,
      }}
      className="flex-grow-1 w-100 py-4 px-2"
    >
      <div className="d-flex">{children}</div>
    </PerfectScrollbar>
  );
}
