import React from 'react';
import { useMemo } from 'react';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Button } from 'reactstrap';

export default function BottomButtons({
  goBack,
  goForward,
  canGoBack = true,
  canGoForward = true,
  disableBack = false,
  disableForward = false,
  backText = '',
  forwardText = '',
}) {
  const { messages } = useIntl();

  const [goBackLabel, goForwardLabel] = useMemo(
    () => [
      backText || messages['general.go-back'],
      forwardText || messages['general.go-forward'],
    ],
    [messages, backText, forwardText]
  );

  return (
    <div className="text-right btns mt-0">
      {canGoBack ? (
        <Button
          color="link"
          onClick={goBack}
          className="my-0"
          disabled={disableBack}
        >
          {goBackLabel}
        </Button>
      ) : null}
      {canGoForward ? (
        <Button
          color="primary"
          onClick={goForward}
          className="my-0 px-3"
          disabled={disableForward}
        >
          {goForwardLabel}
        </Button>
      ) : null}
    </div>
  );
}
