import { find } from 'lodash';
import {
  TFlightSearchAirportParam,
  TFlightSearchJourneyParam,
} from '../types/orders/flight/flightParams';
import { TLabelValueObject, TMinMaxObject, TObject } from '../types/others';
import { convertArrayToLabelValueArray, mapIntoBooleanObject } from './others';
import { TBusSearchJourneyParam } from '../types/orders/bus/busParams';
import { TContains } from '../hooks/useParams/types';

export function isTruthy(value: any, allowUndefined = false) {
  return allowUndefined ? value || value === undefined : value;
}

export function formatContainsParamsWithEvent(
  name: string,
  e: React.ChangeEvent<HTMLInputElement>
) {
  return { name, value: e.target.checked };
}

export function doesParamHaveItems(param: any[] | undefined, minValue = 1) {
  return param ? (param.length as number) > minValue : false;
}

export function shouldShowPriceParam(price?: TMinMaxObject) {
  return price && price.min !== price.max;
}

export function getPriceParamInfo(price?: TMinMaxObject) {
  const showPrice = shouldShowPriceParam(price);

  return price && showPrice
    ? {
        showPrice,
        maxPrice: Math.ceil(price.max),
        minPrice: Math.floor(price.min),
      }
    : { showPrice };
}

export function formatStops(
  stops: (string | number)[] = [],
  messages: TObject<string>
) {
  return stops.map((value) => {
    const intStop = parseInt(value as string);

    const label =
      intStop === 0
        ? messages['flight.no-stop']
        : intStop === 1
        ? `1 ${messages['flight.stop']}`
        : `${intStop} ${messages['flight.stops']}`;

    return { value, label };
  });
}

export function formatConnections(
  airports: TFlightSearchAirportParam[],
  connections: string[]
) {
  return convertArrayToLabelValueArray(connections, (iata) => {
    const airport = airports.find((airport) => airport.iata === iata);
    return airport ? `(${iata}) ${airport.city}` : iata;
  }) as TLabelValueObject[];
}

export function formatCarriers(
  carries: string[],
  lookupTable: TObject<{ name: string }>
) {
  return convertArrayToLabelValueArray(
    carries,
    (carrier) => lookupTable[carrier]?.name || carrier
  ) as TLabelValueObject[];
}

export function formatJourneys(
  journeys: TFlightSearchJourneyParam[],
  airports: TFlightSearchAirportParam[]
) {
  const lookupTable: TObject<string> = {};

  const getStation = airports
    ? (station: string) => {
        if (!(station in lookupTable))
          lookupTable[station] =
            find(airports, { iata: station })?.city || station;

        return lookupTable[station];
      }
    : (station: string) => station;

  return journeys.map((journey) => ({
    ...journey,
    label: `${getStation(journey.originCode)} • ${getStation(
      journey.destinationCode
    )}`,
  }));
}

export function formatBusJourneys(
  journeys: TBusSearchJourneyParam[],
  messages: TObject<string>
) {
  return journeys.map((journey) => ({
    ...journey,
    label:
      journey.index === 0
        ? messages['travel.outbound']
        : messages['travel.inbound'],
  }));
}

export function formatCategories(
  categories: string[],
  messages: TObject<string>
) {
  return convertArrayToLabelValueArray(categories, (label) => {
    const category = parseInt(label);

    switch (category) {
      case 0:
        return messages['containers.search.hotel-filters.no-ratings'];
      case 1:
        return `${category} ${messages['general.star']}`;
      default:
        return `${category} ${messages['general.stars']}`;
    }
  }) as TLabelValueObject[];
}

export function makeContainsParamsFromDefaultSearchParams({
  searchParam = [],
  defaultParam = [],
  returnIfEmpty = {},
  mapAllParams,
}: {
  searchParam?: any[];
  defaultParam?: TLabelValueObject[];
  returnIfEmpty?: any;
  mapAllParams?: boolean;
}) {
  try {
    if (searchParam.length && defaultParam.length) {
      let numAddedItems = 0;

      const initialObj: TContains = mapAllParams
        ? mapIntoBooleanObject(searchParam, false)
        : {};

      const newParams = defaultParam.reduce((obj, item) => {
        if (searchParam.includes(item.value)) {
          obj[item.value] = true;
          numAddedItems++;
        }

        return obj;
      }, initialObj);

      return numAddedItems ? newParams : returnIfEmpty;
    }

    return returnIfEmpty;
  } catch (err) {
    console.error('Unable to make params from default search params', err);
    return returnIfEmpty;
  }
}
