import React from 'react';
import GlideComponent from '../carousel/GlideComponent';
import map from 'lodash/map';

export default function OfficePhotos({ officeId, office }) {
  let mainImage = '/assets/img/default-hotel-bw.png';
  if (office.mainPhoto !== '') mainImage = office.mainPhoto;

  return (
    <GlideComponent
      settings={{
        gap: 1,
        perView: 1,
        type: 'carousel',
        breakpoints: {
          600: { perView: 1 },
          1400: { perView: 1 },
        },
        hideNav: true,
        reservationNav: true,
      }}
    >
      {office.photos.length === 0 && (
        <div
          className="image-slide"
          style={{
            backgroundImage: `url(${mainImage})`,
          }}
        />
      )}

      {map(office.photos, (photo, index) => {
        return (
          <div
            key={`photo-${officeId}-photo-${index}`}
            className="image-slide"
            style={{
              backgroundImage: `url(${photo})`,
            }}
          />
        );
      })}
    </GlideComponent>
  );
}
