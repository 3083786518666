import React, { useState } from 'react';
import classnames from 'classnames';
import { HexColorPicker, HexColorInput } from 'react-colorful';

export default function CustomColorPicker({ color, setColor }) {
  const [show, setShow] = useState(false);

  const toggle = () => setShow(!show);

  return (
    <div className="custom-color-picker">
      <div className="d-flex align-items-center justify-content-start">
        <div
          className="color-swatch flex-shrink-0 pointer"
          onClick={toggle}
          style={{ backgroundColor: color }}
        />
        <HexColorInput
          name="card-color"
          color={color}
          onChange={setColor}
          prefixed
          className="ml-1 w-100"
        />
      </div>
      <div
        className={classnames('picker p-3', {
          show,
        })}
        onMouseLeave={toggle}
      >
        <HexColorPicker color={color} onChange={setColor} />
      </div>
    </div>
  );
}
