import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  DropdownMenu,
  DropdownToggle,
  Label,
  UncontrolledDropdown,
} from 'reactstrap';
import Select from 'react-select';
import { useIntl } from 'react-intl';
import { ReactComponent as PrintIcon } from '../../assets/img/icons/print_2.svg';
import { ReactComponent as ArrowDownIcon } from '../../assets/img/icons/caret-down.svg';

const customStyles = {
  option: (provided, { isSelected }) => {
    return {
      ...provided,
      backgroundColor: isSelected ? '#43418e' : 'white',
      ':hover': {
        backgroundColor: isSelected ? '#43418e' : 'rgba(67, 65, 142, 0.1)',
      },
      ':active': {
        backgroundColor: isSelected ? '#43418e' : 'rgba(67, 65, 142, 0.1)',
      },
    };
  },
  singleValue: (base) => ({
    ...base,
    color: 'inherit',
  }),
  control: (base, { isDisabled }) => ({
    ...base,
    borderColor: 'transparent',
    borderBottom: '1px solid #43418e',
    boxShadow: 'none',
    borderRadius: '0',
    opacity: isDisabled ? 0.5 : 1,
    backgroundColor: 'transparent',
    ':hover': {
      borderColor: 'transparent',
      borderBottom: '1px solid #43418e',
      boxShadow: 'none',
    },
    ':focus': {
      borderColor: 'transparent',
      borderBottom: '1px solid #43418e',
      boxShadow: 'none',
    },
  }),
  menu: (base) => ({
    ...base,
    zIndex: 50,
  }),
};

export default function PrintDropdown({
  text = 'Exportar',
  arrow,
  typeOptions,
  detailOptions = [],
  disabled,
  showDetails = true,
  onPrint = () => {},
  widthIcon = 25,
  heightIcon = 25,
  bgWhite = false,
}) {
  const { messages } = useIntl();

  const [selectedType, setSelectedType] = useState(typeOptions[0] || {});
  const [selectedDetail, setSelectedDetail] = useState({});

  const [isPriting, setIsPrinting] = useState(false);

  const typeDetailsOptions = useMemo(() => {
    if (selectedType.allows) {
      return detailOptions.filter(({ value }) =>
        selectedType.allows.includes(value)
      );
    }

    return detailOptions;
  }, [selectedType, detailOptions]);

  // Effects
  useEffect(() => {
    if (selectedDetail?.value) {
      if (
        selectedType.allows &&
        !selectedType.allows.includes(selectedDetail.value)
      ) {
        setSelectedDetail(typeDetailsOptions[0]);
      }
    } else setSelectedDetail(typeDetailsOptions[0]);
  }, [selectedDetail, typeDetailsOptions]);

  // Functions
  const handlePrint = async () => {
    setIsPrinting(true);
    await onPrint(selectedType, selectedDetail);
    setIsPrinting(false);
  };

  return (
    <UncontrolledDropdown className="print-dropdown">
      <DropdownToggle
        className="m-0 p-0"
        style={{ backgroundColor: 'transparent', border: 'none' }}
        disabled={disabled}
      >
        <div className="print-btn-white p-3 d-flex align-items-center justify-content-center">
          <PrintIcon width={widthIcon} height={heightIcon} fill="#43418E" />
          {text && <span className="ml-2 mr-2">{text}</span>}
          {arrow && <ArrowDownIcon width={7} height={7} />}
        </div>
      </DropdownToggle>
      <DropdownMenu className="px-3 pb-3 menu" right>
        <Label className="form-group search has-top-label w-100">
          <Select
            value={selectedType}
            options={typeOptions}
            isMulti={false}
            className="w-100 pt-4"
            styles={customStyles}
            components={{
              IndicatorSeparator: () => null,
            }}
            onChange={setSelectedType}
          />
          <span>{messages['reports.print.type']}</span>
        </Label>
        {detailOptions && showDetails && (
          <Label className="form-group search has-top-label w-100">
            <Select
              value={selectedDetail}
              options={typeDetailsOptions}
              isMulti={false}
              styles={customStyles}
              className="w-100 pt-4"
              components={{
                IndicatorSeparator: () => null,
              }}
              onChange={setSelectedDetail}
              isDisabled={selectedType.hasDetail === false}
            />
            <span>{messages['reports.print.details']}</span>
          </Label>
        )}
        <div className="text-center">
          {isPriting ? (
            <div className="flex-center" style={{ height: 37 }}>
              <div
                className="loading relative"
                style={{ width: 25, height: 25 }}
              />
            </div>
          ) : (
            <Button
              color="primary"
              size="sm"
              onClick={handlePrint}
              disabled={isPriting}
            >
              {messages['reports.print.generate']}
            </Button>
          )}
        </div>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}
