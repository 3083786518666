import { stringToMoney } from '../../utils/money';

export const getPriceDifference = (originalPrice, currentPrice, currency) => {
  return stringToMoney(originalPrice - currentPrice, 0, currency);
};

export const getSelectedClass = (alternative, selectedAlternative) => {
  if (selectedAlternative) {
    return selectedAlternative.id === alternative.id
      ? 'selected'
      : 'unselected';
  } else return '';
};

export const getStationAbbreviation = (station, maxLetters = 3) => {
  const start = station.split(',')[0];
  const words = start.split(' ').filter((word) => word.length > 2);
  return words.length > 1
    ? words.map((word) => word[0]).join('')
    : words.slice(0, maxLetters);
};
