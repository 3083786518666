import React from 'react';

export default function VerticalLine({
  width = 1,
  height = 50,
  color = 'rgba(0, 0, 0, 0.5)',
  className = '',
  style = {},
  ...props
}) {
  return (
    <div
      className={`vertical-line ${className}`}
      style={{ width, height, backgroundColor: color, ...style }}
      {...props}
    />
  );
}
