import moment from 'moment';

import {
  FilterQueryTypes,
  TFilterQueryTypes,
} from 'src/portao3-legacy/hooks/useFilterItems/types';
import { ParamTypes } from 'src/portao3-legacy/hooks/useParams/types';
import { TFlightSearchItem } from 'src/portao3-legacy/types/orders/flight/flightOrder';
import { TFlightGetDefaultParams } from 'src/portao3-legacy/types/orders/flight/flightParams';
import { makeContainsParamsFromDefaultSearchParams } from 'src/portao3-legacy/utils/params';

export const FlightParamsConfig = [
  {
    name: 'price',
    type: ParamTypes.RANGE,
  },
  {
    name: 'departureDuration',
    type: ParamTypes.RANGE,
  },
  {
    name: 'arrivalDuration',
    type: ParamTypes.RANGE,
  },
  {
    name: 'departureTime',
    type: ParamTypes.RANGE_MAP,
  },
  {
    name: 'departureConnections',
    type: ParamTypes.CONTAINS,
  },
  {
    name: 'arrivalConnections',
    type: ParamTypes.CONTAINS,
  },
  {
    name: 'stops',
    type: ParamTypes.CONTAINS,
  },
  {
    name: 'airlines',
    type: ParamTypes.CONTAINS,
    getDefaultValue: (defaultParams: TFlightGetDefaultParams = {} as any) =>
      makeContainsParamsFromDefaultSearchParams({
        searchParam: defaultParams.searchParams?.carriers,
        defaultParam: defaultParams.defaultSearchParams?.airlines,
      }),
  },
];

export const FlightFilterQueries: TFilterQueryTypes<any, any, any>[] = [
  {
    name: 'price',
    type: FilterQueryTypes.RANGE,
    getValue: ({ fare = {} }: any) => fare.total,
  },
  {
    name: 'airlines',
    type: FilterQueryTypes.CONTAINS,
    getValue: ({ airline }: any) => airline,
    skipIfEmpty: true,
    filterFalsy: true,
    allowUndefined: false,
  },
  {
    type: FilterQueryTypes.CUSTOM,
    getValue: (
      item: TFlightSearchItem,
      {
        departureDuration,
        departureConnections,
        arrivalDuration,
        arrivalConnections,
        stops,
        departureTime,
      }: any
    ) => {
      return item.journeys.every((journey, index) => {
        const [duration, connections] =
          index === 0
            ? [departureDuration, departureConnections]
            : [arrivalDuration, arrivalConnections];

        const shouldFilterDuration = duration.max !== null;

        return journey.segments.some((segment) => {
          // Check for number of Stops
          const numStops = segment.flights.length - 1;

          if (stops && stops[numStops] !== true) return false;

          if (
            shouldFilterDuration &&
            (segment.duration < duration.min || segment.duration > duration.max)
          )
            return false;

          if (
            numStops > 0 &&
            connections &&
            segment.flights.every(
              (flight) => connections[flight.arrivalStation] !== true
            )
          )
            return false;

          // Check for departure time
          const departureHour = moment(segment.departureDateTime).hour(),
            segmentDepartureTime = departureTime[index];

          if (
            segmentDepartureTime &&
            (departureHour < segmentDepartureTime.min ||
              departureHour > segmentDepartureTime.max)
          )
            return false;

          return true;
        });
      });
    },
  },
];
