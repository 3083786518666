import React, { useMemo } from 'react';
import { CardBody, Row } from 'reactstrap';
import { Colxx } from '../../components/common/CustomBootstrap';
import filter from 'lodash/filter';
import find from 'lodash/find';
import isUndefined from 'lodash/isUndefined';
import { useIntl } from 'react-intl';

import { OrderTypes } from '../../constants/orderTypes';
import { registeredBus } from '../../constants/travelValues';
import countBy from 'lodash/countBy';

export default function BusCardTopCompanies({ orders, reservations }) {
  const { messages } = useIntl();

  const companies = useMemo(() => {
    if (!(orders.length && reservations.length)) return {};

    const busReservations = filter(reservations, (reservation) => {
      const orderExists = !isUndefined(
        find(orders, (order) => {
          return order.id === reservation.refs.orderId;
        })
      );

      return reservation.type === OrderTypes.BUS && orderExists;
    });

    return countBy(busReservations, 'busDetails.carrier');
  }, [orders, reservations]);

  return (
    <CardBody>
      <Row>
        <Colxx sm="12">
          {Object.keys(companies).map((company) => {
            return (
              <div
                key={company}
                className="d-flex flex-row mb-3 pb-3 justify-content-between align-items-center"
              >
                <div
                  className="chain-image"
                  style={{
                    backgroundImage: `url(${
                      registeredBus[company] ? registeredBus[company].logo : ''
                    })`,
                  }}
                />
                <div className="flex-fill pl-4">
                  <p className="font-weight-medium mb-0">
                    {registeredBus[company]
                      ? registeredBus[company].name
                      : company}
                  </p>
                </div>
                <div className="pr-2">
                  {companies[company]}{' '}
                  {
                    messages[
                      'containers.dashboard.flight-card-top-airlines.stretches'
                    ]
                  }
                </div>
              </div>
            );
          })}
        </Colxx>
      </Row>
    </CardBody>
  );
}
