import { useEffect, useMemo, useState } from 'react';
import equal from 'fast-deep-equal';

const defaultIsEqual = (originalValue, newValue) => {
  return equal(originalValue, newValue);
};

export default function useHasChanged(values) {
  const [changes, setChanges] = useState([]);

  const hasChanged = useMemo(() => changes.some((value) => value), [changes]);
  const positions = useMemo(
    () =>
      Object.keys(values).reduce((obj, key, index) => {
        obj[key] = index;
        return obj;
      }, {}),
    [values]
  );

  // Effects
  useEffect(() => {
    setChanges(new Array(Object.keys(values).length).fill(false));
  }, []);

  // Functions
  const onChange = (id, newValue) => {
    try {
      if (id in values) {
        const { value, isEqualFunction = defaultIsEqual } = values[id];
        const hasChanged = !isEqualFunction(value, newValue);
        const position = positions[id];

        if (changes[position] !== hasChanged) {
          setChanges((arr) => {
            const newArr = [...arr];
            newArr[position] = hasChanged;
            return newArr;
          });
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  return [hasChanged, onChange];
}
