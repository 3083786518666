import React, { useMemo, useState } from 'react';
import moment from 'moment';

import AlertCard from './AlertCard';

import { imageUrl } from '../../utils/image';
import { registeredAirlines } from '../../constants/travelValues';
import { useIntl } from 'react-intl';
import AssociateServices from './AssociateServices';
import orderStatus from '../../constants/orderStatus';

import { ReactComponent as SeatIcon } from '../../assets/img/icons/seat.svg';
import { ReactComponent as LuggageIcon } from '../../assets/img/icons/luggage.svg';
import useDataSnapshot from '../../hooks/useDataSnapshot';
import firebaseApp from '../../services/Firebase';

function FlightCard({ reservation, locations, order, openFlight }) {
  const { messages } = useIntl();

  const [showAssociate, setShowAssociate] = useState(false);

  const airlineData = useMemo(
    () => registeredAirlines[reservation.flightDetails.airline],
    [reservation]
  );

  const shouldApplyAssociateServices = useMemo(() => {
    const { journeyId } = reservation.flightDetails;

    if (journeyId === 0) {
      const length = order.journeys.length;
      const journeys = order.journeys;

      return (
        order.statusCode < orderStatus.ERROR &&
        reservation.flightDetails.arrivalStation.name ===
          journeys[0].arrivalStation.name &&
        journeys[0].departureStation.name ===
          journeys[length - 1].arrivalStation.name
      );
    }

    return false;
  }, [order, reservation]);

  const [ancillaries] = useDataSnapshot(
    {
      fetchFunction: () => firebaseApp.getBookedAncillariesFromOrder(order.id),
      shouldLoad: order.gordian?.purchased,
    },
    [order]
  );

  const addedAncillaries = useMemo(() => {
    if (ancillaries.length) {
      const ancillary = ancillaries[0];
      const obj = {};

      ancillary.items.forEach(({ type }) => {
        obj[type] = true;
      });

      return obj;
    } else return {};
  }, [ancillaries]);

  return (
    <>
      <div className="item" data-testid={order.id}>
        <div className="date">
          <div className="content">
            <span className="day">
              {moment(
                reservation.flightDetails.departureDateTime,
                'DD/MM/YYYY HH:mm'
              ).format('DD')}
            </span>
            <span className="month">
              {moment(
                reservation.flightDetails.departureDateTime,
                'DD/MM/YYYY HH:mm'
              ).format('MMM')}
            </span>
          </div>
        </div>
        <div
          className="details"
          onClick={() => {
            openFlight(reservation);
          }}
        >
          <div
            className="details-content"
            style={{
              backgroundImage: `url(${imageUrl(
                reservation.type,
                reservation.flightDetails.arrivalStation.iataCode,
                locations
              )})`,
            }}
          >
            <div className="gradient">
              <div className="header">
                <div className="d-flex align-items-center justify-content-between m-0">
                  <div className="m-0">
                    {airlineData && (
                      <img src={airlineData.logoBlack} alt={airlineData.name} />
                    )}
                  </div>
                  <div className="m-0">
                    {addedAncillaries.seat ? (
                      <SeatIcon
                        fill="white"
                        width={15}
                        height={15}
                        className="ml-1"
                        title={messages['flight.with-selected-seat']}
                      />
                    ) : null}
                    {addedAncillaries.bag ? (
                      <LuggageIcon
                        fill="white"
                        width={15}
                        height={15}
                        className="ml-1"
                        title={messages['flight.with-luggage']}
                      />
                    ) : null}
                  </div>
                </div>
                <div>
                  <span>{messages['travel.flight']}</span>
                  {reservation.flightDetails.airline}
                  {reservation.flightDetails.flightNumber}
                </div>
              </div>

              <div className="route clearfix">
                <div className="airport">
                  <span>{reservation.flightDetails.departureStation.name}</span>
                  {reservation.flightDetails.departureStation.iataCode}
                </div>
                <div className="to text-center">
                  <img
                    src="/assets/img/icons/timeline_flight_white.svg"
                    alt={messages['alts.components.timeline.flight-card']}
                  />
                </div>
                <div className="airport text-right">
                  <span>{reservation.flightDetails.arrivalStation.name}</span>
                  {reservation.flightDetails.arrivalStation.iataCode}
                </div>
              </div>
            </div>
          </div>

          <AlertCard order={order} />
        </div>
      </div>

      {shouldApplyAssociateServices && (
        <AssociateServices
          order={order}
          reservation={reservation}
          show={showAssociate}
          toggleShow={() => setShowAssociate(!showAssociate)}
        />
      )}
    </>
  );
}

export default FlightCard;
