import React from 'react';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import Spinner from '../../common/Spinner';

export default function ValidatingModal({ setHeight }) {
  const { messages } = useIntl();

  useEffect(() => {
    setHeight(200);
  }, []);

  return (
    <div
      className="d-flex flex-column align-items-center justify-content-center"
      style={{ marginTop: -20 }}
    >
      <Spinner
        className="position-relative mb-2"
        relative
        style={{ width: 70, height: 70 }}
      />
      <p
        className="description text-center px-5 m-0"
        style={{ lineHeight: 1.5 }}
      >
        {messages['refund.card.connecting']}
      </p>
    </div>
  );
}
