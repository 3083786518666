import { Row } from 'reactstrap';
import { Colxx } from 'src/portao3-legacy/components/common/CustomBootstrap';
import NoResults from 'src/portao3-legacy/components/search/NoResults';
import useParams from 'src/portao3-legacy/hooks/useParams/useParams';
import useReserveTravelItem from 'src/portao3-legacy/hooks/useReserveTravelItem/useReserveTravelItem';
import useTravelSearch from 'src/portao3-legacy/hooks/useTravelSearch/useTravelSearch';

import loadSearchStatus from 'src/portao3-legacy/constants/loadSearchStatus';
import searchStatus from 'src/portao3-legacy/constants/searchStatus';

import { ITravelSearch } from 'src/portao3-legacy/types/search';
import { useMemo } from 'react';
import { TGetDefaultParams } from 'src/portao3-legacy/types/others';

export default function TravelSearch<
  TSearchItem,
  TSearchParams,
  TParams,
  TReservationParams,
  TDefaultSearchParams
>({
  search,
  paramsConfig,
  orderType,
  formatResultItem,
  formatParams,
  formatResult,
  ResultsComponent,
  FilterComponent,
  ...props
}: ITravelSearch<TSearchItem, TSearchParams, TParams, TReservationParams>) {
  // Search
  const {
    results,
    isLoading,
    params: searchParams,
    defaultSearchParams,
    status,
    getRemainingSearch,
  } = useTravelSearch<TSearchItem, TSearchParams, TDefaultSearchParams>({
    search,
    orderType,
    formatResultItem,
    formatParams,
    formatResult,
  });

  const defaultParams = useMemo<
    TGetDefaultParams<TSearchParams, TDefaultSearchParams>
  >(
    () => ({
      searchParams,
      defaultSearchParams,
    }),
    [searchParams, defaultSearchParams]
  );

  // Reservation
  const { reserveItemWithToggle, reserveItem } =
    useReserveTravelItem<TReservationParams>({
      orderType,
      search,
      searchParams,
    });

  // Params
  const { params, setParam, setNamedParam, replaceParam } = useParams({
    params: paramsConfig,
    defaultParams,
  });

  // Render
  if (search.status === searchStatus.NEW) return <div />;

  if (status === loadSearchStatus.EMPTY)
    return (
      <div className="search-results empty">
        <NoResults />
      </div>
    );

  return (
    <Row className="mt-5">
      <Colxx xxs="12" sm="3">
        <FilterComponent
          searchParams={searchParams}
          searchStatus={search.status}
          status={status}
          params={params as any}
          setNamedParam={setNamedParam}
          setParam={setParam}
          isLoading={isLoading}
        />
      </Colxx>

      <Colxx xxs="12" sm="9" className="pb-5 mb-5">
        <ResultsComponent
          searchParams={searchParams}
          params={params as TParams}
          setNamedParam={setNamedParam}
          setParam={setParam}
          replaceParam={replaceParam}
          status={status}
          isLoading={isLoading}
          search={search}
          items={results}
          getRemainingSearch={getRemainingSearch}
          reserveItem={reserveItem}
          reserveItemWithToggle={reserveItemWithToggle}
          {...props}
        />
      </Colxx>
    </Row>
  );
}
