import React, { useEffect, Fragment } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import { Row, Button } from 'reactstrap';
import { Colxx } from '../components/common/CustomBootstrap';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function UserLayout({ children }) {
  const history = useHistory();
  const query = useQuery();

  useEffect(() => {
    document.body.classList.add('background');

    return () => {
      document.body.classList.remove('background');
    };
  }, []);

  // Open Terms and Privacy
  const openTerms = () => {
    query.append('terms-of-service', '');
    history.replace({ search: query.toString() });
  };

  const openPrivacy = () => {
    query.append('privacy-policy', '');
    history.replace({ search: query.toString() });
  };

  return (
    <Fragment>
      <div className="fixed-background" />
      <main className="login">
        <div className="container">
          <Row className="h-100">
            <Colxx xxs="12" md="6" lg="6" xl="4" className="mx-auto my-auto">
              {children}
              <div className="auth-footer">
                <Button onClick={openPrivacy} color="transparent" size="xs">
                  Privacidade
                </Button>
                <Button onClick={openTerms} color="transparent" size="xs">
                  Termos
                </Button>
              </div>
            </Colxx>
          </Row>
        </div>
      </main>
    </Fragment>
  );
}
