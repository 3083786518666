import React from 'react';
import { Row, Card, CardBody, CardTitle } from 'reactstrap';
import { Colxx } from '../../common/CustomBootstrap';
import filter from 'lodash/filter';
import forEach from 'lodash/forEach';
import orderBy from 'lodash/orderBy';
import sumBy from 'lodash/sumBy';
import map from 'lodash/map';
import slice from 'lodash/slice';
import { useIntl } from 'react-intl';

import LoaderAnalyticsSingle from '../../loaders/LoaderAnalyticsSingle';
import LoaderAnalytics from '../../loaders/LoaderAnalytics';
import { ReactComponent as RoundTripArrow } from '../../../assets/img/icons/roundtrip.svg';

import { normalizeCity } from '../../../utils/city';
import { OrderTypes } from '../../../constants/orderTypes';

export default function ESGTopFlightJourneys({ orders, loading }) {
  const { messages } = useIntl();

  if (loading) {
    return (
      <Card className="dashboard-small-chart h-100">
        <CardBody>
          <Row className="w-100 mb-4">
            <Colxx xxs="6">
              <LoaderAnalyticsSingle />
            </Colxx>
            <Colxx xxs="6">
              <LoaderAnalyticsSingle />
            </Colxx>
          </Row>
          <CardTitle>{messages['admin.reports.esg.topSections']}</CardTitle>
          <Row className="w-100">
            <Colxx xxs="12">
              <LoaderAnalytics />
              <LoaderAnalytics />
              <LoaderAnalytics />
              <LoaderAnalytics />
            </Colxx>
          </Row>
        </CardBody>
      </Card>
    );
  }

  const flightOrders = filter(orders, (o) => {
    return o.type === OrderTypes.FLIGHT;
  });

  let routesList = {};
  forEach(flightOrders, (order) => {
    forEach(order.journeys, (journey) => {
      const stations = [
        journey.departureStation.iataCode,
        journey.arrivalStation.iataCode,
      ].sort();

      const name = `${stations[0]} - ${stations[1]}`;

      routesList[name] = {
        departure: stations[0],
        arrival: stations[1],
        name,
        qtd: 0,
        ...routesList[name],
      };

      routesList[name].qtd = routesList[name].qtd + order.esg.carbon_kg;
    });
  });

  routesList = slice(orderBy(routesList, 'qtd', ['desc']), 0, 5);

  return (
    <Card className="dashboard-small-chart h-100">
      <CardBody>
        <Row className="w-100 mb-4">
          <Colxx xxs="6">
            <p className="lead color-theme-1 mb-1 value">
              {sumBy(flightOrders, (o) => parseFloat(o.esg.carbon_kg)).toFixed(
                0
              )}{' '}
              Kg
            </p>
            <p className="text-muted">
              {(
                (sumBy(flightOrders, (o) => parseFloat(o.esg.carbon_kg)) /
                  sumBy(orders, (o) => parseFloat(o.esg.carbon_kg))) *
                100
              ).toFixed(0)}
              % do total emitido
            </p>
          </Colxx>
          <Colxx xxs="6">
            <p className="lead color-theme-1 mb-1 value">
              {sumBy(flightOrders, (o) =>
                parseFloat(o.esg.distance_km)
              ).toFixed(0)}{' '}
              Km
            </p>
            <p className="text-muted">Viajados no período</p>
          </Colxx>
        </Row>
        <CardTitle>{messages['admin.reports.esg.topSections']}</CardTitle>
        <Row className="w-100">
          <Colxx xxs="12">
            {map(routesList, (route) => {
              return (
                <div
                  key={route.name}
                  className="d-flex flex-row mb-3 pb-3 justify-content-between align-items-center"
                >
                  <div className="flex-fill">
                    <div className="trip-info d-flex justify-content-left align-items-center text-dark">
                      <div className="text-right">
                        {normalizeCity(route.departure)}
                      </div>
                      <RoundTripArrow className="round-trip-arrow" />
                      {normalizeCity(route.arrival)}
                    </div>
                    {/* <p className="font-weight-medium mb-0">{route.name}</p> */}
                  </div>
                  <div className="pr-2">{route.qtd.toFixed(0)} Kg</div>
                </div>
              );
            })}
          </Colxx>
        </Row>
      </CardBody>
    </Card>
  );
}
