import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import firebaseApp from '../../../services/Firebase';
import {
  getDocument,
  getDocumentsFromCollection,
} from '../../../utils/firebase';
import Spinner from '../../common/Spinner';

export default function ReplaceUserModal({
  cardId,
  setHeight,
  replaceUser,
  closeModal,
}) {
  const { messages, formatMessage } = useIntl();
  const { organization } = useSelector(({ auth }) => auth);

  // States
  const [loading, setLoading] = useState(true);
  const [description, setDescription] = useState('');
  const [hasActiveBudgets, setHasActiveBudgets] = useState(false);

  // Effects
  useEffect(() => {
    verifyBudgets();
    setHeight(150);
  }, []);

  // Functions
  const verifyBudgets = async () => {
    try {
      setLoading(true);

      const budgets = await getDocumentsFromCollection(() =>
        firebaseApp.getInActionBudgetsFromCardId(organization.id, cardId).get()
      );

      if (!budgets.length) return setLoading(false);

      const passenger = await getDocument(() =>
        firebaseApp.getUserFromId(budgets[0].passengerId).get()
      );

      setDescription(
        formatMessage(
          {
            id: 'refund.card.active-budgets',
          },
          {
            passenger: `${passenger.firstName} ${passenger.lastName}`,
          }
        )
      );
      setHasActiveBudgets(true);
      setLoading(false);
    } catch (err) {
      console.error(err);

      setDescription(messages['refund.card.active-budgets.error']);
      setHasActiveBudgets(true);
    }
  };

  // Render
  const renderReplace = () => (
    <>
      <p className="description text-center px-5" style={{ lineHeight: 1.5 }}>
        {messages['refund.card.replace_user']}
      </p>

      <div className="text-center mt-4">
        <Button onClick={replaceUser} color="primary" className="pretty-btn">
          {messages['refund.card.replace_user.action']}
        </Button>
      </div>
    </>
  );

  const renderActive = () => (
    <>
      <p className="description text-center px-5" style={{ lineHeight: 1.5 }}>
        {description}
      </p>

      <div className="text-center mt-4">
        <Button onClick={closeModal} color="primary" className="pretty-btn">
          {messages['general.close']}
        </Button>
      </div>
    </>
  );

  const renderContent = () =>
    hasActiveBudgets ? renderActive() : renderReplace();

  const renderLoading = () => (
    <Spinner relative style={{ width: 70, height: 70 }} />
  );

  return <div>{loading ? renderLoading() : renderContent()}</div>;
}
