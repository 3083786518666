import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { convertToLabelValueWithMessages } from 'src/portao3-legacy/utils/others';
import GenericParamsOptions from './GenericParamsOptions';

import { registeredBus } from 'src/portao3-legacy/constants/travelValues';
import { BusSeats } from 'src/portao3-legacy/constants/searchParams';
import { OrderTypes } from 'src/portao3-legacy/constants/orderTypes';

export default function BusParamsOptions({ className, initialData }) {
  const { messages } = useIntl();

  // States
  const params = useMemo(() => {
    return [
      {
        key: 'carriers',
        title: messages['admin.travels.params.bus.carriers'],
        options: convertToLabelValueWithMessages({
          items: Object.keys(registeredBus),
        }),
      },
      {
        key: 'seatClasses',
        title: messages['admin.travels.params.bus.categories'],
        options: convertToLabelValueWithMessages({
          items: BusSeats,
        }),
      },
    ];
  }, []);

  // Render
  return (
    <GenericParamsOptions
      className={className}
      paramType={OrderTypes.BUS}
      params={params}
      initialData={initialData}
      title={messages['travel.menu.bus']}
    />
  );
}
