import React, { useMemo } from 'react';
import { DndProvider } from 'react-dnd';
import { isTouchDevice } from '../utils/screen';

const getDndBackend = () => {
  if (isTouchDevice) {
    const { TouchBackend } = require('react-dnd-touch-backend');
    return TouchBackend;
  } else {
    const { HTML5Backend } = require('react-dnd-html5-backend');
    return HTML5Backend;
  }
};

export default function DndWrapper({ children }) {
  const dndBackedn = useMemo(() => getDndBackend(), []);

  return <DndProvider backend={dndBackedn}>{children}</DndProvider>;
}
