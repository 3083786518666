import { useMemo } from 'react';
import { filterItems, sortQueries } from './utils';

import { TFilterQuery } from './types';

interface IProps<I, P> {
  queries: TFilterQuery<I, P>[];
  items: I[];
  params: P;
  formatParams?: (params: P) => P;
  sortItems?: (items: I[]) => I[];
}

export default function useFilterItems<I, P>({
  queries,
  items,
  params,
  formatParams = (data) => data,
  sortItems = (data) => data,
}: IProps<I, P>) {
  const sortedQueries = useMemo(() => sortQueries(queries), [queries]);

  const filteredItems = useMemo(
    () => sortItems(filterItems(sortedQueries, items, params, formatParams)),
    [sortedQueries, items, params, formatParams]
  );

  return filteredItems;
}
