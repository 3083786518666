import moment from 'moment';
import { parse } from 'date-fns';

export function formatYYYYMMDD(
  value,
  inputFormat = 'YYYY-MM-DD',
  outputFormat = 'DD/MM/YYYY'
) {
  return moment(value, inputFormat).format(outputFormat);
}

export function formatUnix(unix, outputFormat = 'DD-MM-YYYY') {
  return moment.unix(unix).format(outputFormat);
}

export function formatDate(date) {
  const numericValue = date.replace(/\D/g, '');

  const formatted = numericValue.replace(
    /^(\d{2})(\d{0,2})(\d{0,4}).*/,
    (match, day, month, year) => {
      if (!day) return '';
      if (!month) return day;
      return `${day}/${month}${year ? `/${year}` : ''}`;
    }
  );

  const parsedInputdate = parse(date, 'dd/MM/yyyy', new Date());

  return { parsedInputdate, formatted };
}
