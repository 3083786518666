import React, { useMemo } from 'react';
import { Card, CardBody, CardTitle, Row } from 'reactstrap';

import { stringToMoney } from '../../utils/money';
import { RefundTypes } from '../../constants/refundTypes';
import { useIntl } from 'react-intl';
import SpendGraph from './dashboard/SpendGraph';
import { Colxx } from '../../components/common/CustomBootstrap';

const CARD_ORDER = [
  RefundTypes.PHYSICAL_CARD,
  RefundTypes.VIRTUAL_CARD,
  RefundTypes.PERSONAL_ACCOUNT,
  RefundTypes.ADMINISTRATIVE_ACCOUNT,
];

function ExpenseCardInfo({ type, data = {} }) {
  const { messages, formatMessage } = useIntl();

  const { total = '', creditCardUsedMsg = '' } = useMemo(() => {
    return {
      total: stringToMoney(data.amount || 0, 2),
      creditCardUsedMsg:
        data.creditCardsUsed > 1
          ? formatMessage(
              { id: 'refund.card.multiple-used' },
              { quantity: data.creditCardsUsed }
            )
          : data.creditCardsUsed === 1
          ? messages['refund.card.single-used']
          : messages['refund.card.none-used'],
    };
  }, [data]);

  return (
    <div className="expense-card-info d-flex align-items-center justify-content-between my-3">
      <div>
        <h4 className="m-0 p-0 font-weight-medium font-primary title">
          {messages[`refund.payment-method.${type}.title`]}
        </h4>
        <p className="m-0 p-0 text-muted num-cards">{creditCardUsedMsg}</p>
      </div>
      <div>
        <p className="m-0 p-0 total">{total}</p>
      </div>
    </div>
  );
}

export default function SmallLineSpent({ data, budgets }) {
  const { messages } = useIntl();

  return (
    <Card className="dashboard-small-chart h-100 p-3">
      <CardBody>
        <CardTitle className="d-block mb-3">
          {messages['refund.card.cards-used']}
        </CardTitle>
        <Row className="w-100">
          <Colxx xxs="6">
            <SpendGraph sortedBudgets={data} budgets={budgets} height={250} />
          </Colxx>
          <Colxx xxs="6" className="d-flex align-items-center w-100 pr-0">
            <div className="d-block w-100">
              {CARD_ORDER.map((type) => (
                <ExpenseCardInfo key={type} type={type} data={data[type]} />
              ))}
            </div>
          </Colxx>
        </Row>
      </CardBody>
    </Card>
  );
}
