import React, { Fragment } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { Row, Nav, NavItem } from 'reactstrap';
import classnames from 'classnames';
import IntlMessages from '../../helpers/IntlMessages';
import { Colxx, Separator } from '../../components/common/CustomBootstrap';
import Breadcrumb from '../../containers/navs/Breadcrumb';

import CostCenterDataListView from '../../containers/policy/CostCenterDataListView';
import ProjectDataListView from '../../containers/policy/ProjectDataListView';
import MotivesDataListView from '../../containers/policy/MotivesDataListView';
import TagsDataListView from '../../containers/policy/TagsDataListView';
import ObservationsDataListView from '../../containers/policy/ObservationsDataListView';
import GroupDataListView from '../../containers/policy/GroupDataListView';

export default function Policy(props) {
  const { tabId } = useParams();

  // Render
  return (
    <Fragment>
      <Row>
        <Colxx xxs="12">
          <Breadcrumb heading="admin.policy.title" match={props.match} />
          <Separator className="mb-5" />
        </Colxx>
      </Row>
      <Row>
        <Colxx xxs="12" className="mb-4">
          <div>
            <Nav className="admin-tabs">
              <NavItem>
                <NavLink
                  to="/app/management/cost_centers"
                  className={classnames({
                    active: !tabId || tabId === 'cost_centers',
                    'nav-link': true,
                  })}
                >
                  <IntlMessages id="admin.policy.costCenters" />
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  to="/app/management/projects"
                  className={classnames({
                    active: tabId === 'projects',
                    'nav-link': true,
                  })}
                >
                  <IntlMessages id="admin.policy.projects" />
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  to="/app/management/motives"
                  className={classnames({
                    active: tabId === 'motives',
                    'nav-link': true,
                  })}
                >
                  <IntlMessages id="admin.policy.motives" />
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  to="/app/management/tags"
                  className={classnames({
                    active: tabId === 'tags',
                    'nav-link': true,
                  })}
                >
                  <IntlMessages id="admin.policy.tags" />
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  to="/app/management/observations"
                  className={classnames({
                    active: tabId === 'observations',
                    'nav-link': true,
                  })}
                >
                  <IntlMessages id="admin.policy.observations" />
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  to="/app/management/groups"
                  className={classnames({
                    active: tabId === 'groups',
                    'nav-link': true,
                  })}
                >
                  <IntlMessages id="admin.policy.groups" />
                </NavLink>
              </NavItem>
            </Nav>

            {!tabId || tabId === 'cost_centers' ? (
              <CostCenterDataListView />
            ) : null}
            {tabId === 'projects' ? <ProjectDataListView /> : null}
            {tabId === 'motives' ? <MotivesDataListView /> : null}
            {tabId === 'tags' ? <TagsDataListView /> : null}
            {tabId === 'observations' ? <ObservationsDataListView /> : null}
            {tabId === 'groups' ? <GroupDataListView /> : null}
          </div>
        </Colxx>
      </Row>
    </Fragment>
  );
}
