import React, { useMemo } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import StatusInfo from './StatusInfo';
import CancellationInfo from './CancellationInfo';
import OrderStatusHeader from './OrderStatusHeader';
import AlterationInfo from './AlterationInfo';
import OrderStatusTitle from './OrderStatusTitle';

export default function OrderStatusFooter({
  order,
  approvals,
  goForApproval,
  goForCancellation,
  goForAlteration,
  toggleModal,
  reservations,
  cancellations = [],
  DetailsComponent,
  canAlter,
  canCancel,
  showCancelButton,
  goForOpenBookingSteps,
  shouldHideOpenBookingSteps,
}) {
  // State
  const showLastHr = useMemo(() => {
    return !(((canAlter || canCancel) && goForAlteration) || showCancelButton);
  }, [canAlter, canCancel, goForAlteration, showCancelButton]);

  // Render
  return (
    <div className="description h-100 d-flex align-items-center">
      <OrderStatusHeader order={order} reservations={reservations} />

      <PerfectScrollbar
        options={{
          suppressScrollX: true,
          wheelPropagation: false,
        }}
        className="h-auto mh-100 py-3"
      >
        <OrderStatusTitle
          order={order}
          reservations={reservations}
          DetailsComponent={DetailsComponent}
        />

        <div className="text order-status">
          <div className="my-3">
            <hr />
            <div className="my-3">
              <StatusInfo
                order={order}
                approvals={approvals}
                goForApproval={goForApproval}
                toggleModal={toggleModal}
                goForOpenBookingSteps={goForOpenBookingSteps}
                shouldHideOpenBookingSteps={shouldHideOpenBookingSteps}
              />
            </div>
            {showLastHr ? <hr /> : null}
          </div>

          {!order.openBooking && (canAlter || canCancel) && goForAlteration ? (
            <AlterationInfo
              order={order}
              goForAlteration={goForAlteration}
              cancellations={cancellations}
              toggleModal={toggleModal}
            />
          ) : null}

          <CancellationInfo
            order={order}
            goForCancellation={goForCancellation}
            toggleModal={toggleModal}
            cancellations={cancellations}
            showCancelButton={showCancelButton}
          />
        </div>
      </PerfectScrollbar>
    </div>
  );
}
