import classNames from 'classnames';
import useShowToggle from 'src/portao3-legacy/hooks/useShowToggle';
import { Button, Collapse } from 'reactstrap';

export default function CustomCollapse({
  className,
  children,
  title,
  loading,
}) {
  const [isOpen, _, toggleIsOpen] = useShowToggle();

  // Render
  return (
    <div className={classNames('border', className)}>
      <section>
        <Button
          color="link"
          onClick={!loading && toggleIsOpen}
          aria-expanded={isOpen}
        >
          {title}
        </Button>
      </section>

      <Collapse isOpen={isOpen}>
        <section className="px-4 py-2">{children}</section>
      </Collapse>
    </div>
  );
}
