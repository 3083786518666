import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import classnames from 'classnames';
import { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import appFunctions from '../../services/Functions';
import debounce from 'lodash/debounce';

import { locationTypes } from '../../constants/travelValues';

const _invalidTypes = {
  administrative_area_level_1: true,
};

function findCity(address_components = []) {
  let cityIndex = address_components.findIndex(({ types }) =>
    types.includes('administrative_area_level_2')
  );

  if (cityIndex === -1) {
    cityIndex = address_components.findIndex(({ types }) =>
      types.includes('locality')
    );
  }

  return cityIndex !== -1
    ? address_components
        .slice(cityIndex)
        .filter(({ types }) => !types.includes('postal_code'))
        .map(({ long_name }) => long_name)
        .join(', ')
    : null;
}

const Option = (props) => {
  const locationClass = locationTypes[props.data.type] || locationTypes.other;

  return (
    <div
      className={classnames({
        'react-select__menu_option': true,
        active: props.isFocused,
      })}
    >
      <div className={`option-icon ${locationClass.style}`} />
      <components.Option {...props} />
    </div>
  );
};

export default function GoogleLocationSearch({
  value,
  setValue,
  invalidTypes = _invalidTypes,
}) {
  const { messages } = useIntl();

  // Functions
  const findLocation = useCallback(
    debounce(async ({ inputValue, callback, invalidTypes = {} }) => {
      let options = [];

      try {
        if (inputValue) {
          const { data } = await appFunctions.getAddress(inputValue);

          options = data
            .filter(
              ({ types }) => !types.some((value) => value in invalidTypes)
            )
            .map(
              ({
                place_id,
                formatted_address,
                geometry,
                address_components,
                types,
              }) => {
                const countryComponent = address_components.find(({ types }) =>
                  types.includes('country')
                );

                return {
                  value: place_id,
                  label: formatted_address,
                  location: geometry.location,
                  country: countryComponent?.short_name || null,
                  city: findCity(address_components),
                  type: types[0],
                };
              }
            );
        }
      } catch (err) {
        console.error(
          { message: 'Unable to get google location', location: inputValue },
          err
        );
      } finally {
        callback(options);
      }
    }, 500),
    []
  );

  const handleLoadOptions = (inputValue) =>
    new Promise((callback) =>
      findLocation({
        inputValue,
        callback,
        invalidTypes,
      })
    );

  // Render
  return (
    <AsyncSelect
      className="react-select"
      classNamePrefix="react-select"
      components={{ Option }}
      value={value}
      isClearable={false}
      cacheOptions
      placeholder=""
      noOptionsMessage={() => {
        return messages['travel.location-message'];
      }}
      loadingMessage={() => {
        return messages['travel.location-searching'];
      }}
      onChange={(selects) => {
        setValue(selects);
      }}
      loadOptions={handleLoadOptions}
    />
  );
}
