import React from 'react';
import { Row, Button } from 'reactstrap';
import { Colxx } from '../common/CustomBootstrap';

export default function KYCModalCompanyTab({ onGoNext }) {
  return (
    <div className="p-4">
      <Row>
        <Colxx xxs="12" className="mb-4 text-center">
          <img src="/assets/img/onboarding/onboarding-1.svg" className="w-40" />
        </Colxx>
      </Row>
      <Row>
        <Colxx xxs="12" className="mb-4 text-center">
          <h5>Estamos quase prontos!</h5>
          <p className="mt-4 mb-4">
            Precisamos de mais algumas informações suas para liberar a
            plataforma para a sua empresa.
          </p>
          <Button color="primary" onClick={onGoNext}>
            Preencher informações
          </Button>
        </Colxx>
      </Row>
    </div>
  );
}
