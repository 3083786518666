import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import RouteStop from './RouteStop';
import SelectedRouteInfo from './SelectedRouteInfo';

export default function ExpenseKmContent({
  stops,
  setStops,
  onStopCreation,
  onStopDelete,
  onSwapItem,
  editable,
  selectedRoute,
}) {
  const { messages } = useIntl();

  const [length, lastStopIndex, canDelete] = useMemo(() => {
    const length = stops.length;

    return [length, length - 1, length > 2];
  }, [stops]);

  // Functions
  // const deleteStop = (_, index) => {
  //   const newItems = [...stops];
  //   newItems.splice(index, 1);
  //   setStops(newItems);
  // };

  // const changeStopPosition = (index, newIndex) => {
  //   const newStops = [...stops];
  //   const [item] = newStops.splice(index, 1);

  //   if (newIndex === 0) {
  //     setStops([item, ...newStops]);
  //   } else if (newIndex === length - 1) {
  //     setStops([...newStops, item]);
  //   } else {
  //     setStops([...newStops.slice(0, newIndex), item, ...newStops.slice(newIndex)]);
  //   }
  // };

  // Render
  return (
    <div className="expense-km-content mt-4 pt-2">
      <div
        className="d-flex justify-content-between align-items-center mx-3 mb-3"
        style={{ zIndex: 100 }}
      >
        <div className="d-flex align-items-center w-50">
          <h5 className="m-0">{messages['refund.modal.km.items']}</h5>
        </div>
      </div>
      <div className="route-stops mx-4">
        <div className="routes position-relative">
          {stops.map((stop, index) => (
            <RouteStop
              key={`stop-${index}`}
              stop={stop}
              onChange={(stop) => onStopCreation(stop, index)}
              onDelete={() => onStopDelete(index)}
              onSwap={onSwapItem}
              isStart={index === 0}
              isEnd={index === lastStopIndex}
              style={{ zIndex: 15 - index }}
              editable={editable}
              index={index}
              canDelete={canDelete}
            />
          ))}
          <div className="route-line-container d-flex align-items-center justify-content-center py-2">
            <div className="route-line" />
          </div>
        </div>
        {editable && length < 12 ? (
          <div className="position-relative new-stop-container">
            <RouteStop
              canDelete={false}
              editable={true}
              onChange={(stop) => onStopCreation(stop)}
              placeholder={messages['refund.km-expense.new-stop']}
              className="new-stop"
              keepAddress={false}
            />
            <div className="route-line-container d-flex align-items-center justify-content-center">
              <div className="route-line" />
            </div>
          </div>
        ) : null}
      </div>
      {selectedRoute ? (
        <SelectedRouteInfo
          selectedRoute={selectedRoute}
          className="mx-4 d-flex align-item-center"
          itemClassName="mr-4"
        />
      ) : null}
    </div>
  );
}
