import moment from 'moment';
import recordStatus from '../constants/recordStatus';
import firebaseApp from '../services/Firebase';

let lastUpdated = null;

const shouldRenewTags = async () => {
  return moment().isAfter(moment(lastUpdated).add(1, 'days'));
};

const getNewTags = async (orgId, onlyActive = false) => {
  let snap = firebaseApp.getTagsFromOrganization(orgId);

  if (onlyActive) snap = snap.where('status', '==', recordStatus.ACTIVE);

  return await snap.get({ source: 'server' });
};

export const getTags = async (orgId, onlyActive = false) => {
  const storageSnapshot = await firebaseApp
    .getTagsFromOrganization(orgId)
    .get({ source: 'cache' });

  if (storageSnapshot.exists) {
    const shouldRenew = shouldRenewTags();
    if (shouldRenew) {
      lastUpdated = moment();
      return await getNewTags(orgId, onlyActive);
    }

    return storageSnapshot;
  }

  return await getNewTags(orgId, onlyActive);
};

export const loadTags = async (orgId, onlyActive = false) => {
  const motiviesSnap = await getTags(orgId, onlyActive);

  const tags = [];
  motiviesSnap.forEach((tagSnap) => {
    tags.push({
      value: tagSnap.id,
      label: tagSnap.data().name,
    });
  });

  return tags;
};
