const msg_type = {
  CARD_REFUND: 1400,
  CARD_REVERSAL: 1420,
  CARD_TRANSACTION: 1100,
  CREDIT_CLEARING: 1120,
  P2P_TRANSFER: 1220,
  FUNDS_TRANSFER: 1600,
};

const txn_type = {
  GOODS_PURCHASE: 0,
  CASH_WITHDRAWAL: 1,
  BALANCE_ENQUIRY: 2,
  PIN_CHANGE: 3,
  PIN_ACTIVATION: 4,
  FINANCIAL_TRANSFER: 5,
  RECONCILIATION_ADVICE: 6,
  SIGN_ON: 7,
  SIGN_OFF: 8,
  CUTOVER: 9,
  KEY_EXCHANGE: 10,
  ECHO_TEST: 11,
  CREDIT: 12,
  DEBIT: 13,
  RETURN: 14,
  RESERVATION: 15,
  CASH_ADVANCE: 16,
  PURCHASE_PHONE_AUTHORISED: 17,
  PURCHASE_FORCED_ACCEPTANCE: 18,
  PURCHASE_PHONE_ORDERED: 19,
  AUTHORISATION_PURCHASE: 20,
  PURCHASE_MAIL_ORDERED: 21,
  RECURRING: 22,
  PURCHASE_WITH_CASHBACK: 23,
  CARD_VALUE_LOAD: 24,
  PRE_AUTHORISATION: 25,
  ONLINE_ADVICE: 26,
  REVERSAL: 27,
  RESERVATION_CANCEL: 28,
  RESERVATION_ADJUSTMENT: 29,
  CONFIRM_PHONE_RESERVATION: 30,
  PRE_AUTHORISATION_EXTENSION: 31,
  PRE_AUTHORISATION_ADJUSTMENT: 32,
  PRE_AUTHORISATION_CAPTURE: 33,
  DATA_EXCHANGE: 34,
  OFFLINE_ADVICE: 35,
  ACCOUNT_FUNDING: 36,
  QUASI_CASH: 37,
  ELIGIBILITY_INQUIRY: 38,
  ACCOUNT_TRANSFER: 39,
  PIN_UNBLOCK: 40,
  CHEQUE_GUARANTEE: 41,
  AFT: 42,
  PAYMENT_REQUEST: 43,
  ACCOUNT_VERIFICATION: 44,
  TOKEN_AUTHORISATION_REQUEST: 45,
  TOKEN_ELIGIBILITY_REQUEST: 46,
  TOKEN_ACTIVATION_CODE_NOTIFICATION: 47,
  TOKEN_COMPLETION_NOTIFICATION: 48,
  TOKEN_EVENT_NOTIFICATION: 49,
  ATM: 50,
  SCRIPT_ISSUE: 51,
  DEPOSIT: 52,
  CHEQUE_DEPOSIT: 53,
  ORIGINAL_CREDIT: 54,
  E_COMMERCE: 55,
  ATC_UPDATE: 56,
  SESSION_UP: 57,
  SESSION_DOWN: 58,
  BALANCE_ENQUIRY_WITH_FEES: 63,
  EXPIRY_TRANSACTION: 68,
  CREDIT_ADJUSTMENT: 73,
  DEBIT_ADJUSTMENT: 74,
  REAL_TIME_FUNDING_DEBIT: 76,
  REAL_TIME_FUNDING_CREDIT: 77,
};

/**
 * Verifica se essa transação creditou um valor de uma conta
 *
 * @param {*} transaction
 * @returns {boolean} Se a transação é de crédito
 */
exports.isCreditTransaction = (transaction) => {
  return (
    transaction.msg_type === msg_type.CARD_REFUND ||
    transaction.msg_type === msg_type.CARD_REVERSAL ||
    (transaction.msg_type === msg_type.CARD_TRANSACTION &&
      transaction.txn_type === txn_type.RETURN) ||
    (transaction.msg_type === msg_type.P2P_TRANSFER &&
      transaction.txn_type === txn_type.CREDIT) ||
    (transaction.msg_type === msg_type.CREDIT_CLEARING &&
      transaction.txn_type === txn_type.RETURN) ||
    (transaction.msg_type === msg_type.CREDIT_CLEARING &&
      transaction.txn_type === txn_type.EXPIRY_TRANSACTION) ||
    (transaction.msg_type === msg_type.FUNDS_TRANSFER &&
      transaction.txn_type !== txn_type.CREDIT_ADJUSTMENT)
  );
};

/**
 * Verifica se essa transação creditou um valor de uma conta
 *
 * @param {*} transaction
 * @returns {boolean} Se a transação é de crédito
 */
exports.isReversalTransaction = (transaction) => {
  return (
    transaction.msg_type === msg_type.CARD_REFUND ||
    transaction.msg_type === msg_type.CARD_REVERSAL ||
    (transaction.msg_type === msg_type.CARD_TRANSACTION &&
      transaction.txn_type === txn_type.RETURN) ||
    (transaction.msg_type === msg_type.CREDIT_CLEARING &&
      transaction.txn_type === txn_type.RETURN) ||
    (transaction.msg_type === msg_type.CREDIT_CLEARING &&
      transaction.txn_type === txn_type.EXPIRY_TRANSACTION)
  );
};

/**
 * Verifica se essa transação creditou um valor de uma conta
 *
 * @param {*} transaction
 * @returns {boolean} Se a transação é de crédito
 */
exports.isFinancialImpactTypeCredit = (transaction) => {
  return transaction.financial_impact_type.toUpperCase() === 'CREDIT';
};

/**
 * Verifica se essa transação creditou um valor de uma conta
 *
 * @param {*} transaction
 * @returns {boolean} Se a transação é de crédito
 */
exports.isFinancialImpactTypeDebit = (transaction) => {
  return transaction.financial_impact_type.toUpperCase() === 'DEBIT';
};
