import React, { useEffect, useState } from 'react';

import { ReactComponent as AddIcon } from '../../assets/img/icons/plus.svg';
import { ReactComponent as HotelIcon } from '../../assets/img/icons/product/hotel.svg';
import { ReactComponent as CarIcon } from '../../assets/img/icons/product/car.svg';
import { getCityFromLocations } from '../../utils/locations';
import moment from 'moment';
import { getArrivalJourney } from '../../constants/flight';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';

const generateUrl = (activeTab, search) => {
  const params = encodeURIComponent(
    JSON.stringify({ activeTab, search, autoSearch: true })
  );
  return `/travel/search/${activeTab}?params=${params}&timestamp=${moment().unix()}`;
};

function RoundButton({
  icon: Icon,
  label,
  onClick = () => {},
  className = '',
  iconStyle = '',
  ...others
}) {
  return (
    <div
      className={`round-btn d-flex align-items-center justify-content-center rounded pointer ${className}`}
      {...others}
      onClick={onClick}
    >
      <Icon className={`icon ${iconStyle}`} width={15} height={15} />
      <span className="text-primary font-weight-semibold">{label}</span>
    </div>
  );
}

export default function AssociateServices({
  order,
  show,
  toggleShow,
  reservation,
}) {
  const [cityPromise, setCityPromise] = useState(null);
  const history = useHistory();

  const { messages } = useIntl();

  useEffect(() => {
    if (show && !cityPromise) fetchCity();
  }, [show]);

  const fetchCity = async () => {
    if (!cityPromise) {
      const { arrivalStation } = order.journeys[0];

      setCityPromise(getCityFromLocations(arrivalStation.name));
    }
  };

  const getMoment = (date) => {
    return moment(`${date} 08:00`, 'YYYY-MM-DD hh:mm');
  };

  const getSearchObj = (type, city, outboundDate, inboundDate) => {
    const destinationId = type === 'hotel' ? 'destination' : 'origin';

    return {
      origin: {},
      destination: {},
      hotel_params: {
        adults: 1,
        rooms: 1,
      },
      flightParams: {
        baggages: 0,
        cabin: 'Economy',
      },
      twoWay: true,
      dropOffSamePickUp: true,
      [destinationId]: city,
      inbound_date: getMoment(inboundDate),
      outbound_date: getMoment(outboundDate),
    };
  };

  const makeSearch = async (type) => {
    const city = await cityPromise;
    if (city) {
      const arrivalJourney = getArrivalJourney(order.journeys, true);

      if (arrivalJourney) {
        const [inboundJourney, index] = arrivalJourney;
        const outboundDate = reservation.flightDetails.arrivalDateTime
          .split(' ')[0]
          .split('/')
          .reverse()
          .join('-');
        const searchObj = getSearchObj(
          type,
          city,
          outboundDate,
          inboundJourney.departureDate
        );
        history.push(generateUrl(type, searchObj));
      }
    }
  };

  return (
    <div
      className={`associate-services d-flex align-items-center ${
        show ? 'show' : 'hide'
      }`}
    >
      <div
        className="add-btn d-flex justify-content-center align-items-center pointer"
        onClick={toggleShow}
      >
        <AddIcon className="add-icon" />
      </div>
      <div className="buttons d-flex ml-3">
        <RoundButton
          icon={HotelIcon}
          label={messages['travel.menu.hotel']}
          className="mr-2"
          onClick={() => makeSearch('hotel')}
        />
        <RoundButton
          icon={CarIcon}
          label={messages['travel.menu.car']}
          onClick={() => makeSearch('car')}
          style={{ transitionDelay: '100ms' }}
        />
      </div>
    </div>
  );
}
