import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Row } from 'reactstrap';
import { Colxx } from '../../components/common/CustomBootstrap';
import DateSelection from '../../components/reports/DateSelection';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import OrderCard from '../../components/reports/OrderCard';
import OrderModal from '../timeline/OrderModal';
import Pagination from '../pages/Pagination';
import NoResults from '../../components/search/NoResults';
import PrintDropdown from '../../components/common/PrintDropdown';
import {
  generateReportFile,
  REPORT_FILE_TYPES,
  REPORT_PRINT_TYPES,
} from '../../utils/reports';
import { downloadBlob } from '../../utils/fileGeneration';
import useLazyDataLoader from '../../hooks/useLazyDataLoader';
import firebaseApp from '../../services/Firebase';
import LoaderCardColumns from '../../components/loaders/LoaderCardColumns';
import { generateFilterFunctions } from '../../utils/filters';
import { FILTER_TYPES } from '../../constants/filters';
import InputFilter from '../../components/reports/filters/InputFilter';
import TravelCreditDataInfo from './TravelCreditDataInfo';
import { formatOrders, formatToReportOrders } from './utils';
import useDataArchive from '../../hooks/useDataArchive';

export default function TravelCreditDataListView({
  itemsPerPage = 15,
  path = '/app/reports/summary',
}) {
  const { orderId } = useParams();
  const { organization, user } = useSelector(({ auth }) => auth);
  const { messages } = useIntl();
  const history = useHistory();

  const { getDataFromArchive } = useDataArchive({}, false);

  // States
  const [dateSelection, setDateSelection] = useState({
    mode: 'month',
    startDate: moment().startOf('month').unix(),
    endDate: moment().endOf('month').unix(),
  });

  const [orders, isLoading] = useLazyDataLoader({
    fetchFunction: () =>
      firebaseApp.getCreditsOrdersFromOrganization(user.organizationId),
    interval: dateSelection,
    dateField: 'creditCreatedAt',
    formatFunction: (data) => formatOrders(data, getDataFromArchive),
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState([]);

  const indexInterval = useMemo(() => {
    const startAt = (currentPage - 1) * itemsPerPage;
    return [startAt, startAt + itemsPerPage];
  }, [currentPage]);

  const DETAIL_TYPES = useMemo(
    () => [
      {
        value: REPORT_PRINT_TYPES.SIMPLE,
        label: messages['reports.details.simple'],
      },
      {
        value: REPORT_PRINT_TYPES.DETAILED,
        label: messages['reports.details.detailed'],
      },
    ],
    []
  );

  const filterFunctions = useMemo(
    () => (filters.length > 0 ? generateFilterFunctions(filters) : []),
    [filters]
  );

  const config = useMemo(
    () => [
      {
        type: FILTER_TYPES.ARRAY,
        field: 'type',
        paramLabel: 'Tipos de reserva',
        formatResult: (values) =>
          values.map((value) => ({
            value,
            label: messages[`travel.menu.${value}`],
          })),
        filterFunctionConfig: {
          valueKey: 'value',
        },
      },
      {
        type: FILTER_TYPES.RANGE,
        field: 'fare',
        paramLabel: 'Total',
        getField: ({ total = 0 }) => total,
      },
      {
        type: FILTER_TYPES.OBJECT,
        field: 'project',
        key: 'label',
        paramLabel: 'Projeto',
        formatResult: (value) => Object.values(value),
        filterFunctionConfig: {
          getField: (obj) => obj.label,
        },
      },
      {
        type: FILTER_TYPES.OBJECT,
        field: 'costCenter',
        key: 'label',
        paramLabel: 'Centro de custo',
        formatResult: (value) => Object.values(value),
        filterFunctionConfig: {
          getField: (obj) => obj.label,
        },
      },
      {
        type: FILTER_TYPES.ARRAY,
        paramName: 'approvers',
        field: 'filter',
        key: 'label',
        options: { multiple: true },
        getField: ({ approvers = [] }) => approvers,
        paramLabel: 'Aprovador',
        formatResult: (values) =>
          values.map((value) => ({
            value,
            label: value,
          })),
      },
      {
        type: FILTER_TYPES.ARRAY,
        paramName: 'passengerName',
        field: 'filter',
        key: 'label',
        getField: ({ passengerName = '' }) => passengerName,
        paramLabel: 'Nome do passageiro',
        formatResult: (values) =>
          values.map((value) => ({
            value,
            label: value,
          })),
      },
      {
        type: FILTER_TYPES.ARRAY,
        field: 'statusCode',
        formatResult: (values) =>
          values
            .filter((value) => value !== 150)
            .map((value) => ({
              value,
              label: messages[`reports.filter.status-code.${value}`],
            })),
        paramLabel: 'Status da reserva',
        filterFunctionConfig: {
          valueKey: 'value',
        },
      },
      {
        type: FILTER_TYPES.BOOLEAN,
        field: 'wasUsed',
        formatResult: (values) =>
          values.map((value) => ({
            value,
            label: messages[value ? 'general.yes' : 'general.no'],
          })),
        paramLabel: 'Foi utilizado',
        filterFunctionConfig: {
          valueKey: 'value',
        },
      },
    ],
    []
  );

  // Effects
  useEffect(() => {
    setCurrentPage(1);
  }, [orders, dateSelection]);

  useEffect(() => {
    if (showFilters) setShowFilters(false);
  }, [dateSelection]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [currentPage]);

  // Date Filters
  const setCustomDate = (start, end) => {
    setDateSelection({
      startDate: moment(start).startOf('day').unix(),
      endDate: moment(end).endOf('day').unix(),
    });
  };

  const filteredOrders = useMemo(() => {
    if (filterFunctions.length > 0) {
      return orders.filter((order) => filterFunctions.every((f) => f(order)));
    } else return orders;
  }, [orders, filterFunctions]);

  // Functions
  const handleFileGeneration = async (type, details) => {
    try {
      if (filteredOrders.length) {
        const isDetailed = details.value === REPORT_PRINT_TYPES.DETAILED;
        const reportOrders = await formatToReportOrders(
          filteredOrders,
          getDataFromArchive
        );

        const blob = await generateReportFile({
          orders: reportOrders,
          type: type.value,
          isDetailed,
          organization,
          messages,
          dateSelection,
          getDataFromArchive,
        });

        downloadBlob(blob, `${messages['reports.credit.name']}.${type.value}`);
      }
    } catch (err) {
      console.error('Unable to generate report file', err);
    }
  };

  // Modal
  const openModal = async (orderId) => {
    history.push(`${path}/${orderId}`);
  };

  // Render
  const renderPagination = (filteredOrders) => {
    const totalItems = filteredOrders.length;

    return (
      totalItems > itemsPerPage && (
        <Pagination
          currentPage={currentPage}
          totalPage={totalItems ? Math.ceil(totalItems / itemsPerPage) : 1}
          onChangePage={(i) => setCurrentPage(i)}
        />
      )
    );
  };

  return (
    <div className="summary-data-list mt-3">
      <div className="summary mb-5 pb-3">
        <Row className="top-container m-0 py-3">
          <Colxx xxs="12" className="d-flex m-0 p-0 align-items-center">
            <div className="filter-container d-flex flex-grow-1 p-3 bg-white">
              <InputFilter
                config={config}
                filters={filters}
                setFilters={setFilters}
                orders={orders}
              />
              <div className="ml-2">
                <DateSelection
                  dateSelection={dateSelection}
                  setDateSelection={setCustomDate}
                />
              </div>
            </div>
            <div className="ml-3 d-flex justify-content-center">
              <PrintDropdown
                typeOptions={REPORT_FILE_TYPES}
                detailOptions={DETAIL_TYPES}
                onPrint={handleFileGeneration}
                disabled={!filteredOrders || filteredOrders.length === 0}
              />
            </div>
          </Colxx>
        </Row>
        {isLoading ? (
          <div className="orders mt-3">
            <LoaderCardColumns />
            <LoaderCardColumns />
            <LoaderCardColumns />
            <LoaderCardColumns />
            <LoaderCardColumns />
          </div>
        ) : filteredOrders && filteredOrders.length ? (
          <div className="orders mt-3">
            {filteredOrders
              .slice(indexInterval[0], indexInterval[1])
              .map((order, index) => (
                <OrderCard
                  order={order}
                  key={`${dateSelection.mode}-${index}-${order.orderId}`}
                  onClick={() => openModal(order.orderId)}
                />
              ))}

            {renderPagination(filteredOrders)}

            <TravelCreditDataInfo items={filteredOrders} className="mt-5" />
          </div>
        ) : (
          <div className="d-flex justify-content-center align-items-center">
            <NoResults />
          </div>
        )}
      </div>

      {orderId && <OrderModal orderId={orderId} closeModalPath={path} />}
    </div>
  );
}
