import React, { useState, useMemo, useEffect } from 'react';
import { Button } from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useIntl } from 'react-intl';
import moment from 'moment';
import { stringToMoney } from '../../utils/money';
import firebaseApp from '../../services/Firebase';
import appFunctions from '../../services/Functions';
import Spinner from '../common/Spinner';
import { CancellationTimeOffset } from '../../constants/cancellation';

const getMomentFromDate = (date, dateFormat = 'YYYY-MM-DD HH:mm') =>
  moment(date, dateFormat);

const getDateAndHour = (momentDate) => [
  momentDate.format('DD MMM YYYY'),
  momentDate.format('HH:mm'),
];

const formatCancellation = ({ startDate, endDate, fare }) => ({
  startDate: getMomentFromDate(startDate),
  endDate: getMomentFromDate(endDate),
  formattedAmount: stringToMoney(fare.total, 2, fare.currency),
  fare,
});

const findCancellationIndex = (cancellations) => {
  const today = moment().format('YYYY-MM-DD HH:mm');

  const index = cancellations.findIndex(
    (cancellation) => today < cancellation.startDate.format('YYYY-MM-DD HH:mm')
  );

  return index === -1 ? cancellations.length + 1 : index;
};

const getObjectFromDate = (startAt, endAt) => {
  const [startDate, startHour] = getDateAndHour(startAt);
  const [endDate, endHour] = getDateAndHour(endAt);

  return {
    startDate,
    startHour,
    endDate,
    endHour,
  };
};

const getFreeUntil = (cancelPolicies = [], orderType) => {
  if (
    cancelPolicies.length > 0 &&
    cancelPolicies.every((cancelPolicy) => cancelPolicy.fare.total !== 0)
  ) {
    const timeOffset = CancellationTimeOffset[orderType];

    if (timeOffset) {
      return moment(cancelPolicies[0].startDate, 'YYYY-MM-DD HH:mm').subtract(
        ...timeOffset
      );
    } else {
      return moment(cancelPolicies[0].startDate, 'YYYY-MM-DD HH:mm')
        .subtract(1, 'days')
        .endOf('days');
    }
  } else return null;
};

function CancellationBubble({ cancellation, isPast, isCurrent, style = {} }) {
  const { formatMessage } = useIntl();

  const title = useMemo(
    () =>
      cancellation.endDate
        ? formatMessage(
            { id: 'travel.cancel.cancel-dates' },
            getObjectFromDate(cancellation.startDate, cancellation.endDate)
          )
        : formatMessage(
            { id: 'travel.cancel.free-until' },
            {
              date: cancellation.startDate.format('DD MMM YYYY'),
              hour: cancellation.startDate.format('HH:mm'),
            }
          ),
    [cancellation]
  );

  return (
    <div
      className={`cancellation-bubble ${isPast ? 'past' : ''} ${
        isCurrent ? 'current' : ''
      }`}
      style={style}
    >
      <div className="bubble" />
      <div className="ml-3">
        <h3 className="m-0 font-primary title mb-1 font-weight-medium">
          {title}
        </h3>
        <p className="m-0 price">{cancellation.formattedAmount}</p>
      </div>
    </div>
  );
}

function CancellationBubbles({ cancelPolicies, orderType, canCancel }) {
  const { messages } = useIntl();

  const {
    cancellations,
    cancellationIndex,
    lineAnimationDuration,
    numCancellations,
  } = useMemo(() => {
    const freeUntil = getFreeUntil(cancelPolicies, orderType);

    const cancellations = freeUntil
      ? [
          {
            startDate: freeUntil,
            fare: {
              currency: cancelPolicies[0].fare.currency,
              total: 0,
            },
            formattedAmount: messages['travel.cancel.free'],
          },
          ...cancelPolicies,
        ]
      : cancelPolicies;

    const cancellationIndex = findCancellationIndex(cancelPolicies);

    return {
      cancellations,
      cancellationIndex,
      lineAnimationDuration: cancellations.length * 400,
      numCancellations: cancelPolicies.length,
    };
  }, [cancelPolicies]);

  return (
    <>
      <div className="cancellation-bubbles ml-3 mt-4 pt-2">
        <div className="all-bubbles">
          {cancellations.map((cancellation, index) => (
            <CancellationBubble
              cancellation={cancellation}
              isPast={index < cancellationIndex}
              isCurrent={index === cancellationIndex}
              key={`bubble-${index}`}
              style={{ animationDelay: `${index * 150}ms` }}
            />
          ))}
        </div>
        <div
          className="line"
          style={{ animationDuration: `${lineAnimationDuration}ms` }}
        />
      </div>
      {canCancel ? null : (
        <p
          className="cancellation-msg w-70"
          style={{ animationDelay: `${(numCancellations + 1) * 150}ms` }}
        >
          {messages['travel.cancel.cannot-cancel']}
        </p>
      )}
    </>
  );
}

export default function CancellationModalInfo({
  order,
  goForCancellation,
  toggleModal,
}) {
  const { messages } = useIntl();

  const [loadingPolicies, setLoadingPolicies] = useState(true);
  const [policies, setPolicies] = useState([]);

  const canCancel = useMemo(() => {
    const numPolicies = policies.length;
    return numPolicies > 0
      ? !moment().isAfter(policies[numPolicies - 1].endDate)
      : false;
  }, [policies]);

  useEffect(() => {
    fetchCancelPolicies();
  }, [order]);

  // Functions
  const fetchCancelPolicies = async () => {
    try {
      setLoadingPolicies(true);

      const {
        data: { cancelPolicies },
      } = await appFunctions.getCancelPolicies(order.id);

      setPolicies(cancelPolicies.map((cancel) => formatCancellation(cancel)));

      setLoadingPolicies(false);
    } catch (err) {
      console.error(err);
    }
  };

  const cancelReservation = async () => {
    await firebaseApp.requestToCancel(order.id);
    toggleModal();
  };

  // Render
  return (
    <div className="approval-steps cancellation-modal h-100 p-3">
      <PerfectScrollbar
        options={{
          suppressScrollX: true,
          wheelPropagation: false,
        }}
        className="d-flex flex-column justify-content-between"
      >
        <div>
          <h3 className="hotel-title m-0">{messages['travel.cancel.title']}</h3>
          <p className="m-0 mt-1" style={{ opacity: 0.5 }}>
            {messages['travel.cancel.subtitle']}
          </p>

          <div>
            {loadingPolicies ? null : (
              <CancellationBubbles
                cancelPolicies={policies}
                canCancel={canCancel}
                orderType={order.type}
              />
            )}
          </div>
        </div>

        {loadingPolicies ? (
          <div className="loading-container d-flex align-items-center justify-content-center">
            <Spinner white />
          </div>
        ) : null}

        <div className="ml-3 text-right btns">
          <Button color="link" onClick={goForCancellation} className="mb-0">
            {messages['general.go-back']}
          </Button>
          <Button
            className="mb-0"
            onClick={cancelReservation}
            disabled={!canCancel}
          >
            {messages['travel.cancel.title']}
          </Button>
        </div>
      </PerfectScrollbar>
    </div>
  );
}
