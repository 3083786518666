import React, { useState } from 'react';
import {
  Row,
  InputGroup,
  ModalFooter,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Alert,
  CustomInput,
} from 'reactstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import IntlMessages from '../../helpers/IntlMessages';
import messages from '../../lang/messages';

import User from '../../services/User';
import { useDispatch, useSelector } from 'react-redux';
import { setupList } from '../../constants/checklists';
import { openChecklistAndToggleItem } from '../../components/checklist';
import { trackPropertiesSegment } from '../../utils/segment';
import { maxNameLength } from '../../constants/defaultValues';
import { isCpfValid } from '../../utils/cpf';
import MaskedInput from 'react-text-mask';
import { cpfMask, dateMask } from '../../utils/masks';

const ERROR_MESSAGES = {
  400: 'user.register.error.invalid-params',
  409: 'user.register.error.email-taken',
  default: 'user.register.error',
};

export default function InviteModal({ toggleModal, verifyEmail = () => true }) {
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const {
    checklist: { setup },
    auth: { user },
  } = useSelector(({ checklist, auth }) => ({ checklist, auth }));

  // Invite
  const inviteUser = async (values, setSubmitting) => {
    const {
      email,
      firstName,
      lastName,
      cpf = '',
      birthdate = '',
      should_invite,
    } = values;

    try {
      await User.inviteUser({
        email,
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        should_invite,
        documents: {
          cpf: {
            number: cpf,
          },
        },
        birthdate,
      });

      setSubmitting(false);

      trackPropertiesSegment('User invited', {
        ...values,
        whoInvited: user.uid,
      });

      if (setup) {
        if (!setup.items[setupList.items.invite]?.done) {
          openChecklistAndToggleItem(
            dispatch,
            setupList.id,
            setupList.items.invite,
            true
          );
        }
      }

      toggleModal();
    } catch (err) {
      console.error(err);

      setError(
        messages[ERROR_MESSAGES[err.response?.status] || ERROR_MESSAGES.default]
      );
    }
  };

  // Render
  return (
    <Modal isOpen={true} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal}>
        <IntlMessages id="admin.users.invite-traveler" />
      </ModalHeader>
      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          email: '',
          birthdate: '',
          cpf: '',
          should_invite: false,
        }}
        validationSchema={Yup.object().shape({
          firstName: Yup.string().required(
            messages['forms.validation.firstName.required']
          ),
          lastName: Yup.string().required(
            messages['forms.validation.lastName.required']
          ),
          email: Yup.string()
            .email(messages['forms.validation.email.valid'])
            .required(messages['forms.validation.email.required'])
            .test(
              'already-exists',
              messages['forms.validation.email.already-exists'],
              (email) => verifyEmail(email)
            ),
          cpf: Yup.string().test(
            'invalid',
            messages['forms.validation.cpf.number.valid'],
            (cpf) => (cpf ? isCpfValid(cpf) : true)
          ),
          birthdate: Yup.string(),
        })}
        onSubmit={(values, { setSubmitting }) => {
          inviteUser(values, setSubmitting);
        }}
      >
        {({ isSubmitting, values, handleChange, handleBlur }) => (
          <Form>
            <ModalBody>
              {error && (
                <Alert color="danger" className="mb-2 mx-0">
                  {error}
                </Alert>
              )}
              <Row className="mb-1 mx-0">
                <InputGroup>
                  <Label className="form-group has-top-label mb-0">
                    <Field
                      type="input"
                      name="firstName"
                      className="form-control"
                      autoComplete="given-name"
                      maxLength={maxNameLength - values.lastName.trim().length}
                    />
                    <span>
                      <IntlMessages id="user.firstName" /> *
                    </span>
                    <ErrorMessage
                      name="firstName"
                      component="div"
                      className="invalid-feedback d-block"
                    />
                  </Label>
                  <Label className="form-group has-top-label mb-0">
                    <Field
                      type="input"
                      name="lastName"
                      className="form-control"
                      autoComplete="family-name"
                      maxLength={maxNameLength - values.firstName.trim().length}
                    />
                    <span>
                      <IntlMessages id="user.lastName" /> *
                    </span>
                    <ErrorMessage
                      name="lastName"
                      component="div"
                      className="invalid-feedback d-block"
                    />
                  </Label>
                </InputGroup>
              </Row>
              <Row className="mb-1 mx-0">
                <Label className="form-group has-top-label mb-0 w-100">
                  <Field
                    type="email"
                    name="email"
                    className="form-control"
                    autoComplete="email"
                  />
                  <span>
                    <IntlMessages id="user.email" /> *
                  </span>
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="invalid-feedback d-block"
                  />
                </Label>
              </Row>
              <Row className="mx-0">
                <InputGroup>
                  <Label className="form-group has-top-label mb-0">
                    <Field
                      type="input"
                      name="birthdate"
                      autoComplete="bday"
                      maxLength={10}
                    >
                      {({ field }) => (
                        <MaskedInput
                          type="text"
                          {...field}
                          mask={dateMask}
                          id="birthdate"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="form-control"
                        />
                      )}
                    </Field>
                    <IntlMessages id="user.birthdate" />
                    <ErrorMessage
                      name="birthdate"
                      component="div"
                      className="invalid-feedback d-block"
                    />
                  </Label>
                  <Label className="form-group has-top-label mb-0">
                    {/* <Field type="input" name="cpf" className="form-control" autoComplete="family-name" maxLength={14} /> */}
                    <Field
                      type="input"
                      name="cpf"
                      autoComplete="cpf"
                      maxLength={14}
                    >
                      {({ field }) => (
                        <MaskedInput
                          type="text"
                          {...field}
                          mask={cpfMask}
                          id="cpf"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="form-control"
                        />
                      )}
                    </Field>
                    <IntlMessages id="user.cpf" />
                    <ErrorMessage
                      name="cpf"
                      component="div"
                      className="invalid-feedback d-block"
                    />
                  </Label>
                </InputGroup>
              </Row>
              <Row className="mb-1 mx-0">
                <Field name="should_invite">
                  {({ field }) => (
                    <CustomInput
                      type="checkbox"
                      {...field}
                      id="should_invite"
                      checked={values.should_invite}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="mt-3"
                      label={messages['user.shouldInvite']}
                    />
                  )}
                </Field>
              </Row>
            </ModalBody>
            <ModalFooter>
              <div className="d-flex justify-content-between align-items-center">
                <Button
                  type="submit"
                  color="primary"
                  outline
                  size="lg"
                  disabled={isSubmitting}
                >
                  <IntlMessages id="forms.action.invite" />
                </Button>
              </div>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
