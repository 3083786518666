import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Label, Button, Collapse, Row, CardBody } from 'reactstrap';
import { Colxx } from '../../components/common/CustomBootstrap';
import { useIntl } from 'react-intl';

import Switch from 'rc-switch';
import 'rc-switch/assets/index.css';

import { ReactComponent as OpenBookingImg } from '../../assets/img/expenses/open-booking.svg';

import firebaseApp from '../../services/Firebase';
import { getAuthRedux } from '../../utils/redux';
import { getOrganizationConfig } from '../../utils/organization';
import NumberFormat from 'react-number-format';

export default function OpenBookingConfigView() {
  const { messages } = useIntl();
  const { organization } = useSelector(getAuthRedux);

  // States
  const openBookingConfig = useMemo(
    () => getOrganizationConfig(organization.config, 'openBooking'),
    [organization]
  );

  const [accordion, setAccordion] = useState(false);
  const [daysBeforeExpiration, setDaysBeforeExpiration] = useState(
    () => openBookingConfig?.daysBeforeExpiration || 1
  );

  const daysSufix = useMemo(
    () =>
      ` ${messages[daysBeforeExpiration > 1 ? 'general.days' : 'general.day']}`,
    [daysBeforeExpiration]
  );

  // Functions
  const updateOpenBookingConfig = async (field, value) => {
    try {
      await firebaseApp.updateTravelsConfig(organization.id, {
        [`config.openBooking.${field}`]: value,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const toggleAllowOpenBooking = (checked) =>
    updateOpenBookingConfig('allowed', checked);

  const updateDaysBeforeExpiration = () =>
    updateOpenBookingConfig('daysBeforeExpiration', daysBeforeExpiration || 1);

  const toggleAccordion = () => {
    setAccordion(!accordion);
  };

  return (
    <Row className="travel-data-list-view d-flex justify-content-between mt-5 ml-3 w-100 pb-5">
      <Colxx
        xxs="12"
        md="5"
        className="d-flex justify-content-center align-self-start"
      >
        <OpenBookingImg width={'85%'} />
      </Colxx>
      <Colxx xxs="12" md="7" className="pl-3">
        <p className="text-one w-70 mt-3 slide-left">
          {messages['admin.travels.open-booking.description']}
        </p>
        <div
          className="slide-left mt-4 mb-5"
          style={{ animationDelay: '25ms' }}
        >
          <Label>
            <Switch
              checked={openBookingConfig.allowed}
              onChange={toggleAllowOpenBooking}
              className="mr-2 custom-switch"
            />
            {messages['admin.travels.open-booking.permissions.allow']}
          </Label>

          <div className="border mt-4">
            <Button
              color="link"
              onClick={toggleAccordion}
              aria-expanded={accordion}
            >
              {messages['admin.refunds.advanced_options']}
            </Button>
            <Collapse isOpen={accordion}>
              <Row>
                <Colxx sm="12">
                  <CardBody>
                    <p className="text-one w-70">
                      {
                        messages[
                          'admin.travels.open-booking.days-before-expiration'
                        ]
                      }
                    </p>

                    <NumberFormat
                      name="openBookingActiveDays"
                      suffix={daysSufix}
                      allowLeadingZeros={false}
                      allowNegative={false}
                      allowedDecimalSeparators={false}
                      className="currency-input form-control w-50"
                      defaultValue={1}
                      onValueChange={(v) =>
                        setDaysBeforeExpiration(Math.trunc(v.floatValue))
                      }
                      onBlur={updateDaysBeforeExpiration}
                      value={daysBeforeExpiration}
                      disabled={!openBookingConfig?.allowed}
                    />
                  </CardBody>
                </Colxx>
              </Row>
            </Collapse>
          </div>
        </div>
      </Colxx>
    </Row>
  );
}
