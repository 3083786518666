import React, { useEffect, useMemo, useRef, useState } from 'react';

export default function SlidingComponents({
  components,
  initialTab = '',
  className = '',
  currentTab: _currentTab,
  onChangeToInitialTab = () => {},
  onChangeTab = () => {},
  ...props
}) {
  const history = useRef(initialTab ? [initialTab] : []);

  const tabs = useMemo(() => Object.keys(components), [components]);

  const [currentTab, setCurrentTab] = useState(initialTab || tabs[0]);
  const [direction, setDirection] = useState('');

  const Component = useMemo(
    () => components[currentTab],
    [components, currentTab]
  );
  const componentClassName = useMemo(
    () => `${className} ${direction ? `show-up ${direction}` : ''}`,
    [className, direction]
  );

  // Effects
  useEffect(() => {
    handleTabChange(initialTab);
  }, [initialTab]);

  useEffect(() => {
    handleTabChange(_currentTab);
  }, [_currentTab]);

  // Functions
  const handleTabChange = (tab) => {
    if (tab && currentTab !== tab) setCurrentTab(tab);
  };

  const getDirection = (tab) => {
    const oldPosition = tabs.findIndex((value) => value === currentTab);
    const newTab = tabs.findIndex((value) => value === tab);

    return oldPosition < newTab ? 'left' : 'right';
  };

  const changeTab = (tab) => {
    setDirection(getDirection(tab));
    setCurrentTab(tab);
    history.current.push(tab);

    onChangeTab(tab);
    if (tab === initialTab) onChangeToInitialTab();
  };

  const goForInitial = () => {
    changeTab(initialTab);
  };

  const goToPrevious = () => {
    history.current.pop();
    changeTab(history.current.pop() || initialTab);
  };

  // Render
  return (
    <div className={componentClassName}>
      <Component
        setCurrentTab={changeTab}
        goToPrevious={goToPrevious}
        goForInitial={goForInitial}
        currentTab={currentTab}
        {...props}
      />
    </div>
  );
}
