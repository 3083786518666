import React from 'react';
import { Button } from 'reactstrap';
import { useIntl } from 'react-intl';
import CreditSelectionCheckbox from '../../CreditSelectionCheckbox';
import PaymentAtDestinationMessage from '../Others/PaymentAtDestinationMessage';

export default function ApprovalForm({
  goForApproval,
  requestToApprove,
  requestToReject,
  ongoingAction,
  order,
  shouldDisableButtons,
  credit,
  setCredit,
  isRejecting,
  setIsRejecting,
}) {
  const { messages } = useIntl();

  // Render
  const renderReject = () => {
    return (
      <div
        className="text-right btns my-3 mr-1"
        style={{
          pointerEvents: ongoingAction ? 'none' : 'auto',
          opacity: ongoingAction ? 0.6 : 1,
        }}
      >
        <Button
          color="link"
          disabled={shouldDisableButtons}
          onClick={goForApproval}
          className="my-0 px-2"
        >
          {messages['general.go-back']}
        </Button>
        <Button
          color="link"
          onClick={() => setIsRejecting(false)}
          disabled={shouldDisableButtons}
          title={shouldDisableButtons ? messages['travel.timeout'] : ''}
          className="my-0 px-2 mx-2"
        >
          {messages['general.cancel']}
        </Button>
        <Button
          className="my-0 px-2 mx-2"
          onClick={requestToReject}
          disabled={shouldDisableButtons}
          title={shouldDisableButtons ? messages['travel.timeout'] : ''}
        >
          {messages['general.reject']}
        </Button>
      </div>
    );
  };

  const renderContent = () => (
    <>
      <CreditSelectionCheckbox
        order={order}
        selectedCredit={credit}
        setSelectedCredit={setCredit}
      />

      <PaymentAtDestinationMessage order={order} />

      <div
        className="ml-3 mr-3 text-right"
        style={{
          pointerEvents: ongoingAction ? 'none' : 'auto',
          opacity: ongoingAction ? 0.6 : 1,
        }}
      >
        <Button
          color="link"
          disabled={shouldDisableButtons}
          className="my-0 px-2"
          onClick={goForApproval}
        >
          {messages['general.go-back']}
        </Button>
        <Button
          color="link"
          onClick={requestToReject}
          disabled={shouldDisableButtons}
          title={shouldDisableButtons ? messages['travel.timeout'] : ''}
          className="my-0 px-2 mx-2"
        >
          {messages['general.reject']}
        </Button>
        <Button
          onClick={() => requestToApprove(credit)}
          disabled={shouldDisableButtons}
          title={shouldDisableButtons ? messages['travel.timeout'] : ''}
          className="my-0 ml-2"
        >
          {messages['travel.order.approval.to-approve']}
        </Button>
      </div>
    </>
  );

  return isRejecting ? renderReject() : renderContent();
}
