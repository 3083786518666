import React from 'react';
import find from 'lodash/find';

export default function AirportName({ station, params }) {
  if (params?.airports) {
    const airport = find(params.airports, { iata: station });

    if (airport) {
      return <span>{airport.airport}</span>;
    }
  }

  if (station?.iataCode) {
    return <span>{station.iataCode}</span>;
  }

  return <span>{station}</span>;
}
