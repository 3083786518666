import React from 'react';
import classnames from 'classnames';

export default function SelectableContainer({
  children,
  className = '',
  selected = false,
  ...props
}) {
  return (
    <div
      className={classnames('selected-container', className, {
        selected,
      })}
      {...props}
    >
      {children}
    </div>
  );
}
