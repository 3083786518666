import { validateLanguage } from '../../lang';
import { CHANGE_LOCALE } from '../actions';

export const changeLocale = (locale) => {
  locale = validateLanguage(locale);

  localStorage.setItem('currentLanguage', locale);

  return {
    type: CHANGE_LOCALE,
    payload: locale,
  };
};
