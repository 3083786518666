import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Map,
  Marker,
  GoogleApiWrapper,
  IProvidedProps,
  GoogleAPI,
} from 'google-maps-react';
import { mapObjectCoordsToArray } from './utils';
import LocationSearch from '../LocationSearch';

import config from '../../../constants/GoogleMaps';

import {
  TCoordsObject,
  TLocation,
  TLocationWithId,
  TObject,
} from 'src/portao3-legacy/types/others';
import { TMapLocation } from 'src/portao3-legacy/types/maps';
import LoadingMap from './LoadingMap';

interface IProps extends IProvidedProps {
  pointCoords: TCoordsObject;
  pinIconSrc?: string;
  selectedPinIconSrc?: string;
  highlightedPinIconSrc?: string;
  selectedFavoritePinIconSrc?: string;
  favoritePlacePinIconSrc?: string;
  selectedPoints?: TObject<boolean>;
  favoritePlacesIds?: string[];
  onPinSelect: (location: TLocationWithId) => void;
  highlightedPin?: TLocation;
  withLocationSearch?: boolean;
  city?: string;
  onLocationSelection?: (location?: TLocation) => void;
  loading?: boolean;
}

const SearchMap = React.memo(
  ({
    google,
    pointCoords,
    pinIconSrc = '/assets/img/icons/location_pin_selected.svg',
    selectedPinIconSrc = '/assets/img/icons/location_pin_selected.svg',
    highlightedPinIconSrc = '/assets/img/icons/highlighted_pin.svg',
    selectedFavoritePinIconSrc = '/assets/img/icons/dark_star_pin.svg',
    favoritePlacePinIconSrc = '/assets/img/icons/highlighted_heart_pin.svg',
    selectedPoints = {},
    favoritePlacesIds = [],
    onPinSelect,
    highlightedPin,
    withLocationSearch,
    city,
    onLocationSelection = () => {},
  }: IProps) => {
    // Refs
    const mapRef = useRef<Map>(null);

    // States
    const [isMapLoaded, setIsMapLoaded] = useState(false);

    const mappedCoords = useMemo(
      () => mapObjectCoordsToArray(pointCoords),
      [pointCoords]
    );

    // Effects
    useEffect(() => {
      if (isMapLoaded) {
        defineBounds(highlightedPin ? [highlightedPin] : mappedCoords);
      }
    }, [mappedCoords, isMapLoaded, highlightedPin]);

    useEffect(() => {}, []);

    // Functions
    const onMapDoubleClick = (
      event: any,
      map: any,
      coord: { latLng: TMapLocation['geometry']['location'] }
    ) => {
      const { latLng } = coord;

      onLocationSelection({
        lat: latLng.lat(),
        lng: latLng.lng(),
      });
    };

    const defineBounds = useCallback(
      (coords: TLocation[] | TLocationWithId[]) => {
        if (!mapRef.current) return;

        const googleMaps = ((window as any).google as GoogleAPI).maps;

        const bounds = new googleMaps.LatLngBounds();

        coords.forEach((coord) => {
          bounds.extend(new googleMaps.LatLng(coord.lat, coord.lng));
        });

        (mapRef.current as any).map.fitBounds(bounds);
      },
      []
    );

    const getMarkerIcon = (item: TLocationWithId) => {
      const isSelected = selectedPoints[item.id];

      if (favoritePlacesIds.includes(item.id)) {
        return favoritePlacePinIconSrc;
      }

      return isSelected ? selectedPinIconSrc : pinIconSrc;
    };

    // Render
    return (
      <div className="search-map">
        {withLocationSearch ? (
          <LocationSearch city={city} onPlaceSelection={onLocationSelection} />
        ) : null}

        <Map
          visible={isMapLoaded}
          ref={mapRef}
          google={google}
          zoom={14}
          scrollwheel={false}
          keyboardShortcuts={false}
          disableDoubleClickZoom
          zoomControl
          mapTypeControl={false}
          maxZoom={15}
          scaleControl={false}
          streetViewControl={false}
          panControl={false}
          rotateControl={false}
          fullscreenControl={false}
          onReady={() => setIsMapLoaded(true)}
          onDblclick={onMapDoubleClick}
        >
          {mappedCoords.map((item) => (
            <Marker
              key={`marker-${item.id}`}
              position={item as any}
              onClick={() => onPinSelect(item)}
              icon={{
                url: getMarkerIcon(item),
              }}
            />
          ))}

          {highlightedPin ? (
            <Marker
              key="highlighted-pin"
              position={{ lat: highlightedPin.lat, lng: highlightedPin.lng }}
              onClick={() => onLocationSelection()}
              icon={{
                url: highlightedPinIconSrc,
              }}
            />
          ) : null}
        </Map>
      </div>
    );
  }
);

export default GoogleApiWrapper({
  apiKey: config.key,
})(SearchMap);
