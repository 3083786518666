import React from 'react';
import { CardBody, Row } from 'reactstrap';
import { Colxx } from '../../components/common/CustomBootstrap';
import moment from 'moment';
import filter from 'lodash/filter';
import sum from 'lodash/sum';
import sumBy from 'lodash/sumBy';
import map from 'lodash/map';
import isUndefined from 'lodash/isUndefined';
import find from 'lodash/find';
import { useIntl } from 'react-intl';

import CarMap from './CarMap';

import { OrderTypes } from '../../constants/orderTypes';

import { DEFAULT_CURRENCY, stringToMoney } from '../../utils/money';

export default function CarCardSummary({ orders, reservations }) {
  const { messages } = useIntl();

  const carOrders = filter(orders, (o) => {
    return o.type === OrderTypes.CAR;
  });

  const currency = carOrders.length
    ? carOrders[0].fare.currency
    : DEFAULT_CURRENCY;

  const carReservations = filter(reservations, (reservation) => {
    const orderExists = !isUndefined(
      find(orders, (order) => {
        return order.id === reservation.refs.orderId;
      })
    );

    return reservation.type === OrderTypes.CAR && orderExists;
  });

  const numDaysPerReservation = map(carReservations, (reservation) => {
    return moment(reservation.carDetails.dropOff, 'DD/MM/YYYY HH:mm').diff(
      moment(reservation.carDetails.pickUp, 'DD/MM/YYYY HH:mm'),
      'days'
    );
  });

  const sumNumDaysPerReservation = sum(numDaysPerReservation);

  const totalSpent = sumBy(carOrders, (o) => {
    return parseFloat(o.fare.total);
  });

  return (
    <CardBody>
      <Row>
        <Colxx sm="6">
          <div className="d-flex flex-row mb-3 pb-3 justify-content-between align-items-center">
            <div className="flex-fill">
              <p className="font-weight-medium mb-0">
                {
                  messages[
                    'containers.dashboard.hotel-card-summary.total-spent'
                  ]
                }
              </p>
              <p className="text-muted mb-0 text-small">
                {
                  messages[
                    'containers.dashboard.hotel-card-summary.total-daily'
                  ]
                }
              </p>
            </div>
            <div>{stringToMoney(totalSpent, 2, currency)}</div>
          </div>

          <div className="d-flex flex-row mb-3 pb-3 justify-content-between align-items-center">
            <div className="flex-fill">
              <p className="font-weight-medium mb-0">
                {
                  messages[
                    'containers.dashboard.hotel-card-summary.average-daily'
                  ]
                }
              </p>
              <p className="text-muted mb-0 text-small">
                {
                  messages[
                    'containers.dashboard.hotel-card-summary.average-daily-value'
                  ]
                }
              </p>
            </div>
            <div>
              {stringToMoney(
                sumNumDaysPerReservation
                  ? totalSpent / sumNumDaysPerReservation
                  : 0,
                2,
                currency
              )}
            </div>
          </div>

          <div className="d-flex flex-row mb-3 pb-3 justify-content-between align-items-center">
            <div className="flex-fill">
              <p className="font-weight-medium mb-0">
                {
                  messages[
                    'containers.dashboard.card-summary.total-reservations'
                  ]
                }
              </p>
              <p className="text-muted mb-0 text-small">
                {
                  messages[
                    'containers.dashboard.card-summary.reservations-per-period'
                  ]
                }
              </p>
            </div>
            <div>{carOrders.length}</div>
          </div>
        </Colxx>
        <Colxx sm="5">
          <CarMap reservations={carReservations} />
        </Colxx>
      </Row>
    </CardBody>
  );
}
