import React, { PureComponent, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Route, Switch, Redirect } from 'react-router-dom';
import AdminRoute from '../../components/AdminRoute';
import DataLayout from '../../layout/DataLayout';
import ESG from './esg';
import Expenses from './expenses';
import Finance from './finance';

import Travels from './travels';
import { PermissionTypes } from '../../constants/permissions';
import { useSelector } from 'react-redux';
import { getAuthRedux } from '../../utils/redux';
import { isUserAllowed } from '../../utils/permissions';

export default function Data({
  match,
  ExpensesComponent = Expenses,
  FinanceComponent = Finance,
  TravelsComponent = Travels,
  ESGComponent = ESG,
}) {
  const { messages } = useIntl();
  const { user = {} } = useSelector(({ auth }) => auth);

  const permissions = useMemo(
    () => ({
      payments: isUserAllowed(user, [PermissionTypes.DATA_BUDGET]),
      travels: isUserAllowed(user, [PermissionTypes.DATA_TRAVEL]),
      esg: isUserAllowed(user, [PermissionTypes.DATA_ESG]),
      finance: isUserAllowed(user, [PermissionTypes.DATA_STATEMENT]),
    }),
    [user]
  );

  const firstEndpoint = useMemo(() => {
    return Object.entries(permissions).find(([, value]) => value === true);
  }, [permissions]);

  // Effects
  useEffect(() => {
    document.title = `Portão 3 - ${messages['menu.data']}`;
  }, []);

  // Render
  return (
    <AdminRoute permissions={[PermissionTypes.DATA_ACCESS]}>
      <DataLayout data-testid="rendered-data">
        <Switch>
          <Redirect
            exact
            from={`${match.url}/`}
            to={`${match.url}/${firstEndpoint[0]}`}
          />

          {permissions.payments && (
            <Route
              exact
              path={`${match.url}/payments`}
              component={ExpensesComponent}
            />
          )}
          {permissions.payments && (
            <Route
              exact
              path={`${match.url}/payments/:tabId`}
              component={ExpensesComponent}
            />
          )}
          {permissions.payments && (
            <Route
              exact
              path={`${match.url}/payments/:tabId/:orderId`}
              component={ExpensesComponent}
            />
          )}
          {permissions.payments && (
            <Route
              exact
              path={`${match.url}/payments/:tabId/:orderId/:action`}
              component={ExpensesComponent}
            />
          )}
          {permissions.payments && (
            <Route
              exact
              path={`${match.url}/payments/:tabId/:orderId/items/:itemId`}
              component={ExpensesComponent}
            />
          )}

          {permissions.travels && (
            <Route
              exact
              path={`${match.url}/travels`}
              component={TravelsComponent}
            />
          )}
          {permissions.travels && (
            <Route
              exact
              path={`${match.url}/travels/:tabId`}
              component={TravelsComponent}
            />
          )}
          {permissions.travels && (
            <Route
              exact
              path={`${match.url}/travels/:tabId/:orderId`}
              component={TravelsComponent}
            />
          )}
          {permissions.travels && (
            <Route
              exact
              path={`${match.url}/travels/:tabId/:orderId/:option`}
              component={TravelsComponent}
            />
          )}

          {permissions.esg && (
            <Route exact path={`${match.url}/esg`} component={ESGComponent} />
          )}
          {permissions.esg && (
            <Route
              exact
              path={`${match.url}/esg/:tabId`}
              component={ESGComponent}
            />
          )}
          {permissions.esg && (
            <Route
              exact
              path={`${match.url}/esg/:tabId/:orderId`}
              component={ESGComponent}
            />
          )}
          {permissions.esg && (
            <Route
              exact
              path={`${match.url}/esg/:tabId/:orderId/items/:itemId`}
              component={ESGComponent}
            />
          )}

          {permissions.finance && (
            <Route
              exact
              path={`${match.url}/finance`}
              component={FinanceComponent}
            />
          )}
          {permissions.finance && (
            <Route
              exact
              path={`${match.url}/finance/details/travel/:travelId`}
              component={FinanceComponent}
            />
          )}
          {permissions.finance && (
            <Route
              exact
              path={`${match.url}/finance/details/expenses/:budgetId`}
              component={FinanceComponent}
            />
          )}
          {permissions.finance && (
            <Route
              exact
              path={`${match.url}/finance/details/expenses/:budgetId/items/:itemId`}
              component={FinanceComponent}
            />
          )}
          {permissions.finance && (
            <Route
              exact
              path={`${match.url}/finance/:tabId`}
              component={FinanceComponent}
            />
          )}
          {permissions.finance && (
            <Route
              exact
              path={`${match.url}/finance/:tabId/summary/:orderId`}
              component={FinanceComponent}
            />
          )}
          {permissions.finance && (
            <Route
              exact
              path={`${match.url}/finance/:tabId/:resourceId`}
              component={FinanceComponent}
            />
          )}

          <Redirect to="/error" />
        </Switch>
      </DataLayout>
    </AdminRoute>
  );
}
