import React, { useState } from 'react';
import {
  FormGroup,
  CustomInput,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
} from 'reactstrap';
import moment from 'moment';
import { useIntl } from 'react-intl';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

export default function DateSelection({ dateSelection, setDateSelection }) {
  const [isFirstClick, setFirstClick] = useState(false);
  const [selection, setSelection] = useState('');
  const [dropdownSplitOpen, setDropdownSplitOpen] = useState(false);

  const { messages } = useIntl();

  const toggleSplit = () => {
    setDropdownSplitOpen(!dropdownSplitOpen);
  };

  // Get Receipt
  const setDate = async (date) => {
    if (
      isFirstClick ||
      moment(date).isBefore(moment.unix(dateSelection.startDate))
    ) {
      setFirstClick(false);

      if (moment(date).isAfter(moment.unix(dateSelection.endDate))) {
        setDateSelection(moment(date), moment(date));
      } else {
        setDateSelection(moment(date), moment.unix(dateSelection.endDate));
      }
    } else {
      setFirstClick(true);
      setDateSelection(moment.unix(dateSelection.startDate), moment(date));
    }

    setSelection('');
  };

  const setPeriod = async (selection, startDate, endDate) => {
    setFirstClick(false);
    setSelection(selection);

    setDateSelection(startDate, endDate);

    setDropdownSplitOpen(false);
  };

  // Render
  return (
    <ButtonDropdown
      isOpen={dropdownSplitOpen}
      toggle={toggleSplit}
      className="analytics-date-selection"
    >
      <DropdownToggle caret color="primary" className="dropdown-toggle-split">
        {moment.unix(dateSelection.startDate).format('DD [de] MMM [de] YYYY')} -{' '}
        {moment.unix(dateSelection.endDate).format('DD [de] MMM [de] YYYY')}
      </DropdownToggle>
      <DropdownMenu right>
        <FormGroup>
          <CustomInput
            type="radio"
            name="radiusRadio"
            id="lifetime"
            label={messages['dateSelection.lifetime']}
            inline
            defaultChecked={selection === 'lifetime'}
            onChange={() =>
              setPeriod(
                'lifetime',
                moment().year(2010).startOf('year'),
                moment()
              )
            }
          />
          <CustomInput
            type="radio"
            name="radiusRadio"
            id="pastMonth"
            label={messages['dateSelection.pastMonth']}
            inline
            defaultChecked={selection === 'pastMonth'}
            onChange={() =>
              setPeriod(
                'pastMonth',
                moment().subtract(1, 'month').startOf('month'),
                moment().subtract(1, 'month').endOf('month')
              )
            }
          />
          <CustomInput
            type="radio"
            name="radiusRadio"
            id="today"
            label={messages['dateSelection.today']}
            inline
            defaultChecked={selection === 'today'}
            onChange={() => setPeriod('today', moment(), moment())}
          />
          <CustomInput
            type="radio"
            name="radiusRadio"
            id="yesterday"
            label={messages['dateSelection.yesterday']}
            inline
            defaultChecked={selection === 'yesterday'}
            onChange={() =>
              setPeriod(
                'yesterday',
                moment().subtract(1, 'day'),
                moment().subtract(1, 'day')
              )
            }
          />
          <CustomInput
            type="radio"
            name="radiusRadio"
            id="past7Days"
            label={messages['dateSelection.past7Days']}
            inline
            defaultChecked={selection === 'past7Days'}
            onChange={() =>
              setPeriod(
                'past7Days',
                moment().subtract(7, 'day'),
                moment().subtract(1, 'day')
              )
            }
          />
          <CustomInput
            type="radio"
            name="radiusRadio"
            id="past14Days"
            label={messages['dateSelection.past14Days']}
            inline
            defaultChecked={selection === 'past14Days'}
            onChange={() =>
              setPeriod(
                'past14Days',
                moment().subtract(14, 'day'),
                moment().subtract(1, 'day')
              )
            }
          />
          <CustomInput
            type="radio"
            name="radiusRadio"
            id="past28Days"
            label={messages['dateSelection.past28Days']}
            inline
            defaultChecked={selection === 'past28Days'}
            onChange={() =>
              setPeriod(
                'past28Days',
                moment().subtract(28, 'day'),
                moment().subtract(1, 'day')
              )
            }
          />
          <CustomInput
            type="radio"
            name="radiusRadio"
            id="thisMonth"
            label={messages['dateSelection.thisMonth']}
            inline
            defaultChecked={selection === 'thisMonth'}
            onChange={() =>
              setPeriod('thisMonth', moment().startOf('month'), moment())
            }
          />
        </FormGroup>
        <DatePicker
          selected={moment.unix(dateSelection.startDate).toDate()}
          onChange={setDate}
          dateFormat={`dd/MM/yyyy`}
          selectsStart={isFirstClick}
          selectsEnd={!isFirstClick}
          inline
          monthsShown={2}
          startDate={moment.unix(dateSelection.startDate).toDate()}
          endDate={moment.unix(dateSelection.endDate).toDate()}
        />
      </DropdownMenu>
    </ButtonDropdown>
  );
}
