import classNames from 'classnames';
import React, { useEffect, useMemo, useRef } from 'react';

export default function NumberedComponent({
  className,
  indexClassName = 'mr-3',
  index,
  indexFontSize = 40,
  indexColor = 'white',
  children,
}) {
  // Refs
  const indexRef = useRef(null);
  const contentRef = useRef(null);

  // States
  const indexText = useMemo(() => `${index}.`, [index]);

  // Effects
  useEffect(() => {
    const { height } = indexRef.current.getBoundingClientRect();
    contentRef.current.style.minHeight = `${Math.ceil(height)}px`;
  }, []);

  // Render
  return (
    <section className={classNames('numbered-component d-flex', className)}>
      <div className="index-container flex-shrink-0 position-relative">
        <h3
          className={classNames(
            'index lh-1 m-0 p-0 font-primary font-weight-bold',
            indexClassName
          )}
          style={{ fontSize: indexFontSize, color: indexColor }}
          ref={indexRef}
        >
          {indexText}
        </h3>
      </div>
      <div
        className="content flex-grow-1 d-flex justify-content-center flex-column"
        ref={contentRef}
      >
        {children}
      </div>
    </section>
  );
}
