export const FLIGHT_FARE_RULES = {
  G3: {
    LT: {
      descriptions: [
        'Bolsa ou mochila pequena',
        'Bagagem de mão 10kg',
        'Alteração com taxa',
      ],
      rules: [
        'Reembolso de 0% antes da partida do primeiro voo da sua viagem',
        'Alterações com diferença de preço',
      ],
    },
    PL: {
      descriptions: [
        'Bolsa ou mochila pequena',
        'Bagagem de mão 10kg',
        '1 bagagem despachada 23kg',
        'Marcação de assento gratuita',
        'Alteração com taxa',
      ],
      rules: [
        'Reembolso de 40% antes da partida do primeiro voo da sua viagem',
        'Alterações com diferença de preço',
      ],
    },
    MX: {
      descriptions: [
        'Bolsa ou mochila pequena',
        'Bagagem de mão 10kg',
        '1 bagagem despachada 23kg',
        'Marcação de assento gratuita',
        'Alteração sem taxa',
      ],
      rules: [
        'Reembolso de 95% antes da partida do primeiro voo da sua viagem',
      ],
    },
  },
  AD: {
    Azul: {
      descriptions: [
        'Bolsa ou mochila pequena',
        'Bagagem de mão 10kg',
        'Alteração com taxa',
      ],
      rules: [
        'Reembolso de 40% antes da partida do primeiro voo da sua viagem',
        'Alterações com diferença de preço',
      ],
    },
    MaisAzul: {
      descriptions: [
        'Bolsa ou mochila pequena',
        'Bagagem de mão 10kg',
        '1 bagagem despachada 23kg',
        'Alteração com taxa',
      ],
      rules: [
        'Reembolso de 40% antes da partida do primeiro voo da sua viagem',
        'Alterações com diferença de preço',
      ],
    },
  },
  JJ: {
    SL: {
      descriptions: ['Bolsa ou mochila pequena', 'Bagagem de mão 10kg'],
      rules: [
        'Reembolso de 0% antes da partida do primeiro voo da sua viagem',
        'Alterações com diferença de preço',
      ],
    },
    Light: {
      descriptions: ['Bolsa ou mochila pequena', 'Bagagem de mão 10kg'],
      rules: [
        'Reembolso de 0% antes da partida do primeiro voo da sua viagem',
        'Alterações com diferença de preço',
      ],
    },
    KM: {
      descriptions: [
        'Bolsa ou mochila pequena',
        'Bagagem de mão 10kg',
        '1 bagagem despachada 23kg',
      ],
      rules: [
        'Reembolso de 0% antes da partida do primeiro voo da sua viagem',
        'Alterações com diferença de preço',
      ],
    },
    SE: {
      descriptions: [
        'Bolsa ou mochila pequena',
        'Bagagem de mão 10kg',
        '1 bagagem despachada 23kg',
        'Marcação de assento gratuita',
      ],
      rules: [
        'Reembolso de 0% antes da partida do primeiro voo da sua viagem',
        'Alterações com diferença de preço',
      ],
    },
    KD: {
      descriptions: [
        'Bolsa ou mochila pequena',
        'Bagagem de mão 10kg',
        '1 bagagem despachada 23kg',
        'Marcação de assento gratuita',
      ],
      rules: [
        'Reembolso de 100% antes da partida do primeiro voo da sua viagem',
        'Alterações com diferença de preço',
      ],
    },
    RY: {
      descriptions: [
        'Bolsa ou mochila pequena',
        'Bagagem de mão 10kg',
        '1 bagagem despachada 23kg',
        'Marcação de assento premium gratuita',
      ],
      rules: [
        'Reembolso de 100% antes da partida do primeiro voo da sua viagem',
        'Alterações com diferença de preço',
      ],
    },
  },
  LA: {
    SL: {
      descriptions: ['Bolsa ou mochila pequena', 'Bagagem de mão 10kg'],
      rules: [
        'Reembolso de 0% antes da partida do primeiro voo da sua viagem',
        'Alterações com diferença de preço',
      ],
    },
    Light: {
      descriptions: ['Bolsa ou mochila pequena', 'Bagagem de mão 10kg'],
      rules: [
        'Reembolso de 0% antes da partida do primeiro voo da sua viagem',
        'Alterações com diferença de preço',
      ],
    },
    KM: {
      descriptions: [
        'Bolsa ou mochila pequena',
        'Bagagem de mão 10kg',
        '1 bagagem despachada 23kg',
      ],
      rules: [
        'Reembolso de 0% antes da partida do primeiro voo da sua viagem',
        'Alterações com diferença de preço',
      ],
    },
    SE: {
      descriptions: [
        'Bolsa ou mochila pequena',
        'Bagagem de mão 10kg',
        '1 bagagem despachada 23kg',
        'Marcação de assento gratuita',
      ],
      rules: [
        'Reembolso de 0% antes da partida do primeiro voo da sua viagem',
        'Alterações com diferença de preço',
      ],
    },
    KD: {
      descriptions: [
        'Bolsa ou mochila pequena',
        'Bagagem de mão 10kg',
        '1 bagagem despachada 23kg',
        'Marcação de assento gratuita',
      ],
      rules: [
        'Reembolso de 100% antes da partida do primeiro voo da sua viagem',
        'Alterações com diferença de preço',
      ],
    },
    RY: {
      descriptions: [
        'Bolsa ou mochila pequena',
        'Bagagem de mão 10kg',
        '1 bagagem despachada 23kg',
        'Marcação de assento premium gratuita',
      ],
      rules: [
        'Reembolso de 100% antes da partida do primeiro voo da sua viagem',
        'Alterações com diferença de preço',
      ],
    },
  },
};
