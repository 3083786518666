import React, { Fragment } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { Row, Nav, NavItem } from 'reactstrap';
import classnames from 'classnames';
import IntlMessages from '../../helpers/IntlMessages';
import { Colxx, Separator } from '../../components/common/CustomBootstrap';
import Breadcrumb from '../../containers/navs/Breadcrumb';

import DashboardView from '../../containers/travels/DashboardView';
import TravelDataListView from '../../containers/reports/TravelDataListView';
import TravelCreditDataListView from '../../containers/reports/TravelCreditDataListView';

export default function Travels(props) {
  const { tabId } = useParams();

  // Render
  return (
    <Fragment>
      <Row>
        <Colxx xxs="12">
          <Breadcrumb heading="admin.travels.title" match={props.match} />
          <Separator className="mb-5" />
        </Colxx>
      </Row>
      <Row>
        <Colxx xxs="12" className="mb-4">
          <div>
            <Nav className="admin-tabs">
              <NavItem>
                <NavLink
                  to="/data/travels/dashboard"
                  className={classnames({
                    active: !tabId || tabId === 'dashboard',
                    'nav-link': true,
                  })}
                >
                  <IntlMessages id="admin.travels.dashboard" />
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  to="/data/travels/details"
                  className={classnames({
                    active: tabId === 'details',
                    'nav-link': true,
                  })}
                >
                  <IntlMessages id="admin.data.details" />
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  to="/data/travels/credits"
                  className={classnames({
                    active: tabId === 'credits',
                    'nav-link': true,
                  })}
                >
                  <IntlMessages id="admin.data.travel_credits" />
                </NavLink>
              </NavItem>
            </Nav>

            {!tabId || tabId === 'dashboard' ? <DashboardView /> : null}
            {tabId === 'details' ? (
              <TravelDataListView path="/data/travels/details" />
            ) : null}
            {tabId === 'credits' ? (
              <TravelCreditDataListView path="/data/travels/credits" />
            ) : null}
          </div>
        </Colxx>
      </Row>
    </Fragment>
  );
}
