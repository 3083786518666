import ContentLoader from 'react-content-loader';

interface IProps {
  className?: string;
  height?: number;
}

export default function LoadingMap({ className, height = 400 }: IProps) {
  return (
    <ContentLoader
      className={className}
      speed={2}
      width={'100%'}
      height={height}
      viewBox="0 0 100 100"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      preserveAspectRatio="none"
    >
      <rect x="0" y="0" rx="0" ry="0" width="100%" height="100%" />
    </ContentLoader>
  );
}
