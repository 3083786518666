import React from 'react';
import { ReactComponent as CheckMark } from '../../assets/img/icons/check.svg';

export default function ChecklistItem({
  title,
  done = true,
  handleCheck,
  style = {},
  handleItemClick = () => {},
  strike,
  className = '',
}) {
  return (
    <div
      className={`checklist-item ${done ? 'done' : ''} ${
        strike ? 'striked' : ''
      } ${className}`}
      style={style}
    >
      <div className="checkbox" onClick={handleCheck}>
        <CheckMark className={`check-mark`} />
      </div>
      <a>
        <span className="checkbox-name" onClick={handleItemClick}>
          {title}
          <div className="strike"></div>
        </span>
      </a>
    </div>
  );
}
