import React from 'react';
import { useMemo } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { TABS } from '../..';
import { getHotelSearch } from '../../../../../../constants/url';
import Spinner from '../../../../../common/Spinner';
import CheckoutTab from './CheckoutTab';
import RoomsTab from './RoomsTab';
import SearchTab from './SearchTab';

const COMPONENTS = [SearchTab, RoomsTab, CheckoutTab];

export default function IncreaseDaysTab({
  order,
  reservations,
  setCurrentTab,
  ...props
}) {
  const { messages } = useIntl();

  const [search, setSearch] = useState(null);
  const [currentStage, setCurrentStage] = useState(0);
  const [results, setResults] = useState(null);
  const [selectedRooms, setSelectedRooms] = useState(null);
  const [direction, setDirection] = useState('left');

  const Component = useMemo(() => {
    const Component = COMPONENTS[currentStage];
    if (Component) return Component;
    else {
      setCurrentStage(0);
      return SearchTab;
    }
  }, [currentStage]);

  useEffect(() => {
    const getSearch = async () => {
      setSearch(await getHotelSearch(order, reservations, null, false));
    };

    getSearch();
  }, [order, reservations]);

  const onDaySelection = (results) => {
    setResults(results);
    goForward();
  };

  const onRoomsSelection = (rooms) => {
    setSelectedRooms(rooms);
    goForward();
  };

  const goBack = () => {
    if (direction !== 'right') setDirection('right');

    if (currentStage === 0) {
      setCurrentTab(TABS.INITIAL);
    } else {
      setCurrentStage(currentStage - 1);
    }
  };

  const goForward = () => {
    if (direction !== 'left') setDirection('left');
    setCurrentStage(currentStage + 1);
  };

  return (
    <div
      className="d-flex flex-column justify-content-between h-100 show-up left"
      style={{ overflow: 'hidden' }}
    >
      <div>
        <h3 className="hotel-title m-0">
          {messages['travel.alter.hotel.increase-days.title']}
        </h3>
      </div>
      <div className="flex-grow-1" style={{ minHeight: 0 }}>
        {search ? (
          <Component
            order={order}
            reservations={reservations}
            search={search}
            setSearch={setSearch}
            onDaySelection={onDaySelection}
            onRoomsSelection={onRoomsSelection}
            goBack={goBack}
            goForward={goForward}
            results={results}
            selectedRooms={selectedRooms}
            direction={direction}
            {...props}
          />
        ) : (
          <div className="h-100 d-flex justify-content-center align-items-center">
            <Spinner relative white style={{ width: 60, height: 60 }} />
          </div>
        )}
      </div>
    </div>
  );
}
