import { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Row, Modal, Label, ModalBody, ModalFooter } from 'reactstrap';
import { Colxx } from '../common/CustomBootstrap';
import classnames from 'classnames';
import IntlMessages from '../../helpers/IntlMessages';
import PerfectScrollbar from 'react-perfect-scrollbar';
import HotelPhotos from '../order/HotelPhotos';
import HotelAmenities from '../order/HotelAmenities';

import firebaseApp from '../../services/Firebase';
import ReservationParams from './ReservationParams';
import HotelStars from '../order/HotelStars';
import Footer from './Footer';
import useCanAlter from '../../hooks/useCanAlter';
import { canApproveReservation, canOrderBeCancelled } from '../../utils/orders';
import orderStatus from '../../constants/orderStatus';
import OrderIssueCard from '../order/OrderIssueCard';
import Comment from '../expensesTimeline/Comment';
import useLocationMapping from '../../hooks/useLocationMapping/useLocationMapping';
import useModalStages from '../../hooks/useModalStages';

let snapshotApprovals = () => {};

export default function HotelModal({ toggleModal, order, path }) {
  const { messages } = useIntl();
  const { user, locale } = useSelector(({ auth, settings }) => ({
    user: auth.user,
    locale: settings.locale,
  }));

  const {
    isApproving,
    toggleIsApproving,
    setIsApproving,
    isAltering,
    toggleIsAltering,
    setIsAltering,
  } = useModalStages({
    item: order,
    path,
  });

  const [isCancelling, setIsCancelling] = useState(false);
  const [loading, setLoading] = useState(true);

  const [reservation, setReservation] = useState({});
  const [approvals, setApprovals] = useState([]);

  const cancellations = useMemo(
    () => reservation?.hotelDetails?.cancellation,
    [reservation]
  );

  const canCancel = useMemo(
    () => canOrderBeCancelled(cancellations),
    [cancellations]
  );

  const canAlter = useCanAlter({
    reservations: reservation,
    detailsField: 'hotelDetails',
    dateField: 'checkOut',
    setValues: { hours: 12, minutes: 0 },
  });

  const showCancelButton = useMemo(
    () => order.statusCode !== orderStatus.ISSUED,
    [order]
  );

  const hotelDescription = useMemo(() => {
    if (!reservation) return '';

    const description = reservation?.hotelDetails?.hotel?.description || {};
    return description[locale.split('-')[0]] || description['pt'];
  }, [reservation, locale]);

  // Effects
  useEffect(() => {
    loadData();

    return () => {
      snapshotApprovals();
    };
  }, []);

  // Hooks
  useLocationMapping(
    {
      paths: {
        approval: {
          onMatch: () => setIsApproving(true),
          validate: () => canApproveReservation({ approvals, user }),
          onFail: () => toggleIsApproving(false),
        },
        alteration: {
          onMatch: () => setIsAltering(true),
          validate: () => order.statusCode === orderStatus.ISSUED,
          onFail: () => toggleIsAltering(false),
        },
      },
    },
    [approvals, user, order]
  );

  // Load Data
  const loadData = async () => {
    await Promise.all([loadApprovals(), loadReservationsFromPassenger()]);

    setLoading(false);
  };

  const loadReservationsFromPassenger = async () => {
    const reservationsSnapshot = await firebaseApp
      .getReservationsofOrderIdFromPassengerId(
        order.id,
        order.passengerId,
        user.organizationId
      )
      .get();

    if (reservationsSnapshot.size === 0) {
      toggleModal();
    }

    let reservation = {};
    reservationsSnapshot.forEach((reservationSnapshot) => {
      reservation = {
        ...reservationSnapshot.data(),
        id: reservationSnapshot.id,
      };
    });

    setReservation(reservation);
  };

  const loadApprovals = async () => {
    snapshotApprovals = firebaseApp
      .getApprovalsFromOrderId(order.id)
      .onSnapshot(function (querySnapshot) {
        const approvals = [];

        querySnapshot.forEach(function (doc) {
          approvals.push({
            id: doc.id,
            ...doc.data(),
          });
        });

        setApprovals(approvals.reverse());
      });
  };

  const goForCancellation = () => {
    setIsCancelling(!isCancelling);
  };

  // Render
  if (loading === true) {
    return (
      <Modal isOpen={true} toggle={toggleModal} className="reservation small">
        <ModalBody>
          <div className="loading p-relative" />
        </ModalBody>
      </Modal>
    );
  }

  return (
    <Modal
      isOpen={true}
      toggle={toggleModal}
      className={classnames({
        reservation: true,
        small: true,
        approving: isApproving || isCancelling || isAltering,
      })}
    >
      <ModalBody className="hotel">
        <Row>
          <Colxx sm="12" md="5" className="pr-0">
            <HotelPhotos
              hotel={reservation.hotelDetails.hotel}
              hotelId={reservation.hotelDetails.hotelId}
            />
          </Colxx>
          <Colxx sm="12" md="7" className="pl-0">
            <div className="hotel-content">
              <PerfectScrollbar
                options={{
                  suppressScrollX: true,
                  wheelPropagation: false,
                }}
              >
                <h3 className="hotel-title ml-3">
                  {reservation.hotelDetails.hotel.name}
                  <HotelStars
                    hotel={reservation.hotelDetails.hotel}
                    hotelId={reservation.hotelDetails.hotelId}
                  />
                </h3>

                <p className="text-muted-darke ml-3 text-capitalize">
                  {reservation.hotelDetails.hotel.destination.address.toLowerCase()}
                </p>

                <HotelAmenities
                  hotel={reservation.hotelDetails.hotel}
                  hotelId={reservation.hotelDetails.hotelId}
                />

                <h5 className="mt-5 ml-3">
                  <IntlMessages id="components.timeline.modal.reservation-data" />
                </h5>
                <ReservationParams order={order} />

                {order.comment ? (
                  <div className="mx-3 comments mb-5">
                    <h5 className="mt-4">
                      {messages['refund.modal.refund.comments']}
                    </h5>
                    <Comment comment={order.comment} />
                  </div>
                ) : null}

                <div className="reservation-params primary pl-3">
                  <Row>
                    <Colxx xxs="10">
                      <Label className="form-group disabled search has-top-label">
                        <p>
                          {reservation.hotelDetails.selectedRoom.description}
                        </p>
                        <p className="pt-0">
                          {reservation.hotelDetails.selectedRoom.adults === 1
                            ? `1 adulto`
                            : `${reservation.hotelDetails.selectedRoom.adults} adultos`}
                        </p>
                        <IntlMessages id="travel.Room" />
                      </Label>
                    </Colxx>
                  </Row>
                  <Row>
                    <Colxx xxs="3">
                      <Label className="form-group disabled search has-top-label">
                        <p>{reservation.hotelDetails.checkIn}</p>
                        <IntlMessages id="travel.checkin" />
                      </Label>
                    </Colxx>
                    <Colxx xxs="3">
                      <Label className="form-group disabled search has-top-label">
                        <p>{reservation.hotelDetails.checkOut}</p>
                        <IntlMessages id="travel.checkout" />
                      </Label>
                    </Colxx>
                    <Colxx xxs="6">
                      <Label className="form-group disabled search has-top-label">
                        <p>
                          {
                            reservation.hotelDetails.selectedRoom.board
                              .description
                          }
                        </p>
                        <IntlMessages id="travel.board" />
                      </Label>
                    </Colxx>
                  </Row>
                </div>

                <h5 className="mt-4 ml-3">
                  {messages['containers.search.hotel-modal.hotel-description']}
                </h5>
                <div
                  className="hotel-description ml-3 mr-3 mb-5"
                  dangerouslySetInnerHTML={{
                    __html: hotelDescription,
                  }}
                />

                <h5 className="mt-4 ml-3">
                  {
                    messages[
                      'containers.search.hotel-modal.reservation-policies'
                    ]
                  }
                </h5>
                <div
                  className="hotel-description ml-3 mr-3 mb-5"
                  dangerouslySetInnerHTML={{
                    __html: reservation.hotelDetails.policies,
                  }}
                />

                <OrderIssueCard
                  show={isApproving}
                  order={order}
                  reservations={[reservation]}
                />
              </PerfectScrollbar>
            </div>
          </Colxx>
        </Row>
      </ModalBody>
      <ModalFooter className="p-3">
        <Footer
          order={order}
          approvals={approvals}
          goForApproval={() => toggleIsApproving()}
          goForCancellation={goForCancellation}
          goForAlteration={() => toggleIsAltering()}
          toggleModal={toggleModal}
          cancellations={cancellations}
          reservations={[reservation]}
          isApproving={isApproving}
          isCancelling={isCancelling}
          isAltering={isAltering}
          canAlter={canAlter || canCancel}
          canCancel={canCancel}
          showCancelButton={showCancelButton}
        />
      </ModalFooter>
    </Modal>
  );
}
