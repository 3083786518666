import classNames from 'classnames';
import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';

export default function AlterationFrame({
  className,
  title,
  subtitle,
  children,
  BottomBar = null,
}) {
  return (
    <div
      className={classNames(
        'd-flex flex-column justify-content-between h-100',
        className
      )}
    >
      <div className="text-left">
        <h3 className="hotel-title m-0">{title}</h3>
        {subtitle ? (
          <p className="m-0 mt-1 lh-1-25 text-white-muted">{subtitle}</p>
        ) : null}
      </div>

      <PerfectScrollbar
        options={{
          suppressScrollX: true,
          wheelPropagation: false,
        }}
        className="flex-grow-1 my-3"
        style={{ minHeight: 0 }}
      >
        {children}
      </PerfectScrollbar>

      <div className="flex-shrink-0">{BottomBar ? <BottomBar /> : null}</div>
    </div>
  );
}
