import React, { useMemo, useState } from 'react';
import { FLIGHT_ALTERATION_TABS } from '..';
import SlidingComponents from '../../../../SlidingComponents';
import CheckoutTab from './CheckoutTab';
import FlightSelection from './FlightSelection';
import moment from 'moment';

export const ALTERATION_TABS = {
  INITIAL: 'initial',
  OUTBOUND: 'outbound',
  INBOUND: 'inbound',
  CHECKOUT: 'checkout',
};

const COMPONENTS = {
  [ALTERATION_TABS.OUTBOUND]: (props) => (
    <FlightSelection type="outbound" {...props} />
  ),
  [ALTERATION_TABS.INBOUND]: (props) => (
    <FlightSelection type="inbound" {...props} />
  ),
  [ALTERATION_TABS.CHECKOUT]: CheckoutTab,
};

export default function AlterationTab({
  order,
  reservations,
  goForAlteration,
  setCurrentTab,
}) {
  const [flights, setFlights] = useState({});

  const { roundTrip, initialTab } = useMemo(() => {
    const today = moment();

    return {
      roundTrip: order.journeys.length > 1,
      initialTab: moment(
        order.journeys[0].departureDate,
        'YYYY-MM-DD'
      ).isSameOrAfter(today, 'day')
        ? ALTERATION_TABS.OUTBOUND
        : ALTERATION_TABS.INBOUND,
    };
  }, [order]);

  // Functions
  const selectFlight = (type, flight) => {
    setFlights({
      ...flights,
      [type]: flight,
    });
  };

  // Render
  return (
    <SlidingComponents
      initialTab={initialTab}
      components={COMPONENTS}
      goForAlteration={goForAlteration}
      goToInitialTab={() => setCurrentTab(FLIGHT_ALTERATION_TABS.INITIAL)}
      order={order}
      reservations={reservations}
      roundTrip={roundTrip}
      className="flight-alteration-tab h-100"
      selectFlight={selectFlight}
      flights={flights}
    />
  );
}
