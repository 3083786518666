import classNames from 'classnames';
import React, { useMemo, useState } from 'react';

export default function UserProfile({ name = '', imgSrc }) {
  const [showImage, setShowImage] = useState(!!imgSrc);
  const [loadingImage, setLoadingImage] = useState(!!imgSrc);

  const userName = useMemo(() => {
    const parts = name.split(' ').map((value) => value.trim());
    return parts.length > 1 ? `${parts[0][0]}${parts.pop()[0]}` : parts[0][0];
  }, [name]);

  return (
    <div className="user-profile flex-shrink-0">
      {showImage ? (
        <img
          className={classNames('user-image', { loading: loadingImage })}
          src={imgSrc}
          alt={name}
          onLoad={() => setLoadingImage(false)}
          onError={() => setShowImage(false)}
        />
      ) : (
        <p className="user-initials font-primary font-weight-light">
          {userName}
        </p>
      )}
    </div>
  );
}
