import moment from 'moment';
import recordStatus from '../../../constants/recordStatus';
import { RecurrenceBudgetHistoryStatus } from '../../../constants/recurrenceBudgets';
import { replaceVariables } from '../../../constants/variableName';

function getActiveMessageInfo(nextRecurrence) {
  const nextDate = moment(nextRecurrence, 'YYYY-MM-DD').startOf('day');
  const daysDiff = nextDate.diff(moment().startOf('day'), 'days');

  let id = '',
    className = '';

  if (daysDiff > 1) {
    id = 'budget-recurrence.time.days';
    className = 'details-alert approved';
  } else if (daysDiff === 1) {
    id = 'budget-recurrence.time.tomorrow';
    className = 'details-alert warning';
  } else {
    id = 'budget-recurrence.time.today';
    className = 'details-alert danger';
  }

  return { id, days: daysDiff, className };
}

function getDeactivedMessageInfo(updatedAt) {
  const updatedAtDate = moment.unix(updatedAt).startOf('day');
  const daysDiff = moment().startOf('day').diff(updatedAtDate, 'days');

  let id = '',
    className = 'details-alert danger';

  if (daysDiff === 0) {
    id = 'budget-recurrence.desactivated.time.today';
  } else if (daysDiff === 1) {
    id = 'budget-recurrence.desactivated.time.yesterday';
  } else {
    id = 'budget-recurrence.desactivated.time.days';
  }

  return { id, className, days: daysDiff };
}

export function getNextRecurrenceMessageInfo({
  nextRecurrence,
  status,
  updatedAt,
}) {
  if (status === recordStatus.ACTIVE)
    return getActiveMessageInfo(nextRecurrence);

  if (status === recordStatus.DEACTIVATED)
    return getDeactivedMessageInfo(updatedAt);

  return null;
}

export function formatRecurrenceBudgets(data) {
  const today = moment();
  return data.map((budget) => {
    const formattedName = replaceVariables(budget.name, today);

    return {
      ...budget,
      formattedName,
      filterKey: `${budget.name} ${formattedName}`.toLowerCase(),
    };
  });
}

export function getFilterFunction({ searchKey, deletedBudgetIds = [] }) {
  if (searchKey && deletedBudgetIds.length) {
    return ({ id, filterKey }) =>
      !deletedBudgetIds.includes(id) && filterKey.includes(searchKey);
  }

  if (searchKey) {
    return ({ filterKey }) => filterKey.includes(searchKey);
  }

  if (deletedBudgetIds.length) {
    return ({ id }) => !deletedBudgetIds.includes(id);
  }

  return null;
}

export function getStatusClassName(status) {
  if (status === RecurrenceBudgetHistoryStatus.SUCCESS)
    return 'details-alert approved';

  return 'details-alert danger';
}
