import React, { useEffect } from 'react';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { CardHeader, Nav, NavItem, NavLink } from 'reactstrap';
import { useSelector } from 'react-redux';
import { trackPropertiesSegment } from '../../../utils/segment';
import { getTravelConfig } from '../../../utils/reservation';
import OrderStructures from '../../../constants/orderStructure';

export default function ReservationParamsNav({
  orderStructure,
  setStructure = () => {},
  allowOrganizationTravel = true,
  allowPersonalTravel = true,
}) {
  const { messages } = useIntl();

  // Hooks
  const travels = useSelector(({ auth }) => getTravelConfig(auth));

  // Effects
  useEffect(() => {
    if (!travels[orderStructure]) {
      const validStructure = Object.keys(travels).find(
        (value) => travels[value]
      );

      setStructure(validStructure || '');
    }
  }, [orderStructure]);

  // Render
  return (
    <CardHeader>
      <Nav tabs className="card-header-tabs">
        {travels[OrderStructures.ORGANIZATION] && allowOrganizationTravel && (
          <NavItem>
            <NavLink
              to="#"
              location={{}}
              className={classNames({
                active: orderStructure === OrderStructures.ORGANIZATION,
                'nav-link': true,
              })}
              onClick={() => {
                trackPropertiesSegment('Changed to Organization Tab');
                setStructure(OrderStructures.ORGANIZATION);
              }}
            >
              <h5>
                {
                  messages[
                    'containers.search.reservation-params.company-travel'
                  ]
                }
              </h5>
            </NavLink>
          </NavItem>
        )}
        {travels[OrderStructures.PERSONAL] && allowPersonalTravel && (
          <NavItem>
            <NavLink
              to="#"
              location={{}}
              className={classNames({
                active: orderStructure === OrderStructures.PERSONAL,
                'nav-link': true,
              })}
              onClick={() => {
                trackPropertiesSegment('Changed to Personal Tab');
                setStructure(OrderStructures.PERSONAL);
              }}
            >
              <h5>
                {
                  messages[
                    'containers.search.reservation-params.personal-travel'
                  ]
                }
              </h5>
            </NavLink>
          </NavItem>
        )}
      </Nav>
    </CardHeader>
  );
}
