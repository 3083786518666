import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useState } from 'react';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import RoomItem from '../../../../../../containers/search/RoomItem';
import BottomButtons from '../BottomButtons';

const formatAmenities = (amenities = []) =>
  amenities
    .map((value) => value.toLowerCase())
    .sort((a, b) => a.localeCompare(b))
    .join(',');

const findRoom = (selectedRoom, rooms) => {
  const room = rooms.find((room) => room.id === selectedRoom.id);

  if (room) return [room, true];
  else {
    const roomName = selectedRoom.description;
    const hasBreakfast = selectedRoom.board?.breakfast;
    const amenities = formatAmenities(selectedRoom.info?.amenities);

    const similarRoom = rooms.find(
      ({ description, board = {}, info = {} }) =>
        roomName === description &&
        board.breakfast === hasBreakfast &&
        amenities === formatAmenities(info.amenities)
    );

    return [similarRoom, false];
  }
};

export default function Room({
  room,
  reservationSelectedRoom,
  alreadySelectedRoom = null,
  goBack,
  goForward,
}) {
  const { messages, formatMessage } = useIntl();

  const { title, rooms, roomsInfo, initialRoom, roomMessage, isSameRoom } =
    useMemo(() => {
      const { item, search } = room;

      const hotelRooms = item.hotel.rooms.reduce((obj, room) => {
        obj[room.id] = room;
        return obj;
      }, {});

      const rooms = item.rooms
        .map((room) => ({
          ...room,
          info: hotelRooms[room.mediaId],
        }))
        .sort((a, b) => a.fare - b.fare);

      const [initialRoom, isSameRoom] = findRoom(
        reservationSelectedRoom,
        rooms
      );

      return {
        rooms: reservationSelectedRoom.board?.breakfast
          ? rooms.filter((room) => room.board?.breakfast === true)
          : rooms,
        title: formatMessage(
          {
            id: 'travel.alter.hotel.increase-days.reservation',
          },
          {
            startDate: search.outbound_date.format('DD MMM'),
            endDate: search.inbound_date.format('DD MMM'),
          }
        ),
        roomsInfo: hotelRooms,
        initialRoom,
        isSameRoom,
        roomMessage:
          messages[
            initialRoom
              ? isSameRoom
                ? 'travel.alter.hotel.increase-days.has-room'
                : 'travel.alter.hotel.increase-days.similar-room'
              : 'travel.alter.hotel.increase-days.no-room'
          ],
      };
    }, [reservationSelectedRoom, room]);

  const [selectedRoom, setSelectedRoom] = useState(
    alreadySelectedRoom || initialRoom || {}
  );

  // Functions
  const selectRoom = (e) => {
    if (e.target.checked) {
      const id = e.target.id;

      setSelectedRoom(rooms.find((room) => room.id === id));
    }
  };

  const handleGoForward = () => {
    if (selectedRoom.id) {
      goForward(selectedRoom);
    }
  };

  return (
    <div className="hotel-content d-flex flex-column justify-content-between h-100">
      <PerfectScrollbar
        options={{
          suppressScrollX: true,
          wheelPropagation: false,
        }}
        className="rooms-container flex-grow-1 my-4 mr-0"
      >
        <div className="mt-1 mb-5">
          <h4 className="font-primary" style={{ fontSize: '1rem' }}>
            {title}
          </h4>
          <p style={{ opacity: 0.5, fontSize: '0.8rem' }}>{roomMessage}</p>
        </div>
        <div className="reservation-rooms">
          <div className="d-flex justify-content-between header">
            <h5 className="text-transform-none font-weight-semibold font-primary">
              {messages['containers.search.hotel-modal.room-category']}
            </h5>
            <h5 className="text-transform-none font-weight-semibold font-primary">
              {messages['containers.search.hotel-modal.price-total']}
            </h5>
          </div>
          <div className="rooms">
            {rooms.map((room, index) => (
              <RoomItem
                key={`room-${index}`}
                roomsInfo={roomsInfo}
                index={index}
                room={room}
                selectedRoom={selectedRoom}
                selectCheckbox={selectRoom}
                white
              />
            ))}
          </div>
        </div>
      </PerfectScrollbar>
      <BottomButtons
        goBack={goBack}
        disableForward={!selectedRoom?.id}
        goForward={handleGoForward}
      />
    </div>
  );
}
