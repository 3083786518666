import React from 'react';
import firebaseApp from '../../services/Firebase';
import TagModal from '../../components/policy/TagModal';
import DefaultDataListView from './DefaultDataListView';

export default function TagsDataListView() {
  return (
    <DefaultDataListView
      fieldName="tags"
      path="tags"
      fetchFunction={firebaseApp.getTagsFromOrganization}
      deleteFunction={firebaseApp.deleteTagFromOrganizationAndId}
      updateFunction={firebaseApp.updateTagFromOrganization}
      createFunction={firebaseApp.createTagFromOrganization}
      ItemModal={TagModal}
      emptyResultsTitleId="admin.policy.empty.tag.title"
      emptyResultsDescriptionId="admin.policy.empty.tag.description"
      emptyResultsButtonTextId="admin.policy.empty.tag.btn"
      archiveKey="tags"
    />
  );
}
