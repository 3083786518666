import React from 'react';

export default function PrivacyPolicyView() {
  return (
    <div className="privacy-policy">
      <p>
        <strong>
          Pol&iacute;tica de Privacidade da Plataforma PORT&Atilde;O 3
        </strong>
      </p>
      <p>&nbsp;</p>
      <p>Atualizado em 1 de setembro de 2021.</p>
      <p>&nbsp;</p>
      <p>
        A PORT&Atilde;O 3 respeita e zela pela privacidade dos Titulares de
        Dados Pessoais, buscando ser transparente e prover
        informa&ccedil;&otilde;es claras sobre suas pr&aacute;ticas de
        Tratamento de Dados Pessoais. N&oacute;s estamos comprometidos em zelar
        pelo sigilo de suas informa&ccedil;&otilde;es. O Tratamento de suas
        informa&ccedil;&otilde;es pessoais visa, antes de tudo, a proporcionar
        um atendimento melhor e mais personalizado, que leve em conta as
        condi&ccedil;&otilde;es espec&iacute;ficas de cada Usu&aacute;rio.
        Quanto mais o Usu&aacute;rio interage conosco, mais nos informa e mais
        capazes somos de lhe oferecer servi&ccedil;os personalizados.
      </p>
      <p>&nbsp;</p>
      <p>
        Ao utilizar a Plataforma, os Usu&aacute;rios declaram que leram e
        consentem expressamente com todas as disposi&ccedil;&otilde;es dos
        Termos e Condi&ccedil;&otilde;es Gerais de Uso (Termos de Uso) da
        Plataforma e da Pol&iacute;tica de Privacidade, em particular com a
        forma e finalidade para a qual os Dados Pessoais s&atilde;o tratados,
        bem como qualquer transfer&ecirc;ncia de Dados Pessoais prevista nesta
        Pol&iacute;tica de Privacidade.&nbsp;
      </p>
      <p>&nbsp;</p>
      <ul>
        <li>
          <a href="https://portao3.com.br/">
            Termos e Condi&ccedil;&otilde;es Gerais de Uso da Plataforma
            PORT&Atilde;O 3
          </a>
        </li>
      </ul>
      <p>&nbsp;</p>
      <p>
        Todas as disposi&ccedil;&otilde;es gerais presentes nos Termos de Uso,
        incluindo limita&ccedil;&otilde;es de responsabilidade, lei
        aplic&aacute;vel e m&eacute;todos de solu&ccedil;&atilde;o de disputas,
        ser&atilde;o integralmente aplic&aacute;veis a esta Pol&iacute;tica de
        Privacidade.
      </p>
      <p>&nbsp;</p>
      <p>
        Os termos em mai&uacute;sculas n&atilde;o expressamente definidos neste
        documento ter&atilde;o o significado que lhes foi atribu&iacute;do nos
        Termos de Uso.
      </p>
      <p>&nbsp;</p>
      <p>
        Sugerimos que o Usu&aacute;rio leia com aten&ccedil;&atilde;o esta
        Pol&iacute;tica de Privacidade e os Termos de Uso, bem como as suas
        eventuais atualiza&ccedil;&otilde;es, antes de tomar a decis&atilde;o de
        usar ou prosseguir com o uso da Plataforma. Caso n&atilde;o concorde com
        qualquer disposi&ccedil;&atilde;o desta Pol&iacute;tica de Privacidade
        ou dos Termos de Uso, o Usu&aacute;rio deve abster-se de se cadastrar,
        acessar e/ou utilizar a Plataforma.
      </p>
      <strong> &nbsp;1. NATUREZA JUR&Iacute;DICA</strong>
      <ol>
        <ul>
          <li>
            O Usu&aacute;rio entende e reconhece que esta Pol&iacute;tica de
            Privacidade e os Termos de Uso t&ecirc;m a natureza jur&iacute;dica
            de um contrato e concordam que o aceite implicar&aacute; na
            vincula&ccedil;&atilde;o do Usu&aacute;rio aos seus termos e
            condi&ccedil;&otilde;es. Dessa forma, recomendamos que o
            Usu&aacute;rio imprima uma c&oacute;pia destes documentos para
            futura refer&ecirc;ncia. Caso n&atilde;o concorde com esta
            Pol&iacute;tica de Privacidade e/ou com os Termos de Uso, o
            Usu&aacute;rio deve abster-se de se cadastrar e utilizar a
            Plataforma.
          </li>
        </ul>
      </ol>
      <p>&nbsp;</p>
      <ul>
        <li>
          O USU&Aacute;RIO DECLARA TER LIDO E COMPREENDIDO TODAS AS
          CONDI&Ccedil;&Otilde;ES ESTABELECIDAS NESTA POL&Iacute;TICA DE
          PRIVACIDADE E NOS TERMOS DE USO, E DECLARA SUA CONCORD&Acirc;NCIA E
          ACEITA&Ccedil;&Atilde;O NO MOMENTO DE CADASTRO E/OU
          REALIZA&Ccedil;&Atilde;O DE SOLICITA&Ccedil;&Atilde;O ATRAV&Eacute;S
          DA PLATAFORMA PORT&Atilde;O 3.CASO O USU&Aacute;RIO TENHA QUALQUER
          D&Uacute;VIDA SOBRE OS TERMOS DE USO E/OU SOBRE ESTA POL&Iacute;TICA
          DE PRIVACIDADE, RECOMENDAMOS QUE ESSE ENTRE EM CONTATO COM A
          PORT&Atilde;O 3 ANTES DE REALIZAR SEU ACEITE, POR MEIO DOS CANAIS DE
          ATENDIMENTO AO USU&Aacute;RIO OU DO E-MAIL oi@portao3.com.br. TEREMOS
          A SATISFA&Ccedil;&Atilde;O DE ESCLARECER-LHE EVENTUAIS D&Uacute;VIDAS.
          QUALQUER PESSOA QUE N&Atilde;O ACEITE OU N&Atilde;O CONCORDE COM ESTES
          TERMOS DE USO OU COM A POL&Iacute;TICA DE PRIVACIDADE DEVE SE ABSTER
          DE SE CADASTRAR, ACESSAR OU UTILIZAR A PLATAFORMA.
        </li>
      </ul>
      <strong> 2. DEFINI&Ccedil;&Otilde;ES</strong>
      <p>
        2.1. Os termos grafados em letra mai&uacute;scula utilizados nesta
        Pol&iacute;tica de Privacidade ter&atilde;o o significado definido
        abaixo:
      </p>
      <p>&nbsp;</p>
      <p>
        (i) &ldquo;Dados Pessoais Sens&iacute;veis&rdquo;. Dados Pessoais sobre
        origem racial ou &eacute;tnica, convic&ccedil;&atilde;o religiosa,
        opini&atilde;o pol&iacute;tica, filia&ccedil;&atilde;o a sindicato ou a
        organiza&ccedil;&atilde;o de car&aacute;ter religioso, filos&oacute;fico
        ou pol&iacute;tico, dado referente &agrave; sa&uacute;de ou &agrave;
        vida sexual, dado gen&eacute;tico ou biom&eacute;trico, quando vinculado
        a uma pessoa natural.
      </p>
      <p>&nbsp;</p>
      <p>
        (ii) &ldquo;Dados Anonimizados&rdquo;. quaisquer dados relativos a um
        Titular que n&atilde;o possa ser identificado, considerando a
        utiliza&ccedil;&atilde;o de meios t&eacute;cnicos razo&aacute;veis e
        dispon&iacute;veis na ocasi&atilde;o de seu Tratamento.
      </p>
      <p>&nbsp;</p>
      <p>
        (iii) &ldquo;Viola&ccedil;&atilde;o de Dados Pessoais&rdquo;.
        Viola&ccedil;&atilde;o de seguran&ccedil;a da informa&ccedil;&atilde;o
        que acarreta a destrui&ccedil;&atilde;o acidental ou ilegal, perda,
        altera&ccedil;&atilde;o, divulga&ccedil;&atilde;o ou acesso n&atilde;o
        autorizado de Dados Pessoais transmitidos, armazenados ou de outra forma
        Tratados pela PORT&Atilde;O 3 ou por um subcontratado autorizado.
      </p>
      <p>&nbsp;</p>
      <p>
        (iv) &ldquo;Consentimento&rdquo;. Manifesta&ccedil;&atilde;o livre,
        informada e inequ&iacute;voca pela qual uma pessoa f&iacute;sica
        concorda com o Tratamento de seus Dados Pessoais para uma finalidade
        determinada.
      </p>
      <p>&nbsp;</p>
      <p>
        (v) &ldquo;Controlador&rdquo;. pessoa f&iacute;sica ou jur&iacute;dica a
        quem competem as decis&otilde;es referentes ao Tratamento de Dados
        Pessoais.
      </p>
      <p>&nbsp;</p>
      <p>
        (vi) &ldquo;Controladores Independentes&rdquo;. duas ou mais pessoas
        f&iacute;sicas ou jur&iacute;dicas com poder de decis&atilde;o sobre o
        Tratamento de Dados Pessoais, sendo que cada Controlador define
        separadamente e independentemente as finalidades e o modo de Tratamento,
        respondendo cada qual pelo Tratamento realizado em sua esfera de
        atua&ccedil;&atilde;o.
      </p>
      <p>&nbsp;</p>
      <p>
        (vii) &ldquo;Operador&rdquo;: pessoa f&iacute;sica ou jur&iacute;dica
        que realiza o Tratamento de Dados Pessoais em nome do Controlador.
      </p>
      <p>&nbsp;</p>
      <p>
        (vii) &ldquo;Cookies&rdquo;: arquivos de navega&ccedil;&atilde;o que
        armazenam temporariamente o que o Usu&aacute;rio est&aacute; visitando
        em um determinado site ou aplicativo.
      </p>
      <p>&nbsp;</p>
      <ul>
        <li>
          &ldquo;Dados Pessoais&rdquo;. Dados que identifiquem ou tornem
          identific&aacute;vel uma pessoa natural.
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li>
          &ldquo;Estabelecimentos&rdquo; qualquer pessoa f&iacute;sica ou
          jur&iacute;dica que utiliza a Plataforma como meio de oferta de seus
          produtos como op&ccedil;&otilde;es de acomoda&ccedil;&atilde;o,
          transporte ou outros servi&ccedil;os designados aos Viajantes.
        </li>
      </ul>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <ul>
        <li>
          &ldquo;Plataforma PORT&Atilde;O 3&rdquo; ou &ldquo;Plataforma&rdquo;.
          Plataforma virtual acess&iacute;vel via web atrav&eacute;s da qual
          &eacute; poss&iacute;vel realizar a gest&atilde;o de viagens
          corporativas e controlar, do in&iacute;cio ao fim, as viagens
          definidas pelo Usu&aacute;rio, bem como fazer reservas,
          emiss&otilde;es, controle de despesas, ter um meio de pagamento
          pr&oacute;prio e ter &agrave; disposi&ccedil;&atilde;o um time
          dispon&iacute;vel 24 horas, 7 dias por semana, para atender os
          Usu&aacute;rios em caso de d&uacute;vida.
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li>
          &ldquo;Usu&aacute;rio&rdquo;. Qualquer pessoa, f&iacute;sica ou
          jur&iacute;dica, que pode ou n&atilde;o estar acessando a Plataforma
          em nome de uma pessoa jur&iacute;dica ou como destinat&aacute;ria
          final, e que acessa a Plataforma PORT&Atilde;O 3 a partir da
          ades&atilde;o aos Termos de Uso e &agrave; esta Pol&iacute;tica de
          Privacidade, para solicitar e adquirir os produtos e/ou
          servi&ccedil;os oferecidos pela PORT&Atilde;O 3.
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li>
          &ldquo;Viajante&rdquo;. &Eacute; a pessoa f&iacute;sica vinculada ao
          USU&Aacute;RIO que realiza as viagens agendadas atrav&eacute;s da
          Plataforma PORT&Atilde;O 3.
        </li>
      </ul>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <strong>
        <strong> 3. QUAIS DADOS S&Atilde;O TRATADOS?</strong>
      </strong>
      <p>&nbsp;</p>
      <p>
        3.1. Ao utilizar a Plataforma, os Usu&aacute;rios devem fornecer algumas
        informa&ccedil;&otilde;es para o cadastro, utiliza&ccedil;&atilde;o da
        Plataforma e realiza&ccedil;&atilde;o de solicita&ccedil;&otilde;es.
      </p>
      <p>&nbsp;</p>
      <p>
        3.2. O Usu&aacute;rio concorda em inserir dados reais e verdadeiros na
        Plataforma. Da mesma forma, ser&aacute; o &uacute;nico
        respons&aacute;vel pelos danos que a PORT&Atilde;O 3, qualquer uma de
        suas Afiliadas, Usu&aacute;rios ou terceiros possam sofrer em
        decorr&ecirc;ncia da inveracidade, inexatid&atilde;o, invalidade ou
        inautenticidade dos dados fornecidos.
      </p>
      <p>&nbsp;</p>
      <p>
        3.3. A PORT&Atilde;O 3 coleta informa&ccedil;&otilde;es fornecidas
        diretamente pelo Usu&aacute;rio atrav&eacute;s da Plataforma e outras
        informa&ccedil;&otilde;es que s&atilde;o fornecidas indiretamente por
        meio do uso da Plataforma.
      </p>
      <p className="px-3">
        <p>
          a){' '}
          <u>
            Informa&ccedil;&otilde;es que os Usu&aacute;rios nos fornecem
            diretamente
          </u>
          :<p>&nbsp;</p>
        </p>
        <p>
          b) Dados de identifica&ccedil;&atilde;o, cadastro e registo:
          s&atilde;o as informa&ccedil;&otilde;es que o Usu&aacute;rio nos
          fornece ao se cadastrar ou realizar uma solicita&ccedil;&atilde;o
          atrav&eacute;s da Plataforma PORT&Atilde;O 3.
        </p>
      </p>
      <p>&nbsp;</p>
      <p>
        (i) No caso de Cadastro do Usu&aacute;rio: nome completo, e-mail, CNPJ e
        telefone.
      </p>
      <p>&nbsp;</p>
      <p>
        (ii) No caso dos Viajantes: nome completo, e-mail, telefone,
        g&ecirc;nero, data de nascimento, celular, CPF, RG, passaporte, cargo,
        matr&iacute;cula, centro de custo, projeto.
      </p>
      <p>&nbsp;</p>
      <p>
        (iii) No caso de solicita&ccedil;&otilde;es feitas diretamente por
        colaboradores do Usu&aacute;rio: nome completo, e-mail, telefone,
        g&ecirc;nero, data de nascimento, celular, CPF, RG e passaporte, tanto
        do colaborador quanto dos membros da fam&iacute;lia e/ou terceiros que
        viajar&atilde;o com o colaborador.
      </p>
      <p>&nbsp;</p>
      <p>
        (iv) Para pagamentos: documentos fiscais, notas fiscais, cart&atilde;o
        de cr&eacute;dito.
      </p>
      <p>&nbsp;</p>
      <p>
        Dados de Identifica&ccedil;&atilde;o, Cadastro e Registro s&atilde;o
        obrigat&oacute;rios para o acesso &agrave; Plataforma e
        utiliza&ccedil;&atilde;o de nossos servi&ccedil;os e ser&atilde;o
        solicitados ao Usu&aacute;rio. Sem o fornecimento dessas
        informa&ccedil;&otilde;es, n&atilde;o ser&aacute; poss&iacute;vel
        utilizar a Plataforma.
      </p>
      <p>&nbsp;</p>
      <ul>
        <li>
          Conte&uacute;do Gerado pelo Usu&aacute;rio: tamb&eacute;m realizamos o
          tratamento de informa&ccedil;&otilde;es do Usu&aacute;rio quando o
          Usu&aacute;rio publica conte&uacute;dos na Plataforma (incluindo
          especifica&ccedil;&otilde;es de centro de custo, etapas de
          aprova&ccedil;&atilde;o etc.), interage com outros Usu&aacute;rios, se
          comunica conosco pelos canais de atendimento ao Usu&aacute;rio, ou por
          meio de coment&aacute;rios, cr&iacute;ticas, elogios,
          sugest&otilde;es, feedback, ou depoimentos enviados pelo
          Usu&aacute;rio acerca da Plataforma ou dos produtos e servi&ccedil;os
          adquiridos de Estabelecimentos, ou quando o Usu&aacute;rio compartilha
          conosco outras informa&ccedil;&otilde;es sobre ele, ou sobre a sua
          experi&ecirc;ncia na Plataforma.
        </li>
      </ul>
      <p>&nbsp;</p>
      <ol>
        <li>
          b){' '}
          <u>
            Informa&ccedil;&otilde;es que os Usu&aacute;rios nos fornecem
            indiretamente
          </u>
          :
        </li>
      </ol>
      <p>&nbsp;</p>
      <ul>
        <li>
          Dados de Uso da Plataforma: A PORT&Atilde;O 3 coleta os dados
          derivados do Uso da Plataforma pelo Usu&aacute;rio cada vez que ele
          interage com a Plataforma. Os Dados de Uso da Plataforma s&atilde;o
          dados de natureza t&eacute;cnica ou estat&iacute;stica conexos
          &agrave; utiliza&ccedil;&atilde;o da Plataforma. Os dados de Uso da
          Plataforma incluem, entre outras, tipo de produtos e servi&ccedil;os
          adquiridos ou ofertados, Estabelecimentos buscados, funcionalidades da
          Plataforma usadas ou p&aacute;ginas visitadas, a maneira pela qual o
          Usu&aacute;rio interage com a Plataforma, os recursos que o
          Usu&aacute;rio mais utiliza, os conte&uacute;dos que Usu&aacute;rio
          publica, compartilha ou interage, prefer&ecirc;ncias dos
          Usu&aacute;rios de maneira geral, e sites ou servi&ccedil;os de
          terceiros que o Usu&aacute;rio utilizou antes de usar a Plataforma.
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li>
          Dados T&eacute;cnicos: a PORT&Atilde;O 3 armazena os dados do
          dispositivo e dos sistemas que o Usu&aacute;rio utiliza para acessar a
          Plataforma. Estes s&atilde;o:
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li>
          Endere&ccedil;o IP que o Usu&aacute;rio utiliza para se conectar
          &agrave; Internet com seu computador ou celular;
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li>
          Informa&ccedil;&otilde;es do seu computador ou celular, como sua
          conex&atilde;o com a Internet, dados de rede, seu tipo de navegador,
          vers&atilde;o e sistema operacional, vers&atilde;o da Plataforma,
          configura&ccedil;&otilde;es ativadas para a sua conta, modelos de
          hardware, identificador, tipo e outros dados t&eacute;cnicos do seu
          dispositivo.
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li>
          Dados Derivados da origem do Usu&aacute;rio: se o Usu&aacute;rio
          chegar &agrave; Plataforma PORT&Atilde;O 3 atrav&eacute;s de uma fonte
          externa (como um link de outra p&aacute;gina web ou uma rede social),
          a PORT&Atilde;O 3 coleta os dados da fonte a partir da qual o
          Usu&aacute;rio PORT&Atilde;O 3 tomou conhecimento e acessou a
          Plataforma.
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li>
          Dados Derivados da Gest&atilde;o de Incidentes: se o Usu&aacute;rio se
          dirigir &agrave; Plataforma PORT&Atilde;O 3 atrav&eacute;s do
          formul&aacute;rio ou do telefone de contato da PORT&Atilde;O 3, a
          PORT&Atilde;O 3 coletar&aacute; as mensagens recebidas no formato
          utilizado pelo Usu&aacute;rio e poder&aacute; utiliz&aacute;-las e
          armazen&aacute;-las para gerenciar incidentes presentes ou futuros.
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li>
          Dados Coletados por meio de "Cookies": a PORT&Atilde;O 3 utiliza
          Cookies e identificadores de dispositivos eletr&ocirc;nicos (como
          SDKs, web beacons, dentre outros) quando o Usu&aacute;rio utiliza a
          Plataforma. Essas tecnologias facilitam a navega&ccedil;&atilde;o dos
          Usu&aacute;rios e permitem a realiza&ccedil;&atilde;o de
          an&aacute;lises estat&iacute;sticas de uso da Plataforma e o
          oferecimento ou desenvolvimento de funcionalidades pela PORT&Atilde;O
          3, mas tamb&eacute;m podem permitir a identifica&ccedil;&atilde;o de
          dispositivos, navegadores e perfis de Usu&aacute;rios. A PORT&Atilde;O
          3 poder&aacute; configurar ou utilizar Cookies e tecnologias
          assemelhadas propriet&aacute;rias ou de empresas terceiras para
          cumprir com as finalidades descritas nesta Pol&iacute;tica de
          Privacidade. Os Cookies e tecnologias assemelhadas de empresas
          terceiras est&atilde;o sujeitos &agrave;s respectivas pol&iacute;ticas
          de privacidade, e n&atilde;o &agrave; Pol&iacute;tica de Privacidade
          da PORT&Atilde;O 3. O Usu&aacute;rio poder&aacute; gerenciar suas
          op&ccedil;&otilde;es de privacidade diretamente na pol&iacute;tica de
          privacidade de tais empresas terceiras.
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li>
          Informa&ccedil;&otilde;es Fornecidas por Terceiros: se o
          Usu&aacute;rio acessar a Plataforma PORT&Atilde;O 3 atrav&eacute;s dos
          produtos e servi&ccedil;os oferecidos por terceiros ou de links
          veiculados nas p&aacute;ginas de terceiros, tais como o Google, estes
          poder&atilde;o enviar os dados de navega&ccedil;&atilde;o do
          Usu&aacute;rio para a PORT&Atilde;O 3.Essas informa&ccedil;&otilde;es
          poder&atilde;o ser associadas &agrave;s demais
          informa&ccedil;&otilde;es coletadas pela PORT&Atilde;O 3 ou fornecida
          pelos Usu&aacute;rios e utilizadas nas situa&ccedil;&otilde;es e para
          as finalidades descritas nesta Pol&iacute;tica de Privacidade. Nessa
          hip&oacute;tese, somente as informa&ccedil;&otilde;es coletadas
          diretamente pela PORT&Atilde;O 3 sobre o Usu&aacute;rio e o resultado
          dessa combina&ccedil;&atilde;o s&atilde;o abrangidas por e sujeitos a
          esta Pol&iacute;tica de Privacidade. As informa&ccedil;&otilde;es
          fornecidas por terceiros externos podem ser controladas pelo
          Usu&aacute;rio de acordo com a pol&iacute;tica de privacidade de
          terceiros externos.
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li>
          Dados de Geolocaliza&ccedil;&atilde;o: dependendo das
          configura&ccedil;&otilde;es do seu aplicativo ou das permiss&otilde;es
          do seu dispositivo, a PORT&Atilde;O 3 pode coletar
          informa&ccedil;&otilde;es sobre sua localiza&ccedil;&atilde;o precisa
          ou aproximada, de acordo com dados de GPS, endere&ccedil;o IP e Wi-Fi.
          Os dados de geolocaliza&ccedil;&atilde;o s&atilde;o importantes para
          monitorarmos a qualidade dos servi&ccedil;os, fornecermos
          localiza&ccedil;&atilde;o dos Viajantes e not&iacute;cias atualizadas
          sobre os referidos locais, bem como proteger o Usu&aacute;rio contra
          fraudes e incidentes de seguran&ccedil;a e para fins de
          leg&iacute;timo interesse da PORT&Atilde;O 3.
        </li>
      </ul>
      <p>&nbsp;</p>
      <p>
        3.5.&nbsp;Periodicamente, a PORT&Atilde;O 3 poder&aacute; solicitar a
        atualiza&ccedil;&atilde;o dos Dados de Identifica&ccedil;&atilde;o,
        Cadastro e Registro.
      </p>
      <p>&nbsp;</p>
      <p>
        3.6. O Usu&aacute;rio &eacute; o &uacute;nico respons&aacute;vel por
        garantir a exatid&atilde;o, clareza, relev&acirc;ncia e
        atualiza&ccedil;&atilde;o tempestiva das informa&ccedil;&otilde;es
        fornecidas, de acordo com a necessidade, n&atilde;o tendo a
        PORT&Atilde;O 3 qualquer obriga&ccedil;&atilde;o de investigar a
        veracidade das informa&ccedil;&otilde;es enviadas. O Usu&aacute;rio
        reconhece e concorda que incorre&ccedil;&otilde;es, imprecis&otilde;es
        ou desatualiza&ccedil;&otilde;es de suas informa&ccedil;&otilde;es podem
        prejudicar ou impedir a efetiva&ccedil;&atilde;o de
        solicita&ccedil;&otilde;es por meio da Plataforma.
      </p>
      <p>&nbsp;</p>
      <p>
        3.7. O Usu&aacute;rio &eacute; correspons&aacute;vel pelo sigilo de seus
        Dados Pessoais. O compartilhamento de logins e senhas de acesso, e
        outros Dados Pessoais que viabilizam o acesso &agrave; sua conta na
        Plataforma violam esta Pol&iacute;tica de Privacidade e os nossos Termos
        de Uso.
      </p>
      <p>&nbsp;</p>
      <p>
        3.8. A PORT&Atilde;O 3 n&atilde;o coleta intencionalmente
        informa&ccedil;&otilde;es de menores de 16 anos intencionalmente, exceto
        se houver pr&eacute;vio consentimento do respons&aacute;vel legal pelo
        menor, hip&oacute;tese em que o tratamento se dar&aacute; para a
        finalidade indicada no consentimento obtido.
      </p>
      <p>&nbsp;</p>
      <p>
        3.9. A PORT&Atilde;O 3, no provimento da Plataforma e demais
        servi&ccedil;os e produtos acess&oacute;rios, atuar&aacute; como
        Controlador do Tratamento de Dados Pessoais. Em rela&ccedil;&atilde;o
        aos Dados Pessoais dos Usu&aacute;rios que sejam Tratados pelos
        Estabelecimentos ou aos Dados Pessoais dos Estabelecimentos que sejam
        Tratados pelos Usu&aacute;rios para a oferta, negocia&ccedil;&atilde;o,
        solicita&ccedil;&atilde;o, contrata&ccedil;&atilde;o,
        aquisi&ccedil;&atilde;o e/ou fornecimento de produtos e servi&ccedil;os
        pelos Estabelecimentos aos Usu&aacute;rios, a PORT&Atilde;O 3, o
        Estabelecimento e o Usu&aacute;rio ser&atilde;o considerados
        Controladores Independentes de tais Dados Pessoais, assumindo
        responsabilidade pelo Tratamento realizado por si utilizando a
        Plataforma ou fora dela. Nessa hip&oacute;tese, a PORT&Atilde;O 3,
        Estabelecimento e o Usu&aacute;rio responder&atilde;o cada qual pelo
        Tratamento de Dados Pessoais realizados em sua esfera de
        atua&ccedil;&atilde;o.
      </p>
      <p>&nbsp;</p>
      <p>
        3.10. Durante o uso da Plataforma, o Usu&aacute;rio pode vir a utilizar
        outros servi&ccedil;os, canais, produtos e plataformas fornecidas,
        mantidas e/ou operadas por terceiros, utilizadas como canais ou meios de
        apoio aos servi&ccedil;os prestados pela PORT&Atilde;O 3, mas sem
        qualquer rela&ccedil;&atilde;o com a PORT&Atilde;O 3 (&ldquo;
        <u>Servi&ccedil;os de Terceiros</u>&rdquo;). Esses Servi&ccedil;os de
        Terceiros podem incluir, por exemplo, plataformas de pagamentos como a
        Bank3 (https://bank3.com.br/), aplicativos de comunica&ccedil;&atilde;o
        e mensagens instant&acirc;neas, incluindo, mas n&atilde;o se limitando
        &agrave; plataforma &ldquo;WhatsApp&rdquo;, cujo download e cadastro
        &eacute; essencial para o uso integral das funcionalidades da Plataforma
        PORT&Atilde;O 3, conforme especificado nos Termos de Uso. Quando os
        servi&ccedil;os da PORT&Atilde;O 3 utilizarem tais Servi&ccedil;os de
        Terceiro, o tratamento dos Dados Pessoais estar&aacute; sujeito
        tamb&eacute;m, em adi&ccedil;&atilde;o a esta Pol&iacute;tica de
        Privacidade, &agrave;s pol&iacute;ticas de privacidade e termos de uso
        de tais Servi&ccedil;os de Terceiro, sob os quais a PORT&Atilde;O 3
        n&atilde;o tem nenhum controle ou inger&ecirc;ncia. Nesse caso, a
        PORT&Atilde;O 3 e esse terceiro ser&atilde;o Controladores Independentes
        dos Dados Pessoais, responsabilizando-se cada qual pelo Tratamento de
        Dados Pessoais realizados em sua esfera de atua&ccedil;&atilde;o. No que
        concerne a PORT&Atilde;O 3, mesmo quando os Dados Pessoais sejam
        recebidos de tais Servi&ccedil;os de Terceiro, tais Dados Pessoais
        ser&atilde;o sempre tratados em conformidade com esta Pol&iacute;tica de
        Privacidade. Entretanto, encorajamos o Usu&aacute;rio a informar-se
        sobre as pol&iacute;ticas aplic&aacute;veis a tais Servi&ccedil;os de
        Terceiros, uma vez que a PORT&Atilde;O 3 n&atilde;o tem inger&ecirc;ncia
        na rela&ccedil;&atilde;o entre o Usu&aacute;rio e tais Terceiros e
        n&atilde;o &eacute; respons&aacute;vel pelo uso feito por tais Terceiros
        dos Dados Pessoais do Usu&aacute;rio inseridos pelo Usu&aacute;rio nos
        Servi&ccedil;os de Terceiros.
      </p>
      <p>&nbsp;</p>
      <strong> 4. PARA QUE FINALIDADE OS DADOS S&Atilde;O COLETADOS?</strong>
      <p>&nbsp;</p>
      <p>
        4.1. &nbsp;A PORT&Atilde;O 3 utiliza os Dados Pessoais dos
        Usu&aacute;rios para prestar os servi&ccedil;os solicitados pelos
        Usu&aacute;rios atrav&eacute;s da Plataforma PORT&Atilde;O 3 e permitir
        que os Usu&aacute;rios se comuniquem com a PORT&Atilde;O 3.
      </p>
      <p>&nbsp;</p>
      <p>
        4.1.1. Durante a execu&ccedil;&atilde;o de uma solicita&ccedil;&atilde;o
        realizada pelo Usu&aacute;rio, seus Dados Pessoais podem ser usados:
      </p>
      <p>&nbsp;</p>
      <p>
        (i) Pelo Estabelecimento do qual o Usu&aacute;rio tenha solicitado a
        aquisi&ccedil;&atilde;o de produtos ou servi&ccedil;os. Neste caso, a
        PORT&Atilde;O 3 e o Estabelecimento ser&atilde;o considerados
        Controladores Independentes de tais Dados Pessoais, assumindo
        responsabilidade pelo Tratamento realizado por si;
      </p>
      <p>&nbsp;</p>
      <p>
        (ii) Pelo Usu&aacute;rio que tenha solicitado a aquisi&ccedil;&atilde;o
        de produtos ou servi&ccedil;os do Estabelecimento. Neste caso, a
        PORT&Atilde;O 3 e o Usu&aacute;rio ser&atilde;o considerados
        Controladores Independentes de tais Dados Pessoais, assumindo
        responsabilidade pelo Tratamento realizado por si;
      </p>
      <p>&nbsp;</p>
      <p>
        (iii) Por meio do Servi&ccedil;o de Atendimento ao Usu&aacute;rio da
        PORT&Atilde;O 3 (direto ou atrav&eacute;s de terceiros) a fim de alertar
        o Usu&aacute;rio sobre poss&iacute;veis incidentes ou outros assuntos
        relacionados &agrave; Plataforma ou a solicita&ccedil;&atilde;o
        realizada. Sempre que seja demandada pelo pr&oacute;prio Usu&aacute;rio
        ou quando tome conhecimento de qualquer problema t&eacute;cnico na
        Plataforma, a PORT&Atilde;O 3 poder&aacute; usar os dados do
        Usu&aacute;rio para identificar, tratar e corrigir o problema ou para
        cumprir com outras solicita&ccedil;&otilde;es realizadas pelo
        Usu&aacute;rio em rela&ccedil;&atilde;o &agrave; Plataforma ou aos
        servi&ccedil;os prestados pela PORT&Atilde;O 3. Isso inclui atendimentos
        pelos canais disponibilizados, assim como solicita&ccedil;&otilde;es em
        geral relacionadas &agrave; Plataforma. A PORT&Atilde;O 3 pode usar os
        Dados Pessoais para fins de gerenciamento de incidentes que possam
        ocorrer na presta&ccedil;&atilde;o de servi&ccedil;os.
      </p>
      <p>&nbsp;</p>
      <p>
        4.1.2. A PORT&Atilde;O 3 tamb&eacute;m pode utilizar os Dados Pessoais
        que o Usu&aacute;rio fornece, bem como compartilhar com outros
        Usu&aacute;rios em caso de contrata&ccedil;&atilde;o de produtos e
        servi&ccedil;os por meio da Plataforma, para se comunicar com o
        Usu&aacute;rio via telefone, e-mail, SMS, WhatsApp, Messenger, Instagram
        ou qualquer outra plataforma de mensagens ou rede social sobre o
        funcionamento do Plataforma ou sobre uma solicita&ccedil;&atilde;o
        realizada. A PORT&Atilde;O 3 e o Estabelecimento podem enviar mensagens
        ao Usu&aacute;rio com informa&ccedil;&otilde;es sobre o status da
        solicita&ccedil;&atilde;o, resumo da solicita&ccedil;&atilde;o e seu
        pre&ccedil;o, bem como ofertas, promo&ccedil;&otilde;es, cupons de
        desconto ou outras comunica&ccedil;&otilde;es com fins promocionais ou
        comerciais.
      </p>
      <p>&nbsp;</p>
      <p>
        4.1.3. A PORT&Atilde;O 3 tamb&eacute;m utiliza a
        informa&ccedil;&atilde;o para gerar an&aacute;lises e relat&oacute;rios
        estat&iacute;sticos agregados e an&ocirc;nimos sobre o funcionamento e
        opera&ccedil;&atilde;o da Plataforma e dos servi&ccedil;os prestados por
        meio da Plataforma, realizar pesquisas de satisfa&ccedil;&atilde;o sobre
        a Plataforma e sobre os servi&ccedil;os prestados por meio da
        Plataforma, investigar e analisar como melhorar os servi&ccedil;os que
        oferece aos Usu&aacute;rios, bem como para desenvolver e melhorar as
        caracter&iacute;sticas da Plataforma e dos servi&ccedil;os que oferece
        aos Usu&aacute;rios, gerar novos produtos, neg&oacute;cios ou
        intelig&ecirc;ncia de mercado para a PORT&Atilde;O 3. Internamente, a
        PORT&Atilde;O 3 utiliza as informa&ccedil;&otilde;es de forma
        anonimizada para fins estat&iacute;sticos, a fim de analisar o
        comportamento e tend&ecirc;ncias dos Usu&aacute;rios, entender como os
        Usu&aacute;rios utilizam a Plataforma PORT&Atilde;O 3, gerenciar e
        melhorar os servi&ccedil;os oferecidos, incluindo a possibilidade de
        adicionar novos diferenciais e funcionalidades &agrave; Plataforma.
        Essas informa&ccedil;&otilde;es podem ser compartilhadas com as
        Afiliadas, desde que estejam anonimizadas.
      </p>
      <p>&nbsp;</p>
      <p>
        4.1.4. Os tratamentos acima s&atilde;o necess&aacute;rios para a
        execu&ccedil;&atilde;o dos servi&ccedil;os que o Usu&aacute;rio utiliza
        atrav&eacute;s da Plataforma PORT&Atilde;O 3. A execu&ccedil;&atilde;o
        do contrato celebrado entre a PORT&Atilde;O 3 e o Usu&aacute;rio,
        conforme regulado por esta Pol&iacute;tica de Privacidade e pelos Termos
        de Uso, &eacute; a base que legitima a PORT&Atilde;O 3 a tratar os Dados
        Pessoais, por si ou por terceiros.
      </p>
      <p>&nbsp;</p>
      <p>
        4.2. A PORT&Atilde;O 3 utiliza os Dados Pessoais dos Usu&aacute;rios
        para garantir a seguran&ccedil;a e um ambiente adequado para a
        utiliza&ccedil;&atilde;o da Plataforma e presta&ccedil;&atilde;o segura
        dos servi&ccedil;os
      </p>
      <p>&nbsp;</p>
      <p>
        4.2.1. A PORT&Atilde;O 3 poder&aacute; utilizar os Dados Pessoais para
        garantir a correta utiliza&ccedil;&atilde;o dos produtos e
        servi&ccedil;os oferecidos ou solicitados por meio Plataforma e a
        correta presta&ccedil;&atilde;o dos servi&ccedil;os.
      </p>
      <p>&nbsp;</p>
      <p>
        4.2.2. A PORT&Atilde;O 3 tamb&eacute;m pode utilizar as
        informa&ccedil;&otilde;es do Usu&aacute;rio com o objetivo de detectar e
        investigar fraudes, bem como outras atividades ilegais e
        poss&iacute;veis viola&ccedil;&otilde;es desta Pol&iacute;tica de
        Privacidade, dos nossos Termos de Uso e das legisla&ccedil;&otilde;es
        aplic&aacute;veis. Para tanto, a PORT&Atilde;O 3 pode compartilhar os
        Dados Pessoais do Usu&aacute;rio com parceiros que analisam
        opera&ccedil;&otilde;es de fraude.
      </p>
      <p>&nbsp;</p>
      <p>
        4.2.3. Os tratamentos acima s&atilde;o necess&aacute;rios para garantir
        a preven&ccedil;&atilde;o &agrave; fraude e &agrave; seguran&ccedil;a do
        Titular e para atender ao leg&iacute;timo interesse da PORT&Atilde;O 3,
        e dos demais Usu&aacute;rios e de terceiros, que consistam em proteger o
        uso adequado da Plataforma, cumprindo da legisla&ccedil;&atilde;o
        aplic&aacute;vel, bem como garantindo a correta e segura
        presta&ccedil;&atilde;o dos servi&ccedil;os.
      </p>
      <p>&nbsp;</p>
      <p>
        4.3. A PORT&Atilde;O 3 usa tecnologia de terceiros integrada &agrave;
        sua Plataforma para coletar seus Dados Pessoais e prefer&ecirc;ncias e
        us&aacute;-los com sistemas CRM (
        <em>
          <em>Client Relationship Management</em>
        </em>
        ) e tecnologia avan&ccedil;ada para o benef&iacute;cio da PORT&Atilde;O
        3, de suas Afiliadas ou de terceiros. Da mesma forma, a PORT&Atilde;O 3
        e os Estabelecimentos podem acessar e utilizar os Dados Pessoais dos
        Usu&aacute;rios, para as seguintes finalidades:
      </p>
      <p>&nbsp;</p>
      <p>
        4.3.1. Enviar aos Usu&aacute;rios e-mails e mensagens promocionais e/ou
        ofertas relacionadas com o servi&ccedil;o oferecido pela PORT&Atilde;O 3
        ou pelos Estabelecimentos e que possam ser do interesse do
        Usu&aacute;rio. Caso o Usu&aacute;rio PORT&Atilde;O 3 n&atilde;o deseje
        receber as informa&ccedil;&otilde;es e/ou comunica&ccedil;&otilde;es
        comerciais acima mencionadas, poder&aacute; a qualquer momento escolher
        a op&ccedil;&atilde;o de &ldquo;Cancelar a assinatura&rdquo; no
        pr&oacute;prio e-mail e, consequentemente, o envio das referidas
        informa&ccedil;&otilde;es cessar&aacute; imediatamente.
      </p>
      <p>&nbsp;</p>
      <p>
        4.3.2. Enviar e-mails sobre atualiza&ccedil;&otilde;es da Plataforma,
        aprova&ccedil;&atilde;o de viagens e informa&ccedil;&otilde;es gerais
        sobre a Plataforma.
      </p>
      <p>&nbsp;</p>
      <p>
        4.3.3. Durante a utiliza&ccedil;&atilde;o da Plataforma PORT&Atilde;O 3,
        os Usu&aacute;rios tamb&eacute;m podem receber
        comunica&ccedil;&otilde;es comerciais de terceiros associados &agrave;
        Plataforma, como Facebook e Google, todas baseadas nas
        prefer&ecirc;ncias de privacidade que o Usu&aacute;rio possui nas
        referidas plataformas.
      </p>
      <p>&nbsp;</p>
      <p>
        4.4. Adicionalmente, a PORT&Atilde;O 3 poder&aacute; utilizar os Dados
        Pessoais dos Usu&aacute;rios para as seguintes finalidades:
      </p>
      <p>&nbsp;</p>
      <p>
        4.4.1.{' '}
        <u>
          Cria&ccedil;&atilde;o de conta de acesso &agrave; Plataforma e
          Identifica&ccedil;&atilde;o do Usu&aacute;rio para
          realiza&ccedil;&atilde;o de solicita&ccedil;&otilde;es.
        </u>
        &nbsp;a PORT&Atilde;O 3 utilizar&aacute; dados b&aacute;sicos do
        Usu&aacute;rio para identificar e individualizar o Usu&aacute;rio na
        Plataforma, de modo a gerar as credenciais de acesso e o perfil do
        Usu&aacute;rio na Plataforma e possibilitar sua
        identifica&ccedil;&atilde;o para efetiva&ccedil;&atilde;o das
        solicita&ccedil;&otilde;es.
      </p>
      <p>&nbsp;</p>
      <p>
        4.4.2. <u>An&aacute;lise de perfil e personaliza&ccedil;&atilde;o.</u>
        &nbsp;a PORT&Atilde;O 3 pode utilizar os Dados Pessoais do
        Usu&aacute;rio para analisar seu uso da Plataforma e seu perfil como
        Usu&aacute;rio, buscando identificar, por exemplo, prefer&ecirc;ncias
        pessoais, interesses, fiabilidade, comportamento,
        localiza&ccedil;&atilde;o, entre outros. Essa an&aacute;lise ser&aacute;
        ent&atilde;o utilizada para personalizar a experi&ecirc;ncia do
        Usu&aacute;rio na Plataforma, sugerir conte&uacute;dos, servi&ccedil;os
        e produtos que sejam do seu interesse, entre outros.
      </p>
      <p>&nbsp;</p>
      <p>
        4.4.3. <u>Cobran&ccedil;as e pagamentos.</u>&nbsp;a PORT&Atilde;O 3
        poder&aacute; usar os dados do Usu&aacute;rio para processar pagamentos
        e realizar cobran&ccedil;as relacionadas ao uso da Plataforma e oferta,
        solicita&ccedil;&atilde;o, contrata&ccedil;&atilde;o, fornecimento e
        aquisi&ccedil;&atilde;o de produtos e servi&ccedil;os, conforme disposto
        nos Termos de Uso.
      </p>
      <p>&nbsp;</p>
      <p>
        4.4.4.{' '}
        <u>
          Cumprimento de obriga&ccedil;&otilde;es legais ou regulat&oacute;rias.
        </u>
        &nbsp;a PORT&Atilde;O 3 tratar&aacute; certos Dados Pessoais do
        Usu&aacute;rio para cumprir com suas obriga&ccedil;&otilde;es legais
        e/ou regulat&oacute;rias. Essas obriga&ccedil;&otilde;es podem incluir,
        por exemplo, obriga&ccedil;&otilde;es fiscais e tribut&aacute;rias,
        obriga&ccedil;&otilde;es sobre manuten&ccedil;&atilde;o de registros
        t&eacute;cnicos, documenta&ccedil;&atilde;o de servi&ccedil;os
        contratados, presta&ccedil;&atilde;o de contas, entre outras.
      </p>
      <p>&nbsp;</p>
      <p>
        4.4.5.{' '}
        <u>
          Defesa administrativa, judicial, extrajudicial ou arbitral de
          interesses e direitos da PORT&Atilde;O 3
        </u>
        . a PORT&Atilde;O 3 poder&aacute; utilizar os Dados Pessoais do
        Usu&aacute;rio para exercitar seus pr&oacute;prios direitos e/ou para
        defender seus direitos e interesses perante o pr&oacute;prio
        Usu&aacute;rio ou terceiros. Isso pode incluir tratativas extrajudiciais
        entre as Partes, processos judiciais ou arbitrais, procedimentos
        administrativos, entre outros.
      </p>
      <p>&nbsp;</p>
      <p>
        4.5. <u>Dados Sens&iacute;veis do Usu&aacute;rio</u>. Os Dados
        Sens&iacute;veis do Usu&aacute;rio ser&atilde;o tratados com base no
        consentimento do Usu&aacute;rio. Ao inserir Dados Sens&iacute;veis do
        Usu&aacute;rio na Plataforma (por exemplo, informa&ccedil;&otilde;es
        sobre origem racial ou &eacute;tnica, convic&ccedil;&atilde;o religiosa,
        opini&atilde;o pol&iacute;tica, filia&ccedil;&atilde;o a sindicato ou a
        organiza&ccedil;&atilde;o de car&aacute;ter religioso, filos&oacute;fico
        ou pol&iacute;tico, dado referente &agrave; sa&uacute;de ou &agrave;
        vida sexual, dado gen&eacute;tico ou biom&eacute;trico, quando vinculado
        a uma pessoa natural), o Usu&aacute;rio concede seu consentimento livre,
        informado, espec&iacute;fico e destacado para tratamento de tais dados
        pessoais, para as finalidades detalhadas acima, conforme indicado na
        Plataforma.
      </p>
      <p>&nbsp;</p>
      <p>
        4.6. <u>Demais Tratamentos</u>. Em rela&ccedil;&atilde;o aos demais
        Tratamentos de Dados realizados pela PORT&Atilde;O 3, estes ser&atilde;o
        fundamentados, conforme aplic&aacute;vel a cada caso, nas seguintes
        hip&oacute;teses legais: para execu&ccedil;&atilde;o de um contrato ou
        provid&ecirc;ncias preliminares; para cumprimento de
        obriga&ccedil;&otilde;es legais ou regulat&oacute;rias; para
        exerc&iacute;cio e defesa de direitos e interesses da PORT&Atilde;O 3;
        ou com base em um interesse leg&iacute;timo da PORT&Atilde;O 3, sempre
        considerando e respeitando os direitos e garantias fundamentais
        asseguradas ao Titular dos Dados Pessoais; ou ainda, com base no
        Consentimento, quando expressamente solicitado pela Plataforma.
      </p>
      <p>&nbsp;</p>
      <strong>
        5. A PORT&Atilde;O 3 COMPARTILHA AS INFORMA&Ccedil;&Otilde;ES QUE
        COLETA?
      </strong>
      <p>&nbsp;</p>
      <p>
        5.1. Para o correto desenvolvimento da rela&ccedil;&atilde;o contratual
        e excel&ecirc;ncia na presta&ccedil;&atilde;o do servi&ccedil;o, bem
        como para seu leg&iacute;timo interesse, a PORT&Atilde;O 3 poder&aacute;
        compartilhar determinados Dados Pessoais dos Usu&aacute;rios com:
      </p>
      <p>&nbsp;</p>
      <p>
        (i) Outros Usu&aacute;rios: a Plataforma PORT&Atilde;O 3 intermedia a
        rela&ccedil;&atilde;o entre Estabelecimentos que oferecem e
        comercializam seus produtos e servi&ccedil;os Usu&aacute;rios que
        desejam solicitar e adquirir tais produtos ou servi&ccedil;os do
        Estabelecimento, por meio da utiliza&ccedil;&atilde;o da Plataforma
        PORT&Atilde;O 3. Caso um Usu&aacute;rio solicite a
        aquisi&ccedil;&atilde;o de produtos ou servi&ccedil;os de determinado
        Estabelecimento por meio da Plataforma, os dados do Usu&aacute;rio
        ser&atilde;o compartilhados com referido Estabelecimento com o
        &uacute;nico prop&oacute;sito de viabilizar a transa&ccedil;&atilde;o.
      </p>
      <p>&nbsp;</p>
      <p>
        (ii) Prestadores de servi&ccedil;os: os eventuais prestadores de
        servi&ccedil;os terceirizados da PORT&Atilde;O 3 (em caso de
        terceiriza&ccedil;&atilde;o do atendimento ou solu&ccedil;&atilde;o de
        incidentes com os servi&ccedil;os) ter&atilde;o acesso aos Dados
        Pessoais dos Usu&aacute;rios necess&aacute;rios ao desempenho de suas
        fun&ccedil;&otilde;es, mas n&atilde;o poder&atilde;o utiliz&aacute;-los
        para outros prop&oacute;sitos. Eles devem tratar as
        informa&ccedil;&otilde;es pessoais de acordo com esta Pol&iacute;tica de
        Privacidade e a legisla&ccedil;&atilde;o aplic&aacute;vel sobre
        Prote&ccedil;&atilde;o De Dados.
      </p>
      <p>&nbsp;</p>
      <p>
        (iii) O Usu&aacute;rio tamb&eacute;m pode receber e-mails da
        PORT&Atilde;O 3 e/ou do Estabelecimento para confirmar a
        solicita&ccedil;&atilde;o, organizar um estorno, solicitar a
        avalia&ccedil;&atilde;o do Usu&aacute;rio sobre o servi&ccedil;o ou
        receber um resumo do servi&ccedil;o. Todas as informa&ccedil;&otilde;es
        fornecidas pelos Usu&aacute;rios aos Estabelecimentos por meio dos
        aplicativos de comunica&ccedil;&atilde;o instant&acirc;nea ou quaisquer
        outros meios fora da Plataforma n&atilde;o s&atilde;o cobertas por esta
        Pol&iacute;tica de Privacidade.
      </p>
      <p>&nbsp;</p>
      <p>
        (iv) Empresas de seguran&ccedil;a privada e autoridades e
        &oacute;rg&atilde;os p&uacute;blicos: a PORT&Atilde;O 3 pode divulgar
        Dados Pessoais dos Usu&aacute;rios quando acreditar que sua
        divulga&ccedil;&atilde;o seja necess&aacute;ria para o cumprimento da
        lei, para fazer cumprir ou aplicar os Termos e Condi&ccedil;&otilde;es
        ou esta Pol&iacute;tica de Privacidade, ou para proteger o direitos,
        propriedade ou seguran&ccedil;a da PORT&Atilde;O 3, seus Usu&aacute;rios
        ou terceiros. O anterior inclui, portanto, a troca de
        informa&ccedil;&otilde;es com outras empresas e
        organiza&ccedil;&otilde;es, bem como com as autoridades e
        &Oacute;rg&atilde;os p&uacute;blicos para prote&ccedil;&atilde;o contra
        fraudes e redu&ccedil;&atilde;o de riscos.
      </p>
      <p>&nbsp;</p>
      <p>
        (v) Mediante exig&ecirc;ncia legal: a PORT&Atilde;O 3 pode compartilhar
        informa&ccedil;&otilde;es com &oacute;rg&atilde;os e autoridades
        p&uacute;blicas e/ou terceiros a respeito de solicita&ccedil;&otilde;es
        de informa&ccedil;&otilde;es relacionadas a investiga&ccedil;&otilde;es
        criminais e supostas atividades ilegais ou para o cumprimento de dever
        legal.
      </p>
      <p>&nbsp;</p>
      <p>
        (vi) A fim de prestar servi&ccedil;os: dependendo da &aacute;rea
        geogr&aacute;fica de onde os Usu&aacute;rios solicitam os
        servi&ccedil;os, determinados Dados Pessoais dos Usu&aacute;rios podem
        ser transferidos para as Afiliadas da PORT&Atilde;O 3 para fins de
        execu&ccedil;&atilde;o de contrato. Os Usu&aacute;rios s&atilde;o
        informados de que ao acessar, utilizar ou se cadastrar na Plataforma de
        qualquer pa&iacute;s no qual a PORT&Atilde;O 3 opera, seus Dados
        Pessoais ser&atilde;o armazenados no banco de dados PORT&Atilde;O 3 ou
        de terceiros, no Brasil ou no exterior. Nestes casos, a
        transfer&ecirc;ncia internacional de Dados Pessoais ser&aacute;
        realizada em conformidade com a legisla&ccedil;&atilde;o
        aplic&aacute;vel.
      </p>
      <p>&nbsp;</p>
      <p>
        (vii) Prestadores de servi&ccedil;os para o anonimato de alguns dados:
        para evitar o uso indevido de Dados Pessoais do Usu&aacute;rio por
        prestadores de servi&ccedil;os terceirizados, a PORT&Atilde;O 3 pode
        transferir os Dados Pessoais do Usu&aacute;rio para que possam ser
        anonimizados e usados apenas para a presta&ccedil;&atilde;o do
        servi&ccedil;o aos Usu&aacute;rios. Por exemplo, a PORT&Atilde;O 3 pode
        transferir o n&uacute;mero de telefone dos Usu&aacute;rios a terceiros
        para torn&aacute;-los an&ocirc;nimos e fornec&ecirc;-los neste formato
        aos Estabelecimentos e provedores utilizados para cumprir os
        servi&ccedil;os contratados pelos Usu&aacute;rios.
      </p>
      <p>&nbsp;</p>
      <p>
        (viii) Os Dados Pessoais dos Usu&aacute;rios s&atilde;o armazenados nos
        servidores da Retool (https://retool.com/) localizados nos Estados
        Unidos da Am&eacute;rica. A PORT&Atilde;O 3 declara que os referidos
        servidores cumprem a Legisla&ccedil;&atilde;o de Prote&ccedil;&atilde;o
        de Dados e os compromissos previstos nesta Pol&iacute;tica de
        Privacidade. Os Usu&aacute;rios autorizam expressamente as Afiliadas da
        PORT&Atilde;O 3 a acessar seus dados pessoais de qualquer
        territ&oacute;rio para fins de presta&ccedil;&atilde;o do servi&ccedil;o
        solicitado pelo Usu&aacute;rio.
      </p>
      <p>&nbsp;</p>
      <p>
        (ix) Os Dados Pessoais dos Usu&aacute;rios n&atilde;o ser&atilde;o
        transmitidos a terceiros, exceto que (a) sejam necess&aacute;rios para
        os servi&ccedil;os solicitados, (b) quando o Usu&aacute;rio solicitar ou
        consentir expressamente com o compartilhamento (c) quando solicitado por
        autoridade competente no exerc&iacute;cio de suas fun&ccedil;&otilde;es
        (inclusive para investigar, prevenir ou praticar atos relacionados a
        atos il&iacute;citos) ou (d) se exigido por lei.
      </p>
      <p>&nbsp;</p>
      <p>
        (x) Tamb&eacute;m a terceiros parceiros comerciais e n&atilde;o
        comerciais para uso para (a) fins estat&iacute;sticos, desde que os
        dados estejam anonimizados &nbsp;e (b) realizar
        comunica&ccedil;&otilde;es comerciais aos Usu&aacute;rios, desde que os
        referidos parceiros cumpram com a legisla&ccedil;&atilde;o em vigor e
        com esta Pol&iacute;tica de Privacidade.
      </p>
      <p>&nbsp;</p>
      <p>
        5.2. Compartilhamento com Operadores do Tratamento. A PORT&Atilde;O 3
        poder&aacute; contratar terceiros que a auxiliem na
        presta&ccedil;&atilde;o de seus servi&ccedil;os, como por exemplos,
        servidores de armazenamento em nuvem e plataformas de processamento de
        pagamentos. No momento, a PORT&Atilde;O 3 compartilha Dados Pessoas dos
        Usu&aacute;rios com provedores de infraestrutura (servidores, hospedagem
        e servi&ccedil;os cloud) para o devido funcionamento da Plataforma,
        prestadores de servi&ccedil;os de TI, parceiros relacionados ao
        processamento de pagamentos, fornecedores estes, no entanto, que podem
        ser substitu&iacute;dos a qualquer momento, desde que mantidos
        padr&otilde;es adequados de seguran&ccedil;a e confidencialidade dos
        dados. A PORT&Atilde;O 3, na qualidade de Controladora dos Dados
        Pessoais, exigir&aacute; de tais parceiros Operadores n&iacute;veis
        adequados de seguran&ccedil;a e confidencialidade.
      </p>
      <p>&nbsp;</p>
      <p>
        5.3. Compartilhamento com Controladores Independentes. Em
        rela&ccedil;&atilde;o aos Dados Pessoais dos Usu&aacute;rios que sejam
        Tratados pelos Estabelecimentos ou aos Dados Pessoais dos
        Estabelecimentos que sejam Tratados pelos Usu&aacute;rios para a oferta,
        negocia&ccedil;&atilde;o, solicita&ccedil;&atilde;o,
        contrata&ccedil;&atilde;o, aquisi&ccedil;&atilde;o e/ou fornecimento de
        produtos e servi&ccedil;os pelos Estabelecimentos aos Usu&aacute;rios, a
        PORT&Atilde;O 3, o Estabelecimento e o Usu&aacute;rio ser&atilde;o
        considerados Controladores Independentes de tais Dados Pessoais,
        assumindo responsabilidade pelo Tratamento realizado por si utilizando a
        Plataforma ou fora dela.
      </p>
      <p>&nbsp;</p>
      <p>
        5.4. Outros Terceiros. Em adi&ccedil;&atilde;o &agrave;s
        hip&oacute;teses j&aacute; mencionadas nesta Pol&iacute;tica de
        Privacidade, os Dados Pessoais poder&atilde;o tamb&eacute;m ser
        transferidos a terceiros, inclusive na qualidade de Controladores, nos
        seguintes casos:
      </p>
      <p>&nbsp;</p>
      <p>
        (i) Novos Neg&oacute;cios. Poder&aacute; haver a transfer&ecirc;ncia de
        Dados Pessoais para e entre Afiliadas da PORT&Atilde;O 3 e/ou no
        contexto processos de aquisi&ccedil;&atilde;o, venda, fus&atilde;o,
        reorganiza&ccedil;&atilde;o societ&aacute;ria ou qualquer outra
        mudan&ccedil;a de controle da PORT&Atilde;O 3. Nesse caso, a
        PORT&Atilde;O 3 ir&aacute; garantir a que a pessoa, f&iacute;sica ou
        jur&iacute;dica, que venha a acessar ou assumir o controle sobre os
        Dados Pessoais Tratados nos termos desta Pol&iacute;tica de Privacidade
        sejam tamb&eacute;m vinculados a ela, garantindo a continuidade da
        prote&ccedil;&atilde;o dos Dados Pessoais, e comunicando
        Usu&aacute;rios, com anteced&ecirc;ncia, caso essa transfer&ecirc;ncia
        implique qualquer altera&ccedil;&atilde;o na Pol&iacute;tica de
        Privacidade.
      </p>
      <p>&nbsp;</p>
      <p>
        (ii) Exerc&iacute;cio regular de direitos por via administrativa ou
        judicial ou extrajudicial. A PORT&Atilde;O 3 pode compartilhar Dados
        Pessoais com terceiros como escrit&oacute;rios de advocacia,
        consultorias, empresas de cobran&ccedil;a, escrit&oacute;rios de
        contabilidade, e outros semelhantes, com a finalidade de exercer seus
        pr&oacute;prios direitos ou para garantir o cumprimento e
        aplica&ccedil;&atilde;o desta Pol&iacute;tica de Privacidade e dos
        Termos de Uso.
      </p>
      <p>&nbsp;</p>
      <p>
        (iii) Requisi&ccedil;&atilde;o Judicial ou Administrativa. A
        PORT&Atilde;O 3 pode compartilhar Dados Pessoais em caso de
        requisi&ccedil;&atilde;o judicial ou administrativa.
      </p>
      <p>&nbsp;</p>
      <p>
        (iv) Cumprimento de obriga&ccedil;&atilde;o legal ou regulat&oacute;ria.
        A PORT&Atilde;O 3 pode compartilhar Dados Pessoais com
        &oacute;rg&atilde;os, autoridades e demais entes do poder
        p&uacute;blico, bem como a pessoas f&iacute;sicas ou jur&iacute;dicas de
        natureza privada, em cumprimento de obriga&ccedil;&atilde;o legal ou
        regulat&oacute;ria.
      </p>
      <p>&nbsp;</p>
      <p>
        (v) Com a autoriza&ccedil;&atilde;o do Usu&aacute;rio. Em demais casos,
        havendo a necessidade de compartilhamento das informa&ccedil;&otilde;es,
        enviaremos ao Usu&aacute;rio uma notifica&ccedil;&atilde;o solicitando
        sua aprova&ccedil;&atilde;o.
      </p>
      <p>&nbsp;</p>
      <p>
        5.5. A PORT&Atilde;O 3 compartilha as informa&ccedil;&otilde;es dos
        Usu&aacute;rios, conforme descrito, para cumprir com
        obriga&ccedil;&atilde;o legal ou regulat&oacute;ria, exerc&iacute;cio
        regular de direitos da PORT&Atilde;O 3, para execu&ccedil;&atilde;o do
        contrato celebrado com o Usu&aacute;rio, bem como para o leg&iacute;timo
        interesse da PORT&Atilde;O 3.
      </p>
      <p>
        <strong>
          <strong>&nbsp;</strong>
        </strong>
      </p>
      <strong> 6. QUAIS S&Atilde;O OS DIREITOS DOS USU&Aacute;RIOS?</strong>
      <p>&nbsp;</p>
      <p>
        6.1. A PORT&Atilde;O 3 disponibiliza ferramentas para que os
        Usu&aacute;rios exer&ccedil;am os seus direitos legais sobre os Dados
        Pessoais dos quais s&atilde;o Titulares. Neste item descreveremos esses
        direitos e como os Usu&aacute;rios podem exerc&ecirc;-los.
      </p>
      <p>&nbsp;</p>
      <p>
        (i) Confirma&ccedil;&atilde;o da exist&ecirc;ncia de tratamento:
        Usu&aacute;rios podem confirmar se a PORT&Atilde;O 3 est&aacute;
        Tratando os seus Dados Pessoais;
      </p>
      <p>&nbsp;</p>
      <p>
        (ii) Acesso aos Dados Pessoais: Usu&aacute;rios podem acessar os seus
        Dados Pessoais, inclusive solicitando c&oacute;pia dos Dados Tratados;
      </p>
      <p>&nbsp;</p>
      <p>
        (iii) Corre&ccedil;&atilde;o de Dados incompletos, inexatos ou
        desatualizados: Usu&aacute;rios podem solicitar altera&ccedil;&atilde;o
        ou corre&ccedil;&atilde;o dos seus Dados Pessoais que se apresentem
        incorretos;
      </p>
      <p>&nbsp;</p>
      <p>
        (iv) Anonimiza&ccedil;&atilde;o, bloqueio ou elimina&ccedil;&atilde;o:
        Usu&aacute;rios podem solicitar a anonimiza&ccedil;&atilde;o, bloqueio
        ou elimina&ccedil;&atilde;o de dados desnecess&aacute;rios, excessivos
        ou tratados em desconformidade com o disposto na
        Legisla&ccedil;&atilde;o de Prote&ccedil;&atilde;o de Dados;
      </p>
      <p>&nbsp;</p>
      <p>
        (v) Portabilidade. Usu&aacute;rios podem solicitar a portabilidade dos
        seus Dados para outro fornecedor de servi&ccedil;os por meio de
        ferramentas de exporta&ccedil;&atilde;o constantes da Plataforma ou por
        solicita&ccedil;&atilde;o pelos canais de atendimento indicados nesta
        Pol&iacute;tica de Privacidade;
      </p>
      <p>&nbsp;</p>
      <p>
        (vi) Elimina&ccedil;&atilde;o dos Dados Pessoais. Usu&aacute;rios podem
        solicitar a exclus&atilde;o de seus Dados Pessoais tratados pela
        PORT&Atilde;O 3 quando estes forem coletados e tratados com base em seu
        consentimento, por meio da pr&oacute;pria Plataforma ou por
        solicita&ccedil;&atilde;o pelos canais de atendimento indicados nesta
        Pol&iacute;tica de Privacidade;
      </p>
      <p>&nbsp;</p>
      <p>
        (vii) Informa&ccedil;&atilde;o sobre compartilhamento. Usu&aacute;rios
        podem solicitar informa&ccedil;&atilde;o sobre com quais entidades
        p&uacute;blicas e privadas a PORT&Atilde;O 3 compartilhou os seus Dados
        Pessoais, nos termos desta Pol&iacute;tica;
      </p>
      <p>&nbsp;</p>
      <p>
        (viii) Informa&ccedil;&atilde;o sobre a possibilidade de n&atilde;o
        consentir. Usu&aacute;rios recebem por meio da presente Pol&iacute;tica
        de Privacidade e podem solicitar, pelos canais de atendimento,
        informa&ccedil;&otilde;es sobre a possibilidade de n&atilde;o consentir
        com o Tratamento de Dados Pessoais e suas potenciais
        consequ&ecirc;ncias, inclusive em rela&ccedil;&atilde;o &agrave;
        impossibilidade de presta&ccedil;&atilde;o dos servi&ccedil;os da
        PORT&Atilde;O 3;
      </p>
      <p>&nbsp;</p>
      <p>
        (ix) Revoga&ccedil;&atilde;o do consentimento. Usu&aacute;rios podem, a
        qualquer momento e a seu crit&eacute;rio, revogar o consentimento
        anteriormente fornecido para Tratamento de Dados Pessoais, permanecendo
        os tratamentos realizados com base nele, at&eacute; tal momento,
        v&aacute;lidos.
      </p>
      <p>&nbsp;</p>
      <p>
        6.2. Exerc&iacute;cio de direitos. Os direitos mencionados acima e
        outros previstos na legisla&ccedil;&atilde;o aplic&aacute;vel podem ser
        exercidos pelo Titular dos Dados diretamente pela Plataforma, conforme
        funcionalidades nela disponibilizadas (como por exemplo, ferramentas de
        acesso e edi&ccedil;&atilde;o de Dados Pessoais) ou por meio de
        solicita&ccedil;&atilde;o dirigida ao e-mail oi@portao3.com.br. As
        solicita&ccedil;&otilde;es dever&atilde;o conter, no m&iacute;nimo, nome
        do Titular dos Dados, direito a ser exercido, detalhes e
        especifica&ccedil;&otilde;es sobre a solicita&ccedil;&atilde;o, CPF ou
        CNPJ e endere&ccedil;o de e-mail do Usu&aacute;rio. A PORT&Atilde;O 3 se
        reserva o direito de solicitar outras informa&ccedil;&otilde;es ou
        documentos para comprova&ccedil;&atilde;o das alega&ccedil;&otilde;es do
        solicitante.
      </p>
      <p>&nbsp;</p>
      <p>
        6.3. Consequ&ecirc;ncias do apagamento de dados. &Eacute; importante
        ressaltar que, em algumas hip&oacute;teses, ao cumprir com eventuais
        solicita&ccedil;&otilde;es de exclus&atilde;o, que, quando
        poss&iacute;vel, ser&atilde;o informadas aos Usu&aacute;rios, a
        PORT&Atilde;O 3 n&atilde;o poder&aacute; prestar os seus
        servi&ccedil;os. Dessa forma, a solicita&ccedil;&atilde;o de
        exclus&atilde;o de Dados Pessoais implicar&aacute; a imediata
        desativa&ccedil;&atilde;o da conta do Usu&aacute;rio na Plataforma, com
        perda permanente de qualquer um desses Dados inseridos na Plataforma.
        Ap&oacute;s a exclus&atilde;o desses Dados Pessoais, a PORT&Atilde;O 3
        poder&aacute; continuar a us&aacute;-los, de forma n&atilde;o
        individualizada e anonimizada, ou seja, sem qualquer
        identifica&ccedil;&atilde;o pessoal, para os fins previstos nesta
        Pol&iacute;tica de Privacidade.
      </p>
      <p>&nbsp;</p>
      <p>
        6.4. Reten&ccedil;&atilde;o de dados. A PORT&Atilde;O 3 poder&aacute;
        guardar os Dados Pessoais de certos Usu&aacute;rios por um
        per&iacute;odo superior ao de guarda legal, em cumprimento de eventuais
        ordens de autoridade p&uacute;blicas, para se defender em processos
        judiciais e/ou administrativos e em casos nos quais os Dados Pessoais
        tenham sido devidamente anonimizados.
      </p>
      <p>&nbsp;</p>
      <p>
        6.5. EM OBSERV&Acirc;NCIA &Agrave; LEGISLA&Ccedil;&Atilde;O
        APLIC&Aacute;VEL OU CUMPRIMENTO DE ORDEM JUDICIAL, A PORT&Atilde;O 3
        PODER&Aacute; MANTER DETERMINADOS DADOS PESSOAIS SEUS ARMAZENADOS POR UM
        PER&Iacute;ODO N&Atilde;O INFERIOR A 6 (SEIS) MESES, AP&Oacute;S O SEU
        PEDIDO DE EXCLUS&Atilde;O. REFERIDOS DADOS N&Atilde;O SER&Atilde;O
        ANONIMIZADOS OU DESTRU&Iacute;DOS PELA PORT&Atilde;O 3 ANTES DA
        CONCLUS&Atilde;O DESSE PRAZO. A PORT&Atilde;O 3 ARMAZENAR&Aacute; A SUA
        SOLICITA&Ccedil;&Atilde;O DE EXCLUS&Atilde;O E, OBSERVADO O PRAZO LEGAL
        DE GUARDA OBRIGAT&Oacute;RIA DE ALGUNS DETERMINADOS DADOS,
        PROVIDENCIAR&Aacute; A DESTRUI&Ccedil;&Atilde;O OU
        ANONIMIZA&Ccedil;&Atilde;O, A CRIT&Eacute;RIO EXCLUSIVO DA PORT&Atilde;O
        3, DAS INFORMA&Ccedil;&Otilde;ES CAPAZES DE IDENTIFICAR O
        USU&Aacute;RIO.
      </p>
      <p>&nbsp;</p>
      <p>
        CASO O USU&Aacute;RIO SOLICITE A EXCLUS&Atilde;O DAS SUAS
        INFORMA&Ccedil;&Otilde;ES, MAS AINDA TENHA ALGUMA
        OBRIGA&Ccedil;&Atilde;O PENDENTE DE CUMPRIMENTO COM A PORT&Atilde;O 3,
        AS SUAS INFORMA&Ccedil;&Otilde;ES N&Atilde;O SER&Atilde;O
        EXCLU&Iacute;DAS E PERMANECER&Atilde;O ARMAZENADAS PARA O FIM DE
        VIABILIZAR A SOLU&Ccedil;&Atilde;O DA PEND&Ecirc;NCIA E A
        ADO&Ccedil;&Atilde;O DE MEDIDAS CAB&Iacute;VEIS.
      </p>
      <p>
        <strong>
          <strong>&nbsp;</strong>
        </strong>
      </p>
      <strong>
        <strong> 7. COMO ARMAZENAMOS OS DADOS PESSOAIS</strong>
      </strong>
      <p>&nbsp;</p>
      <p>
        7.1. Seguran&ccedil;a. A PORT&Atilde;O 3 far&aacute; todo o
        poss&iacute;vel para manter os Dados Pessoais sempre seguros e,
        inclusive, ir&aacute; adotar medidas de seguran&ccedil;a e de
        prote&ccedil;&atilde;o, t&eacute;cnicas e administrativas,
        compat&iacute;veis com a natureza dos Dados Pessoais coletados, usados,
        armazenados ou de outra forma Tratados pela PORT&Atilde;O 3, em
        conformidade com as pr&aacute;ticas apropriadas de mercado.
      </p>
      <p>&nbsp;</p>
      <p>
        7.1.1. Exce&ccedil;&otilde;es. No entanto, nenhum m&eacute;todo de
        transmiss&atilde;o ou reten&ccedil;&atilde;o de dados eletr&ocirc;nicos
        &eacute; plenamente seguro e pode estar sujeito a ataques externos.
        Dessa forma, a PORT&Atilde;O 3 n&atilde;o pode garantir que tais medidas
        de seguran&ccedil;a sejam isentas de erros ou que n&atilde;o estejam
        sujeitas a interfer&ecirc;ncia de terceiros (hackers, entre outros). Por
        sua natureza, apesar dos melhores esfor&ccedil;os da PORT&Atilde;O 3,
        qualquer medida de seguran&ccedil;a pode falhar e qualquer Dado Pessoal
        pode se tornar p&uacute;blico. O USU&Aacute;RIO ENTENDE E ASSUME
        EXPRESSAMENTE ESSE RISCO E CONCORDA QUE A PORT&Atilde;O 3 N&Atilde;O
        SER&Aacute; RESPONS&Aacute;VEL POR TAL TIPO DE VAZAMENTO OU ACESSO
        N&Atilde;O AUTORIZADO AOS DADOS PESSOAIS.
      </p>
      <p>&nbsp;</p>
      <p>
        7.1.2. Caso ocorra uma Viola&ccedil;&atilde;o de Seguran&ccedil;a na
        Plataforma, o Titular e a Autoridade Nacional de Prote&ccedil;&atilde;o
        de Dados (ANPD) ser&atilde;o notificados da exist&ecirc;ncia de tal
        incidente, e a PORT&Atilde;O 3 informar&aacute;:
      </p>
      <p>&nbsp;</p>
      <p>(i) A natureza dos Dados Pessoais afetados;</p>
      <p>(ii) Informa&ccedil;&otilde;es sobre os Titulares envolvidos;</p>
      <p>
        (iii) As medidas t&eacute;cnicas e de seguran&ccedil;a utilizadas para a
        prote&ccedil;&atilde;o dos Dados Pessoais, observados os segredos
        comercial e industrial;
      </p>
      <p>(iv) Os riscos relacionados ao incidente; e</p>
      <p>
        (v) As medidas que foram ou que ser&atilde;o adotadas para reverter ou
        mitigar os efeitos do preju&iacute;zo.
      </p>
      <p>&nbsp;</p>
      <p>
        7.1.3. As medidas de seguran&ccedil;a acima descritas aplicam-se aos
        Dados Pessoais do Usu&aacute;rio somente a partir do momento em que a
        PORT&Atilde;O 3 as recebe e enquanto as mant&eacute;m sob sua guarda. O
        funcionamento e a seguran&ccedil;a do dispositivo que o Usu&aacute;rio
        usa para acessar a Plataforma, bem como redes terceiras pelas quais os
        dados trafegam n&atilde;o s&atilde;o de responsabilidade da
        PORT&Atilde;O 3.
      </p>
      <p>&nbsp;</p>
      <p>
        7.2. Transfer&ecirc;ncias internacionais. Por todo o tempo em que o
        Usu&aacute;rio acessar, utilizar ou mantiver a sua conta na Plataforma
        PORT&Atilde;O 3 ativa, todas as informa&ccedil;&otilde;es coletadas
        ser&atilde;o armazenadas com alto padr&atilde;o de seguran&ccedil;a em
        servidores pr&oacute;prios, em servidores operados e controlados pela
        PORT&Atilde;O 3, ou mesmo em servidores de terceiros, localizados no
        Brasil ou no exterior. O Usu&aacute;rio fica desde j&aacute; ciente que
        a PORT&Atilde;O 3 poder&aacute; armazenar seus Dados Pessoais em
        servidores fora do Brasil e/ou utilizar prestadores de servi&ccedil;o
        que n&atilde;o se encontram no territ&oacute;rio brasileiro. Nesses
        casos, a PORT&Atilde;O 3 observar&aacute; os requisitos legais para tais
        transfer&ecirc;ncias internacionais, assegurando o mesmo n&iacute;vel de
        seguran&ccedil;a aplicado aos Tratamentos realizados em
        territ&oacute;rio brasileiro.
      </p>
      <p>&nbsp;</p>
      <p>
        7.3. Prazo de armazenamento. Os Dados Pessoais ser&atilde;o conservados
        enquanto forem necess&aacute;rios para as finalidades elencadas nesta
        Pol&iacute;tica de Privacidade . Isso pode significar, por exemplo, que
        os Registro de Acesso ser&atilde;o armazenados por pelo menos 6 (seis)
        meses, como exigido pela lei, ou por prazo maior, caso assim solicitado
        pelo Titular do Dados Pessoais ou determinado por ordem judicial. Outros
        Dados Pessoais ser&atilde;o conservados pelo prazo de
        prescri&ccedil;&atilde;o de eventual responsabilidade civil, de modo a
        permitir a defesa da PORT&Atilde;O 3 em ju&iacute;zo, por exemplo. A
        PORT&Atilde;O 3 adota controles para garantir que os Dados Pessoais
        sejam conservados unicamente enquanto sejam de fato necess&aacute;rios,
        sendo descartados sempre que encerrado o Tratamento ou n&atilde;o
        aplic&aacute;vel alguma hip&oacute;tese legal de reten&ccedil;&atilde;o.
      </p>
      <p>&nbsp;</p>
      <p>
        7.4. Elimina&ccedil;&atilde;o dos Dados. Quando n&atilde;o precisarmos
        mais usar os Dados Pessoais, eles ser&atilde;o removidos de nossos
        sistemas e registros ou anonimizados, para que o Usu&aacute;rio
        n&atilde;o possa mais ser identificado a partir desses dados. A
        PORT&Atilde;O 3 poder&aacute; reter alguns Dados Pessoais para cumprir
        nossas obriga&ccedil;&otilde;es legais ou regulat&oacute;rias, bem como
        para permitir e garantir o exerc&iacute;cio regular de nossos direitos
        (por exemplo, em processo judicial, administrativo ou arbitral). Para
        fins de auditoria, seguran&ccedil;a, controle de fraudes e
        preserva&ccedil;&atilde;o de direitos, a PORT&Atilde;O 3 poder&aacute;
        permanecer com o hist&oacute;rico de registro dos Dados Pessoais por
        prazo maior nas hip&oacute;teses que a lei ou norma regulat&oacute;ria
        assim estabelecer ou para preserva&ccedil;&atilde;o de direitos.
      </p>
      <p>&nbsp;</p>
      <p>
        7.5. LIMITA&Ccedil;&Atilde;O DE RESPONSABILIDADE. NADA DO QUE
        EST&Aacute; ESCRITO NA POL&Iacute;TICA DE PRIVACIDADE TEM COMO
        INTEN&Ccedil;&Atilde;O EXCLUIR OU LIMITAR QUALQUER
        CONDI&Ccedil;&Atilde;O, GARANTIA, DIREITO OU RESPONSABILIDADE QUE
        N&Atilde;O POSSA SER LEGALMENTE EXCLU&Iacute;DA OU LIMITADA. ALGUMAS
        JURISDI&Ccedil;&Otilde;ES N&Atilde;O PERMITEM A EXCLUS&Atilde;O DE
        CERTAS GARANTIAS OU CONDI&Ccedil;&Otilde;ES OU A LIMITA&Ccedil;&Atilde;O
        OU EXCLUS&Atilde;O DE RESPONSABILIDADE POR PERDAS E DANOS.
        CONSEQUENTEMENTE, APENAS AS LIMITA&Ccedil;&Otilde;ES QUE FOREM
        PERMITIDAS POR LEI NA SUA JURISDI&Ccedil;&Atilde;O SE APLICAR&Atilde;O A
        VOC&Ecirc;. NOS CASOS EM QUE EXCLUS&Atilde;O DE RESPONSABILIDADE
        N&Atilde;O SE MOSTRAR POSS&Iacute;VEL, MAS A LIMITA&Ccedil;&Atilde;O DE
        RESPONSABILIDADE FOR LEGALMENTE APLIC&Aacute;VEL, A RESPONSABILIDADE
        TOTAL DA PORT&Atilde;O 3 ESTAR&Aacute; LIMITADA A R$ 1.000,00 (MIL
        REAIS).
      </p>
      <p>&nbsp;</p>
      <strong>
        <strong>
          {' '}
          8. RESPONSABILIDADE DO USU&Aacute;RIO NA PROTE&Ccedil;&Atilde;O DE
          SEUS DADOS
        </strong>
      </strong>
      <p>&nbsp;</p>
      <p>
        8.1. N&atilde;o obstante mantermos os Dados Pessoais confidenciais, de
        acordo com os termos desta Pol&iacute;tica de Privacidade, ser&aacute;
        responsabilidade de cada Usu&aacute;rio manter o login e a senha de
        acesso de sua conta seguras, n&atilde;o devendo fornecer as mesmas a
        ningu&eacute;m.
      </p>
      <p>&nbsp;</p>
      <p>
        8.2. Caso o Usu&aacute;rio acredite que seu o login e senha de acesso a
        sua conta na Plataforma tenham sido indevidamente acessados por
        terceiros ou sejam de conhecimento de outras pessoas n&atilde;o
        autorizadas, por qualquer raz&atilde;o, o Usu&aacute;rio dever&aacute;
        imediatamente comunicar &agrave; PORT&Atilde;O 3 &nbsp;atrav&eacute;s do
        e-mail oi@portao3.com.br, sem preju&iacute;zo da altera&ccedil;&atilde;o
        de senha imediata por meio da pr&oacute;pria Plataforma pelo
        Usu&aacute;rio.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>
          <strong>9. COOKIES</strong>
        </strong>
      </p>
      <p>&nbsp;</p>
      <p>
        9.1. A PORT&Atilde;O 3 utiliza Cookies e tecnologias similares, como
        pixels e tags, para certificar-se de que os servi&ccedil;os prestados
        est&atilde;o de acordo com os nossos padr&otilde;es de qualidade. Os
        Cookies coletam somente estat&iacute;sticas e n&atilde;o ser&atilde;o
        utilizados para prop&oacute;sitos diversos dos expressamente previstos
        nesta Pol&iacute;tica de Privacidade.
      </p>
      <p>&nbsp;</p>
      <p>
        9.2. O que s&atilde;o Cookies e para que servem? Cookie &eacute; um
        pequeno arquivo adicionado ao seu aparelho ou computador para fornecer
        uma experi&ecirc;ncia personalizada de acesso &agrave; Plataforma. A
        PORT&Atilde;O 3 pode utilizar Cookies:
      </p>
      <p>&nbsp;</p>
      <p>
        9.2.1. Necess&aacute;rios: Cookies que viabilizam a
        utiliza&ccedil;&atilde;o da Plataforma e sem os quais a Plataforma pode
        n&atilde;o funcionar adequadamente. Como tais Cookies s&atilde;o
        necess&aacute;rios, eles s&atilde;o utilizados com base na
        execu&ccedil;&atilde;o do contrato entre a PORT&Atilde;O 3 e o
        Usu&aacute;rio.
      </p>
      <p>&nbsp;</p>
      <p>
        9.2.2. Funcionais: Cookies que ativam fun&ccedil;&otilde;es adicionais
        ou servem para possibilitar o acesso a certas se&ccedil;&otilde;es
        espec&iacute;ficas da Plataforma. O uso de tais Cookies n&atilde;o
        &eacute; absolutamente necess&aacute;rio para uso do servi&ccedil;o, mas
        caso escolha desabilit&aacute;-los o Usu&aacute;rio poder&aacute; ter
        funcionalidades reduzidas, de modo que o uso desses Cookies &eacute;
        fundado no seu consentimento ao ativar tais fun&ccedil;&otilde;es.
      </p>
      <p>&nbsp;</p>
      <p>
        9.2.3. Performance: Cookies utilizados para medir e melhorar a
        performance de determinada p&aacute;gina web e conte&uacute;dos
        espec&iacute;ficos contidos na p&aacute;gina.
      </p>
      <p>&nbsp;</p>
      <p>
        9.2.4. Redes sociais: A Plataforma utiliza plugins de redes sociais, que
        permitem acess&aacute;-las a partir da Plataforma. Assim, ao
        faz&ecirc;-lo, os Cookies utilizados por elas poder&atilde;o ser
        armazenados no navegador do Usu&aacute;rio. Cada rede social possui sua
        pr&oacute;pria pol&iacute;tica de privacidade e de
        prote&ccedil;&atilde;o de dados pessoais, sendo as pessoas
        f&iacute;sicas ou jur&iacute;dicas que as mant&ecirc;m
        respons&aacute;veis pelos Dados Pessoais coletados e pelas
        pr&aacute;ticas de privacidade adotadas. O Usu&aacute;rio pode
        pesquisar, junto &agrave;s redes sociais, informa&ccedil;&otilde;es
        sobre como seus Dados Pessoais s&atilde;o Tratados.
      </p>
      <p>&nbsp;</p>
      <p>
        A PORT&Atilde;O 3 N&Atilde;O POSSUI QUALQUER CONTROLE OU
        INGER&Ecirc;NCIA SOBRE ESSES SITES DE TERCEIROS E N&Atilde;O SER&Aacute;
        RESPONS&Aacute;VEL PELOS CONTE&Uacute;DOS, PR&Aacute;TICAS E
        SERVI&Ccedil;OS OFERTADOS POR QUAISQUER TERCEIROS, NEM PELO TRATAMENTO
        DADO A SEUS DADOS PESSOAIS POR ESSES SITES E REDES SOCIAIS, AINDA QUANDO
        OS LINKS ESTIVEREM CONSTANTES NA PLATAFORMA PORT&Atilde;O 3.
      </p>
      <p>&nbsp;</p>
      <p>
        9.3. &Eacute; poss&iacute;vel limitar a utiliza&ccedil;&atilde;o de
        Cookies? Os navegadores em geral permitem que seja desabilitada a coleta
        de Cookies, desta forma, caso o Usu&aacute;rio n&atilde;o altere as
        pol&iacute;ticas de coleta de cookie do seu navegador N&oacute;s iremos
        considerar que o Usu&aacute;rio n&atilde;o se op&otilde;e &agrave;
        utiliza&ccedil;&atilde;o de Cookies pela Plataforma. A eventual coleta
        de Dados Pessoais identificados por Cookies necess&aacute;rios
        ser&aacute; baseada na necessidade de fornecer a Plataforma
        (execu&ccedil;&atilde;o de contrato), enquanto a
        implementa&ccedil;&atilde;o dos demais Cookies, caso implique tratamento
        de Dados Pessoais identificados, ser&aacute; baseada em um
        leg&iacute;timo interesse da PORT&Atilde;O 3. Neste &uacute;ltimo caso,
        o Usu&aacute;rio pode opor-se a tal tratamento pelo ajuste das
        configura&ccedil;&otilde;es do seu navegador para rejeitar tais Cookies.
      </p>
      <p>
        <strong>
          <strong>&nbsp;</strong>
        </strong>
      </p>
      <strong> CONTATO COM A PORT&Atilde;O 3</strong>
      <p>&nbsp;</p>
      <p>
        10.1. Em caso de d&uacute;vida, sugest&otilde;es,
        reclama&ccedil;&otilde;es ou esclarecimentos acerca desta
        Pol&iacute;tica de Privacidade ou do Tratamento de Dados Pessoais pela
        PORT&Atilde;O 3, ou para requerimento do exerc&iacute;cio de algum dos
        direitos descritos nesta Pol&iacute;tica de Privacidade ou na
        Legisla&ccedil;&atilde;o de Prote&ccedil;&atilde;o de Dados, o
        Usu&aacute;rio poder&aacute; entre em contato com o nosso Encarregado de
        Dados, nos termos da lei, por meio do e-mail: oi@portao3.com.br. A
        PORT&Atilde;O 3 ter&aacute; a satisfa&ccedil;&atilde;o de esclarecer-lhe
        eventuais d&uacute;vidas e/ou atender &agrave; sua
        solicita&ccedil;&atilde;o.
      </p>
      <p>&nbsp;</p>
      <strong>
        <strong>
          {' '}
          11. ATUALIZA&Ccedil;&Otilde;ES DA POL&Iacute;TICA DE PRIVACIDADE
        </strong>
      </strong>
      <p>
        <strong>
          <strong>&nbsp;</strong>
        </strong>
      </p>
      <p>
        11.1. Devido &agrave; cont&iacute;nua evolu&ccedil;&atilde;o das
        atividades da PORT&Atilde;O 3, esta Pol&iacute;tica de Privacidade e os
        Termos de Uso podem ser modificados. A PORT&Atilde;O 3 enviar&aacute; ao
        Usu&aacute;rio avisos sobre as altera&ccedil;&otilde;es e
        modifica&ccedil;&otilde;es substanciais dos referidos documentos por
        e-mail, ou qualquer outro meio que garanta seu recebimento. Em qualquer
        caso, a PORT&Atilde;O 3 em nenhum caso modificar&aacute; as
        pol&iacute;ticas ou pr&aacute;ticas para torn&aacute;-las menos eficazes
        na prote&ccedil;&atilde;o dos Dados Pessoais previamente armazenados de
        nossos Usu&aacute;rios.&nbsp;O Usu&aacute;rio deve verificar essa
        p&aacute;gina e rever esta Pol&iacute;tica de Privacidade periodicamente
        para garantir que concorda com as modifica&ccedil;&otilde;es.
      </p>
      <p>&nbsp;</p>
      <p>
        11.2. Exceto quando o consentimento expresso for obrigat&oacute;rio,
        caso o Usu&aacute;rio continue utilizando a Plataforma e/ou n&atilde;o
        apresente obje&ccedil;&otilde;es &agrave;s altera&ccedil;&otilde;es e
        aos novos termos informados pela PORT&Atilde;O 3 ap&oacute;s a
        divulga&ccedil;&atilde;o da nova vers&atilde;o da Pol&iacute;tica de
        Privacidade, ser&aacute; entendido que este est&aacute; plenamente
        ciente e concorda com os novos termos aplic&aacute;veis ao Tratamento
        dos Dados Pessoais. Caso o Usu&aacute;rio n&atilde;o concorde com as
        altera&ccedil;&otilde;es &agrave; Pol&iacute;tica de Privacidade, deve
        abster-se de utilizar a Plataforma, podendo solicitar o cancelamento de
        sua conta, em conformidade com os Termos de Uso.
      </p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
    </div>
  );
}
