import TravelSearch from './TravelSearch';
import CarResults from './CarResults.tsx';
import CarFilters from './CarFilters.tsx';

import { OrderTypes } from 'src/portao3-legacy/constants/orderTypes';
import { CarParamsConfig } from 'src/portao3-legacy/constants/search/car.ts';

import { TSearch } from 'src/portao3-legacy/types/orders/orders';

export default function CarSearch({ search }: { search: TSearch }) {
  // Render
  return (
    <TravelSearch
      orderType={OrderTypes.CAR}
      search={search}
      FilterComponent={CarFilters}
      ResultsComponent={CarResults as any}
      paramsConfig={CarParamsConfig}
    />
  );
}
