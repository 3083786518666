import React, { Fragment } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { Row, Nav, NavItem } from 'reactstrap';
import classnames from 'classnames';
import IntlMessages from '../../helpers/IntlMessages';
import { Colxx, Separator } from '../../components/common/CustomBootstrap';
import Breadcrumb from '../../containers/navs/Breadcrumb';

import GroupDataListView from '../../containers/policy/GroupDataListView';
import PolicyDataListView from '../../containers/policy/PolicyDataListView';
import FavoriteHotelsDataListView from '../../containers/travels/FavoriteHotelsDataListView';
import FavoritePlaceDataListView from '../../containers/travels/FavoritePlacesDataListView';
import TravelConfigDataListView from '../../containers/travels/TravelConfigDataListView';
import ConnectorsView from '../../containers/others/ConnectorsView';
import OpenBookingConfigView from '../../containers/travels/OpenBookingConfigView';
import TravelParamsConfigView from 'src/portao3-legacy/containers/travels/TravelParams/TravelParamsConfigView';

export default function Travels(props) {
  const { tabId } = useParams();

  // Render
  return (
    <Fragment>
      <Row>
        <Colxx xxs="12">
          <Breadcrumb heading="admin.travels.title" match={props.match} />
          <Separator className="mb-5" />
        </Colxx>
      </Row>
      <Row>
        <Colxx xxs="12" className="mb-4">
          <div>
            <Nav className="admin-tabs">
              <NavItem>
                <NavLink
                  to="/app/travels/favorite-places"
                  className={classnames({
                    active: !tabId || tabId === 'favorite-places',
                    'nav-link': true,
                  })}
                >
                  <IntlMessages id="admin.travels.favorite-places" />
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  to="/app/travels/favorite-hotels"
                  className={classnames({
                    active: tabId === 'favorite-hotels',
                    'nav-link': true,
                  })}
                >
                  <IntlMessages id="admin.travels.favorite-hotels" />
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  to="/app/travels/rules"
                  className={classnames({
                    active: tabId === 'rules',
                    'nav-link': true,
                  })}
                >
                  <IntlMessages id="admin.policy.approvalRules" />
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  to="/app/travels/connectors"
                  className={classnames({
                    active: tabId === 'connectors',
                    'nav-link': true,
                  })}
                >
                  <IntlMessages id="admin.others.connectors" />
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  to="/app/travels/open-booking"
                  className={classnames({
                    active: tabId === 'open-booking',
                    'nav-link': true,
                  })}
                >
                  <IntlMessages id="admin.others.open-booking" />
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  to="/app/travels/params"
                  className={classnames({
                    active: tabId === 'params',
                    'nav-link': true,
                  })}
                >
                  <IntlMessages id="admin.others.params" />
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  to="/app/travels/config"
                  className={classnames({
                    active: tabId === 'config',
                    'nav-link': true,
                  })}
                >
                  <IntlMessages id="general.config" />
                </NavLink>
              </NavItem>
            </Nav>

            {!tabId || tabId === 'favorite-places' ? (
              <FavoritePlaceDataListView />
            ) : null}
            {tabId === 'favorite-hotels' ? (
              <FavoriteHotelsDataListView />
            ) : null}
            {tabId === 'rules' ? <PolicyDataListView tabId="travels" /> : null}
            {tabId === 'groups' ? <GroupDataListView tabId="travels" /> : null}
            {tabId === 'config' ? <TravelConfigDataListView /> : null}
            {tabId === 'connectors' ? <ConnectorsView /> : null}
            {tabId === 'open-booking' ? <OpenBookingConfigView /> : null}
            {tabId === 'params' ? <TravelParamsConfigView /> : null}
          </div>
        </Colxx>
      </Row>
    </Fragment>
  );
}
