import React, { Component } from 'react';
import { Colxx } from '../../components/common/CustomBootstrap';
import { Card, Button } from 'reactstrap';
import { injectIntl } from 'react-intl';

import { stringToMoney } from '../../utils/money';
import OfficeAmenities from '../../components/order/OfficeAmenities';
import CurrencyWrapper from '../../components/common/CurrencyWrapper';

class OfficeItem extends Component {
  // Render
  renderImage() {
    const { item } = this.props;

    let backgroundImage = '/assets/img/default-hotel-bw.png';
    if (item.office.mainPhoto !== '') backgroundImage = item.office.mainPhoto;

    return (
      <div
        className="hotel-image"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      />
    );
  }

  render() {
    const {
      item,
      intl: { messages },
    } = this.props;

    return (
      <Card className="search-card hotel mt-5">
        <Colxx xxs="12" lg="9" className="pl-0">
          <div className="row ml-0 h-100">
            <Colxx xxs="4" className="pl-0">
              {this.renderImage()}
            </Colxx>
            <Colxx xxs="8" className="pt-2">
              <h3 className="hotel-title">{item.office.name}</h3>

              <p className="text-muted">
                {item.office.destination.address.toLowerCase()}
              </p>

              <OfficeAmenities office={item.office} officeId={item.officeId} />
            </Colxx>
          </div>
        </Colxx>
        <Colxx xxs="12" lg="3" className="total pr-0 pt-3 pr-3">
          <div className="text">
            <span>{messages['general.from']}</span>
            <CurrencyWrapper value={item.fare.total} decimalPlaces={0}>
              {({ formattedValue }) => formattedValue}
            </CurrencyWrapper>
            <hr />
          </div>
          <div className="description">{messages['general.taxes']}</div>

          <Button
            color="primary"
            onClick={() => {
              this.props.showDetails(item);
            }}
          >
            {messages['general.select']}
          </Button>
        </Colxx>
      </Card>
    );
  }
}

export default injectIntl(OfficeItem);
