import React, { useMemo } from 'react';
import { registeredAirlines } from '../../constants/travelValues';
import VerticalLine from '../VerticalLine';
import { stringToMoney } from '../../utils/money';
import Badge from './Badge';

import { ReactComponent as OneWayIcon } from '../../assets/img/icons/oneway.svg';
import { ReactComponent as RoundTripIcon } from '../../assets/img/icons/roundtrip.svg';
import moment from 'moment';
import { getPriceDifference, getSelectedClass } from './utils';
import { useIntl } from 'react-intl';
import CurrencyWrapper from '../common/CurrencyWrapper';

function FlightJourney({ journey, className = '', messages, reservations }) {
  const { departureTime, arrivalTime, action, stops, arriveEarly } =
    useMemo(() => {
      const segments = journey.segments;
      const numStops = segments[0].flights.length - 1;
      const firstSegment = segments[0];
      const lastSegment = segments[segments.length - 1];
      const departureTime = moment(firstSegment.departureDateTime);
      const arrivalTime = moment(lastSegment.arrivalDateTime);
      const journeyReservations = reservations[journey.id - 1];
      const lastReservation = journeyReservations
        ? journeyReservations[journeyReservations.length - 1]
        : null;

      return {
        departureTime: departureTime.format('HH:mm'),
        arrivalTime: arrivalTime.format('HH:mm'),
        arriveEarly:
          arrivalTime.format('DD/MM/YYYY HH:mm') <
          lastReservation?.flightDetails?.arrivalDateTime,
        action:
          journey.id === 1
            ? messages['travel.outbound']
            : messages['travel.inbound'],
        stops:
          numStops > 0
            ? `${numStops} ${
                messages[numStops > 1 ? 'flight.stops' : 'flight.stop']
              }`
            : messages['flight.no-stop'],
      };
    }, [journey, reservations]);

  return (
    <div className={className}>
      <p className="m-0 medium">{action}</p>
      <div className="d-flex align-items-center mb-1">
        <h3 className="m-0 font-primary font-weight-medium time">
          {departureTime}
        </h3>
        <OneWayIcon width={15} height={15} className="mx-2" />
        <h3 className="m-0 font-primary font-weight-medium time">
          {arrivalTime}
        </h3>
      </div>
      <div>
        <Badge color="#E8E8FC" className="px-3 py-1">
          <span className="font-weight-normal text-primary">{stops}</span>
        </Badge>
        {arriveEarly && (
          <Badge color="#E8E8FC" className="px-3 py-1 ml-1">
            <span className="font-weight-normal text-primary">
              {messages['order.alternative.arrive-early']}
            </span>
          </Badge>
        )}
      </div>
    </div>
  );
}

export default function FlightAlternative({
  alternative,
  originalTotal,
  index,
  selectedAlternative,
  reservations,
  onClick = () => {},
  badgeText,
}) {
  const { messages, formatMessage } = useIntl();

  const {
    registeredAirline = {},
    departureStation,
    arrivalStation,
    twoWay,
    total,
    _badgeText,
  } = useMemo(() => {
    const { departureStation, arrivalStation } = alternative.journeys[0];

    const _badgeText =
      badgeText ||
      (index === 0
        ? messages['order.alternative.best-price']
        : formatMessage(
            {
              id: 'order.alternative.save',
            },
            {
              save: getPriceDifference(
                originalTotal,
                alternative.fare.total,
                alternative.fare.currency
              ),
            }
          ));

    return {
      registeredAirline: registeredAirlines[alternative.airline],
      departureStation,
      arrivalStation,
      twoWay: alternative.journeys.length > 1,
      total: alternative.fare.total,
      _badgeText,
    };
  }, [alternative]);

  const selectedClass = useMemo(
    () => getSelectedClass(alternative, selectedAlternative),
    [alternative, selectedAlternative]
  );

  // Render
  return (
    <div
      className={`alternative-card p-3 d-flex flex-column justify-content-between ${selectedClass}`}
      onClick={onClick}
    >
      <div className="d-flex align-items-center">
        <div
          className="carrier m-0"
          style={{
            backgroundImage: `url(${registeredAirline?.logo})`,
            width: 70,
          }}
        />
        <VerticalLine
          height={15}
          width={1}
          color="rgba(0, 0, 0, 0.3)"
          className="mx-2"
        />
        <div className="d-flex align-items-center">
          <h3 className="font-primary m-0 small-title text-dark font-weight-normal">
            {departureStation}
          </h3>
          <div className="mx-1">
            {twoWay ? (
              <RoundTripIcon width={10} height={10} />
            ) : (
              <OneWayIcon width={15} height={15} />
            )}
          </div>
          <h3 className="font-primary m-0 small-title text-dark font-weight-normal">
            {arrivalStation}
          </h3>
        </div>
      </div>
      <div className="position-relative">
        {twoWay ? <div className="line" /> : null}
        {alternative.journeys.map((journey) => (
          <div key={`journey-${journey.id}`} className="d-flex">
            <div className="circle mt-3 mr-2 flex-shrink-0" />
            <FlightJourney
              journey={journey}
              className={journey.id === 1 ? 'mb-3' : ''}
              messages={messages}
              reservations={reservations}
            />
          </div>
        ))}
      </div>
      <div className="text-right">
        <p className="m-0 font-primary one-line-height medium">
          {messages['general.total']}
        </p>
        <h3 className="m-0 font-primary text-dark font-weight-medium one-line-height mt-1">
          <CurrencyWrapper value={total} decimalPlaces={0} tooltipFill="black">
            {({ formattedValue }) => <span>{formattedValue}</span>}
          </CurrencyWrapper>
        </h3>
        <Badge color="#0A936D" className="bottom-badge mt-1 py-2">
          <span className="font-weight-normal text-white small-medium-title">
            {_badgeText}
          </span>
        </Badge>
      </div>
    </div>
  );
}
