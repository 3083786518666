import React from 'react';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Button } from 'reactstrap';

export default function CancelModal({ setHeight, closeModal }) {
  const { messages } = useIntl();

  useEffect(() => {
    setHeight(110);
  }, []);

  return (
    <div className="text-center">
      <p className="description m-0 mb-3 px-4">
        {messages['refund.card.cancel']}
      </p>
      <Button color="primary" className="m-0 pretty-btn" onClick={closeModal}>
        {messages['refund.card.btn.alright']}
      </Button>
    </div>
  );
}
