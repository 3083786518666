import React, { useEffect, useRef, useState } from 'react';
import { Tooltip } from 'reactstrap';
import CustomAnimatedCheckbox from '../../../components/common/CustomCheckbox';
import { useDispatch, useSelector } from 'react-redux';
import {
  addAllBudgetsToReportExpense,
  addBudgetToReportExpense,
  removeAllBudgetsToReportExpenseByCardId,
  removeBudgetToReportExpense,
} from '../../../redux/actions';
import { useIntl } from 'react-intl';
import { RefundStatus } from '../../../constants/refundStatus';

//@param color: 'white' | 'lightPurple'
export const BudgetReportCheckbox = ({ color, card, budget }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [checked, setCheckbox] = useState(false);
  const [isPartialCheck, setIsPartialCheck] = useState(false);
  const isGlobalCheckBox = budget === undefined;
  const dispatch = useDispatch();
  const { budgets, reports } = useSelector(
    ({ reportBudgetExpenses }) => reportBudgetExpenses
  );
  const { messages } = useIntl();

  const divRef = useRef(null);

  const getEligibleBudgets = () => {
    return (
      budgets?.[card.id]?.filter(
        ({ statusCode, expenses }) =>
          statusCode <= RefundStatus.APPROVED && expenses.length > 0
      ) || []
    );
  };

  const hasExpenses = () => {
    if (isGlobalCheckBox) {
      const budgets = getEligibleBudgets();
      return budgets.length > 0;
    } else {
      return (
        budget.expenses?.length > 0 && budget.statusCode <= RefundStatus.PAID
      );
    }
  };

  const checkIfBudgetIsInReport = () => {
    return reports.find((report) => report.id === budget.id) !== undefined;
  };

  const checkIfAllCardBudgetsIsInReport = () => {
    const reportsByCardLen = reports.filter(
      (report) => report.cardId === card.id
    ).length;
    setIsPartialCheck(reportsByCardLen !== getEligibleBudgets().length);
    return reportsByCardLen > 0;
  };

  useEffect(() => {
    if (isGlobalCheckBox) {
      setCheckbox(checkIfAllCardBudgetsIsInReport());
    } else {
      setCheckbox(checkIfBudgetIsInReport());
    }
  }, [reports]);

  useEffect(() => {
    if (budgets?.[card.id]?.length > 0 && reports.length > 0) {
      const updatedBudgets = budgets?.[card.id]
        .filter((budget) => reports.find(({ id }) => budget.id === id))
        .map((budget) => ({ ...budget, card }));

      dispatch(addAllBudgetsToReportExpense(updatedBudgets));
    }
  }, [budgets]);

  const onCheckboxChange = (checked) => {
    if (isGlobalCheckBox) {
      if (checked) {
        const budgetsWithCard = getEligibleBudgets().map((budget) => ({
          ...budget,
          card,
        }));
        dispatch(addAllBudgetsToReportExpense(budgetsWithCard));
      } else {
        dispatch(removeAllBudgetsToReportExpenseByCardId(card.id));
      }
    } else {
      if (checked) {
        dispatch(addBudgetToReportExpense({ ...budget, card }));
      } else {
        dispatch(removeBudgetToReportExpense({ ...budget, card }));
      }
    }
  };

  return (
    <>
      <div
        ref={divRef}
        className="budget-report-checkbox"
        data-testid={isGlobalCheckBox ? 'br-checkbox-global' : 'br-checkbox'}
      >
        <CustomAnimatedCheckbox
          size="1.5em"
          checked={checked}
          onChange={onCheckboxChange}
          isPartialCheck={isPartialCheck}
          disabled={!hasExpenses()}
          white={color === 'white'}
          lightPurple={color !== 'white'}
        />
      </div>
      {!hasExpenses() && (
        <Tooltip
          isOpen={showTooltip}
          toggle={() => setShowTooltip((v) => !v)}
          target={divRef}
          innerClassName="fs-0-7rem"
        >
          {messages['budget.my-cards.no-expenses']}
        </Tooltip>
      )}
    </>
  );
};
