import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setPassenger } from 'src/portao3-legacy/redux/actions';
import { getAuthRedux } from 'src/portao3-legacy/utils/redux';
import { analyzeReservationParams } from 'src/portao3-legacy/utils/reservation';
import { trackPropertiesSegment } from 'src/portao3-legacy/utils/segment';
import { getParams, makeReservation } from './utils';
import { removeUndefinedFromObject } from 'src/portao3-legacy/utils/others';

import { TGenericParams, TGetParams } from './types';

interface IProps {
  orderType: string;
  search: any;
  searchParams: any;
  shouldSetPassenger?: boolean;
}

export type TReserveItem<TParams> = (
  params: TParams,
  toggle?: () => any
) => Promise<void>;

export type TReserveItemWithToggle<TParams> = (
  toggle: () => any
) => (params: TParams) => Promise<void>;

export default function useReserveTravelItem<TParams = TGenericParams>({
  orderType,
  search,
  searchParams,
  shouldSetPassenger = true,
}: IProps) {
  const { user } = useSelector(getAuthRedux);
  const { passenger } = useSelector(({ timeline }) => timeline);
  const dispatch = useDispatch();

  // Functions
  const reserveItem = useCallback(
    async (params: TParams, toggle?: () => any) => {
      if (toggle) toggle();

      const newReservationParams = await analyzeReservationParams(
        (params as TGenericParams).reservationParams
      );

      const { order, reservations, filterTimestamp } = await getParams(
        orderType,
        {
          ...params,
          search,
          params: searchParams,
          reservationParams: newReservationParams,
        } as unknown as TGetParams
      );

      trackPropertiesSegment('New Reservation', {
        ...order,
        type: orderType,
      });

      await makeReservation({
        organizationId: user.organizationId,
        passengerId: newReservationParams.passengerId,
        passenger: newReservationParams.passenger,
        orderDetails: removeUndefinedFromObject(order),
        reservations,
        filterTimestamp,
        type: orderType,
      });

      // Change timeline to selected user
      if (
        shouldSetPassenger &&
        passenger?.uid !== newReservationParams.passengerTimeline?.uid
      ) {
        dispatch(setPassenger(newReservationParams.passengerTimeline));
      }
    },
    [orderType, search, searchParams]
  );

  const reserveItemWithToggle = useCallback(
    (toggle: () => any) => (params: TParams) => reserveItem(params, toggle),
    [reserveItem]
  );

  return {
    reserveItem,
    reserveItemWithToggle,
  };
}
