export function getPassengerInfo(passenger, shouldCheckVIP = false) {
  const {
    firstName,
    lastName,
    birthdate = null,
    mobilePhoneNumber = null,
    nationality = null,
    documents = {},
    vip = false,
  } = passenger;

  const passengerInfo = shouldCheckVIP
    ? {
        firstName,
        lastName,
        birthdate,
        mobilePhoneNumber,
        nationality,
        documents: {},
        vip,
      }
    : {
        firstName,
        lastName,
        birthdate,
        mobilePhoneNumber,
        nationality,
        documents: {},
      };

  if (documents.cpf?.number) {
    passengerInfo.documents.cpf = {
      number: documents.cpf.number,
    };
  }

  if (documents.identity?.number) {
    passengerInfo.documents.identity = {
      number: documents.identity.number,
    };
  }

  if (
    documents.passport &&
    documents.passport.country &&
    documents.passport.number &&
    documents.passport.validUntil
  ) {
    passengerInfo.documents.passport = documents.passport;
  }

  return passengerInfo;
}

export const getDocument = async (query, emptyReturn = null) => {
  const snap = await query();

  if (snap.exists) {
    return {
      ...snap.data(),
      id: snap.id,
    };
  } else return emptyReturn;
};

export const getDataFromDocument = (snap, emptyReturn = null) => {
  if (snap.exists) {
    return {
      ...snap.data(),
      id: snap.id,
    };
  } else return emptyReturn;
};

export const getDocumentsFromCollection = async (query, returnSnapToo) => {
  const data = [];

  const snap = await query();

  snap.forEach((docRef) => {
    data.push({
      ...docRef.data(),
      id: docRef.id,
    });
  });

  return returnSnapToo ? { data, snap } : data;
};

export const getDocumentsFromCollectionReference = (query, idName = 'id') => {
  if ('forEach' in query) {
    const data = [];

    query.forEach((doc) =>
      data.push({
        ...doc.data(),
        [idName]: doc.id,
      })
    );

    return data;
  } else {
    return {
      [idName]: query.id,
      ...query.data(),
    };
  }
};
