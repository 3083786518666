import {
  RESET_CHECKLIST,
  SET_CHECKLIST,
  SET_CHECKLIST_OPEN,
  TOGGLE_ITEM,
  SET_CHECKLIST_COMPLETE,
} from '../actions';

export const setChecklist = (id, checklist) => ({
  type: SET_CHECKLIST,
  payload: {
    id,
    checklist,
  },
});

export const toggleItem = (checklistId, itemId, value = undefined) => ({
  type: TOGGLE_ITEM,
  payload: {
    checklistId,
    itemId,
    value,
  },
});

export const setChecklistOpen = (checklistId, isOpen = false) => ({
  type: SET_CHECKLIST_OPEN,
  payload: { checklistId, isOpen },
});

export const resetChecklist = () => ({
  type: RESET_CHECKLIST,
});

export const setChecklistComplete = (checklistId, completed = true) => ({
  type: SET_CHECKLIST_COMPLETE,
  payload: { checklistId, completed },
});
