const algolia = require('algoliasearch');

// Algolia Configuration
const ALGOLIA_ID = process.env.REACT_APP_ALGOLIA_APP;
const ALGOLIA_SEARCH_KEY = process.env.REACT_APP_ALGOLIA_KEY;

const algoliaSearch = algolia(ALGOLIA_ID, ALGOLIA_SEARCH_KEY);

// Methods: Locations
algoliaSearch.locationIndex = function () {
  return algoliaSearch.initIndex('locations');
};

algoliaSearch.obtLicenseIndex = function () {
  return algoliaSearch.initIndex('obt_licenses');
};

export default algoliaSearch;
