import { UPDATE_SEARCH_HOTEL } from '../actions';

const INITIAL_STATE = {
  search: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_SEARCH_HOTEL: {
      return {
        ...state,
        search: action.payload.search,
      };
    }
    default: {
      return { ...state };
    }
  }
};
