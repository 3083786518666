import React, { Component } from 'react';

import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import config from '../../constants/GoogleMapsBasic';
import map from 'lodash/map';

const GOOGLE_API_KEY = config.key;

class BusMap extends Component {
  constructor(props) {
    super(props);

    this.mapRef = React.createRef();
  }

  //Lifecycle
  mapLoaded(mapProps, map) {
    map.setOptions({ styles: config.mapStyle });

    this.defineBounds();
  }

  defineBounds() {
    const { stations } = this.props;

    const bounds = new window.google.maps.LatLngBounds();
    map(stations, (station) => {
      bounds.extend(
        new window.google.maps.LatLng(station.latitude, station.longitude)
      );

      return true;
    });

    this.mapRef.current?.map?.fitBounds(bounds);
  }

  filterMarker = (hotelId) => {
    this.props.toggleSelectedHotels(hotelId);
  };

  // Render
  render() {
    const { stations } = this.props;

    return (
      <Map
        google={this.props.google}
        zoom={14}
        scrollwheel={false}
        keyboardShortcuts={false}
        disableDoubleClickZoom={true}
        zoomControl={false}
        mapTypeControl={false}
        scaleControl={false}
        streetViewControl={false}
        panControl={false}
        rotateControl={false}
        fullscreenControl={false}
        gestureHandling="none"
        disableDefaultUI={true}
        onReady={(mapProps, map) => this.mapLoaded(mapProps, map)}
        ref={this.mapRef}
      >
        {map(stations, (station) => {
          return (
            <Marker
              key={`station-map-${station.id}`}
              position={{
                lat: station.latitude,
                lng: station.longitude,
              }}
              icon={{
                url: '/assets/img/icons/location_pin_selected.svg',
                scaledSize: new window.google.maps.Size(10, 10),
              }}
            />
          );
        })}
      </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: GOOGLE_API_KEY,
})(BusMap);
