import React, { useState, useEffect } from 'react';
import map from 'lodash/map';
import { useIntl } from 'react-intl';

import LoaderESG from '../loaders/LoaderESG';

import firebaseApp from '../../services/Firebase';

export default function BusCarbonDetail({ esg, legs }) {
  const [carbon, setCarbon] = useState({});
  const [loading, setLoading] = useState(true);

  const { messages, formatMessage } = useIntl();

  useEffect(() => {
    (async () => {
      await loadCarbonData();
    })();
  }, []);

  // Carbon Data
  const loadCarbonData = async () => {
    if (esg) {
      setCarbon(esg);
    }

    if (legs) {
      try {
        const locationIds = [];
        legs.forEach((leg, index) => {
          if (index === 0) locationIds.push(leg.departureStationId);
          locationIds.push(leg.arrivalStationId);
        });

        const promises = map(
          locationIds,
          (locationId) =>
            new Promise(async (resolve, reject) => {
              const locationSnaps = await firebaseApp.getStationFromClickbusId(
                locationId
              );
              const location = locationSnaps.docs[0].data();
              resolve(`${location.latitude},${location.longitude}`);
            })
        );

        const coords = [];
        for (let i = 0; i < promises.length; i++) {
          coords.push(await promises[i]);
        }

        const carbon = await firebaseApp.getBusCarbonEmission(coords);

        setCarbon(carbon);
      } catch (err) {
        console.error(err);
      }
    }

    setLoading(false);
  };

  if (loading) return <LoaderESG />;

  // Render
  if (carbon.carbon_kg) {
    return (
      <div className="esg-label">
        <i className="esg-icon" />
        <p>
          {formatMessage(
            { id: 'esg.bus.estimate' },
            {
              carbon: parseInt(carbon.carbon_kg),
              km: parseInt(carbon.distance_km),
            }
          )}
        </p>
      </div>
    );
  }

  return <div />;
}
