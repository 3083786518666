import React, { Component } from 'react';
import ContentLoader from 'react-content-loader';

import { injectIntl } from 'react-intl';

class LoadingCard extends Component {
  render() {
    const { messages } = this.props.intl;

    return (
      <div className="item loader">
        <div className="date" />
        <div className="details">
          <div>
            <ContentLoader
              title={messages['travel.timeline.loading']}
              speed={2}
              width={280}
              height={170}
              viewBox="0 0 280 170"
              backgroundColor={'#2B2770'}
              foregroundColor={'#3B3588'}
            >
              <rect x="15" y="70" rx="0" ry="0" width="30" height="6" />
              <rect x="15" y="112" rx="0" ry="0" width="50" height="6" />
              <rect x="15" y="128" rx="0" ry="0" width="112" height="30" />
              <rect x="15" y="80" rx="0" ry="0" width="40" height="6" />
              <circle cx="30" cy="45" r="15" />
              <rect x="150" y="128" rx="0" ry="0" width="112" height="30" />
              <rect x="150" y="112" rx="0" ry="0" width="50" height="6" />
            </ContentLoader>
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(LoadingCard);
