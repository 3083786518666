import axios from 'axios';

export async function getCurrentAppVersion() {
  try {
    const res = await axios.get('/version.json');
    return res.data.version || 0;
  } catch (err) {
    console.debug(err);
    return 0;
  }
}
