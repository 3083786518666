import React from 'react';
import { Card } from 'reactstrap';
import { Colxx } from '../common/CustomBootstrap';
import ContentLoader from 'react-content-loader';
import LoadingTotal from './LoadingTotal';

export default function LoadingFlight() {
  return (
    <Card className="search-card flight mt-5">
      <Colxx xxs="12" lg="9" className="p-0">
        <ContentLoader
          title=""
          speed={2}
          width={600}
          height={200}
          viewBox="0 0 600 200"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
        >
          <circle cx="50" cy="65" r="10" />
          <rect x="100" y="59" rx="1" ry="1" width="100" height="12" />
          <rect x="0" y="0" rx="0" ry="0" width="600" height="40" />
          <rect x="0" y="99" rx="0" ry="0" width="600" height="40" />
          <circle cx="50" cy="164" r="10" />
          <rect x="220" y="59" rx="1" ry="1" width="70" height="12" />
          <rect x="310" y="59" rx="1" ry="1" width="100" height="12" />
          <rect x="100" y="158" rx="1" ry="1" width="100" height="12" />
          <rect x="220" y="158" rx="1" ry="1" width="70" height="12" />
          <rect x="310" y="158" rx="1" ry="1" width="100" height="12" />
        </ContentLoader>
      </Colxx>
      <Colxx xxs="12" lg="3" className="text-right">
        <LoadingTotal />
      </Colxx>
    </Card>
  );
}
