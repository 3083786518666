import moment from 'moment';
import React from 'react';
import { useMemo } from 'react';
import { Row, Label } from 'reactstrap';
import IntlMessages from '../../helpers/IntlMessages';
import { stringToMoney } from '../../utils/money';
import { Colxx } from '../common/CustomBootstrap';
import { useSelector } from 'react-redux';
import { getAuthRedux } from 'src/portao3-legacy/utils/redux';

export default function ReadOnlyBudgetParams({ budget }) {
  const { user } = useSelector(getAuthRedux);

  const { budgetTotal, startDate, endDate, administrative, budgetOwner } = useMemo(
    () => ({
      budgetTotal: budget.budget
        ? stringToMoney(budget.budget, 2, budget.fare.currency)
        : null,
      startDate: moment(budget.startDate, 'YYYY-MM-DD').format('DD/MM/YYYY'),
      endDate: moment(budget.endDate, 'YYYY-MM-DD').format('DD/MM/YYYY'),
      administrative: budget.administrative,
      budgetOwner: budget.passengerId === user.uid ? null : `${budget.passengerInfo.firstName} ${budget.passengerInfo.lastName}`
    }),
    [budget, user]
  );

  // Render
  const renderDates = () => {
    if (administrative) {
      return (
        <>
          <p className="text-uppercase">
            <span className="text-uppercase">{startDate}</span>
          </p>
          <IntlMessages id="refund.params.administrative.time-interval" />
        </>
      );
    }

    return (
      <>
        <p className="text-uppercase">
          <span className="text-uppercase">{startDate}</span>
          <span className="mx-2">-</span>
          <span className="text-uppercase">{endDate}</span>
        </p>
        <IntlMessages id="refund.params.time-interval" />
      </>
    );
  };
  return (
    <div className="reservation-params px-3">
      {
        budgetOwner ? (
        <Row>
          <Label className="form-group disabled search has-top-label">
            <p className="text-uppercase">{budgetOwner}</p>
            <IntlMessages id='refund.modal.items.owner' />
          </Label>
        </Row>
        ) : null
      }
      <Row>
        <Label className="form-group disabled search has-top-label">
          <p className="text-uppercase">{budget.name}</p>
          <IntlMessages id="refund.modal.items.name" />
        </Label>
      </Row>
      <Row>
        <Label className="form-group disabled search has-top-label w-100">
          {renderDates()}
        </Label>
      </Row>
      <Row>
        {budget.costCenter?.label && (
          <Colxx xxs="12" lg="6">
            <Label className="form-group disabled search has-top-label">
              <p className="text-uppercase">{budget.costCenter.label}</p>
              <IntlMessages id="travel.booking.costCenter" />
            </Label>
          </Colxx>
        )}
        {budget.project?.label && (
          <Colxx xxs="12" lg="6">
            <Label className="form-group disabled search has-top-label">
              <p className="text-uppercase">{budget.project.label}</p>
              <IntlMessages id="travel.booking.project" />
            </Label>
          </Colxx>
        )}
        {budget.motive?.label && (
          <Colxx xxs="12" lg="6">
            <Label className="form-group disabled search has-top-label">
              <p className="text-uppercase">{budget.motive.label}</p>
              <IntlMessages id="travel.booking.motive" />
            </Label>
          </Colxx>
        )}
        {budget.tag?.label && (
          <Colxx xxs="12" lg="6">
            <Label className="form-group disabled search has-top-label">
              <p className="text-uppercase">{budget.tag.label}</p>
              <IntlMessages id="travel.booking.tag" />
            </Label>
          </Colxx>
        )}
        {budget.observation && (
          <Colxx xxs="12" lg="6">
            <Label className="form-group disabled search has-top-label">
              <p className="text-uppercase">{budget.observation}</p>
              <IntlMessages id="travel.booking.observation" />
            </Label>
          </Colxx>
        )}
      </Row>
      {budgetTotal ? (
        <Row>
          <Colxx xxs="12" lg="6">
            <Label className="form-group disabled search has-top-label">
              <p className="text-uppercase">{budgetTotal}</p>
              <IntlMessages id="refund.modal.refund.budget" />
            </Label>
          </Colxx>
          <Colxx xxs="12" lg="6">
            <Label className="form-group disabled search has-top-label">
              <p className="text-uppercase">{budget.category.label}</p>
              <IntlMessages id="refund.modal.refund.budget-category" />
            </Label>
          </Colxx>
        </Row>
      ) : null}
    </div>
  );
}
