import React, { useMemo, useState } from 'react';
import { Button, Row } from 'reactstrap';
import { Colxx } from '../../components/common/CustomBootstrap';

import { ReactComponent as KmIcon } from '../../assets/img/expenses/km.svg';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { getCurrencyAndPrice } from '../../utils/money';
import { getOrganizationConfig } from '../../utils/organization';
import NumberFormat from 'react-number-format';
import firebaseApp from '../../services/Firebase';

export default function KmDataListView() {
  const { messages } = useIntl();
  const { organization } = useSelector(({ auth }) => auth);

  const currency = useMemo(
    () =>
      `${
        getCurrencyAndPrice(
          '10',
          getOrganizationConfig(organization.config, 'currency')
        )[0]
      } `,
    []
  );

  const initialValue = useMemo(
    () => getOrganizationConfig(organization.config, 'refunds.perKm'),
    []
  );

  const [perKm, setPerKm] = useState(initialValue);

  const savePerKm = async () => {
    try {
      await firebaseApp.updatePerKmConfig(organization.id, perKm);
    } catch (err) {
      console.debug(err);
    }
  };

  return (
    <Row className="travel-data-list-view d-flex justify-content-between mt-5 ml-3 w-100">
      <Colxx xxs="5">
        <KmIcon width="90%" />
      </Colxx>
      <Colxx xxs="7">
        <p className="text-one w-70 mt-3 slide-left">
          {messages['admin.receipts.km.description']}
        </p>
        <div
          className="d-flex flex-column align-items-start slide-left"
          style={{ animationDelay: '50ms' }}
        >
          <NumberFormat
            prefix={currency}
            suffix={''}
            thousandSeparator="."
            decimalSeparator=","
            decimalScale="2"
            fixedDecimalScale="2"
            className="price-input mb-4 without-outline px-2 py-1 light-round"
            defaultValue={perKm}
            onValueChange={(v) => {
              setPerKm(v.floatValue);
            }}
          />
          <Button
            disabled={initialValue === perKm}
            size="lg"
            color="primary"
            onClick={savePerKm}
          >
            {messages['admin.policy.policy.Save']}
          </Button>
        </div>
      </Colxx>
    </Row>
  );
}
