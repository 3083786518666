import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import firebaseApp from '../../../services/Firebase';
import orderStructure from '../../../constants/orderStructure';
import { trackPropertiesSegment } from '../../../utils/segment';
import debounce from 'lodash/debounce';
import filter from 'lodash/filter';
import { canPerformAction } from '../../../utils/reservation';
import { storeReservationAction } from '../../../redux/actions';
import { RESERVATION_ACTION_TIMEOUT } from '../../../constants/reservations';
import appFunctions from '../../../services/Functions';
import OrganizationApproval from './Approvals/OrganizationApproval';
import PersonalApproval from './Approvals/PersonalApproval';
import OpenBookingApproval from './Approvals/OpenBookingApproval';
import { getAuthRedux } from '../../../utils/redux';

export default function ApprovalInfo({
  order,
  approvals,
  goForApproval,
  toggleModal,
  reservations,
}) {
  const { messages } = useIntl();
  const dispatch = useDispatch();
  const { user } = useSelector(getAuthRedux);
  const reservationActions = useSelector(
    ({ reservationActions }) => reservationActions
  );

  // States
  const [allowAction, setAllowAction] = useState(true);
  const [ongoingAction, setOngoingAction] = useState(false);
  const [error, setError] = useState(null);
  const [isRejecting, setIsRejecting] = useState(false);

  const ApprovalComponent = useMemo(() => {
    if (order.openBooking) return OpenBookingApproval;

    return order.structure === orderStructure.PERSONAL
      ? PersonalApproval
      : OrganizationApproval;
  }, [order]);

  const shouldDisableButtons = !allowAction || ongoingAction;

  // Effects
  useEffect(() => {
    const lastAction = reservationActions[order.id];

    if (lastAction) {
      const diff =
        lastAction + RESERVATION_ACTION_TIMEOUT - new Date().getTime();

      if (diff > 0) {
        setAllowAction(false);
        const timeout = setTimeout(() => setAllowAction(true), diff);
        return () => clearTimeout(timeout);
      }
    }

    if (!allowAction) setAllowAction(true);
  }, [reservationActions]);

  // Functions
  const goForApprovalWrapper = () => {
    trackPropertiesSegment('User went back to approval', { order, user });
    goForApproval();
  };

  const executeAction = async (action) => {
    if (!canPerformAction(reservationActions[order.id])) return;

    try {
      setOngoingAction(true);

      await action();

      dispatch(storeReservationAction(order.id));
      toggleModal();
    } catch (err) {
      console.error(err);
      setError(messages['error.approval-info.error']);
      setOngoingAction(false);
    }
  };

  // Send to Issue
  const requestToIssue = useCallback(
    debounce(
      (selectedCard = null, installments = 0, travelCreditId) =>
        executeAction(async () => {
          if (order.openBooking || (selectedCard && installments > 0)) {
            trackPropertiesSegment('User issued order', { order, user });

            await firebaseApp.requestToIssue({
              orderId: order.id,
              cardId: selectedCard,
              installments,
              params: travelCreditId ? { travelCreditId } : {},
            });
          } else {
            setOngoingAction(false);
            setError(messages['error.approval-info.credit-card.select']);
          }
        }),
      300
    ),
    [reservationActions, order]
  );

  const requestToApprove = useCallback(
    debounce(
      (travelCreditId) =>
        executeAction(async () => {
          trackPropertiesSegment('User approved order', { order, user });

          const userApprovals = filter(approvals, (a) => a.userId === user.uid);

          const promises = userApprovals.map((approvalItem) => {
            return firebaseApp.approveOrder(
              order.id,
              approvalItem.id,
              travelCreditId
            );
          });

          await Promise.all(promises);
        }),
      300
    ),
    [reservationActions]
  );

  const _requestToReject = useCallback(
    debounce(
      (comment) =>
        executeAction(async () => {
          trackPropertiesSegment('User denied order', { order, user, comment });

          const userApprovals = filter(approvals, (a) => a.userId === user.uid);

          // If it's approver
          if (userApprovals.length) {
            const promises = userApprovals.map((approvalItem) => {
              return firebaseApp.denyOrder(order.id, approvalItem.id, comment);
            });

            return await Promise.all(promises);
          }

          // If it's admin
          if (user.admin) {
            await firebaseApp.requestToRejectOrder(order.id, comment);
          }
        }),
      300
    ),
    [reservationActions]
  );

  const requestToReject = (comment) => {
    if (
      isRejecting ||
      order.openBooking ||
      order.structure === orderStructure.PERSONAL
    )
      return _requestToReject(comment);
    else setIsRejecting(true);
  };

  // Render
  return (
    <ApprovalComponent
      order={order}
      goForApproval={goForApprovalWrapper}
      toggleModal={toggleModal}
      requestToIssue={requestToIssue}
      requestToApprove={requestToApprove}
      requestToReject={requestToReject}
      approvals={approvals}
      reservations={reservations}
      error={error}
      setError={setError}
      ongoingAction={ongoingAction}
      allowAction={allowAction}
      shouldDisableButtons={shouldDisableButtons}
      isRejecting={isRejecting}
      setIsRejecting={setIsRejecting}
    />
  );
}
