import React, { useMemo } from 'react';
import { Card, Row } from 'reactstrap';
import { Colxx } from '../../../components/common/CustomBootstrap';
import OrderStructures from '../../../constants/orderStructure';

import { loadCostCenters as _loadCostCenters } from '../../../utils/costCenters';
import { loadProjects as _loadProjects } from '../../../utils/projects';
import { loadMotives as _loadMotives } from '../../../utils/motives';
import { loadTags as _loadTags } from '../../../utils/tags';
import ReservationParamsNav from '../ReservationParams/ReservationParamsNav';
import OrganizationParams from '../ReservationParams/StructureParams/OrganizationParams';
import { OrderTypes } from '../../../constants/orderTypes';
import FlightParams from './TravelParams/FlightParams';
import useReservationParamsInfo from '../../../hooks/useReservationParamsInfo';
import { getOpenBookingProviders } from '../../../utils/openBooking';
import { useSelector } from 'react-redux';
import { getAuthRedux } from '../../../utils/redux';
import { useIntl } from 'react-intl';

const TravelParams = {
  [OrderTypes.FLIGHT]: FlightParams,
};

export default function OpenBookingReservationParams({
  setReservationParams,
  travelParams,
  setTravelParams,
  travelType,
  nationality: _nationality,
  hasNationality,
  search,
  showingSteps,
}) {
  const { messages } = useIntl();
  const { organization } = useSelector(getAuthRedux);

  // States
  const { editableFields, nationality, requiredFields } =
    useReservationParamsInfo(_nationality);

  const { TravelParamsComponent, openBookingProviders } = useMemo(
    () => ({
      TravelParamsComponent: TravelParams[travelType],
      openBookingProviders: getOpenBookingProviders(
        travelType,
        organization,
        messages
      ),
    }),
    [travelType, organization]
  );

  // Render
  return (
    <Card className="my-2 reservation-type-tab">
      <ReservationParamsNav
        allowPersonalTravel={false}
        orderStructure={OrderStructures.ORGANIZATION}
      />

      {TravelParamsComponent ? (
        <Row>
          <Colxx sm="12">
            <TravelParamsComponent
              params={travelParams}
              setParams={setTravelParams}
              search={search}
              associates={openBookingProviders}
              showingSteps={showingSteps}
            />
          </Colxx>
        </Row>
      ) : null}
      <Row>
        <Colxx sm="12">
          <OrganizationParams
            className="pt-0"
            setReservationParams={setReservationParams}
            requiredFields={requiredFields}
            editableFields={editableFields}
            nationality={nationality}
            hasNationality={hasNationality}
          />
        </Colxx>
      </Row>
    </Card>
  );
}
