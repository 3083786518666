import React, { useMemo } from 'react';
import BaggageItems from './Items/BaggageItems';
import SeatItems from './Items/SeatItems';

const ITEM_COMPONENTS = {
  bag: BaggageItems,
  seat: SeatItems,
};

export default function AncillaryItems({ ancillary, order, reservations }) {
  const mappedReservations = useMemo(
    () =>
      reservations.reduce((obj, reservation) => {
        obj[reservation.id] = reservation;
        return obj;
      }, {}),
    [reservations]
  );

  return (
    <div>
      {ancillary.items
        ? ancillary.items.map((item, index) => {
            const Component = ITEM_COMPONENTS[item.type];
            return Component ? (
              <Component
                className="half-slide-left mb-4"
                key={item.type}
                items={item}
                reservations={mappedReservations}
                order={order}
                style={{ animationDelay: `${150 + index * 50}ms` }}
              />
            ) : null;
          })
        : null}
    </div>
  );
}
