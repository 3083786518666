import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip);

import { gaugeChartOptions } from './config';

export default function Gauge({ data, icon }) {
  return (
    <>
      <Doughnut data={data} options={gaugeChartOptions} />
      <div className="gauge-icon">{icon}</div>
    </>
  );
}
