import React from 'react';
import { useIntl } from 'react-intl';
import { stringToMoney } from '../../utils/money';
import TicketDetails from './TicketDetails';
import OrderStatus from '../../constants/orderStatus';
import CurrencyWrapper from '../common/CurrencyWrapper';

export default function OrderStatusTitle({
  order,
  reservations,
  DetailsComponent = null,
  tooltipFill = 'white',
}) {
  const { formatMessage, messages } = useIntl();

  // Render
  const renderTotal = () => {
    if (!order.fare) return null;

    return (
      <>
        <span className="d-block value-title">
          {messages['alts.components.travel.total']}
        </span>
        {order.fare.paymentAtDestination ? (
          <>
            <span className="fs-1-25rem value">
              {stringToMoney(
                order.fare.prePayment || order.fare.total,
                0,
                order.fare.currency
              )}
            </span>
            <p className="color-alert value">
              {formatMessage(
                { id: 'travel.payment-at-destination' },
                {
                  value: stringToMoney(
                    order.fare.paymentAtDestination,
                    0,
                    order.fare.currency
                  ),
                }
              )}
            </p>
          </>
        ) : (
          <span className="fs-1-25rem value">
            <CurrencyWrapper
              value={order.fare.total}
              tooltipFill={tooltipFill}
              loadingBackgroundColor="transparent"
              loadingForegroundColor="white"
              decimalPlaces={0}
            >
              {({ formattedValue }) => formattedValue}
            </CurrencyWrapper>
          </span>
        )}
      </>
    );
  };

  if (order.statusCode === OrderStatus.ISSUED) {
    return (
      <>
        {order.openBooking ? null : (
          <div className="text">
            <span>
              {order.type === 'bus'
                ? messages['alts.components.travel.code']
                : messages['alts.components.travel.bookingCode']}
            </span>
            {order.bookingCode}
            <TicketDetails order={order} reservations={reservations} />
            {DetailsComponent && <DetailsComponent />}
            <hr className="my-2" />
          </div>
        )}
        <div className="text">
          <span className="pt-2">{renderTotal()}</span>
        </div>
      </>
    );
  }

  return <div className="text">{renderTotal()}</div>;
}
