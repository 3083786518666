import classNames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import firebaseApp from '../services/Firebase';
import ProfileImage from './ProfileImage';

const storedUsers = {};

const fetchUser = (userId) =>
  firebaseApp
    .getUserFromId(userId)
    .get()
    .then((snap) => snap.data());

export default function UsersList({
  users,
  maxUsers = 10,
  displacement = -10,
  delay = 100,
  className = '',
  fetchFunction,
}) {
  const [loading, setLoading] = useState(true);
  const [fetchedUsers, setFetchedUsers] = useState(
    users.map((id) => ({ id, name: '' }))
  );

  const overflowNumber = useMemo(() => {
    const length = users.length;
    return length > maxUsers ? length - maxUsers : 0;
  });

  // Effects
  useEffect(() => {
    const doFetchUsers = async () => {
      const fetch = fetchFunction || fetchUser;

      const promises = users.slice(0, maxUsers).map((userId) => {
        const user = storedUsers[userId];

        if (user) {
          return user;
        } else {
          return new Promise((res) =>
            fetch(userId)
              .then((userObj) => {
                const {
                  firstName,
                  lastName,
                  avatar = '/assets/img/profile-pic-l.png',
                } = userObj;
                const user = {
                  firstName,
                  lastName,
                  avatar,
                  name: `${firstName} ${lastName}`,
                  id: userId,
                };

                storedUsers[userId] = user;

                res(user);
              })
              .catch(() =>
                res({
                  avatar: '/assets/img/profile-pic-l.png',
                  name: '',
                  id: userId,
                })
              )
          );
        }
      });

      setFetchedUsers(await Promise.all(promises));
      setLoading(false);
    };

    doFetchUsers();
  }, [users]);

  return (
    <div
      className={classNames('users-list d-flex align-items-center', className)}
    >
      <div className="users d-flex justify-content-start align-items-center">
        {fetchedUsers.map((user, index) => (
          <div
            key={user.id}
            style={{
              zIndex: maxUsers - index,
              marginLeft: index > 0 ? displacement : 0,
            }}
            title={user.name}
            className="img-container"
          >
            <ProfileImage
              user={user}
              loading={loading}
              xxsmall
              style={{ animationDelay: `${index * delay}ms` }}
            />
          </div>
        ))}
      </div>
      {overflowNumber ? (
        <p className="m-0 pl-2" style={{ letterSpacing: 1 }}>
          +{overflowNumber}
        </p>
      ) : null}
    </div>
  );
}
