import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';
import useWindowSize from '../../../hooks/useWindowSize';
import CustomPortal from '../CustomPortal';
import { PopupModalPositions } from './consts';
import { getHideStyle, getShowStyle } from './utils';

export default function PopupModal({
  className,
  children,
  show: _show,
  toggle,
  target,
  showPosition = PopupModalPositions.CENTER,
  hidePosition = PopupModalPositions.CENTER,
  calculateStyleOnShow,
  blockScroll,
  hideOnScroll,
  displacementX = 0,
  displacementY = 0,
}) {
  const windowSize = useWindowSize(100);

  const popupRef = useRef(null);

  // Style
  const calculateStyle = () => {
    if (!componentSize || !target?.current)
      return {
        show: {
          transform: `scale(1)`,
        },
        hide: {
          transform: `scale(0)`,
        },
      };

    const targetBounding = target.current.getBoundingClientRect();

    return {
      show: getShowStyle({
        position: showPosition,
        targetBounding,
        windowSize,
        componentSize,
        displacementX,
        displacementY,
      }),
      hide: getHideStyle({
        position: hidePosition,
        targetBounding,
        windowSize,
        componentSize,
      }),
    };
  };

  // States
  const [show, setShow] = useState(show);
  const [componentSize, setComponentSize] = useState(null);
  const resizeObserver = useRef(
    new ResizeObserver((entries) => {
      setComponentSize(entries[0].contentRect);
    })
  );

  const [styles, setStyles] = useState(() => calculateStyle());

  const currentStyle = useMemo(
    () => (show ? styles.show : styles.hide) || {},
    [styles, show]
  );

  // Effects
  useLayoutEffect(() => {
    if (_show) {
      if (calculateStyleOnShow) {
        handleStyle();
      }

      setShow(true);

      if (hideOnScroll) {
        const onScroll = () => toggle();

        window.addEventListener('wheel', onScroll);

        return () => {
          window.removeEventListener('wheel', onScroll);
        };
      }

      if (blockScroll) {
        document.body.style.overflow = 'hidden';
      }
    } else {
      setShow(false);

      if (blockScroll) {
        document.body.style.overflow = 'auto';
      }
    }
  }, [_show]);

  useEffect(() => {
    handleStyle({ shouldResetTransition: !show });
  }, [windowSize, target, componentSize, displacementX, displacementY]);

  useEffect(() => {
    if (resizeObserver.current && popupRef.current) {
      resizeObserver.current.observe(popupRef.current);
    }
  }, []);

  // Functions
  const handleStyle = ({
    shoudlReturnStyle = false,
    shouldResetTransition = true,
  } = {}) => {
    const styles = calculateStyle();

    if (shouldResetTransition && styles.hide && popupRef.current) {
      popupRef.current.style.transition = 'none';
      popupRef.current.style.transform = styles.hide.transform;
      popupRef.current.offsetHeight;
      popupRef.current.style.transition = '';
    }

    if (shoudlReturnStyle) return styles;
    else setStyles(styles);
  };

  // Render
  return (
    <CustomPortal>
      <div className={classNames('popup-modal', className, { show })}>
        <div className="modal-content" style={currentStyle} ref={popupRef}>
          {children}
        </div>
        {show ? <div className="background" onClick={toggle} /> : null}
      </div>
    </CustomPortal>
  );
}
