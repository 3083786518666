export const PixTypes = {
  CPF: 'CPF',
  CNPJ: 'CNPJ',
  EMAIL: 'EMAIL',
  PHONE: 'PHONE',
  RANDOM: 'RANDOM',
  NONE: 'NONE',
  QRCODE: 'QRCODE',
};

export const StarkPixTypes = {
  cpf: [PixTypes.CPF],
  cnpj: [PixTypes.CNPJ],
  phone: [PixTypes.PHONE],
  email: [PixTypes.EMAIL],
  evp: [PixTypes.RANDOM],
};

export const pixStripRegex = /[^A-Za-z0-9\.\_\@\-]/g;
