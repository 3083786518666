import React from 'react';
import { Row } from 'reactstrap';
import { Colxx } from '../../components/common/CustomBootstrap';

import ContentLoader from 'react-content-loader';
import LoaderAnalyticsSingle from '../../components/loaders/LoaderAnalyticsSingle';

export default function SpentLoading() {
  return (
    <Row className="w-100">
      <Colxx xxs="6" className="mt-5 pt-5">
        <LoaderAnalyticsSingle />
      </Colxx>
      <Colxx xxs="6" className="mt-3">
        <ContentLoader
          speed={2}
          width={300}
          height={180}
          viewBox="0 0 300 180"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
        >
          <rect x="0" y="0" rx="3" ry="3" width="300" height="180" />
        </ContentLoader>
      </Colxx>
    </Row>
  );
}
