import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import {
  RefundStatus,
  RefundStatusClasses,
  BudgetStatusMessages,
  AdminstrativeBudgetStatusMessages,
} from '../../../constants/refundStatus';
import { getBudgetTotal } from '../../../utils/budget';
import { stringToMoney } from '../../../utils/money';
import CustomProgressBar from '../../common/CustomProgressBar';

function BudgetProgress({ budget }) {
  const { budgetValue, total, progress } = useMemo(() => {
    const {
      budget: budgetValue,
      fare: { total, currency },
    } = budget;
    return {
      budgetValue: stringToMoney(budgetValue, 2, currency),
      total: stringToMoney(total, 2, currency),
      progress: budgetValue ? total / budgetValue : 0,
    };
  }, [budget]);

  return (
    <div className="budget-progress flex-grow-1 mr-3 mt-2">
      <CustomProgressBar progress={progress} thin />
      <div className="d-flex justify-content-between mt-2">
        <p className="p-0 m-0 total">{total}</p>
        <p className="p-0 m-0 total">{budgetValue}</p>
      </div>
    </div>
  );
}

function BudgetTotal({ budget }) {
  const total = useMemo(() => {
    const value = getBudgetTotal(budget);
    return stringToMoney(value, 2, budget.fare.currency);
  }, [budget]);

  return <p className="m-0 total flex-grow-1 my-2">{total}</p>;
}

const NEEDS_PROGRESS_BAR = [RefundStatus.ACTIVE];

export default function BudgetStatus({ budget = {} }) {
  const { messages } = useIntl();

  const { Component, statusClass, statusMessage } = useMemo(() => {
    const StatusMessages = budget.administrative
      ? AdminstrativeBudgetStatusMessages
      : BudgetStatusMessages;

    return {
      Component:
        NEEDS_PROGRESS_BAR.includes(budget.statusCode) &&
        budget.budget !== undefined
          ? BudgetProgress
          : BudgetTotal,
      statusClass: RefundStatusClasses[budget.statusCode],
      statusMessage:
        budget.statusCode !== RefundStatus.APPROVED
          ? messages[StatusMessages[budget.statusCode]]
          : '',
    };
  }, [budget.statusCode]);

  return (
    <div className="budget-status d-flex align-items-center justify-content-between flex-wrap">
      <Component budget={budget} />
      {statusMessage ? (
        <div className={`status-code rounded text-center ${statusClass}`}>
          <span>{statusMessage}</span>
        </div>
      ) : null}
    </div>
  );
}
