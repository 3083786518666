import React, { useMemo } from 'react';
import { sortByFareBasedOnProperty } from '../../../../utils/expensesDashboard';
import DefaultTab from './DefaultTab';

export default function CostCenterTab({ items, ...props }) {
  const costCenters = useMemo(() => {
    return sortByFareBasedOnProperty(
      items,
      ({ costCenter = {} }) => costCenter.label
    );
  }, [items]);

  return <DefaultTab values={costCenters} {...props} />;
}
