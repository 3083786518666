import React, { Component } from 'react';

import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import config from '../../constants/GoogleMapsBasic';
import map from 'lodash/map';
import uniqBy from 'lodash/unionBy';

const GOOGLE_API_KEY = config.key;

class CarMap extends Component {
  //Lifecycle
  mapLoaded(mapProps, map) {
    map.setOptions({ styles: config.mapStyle });

    this.defineBounds();
  }

  defineBounds() {
    const { reservations } = this.props;

    const bounds = new window.google.maps.LatLngBounds();
    map(
      uniqBy(reservations, (reservation) => {
        return reservation.carDetails.pickUpLocation.code;
      }),
      (reservation) => {
        bounds.extend(
          new window.google.maps.LatLng(
            reservation.carDetails.pickUpLocation.lat,
            reservation.carDetails.pickUpLocation.lng
          )
        );

        return true;
      }
    );

    this.refs.resultMap.map.fitBounds(bounds);
  }

  filterMarker = (carId) => {
    this.props.toggleSelectedHotels(carId);
  };

  // Render
  render() {
    const { reservations } = this.props;

    return (
      <Map
        google={this.props.google}
        zoom={14}
        scrollwheel={false}
        keyboardShortcuts={false}
        disableDoubleClickZoom={true}
        zoomControl={false}
        mapTypeControl={false}
        scaleControl={false}
        streetViewControl={false}
        panControl={false}
        rotateControl={false}
        fullscreenControl={false}
        gestureHandling="none"
        disableDefaultUI={true}
        onReady={(mapProps, map) => this.mapLoaded(mapProps, map)}
        ref="resultMap"
      >
        {map(
          uniqBy(reservations, (reservation) => {
            return reservation.carDetails.pickUpLocation.code;
          }),
          (reservation) => {
            return (
              <Marker
                key={`hotel-map-${reservation.refs.orderId}`}
                position={{
                  lat: reservation.carDetails.pickUpLocation.lat,
                  lng: reservation.carDetails.pickUpLocation.lng,
                }}
                icon={{
                  url: '/assets/img/icons/location_hotel_selected.svg',
                  scaledSize: new window.google.maps.Size(10, 10),
                }}
              />
            );
          }
        )}
      </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: GOOGLE_API_KEY,
})(CarMap);
