import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getAuthRedux } from '../utils/redux';
import { useIntl } from 'react-intl';
import { sortAlphabetically } from '../utils/sort';
import { MappedBudgetCategories } from '../constants/refundTypes';

export default function useBudgetCategories({
  cardType,
  messagePrefix = 'refund.budget-category',
}) {
  const { organization } = useSelector(getAuthRedux);
  const { messages } = useIntl();

  // States
  const categoriesConfig = useMemo(
    () => (organization.config?.refunds?.categories || {})[cardType] || {},
    [organization, cardType]
  );

  const categories = useMemo(() => {
    const categories = MappedBudgetCategories[cardType] || {};

    return sortAlphabetically(
      Object.keys(categories)
        .filter((category) => categoriesConfig[category] !== false)
        .map((category) => {
          const value = categories[category];

          return {
            value,
            label: messages[`${messagePrefix}.${cardType}.${value}`],
          };
        }),
      'label'
    );
  }, [cardType, categoriesConfig, messagePrefix]);

  return categories;
}
