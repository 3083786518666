import firebaseApp from '../services/Firebase';

export const loadPassengers = async (orgId) => {
  const passengersSnap = await firebaseApp
    .getUsersFromOrganization(orgId)
    .get();

  const passengers = [];

  passengersSnap.forEach((docSnap) => {
    const { firstName, lastName } = docSnap.data();

    passengers.push({
      value: docSnap.id,
      label: `${firstName} ${lastName}`,
    });
  });

  return passengers;
};
