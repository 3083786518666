import React, { useMemo, useState } from 'react';
import { Row, Modal, ModalBody, ModalFooter, CustomInput } from 'reactstrap';
import { Colxx } from '../../components/common/CustomBootstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import find from 'lodash/find';
import minBy from 'lodash/minBy';
import orderBy from 'lodash/orderBy';
import map from 'lodash/map';

import { stringToMoney } from '../../utils/money';

import ReservationParams from './ReservationParams/ReservationParams';
import OfficePhotos from '../../components/order/OfficePhotos';
import OfficeAmenities from '../../components/order/OfficeAmenities';
import { useIntl } from 'react-intl';
import { trackPropertiesSegment } from '../../utils/segment';
import ReservationButton from './ReservationButton';
import { useSelector } from 'react-redux';
import useIsReservationValid from '../../hooks/useIsReservationValid';
import CurrencyWrapper from '../../components/common/CurrencyWrapper';
import OrderStructures from '../../constants/orderStructure';

function OfficeTotal({
  item,
  selectedRoom,
  createReservation,
  canReservate = true,
  orderStructure,
  reservationParams,
  serviceCharge = 0,
}) {
  const { messages } = useIntl();

  const { isValid, disabled, title } = useIsReservationValid({
    orderStructure,
    reservationParams,
  });

  const additionalInfo = useMemo(
    () =>
      orderStructure === OrderStructures.PERSONAL
        ? formatMessage(
            {
              id: 'containers.search.flight-modal.taxes',
            },
            {
              taxes: stringToMoney(serviceCharge, 0, item.fare.currency),
            }
          )
        : null,
    [item, orderStructure, serviceCharge]
  );

  if (selectedRoom?.name) {
    return (
      <ModalFooter>
        <div>
          <div className="text mt-3 pr-3">
            <span>{messages['general.total']}</span>
            <CurrencyWrapper value={selectedRoom?.amount} decimalPlaces={0}>
              {({ formattedValue }) => formattedValue}
            </CurrencyWrapper>
            {additionalInfo ? (
              <span style={{ fontSize: '0.5em' }} className="text-theme-3 mb-2">
                {additionalInfo}
              </span>
            ) : null}
            <hr />
          </div>
          <div className="description pr-3">
            <p className="mb-0">{selectedRoom?.name}</p>

            <p className="mb-0">
              {selectedRoom?.size}{' '}
              {selectedRoom?.size > 1
                ? messages['general.people']
                : messages['general.person']}
            </p>

            <p>{selectedRoom?.description}</p>
          </div>
          <div className="text pr-3">
            <hr />
          </div>
          <div className="description pr-3">
            <p className="mb-0 text-small">
              {messages['containers.search.modal.fees']}
            </p>
          </div>

          <ReservationButton
            onClick={createReservation}
            disabled={!canReservate || disabled}
            isValid={isValid}
            title={title}
          />
        </div>
      </ModalFooter>
    );
  } else {
    return (
      <ModalFooter>
        <div>
          <div className="text mt-3 pr-3">
            <span>{messages['general.from']}</span>
            <CurrencyWrapper
              value={minBy(item.rooms, 'amount').amount}
              decimalPlaces={0}
            >
              {({ formattedValue }) => formattedValue}
            </CurrencyWrapper>
            <hr />
          </div>
          <div className="description pr-3">
            <p>{messages['containers.search.office-modal.select-room']}</p>
          </div>
        </div>
      </ModalFooter>
    );
  }
}

export default function OfficeModal({ toggleModal, item, reserveItem, user }) {
  const { messages } = useIntl();
  const { serviceCharge } = useSelector(({ auth }) => ({
    serviceCharge: parseFloat(auth.organization.fee?.office || 0),
  }));

  const [reservationParams, setReservationParams] = useState({});
  const [selectedRoom, setRoom] = useState({});
  const [orderStructure, setStructure] = useState(OrderStructures.ORGANIZATION);

  // Room Selection
  const selectCheckbox = (e) => {
    if (e.target.checked) {
      setRoom(find(item.rooms, ['name', e.target.id]));
    } else {
      setRoom({});
    }
  };

  // Reservation
  const createReservation = () => {
    if (orderStructure) {
      reserveItem({
        reservationParams,
        item: item,
        selectedRoom: selectedRoom,
      });
    }
  };

  // Render
  return (
    <Modal isOpen={true} toggle={toggleModal} className="reservation">
      <ModalBody className="hotel">
        <Row>
          <Colxx xxs="5" className="pr-0">
            <OfficePhotos office={item.office} officeId={item.officeId} />
          </Colxx>
          <Colxx xxs="7" className="pl-0">
            <div className="hotel-content">
              <PerfectScrollbar
                options={{
                  suppressScrollX: true,
                  wheelPropagation: false,
                }}
              >
                <h3 className="hotel-title ml-3">{item.office.name}</h3>

                <p className="text-muted-darke ml-3 text-capitalize">
                  {item.office.destination.address.toLowerCase()}
                </p>

                <OfficeAmenities
                  office={item.office}
                  officeId={item.officeId}
                />

                <ReservationParams
                  setReservationParams={setReservationParams}
                  orderStructure={orderStructure}
                  setStructure={setStructure}
                />

                <table className="reservation-rooms ml-3">
                  <thead>
                    <tr>
                      <th>
                        <h5>
                          {messages['containers.search.office-modal.room-name']}
                        </h5>
                      </th>
                      <th>
                        <h5>
                          {messages['containers.search.office-modal.room-size']}
                        </h5>
                      </th>
                      <th>
                        <h5>
                          {
                            messages[
                              'containers.search.office-modal.price-total'
                            ]
                          }
                        </h5>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {map(orderBy(item.rooms, 'amount'), (room, index) => {
                      // const roomDetails = find(item.hotel.rooms, {
                      //    id: room.mediaId,
                      //});

                      return (
                        <tr key={`room-${index}`}>
                          <td>
                            <CustomInput
                              type="checkbox"
                              id={room.name}
                              className="custom-check"
                              label={room.name}
                              title={room.name}
                              checked={selectedRoom.name === room.name}
                              onChange={(e) => {
                                trackPropertiesSegment('User selected office', {
                                  room,
                                  user,
                                });
                                selectCheckbox(e);
                              }}
                            />
                          </td>
                          <td>{room.size}</td>
                          <td>{stringToMoney(room.amount)}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>

                <h5 className="mt-4 ml-3">
                  {
                    messages[
                      'containers.search.office-modal.office-description'
                    ]
                  }
                </h5>
                <div
                  className="ml-3 mr-3 mb-5"
                  dangerouslySetInnerHTML={{
                    __html: item.office.description['pt'],
                  }}
                />
              </PerfectScrollbar>
            </div>
          </Colxx>
        </Row>
      </ModalBody>

      <OfficeTotal
        item={item}
        selectedRoom={selectedRoom}
        createReservation={createReservation}
        canReservate={orderStructure}
        orderStructure={orderStructure}
        reservationParams={reservationParams}
        serviceCharge={serviceCharge}
      />
    </Modal>
  );
}
