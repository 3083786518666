import { useState } from 'react';
import appFunctions from '../services/Functions';
import { openBlob } from '../utils/fileGeneration';

export default function useBudgetsExpenses() {
  const [loading, setLoading] = useState(false);

  const buildReport = async (reports) => {
    try {
      setLoading(true);

      const { data } = await appFunctions.printBudgetsExpenses(reports);

      openBlob(new Blob([data], { type: 'application/pdf' }));

      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  return {
    loading,
    buildReport,
  };
}
