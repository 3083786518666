import moment from 'moment';
import React, { useMemo } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import { stringToMoney } from '../../utils/money';
import noItemsImg from '../../assets/img/expenses/empty-cart.png';
import { useIntl } from 'react-intl';

const getOrCreateTooltip = (chart) => {
  let tooltipEl = chart.canvas.parentNode.querySelector('div');

  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)';
    tooltipEl.style.borderRadius = '3px';
    tooltipEl.style.color = 'white';
    tooltipEl.style.opacity = 1;
    tooltipEl.style.pointerEvents = 'none';
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.transform = 'translate(-50%, 0)';
    tooltipEl.style.transition = 'all .1s ease';

    const table = document.createElement('table');
    table.style.margin = '0px';

    tooltipEl.appendChild(table);
    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
};

const externalTooltipHandler = (context, numBudgets) => {
  // Tooltip Element
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart);

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  // Set Text
  if (tooltip.body) {
    const titleLines = tooltip.title || [];
    const bodyLines = tooltip.body.map((b) => b.lines);

    let budgetTextContent = '';

    titleLines.forEach((title) => {
      budgetTextContent = `${numBudgets[title]} orçamento(s)`;
    });

    const tableBody = document.createElement('tbody');

    bodyLines.forEach((body, i) => {
      const colors = tooltip.labelColors[i];

      const span = document.createElement('span');
      span.style.background = colors.backgroundColor;
      span.style.borderColor = colors.borderColor;
      span.style.borderWidth = '2px';
      span.style.marginRight = '10px';
      span.style.height = '10px';
      span.style.width = '10px';
      span.style.display = 'inline-block';

      const priceTr = document.createElement('tr');
      priceTr.style.backgroundColor = 'inherit';
      priceTr.style.borderWidth = 0;

      const priceTd = document.createElement('td');
      priceTd.style.borderWidth = 0;
      priceTd.style.fontSize = '0.9rem';
      priceTd.style.fontWeight = 500;
      priceTd.style.lineHeight = 1;

      const budgetTr = document.createElement('tr');
      budgetTr.style.backgroundColor = 'inherit';
      budgetTr.style.borderWidth = 0;

      const budgetTd = document.createElement('td');
      budgetTd.style.borderWidth = 0;
      budgetTd.style.fontSize = '0.65rem';
      budgetTd.style.lineHeight = 1.5;
      budgetTd.style.fontWeight = 300;
      budgetTd.style.marginTop = '50px';

      const priceText = document.createTextNode(
        stringToMoney(body[0].replace(',', ''), 2)
      );
      const budgetText = document.createTextNode(budgetTextContent);

      priceTd.appendChild(priceText);
      priceTr.appendChild(priceTd);
      tableBody.appendChild(priceTr);

      budgetTd.appendChild(budgetText);
      budgetTr.appendChild(budgetTd);
      tableBody.appendChild(budgetTr);
    });

    const tableRoot = tooltipEl.querySelector('table');

    // Remove old children
    while (tableRoot.firstChild) {
      tableRoot.firstChild.remove();
    }

    // Add new children
    tableRoot.appendChild(tableBody);
  }

  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1;
  tooltipEl.style.left = positionX + tooltip.caretX + 'px';
  tooltipEl.style.top = positionY + tooltip.caretY - 20 + 'px';
  tooltipEl.style.font = tooltip.options.bodyFont.string;
  tooltipEl.style.padding = '15px';
  tooltipEl.style.width = 'fit-content';
};

export default function SmallLineBalance({ balance, className = '' }) {
  const { messages } = useIntl();

  const [charData, numBudgets] = useMemo(() => {
    if (balance) {
      const today = moment().format('YYYY-MM-DD'),
        labels = [],
        data = [],
        numBudgets = {},
        summation = [];
      let sum = 0;

      Object.keys(balance)
        .sort((a, b) => a.localeCompare(b))
        .forEach((key) => {
          const { amount, budgets } = balance[key];
          let label;

          if (key === today) label = 'Hoje';
          else {
            const splitted = key.split('-');
            label = `${splitted[2]}/${splitted[1]}`;
          }

          labels.push(label);
          data.push(amount);
          numBudgets[label] = budgets.length;

          sum += amount;
          summation.push(sum);
        });

      return [
        {
          labels,
          datasets: [
            {
              data,
              backgroundColor: '#43418e',
              borderRadius: 1,
              hoverBackgroundColor: '#333077',
              borderRadius: 3,
            },
          ],
        },
        numBudgets,
      ];
    } else return [null, null];
  }, [balance]);

  return (
    <div className={className}>
      {charData ? (
        <Bar
          data={charData}
          options={{
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                enabled: false,
                position: 'nearest',
                external: (context) =>
                  externalTooltipHandler(context, numBudgets),
              },
            },
            maintainAspectRatio: false,
            scales: {
              x: {
                grid: {
                  display: false,
                  drawTicks: false,
                  drawBorder: false,
                },
                ticks: {
                  padding: 10,
                },
              },
              y: {
                ticks: {
                  display: false,
                },
                grid: {
                  display: false,
                  drawTicks: false,
                  drawBorder: false,
                },
              },
            },
          }}
        />
      ) : (
        <div className="d-flex align-items-center w-70 ml-4">
          <img src={noItemsImg} width={180} />
          <div className="ml-4">
            <h2
              className="font-primary text-dark one-line-height mb-2"
              style={{ fontSize: '1.3rem' }}
            >
              {messages['balance-card.empty-data.title']}
            </h2>
            <p className="m-0 p-0 text-muted" style={{ lineHeight: 1.3 }}>
              {messages['balance-card.empty-data.description']}
            </p>
          </div>
        </div>
      )}
    </div>
  );
}
