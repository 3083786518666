import React, { Component } from 'react';
import { Modal, ModalBody } from 'reactstrap';

class LoadingModal extends Component {
  constructor(props) {
    super(props);
  }

  // Render
  render() {
    return (
      <Modal className="reservation small">
        <ModalBody>
          <div className="loading p-relative" />
        </ModalBody>
      </Modal>
    );
  }
}

export default LoadingModal;
