import React, { useMemo, useState } from 'react';
import { RefundTypes } from '../../../constants/refundTypes';
import AdministrativeAccountCreationModal from '../AdministrativeAccountCreationModal';
import CreditCardNewModal from '../CreditCardNewModal';

import NewCreditCardSelectionModal from '../NewCreditCardSelectionModal';
import PersonalAccountCreationModal from '../PersonalAccountCreationModal';
import VirtualCardCreationModal from '../VirtualCardCreationModal';

const COMPONENTS = {
  START: NewCreditCardSelectionModal,
  [RefundTypes.PHYSICAL_CARD]: CreditCardNewModal,
  [RefundTypes.VIRTUAL_CARD]: VirtualCardCreationModal,
  [RefundTypes.PERSONAL_ACCOUNT]: PersonalAccountCreationModal,
  [RefundTypes.ADMINISTRATIVE_ACCOUNT]: AdministrativeAccountCreationModal,
};

export default function NewCreditCardModal({ closeModal }) {
  const [step, setStep] = useState('START');

  const Component = useMemo(() => COMPONENTS[step], [step]);

  return Component ? (
    <Component closeModal={closeModal} setStep={setStep} />
  ) : null;
}
