import React, { useMemo } from 'react';
import { ADMINISTRATIVE_BUDGET_TYPES } from '../../../constants/administrativeBudgets';
import { RefundStatus } from '../../../constants/refundStatus';
import BoletoInvoiceInfo from './InvoiceInfo/BoletoInvoiceInfo';
import PixInvoiceInfo from './InvoiceInfo/PixInvoiceInfo';
import BoletoPaymentInfo from './PaymentInfo/BoletoPaymentInfo';
import PixPaymentInfo from './PaymentInfo/PixPaymentInfo';

const PaymentInfoComponents = {
  [ADMINISTRATIVE_BUDGET_TYPES.BOLETO]: BoletoPaymentInfo,
  [ADMINISTRATIVE_BUDGET_TYPES.PIX]: PixPaymentInfo,
};

const InvoiceComponents = {
  [ADMINISTRATIVE_BUDGET_TYPES.BOLETO]: BoletoInvoiceInfo,
  [ADMINISTRATIVE_BUDGET_TYPES.PIX]: PixInvoiceInfo,
};

export default function ItemTab({ budget, item }) {
  // State
  const { PaymentInfoComponent, InvoiceComponent } = useMemo(() => {
    if (!item) return {};

    const type = item.payment.type;

    return {
      PaymentInfoComponent: PaymentInfoComponents[type],
      InvoiceComponent:
        budget.statusCode === RefundStatus.APPROVED
          ? InvoiceComponents[type]
          : null,
    };
  }, [item, budget]);

  // Render
  return (
    <div className="mb-5 pb-4">
      {PaymentInfoComponent ? (
        <PaymentInfoComponent item={item} budget={budget} />
      ) : null}
      {InvoiceComponent ? (
        <InvoiceComponent item={item} budget={budget} />
      ) : null}
    </div>
  );
}
