import React from 'react';
import map from 'lodash/map';

import { amenities } from '../../constants/hotels';

export default function HotelAmenities({ hotelId, hotel }) {
  return (
    <ul className="amenities">
      {map(hotel.amenities, (amenity, index) => {
        if (!amenities[amenity]) return;

        return (
          <li key={`hotel-${hotelId}-amenity-${index}`}>
            <img
              src={`/assets/img/icons/amenities/${amenities[amenity]}`}
              alt={amenity}
              title={amenity}
            />
          </li>
        );
      })}

      {map(hotel.covidResponse?.pt, (description, index) => {
        return (
          <li key={`hotel-${hotelId}-covid-${index}`}>
            <img
              src={`/assets/img/icons/amenities/covid.svg`}
              alt={description}
              title={description}
            />
          </li>
        );
      })}
    </ul>
  );
}
