import React, { useState } from 'react';
import { CardTitle, Label, Button, Alert } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import appFunctions from '../../services/Functions';

import IntlMessages from '../../helpers/IntlMessages';
import { useIntl } from 'react-intl';

export default function CodeNew({ changeStep, resetSuccess, code }) {
  const [error, setError] = useState('');

  const { messages } = useIntl();

  const onUserCode = async (newPassword, setSubmitting) => {
    try {
      await appFunctions.activateAccount({
        code,
        password: newPassword,
      });

      await resetSuccess(newPassword);

      setSubmitting(false);
    } catch (err) {
      console.error(err);
      setSubmitting(false);
      setError(`${messages['user.GeneralException']} ${err.message}`);
    }
  };

  // Render
  return (
    <Formik
      initialValues={{ newPassword: '', newPasswordConfirm: '' }}
      validationSchema={Yup.object().shape({
        newPassword: Yup.string()
          .required(messages['forms.validation.password.required'])
          .min(8, messages['forms.validation.password.weak']),
        newPasswordConfirm: Yup.string().equals(
          [Yup.ref('newPassword')],
          messages['forms.validation.password.confirmation.required']
        ),
      })}
      onSubmit={(values, { setSubmitting }) => {
        onUserCode(values.newPassword, setSubmitting);
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <NavLink to="/user/login" className="d-block mb-4">
            <i className="iconsminds-arrow-out-left" />
            <IntlMessages id="user.login.page" />
          </NavLink>

          <CardTitle className="mb-4">
            <IntlMessages id="user.new-password" />
          </CardTitle>

          <p className="mb-4">
            <IntlMessages id="user.new-password_desc" />
          </p>

          {error && (
            <Alert color="danger" className="mt-2 mb-2">
              {error}
            </Alert>
          )}

          <Label className="form-group has-top-label mb-4">
            <Field
              type="password"
              name="newPassword"
              className="form-control"
            />
            <IntlMessages id="user.new_password" />
            <ErrorMessage
              name="newPassword"
              component="div"
              className="invalid-feedback d-block"
            />
          </Label>

          <Label className="form-group has-top-label mb-4">
            <Field
              type="password"
              name="newPasswordConfirm"
              className="form-control"
            />
            <IntlMessages id="user.new_password_confirm" />
            <ErrorMessage
              name="newPasswordConfirm"
              component="div"
              className="invalid-feedback d-block"
            />
          </Label>

          <div className="d-flex justify-content-end align-items-center">
            <Button
              type="submit"
              color="primary"
              outline
              size="lg"
              disabled={isSubmitting}
            >
              <IntlMessages id="forms.action.reset" />
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
