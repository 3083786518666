import { useEffect, useMemo, useState } from 'react';
import searchStatus from '../constants/searchStatus';
import firebaseApp from '../services/Firebase';
import useDataSnapshot from './useDataSnapshot';

import { getDataFromDocument } from '../utils/firebase';

const loadingStatus = [searchStatus.LOADING, searchStatus.NEW];

const getInitialSearch = () => ({
  status: searchStatus.NEW,
});

export default function useSearch({
  searchCode,
  getResults,
  getParams,
  limit = 20,
}) {
  const [search, setSearch] = useDataSnapshot(
    {
      fetchFunction: () => firebaseApp.getSearch(searchCode),
      getSnapshotDataFunction: (snap) =>
        getDataFromDocument(snap, { status: searchStatus.DONE }),
      formatFunction: (data) => ({ ...data, searchCode }),
      shouldLoad: searchCode,
      initialData: getInitialSearch(),
    },
    [searchCode]
  );

  const [results, setResults] = useState([]);
  const [loadingResults, setLoadingResults] = useState(true);

  const [params, setParams] = useState({});
  const [loadingParams, setLoadingParams] = useState(true);

  const isLoading = useMemo(
    () =>
      loadingStatus.includes(search.status) || loadingResults || loadingParams,
    [search, loadingResults, loadingParams]
  );

  // Effects
  useEffect(() => {
    if (!searchCode) {
      setSearch(getInitialSearch());
      setResults([]);
      setParams({});
    } else if (!loadingResults) setLoadingResults(true);
  }, [searchCode]);

  useEffect(() => {
    if (!loadingStatus.includes(search.status)) {
      doGetResults();
      doGetParams();
    }
  }, [search]);

  // Functions
  const doGetResults = async () => {
    try {
      setLoadingResults(true);
      let query = getResults(searchCode);

      if (limit) query = query.limit(limit);

      const resultsSnap = await query.get();

      let results = [];

      resultsSnap.forEach((resultSnap) => {
        const result = resultSnap.data();
        result.id = resultSnap.id;

        results.push(result);
      });

      setResults(results);
    } catch (err) {
      console.log('Unable to get results', err);
    } finally {
      setLoadingResults(false);
    }
  };

  const doGetParams = async () => {
    try {
      setLoadingParams(true);

      const paramSnap = await getParams(searchCode).get();

      setParams(paramSnap.data() || {});
    } catch (err) {
    } finally {
      setLoadingParams(false);
    }
  };

  return {
    search,
    params,
    loadingParams,
    results,
    loadingResults,
    loading: isLoading,
  };
}
