import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { stringToMoney } from '../../utils/money';
import Badge from './Badge';
import { getPriceDifference, getSelectedClass } from './utils';
import moment from 'moment';
import CurrencyWrapper from '../common/CurrencyWrapper';

export default function HotelAlternative({
  alternative,
  originalTotal,
  index,
  selectedAlternative,
  onClick = () => {},
  badgeText,
}) {
  const { messages, formatMessage } = useIntl();

  const {
    hotelName,
    hotelImage,
    category,
    roomName,
    total,
    _badgeText,
    cancelUntil,
    hasBreakfast,
  } = useMemo(() => {
    const selectedRoom = alternative.rooms[0];

    const today = moment().format('YYYY-MM-DD');

    const _badgeText =
      badgeText ||
      (index === 0
        ? messages['order.alternative.best-price']
        : formatMessage(
            {
              id: 'order.alternative.save',
            },
            {
              save: getPriceDifference(
                originalTotal,
                alternative.fare.total,
                alternative.fare.currency
              ),
            }
          ));

    return {
      hotelName: alternative.hotel.name,
      hotelImage: alternative.hotel.mainPhoto,
      roomName: selectedRoom.description.toLowerCase(),
      category: new Array(alternative.hotel.category).fill(1),
      total: alternative.fare.total,
      _badgeText,
      cancelUntil:
        selectedRoom.cancellation.length > 0 &&
        today <= selectedRoom.cancellation[0].startDate
          ? formatMessage(
              {
                id: 'order.alternative.cancellation',
              },
              {
                date: moment(
                  selectedRoom.cancellation[0].startDate,
                  'YYYY-MM-DD'
                ).format('DD/MM/YYYY'),
              }
            )
          : null,
      hasBreakfast: selectedRoom.board?.breakfast,
    };
  }, [alternative]);

  const selectedClass = useMemo(
    () => getSelectedClass(alternative, selectedAlternative),
    [alternative, selectedAlternative]
  );

  return (
    <div
      className={`alternative-card d-flex flex-column justify-content-between ${selectedClass}`}
      onClick={onClick}
    >
      <img
        src={hotelImage}
        alt={hotelName}
        className="w-100 hotel-img"
        style={{ height: '35%', objectFit: 'cover' }}
      />
      <div
        className="p-3 d-flex flex-column justify-content-between position-relative"
        style={{ height: '65%' }}
      >
        <div>
          <div className="d-flex">
            {category.map((_, index) => (
              <img
                key={`category-${index}`}
                src="/assets/img/hotel-star.svg"
                className="mr-1"
                width={10}
                height={10}
                alt={
                  messages['alts.containers.search.hotel-item.hotel-category']
                }
              />
            ))}
          </div>
          <h2 className="m-0 text-dark font-primary title mt-1">{hotelName}</h2>
          <p className="room-name text-muted mt-1 mb-2">{roomName}</p>
          <div className="position-absolute d-flex">
            {hasBreakfast && (
              <img
                className="mr-2"
                style={{ width: 17 }}
                src="/assets/img/icons/amenities/meal-breakfast.svg"
                alt={
                  messages['containers.search.hotel-modal.breakfast-included']
                }
                title={
                  messages['containers.search.hotel-modal.breakfast-included']
                }
              />
            )}
            {cancelUntil && (
              <img
                style={{ width: 17 }}
                src="/assets/img/icons/cancel.svg"
                alt={cancelUntil}
                title={cancelUntil}
              />
            )}
          </div>
        </div>
        <div className="text-right">
          <p className="m-0 font-primary one-line-height medium">
            {messages['general.total']}
          </p>
          <h3 className="m-0 font-primary text-dark font-weight-medium one-line-height mt-1">
            <CurrencyWrapper
              value={total}
              tooltipFill="black"
              decimalPlaces={0}
            >
              {({ formattedValue }) => <span>{formattedValue}</span>}
            </CurrencyWrapper>
          </h3>
          <Badge color="#0A936D" className="mt-2 py-2 bottom-badge">
            <span className="font-weight-normal text-white small-medium-title">
              {_badgeText}
            </span>
          </Badge>
        </div>
      </div>
    </div>
  );
}
