import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import classnames from 'classnames';
import User from '../../services/User';
import { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import { useSelector } from 'react-redux';

import { locationTypes } from '../../constants/travelValues';
import {
  filterHotelLocations,
  findHotelLocation,
  resultDestinations,
} from '../../utils/findHotelLocation';

const Option = (props) => {
  const locationClass = locationTypes[props.data.type] || locationTypes.city;

  return (
    <div
      className={classnames({
        'react-select__menu_option': true,
        active: props.isFocused,
      })}
    >
      <div className={`option-icon ${locationClass.style}`} />
      <components.Option {...props} />
    </div>
  );
};

export default function HotelLocationSearch({ value, setValue, className }) {
  const { messages } = useIntl();
  const locale = useSelector(({ settings }) => {
    const { locale } = settings;
    return locale ? locale.split('-')[0] : '';
  });

  // Functions
  const findLocation = useCallback(
    (inputValue) => {
      return new Promise(async (resolve, fail) => {
        try {
          const token = await User.getToken();
          const responseServiceLocation = await findHotelLocation(
            token,
            inputValue
          );

          if (responseServiceLocation?.data?.size > 0) {
            const filteredData = filterHotelLocations(
              responseServiceLocation.data.data
            );

            const results = resultDestinations(
              filteredData,
              responseServiceLocation.data.data,
              locale
            );

            resolve(results);
          }

          resolve([]);
        } catch (err) {
          console.error(err);
          resolve([]);
        }
      });
    },
    [locale]
  );

  // Render
  return (
    <AsyncSelect
      className={classnames('react-select', className)}
      classNamePrefix="react-select"
      components={{ Option }}
      isClearable={false}
      value={value}
      cacheOptions
      placeholder=""
      noOptionsMessage={() => messages['travel.location-message']}
      loadingMessage={() => messages['travel.location-searching']}
      onChange={(selects) => setValue(selects)}
      loadOptions={findLocation}
    />
  );
}
