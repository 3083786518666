import React, { useMemo } from 'react';
import { CardBody, Row } from 'reactstrap';
import { Colxx } from '../../components/common/CustomBootstrap';
import filter from 'lodash/filter';
import find from 'lodash/find';
import forEach from 'lodash/forEach';
import orderBy from 'lodash/orderBy';
import map from 'lodash/map';
import slice from 'lodash/slice';
import { useIntl } from 'react-intl';

import FlightRouteMap from './FlightRouteMap';
import { ReactComponent as RoundTripArrow } from '../../assets/img/icons/roundtrip.svg';

import { normalizeCity } from '../../utils/city';
import { OrderTypes } from '../../constants/orderTypes';

export default function BusCardTopRoutes({ orders, stations }) {
  const { messages } = useIntl();

  const routesList = useMemo(() => {
    if (!(orders.length && stations.length)) return [];

    const busOrders = filter(orders, (o) => {
      return o.type === OrderTypes.BUS;
    });

    let routesList = {};
    forEach(busOrders, (order) => {
      forEach(order.journeys, (journey) => {
        const stops = [journey.departureStation, journey.arrivalStation].sort();

        const name = `${stops[0]} - ${stops[1]}`;

        routesList[name] = {
          departure: find(stations, (o) => {
            return o.name === stops[0];
          }),
          arrival: find(stations, (o) => {
            return o.name === stops[1];
          }),
          name,
          qtd: 0,
          ...routesList[name],
        };

        routesList[name].qtd = routesList[name].qtd + 1;
      });
    });

    return slice(orderBy(routesList, 'qtd', ['desc']), 0, 5);
  }, [orders, stations]);

  return (
    <CardBody>
      <Row>
        <Colxx sm="12">
          {map(routesList, (route) => {
            return (
              <div
                key={route.name}
                className="d-flex flex-row mb-3 pb-3 justify-content-between align-items-center"
              >
                <FlightRouteMap route={route} />
                <div className="flex-fill pl-4">
                  <div className="trip-info d-flex justify-content-left align-items-center text-dark">
                    <div className="text-right">
                      {normalizeCity(route.departure.name)}
                    </div>
                    <RoundTripArrow className="round-trip-arrow" />
                    {normalizeCity(route.arrival.name)}
                  </div>
                  {/* <p className="font-weight-medium mb-0">{route.name}</p> */}
                </div>
                <div className="pr-2">
                  {route.qtd} {messages['general.reservations']}
                </div>
              </div>
            );
          })}
        </Colxx>
      </Row>
    </CardBody>
  );
}
