import React from 'react';
import axios from 'axios';
import classnames from 'classnames';
import map from 'lodash/map';

import { components } from 'react-select';
import AsyncSelect from 'react-select/async';

import User from '../../services/User';
import messages from '../../lang/messages';

const Option = (props) => {
  return (
    <div
      className={classnames({
        'react-select__menu_option': true,
        active: props.isFocused,
      })}
    >
      <components.Option {...props} />
    </div>
  );
};

const SingleValue = (props) => {
  return (
    <div
      className={classnames({
        'react-select__single-value-container': true,
        hasAvatar: props.data.avatar ? true : false,
      })}
    >
      {props.data.avatar && (
        <img src={props.data.avatar} alt={props.data.label} />
      )}
      <components.SingleValue {...props} />
    </div>
  );
};

export default function UserSearch({ setValue, defaultUser, className = '' }) {
  const findUser = (inputValue) => {
    return new Promise(async (resolve, fail) => {
      try {
        const token = await User.getToken();

        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}/users/search`,
          {
            params: { q: inputValue },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (res.data.users.length > 0) {
          const results = map(res.data.users, (user) => {
            return {
              value: user.value,
              label: user.label,
              avatar: user.avatar,
            };
          });

          resolve(results);
        }

        resolve([]);
      } catch (err) {
        console.error(err);
        resolve([]);
      }
    });
  };

  // Render
  return (
    <AsyncSelect
      className={`react-select timeline-select ${className}`}
      classNamePrefix="react-select"
      components={{ Option, SingleValue }}
      isClearable={false}
      cacheOptions
      placeholder=""
      value={{
        value: defaultUser?.uid || '',
        label: `${defaultUser?.firstName} ${defaultUser?.lastName}` || '',
        avatar: defaultUser?.avatar || '',
      }}
      noOptionsMessage={() => {
        return messages['travel.location-message'];
      }}
      loadingMessage={() => {
        return messages['travel.location-searching'];
      }}
      onChange={(selects) => {
        setValue(selects);
      }}
      loadOptions={findUser}
    />
  );
}
