import { useCallback, useEffect, useRef, useState } from 'react';

const getSnapshotData = (snapshot, idName = 'id') => {
  const data = [];

  snapshot.forEach((doc) =>
    data.push({
      ...doc.data(),
      [idName]: doc.id,
    })
  );

  return data;
};

export default function useDataSnapshotPaginated({
  initialData = [],
  initialLoading = true,
  fetchFunction = () => {},
  getSnapshot = getSnapshotData,
  formatData = (data) => data,
  limit = 25,
  getAll,
}) {
  // Refs
  const lastLength = useRef(0);
  const snapshotSubscription = useRef(null);
  const fetchDataPromise = useRef(null);

  // States
  const [data, setData] = useState(initialData);
  const [loading, setLoading] = useState(initialLoading);
  const [currentLimit, setCurrentLimit] = useState(limit);
  const [atEnd, setAtEnd] = useState(false);

  // Effects
  useEffect(() => {
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    fetchData(currentLimit);
  }, [currentLimit]);

  useEffect(() => {
    if (getAll && !atEnd && currentLimit !== -1) setCurrentLimit(-1);
  }, [getAll]);

  // Functions
  const unsubscribe = () => {
    if (snapshotSubscription.current) {
      snapshotSubscription.current();
      snapshotSubscription.current = null;
    }
  };

  const fetchData = (currentLimit) => {
    if (atEnd) return fetchDataPromise.current;

    unsubscribe();
    setLoading(true);

    const dataPromise = new Promise((resolve, reject) => {
      let query = fetchFunction();

      if (currentLimit > 0) query = query.limit(currentLimit);

      snapshotSubscription.current = query.onSnapshot((snapshot) => {
        try {
          const data = formatData(getSnapshot(snapshot));
          setData(data);

          const numItems = data.length;

          if (
            currentLimit === -1 ||
            numItems < currentLimit ||
            lastLength.current === numItems
          )
            setAtEnd(true);
          else if (atEnd) setAtEnd(false);

          lastLength.current = numItems;
          setLoading(false);

          resolve(data);
        } catch (err) {
          console.error('Unable to fetch data', err);
          reject(err);
        }
      });
    });

    fetchDataPromise.current = dataPromise;

    return dataPromise;
  };

  const fetchMore = useCallback(() => {
    if (!(loading || atEnd)) {
      setCurrentLimit((currentLimit) => currentLimit + limit);
    }
  }, [loading, atEnd]);

  const fetchAll = useCallback(() => {
    if (!atEnd && currentLimit !== -1) {
      return fetchData(-1);
    } else {
      return fetchDataPromise.current;
    }
  }, [atEnd, currentLimit]);

  return { data, setData, loading, fetchMore, atEnd, fetchAll };
}
