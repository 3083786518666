import { useEffect, useState } from 'react';

export default function useShouldInitialize(isVisible) {
  const [initialize, setInitialize] = useState(false);

  useEffect(() => {
    if (isVisible && !initialize) setInitialize(true);
  }, [isVisible]);

  return initialize;
}
