import React, { useRef } from 'react';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import useShowToggle from '../../../../hooks/useShowToggle';
import BudgetNameDocumentationModal from '../../BudgetNameDocumentationModal/BudgetNameDocumentationModal';
import RecurrenceItem from '../RecurrenceItem';

import { RecurrenceBudgetTypes } from '../../../../constants/recurrenceBudgets';

import { ReactComponent as AlertIcon } from '../../../../assets/img/icons/alert-circle.svg';

export default function RecurrenceTab({
  recurrence = {},
  setRecurrence,
  checkboxSize = '1.5rem',
}) {
  const { messages } = useIntl();

  const targetRef = useRef(null);

  // States
  const [
    showNameDocumentation,
    setShowNameDocumentation,
    toggleNameDocumentation,
  ] = useShowToggle();

  // Render
  const renderDayRecurrenceMessage = useCallback((recurrence = {}, checked) => {
    if (!checked || !recurrence.days || recurrence.days < 29) return null;

    return (
      <p className="color-alert fs-0-7rem lh-1-5 m-0 mt-1 font-weight-medium">
        {messages['budget-recurrence.day.overflow']}
      </p>
    );
  }, []);

  return (
    <div className="recurrence-tab">
      <section className="bg-secondary-light p-4 lh-1-75">
        <span className="text-primary fs-0-75rem m-0 p-0">
          {messages['budget-recurrence.description']}
        </span>

        <span
          className="position-relative"
          ref={targetRef}
          style={{ width: '1rem' }}
        >
          <AlertIcon
            className="position-absolute text-primary ml-1 pointer mt-1"
            width="1rem"
            height="1rem"
            fill="black"
            onClick={() => setShowNameDocumentation(true)}
          />
        </span>
      </section>
      <div className="p-4">
        <RecurrenceItem
          name={RecurrenceBudgetTypes.interval}
          title={messages['budget-recurrence.interval.title']}
          description={messages['budget-recurrence.interval.description']}
          recurrence={recurrence}
          setRecurrence={setRecurrence}
          singularSuffix={messages['budget-recurrence.interval.sufix.singular']}
          pluralSuffix={messages['budget-recurrence.interval.sufix.plural']}
          checkboxSize={checkboxSize}
        />

        <RecurrenceItem
          className="mt-4 pt-2"
          name={RecurrenceBudgetTypes.date}
          recurrence={recurrence}
          setRecurrence={setRecurrence}
          title={messages['budget-recurrence.day.title']}
          description={messages['budget-recurrence.day.description']}
          singularSuffix={messages['budget-recurrence.day.sufix.singular']}
          pluralSuffix={messages['budget-recurrence.day.sufix.plural']}
          maxDays={31}
          checkboxSize={checkboxSize}
          renderBottomMessage={renderDayRecurrenceMessage}
        />
      </div>

      <BudgetNameDocumentationModal
        show={showNameDocumentation}
        toggle={toggleNameDocumentation}
        target={targetRef}
      />
    </div>
  );
}
