import React, { useMemo } from 'react';
import { OrderTypes } from '../../../constants/orderTypes';
import FlightDetails from './TravelDetails/FlightDetails';
import classNames from 'classnames';

const TravelDetails = {
  [OrderTypes.FLIGHT]: FlightDetails,
};

export default function OpenBookingReservationDetails({
  className,
  travelParams,
  travelType,
  search,
  searchParams,
  showingSteps,
}) {
  // States
  const TravelDetailsComponent = useMemo(
    () => TravelDetails[travelType],
    [travelType]
  );

  // // Render
  return (
    <div className={classNames('open-booking-reservation-details', className)}>
      {TravelDetailsComponent ? (
        <TravelDetailsComponent
          travelParams={travelParams}
          search={search}
          searchParams={searchParams}
          showingSteps={showingSteps}
        />
      ) : null}
    </div>
  );
}
