import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
} from 'reactstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import IntlMessages from '../../helpers/IntlMessages';
import { useIntl } from 'react-intl';

import firebaseApp from '../../services/Firebase';
import { trackPropertiesSegment } from '../../utils/segment';
import useDataArchive from '../../hooks/useDataArchive';

export default function ProjectModal(props) {
  const { messages } = useIntl();
  const { user } = useSelector(({ auth }) => auth);
  const { deleteDataFromArchive } = useDataArchive();

  // States
  const [project, setProject] = useState({});
  const [isLoading, setLoading] = useState(true);

  // Effects
  useEffect(() => {
    (async () => {
      if (props.id !== 'new') await loadProject();
      setLoading(false);
    })();
  }, []);

  // Functions
  const loadProject = async () => {
    const projectSnap = await firebaseApp
      .getProjectFromId(user.organizationId, props.id)
      .get();

    setProject(projectSnap.data());
    return;
  };

  // Update User
  const updateProject = async (values, setSubmitting) => {
    if (props.id !== 'new') {
      await firebaseApp.updateProjectFromOrganization(
        user.organizationId,
        props.id,
        values
      );

      trackPropertiesSegment('Project updated', {
        user,
        project: values,
      });
    } else {
      await firebaseApp.createProjectFromOrganization(
        user.organizationId,
        values
      );

      trackPropertiesSegment('New project created', {
        user,
        project: values,
      });
    }

    setSubmitting(false);
    deleteDataFromArchive('projects');

    props.toggleModal();
  };

  // Render
  if (isLoading) {
    return (
      <Modal className="admin" isOpen={true} toggle={props.toggleModal}>
        <ModalBody className="edit-group">
          <div className="loading" />
        </ModalBody>
      </Modal>
    );
  }

  return (
    <Modal className="admin" isOpen={true} toggle={props.toggleModal}>
      <ModalHeader toggle={props.toggleModal}>
        <IntlMessages id="admin.policy.project.Modal.Add" />
      </ModalHeader>
      <Formik
        initialValues={{
          name: project.name || '',
          externalId: project.externalId || '',
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required(
            messages['forms.validation.name.required']
          ),
          externalId: Yup.string(),
        })}
        onSubmit={(values, { setSubmitting }) => {
          updateProject(values, setSubmitting);
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <ModalBody>
              <Row className="mb-1">
                <Label className="form-group has-top-label mb-0 w-100">
                  <Field type="input" name="name" className="form-control" />
                  <IntlMessages id="admin.policy.project.Name" />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="invalid-feedback d-block"
                  />
                </Label>
              </Row>
              <Row className="mb-1">
                <Label className="form-group has-top-label mb-0 w-100">
                  <Field
                    type="input"
                    name="externalId"
                    className="form-control"
                  />
                  <IntlMessages id="admin.policy.project.ExternalId" />
                  <ErrorMessage
                    name="externalId"
                    component="div"
                    className="invalid-feedback d-block"
                  />
                </Label>
              </Row>
            </ModalBody>
            <ModalFooter>
              <div className="d-flex justify-content-between align-items-center">
                <Button
                  type="submit"
                  color="primary"
                  outline
                  size="lg"
                  disabled={isSubmitting}
                >
                  <IntlMessages id="forms.action.save" />
                </Button>
              </div>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
