export const getJourneyFlightID = (journeys) => {
  const firstAirport = journeys[0].departureStation.iataCode;
  const lastAirport = journeys[journeys.length - 1].arrivalStation.iataCode;

  if (firstAirport === lastAirport) {
    const middleJourney = journeys[journeys.length / 2 - 1];
    return `_${firstAirport}${middleJourney.arrivalStation.iataCode}`;
  } else return `${firstAirport}${lastAirport}`;
};

export const getArrivalJourney = (journeys, withIndex = false) => {
  const length = journeys.length;

  if (
    journeys[0].departureStation.name ===
    journeys[length - 1].arrivalStation.name
  ) {
    const arrivalName = journeys[0].arrivalStation.name;

    for (let i = 1; i < length; i++) {
      const journey = journeys[i];
      if (journey.departureStation.name === arrivalName) {
        return withIndex ? [journey, i] : journey;
      }
    }
  }

  return null;
};

export const FlightTypes = {
  OUTBOUND: 'outbound',
  INBOUND: 'inbound',
};
