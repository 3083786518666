import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDrop } from 'react-dnd';
import { useIntl } from 'react-intl';
import appFunctions from '../../../services/Functions';
import classnames from 'classnames';
import Spinner from '../../common/Spinner';
import {
  CanAddBudgetItems,
  CanDropStatus,
  RefundStatus,
} from '../../../constants/refundStatus';
import useDataSnapshot from '../../../hooks/useDataSnapshot';
import firebaseApp from '../../../services/Firebase';
import BudgetItemCard from './BudgetItemCard';
import { Button } from 'reactstrap';
import { CardCategoriesIcons } from '../../../constants/cardIcons';
import BudgetStatus from './BudgetStatus';
import { BudgetReportCheckbox } from '../../../containers/expenses/Report/BudgetReportCheckbox';

const today = moment();

export default function BudgetCard({
  budget,
  card,
  className = '',
  onClick = () => {},
  canCreateExpense = true,
  onCreateBudgetItem = () => {},
  onExpenseClick = () => {},
  ...props
}) {
  const { category = {}, fare, name, startDate, endDate, statusCode } = budget;
  const { messages, formatMessage } = useIntl();

  const [isStoring, setIsStoring] = useState(false);

  const [total, setTotal] = useState(fare.total);
  const totalRef = useRef(total);
  const canReceiveExpenses = useRef(CanDropStatus.includes(budget.statusCode));

  const date = useMemo(() => {
    const _startDate = moment(startDate, 'YYYY-MM-DD');
    const _endDate = moment(endDate, 'YYYY-MM-DD');
    const dateFormat =
      _startDate.isSame(_endDate, 'year') && _startDate.isSame(today, 'year')
        ? 'DD MMM'
        : 'DD MMM YY';

    return budget.administrative
      ? _startDate.format(dateFormat)
      : `${_startDate.format(dateFormat)} - ${_endDate.format(dateFormat)}`;
  }, [budget]);

  const [CategoryIcon, categoryLabel] = useMemo(() => {
    const {
      value = 'default',
      label = messages['refund.modal.items.refund-type.free'],
    } = category;
    return [CardCategoriesIcons[value] || CardCategoriesIcons.default, label];
  }, [category]);

  const allowItemCreation = useMemo(
    () => canCreateExpense && CanAddBudgetItems.includes(statusCode),
    [canCreateExpense, statusCode]
  );

  const [[expenses, numExpenses, remainingExpenses], _, loadingExpenses] =
    useDataSnapshot({
      fetchFunction: () => firebaseApp.getItemsFromBudget(budget.id),
      initialData: [[], 0, 0],
      formatFunction: (data) => {
        const items = data.filter((item) => item.name);

        const length = items.length;
        return [items.slice(0, 2), length, length > 2 ? length - 2 : 0];
      },
      retryOnError: true,
    });

  // Effects
  useEffect(() => {
    setTotal(fare.total);
    totalRef.current = fare.total;
    canReceiveExpenses.current = CanDropStatus.includes(budget.statusCode);
  }, [budget]);

  // Hooks
  const [{ isOver, canDrop }, dropRef] = useDrop(() => ({
    accept: 'CARD',
    drop: ({ expense, container }) => {
      if (container === 'timeline') addExpenseToRefund(expense);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    canDrop: () => {
      return canReceiveExpenses.current;
    },
  }));

  // Functions
  const updateValues = (newTotal) => {
    totalRef.current = newTotal;
    setTotal(newTotal);
  };

  const addExpenseToRefund = async (expense) => {
    if (statusCode < RefundStatus.APPROVING) {
      const newTotal = totalRef.current + parseFloat(expense.fare.total);

      try {
        setIsStoring(true);
        await appFunctions.assignExpenseToRefund(expense.id, budget.id);
        updateValues(newTotal);
      } catch (err) {
        console.debug(err);
      } finally {
        setIsStoring(false);
      }
    }
  };

  const goToExpense = (e) => {
    e.stopPropagation();
    e.preventDefault();

    onCreateBudgetItem(budget.id);
  };

  // // Render
  const renderExpenses = () => {
    if (loadingExpenses) {
      return (
        <div className="h-100 d-flex align-items-center justify-content-center">
          <Spinner relative style={{ width: 50, height: 50 }} />
        </div>
      );
    } else {
      if (numExpenses > 0) {
        return (
          <div>
            {expenses.map((item, index) => (
              <BudgetItemCard
                key={item.id}
                item={item}
                className="my-2"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();

                  onExpenseClick(budget.id, item.id);
                }}
                style={{ animationDelay: `${index * 100}ms` }}
              />
            ))}
            {remainingExpenses > 0 && (
              <p
                className="text-center text-muted m-0 p-0 remaining"
                style={{ fontSize: '0.7rem' }}
              >
                {formatMessage(
                  {
                    id: `refund.refund-budget-card.remaining.${
                      remainingExpenses > 1 ? 'multiple' : 'one'
                    }`,
                  },
                  {
                    remainingExpenses,
                  }
                )}
              </p>
            )}
          </div>
        );
      } else
        return (
          <div className="d-flex align-items-center justify-content-center h-100 px-3">
            <p className="text-center text-muted no-expenses m-0 mb-2">
              {messages['refund.refund-budget-card.empty']}
            </p>
          </div>
        );
    }
  };

  return (
    <div
      name="create-budget-modal"
      className={`refund-budget-card refund d-flex flex-column justify-content-between pointer ${className} ${classnames(
        {
          hovering: isOver && canDrop,
          storing: isStoring,
        }
      )}`}
      ref={dropRef}
      onClick={onClick}
      {...props}
    >
      <BudgetReportCheckbox color="white" budget={budget} card={card} />
      <div className="d-flex align-items-center top">
        {isStoring && (
          <div className="loading-container">
            <Spinner />
          </div>
        )}
        <div className="icon-card d-flex align-items-center justify-content-center">
          <CategoryIcon title={categoryLabel} className="icon" fill="white" />
        </div>
        <div className="ml-2 w-80 align-self-start">
          <p className="p-0 m-0 date">{date}</p>
          <h2 className="p-0 m-0 name font-primary">{name}</h2>
          <BudgetStatus budget={budget} />
        </div>
      </div>
      <div className="bottom flex-grow-1 d-flex flex-column">
        <div className="flex-grow-1">{renderExpenses()}</div>
        {allowItemCreation && (
          <Button
            className="new-expense-btn align-self-center py-2 px-4 pretty-btn"
            size="sm"
            color="primary"
            onClick={goToExpense}
          >
            {messages['refund.refund-budget-card.new-expense']}
          </Button>
        )}
      </div>
    </div>
  );
}
