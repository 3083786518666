import 'react-perfect-scrollbar/dist/css/styles.css';
import './portao3-legacy/assets/css/vendor/bootstrap.min.css';

import {
  isMultiColorActive,
  defaultColor,
  themeColorStorageKey,
  isDarkSwitchActive,
} from './portao3-legacy/constants/defaultValues';
const color =
  (isMultiColorActive || isDarkSwitchActive) &&
  localStorage.getItem(themeColorStorageKey)
    ? localStorage.getItem(themeColorStorageKey)
    : defaultColor;

localStorage.setItem(themeColorStorageKey, color);

let render = () => {
  import('./portao3-legacy/assets/css/sass/themes/' + color + '.scss').then(
    (x) => {
      require('./AppRenderer');
    },
  );
};

render();
